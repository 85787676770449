import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input, Select, DatePicker, Button, TimePicker, Checkbox } from 'antd'
import dayjs from 'dayjs'
import range from 'lodash/range'

import IntlMessages from '../utils/IntlMessages'
import { TimezoneInfo } from '../timezone/timezone-info'

import { ShortestLeaveIntervalEnum } from '../../types/leave-policy'
import { HourFormatEnum } from '../../types/user'
import { IToilForm, IToilFormData } from './types'
import { IGetUserForLeaveReueqstUserData, IGetUserForLeaveReueqstUserDataLocationLeavePolicies, IUserIdAndName } from '../../../frontend/src/types/custom-queries'
import { getUserWorkingHoursPerDay, userHasDefaultWorkingHours } from '../../functions/work-week'
import logger from '../../functions/logger'

const { TextArea } = Input
const { Option } = Select
const { RangePicker } = DatePicker

const ToilForm = ({
  onSave,
  authUserId,
  authUserRole,
  listOfUsers,
  loading,
  onCancel,
  hourFormat,
  toilRequest,
  onSelectUserToAddToilRequest,
  selectedUser,
  onLeaveTypeSelected,
  isEditToilRequest = false,
  allowAddToilForAuthUser = true,
}: IToilForm): React.ReactElement => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const [formLoading, setFormLoading] = useState(false)
  const [isAddingToil, setIsAddingToil] = useState(false)
  const [selectedLeavePolicy, setSelectedLeavePolicy] = useState<IGetUserForLeaveReueqstUserDataLocationLeavePolicies | null>(null)
  const [leaveTypes, setLeaveTypes] = useState<IGetUserForLeaveReueqstUserDataLocationLeavePolicies[]>([])
  const [hourlyLeave, setHourlyLeave] = useState<boolean>(false)
  const [userTimezone, setUserTimezone] = useState<string | undefined>()

  let defaultValue

  if (toilRequest && !toilRequest.isPartDay) {
    defaultValue = {
      reason: toilRequest.reason,
      rangeDate: [dayjs(toilRequest.startDate), dayjs(toilRequest.endDate)],
      leaveTypeId: toilRequest.leaveType.id,
    }
  } else if (toilRequest && toilRequest.isPartDay) {
    defaultValue =  {
      hourlyLeave: true,
      reason: toilRequest.reason,
      startDate: dayjs(toilRequest.startDate),
      range: [dayjs().hour(toilRequest.partDayStartHour), dayjs().hour(toilRequest.partDayEndHour)],
      leaveTypeId: toilRequest.leaveType.id,
    }
  } else {
    defaultValue =  {
      reason: formatMessage({id: 'components.toil.reasonDefault'}),
      hourlyLeave: false,
      userId: selectedUser?.id,
    }
  }

  const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 12 },
  }

  const offsetItemLayout = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16, offset: 6 },
      md: { span: 16, offset: 6 },
      lg: { span: 16, offset: 6 },
      xl: { span: 16, offset: 6 },
    },
  }


  useEffect(() => {
    if (loading) {
      setFormLoading(true)
    } else {
      setFormLoading(false)
    }
  }, [loading])

  useEffect(() => {
    if (selectedUser) {
      setUserTimezone(selectedUser.location.timezone)
      setLeaveTypes(leaveTypeLoop(selectedUser.location.leavePolicies))
    }
  }, [selectedUser])

  const handleResetForm = () => {
    form.resetFields()
  }

  const onChangeLeaveType = (leaveTypeId: string) => {
    resetLeaveRequestForm()
    const selectedLeaveType = leaveTypes.find(leaveType => leaveType?.id === leaveTypeId)
    setSelectedLeavePolicy(selectedUser?.location.leavePolicies.find(item => item.leaveType.id === leaveTypeId) as IGetUserForLeaveReueqstUserDataLocationLeavePolicies)
    onLeaveTypeSelected && onLeaveTypeSelected(selectedLeaveType)
  }

  const onChangeUser = (id: string) => {
    form.setFieldsValue({ leaveTypeId: undefined })
    setSelectedLeavePolicy(null)
    resetLeaveRequestForm()
    const selectedUser = listOfUsers.find(u => u.id === id) as IGetUserForLeaveReueqstUserData | IUserIdAndName
    if (selectedUser) {
      if ('location' in selectedUser) {
        setUserTimezone(selectedUser.location.timezone)
        setLeaveTypes(leaveTypeLoop(selectedUser.location.leavePolicies))
      }
      onSelectUserToAddToilRequest && onSelectUserToAddToilRequest(selectedUser.id)
    }
  }

  const resetLeaveRequestForm = () => {
    form.setFieldsValue({ startDate: '', range: [], rangeDate: [] })
    setHourlyLeave(false)
  }

  const leaveTypeLoop = (data: IGetUserForLeaveReueqstUserDataLocationLeavePolicies[]) => {
    const userLeaveTypesData = [] as IGetUserForLeaveReueqstUserDataLocationLeavePolicies[]
    data.forEach((lp: IGetUserForLeaveReueqstUserDataLocationLeavePolicies) => {
      if (lp.isActive && lp.leaveType.isActive && !lp.leaveType.deleted && lp.toil) {
        if (authUserRole === 'User' && lp.toilRequestsAllowedForUsers) {
          userLeaveTypesData.push({
            ...lp,
            id: lp.leaveType.id,
          })
        } else if (['Admin', 'Approver'].includes(authUserRole)) {
          userLeaveTypesData.push({
            ...lp,
            id: lp.leaveType.id,
          })
        }
      }
    })

    return userLeaveTypesData.sort((a, b) => a.leaveType.position < b.leaveType.position ? -1 : 1)
  }

  const disabledToilDate = current => {
    return current && current > dayjs().endOf('day')
  }

  const handleDisableTime = (
    _: dayjs.Dayjs,
    pickedRange: 'start' | 'end',
    info: { from?: dayjs.Dayjs | undefined }
  ) => {
    // No need to validate only start time or if start date is not selected
    if (pickedRange === 'start' || !form.getFieldValue('startDate')) {
      return {}
    }
    // Get start hour
    const startHour = info.from?.hour() || 0
    // Disable all hours before start hour + 1 hour (we do not allow less than 1 hour leaves)
    const disabledBefore = range(0, (startHour as number) + 1)
    const user = selectedUser ?? listOfUsers[0] as IGetUserForLeaveReueqstUserData

    const workingHours = getUserWorkingHoursPerDay(user.workHours)
    const disabledAfter = range((startHour as number) + Math.floor(workingHours + 1), 24)

    return {
      disabledHours: () => [...disabledBefore, ...disabledAfter],
    }
  }
  
  const onFilterUsers = (input, option) => {
    if (!option?.children) return false
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        const isPartDay = values.range ? true : false
        let partDayStartHour: number | undefined
        let partDayEndHour: number | undefined
        if (isPartDay) {
          partDayStartHour = values.range[0] ? values.range[0].hour() : 0
          partDayEndHour = values.range[1] ? values.range[1].hour() : 0
        }
        const saveObj: IToilFormData = {
          userId: authUserRole === 'User' ? authUserId : values.userId,
          isPartDay,
          leaveTypeId: values.leaveTypeId,
          startDate: values.range ? values.startDate.format('YYYY-MM-DD') : values.rangeDate[0].format('YYYY-MM-DD'),
          endDate: values.range ? values.startDate.format('YYYY-MM-DD') : values.rangeDate[1].format('YYYY-MM-DD'),
          partDayStartHour,
          partDayEndHour,
          reason: values.reason,
          addToil: isAddingToil || false,
        }
        onSave(saveObj, Boolean(toilRequest))
      })
      .catch(info => {
        logger.debug('Validate Failed:', info)
      })
  }

  return (
    <Form
      form={form}
      scrollToFirstError
      onFinish={onFinish}
      labelWrap
      initialValues={defaultValue}
      {...formLayout}
    >

      {(authUserRole !== 'User' && !isEditToilRequest) &&
        <Form.Item name="userId"
          label={<IntlMessages id="components.leaveForm.selectUser" />}
          rules={[{ required: true, message: <IntlMessages id="components.leaveForm.selectUser" /> }]}
        >
          <Select filterOption={onFilterUsers} disabled={Boolean(toilRequest)} style={{ width: 320 }} showSearch onSelect={onChangeUser}>
            {listOfUsers.map(item => (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
      }

      <Form.Item 
        name="leaveTypeId"
        label={<IntlMessages id="components.leavesColumns.leaveType" />}
        rules={[{ required: true, message: <IntlMessages id="components.leaveForm.pleaseSelectLeaveType" /> }]}
      >
        <Select 
          notFoundContent={<IntlMessages id="components.toil.locationNotHaveToil" values={{ me: authUserId === selectedUser?.id ? 1 : 2 }} />}
          style={{ width: 320 }}
          disabled={!selectedUser} 
          onChange={onChangeLeaveType}
        >
          {leaveTypes.map(item => (<Option key={item.id} value={item.id}>{item.leaveType.name}</Option>))}
        </Select>
      </Form.Item>

      {(selectedLeavePolicy && selectedLeavePolicy.toilShortestInterval !== ShortestLeaveIntervalEnum.fullDay &&
        (
          selectedLeavePolicy.toilShortestInterval !== ShortestLeaveIntervalEnum.halfDay || 
          userHasDefaultWorkingHours(selectedUser?.workHours)
        )
      ) && (
        <Form.Item 
          name="hourlyLeave" 
          label={
            selectedLeavePolicy.toilShortestInterval === ShortestLeaveIntervalEnum.oneHour ? 
              <IntlMessages id="components.toil.hourly" /> : 
              <IntlMessages id="components.toil.halfDay" />
          }
        >
          <Checkbox checked={hourlyLeave} onChange={() => setHourlyLeave(!hourlyLeave)} />
        </Form.Item>
      )}

      {!hourlyLeave &&
        <Form.Item 
          name="rangeDate"
          label={<IntlMessages id="app.dateRange" />}
          rules={[{ required: true, message: <IntlMessages id="components.leaveForm.pleaseSelectstartDate" /> }]}>
          <RangePicker disabled={!selectedUser || leaveTypes.length === 0} disabledDate={disabledToilDate}/>
        </Form.Item>
      }

      {hourlyLeave &&
        <>
          <Form.Item 
            name="startDate"
            label={<IntlMessages id="components.leaveForm.date" />}
            rules={[{ required: true, message: <IntlMessages id="components.leaveForm.pleaseSelectstartDate" /> }]}
          >
            <DatePicker disabledDate={disabledToilDate} style={{ width: 320 }} />
          </Form.Item>
          <Form.Item name="range"
            label={<IntlMessages id="app.time" />}
            rules={[{ required: true }]}
            extra={<TimezoneInfo userTimezone={userTimezone} />}
          >
            <TimePicker.RangePicker
              format={hourFormat === HourFormatEnum.twentyFour ? 'HH' : 'hh'}
              needConfirm={false}
              allowClear={false}
              use12Hours={hourFormat === HourFormatEnum.twelve}
              disabledTime={handleDisableTime}
            />

          </Form.Item>
        </>
      }

      <Form.Item name="reason"
        label={<IntlMessages id="app.reason" />}
        rules={[{ required: true, message: <IntlMessages id="components.leaveForm.pleaseInputReason" /> }]}
      >
        <TextArea
          disabled={leaveTypes.length === 0}
          rows={4}
        />
      </Form.Item>

      <Form.Item {...offsetItemLayout}>
        {onCancel && 
          <Button type="default" danger style={{ marginRight: 10 }} onClick={onCancel}>
            <IntlMessages id="app.cancel" />
          </Button>
        }
        <Button className='vt-btn-reset'
          type="default"
          style={{ marginRight: 40, marginBottom: 10 }}
          onClick={handleResetForm}>
          <IntlMessages id="app.reset" />
        </Button> 
        {!toilRequest && authUserId === selectedUser?.id && selectedLeavePolicy?.toilRequestsAllowedForUsers &&
          <Button
            htmlType="submit"
            onClick={() => setIsAddingToil(false)}
            type="primary"
            loading={formLoading}
            disabled={formLoading || authUserId !== selectedUser?.id}
            data-qa='submit-toil-request'
            style={{ marginRight: 10 }}
          >
            <IntlMessages id="components.toil.requestToil"/>
          </Button>
        }
        {toilRequest && (authUserId === selectedUser?.id || authUserRole === 'Admin') && 
          <Button
            htmlType="submit"
            onClick={() => setIsAddingToil(false)}
            type="primary"
            loading={formLoading}
            disabled={formLoading && authUserRole === 'Admin'}
            data-qa='edit-toil-request'
            style={{ marginRight: 10 }}
          >
            <IntlMessages id="components.toil.edit"/>
          </Button>
        }
        {authUserRole !== 'User' && !toilRequest && !(authUserId === selectedUser?.id && !allowAddToilForAuthUser) &&
          <Button
            htmlType="submit"
            onClick={() => setIsAddingToil(true)}
            type="primary"
            disabled={formLoading}
            loading={formLoading}
            data-qa='add-toil-request'
          >
            <IntlMessages id="components.toil.addToil"/>
          </Button>
        }
      </Form.Item>
    </Form >
  )
}

export default ToilForm
