import { LeaveTypeCalendarStatusesEnum } from '../types/calendar'

export function getCalendarStatusSelectOptions (formatMessage) {
  return [{
    label: formatMessage({ id: 'components.createLeaveTypeForm.calendarStatusBusy' }),
    value: LeaveTypeCalendarStatusesEnum.busy,
  }, {
    label: formatMessage({ id: 'components.createLeaveTypeForm.calendarStatusFree' }),
    value: LeaveTypeCalendarStatusesEnum.free,
  }]
}