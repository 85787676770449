import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { App, Modal, Form, Select, FormInstance } from 'antd'
import { useIntl } from 'react-intl'
import { useManualQuery } from 'graphql-hooks'
import { getSlackBotDetails } from '../../graphql/custom-queries'
import * as logger from '../../services/logger'

import Api from '@vacationtracker/shared/services/api'
import { SlackAuth } from '../../services/auth/slack/slack'
import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'

import IntlMessages from '../../util/IntlMessages'
import { filterOptions } from '../../util/filter-options'

import { ISlackBotDetails } from '../../types/slack'

interface IChannel {
  id: string
  name?: string
}

interface ISelectChannelFromSlack {
  name: string[]
  form: FormInstance
  onSelectDestination: (e) => void
  setChannelName: (e) => void
}

const { Option } = Select

if (!process.env.REACT_APP_SLACK_CLIENT_ID) {
  throw new Error('Client ID are required')
}
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)

export const SelectChannelFromSlack = ({ name, onSelectDestination, setChannelName, form }: ISelectChannelFromSlack): React.ReactElement => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { notification } = App.useApp()

  const { authUser } = useAppSelector(selectAuthUserSlice)
  const [channels, setChannels] = useState<IChannel[]>([])
  const [showSlackLoginRequiredModal, setShowSlackLoginRequiredModal] = useState<boolean>(false)
  const [slackBotId, setSlackBotId] = useState<string>()
  const [getSlackBotDetailsQuery] = useManualQuery<ISlackBotDetails, { id: string }>(getSlackBotDetails)

  useEffect(() => {
    fetchSlackData()
  }, [])

  const fetchSlackData = async (id = authUser.id) => {
    try {
      const response = await getSlackBotDetailsQuery({ variables: { id }})
      if (!response.data || response.error) throw response.error
      if (!response.data.slackId) {
        throw new Error('Slack ID not found')
      }
      setSlackBotId(response.data.slackId.slackBotId)
      await fetchSlackChannels()
    } catch (error) {
      if (id === authUser.id) {
        // TODO: @slobodan remove this after migration
        // Fetch user without prefix
        return fetchSlackData(authUser.id.replace('slack-', ''))
      }
      logger.error('error', error)
    }
  }

  const fetchSlackChannels = async () => {
    try {
      slackAuth.setTokensFromStorage()
      await slackAuth.authTest()
      const response = await slackAuth.conversationsList()

      const channelsData = response.map(channel => {
        return {id: channel.id, name: `${channel.name}${channel.is_private ? ` ${formatMessage({ id: 'components.selectChannelFormSlack.privateChannel' })}` : ''}` }
      }) as IChannel[]
      setChannels(channelsData)

      const formData = form.getFieldsValue()
      if (name[0] && formData[name[0]]) {
        const channelId = formData[name[0]].addresses
        if (channelId) {
          const channel = channelsData.find(v => channelId === v.id)
          setChannelName(channel?.name)
        }
      }
    } catch (error) {
      logger.error('error', error)
      if (error?.error === 'not_authed' || error?.error === 'invalid_auth' || error?.error === 'missing_scope' || error?.error === 'token_revoked') {
        setShowSlackLoginRequiredModal(true)
      }
    }
  }

  const getSlackTokens = () => {
    slackAuth.signin(
      ['chat:write', 'commands', 'team:read', 'users:read', 'users:read.email'],
      ['channels:write', 'groups:write', 'users:read', 'team:read', 'channels:read', 'groups:read', 'users.profile:write']
    ).then(async (response) => {
      slackAuth.setUserToken(response.authed_user.access_token)
      try {
        await Api.post('/slack/update-tokens', {
          token: response.authed_user.access_token,
          botToken: response.access_token,
        })
      } catch (error) {
        logger.warning('UPDATE TOKEN ERROR', error.response)
      }
      setShowSlackLoginRequiredModal(false)
      await fetchSlackChannels()
    }).catch(error => {
      logger.warning('SLACK SIGNIN ERROR', error)
      handleErrorNotification(
        {message: formatMessage({ id: 'components.selectChannelFormSlack.toProccedPleaseLogin' })},
        undefined,
        formatMessage({ id: 'components.selectChannelFormSlack.missingSlackScopes' })
      )
    })
  }

  const handleErrorNotification = (error, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'error.notificationGeneral' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'error.notificationGeneral' }),
      description,
      duration: 0,
    })
  }

  const onSelect = async (channelId) => {
    onSelectDestination(channelId)
    const channelName = channels.find(channel => channel.id === channelId)?.name
    setChannelName(channelName)

    try {
      if (!slackBotId) {
        handleErrorNotification({message: formatMessage({ id: 'components.selectChannelFormSlack.slackBotIdIsRequred' }) })
        return
      }
      slackAuth.setTokensFromStorage()
      await slackAuth.authTest()
      await slackAuth.conversationsInvite(channelId, slackBotId)
    } catch (error) {
      if (error.error === 'not_in_channel') {
        handleErrorNotification({message: formatMessage(
          { id: 'components.selectChannelFormSlack.youCanAddTheBotOnlyOnTheChannels' },
          { channelName }),
        })
        return
      }
      if (error.error === 'invitee_is_not_a_member_of_this_workspace') {
        setShowSlackLoginRequiredModal(true)
        return
      }
      if (error.error !== 'already_in_channel') {
        handleErrorNotification({message: `${formatMessage({ id: 'components.selectChannelFormSlack.slackError' })}: ${error.error}${error.needed ? ` ${error.needed}` : ''}`})
        return
      }
    }
  }

  return (
    <>
      <Form.Item
        name={name}
        noStyle
        rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
      >
        <Select style={{ width: 250, marginLeft: 5 }}
          showSearch
          filterOption={filterOptions}
          disabled={!channels.length}
          loading={!channels.length}
          onSelect={onSelect}
          className="select-channel-from-slack"
        >
          {channels.map(channel => <Option key={channel.id} value={channel.id}>{channel.name}</Option>)}
        </Select>
      </Form.Item>

      {showSlackLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'error.slack.usersLoginRequiredTitle' })}
          open={showSlackLoginRequiredModal}
          onOk={getSlackTokens}
          onCancel={() => {
            setShowSlackLoginRequiredModal(false)
            history.goBack()
          }}
        >
          <p><IntlMessages id="slack.notificationsLoginRequiredDescription1" /></p>
          <p><IntlMessages id="slack.notificationsLoginRequiredDescription2" /></p>
        </Modal>
      }
    </>
  )
}
