export const testimonials = [
  {
    id: 1,
    creator: 'Aleksandar L.',
    text: 'Makes vacation management a breeze, both for the management and employees.',
  },
  {
    id: 2,
    creator: 'Austen C.',
    text: 'Simple, focused, no-nonsense.',
  },
  {
    id: 3,
    creator: 'Timea V.',
    text: 'Really easy to use, my team and I love it, made our life so much easier.',
  },
  {
    id: 4,
    creator: 'Luciano M.',
    text: 'Great and easy to use. Makes leave management painless for our team.',
  },
  {
    id: 5,
    creator: 'Teodora G.',
    text: 'Time saver, frees me from the mundane tasks, great price compared to the super expensive HR softwares.',
  },
  {
    id: 6,
    creator: 'Volker W.',
    text: 'HR is delighted to see the workload reduced and managers can approve a leave request with one click.',
  },
  {
    id: 7,
    creator: 'Niva S.',
    text: 'Great software for allowing transparency in the workplace.',
  },
  {
    id: 8,
    creator: 'Kate B.',
    text: 'Saves a ton of time, super easy to use, and it was really easy to get used to.',
  },
  {
    id: 9,
    creator: 'John A.',
    text: 'They have focused on ONE overhead-intensive task - vacation / PTO / sick / bereavement tracking. And the app is excellent.',
  },
  {
    id: 10,
    creator: 'Joao M.',
    text: 'Vacation Tracker is one amazing product to easily manage leave for any company. It\'s highly configurable.',
  },
  {
    id: 11,
    creator: 'Jennifer S.',
    text: 'Best implementation of this year for our company!',
  },
  {
    id: 12,
    creator: 'Emiliano V.',
    text: 'Love how easy it is!',
  },
]


export const newTestimonials = [
  {
    id: 1,
    creator: 'Romana R.',
    company: 'No Code',
  },
  {
    id: 2,
    creator: 'Manuel B.',
    company: 'Timeular',
  },
  {
    id: 3,
    creator: 'Michelle B.',
    company: 'EMJ Corporation',
  },
]
