import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'

export enum BadgeFeaturesEnum {
  profileMenuNotificationSeen = 'profile-menu-notification-seen'
}

interface IBadgeFeatures {
  badgeFeatures: {
    [BadgeFeaturesEnum.profileMenuNotificationSeen]: boolean
  }
}

interface IBadgeFeaturesActionPayload {
  feature: BadgeFeaturesEnum
  value: boolean
}

const badgeFeaturesFromStorage = localStorage.getItem('badgeFeatures')
let badgeFeatures
if (badgeFeaturesFromStorage) {
  badgeFeatures = JSON.parse(badgeFeaturesFromStorage)
} else {
  const initial = {
    [BadgeFeaturesEnum.profileMenuNotificationSeen]: false,
  }
  badgeFeatures = initial
  localStorage.setItem('badgeFeatures', JSON.stringify(initial))
}

const initialState: IBadgeFeatures = {
  badgeFeatures,
}

export const badgeFeaturesSlice = createSlice({
  name: 'badgeFeatures',
  initialState,
  reducers: {
    setBadgeFeature: (state, action: PayloadAction<IBadgeFeaturesActionPayload>) => {
      const {feature, value} = action.payload
      if (action.payload) {
        state.badgeFeatures[feature] = value
      }
    },
    setAllBadgeFeatures: (state, action: PayloadAction<{
      [BadgeFeaturesEnum.profileMenuNotificationSeen]: boolean
    }>) => {
      if (action.payload) {
        state.badgeFeatures = action.payload
      }
    },
  },
})

export const { setBadgeFeature, setAllBadgeFeatures } = badgeFeaturesSlice.actions

export const selectFeatureBadgeSlice = (state: RootState) => state.badgeFeaturesSlice

export default badgeFeaturesSlice.reducer
