import React from 'react'
import { useSelector } from 'react-redux'
import { App, Alert, Button } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'

import { isTrial } from '../../store/auth-company-slice'
import IntlMessages from '../../util/IntlMessages'
import { AddonsStatus, AddonsStatusEnum } from '@vacationtracker/shared/types/addons'
import { ProductService } from '@vacationtracker/shared/data/stripe/product-service'
import { ProductName } from '@vacationtracker/shared/data/stripe/prices'

interface IHandleSubscribeAutomations {
  formatMessage: Function
  automationType: string
  userCount: number
  status?: AddonsStatus
  handleSubscribe: (automationType: string, status: boolean) => void
  includedInCurrentPlan?: boolean
}

const HandleSubscribeAutomations = ({
  formatMessage,
  automationType,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  userCount,
  status,
  handleSubscribe,
  includedInCurrentPlan = false,
}: IHandleSubscribeAutomations) => {

  const isTrialSubscription = useSelector(isTrial)
  const { modal } = App.useApp()

  const env = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'prod' : 'test'
  const productService = new ProductService(env)
  const addonsPrice = productService.getPriceUnitByProductName(ProductName.ADDONS)
  
  const unsubscribeModal = () => {
    modal.confirm({
      title: formatMessage({ id: 'automations.unsubscripteAddons' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'automations.removeAddonsDescirptionForAdmin' }),
      okText: formatMessage({ id: 'automations.removeAddons' }),
      okType: 'danger',
      width: 500,
      maskClosable: true,
      onOk() {
        handleSubscribe(automationType, false)
      },
    })
  }

  const reSubscribeModal = () => {
    modal.confirm({
      title: formatMessage({ id: 'automations.reSubscribeAddons' }),
      icon: <ExclamationCircleOutlined />,
      content: <>
        {isTrialSubscription && formatMessage({ id: 'automations.reSubscribeAddonsDescirptionForAdminInTrial' }, { addonsPrice })}
        {!isTrialSubscription && !includedInCurrentPlan && formatMessage({ id: 'automations.reSubscribeAddonsDescirptionForAdmin' }, { addonsPrice })}
        {includedInCurrentPlan && formatMessage({ id: 'automations.addFreeAddonsDescirptionForAdmin' })}
      </>,
      okText: formatMessage({ id: 'app.confirm' }),
      okType: 'primary',
      width: 500,
      maskClosable: true,
      onOk() {
        handleSubscribe(automationType, true)
      },
    })
  }

  return (
    <>
      {(status === AddonsStatusEnum.CANCELED || status === AddonsStatusEnum.CANCEL_AT_PERIOD_END) &&
        <Alert
          message={<IntlMessages id="automations.reSubscribeInfo" />}
          style={{ marginBottom: '30px' }}
          action={<Button size="small" onClick={reSubscribeModal} ><IntlMessages id="automations.reSubscribeAddons" /></Button>}
        /> 
      }
      {status === AddonsStatusEnum.ACTIVE && !includedInCurrentPlan &&
        <Alert
          message={<IntlMessages id="automations.unsubscribeInfo" values={{ addonsPrice }} />}
          style={{ marginBottom: '30px' }}
          action={<Button size="small" onClick={unsubscribeModal} ><IntlMessages id="automations.unsubscripteAddons" /></Button>}
        />
      }
      {status === AddonsStatusEnum.ACTIVE && includedInCurrentPlan &&
        <Alert
          message={<IntlMessages id="automations.unsubscribeForFreeAddonsInfo" values={{ addonsPrice }} />}
          style={{ marginBottom: '30px' }}
          action={<Button size="small" onClick={unsubscribeModal} ><IntlMessages id="automations.unsubscripteAddons" /></Button>}
        />
      }
    </>
  )
}

export default HandleSubscribeAutomations
