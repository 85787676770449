import React, { ReactElement, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { App, Input, Button, Spin, Dropdown, Space } from 'antd'
import type { MenuProps } from 'antd'
import { DownOutlined, ArrowLeftOutlined } from '@ant-design/icons'
import { useManualQuery } from 'graphql-hooks'
import { takeRight } from 'lodash'
import { useIntl } from 'react-intl'

import Api from '@vacationtracker/shared/services/api'
import * as logger from '../../services/logger'
import { getAiAssistedOnboardingData, getAiAssistantConversationInfoAndMessagesData } from '../../graphql/custom-queries'
import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import countries from '@vacationtracker/shared/data/countries'
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react'

import CircularProgress from '../../components/circular-progress'
import IntlMessages from '../../util/IntlMessages'
import AIConversationMessage from '../../components/ai-assistant/conversation-message'
import OnboardingDoneNotifications from './components'
import { track } from '../../services/analytics/analytics'

import { ConversationRole, IBaseConversation, IConversation, IConversationRequestBody } from '@vacationtracker/shared/types/conversation'
import { FrontendUrls } from '../../types/urls'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { ITeam } from '@vacationtracker/shared/types/team'
import { ILeaveType } from '@vacationtracker/shared/types/leave-type'
import { ILocation } from '@vacationtracker/shared/types/location'
import { openSupportChat } from '../../util/open-support-chat'
import { wait } from '@vacationtracker/shared/functions/wait'


interface IAiAssistedConversationInfoAndMessages {
  getAiAssistedConversationInfoAndMessages: IConversation[]
}

interface IAiAssistedOnboardingData {
  getTeamList: ITeam[]
  getLocationList: ILocation[]
  getLeaveTypeList: ILeaveType[]
}

const AiAssistedOnboarding = ({ history }): ReactElement=> {
  const { notification } = App.useApp()
  const chatConversationRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null)
  const messagesEndRef = useRef<null | HTMLDivElement>(null)
  const scrollableRef = useRef<null | Element>(null)
  const lastScrollHeight = useRef(0)
  const isUserScrolling = useRef(false)
  const { formatMessage } = useIntl()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const aiAssistedOnboardingEnabled = useFeatureFlagEnabled(FeatureFlagEnum.aiAssistedOnboarding)
  const posthog = usePostHog()
  const fetchDataTimeoutSignalRef = useRef<AbortController>(new AbortController())

  const [messages, setMessages] = useState<IConversation[] | IBaseConversation[] | null>(null)
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [waitingForAiResponse, setWaitingForAiResponse] = useState(false)
  const [waitingForTypingToFinish, setWaitingForTypingToFinish] = useState(false)
  const [isOnboardingDone, setIsOnboardingDone] = useState(false)
  const [topic, setTopic] = useState('Onboarding')
  const [userScrolled, setUserScrolled] = useState(false)
  const [isOnboardingNotificationVisible, setIsOnboardingNotificationVisible] = useState(false)
  
  const [ getAiAssistantConversationInfoAndMessagesDataQuery ] = useManualQuery<
  IAiAssistedConversationInfoAndMessages,
  {
    companyId: string
    userId: string
    conversationId: string
  }
  >(getAiAssistantConversationInfoAndMessagesData)
  const [ getAiAssistedOnboardingDataQuery ] = useManualQuery<IAiAssistedOnboardingData>(getAiAssistedOnboardingData)

  useEffect(() => {
    fetchDataTimeoutSignalRef.current = new AbortController()
    // Start screen recording in production
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      posthog.startSessionRecording()
    }
    return () => {
      if (fetchDataTimeoutSignalRef.current) {
        fetchDataTimeoutSignalRef.current.abort()
        if (messages === null) {
          // Init operation was aborted, clean the localStorage, so we can try again
          localStorage.removeItem(`${authUser.id}-ai-assisted-onboarding-initialized`)
        }
      }
    }
  }, [])

  useEffect(() => {
    const scrollableElement = document.querySelector('.ant-layout-content')
    if (scrollableElement) {
      scrollableRef.current = scrollableElement
      scrollToBottom()
      const handleScroll = () => {
        if (isUserScrolling.current) {
          setUserScrolled(true)
        }
      }

      const handleWheel = () => {
        isUserScrolling.current = true
        setUserScrolled(true)
      }

      const handleTouchStart = () => {
        isUserScrolling.current = true
        setUserScrolled(true)
      }

      scrollableElement.addEventListener('scroll', handleScroll)
      scrollableElement.addEventListener('wheel', handleWheel)
      scrollableElement.addEventListener('touchlist', handleTouchStart)

      const observer = new MutationObserver(() => {
        if (scrollableElement.scrollHeight !== lastScrollHeight.current) {
          if (!userScrolled) {
            scrollToBottom()
          }
          lastScrollHeight.current = scrollableElement.scrollHeight
        }
      })

      observer.observe(scrollableElement, { childList: true, subtree: true })

      return () => {
        scrollableElement.removeEventListener('scroll', handleScroll)
        scrollableElement.removeEventListener('wheel', handleWheel)
        scrollableElement.removeEventListener('touchlist', handleTouchStart)
        observer.disconnect()
      }
    }
  }, [userScrolled])

  useEffect(() => {
    if (typeof aiAssistedOnboardingEnabled === 'undefined') {
      return
    }
    if (authUser?.initialUser && aiAssistedOnboardingEnabled) {
      track('AI_ONBOARDING_PAGE_VIEW', {
        initialUser: authUser?.initialUser,
        aiAssistedOnboardingEnabled,
      })
      fetchData(fetchDataTimeoutSignalRef.current)
    } else {
      track('AI_ONBOARDING_PAGE_VIEW_REDIRECT', {
        initialUser: authUser?.initialUser,
        aiAssistedOnboardingEnabled,
        redirectTo: `${FrontendUrls.dashboard}?tour=true`,
      })
      history.push(`${FrontendUrls.dashboard}?tour=true`)
    }
  }, [authUser?.initialUser, aiAssistedOnboardingEnabled])

  useEffect(() => {
    scrollToBottom()
  }, [messages, isLoading])

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const fetchData = async (fetchDataTimeoutAbortController: AbortController) => {
    setIsLoading(true)
    try {
      if (!authCompany?.id) {
        // Auth company ID is not yet available, return
        return
      }
      const allMessagesAndInfo = await getAiAssistantConversationInfoAndMessagesDataQuery({
        variables: {
          companyId: authCompany?.id,
          userId: authUser?.id,
          conversationId: 'onboarding',
        },
        fetchOptionsOverrides: {
          signal: fetchDataTimeoutSignalRef.current.signal,
        },
      })
      if (!allMessagesAndInfo.data || allMessagesAndInfo.error) throw allMessagesAndInfo.error
      const allMessages = allMessagesAndInfo.data?.getAiAssistedConversationInfoAndMessages
      track('AI_ONBOARDING_EXISTING_CONVERSATION_LOADED', {
        aiAssistedOnboardingEnabled,
        messagesCount: allMessages.length,
        startNewOnboardingConversation: allMessages.length === 0,
        conversation: JSON.stringify(allMessages),
      })
      setTopic(allMessagesAndInfo.data?.getAiAssistedConversationInfoAndMessages.find(message => message.topic)?.topic as string)
      let initUserMessage

      const conversationInitialized = Boolean(localStorage.getItem(`${authUser.id}-ai-assisted-onboarding-initialized`))
      if (allMessages.length === 0 && !conversationInitialized) {
        // Conversation already sent, we're saving it to localStorage to prevent double sending
        localStorage.setItem(`${authUser.id}-ai-assisted-onboarding-initialized`, 'true')

        const dataRes = await getAiAssistedOnboardingDataQuery({
          fetchOptionsOverrides: {
            signal: fetchDataTimeoutSignalRef.current.signal,
          },
        })
        if (!dataRes.data || dataRes.error) throw dataRes.error

        const leaveTypes = dataRes.data.getLeaveTypeList
          .filter(leaveType => !leaveType.deleted && leaveType.isActive)
          .map(leaveType => ({
            id: leaveType.id,
            name: leaveType.name,
            deleted: leaveType.deleted,
            isActive: leaveType.isActive,
            isDefault: leaveType.isDefault,
            position: leaveType.position,
            color: leaveType.color,
          })) as ILeaveType[]
        const locations = dataRes.data.getLocationList
          .map(location => ({
            id: location.id,
            name: location.name,
            iso: countries.find(country => country.name === location.name)?.iso,
            resetQuotas: location.resetQuotas,
            rolloverNeverExpireDays: location.rolloverNeverExpireDays,
            timestamp: location.timestamp,
            timezone: location.timezone,
            yearStartDay: location.yearStartDay,
            yearStartMonth: location.yearStartMonth,
            firstDayOfWeek: location.firstDayOfWeek,
          })) as {iso: string} extends ILocation ? ILocation : never[]
        const departments = dataRes.data.getTeamList
          .map(department => ({
            id: department.id,
            name: department.name,
          })) as ITeam[]
        const response = await Api.post<IConversationRequestBody, IConversation>('/core/ai-assisted-onboarding', {
          conversationId: 'onboarding',
          isInitial: true,
          userLang: authUser?.locale,
          message: JSON.stringify({
            leaveTypes,
            locations,
            departments,
          }),
        })
        logger.warning('AI onboarding messages', response)
        initUserMessage = {
          content: formatMessage({id: 'ai.assisted.onboarding.initialUserMessage'}),
          role: ConversationRole.assistant,
          timestamp: new Date().toISOString(),
        }
        setTopic('Onboarding')
        setMessages([
          initUserMessage,
          response,
        ])
        setIsLoading(false)
        return
      } else if (allMessages.length === 0) {
        await wait(500, fetchDataTimeoutAbortController)
        fetchData(fetchDataTimeoutAbortController)
        return
      }
      initUserMessage = {
        ...allMessages[1] as IConversation,
        content: formatMessage({id: 'ai.assisted.onboarding.initialUserMessage'}),
        role: ConversationRole.assistant,
      }
      const isDone = allMessages.find(conversation => conversation.isDone)?.isDone
      setIsOnboardingDone(isDone as boolean)
      if (isDone) {
        setIsOnboardingNotificationVisible(true)
      }
      setMessages([
        initUserMessage,
        ...takeRight(allMessages, allMessages.length - 2),
      ])
      setIsLoading(false)
      notification.destroy()
    } catch (error) {
      if (error.fetchError?.name === 'AbortError') {
        return
      }
      logger.error('AI Onboarding fetchData failed', error)
    } finally {
      setIsLoading(false)
    }
  }

  const sendMessage = async () => {
    isUserScrolling.current = false
    setUserScrolled(false)
    if (!input || input.trim() === '' || input.length < 1) {
      return
    }
    setInput('')
    setWaitingForAiResponse(true)
    setWaitingForTypingToFinish(true)
    const timeOfTheNewMessage = new Date().toISOString()
    const newMessage = {
      content: input,
      role: ConversationRole.user,
      timestamp: timeOfTheNewMessage,
      conversationId: 'onboarding',
    }
    const withAddedNewMessage = [...messages as IBaseConversation[], newMessage]
    setMessages(withAddedNewMessage)

    try {
      track('AI_ONBOARDING_USER_MESSAGE_SENT', newMessage)
      const response = await Api.post('/core/ai-assisted-onboarding', {
        isInitial: false,
        userLang: authUser?.locale,
        message: newMessage.content,
        conversationId: 'onboarding',
      })
      logger.warning('AI onboarding AI response', response)
      track('AI_ONBOARDING_ASSISTANT_REPLY', response)
      setIsOnboardingDone(response.isDone)
      if (response.isDone) {
        setIsOnboardingNotificationVisible(true)
      }
      setMessages([...withAddedNewMessage, { content: response.content, role: response.role, timestamp: response.timestamp, typing: true }])
      setWaitingForAiResponse(false)
      setInput('')
    } catch (error) {
      track('AI_ONBOARDING_MESSAGE_OR_REPLY_ERROR', error)
      logger.error(error instanceof Error ? error : JSON.stringify(error))
      const errorDescription = error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
      notification.error({
        message: formatMessage({ id: 'ai.assisted.onboarding.sendMessageError' }),
        description: errorDescription,
        duration: 0,
        key: 'ai-assisted-onboarding',
        btn: (
          <Button onClick={() => history.push(`${FrontendUrls.aiAssistant}/onboarding`)}>{ formatMessage({ id: 'app.reload' }) }</Button>
        ),
      })
      setWaitingForAiResponse(false)
      setWaitingForTypingToFinish(false)
      setInput('')
    }
  }

  const handleUserInput = (e) => {
    setInput(e.target.value)
  }

  const handleIsOnboardingNotificationVisible = () => {
    setIsOnboardingNotificationVisible(!isOnboardingNotificationVisible)
  }

  const conversationTitleMenuItems: MenuProps['items'] = [
    {
      key: 'rename',
      label: formatMessage({ id: 'app.rename' }),
      disabled: true,
    },
    {
      key: 'delete',
      danger: true,
      label: formatMessage({ id: 'app.delete' }),
      disabled: true,
    },
  ]

  return (
    <div className="main-content">
      <div className="ai-assistant-chat">
        <div className="ai-assistant-chat-header">
          {!isLoading && <>
            <Button
              className='button'
              htmlType="button"
              style={{ marginLeft: 12 }}>
              <Link to={FrontendUrls.aiAssistant}>
                <ArrowLeftOutlined /> <IntlMessages id="app.back" />
              </Link>
            </Button>
            <div className="center-content">
              <Dropdown menu={{ items: conversationTitleMenuItems }} trigger={['click']}>
                <Space>
                  {topic}
                  <DownOutlined />
                </Space>
              </Dropdown>
            </div>
          </>}
        </div>
        <div className="ai-assistant-chat-conversation" ref={chatConversationRef}>

          <OnboardingDoneNotifications
            visible={isOnboardingNotificationVisible}
            handleIsOnboardingNotificationVisible={handleIsOnboardingNotificationVisible}
          />

          {isLoading && <CircularProgress />}
          {!isLoading && aiAssistedOnboardingEnabled && <div>
            {messages?.map(item => item && <AIConversationMessage
              item={item}
              authUser={authUser}
              key={item.timestamp}
              scrollToBottom={scrollToBottom}
              userScrolled={userScrolled}
              setWaitingForTypingToFinish={setWaitingForTypingToFinish}
            />)}
            {isOnboardingDone && <div style={{ textAlign: 'center', marginTop: '10px' }}>
              <Button
                type='primary'
                onClick={() => setIsOnboardingNotificationVisible(true)}
                disabled={isLoading}
                size="large"
              >
                <IntlMessages id="ai.assisted.onboarding.openSetupNotification" />
              </Button>
            </div>}
            <div className='ai-assistant-chat-conversation-message-wrapper' ref={messagesEndRef} />
          </div>}
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
            <Spin spinning={waitingForAiResponse} />
          </div>
        </div>
        {!isOnboardingDone && <>
          <div className="ai-assistant-chat-footer">
            <Input.Group className="ai-assistant-conversation-list-input" compact>
              <Input.TextArea
                maxLength={1000}
                value={input}
                onChange={handleUserInput}
                autoSize={{ minRows: 1, maxRows: 15 }}
                autoFocus
                onKeyDown={e => e.key === 'Enter' && !e.shiftKey && sendMessage()}
                disabled={isLoading || waitingForAiResponse || isOnboardingDone || waitingForTypingToFinish}
                size='large'
                className="ai-assistant-conversation-list-input-field"
              />
              <Button
                type='primary'
                onClick={sendMessage}
                size="large"
                className="ai-assistant-conversation-list-input-button"
                disabled={isLoading || !input || input?.trim() === '' || input?.length < 1 || waitingForAiResponse}
              >
                <IntlMessages id="app.send" />
              </Button>
            </Input.Group>
          </div>
          <div className="ai-assistant-input-disclaimer">
            <IntlMessages id="ai.assistant.disclaimer" values={{
              support: (...chunks) => <a onClick={() => openSupportChat('AI_ASSISTANT_ONBOARDING')}>{chunks}</a>,
            }} />
          </div>
        </>}
      </div>
    </div>
  )
}

export default AiAssistedOnboarding