import { createClient } from 'graphql-ws'
import { getAuthTokens } from '../../services/auth'
import logger from '../../functions/logger'

interface IAppSyncAuthorizationInfo {
  Authorization: string
  host: string
}

const asBase64EncodedJson = value => Buffer.from(JSON.stringify(value), 'utf8').toString('base64')

function appSyncWSUrl(graphQlUrl: string, appSyncAuthorizationInfo: IAppSyncAuthorizationInfo) {
  const url = new URL(graphQlUrl)

  return `${url}?header=${asBase64EncodedJson(appSyncAuthorizationInfo)}&payload=${asBase64EncodedJson({})}`
}

let currentToken: string | null = null

export function getAppSyncClient(url: string, appSyncAuthorizationInfo: IAppSyncAuthorizationInfo) {
  return createClient({
    url: async () => {
      // Instead of a static token, we fetch the token each time
      const { idToken } = await getAuthTokens(true)
      // We also cache it, because some sync calls use it (see the "send" method below)
      currentToken = idToken
      const params = {
        ...appSyncAuthorizationInfo,
        Authorization: idToken,
      }
      return appSyncWSUrl(url, params)
    },

    // We keep retry attempts to infinity, because we want to keep the connection alive
    retryAttempts: Infinity,

    // Lazy mode connects on the first subscription and disconnects on the last unsubscribe
    // We want to disable it
    lazy: false,

    // We want to keep the connection alive, and keep retrying
    shouldRetry: () => true,

    // For debug purposes only
    on: {
      error: (error) => {
        logger.debug('GraphQL subscription error', error)
      },
      connected: () => {
        logger.debug('GraphQL subscription connected')
      },
      connecting: (ev) => {
        logger.debug('GraphQL subscription connecting', ev)
      },
      closed: (ev) => {
        logger.debug('GraphQL subscription closed', ev)
      },
    },

    connectionParams: async () => {
      // Update params with the latest token
      const { idToken } = await getAuthTokens()
      if (!idToken) {
        return {}
      }

      const params = {
        ...appSyncAuthorizationInfo,
        Authorization: idToken,
      }

      return params
    },

    // AppSync requires `graphql-ws` protocol
    webSocketImpl: class extends WebSocket {
      constructor(url: URL) {
        super(url, 'graphql-ws')
      }

      send(originalData) {
        const data = this._tryParseJsonString(originalData)

        // AppSync's subscription event requires extensions
        // and a slightly different message format
        if (data?.payload?.query) {
          return super.send(JSON.stringify({
            ...data,
            payload: {
              data: JSON.stringify({
                query: data.payload.query,
                variables: data.payload.variables,
              }),
              extensions: {
                authorization: {
                  ...appSyncAuthorizationInfo,
                  // We use the cached token here, this is used when we create a new subscription (see the `useSubscription` in the <Notifications /> component, etc.)
                  Authorization: currentToken !== null ? currentToken : appSyncAuthorizationInfo.Authorization,
                },
              },
            },
          }))
        }

        return super.send(originalData)
      }

      _tryParseJsonString(jsonString) {
        try {
          return JSON.parse(jsonString)
        } catch (e) {
          return undefined
        }
      }
    },

    jsonMessageReplacer: (key, value) => {
      if (key === 'type' && value === 'subscribe') {
        return 'start'
      }
      if (key === 'type' && value === 'complete') {
        return 'stop'
      }

      return value
    },

    jsonMessageReviver: (key, value) => {
      if (key === 'type') {
        if (value === 'start') {
          return 'subscribe'
        }

        if (value === 'start_ack' || value === 'ka') {
          return 'connection_ack'
        }

        if (value === 'data') {
          return 'next'
        }
      }

      return value
    },
  })
}
