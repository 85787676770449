import { ICreateCompany, ICreateUser } from './types'
import { IMicrosoftUser } from '../../types/microsoft'

export const actions = {
  setCurrentStep: (payload: number) => ({
    type: 'SET_CURRENT_STEP',
    payload,
  }),
  onNextStepChange: () => ({
    type: 'ON_NEXT_STEP',
    payload: null,
  }),
  backtToWelcomePage: () => ({
    type: 'BACK_TO_WELCOME_PAGE',
    payload: null,
  }),
  setPurchaseToken: (payload: string) => ({
    type: 'SET_PURCHASE_TOKEN',
    payload,
  }),
  setCreateCompanyDetails: (payload: ICreateCompany) => ({
    type: 'SET_CREATE_COMPANY_DETAILS',
    payload,
  }),
  setCreateCompanyPlan: (payload: string) => ({
    type: 'SET_CREATE_COMPANY_PLAN',
    payload,
  }),
  setCreateUser: (payload: ICreateUser) => ({
    type: 'SET_CREATE_USER',
    payload,
  }),
  setNumberOfLicenses: (payload: number) => ({
    type: 'SET_NUMBER_OF_LICENSES',
    payload,
  }),
  isCreateCompanyLoader: (payload: boolean) => ({
    type: 'IS_CREATE_COMPANY_LOADER',
    payload,
  }),
  setStepsCreateCompany: (payload: boolean) => ({
    type: 'STEPS_CREATE_COMPANY',
    payload,
  }),
  setStepsAssignLicenses: (payload: boolean) => ({
    type: 'STEPS_ASSIGN_LICENSES',
    payload,
  }),
  onSelectUser: (payload: IMicrosoftUser) => ({
    type: 'ON_SELECT_USER',
    payload,
  }),
  setSelectedUser: (payload) => ({
    type: 'SET_SELECTED_USER',
    payload,
  }),
  setTotalUSers: (payload: number) => ({
    type: 'SET_TOTAL_USERS',
    payload,
  }),
  changeUserRole: (role: string, userId: string) => ({
    type: 'CHANGE_USER_ROLE',
    payload: {
      role,
      userId,
    },
  }),
  changeUserAnnounce: (announce: 'none' | 'email' | 'chat', userId: string) => ({
    type: 'CHANGE_USER_ANNOUNCE',
    payload: {
      announce,
      userId,
    },
  }),
  handleBulkSelect: (payload) => ({
    type: 'HANDLE_BULK_SELECT',
    payload,
  }),
  handleImportAllUsers: (payload) => ({
    type: 'HANDLE_IMPORT_ALL_USERS',
    payload,
  }),
  setTenantId: (payload: string) => ({
    type: 'SET_TENANT_ID',
    payload,
  }),
  setBotAndTabsInstalled: () => ({
    type: 'SET_BOT_AND_TABS_INSTALLED',
    payload: null,
  }),
  handleBulkAnnounceAction: (action: 'none' | 'email' | 'chat') => ({
    type: 'CHANGE_BULK_ANNOUNCE',
    payload: action,
  }),
}