/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react'
import { useManualQuery } from 'graphql-hooks'
import { useHistory } from 'react-router-dom'
import { App, Button, Form, Input, Modal, Radio, Result, Typography } from 'antd'
import qs from 'qs'
import { useIntl } from 'react-intl'
import jwtDecode from 'jwt-decode'
import * as Sentry from '@sentry/react'

import Api from '@vacationtracker/shared/services/api'
import { getDataForOutOfOffice } from '../../graphql/custom-queries'
import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { GoogleAuth } from '../../services/auth/google/google'
import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'
import { notificationStore } from '../../context/notificationsContext/store'
import { formatDateToLocale } from '@vacationtracker/shared/components/formatted-date'
import { track } from '../../services/analytics/analytics'

import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../../components/circular-progress'

import {
  IGetLeaveRequestById,
  IGetDataForOutOfOfficeSettingsResponse
} from '../../types/custom-queries'
import { ISetOutOfOfficeEvent } from '@vacationtracker/shared/types/set-out-of-office'
import { IOutOfOfficeSettingsParams, OutOfOfficePlatform } from '@vacationtracker/shared/types/user'
import { IGoogleOAuthTokens } from '@vacationtracker/shared/types/google'
import { EventTypeEnum, Platform } from '@vacationtracker/shared/types/core-event'
import { ITokens } from '../../types/microsoft'
import { FrontendUrls } from '../../types/urls'


const { Paragraph } = Typography


if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID) {
  throw new Error('Client IDs are required')
}

const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID)

const OutOfOfficePage: React.FC = () => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const { notification } = App.useApp()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const history = useHistory()
  const [isLoadingData, setIsLoadingData] = useState(true)
  const [isUpdatingUserOutOfOfficeSettings, setIsUpdatingUserOutOfOfficeSettings] = useState(false)
  const [showGoogleLoginRequiredModal, setShowGoogleLoginRequiredModal] = useState(false)
  const [showMicrosoftLoginRequiredModal, setShowMicrosoftLoginRequiredModal] = useState(false)
  const [googleTokens, setGoogleTokens] = useState<IGoogleOAuthTokens | null>(sessionStorage.getItem('googleOutOfOfficeTokens') ? JSON.parse(sessionStorage.getItem('googleOutOfOfficeTokens') as string) : null)
  const [googleTimezone, setGoogleTimezone] = useState(sessionStorage.getItem('googleOutOfOfficeTimezone') || '')
  const [microsoftTokens, setMicrosoftTokens] = useState<ITokens | null>(sessionStorage.getItem('outlookOutOfOfficeTokens') ? JSON.parse(sessionStorage.getItem('outlookOutOfOfficeTokens') as string) : null)
  const [microsoftTimezone, setMicrosoftTimezone] = useState(sessionStorage.getItem('outlookOutOfOfficeTimezone') || '')
  const [leaveRequest, setLeaveRequest] = useState<IGetLeaveRequestById>()
  const [googleUser, setGoogleUser] = useState('')
  const [outOfOfficeMessage, setOutOfOfficeMessage] = useState(sessionStorage.getItem('outOfOfficeMessage') || '')
  const [userOutOfOfficeSettings, setUserOutofOfficeSettings] = useState<IOutOfOfficeSettingsParams | null>()
  const [isOutOfOfficeActive, setIsOutOfOfficeActive] = useState(false)
  const [isOutOfOfficeSet, setIsOutOfOfficeSet] = useState(false)
  const [msTenantId, setMsTenantId] = useState(sessionStorage.getItem('msTenantId') || '')
  const [outlookEmail, setOutlookEmail] = useState(sessionStorage.getItem('outlookEmail') || '')

  const queryParams = qs.parse(location.search, { ignoreQueryPrefix: true })

  const [ getDataForOutOfOfficeQuery ] = useManualQuery<IGetDataForOutOfOfficeSettingsResponse, {
    userId: string
    leaveRequestId: string
  }>(getDataForOutOfOffice)

  useEffect(() => {
    fetchData(queryParams.leaveRequestId)
  }, [actionNotifications])

  const fetchData = async (leaveRequestId) => {
    setIsLoadingData(true)
    const dataForOutofOfficeSettingsResponse = await getDataForOutOfOfficeQuery({ variables: {
      userId: authUser?.id,
      leaveRequestId,
    }})
    if (!dataForOutofOfficeSettingsResponse.data || dataForOutofOfficeSettingsResponse.error) throw dataForOutofOfficeSettingsResponse.error

    if (outOfOfficeMessage === '') {
      setOutOfOfficeMessage(formatMessage({ id: 'bot.notification.oooDefaultMessage' }, {
        firstWorkingDay: `${formatDateToLocale(dataForOutofOfficeSettingsResponse?.data?.getLeaveRequest?.endDate, 'MMMM Do YYYY', authUser?.locale)}`,
      }))
    }

    if (dataForOutofOfficeSettingsResponse.data.getLeaveRequest?.user?.id !== authUser.id) {
      history.push(FrontendUrls.dashboard)
      return
    }

    setLeaveRequest(dataForOutofOfficeSettingsResponse.data.getLeaveRequest)
    setUserOutofOfficeSettings(dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings)

    if (dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings?.oooPlatform === 'outlook') {
      setMicrosoftTimezone(dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings?.timezone as string)
    }

    if (dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings?.oooPlatform === 'gmail') {
      setGoogleTimezone(dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings?.timezone as string)
    }

    setIsOutOfOfficeActive(dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings
      ? dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings.active
      : true
    )
    if (dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings?.googleUserId) {
      setGoogleUser(dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings?.googleUserId)
    }
    setMsTenantId(dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings?.msTenantId || '')
    setOutlookEmail(dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings?.outlookEmail || '')
    setGoogleUser(dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings?.googleUserId || '')
    form.setFieldsValue({otherEmail: sessionStorage.getItem('otherEmail') || ''})
    if (
      sessionStorage.getItem('outOfOfficeLeaveRequestId') === dataForOutofOfficeSettingsResponse.data.getLeaveRequest.id
      && dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings
    ) {
      setIsOutOfOfficeSet(true)
    } else {
      sessionStorage.removeItem('googleOutOfOfficeTokens')
      sessionStorage.removeItem('googleOutOfOfficeTimezone')
      sessionStorage.removeItem('outlookOutOfOfficeTokens')
      sessionStorage.removeItem('outlookOutOfOfficeTimezone')
      sessionStorage.removeItem('outOfOfficeMessage')
      sessionStorage.removeItem('outOfOfficeLeaveRequestId')
      sessionStorage.removeItem('msTenantId')
      sessionStorage.removeItem('outlookEmail')
      sessionStorage.removeItem('otherEmail')
      setIsOutOfOfficeSet(false)
    }
    setIsLoadingData(false)
    if (!dataForOutofOfficeSettingsResponse.data.getOutOfOfficeSettings && isUpdatingUserOutOfOfficeSettings) {
      setIsUpdatingUserOutOfOfficeSettings(true)
    } else {
      setIsUpdatingUserOutOfOfficeSettings(false)
    }
  }

  const checkTokenValidity = (platform: OutOfOfficePlatform, hasRefreshToken: boolean) => {
    if (platform === 'outlook') {
      if (microsoftTokens) {
        return true
      } else if (hasRefreshToken) {
        return true
      } else {
        setShowMicrosoftLoginRequiredModal(true)
        return false
      }
    } else if (platform === 'gmail') {
      if (googleTokens) {
        return true
      } else if (hasRefreshToken) {
        return true
      } else {
        setShowGoogleLoginRequiredModal(true)
        return false
      }
    } else {
      return false
    }
  }

  const handleOnSetOOO = async () => {
    const tokenValidity = checkTokenValidity(
      userOutOfOfficeSettings?.oooPlatform as OutOfOfficePlatform,
      userOutOfOfficeSettings && Boolean(userOutOfOfficeSettings?.refreshToken) || false
    )
    if (!tokenValidity) {
      return
    }
    const microsoftRefreshToken = microsoftTokens?.refreshToken || userOutOfOfficeSettings?.refreshToken
    const googleRefreshToken = googleTokens?.refreshToken || userOutOfOfficeSettings?.refreshToken
    const params: ISetOutOfOfficeEvent = {
      companyId: authCompany?.id as string,
      userId: authUser?.id as string,
      leaveRequestId: leaveRequest?.id as string,
      timezone: userOutOfOfficeSettings?.oooPlatform === 'outlook'
        ? microsoftTimezone
        : googleTimezone,
      refreshToken: userOutOfOfficeSettings?.oooPlatform === 'outlook'
        ? microsoftRefreshToken as string
        : googleRefreshToken as string,
      outOfOfficeMessage,
      platform: authUser.platform,
    }

    if (userOutOfOfficeSettings?.oooPlatform === 'outlook' && msTenantId !== '') {
      params.msTenantId = msTenantId || userOutOfOfficeSettings?.msTenantId
      params.outlookEmail = outlookEmail || userOutOfOfficeSettings?.outlookEmail
    }

    if (userOutOfOfficeSettings?.oooPlatform === 'gmail') {
      params.googleUserId = googleUser || userOutOfOfficeSettings?.googleUserId
    }

    try {
      await Api.post('/core/set-out-of-office', params)
      track('OUT_OF_OFFICE_PLATFORM', {
        companyId: authCompany?.id,
        userId: authUser?.id,
        platform: params.msTenantId ? 'outlook' : 'gmail',
      })
      setIsOutOfOfficeSet(true)
      sessionStorage.setItem('outOfOfficeLeaveRequestId', leaveRequest?.id as string)
    } catch (error) {
      notification.error({
        message: formatMessage({ id: 'error.generic' } ),
        description: formatMessage({ id: 'dashboard.outOfOfficeSetError' }),
        key: 'error-notification-handler-1',
        btn: <Button onClick={handleOnSetOOO}>
          <IntlMessages id="dashboard.outOfOfficeSet" />
        </Button>,
        duration: 0,
      })
      setIsOutOfOfficeSet(false)
    }
  }

  const getGoogleData = async () => {
    const tokens = await googleAuth.grantGmailPermission()
    const timezone = await googleAuth.getGoogleCalendarTimezone(tokens.accessToken as string) as string
    let user
    if (authUser.platform !== 'google') {
      const userRes = await googleAuth.userInfo(tokens.accessToken as string)
      user = userRes.email
      setGoogleUser(user.email)
    } else {
      user = authUser.email
      setGoogleUser(authUser.email)
    }
    sessionStorage.setItem('googleOutOfOfficeTokens', JSON.stringify(tokens))
    sessionStorage.setItem('googleOutOfOfficeTimezone', timezone)
    setGoogleTimezone(timezone)
    setGoogleTokens(tokens)
    // reset outlook platform in state
    setMicrosoftTokens(null)
    setMicrosoftTimezone('')
    setMsTenantId('')
    setOutlookEmail('')
    setShowGoogleLoginRequiredModal(false)
    return {
      tokens,
      googleUser: user,
      timezone,
    }
  }

  const getMicrosoftData = async () => {
    const tokens = await msAuth.getWebAppTokensForOutlook('offline_access MailboxSettings.ReadWrite user.readbasic.all')
    const timezone = await msAuth.getUserOutlookTimezone(tokens.accessToken)

    let tenantId
    let outlookEmail
    try {
      const decoded = jwtDecode<{tid: string; upn: string; unique_name: string}>(tokens?.accessToken as string)
      tenantId = decoded.tid
      outlookEmail = decoded?.upn || decoded?.unique_name
    } catch (error) {
      Sentry.captureException(JSON.stringify({
        ...error,
        message: 'Error decoding microsoft token - fallback to "common" tenant',
      }))
      tenantId = 'common'
    }
    setMsTenantId(tenantId)
    setOutlookEmail(outlookEmail)
    sessionStorage.setItem('msTenantId', tenantId)
    sessionStorage.setItem('outlookEmail', outlookEmail)
    sessionStorage.setItem('outlookOutOfOfficeTokens', JSON.stringify(tokens))
    sessionStorage.setItem('outlookOutOfOfficeTimezone', timezone)
    setMicrosoftTokens(tokens)
    setMicrosoftTimezone(timezone)
    // reset gmail platform in state
    setGoogleTokens(null)
    setGoogleTimezone('')
    setGoogleUser('')
    setShowMicrosoftLoginRequiredModal(false)
    return {
      tokens,
      timezone,
      msTenantId: tenantId,
      outlookEmail,
    }
  }

  const errorNotificationHandler = (error, platform: Platform, onClickHandler: Function) => {
    const isTokenError = error.message === 'no_tokens'
    let isInsufficientPermission = false
    if (platform === 'google') {
      const parsedError = JSON.parse(error.message)
      isInsufficientPermission = parsedError?.error?.message?.includes('insufficient authentication scopes')
    }
    const errorMessageDescription = isTokenError
      ? formatMessage({ id: 'error.token.description' }, {platform} )
      : isInsufficientPermission
        ? formatMessage({ id: 'error.token.permissionDenied' } )
        : error.message

    notification.error({
      message: formatMessage({ id: 'error.generic' } ),
      description: errorMessageDescription,
      key: 'error-notification-handler',
      btn: isTokenError || isInsufficientPermission
        ? <Button onClick={() => {
          onClickHandler()
          notification.destroy('error-notification-handler')
        }}>
          {formatMessage({ id: 'error.token.button' }, {platform} )}
        </Button>
        : null,
      duration: 0,
    })
  }

  const handleActivateOutOfOffice = async () => {
    if (userOutOfOfficeSettings && userOutOfOfficeSettings?.oooPlatform && userOutOfOfficeSettings?.refreshToken) {
      setIsOutOfOfficeActive(true)
      setIsUpdatingUserOutOfOfficeSettings(true)
      const body = {
        eventType: EventTypeEnum.USER_OUT_OF_OFFICE_SETTINGS_UPDATED,
        eventGroup: 'USER',
        companyId: authCompany?.id,
        platform: authUser.platform,
        userId: authUser.id,
        oooPlatform: userOutOfOfficeSettings?.oooPlatform,
        active: true,
        snoozedLeaveTypes: userOutOfOfficeSettings?.snoozedLeaveTypes,
        refreshToken: userOutOfOfficeSettings?.refreshToken,
        msTenantId: userOutOfOfficeSettings?.msTenantId || '',
        googleUserId: userOutOfOfficeSettings?.googleUserId || '',
        outlookEmail: userOutOfOfficeSettings?.outlookEmail || '',
      }
      try {
        const response = await Api.post('/core/event', body)
        setActionNotifications([ ...actionNotifications, response.correlationId ])
      } catch (error) {
        notification.error({
          message: formatMessage({ id: 'error.generic' } ),
          description: formatMessage({ id: 'dashboard.outOfOfficeSetError' }),
          key: 'error-notification-handler-1',
          btn: <Button onClick={handleActivateOutOfOffice}>
            <IntlMessages id="dashboard.outOfOfficeSet" />
          </Button>,
          duration: 0,
        })
      }
    } else {
      setIsOutOfOfficeActive(true)
    }
  }

  const handleOnGmailClick = async () => {
    try {
      const {
        tokens,
        googleUser,
        timezone,
      } = await getGoogleData()
      setIsUpdatingUserOutOfOfficeSettings(true)
      const body = {
        eventType: EventTypeEnum.USER_OUT_OF_OFFICE_SETTINGS_UPDATED,
        eventGroup: 'USER',
        companyId: authCompany?.id,
        platform: authUser.platform,
        userId: authUser.id,
        oooPlatform: 'gmail',
        active: isOutOfOfficeActive,
        snoozedLeaveTypes: !userOutOfOfficeSettings
          ? []
          : userOutOfOfficeSettings.snoozedLeaveTypes,
        refreshToken: tokens.refreshToken,
        msTenantId: userOutOfOfficeSettings?.msTenantId || '',
        outlookEmail: userOutOfOfficeSettings?.outlookEmail as string || '',
        googleUserId: googleUser,
        timezone,
      }

      const response = await Api.post('/core/event', body)
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      setIsUpdatingUserOutOfOfficeSettings(false)
      Sentry.captureException(JSON.stringify(error))
      errorNotificationHandler(error, 'google', handleOnGmailClick)
    }
  }

  const handleOnOutlookClick = async () => {
    try {
      const { tokens, timezone, msTenantId, outlookEmail } = await getMicrosoftData()
      setIsUpdatingUserOutOfOfficeSettings(true)
      const body = {
        eventType: EventTypeEnum.USER_OUT_OF_OFFICE_SETTINGS_UPDATED,
        eventGroup: 'USER',
        companyId: authCompany?.id,
        platform: authUser.platform,
        userId: authUser.id,
        oooPlatform: 'outlook',
        active: isOutOfOfficeActive,
        snoozedLeaveTypes: !userOutOfOfficeSettings
          ? []
          : userOutOfOfficeSettings.snoozedLeaveTypes,
        refreshToken: tokens.refreshToken,
        msTenantId,
        googleUserId: userOutOfOfficeSettings?.googleUserId || '',
        timezone,
        outlookEmail,
      }
      const response = await Api.post('/core/event', body)
      setIsUpdatingUserOutOfOfficeSettings(false)
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      Sentry.captureException(JSON.stringify(error))
      errorNotificationHandler(error, 'microsoft', handleOnOutlookClick)
    }
  }

  const handleSetOutOfOfficeMessage = (event) => {
    const message = event.target.value
    setOutOfOfficeMessage(message)
    sessionStorage.setItem('outOfOfficeMessage', message)
  }

  const handleOnSaveOtherEmail = async () => {
    const result = await form.validateFields()
    track('OUT_OF_OFFICE_OTHER_EMAIL_PLATFORM', {
      companyId: authCompany?.id,
      userId: authUser?.id,
      otherEmailPlatform: result.otherEmail,
    })
    sessionStorage.setItem('otherEmail', result.otherEmail)
  }

  const isOutOfOfficeSettingOk = userOutOfOfficeSettings
    && userOutOfOfficeSettings.oooPlatform
    && userOutOfOfficeSettings.active
    && userOutOfOfficeSettings.refreshToken
    && (
      (userOutOfOfficeSettings.oooPlatform === 'gmail' && userOutOfOfficeSettings.googleUserId)
      || (userOutOfOfficeSettings.oooPlatform === 'outlook' && userOutOfOfficeSettings.msTenantId)
    )

  if (isLoadingData || isUpdatingUserOutOfOfficeSettings) {
    return <CircularProgress />
  }

  if (!isLoadingData && !isOutOfOfficeActive) {
    return <div className='main-content'>
      <Paragraph>
        <IntlMessages id='dashboard.outOfOfficeNotActiveInfo' />
      </Paragraph>
      <Paragraph>
        <Radio.Group
          value={isOutOfOfficeActive}
          onChange={handleActivateOutOfOffice}
        >
          <Radio value={true}><IntlMessages id='app.yes' /></Radio>
          <Radio value={false}><IntlMessages id='app.no' /></Radio>
        </Radio.Group>
      </Paragraph>
    </div>
  }

  if (!isLoadingData && leaveRequest?.status === 'CANCELLED') {
    return <div className='main-content'>
      <Result
        status='warning'
        title={<IntlMessages id="user.cancelLeaveRequestTitle" />}
      />
    </div>
  }

  if (!isLoadingData && !leaveRequest) {
    return <div className='main-content'>
      <Result
        status='warning'
        title={<IntlMessages id="dashboard.noLeaveRequest" />}
      />
    </div>
  }

  if (isOutOfOfficeSet) {
    return <div className='main-content'>
      <Result
        status='success'
        title={<IntlMessages id="dashboard.outOfOfficeSetSuccess" />}
      />
    </div>
  }

  return <div className='main-content'>
    <div className="main-content-header">
      <div className="main-content-header-title">
        {!isOutOfOfficeSettingOk ? <IntlMessages id='dashboard.outOfOfficePageSettingsTitle' /> : <IntlMessages id='dashboard.outOfOfficePageTitle' />}
      </div>
    </div>
    <div className="main-content-body">
      {!isOutOfOfficeSettingOk && <div>
        <Button
          onClick={handleOnGmailClick}
          size='large'
        >
          <IntlMessages id='dashboard.outOfOfficeGoogleMailButton' />
        </Button>
        <span style={{ marginLeft: 15, marginRight: 15 }}>
          {formatMessage({ id: 'app.or' }).toLowerCase()}
        </span>
        <Button
          onClick={handleOnOutlookClick}
          size='large'
        >
          <IntlMessages id='dashboard.outOfOfficeOutlookButton' />
        </Button>
        <br />
        <br />
        <br />
        <Paragraph>
          <IntlMessages id='dashboard.outOfOfficeOtherEmailInfo' />
        </Paragraph>
        <br />
        <Paragraph>
          <IntlMessages id='dashboard.outOfOfficeOtherEmail' />
        </Paragraph>
        <Form
          form={form}
          onFinish={handleOnSaveOtherEmail}
        >
          <Form.Item
            name='otherEmail'
            rules={[
              { type: 'email',
                message: formatMessage({ id: 'app.notValidEmail' }),
                required: true,
              }]}
          >
            <Input
              disabled={!!sessionStorage.getItem('otherEmail')}
              style={{ width: '300px', marginRight: '10px' }}
              placeholder={formatMessage({ id: 'dashboard.outOfOfficeOtherEmailPlaceholder' })}
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              disabled={!!sessionStorage.getItem('otherEmail')}
            >
              <IntlMessages id='dashboard.outOfOffice.requestIntegrationButton' />
            </Button>
          </Form.Item>
        </Form>
      </div>
      }
      {isOutOfOfficeSettingOk && <div>
        <Paragraph>
          <IntlMessages id='dashboard.outOfOfficeSetInfo' />
        </Paragraph>
        <Input.TextArea
          defaultValue={outOfOfficeMessage}
          autoSize={{ minRows: 3, maxRows: 10 }}
          style={{ width: '600px', maxWidth: '100%', marginRight: '10px' }}
          onChange={handleSetOutOfOfficeMessage}
        />
        <br />
        <br />
        <Button
          type="primary"
          disabled={!outOfOfficeMessage}
          onClick={handleOnSetOOO}
        >
          <IntlMessages id="dashboard.outOfOfficeSet" />
        </Button>
      </div>}

      {showGoogleLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'dashboard.outOfOfficeGoogleSigninModalTitle' })}
          open={showGoogleLoginRequiredModal}
          onOk={() => getGoogleData()}
          onCancel={() => {
            setShowGoogleLoginRequiredModal(false)
          }}
        >
          <p><IntlMessages id="dashboard.permissionModalInfo" /></p>
          <p><IntlMessages id="dashboard.googlePermissionInfo2" /></p>
          <p><IntlMessages id="dashboard.googlePermissionInfo3" /></p>
        </Modal>
      }
      {showMicrosoftLoginRequiredModal &&
        <Modal
          title={formatMessage({ id: 'dashboard.outOfOfficeMicrosoftSigninModalTitle' })}
          open={showMicrosoftLoginRequiredModal}
          onOk={() => getMicrosoftData()}
          onCancel={() => {
            setShowMicrosoftLoginRequiredModal(false)
          }}
        >
          <p><IntlMessages id="dashboard.permissionModalInfo" /></p>
          <p><IntlMessages id="dashboard.outlookPermissionInfo2" /></p>
          <p><IntlMessages id="dashboard.outlookPermissionInfo3" /></p>
        </Modal>
      }
    </div>
  </div>
}

export default OutOfOfficePage