import axios from 'axios'
import { BrowserRuntime } from '../../../util/openid/browser-runtime'
import { parse, stringify } from 'qs'
import * as logger from '../../../services/logger'

import { wait } from '@vacationtracker/shared/functions/wait'

import { ISlackUser, ISlackConversationsList, IValidateScopesParams, IGetScopesResponse } from '@vacationtracker/shared/types/repository/slack-api-repository'
import difference from 'lodash/difference'
import { ISlackTokenFromCodeResponse } from '@vacationtracker/shared/types/slack'

type RawBody = {
  token: string
  team?: string
}

export class SlackAuth {
  private slackUrl = 'https://slack.com'
  private slackApiUrl = `${this.slackUrl}/api`
  private apiUrl = `${process.env.REACT_APP_API_URL}/slack`
  private targetUrl = `${window.location.origin}/slackredirect/index.html`
  private runtime = BrowserRuntime()
  private clientId?: string
  private botToken?: string
  private userToken?: string

  constructor(clientId?: string) {
    if (clientId) {
      this.clientId = clientId
    }
  }

  connect(teamId?: string | null, overrideScopes?: string[]) {
    const scopes = overrideScopes || ['openid', 'profile', 'email']
    const queryParams = [
      `scope=${scopes.join(',')}`,
      `client_id=${this.clientId}`,
      `redirect_uri=${this.targetUrl}`,
      `state=slack-${Math.random().toString(36).slice(2)}`,
      'response_type=code',
    ]
    if (teamId) {
      queryParams.push(`team=${teamId}`)
    }
    return this.runtime.openAndWaitForMessage(`${this.slackUrl}/openid/connect/authorize?${queryParams.join('&')}`, 'slack')
      .then((payloadRaw: string) => {
        payloadRaw = payloadRaw.slice(1)
        const payload = parse(payloadRaw)

        if(!payload.code || typeof payload.code !== 'string') {
          throw payload
        }

        return Promise.resolve(this.getTokenFromCode(payload.code))
      })
  }

  signinAndReturnCode(botScopes: string[], userScopes: string[], teamId?: string | null) {
    let slackUrl = `${this.slackUrl}/oauth/v2/authorize?scope=${botScopes.join(',')}&user_scope=${userScopes.join(',')}&client_id=${this.clientId}&redirect_uri=${this.targetUrl}`
    
    slackUrl += `&state=slack-${Math.random().toString(36).slice(2)}`
    if (teamId) {
      slackUrl += `&team=${teamId}`
    }
    return this.runtime.openAndWaitForMessage(slackUrl, 'slack')
      .then((payloadRaw: string) => {
        payloadRaw = payloadRaw.slice(1)
        const payload = parse(payloadRaw)

        if (!payload.code || typeof payload.code !== 'string') {
          throw payload
        }

        return {
          code: payload.code,
          state: payload.state,
        }
      })
  }

  signin(botScopes: string[], userScopes: string[], teamId?: string | null) {
    return this.signinAndReturnCode(botScopes, userScopes, teamId)
      .then(({code}) => {
        return this.getTokenFromCode(code)
      })
  }

  async getTokenFromCode(code: string, redirectUrl?: string): Promise<ISlackTokenFromCodeResponse> {
    try {
      const encodedRedirectUrl = encodeURIComponent(redirectUrl || this.targetUrl)
      const response = await axios.get(`${this.apiUrl}/get-token/${code}?redirect_url=${encodedRedirectUrl}`, {
        headers: {
          'Content-Type': 'application/json',
        },
      })

      return response.data
    } catch (err) {
      throw err.response.data
    }
  }

  setBotToken(token: string) {
    this.botToken = token
    localStorage.setItem('slackTokensBotToken', token)
  }

  setUserToken(token: string) {
    this.userToken = token
    localStorage.setItem('slackTokensUserToken', token)
  }

  setTokensFromStorage() {
    const userToken = localStorage.getItem('slackTokensUserToken')
    if (userToken) {
      this.userToken = userToken
    }
    const botToken = localStorage.getItem('slackTokensBotToken')
    if (botToken) {
      this.botToken = botToken
    }
  }

  getUserToken() {
    if (this.userToken) {
      return this.userToken
    }

    // Get tokens from local stogage
    const token = localStorage.getItem('slackTokensUserToken')

    if (token) {
      this.userToken = token
      return token
    }

    throw new Error('no_tokens')
  }

  getBotToken() {
    if (this.botToken) {
      return this.botToken
    }

    // Get tokens from local stogage
    const token = localStorage.getItem('slackTokensBotToken')

    if (token) {
      this.botToken = token
      return token
    }

    throw new Error('no_tokens')
  }

  removeTokens() {
    localStorage.removeItem('slackTokensUserToken')
    localStorage.removeItem('slackTokensBotToken')
    this.userToken = undefined
    this.botToken = undefined
  }

  async authTest(token?: string) {
    const rawBody = {
      token: token || this.userToken,
    }

    const response = await axios.post(`${this.slackApiUrl}/auth.test`, stringify(rawBody), {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
    })

    if (response.data.ok) {
      return response.data
    }

    throw response.data
  }

  async getUserFromOpenId(token?: string) {
    const rawBody = {
      token: token || this.userToken,
    }

    const response = await axios.post(`${this.slackApiUrl}/openid.connect.userInfo`, stringify(rawBody), {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
    })

    logger.info('Slack OpenID response', JSON.stringify(response.data, null, 2))

    if (response.data.ok) {
      return {
        ...response.data,
        real_name: response.data.name,
        team: {
          id: response.data['https://slack.com/team_id'],
          name: response.data['https://slack.com/team_name'],
        },
      }
    }

    throw response.data
  }

  async getUser(slackId: string, token?: string) {
    const rawBody = {
      token: token || this.userToken,
      user: slackId,
    }

    const response = await axios.post(`${this.slackApiUrl}/users.info`, stringify(rawBody), {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
    })

    if (response.data.ok) {
      return response.data.user
    }

    if (response.data.ok === false && response.data.error === 'missing_scope' && response.data.needed === 'users:read') {
      return await this.getUserFromOpenId(token)
    }

    throw response.data
  }

  async getTeam(teamId?: string) {
    const rawBody: RawBody = {
      token: this.userToken || '',
    }
    if (teamId) {
      rawBody.team = teamId
    }

    const response = await axios.post(`${this.slackApiUrl}/team.info`, stringify(rawBody), {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
    })

    if (response.data.ok) {
      return response.data.team
    }

    throw response.data
  }

  async getUsersList(nextCursor?: string, slackUsers = []): Promise<ISlackUser[]> {
    const params = {
      token: this.botToken || this.userToken,
      limit: 1000,
      // eslint-disable-next-line id-blacklist
      cursor: nextCursor ? nextCursor : undefined,
    }

    try {
      const response = await axios.post(`${this.slackApiUrl}/users.list`, stringify(params), {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      })

      if (response.data.ok) {
        const users = slackUsers.concat(response.data.members)

        if (response.data.response_metadata && response.data.response_metadata.next_cursor) {
          return await this.getUsersList(response.data.response_metadata.next_cursor, users)
        }

        return users
      }

      throw response.data
    } catch (err) {
      // If error status is 429, wait and retry
      if (err?.response?.status === 429) {
        const retryAfter = Number(err.response.headers && err.response.headers['retry-after'])
        logger.debug('RETRY AFTER: ', retryAfter)
        await wait(retryAfter * 1000)
        return await this.getUsersList(nextCursor, slackUsers)
      }
      logger.error('SLACK ERROR', err)
      // Throw an error for further processing
      throw err
    }
  }

  async getUserListPagination(nextCursor?: string, limit = 200): Promise<{
    members: ISlackUser[]
    nextCursor?: string
  }> {
    const params = {
      token: this.botToken || this.userToken,
      limit,
      // eslint-disable-next-line id-blacklist
      cursor: nextCursor ? nextCursor : undefined,
    }

    try {
      const response = await axios.post(`${this.slackApiUrl}/users.list`, stringify(params), {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      })

      if (response.data.ok) {
        return {
          members: response.data.members,
          nextCursor: response.data.response_metadata.next_cursor,
        }
      }
      throw response.data
    } catch (err) {
      // If error status is 429, wait and retry
      if (err?.response?.status === 429) {
        const retryAfter = Number(err.response.headers && err.response.headers['retry-after'])
        logger.debug('RETRY AFTER: ', retryAfter)
        await wait(retryAfter * 1000)
        return await this.getUserListPagination(nextCursor)
      }
      logger.error('SLACK ERROR', err)
      // Throw an error for further processing
      throw err
    }
  }

  async conversationsList(nextCursor?: string, slackConversationsList = []): Promise<ISlackConversationsList[]> {
    const params = {
      token: this.userToken,
      limit: 1000,
      types: 'public_channel,private_channel',
      exclude_archived: true,
      // eslint-disable-next-line id-blacklist
      cursor: nextCursor ? nextCursor : undefined,
    }

    try {
      const response = await axios.post(`${this.slackApiUrl}/conversations.list`, stringify(params), {
        headers: {
          'Content-type': 'application/x-www-form-urlencoded',
        },
      })

      if (response.data.ok) {
        const conversationsList = slackConversationsList.concat(response.data.channels)

        if (response.data.response_metadata && response.data.response_metadata.next_cursor) {
          return await this.conversationsList(response.data.response_metadata.next_cursor, conversationsList)
        }

        return conversationsList
      }

      throw response.data
    } catch (err) {
      // If error status is 429, wait and retry
      if (err?.response?.status === 429) {
        const retryAfter = Number(err.response.headers && err.response.headers['retry-after'])
        logger.debug('RETRY AFTER: ', retryAfter)
        await wait(retryAfter * 1000)
        return await this.conversationsList(nextCursor, slackConversationsList)
      }
      logger.error('SLACK ERROR', err)
      // Throw an error for further processing
      throw err
    }
  }

  async conversationsInvite(channel: string, users: string): Promise<void> {
    const rawBody = {
      token: this.userToken,
      channel,
      users,
    }

    const response = await axios.post(`${this.slackApiUrl}/conversations.invite`, stringify(rawBody), {
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
    })

    if (response.data.ok) {
      return response.data.channel
    }

    if (response.data.needed === 'groups:write') {
      return
    }

    throw response.data
  }

  async areScopesValid(params: IValidateScopesParams): Promise<boolean> {
    const { userToken, botToken, requiredUserScopes, requiredBotScopes } = params
    let queryParams = `user_token=${userToken}`
    if (botToken) {
      queryParams += `&bot_token=${botToken}`
    }

    const response = await axios.get(`${this.apiUrl}/get-scopes?${queryParams}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    })

    const { userScopes, botScopes } = response.data as IGetScopesResponse
    
    if (!userScopes || difference(requiredUserScopes, userScopes).length > 0) {
      return false
    }

    if (botToken && (!botScopes || difference(requiredBotScopes, botScopes).length > 0)) {
      return false
    }

    return true
  }
}