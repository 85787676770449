import { findIndex } from 'lodash'
import { findOverlappingLeaves } from '../helpers'

export const findOverlaps = (data, addError) => {
  const overlaps = findOverlappingLeaves(data).overlappingPairs.flat()
  overlaps.forEach((overlap) => {
    const index = findIndex(data, overlap)
    if (index > -1) {
      const row = data[index]
      const error = `Leave date overlaps with existing leave from ${JSON.stringify(
        row.dateFrom
      )} to ${JSON.stringify(row.dateTo)}.`
      addError(index, 'dateFrom', { message: error, level: 'error' })
    }
  })
  return data
}
