import dayjs from 'dayjs'
import { IAccrualsDates } from './types'

interface IParams {
  yearStartDay: number
  today: Date
}

export function getDatesForAccrualsMonthly({ today, yearStartDay }: IParams): IAccrualsDates {
  const accrualPeriodStart = dayjs(today).set('date', yearStartDay).format('YYYY-MM-DD')
  const accrualPeriodEnd = dayjs(accrualPeriodStart).add(1, 'month').subtract(1, 'days').format('YYYY-MM-DD')
  const pickerStartDate = accrualPeriodStart
  const pickerEndDate = dayjs(accrualPeriodStart).add(2, 'month').subtract(4, 'days').format('YYYY-MM-DD')
  const nextAccrualPeriod = dayjs(accrualPeriodStart).add(1, 'month').format('YYYY-MM-DD')

  return {
    pickerStartDate,
    pickerEndDate,
    accrualPeriodStart,
    accrualPeriodEnd,
    nextAccrualPeriod,
  }
}
