import * as Sentry from '@sentry/react'
import { CrispSegmentsEnum } from '../types/crisp'

type CrispSessionData = {
  [key: string]: string | number
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

export const setCrispSessionInfo = (segments: CrispSegmentsEnum[], sessionData: CrispSessionData) => {
  try {
    if (!window.$crisp) {
      return
    }

    window.$crisp.push(['set', 'session:segments', [segments, true]])
    window.$crisp.push(['set', 'session:data', Object.entries(sessionData)])
  } catch (err) {
    // Swallow errors
    Sentry.captureException(err)
  }
}