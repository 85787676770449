//libraries
import React, { useEffect, useState } from 'react'
import { useManualQuery } from 'graphql-hooks'
import dayjs, { Dayjs } from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { orderBy } from 'lodash'

//ant
import { App, Card, Timeline, DatePicker, Select, Typography, Row, Col, Button, Space, Tooltip, TreeSelect } from 'antd'
import { InfoCircleOutlined, EditOutlined } from '@ant-design/icons'

//vt shared
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { EventGroupEnum } from '@vacationtracker/shared/types/core-event'
import { eventGroupsForCompanyLogs, maxMonthsInPast, maxPerPage } from '@vacationtracker/shared/types/logs-company'
import timezoneData from '@vacationtracker/shared/data/timezone'
import { getLogLine, ICompanyLogLineResult } from '../../../components/log-line-company'

//store
import { useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'

//components
import CircularProgress from '../../../components/circular-progress'

//utils
import IntlMessages from '../../../util/IntlMessages'
import { filterOptions } from '../../../util/filter-options'

//graphql
import { IGetCompanyLogsData, IUserLogs } from '../../../types/custom-queries'
import { getCompanyLogs, getLocations, getTeams } from '../../../graphql/custom-queries'

//types
import { IGetLocations, IGetLocationsData } from '../../../types/locations'
import { IGetTeamList, IGetTeamListData } from '../../../types/teams'
import { useIntl } from 'react-intl'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'

export interface IDecoratorWords {
  notSet: string
  yes: string
  no: string
  days: string
  years: string
  every: string
}

export interface IGetCompanyLogsVariables {
  fromDate: string
  toDate: string
  eventGroup: string | null
  groupKey: string
  lastId?: string
}

dayjs.extend(utc)
dayjs.extend(timezone)

const CompanyLogsPage: React.FC = () => {
  const { notification } = App.useApp()
  const { RangePicker } = DatePicker
  const { Option } = Select
  const { Paragraph, Text } = Typography
  const dateFormat = 'YYYY-MM-DD'
  const [isLoading, setIsLoading] = useState(true)
  const [disableLoadMoreLogs, setDisableLoadMoreLogs] = useState(false)
  const [companyLogs, setCompanyLogs] = useState<IUserLogs[]>([])
  const [locations, setLocations] = useState<IGetLocations[]>([])
  const [teams, setTeams] = useState<IGetTeamList[]>([])
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { formatMessage } = useIntl()

  const decoratorWords: IDecoratorWords = {
    notSet: formatMessage({id: 'app.notSet'}),
    yes: formatMessage({id: 'app.yes'}),
    no: formatMessage({id: 'app.no'}),
    days: formatMessage({id: 'app.days'}),
    years: formatMessage({id: 'app.years'}),
    every: formatMessage({id: 'app.every'}),
  }

  const [timezone, setTimezone] = useState(() => {
    const storageTimezone = localStorage.getItem('USER_TIMEZONE')
    const existTimezone = timezoneData.find((item) => item.id === (storageTimezone || authUser.location.timezone))
    if (existTimezone) {
      return existTimezone.id
    }
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  })
  const [startDate, setStartDate] = useState(dayjs().utc().tz(timezone).subtract(1, 'month'))
  const [endDate, setEndDate] = useState(dayjs().utc().tz(timezone))
  const [eventGroup, setEventGroup] = useState('ALL')
  const [groupKey, setGroupKey] = useState('')
  const [editTimezone, setEditTimezone] = useState(false)

  const [ getCompanyLogsQuery ] = useManualQuery<IGetCompanyLogsData, IGetCompanyLogsVariables>(getCompanyLogs)
  const [ getLocationsQuery ] = useManualQuery<IGetLocationsData>(getLocations)
  const [ getTeamsQuery ] = useManualQuery<IGetTeamListData>(getTeams)

  useEffect(() => {
    fetchLocations()
    fetchTeams()
  }, [])

  useEffect(() => {
    fetchCompanyLogs(eventGroup, startDate, endDate, groupKey)
  }, [startDate, endDate, eventGroup, groupKey, timezone])

  const fetchCompanyLogs = async (eventGroup: string, startDate: Dayjs, endDate: Dayjs, groupKey: string, moreLogs = false) => {
    try {
      const response = await getCompanyLogsQuery({ variables: {
        fromDate: startDate.startOf('day').toISOString(),
        toDate: endDate.endOf('day').toISOString(),
        eventGroup: eventGroup === 'ALL' ? null : eventGroup,
        groupKey,
        lastId: moreLogs ? companyLogs[companyLogs.length - 1].sk : '',
      }})
      if (!response.data || response.error) throw response.error
      const logs = response.data.getCompany.logs
      setDisableLoadMoreLogs(logs.length < maxPerPage)
      moreLogs ? setCompanyLogs([...companyLogs, ...logs]) : setCompanyLogs(logs)
      setIsLoading(false)
    } catch (error) {
      const message = error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
      notification.error({
        message,
        duration: 0,
      })
    }
  }

  const fetchMoreLogs = async () : Promise<void> => {
    setDisableLoadMoreLogs(true)
    await fetchCompanyLogs(eventGroup, startDate, endDate, groupKey, true)
  }

  const fetchLocations = async() => {
    const response = await getLocationsQuery()
    if (!response.data || response.error) throw response.error
    setLocations(orderBy(response.data.getLocationList, [l => l.isDefault,'name'], ['desc', 'asc']))
  }

  const fetchTeams = async() => {
    const response = await getTeamsQuery()
    if (!response.data || response.error) throw response.error
    const orderedTeams = orderBy(response.data.getTeamList, [team => team.isDefault,'name'], ['desc', 'asc'])
    setTeams(orderedTeams)
  }

  const changeTimezone = (newTimezone: string) => {
    setTimezone(newTimezone)
    localStorage.setItem('USER_TIMEZONE', newTimezone)
    const start = startDate.utc().tz(newTimezone)
    const end = endDate.utc().tz(newTimezone)
    setStartDate(start)
    setEndDate(end)
    setEditTimezone(false)
  }

  const onChangeDateRangePicker = (dates) => {
    const start = dates[0].utc().tz(timezone) as Dayjs
    const end = dates[1].utc().tz(timezone) as Dayjs
    setStartDate(start)
    setEndDate(end)
  }

  const onChangeEventFilter =  (eventGroup: string) => {
    setGroupKey('')
    setEventGroup(eventGroup)
  }

  const onChangeGroupKeyFilter =  (groupKey: string) => {
    setGroupKey(groupKey)
  }

  function isDateDisabled(current): boolean {
    if (!startDate || !endDate) {
      return false
    }
    if (authCompany?.plan !== SubscriptionPlanEnum.complete) {
      return current > dayjs(new Date()) || current < dayjs().subtract(maxMonthsInPast, 'months')
    }
    return current > dayjs(new Date()) || current < dayjs().subtract(1, 'year')
  }

  const filters = () => {
    return (<>
      <Row gutter={[8,24]} justify='space-between' align='middle'>
        <Col>
          <IntlMessages id="components.userLogsTab.filter" />
          <Tooltip className="info-tooltip"
            title={<IntlMessages
              id="components.comparePlatformsModal.forMoreInfoSeeOurHelpdesk"
              values={{
                // eslint-disable-next-line
                helpDesk: (...chunks) => (
                  <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noopener noreferrer">
                    {chunks}
                  </a>
                ),
              }}
            />} ><InfoCircleOutlined />
          </Tooltip>
          <Select
            style={{ marginLeft: 10, marginRight: 10, width: 200 }}
            value={eventGroup}
            onSelect={onChangeEventFilter}
            filterOption={filterOptions}
            defaultValue={'ALL'}
          >
            <Option key={'all'} value='ALL'><IntlMessages id={'app.all'} /></Option>
            {Object
              .keys(EventGroupEnum)
              .filter((event) => eventGroupsForCompanyLogs.includes(event as EventGroupEnum))
              .map(event =>
                <Option key={event} value={event}>
                  <IntlMessages id={`events.logs.${event}`} />
                </Option>)
            })
          </Select>
        </Col>
        {
          [EventGroupEnum.LEAVE_POLICY, EventGroupEnum.LOCATION, EventGroupEnum.LOCATION_YEAR_ROLLOVER, EventGroupEnum.NOTIFICATION].includes(eventGroup as EventGroupEnum)
          && <Col>
            <IntlMessages id="app.locations" />
            <Select
              style={{ marginLeft: 10, marginRight: 10, width: 200 }}
              value={groupKey}
              onSelect={onChangeGroupKeyFilter}
              filterOption={filterOptions}
              defaultValue={'ALL'}
            >
              {locations
                .map(location =>
                  <Option key={location.id} value={location.id}>
                    {location.name}
                  </Option>)
              }
            </Select>
          </Col>}
        {
          [EventGroupEnum.TEAM_POLICY].includes(eventGroup as EventGroupEnum)
          && <Col>
            <IntlMessages id="app.departments" />
            <Select
              style={{ marginLeft: 10, marginRight: 10, width: 200 }}
              value={groupKey}
              onSelect={onChangeGroupKeyFilter}
              filterOption={filterOptions}
              defaultValue={'ALL'}
            >
              {teams
                .map(team =>
                  <Option key={team.id} value={team.id}>
                    {team.name}
                  </Option>)
              }
            </Select>
          </Col>}{
          [EventGroupEnum.TEAM].includes(eventGroup as EventGroupEnum)
          && <Col>
            <IntlMessages id="app.departments" />
            <TreeSelect
              showSearch
              style={{ marginLeft: 10, marginRight: 10, width: 300 }}
              value={groupKey}
              onSelect={onChangeGroupKeyFilter}
              treeNodeFilterProp='name'
              defaultValue={'ALL'}
              treeData={teams}
              treeDataSimpleMode={{id: 'id', pId: 'parentId'}}
              fieldNames={{label: 'name', value: 'id'}}
            >
            </TreeSelect>
          </Col>}
        <Col>
          <IntlMessages id="components.userLogsTab.selectFromTo" />
          <Tooltip className="info-tooltip"
            title={<IntlMessages id={authCompany?.plan === 'Complete' ? 'components.companyLogs.rangeLimit' : 'components.companyLogs.dateRangeLimit'} />} ><InfoCircleOutlined />
          </Tooltip>
          <RangePicker
            style={{ marginLeft: 10, marginRight: 10 }}
            onChange={onChangeDateRangePicker}
            allowClear={false}
            defaultValue={[startDate, endDate]}
            format={dateFormat}
            placement="bottomRight"
            disabledDate={isDateDisabled}
          />
        </Col>
        <Col>
          {editTimezone ?
            <Select style={{ width: 300 }}
              value={timezone}
              showSearch
              onSelect={changeTimezone}
              filterOption={filterOptions}
            >
              {timezoneData.map(tz => <Option key={tz.id} value={tz.id}>{tz.text}</Option>)}
            </Select> :
            <><Text type="secondary">{timezone}</Text> <EditOutlined className="edit-timezone" onClick={() => setEditTimezone(true)} /></>
          }
        </Col>
      </Row>
    </>)
  }
  return (
    <Card
      className="user-logs-card"
      title={filters()}
      style={{ marginBottom: 30 }}
      styles={{ body: { padding: 0 } }}
    >
      {(isLoading) ?
        <CircularProgress style={{ marginTop: 40 }} /> :
        <>
          {companyLogs?.length > 0 ?
            <>
              <Timeline
                mode="left"
                className="logs-timeline ant-timeline-label"
                items={companyLogs
                  .map((log, index) => (
                    getLogLine({
                      decoratorWords: decoratorWords,
                      sk: log.sk,
                      type: log.type,
                      timestamp: log.timestamp,
                      creatorName: log.creatorName,
                      creatorId: log.creatorId,
                      paramsJson: JSON.parse(log.paramsJson),
                      timezone: timezone,
                      hourFormat: authUser.hourFormat || HourFormatEnum.twentyFour,
                      formatMessage,
                      index,
                    })
                  ))
                  .filter(log => log !== null) as ICompanyLogLineResult[]
                }
              />
              <Space direction="horizontal" style={{width: '100%', justifyContent: 'center', marginBottom: '20px'}}>
                {!disableLoadMoreLogs &&
                <Button
                  type="primary"
                  loading={isLoading}
                  size='small'
                  onClick={fetchMoreLogs}
                >
                  <IntlMessages id="app.loadMore" />
                </Button>
                }
              </Space>
            </>
            :
            <Paragraph style={{ textAlign: 'center', marginTop: 30 }}><IntlMessages id="components.userLogsTab.logsNotFound" /></Paragraph>
          }
        </>
      }
    </Card>
  )
}

export default CompanyLogsPage
