import * as url from 'url'
import { ILeaveRequest } from './types'
import { CalendarType } from '../../types/features/add-to-calendar'


export function generateAddToCalendarLink(platform: CalendarType, request: ILeaveRequest, apiUrl: string, URL?: any): string {
  if (!apiUrl.includes('https://')) {
    apiUrl = `https://${apiUrl}/`
  }

  let addToCalendarUrl

  if (URL) {
    addToCalendarUrl = new URL('/core/add-to-calendar', apiUrl)
  } else {
    addToCalendarUrl = new url.URL('/core/add-to-calendar', apiUrl)
  }

  /*
    Querystring params format:
    ci - company ID
    ui - user ID
    pl - platform (google or office365)
    lt - leave type name
    sd - start date
    ed - end date
    pd - is part day
    sh - part day start hour (optional, only for the non-full day leaves)
    eh - part day end hour (optional, only for the non-full day leaves)
  */

  addToCalendarUrl.searchParams.append('ci', request.companyId)
  addToCalendarUrl.searchParams.append('ui', request.userId)
  addToCalendarUrl.searchParams.append('pl', platform)
  addToCalendarUrl.searchParams.append('lt', request.leaveTypeName)
  addToCalendarUrl.searchParams.append('sd', request.startDate)
  addToCalendarUrl.searchParams.append('ed', request.endDate)
  addToCalendarUrl.searchParams.append('pd', String(request.isPartDay))

  if (
    request.isPartDay &&
    request.partDayStartHour &&
    request.partDayEndHour
  ) {
    addToCalendarUrl.searchParams.append('sh', String(request.partDayStartHour))
    addToCalendarUrl.searchParams.append('eh', String(request.partDayEndHour))
  }

  return addToCalendarUrl.href
}

export function generateBroadcastWhatsNewLink({
  eventName,
  announcementId,
  companyId,
  userId,
  linkUrl,
  medium,
  apiUrl,
}: {
  eventName: string
  announcementId: string
  companyId: string
  userId: string
  linkUrl: string
  medium: string
  apiUrl: string
}): string {
  if (!apiUrl.includes('https://')) {
    apiUrl = `https://${apiUrl}/`
  }

  const newLinkUrl = new url.URL('/core/redirect', apiUrl)

  newLinkUrl.searchParams.append('companyId', companyId)
  newLinkUrl.searchParams.append('userId', userId)
  newLinkUrl.searchParams.append('linkUrl', linkUrl)
  newLinkUrl.searchParams.append('medium', medium)
  newLinkUrl.searchParams.append('announcementId', Buffer.from(announcementId, 'base64').toString('base64'))
  newLinkUrl.searchParams.append('eventName', eventName)
  newLinkUrl.searchParams.append('eventType', 'BROADCAST_WHATS_NEW')

  return newLinkUrl.href
}