import React from 'react'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
dayjs.extend(advancedFormat)


import { getSafeLocale } from '@vacationtracker/shared/i18n'
import getDateInUserTimezone from '@vacationtracker/shared/functions/get-date-in-user-timezone'

import { LocaleEnum } from '@vacationtracker/shared/types/i18n'


interface IFormattedDate {
  value: string
  format: string
  locale?: LocaleEnum
}

const FormattedDate: React.FunctionComponent<IFormattedDate> = ({value, format = 'YYYY-MM-DD', locale}) => {
  const safeLocale = getSafeLocale(locale)
  const dateInTimeZone = getDateInUserTimezone(value)

  return (<span>{dayjs(dateInTimeZone).locale(safeLocale).format(format)}</span>)
}

export const formatDateToLocale = (value: string, format: string, locale?: LocaleEnum) => {
  const safeLocale = getSafeLocale(locale)
  const dateInTimeZone = getDateInUserTimezone(value)

  return dayjs(dateInTimeZone).locale(safeLocale).format(format)
}


export default FormattedDate