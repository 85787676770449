/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import { Button, Typography, Row, Col } from 'antd'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'

import IntlMessages from '../../../util/IntlMessages'
import { actions } from '../actions'
import { ICreateCompanyState, SignupVariation } from '../types'
import { track } from '../../../services/analytics/analytics'
import { Platform } from '@vacationtracker/shared/types/core-event'
import { setCrispSessionParams } from '../helpers'
import PricePlanCard from '../../../components/price-plan-card'
import CircularProgress from '../../../components/circular-progress'
import { PlanPricePerUser } from '../../../util/get-price-plan-bucket-info'
import { FrontendUrls } from '../../../types/urls'
import { AuthStateEnum } from '@vacationtracker/shared/types/auth-state'
import { setAuthState } from '../../../store/auth-state-slice'
import { useAppDispatch } from '../../../store/hooks'

const { Title, Text } = Typography

interface ISelectPlanProps {
  state: ICreateCompanyState
  variation: SignupVariation
  dispatch: (e) => void
  onFinishSetup: (e) => void
  notAllCompanyDataSet: (state) => boolean
}

export const SelectPlan = ({
  state,
  variation,
  dispatch,
  onFinishSetup,
  notAllCompanyDataSet,
}: ISelectPlanProps): React.ReactElement => {
  const { formatMessage } = useIntl()
  const [ platform, setPlatform ] = useState<Platform>()
  const history = useHistory()
  const reduxDispatch = useAppDispatch()

  const { createUser, createCompany } = state

  useEffect(() => {
    if (createUser.platform) {
      setPlatform(createUser.platform)
    }
  }, [createUser.platform])

  useEffect(() => {
    if (platform) {
      track('SIGNUP_SELECT_PLAN_FORM_VIEWED', {
        platform,
        variation,
        email: createCompany.contactEmail,
        timestamp: new Date().toISOString(),
        source: 'app',
        status: 'step3',
      })
      setCrispSessionParams('step 3: congrats')
    }
  }, [platform])

  const onSelectPLan = (plan) => {
    track('SIGNUP_SELECT_PLAN_FORM_COMPLETED', {
      platform,
      variation,
      plan,
      email: createCompany.contactEmail,
    })

    dispatch(actions.setSubscriptionPlan(plan))

    if(variation === 'ACCOUNT_USERS_PLAN') {
      if(notAllCompanyDataSet(state) && state.selectedUsers.length === 0) {
        // TODO: Show error state missing
        return
      }
      onFinishSetup(plan)
    } else {
      history.push('/create-company/step2')
      dispatch(actions.onNextStepChange())
    }
  }

  const handleBack = () => {
    track('SIGNUP_SELECT_PLAN_FORM_BACK', {
      platform,
      variation,
      plan: createCompany.plan, // Previously selected plan
      email: createCompany.contactEmail,
    })
    if(variation === 'ACCOUNT_USERS_PLAN') {
      dispatch(actions.onPrevStepChange())
      history.push('/create-company/step2')
    } else {
      localStorage.removeItem('vtCreateUser')
      localStorage.removeItem('vtCreateCompany')
      localStorage.removeItem('vtSelectedUsers')

      reduxDispatch(setAuthState(AuthStateEnum.signIn))
      history.push(FrontendUrls.signin)
    }
  }

  return (
    <div style={{textAlign: 'center'}} className="select-plan">
      <Title level={4}>
        <IntlMessages id="createCompany.steps.selectPlan.title" />
      </Title>

      <div className="plans">
        {state.isCreateCompanyLoader &&
          <div className="loader-wrap">
            <CircularProgress />
          </div>
        }
        <Row gutter={16} justify='center' style={{ opacity: state.isCreateCompanyLoader ? 0.6 : 1}}>
          <Col xxl={9} xl={10} lg={11} md={11} sm={16} xs={22} style={{ display: 'flex' }}>
            <PricePlanCard
              plan='Core'
              currentPeriod="monthly"
              totalPrice={25}
              totalUsers={1}
              pricePerUser={PlanPricePerUser.Core}
              currentPlan="none"
              newPeriod="monthly"
              showRevertingInfo={false}
              isSignup={true}
              onSelectPLan={onSelectPLan}
              signupButtonText={
                variation === 'ACCOUNT_USERS_PLAN' ?
                  formatMessage({ id: 'app.startFreeTrial' }) :
                  formatMessage({ id: 'components.pricePlanCard.selectPlan' })
              }
            />
          </Col>
          <Col xxl={9} xl={10} lg={11} md={11} sm={16} xs={22} style={{ display: 'flex' }}>
            <PricePlanCard
              plan='Complete'
              currentPeriod="monthly"
              totalPrice={75}
              totalUsers={1}
              pricePerUser={PlanPricePerUser.Complete}
              currentPlan="none"
              newPeriod="monthly"
              showRevertingInfo={false}
              isSignup={true}
              onSelectPLan={onSelectPLan}
              signupButtonText={
                variation === 'ACCOUNT_USERS_PLAN' ?
                  formatMessage({ id: 'app.startFreeTrial' }) :
                  formatMessage({ id: 'components.pricePlanCard.selectPlan' })
              }
            />
          </Col>
        </Row>
      </div>
      <p style={{ marginBottom: 0 }}>
        <Text type="secondary" style={{ fontSize: 16 }}><IntlMessages id="createCompany.steps.selectPlan.noCC" /></Text>
      </p>
      <p>
        <Text type="secondary" style={{ fontSize: 16 }}><IntlMessages id="createCompany.steps.selectPlan.youCanSwitchPlanDuringTrial" /></Text>
      </p>

      <Button
        size="large"
        style={{ marginTop: 10 }}
        onClick={handleBack}>
        {variation === 'ACCOUNT_USERS_PLAN' ?
          <IntlMessages id="app.back" /> :
          <IntlMessages id="app.cancel" />
        }
      </Button>
    </div>
  )
}