import { createSlice, PayloadAction } from '@reduxjs/toolkit'


import { RootState } from './configure'

import { availableLanguages } from '@vacationtracker/shared/i18n'
import { ILocale } from '@vacationtracker/shared/types/i18n'



interface LocaleState {
  locale: ILocale
}

const localeFromStorage = localStorage.getItem('vtLocale')

const initialState: LocaleState = {
  locale: localeFromStorage
    ? availableLanguages[localeFromStorage]
    : availableLanguages.en,
}

export const localeSlice = createSlice({
  name: 'locale',
  initialState,
  reducers: {
    setLocale: (state, action: PayloadAction<ILocale>) => {
      state.locale = action.payload
    },
  },
})

export const { setLocale } = localeSlice.actions

export const selectLocaleSlice = (state: RootState) => state.localeSlice

export default localeSlice.reducer
