import { IPrice, PriceId, PriceName } from '@vacationtracker/shared/data/stripe/prices'
import { ProductService } from '@vacationtracker/shared/data/stripe/product-service'

export const USER_PLAN_LIMIT = 25

export enum PlanPricePerUser {
  Core = 1,
  Complete = 3,
  CompleteTransition2024 = 2.5,
}

const defaultAnnualCorePrice = PriceName.CORE_ANNUAL_2024
const defaultAnnualCompletePrice = PriceName.COMPLETE_ANNUAL_2024

export const getBucketPlanInfo = (totalUsers: number, plan: string, existingPriceId?: PriceId) => {
  const env = process.env.REACT_APP_ENVIRONMENT === 'production' ? 'prod' : 'test'
  const productsService = new ProductService(env)
  let price: IPrice
  price = productsService.getPriceById(existingPriceId as PriceId)
  if (existingPriceId && price?.period === 'annual') {
    price = productsService.getPriceById(existingPriceId)
  } else {
    const priceName = plan === 'Complete' ? defaultAnnualCompletePrice : defaultAnnualCorePrice
    price = productsService.getPriceByName(priceName)
  }
  if (price.legacy) {
    const amount = price?.tiers[0]?.unit_amount
    return {
      currentBucket: {
        flat_amount: Number(amount) / 100,
        up_to: 1,
      },
      price: Number(amount) / 100,
    }
  }

  const currentBucket = price?.tiers?.filter(bucket => bucket.up_to === 'inf' ? bucket.flat_amount : bucket.up_to >= totalUsers)[0]
  return { 
    currentBucket,
    price: Math.floor(Number(currentBucket?.flat_amount ) / 100),
  }
}
