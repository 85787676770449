import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import {
  Breadcrumb,
  Divider,
  Form,
  Result,
  Typography
} from 'antd'
import { Dayjs } from 'dayjs'
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'

import { useManualQuery } from 'graphql-hooks'
import Api from '@vacationtracker/shared/services/api'
import { getAllUsersSlim, getImportPageValidationData, getConsumedLeaveRequestsForPeriod } from '../../graphql/custom-queries'

import * as logger from '../../services/logger'
import { IGetImportPageValidationData, IGetUsersSlim, IUserSlim } from '../../types/users'
import ImportForm from '../../components/import-leaves-form'
import CircularProgress from '../../components/circular-progress'
import { track } from '../../services/analytics/analytics'
import { useAppSelector } from '../../store/hooks'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { ImportFormat, ImportType } from './types'
import { CSV_TEMPLATE_URL, EXCEL_TEMPLATE_URL } from '../../constants'
import { IUserLeaves } from '@vacationtracker/shared/types/leave-request'
import { IGetConsumedLeaveRequestsForPeriod } from '../../types/custom-queries'
import { FrontendUrls } from '../../types/urls'

const { Paragraph, Text } = Typography

const filters = {
  status: 'ACTIVE',
  limit: 2000,
  nextToken: '',
}

export const ImportPage: React.FC = () => {
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  
  if (!authCompany) {
    throw new Error('Company not found!')
  }

  const [form] = Form.useForm()
  const [importType, setImportType] = useState(ImportType.leaves)
  const [isUploading, setIsUploading] = useState(false)
  const [spreadSheetOpen, setSpreadSheetOpen] = useState(false)
  const [companyUsers, setCompanyUsers] = useState([] as IUserSlim[])
  const [importPageValidationData, setImportPageValidationData] = useState<IGetImportPageValidationData>()
  const [showSuccess, setShowSuccess] = useState(false)
  const [showFail, setShowFail] = useState<string | null>(null)
  const [selectedFormat, setSelectedFormat] = useState(ImportFormat.csv)

  const [getConsumedLeaveRequestsForPeriodQuery ] = useManualQuery<IGetConsumedLeaveRequestsForPeriod, {
    dateStart: string
    dateEnd: string
  }>(getConsumedLeaveRequestsForPeriod)
  const [getAllUsersSlimQuery] = useManualQuery<IGetUsersSlim, {
    status: string
    limit: number
    nextToken: string
  }>(getAllUsersSlim)

  async function loadLeaveRequests(dateStart: Dayjs, dateEnd: Dayjs): Promise<IUserLeaves[]> {
    const response = await getConsumedLeaveRequestsForPeriodQuery({ variables: {
      dateStart: dateStart.subtract(30, 'day').toISOString(),
      dateEnd: dateEnd.add(30, 'day').toISOString(),
    }})
    return response.data?.getConsumedLeaveRequestsForPeriod as IUserLeaves[]
  }

  const defaultValue = {
    type: 'importLeaves',
    importFormat: 'csv',
  }

  const handleChangeImportType = (value: ImportType) => {
    setImportType(value)
    track('IMPORT_TYPE_SELECTED', {
      companyId: authCompany.id,
      importType: value,
    })
  }

  const handleChangeImportFormat = (value: ImportFormat) => {
    setSelectedFormat(value)
  }

  //open close modal
  const onHideModal = () => {
    setSpreadSheetOpen(false)
  }

  const fetchUserList = async () => {
    try {
      let response = await getAllUsersSlimQuery({ variables: filters})
      if (!response.data || response.error) throw response.error
      const users = response.data.getUsersFiltered.users
      const nextToken = response.data.getUsersFiltered.nextToken
      while (nextToken) {
        filters.nextToken = nextToken
        response = await getAllUsersSlimQuery({ variables: filters })
        const newUsers = response.data?.getUsersFiltered?.users || []
        users.push(...newUsers)
      }
      setCompanyUsers(users)
      
    } catch (error) {
      logger.error(error)
    }
  }

  const onUpload = async (data: any): Promise<void> => {
    setIsUploading(true)
    track('IMPORT_STARTED', {
      companyId: authCompany.id,
      importType: importType,
    })

    try {
      await Api.post('/core/event', {
        eventType: 'IMPORT_LEAVES',
        eventGroup: 'BULK_ACTIONS',
        leaves: data.validData,
      })
      setIsUploading(false)
      setShowSuccess(true)
      track('IMPORT_SUCCESSFUL', {
        companyId: authCompany.id,
        importType: importType,
      })
    } catch (error) {
      logger.error(error, error.response.data)
      setIsUploading(false)
      setShowFail(error.response.data && error.response.data.message ? error.response.data.message : 'Something went wrong')
      track('IMPORT_FAILED', {
        companyId: authCompany.id,
        importType: importType,
      })
    } finally {
      setIsUploading(false)
    }

  }

  const [ getImportPageValidationDataQuery ] = useManualQuery<IGetImportPageValidationData>(getImportPageValidationData)

  const fetchImportPageValidationData = async () => {
    const data = await getImportPageValidationDataQuery()
    if (data.data) {
      setImportPageValidationData(data.data)
    }
  }

  const getDownloadTemplateUrl = (format: string) => {
    const url = format === 'csv' ? CSV_TEMPLATE_URL : EXCEL_TEMPLATE_URL
    return url
  }

  useEffect(() => {
    fetchUserList()
    fetchImportPageValidationData()
  }, [])

  return (
    <div className='main-content import-page'>
      <div className='main-content-header'>
        <div className='main-content-header-title'>
          <span>
            <IntlMessages id='app.import' />
            &nbsp;
          </span>
        </div>
        <div className='main-content-header-breadcrumb'>
          <Breadcrumb
            items={[
              {
                title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
              },
              {
                title: <IntlMessages id='app.import' />,
              },
            ]}
          />
        </div>
      </div>
      <div className='main-content-body'>
        {!isUploading && !showSuccess && !showFail &&
          <ImportForm
            spreadSheetOpen={spreadSheetOpen}
            setSpreadSheetOpen={setSpreadSheetOpen}
            onHideModal={onHideModal}
            defaultValue={defaultValue}
            form={form}
            handleChangeImportType={handleChangeImportType}
            selectedFormat={selectedFormat}
            changeFormat={handleChangeImportFormat}
            companyUsers={companyUsers}
            validationData={importPageValidationData as IGetImportPageValidationData}
            onUpload={onUpload}
            loadLeaveRequests={loadLeaveRequests}
            getDownloadTemplateUrl={getDownloadTemplateUrl}
          />
        }
        {isUploading ? <CircularProgress /> : null}
        {showSuccess ? success() : null}
        {showFail ? failed(showFail) : null}
      </div>
      <Divider />
      <Text style={{display: 'block', marginBottom: '20px'}}>
        <IntlMessages id="app.importLeaves.helpdesk" values={{
          helpdesk: (...chunks) => (
            <a href="https://vacationtracker.crisp.help/en/article/how-can-i-import-my-leave-data-into-vacation-tracker-leave-data-import-179gahr/?bust=1703238871628" 
              target="_blank" rel="noopener noreferrer">
              {chunks}
            </a>
          ),
        }} />
      </Text>
    </div>
  )
}

const success = () => <Result
  icon={<CheckCircleOutlined />}
  subTitle={<Text type="secondary"><IntlMessages id='importLeaves.importSuccess' /></Text>}
/>

const failed = (errorMessage: string) => <Result
  icon={<CloseCircleOutlined style={{ color: 'red' }}/>}
  subTitle={<>
    <Paragraph>
      <IntlMessages id='importLeaves.importFail' />
    </Paragraph>
    <Paragraph code>
      {errorMessage}
    </Paragraph>
  </>}
/>

export default ImportPage
