import React from 'react'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { Form, Button, Input } from 'antd'

import IntlMessages from '../../util/IntlMessages'

const formItemLayout = (wrapperSpanSize = 6) => {
  return {
    labelCol: { xs:{span: 24 }, md:{span: 6} },
    wrapperCol: { span: wrapperSpanSize },
  }
}

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    span: 10,
    xs:{offset: 0}, md:{offset: 6},
  },
}

interface IDynamicField {
  name: string
  labelText: string
  addNewText: string
  type: string
  rules: object[]
  maxLength?: number
  required: boolean
  wrapperSpanSize?: number
}

const DynamicField = ({ name, labelText, addNewText, type, rules, maxLength, required = false, wrapperSpanSize = 6 }: IDynamicField): React.ReactElement => {
  return (
    <Form.List name={name}>
      {(fields, { add, remove }, { errors }) => (
        <>
          {fields.map((field, index) => (
            <Form.Item
              {...(index === 0 ? formItemLayout(wrapperSpanSize) : formItemLayoutWithOutLabel)}
              label={index === 0 ? <IntlMessages id={labelText} /> : ''}
              required={required}
              className="dynamic-field"
              key={field.key}
            >
              <Form.Item
                name={[index, 'name']}
                validateTrigger={['onChange', 'onBlur']}
                rules={rules}
                noStyle
              >
                <Input type={type} style={{ width: 'calc(100% - 40px)' }} />
              </Form.Item>
              {fields.length > 1 ? (
                <MinusCircleOutlined
                  style={{ paddingLeft: 5 }}
                  onClick={() => remove(field.name)}
                />
              ) : null}
            </Form.Item>
          ))}
          <Form.Item {...formItemLayoutWithOutLabel}>
            {(maxLength ? fields.length < maxLength : true) &&
              <Button
                type="dashed"
                onClick={() => add()}
                icon={<PlusOutlined />}
              >
                <IntlMessages id={addNewText} />
              </Button>
            }
            <Form.ErrorList errors={errors} />
          </Form.Item>
        </>
      )}
    </Form.List>
  )
}

export default DynamicField
