/* eslint-disable react/no-unescaped-entities, max-len */
import React from 'react'
import IntlMessages from '../../util/IntlMessages'

export const userSteps = [
  {
    target: 'body',
    placement: 'center' as const,
    isFixed: true,
    title: <IntlMessages id='components.onboarding.joyride.admin.start.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.user.start1' />
        </p>
        <p>
          <IntlMessages
            id='components.onboarding.joyride.admin.start2'
            values={{
              link: (...chunks) => (
                <a href='mailto:hello@vacationtracker.io'>{chunks}</a>
              ),
            }}
          />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-dashboard-menu',
    placement: 'center' as const,
    isFixed: true,
    title: <IntlMessages id='app.dashboard' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.user.dashboard.info1' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-request-leave-user',
    placement: 'auto' as const,
    title: <IntlMessages id='app.requestLeave' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.user.requestLeave.info1' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-my-profile-menu',
    placement: 'auto' as const,
    title: <IntlMessages id='app.myProfile' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.user.myProfile.info1' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-calendar-menu',
    placement: 'auto' as const,
    title: <IntlMessages id='app.calendar' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.user.calendar.info1' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-holidays-menu-user',
    placement: 'auto' as const,
    title: <IntlMessages id='app.holidays' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.user.holidays.info1' />
        </p>
      </>
    ),
  },
  {
    target: 'body',
    placement: 'center' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.helpDesk.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.helpDesk.info' />
        </p>
      </>
    ),
  },
]
