import React, { useEffect, useState } from 'react'
import { SeoTags } from '../../components/seo-tags'
import { App, Button, Col, Row, Skeleton, Typography } from 'antd'
import { SlackOutlined } from '@ant-design/icons'
import VtLogo from '../../assets/images/logo-purple.svg'
import IntlMessages from '../../util/IntlMessages'
import { getUserId as getSlackUserId } from '../../services/api/slack'
import qs, { ParsedQs } from 'qs'
import { SlackAuth } from '../../services/auth/slack/slack'
import { track } from '../../services/analytics/analytics'
import { prepareUtmTags } from '../../util/get-and-parse-cookie'
import { SLACK_APPSTORE_INSTALL_BUTTON_UTM_TAGS } from '@vacationtracker/shared/data/utm-tags'
import { useHistory } from 'react-router'
import { confirmSigninWithCustomChallengeAnswer, IAuthNextStep, signin } from '@vacationtracker/shared/services/auth'
import { updateSlackToken } from '../../services/api/slack'
import { FrontendUrls } from '../../types/urls'
import { getCompanyAndUser } from '../../services/api/companies'
import { useAppDispatch } from '../../store/hooks'
import { setUserId } from '../../store/user-id-slice'
import { setAuthState } from '../../store/auth-state-slice'
import { SLACK_REQUIRED_BOT_SCOPES, SLACK_REQUIRED_USER_SCOPES } from '@vacationtracker/shared/data/slack'
import { useIntl } from 'react-intl'
import { ISlackTokenFromCodeResponse } from '@vacationtracker/shared/types/slack'
import OtherPlatformLoginModal from '../../components/other-platform-login-modal'
import { IContactAdminData } from '../../types/auth'
import CompanyExistsModal from '../../components/modal-company-exists'
import { useManualQuery } from 'graphql-hooks'
import { IGetCompanyAndUserInfo } from '../../types/custom-queries'
import { getCompanyAndUserInfo } from '../../graphql/custom-queries'
import { AuthStateEnum } from '@vacationtracker/shared/types/auth-state'

const { Paragraph, Title } = Typography
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)

const SlackPostInstallation = (): React.ReactElement => {

  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { notification } = App.useApp()
  const [userName, setUserName] = useState<string | null>(null)
  const [userEmail, setUserEmail] = useState<string | null>(null)
  const [slackCode, setSlackCode] = useState<string | null>(null)
  const [signupVariant, setSignupVariant] = useState<'old' | 'new'>('new')
  const [slackTeamId, setSlackTeamId] = useState<string | null>(null)
  const [slackTeamName, setSlackTeamName] = useState<string | null>(null)
  const [userToken, setUserToken] = useState<string | null>(null)
  const [slackUserData, setSlackUserData] = useState<ISlackTokenFromCodeResponse | null>(null)
  const [botToken, setBotToken] = useState<string | null>(null)
  const [userSlackId, setUserSlackId] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [loading, setLoadingStatus] = useState(true)
  const [otherPlatformLogin, setOtherPlatformLogin] = useState(false)
  const [existsOnAnotherPlatform, setExistsOnAnotherPlatform] = useState('')
  const [existingOrganizationName, setExistingOrganizationName] = useState('')
  const [contactAdminModal, setContactAdminModal] = useState<IContactAdminData | null>(null)
  const [companyExists, setCompanyExists] = useState(false)
  const [queryParams] = useState<ParsedQs>(qs.parse(location.search, { ignoreQueryPrefix: true }))

  const [getCompanyAndUserInfoQuery] = useManualQuery<IGetCompanyAndUserInfo, { userId: string }>(getCompanyAndUserInfo)

  const resolveSlackCode = async (code: string) => {
    setError(null)
    const signupUtmTags = SLACK_APPSTORE_INSTALL_BUTTON_UTM_TAGS.split('&')
    let utmTags = ''
    if (signupUtmTags.every((tag) => location.search.includes(tag))) {
      utmTags = `?${SLACK_APPSTORE_INSTALL_BUTTON_UTM_TAGS}`
    }
    try {
      const redirectUrl = signupVariant === 'old' ? `${window.location.origin}/slackredirect/index.html` : `${window.location.origin}/slack/post-installation${utmTags}`
      const tokenResponse = await slackAuth.getTokenFromCode(code as string, redirectUrl)

      setSlackUserData(tokenResponse)

      const userIdFromCode = tokenResponse.authed_user.id
      const userTokenFromCode = tokenResponse.authed_user.access_token
      const botTokenFromCode = tokenResponse.access_token
      const teamId = tokenResponse.team.id
      const teamName = tokenResponse.team.name

      setUserSlackId(userIdFromCode)
      setUserToken(userTokenFromCode)
      setBotToken(botTokenFromCode)
      setSlackTeamId(teamId)
      setSlackTeamName(teamName)

      slackAuth.setBotToken(botTokenFromCode)
      slackAuth.setUserToken(userTokenFromCode)

      const user = await slackAuth.getUser(userIdFromCode, botTokenFromCode)
      
      if (typeof user.profile.email === 'string') {
        setUserEmail(user.profile.email as string)
      }

      setUserName(user.real_name as string || user.name as string)
      setLoadingStatus(false)
    } catch (error) {
      console.log('error', error)
      setError(typeof error === 'string' ? error : error.message as string)
      setLoadingStatus(false)
    }
  }

  const installSlackApp = async () => {
    setError(null)
    setLoadingStatus(true)
    try {
      const slackInstallResponse = await slackAuth.signin(
        SLACK_REQUIRED_BOT_SCOPES,
        SLACK_REQUIRED_USER_SCOPES,
        slackTeamId
      )

      setSlackUserData(slackInstallResponse)

      setUserSlackId(slackInstallResponse.authed_user.id)
      setUserToken(slackInstallResponse.authed_user.access_token)
      setBotToken(slackInstallResponse.access_token)
      setSlackTeamId(slackInstallResponse.team.id)

      const user = await slackAuth.getUser(slackInstallResponse.authed_user.id, slackInstallResponse.access_token)

      setUserName(user.real_name as string || user.name as string)
      setLoadingStatus(false)
    } catch (error) {
      console.log('error', error)
      setError(typeof error === 'string' ? error : error.message as string)
      setLoadingStatus(false)
    }
  }

  const connectWithSlack = async (slackId: string, token: string, botToken: string) => {
    try {
      setLoadingStatus(true)
      await slackAuth.connect(slackTeamId)
      const userData = await getSlackUserId(slackId, token)

      if (!userEmail && userData.mail) {
        setUserEmail(userData.mail)
      }

      const email = userEmail || userData.mail
      
      if (userData.type === 'COMPANY_NOT_FOUND') {
        localStorage.setItem('vtCreateUser', JSON.stringify({
          name: userData.name,
          id: `slack-${userData.id}`,
          mail: email,
          userEmail: email,
          platform: 'slack',
          slackBotToken: botToken,
          slackUserToken: token,
          slackUserId: slackId,
          teamName: slackUserData?.team.name,
          slackTeamId: slackUserData?.team.id,
          slackOrgTeamId: slackUserData?.enterprise?.id,
          slackBotId: slackUserData?.bot_user_id,
          imageUrl: userData?.slackUser?.imageUrl,
          isOwner: userData?.slackUser?.isOwner,
          isAdmin: userData?.slackUser?.isAdmin,
          timezone: userData?.slackUser?.timezone,
        }))

        if (userData.existsOnAnotherPlatform && userData.existingOrganizationName) {
          track('ORG_EXISTS_ON_ANOTHER_PLATFORM_OPEN_MODAL', { platform: 'slack', existingOrganizationName: userData.existingOrganizationName })
          setExistsOnAnotherPlatform(userData.existsOnAnotherPlatform)
          setExistingOrganizationName(userData.existingOrganizationName)
          setOtherPlatformLogin(true)
          return
        }

        setLoadingStatus(false)
        dispatch(setAuthState(AuthStateEnum.signUp))
        history.push(FrontendUrls.createCompanyStep1)
        return
      }

      if(userData.type === 'USER_NOT_FOUND' && userData.subscriptionStatus === 'canceled') {
        notification.error({
          message: formatMessage({ id: 'connect.subscriptionExpiredTitle' }),
          description: formatMessage({ id: 'error.subscriptionExpired' }),
          duration: 0,
        })
        return
      }

      if(userData.type === 'USER_NOT_FOUND') {
        setContactAdminModal({
          platform: 'slack',
          organizationName: userData.organizationName || '',
          contactEmail: userData.contactEmail || '',
          adminContacts: userData.adminContacts || [],
        })
        track('ORG_ALREADY_EXISTS_PAGE_VIEWED', {
          platform: 'slack',
          organizationName: userData.organizationName,
        })
        setCompanyExists(true)
        setLoadingStatus(false)
        return
      }

      // TODO:@filip remove prefix
      const signInResponse = await signin(`slack-${userData.vacationTrackerId}`) as IAuthNextStep
      if (signInResponse.challengeName === 'CUSTOM_CHALLENGE' && signInResponse.challengeParam?.question === 'token') {
        const cognitoResponse = await confirmSigninWithCustomChallengeAnswer(token, { loginType: 'slack' })
        localStorage.setItem('userId', cognitoResponse.username)

        try {
          await updateSlackToken(token, botToken)
        } catch (error) {
          if(error?.response?.data?.error && (error?.response.data.error === 'error.botTokenNotExist' || error?.response.data.error === 'error.botTokenNotValid')) {
            setLoadingStatus(false)
            setError('bot_token_not_valid')
            return
          }
        }
        dispatch(setUserId(cognitoResponse.username))
        await getCompanyAndUser(cognitoResponse.username, getCompanyAndUserInfoQuery, dispatch, history)
        dispatch(setAuthState(AuthStateEnum.signedIn))
        history.push(FrontendUrls.dashboard)
      }
    } catch (error) {
      console.log('error', error)
      setLoadingStatus(false)
      setError(typeof error === 'string' ? error : error.message as string)
    }
  }

  const connectWithPlatform = (platform: string) => {
    history.push(FrontendUrls.signin + `?platform=${platform}`)
  }

  const closeOtherPlatformLoginModal = () => {
    track('ORG_EXISTS_ON_ANOTHER_PLATFORM_CLOSE_MODAL', {})
    history.push(FrontendUrls.signup)
  }

  useEffect(() => {
    if (slackCode !== null) {
      resolveSlackCode(slackCode)
    }
  }, [slackCode])

  useEffect(() => {
    track('SIGNUP_STARTED', {
      platform: 'slack',
      timestamp: new Date().toDateString(),
      source: 'app',
      status: 'started',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      utmCookie: prepareUtmTags(queryParams as { [key: string]: string }) as any,
    })
    if (!queryParams || !queryParams.code || typeof queryParams.state !== 'string' || !queryParams.state?.includes('slack-')) {
      setError('no_code')
      setLoadingStatus(false)
      return
    }
    setSlackCode(queryParams.code as string)
  }, [queryParams])

  return (
    <div className="auth-wrap">
      <SeoTags
        title='connect.meta.title.signup'
        description='connect.meta.description'
      />
      <div className="auth-container">
        <Row align="middle" justify="space-around" gutter={[{ xs: 16, lg: 48 },{ xs: 16, lg: 48 }]}>
          <Col span={24}>
            <div className="auth-sidebar-logo">
              <VtLogo />
            </div>
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 24 }}>
            {
              error === null ? (
                <Skeleton loading={loading} paragraph={{ rows: 2, width: 320 }} title>
                  <Title level={3} style={{ textAlign: 'center' }}>{
                    <IntlMessages id='connect.welcome' values={{
                      name: userName,
                    }} />
                  }</Title>
                  <Paragraph style={{ textAlign: 'center' }}>
                    <IntlMessages id='connect.slackPostInstallation1' />
                  </Paragraph>
                  <Paragraph style={{ textAlign: 'center' }}>
                    <IntlMessages id='connect.slackPostInstallation2' />
                  </Paragraph>
                  <Paragraph style={{ textAlign: 'center' }}>
                    <IntlMessages id='connect.slackPostInstallation3' />
                  </Paragraph>
                </Skeleton>
              ) : (
                <>
                  <Title level={3} style={{ textAlign: 'center' }}>
                    Install Vacation Tracker bot
                  </Title>
                  {
                    error === 'invalid_code' ? (
                      <Paragraph style={{ textAlign: 'center' }}>
                        <IntlMessages id='connect.slackPostInstallationError.invalidCode' />
                      </Paragraph>
                    ) : (
                      <Paragraph style={{ textAlign: 'center' }}>
                        <IntlMessages id='connect.slackPostInstallationError.generic' />
                      </Paragraph>
                    )
                  }
                  <Paragraph style={{ textAlign: 'center' }}>
                    Click the button below to install the Vacation Tracker bot to your Slack workspace.
                  </Paragraph>
                </>
              )
            }
          </Col>
          <Col xs={{ span: 20 }} lg={{ span: 12 }}>
            { loading ? (
              <Skeleton.Button />
            ) : (
              (error === null && userSlackId && userToken && botToken) ? (
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                <Button icon={<SlackOutlined />} size="large" className="vt-auth" type="primary" block onClick={
                  () => connectWithSlack(userSlackId, userToken, botToken)
                }>
                  <IntlMessages id="connect.signInWithSlack" />
                </Button>
              ) : (
                <Button icon={<SlackOutlined />} size="large" className="vt-auth" type="primary" block onClick={() => installSlackApp()}>
                  <IntlMessages id="connect.signInWithSlackInstallBot" />
                </Button>
              )
            ) }
          </Col>
        </Row>
      </div>
      {companyExists && contactAdminModal && userEmail &&
        <CompanyExistsModal
          companyExists={companyExists}
          setCompanyExists={setCompanyExists}
          contactAdminModal={contactAdminModal}
          userEmail={userEmail}
          userWorkspaceName={slackTeamName ?? undefined}
        />
      }
      {
        otherPlatformLogin &&
          <OtherPlatformLoginModal
            otherPlatformLogin={otherPlatformLogin}
            connectWithPlatform={connectWithPlatform}
            closeOtherPlatformLoginModal={closeOtherPlatformLoginModal}
            existingOrganizationName={existingOrganizationName}
            existsOnAnotherPlatform={existsOnAnotherPlatform}
            selectedPlatform={'slack'}
          />
      }
    </div>
  )
}

export default SlackPostInstallation
