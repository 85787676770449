import React, { createContext, useReducer } from 'react'

import { reducer, initialState } from './reducer'
import { actions } from './actions'

const notificationStore = createContext(initialState)
const { Provider } = notificationStore

interface INotificationsProvider {
  children: React.ReactNode
}

const NotificationsProvider = ({ children }: INotificationsProvider) => {

  const [state, dispatch] = useReducer(reducer, initialState)
  const value = {
    ...state,
    ...actions(dispatch),
  }

  return <Provider value={value}>{children}</Provider>
}

export { notificationStore, NotificationsProvider }