import React from 'react'
import {  Divider, FormInstance, Typography } from 'antd'
import { isEqual, isNull, isNumber } from 'lodash'

import capitalizeString from '@vacationtracker/shared/functions/capitalize-string'

import { NoOfUsersWarrningMessage } from '../../routes/Users/ImportUsersPage/components'

import { WelcomeMessageOptions } from '../../routes/Users/ImportUsersPage/types'
import { ICompanyInfo } from '@vacationtracker/shared/types/company'
import { ISelectedLeaveType, IShowConfirmCompanySettingsAndImportUsers } from './types'
import { BillingTypesEnum } from '@vacationtracker/shared/types/billing'
import { ModalFuncWithPromise } from 'antd/es/modal/useModal'

const { Paragraph } = Typography


const getShouldProrateMessage = ({
  form,
  authCompany,
  selectedLeaveTypes,
  formatMessage,
}: {
  authCompany: ICompanyInfo | null
  form: FormInstance
  selectedLeaveTypes: ISelectedLeaveType[]
  formatMessage: Function
}): string => {
  const {importUsersAutomatically, shouldProrate} = form.getFieldsValue(true)
  if (importUsersAutomatically && shouldProrate) {
    if (authCompany?.shouldProrate !== shouldProrate
    || !isEqual(authCompany?.proratedLeaveTypes, selectedLeaveTypes.map(lt => lt.id))) {
      return formatMessage(
        { id: 'components.importUsersForm.confirmImportUsersModalContentProrateInfo' },
        { leaveTypes:  selectedLeaveTypes
          .map(lt => lt.name)
          .join(', ') }
      )
    }
  }

  if (importUsersAutomatically && authCompany?.shouldProrate && !form.getFieldValue('shouldProrate')) {
    return formatMessage({ id: 'components.importUsersForm.confirmImportUsersModalContentProrateInfoTurnOff' })
  }

  if (!importUsersAutomatically && form.getFieldValue('shouldProrate')) {
    return formatMessage(
      { id: 'components.importUsersForm.confirmImportUsersModalContentProrateInfo' },
      { leaveTypes:  selectedLeaveTypes
        .map(lt => lt.name)
        .join(', ') }
    )
  }

  return ''
}

const getWelcomeMessage = ({
  form,
  authCompany,
  formatMessage,
  welcomeMessageOpts,
}: {
  form: FormInstance
  authCompany: ICompanyInfo | null
  formatMessage: Function
  welcomeMessageOpts: WelcomeMessageOptions[]
}) => {
  if (form.getFieldValue('sendWelcomeMessageTo').platform === 'none') {
    return formatMessage({id: 'components.importUsersForm.confirmImportUsersModalContentWelcomeMessageTurnOff'})
  } else if (authCompany?.announceNewUsers &&
    form.getFieldValue('sendWelcomeMessageTo').platform === authCompany?.platform &&
    form.getFieldValue('formName') !== 'configureImportUsersForm') {
    return ''
  } else {
    return formatMessage(
      { id: 'components.importUsersForm.confirmImportUsersModalContentWelcomeMessage' },
      { channel:  welcomeMessageOpts.find(op => op.platform === authCompany?.platform)?.channel }
    )
  }
}

const showConfirmCompanySettingsAndImportUsers = ({
  authCompany,
  form,
  title,
  okText,
  onConfirm,
  formatMessage,
  selectedLocation,
  selectedDepartment,
  welcomeMessageOpts,
  selectedLeaveTypes,
  showInfoFor,
  noOfUsersToImport,
  totalNumberOfUsersAfterImport,
  onCancel,
  upcomingInvoiceInfo,
  isLoadingUpcomingInvoiceInfo,
  showAdditionalBillingInfo,
  locale,
  contactEmail,
}: IShowConfirmCompanySettingsAndImportUsers, confirm: ModalFuncWithPromise): void => {
  const {
    showNewNameInfo,
    showImportUsersAutomaticallyInfo,
    showAiBotHelperInfo,
    showUserEndDateInfo,
    showHourlyLeaveAccountingInfo,
    shownNOfUsersToImportInfo,
    showAlwaysDefaultDepartmentAndLocation,
    showMoreInfo,
    showSlackMultichannelUsersImport,
    showCalculationStartYearInfo,
    showWelcomeMessageToInfo,
    showCompanyEmail,
  } = showInfoFor
  const noOfUsersToImportInfo = formatMessage(
    { id: 'components.importUsersForm.confirmImportUsersModalContentNoOfUsers' },
    { noOfUsers:  noOfUsersToImport }
  )
  let defaultDepartmentAndLocation
  if (authCompany?.platform !== 'email'
    && form.getFieldValue('importUsersAutomatically')
    && (showAlwaysDefaultDepartmentAndLocation
    || authCompany?.defaultDepartmentForAutomaticImport !== selectedDepartment?.id
    || authCompany?.defaultLocationForAutomaticImport !== selectedLocation?.id)) {
    defaultDepartmentAndLocation = formatMessage(
      { id: 'components.importUsersForm.confirmImportUsersModalContentDepartmentAndLocationInfo' },
      {
        noOfUsers: noOfUsersToImport,
        chosenDepartment: selectedDepartment?.name,
        chosenLocation: selectedLocation?.name,
      })
  }
  const overSeatsLimit = authCompany?.billing?.billingType === BillingTypesEnum.seatBased && Number(totalNumberOfUsersAfterImport) > Number(authCompany.billing?.seats)
  const calculationYear = form.getFieldValue('calculationStartYear') && form.getFieldValue('calculationStartYear').format('YYYY')
  const sendWelcomeMessageTo = authCompany?.platform !== 'email' && getWelcomeMessage({form, authCompany, formatMessage, welcomeMessageOpts})
  const shouldProrate = getShouldProrateMessage({form, authCompany, selectedLeaveTypes, formatMessage})
  const newName = authCompany?.name !== form.getFieldValue('name')
    ? formatMessage({ id: 'components.importUsersForm.newCompanyNameInfo' }, { newName: form.getFieldValue('name') })
    : undefined
  const importUsersAutomatically = authCompany?.importUsersAutomatically !== form.getFieldValue('importUsersAutomatically')
    ? form.getFieldValue('importUsersAutomatically')
      ? formatMessage({ id: 'general.importUsersAutomaticallyInfo' }, { platform: capitalizeString(authCompany?.platform as string) })
      : formatMessage({ id: 'general.importUsersAutomaticallyTurnOffInfo' }, { platform: capitalizeString(authCompany?.platform as string) })
    : undefined
  const aiBotHelper = Boolean(authCompany?.aiBotHelper) !== Boolean(form.getFieldValue('aiBotHelper'))
    ? form.getFieldValue('aiBotHelper')
      ? formatMessage({ id: 'components.generalSettings.aiBotHelperLabel' } )
      : formatMessage({ id: 'components.generalSettings.aiBotHelperLabelDisable' } )
    : undefined
  const userEndDate = authCompany?.userEndDate !== form.getFieldValue('userEndDate')
    ? form.getFieldValue('userEndDate')
      ? formatMessage({ id: 'components.importUsersForm.userEndDateInfo' })
      : formatMessage({ id: 'components.importUsersForm.userEndDateTurnOffInfo' })
    : undefined
  const hourlyLeaveAccounting = authCompany?.hourlyLeaveAccounting !== form.getFieldValue('hourlyLeaveAccounting')
    ? form.getFieldValue('hourlyLeaveAccounting')
      ? formatMessage({ id: 'components.importUsersForm.trackingQuotaInfoHours' })
      : formatMessage({ id: 'components.importUsersForm.trackingQuotaInfoDays' })
    : undefined
  const moreInfo = formatMessage({ id: 'components.importUsersForm.confirmImportUsersModalContentChangeLaterInfo' })
  const slackMultichannelUsersImportInfo = showSlackMultichannelUsersImport && authCompany?.shouldImportGuestUsers !== form.getFieldValue('shouldImportGuestUsers')
    ? form.getFieldValue('shouldImportGuestUsers')
      ? formatMessage({ id: 'components.importUsersForm.confirmImportSlackMultichannelUsersInfoOn' })
      : formatMessage({ id: 'components.importUsersForm.confirmImportSlackMultichannelUsersInfoOff' })
    : undefined
  const calculationStartYearInfo = showCalculationStartYearInfo && !isNull(authCompany?.calculationStartYear) && authCompany?.calculationStartYear !== calculationYear
    ? formatMessage({ id: 'components.importUsersForm.calculationStartYearInfo' }, { year: calculationYear })
    : undefined

  const companyEmailInfo = formatMessage({ id: 'components.companySettings.contactEmailInfo' }, { email: contactEmail })

  confirm({
    onCancel,
    title,
    icon: null,
    content: <>
      <Divider />
      <ul>
        {showNewNameInfo && newName && <li>
          <Paragraph>
            {newName}
          </Paragraph>
        </li>}

        {showCompanyEmail && <li>
          <Paragraph>{companyEmailInfo}</Paragraph>
        </li>}

        {showImportUsersAutomaticallyInfo && importUsersAutomatically && <li>
          <Paragraph>
            {importUsersAutomatically}
          </Paragraph>
        </li>}

        {showAiBotHelperInfo && aiBotHelper && <li>
          <Paragraph>
            {aiBotHelper}
          </Paragraph>
        </li>}

        {showUserEndDateInfo && userEndDate && <li>
          <Paragraph>
            {userEndDate}
          </Paragraph>
        </li>}

        {showHourlyLeaveAccountingInfo && hourlyLeaveAccounting && <li>
          <Paragraph>
            {hourlyLeaveAccounting}
          </Paragraph>
        </li>}

        {calculationStartYearInfo && <li>
          <Paragraph>
            {calculationStartYearInfo}
          </Paragraph>
        </li>}

        {shownNOfUsersToImportInfo && isNumber(noOfUsersToImport) &&  noOfUsersToImport > 0 && <li>
          <Paragraph>
            {noOfUsersToImportInfo}
          </Paragraph>
        </li>}

        {showWelcomeMessageToInfo && sendWelcomeMessageTo && <li>
          <Paragraph>{sendWelcomeMessageTo}</Paragraph>
        </li>}

        {slackMultichannelUsersImportInfo && <li>
          <Paragraph>{slackMultichannelUsersImportInfo}</Paragraph>
        </li>}

        {defaultDepartmentAndLocation && <li>
          {defaultDepartmentAndLocation}
        </li>}

        {shouldProrate && <li>
          <Paragraph>{shouldProrate}</Paragraph>
        </li>}

      </ul>
      {showMoreInfo && <Paragraph>{moreInfo}</Paragraph>}
      {isNumber(noOfUsersToImport) && noOfUsersToImport <= 0
        ? <></>
        : <NoOfUsersWarrningMessage
          noOfUsers={noOfUsersToImport as number}
          totalNumberOfUsersAfterImport={totalNumberOfUsersAfterImport as number}
          formatMessage={formatMessage}
          upcomingInvoiceInfo={upcomingInvoiceInfo}
          isLoadingUpcomingInvoiceInfo={isLoadingUpcomingInvoiceInfo}
          isAnnualBilling={authCompany?.billing?.subscriptionPeriod === 'annual'}
          showAdditionalBillingInfo={showAdditionalBillingInfo}
          locale={locale}
          overSeatsLimit={overSeatsLimit}
          seatsLimit={authCompany?.billing?.seats}
          isTrial={authCompany?.subscriptionStatus === 'trialing' && !authCompany?.billing?.paymentMethodAdded}
          stripeCurrentPeriodEnd={authCompany?.billing?.stripeCurrentPeriodEnd as string}
        />}
    </>,
    width: 560,
    okType: 'primary',
    okText,
    cancelText: formatMessage({ id: 'app.cancel' }),
    onOk() {
      onConfirm()
    },
  })
}

export default showConfirmCompanySettingsAndImportUsers