import React, { useState } from 'react'
import { Modal, Form, Input, Button } from 'antd'
import {
  FaRegGrinHearts,
  FaRegSmile,
  FaRegMeh,
  FaRegMehRollingEyes,
  FaRegSadCry
} from 'react-icons/fa'
import { IconContext } from 'react-icons'

import IntlMessages from '../utils/IntlMessages'
import { useIntl } from 'react-intl'


const allRatingOptions = [{
  rating: '5',
  intlId: 'components.feedbackModal.iLoveIt',
  iconComponent: <FaRegGrinHearts />,
}, {
  rating: '4',
  intlId: 'components.feedbackModal.iLikeIt',
  iconComponent: <FaRegSmile />,
}, {
  rating: '3',
  intlId: 'components.feedbackModal.itsOk',
  iconComponent: <FaRegMeh />,
}, {
  rating: '2',
  intlId: 'components.feedbackModal.Nah',
  iconComponent: <FaRegMehRollingEyes />,
}, {
  rating: '1',
  intlId: 'components.feedbackModal.ItMakesMeCry',
  iconComponent: <FaRegSadCry />,
},
]
interface IFeedbackModalProps {
  visible: boolean
  onCancel: () => void
  onSave: (d) => Promise<void>
  emailRequired?: boolean
}

const IconWrapper = ({component}) => <IconContext.Provider value={{ size: '2em' }}>{component}</IconContext.Provider>

const Feedback = ({
  rating,
  handleOnRatingClick,
}) => {
  return (
    <div style={{display: 'flex', justifyContent: 'space-evenly' }}>
      {allRatingOptions.map(ratingOption => 
        <div style={{ alignItems: 'center', display: 'flex', flexDirection: 'column', width: '70px' }} key={ratingOption.intlId}>
          <Button
            className='no-border-button'
            onClick={() => handleOnRatingClick(ratingOption.rating)}
            style={{ border: 'none', boxShadow: 'none', color: rating === ratingOption.rating ? '#7f00ff' : '' }}
            size='large' icon={<IconWrapper component={ratingOption.iconComponent} />}
          />
          <div style={{ flexWrap: 'nowrap', textAlign: 'center', color: rating === ratingOption.rating ? '#7f00ff' : '' }}> <IntlMessages id={ratingOption.intlId} /></div>
        </div>
      )}
    </div>
  )
}

export const FeedbackModal: React.FC<IFeedbackModalProps> = ({
  visible,
  onCancel,
  onSave,
  emailRequired = false,
}: IFeedbackModalProps) => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const [isSending, setSending] = useState<boolean>(false)
  const [rating, setRating] = useState<string | undefined>()

  const handleOnRatingClick = (v: string) => {
    form.setFieldsValue({ rating: v })
    setRating(v)
  }

  const resetFields = () => {
    form.resetFields()
    setRating(undefined)
  }

  const sendFeedback = async () => {
    try {
      setSending(true)
      const values = await form.validateFields()
      const body = {
        rating: values.rating,
        email: values.email,
        message: values.message,
      }
      await onSave(body)
      resetFields()
      setSending(false)
    } catch(err) {
      // eslint-disable-next-line no-console
      console.error(err)
      setSending(false)
    }
  }

  return (
    <Modal
      title={<IntlMessages id="containers.topbar.giveUsFeedback" />}
      open={visible}
      onCancel={() => {
        resetFields()
        onCancel()
      }}
      onOk={sendFeedback}
      okText={<IntlMessages id="components.feedbackModal.sendFeedback" />}
      cancelText={<IntlMessages id="app.close" />}
      confirmLoading={isSending}
    >
      <Form
        key="feedbackModal"
        form={form}
        name="feedbackModal"
        layout='vertical'
      >
        <Form.Item
          key="rating"
          name="rating"
          rules={[{ required: true, message: (<IntlMessages id="components.feedbackModal.ratingRequired" />) }]}
        >
          <Feedback rating={rating} handleOnRatingClick={handleOnRatingClick} />
        </Form.Item>

        <Form.Item
          name="email"
          label={<IntlMessages id="components.feedbackModal.yourEmail" />}
          rules={[{ required: emailRequired, message: <IntlMessages id="error.fieldIsRequired" /> }]}
          extra={emailRequired ? '' : <IntlMessages id="components.feedbackModal.yourEmailInfo" />}
        >
          <Input type="email" placeholder={formatMessage({id: 'components.feedbackModal.emailPlaceholder'})} />
        </Form.Item>

        <Form.Item
          name="message"
          label={<IntlMessages id="components.feedbackModal.feedbackMessage" />}
        >
          <Input.TextArea rows={4} placeholder={formatMessage({id: 'components.feedbackModal.messagePlaceholder'})} />
        </Form.Item>
      </Form>
    </Modal>
  )
}