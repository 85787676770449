import React, { useState, useEffect } from 'react'
import { Spin, Result, Button } from 'antd'
import { Typography } from 'antd'
import { History } from 'history'
import { useIntl } from 'react-intl'
import { GoogleAuth } from '../../services/auth/google/google'
import VtLogo from '../../assets/images/logo-purple.svg'
import { wait } from '@vacationtracker/shared/functions/wait'
import * as Sentry from '@sentry/react'
import axios from 'axios'

const { Paragraph } = Typography
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID)

interface IData {
  history: History
  location: {
    pathname: string
    search: string
    hash: string
  }
}

const GooglePermissions = ({ history, location }: IData): React.ReactElement => {
  
  const { formatMessage } = useIntl()

  const [permissionsInProgress, setPermissionsInProgress] = useState(false)
  const [sucess, setSuccess] = useState<boolean|undefined>(undefined)

  useEffect(() => {
    checkCode() 
  }, [])

  const checkCode = () => {
    const hash = location.hash
    if (hash.length !== 129) { // 128(hash size) + 1 for the # symbol
      setSuccess(false)
      goToHomePage(0)
    }
  }

  const goToHomePage = (timeout: number) => {
    setTimeout(() => {
      history.push('/app/dashboard')
    }, timeout)
  }

  const askForGooglePermissions = () => {
    sessionStorage.clear()
    setPermissionsInProgress(true)
    googleAuth.signin(true).then(async() => {
      await wait(500)
      const refreshToken = sessionStorage.getItem('googleRefreshToken')
      if (refreshToken) {
        try {
          await axios.post(`${process.env.REACT_APP_API_URL}/google/admin-token`, { 
            token: refreshToken,
            hash: location.hash.substring(1),
          })
          setSuccess(true)
          setPermissionsInProgress(false)
          goToHomePage(5000)
        } catch (e) {
          setSuccess(false)
          setPermissionsInProgress(false)
          goToHomePage(10000)
          Sentry.captureException(e)
        }
      }
      else {
        Sentry.captureMessage('No refresh token found in session storage')
        setSuccess(false)
        setPermissionsInProgress(false)
        goToHomePage(10000)
      }
    })
  }

  return (
    <div className="main-content">
      <div className="auth-container" style={{ width: 380 }}>
        <br />
        <div className="auth-sidebar-logo"><VtLogo /></div>
        {sucess === undefined && <Spin spinning={permissionsInProgress}>
          <div className="auth-sidebar-content-button" style={{ height: 200 }}>
            <Paragraph>{formatMessage({ id: 'connect.google.sharedPermissionsConsent' })}</Paragraph>
            <Button onClick={() => askForGooglePermissions()} type="primary" key="console">{formatMessage({ id: 'general.googleConsentInfo' })}</Button>
          </div>
        </Spin>}
        {sucess !== undefined && <Result
          status={sucess ? 'success' : 'error'}
          title={formatMessage({ id: sucess ? 'connect.google.sharedPermissionsSuccess' : 'connect.google.sharedPermissionsError' })}
          subTitle={formatMessage({ id: 'connect.google.sharedPermissionsSuccessRedirect' })}
          extra={[
            <Button onClick={() => {goToHomePage(0)}} type="primary" key="console">{formatMessage({ id: 'app.home' })}</Button>,
          ]}
        />}
      </div>
    </div>
    
  )
}

export default GooglePermissions
