import axios from 'axios'

import { MicrosoftBillingResolvedToken, MicrosoftBillingResolvedResponse } from '@vacationtracker/shared/types/microsoft-billing'

const url = `${process.env.REACT_APP_API_URL}/microsoft-billing`

export const handlePurchaseToken = async (token: string): Promise<MicrosoftBillingResolvedResponse> => {
  const response = await axios.post(`${url}/resolve-token`, {
    token,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}

export const cancelSubscription = async (token: string): Promise<MicrosoftBillingResolvedToken> => {
  const response = await axios.delete(`${url}/resolve-token`, {
    headers: {
      'Content-Type': 'application/json',
    },
    data: {
      token,
    },
  })

  return response.data
}
