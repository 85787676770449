import antdFr from 'antd/locale/fr_FR'
import enMessages from '@vacationtracker/shared/i18n/fr-fr.json'

const FrLang = {
  messages: {
    ...enMessages,
  },
  antd: antdFr,
  locale: 'fr-FR',
}
export default FrLang
