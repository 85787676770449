/* eslint-disable @typescript-eslint/no-empty-function */
import { TAB_SIZE } from '@vacationtracker/shared/constants/sizes'
import React, { createContext, useState, useEffect } from 'react'

interface IMenuSetting {
  navCollapsed: boolean
  changeSelectedKeys: boolean
  miniMenu: boolean
  collapseNavbar: boolean
  setCollapseNavbar: (value: boolean) => void
  setNavCollapsed: Function
  setMiniMenu: Function
  setChangeSelectedKeys: Function
}

interface IMenuProvider {
  children: React.ReactNode
}

const menuSetting: IMenuSetting = {
  navCollapsed: false,
  changeSelectedKeys: false,
  miniMenu: localStorage.getItem('miniMenu') === 'true' ? true : false,
  collapseNavbar: window.innerWidth < TAB_SIZE,
  // Placeholder functions
  setCollapseNavbar: () => {},
  setNavCollapsed: () => {},
  setMiniMenu: () => {},
  setChangeSelectedKeys: () => {},
}

export const MenuContext = createContext(menuSetting)


// This context provider is passed to any component requiring the context
export const MenuProvider = ({ children }: IMenuProvider) => {
  const [navCollapsed, setNavCollapsed] = useState(menuSetting.navCollapsed)
  const [miniMenu, setMiniMenu] = useState(menuSetting.miniMenu)
  const [collapseNavbar, setCollapseNavbar] = useState(menuSetting.collapseNavbar)
  const [changeSelectedKeys, setChangeSelectedKeys] = useState(menuSetting.changeSelectedKeys)

  useEffect(() => {
    localStorage.setItem('miniMenu', `${miniMenu}`)
  }, [miniMenu])

  const value = {
    navCollapsed,
    setNavCollapsed,
    miniMenu,
    setMiniMenu,
    collapseNavbar,
    setCollapseNavbar,
    changeSelectedKeys,
    setChangeSelectedKeys,
  }

  return (
    <MenuContext.Provider value={value}>
      {children}
    </MenuContext.Provider>
  )
}
