import React from 'react'
import { Alert, Button, Col, Row, Skeleton, Space, Typography } from 'antd'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { format } from 'date-fns'

import { useAppSelector } from '../../../store/hooks'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { track } from '../../../services/analytics/analytics'
import { getDateFnsLocale } from '@vacationtracker/shared/i18n'

import IntlMessages from '../../../util/IntlMessages'

import { IImportUsersNavigationProps, ImportUsersStepEnum } from './types'
import { IUpcomingInvoiceInformation } from '@vacationtracker/shared/types/stripe'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { BillingTypesEnum } from '@vacationtracker/shared/types/billing'
import { UpdateSeats } from '../../../components/update-seats'


const { Paragraph } = Typography

interface INoOfUsersWarrningMessage {
  noOfUsers: number
  totalNumberOfUsersAfterImport: number
  upcomingInvoiceInfo: IUpcomingInvoiceInformation | null
  isLoadingUpcomingInvoiceInfo: boolean
  isAnnualBilling: boolean
  formatMessage: Function
  locale: LocaleEnum
  overSeatsLimit: boolean
  seatsLimit: number | undefined
  isTrial: boolean
  stripeCurrentPeriodEnd: string
  importAllUsers?: boolean
  showAdditionalBillingInfo?: boolean
  submitBilling?: (seats: number) => void
  numberOfActiveUsers?: number
}

export const NoOfUsersWarrningMessage = ({
  noOfUsers,
  formatMessage,
  upcomingInvoiceInfo,
  isLoadingUpcomingInvoiceInfo,
  isAnnualBilling,
  showAdditionalBillingInfo = false,
  locale,
  overSeatsLimit,
  seatsLimit,
  submitBilling,
  importAllUsers,
  numberOfActiveUsers,
  isTrial,
  stripeCurrentPeriodEnd,
}: INoOfUsersWarrningMessage): React.ReactElement  => {
  const OverSeatsLimit = () => {
    return <Alert
      message={<>
        {formatMessage({
          id: importAllUsers ? 'components.importUsersForm.warningOfUsersImportOverSeatsLimitImportAll' : 'components.importUsersForm.warningOfUsersImportOverSeatsLimit',
        }, { seats: seatsLimit })}
        <UpdateSeats
          numberOfSeats={seatsLimit}
          numberOfActiveUsers={numberOfActiveUsers}
          onSubmit={(seats) => submitBilling && submitBilling(seats)}
          isAnnualBilling={isAnnualBilling}
          locale={locale}
          inputLayout={{
            xl: { span: 6 },
            lg: { span: 10 },
            md: { span: 10 },
            sm: { span: 24 },
            xs: { span: 24 },
          }}
        />
      </>
      }
      type='warning'
    />
  }

  const NoBillingChanges = () => {
    return <Alert
      message={
        formatMessage({ id: 'components.importUsersForm.warningOfUsersImportNoChange' }, {
          noOfUsers,
        })
      }
      type='success'
      showIcon
    />
  }

  if (overSeatsLimit) {
    return <OverSeatsLimit />
  }

  if (isLoadingUpcomingInvoiceInfo) {
    return <Skeleton.Button
      size="large"
      shape="square"
      style={{ width: '20vw'}}
      active
    />
  }

  if (noOfUsers === 0) {
    return <></>
  }

  if (upcomingInvoiceInfo) {
    if (upcomingInvoiceInfo.isTrial) {
      return <Alert
        message={
          formatMessage({ id: 'components.importUsersForm.warningOfUsersImportTrial' }, {
            amount: `$${upcomingInvoiceInfo?.nextInvoiceAmount}`,
            startDate: format(new Date(upcomingInvoiceInfo?.nextInvoiceDate as string), 'PPP', {locale: getDateFnsLocale(locale)}),
          })
        }
        type={noOfUsers > 50 ? 'warning' : 'info'}
        showIcon
      />
    } else if (upcomingInvoiceInfo.isSameInvoiceAmount) {
      return <NoBillingChanges />
    } else if (isAnnualBilling) {
      return <Alert
        message={<>
          {showAdditionalBillingInfo && <Paragraph>
            {formatMessage({ id: 'components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNewBucket' }, {
              amount: `$${upcomingInvoiceInfo?.nextInvoiceAmount}`,
              noOfUsers,
              startDate: format(new Date(upcomingInvoiceInfo?.nextInvoiceDate as string), 'PPP', {locale: getDateFnsLocale(locale)}),
            })}
          </Paragraph>}
          <div>
            {formatMessage({ id: 'components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNoOfUsers' }, {
              proratedAmount: `$${upcomingInvoiceInfo?.prorateAmount}`,
            })}
          </div>
        </>
        }
        type='info'
        showIcon
      />
    } else {
      return <Alert
        message={
          formatMessage({ id: 'components.importUsersForm.warningOfUsersImportProrateAdditionalNewBucketMonthly' }, {
            noOfUsers,
            amount: `$${upcomingInvoiceInfo?.nextInvoiceAmount}`,
            startDate: format(new Date(upcomingInvoiceInfo?.nextInvoiceDate as string), 'PPP', {locale: getDateFnsLocale(locale)}),
          })
        }
        type={noOfUsers > 50 ? 'warning' : 'info'}
        showIcon
      />
    }

  }

  if (isTrial && stripeCurrentPeriodEnd) {
    return <Alert
      message={formatMessage(
        { id: 'components.importUsersForm.warningOfUsersImportTrialWithoutPaymentMethod' },
        { stripeCurrentPeriodEnd: format(new Date(Number(stripeCurrentPeriodEnd) * 1000), 'MMMM do yyyy')})
      }
      type="info"
      showIcon
      style={{ marginBottom: '20px' }}
    />
  }

  return <></>
}


export const ImportUsersNavigation = ({
  platform,
  importAllUsers,
  noOfUsersToImport,
  submitInProgress,
  activeStep,
  handleNext,
  handleBack,
  backButtonTitle,
  nextButtonTitle,
  upcomingInvoiceInfo,
  isLoadingUpcomingInvoiceInfo,
  isAnnualBilling,
  showAdditionalBillingInfo,
  totalNumberOfUsersAfterImport,
  locale,
  numberOfActiveUsers,
  submitBilling,
  isTrial,
  stripeCurrentPeriodEnd,
}: IImportUsersNavigationProps) : React.ReactElement=> {
  const { formatMessage } = useIntl()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const backButton = backButtonTitle || formatMessage({ id: 'app.back' })
  const overSeatsLimit = authCompany?.billing?.billingType === BillingTypesEnum.seatBased && totalNumberOfUsersAfterImport > Number(authCompany.billing?.seats)
  const shouldDisableNext = () => {
    if (overSeatsLimit) {
      return true
    }
    if (importAllUsers || submitInProgress) {
      return false
    }

    return noOfUsersToImport === 0
  }

  return <Row justify="space-between" align="middle" gutter={[0, 16]}>
    <Col>
      <Space>
        <NoOfUsersWarrningMessage
          noOfUsers={noOfUsersToImport}
          totalNumberOfUsersAfterImport={totalNumberOfUsersAfterImport}
          formatMessage={formatMessage}
          upcomingInvoiceInfo={upcomingInvoiceInfo}
          isLoadingUpcomingInvoiceInfo={isLoadingUpcomingInvoiceInfo}
          isAnnualBilling={isAnnualBilling}
          locale={locale}
          showAdditionalBillingInfo={showAdditionalBillingInfo}
          overSeatsLimit={overSeatsLimit}
          seatsLimit={authCompany?.billing?.seats}
          importAllUsers={importAllUsers}
          numberOfActiveUsers={numberOfActiveUsers}
          submitBilling={submitBilling}
          isTrial={isTrial}
          stripeCurrentPeriodEnd={stripeCurrentPeriodEnd}
        />
      </Space>
    </Col>
    <Col>
      <Space direction="horizontal">
        {activeStep === ImportUsersStepEnum.selectUsers &&
          <Button
            htmlType="button"
            disabled={submitInProgress}
          >
            <Link to='/app/users' onClick={() => { track('IMPORT_USERS_CANCEL', { platform })}}>
              <IntlMessages id="importUsers.form.backButton" />
            </Link>
          </Button>
        }
        {(activeStep === ImportUsersStepEnum.configure || activeStep === ImportUsersStepEnum.confirm) &&
          <Button
            disabled={submitInProgress || !importAllUsers && noOfUsersToImport === 0}
            onClick={() => handleBack && handleBack()}>
            {backButton}
          </Button>
        }
        <Button
          type="primary"
          loading={submitInProgress}
          disabled={shouldDisableNext()}
          onClick={() => handleNext()}>
          {nextButtonTitle}
        </Button>
      </Space>
    </Col>
  </Row>
}
