import React, { useEffect, useRef } from 'react'
import { Typography } from 'antd'
import { ReactTyped } from 'react-typed'
import { format } from 'date-fns'

import { ConversationRole } from '@vacationtracker/shared/types/conversation'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'

interface IAIConversationMessageProps {
  item: {
    timestamp: string
    role: ConversationRole
    content: string
    typing?: boolean
  }
  authUser: {
    imageUrl: string
    name: string
  }
  setWaitingForTypingToFinish: (value: boolean) => void
  userScrolled: boolean
  scrollToBottom: () => void
}

const { Paragraph, Text } = Typography

const AIConversationMessage: React.FC<IAIConversationMessageProps> = ({
  item,
  authUser,
  scrollToBottom,
  userScrolled,
  setWaitingForTypingToFinish,
}) => {
  const containerRef: React.MutableRefObject<HTMLDivElement | null> = useRef(null)

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      handleScrollToBottom()
    })

    if (containerRef.current) {
      observer.observe(containerRef.current)
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current)
      }
    }
  }, [userScrolled])

  const handleScrollToBottom = () => {
    if (!userScrolled) {
      scrollToBottom && scrollToBottom()
    }
  }

  const handleOnComplete = () => {
    setWaitingForTypingToFinish(false)
    handleScrollToBottom()
  }

  return (
    <div className='ai-assistant-chat-conversation-message-wrapper' ref={containerRef}>
      <div className={`ai-assistant-chat-conversation-message ai-assistant-chat-conversation-message-${item.role}`}>
        {item.role === ConversationRole.user && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <UserAvatar id="user-avatar" avatar={authUser.imageUrl} name={authUser.name} avatarSize={30} shape="circle" className="ai-assistant-chat-conversation-message-avatar" />
            <Text strong className="ai-assistant-chat-conversation-message-username">{authUser.name}</Text>
            <Text type="secondary" className="ai-assistant-chat-conversation-message-timestamp">
              {format(new Date(item.timestamp as string), 'MMMM d, HH:mm')}
            </Text>
          </div>
        )}
        <div className='ai-assistant-chat-conversation-message-content'>
          { item.typing ? (
            <ReactTyped
              strings={[item.content]}
              typeSpeed={25}
              showCursor={false}
              onComplete={handleOnComplete}
            />
          ) : (
            <Paragraph>{item.content}</Paragraph>
          ) }
        </div>
      </div>
      <br />
    </div>
  )
}

export default AIConversationMessage