import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft' 
import { IMicrosoftUser } from '../../types/microsoft'
import * as logger from '../../services/logger'

export const fetchMicrosoftUsers = async (
  msAuth: MicrosoftAuth,
  token: string,
  onSuccess: (users: IMicrosoftUser[]) => void,
  onError: (error: Error) => void
): Promise<void> => {
  try {
    const users = await msAuth.getUsers(token)
    console.log('users', users)
    onSuccess(users)
  } catch (error: any) {
    console.log('Error object:', error)
    
    let errorCode = ''
    let errorMessage = ''

    if (error.response && error.response.data) {
      errorCode = error.response.data.error.code
      errorMessage = error.response.data.error.message
    } else {
      errorCode = error.code || 'UnknownError'
      errorMessage = error.message || 'An unknown error occurred'
    }

    logger.error('Error code:', errorCode)
    logger.error('Error message:', errorMessage)

    if (errorCode === 'InvalidAuthenticationToken') {
      logger.info('Token expired, attempting to sign in again')
      try {
        await msAuth.signin(['user.readbasic.all', 'team.readbasic.all'], true)
        logger.info('Successfully signed in again')
        await fetchMicrosoftUsers(msAuth, token, onSuccess, onError)
      } catch (signinError) {
        logger.error('Error signing in:', signinError)
        onError(signinError as Error)
      }
    } else {
      logger.error(`Unexpected error: ${errorCode} - ${errorMessage}`)
      onError(new Error(`${errorCode} - ${errorMessage}`))
    }
  }
}