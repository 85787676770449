import antdPl from 'antd/locale/pl_PL'
import plMessages from '@vacationtracker/shared/i18n/pl-pl.json'

const PlLang = {
  messages: {
    ...plMessages,
  },
  antd: antdPl,
  locale: 'pl-PL',
}
export default PlLang
