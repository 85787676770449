import { filterXSS } from 'xss'
import sanitizeHtml from 'sanitize-html'

export function preventXss(value: string, options = {}): string {
  const excelFormulaRegex = /^([=+\-@])(.*)$/gi // Formula starts with +, -, = or @
  const filtered = filterXSS(value, {
    ...options,
    whiteList: {},
    stripIgnoreTag: true,
    stripIgnoreTagBody: ['script'],
  })
  if (excelFormulaRegex.test(filtered)) {
    return filtered.replace(excelFormulaRegex, '\'$1$2\'')
  }
  return filtered
}

export function sanitizeInput(value: string) {
  const config = {
    allowedTags: ['&'],
    allowedAttributes: {},
    allowedSchemesByTag: {},
    allowedSchemesAppliedToAttributes: [],
    allowProtocolRelative: false,
    enforceHtmlBoundary: false,
    parseStyleAttributes: false,
    filterXSS: true,
  }

  const sanitized = sanitizeHtml(replaceEmoticonsWithEmojis(value), config)
  return sanitized
    .replace(/^([=+-@<>])(.*)$/, '\\$1$2') // Prepend a space if the string starts with =, +, -, or @
    .replace(/:/g, '\\$&')
    .replace(/[";]/g, '')   // Remove double quotes dots and semicolons
    .replace(/\s+/g, ' ')   // Replace multiple spaces with a single space
    .replace(/&amp/g, '&') // allow the & character
    .trim()                 // Trim leading and trailing spaces
}

function replaceEmoticonsWithEmojis(inputString: string): string {
  const map: { [key: string]: string } = {
    '<3': '\u2764\uFE0F',
    '</3': '\uD83D\uDC94',
    ':D': '\uD83D\uDE00',
    ':)': '\uD83D\uDE03',
    ';)': '\uD83D\uDE09',
    ':(': '\uD83D\uDE12',
    ':p': '\uD83D\uDE1B',
    ';p': '\uD83D\uDE1C',
    ':\'(': '\uD83D\uDE22',
  }

  const escapeSpecialChars = (regex: string): string => {
    return regex.replace(/([()[{*+.$^\\|?])/g, '\\$1')
  }

  let resultString = inputString
  Object.keys(map).forEach(emoticon => {
    const regex = new RegExp(escapeSpecialChars(emoticon), 'gim')
    resultString = resultString.replace(regex, map[emoticon])
  })
  return resultString
}