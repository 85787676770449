interface IDays {
  value: number
  label: string
}

export function numberOfDaysInMonth(month: number): number {
  const helperDate = new Date()
  helperDate.setMonth(month)
  helperDate.setDate(0)
  return helperDate.getDate()
}


export const generateDaysForMonth = (numberOfDays: number) => {
  const days: IDays[] = []
  for (let i = 1; i <= numberOfDays; i++) {
    days.push({ value: i, label: `${i}` })
  }
  return days
}


