import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  IToilRequestCreatedEvent,
  IToilRequestDeniedEvent,
  IToilRequestExpiredEvent,
  IToilRequestDeletedEvent,
  IToilRequestAddedEvent,
  IToilRequestApprovedEvent
} from '@vacationtracker/shared/types/toil-request'
import { RootState } from './configure'

type ToilRequestActionEvent = IToilRequestCreatedEvent
| IToilRequestDeniedEvent
| IToilRequestExpiredEvent
| IToilRequestDeletedEvent
| IToilRequestAddedEvent
| IToilRequestApprovedEvent
| null

interface IInitialState {
  toilRequestActionEvent: ToilRequestActionEvent
}

const initialState: IInitialState = {
  toilRequestActionEvent: null,
}

export const toilRequestActionEventSlice = createSlice({
  name: 'toilRequestActionEvent',
  initialState,
  reducers: {
    setToilRequestActionEvent: (state, action: PayloadAction<ToilRequestActionEvent>) => {
      state.toilRequestActionEvent = action.payload
    },
  },
})

export const { setToilRequestActionEvent } = toilRequestActionEventSlice.actions

export const selectToilRequestActionEventSlice = (state: RootState) => state.toilRequestActionEventSlice

export default toilRequestActionEventSlice.reducer