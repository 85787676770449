import React from 'react'

interface IYoutubeEmbed {
  embedId: string
  title?: string
  width?: string
  height?: string
  autoplay?: boolean
  mute?: boolean
}

const YoutubeEmbed = ({ embedId, width, height, title='Video', autoplay=false, mute=false }: IYoutubeEmbed): React.ReactElement => (
  <div className="video-responsive">
    <iframe
      style={{ aspectRatio: '16 / 9', width: '100%' }}
      width={width ? width : 'auto'}
      height={height ? height : 'auto'}
      src={`https://www.youtube.com/embed/${embedId}${autoplay ? `?autoplay=1&mute=${mute}` : ''}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
      title={title}
    />
  </div>
)

export default YoutubeEmbed