import { IConversationInitialState } from './conversation'
import { ICoreEvent } from './core-event'

export interface IAIAssistedLeavePolicy {
  iso: string
  locationId: string
  leaveTypeId: string
  hasUnlimitedQuota: boolean
  quota: number
  leaveTypeName: string
}

export interface IBasicEntity {
  id: string
  name: string
  locationId?: string
  leaveTypeId?: string
  iso?: string
}

export interface IBasicEntitiesAIAssistentResponse {
  leavePolicies: IAIAssistedLeavePolicy[]
  locations?: IBasicEntity[]
  departments?: IBasicEntity[]
  leaveTypes?: IBasicEntity[]
}


export interface IAIAssistedResponse extends IBasicEntitiesAIAssistentResponse {
  isDone: boolean
  message: string
  error?: string
  initialState?: IConversationInitialState
  intent?: AIAssistedOnboardingIntent
  setup?: IBasicEntitiesAIAssistentResponse | null
}
export interface IAIAssistedJSONResultResponse<T> {
  result: T | null
  error?: string
}

export enum AIAssistedOnboardingIntent {
  confirmed = 'CONFIRMED',
  notConfirmed = 'NOT_CONFIRMED',
}

export enum AIAssistedOnboardingCoreEventStatusEnum {
  pending = 'PENDING',
  completed = 'COMPLETED',
  error = 'ERROR',
}

export interface IAIAssistedOnboardingSetupItem extends ICoreEvent {
  PK: string
  SK: string
  status:  AIAssistedOnboardingCoreEventStatusEnum
  setupJSON?: string
  name?: string
  locationId?: string
  leaveTypeId?: string
  id?: string
  teamId?: string
}
