import React from 'react'

import { useAppSelector } from '../../../store/hooks'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'


import StripeBillingPage from './stripe'
import MicrosoftBillingPage from './microsoft'


const BillingPage: React.FC = () => {
  const { authCompany } = useAppSelector(selectAuthCompanySlice)

  if (authCompany?.billing?.paymentProcessor === 'microsoft-billing') {
    return <MicrosoftBillingPage />
  } else {
    return <StripeBillingPage />
  }
}


export default BillingPage
