{
  "accountSettings.connectYourPersonalCalendar": "Połącz swój osobisty kalendarz",
  "accountSettings.connectYourPersonalCalendar.description": "Połącz swój osobisty kalendarz z urlopami automatycznie. Gdy wnioskujesz o urlop, możesz ustawić, czy chcesz, aby wnioskowany urlop był widoczny jako dostępny czy zajęty w twoim kalendarzu. Odwiedź nasze <helpdesk>Centrum pomocy</helpdesk>, aby uzyskać więcej informacji.",
  "actionNotifications.companyAnnouncement.success": "Ogłoszenie wysłane",
  "actionNotifications.leave.editedRequestDenied": "Edycja wniosku urlopowego odrzucona.",
  "actionNotifications.leave.editedRequestDeniedDescription": "Twój edytowany wniosek urlopowy został odrzucony, jednak oryginalny wniosek pozostaje aktywny.",
  "actionNotifications.leave.requestApproved": "Wniosek urlopowy został zatwierdzony",
  "actionNotifications.leave.requestDenied": "Wniosek urlopowy został odrzucony.",
  "actionNotifications.leaveType.created": "Typ urlopu {leaveTypeName} został utworzony",
  "actionNotifications.leaveType.deleted": "Typ urlopu został usunięty",
  "actionNotifications.leaveType.updated": "Typ urlopu {leaveTypeName} został zaktualizowany",
  "actionNotifications.location.createdTitle": "Lokalizacja {locationName} została utworzona",
  "actionNotifications.location.deletedTitle": "Lokalizacja została usunięta",
  "actionNotifications.location.updatedTitle": "Lokalizacja {locationName} została zaktualizowana",
  "actionNotifications.location.view": "Zobacz lokalizację",
  "actionNotifications.notification.created": "Powiadomienie zostało utworzone",
  "actionNotifications.notification.deleted": "Powiadomienie zostało usunięte",
  "actionNotifications.notification.updated": "Powiadomienie zostało zaktualizowane",
  "actionNotifications.outOfOfficeSetting.success": "Twoje ustawienia nieobecności zostały zaktualizowane",
  "actionNotifications.scheduledReport.createdTitle": "Zaplanowany raport {scheduledReportName} został utworzony",
  "actionNotifications.scheduledReport.deletedTitle": "Zaplanowany raport został usunięty",
  "actionNotifications.scheduledReport.updatedTitle": "Zaplanowany raport {scheduledReportName} został zaktualizowany",
  "actionNotifications.scheduledReport.viewScheduledReport": "Zobacz zaplanowany raport",
  "actionNotifications.team.createdTitle": "Dział {teamName} został utworzony",
  "actionNotifications.team.deletedTitle": "Dział został usunięty",
  "actionNotifications.team.updatedTitle": "Dział {teamName} został zaktualizowany",
  "actionNotifications.team.view": "Zobacz dział",
  "addLeave.addLeave": "Dodaj urlop",
  "addLeave.handleSubmitErrorTitle": "Błąd przesyłania urlopu",
  "addLeave.inProgress": "Dodawanie urlopu",
  "addLeave.noApproverUsers": "Jeśli nie jesteś częścią działu, nie będziesz mógł dodać urlopu dla siebie. Aby wnioskować o urlop, nawet jeśli nie jesteś częścią działu, dla którego masz uprawnienia do zatwierdzania, otwórz formularz \"Wniosek o urlop\" i złóż wniosek urlopowy.",
  "addLeave.title": "Urlop dodany",
  "addons.openApi.apiKeyName": "Nazwa klucza API",
  "addons.openApi.apiKeyNamePlaceholder": "Nazwa klucza API",
  "addons.openApi.apiKeySecret": "Sekret klucza API",
  "addons.openApi.apiKeysInfo": "Generuj i zarządzaj swoimi kluczami API.",
  "addons.openApi.apiKeysTitle": "Klucze API",
  "addons.openApi.buyAppiAddon": "Kup dodatek API",
  "addons.openApi.buyOpenApiInfoPrice": "Dodatek API jest płatnym dodatkiem, który kosztuje {price}$/miesiąc/aktywny użytkownik. Zakup zwiększy twój miesięczny rachunek o {totalPaid}$ dla {userCount, plural, =1 {twojego} other {twoich}} {userCount} {userCount, plural, =1 {aktywnego użytkownika} other {aktywnych użytkowników}} (podamy dokładną wycenę po kliknięciu przycisku \"Kup\").",
  "addons.openApi.buyOpenApiInfoPrice2line": "Po zakupie dodatku, ty lub każdy inny administrator w twojej organizacji może generować i zarządzać kluczem API.",
  "addons.openApi.buyOpenApiTitle": "Kup dodatek API, aby generować klucze API",
  "addons.openApi.createInProgress": "Tworzenie klucza API w toku",
  "addons.openApi.createInProgressCompleted": "Tworzenie klucza API zakończone",
  "addons.openApi.createNewApiKey": "Utwórz nowy klucz API",
  "addons.openApi.currentMonthUsage": "Wykorzystanie w bieżącym miesiącu",
  "addons.openApi.documentation": "Dokumentacja API",
  "addons.openApi.documentationInfo": "Dokumentacja API opisuje, jak działa nasze API i jakie metody są aktualnie dostępne.",
  "addons.openApi.dontHaveApiKeys": "Nie masz kluczy API, kliknij przycisk poniżej, aby utworzyć jeden",
  "addons.openApi.dpenDocumentation": "Otwórz dokumentację",
  "addons.openApi.feedback": "Jeśli masz uwagi lub potrzebujesz danych, których nasze API nie udostępnia, <link>kliknij tutaj, aby nas o tym poinformować</link>.",
  "addons.openApi.limitRequestPerMonth": "Możesz wysłać do 30000 zapytań miesięcznie",
  "addons.openApi.limitRequestPerSecound": "Zapytania są ograniczone do 10 na sekundę z trybem burstowym 50 zapytań/sekundę",
  "addons.openApi.notificationDeleteConfirm": "Potwierdź, że chcesz usunąć klucz API <strong>{name}</strong>.",
  "addons.openApi.notificationDeleteTitle": "Usuń klucz API",
  "addons.openApi.openApiDeleted": "Klucz Open API usunięty",
  "addons.openApi.quotasAndRateLimitsInfo": "Oto nasze limity i ograniczenia szybkości API dla każdego klucza API:",
  "addons.openApi.quotasAndRateLimitsTitle": "Limity i ograniczenia szybkości",
  "addons.openApiInfo": "Nasz dodatek API pozwala na programowe połączenie Vacation Tracker z innymi aplikacjami. Obecnie nasze API umożliwia dostęp tylko do odczytu do Twoich użytkowników, ich urlopów, lokalizacji, działów, typów urlopów i etykiet.",
  "addonsPaymentStatus.activateForFree": "Aktywuj za darmo",
  "addonsPaymentStatus.activeUntilBillingPeriodEnds": "Aktywne do końca okresu rozliczeniowego",
  "addonsPaymentStatus.canceled": "Anulowano",
  "addonsPaymentStatus.grantPeriod": "Okres próbny",
  "addonsPaymentStatus.paymentActive": "Płatność aktywna",
  "ai.assistant.completedConversationTooltip": "Ta rozmowa jest zakończona. Nie możesz wysyłać nowych wiadomości, ale możesz zobaczyć istniejącą konwersację.",
  "ai.assistant.defaultMessage": "W czym mogę Ci pomóc?",
  "ai.assistant.disclaimer": "Asystent AI jest w wersji Beta. Może popełniać błędy. W przypadku błędów lub niejasnych odpowiedzi, prosimy o <support>kontakt z naszym wsparciem</support>.",
  "ai.assistant.inputLockedTooltip": "To jest wczesna wersja naszego Asystenta AI. Obecnie pomaga tylko w procesie onboardingu. Wkrótce będziesz mógł rozpoczynać nowe rozmowy i rozmawiać z Asystentem AI Vacation Tracker. Dziękujemy za cierpliwość!",
  "ai.assistant.lastMessageTimestampTooltip": "Ostatnia wiadomość została wysłana: {date}",
  "ai.assisted.onboarding.companyLogUserNameDecorator": "(przez Asystenta AI)",
  "ai.assisted.onboarding.finalAssistantMessage": "Twój proces onboardingu jest zakończony. Vacation Tracker skonfiguruje teraz Twoje konto firmowe z tymi ustawieniami. Pamiętaj, że zawsze możesz później wprowadzić zmiany w swojej konfiguracji, jeśli będzie to potrzebne.",
  "ai.assisted.onboarding.initialAssistantMessage": "Podczas rejestracji już skonfigurowano dla Ciebie kilka rzeczy:\n\nLokalizacje ({locations}), \nDziały ({departments}), i \nTypy urlopów ({leaveTypes}).\n\nCzy wszyscy Twoi pracownicy pracują w tej lokalizacji?",
  "ai.assisted.onboarding.initialUserMessage": "Cześć! Jestem Twoim Asystentem AI. Jestem tu, aby pomóc Ci rozpocząć pracę z nowym kontem firmowym.\n\nTa konfiguracja AI jest oczywiście opcjonalna. Możesz ją pominąć i samodzielnie skonfigurować system. (Prawdopodobnie chcesz zacząć od sekcji \"Ustawienia\" w naszym menu). Jednak przeprowadzę Cię przez kilka podstawowych części Vacation Tracker i skonfiguruję wszystko dla Ciebie w ciągu kilku minut. Nie przechowujemy żadnych Twoich prywatnych danych (ale proszę nie udostępniaj mi żadnych poufnych informacji).",
  "ai.assisted.onboarding.noTopics": "Hej, wygląda na to, że właśnie znalazłeś pisankę! 🐣 Pracujemy nad czymś nowym, co powinno zaoszczędzić Ci dużo czasu, ale jeszcze nie jest gotowe. Więc proszę, zachowajmy to jako naszą małą tajemnicę. Dzięki!",
  "ai.assisted.onboarding.openSetupNotification": "Zobacz, co zostało zrobione do tej pory",
  "ai.assisted.onboarding.openSetupNotificationFetchingSetup": "Dziękuję za dostarczenie wszystkich niezbędnych informacji. Zaczynam teraz tworzyć Twoją konfigurację Vacation Tracker. Wkrótce zobaczysz aktualizacje na żywo...",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigned": "Typ urlopu {leaveTypeName} przypisany do {locationName}",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigning": "Przypisywanie typu urlopu {leaveTypeName} do {locationName}",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicySuccessAction": "{entityName} {entity} został(a) pomyślnie {action}",
  "ai.assisted.onboarding.openSetupNotificationNoData": "Nie można wyświetlić danych konfiguracji",
  "ai.assisted.onboarding.openSetupNotificationNoDataSubtitle": "Nie mogę teraz pokazać danych konfiguracji. Może to wynikać z krótkiej przerwy w połączeniu sieciowym lub dlatego, że system jeszcze nie zakończył przetwarzania danych. Proszę odświeżyć tę stronę lub sprawdzić ustawienia w sekcji Ustawienia w menu.",
  "ai.assisted.onboarding.openSetupNotificationTitle": "Status konfiguracji onboardingu",
  "ai.assisted.onboarding.sendMessageError": "Wygląda na to, że odpowiedź Asystenta AI zajmuje zbyt dużo czasu. Proszę odświeżyć stronę.",
  "ai.assisted.onboarding.summary.intro": "Wszystko jest już skonfigurowane. Możesz teraz otworzyć panel i zacząć korzystać z aplikacji.",
  "ai.assisted.onboarding.summary.introSummary": "Oto podsumowanie Twojej konfiguracji:",
  "ai.assisted.onboarding.summary.leavePoliciesInfo": "Polityki urlopowe to konfiguracje typów urlopów specyficzne dla danej lokalizacji. Aby zobaczyć utworzone polityki urlopowe, przejdź do strony lokalizacji, wybierz lokalizację, a następnie otwórz zakładkę polityk urlopowych.",
  "ai.assisted.onboarding.summary.view.departments": "Zobacz Działy",
  "ai.assisted.onboarding.summary.view.leaveTypes": "Zobacz Typy Urlopów",
  "ai.assisted.onboarding.summary.view.locations": "Zobacz Lokalizacje",
  "app.12h": "12h",
  "app.24h": "24h",
  "app.aboutMe": "O mnie:",
  "app.accountPreferences": "Zobacz Preferencje",
  "app.accountSettings": "Ustawienia Konta",
  "app.accruals": "Naliczenia",
  "app.actions": "Akcje",
  "app.activate": "Aktywuj",
  "app.active": "Aktywny",
  "app.add": "Dodaj",
  "app.addAvatar": "Dodaj Awatar",
  "app.addLeave": "Dodaj Urlop",
  "app.addNewEmail": "Dodaj Odbiorcę",
  "app.addons": "Dodatki",
  "app.addToGoogleCalendar": "Kalendarz Google",
  "app.addToMicrosoft365Calendar": "Kalendarz Microsoft 365",
  "app.AIAssistant": "Asystent AI",
  "app.all": "Wszystkie",
  "app.allDay": "Cały Dzień",
  "app.allowHalfDays": "Zezwól na Pół Dnia",
  "app.allowHourlyIncrements": "Zezwól na Przyrosty Godzinowe",
  "app.and": "i",
  "app.announcementsHaveBeenPaused": "Twoje ogłoszenia zostały wstrzymane na 2 miesiące.",
  "app.applyTo": "Zastosuj do",
  "app.approvalReason": "Powód zatwierdzenia",
  "app.approvalRequired": "Wymagane Zatwierdzenie",
  "app.approve": "Zatwierdź",
  "app.approved": "Zatwierdzony",
  "app.approveLeaveText": "Proszę potwierdzić, że chcesz zatwierdzić wniosek urlopowy.",
  "app.approveLeaveTitle": "Zatwierdź Wniosek Urlopowy",
  "app.april": "Kwiecień",
  "app.areYouSure": "Czy jesteś pewien?",
  "app.askMeLater": "Zapytaj mnie później",
  "app.assignLeavePolicy": "Przypisz politykę urlopową",
  "app.august": "Sierpień",
  "app.autoApproved": "Automatycznie zatwierdzony",
  "app.autoDeleted": "<Automatycznie usunięty>",
  "app.autoImport": "<Automatyczny import>",
  "app.automations": "Automatyzacje",
  "app.avatar": "Awatar",
  "app.back": "Wstecz",
  "app.backToMenu": "Powrót do menu",
  "app.bi-weekly": "Dwutygodniowy",
  "app.billing.periodAnnual": "Roczny",
  "app.billingStatus": "Status płatności",
  "app.billingStatus.ACTIVE": "Płatność aktywna",
  "app.billingStatus.CANCEL_AT_PERIOD_END": "Aktywny do końca okresu rozliczeniowego",
  "app.billingStatus.CANCELED": "Płatność anulowana",
  "app.billingStatus.GRANT_PERIOD": "Okres próbny",
  "app.broughtForward": "Przeniesiony",
  "app.by": "przez",
  "app.calendar": "Kalendarz",
  "app.cancel": "Anuluj",
  "app.cancelLeave": "Anuluj urlop",
  "app.cancelLeaveConfirmText": "Czy na pewno chcesz anulować ten zaplanowany urlop?",
  "app.cancelled": "Anulowany",
  "app.change": "Zmień",
  "app.changePassword": "Zmień hasło",
  "app.close": "Zamknij",
  "app.code": "Kod",
  "app.color": "Kolor",
  "app.comingSoon": "Wkrótce",
  "app.company": "Firma",
  "app.companyLogs": "Logi firmowe",
  "app.companyNotExist": "Ups! Wygląda na to, że nie ukończyłeś rejestracji w Vacation Tracker. Odwiedź: app.vacationtracker.io, aby dokończyć rejestrację.",
  "app.companyNotExist.description1": "Dodałeś już bota i zakładkę Vacation Tracker do swojego obszaru roboczego Microsoft Teams.",
  "app.companyNotExist.description2": "Przejdź na naszą stronę internetową, aby dokończyć rejestrację i skonfigurować ustawienia swojej organizacji.",
  "app.companyNotExist.getStarted": "Dokończ rejestrację",
  "app.completed": "Zakończony",
  "app.completeRegistration": "Dokończ rejestrację",
  "app.configuration.moreInfo": "Aby uzyskać więcej informacji, odwiedź nasz help desk: <link>https://vacationtracker.crisp.help/en/</link>",
  "app.configuration.option.overview": "Przegląd",
  "app.configuration.options.info": "Wybierz typ zakładki: ",
  "app.configuration.welcome": "Witaj w Vacation Tracker! Ta karta umożliwia łatwe składanie wniosków o urlop bez opuszczania Microsoft Teams, zarządzanie urlopami, sprawdzanie statusów urlopów, sprawdzanie kto jest poza biurem oraz przeglądanie nadchodzących świąt dla twojego zespołu.",
  "app.configure": "Konfiguruj",
  "app.confirm": "Potwierdź",
  "app.confirmNewPassword": "Potwierdź nowe hasło",
  "app.confirmPassword": "Potwierdź hasło",
  "app.consentRequired": "Wymagana zgoda administratora Microsoft Teams. Wygląda na to, że nie jesteś administratorem Twojej organizacji Microsoft Teams. Aby uzyskać dostęp do karty Vacation Tracker, potrzebujesz zgody administratora. Proszę wysłać poniższy link do administratora Twojej organizacji, aby uzyskać zgodę administratora: {link}",
  "app.consentRequired.title": "Wymagana zgoda administratora Microsoft Teams",
  "app.contactSupport": "Skontaktuj się z pomocą techniczną",
  "app.continue": "Kontynuuj",
  "app.copyright": "Prawa autorskie VacationTracker",
  "app.correlationIdError": "Wystąpił błąd. Prosimy o kontakt z naszym działem obsługi klienta pod adresem hello@vacationtracker.io, podając następujący identyfikator: {correlationId}",
  "app.country": "Kraj",
  "app.create": "Utwórz",
  "app.created": "Utworzono",
  "app.createdAt": "Utworzono",
  "app.createdBy": "Utworzone przez",
  "app.createdSuccessfully": "Wniosek złożony",
  "app.creating": "Tworzenie",
  "app.creditCard": "Karta kredytowa",
  "app.currentYear": "Bieżący rok",
  "app.customDays": "Niestandardowe dni",
  "app.customWorkHours": "Niestandardowe godziny pracy",
  "app.daily": "Codziennie",
  "app.dashboard": "Panel",
  "app.dateRange": "Data początkowa i końcowa",
  "app.day": "dzień",
  "app.days": "dni",
  "app.december": "Grudzień",
  "app.delete": "Usuń",
  "app.deleted": "Usunięto",
  "app.deleteFailed": "Usunięcie nie powiodło się",
  "app.deleteUser": "Usuń użytkownika",
  "app.deleting": "Usuwanie",
  "app.deliveryFailed": "Dostawa nie powiodła się",
  "app.denialReason": "Powód odmowy",
  "app.denied": "Odrzucono",
  "app.deny": "Odrzuć",
  "app.denyLeaveRequest": "Odrzuć wniosek urlopowy",
  "app.denyLeaveRequestText": "Potwierdź, że chcesz odrzucić wniosek urlopowy.",
  "app.denyWithReason": "Odrzuć z podaniem powodu",
  "app.department": "Dział",
  "app.departments": "Działy",
  "app.departmentsSub": "Poddziały",
  "app.deselectAll": "Odznacz wszystko",
  "app.displayDaysHoursTooltip.days": "To {value} dni",
  "app.displayDaysHoursTooltip.hours": "To {value} godzin",
  "app.documentation": "Dokumentacja",
  "app.done": "Gotowe",
  "app.dontSend": "Nie wysyłaj",
  "app.downgradeWithoutUnsubscribe": "Obniż plan bez rezygnacji z subskrypcji",
  "app.downgradeWithSubscribe": "Obniż plan i zrezygnuj z subskrypcji",
  "app.download": "Pobierz",
  "app.downloadProgressReport": "Tworzenie pliku, to może chwilę potrwać...",
  "app.downloadReady": "Twój plik jest gotowy. Kliknij tutaj, aby go pobrać.",
  "app.downloadTemplate": "Pobierz szablon",
  "app.edit": "Edytuj",
  "app.edited": "Edytowano",
  "app.editLeave": "Edytuj urlop",
  "app.email": "E-mail",
  "app.emailDeleted": "E-mail usunięty",
  "app.employeeEndDate": "Data zakończenia zatrudnienia",
  "app.enable": "Włącz",
  "app.enabled": "Włączone",
  "app.enterWorkEmail": "Wprowadź swój służbowy adres e-mail",
  "app.error": "Błąd",
  "app.every": "każdy",
  "app.expired": "Wygasło",
  "app.expires": "Wygasa",
  "app.export": "Eksportuj",
  "app.export.comingSoon": "Nasz zespół pracuje nad tą funkcją. Spodziewaj się jej wkrótce.",
  "app.feature.access": "Dostępne wyłącznie w planie Complete.",
  "app.february": "Luty",
  "app.firstAndLastName": "Imię i nazwisko",
  "app.friday": "Piątek",
  "app.friday.short": "Pt",
  "app.full-day": "Cały dzień",
  "app.full-name": "Pełne imię i nazwisko",
  "app.general": "Ogólne",
  "app.goodAfternoon": "Dzień dobry",
  "app.goodEvening": "Dobry wieczór",
  "app.goodMorning": "Dzień dobry",
  "app.gotIt": "Rozumiem",
  "app.half-day": "Pół dnia",
  "app.halfDay": "Pół dnia",
  "app.halfDayInParenthesis": " (Pół dnia)",
  "app.happyVacationing": "Udanego urlopu 🌴",
  "app.helpdesk": "Pomoc techniczna",
  "app.holidays": "Święta",
  "app.home": "Strona główna",
  "app.hour": "godzina",
  "app.hours": "godziny",
  "app.import": "Importuj",
  "app.importFile": "Importuj plik",
  "app.importFormat": "Format importu",
  "app.importLeaves": "Importuj urlopy",
  "app.importLeaves.alerts.confirmClose.bodyText": "Czy jesteś pewien? Twoje obecne informacje nie zostaną zapisane.",
  "app.importLeaves.alerts.confirmClose.cancelButtonTitle": "Anuluj",
  "app.importLeaves.alerts.confirmClose.exitButtonTitle": "Wyjdź",
  "app.importLeaves.alerts.confirmClose.headerTitle": "Wyjdź z procesu importu",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyText": "Nadal istnieją wiersze zawierające błędy. Wiersze z błędami zostaną zignorowane podczas przesyłania.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyTextSubmitForbidden": "Nadal istnieją wiersze zawierające błędy. Usuń nieprawidłowe wiersze lub popraw błędy i spróbuj ponownie.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.cancelButtonTitle": "Anuluj",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.finishButtonTitle": "Prześlij",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.headerTitle": "Wykryto błędy",
  "app.importLeaves.alerts.toast": "Błąd",
  "app.importLeaves.alerts.unmatchedRequiredFields.bodyText": "Istnieją wymagane kolumny, które nie są dopasowane lub są ignorowane. Musisz dopasować wszystkie wymagane kolumny, aby kontynuować.",
  "app.importLeaves.alerts.unmatchedRequiredFields.cancelButtonTitle": "Anuluj",
  "app.importLeaves.alerts.unmatchedRequiredFields.continueButtonTitle": "Kontynuuj",
  "app.importLeaves.alerts.unmatchedRequiredFields.headerTitle": "Nie wszystkie kolumny są dopasowane",
  "app.importLeaves.alerts.unmatchedRequiredFields.listTitle": "Niedopasowane wymagane kolumny:",
  "app.importLeaves.downloadTemplateFileTooltip": "Tutaj możesz pobrać nasz plik szablonu importu, który służy jako przykład wypełniania danych, które chcesz zaimportować. Tworząc plik importu, upewnij się, że zawiera on kolumnę nagłówka, aby system mógł poprawnie rozpoznać Twoje dane.<br></br><br></br>Sprawdź, czy Twoje dane pasują do szablonu, aby zapewnić pomyślny import. Aby uzyskać dodatkowe instrukcje, skontaktuj się z naszym zespołem wsparcia.",
  "app.importLeaves.dropzone.activeDropzoneTitle": "Upuść plik tutaj",
  "app.importLeaves.dropzone.buttonTitle": "Wybierz plik",
  "app.importLeaves.dropzone.errorToastDescription": "Prześlij plik .xlsx, .xls lub .csv",
  "app.importLeaves.dropzone.loadingTitle": "Przetwarzanie...",
  "app.importLeaves.dropzone.title": "Prześlij plik .xlsx, .xls lub .csv",
  "app.importLeaves.errors.dateFromAfterDateTo": "Data Od jest późniejsza niż Data Do",
  "app.importLeaves.errors.dateFromNotValid": "Data Od jest nieprawidłowa",
  "app.importLeaves.errors.dateFromRequired": "Data Od jest wymagana",
  "app.importLeaves.errors.dateToNotValid": "Data Do jest nieprawidłowa",
  "app.importLeaves.errors.dateToRequired": "Data Do jest wymagana",
  "app.importLeaves.errors.halfDay": "Urlop jest dłuższy niż jeden dzień",
  "app.importLeaves.errors.hourFromAfterHourTo": "Godzina Od jest późniejsza niż Godzina Do",
  "app.importLeaves.errors.hourFromNotValid": "Godzina Od jest nieprawidłowa, wprowadź wartość między 0 a 24",
  "app.importLeaves.errors.hourFromRequired": "Godzina Od jest wymagana",
  "app.importLeaves.errors.hourRange": "Wybrane godziny przekraczają długość dnia pracy tego użytkownika ({hours} godzin). Proszę dostosować wybór.",
  "app.importLeaves.errors.hourToNotValid": "Godzina Do jest nieprawidłowa, wprowadź wartość między 0 a 24",
  "app.importLeaves.errors.hourToRequired": "Godzina Do jest wymagana",
  "app.importLeaves.errors.leavePolicyError": "Polityka urlopowa pozwala tylko na pełne dni",
  "app.importLeaves.errors.leaveTypeNotFound": "Nie znaleziono typu urlopu",
  "app.importLeaves.errors.leaveTypeNotInUserLocation": "Typ urlopu nie jest dostępny w lokalizacji użytkownika",
  "app.importLeaves.helpdesk": "Aby uzyskać szczegółowe wskazówki dotyczące importowania plików, zapoznaj się z instrukcjami krok po kroku dostępnymi na <helpdesk>stronie pomocy technicznej</helpdesk>.",
  "app.importLeaves.importFormatTooltip": "Wybierz format importu, który odpowiada typowi danych i preferencjom.",
  "app.importLeaves.leaveStatusWarning": "Status urlopu zostanie ustawiony na \"Zatwierdzony\" dla wszystkich importowanych urlopów.",
  "app.importLeaves.manifestDescription": "(Będziesz mieć możliwość zmiany nazw lub usunięcia kolumn w następnych krokach)",
  "app.importLeaves.manifestTitle": "Dane, których oczekujemy:",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningDescription": "Kolumny nie mogą się powielać",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningTitle": "Inna kolumna została odznaczona",
  "app.importLeaves.matchColumnsStep.ignoredColumnText": "Kolumna zignorowana",
  "app.importLeaves.matchColumnsStep.matchDropdownTitle": "Dopasuj",
  "app.importLeaves.matchColumnsStep.nextButtonTitle": "Dalej",
  "app.importLeaves.matchColumnsStep.selectPlaceholder": "Wybierz kolumnę...",
  "app.importLeaves.matchColumnsStep.subSelectPlaceholder": "Wybierz...",
  "app.importLeaves.matchColumnsStep.templateTitle": "Stanie się",
  "app.importLeaves.matchColumnsStep.title": "Dopasuj Kolumny",
  "app.importLeaves.matchColumnsStep.unmatched": "Niedopasowane",
  "app.importLeaves.matchColumnsStep.userTableTitle": "Twoja tabela",
  "app.importLeaves.maxRecordsExceeded": "Zbyt wiele rekordów. Dozwolone jest maksymalnie {maxRecords}",
  "app.importLeaves.nextButtonTitle": "Dalej",
  "app.importLeaves.selectHeaderStep.title": "Wybierz wiersz nagłówka",
  "app.importLeaves.selectSheet.title": "Wybierz arkusz do użycia",
  "app.importLeaves.validationStep.discardButtonTitle": "Odrzuć wybrane wiersze",
  "app.importLeaves.validationStep.filterSwitchTitle": "Pokaż tylko wiersze z błędami",
  "app.importLeaves.validationStep.nextButtonTitle": "Potwierdź",
  "app.importLeaves.validationStep.noRowsMessage": "Nie znaleziono danych",
  "app.importLeaves.validationStep.noRowsMessageWhenFiltered": "Brak danych zawierających błędy",
  "app.importLeaves.validationStep.title": "Sprawdź poprawność danych",
  "app.importType": "Typ importu",
  "app.integrations": "Integracje",
  "app.invite": "Zaproś",
  "app.january": "Styczeń",
  "app.july": "Lipiec",
  "app.june": "Czerwiec",
  "app.label": "Etykieta",
  "app.labels": "Etykiety",
  "app.lastMonth": "Ostatni miesiąc",
  "app.learnMore": "Dowiedz się więcej",
  "app.leave": "Urlop",
  "app.leaveBalanceReport": "Raport stanu urlopów",
  "app.leaveCanceled": "Urlop anulowany",
  "app.leaveHistory": "Historia urlopów",
  "app.leaveHistoryReport": "Raport wniosków urlopowych",
  "app.leavePolicies": "Polityki urlopowe",
  "app.leaveRequestCalendarStatusLabel": "Pokaż w kalendarzu jako ",
  "app.leaveRequestCreated": "Wniosek urlopowy utworzony",
  "app.leaveRequests": "Wnioski urlopowe",
  "app.leaveType": "Typ urlopu",
  "app.leaveTypes": "Typy urlopów",
  "app.link": "Link",
  "app.load": "Załaduj",
  "app.loading": "Ładowanie",
  "app.loadMore": "Załaduj więcej",
  "app.location": "Lokalizacja",
  "app.locations": "Lokalizacje",
  "app.login": "Zaloguj się",
  "app.logInWithMicrosoft": "Zaloguj się przez Microsoft",
  "app.logout": "Wyloguj się",
  "app.logoutNow": "Wyloguj się teraz",
  "app.logs": "Logi",
  "app.manageLabels": "Zarządzaj etykietami",
  "app.march": "Marzec",
  "app.may": "Maj",
  "app.maybe": "Może",
  "app.message": "wiadomość",
  "app.meta.description": "Uwielbiany przez ponad 1000 firm na całym świecie, Vacation Tracker sprawia, że zarządzanie urlopami jest niezwykle łatwe. Dostępny dla Slack, Microsoft Teams i Google Workspace.",
  "app.meta.title": "Vacation Tracker - Przyjazne dla pracy zdalnej rozwiązanie do śledzenia urlopów",
  "app.mfa": "Uwierzytelnianie wieloskładnikowe",
  "app.mfaCode": "Kod",
  "app.mfaCodeErrorGeneric": "Nie udało się zweryfikować kodu",
  "app.mfaCodeErrorMissmatch": "Niezgodność kodu",
  "app.mfaCodeErrorNetwork": "Błąd sieci",
  "app.mfaCodePlaceholder": "Proszę wpisać kod wyświetlony w aplikacji uwierzytelniającej",
  "app.mfaDisableAnswerNo": "Nie, zachowaj MFA",
  "app.mfaDisableAnswerYes": "Tak, wyłącz MFA",
  "app.mfaDisableError": "Nie udało się wyłączyć MFA. Spróbuj ponownie później.",
  "app.mfaDisableSuccess": "Pomyślnie wyłączono MFA.",
  "app.mfaDisableWarning": "Czy na pewno chcesz wyłączyć MFA?",
  "app.mfaDisableWarningTitle": "Wyłącz uwierzytelnianie wieloskładnikowe",
  "app.mfaEnableError": "Nie udało się włączyć MFA. Spróbuj ponownie później.",
  "app.mfaEnableSuccess": "Pomyślnie skonfigurowano MFA.",
  "app.mfaEnterCode": "Wprowadź kod",
  "app.mfaLabel": "Włącz MFA",
  "app.mfaLoginFailed": "Logowanie nie powiodło się",
  "app.mfaSecretKey": "Lub wprowadź klucz tajny ręcznie:",
  "app.mfaSubTitle": "Zeskanuj poniższy kod QR w swojej aplikacji MFA (np. Authy lub Google Authenticator):",
  "app.mfaTitle": "Włącz uwierzytelnianie wieloskładnikowe",
  "app.mfaTooltip": "Po dodaniu Vacation Tracker do aplikacji MFA, poczekaj aż wygeneruje kod i wprowadź go poniżej:",
  "app.microsoft.manageLicenses": "Zarządzaj licencjami",
  "app.microsoftTeams": "Microsoft Teams",
  "app.monday": "Poniedziałek",
  "app.monday.short": "Pon",
  "app.monthly": "Miesięcznie",
  "app.monthlyLeaveBalanceReport": "Miesięczny raport salda urlopowego",
  "app.moreDetails": "Więcej szczegółów",
  "app.myProfile": "Mój profil",
  "app.myStatus": "Mój status",
  "app.name": "Nazwa",
  "app.newPassword": "Nowe hasło",
  "app.next": "Dalej",
  "app.nextInvoice": " Twoja następna faktura będzie wynosić {amount} (bez VAT) w dniu {startDate}",
  "app.nextMonth": "Następny miesiąc",
  "app.nextYear": "Następny rok",
  "app.no": "Nie",
  "app.noLeaves": "Brak urlopów",
  "app.none": "Brak",
  "app.nonWorkingDay": "Dzień wolny od pracy",
  "app.notDone": "Nie wykonano",
  "app.note": "Notatka",
  "app.notificationError": "O nie! 😕 Coś poszło nie tak. ",
  "app.notifications": "Powiadomienia",
  "app.notSet": "Nie ustawiono",
  "app.notValidEmail": "Proszę podać poprawny adres e-mail.",
  "app.november": "Listopad",
  "app.october": "Październik",
  "app.of": "z",
  "app.ofThat": "z tego",
  "app.ok": "Ok",
  "app.oldPassword": "Stare hasło",
  "app.one-hour": "1 godzina",
  "app.openCalendar": "Otwórz kalendarz",
  "app.openDashboard": "Otwórz panel",
  "app.openWebDashboard": "Otwórz panel internetowy",
  "app.or": "Lub",
  "app.password": "Hasło",
  "app.passwordAndSecurity": "Hasło i bezpieczeństwo",
  "app.pastDue": "Z powodu nieudanej płatności twój dostęp do Vacation Tracker zostanie wkrótce zamknięty. Jeśli chcesz dalej korzystać z Vacation Tracker, proszę <a>wprowadź ważną kartę</a>.",
  "app.pending": "Oczekujące",
  "app.pendingLeaveRequests": "Oczekujące wnioski urlopowe",
  "app.pleaseSelect": "Proszę wybrać",
  "app.prev": "Poprzedni",
  "app.previous": "Poprzedni",
  "app.proceed": "Kontynuuj",
  "app.processingRequest": "Przetwarzanie żądania...",
  "app.profilePicture": "Zdjęcie profilowe",
  "app.reason": "Powód",
  "app.recipients": "Odbiorcy",
  "app.recommended": "zalecane",
  "app.reconnect": "Połącz ponownie",
  "app.reload": "Odśwież",
  "app.removed": "usunięto",
  "app.rename": "Zmień nazwę",
  "app.reportType": "Typ raportu",
  "app.requestLeave": "Złóż wniosek urlopowy",
  "app.requestToil": "Złóż wniosek o czas wolny w zamian za nadgodziny",
  "app.required": "Wymagane",
  "app.resendCode": "Wyślij kod ponownie",
  "app.resent": "Wysłano ponownie",
  "app.reset": "Resetuj",
  "app.resetPassword": "Zresetuj hasło",
  "app.reviewAndConfirm": "Przejrzyj i potwierdź",
  "app.role.administrator": "Administrator",
  "app.role.approver": "Zatwierdzający",
  "app.role.approvers": "Zatwierdzający",
  "app.saturday": "Sobota",
  "app.saturday.short": "Sob",
  "app.save": "Zapisz",
  "app.scheduledLeaves": "Zaplanowane urlopy",
  "app.scheduledReports": "Zaplanowane raporty",
  "app.seeInCalendar": "🗓️ Zobacz w kalendarzu",
  "app.seeMoreOptions": "Zobacz więcej opcji",
  "app.select": "Wybierz",
  "app.selectAll": "Wybierz wszystko",
  "app.semi-monthly": "Dwa razy w miesiącu",
  "app.send": "Wyślij",
  "app.sendNow": "Wyślij teraz",
  "app.september": "Wrzesień",
  "app.set": "ustaw",
  "app.setOn": "ustawiono na",
  "app.shortestLeaveDuration": "Najkrótszy czas trwania urlopu",
  "app.showLess": "Pokaż mniej",
  "app.showMore": "Pokaż więcej",
  "app.skip": "Pomiń",
  "app.slack": "Slack",
  "app.snooze": "Odłóż",
  "app.snoozeForMonths": "Odłóż na 2 miesiące",
  "app.startFreeTrial": "Rozpocznij 7-dniowy darmowy okres próbny",
  "app.state": "Stan",
  "app.status": "Status",
  "app.submit": "Wyślij",
  "app.substituteApprover": "Zastępczy zatwierdzający",
  "app.substituteApproverSet": "Zostałeś ustawiony jako zastępczy zatwierdzający.",
  "app.sunday": "Niedziela",
  "app.sunday.short": "Niedz",
  "app.switch": "Przełącz",
  "app.sync": "Synchronizuj",
  "app.takeQuickTour": "Rozpocznij szybki przewodnik",
  "app.text": "Tekst",
  "app.thisMonth": "Ten miesiąc",
  "app.thisWeek": "Ten tydzień",
  "app.thursday": "Czwartek",
  "app.thursday.short": "Czw",
  "app.time": "Czas",
  "app.timeOffInLieu": "Czas wolny w zamian",
  "app.timeOffInLieuTooltip": "Chcesz edytować Czas wolny w zamian? <helpDesk>Dowiedz się, jak to zrobić</helpDesk>",
  "app.timezone": "Strefa czasowa",
  "app.today": "Dzisiaj",
  "app.toil": "TOIL",
  "app.trialExpired": "Wersja próbna wygasa za {days} {daysInfo}. Jeśli Ci się podobamy, prosimy <a>zasubskrybuj</a>, aby nadal korzystać z Vacation Tracker po zakończeniu okresu próbnego.",
  "app.trialExtend": "Potrzebujesz przedłużenia okresu próbnego?",
  "app.trialPeriod": "Okres próbny",
  "app.tryAgain": "Spróbuj ponownie",
  "app.tryLateOrContactSupport": "Coś poszło nie tak. Spróbuj ponownie później lub skontaktuj się z naszym wsparciem klienta pod adresem hello@vacationtracker.io, podając następujący identyfikator: {code}",
  "app.tuesday": "Wtorek",
  "app.tuesday.short": "Wt",
  "app.type": "Typ",
  "app.unknown": "<Nieznany>",
  "app.unlimited": "Bez ograniczeń",
  "app.update": "Aktualizuj",
  "app.updated": "zaktualizowano",
  "app.updatedInProgress": "Przetwarzanie odpowiedzi",
  "app.updatedSuccessfully": "Zgłoszenie przesłane.",
  "app.updating": "Aktualizowanie",
  "app.upload": "Prześlij",
  "app.user": "Użytkownik",
  "app.users": "Użytkownicy",
  "app.users.role": "Rola",
  "app.via": "przez",
  "app.view": "Wyświetl",
  "app.viewAll": "Wyświetl wszystko",
  "app.visitHelpdesk": "Odwiedź Helpdesk",
  "app.wallchart": "Tablica urlopów",
  "app.was": "był",
  "app.watchVideo": "Obejrzyj wideo",
  "app.wednesday": "Środa",
  "app.wednesday.short": "Śr",
  "app.weekly": "Tygodniowo",
  "app.willBe": "będzie",
  "app.workday": "Długość dnia pracy",
  "app.workdayWarning": "Opcja urlopu półdniowego jest dostępna tylko dla 8-godzinnego dnia pracy. Zmiana standardowego dnia pracy z 8 godzin spowoduje wyłączenie tej opcji.",
  "app.workweek": "Tydzień pracy",
  "app.year": "Rok",
  "app.years": "Lata",
  "app.yes": "Tak",
  "automation.feedback.thankYou": "Dziękujemy za podzielenie się z nami swoimi przemyśleniami. Twój wkład napędza nasz ciągły rozwój.",
  "automations.activateInProgress": "W toku - Aktywacja",
  "automations.activateInProgressDescription": "Twoja prośba o aktywację automatyzacji jest obecnie w toku. Pracujemy nad włączeniem tej funkcji dla Ciebie.",
  "automations.add": "Utwórz automatyzację",
  "automations.addAddonsDescirptionForAdmin": "Czy kontynuować zakup wybranego dodatku? Kwota zostanie proporcjonalnie naliczona w wysokości ${prorateAmount}, a Twoja następna faktura wyniesie łącznie ${nextInvoiceAmount}. Kliknij 'Potwierdź', aby sfinalizować.",
  "automations.addAddonsDescirptionForAdminInTrial": "Ten dodatek zostanie naliczony na Twojej następnej fakturze: ${total} na podstawie {userCount} użytkowników po ${addonsPrice} za dodatek. Podczas okresu próbnego możesz swobodnie aktywować i dezaktywować go bez opłat, jeśli żadne dodatki nie będą aktywne na koniec.",
  "automations.addAddonsDescirptionForApprover": "Tylko użytkownicy na poziomie Administratora mają możliwość zakupu lub rezygnacji z dodatków. Jeśli potrzebujesz dostosować subskrypcje dodatków, skontaktuj się z administratorem.",
  "automations.addFreeAddonsDescirptionForAdmin": "Ten dodatek jest wliczony w cenę Twojego obecnego planu bez dodatkowych kosztów. Możesz swobodnie aktywować i dezaktywować go w trakcie obecnej subskrypcji planu bez żadnych dodatkowych opłat.",
  "automations.addpnSuccessfullyAdded": "Zakończono - Aktywacja",
  "automations.addpnSuccessfullyAddedDescription": "Automatyzacja została pomyślnie aktywowana. Możesz teraz korzystać ze wszystkich jej funkcji i funkcjonalności.",
  "automations.addpnSuccessfullyDeactivated": "Zakończono - Dezaktywacja",
  "automations.addpnSuccessfullyDeactivatedDescription": "Automatyzacja została pomyślnie dezaktywowana. Nie będzie już aktywna w Twoim systemie.",
  "automations.ADP_INTEGRATION": "Integracja z ADP",
  "automations.anythingMissing": "Czegoś brakuje?",
  "automations.automationBlackoutPeriodDeleted": "Okres blokady usunięty",
  "automations.automationEntitlementByRoleDeleted": "Uprawnienie według roli usunięte",
  "automations.automationProbationPeriodDeleted": "Okres próbny usunięty",
  "automations.automationSeniorityEntitlementDeleted": "Uprawnienie stażowe usunięte",
  "automations.automationType": "Typ automatyzacji",
  "automations.BAMBOOHR_INTEGRATION": "Integracja z BambooHR",
  "automations.BLACKOUT_PERIOD": "Okres blokady",
  "automations.blackoutPeriod.autoRejectReasonDefault": "W trakcie obecnego okresu wysokiego obciążenia, cały nasz zespół jest niezbędny do utrzymania działalności. Niestety, nie możemy zatwierdzić urlopu, ponieważ wpłynęłoby to na nasze cele biznesowe i zobowiązania wobec klientów.",
  "automations.blackoutPeriod.createInProgress": "Tworzenie okresu blokady w toku",
  "automations.blackoutPeriod.createInProgressCompleted": "Tworzenie okresu blokady zakończone",
  "automations.blackoutPeriod.updateInProgress": "Aktualizacja okresu blokady w toku",
  "automations.blackoutPeriod.updateInProgressCompleted": "Aktualizacja okresu blokady zakończona",
  "automations.blackoutPeriodFromTo": "Okres blokady od {fromDate} <endDate>do {toDate}</endDate>",
  "automations.cantOpenAutomations": "Ta automatyzacja została utworzona przez innego użytkownika i nie możesz jej edytować.",
  "automations.createAutomation": "Utwórz automatyzację",
  "automations.datePeriod": "{fromDate} <endDate>i {toDate}</endDate><recurring> każdego roku</recurring>",
  "automations.deactivateInPgoress": "W toku - Dezaktywacja",
  "automations.deactivateInPgoressDescription": "Twoja prośba o dezaktywację automatyzacji jest obecnie przetwarzana. Proszę czekać, aktualizujemy Twoje ustawienia",
  "automations.deactivateVisibilityWarn": "Wyłączenie automatyzacji nie anuluje subskrypcji. Jeśli chcesz wyłączyć subskrypcję, musisz kliknąć przycisk \"Anuluj subskrypcję\".",
  "automations.deleteInProgress": "Usuwanie automatyzacji {automationName}",
  "automations.departmentVisibilityLevelInfo": "Użytkownicy będą widzieć informacje o urlopach tylko w obrębie swojego działu. Zatwierdzający będą widzieć informacje o urlopach w swoim dziale i we wszystkich działach, dla których są zatwierdzającymi, nawet jeśli nie są ich członkami. Administratorzy zawsze będą widzieć wszystko.",
  "automations.discoveryMoreAddons": "Odkryj więcej dodatków",
  "automations.EARLY_EMPLOYMENT_LEAVE_RESTRICTION": "Ograniczenie urlopu we wczesnym okresie zatrudnienia",
  "automations.emptyViewButton": "Zobacz dostępne dodatki",
  "automations.emptyViewMessage": "Dodaj dni urlopu na podstawie stażu pracy, twórz okresy blokady, automatyzuj proces zatwierdzania lub odrzucania, i wiele więcej dzięki naszym automatyzacjom, dodatkom i integracjom.",
  "automations.emptyViewTitle": "Zautomatyzuj czasochłonne zadania i zintegruj się z zewnętrznymi systemami",
  "automations.ENTITLEMENT_BY_ROLE": "Uprawnienia według roli",
  "automations.entitlementByRole.createInProgress": "Tworzenie uprawnień według roli w toku",
  "automations.entitlementByRole.createInProgressCompleted": "Tworzenie uprawnień według roli zakończone",
  "automations.entitlementByRole.days": "Dni",
  "automations.entitlementByRole.updateInProgress": "Aktualizacja uprawnień według roli w toku",
  "automations.entitlementByRole.updateInProgressCompleted": "Aktualizacja uprawnień według roli zakończona",
  "automations.LEAVE_DURATION_LIMIT": "Limit czasu trwania urlopu",
  "automations.message": "Wiadomość",
  "automations.messagePlaceholder": "Twoja wiadomość",
  "automations.myAddons": "Moje dodatki",
  "automations.OPEN_API": "Open API",
  "automations.OPEN_API_DOCUMENTATION": "Dokumentacja Open API",
  "automations.period": "Okres",
  "automations.PROBATION_PERIOD": "Okres próbny",
  "automations.probationPeriod.autoRejectReasonDefault": "Twój wniosek o urlop został automatycznie odrzucony, ponieważ jesteś we wczesnej fazie zatrudnienia, w okresie, gdy urlop nie jest dozwolony. Ta polityka pomaga nowym pracownikom w pełni zanurzyć się i dostosować do nowych ról i dynamiki zespołu.",
  "automations.probationPeriod.createInProgress": "Tworzenie okresu próbnego w toku",
  "automations.probationPeriod.createInProgressCompleted": "Tworzenie okresu próbnego zakończone",
  "automations.probationPeriod.lengthDays": "Długość (dni)",
  "automations.probationPeriod.name": "Nazwa polityki okresu próbnego",
  "automations.probationPeriod.namePlaceholder": "Wprowadź nazwę polityki okresu próbnego",
  "automations.probationPeriod.settings.applyToMDescription": "Możesz wybrać, czy chcesz zastosować tę automatyzację uniwersalnie dla wszystkich użytkowników, czy dostosować ją do konkretnych działów, lokalizacji lub etykiet.",
  "automations.probationPeriod.settings.applyToMDescriptionTwo": "Możesz również wybrać jeden lub więcej typów urlopów, do których chcesz zastosować tę automatyzację.",
  "automations.probationPeriod.settings.lengthDaysDescription": "Wprowadź liczbę dni, przez które nowi pracownicy będą mieli ograniczenia w korzystaniu z określonych typów urlopów, licząc od daty rozpoczęcia zatrudnienia.",
  "automations.probationPeriod.settings.nameDescription": "Ta sekcja pozwala ustawić politykę, która ogranicza nowo zatrudnionym pracownikom branie urlopu w początkowym okresie zatrudnienia.",
  "automations.probationPeriod.settings.nameDescriptionThree": "Upewnij się, że użytkownicy otrzymają automatyczną wiadomość wyjaśniającą, dlaczego ich wniosek o urlop został odrzucony.",
  "automations.probationPeriod.settings.nameDescriptionTwo": "Wprowadź liczbę dni, a my obliczymy okres próbny na podstawie daty zatrudnienia każdego użytkownika.",
  "automations.probationPeriod.settings.nameTitle": "Zdefiniuj politykę",
  "automations.probationPeriod.updateInProgress": "Aktualizacja okresu próbnego w toku",
  "automations.probationPeriod.updateInProgressCompleted": "Aktualizacja okresu próbnego zakończona",
  "automations.probationPeriodMessages": "Twój okres próbny trwa do {date}",
  "automations.removeAddons": "Usuń dodatki",
  "automations.removeAddonsDescirptionForAdmin": "Czy na pewno chcesz zrezygnować z subskrypcji tego dodatku? Zachowasz dostęp do wszystkich jego funkcji do końca bieżącego okresu rozliczeniowego. Po tym czasie zostanie on dezaktywowany, co wpłynie na konfigurację zarządzania urlopami.",
  "automations.removeAddonsDescirptionForApprover": "Tylko użytkownicy na poziomie administratora mają możliwość zakupu lub rezygnacji z dodatków. Jeśli potrzebujesz dostosować subskrypcje dodatków, skontaktuj się z administratorem.",
  "automations.requestAnAutomation": "Poproś o dodatek",
  "automations.requestAutomationTitle": "Nie możesz znaleźć potrzebnego dodatku?",
  "automations.reSubscribeAddons": "Odnów subskrypcję dodatku",
  "automations.reSubscribeAddonsDescirptionForAdmin": "Czy na pewno chcesz odnowić subskrypcję tego dodatku? Będziesz obciążany kwotą ${addonsPrice} za użytkownika miesięcznie, począwszy od następnej faktury. Opłata za pierwszy miesiąc zostanie obliczona proporcjonalnie na podstawie liczby dni, w których dodatek był używany.",
  "automations.reSubscribeAddonsDescirptionForAdminInTrial": "Czy na pewno chcesz odnowić subskrypcję tego dodatku? Pozostanie on bezpłatny do końca okresu próbnego. Po tym czasie zostanie naliczona opłata w wysokości ${addonsPrice} za użytkownika miesięcznie.",
  "automations.reSubscribeInfo": "Odnów subskrypcję dodatku i kontynuuj korzystanie z naszych zaawansowanych funkcji. Kliknij Odnów subskrypcję, aby kontynuować.",
  "automations.SENIORITY_ENTITLEMENT": "Prawo do urlopu ze względu na staż pracy",
  "automations.seniorityEntitlement.createInProgress": "Tworzenie prawa do urlopu ze względu na staż pracy w toku",
  "automations.seniorityEntitlement.createInProgressCompleted": "Tworzenie prawa do urlopu ze względu na staż pracy zakończone",
  "automations.seniorityEntitlement.periodsViewTable": "{days} dni po {years} latach",
  "automations.seniorityEntitlement.updateInProgress": "Aktualizacja prawa do urlopu ze względu na staż pracy w toku",
  "automations.seniorityEntitlement.updateInProgressCompleted": "Aktualizacja prawa do urlopu ze względu na staż pracy zakończona",
  "automations.showCaneledAddons": "Pokaż anulowane dodatki",
  "automations.stepByStepHelpDesk": "Aby uzyskać szczegółowe wskazówki dotyczące konfiguracji automatyzacji <automationName></automationName>, zapoznaj się z instrukcjami krok po kroku dostępnymi na <link>stronie pomocy</link>.",
  "automations.subscripteToAddons": "Subskrybuj dodatki",
  "automations.TEAM_LEAVE_BALANCING": "Bilansowanie urlopów zespołu",
  "automations.unsubscribeForFreeAddonsInfo": "Obecnie subskrybujesz ten dodatek bez dodatkowych kosztów, ponieważ jest on zawarty w Twoim planie. Jeśli chcesz zrezygnować z subskrypcji, kliknij przycisk rezygnacji po prawej stronie.",
  "automations.unsubscribeInfo": "Obecnie subskrybujesz ten dodatek w cenie ${addonsPrice} za użytkownika. Jeśli chcesz zrezygnować z subskrypcji, kliknij przycisk rezygnacji po prawej stronie.",
  "automations.unsubscripteAddons": "Zrezygnować z dodatku?",
  "automations.update": "Zaktualizuj automatyzacje",
  "automations.VISIBILITY": "Menedżer widoczności",
  "automations.visibility.createInProgress": "Ograniczanie widoczności w toku",
  "automations.visibility.createInProgressCompleted": "Ograniczanie widoczności zakończone",
  "automations.visibility.updateInProgress": "Aktualizacja dodatku widoczności",
  "automations.visibility.updateInProgressCompleted": "Aktualizacja dodatku widoczności zakończona",
  "automations.visibilityForm.DEPARTMENT": "Ich dział",
  "automations.visibilityForm.limitVisibility": "Ogranicz widoczność dla użytkowników",
  "automations.visibilityForm.visibilityLevel": "Użytkownicy mogą zobaczyć",
  "automations.visibilityForm.visibilityTypeValidationRequired": "Musisz wybrać co najmniej jeden typ widoczności",
  "automations.visibilityInfo.settings.nameTitle": "Ustawienia widoczności",
  "automations.visibilityManagerNotificationsInfo": "Współdzielone kalendarze i powiadomienia utworzone przed zmianą ustawień widoczności muszą zostać ręcznie usunięte, jeśli nie są zgodne z nowymi zasadami.",
  "automationsForm.advancedFilter.tooltipInfo": "Możesz filtrować użytkowników, do których ta automatyzacja zostanie zastosowana, na podstawie działów, lokalizacji lub etykiet.",
  "automationsForm.applyToAllUsers": "Zastosować do wszystkich użytkowników?",
  "automationsForm.applyToAllUsersTooltip": "Wybierz, czy chcesz zastosować tę automatyzację uniwersalnie do wszystkich użytkowników, czy dostosować ją na podstawie konkretnych działów, lokalizacji lub etykiet.",
  "automationsForm.autoRejectReason": "Automatyczna odpowiedź odrzucenia",
  "automationsForm.autoRejectReasonTooltip": "Podaj użytkownikowi automatyczną odpowiedź wyjaśniającą, dlaczego jego wniosek urlopowy został odrzucony w tym czasie.",
  "automationsForm.blackoutPeriodShortText": "Okres, w którym pracownicy nie mogą brać urlopu",
  "automationsForm.period": "Okres",
  "automationsForm.recurring": "Powtarzaj corocznie",
  "automationsForm.seniorityEntitlement.applyToAllUsersTooltip": "Czy chcesz zastosować to prawo do urlopu ze względu na staż pracy do wszystkich użytkowników w firmie, czy do użytkowników w konkretnej lokalizacji? Jeśli masz różne zasady dotyczące prawa do urlopu ze względu na staż pracy w różnych lokalizacjach, musisz utworzyć wiele reguł automatyzacji prawa do urlopu ze względu na staż pracy, po jednej dla każdej lokalizacji.",
  "automationsForm.seniorityEntitlement.beginningOfTheFiscalYear": "Początek roku fiskalnego",
  "automationsForm.seniorityEntitlement.employeeStartDate": "Data rozpoczęcia pracy pracownika",
  "automationsForm.seniorityEntitlement.endOfTheFiscalYear": "Koniec roku fiskalnego",
  "billing.applyingCoupon": "Stosowanie kuponu",
  "billing.applyingCouponAndResubscribing": "Stosowanie kuponu i ponowna subskrypcja",
  "billing.cancelSubscription": "Anuluj subskrypcję",
  "billing.cancelTrial": "Twój okres próbny pozostanie aktywny do {stripeCurrentPeriodEnd}. Aby nadal korzystać z Vacation Tracker, wprowadź swoje dane rozliczeniowe.",
  "billing.cancelTrialButton": "Anuluj okres próbny",
  "billing.cancelUpdateInProgress": "Anulowanie...",
  "billing.linkPaymentMethod": "Link Stripe",
  "billing.microsoft.billedLabel": "Rozliczono",
  "billing.microsoft.billingCycle": "Bieżący cykl rozliczeniowy kończy się {date}",
  "billing.microsoft.billingCycleEnds": "Twoja subskrypcja zostanie anulowana po zakończeniu cyklu rozliczeniowego i nie będziesz mógł już korzystać z Vacation Tracker.",
  "billing.microsoft.billingCycleLabel": "Cykl rozliczeniowy",
  "billing.microsoft.buyLessLicensesConfirmModalContent": "Masz {currentNoOfLicenses} licencji. Zamierzasz usunąć {additionalLicences}. Czy jesteś pewien?",
  "billing.microsoft.buyMoreLicensesConfirmModalContent": "Masz {currentNoOfLicenses} licencji. Zamierzasz kupić {additionalLicences} więcej. Czy jesteś pewien?",
  "billing.microsoft.canceledSubscriptionMessage": "Twoja subskrypcja zostanie anulowana za około 15 minut",
  "billing.microsoft.changePlanModalTitle": "Zmień obecny plan <strong>{plan}</strong>",
  "billing.microsoft.info": "Twoje informacje płatnicze są zarządzane przez Microsoft. Odwiedź <adminCenterLink>Centrum administracyjne</adminCenterLink>, aby zaktualizować swoje {labelInfo}",
  "billing.microsoft.licensesSideActionLabel": "Zobacz historię",
  "billing.microsoft.licensesTitle": "Licencje",
  "billing.microsoft.pricingPlanLabel": "Plan cenowy",
  "billing.microsoft.switchPlanModal.confirm": "Potwierdź, że chcesz przejść na ten plan.",
  "billing.paymentInformation": "Informacje o płatności",
  "billing.paymentMethod": "Metoda płatności",
  "billing.pricingPlanPeriodAnnual": "Roczny",
  "billing.pricingPlanPeriodMonthly": "Miesięczny",
  "billing.pricingPlansText": "Ta sekcja wyświetla Twój plan subskrypcji. Możesz ponownie zasubskrybować plan, który miałeś wcześniej lub zmienić plan przed ponowną subskrypcją.",
  "billing.pricingPlansTitle": "Plany cenowe",
  "billing.promoCodeErrorDescription": "Kod promocyjny {code} jest nieprawidłowy.",
  "billing.resubscribeCTA": "Odnów subskrypcję",
  "billing.resubscribingUpdateInProgress": "Odnawianie subskrypcji...",
  "billing.seats": "Miejsca",
  "billing.seats.buyLessSeatsConfirmModalContent": "Masz {currentNoOfSeats} miejsc. Zamierzasz usunąć {additionalSeats}.",
  "billing.seats.buyMoreSeatsConfirmModalContent": "Masz {currentNoOfSeats} miejsc. Zamierzasz kupić {additionalSeats} więcej.",
  "billing.seats.canNotDowngrade": "Nie możesz zmniejszyć liczby miejsc ({currentNumberOfSeats}) bez dezaktywacji jednego lub więcej obecnie aktywnych użytkowników.",
  "billing.seats.info": "Liczba miejsc, które kupiłeś.",
  "billing.seats.loadingUpcomingPaymentInformation": "Ładowanie informacji o nadchodzącej płatności...",
  "billing.seats.manage": "Zarządzaj miejscami",
  "billing.seats.noBillingChange": "Zamierzasz kupić {noOfSeats} {noOfSeats, plural, =1 {miejsce} other {miejsc}}. Twoje rozliczenie nie zostanie zmienione.",
  "billing.seats.warningOfProrateAdditional": "Szacowana proporcjonalna opłata w wysokości {proratedAmount} (bez VAT) zostanie naliczona dzisiaj.",
  "billing.seats.warningOfSeatsAnnualProrateAdditionalNewBucket": "Dodanie {noOfSeats} {noOfSeats, plural, =1 {miejsca} other {miejsc}} zwiększy Twój następny rachunek do {amount} (bez VAT) począwszy od {startDate}.",
  "billing.seats.warningOfSeatsProrateAdditionalNewBucketMonthly": "Zakup {noOfSeats} {noOfSeats, plural, =1 {miejsca} other {miejsc}} wpłynie na Twoje rozliczenie. Twoja następna faktura będzie wynosić {amount} (bez VAT) w dniu {startDate}",
  "billing.seats.warningOfSeatsRemovingAnnualProrateAdditionalNewBucket": "Usunięcie {noOfSeats} {noOfSeats, plural, =1 {miejsca} other {miejsc}} zmniejszy Twój następny rachunek do {amount} (bez VAT) począwszy od {startDate}.",
  "billing.seats.warningOfSeatsRemovingProrateAdditionalNewBucketMonthly": "Usunięcie {noOfSeats} {noOfSeats, plural, =1 {miejsca} other {miejsc}} wpłynie na Twoje rozliczenie. Twoja następna faktura będzie wynosić {amount} (bez VAT) w dniu {startDate}",
  "billing.subscriptionCanceled": "Subskrypcja anulowana",
  "billing.subscriptionExpired": "Subskrypcja nieaktywna",
  "billing.subscriptionExpiredDescription": "Twoja subskrypcja została anulowana lub wygasła. Aktualizacje nie są możliwe w tym momencie.",
  "billing.subscriptionExpiredText": "Twoja subskrypcja wygasła. Odnów subskrypcję, aby kontynuować korzystanie z Vacation Tracker. Jeśli masz jakiekolwiek problemy lub pytania, skontaktuj się z naszym {supportLink}.",
  "billing.SubscriptionPlan": "Plan subskrypcji",
  "billing.subscriptionUpdateInProgress": "Aktualizacja subskrypcji w toku",
  "billing.subscriptionUpdateInProgressDescription": "Poprzednia operacja jest nadal w toku. Spróbuj ponownie za minutę.",
  "billing.updateBillingInfo": "Zaktualizuj dane rozliczeniowe",
  "billing.updatedFaild": "Aktualizacja danych rozliczeniowych nie powiodła się",
  "billing.updatedSuccessfully": "Dane rozliczeniowe zostały zaktualizowane",
  "billing.updateInProgress": "Aktualizacja danych rozliczeniowych",
  "bot.announce.slack": "Cześć <@{slackUserId}>\n\nNazywam się Vacation Tracker. Pomogę Ci rezerwować urlopy przez Slacka i będę informować wszystkich o nadchodzących urlopach w Twojej organizacji.\n\nWpisz `/vacation` gdziekolwiek w Slacku, aby rozpocząć, lub sprawdź mój tutorial na YouTube, aby dowiedzieć się więcej:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.announce.slack.info": "Cześć <@{slackUserId}>, nazywam się Vacation Tracker.",
  "bot.announce.slackCompany": "Cześć Zespole,\n\nNazywam się Vacation Tracker Bot!\n\nPozwalam Ci rezerwować urlopy przez Slacka i informuję wszystkich o nadchodzących urlopach członków zespołu. Zacznijmy śledzić urlopy!\n\nWpisz `/vacation` gdziekolwiek w Slacku lub sprawdź tutorial na YouTube, aby dowiedzieć się więcej:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.message.about.commandDescription": "Vacation Tracker upraszcza proces składania wniosków i rezerwacji urlopów w Twojej organizacji. Członkowie zespołu mogą rezerwować dowolny typ urlopu lub dzień wolny płatny/niepłatny bez opuszczania Slacka i bez konieczności pamiętania kolejnego loginu i hasła.\n\nUstaw niestandardowe typy urlopów i pozwól każdemu w Twojej organizacji kontrolować swój czas wolny. Vacation Tracker zapewnia każdemu członkowi zespołu dostęp do Panelu (http://app.vacationtracker.io), gdzie mogą wnioskować o urlop, sprawdzać swój obecny status urlopu lub historię oraz przeglądać zaplanowane urlopy dla całego zespołu.\n\nZgodnie z ustawieniami Administratora lub Zatwierdzającego, członkowie zespołu będą otrzymywać codzienne/tygodniowe powiadomienia o nadchodzących nieobecnościach. Bez zamieszania i nieprzyjemnych niespodzianek.\n\nChcesz dowiedzieć się więcej o Vacation Tracker? Nasz <https://vacationtracker.crisp.help/en/|Helpdesk> jest Twoim drugim najlepszym wyborem po <https://vacationtracker.io/request-a-demo/|demo>.\n\nJeśli Twoje pytania lub wątpliwości pozostają bez odpowiedzi, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia: hello@vacationtracker.io",
  "bot.message.accessExpired": "O nie! Twój dostęp do Vacation Tracker wygasł. Skontaktuj się z administratorem, aby uzyskać więcej informacji.",
  "bot.message.accruedDaysInfoForApprover": "ℹ️ Ten wniosek urlopowy jest oparty na przyszłych naliczonych dniach wolnych, a nie na obecnej ilości. <@{name}> ma obecnie {amount} pozostałych dni. Naliczą {accrued} dnia {earningDate}.",
  "bot.message.accruedDaysInfoForUser": "ℹ️ Twój wniosek urlopowy jest oparty na przyszłych naliczonych dniach wolnych, a nie na obecnej ilości. Masz obecnie {amount} pozostałych dni, a naliczysz {accrued} dnia {earningDate}.",
  "bot.message.approveLeave": "Zatwierdź urlop",
  "bot.message.approveLeaves.approvedRequest": "✅ Zatwierdziłeś wniosek. Powiadomienie zostanie wysłane do <@{userSlackId}>, aby go o tym poinformować.",
  "bot.message.approveLeaves.deniedRequest": "❌ Odrzuciłeś wniosek. Powiadomienie zostanie wysłane do <@{userSlackId}>, aby go o tym poinformować.",
  "bot.message.approver": "*Zatwierdzający:*\n<@{approverSlackId}>",
  "bot.message.approver.autoApproved": "*Zatwierdzający:*\nAutomatycznie zatwierdzony",
  "bot.message.approveToil": "Zatwierdź TOIL",
  "bot.message.backToOptions": "Powrót do opcji",
  "bot.message.blackoutPeriod": "*Okres blokady*: {startDate} - {endDate} \n \n_{message}_",
  "bot.message.couldNotProcessRequest": "Ups! Nie mogliśmy przetworzyć Twojego żądania.",
  "bot.message.date": "*Data*",
  "bot.message.dateEdited": "*Data [Edytowana]*",
  "bot.message.dates": "*Daty*",
  "bot.message.datesEdited": "*Daty [Edytowane]*",
  "bot.message.deniedLeave": "Odrzucony urlop",
  "bot.message.deniedLeaveDate": "*Data*\n{leaveDate}",
  "bot.message.deniedLeaveDates": "*Daty*\n{leaveDate}",
  "bot.message.deniedLeaveLeaveType": "*Typ urlopu:*\n{leaveTypeName}",
  "bot.message.deniedLeaveWithReason": "Cześć, <@{userSlackId}> złożył następujący wniosek urlopowy:",
  "bot.message.deniedToil": "Odrzucony TOIL",
  "bot.message.error.endDateCantBeInPast": "Data zakończenia nie może być przed rozpoczęciem, wybierz datę w przyszłości",
  "bot.message.error.endTimeCantBeBeforeStartTime": "Wygląda na to, że wybrana godzina rozpoczęcia jest po godzinie zakończenia.*\n *Proszę wybrać godzinę rozpoczęcia, która jest przed godziną zakończenia.",
  "bot.message.error.leaveRequestOverlap": "Wybrane daty pokrywają się z istniejącym urlopem.*\n *Proszę wybrać inny okres.",
  "bot.message.error.negativeBallanceNotAllowed": "Ujemny bilans nie jest dozwolony dla tego typu urlopu.",
  "bot.message.error.oneHoureIntervalNotAllowed": "Najmniejszy odstęp czasu, jaki możesz zażądać dla tego typu urlopu, to 4 godziny (pół dnia).",
  "bot.message.error.partTimeIsntAllowed": "Praca w niepełnym wymiarze godzin nie jest dozwolona",
  "bot.message.error.reasonRequired": "Pole powodu jest wymagane.",
  "bot.message.error.requestedMoreThanFullDay": "Zażądałeś więcej niż jeden pełny dzień urlopu w jednym dniu roboczym.*\n *Proszę wybrać inny dzień.",
  "bot.message.error.startDateCantBeInPast": "Data rozpoczęcia nie może być w przeszłości, proszę wybrać datę w przyszłości",
  "bot.message.error.userCannotBeFound": "Wybrany użytkownik nie został znaleziony w Twojej organizacji.*\n *Proszę skontaktować się z administratorem Twojej organizacji lub wsparciem Vacation Tracker, aby uzyskać więcej informacji.",
  "bot.message.error.userEndDateInFuture": "Żądane daty są po dacie zakończenia zatrudnienia użytkownika.*\n *Proszę skontaktować się z administratorem Twojej organizacji lub wsparciem Vacation Tracker, aby uzyskać więcej informacji.",
  "bot.message.error.userStartDateInFuture": "Żądane daty są przed datą rozpoczęcia zatrudnienia użytkownika. Proszę skontaktować się z administratorem Twojej organizacji lub wsparciem Vacation Tracker, aby uzyskać więcej informacji.",
  "bot.message.error.zeroDaysLeaveRequest": "Wybrałeś święto lub dzień niepracujący.*\n *Proszę wybrać prawidłowy zakres dat.",
  "bot.message.feedback.chooseAnOption": "Wybierz opcję...",
  "bot.message.feedback.form": "Formularz opinii",
  "bot.message.feedback.haveBeenSend": "Opinia została wysłana",
  "bot.message.feedback.howDoYouLikeOurApp": "Jak podoba Ci się nasza aplikacja?",
  "bot.message.feedback.info": "Podziel się swoją opinią :heart:",
  "bot.message.feedback.itMakesMeCry": "To mnie zasmuca 😢",
  "bot.message.feedback.itsOk": "Jest w porządku 😐",
  "bot.message.feedback.likeIt": "Podoba mi się 😀",
  "bot.message.feedback.loveIt": "Uwielbiam to 😍",
  "bot.message.feedback.message": "Wiadomość z opinią",
  "bot.message.feedback.nah": "Nie 🙁",
  "bot.message.feedback.response": "Jeśli Twoja opinia wymaga odpowiedzi, podaj swój adres e-mail, abyśmy mogli się z Tobą skontaktować.",
  "bot.message.feedback.thanks": "Dziękujemy za opinię! Zawsze możesz się z nami skontaktować, używając adresu support@vacationtracker.io.",
  "bot.message.feedback.youEmailAddress": "Twój adres e-mail",
  "bot.message.genericError": "Ojej! Wystąpił nieoczekiwany błąd. 😱 Jeśli problem będzie się powtarzał, skontaktuj się z pomocą techniczną i podaj im następujący kod błędu: {requestId}.",
  "bot.message.goToTheMyProfileTab": "Przejdź do zakładki Mój Profil",
  "bot.message.helpMessage": "Witaj, \nZ Vacation Tracker możesz wykonać jedną z następujących czynności:\n- Wyślij **Poproś o urlop** w wiadomości bezpośredniej, aby poprosić o nowy urlop za pomocą bota Microsoft Teams.\n- Wyślij **Poproś o TOIL** w wiadomości bezpośredniej, aby poprosić o nowy TOIL za pomocą bota Microsoft Teams.\n- Odwiedź zakładkę **Mój Profil**, aby zobaczyć swoje informacje osobiste, takie jak nadchodzące urlopy i święta oraz historię urlopów.\n- Przypnij zakładkę **Przegląd** do jednego z kanałów, aby zobaczyć przegląd zespołu, w tym nadchodzące urlopy i święta dla Twojego zespołu.\n- Odwiedź [**Panel internetowy Vacation Tracker ⧉**]({dashboardLink}/connect?platform=microsoft&redirect=/app/dashboard).\nAby uzyskać więcej pomocy i poradników, odwiedź naszą [**Pomoc ⧉**](https://vacationtracker.crisp.help/en/). Możesz też umówić się na spersonalizowaną demonstrację Vacation Tracker pod tym linkiem: [https://vacationtracker.io/request-a-demo](https://vacationtracker.io/request-a-demo).",
  "bot.message.holidays": "*Święta:*",
  "bot.message.leaveDate": "*Data:*\n{leaveDate}",
  "bot.message.leaveDateEdited": "*Data [Edytowana]:*\n{leaveDate}",
  "bot.message.leaveDates": "*Daty:*\n{leaveDate}",
  "bot.message.leaveDatesEdited": "*Daty [Edytowane]:*\n{leaveDate}",
  "bot.message.leaveDuration1": "*{word} zażądane na bieżący rok:*\n{requestedThisYearNumber}",
  "bot.message.leaveDuration2": "*{word} pozostałe na bieżący rok:*\n{currentYearNumber}",
  "bot.message.leaveDuration2Unlimited": "*{word} pozostałe na bieżący rok:*\nNieograniczone",
  "bot.message.leaveDuration3": "*{word} zażądane na przyszły rok:*\n{requestedNextYearNumber}",
  "bot.message.leaveDuration4": "*{word} pozostałe na przyszły rok:*\n{nextYearNumber}",
  "bot.message.leaveDuration4Unlimited": "*{word} pozostałe na przyszły rok:*\nNieograniczone",
  "bot.message.leaveRemaining": "Pozostały urlop",
  "bot.message.leaveRemainingCurrent": "Pozostały urlop (Bieżący rok)",
  "bot.message.leaveRemainingDays": "Pozostałe dni urlopu",
  "bot.message.leaveRemainingDaysCurrent": "Pozostałe dni urlopu (Bieżący rok)",
  "bot.message.leaveRemainingDaysNext": "Pozostałe dni urlopu (Następny rok)",
  "bot.message.leaveRemainingHours": "Pozostałe godziny urlopu",
  "bot.message.leaveRemainingHoursNext": "Pozostałe godziny urlopu (Następny rok)",
  "bot.message.leaveRemainingNext": "Pozostały urlop (Następny rok)",
  "bot.message.leaveRequest.FULL-DAY": "{leaveTypeName}",
  "bot.message.leaveRequest.HALF-DAY": "{leaveTypeName} (Pół dnia)",
  "bot.message.leaveRequest.halfDay": "(Pół dnia)",
  "bot.message.leaveRequest.hourly": "(Godzinowy)",
  "bot.message.leaveRequest.leaveTypeName": "{leaveTypeName}",
  "bot.message.leaveRequest.leaveTypeNamePartTime": "{leaveTypeName} (Godzinowy)",
  "bot.message.leaveRequest.ONE-HOUR": "{leaveTypeName} (Godzinowy)",
  "bot.message.leaveRequestCreated.error": "Wniosek urlopowy nie może zostać złożony",
  "bot.message.leaveRequestCreated.errorBadTime": "❌ Wniosek urlopowy nie może zostać złożony, ponieważ jest dłuższy niż godziny pracy lub przedział czasowy jest nieprawidłowy.",
  "bot.message.leaveRequestCreated.errorDateAfter": "❌ Wniosek urlopowy nie może zostać złożony, ponieważ wybrana data rozpoczęcia jest późniejsza niż data zakończenia. Upewnij się, że data rozpoczęcia jest wcześniejsza niż data zakończenia i złóż wniosek ponownie.",
  "bot.message.leaveRequestCreated.errorDateInThePast": "❌ Wniosek urlopowy nie może zostać złożony, ponieważ wybrana data rozpoczęcia jest w przeszłości. Upewnij się, że wybrane daty są w przyszłości i złóż wniosek ponownie.",
  "bot.message.leaveRequestCreated.errorDateNotSelected": "❌ Wniosek urlopowy nie może zostać złożony, ponieważ nie wybrano daty zakończenia lub data jest nieprawidłowa.",
  "bot.message.leaveRequestCreated.errorNoStartDate": "❌ Wniosek urlopowy nie może zostać złożony, ponieważ nie wybrano daty rozpoczęcia lub data jest nieprawidłowa.",
  "bot.message.leaveRequestCreated.errorNoStartTime": "❌ Wniosek urlopowy nie może zostać złożony, ponieważ nie wybrano godziny rozpoczęcia lub godzina jest nieprawidłowa.",
  "bot.message.leaveRequestCreated.errorReasonRequired": "❌ Wniosek urlopowy nie może zostać złożony, powód jest wymagany.",
  "bot.message.leaveRequested": "Urlop wnioskowany",
  "bot.message.leaveRequestedCurrent": "Urlop wnioskowany (Bieżący rok)",
  "bot.message.leaveRequestedDays": "Wnioskowane dni urlopu",
  "bot.message.leaveRequestedDaysCurrent": "Wnioskowane dni urlopu (Bieżący rok)",
  "bot.message.leaveRequestedDaysNext": "Wnioskowane dni urlopu (Następny rok)",
  "bot.message.leaveRequestedHours": "Wnioskowane godziny urlopu",
  "bot.message.leaveRequestedHoursCurrent": "Wnioskowane godziny urlopu (Bieżący rok)",
  "bot.message.leaveRequestedHoursNext": "Wnioskowane godziny urlopu (Następny rok)",
  "bot.message.leaveRequestedNext": "Urlop wnioskowany (Następny rok)",
  "bot.message.leaveRequestNext.endDate": "Data zakończenia:",
  "bot.message.leaveRequestNext.info": "Świetnie! 🐨 Wybierz okres czasu i złóż wniosek.",
  "bot.message.leaveRequestNext.isHalfDay": "{leaveTypeName} (Pół dnia)",
  "bot.message.leaveRequestNext.leaveTime": "Czas urlopu:",
  "bot.message.leaveRequestNext.reasonOptional": "Powód (Opcjonalny)",
  "bot.message.leaveRequestNext.reasonRequired": "Powód (Wymagany)",
  "bot.message.leaveRequestNext.startDate": "Data rozpoczęcia:",
  "bot.message.leaveRequestNext.title": "Złóż wniosek o urlop",
  "bot.message.leaves": "*Urlopy:*",
  "bot.message.leavesHistory": "Twoja historia urlopów:",
  "bot.message.leavesHistory.leaveMessageFullDay": "- Urlop (*{leaveTypeName}*) od {start} do {end} *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOn": "- Urlop (*{leaveTypeName}*) w dniu {start} *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOnPartDay": "- Urlop (*{leaveTypeName}*) w dniu {start} od {partDayStartHour} do {partDayEndHour} *[{leaveStatus}]*\n",
  "bot.message.leavesHistoryNot": "Hm, wygląda na to, że nie miałeś jeszcze żadnych urlopów.",
  "bot.message.leaveTypeName": "*Typ urlopu:*\n{leaveTypeName}",
  "bot.message.leaveTypeWithHalfDay": "*Typ urlopu:*\n{leaveTypeName} (pół dnia)",
  "bot.message.manageLeaves.cancelRequest": "Anuluj wniosek",
  "bot.message.manageLeaves.confirmCancellation": "Proszę potwierdź, że chcesz anulować swój wniosek urlopowy",
  "bot.message.manageLeaves.summary": "Oto podsumowanie Twoich nadchodzących urlopów i oczekujących wniosków.",
  "bot.message.manageLeaves.summaryNoLeaves": "Nie masz żadnych zaplanowanych urlopów",
  "bot.message.manageLeaves.upcomingLeaves": "*Masz {upcomingLeaves} potwierdzony wniosek:*",
  "bot.message.manageLeaves.upcomingLeavesPlural": "*Masz {upcomingLeaves} potwierdzonych wniosków:*",
  "bot.message.manageLeaves.waitingLeaves": "*Masz {waitingLeaves} oczekujący wniosek:*",
  "bot.message.manageLeaves.waitingLeavesPlural": "*Masz {waitingLeaves} oczekujących wniosków:*",
  "bot.message.membersAway": "Nieobecni członkowie",
  "bot.message.membersAway.holiday": "- *{holidayLocationName}* będzie wolne z powodu *{holidayName}* w dniu {date}.\n",
  "bot.message.membersAway.leaveMessage": "- *{user}* jest na urlopie{leaveTypeName} dzisiaj {partDay}\n",
  "bot.message.membersAway.leaveMessage.fullDay": "- *{user}* jest na urlopie{leaveTypeName} od {startDate} do {endDate}\n",
  "bot.message.membersAway.leaveMessageWasWillBe": "- *{user}* {verb} na urlopie{leaveTypeName} w dniu {startDate}{partDay}\n",
  "bot.message.membersAway.leaveMessageWasWillBe.fullDay": "- *{user}* {verb} na urlopie{leaveTypeName} od {startDate} do {endDate}\n",
  "bot.message.membersAway.MEMBERS_AWAY_MONTH": "Członkowie zespołu, którzy są nieobecni w tym miesiącu.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_MONTH": "Członkowie zespołu, którzy będą nieobecni w przyszłym miesiącu.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_WEEK": "Członkowie zespołu, którzy będą nieobecni w przyszłym tygodniu.",
  "bot.message.membersAway.MEMBERS_AWAY_TODAY": "Członkowie zespołu, którzy są nieobecni dzisiaj.",
  "bot.message.membersAway.MEMBERS_AWAY_WEEK": "Członkowie zespołu, którzy są nieobecni w tym tygodniu.",
  "bot.message.membersAway.multiHoliday": "- *{holidayLocationName}* będzie wolne z powodu *{holidayName}* od {start} do {end}.\n",
  "bot.message.membersAway.noLeaves": "Obecnie nie ma zaplanowanych urlopów dla tego okresu w Twoim zespole 😎.",
  "bot.message.memersAway.noLeaves": "Obecnie nie ma zaplanowanych urlopów dla tego okresu w Twoim zespole 😎.",
  "bot.message.menageLeaves": "Zarządzaj urlopami",
  "bot.message.moreOptions": "Cześć <@{username}>!\nW czym mogę Ci pomóc?",
  "bot.message.moreOptions.about": "Więcej o mnie :koala:",
  "bot.message.moreOptions.leaveHistoryTitle": "Historia urlopów",
  "bot.message.moreOptions.leavesAndHolidaysTitle": "Twoje urlopy i święta:",
  "bot.message.moreOptions.membersAway": "Zobacz, którzy członkowie zespołu są nieobecni:",
  "bot.message.moreOptions.menageLeavesTitle": "Zarządzaj urlopami",
  "bot.message.moreOptions.showHolidaysTitle": "Pokaż święta",
  "bot.message.myStatus.currentYear": "_Bieżący rok, do {yearEnd}_",
  "bot.message.myStatus.leaveTypeStatusEarned": "*{leaveTypeName}*\nPozostało: {current}\nWykorzystano: {used}\nŁącznie: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedDays": "*{leaveTypeName}*\nPozostałe dni: {current}\nWykorzystane dni: {used}\nŁącznie dni: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedHours": "*{leaveTypeName}*\nPozostałe godziny: {current}\nWykorzystane godziny: {used}\nŁącznie godzin: {earned}",
  "bot.message.myStatus.leaveTypeStatusUnlimited": "*{leaveTypeName}*\nPozostałe dni: Nieograniczone\nWykorzystane dni: {usedDays}\nŁącznie dni: Nieograniczone",
  "bot.message.myStatus.nextYear": "_Następny rok, od {startYear} do {endYear}_",
  "bot.message.myStatus.summary": "Oto podsumowanie twoich urlopów na bieżący i następny rok.",
  "bot.message.noLeavePolicies": "Wygląda na to, że Twoja Lokalizacja nie ma przypisanych zasad urlopowych. 🤔 Skontaktuj się z Administratorem, aby uzyskać więcej informacji.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYear": "Użytkownik prosi o {leaveDurationTotal} dni urlopu, co przekracza limit o {overLimitCurrentYear} dzień w bieżącym roku i o {overLimitNextYear} dzień w następnym roku.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearDays": "Użytkownik prosi o {leaveDurationTotal} {leaveDurationTotal, plural, =1 {dzień} other {dni}}, co przekracza limit o {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {dzień} other {dni}} w bieżącym roku i o {overLimitNextYear} {overLimitNextYear, plural, =1 {dzień} other {dni}} w następnym roku.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearHours": "Użytkownik prosi o {leaveDurationTotal} {leaveDurationTotal, plural, =1 {godzinę} other {godzin}}, co przekracza limit o {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {godzinę} other {godzin}} w bieżącym roku i o {overLimitNextYear} {overLimitNextYear, plural, =1 {godzinę} other {godzin}} w następnym roku.",
  "bot.message.notification.overAllowedLimitForCurrentYear": "Użytkownik prosi o {leaveDurationCurrentYear} dzień urlopu, co przekracza dozwolony limit o {overLimit} dzień w bieżącym roku.",
  "bot.message.notification.overAllowedLimitForCurrentYearDays": "Użytkownik prosi o {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {dzień} other {dni}}, co przekracza dozwolony limit o {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {dzień} other {dni}} w bieżącym roku.",
  "bot.message.notification.overAllowedLimitForCurrentYearHours": "Użytkownik prosi o {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {godzinę} other {godzin}}, co przekracza dozwolony limit o {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {godzinę} other {godzin}} w bieżącym roku.",
  "bot.message.notification.overAllowedLimitForCurrentYearPlural": "Użytkownik prosi o {leaveDurationCurrentYear} dni urlopu, co przekracza dozwolony limit o {overLimit} dzień w bieżącym roku.",
  "bot.message.notification.overAllowedLimitForFollowingYear": "Użytkownik prosi o {leaveDurationNextYear} dzień urlopu, co przekracza dozwolony limit o {overLimit} dzień w następnym roku.",
  "bot.message.notification.overAllowedLimitForFollowingYearDays": "Użytkownik prosi o {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {dzień} other {dni}}, co przekracza dozwolony limit o {overLimitNextYear} {overLimitNextYear, plural, =1 {dzień} other {dni}} w następnym roku.",
  "bot.message.notification.overAllowedLimitForFollowingYearHours": "Użytkownik prosi o {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {godzinę} other {godzin}}, co przekracza dozwolony limit o {overLimitNextYear} {overLimitNextYear, plural, =1 {godzinę} other {godzin}} w następnym roku.",
  "bot.message.notification.overAllowedLimitForFollowingYearPlural": "Użytkownik prosi o {leaveDurationNextYear} dni urlopu, co przekracza dozwolony limit o {overLimit} dzień w następnym roku.",
  "bot.message.notSignedUpError": "Wygląda na to, że nie jesteś zarejestrowany w Vacation Tracker. Przejdź do [{link}]({utmLink}) i utwórz konto. To zajmie mniej niż minutę!",
  "bot.message.notSignedUpError.slack": "Wygląda na to, że nie jesteś zarejestrowany w Vacation Tracker. Kliknij przycisk poniżej, aby utworzyć konto. To zajmie mniej niż minutę!",
  "bot.message.on": "W dniu",
  "bot.message.openTheWebDashboard": "Otwórz panel internetowy",
  "bot.message.partDay": "od {partDayStartHour} do {partDayEndHour}",
  "bot.message.probationPeriod": "*Okres próbny*: \nTwój okres próbny trwa do {date} \n \nPowód: _{message}_",
  "bot.message.reminder": "Hej zespole! Przyjazne przypomnienie od bota Vacation Tracker",
  "bot.message.reminderSnoozed": "Przypomnienia o prośbach odłożone",
  "bot.message.requestLeave": "Wybierz jeden z dostępnych typów urlopu z poniższej listy i wybierz daty rozpoczęcia i zakończenia urlopu. Po kliknięciu przycisku Wyślij prośbę, Twoja prośba zostanie wysłana do zatwierdzenia.",
  "bot.message.requestLeave.error": "*Błąd: {errorMessage}*",
  "bot.message.requestLeave.errorAutomation": "{errorMessage}",
  "bot.message.requestLeave.initialHelp": "Jestem tu, aby pomóc Ci złożyć prośbę o urlop. 🐨 Proszę wybrać Typ Urlopu, a następnie kliknąć Dalej.",
  "bot.message.requestLeave.leaveDuration1": "To 1 dzień urlopu.",
  "bot.message.requestLeave.leaveDurationHalfDay": "To pół dnia urlopu",
  "bot.message.requestLeave.leaveDurationYear1": "To {year1Value} urlopu.",
  "bot.message.requestLeave.leaveDurationYear1And2": "To {year1Value} urlopu na bieżący rok i {year2Value} urlopu na następny rok.",
  "bot.message.requestLeave.leaveDurationYear1And2Days": "To {year1Value} {year1Value, plural, =1 {dzień} other {dni}} urlopu na bieżący rok i {year2Value} {year2Value, plural, =1 {dzień} other {dni}} urlopu na następny rok.",
  "bot.message.requestLeave.leaveDurationYear1And2Hours": "To {year1Value} {year1Value, plural, =1 {godzina} other {godzin}} urlopu na bieżący rok i {year2Value} {year2Value, plural, =1 {godzina} other {godzin}} urlopu na następny rok.",
  "bot.message.requestLeave.leaveDurationYear1Days": "To {year1Value} {year1Value, plural, =1 {dzień} other {dni}} urlopu.",
  "bot.message.requestLeave.leaveDurationYear1Hours": "To {year1Value} {year1Value, plural, =1 {godzina} other {godzin}} urlopu.",
  "bot.message.requestLeave.leaveDurationYear1PluralYear2": "To {year1Days} dni urlopu na bieżący rok i {year2Days} dzień urlopu na następny rok.",
  "bot.message.requestLeave.leaveDurationYear1Year2AllPlural": "To {year1Days} dni urlopu na bieżący rok i {year2Days} dni urlopu na następny rok.",
  "bot.message.requestLeave.leaveDurationYear2": "To {year2Value} urlopu na następny rok.",
  "bot.message.requestLeave.leaveDurationYear2Days": "To {year2Value} {year2Value, plural, =1 {dzień} other {dni}} urlopu na następny rok.",
  "bot.message.requestLeave.leaveDurationYear2Hours": "To {year2Value} {year2Value, plural, =1 {godzina} other {godzin}} urlopu na następny rok.",
  "bot.message.requestLeave.leaveDurationYear2Plural": "To {year2Days} dni urlopu na następny rok.",
  "bot.message.requestLeave.leaveDurationYear2PluralYear1": "To {year1Days} dzień urlopu na bieżący rok i {year2Days} dni urlopu na następny rok",
  "bot.message.requestLeave.selectCalendarStatus": "Wybierz status kalendarza",
  "bot.message.requestLeave.selectCalendarStatus.info": "*Status kalendarza*\nPokaż w kalendarzu jako",
  "bot.message.requestLeave.selectDate.from": "*Data początkowa*\nProszę wybrać datę początkową",
  "bot.message.requestLeave.selectDate.info": "*Data początkowa*\nProszę wybrać datę początkową",
  "bot.message.requestLeave.selectDate.oneDay": "*Data*\nProszę wybrać datę",
  "bot.message.requestLeave.selectDate.to": "*Data końcowa*\nProszę wybrać datę końcową",
  "bot.message.requestLeave.selectLeaveType": "Wybierz rodzaj urlopu",
  "bot.message.requestLeave.selectLeaveType.info": "*Rodzaj urlopu*\nProszę wybrać rodzaj urlopu",
  "bot.message.requestLeave.selectTimeFormat": "*AM/PM*",
  "bot.message.requestLeave.selectTimeRange": "Wybierz zakres czasu",
  "bot.message.requestLeave.selectTimeRange.end": "*Data końcowa*\nProszę wybrać datę końcową",
  "bot.message.requestLeave.selectTimeRange.from": "*Godzina rozpoczęcia*",
  "bot.message.requestLeave.selectTimeRange.info": "*Zakres czasu*\n Proszę wybrać zakres czasu",
  "bot.message.requestLeave.selectTimeRange.range": "*Zakres czasu*",
  "bot.message.requestLeave.selectTimeRange.to": "*Godzina zakończenia*",
  "bot.message.requestLeave.submitted.end": ":tada: *{message}*.\n Do następnego razu!",
  "bot.message.requestLeave.submitted.letTeamKnow": "P.S. Daj znać mojemu zespołowi, jeśli byłem pomocny!",
  "bot.message.requestLeave.submitted.withApproval": "Wybrane przez Ciebie daty zostały wysłane do zatwierdzenia przez Twojego przełożonego",
  "bot.message.requestLeave.submitted.withoutApproval": "Wybrane przez Ciebie daty zostały automatycznie zatwierdzone",
  "bot.message.requestLeaveModal.requestALeave": "Złóż wniosek o urlop",
  "bot.message.requestLeaveModal.requestingLeave": "Składanie wniosku o urlop",
  "bot.message.requestLeaveModal.sendFeedback": "Wyślij opinię",
  "bot.message.requestLeaveModal.sendRequest": "Wyślij wniosek",
  "bot.message.requestToil.initialHelp": "Jestem tu, aby pomóc Ci złożyć wniosek o TOIL. 🐨 Proszę wybrać rodzaj urlopu, a następnie kliknąć Dalej.",
  "bot.message.selectADate": "Wybierz datę",
  "bot.message.selectSubstituteApprover": "*Wybierz zastępcę zatwierdzającego*\nWybierz osobę, która będzie zarządzać Twoimi przychodzącymi wnioskami o urlop podczas Twojej nieobecności.",
  "bot.message.showHolidays.holidaysHalfDay": "- *{holidayLocationName}* będzie mieć wolne z powodu *{holidayName}* w dniu {date} (Pół dnia).\n",
  "bot.message.showHolidays.noUpcomingHolidays": "Nie ma nadchodzących świąt w Twojej organizacji lub administrator nie ustawił jeszcze żadnych świąt.",
  "bot.message.showHolidays.one": "- *{holidayLocationName}* będzie mieć wolne z powodu *{holidayName}* od {startDate} do {endDate}.\n",
  "bot.message.showHolidays.two": "- *{holidayLocationName}* będzie mieć wolne z powodu *{holidayName}* w dniu {date}.\n",
  "bot.message.showHolidays.upcomingHolidays": "Nadchodzące święta",
  "bot.message.slack.userChanged": "Użytkownik zmienił ten wniosek",
  "bot.message.slashCommand.helpCommand1": "*Oto trochę więcej informacji o mnie:*",
  "bot.message.slashCommand.helpCommand2": "Zaufany przez ponad 1000 organizacji na całym świecie, *Vacation Tracker umożliwia Twojemu zespołowi łatwe śledzenie, kto jest obecny i nieobecny w biurze przez cały czas.*",
  "bot.message.slashCommand.helpCommand3": "Dowiedz się więcej o naszej historii na naszej <https://vacationtracker.io/about|stronie O nas>.",
  "bot.message.slashCommand.helpCommand4": "Aby uzyskać szczegółowe instrukcje, odwiedź nasze <https://vacationtracker.crisp.help/en/|Centrum pomocy>.",
  "bot.message.slashCommand.helpCommand5": "Aby zalogować się do panelu online, odwiedź <{dashboardUrl}/connect?platform=slack>.",
  "bot.message.slashCommand.helpCommand6": "A jeśli kiedykolwiek będziesz nas potrzebować, jesteśmy tylko jeden e-mail dalej pod adresem hello@vacationtracker.io.",
  "bot.message.slashCommand.noPermissions": "Przepraszam, że jestem zwiastunem złych wieści, ale wygląda na to, że nie masz uprawnień dostępu do Vacation Tracker. 😟 Skontaktuj się z Administratorem.",
  "bot.message.slashCommand.title": "Cześć <@{userId}>! Chcesz wziąć trochę wolnego? Pozwól, że pomogę!",
  "bot.message.sorryLetsSpokePriveate": "Hmm, porozmawiajmy prywatnie. 🤫",
  "bot.message.sorryYouAreNotActive": "Przepraszamy, Twoje konto jest nieaktywne",
  "bot.message.sorryYouAreNotExist": "Przepraszamy, nie znaleziono Twojego konta",
  "bot.message.submittedLeaveRequest": "Witaj, <@{userSlackId}> złożył(a) następujący wniosek o urlop:",
  "bot.message.submittedLeaveRequestReminder": "Witaj, przypominam, że <@{name}> złożył(a) wniosek wymagający Twojej uwagi.",
  "bot.message.submittedLeaveRequestReminderExpiration": "⏰ Uwaga, ten wniosek wygaśnie za {days} dni. To jest przypomnienie {reminderNo} z 3.",
  "bot.message.substituteApproverPlaceholder": "🔍 Brak",
  "bot.message.unableToRequestLeave": "Nie można złożyć wniosku o urlop.",
  "bot.message.unknownCommand": "Przepraszam, nie rozumiem tego polecenia.\nWpisz **help**, aby zobaczyć listę dostępnych poleceń.",
  "bot.microsoft.whatsNewInfoMessages": "To jest ogłoszenie od **Vacation Tracker**. Otrzymujesz je, ponieważ jesteś administratorem lub osobą zatwierdzającą.",
  "bot.notification.accruedDaysInfoForApprover": "ℹ️ Ten wniosek o urlop opiera się na przyszłych naliczonych dniach wolnych, a nie na aktualnej ilości. @{name} ma obecnie {amount} dni pozostałych. Naliczą {accrued} w dniu {earningDate}.",
  "bot.notification.accruedDaysInfoForUser": "ℹ️ Twój wniosek o urlop opiera się na przyszłych naliczonych dniach wolnych, a nie na aktualnej ilości. Masz obecnie {amount} dni pozostałych, a naliczysz {accrued} w dniu {earningDate}.",
  "bot.notification.adminToApprovers": "Zostałeś(aś) mianowany(a) nowym Zatwierdzającym dla {teamName}, ponieważ konto poprzedniego Zatwierdzającego zostało dezaktywowane.",
  "bot.notification.approverCard.approved": "✅ @{approverName} zatwierdził(a) wniosek, nie są wymagane dalsze działania.",
  "bot.notification.approverCard.autoApproved": "Wniosek został automatycznie zatwierdzony, nie są wymagane dalsze działania.",
  "bot.notification.approverCard.cancelled": "⚠️ Wygląda na to, że {requesterName} zmienił(a) zdanie. Wniosek został anulowany.",
  "bot.notification.approverCard.cancelledLeave": "Witaj, {requesterName} anulował(a) swój urlop.",
  "bot.notification.approverCard.cancelledTitle": "⚠️ Ten urlop został anulowany przez zatwierdzającego.",
  "bot.notification.approverCard.denied": "❌ @{approverName} odrzucił(a) wniosek, nie są wymagane dalsze działania.",
  "bot.notification.approverCard.deniedEdited": "❌ @{approverName} odrzucił(a) edytowany wniosek, nie są wymagane dalsze działania.",
  "bot.notification.approverCard.editedByApprover": "@{approverName} edytował(a) wniosek o urlop złożony przez @{requesterName}",
  "bot.notification.approverCard.editedByApproverForUser": "@{approverName} edytował(a) wniosek o urlop dla @{requesterName}.",
  "bot.notification.approverCard.info": "Dodaj powód zatwierdzenia lub odrzucenia",
  "bot.notification.approverCard.notActive": "⚠️ Ten wniosek o urlop nie jest już aktywny.",
  "bot.notification.changeOutOfOfficeMessage": "Edytuj szablon",
  "bot.notification.daily.info": "Czas na Twoją codzienną aktualizację! 🐨 Oto nadchodzące urlopy:",
  "bot.notification.daily.noLeaves.isNotForToday": "Nie ma zaplanowanych urlopów na {dayName}",
  "bot.notification.daily.noLeaves.isToday": "Nie ma zaplanowanych urlopów na dzisiaj",
  "bot.notification.holiday.plural": "- Lokalizacje **{locationNames}** będą niedostępne z powodu **{holidayName}** {holidayPeriodText}",
  "bot.notification.holiday.singular": "- Lokalizacja **{locationNames}** będzie niedostępna z powodu **{holidayName}** {holidayPeriodText}",
  "bot.notification.leave.info.isInTheFuture": "będzie na urlopie{leaveTypeText} {leavePeriodText}",
  "bot.notification.leave.info.isInThePast": "będzie na urlopie{leaveTypeText} {leavePeriodText}",
  "bot.notification.leave.info.isToday": "jest na urlopie{leaveTypeText} {leavePeriodText}",
  "bot.notification.leaveDates.partDay": "{start} od {partDayStartHour} do {partDayEndHour}",
  "bot.notification.leavePeriod.fullDay": "w dniu {day}",
  "bot.notification.leavePeriod.halfDay": "w dniu {day} (pół dnia)",
  "bot.notification.leavePeriod.moreDays": "od {start} do {end}",
  "bot.notification.leavePeriod.partDay": "w dniu {start} od {partDayStartHour} do {partDayEndHour}",
  "bot.notification.leavePolicyInfo": "*Uwaga: dla tego użytkownika lub tego typu urlopu ustawiono limit 0 dla wnioskowanego urlopu.*",
  "bot.notification.leaveRequestError.general": "Błąd związany z wnioskiem o urlop",
  "bot.notification.leaveRequestError.notApprover": "Ta akcja jest dozwolona tylko dla zatwierdzających zespołu/administratorów",
  "bot.notification.leaveRequestError.notOpen": "Wniosek o urlop został {leaveRequestStatus}",
  "bot.notification.leaveRequestError.notOpenIsSameAppover": "Wniosek o urlop został {leaveRequestStatus}",
  "bot.notification.leaveRequestExpired": "Twój wniosek wygasł",
  "bot.notification.leaveRequestExpiredGeneral": "Wniosek wygasł",
  "bot.notification.leaveTypeText.isPartDay": "({leaveTypeName} - Pół dnia)",
  "bot.notification.oooDefaultMessage": "Witaj,\n\nDziękuję za wiadomość. Obecnie jestem poza biurem i nie będę dostępny/a do odpowiadania na e-maile do {firstWorkingDay}.\n\nPostaram się odpowiedzieć na Twoją wiadomość jak najszybciej po powrocie.\n\nDziękuję za zrozumienie.\n\nPozdrawiam",
  "bot.notification.processingApproval": "Przetwarzanie zatwierdzenia...",
  "bot.notification.processingDenial": "Przetwarzanie odrzucenia...",
  "bot.notification.processingResend": "Ponowne wysyłanie wniosku o urlop...",
  "bot.notification.processingSnooze": "Wyciszanie przypomnień...",
  "bot.notification.reconnectCalendar": "Połącz ponownie",
  "bot.notification.reminderSent": "Wysłano przypomnienie o wniosku urlopowym",
  "bot.notification.reminderSentToil": "Wysłano przypomnienie o wniosku TOIL",
  "bot.notification.requesterCard.addedLeave": "Utworzono dla Ciebie urlop",
  "bot.notification.requesterCard.addedLeaveBy": "Dodane przez {approverName}",
  "bot.notification.requesterCard.addedLeaveToil": "Utworzono dla Ciebie TOIL",
  "bot.notification.requesterCard.approvedTitle": "Twój wniosek o urlop został zatwierdzony",
  "bot.notification.requesterCard.approvedTitleToil": "Twój wniosek o TOIL został zatwierdzony",
  "bot.notification.requesterCard.approverInfo": "Zatwierdzone przez {approverName}",
  "bot.notification.requesterCard.autoApprovedInfo": "Wniosek został automatycznie zatwierdzony.",
  "bot.notification.requesterCard.blackoutPeriod": "Okres: {startDate} - {endDate}",
  "bot.notification.requesterCard.blackoutPeriodReason": "Powód: {message}",
  "bot.notification.requesterCard.blackoutPeriodTitle": "Okres blokady",
  "bot.notification.requesterCard.cancelled": "⚠️ Anulowano",
  "bot.notification.requesterCard.cancelledEdited": "⚠️ Wprowadzono zmiany do pierwotnego wniosku.",
  "bot.notification.requesterCard.cancelledEditedByRequester": "@{requesterName} edytował(a) ten wniosek o urlop.",
  "bot.notification.requesterCard.cancelledTitle": "⚠️ Twój urlop został anulowany przez zatwierdzającego.",
  "bot.notification.requesterCard.deniedBy": "Twój wniosek o urlop został zatwierdzony",
  "bot.notification.requesterCard.deniedEditedDescription": "Twój edytowany wniosek o urlop został odrzucony, jednak Twój pierwotny wniosek pozostaje aktywny.",
  "bot.notification.requesterCard.deniedEditedTitle": "Edycja wniosku o urlop odrzucona.",
  "bot.notification.requesterCard.deniedOpenDescription": "Zarówno Twój pierwotny wniosek o urlop, jak i edytowany wniosek zostały odrzucone.",
  "bot.notification.requesterCard.deniedOpenTitle": "Wniosek o urlop odrzucony",
  "bot.notification.requesterCard.deniedTitle": "Twój wniosek o urlop został odrzucony",
  "bot.notification.requesterCard.edited": "@{requesterName} edytował(a) swój wcześniej zatwierdzony wniosek i ponownie składa go do zatwierdzenia:",
  "bot.notification.requesterCard.editedOpen": "@{requesterName} edytował(a) i ponownie złożył(a) swój wniosek o urlop.",
  "bot.notification.requesterCard.moreDays": "❌ Poprosiłeś(aś) o więcej dni niż jest dostępnych.",
  "bot.notification.requesterCard.moreThanFullDay": "❌ Nie można złożyć wniosku o urlop, ponieważ poprosiłeś(aś) o więcej niż jeden pełny dzień urlopu w ciągu jednego dnia roboczego.",
  "bot.notification.requesterCard.noWorkingDays": "❌ Nie można złożyć wniosku o urlop, ponieważ nie uwzględniono żadnych dni roboczych. Upewnij się, że sprawdziłeś(aś) dni robocze swojego zespołu przed złożeniem wniosku.",
  "bot.notification.requesterCard.originalLeaveRequest": "Pierwotny wniosek urlopowy:",
  "bot.notification.requesterCard.overlap": "❌ Wniosek urlopowy nie może zostać złożony, ponieważ wybrane daty pokrywają się z datami istniejącego wniosku urlopowego.",
  "bot.notification.requesterCard.probationPeriod": "Twój okres próbny trwa do {date}",
  "bot.notification.requesterCard.probationPeriodReason": "Powód: {message}",
  "bot.notification.requesterCard.probationPeriodTitle": "Okres próbny",
  "bot.notification.requesterCard.requestInfo": "Twój wniosek został wysłany do zatwierdzającego. Do następnego razu! 🐨",
  "bot.notification.requesterCard.requestPossibleActions": "Aby edytować lub anulować wniosek, odwiedź Dashboard",
  "bot.notification.requesterCardapp.tryAnotherDates": "Spróbuj innych dat",
  "bot.notification.requesterInfo": "Witaj, @{userName} złożył(a) wniosek do rozpatrzenia.",
  "bot.notification.requesterInfoAutoApproved": "Witaj, @{userName} złożył(a) wniosek.",
  "bot.notification.requesterInfoEdited": "@{userName} edytował(a) i ponownie złożył(a) swój wniosek.",
  "bot.notification.requesterInfoEditedByApprover": "@{approverName} edytował(a) wniosek złożony przez @{userName}",
  "bot.notification.requesterInfoEditedForUser": "Twój wniosek został edytowany.",
  "bot.notification.requesterInfoEditedForUserByApprover": "Zatwierdzający @{approverName} wprowadził(a) zmiany w Twoim wniosku.",
  "bot.notification.requesterInfoEditedOpen": "@{userName} edytował(a) i ponownie złożył(a) swój wniosek.",
  "bot.notification.setOutOfOffice": "Twój urlop {leaveTypeName} ma się rozpocząć za mniej niż {days} {days, plural, =1 {dzień} other {dni}} w dniu {leavePeriodStart}. Czy chcesz ustawić automatyczną wiadomość o nieobecności, która będzie wysyłana podczas Twojej nieobecności?",
  "bot.notification.setOutOfOfficeButton": "Ustaw wiadomość OOO",
  "bot.notification.setOutOfOfficeFallbackText": "Ustaw automatyczną odpowiedź dla Twojego urlopu {leaveTypeName}",
  "bot.notification.slack.additional": "... i więcej.\nDodatkowe urlopy nie mogły zostać wyświetlone ze względu na limit rozmiaru. Otwórz Dashboard, aby uzyskać pełny przegląd.",
  "bot.notification.slack.approved": "✅ <@{approverName}> zatwierdził(a) wniosek, nie są wymagane dalsze działania.",
  "bot.notification.slack.approverChanged": "Witaj, zatwierdzający <@{approverSlackId}> zmienił(a) wniosek urlopowy.",
  "bot.notification.slack.approverChangedDatesOfLeaveReuquest": "Zatwierdzający <@{approverSlackId}> zmienił(a) daty wniosku",
  "bot.notification.slack.approverChangedLeaveReqest": "Witaj, zatwierdzający <@{approverSlackId}> zmienił(a) następujący wniosek urlopowy dla <@{userSlackId}>:",
  "bot.notification.slack.approverChangedYour": "Zatwierdzający <@{approverSlackId}> zmienił(a) Twój wniosek urlopowy.",
  "bot.notification.slack.approverHasDeniedThisRequest": "<@{approverSlackId}> odrzucił(a) ten wniosek, nie są wymagane dalsze działania",
  "bot.notification.slack.calendar": ":calendar: |  *{dayName}*  | :calendar: ",
  "bot.notification.slack.cancelled": "Witaj, <@{userName}> anulował(a) swój urlop.",
  "bot.notification.slack.cancelledByApprover": "Twój urlop został anulowany przez zatwierdzającego",
  "bot.notification.slack.cancelledInfo": "Ten urlop został anulowany przez zatwierdzającego",
  "bot.notification.slack.created": "Urlop został utworzony dla Ciebie przez zatwierdzającego",
  "bot.notification.slack.holiday.plural": "- Lokalizacje *{locationNames}* będą niedostępne z powodu *{holidayName}* {holidayPeriodText}",
  "bot.notification.slack.holiday.singular": "- Lokalizacja *{locationNames}* będzie niedostępna z powodu *{holidayName}* {holidayPeriodText}",
  "bot.notification.slack.holidays": ":airplane: *Święta: *",
  "bot.notification.slack.leave.info.isInTheFuture": "- *{userName}* będzie na urlopie{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leave.info.isInThePast": "- *{userName}* był(a) na urlopie{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leave.info.isToday": "- *{userName}* jest na urlopie{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leaveRequestCreated": "Witaj, <@{userName}> złożył(a) wniosek urlopowy.",
  "bot.notification.slack.leaveRequestInfo": "Witaj, <@{userName}> proszę potwierdź informacje o wniosku urlopowym.",
  "bot.notification.slack.leaveRequestInfoDateFrom": "Data rozpoczęcia: {date}",
  "bot.notification.slack.leaveRequestInfoDateTo": "Data zakończenia: {date}",
  "bot.notification.slack.leaveRequestInfoError": "Nie mogliśmy zasugerować urlopu na podstawie dostarczonych danych",
  "bot.notification.slack.leaveRequestInfoLeaveType": "Typ urlopu: {leaveType}",
  "bot.notification.slack.leaveRequestInfoReason": "Powód: {reason}",
  "bot.notification.slack.leaveRequestSuggestionCancelled": "Anulowano formularz wniosku o urlop ❌",
  "bot.notification.slack.leaves": ":loud_sound: *Urlopy: *",
  "bot.notification.slack.reason": "*Powód*\n{reason}",
  "bot.notification.slack.reasonEdited": "*Powód [Edytowany] *\n{reason}",
  "bot.notification.slack.requestedBy": "*Wnioskowane przez:*\n<@{userSlackId}>",
  "bot.notification.slack.statusApproved": "*Status:*\n✅ Zatwierdzony",
  "bot.notification.slack.statusAutoApproved": "*Status*\nAutomatycznie zatwierdzony",
  "bot.notification.slack.statusDenied": "*Status*\n❌ Odrzucony",
  "bot.notification.slack.statusInfo": "👋 Możemy teraz automatycznie ustawić Twój status Slack dla tego typu urlopu! Kliknij przycisk Włącz, aby pozwolić nam zaktualizować Twój status, gdy rozpocznie się Twój urlop. Wyczyścimy go, gdy się skończy.",
  "bot.notification.slack.thisLeaveHasBeenCancelledByAppover": "Ten urlop został anulowany przez osobę zatwierdzającą. Zobacz poniżej, aby uzyskać więcej informacji.",
  "bot.notification.slack.title": "Powiadomienie: \"_{title}_\"",
  "bot.notification.slack.userCancelledLeaveRequest": "Wygląda na to, że <@{userSlackId}> zmienił(a) zdanie. Wniosek został anulowany.",
  "bot.notification.slack.warning": ":warning: *Ostrzeżenie: {infoMessage}*",
  "bot.notification.slack.yourLeaveHasBeenCancelledByAppover": "Twój urlop został anulowany przez osobę zatwierdzającą. Zobacz poniżej, aby uzyskać więcej informacji.",
  "bot.notification.slack.yourLeaveRequestApproved": "Twój wniosek został zatwierdzony",
  "bot.notification.slack.yourRequestHasBeenDenied": "Twój wniosek został odrzucony",
  "bot.notification.snoozeOutOfOfficeButton": "Nie pytaj mnie ponownie",
  "bot.notification.snoozeOutOfOfficeMessage": "Nie będziemy już pytać o ustawienie statusu poza biurem dla {leaveTypeName}. Zawsze możesz zmienić to ustawienie na panelu internetowym Vacation Tracker - na stronie Ustawienia konta",
  "bot.notification.submittedLeaveRequestReminder": "Witaj, to tylko przypomnienie, że @{name} złożył(a) wniosek wymagający Twojej uwagi.",
  "bot.notification.substituteApproverDeleted": "Twoja rola jako Zastępczego Zatwierdzającego na daty {startDate} - {endDate} została usunięta.",
  "bot.notification.substituteApproverSelected": "Zostałeś wybrany przez {creatorName} jako zastępczy zatwierdzający dla działu(-ów) {teamNames} w imieniu {approverName} podczas jego nieobecności. Jako zastępczy zatwierdzający będziesz odpowiedzialny za przeglądanie i działanie w sprawie wniosków urlopowych składanych do {approverName} od {startDate} do {endDate}.",
  "bot.notification.substituteApproverUpdated": "Twój okres jako Zastępczy Zatwierdzający został zaktualizowany od {startDate} do {endDate}.",
  "bot.notification.title": "Powiadomienie: {title}",
  "bot.notification.weekly.current.info": "Cześć!\n 🐨 Jestem tu, aby zaktualizować Cię o zaplanowanych urlopach na bieżący tydzień ({start} - {end})",
  "bot.notification.weekly.next.info": "Cześć!\n 🐨 Jestem tu, aby zaktualizować Cię o zaplanowanych urlopach na przyszły tydzień ({start} - {end})",
  "bot.notification.weekly.noHolidays": "Nie ma świąt w tym tygodniu.",
  "bot.notification.weekly.noLeaves": "Nie ma zaplanowanych urlopów na ten tydzień.",
  "bot.slack.whatsNewInfoMessages": "To jest wiadomość z ogłoszeniem od *Vacation Tracker*. Otrzymujesz ją, ponieważ jesteś administratorem lub osobą zatwierdzającą.",
  "bot.slackFuckedUpAgain": "Slack znowu coś naprawiał, skontaktuj się z naszym wsparciem, aby naprawić błędy Slacka.",
  "bot.somethingNewFromVacationTracker": "Coś nowego od Vacation Tracker",
  "bot.welcomeMessage.furtherAssistance": "Czujesz się przytłoczony wszystkimi tymi informacjami? Nie martw się! Nie musisz zapamiętywać tego wszystkiego. Po prostu wpisz 'Help' na naszym czacie, a nasz bot wyśle Ci te informacje. Aby uzyskać dalszą pomoc, możesz porozmawiać z naszym zespołem obsługi klienta przez naszą stronę internetową lub odwiedzić nasze Helpdesk.",
  "bot.welcomeMessage.goToMyProfile": "Przejdź do zakładki Mój profil",
  "bot.welcomeMessage.greeting": "Witaj {name}, jestem Botem Vacation Tracker! Jestem tu, aby pomóc Ci planować urlopy przez Microsoft Teams, a także informować wszystkich o tym, kto jest w biurze, a kogo nie ma. Bez zbędnych ceregieli, zacznijmy śledzić te urlopy!",
  "bot.welcomeMessage.happyVacationing": "Udanego urlopowania 🌴",
  "bot.welcomeMessage.howToAccessTabs": "Jak uzyskać dostęp do zakładek",
  "bot.welcomeMessage.noCompany": "Witaj, \n\n Dziękujemy za zainstalowanie bota i zakładki Vacation Tracker w Microsoft Teams. Jako ostatni krok, przejdź na naszą stronę internetową, aby dokończyć rejestrację i skonfigurować ustawienia Twojej organizacji: [{dashboardLink}]({utmDashboardLink})",
  "bot.welcomeMessage.noCompanyInfo": "Jeśli masz jakiekolwiek pytania, możesz rozpocząć czat z naszym zespołem obsługi klienta na naszej stronie internetowej lub odwiedzić nasze helpdesk.",
  "bot.welcomeMessage.openWebDashboardInfo": "Te same informacje są również dostępne za pośrednictwem naszego panelu internetowego. Wystarczy kliknąć przycisk 'Otwórz Panel Internetowy' poniżej i zalogować się za pomocą konta Microsoft, aby uzyskać do niego dostęp.",
  "bot.welcomeMessage.requestLeaveInfo": "Aby rozpocząć, wystarczy wysłać mi bezpośrednią wiadomość z prośbą 'Poproś o urlop' lub obejrzeć ten krótki samouczek o Vacation Tracker:",
  "bot.welcomeMessage.restInfo": "Oprócz bota, możesz również wysyłać prośby o urlop, sprawdzać swoje limity urlopowe, przeglądać kalendarze zespołu i więcej z Microsoft Teams, korzystając z zakładek:",
  "bot.welcomeMessage.title": "Witaj w Vacation Tracker",
  "botUpdatePermissions.thanks": "Dziękujemy! Wszystko jest skonfigurowane. Możesz zamknąć panel.",
  "button.no": "Nie",
  "button.yes": "Tak",
  "calendar.all": "Wszystkie",
  "calendar.calendar": "Kalendarz",
  "calendar.filterByLocation": "Filtruj według Lokalizacji:",
  "calendar.filterByTeam": "Filtruj według Działu:",
  "calendar.forAllUsers": "Dla wszystkich użytkowników",
  "calendar.getCalendarLink": "Uzyskaj link do kalendarza",
  "calendar.halfdayTags": "Urlopy i święta krótsze niż pełny dzień są reprezentowane przez pasiaste bloki.",
  "calendar.halfdayTagsShort": "Urlopy krótsze niż dzień",
  "calendar.labels": "Etykieta(y)",
  "calendar.listView": "Widok listy",
  "calendar.location": "Lokalizacja(e)",
  "calendar.monthView": "Widok miesiąca",
  "calendar.sync.calendarAlreadyConnected": "Ten kalendarz jest już połączony",
  "calendar.sync.calendarNotFound": "Nie można znaleźć kalendarza. Mógł zostać usunięty lub nie jest już dostępny. Proszę sprawdzić ustawienia kalendarza.",
  "calendar.sync.canNotUpdateSharedCalendar": "Połączyłeś kalendarz {calendarName} {provider}. Zmiana kalendarza nie jest dozwolona. Aby zmienić połączony kalendarz, usuń tę integrację i utwórz nową.",
  "calendar.sync.confirmDelete": "Potwierdź, że chcesz usunąć kalendarz <strong>{name}</strong>.",
  "calendar.sync.connect": "Połącz swój Kalendarz {provider}",
  "calendar.sync.connectCalendar": "Połącz Kalendarz",
  "calendar.sync.connectCalendarCompletePlanyOnly": "TYLKO PLAN PEŁNY",
  "calendar.sync.connectCalendarIcalOptionLabel": "Dowolny kalendarz (przez link iCal)",
  "calendar.sync.connectCalendarOptionLabel": "Kalendarz {platform}",
  "calendar.sync.connectInfo": "Połącz swoje konto {provider} i wybierz współdzielony kalendarz, gdzie wszystkie zatwierdzone urlopy będą automatycznie przesyłane i synchronizowane.",
  "calendar.sync.createICalButton": "Utwórz link iCal",
  "calendar.sync.createICalFiltersInfo": "Do tego linku kalendarza iCal zostaną zastosowane następujące filtry:",
  "calendar.sync.createICalFiltersWarning": "Twój kalendarz nie odświeża danych iCal w czasie rzeczywistym. Opóźnienie może wynosić do 24 godzin (w zależności od dostawcy kalendarza). Aby uzyskać aktualizacje w czasie rzeczywistym, połącz bezpośrednio Kalendarz Google lub Kalendarz Microsoft Outlook.",
  "calendar.sync.createICalInfo": "Link do kalendarza iCal umożliwia subskrypcję i przeglądanie zaplanowanych urlopów zespołu z Vacation Tracker w dowolnym kalendarzu (w tym Kalendarz Google, Kalendarz Microsoft Outlook, Kalendarz Apple i wiele innych).",
  "calendar.sync.createPushToSharedInfo": "I przesyłaj oraz synchronizuj przefiltrowane zatwierdzone urlopy do następującego współdzielonego kalendarza {provider}:",
  "calendar.sync.createSyncCalendarButton": "Utwórz synchronizację kalendarza",
  "calendar.sync.createSyncCalendarFiltersInfo": "Vacation Tracker zastosuje następujące filtry do wszystkich zatwierdzonych urlopów:",
  "calendar.sync.creating": "Tworzenie synchronizacji kalendarza",
  "calendar.sync.creatingError": "Błąd podczas tworzenia synchronizacji kalendarza",
  "calendar.sync.creatingSuccess": "Tworzenie synchronizacji kalendarza powiodło się",
  "calendar.sync.deletedCalendar": "Ta synchronizacja kalendarza została usunięta",
  "calendar.sync.deleteTitle": "Usuń kalendarz",
  "calendar.sync.deleting": "Usuwanie synchronizacji kalendarza",
  "calendar.sync.deletingError": "Błąd podczas usuwania synchronizacji kalendarza",
  "calendar.sync.deletingSuccess": "Usunięcie synchronizacji kalendarza powiodło się",
  "calendar.sync.emptyDataMessage": "Synchronizuj i przeglądaj wszystkie urlopy zespołu w swoim współdzielonym kalendarzu. Możesz połączyć swój kalendarz Outlook lub Google Calendar, lub użyć iCal do synchronizacji Vacation Tracker z dowolnym innym typem kalendarza.",
  "calendar.sync.emptyDataTitle": "Synchronizuj urlopy z kalendarzem swojego zespołu",
  "calendar.sync.loginWithDifferentAccountInfo": "Nie widzisz kalendarza?",
  "calendar.sync.loginWithDifferentAccountLink": "Zaloguj się na inne konto {provider}",
  "calendar.sync.reconnect": "Połącz ponownie swój Kalendarz {provider}",
  "calendar.sync.reconnectInfo": "Połącz ponownie swoje konto {provider}, aby kontynuować automatyczną synchronizację urlopów z twoim współdzielonym kalendarzem.",
  "calendar.sync.reconnecting": "Ponowne łączenie synchronizacji kalendarza",
  "calendar.sync.reconnectingError": "Błąd podczas ponownego łączenia synchronizacji kalendarza",
  "calendar.sync.reconnectingSuccess": "Ponowne połączenie synchronizacji kalendarza powiodło się",
  "calendar.sync.selectSharedCalendarPlaceholder": "Wybierz współdzielony kalendarz {provider}",
  "calendar.sync.stepByStep": "Instrukcje krok po kroku",
  "calendar.sync.title": "Synchronizuj kalendarz swojego zespołu",
  "calendar.sync.tooltipInfo": "Wszystkie twoje zsynchronizowane kalendarze są zebrane na tej stronie dla łatwiejszego zarządzania. Aby dowiedzieć się więcej, zapoznaj się z naszym <helpDesk>artykułem w Helpdesku.</helpDesk>",
  "calendar.sync.update": "Zaktualizuj swój Kalendarz {provider}",
  "calendar.sync.updateSyncCalendarButton": "Zaktualizuj synchronizację kalendarza",
  "calendar.sync.updating": "Aktualizowanie synchronizacji kalendarza",
  "calendar.sync.updatingError": "Błąd podczas aktualizacji synchronizacji kalendarza",
  "calendar.sync.updatingSuccess": "Aktualizacja synchronizacji kalendarza powiodła się",
  "calendar.syncButton": "Synchronizuj ze swoim kalendarzem (Google, Outlook, itp.)",
  "calendar.syncModal.description": "Skopiuj poniższy link i zintegruj go w swoim Kalendarzu Google, Kalendarzu Outlook lub dowolnym innym kalendarzu obsługującym kanały kalendarza iCal.",
  "calendar.syncModal.filters": "Do tego linku kalendarza zastosowano następujące filtry:",
  "calendar.syncModal.helpdesk": "Odwiedź nasz <helpDesk>Helpdesk</helpDesk>, aby uzyskać pomoc i instrukcje krok po kroku.",
  "calendar.team": "Dział(y)",
  "calendar.timezone.info": "Czas jest przeliczany na Twoją Strefę Czasową",
  "calendar.week.monFri": "Pon-Pt",
  "calendar.week.sunThu": "Nie-Czw",
  "calendarListModal.description": "Proszę wybrać kalendarz z poniższej listy dostępnych kalendarzy. Po połączeniu kalendarza, Vacation Tracker będzie dodawać, edytować lub anulować Twoje urlopy w wybranym kalendarzu.",
  "calendarListModal.descriptionGoogleFirstLine": "Proszę wybrać kalendarz z poniższej listy dostępnych kalendarzy. ",
  "calendarListModal.descriptionOutlookFirstLine": "Możesz połączyć tylko swój domyślny kalendarz. ",
  "calendarListModal.descriptionSecondLine": "Po połączeniu kalendarza, Vacation Tracker będzie dodawać, edytować lub anulować Twoje urlopy w wybranym kalendarzu.",
  "calendarListModal.hiddenLeaveTypesDescription": "Dowiedz się więcej o <helpdesk>wyświetlaniu ukrytych typów urlopów</helpdesk>.",
  "calendarListModal.reconnectDescription": "Połącz ponownie swój kalendarz, aby kontynuować automatyczną synchronizację urlopów z Twoim połączonym kalendarzem.",
  "calendarListModal.reconnectTitle": "Ponowne połączenie kalendarza",
  "calendarListModal.selectPlaceHolder": "Proszę wybrać kalendarz...",
  "calendarListModal.title": "Wybierz Kalendarz",
  "channel_not_found": "Wybrany kanał został usunięty lub nie jest już dostępny. Proszę wybrać inny kanał do otrzymywania Powiadomień.",
  "choiceAutomations.accessibleTo": "Dostępne dla <approver>Zatwierdzającego </approver><and>i </and><admin>Administratora </admin><only>tylko</only>",
  "choiceAutomations.activeSubscribedAddons": "Aktywne subskrybowane dodatki",
  "choiceAutomations.activeSubscribedAutomations": "Aktywne subskrybowane automatyzacje",
  "choiceAutomations.adpIntegration": "Zapewnia bezpośrednie połączenie z narzędziem płacowym, gwarantując dokładność i aktualność danych płacowych we wszystkich platformach.",
  "choiceAutomations.automationPrice": "Cena: ${price}/użytkownik/miesiąc",
  "choiceAutomations.automationPriceInfo": "Możesz kupić i aktywować dowolną z poniższych automatyzacji za $0,50/użytkownik/miesiąc",
  "choiceAutomations.bambooHrIntegration": "Zapewnia bezpośrednie połączenie z narzędziem HR, utrzymując spójność i dokładność danych w różnych systemach.",
  "choiceAutomations.blackoutPeriodInfo": "Okres blokady to określony czas, w którym pracownicy nie mogą brać urlopu z pracy.",
  "choiceAutomations.cancelAtPeriodEnd": "Aktywne do końca okresu rozliczeniowego",
  "choiceAutomations.cancelAtPeriodEndTooltip": "Zrezygnowałeś z subskrypcji tego dodatku. Pozostanie on aktywny do końca bieżącego okresu rozliczeniowego, po czym zostanie dezaktywowany.",
  "choiceAutomations.comingSoonScheduleCall": "Możesz podzielić się szczegółami dotyczącymi Twoich konkretnych potrzeb związanych z automatyzacją, nad którą pracujemy.",
  "choiceAutomations.comingSoonScheduleCallTitle": "Wkrótce dostępne",
  "choiceAutomations.deacticate": "Anuluj subskrypcję",
  "choiceAutomations.employmentLeaveRestrictionInfo": "Polityka urlopowa dla nowych pracowników pozwala zapewnić dedykowany czas na aklimatyzację w pierwszych dniach zatrudnienia poprzez ograniczenie wniosków urlopowych nowych pracowników.",
  "choiceAutomations.enitlementByRoleShortInfo": "Pracownicy otrzymują dodatkowe dni urlopowe na podstawie ich roli, zdefiniowanej przez etykietę(y).",
  "choiceAutomations.entitlementByRoleInfo": "Pracownicy otrzymują dodatkowe dni urlopowe na podstawie ich roli, zdefiniowanej przez etykietę(y). Na przykład, możesz utworzyć etykietę 'Menedżer' i przypisać ją wszystkim menedżerom w Twojej organizacji. Następnie możesz utworzyć automatyzację, która przyznaje 5 dodatkowych dni urlopowych wszystkim pracownikom z etykietą 'Menedżer'. Możesz utworzyć wiele automatyzacji dla różnych ról. Ta automatyzacja jest ważna od bieżącego roku podatkowego. <link>Skontaktuj się z nami</link>, jeśli chcesz zastosować tę automatyzację do poprzednich lat.",
  "choiceAutomations.includedInYourPlan": "Zawarte w Twoim planie",
  "choiceAutomations.leaveDurationLimitInfo": "Dostosuj czas trwania urlopu: Ustaw minimalną i maksymalną liczbę dni na wybrany okres.",
  "choiceAutomations.mySbscribedAddons": "Moje subskrybowane dodatki",
  "choiceAutomations.notActivatedAddons": "Dostępne do subskrypcji",
  "choiceAutomations.openApiInfo": "Zapewnia bezproblemową integrację z zewnętrznymi systemami, umożliwiając automatyczną synchronizację danych i rozszerzoną funkcjonalność",
  "choiceAutomations.reviewAndBuy": "Przejrzyj i kup",
  "choiceAutomations.selectAutomations": "Wybierz preferowaną automatyzację",
  "choiceAutomations.seniorityEntitlementInfo": "Uprawnienie stażowe to system, w którym pracownicy zyskują więcej płatnego urlopu (PTO) w oparciu o długość ich stażu pracy w firmie. Obecnie można utworzyć tylko jedno uprawnienie stażowe dla każdej lokalizacji. Ta automatyzacja jest ważna od bieżącego roku podatkowego. <link>Skontaktuj się z nami</link>, jeśli potrzebujesz więcej niż jednego uprawnienia stażowego w lokalizacji lub chcesz zastosować tę automatyzację do poprzednich lat.",
  "choiceAutomations.seniorityEntitlementShortInfo": "Uprawnienie stażowe to system, w którym pracownicy zyskują więcej płatnego urlopu (PTO) w oparciu o długość ich stażu pracy w firmie.",
  "choiceAutomations.teamLeaveBalancingInfo": "Zarządzaj procentem jednoczesnych urlopów zespołu.",
  "choiceAutomations.visibilityInfo": "Pozwala administratorom dostosować widoczność informacji o urlopach dla zwykłych użytkowników, poprawiając prywatność i trafność danych.",
  "company.platformChanged": "Migracja na nową platformę",
  "company.platformChanged.confirmDescription": "Zamierzasz migrować na nową platformę. Ta akcja jest nieodwracalna. Proszę przejrzeć poniższe informacje przed kontynuowaniem.",
  "company.platformChanged.confirmDescription.unmappedUsers.deleted": "Znaleźliśmy {unmappedUsers} użytkowników, których nie ma na Twoim koncie {newPlatform}. Ci użytkownicy zostaną oznaczeni jako USUNIĘCI po zakończeniu migracji.",
  "company.platformChanged.confirmDescription.unmappedUsers.unmatched": "Znaleźliśmy {unmappedUsers} użytkowników, których nie ma na Twoim koncie {newPlatform}. Ci użytkownicy nie będą mogli zalogować się do Vacation Tracker.",
  "company.platformChanged.confirmQuestion": "Czy na pewno chcesz kontynuować?",
  "company.platformChanged.confirmTitle": "Potwierdź migrację",
  "company.platformChanged.description": "Proszę dopasować użytkowników Vacation Tracker do ich kont {newPlatform}. Automatycznie dopasujemy użytkowników o tych samych adresach e-mail.",
  "company.platformChanged.googleAccount": "Konto Google",
  "company.platformChanged.hideInactive": "Ukryj nieaktywne:",
  "company.platformChanged.markUnmappedAsDeleted": "Oznaczyć niedopasowanych użytkowników jako usunięci?",
  "company.platformChanged.microsoftAccount": "Konto Microsoft",
  "company.platformChanged.slackAccount": "Konto Slack",
  "company.platformChanged.title": "Migracja z {oldPlatform} do {newPlatform}",
  "company.platformChanged.unmatched": "Nie znaleziono w {platform}",
  "company.platformChanged.vacationTrackerUser": "Użytkownik Vacation Tracker",
  "component.filterSimple.filterBy": "Filtruj według",
  "component.filterSimple.selectType": "Wybierz typ",
  "component.filterSimple.tooltipInfo": "Jeśli nie wybierzesz żadnych filtrów, eksport będzie zawierał wszystkich użytkowników.",
  "component.notificationDestination.label": "Wyślij do",
  "component.notificationDestination.tooltip": "Pamiętaj, że możesz wysłać powiadomienie na wiele adresów e-mail, ale tylko na jeden kanał jednocześnie.",
  "component.password.timeToHack.days": "{duration} dni",
  "component.password.timeToHack.hours": "{duration} godzin",
  "component.password.timeToHack.instantly": "Natychmiast",
  "component.password.timeToHack.label": "Czas potrzebny hakerom do złamania tego hasła:",
  "component.password.timeToHack.minutes": "{duration} minut",
  "component.password.timeToHack.perfect": "Dłużej niż życie",
  "component.password.timeToHack.seconds": "{duration} sekund",
  "component.password.timeToHack.years": "{duration} lat",
  "component.setPassword.enterOldPassword": "Wprowadź stare hasło",
  "component.setPassword.enterPassword": "Wprowadź hasło",
  "component.setPassword.tooltip.description.line1": "Powyższe informacje pokazują szacowany maksymalny czas potrzebny hakerom do złamania wybranego hasła metodą brute force. Oszacowanie oparte jest na tabeli <italic>\"Tabela haseł do łamania funkcji skrótu PBKDF2 przy użyciu GPU RTX 3090\"</italic> z <link>tego artykułu</link>.",
  "component.setPassword.tooltip.description.line2": "Jak wybrać dobre hasło? Jest wiele sposobów na wybór dobrego hasła. My używamy menedżerów haseł i nie używamy ponownie haseł. Spójrz na ten <link>zabawny, ale poprawny przewodnik</link> po więcej inspiracji.",
  "component.setPassword.tooltip.title": "Czas potrzebny hakerom do złamania Twojego hasła metodą brute force",
  "component.setPassword.validation": "Hasło musi zawierać co najmniej: 8 znaków, jedną małą literę, jedną cyfrę i jeden symbol.",
  "components.accrualsCalculator.calculate": "Oblicz",
  "components.accrualsCalculator.calculateAccrualsDays": "Oblicz dni naliczenia",
  "components.accrualsCalculator.youWillEarnedInCurrentPeriod": "Zarobisz <strong>{days}</strong> dni w bieżącym okresie w tym dniu",
  "components.accrualsCalculator.youWillEarnedInNextPeriod": "Zarobisz <strong>{days}</strong> dni w następnym okresie w tym dniu",
  "components.addHolidayForm.addHolidays": "Dodaj święto",
  "components.addHolidayForm.addHolidays.cancel": "Anuluj",
  "components.addHolidayForm.addHolidays.isHalfDayHoliday": "Święto połowy dnia",
  "components.addHolidayForm.addHolidays.isHolidayMultiday": "Więcej niż jeden dzień?",
  "components.addHolidayForm.addHolidays.saveHoliday": "Zapisz",
  "components.addHolidayForm.date.error": "Proszę wybrać datę",
  "components.addHolidayForm.editHoliday": "Edytuj święto",
  "components.addHolidayForm.editHolidays": "Edytuj święta",
  "components.addHolidayForm.endDate.error": "Proszę wybrać datę końcową",
  "components.addHolidayForm.holidaysName": "Nazwa święta",
  "components.addHolidayForm.holidaysName.error": "Proszę wprowadzić nazwę święta",
  "components.addHolidayForm.startDate.error": "Proszę wybrać datę początkową",
  "components.announcementModal.announceToCompany": "Czy chcesz poinformować swój zespół, że korzystasz z Vacation Tracker?",
  "components.announcementModal.send": "Wyślij ogłoszenie",
  "components.automationBlackoutSummaryInfo.blacoutSummary": "{noOfUsers} użytkownik<multiUsers>ów</multiUsers> <link>(Zobacz użytkownik<multiUsers>ów</multiUsers>)</link> nie będzie mógł wziąć urlopu {leaveTypes},<endDate> między</endDate> {fromDate} <endDate>a {toDate}</endDate><recurring> każdego roku.</recurring>",
  "components.billing.email": "E-mail do rozliczeń",
  "components.billing.emailPlaceholder": "Wprowadź adres e-mail.",
  "components.billing.emailsValidationRequired": "Adres e-mail jest wymagany do celów rozliczeniowych.",
  "components.billing.resubscribe": "Odnów subskrypcję",
  "components.billingCouponCode.activeCouponInfo": "<percentOffCover>{off}%</percentOffCover><amountOffCover>US${off}</amountOffCover> zniżki <durationCover>{duration}</durationCover><durationInMonthsCover>{duration} miesiąc</durationInMonthsCover>, od {start}",
  "components.billingCouponCode.activeCouponInfoTitle": "Aktywny kod kuponu",
  "components.billingCouponCode.applyCode": "Zastosuj kod",
  "components.billingCouponCode.applyCodeAndResubscribe": "Zastosuj kod i odnów subskrypcję",
  "components.billingCouponCode.couponCode": "Kod kuponu",
  "components.billingCouponCode.text": "Masz kod kuponu? Wprowadź go tutaj, aby zastosować do swojej subskrypcji.",
  "components.billingCouponCode.title": "Kupony",
  "components.billingEmails.emailPlaceholder": "Wprowadź adres e-mail.",
  "components.billingEmails.emails": "E-maile",
  "components.billingEmails.emailsValidationRequired": "Adres e-mail jest wymagany do celów rozliczeniowych.",
  "components.billingEmails.title": "E-maile rozliczeniowe",
  "components.billingForm.address1": "Adres - linia 1",
  "components.billingForm.address1ValidationRequired": "Adres jest wymagany.",
  "components.billingForm.address2": "Adres - linia 2",
  "components.billingForm.billingEmails": "E-mail rozliczeniowy",
  "components.billingForm.billingEmailsPlaceholder": "Wprowadź adres e-mail.",
  "components.billingForm.billingEmailsValidationRequired": "Adres e-mail jest wymagany do celów rozliczeniowych.",
  "components.billingForm.ccRequired": "Numer karty jest wymagany.",
  "components.billingForm.city": "Miasto",
  "components.billingForm.cityValidationRequired": "Miasto jest wymagane.",
  "components.billingForm.companyName": "Nazwa firmy",
  "components.billingForm.companyNameValidationRequired": "Nazwa firmy jest wymagana.",
  "components.billingForm.country": "Kraj",
  "components.billingForm.countryValidationRequired": "Kraj jest wymagany.",
  "components.billingForm.couponCode": "Kod kuponu",
  "components.billingForm.coupons": "Kupony",
  "components.billingForm.description": "Proszę wprowadzić informacje o płatności w powyższym formularzu, aby opłacić subskrypcję kartą kredytową.",
  "components.billingForm.invoiceDescription": "Jeśli osoba odpowiedzialna za opłacenie subskrypcji nie jest użytkownikiem Slack lub Microsoft Teams, możemy wysłać im fakturę e-mailem, którą można opłacić kartą kredytową.",
  "components.billingForm.invoiceLabel": "Wyślij faktury e-mailem",
  "components.billingForm.invoiceTitle": "Płatność fakturą wysłaną e-mailem",
  "components.billingForm.paymentCC": "Karta kredytowa",
  "components.billingForm.paymentTitle": "Informacje o płatności",
  "components.billingForm.planTitle": "Plan rozliczeniowy ",
  "components.billingForm.state": "Stan/Województwo",
  "components.billingForm.stateValidationRequired": "Stan/Województwo jest wymagane.",
  "components.billingForm.title": "Informacje rozliczeniowe ",
  "components.billingInfo.address": "Adres",
  "components.billingInfo.canceledDescription": "Anulowałeś swoją subskrypcję Vacation Tracker. Pozostanie ona aktywna do {periodEnd} ",
  "components.billingInfo.canceledTrialDescription": "Twój okres próbny pozostanie aktywny do {periodEnd}, po tym czasie Twoje konto Vacation Tracker zostanie anulowane. ",
  "components.billingInfo.companyName": "Nazwa firmy",
  "components.billingInfo.email": "E-mail do rozliczeń",
  "components.billingInfo.resubscribe": "Odnów subskrypcję",
  "components.billingInfo.text": "Twoje dane rozliczeniowe są używane do przetwarzania płatności za subskrypcję. Możesz tutaj zaktualizować swoje dane rozliczeniowe i dodać informacje o karcie kredytowej.",
  "components.billingInfo.title": "Informacje rozliczeniowe ",
  "components.billingInvoice.description": "Możesz zarządzać metodami płatności i fakturami w naszym portalu klienta. <link>Kliknij tutaj, aby odwiedzić portal klienta</link><creatingLink>Tworzenie linku do portalu klienta</creatingLink>",
  "components.billingInvoice.title": "Faktury",
  "components.cancelSubscriptionModal.anotherLeaveTrackingTool": "Znaleźliśmy inne narzędzie do śledzenia urlopów",
  "components.cancelSubscriptionModal.anotherLeaveTrackingToolAdditionalInfo": "Czy możesz nam powiedzieć, na jakie narzędzie się przerzucasz?",
  "components.cancelSubscriptionModal.cancelText": "Nie anuluj subskrypcji",
  "components.cancelSubscriptionModal.contactInfo": "W przypadku wszystkich innych spraw prosimy o kontakt z naszym wsparciem pod adresem {email}",
  "components.cancelSubscriptionModal.info": "Czy możesz nam powiedzieć, jaki jest powód anulowania subskrypcji Vacation Tracker?",
  "components.cancelSubscriptionModal.min20characters": "Minimum 20 znaków",
  "components.cancelSubscriptionModal.missingFeatures": "Narzędzie nie ma funkcji potrzebnych mojemu zespołowi",
  "components.cancelSubscriptionModal.missingFeaturesAdditionalInfo": "Czy możesz nam powiedzieć w kilku słowach, jakiej funkcji brakuje?",
  "components.cancelSubscriptionModal.moreInfo": "Co moglibyśmy zrobić, aby zatrzymać Cię jako klienta?",
  "components.cancelSubscriptionModal.okText": "Anuluj teraz",
  "components.cancelSubscriptionModal.option1": "Przeszliśmy na oprogramowanie HR",
  "components.cancelSubscriptionModal.option2": "Znaleźliśmy inne narzędzie do śledzenia urlopów",
  "components.cancelSubscriptionModal.option3": "Narzędzie nie ma funkcji potrzebnych mojemu zespołowi",
  "components.cancelSubscriptionModal.option4": "Cena przekracza nasz budżet",
  "components.cancelSubscriptionModal.option5": "Inne",
  "components.cancelSubscriptionModal.other": "Inne",
  "components.cancelSubscriptionModal.otherAdditionalInfo": "Inne",
  "components.cancelSubscriptionModal.priceOverBudget": "Cena przekracza nasz budżet",
  "components.cancelSubscriptionModal.priceOverBudgetAdditionalInfo": "Jaki jest Twój budżet na tego typu narzędzie?",
  "components.cancelSubscriptionModal.title": "Przykro nam, że odchodzisz...",
  "components.cancelSubscriptionModal.weAreMovingToPayroll": "Przechodzimy na system płacowy",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfo": "Czy możesz nam powiedzieć, na jaki system płacowy się przerzucacie?",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfoIntegration": "Gdybyśmy mieli integrację z tym systemem płacowym, czy rozważyłbyś pozostanie?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolution": "Przeszliśmy na oprogramowanie HR",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfo": "Czy możesz nam powiedzieć, na jaki system HR się przerzucacie?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfoIntegration": "Gdybyśmy mieli integrację z tym systemem HR, czy rozważyłbyś pozostanie?",
  "components.cancelTrialSubscriptionModal.cancelText": "Kontynuuj okres próbny",
  "components.cancelTrialSubscriptionModal.info": "Proszę wyjaśnij w jednym zdaniu, dlaczego chcesz anulować swój okres próbny?",
  "components.cancelTrialSubscriptionModal.okText": "Anuluj okres próbny",
  "components.cancelTrialSubscriptionModal.title": "Przykro nam, że odchodzisz...",
  "components.changeTeamForm.changeTeamWarning": "Wybierz dział, do którego chcesz przenieść użytkownika.",
  "components.changeTeamForm.changeUserTeam": "Zmień dział użytkownika",
  "components.changeTeamForm.selectTeam": "Wybierz dział",
  "components.changeTeamForm.selectTeamError": "Proszę wybrać dział.",
  "components.companyAlreadySignup.connectWith": " Połącz z {existsOnAnotherPlatform}",
  "components.companyAlreadySignup.info": "Zauważyliśmy, że Twoja firma ma już konto w Vacation Tracker. Jeśli chciałeś się zalogować, po prostu kliknij tutaj:",
  "components.companyAlreadySignup.signupWithMail": "Połącz się za pomocą służbowego adresu e-mail",
  "components.companyAlreadySignup.title": "Twoja firma już korzysta z Vacation Tracker",
  "components.companyLogs.dateRangeLimit": "Logi starsze niż 3 miesiące są dostępne tylko w pełnym planie.",
  "components.companyLogs.rangeLimit": "Zakres dat może wynosić maksymalnie jeden rok",
  "components.companySettings.contactEmailInfo": "Nowy firmowy adres e-mail do kontaktu to: {email}.",
  "components.comparePlatformsModal.forMoreInfoSeeOurHelpdesk": "Aby dowiedzieć się więcej, odwiedź nasz <helpDesk>Helpdesk</helpDesk>.",
  "components.comparePlatformsModal.info": "Niezależnie od wybranej platformy, będziesz mieć dostęp do wszystkich głównych funkcji Vacation Tracker. Niektóre jednak mają dodatkowe korzyści, które nie są dostępne w innych. Zapoznaj się z poniższą tabelą porównawczą.",
  "components.comparePlatformsModal.table.comingSoon": "Wkrótce",
  "components.comparePlatformsModal.table.email": "E-mail",
  "components.comparePlatformsModal.table.feature": "Funkcja",
  "components.comparePlatformsModal.table.google": "Google",
  "components.comparePlatformsModal.table.importUsersFromGoogleWorkspace": "Importuj użytkowników z Google Workspace",
  "components.comparePlatformsModal.table.importUsersFromMicrosoftTeams": "Importuj użytkowników z Microsoft Active Directory",
  "components.comparePlatformsModal.table.importUsersFromSlack": "Importuj użytkowników z przestrzeni roboczej Slack",
  "components.comparePlatformsModal.table.inviteUsersViaEmail": "Zaproś użytkowników przez e-mail",
  "components.comparePlatformsModal.table.manageLeaveTypes": "Zarządzaj typami urlopów, świętami i wnioskami o urlop",
  "components.comparePlatformsModal.table.microsoft": "Microsoft",
  "components.comparePlatformsModal.table.multiTeamLocation": "Konfiguruj wiele działów i lokalizacji z różnymi zasadami urlopowymi",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromMicrosoftTeams": "Składaj i zarządzaj wnioskami urlopowymi bezpośrednio z Microsoft Teams",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromSlack": "Składaj i zarządzaj wnioskami urlopowymi bezpośrednio ze Slack",
  "components.comparePlatformsModal.table.slack": "Slack",
  "components.comparePlatformsModal.table.syncToGoogleCalendar": "Synchronizuj Vacation Tracker z Kalendarzem Google",
  "components.comparePlatformsModal.table.syncToOutlookCalendar": "Synchronizuj Vacation Tracker z Kalendarzem Outlook 365",
  "components.comparePlatformsModal.title": "Która integracja Vacation Tracker jest dla mnie odpowiednia?",
  "components.configureImport.configureProratedLeaveTypesErrorMessage": "Musisz wybrać co najmniej jeden typ urlopu",
  "components.configureImport.defaultDepartmentTooltip": "Wszyscy importowani użytkownicy zostaną dodani do tego działu. Po zaimportowaniu możesz przenieść ich do innego działu.",
  "components.configureImport.defaultLocationTooltip": "Wszyscy importowani użytkownicy zostaną dodani do tej lokalizacji. Po zaimportowaniu możesz przenieść ich do innej lokalizacji.",
  "components.configureImport.guestUsers": "Czy chcesz importować wielokanałowych użytkowników-gości?",
  "components.configureImport.guestUsersTooltip": "Jeśli ta opcja jest włączona, wielokanałowi użytkownicy-goście z Twojej przestrzeni roboczej zostaną zaimportowani do Vacation Tracker.",
  "components.configureImport.proRateLabel": "Proporcjonalnie dostosować limit urlopowy nowych użytkowników na ten rok?",
  "components.configureImport.proRateLeaveTypes": "Zastosuj proporcjonalne dostosowanie do następujących typów urlopów",
  "components.configureImport.proRateLeaveTypesTooltip": "Proszę wymienić typy urlopów, dla których chciałbyś proporcjonalnie dostosować dostępne limity urlopowe użytkowników.",
  "components.configureImport.proRateTooltip": "Wybierz, czy chcesz, aby nasz system obliczył dostępny limit urlopowy importowanych użytkowników na początkowy rok w oparciu o ustawienia firmy i datę rozpoczęcia pracy użytkownika.",
  "components.createLeaveType.assignToLocationManyInProgress": "Przypisywanie polityki urlopowej do lokalizacji",
  "components.createLeaveType.assignToLocationManyInProgressCompleted": "Przypisywanie polityki urlopowej do lokalizacji zakończone",
  "components.createLeaveType.assignToLocationOneInProgress": "Przypisywanie polityki urlopowej do lokalizacji",
  "components.createLeaveType.assignToLocationOneInProgressCompleted": "Przypisywanie polityki urlopowej do lokalizacji zakończone",
  "components.createLeaveType.createInProgress": "Tworzenie typu urlopu {leaveTypeName}",
  "components.createLeaveTypeForm.assignAndConfigure": "Przypisz i skonfiguruj",
  "components.createLeaveTypeForm.assignToLocations": "Przypisz do lokalizacji",
  "components.createLeaveTypeForm.calendarStatusBusy": "Zajęty",
  "components.createLeaveTypeForm.calendarStatusFree": "Wolny",
  "components.createLeaveTypeForm.calendarStatusLabel": "Pokaż ten typ urlopu w kalendarzu jako",
  "components.createLeaveTypeForm.calendarStatusLabelTooltipInfo": "Wybierz 'Zajęty' dla całkowitej nieobecności jak wakacje, sygnalizując niedostępność, lub 'Wolny' dla dni pracy zdalnej, wskazując że nie jesteś w biurze, ale jesteś dostępny.",
  "components.createLeaveTypeForm.continueCreatingLeaveType": "Tak, chcę utworzyć typ urlopu",
  "components.createLeaveTypeForm.createAndAssign": "Utwórz i przypisz",
  "components.createLeaveTypeForm.createLeaveType": "Utwórz typ urlopu",
  "components.createLeaveTypeForm.justLastStep.assign": "Przypisz",
  "components.createLeaveTypeForm.justLastStep.title": "Przypisz politykę urlopową",
  "components.createLeaveTypeForm.Locations": "Lokalizacje",
  "components.createLeaveTypeForm.locationsInfo": "Przypisz <strong>{leaveTypeName}</strong> do wszystkich lokalizacji lub konkretnej lokalizacji.",
  "components.createLeaveTypeForm.locationsPolicyHint": "Aby edytować politykę (zasady) dla tego typu urlopu, przejdź do konkretnej {locations, plural, =1 {Lokalizacji} other {Lokalizacji}}:",
  "components.createLeaveTypeForm.selectLocation": "Wybierz lokalizację",
  "components.createLeaveTypeForm.similarLeaveTypeChecking": "Sprawdzanie istniejących typów urlopów...",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAreYouSure": "Czy nadal chcesz utworzyć nowy typ (<strong>{newLeaveTypeName}</strong>)?",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAssigningTip": "Możesz przypisać typ urlopu do wielu lokalizacji i dostosować zasady dla każdej lokalizacji (takie jak limity, przeniesione dni, naliczenia itp.). Nie ma potrzeby tworzenia osobnych typów dla każdego miejsca!",
  "components.createLeaveTypeForm.similarLeaveTypeInfoRecommend": "Zalecamy używanie jednego typu urlopu w wielu lokalizacjach, aby zachować spójność danych urlopowych użytkowników.",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSetRules": "Ustaw zasady specyficzne dla lokalizacji dla:",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSimilarLeaveType": "Podobny typ urlopu już istnieje (<strong>{leaveTypeName}</strong>).",
  "components.createLeaveTypeForm.similarLeaveTypeTitle": "Podobny typ urlopu",
  "components.createLeaveTypeForm.title": "Utwórz typ urlopu i przypisz politykę urlopową",
  "components.denyWithReasonForm.deny": "Odrzuć",
  "components.denyWithReasonForm.denyLeaveRequest": "Odrzuć wniosek urlopowy",
  "components.denyWithReasonForm.reason": "Dodaj powód",
  "components.denyWithReasonForm.reasonIsRequired": "To pole jest wymagane.",
  "components.downgradeAlert.cancelChangeToPlan": "Anuluj zmianę na plan <strong>{nextPlan}</strong>",
  "components.downgradeAlert.cancelSwitchPlan": "Czy chcesz anulować zaplanowaną zmianę na plan <strong>{nextPlan}</strong> {nextPeriod}?",
  "components.downgradeAlert.noIWantToSwitch": "Nie, chcę przejść na plan <strong>{nextPlan}</strong> {nextPeriod}",
  "components.downgradeAlert.yesIWantToStay": "Tak, chcę pozostać na planie <strong>{currentPlan}</strong> {currentPeriod}",
  "components.entitlementByRole.additionalDays": "dodatkowy dzień (dni)",
  "components.entitlementByRole.additionalHours": "dodatkowa godzina (godziny)",
  "components.entitlementByRole.applayToUsersWithLabel": "Zastosuj do użytkowników z etykietą",
  "components.entitlementByRole.applyToUsersWithLabel": "Zastosuj do użytkowników z etykietą",
  "components.entitlementByRole.completePlanOnly": "Ta funkcja jest dostępna wyłącznie dla użytkowników subskrybujących nasz plan Complete.",
  "components.entitlementByRole.noLabels": "Nie ma utworzonych etykiet lub wszystkie są wykorzystane dla innych automatyzacji uprawnień według roli.",
  "components.entitlementByRole.startApplayingEnintlementByRoleFrom": "Rozpocznij stosowanie uprawnień według roli od",
  "components.entitlementByRole.startApplyingEntitlementByRoleFrom": "Rozpocznij stosowanie uprawnień według roli od",
  "components.fakeDoorMailLoginModal.discount": "Jako część grupy wczesnego dostępu, otrzymasz również 50% zniżki na Vacation Tracker przez pierwsze 3 miesiące.",
  "components.fakeDoorMailLoginModal.info": "Obecnie pracujemy nad umożliwieniem rejestracji przez e-mail. Aby uzyskać wczesny dostęp, zarejestruj się poniżej, a wkrótce się z Tobą skontaktujemy.",
  "components.fakeDoorMailLoginModal.no": "Nie, poczekam.",
  "components.fakeDoorMailLoginModal.title": "Zainteresowany wczesnym dostępem? 🚀 ",
  "components.fakeDoorMailLoginModal.yes": "Tak, chcę uzyskać wczesny dostęp.",
  "components.feedbackModal.button": "Przekaż nam opinię",
  "components.feedbackModal.emailPlaceholder": "Jaki jest Twój adres e-mail?",
  "components.feedbackModal.feedbackForm": "Formularz opinii",
  "components.feedbackModal.feedbackMessage": "Wiadomość z opinią",
  "components.feedbackModal.howDoYouLikeApp": "Jak podoba Ci się nasza aplikacja?",
  "components.feedbackModal.howDoYouLikeAppPlaceholder": "Wybierz opcję...",
  "components.feedbackModal.iLikeIt": "Podoba mi się",
  "components.feedbackModal.iLoveIt": "Kocham to",
  "components.feedbackModal.ItMakesMeCry": "Doprowadza mnie do płaczu",
  "components.feedbackModal.itsOk": "Jest w porządku",
  "components.feedbackModal.messagePlaceholder": "Twoja wiadomość z opinią",
  "components.feedbackModal.Nah": "Nie",
  "components.feedbackModal.namePlaceholder": "Wpisz swoje imię",
  "components.feedbackModal.ratingRequired": "Proszę wybrać jedną z podanych ocen",
  "components.feedbackModal.sendFeedback": "Wyślij opinię",
  "components.feedbackModal.yourEmail": "Twój adres e-mail",
  "components.feedbackModal.yourEmailInfo": "Jeśli Twoja opinia wymaga odpowiedzi, proszę podać swój adres e-mail, abyśmy mogli się z Tobą skontaktować.",
  "components.feedbackModal.yourName": "Twoje imię",
  "components.filterSimple.filterBy": "Filtruj według",
  "components.filterSimple.selectType": "Wybierz typ",
  "components.filterSimple.tooltipInfo": "Jeśli nie wybierzesz żadnych filtrów, eksport będzie zawierał wszystkich użytkowników.",
  "components.generalSettings.aiBotHelperLabel": "Zezwól na komunikację z Botem Vacation Tracker",
  "components.generalSettings.aiBotHelperLabelDisable": "Wyłącz komunikację z Botem Vacation Tracker",
  "components.generalSettings.aiBotHelperTooltip": "Komunikacja z botem to wygodny i efektywny sposób dla pracowników na składanie wniosków o urlop. Pracownicy mogą po prostu wysłać wiadomość z prośbą o urlop do bota, określając szczegóły takie jak data rozpoczęcia i zakończenia, liczba dni i rodzaj urlopu.",
  "components.heatmap.clickForMore": "Kliknij, aby uzyskać więcej informacji",
  "components.heatmap.holiday": "Święto",
  "components.heatmap.holidayDescription": "Lokalizacja {location} jest niedostępna z powodu {holiday}",
  "components.heatmap.loading": "ładowanie {day}",
  "components.heatmap.nextMonth": "Następny miesiąc",
  "components.heatmap.nonWorkingDay": "dzień wolny od pracy",
  "components.heatmap.previousMonth": "Poprzedni miesiąc",
  "components.heatmap.showLess": "Pokaż mniej",
  "components.heatmap.showMore": "Pokaż {amount} więcej",
  "components.heatmap.today": "dzisiaj",
  "components.helpDrawer.adminProductTourInfo": "Jako Administrator masz najwyższy poziom uprawnień w Vacation Tracker i możesz dostosować wszystkie ustawienia organizacji poprzez Panel Administratora.\n\nPanel umożliwia: \n- Wnioskowanie o urlop lub dodawanie urlopu dla innych członków działu. \n- Pełny przegląd poprzez Stronę Główną Panelu i Kalendarz. \n- Tworzenie i konfigurowanie wielu działów (Przypisywanie Osób Zatwierdzających) \n- Ustawianie Powiadomień  \n- Zarządzanie Użytkownikami (Aktywni, Nieaktywni, Usunięci).\n- Tworzenie i zarządzanie Lokalizacjami (Rodzaje Urlopów, Święta, Powiadomienia)\n\nBot umożliwia: \n- Wnioskowanie o urlop \n- Zatwierdzanie/Odrzucanie Wniosków Urlopowych\n- Otrzymywanie Codziennych/Tygodniowych Powiadomień\n\nJeśli Twoje pytania lub wątpliwości pozostają bez odpowiedzi, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia: ",
  "components.helpDrawer.adminProductTourTitle": "Twoja rola: Administrator",
  "components.helpDrawer.approverProductTourInfo": "Jako Zatwierdzający, masz drugi najwyższy poziom uprawnień w Vacation Tracker i możesz zatwierdzać lub odrzucać wnioski o urlop składane przez Użytkowników z działu, do którego zostałeś przypisany jako Zatwierdzający.\n\nPanel kontrolny umożliwia:\n- Wnioskowanie o urlop lub dodawanie urlopu dla innych członków działu.\n- Pełny przegląd poprzez Stronę główną Panelu i Kalendarz. \n- Ustawianie powiadomień \n- Przeglądanie profilu użytkownika.\n- Zarządzanie wnioskami (Zatwierdzanie/Odrzucanie wniosków, Przeglądanie historii).\n\nBot umożliwia:\n- Wnioskowanie o urlop\n- Zatwierdzanie/Odrzucanie wniosków o urlop\n- Otrzymywanie codziennych/tygodniowych powiadomień\n\nJeśli Twoje pytania lub wątpliwości pozostają bez odpowiedzi, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia: ",
  "components.helpDrawer.approverProductTourTitle": "Twoja rola: Zatwierdzający",
  "components.helpDrawer.google.adminProductTourInfo": "Jako Administrator masz najwyższy poziom uprawnień w Vacation Tracker i możesz dostosowywać wszystkie ustawienia organizacji poprzez Panel Administratora.\n\nPanel kontrolny umożliwia: \n- Wnioskowanie o urlop lub dodawanie urlopu dla innych członków działu. \n- Pełny przegląd poprzez Stronę główną Panelu i Kalendarz. \n- Tworzenie i konfigurowanie wielu działów (Przypisywanie Zatwierdzających)  \n- Ustawianie powiadomień \n- Zarządzanie użytkownikami (Aktywni, Nieaktywni, Usunięci).\n- Tworzenie i zarządzanie lokalizacjami (Typy urlopów, Święta, Powiadomienia)\n\nJeśli Twoje pytania lub wątpliwości pozostają bez odpowiedzi, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia: ",
  "components.helpDrawer.google.approverProductTourInfo": "Jako Zatwierdzający, masz drugi najwyższy poziom uprawnień w Vacation Tracker i możesz zatwierdzać lub odrzucać wnioski o urlop składane przez Użytkowników z działu, do którego zostałeś przypisany jako Zatwierdzający.\n\nPanel kontrolny umożliwia:\n- Wnioskowanie o urlop lub dodawanie urlopu dla innych członków działu.\n- Pełny przegląd poprzez Stronę główną Panelu i Kalendarz. \n- Ustawianie powiadomień \n- Przeglądanie profilu użytkownika.\n- Zarządzanie wnioskami (Zatwierdzanie/Odrzucanie wniosków, Przeglądanie historii).\n\nJeśli Twoje pytania lub wątpliwości pozostają bez odpowiedzi, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia: ​​​​​​​​",
  "components.helpDrawer.google.userProductTourInfo": "Jako Użytkownik Vacation Tracker, możesz składać i zarządzać swoimi wnioskami o urlop poprzez Panel kontrolny, a także mieć pełny przegląd planowanych nieobecności w Twojej organizacji.\n\nPanel kontrolny umożliwia:\n- Wnioskowanie o urlop\n- Pełny przegląd poprzez Stronę główną Panelu i Kalendarz.\n- Przeglądanie profilu użytkownika.\n\nJeśli Twoje pytania lub wątpliwości pozostają bez odpowiedzi, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia: ​​​​​​​​",
  "components.helpDrawer.helpDeskInfo": "Odwiedź nasze Centrum pomocy, aby dowiedzieć się więcej o naszym produkcie i znaleźć odpowiedzi na niektóre często zadawane pytania.",
  "components.helpDrawer.learnMore": "Dowiedz się więcej",
  "components.helpDrawer.needhelp": "Potrzebujesz pomocy?",
  "components.helpDrawer.quickTourInfo": "Przygotowaliśmy kilka krótkich kroków, które zalecamy wykonać, aby szybko skonfigurować Twoją organizację.",
  "components.helpDrawer.quickTourTitle": "Szybki przewodnik",
  "components.helpDrawer.supportInfo": "Jeśli masz jakiekolwiek pytania lub potrzebujesz pomocy, rozpocznij czat z naszym zespołem obsługi klienta lub wyślij nam e-mail na adres <link>hello@vacationtracker.io</link>",
  "components.helpDrawer.supportTitle": "Wsparcie",
  "components.helpDrawer.userProductTourInfo": "Jako Użytkownik Vacation Tracker, możesz składać i zarządzać swoimi wnioskami o urlop poprzez Panel kontrolny lub Bota, a także mieć pełny przegląd planowanych nieobecności w Twojej organizacji.\n\nPanel kontrolny umożliwia:\n- Wnioskowanie o urlop\n- Pełny przegląd poprzez Stronę główną Panelu i Kalendarz.\n- Przeglądanie profilu użytkownika.\n\nBot umożliwia:\n- Wnioskowanie o urlop \n- Otrzymywanie codziennych/tygodniowych powiadomień\n\nJeśli Twoje pytania lub wątpliwości pozostają bez odpowiedzi, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia: ",
  "components.helpDrawer.userProductTourTitle": "Twoja rola: Użytkownik",
  "components.importHolidaysForm.backButton": "Wstecz",
  "components.importHolidaysForm.selectCountry": "Wybierz kraj",
  "components.importHolidaysForm.selectHolidays": "Wybierz święta",
  "components.importHolidaysForm.selectYear": "Wybierz rok",
  "components.importUsersForm.button.importAllUsers": "Importuj i aktywuj wszystkich {users} użytkowników",
  "components.importUsersForm.button.importSelectedUsers": "Importuj {noOfUsers} wybranych {noOfUsers, plural, =1 {użytkownika} other {użytkowników}}",
  "components.importUsersForm.button.importUsersAutmaticallyFromPlatform": "Czy chcesz automatycznie importować wszystkich użytkowników z {platform}?",
  "components.importUsersForm.calculationStartYearInfo": "Vacation Tracker będzie obliczać urlopy począwszy od {year}",
  "components.importUsersForm.cancel": "Anuluj",
  "components.importUsersForm.chooseUsersPlaceholder": "Nazwa członka zespołu",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOff": "Vacation Tracker nie będzie automatycznie importować gości wielokanałowych z twojego obszaru roboczego. Nadal możesz importować ich ręcznie w razie potrzeby.",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOn": "Vacation Tracker będzie automatycznie importować gości wielokanałowych z twojego obszaru roboczego. ",
  "components.importUsersForm.confirmImportUsersModalContentChangeLaterInfo": "Możesz zmienić te ustawienia później w panelu Vacation Tracker",
  "components.importUsersForm.confirmImportUsersModalContentDepartmentAndLocationInfo": "Nowi {noOfUsers, plural, =1 {użytkownik} other {użytkownicy}} zostaną dodani do działu {chosenDepartment} i w lokalizacji {chosenLocation}",
  "components.importUsersForm.confirmImportUsersModalContentNoOfUsers": "Importuj {noOfUsers} {noOfUsers, plural, =1 {użytkownika} other {użytkowników}}",
  "components.importUsersForm.confirmImportUsersModalContentOpener": "Wybrałeś:",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfo": "Proporcjonalnie rozlicz limit urlopowy użytkowników dla następujących typów urlopów: {leaveTypes}",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfoTurnOff": "Limity urlopowe użytkowników nie będą już proporcjonalnie rozliczane",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessage": "Wyślij wiadomość powitalną {channel, select, Email {e-mailem} other {}} {channel} do nowych {noOfUsers, plural, =1 {użytkownika} other {użytkowników}}",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessageTurnOff": "Nie będziemy już wysyłać wiadomości powitalnej do nowych użytkowników",
  "components.importUsersForm.confirmImportUsersModalTitle": "Potwierdź import {noOfUsers} wybranych użytkowników",
  "components.importUsersForm.consent.line1": "Aby importować użytkowników, musisz uzyskać zgodę administratora od administratora Microsoft Teams. Aby uzyskać tę jednorazową zgodę, wyślij następujący link do administratora Microsoft Teams:",
  "components.importUsersForm.consent.line2": "Po uzyskaniu zgody, zamknij i ponownie otwórz to okno modalne, a ta wiadomość zniknie.",
  "components.importUsersForm.consent.warning": "Proszę zamknąć i ponownie otworzyć to okno modalne po uzyskaniu zgody, aby móc importować wszystkich użytkowników.",
  "components.importUsersForm.googleAPIErrorMessage": "Wydaje się, że wystąpił problem z ustawieniami w katalogu Google. Proszę skontaktować się z administratorem i przekazać im następujący błąd:",
  "components.importUsersForm.importAllUsers": "Czy chcesz zaimportować wszystkich użytkowników?",
  "components.importUsersForm.importAllUsersExceedingLimt": "Masz dużą liczbę użytkowników w swoim katalogu. Jeśli chcesz zaimportować wszystkich użytkowników, skontaktuj się z naszym zespołem wsparcia, a my możemy włączyć import. Alternatywnie możesz wybrać konkretnych użytkowników do importu.",
  "components.importUsersForm.importAllUsersTooltip": "{noOfUsersToImport} {noOfUsersToImport, plural, =1 {użytkownik} other {użytkowników}} z {platform} zostanie zaimportowanych do Vacation Tracker. Może to wpłynąć na Twoje rozliczenia.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimit": "Masz dużą liczbę użytkowników w swoim katalogu. Jeśli chcesz importować użytkowników automatycznie, skontaktuj się z naszym zespołem wsparcia, a my możemy włączyć import.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimt": "Masz dużą liczbę użytkowników w swoim katalogu. Jeśli chcesz importować użytkowników automatycznie, skontaktuj się z naszym zespołem wsparcia, a my możemy włączyć import.",
  "components.importUsersForm.importUsersAutomaticallyTooltip": "Gdy nowo dodany użytkownik do Twojego {platform} zostanie zaimportowany do Vacation Tracker, otrzyma krótką wiadomość wyjaśniającą, czym jest Vacation Tracker i jak z niego korzystać.",
  "components.importUsersForm.importUsersTitle": "Importuj użytkowników",
  "components.importUsersForm.newCompanyNameInfo": "Nowa nazwa firmy będzie: {newName}",
  "components.importUsersForm.selectUsers": "Wybierz użytkowników do importu:",
  "components.importUsersForm.selectUsersNext": "Skonfiguruj ustawienia dla wybranych użytkowników",
  "components.importUsersForm.selectUsersStepName": "Wybierz użytkowników",
  "components.importUsersForm.sendWelcomeMessageLabel": "Wysłać powitalną wiadomość do nowych użytkowników?",
  "components.importUsersForm.trackingQuotaInfoDays": "Vacation Tracker będzie śledzić czas trwania wszystkich urlopów i limitów w dniach.",
  "components.importUsersForm.trackingQuotaInfoHours": "Vacation Tracker będzie śledzić czas trwania wszystkich urlopów i limitów w godzinach.",
  "components.importUsersForm.userEndDateInfo": "Użytkownicy z datą końcową nie będą mogli składać wniosków urlopowych po tej dacie, a my automatycznie ich dezaktywujemy.",
  "components.importUsersForm.userEndDateTurnOffInfo": "Użytkownicy z datą końcową będą mogli składać wnioski urlopowe po tej dacie, a my nie będziemy ich automatycznie dezaktywować.",
  "components.importUsersForm.usersIsRequired": "To pole jest wymagane.",
  "components.importUsersForm.warningAllUsersImport": "Vacation Tracker może automatycznie synchronizować, importować i usuwać użytkowników z Twojego {platform}.",
  "components.importUsersForm.warningNumberOfUsersToImport": "Po kliknięciu przycisku \"Importuj i aktywuj wszystkich użytkowników\" aktywujesz wszystkich {users} użytkowników z Twojego katalogu. Ta akcja może wpłynąć na Twoje rozliczenia.",
  "components.importUsersForm.warningOfUsersImport": "Import {noOfUsers} wybranych {noOfUsers, plural, =1 {użytkownika} other {użytkowników}} może wpłynąć na Twoje rozliczenia.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNewBucket": "Dodanie {noOfUsers} {noOfUsers, plural, =1 {użytkownika} other {użytkowników}} zwiększy Twój następny rachunek do {amount} (bez VAT) począwszy od {startDate}.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNoOfUsers": "Szacowana proporcjonalna opłata w wysokości {proratedAmount} (bez VAT) zostanie naliczona dzisiaj.",
  "components.importUsersForm.warningOfUsersImportNoChange": "Zamierzasz zaimportować {noOfUsers} {noOfUsers, plural, =1 {użytkownika} other {użytkowników}}. Twoje rozliczenia nie zostaną zmienione.",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimit": "Osiągnąłeś swój aktualny limit miejsc ({seats}). Czy chcesz kupić więcej?",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimitImportAll": "Nie możesz zaimportować wszystkich użytkowników z swojego obszaru roboczego/katalogu, ponieważ przekroczysz wtedy swój aktualny limit miejsc ({seats}). Czy chcesz kupić więcej?",
  "components.importUsersForm.warningOfUsersImportProrateAdditionalNewBucketMonthly": "Import {noOfUsers} {noOfUsers, plural, =1 {użytkownika} other {użytkowników}} wpłynie na Twoje rozliczenia. Twoja następna faktura będzie wynosić {amount} (bez VAT) w dniu {startDate}",
  "components.importUsersForm.warningOfUsersImportTrial": "Obecnie jesteś w trybie próbnym. Zostaniesz obciążony kwotą {amount} (bez VAT) w dniu {startDate}",
  "components.importUsersForm.warningOfUsersImportTrialWithoutPaymentMethod": "Jesteś w okresie próbnym i nie zostaniesz obciążony za dodatkowy import użytkowników przed {stripeCurrentPeriodEnd}. Dodaj swoje dane rozliczeniowe, aby zobaczyć szacunkową opłatę.",
  "components.insertHolidaysForm.back": "Wstecz",
  "components.insertHolidaysForm.deselectAll": "Odznacz wszystko",
  "components.insertHolidaysForm.findHolidays": "Znajdź święta",
  "components.insertHolidaysForm.insertHolidays": "Importuj święta",
  "components.insertHolidaysForm.pleaseChooseACountry": "Wybierz kraj",
  "components.insertHolidaysForm.saveHolidays": "Zapisz święta",
  "components.insertHolidaysForm.selectAll": "Zaznacz wszystko",
  "components.insertHolidaysForm.selectHolidays": "Wybierz święta",
  "components.insertHolidaysForm.subtitle": "Ta zmiana może wpłynąć na liczbę dni urlopowych dla niektórych użytkowników. Ci użytkownicy będą mieli dodane dni do ich dostępnego limitu dla żądanego urlopu. Czy na pewno chcesz kontynuować?",
  "components.insertHolidaysForm.title": "Importowanie świąt nadpisze wszystkie wcześniej dodane święta.",
  "components.inviteAndManageUsersWithEmail.canNotInviteYourself": "Nie możesz zaprosić samego siebie",
  "components.inviteAndManageUsersWithEmail.header": "Zaproszenia e-mail",
  "components.inviteAndManageUsersWithEmail.userAlreadyExists": "Użytkownik o podanym adresie e-mail już istnieje",
  "components.inviteBotToPrivateChannel.descriptionOne": "Aby dodać Vacation Tracker do prywatnego kanału, wpisz",
  "components.inviteBotToPrivateChannel.descriptionTwo": "na kanale i odśwież listę",
  "components.inviteBotToPrivateChannel.text": "Czy chcesz wybrać prywatny kanał?",
  "components.inviteBotToPrivateChannel.title": "Dodaj prywatny kanał",
  "components.leaveBox.approve": "Zatwierdź",
  "components.leaveBox.approvedBy": "Zatwierdzone przez",
  "components.leaveBox.approveLeaveText": "Proszę potwierdzić, że chcesz zatwierdzić wniosek o urlop.",
  "components.leaveBox.approveLeaveTitle": "Zatwierdź wniosek o urlop",
  "components.leaveBox.autoApproved": "Automatycznie zatwierdzony",
  "components.leaveBox.cancelLeave": "Anuluj urlop",
  "components.leaveBox.cancelLeaveConfirm": "Czy na pewno chcesz anulować urlop?",
  "components.leaveBox.cancelLeaveTitle": "Anuluj urlop",
  "components.leaveBox.columns.daysOut": "{value} {value, plural, =1 {dzień} few {dni} many {dni} other {dnia}}",
  "components.leaveBox.columns.hoursOut": "{value} {value, plural, =1 {godzina} few {godziny} many {godzin} other {godziny}}",
  "components.leaveBox.daysLeft": "Pozostały urlop:",
  "components.leaveBox.daysOut": "To {value} {value, plural, =1 {dzień} few {dni} many {dni} other {dnia}} poza biurem.",
  "components.leaveBox.deny": "Odrzuć",
  "components.leaveBox.denyLeaveConfirm": "Proszę potwierdzić, że chcesz odrzucić wniosek o urlop.",
  "components.leaveBox.denyLeaveTitle": "Odrzuć wniosek o urlop",
  "components.leaveBox.denyWithReason": "Odrzuć z podaniem powodu",
  "components.leaveBox.edit": "Edytuj",
  "components.leaveBox.formattedLeaveInfo": "To {leave} poza biurem.",
  "components.leaveBox.hoursOut": "To {value} {value, plural, =1 {godzina} few {godziny} many {godzin} other {godziny}} poza biurem.",
  "components.leaveBox.leavesBoxHalfDay": "Będzie nieobecny z powodu {leaveTypeName} w dniu {leaveStartDate} od {startHour}{hourFormat24, select, true {:00} other {}} do {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayPending": "Wniosek o {leaveTypeName} w dniu {leaveStartDate} od {startHour}{hourFormat24, select, true {:00} other {}} do {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayToday": "Jest nieobecny dzisiaj od {startHour}{hourFormat24, select, true {:00} other {}} do {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayTodayWithoutReason": "Jest nieobecny dzisiaj od {startHour}{hourFormat24, select, true {:00h} other {}} do {endHour}{hourFormat24, select, true {:00h} other {}}.",
  "components.leaveBox.leavesBoxHalfDayWithoutReason": "Bierze urlop w dniu {leaveStartDate} od {startHour}{hourFormat24, select, true {:00} other {}} do {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesOffTodayInfo": "Jest nieobecny z powodu {leaveTypeName} od {leaveStartDate} do {leaveEndDate}. Obie daty włącznie.",
  "components.leaveBox.leavesOffTodayWithoutReasonInfo": "Jest nieobecny od {leaveStartDate} do {leaveEndDate}. Włączając obie daty.",
  "components.leaveBox.leavesPendingInfo": "Wniosek o {leaveTypeName} od {leaveStartDate} do {leaveEndDate}. Obie daty włącznie.",
  "components.leaveBox.leavesPendingOneDay": "Wniosek o {leaveTypeName} w dniu {leaveStartDate}.",
  "components.leaveBox.leavesUpcomingOneDayInfo": "Będzie nieobecny z powodu {leaveTypeName} w dniu {leaveStartDate}.",
  "components.leaveBox.leavesUpcomingOneDayWithoutReasonInfo": "Będzie nieobecny w dniu {leaveStartDate}.",
  "components.leaveBox.reason": "Powód",
  "components.leaveBox.toilDuration": "Czas trwania: {leave}",
  "components.leaveForm.addLeave": "Dodaj urlop",
  "components.leaveForm.addLeaveDescription": "Użyj tego formularza, jeśli dodajesz urlop dla innego użytkownika lub jeśli dodajesz urlop dla siebie, który nie wymaga zatwierdzenia.",
  "components.leaveForm.connectedCalendarTimezone": "Strefa czasowa połączonego kalendarza {calendarType} to {timezone}",
  "components.leaveForm.date": "Data",
  "components.leaveForm.editLeave": "Edytuj urlop",
  "components.leaveForm.editLeaveDescription.adminAndApprover": "Możesz edytować daty tego urlopu. Jeśli chcesz zmienić typ urlopu, anuluj obecny i dodaj nowy.",
  "components.leaveForm.editLeaveDescription.user": "Możesz edytować daty i powód tego urlopu. Jeśli chcesz zmienić typ urlopu, anuluj obecny i utwórz nowe zgłoszenie.",
  "components.leaveForm.endTime": "Czas zakończenia",
  "components.leaveForm.halfDay": "{leaveTypeName} (Pół dnia)",
  "components.leaveForm.hourly": "{leaveTypeName} (Godzinowy)",
  "components.leaveForm.leaveTypeNamePartTime": "{leaveTypeName} (Godzinowy)",
  "components.leaveForm.pleaseInputReason": "Powód jest wymagany",
  "components.leaveForm.pleaseSelectEndDate": "Wybierz datę zakończenia",
  "components.leaveForm.pleaseSelectLeaveType": "Wybierz typ urlopu",
  "components.leaveForm.pleaseSelectRangeTime": "Wybierz zakres czasu",
  "components.leaveForm.pleaseSelectstartDate": "Wybierz datę rozpoczęcia",
  "components.leaveForm.pleaseSelectUser": "Wybierz użytkownika",
  "components.leaveForm.pleaseSelectValidRangeTime": "Wybierz prawidłowy zakres czasu",
  "components.leaveForm.rangeDate": "Data rozpoczęcia i zakończenia",
  "components.leaveForm.reason": "Powód",
  "components.leaveForm.request": "Wniosek",
  "components.leaveForm.requestLeave": "Złóż wniosek o urlop",
  "components.leaveForm.requestLeaveDescription": "Użyj tego formularza, jeśli Twój wniosek wymaga zatwierdzenia przez kogoś innego niż Ty.",
  "components.leaveForm.selectSubstituteApprover": "Wybierz zastępczego zatwierdzającego",
  "components.leaveForm.selectSubstituteApproverInfoMessage": "Wybierz osobę, która będzie zarządzać Twoimi przychodzącymi wnioskami o urlop podczas Twojej nieobecności. Zastępczy zatwierdzający będzie miał ograniczone uprawnienia, ponieważ będzie mógł jedynie zatwierdzać lub odrzucać wnioski o urlop. Ten krok jest opcjonalny.",
  "components.leaveForm.selectUser": "Wybierz użytkownika",
  "components.leaveForm.startTime": "Czas rozpoczęcia",
  "components.leaveForm.time": "Czas",
  "components.leaveForm.user": "Wybierz użytkownika",
  "components.leavePolicyForm.accrualBiWeeklySummary": "Użytkownicy będą naliczać {amount} ({quota}/26) dni co drugi {day}",
  "components.leavePolicyForm.accrualBiWeeklySummaryHours": "Użytkownicy będą naliczać {amount} ({quota}/26) godzin co drugi {day}",
  "components.leavePolicyForm.accrualCap": "Włącz limit naliczania",
  "components.leavePolicyForm.accrualCapRate": "Ogranicz naliczone dni do",
  "components.leavePolicyForm.accrualCapRateTimes": "razy roczna stawka naliczania",
  "components.leavePolicyForm.accrualCapTooltip": "Limit naliczania ogranicza ilość PTO, jaką pracownik może zgromadzić w określonym okresie. Jest on definiowany jako wielokrotność rocznej stawki naliczania, a nie jako dokładna liczba.",
  "components.leavePolicyForm.accrualCapWarning": "Osiągnięto limit naliczania dla {leaveTypeName}. Naliczanie nowych dni zostanie wznowione po wykorzystaniu niektórych istniejących dni.",
  "components.leavePolicyForm.accrualCapWarning2": "Ten użytkownik osiągnął limit naliczania {leaveTypeName} i nie będzie gromadzić więcej dni, dopóki nie wykorzysta części obecnego salda.",
  "components.leavePolicyForm.accrualDay": "Dzień naliczania",
  "components.leavePolicyForm.accrualDayBiWeekly": "Naliczanie przyznawane w",
  "components.leavePolicyForm.accrualDisabledInfo": "Wyłącz nieograniczoną pulę i ustaw pulę na dowolną liczbę większą niż 0, aby włączyć naliczanie.",
  "components.leavePolicyForm.accrualMonthlySummary": "Użytkownicy będą naliczać {amount} ({quota}/12) dni {date} każdego miesiąca",
  "components.leavePolicyForm.accrualMonthlySummaryHours": "Użytkownicy będą naliczać {amount} ({quota}/12) godzin {date} każdego miesiąca",
  "components.leavePolicyForm.accruals": "Naliczanie",
  "components.leavePolicyForm.accrualSemiMonthlySummary": "Użytkownicy będą naliczać {amount} ({quota}/24) dni w {firstDate} i {secondDate} dniu miesiąca",
  "components.leavePolicyForm.accrualSemiMonthlySummaryHours": "Użytkownicy będą naliczać {amount} ({quota}/24) godzin w {firstDate} i {secondDate} dniu miesiąca",
  "components.leavePolicyForm.accrualType": "Typ naliczania",
  "components.leavePolicyForm.accrualTypeError": "Wymagane",
  "components.leavePolicyForm.accrualTypeInfo": "Naliczony urlop kumuluje się stopniowo w ciągu roku zgodnie z polityką firmy. Jeśli Twoja firma stosuje naliczanie, wybierz typ naliczania określony przez zarząd.",
  "components.leavePolicyForm.accrualWeeklySummary": "Użytkownicy będą naliczać {amount} ({quota}/52) dni co {day}",
  "components.leavePolicyForm.accrualWeeklySummaryHours": "Użytkownicy będą naliczać {amount} ({quota}/52) godzin co {day}",
  "components.leavePolicyForm.addTitle": "Przypisz politykę urlopową",
  "components.leavePolicyForm.allDays": "Wszystkie",
  "components.leavePolicyForm.allowAddAndEditLeaveInPast": "Edytuj i wnioskuj o urlopy w przeszłości",
  "components.leavePolicyForm.allowAddAndEditLeaveInPastInfo": "Wybierz, czy chcesz zezwolić zwykłym użytkownikom na edycję lub wnioskowanie o urlopy w przeszłości.",
  "components.leavePolicyForm.allowAdvanceAccrualUsage": "Zezwól na wnioskowanie o urlop na podstawie przyszłego naliczenia",
  "components.leavePolicyForm.allowAdvanceAccrualUsageInfo": "Pozwól pracownikom wnioskować o urlop na podstawie przewidywanego naliczenia dni wolnych, a nie tylko tego, co zgromadzili do momentu złożenia wniosku. Oznacza to, że jeśli pracownik wie, że będzie miał wystarczającą liczbę naliczonych dni w momencie rozpoczęcia urlopu, może złożyć wniosek z wyprzedzeniem, nawet jeśli nie ma wystarczającej liczby dni w momencie składania wniosku.",
  "components.leavePolicyForm.approvalRequired": "Wymagana aprobata",
  "components.leavePolicyForm.assignLeavePolicy": "Przypisz politykę urlopową",
  "components.leavePolicyForm.back": "Wstecz",
  "components.leavePolicyForm.BI-WEEKLY": "Dwutygodniowo",
  "components.leavePolicyForm.cancel": "Anuluj",
  "components.leavePolicyForm.copyLeavePolicy": "Kopiuj politykę z",
  "components.leavePolicyForm.copyLeavePolicyDivider": "Możesz użyć szablonu z innego urlopu lub lokalizacji.",
  "components.leavePolicyForm.copyLeavePolicyInfo": "Skopiuj ustawienia polityki urlopowej z wcześniej ustawionego typu urlopu. W menu rozwijanym wybierz typ urlopu, z którego chcesz skopiować politykę urlopową, a ustawienia zostaną automatycznie skonfigurowane. Jeśli chcesz zrobić to ręcznie, możesz to zrobić, konfigurując ustawienia poniżej.",
  "components.leavePolicyForm.createLeaveTypeFormAccrualsInfo1": " Naliczanie jest nieaktywne podczas tworzenia typu urlopu. Aby je aktywować, dostosuj politykę urlopową dla konkretnej lokalizacji.",
  "components.leavePolicyForm.createLeaveTypeFormToilInfo1": "TOIL (czas wolny w zamian) jest nieaktywny podczas tworzenia typu urlopu. Aby go aktywować, dostosuj politykę urlopową dla konkretnej lokalizacji.",
  "components.leavePolicyForm.currentAccrualPeriod": "Bieżący okres naliczania",
  "components.leavePolicyForm.daysPerYear": "Roczny limit urlopu",
  "components.leavePolicyForm.defaultLeavePolicy": "Nie kopiuj, zacznę nową politykę",
  "components.leavePolicyForm.editTitle": "Edytuj politykę urlopową {leavePolicyName} dla {locationName}",
  "components.leavePolicyForm.futureBroughtForward": "Przyszłe przeniesienie",
  "components.leavePolicyForm.halfDays": "Zezwól na pół dnia",
  "components.leavePolicyForm.hasUnlimitedDays": "Nieograniczony limit",
  "components.leavePolicyForm.hideLeaveType": "Ukryj typ urlopu",
  "components.leavePolicyForm.hideLeaveTypeInfo": "Wybierz, czy chcesz ukryć ten typ urlopu w kalendarzu i powiadomieniach. Tylko administratorzy, osoby zatwierdzające i użytkownik, który wnioskował o ten typ urlopu, będą mogli go zidentyfikować w kalendarzu.",
  "components.leavePolicyForm.hourlyIncrementsTooltip": "Pozwól członkom zespołu wnioskować o urlop w oparciu o godziny.",
  "components.leavePolicyForm.isActive": "Aktywny",
  "components.leavePolicyForm.leavePolicy": "Polityka urlopowa",
  "components.leavePolicyForm.limitedNumberOfDays": "Ograniczona",
  "components.leavePolicyForm.maxRolloverDays": "Maksymalna liczba dni do przeniesienia",
  "components.leavePolicyForm.maxRolloverDaysError": "Wymagane",
  "components.leavePolicyForm.maxRolloverDaysInfo": "Wybierz liczbę dni, które mogą być przeniesione na następny rok kalendarzowy dla tego typu urlopu.",
  "components.leavePolicyForm.maxRolloverDaysInfoHours": "Wybierz liczbę godzin, które mogą być przeniesione na następny rok kalendarzowy dla tego typu urlopu.",
  "components.leavePolicyForm.MONTHLY": "Miesięcznie",
  "components.leavePolicyForm.negativeBalance": "Zezwól na ujemny bilans",
  "components.leavePolicyForm.negativeBalanceInfo": "Wybierz, czy chcesz zezwolić użytkownikom na przekroczenie ustalonego limitu urlopu dla tego typu urlopu. Jeśli nie chcesz zezwolić na tę opcję, pozostaw przycisk odznaczony.",
  "components.leavePolicyForm.nextAccrualDay": "Następne naliczenie przyznane w dniu",
  "components.leavePolicyForm.noLeaveTypes": "Brak dostępnych typów urlopów. Aby dodać nowy typ urlopu z niestandardową polityką urlopową, przejdź do zakładki Typy urlopów, aby utworzyć nowy typ urlopu, a następnie dodaj go do lokalizacji.",
  "components.leavePolicyForm.NONE": "Brak",
  "components.leavePolicyForm.noRollover": "Wyłączone",
  "components.leavePolicyForm.override.affectedUsers": "Dotknięci użytkownicy: <strong>{totalUsers}</strong>",
  "components.leavePolicyForm.override.change": "Zmiana: <unlimited>Zmiana polityki nieograniczonego urlopu</unlimited><customDays>Aktualizacja limitu urlopu</customDays>",
  "components.leavePolicyForm.override.customLeaveQuotaInfo": "Niektórzy z dotkniętych użytkowników (<strong>{totalUsers}</strong>) mają niestandardowe limity urlopu. Czy chcesz nadpisać to ustawienie?",
  "components.leavePolicyForm.override.leaveType": "Typ urlopu: <strong>{name}</strong>",
  "components.leavePolicyForm.override.location": "Lokalizacja: <strong>{name}</strong>",
  "components.leavePolicyForm.override.note": "Uwaga: Ta zmiana wpłynie na wszystkich użytkowników bez niestandardowego limitu urlopu, niezależnie od wybranej opcji.",
  "components.leavePolicyForm.override.overrideDisable": "Nadpisz ich niestandardowy limit urlopu",
  "components.leavePolicyForm.override.overrideEnable": "Zachowaj istniejący limit urlopu",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivated": "Wstrzymaj naliczanie, gdy użytkownik jest dezaktywowany",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedInfo": "Aktywuj tę funkcję, aby tymczasowo wstrzymać naliczanie dni urlopowych dla każdego dezaktywowanego użytkownika. Po ponownej aktywacji użytkownika, naliczanie dni urlopowych automatycznie zostanie wznowione, zapewniając dokładne śledzenie ich uprawnień urlopowych",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedUserInfo": "Zgodnie z polityką Twojej organizacji, naliczanie urlopu jest wstrzymane, gdy konto tego użytkownika jest nieaktywne. Skontaktuj się z administratorem, aby uzyskać więcej szczegółów.",
  "components.leavePolicyForm.previouesBroughForwardAndFutureOnce": "Poprzednie przeniesione i przyszłe",
  "components.leavePolicyForm.reasonRequired": "Wymagane podanie powodu",
  "components.leavePolicyForm.required": "Wymagane",
  "components.leavePolicyForm.rolloverDaysPerYear": "Maksymalna liczba dni",
  "components.leavePolicyForm.rolloverPerYearDays": "Maksymalna liczba dni",
  "components.leavePolicyForm.rolloverPerYearHours": "Maksymalna liczba godzin",
  "components.leavePolicyForm.rolloverSettings": "Ustawienia przeniesienia",
  "components.leavePolicyForm.rolloverType": "Przeniesione",
  "components.leavePolicyForm.save": "Zapisz",
  "components.leavePolicyForm.saveLeavePolicy": "Zapisz",
  "components.leavePolicyForm.selectLeaveType": "Wybierz typ urlopu",
  "components.leavePolicyForm.SEMI-MONTHLY": "Dwa razy w miesiącu",
  "components.leavePolicyForm.updateLeavePolicy": "Aktualizuj",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChanges": "Kiedy chciałbyś zastosować te zmiany?",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChangesInfo": "Wybierz ramy czasowe dla zastosowania zmian urlopowych. Wybierz 'Przyszłe przeniesione', aby dostosować tylko nadchodzące daty urlopów, lub 'Poprzednie przeniesione i przyszłe', aby zmodyfikować zarówno przeszłe, jak i przyszłe daty urlopów.",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChanges": "Kiedy chciałbyś zastosować te zmiany?",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChangesInfo": "Wybierz ramy czasowe dla zastosowania zmian urlopowych. Wybierz 'Przyszłe przeniesione', aby dostosować tylko nadchodzące daty urlopów, lub 'Poprzednie przeniesione i przyszłe', aby zmodyfikować zarówno przeszłe, jak i przyszłe daty urlopów.",
  "components.leavePolicyForm.yearlyQuota": "Roczny limit urlopu",
  "components.leavePolicyForm.yearlyQuotaHours": "Roczny limit urlopu (Godziny)",
  "components.leavePolicyForm.yearlyQuotaInfo": "To pole reprezentuje podstawowy roczny limit urlopu. Będziesz mógł ręcznie dostosować indywidualne limity za pośrednictwem profili użytkowników lub zautomatyzować dostosowania dla różnych użytkowników za pomocą naszej funkcji automatyzacji.",
  "components.leaveRequestAction.added": "Urlop <forUser>dla </forUser>{requestor} dodany <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.addToCalendar": "Dodaj do kalendarza",
  "components.leaveRequestAction.addToCalendarMoreInfo": "Więcej informacji o funkcji \"Dodaj do kalendarza\"",
  "components.leaveRequestAction.approved": "Urlop {requestor} zatwierdzony <byActionCreator>przez {actionCreator}</byActionCreator> ✅",
  "components.leaveRequestAction.approversLabel": "Zatwierdzający: ",
  "components.leaveRequestAction.autoApproved": "Urlop {requestor} został automatycznie zatwierdzony",
  "components.leaveRequestAction.cancelled": "Urlop {requestor} anulowany <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.created": "Urlop wnioskowany <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.deleted": "Urlop usunięty <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.denied": "Urlop {requestor} odrzucony <byActionCreator>przez {actionCreator}</byActionCreator> ❌",
  "components.leaveRequestAction.edited": "Urlop {requestor} edytowany <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.editedApproved": "Zaplanowany urlop został edytowany <byActionCreator>przez {actionCreator}</byActionCreator> ✅",
  "components.leaveRequestAction.editedAutoApproved": "Automatycznie zatwierdzony edytowany urlop <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.editedDenied": "Edytowany urlop odrzucony <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.fullDayLeavePeriod": "{date}{endDate} ",
  "components.leaveRequestAction.leaveRemaining": "Pozostały urlop: ",
  "components.leaveRequestAction.leaveRequested": "Wnioskowany urlop: ",
  "components.leaveRequestAction.leaveTypeLabel": "Typ urlopu: ",
  "components.leaveRequestAction.partDayLeavePeriod": "{date} - od {partDayStartHour}{hourFormat24, select, true {:00} other {}}  do {partDayEndHour}{hourFormat24, select, true {:00} other {}} ",
  "components.leaveRequestAction.periodLabel": "Data: ",
  "components.leaveRequestAlertStatus.leaveRequestApprovedByYouInfo": "Zatwierdziłeś wniosek. Powiadomienie zostanie wysłane do {name}, aby ich o tym poinformować.",
  "components.leaveRequestAlertStatus.leaveRequestApprovedInfo": "Wniosek urlopowy został zatwierdzony przez {approverName}.",
  "components.leaveRequestAlertStatus.leaveRequestCancelledInfo": "Wniosek urlopowy został anulowany.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedByYouInfo": "Odrzuciłeś wniosek. Powiadomienie zostanie wysłane do {name}, aby ich o tym poinformować.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedInfo": "Wniosek urlopowy został odrzucony przez {approverName}.",
  "components.leaveRequestAlertStatus.leaveRequestExpiredInfo": "Wniosek wygasł.",
  "components.leaveRequestAlertStatus.leaveRequestHasApprovedByYouInfo": "Wniosek urlopowy został zatwierdzony.",
  "components.leaveRequestAlertStatus.leaveRequestHasDeniedByYouInfo": "Wniosek urlopowy został odrzucony.",
  "components.leavesColumns.approver": "Zatwierdzający",
  "components.leavesColumns.autoApproved": "Automatycznie zatwierdzony",
  "components.leavesColumns.cancelledBeforeReview": "Anulowany przed rozpatrzeniem",
  "components.leavesColumns.dates": "Data(y)",
  "components.leavesColumns.days": "{value} dni",
  "components.leavesColumns.denyReason": "Powód odrzucenia przez zatwierdzającego",
  "components.leavesColumns.duration": "Czas trwania",
  "components.leavesColumns.halfDayHoliday": "(Pół dnia świątecznego)",
  "components.leavesColumns.hours": "{value} godzin",
  "components.leavesColumns.leaveType": "Typ urlopu",
  "components.leavesColumns.reason": "Powód wnioskodawcy",
  "components.leavesColumns.reviewedby": "Rozpatrzony przez",
  "components.leavesColumns.status": "Status",
  "components.leaveTypeForm.cancel": "Anuluj",
  "components.leaveTypeForm.color": "Kolor",
  "components.leaveTypeForm.editLeaveTypeTitle": "Edytuj typ urlopu {leaveTypeName}",
  "components.leaveTypeForm.forbiddenCharacters": "Nazwa typu urlopu nie może zawierać znaków interpunkcyjnych ani specjalnych.",
  "components.leaveTypeForm.isActive": "Aktywny",
  "components.leaveTypeForm.maxLength": "Nazwa typu urlopu jest za długa.",
  "components.leaveTypeForm.name": "Nazwa",
  "components.leaveTypeForm.nameIsRequired": "To pole jest wymagane.",
  "components.leaveTypeForm.save": "Zapisz",
  "components.leaveTypeForm.setSlackStatus": "Ustaw status Slack",
  "components.leaveTypeForm.setSlackStatusPlaceholder": "Proszę ustawić status Slack",
  "components.leaveTypeForm.slackStatus": "Status Slack",
  "components.leaveTypeForm.title": "Typ urlopu",
  "components.leaveTypeForm.tooltipElement1": "<strong>Wymagana akceptacja</strong>: Tę opcję można włączyć, jeśli urlop wymaga zatwierdzenia przez przełożonego.",
  "components.leaveTypeForm.tooltipElement2": "<strong>Zezwalaj na ujemny bilans</strong>: Ta opcja pozwala użytkownikom wnioskować o urlop nawet po wykorzystaniu swojego limitu.",
  "components.leaveTypeForm.tooltipElement3": "<strong>Zezwalaj na półdniówki</strong>: Tę opcję można aktywować zgodnie z potrzebami Twojej organizacji.",
  "components.leaveTypeForm.tooltipFooter": "Pamiętaj, że polityki urlopowe są definiowane dla każdej lokalizacji. Możesz dostosować te polityki do potrzeb Twojej organizacji i przypisać je do odpowiednich typów urlopów.",
  "components.leaveTypeForm.tooltipHeader": "W Vacation Tracker 'Typy urlopów' odnoszą się do globalnych kategorii urlopów, takich jak Płatny urlop wypoczynkowy, Zwolnienie chorobowe, Praca z domu itp. Z drugiej strony, 'Polityki urlopowe' to konkretne zasady i ustawienia przypisane do każdego typu urlopu dla danej lokalizacji. Te polityki mogą obejmować różne opcje, takie jak:",
  "components.legacyPlanAlert.warning": "Obecnie korzystasz z planu starszego typu. Jeśli zmienisz plan, nie będziesz mógł go ponownie aktywować. Aby uzyskać więcej informacji, skontaktuj się z naszym <link>wsparciem</link>.",
  "components.locationForm.changeLocationWarning": "Wybierz lokalizację, do której chcesz przenieść użytkownika.",
  "components.locationForm.changeUserLocation": "Zmień lokalizację użytkownika",
  "components.locationForm.days": "Dni",
  "components.locationForm.daysAfterEmploymentDate": "dni po dacie zatrudnienia.",
  "components.locationForm.daysInfo": "Przeniesienie niewykorzystanego urlopu na kolejny rok kalendarzowy wygaśnie po liczbie dni, którą ustawiłeś po dacie zatrudnienia użytkownika. Limit urlopu dla każdego typu urlopu jest konfigurowany w zakładce Typy urlopów w Ustawieniach lokalizacji, włącznie z opcją włączenia lub wyłączenia przeniesienia.",
  "components.locationForm.defaultLocation": "Ustaw tę lokalizację jako domyślną",
  "components.locationForm.firstDayOfWeek": "Tydzień zaczyna się od",
  "components.locationForm.fiscalYear": "Rok obrachunkowy",
  "components.locationForm.fiscalYearStart": "Początek roku obrachunkowego",
  "components.locationForm.name": "Nazwa",
  "components.locationForm.noRolloverExpiryDate": "Brak daty wygaśnięcia przeniesionych dni",
  "components.locationForm.overrideUserSettings": "Nadpisz ustawienia użytkownika",
  "components.locationForm.overrideUserSettingsInfo": "Konfiguracja tygodnia pracy zostanie zastosowana tylko dla użytkowników, którzy nie mają własnych ustawień w profilu użytkownika. Jeśli chcesz zastosować nowe ustawienia tygodnia pracy dla wszystkich użytkowników, w tym tych, którzy mają ustawiony własny tydzień pracy, wybierz opcję \"Nadpisz ustawienia użytkownika\".",
  "components.locationForm.resetQuotas": "Reset limitu urlopu na podstawie",
  "components.locationForm.resetQuotasInfo": "To ustawienie określi, czy Twój bilans urlopowy będzie resetowany na podstawie roku obrachunkowego (roku podatkowego firmy) czy na podstawie daty rozpoczęcia pracy pracownika. Oprócz limitów, Twoja polityka przenoszenia dni również będzie zależna od tego ustawienia.",
  "components.locationForm.rolloverExpiryAfterDays": "Brak daty wygaśnięcia przeniesionych dni",
  "components.locationForm.rolloverExpiryAfterDaysInfo": "Każdego roku przeniesione dni urlopu użytkowników wygasną po określonej liczbie dni od daty ich zatrudnienia. Limity dla każdego typu urlopu są konfigurowane w sekcji Typy urlopów dla tej lokalizacji i każdy z nich może być indywidualnie ustawiony, aby zezwalać lub nie zezwalać na przenoszenie.",
  "components.locationForm.rolloverExpiryDay": "Brak daty wygaśnięcia przeniesionych dni",
  "components.locationForm.rolloverExpiryDayInfo": "Każdego roku przeniesione dni urlopu użytkownika wygasną w dniu, który ustawisz. Limity dla każdego typu urlopu są konfigurowane w sekcji Typy urlopów dla tej lokalizacji i każdy z nich może być indywidualnie ustawiony, aby zezwalać lub nie zezwalać na przenoszenie.",
  "components.locationForm.selectLocation": "Wybierz lokalizację",
  "components.locationForm.selectLocationError": "Proszę wybrać lokalizację.",
  "components.locationForm.settings.makeDefaultDescription": "Ustawiając tę lokalizację jako domyślną, wszyscy nowi członkowie zespołu będą automatycznie dodawani do tej lokalizacji.",
  "components.locationForm.settings.makeDefaultTitle": "Domyślna lokalizacja",
  "components.locationForm.settings.nameDescription": "Ustaw nazwę swojej lokalizacji i wybierz jej użytkowników. Jeśli chcesz skonfigurować lokalizację przed dodaniem użytkowników, możesz najpierw utworzyć lokalizację, a później dodać użytkowników.",
  "components.locationForm.settings.nameDescription2": "Ta domyślna strefa czasowa jest używana w całym systemie. Na przykład do dokładnego wyświetlania informacji o urlopach w kalendarzu i dla zdarzeń systemowych wymienionych w Logach.",
  "components.locationForm.settings.namePlaceholder": "Wprowadź nazwę lokalizacji",
  "components.locationForm.settings.nameTitle": "Ustawienia lokalizacji",
  "components.locationForm.settings.quotasDescription": "To ustawienie określi, czy Twój bilans urlopowy zostanie zresetowany na podstawie roku obrachunkowego (roku podatkowego firmy) czy na podstawie daty rozpoczęcia pracy pracownika. Oprócz limitów, Twoja polityka przenoszenia dni również będzie zależeć od tego ustawienia.",
  "components.locationForm.settings.quotasTitle": "Limit urlopowy i rok obrachunkowy",
  "components.locationForm.settings.workWeekDescription": "Proszę wybrać dni robocze tygodnia, aby zapewnić dokładne śledzenie danych urlopowych.",
  "components.locationForm.settings.workWeekTitle": "Tydzień pracy i dzień roboczy",
  "components.locationForm.users": "Użytkownicy",
  "components.locationForm.userYear": "Data zatrudnienia użytkownika",
  "components.locationForm.warningInitialRollover": "Wszelkie zmiany w ustawieniach lokalizacji mogą wpłynąć na konfigurację użytkowników i informacje o urlopach, takie jak limit urlopowy i obliczenia. \nPamiętaj, że jeśli masz ustawioną datę wygaśnięcia przeniesienia w jakiejkolwiek polityce urlopowej w tej lokalizacji, zostanie ona wyłączona.",
  "components.locationForm.warningInitialRolloverFiscalYear": "Wszelkie zmiany w ustawieniach lokalizacji mogą wpłynąć na konfigurację użytkowników i informacje o urlopach, takie jak limit urlopowy i obliczenia.",
  "components.locationForm.warningInitialRolloverResetQuotas": "Wszelkie zmiany w ustawieniach lokalizacji mogą wpłynąć na konfigurację użytkowników i informacje o urlopach, takie jak limit urlopowy i obliczenia.",
  "components.locationForm.warningInitialRolloverUsers": "Dodanie lub usunięcie użytkownika może wpłynąć na konfigurację użytkownika i informacje o urlopach (np. początkowe dni przeniesione).",
  "components.locationForm.workWeek": "Tydzień pracy",
  "components.locationHolidays.addHolidays": "Dodaj święto",
  "components.locationHolidays.deleteHolidaysConfirm": "Czy na pewno chcesz usunąć święto {name}?",
  "components.locationHolidays.deleteHolidaysTitle": "Usuń święto",
  "components.locationHolidays.holidaysForLocation": "Święta dla lokalizacji {locationName}",
  "components.locationHolidays.insertHolidays": "Importuj święta",
  "components.locationHolidays.overlap": "To święto pokrywa się z istniejącym",
  "components.locationLeavePolicies.activatingLeaveTypeInfo": "Aktywując ten typ urlopu, pozwalasz użytkownikom na wnioskowanie o ten konkretny typ urlopu. \n\nPoprzednie ustawienia i dane zostaną przywrócone.",
  "components.locationLeavePolicies.activatingLeaveTypeTitle": "Aktywuj typ urlopu",
  "components.locationLeavePolicies.allowHalfDays": "Zezwalaj na pół dnia",
  "components.locationLeavePolicies.createNewLeavePolicy": "Przypisz politykę urlopową",
  "components.locationLeavePolicies.createNewLeavePolicyInfo": "Aby dodać niestandardowy typ urlopu do lokalizacji, przejdź do Przypisz politykę urlopową. Wybierz typ urlopu, który już utworzyłeś w zakładce Typy urlopów i skonfiguruj ustawienia polityki urlopowej.",
  "components.locationLeavePolicies.daysPerYear": "Roczny limit urlopowy",
  "components.locationLeavePolicies.deactivateLeaveTypeInfo": "Po dezaktywacji tego typu urlopu, użytkownicy w tej lokalizacji nie będą już mogli wnioskować o ten konkretny typ urlopu. Gdy aktywujesz go ponownie, typ urlopu zostanie przywrócony z poprzednimi ustawieniami i danymi. \n\nJeśli chcesz dezaktywować ten typ urlopu dla wszystkich lokalizacji, możesz to zrobić w zakładce Typy urlopów.",
  "components.locationLeavePolicies.deactivateLeaveTypeTitle": "Dezaktywuj typ urlopu",
  "components.locationLeavePolicies.hasUnlimitedDays": "Nieograniczony",
  "components.locationLeavePolicies.isApprovalRequired": "Wymagana akceptacja",
  "components.locationLeavePolicies.leaveTypeName": "Nazwa typu urlopu",
  "components.locationLeavePolicies.status": "Status",
  "components.locationLeavePolicy.createInProgress": "Tworzenie polityki urlopowej {name}",
  "components.locationLeavePolicy.successTitle": "Polityka urlopowa została ustawiona",
  "components.locationLeavePolicy.updateInProgress": "Aktualizacja polityki urlopowej",
  "components.locationLeaveTypesTag.hiddenLeaveType": "Ukryty typ urlopu",
  "components.locationLeaveTypesTag.holidays": "Święta",
  "components.logs.accrual": "Użytkownik naliczył {earned} {leaveTypeName} {earnedDayInGrammaticalNumber} (z {defaultDaysPerYear} {defaultDayInGrammaticalNumber} limitu)",
  "components.logs.accrualDays": "Użytkownik naliczył {earned} {leaveTypeName} {earned, plural, =1 {dzień} few {dni} many {dni} other {dnia}} (z {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {dnia} few {dni} many {dni} other {dnia}} przydziału)",
  "components.logs.accrualDisplay": "Użytkownik naliczył {earned} {leaveTypeName} (z {defaultDaysPerYear} przydziału)",
  "components.logs.accrualHours": "Użytkownik naliczył {earned} {leaveTypeName} {earned, plural, =1 {godzinę} few {godziny} many {godzin} other {godziny}} (z {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {godziny} few {godzin} many {godzin} other {godziny}} przydziału)",
  "components.logs.automationAddonsUpdatedPurchased": "{user} zakupił dodatek: {automationType}",
  "components.logs.automationAddonsUpdatedUnsubscribed": "{user} anulował subskrypcję dodatku: {automationType}",
  "components.logs.billingCancelled": "Rozliczenie anulowane w {paymentProcessor}",
  "components.logs.billingUpdated": "Rozliczenie zaktualizowane w {paymentProcessor}",
  "components.logs.blackoutPeriodCreated": "{user} utworzył okres blokady <b>{name}</b>.<br></br>Włączony: {enabled}.<br></br>Powtarzający się: {recurring}.<br></br>Data rozpoczęcia: {startDate}.<br></br>Data zakończenia: {endDate}.<br></br>Typy urlopów: {leaveTypes}.<br></br>Lokalizacje: {locations}.<br></br>Działy: {teams}.<br></br>Etykiety: {labels}.<br></br>",
  "components.logs.blackoutPeriodDeleted": "{user} usunął okres blokady.",
  "components.logs.blackoutPeriodUpdated": "{user} zaktualizował okres blokady <b>{name}</b>.<br></br>Włączony: {enabled}.<br></br>Powtarzający się: {recurring}.<br></br>Data rozpoczęcia: {startDate}.<br></br>Data zakończenia: {endDate}.<br></br>Typy urlopów: {leaveTypes}.<br></br>Lokalizacje: {locations}.<br></br>Działy: {teams}.<br></br>Etykiety: {labels}.<br></br>",
  "components.logs.companyCreated": "Firma <b>{name}</b> została utworzona",
  "components.logs.companySettingsUpdated": "<b>{user}</b> zaktualizował ustawienia firmy.<br></br>Ogłaszaj nowych użytkowników: {announceNewUsers}.<br></br>Rozliczanie urlopów godzinowe: {hourlyLeaveAccounting}.<br></br>Data zakończenia dla użytkownika: {userEndDate}.<br></br>Rok rozpoczęcia obliczeń: {calculationStartYear}<br></br>Automatyczne importowanie użytkowników: {importUsersAutomatically}",
  "components.logs.deleted": "<Usunięto>",
  "components.logs.earned": "Użytkownik zarobił {total} dni {leaveTypeName} z nadgodzin (TOIL) {period}. {showExpiration, plural, =1 {Dni wygasną } other {}}{expiration}",
  "components.logs.entitlementByRoleCreated": "{user} utworzył uprawnienie według roli <b>{name}</b>.<br></br>Włączone: {enabled}.<br></br>Dni: {days}.<br></br>Etykieta: {label}.<br></br>Typ urlopu: {leaveType}.<br></br>",
  "components.logs.entitlementByRoleDeleted": "{user} usunął uprawnienie według roli.",
  "components.logs.entitlementByRoleUpdated": "{user} zaktualizował uprawnienie według roli <b>{name}</b>.<br></br>Włączone: {enabled}.<br></br>Dni: {days}.<br></br>Etykieta: {label}.<br></br>Typ urlopu: {leaveType}.<br></br>",
  "components.logs.failedToLoadLog": "Nie udało się załadować logu",
  "components.logs.importLeaves": "<b>{user}</b> zaimportował {totalLeaves} {totalLeaves, plural, =1 {urlop} few {urlopy} many {urlopów} other {urlopu}}.",
  "components.logs.importUsers": "<b>{user}</b> zaimportował {totalUsers} {totalUsers, plural, =1 {użytkownika} few {użytkowników} many {użytkowników} other {użytkownika}}.",
  "components.logs.initialRolloverDeleted": "Początkowe przeniesione dni dla typu urlopu: {leaveTypeName} zresetowane do 0 przez <link>{creatorName}</link>",
  "components.logs.initialRolloverUpdated": "Początkowe przeniesione dni dla typu urlopu: {leaveTypeName} zmienione na {customRolloverDays} przez <link>{creatorName}</link>",
  "components.logs.leavePolicyDisabled": "{user} wyłączył politykę urlopową <b>{leaveTypeName}</b> dla lokalizacji <b>{locationName}</b>",
  "components.logs.leavePolicyEnabled": "{user} włączył politykę urlopową <b>{leaveTypeName}</b> dla lokalizacji <b>{locationName}</b>.<br></br>Dni w roku: {daysPerYear}.<br></br>Maks. dni przenoszonych: {maxRolloverDays}.<br></br>Czy przenoszone dni powinny wygasać: {enableRolloverExpiry}.<br></br>Przenoszone dni wygasają dnia: {rolloverExpiryDate}.<br></br>Przenoszone dni wygasają po dniach: {rolloverExpiryAfterDays}.<br></br>Wymagana akceptacja: {isApprovalRequired}.<br></br>Nieograniczona liczba dni: {hasUnlimitedDays}.<br></br>Najkrótszy odstęp urlopu: {shortestLeaveInterval}.<br></br>Wymagany powód: {isReasonRequired}.<br></br>Typ naliczania: {accrualType}.<br></br>Dozwolone ujemne saldo: {negativeBallanceAllowed}.<br></br>Zezwól na urlop w przeszłości: {allowLeaveInPast}.<br></br>Limit naliczania: {accrualCapRate, plural, =0 {Wyłączony} other {Włączony z wartością {accrualCapRate}}}.<br></br>Ukryj typ urlopu: {hideLeaveType}.<br></br>TOIL włączony: {toil}.<br></br>Najkrótszy odstęp TOIL: {toilShortestInterval}.<br></br>TOIL dla wszystkich użytkowników: {toilRequestsAllowedForUsers}.<br></br>Wygaśnięcie TOIL: {toilExpiration}.<br></br>Data wygaśnięcia TOIL: {toilExpirationDate}.<br></br>Miesiące wygaśnięcia TOIL: {toilExpirationMonths}.",
  "components.logs.leaveTypeCreated": "{user} utworzył typ urlopu <b>{name}</b> i przypisał do lokalizacji: <b>{locations}</b>.<br></br>Kolor: {color}.<br></br>Dni w roku: {daysPerYear}.<br></br>Maks. liczba przenoszonych dni: {maxRolloverDays}.<br></br>Wymagana akceptacja: {isApprovalRequired}.<br></br>Nieograniczona liczba dni: {hasUnlimitedDays}.<br></br>Najkrótszy odstęp urlopu: {shortestLeaveInterval}.<br></br>Wymagany powód: {isReasonRequired}.<br></br>Typ naliczania: {accrualType}.<br></br>Dozwolone ujemne saldo: {negativeBallanceAllowed}.<br></br>Zezwól na dodawanie urlopów w przeszłości: {allowLeaveInPast}.<br></br>Ukryj typ urlopu: {hideLeaveType}",
  "components.logs.leaveTypeDeleted": "{user} usunął typ urlopu <b>{name}</b>",
  "components.logs.leaveTypeUpdated": "{user} zaktualizował typ urlopu <b>{name}</b>.<br></br>Aktywny: {isActive}.<br></br>Kolor: {color}",
  "components.logs.locationCreated": "{user} utworzył lokalizację <b>{name}</b>.<br></br>Miesiąc rozpoczęcia roku: {yearStartMonth}.<br></br>Dzień rozpoczęcia roku: {yearStartDay}.<br></br>Domyślna lokalizacja: {isDefault}.<br></br>Pierwszy dzień tygodnia: {firstDayOfWeek}.<br></br>Reset przydziału oparty na: {resetQuotas}.<br></br>Miesiąc wygaśnięcia przeniesienia: {rolloverExpiryMonth}.<br></br>Dzień wygaśnięcia przeniesienia: {rolloverExpiryDay}.<br></br>Wygaśnięcie przeniesienia po dniach: {rolloverExpiryAfterDays}.<br></br>Strefa czasowa: {timezone}.<br></br>Użytkownicy: {users}.<br></br>Dni: {days}",
  "components.logs.locationDeleted": "{user} usunął lokalizację",
  "components.logs.locationHolidaysAutoimportOFF": "<b>{user}</b> wyłączył automatyczne importowanie świąt dla lokalizacji <b>{location}</b>",
  "components.logs.locationHolidaysAutoimportON": "<b>{user}</b> włączył automatyczne importowanie świąt dla lokalizacji <b>{location}</b><br></br>Święta: {holidays}",
  "components.logs.locationUpdated": "{user} zaktualizował lokalizację <b>{name}</b>.<br></br>Miesiąc rozpoczęcia roku: {yearStartMonth}.<br></br>Dzień rozpoczęcia roku: {yearStartDay}.<br></br>Domyślna lokalizacja: {isDefault}.<br></br>Pierwszy dzień tygodnia: {firstDayOfWeek}.<br></br>Reset przydziałów: {resetQuotas}.<br></br>Miesiąc wygaśnięcia przeniesienia: {rolloverExpiryMonth}.<br></br>Dzień wygaśnięcia przeniesienia: {rolloverExpiryDay}.<br></br>Wygaśnięcie przeniesienia po dniach: {rolloverExpiryAfterDays}.<br></br>Strefa czasowa: {timezone}.<br></br>Użytkownicy: {users}",
  "components.logs.locationWorkWeekUpdated": "{user} zaktualizował tydzień pracy dla lokalizacji <b>{name}</b>.<br></br>Dni: {days}.<br></br>Godziny: {hours}",
  "components.logs.locationYearHolidaysChanged": "<b>{user}</b> zmienił święto(a) dla roku {year} w lokalizacji <b>{name}</b>.<br></br>Święta: {holidays}",
  "components.logs.locationYearRollover": "Nastąpiło przeniesienie roku dla lokalizacji <b>{name}</b>",
  "components.logs.nonAccrualLeaveType": "Użytkownik otrzymał {earned} <b>{leaveTypeName}</b> za {period}",
  "components.logs.nonAccrualLeaveTypeDays": "Użytkownik otrzymał {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {dzień} few {dni} many {dni} other {dnia}} za {period}",
  "components.logs.nonAccrualLeaveTypeHours": "Użytkownik otrzymał {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {godzinę} few {godziny} many {godzin} other {godziny}} za {period}",
  "components.logs.notificationCreated": "Użytkownik {user} utworzył powiadomienie <b>{name}</b>.<br></br>Częstotliwość: {frequency}.<br></br>Włączone: {enabled}.<br></br>Lokalizacje: {locations}.<br></br>Działy: {teams}.<br></br>Etykiety: {labels}.<br></br>Pierwszy dzień tygodnia: {firstDayOfWeek}.<br></br>Strefa czasowa: {timezone}.<br></br>Grupuj według {groupBy}.<br></br>Wysyłaj puste: {sendEmpty}.<br></br>Miejsca docelowe: {destinations}.<br></br>Minuta: {minute}.<br></br>Godzina: {hour}.<br></br>Dzień: {day}.<br></br>Język: {locale}.<br></br>Wysyłaj w dni: {sendOnDays}.<br></br>Bieżący tydzień: {currentWeek}",
  "components.logs.notificationDeleted": "Użytkownik {user} usunął powiadomienie",
  "components.logs.notificationDetailsDaily": "Użytkownik {user} {action} powiadomienie <b>{name}</b>.<br></br>Częstotliwość: {frequency}.<br></br>Włączone: {enabled}.<br></br>Lokalizacje: {locations}.<br></br>Działy: {teams}.<br></br>Etykiety: {labels}.<br></br>Strefa czasowa: {timezone}.<br></br>Grupuj według {groupBy}.<br></br>Wysyłaj puste: {sendEmpty}.<br></br>Miejsca docelowe: {destinations}.<br></br>Minuta: {minute}.<br></br>Godzina: {hour}.<br></br>Język: {locale}.<br></br>Wysyłaj w dni: {sendOnDays}.",
  "components.logs.notificationDetailsMonthly": "Użytkownik {user} {action} powiadomienie <b>{name}</b>.<br></br>Częstotliwość: {frequency}.<br></br>Włączone: {enabled}.<br></br>Lokalizacje: {locations}.<br></br>Działy: {teams}.<br></br>Etykiety: {labels}.<br></br>Pierwszy dzień tygodnia: {firstDayOfWeek}.<br></br>Strefa czasowa: {timezone}.<br></br>Grupuj według {groupBy}.<br></br>Wysyłaj puste: {sendEmpty}.<br></br>Miejsca docelowe: {destinations}.<br></br>Minuta: {minute}.<br></br>Godzina: {hour}.<br></br>Dzień: {day}.<br></br>Język: {locale}.<br></br>Bieżący tydzień: {currentWeek}",
  "components.logs.notificationUpdated": "Użytkownik {user} zaktualizował powiadomienie <b>{name}</b>.<br></br>Częstotliwość: {frequency}.<br></br>Włączone: {enabled}.<br></br>Lokalizacje: {locations}.<br></br>Działy: {teams}.<br></br>Etykiety: {labels}.<br></br>Pierwszy dzień tygodnia: {firstDayOfWeek}.<br></br>Strefa czasowa: {timezone}.<br></br>Grupuj według {groupBy}.<br></br>Wysyłaj puste: {sendEmpty}.<br></br>Miejsca docelowe: {destinations}.<br></br>Minuta: {minute}.<br></br>Godzina: {hour}.<br></br>Dzień: {day}.<br></br>Język: {locale}.<br></br>Wysyłaj w dni: {sendOnDays}.<br></br>Bieżący tydzień: {currentWeek}",
  "components.logs.probationPeriodCreated": "{user} utworzył Okres Próbny <b>{name}</b>.<br></br>Włączony: {enabled}.<br></br>Długość (dni): {lengthDays}.<br></br>Rodzaje urlopów: {leaveTypes}.<br></br>Lokalizacje: {locations}.<br></br>Działy: {teams}.<br></br>Etykiety: {labels}.<br></br>",
  "components.logs.probationPeriodDeleted": "{user} usunął Okres Próbny.",
  "components.logs.probationPeriodUpdated": "{user} zaktualizował Okres Próbny <b>{name}</b>.<br></br>Włączony: {enabled}.<br></br>Długość (dni): {lengthDays}.<br></br>Rodzaje urlopów: {leaveTypes}.<br></br>Lokalizacje: {locations}.<br></br>Działy: {teams}.<br></br>Etykiety: {labels}.<br></br>",
  "components.logs.seniorityEntitlementCreated": "{user} utworzył Uprawnienie Stażowe <b>{name}</b>.<br></br>Włączone: {enabled}.<br></br>Proporcjonalne: {prorated}.<br></br>Data rozpoczęcia: {startDate}.<br></br> Rodzaj urlopu: {leaveType}.<br></br>Przyznane: {entitlementPeriods}.<br></br>Lokalizacje: {locations}.<br></br>Działy: {teams}.<br></br>Etykiety: {labels}.<br></br>",
  "components.logs.seniorityEntitlementDeleted": "{user} usunął Uprawnienie Stażowe.",
  "components.logs.seniorityEntitlementUpdated": "{user} zaktualizował Uprawnienie Stażowe <b>{name}</b>.<br></br>Włączone: {enabled}.<br></br>Proporcjonalne: {prorated}.<br></br>Data rozpoczęcia: {startDate}.<br></br> Rodzaj urlopu: {leaveType}.<br></br>Przyznane: {entitlementPeriods}.<br></br>Lokalizacje: {locations}.<br></br>Działy: {teams}.<br></br>Etykiety: {labels}.<br></br>",
  "components.logs.subscriptionCancelled": "<b>{user}</b> anulował subskrypcję.<br></br>Powód: {reason}.<br></br>Komentarz: {comment}",
  "components.logs.subscriptionChanged": "Subskrypcja została zmieniona.<br></br>Nowy plan: {plan}.<br></br>Okres: {period}.<br></br>Status: {status}.<br></br>Procesor płatności: {paymentProcessor}",
  "components.logs.substituteApproverCreated": "{substituteApproverName} został ustawiony jako Zastępczy Zatwierdzający dla {approverFor} przez {creator} od {startDate} do {endDate}",
  "components.logs.substituteApproverDeleted": "{creator} usunął {substituteApproverName} jako Zastępczego Zatwierdzającego.",
  "components.logs.substituteApproverUpdated": "{creator} zaktualizował zastępczego zatwierdzającego.<br></br>Nazwa Zastępczego Zatwierdzającego: {substituteApproverName}<br></br>Data rozpoczęcia: {startDate}<br></br>Data zakończenia: {endDate}<br></br>",
  "components.logs.teamApproverStatusChanged": "{user} ustawił zatwierdzających {approvers} dla zespołu {team}",
  "components.logs.teamCreated": "{user} utworzył dział <b>{team}</b>.<br></br>Domyślny dział: {isDefault}.<br></br>Użytkownicy: {users}.<br></br>Zatwierdzający: {approvers} <br></br>Dział nadrzędny: {parentDepartment}",
  "components.logs.teamDeleted": "{user} usunął zespół",
  "components.logs.teamUpdated": "{user} zaktualizował dział <b>{team}</b>.<br></br>Domyślny dział: {isDefault}.<br></br>Użytkownicy: {users}.<br></br>Zatwierdzający: {approvers} <br></br>Dział nadrzędny: {parentDepartment}",
  "components.logs.userActivatedBy": "Użytkownik został aktywowany przez <link>{creatorName}</link>",
  "components.logs.userApproverStatus": "Użytkownik jest <status></status> jako zatwierdzający dla działu <team>{teamName}</team> przez <link>{creatorName}</link>",
  "components.logs.userChangedAvatar": "Użytkownik zaktualizował swoje zdjęcie profilowe",
  "components.logs.userDeactivatedBy": "Użytkownik został dezaktywowany przez <link>{creatorName}</link>",
  "components.logs.userDeletedBy": "Użytkownik został usunięty przez <link>{creatorName}</link>",
  "components.logs.userEntitlementByRole": "Użytkownikowi przyznano {totalDays} dzień/dni {leaveTypeName} poprzez Uprawnienie według Roli",
  "components.logs.userEntitlementByRoleDeleted": "Dni Uprawnienia według Roli użytkownika dla {leaveTypeName} zostały usunięte z powodu usunięcia etykiety <b>{labelName}</b>.",
  "components.logs.userEntitlementByRoleOverride": "Dni uprawnienia użytkownika dla {leaveTypeName} zostały nadpisane na {totalDays} dzień/dni z powodu dostosowań Uprawnienia według Roli",
  "components.logs.userInvitationAccepted": "Użytkownik zaakceptował zaproszenie od <link>{creatorName}</link>",
  "components.logs.userLeaveTypeDeleteQuota": "Limit dla typu urlopu <b>{leaveTypeName}</b> został zresetowany do wartości domyślnej przez <link>{creatorName}</link>",
  "components.logs.userLeaveTypeUnlimitedDays": "nieograniczone",
  "components.logs.userLeaveTypeUpdatedQuota": "<link>{creatorName}</link> zmienił limit dla tego użytkownika dla <b>{leaveTypeName}</b> na <quota></quota> dni",
  "components.logs.userLeaveTypeUpdatedQuotaDays": "<link>{creatorName}</link> zmienił limit dla tego użytkownika dla <b>{leaveTypeName}</b> na <quota></quota> dni",
  "components.logs.userLeaveTypeUpdatedQuotaHours": "<link>{creatorName}</link> zmienił limit dla tego użytkownika dla <b>{leaveTypeName}</b> na <quota></quota> godzin",
  "components.logs.userMovedToLocation": "Użytkownik został przeniesiony do lokalizacji <locationLink>{location}</locationLink> przez <link>{creatorName}</link>",
  "components.logs.userMovedToTeam": "Użytkownik został przeniesiony do działu <teamLink>{team}</teamLink> przez <link>{creatorName}</link>",
  "components.logs.userRolloverDays": "Przeniesione z poprzedniego roku: {leaveTypeName} - {value} dzień/dni",
  "components.logs.userRolloverDisplay": "Przeniesione z poprzedniego roku: {leaveTypeName} - {value}",
  "components.logs.userRolloverExpired": "<b>{total}</b> <b>{leaveTypeName}</b> dni z poprzedniego roku wygasło",
  "components.logs.userRolloverExpiredDays": "Dni z poprzedniego roku wygasły: <b>{leaveTypeName}</b> - {value} dzień/dni",
  "components.logs.userRolloverExpiredHours": "Dni z poprzedniego roku wygasły: <b>{leaveTypeName}</b> - {value} godzin(y)",
  "components.logs.userRolloverHours": "Przeniesione z poprzedniego roku: <b>{leaveTypeName}</b> - {value} godzin(y)",
  "components.logs.userSeniorityEntitlement": "Użytkownik otrzymał {totalDays} dni {leaveTypeName} poprzez Uprawnienie Stażowe",
  "components.logs.userSeniorityEntitlementOverride": "Dni uprawnienia użytkownika dla {leaveTypeName} zostały nadpisane na {totalDays} dni z powodu dostosowań Uprawnienia Stażowego",
  "components.logs.userUpdated": "Użytkownik <name></name> <startDate></startDate> <endDate></endDate> <userStatus></userStatus> przez <link>{creatorName}</link>",
  "components.logs.userUpdatedChangeStatusToAdmin": "rola zmieniona na Administrator",
  "components.logs.userUpdatedChangeStatusToUser": "rola zmieniona na Użytkownik",
  "components.logs.userUpdatedEndDate": "data końcowa zmieniona na {endDate}",
  "components.logs.userUpdatedName": "Imię zmienione na <wrappedName>{name}</wrappedName>",
  "components.logs.userUpdatedStartDate": "Data rozpoczęcia zmieniona na {startDate}",
  "components.logs.userWorkWeek": "Tydzień pracy użytkownika został zmieniony na {workDays} przez <link>{creatorName}</link>",
  "components.logs.userWorkWeekDefaultLocation": "Tydzień pracy użytkownika został zresetowany do domyślnych ustawień lokalizacji przez <link>{creatorName}</link>",
  "components.logs.visibilityAddonCreated": "{user} ograniczył widoczność dla firmy.<br></br>Włączone: {enabled}.<br></br> Poziom widoczności: {visibilityLevel}.<br></br>",
  "components.logs.visibilityAddonUpdated": "{user} zaktualizował ograniczoną widoczność dla firmy.<br></br>Włączone: {enabled}.<br></br> Poziom widoczności: {visibilityLevel}.<br></br>",
  "components.manageAssignLicenses.assignLicenses": "Przypisz {numberOfToAssignLicenses} licencji",
  "components.manageAssignLicenses.avaliableToAssign": "Dostępne do przypisania: {avaliableLicenses}",
  "components.manageAssignLicenses.microsoftTeamsMessage": "Wiadomość Microsoft Teams",
  "components.manageAssignLicenses.moreUserThenLicenses": "Przypisałeś {users} licencji więcej niż jest dostępnych",
  "components.manageAssignLicenses.placeholderBulkWelcomeMessage": "Wybierz działanie masowe",
  "components.manageAssignLicenses.placeholderSelectRole": "Wybierz rolę",
  "components.manageAssignLicenses.roleTooltipInfo": "Przypisz role użytkowników w Vacation Tracker. Więcej informacji znajdziesz w naszym <helpDesk>Centrum pomocy</helpDesk>.",
  "components.manageAssignLicenses.sendWelcomeMessage": "Wyślij wiadomość powitalną",
  "components.manageAssignLicenses.totalLicenses": "Łączna liczba licencji: {numberOflicenses}",
  "components.modal.confirm": "Potwierdź",
  "components.modal.verify": "Zweryfikuj",
  "components.notificationDestination.label": "Wyślij do",
  "components.notificationDestination.tooltip": "Pamiętaj, że możesz wysłać powiadomienie na wiele adresów e-mail, ale tylko na jeden kanał jednocześnie.",
  "components.notificationForm.channel": "Kanał",
  "components.notificationForm.channelSlackInfo": "Powiadomienia mogą być wysyłane tylko na kanały, których jesteś członkiem.",
  "components.notificationForm.daily": "Codziennie",
  "components.notificationForm.day": "Dzień",
  "components.notificationForm.firstDayOfWeek": "Tydzień zaczyna się w",
  "components.notificationForm.refreshTheList": "Odśwież listę",
  "components.notificationForm.team": "Zespół Microsoft Teams",
  "components.notificationForm.time": "Czas",
  "components.notificationForm.timezone": "Strefa czasowa",
  "components.notificationForm.weekly": "Tygodniowo",
  "components.notificationSummaryInfo.notificationSummary": "Powiadomienie zostanie wysłane <to>do</to> <channelWrapper>{channelName} </channelWrapper> <and>i</and> <addressesWrapper>{numberOfEmailAddress} adres<multiEmailAddress>y</multiEmailAddress> e-mail</addressesWrapper> w {sendingDaysInfo} o godzinie {time}. Będzie zawierać informacje o urlopach <danger>{noOfUsers} Użytkownik<multiUsers>ów</multiUsers></danger> <link>(Zobacz Użytkownik<multiUsers>ów</multiUsers>)</link>",
  "components.onboarding.assignLicensesTitle": "<icon></icon> Przypisz licencje",
  "components.onboarding.installMSBotButton": "Zainstaluj bota",
  "components.onboarding.installMSBotDescription": "Zainstaluj Bota i Zakładki Vacation Tracker, aby łatwo zarządzać urlopami zespołu w Microsoft Teams.",
  "components.onboarding.installMSBotTitle": "<icon></icon> Zakończ konfigurację Bota",
  "components.onboarding.inviteFriendsDescription": "Zaangażuj swój zespół w dokładne i terminowe zarządzanie urlopami. (Dodaj co najmniej 3 członków zespołu, aby ukończyć ten krok.)",
  "components.onboarding.inviteFriendsTitle": "<icon></icon> Zaproś członków swojego zespołu",
  "components.onboarding.joyride.admin.assignLeavePolicy.info1": "Jak wspomnieliśmy wcześniej, typy urlopów są tworzone dla całej organizacji. Zasady urlopowe są powiązane z lokalizacjami. Każdy typ urlopu może być ustawiony dla każdej lokalizacji poprzez przypisanie mu niestandardowej zasady urlopowej.",
  "components.onboarding.joyride.admin.assignLeavePolicy.info2": "Po wybraniu typu urlopu, który chcesz dodać do lokalizacji, możesz przypisać zasadę urlopową. Masz opcję skopiowania ustawień z istniejącej zasady urlopowej lub stworzenia całkowicie nowej zasady urlopowej z nowymi wartościami.",
  "components.onboarding.joyride.admin.assignLeavePolicy.title": "Przypisz zasady urlopowe",
  "components.onboarding.joyride.admin.createLocation.info1": "Jeśli masz członków zespołu w różnych lokalizacjach, masz możliwość utworzenia wielu lokalizacji w Vacation Tracker i dodania użytkowników do odpowiedniej lokalizacji.",
  "components.onboarding.joyride.admin.createLocation.info2": "Każda lokalizacja ma swoje własne zasady urlopowe, które musisz przypisać do określonego typu urlopu. Święta są również powiązane z lokalizacjami.",
  "components.onboarding.joyride.admin.createLocation.title": "Utwórz lokalizację",
  "components.onboarding.joyride.admin.createNotifications.info1": "Teraz, gdy ukończyłeś wyżej wymienione kroki, czas utworzyć powiadomienia.",
  "components.onboarding.joyride.admin.createNotifications.title": "Twórz i edytuj powiadomienia",
  "components.onboarding.joyride.admin.createTeams.info1": "Po ukończeniu powyższych kroków, panel kontrolny daje ci przegląd dzisiejszych i zaplanowanych urlopów w twojej organizacji. Teraz czas utworzyć działy.",
  "components.onboarding.joyride.admin.createTeams.info2": "Podczas tworzenia działu będziesz musiał dodać zatwierdzających. Zatwierdzający mają drugi najwyższy poziom uprawnień w Vacation Tracker i mogą zatwierdzać/odrzucać wnioski urlopowe wysłane przez użytkowników z działu, w którym są zatwierdzającymi.",
  "components.onboarding.joyride.admin.createTeams.title": "Utwórz działy i przypisz zatwierdzających",
  "components.onboarding.joyride.admin.helpDesk.info": "Jeśli masz jakiekolwiek pytania lub potrzebujesz więcej informacji, sprawdź nasze Biuro Pomocy lub rozpocznij czat z naszym zespołem obsługi klienta.",
  "components.onboarding.joyride.admin.helpDesk.title": "Biuro Pomocy",
  "components.onboarding.joyride.admin.importHolidays.info1": "Święta w Vacation Tracker są ustawiane dla każdej lokalizacji, a nie dla całej organizacji.",
  "components.onboarding.joyride.admin.importHolidays.info2": "Masz opcję importowania świąt z naszej listy predefiniowanych świąt dla każdego kraju oraz opcję ręcznego importowania święta.",
  "components.onboarding.joyride.admin.importHolidays.title": "Importuj święta",
  "components.onboarding.joyride.admin.leaveTypesMenu.info1": "Zawsze zalecamy rozpoczęcie przygody z Vacation Tracker od utworzenia typów urlopów. Będziesz przypisywać te typy urlopów później, gdy utworzysz wszystkie lokalizacje, w których twoja firma ma pracowników.",
  "components.onboarding.joyride.admin.leaveTypesMenu.info2": "Odwiedź <link>Helpdesk</link>, aby uzyskać szczegółowy przewodnik, jak tworzyć i edytować typy urlopów.",
  "components.onboarding.joyride.admin.leaveTypesMenu.title": "Utwórz typy urlopów",
  "components.onboarding.joyride.admin.newLeaveType.info1": "Oprócz domyślnego typu urlopu (PTO), możesz utworzyć do 24 dodatkowych typów urlopów.",
  "components.onboarding.joyride.admin.start.title": "Poznaj Vacation Tracker! 🏖️",
  "components.onboarding.joyride.admin.start1": "Aby pomóc ci rozpocząć, przygotowaliśmy kilka krótkich kroków, które zalecamy, abyś wykonał, aby szybko skonfigurować swoją organizację.",
  "components.onboarding.joyride.admin.start2": "Jeśli potrzebujesz naszej pomocy po drodze, jesteśmy tylko jeden e-mail od ciebie na adresie <link>hello@vacationtracker.io</link>",
  "components.onboarding.joyride.admin.teams.cta1": "Zainstaluj bota Microsoft Teams",
  "components.onboarding.joyride.admin.teams.cta2": "Dowiedz się więcej w naszym Helpdesku",
  "components.onboarding.joyride.admin.teams.info1": "Wszystko gotowe na panelu kontrolnym. Ostatnim krokiem jest zainstalowanie bota Microsoft Teams, aby twój zespół mógł składać wnioski bezpośrednio w Microsoft Teams.",
  "components.onboarding.joyride.admin.teams.title": "Microsoft Teams",
  "components.onboarding.joyride.approver.dashboard.info1": "Ta strona to twoje centrum dowodzenia. Daje ci przegląd oczekujących urlopów, kto jest dziś nieobecny i nadchodzących urlopów dla twojej organizacji.",
  "components.onboarding.joyride.approver.leaveRequests.info1": "Jako zatwierdzający możesz \"Dodać urlop\" dla każdego członka zespołu, dla którego jesteś zatwierdzającym. Możesz również \"Wnioskować o urlop\" dla siebie, który zostanie wysłany do zatwierdzenia przez twojego zatwierdzającego.",
  "components.onboarding.joyride.approver.notifications.info1": "Ta strona oferuje przegląd wszystkich powiadomień wysyłanych w twojej organizacji.",
  "components.onboarding.joyride.approver.notifications.info2": "Możesz tworzyć lub edytować powiadomienia dla użytkowników działów, dla których jesteś zatwierdzającym.",
  "components.onboarding.joyride.approver.notifications.info3": "Pamiętaj, że możesz zarządzać tylko powiadomieniami utworzonymi przez ciebie.",
  "components.onboarding.joyride.approver.users.info1": "Strona 'Użytkownicy' daje ci pełny przegląd wszystkich aktywnych użytkowników w twoim dziale wraz z dostępem tylko do odczytu do ich profili użytkownika. Ich oczekujące wnioski są dostępne na stronie 'Panel kontrolny'.",
  "components.onboarding.joyride.user.calendar.info1": "Na tej stronie możesz zobaczyć historię i nadchodzące urlopy dla twojej organizacji w formacie kalendarza.",
  "components.onboarding.joyride.user.dashboard.info1": "Panel kontrolny daje ci przegląd dzisiejszych i zaplanowanych urlopów w twojej organizacji.",
  "components.onboarding.joyride.user.holidays.info1": "Tutaj możesz przejrzeć święta w twojej lokalizacji, aby upewnić się, że wiesz, które dni masz już wolne.",
  "components.onboarding.joyride.user.myProfile.info1": "Tutaj możesz zobaczyć dostępne typy urlopów, limity, dni robocze i historię twoich urlopów.",
  "components.onboarding.joyride.user.requestLeave.info1": "Aby wnioskować o urlop przez panel kontrolny Vacation Tracker, wybierz jeden z dostępnych typów urlopów, dodaj okres i powód, jeśli to konieczne, i złóż wniosek. Po złożeniu zostanie on wysłany do zatwierdzenia przez zatwierdzającego.",
  "components.onboarding.joyride.user.start1": "Aby pomóc Ci rozpocząć, przygotowaliśmy listę pięciu kroków, które zalecamy wykonać, aby szybko skonfigurować Twoją organizację.",
  "components.onboarding.openNotificationsButton": "Otwórz powiadomienia",
  "components.onboarding.requestALeaveDescription": "Złóż swój pierwszy wniosek o urlop w 3 prostych krokach.",
  "components.onboarding.requestALeaveTitle": "<icon></icon> Złóż wniosek o urlop",
  "components.onboarding.setNotificationsDescription": "Wybierz, jak często chcesz być informowany o urlopach swojego zespołu, aby być na bieżąco.",
  "components.onboarding.setNotificationsTitle": "<icon></icon> Ustaw powiadomienia",
  "components.onboarding.skipOnboarding": "Pomiń wprowadzenie",
  "components.onboarding.syncTheCalendarDescription": "Zintegruj kalendarz Vacation Tracker ze swoim kalendarzem, aby urlopy Twojego zespołu były aktualne.",
  "components.onboarding.syncTheCalendarTitle": "<icon></icon> Synchronizuj kalendarze",
  "components.onboarding.syncTheCalendarVideoForSyncingWithGoogleCalendar": "Film o synchronizacji z Kalendarzem Google",
  "components.onboarding.syncTheCalendarVideoForSyncingWithIcal": "Film o synchronizacji z iCal",
  "components.onboarding.syncTheCalendarVideoForSyncingWithMicrosoftOutlookCalendar": "Film o synchronizacji z MS Windows",
  "components.onboarding.title": "Lista kontrolna wprowadzenia <progressBar>{progressStatus}</progressBar>",
  "components.paymentInformation.expiryDate": "Data ważności",
  "components.paymentInformation.labelCreditCard": "Karta kredytowa",
  "components.paymentInformation.title": "Informacje o płatności ",
  "components.platformSwitchModal.content": "Musisz najpierw zalogować się na swoje konto {platform}, aby przejść na platformę {platform}. Kliknij przycisk poniżej, aby kontynuować. Po zalogowaniu i udzieleniu niezbędnych uprawnień, przekierujemy Cię do procesu mapowania użytkowników.",
  "components.platformSwitchModal.title": "Migracja na platformę {platform}",
  "components.pricePlanCard.accruals": "Naliczaj urlop",
  "components.pricePlanCard.APISupport": "Wsparcie API",
  "components.pricePlanCard.calendarIntegrations": "Integracja z kalendarzem",
  "components.pricePlanCard.contactUs": "Skontaktuj się z nami",
  "components.pricePlanCard.coreDepartmentsAndLocations": "Utwórz do 3 lokalizacji i 10 działów",
  "components.pricePlanCard.currentPlan": "Obecny plan",
  "components.pricePlanCard.customContract": "Indywidualna umowa",
  "components.pricePlanCard.customizeLeaveTypes": "Dostosuj rodzaje urlopów",
  "components.pricePlanCard.customLocationsDepartmentsLeaveTypes": "Nieograniczone lokalizacje, działy i niestandardowe rodzaje urlopów",
  "components.pricePlanCard.customOnboardingAndSetup": "Niestandardowe wdrożenie i konfiguracja",
  "components.pricePlanCard.dedicatedSupport": "Dedykowane wsparcie",
  "components.pricePlanCard.disableDowngradeToCore": "Przejście na plan Core wymaga {noOfActions} {noOfActions, plural, =1 {działania} other {działań}}",
  "components.pricePlanCard.disableDowngradeToCoreDescription": "Aby przejść na plan Core, wprowadź następujące zmiany:",
  "components.pricePlanCard.disableDowngradeToCoreReasonDepartments": "Plan Core pozwala na maksymalnie 10 działów. Usuń dodatkowe działy.",
  "components.pricePlanCard.disableDowngradeToCoreReasonLocations": "Plan Core pozwala na maksymalnie 3 lokalizacje. Usuń dodatkowe lokalizacje.",
  "components.pricePlanCard.disableDowngradeToCoreReasonSubdepartments": "W planie Core działy mogą być zagnieżdżone tylko na jednym poziomie. Obecnie masz {total} poddziałów. Usuń niektóre poddziały, aby przejść na plan Core.",
  "components.pricePlanCard.disableDowngradeToCoreReasonWorkday": "Przejście na plan Core nie jest możliwe przy korzystaniu z niestandardowych godzin pracy. Przywróć domyślne godziny pracy (8) w ustawieniach lokalizacji",
  "components.pricePlanCard.disableDowngradeToCoreTitle": "Obniżenie planu. Wymagane dostosowania",
  "components.pricePlanCard.displayLeaveInformationInDaysHours": "Wyświetlaj informacje o urlopach w dniach/godzinach",
  "components.pricePlanCard.downgradePlan": "Plan zostanie obniżony w następnym cyklu rozliczeniowym",
  "components.pricePlanCard.duringTheTrial": "W okresie próbnym każda zmiana planu zostanie wprowadzona natychmiast",
  "components.pricePlanCard.everythingInTheCompletePlan": "Wszystko, co zawiera plan Complete",
  "components.pricePlanCard.EverythingInTheCorePlan": "Wszystko w planie Core",
  "components.pricePlanCard.exportsAndReports": "Eksporty i Raporty",
  "components.pricePlanCard.forSeats": "dla mniej niż {users} miejsc",
  "components.pricePlanCard.forUsers": "dla mniej niż {users} użytkowników",
  "components.pricePlanCard.hourlyLeaves": "Śledź Urlopy w Godzinach",
  "components.pricePlanCard.HRFields": "Pola HR (wkrótce)",
  "components.pricePlanCard.labels": "Organizuj Użytkowników za pomocą Etykiet",
  "components.pricePlanCard.managePTO": "Zarządzaj wnioskami o urlop",
  "components.pricePlanCard.minimumPlanPrice": "minimum <price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>",
  "components.pricePlanCard.notifications": "Wysyłaj Powiadomienia o Urlopach",
  "components.pricePlanCard.pricePerSeat": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>za miejsce/miesiąc",
  "components.pricePlanCard.pricePerUser": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>za użytkownika/miesiąc",
  "components.pricePlanCard.reports": "Generuj Raporty",
  "components.pricePlanCard.reportsSchedule": "Planuj Raporty",
  "components.pricePlanCard.requestHourlyLeaveIncrements": "Wnioskuj o Urlop w Przyrostach Godzinowych",
  "components.pricePlanCard.scheduleAConsultation": "Zaplanuj Konsultację",
  "components.pricePlanCard.scheduledReport": "Zaplanowane Raporty",
  "components.pricePlanCard.selectPlan": "Wybierz Plan",
  "components.pricePlanCard.sharedCalendars": "Współdzielone Kalendarze",
  "components.pricePlanCard.SSOIntegration": "Integracja SSO dla Przedsiębiorstw (Okta, itp.)",
  "components.pricePlanCard.startFreeTrial": "Rozpocznij 14-dniowy Darmowy Okres Próbny",
  "components.pricePlanCard.substituteApprovers": "Zastępczy Zatwierdzający",
  "components.pricePlanCard.support": "Priorytetowe Wsparcie",
  "components.pricePlanCard.switchPlan": "Przełącz <period> Na Plan {newPeriod}</period>",
  "components.pricePlanCard.totalPrice": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/miesiąc dla {totalUsers} aktywnych użytkowników",
  "components.pricePlanCard.totalPriceForLessThan25Users": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/miesiąc dla mniej niż 25 użytkowników",
  "components.pricePlanCard.totalPricePerSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/rok",
  "components.pricePlanCard.totalPricePerUser": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/rok",
  "components.pricePlanCard.totalPriceSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/miesiąc dla {totalUsers} miejsc",
  "components.pricePlanCard.unlimitedDepartmentsAndLocations": "Twórz Nieograniczoną Liczbę Działów i Lokalizacji",
  "components.pricePlanCard.upgradePlan": "Plan zostanie aktywowany natychmiast, a cena zostanie proporcjonalnie obliczona",
  "components.requestAutomation.anythingElse": "Czy jest coś jeszcze, co powinniśmy wiedzieć?",
  "components.requestAutomation.automationExtraCharge": "Czy korzystałbyś z tego Dodatku, gdyby wiązało się to z dodatkową opłatą?",
  "components.requestAutomation.automationFunctionalities": "Jakie funkcjonalności lub zadania umożliwi ten Dodatek?",
  "components.requestAutomation.automationShouldWeAdd": "Który Dodatek powinniśmy dodać?",
  "components.requestAutomation.submiteRequest": "Wyślij Prośbę",
  "components.requestAutomation.suggestAnAutomation": "Zaproponuj Dodatek",
  "components.resetQuotas.fiscalYear": "Rok Obrachunkowy ({date})",
  "components.resetQuotas.userYear": "Data Zatrudnienia Użytkownika",
  "components.selectChannelForm.addNewEmail": "Dodaj Odbiorcę",
  "components.selectChannelForm.channel": "Kanał",
  "components.selectChannelForm.channelSlackInfo": "Powiadomienia mogą być wysyłane tylko do kanałów, których jesteś członkiem.",
  "components.selectChannelForm.email": "Odbiorcy",
  "components.selectChannelForm.errorNotification": "Błąd powiadomienia",
  "components.selectChannelForm.errorNotInTeams": "Odmowa dostępu. Aby korzystać z tego kanału, musisz być członkiem tego działu.",
  "components.selectChannelForm.notFound": "Twoja organizacja zarejestrowała się z pojedynczym zespołem Microsoft Teams, a ty nie jesteś członkiem zespołu o następującym ID: {msTeamId}.\n\nMożesz dołączyć do tego zespołu w aplikacji Microsoft Teams i spróbować ponownie lub skontaktować się z naszym wsparciem, aby zmienić typ instalacji.",
  "components.selectChannelForm.notFoundTitle": "Nie jesteś członkiem wybranego zespołu Microsoft Teams",
  "components.selectChannelForm.team": "Zespół",
  "components.selectChannelFormMicrosoft.channelPlaceholder": "Proszę wybrać kanał",
  "components.selectChannelFormMicrosoft.errorNotInTeams": "Odmowa dostępu. Aby korzystać z tego kanału, musisz być członkiem tego działu.",
  "components.selectChannelFormMicrosoft.notFound": "Twoja organizacja zarejestrowała się z pojedynczym zespołem Microsoft Teams, a ty nie jesteś członkiem zespołu o następującym ID: {msTeamId}.\n\nMożesz dołączyć do tego zespołu w aplikacji Microsoft Teams i spróbować ponownie lub skontaktować się z naszym wsparciem, aby zmienić typ instalacji.",
  "components.selectChannelFormMicrosoft.notFoundTitle": "Nie jesteś członkiem wybranego zespołu Microsoft Teams",
  "components.selectChannelFormMicrosoft.teamPlaceholder": "Proszę wybrać zespół",
  "components.selectChannelFormSlack.errorNotification": "Błąd powiadomienia",
  "components.selectChannelFormSlack.missingSlackScopes": "Brakujące uprawnienia Slack",
  "components.selectChannelFormSlack.privateChannel": "(kanał prywatny)",
  "components.selectChannelFormSlack.slackBotIdIsRequred": "Błąd Slack: Wymagane jest ID bota Slack! Spróbuj odświeżyć stronę!",
  "components.selectChannelFormSlack.slackError": "Błąd Slack",
  "components.selectChannelFormSlack.toProccedPleaseLogin": "Aby kontynuować, zaloguj się do swojego konta Slack",
  "components.selectChannelFormSlack.youCanAddTheBotOnlyOnTheChannels": "Nie jesteś członkiem {channelName}. Aby wysłać powiadomienie do tego kanału, musisz być jego członkiem.",
  "components.seniorityEntitlement.addDaysFrom": "Dodaj dni od",
  "components.seniorityEntitlement.allFiledsAreRequired": "Wszystkie pola są wymagane",
  "components.seniorityEntitlement.confirmDescription": "Wybranie opcji rozpoczęcia obliczania uprawnień stażowych od daty rozpoczęcia pracy pracownika spowoduje wsteczne dostosowanie limitów użytkowników od roku rozpoczęcia obliczeń.",
  "components.seniorityEntitlement.confirmTitle": "Potwierdź utworzenie automatyzacji",
  "components.seniorityEntitlement.daysAfter": "dni po",
  "components.seniorityEntitlement.disableEditInfo": "Nie możesz edytować automatyzacji uprawnień stażowych. Jeśli potrzebujesz wprowadzić zmiany, usuń tę automatyzację i utwórz nową. <link>Skontaktuj się z nami</link>, jeśli masz pytania lub potrzebujesz pomocy.",
  "components.seniorityEntitlement.hoursAfter": "godzin po",
  "components.seniorityEntitlement.noLocations": "Masz automatyzację, która została zastosowana do wszystkich użytkowników, nie możesz mieć wielu automatyzacji dla wszystkich użytkowników.",
  "components.seniorityEntitlement.notSortedYearInPeriodError": "Lata muszą być posortowane chronologicznie.",
  "components.seniorityEntitlement.prorateForTheFirstYear": "Czy chcesz włączyć proporcjonalne naliczanie?",
  "components.seniorityEntitlement.prorateForTheFirstYearTooltip": "Jeśli data rozpoczęcia pracy pracownika nie pokrywa się z początkiem roku fiskalnego, proporcjonalnie naliczymy dni oparte na stażu pracy w roku, w którym są przyznawane. Na przykład, jeśli twój rok fiskalny zaczyna się 1 stycznia, pracownik zaczął pracę 1 lipca 5 lat temu, a twoja firma przyznaje 5 dodatkowych dni urlopu 5 lipca po 5 latach pracy, pracownik otrzyma 2,5 dnia 1 lipca z powodu proporcjonalnego naliczania. Ten pracownik otrzyma pełne 5 dni stażowych w następnym roku fiskalnym.",
  "components.seniorityEntitlement.sameYearError": "Nie możesz przypisać wielu reguł do tej samej liczby lat.",
  "components.seniorityEntitlement.startApplayingSeniorityEnintlementFrom": "Rozpocznij stosowanie uprawnień stażowych od",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFrom": "Rozpocznij stosowanie uprawnień stażowych od",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFromInfo": "Jeśli wybierzesz rozpoczęcie stosowania uprawnień stażowych od daty rozpoczęcia pracy pracownika, ta zmiana wpłynie na limity użytkownika w przeszłości.",
  "components.seniorityEntitlement.summaryInfoPrefix": "Automatyzacja stażowa przyznaje dodatkowy urlop w podejściu opartym na kamieniach milowych. Na przykład: ",
  "components.seniorityEntitlement.summaryInfoSuffix": ", łącznie {days} <showDays>dni</showDays><showHour>godzin(y)</showHour> urlopu stażowego.",
  "components.seniorityEntitlement.summeryInfoFirstRow": "{days} <showDays>dni</showDays><showHour>godzin(y)</showHour> jest dodawanych po {year} roku",
  "components.seniorityEntitlement.summeryInfoMoreDays": ", {days} więcej <showDays>dni</showDays><showHour>godzin(y)</showHour> po {year} roku",
  "components.seniorityEntitlement.yearsInTheCOmpany": "lat w firmie",
  "components.setupMsBot.followDirectLink": "1) Kliknij na <directLink>ten link</directLink>, aby znaleźć aplikację Vacation Tracker w sklepie Microsoft Teams.",
  "components.setupMsBot.inMSAppPreviewWindows": "2) W oknie podglądu aplikacji kliknij strzałkę obok przycisku \"Otwórz\" i wybierz \"Dodaj do zespołu\"",
  "components.setupMsBot.setUpInMS": "3) Zostaniesz poproszony o wybranie zespołu i kanału, w którym chcesz zainstalować Vacation Tracker. Będziesz miał też możliwość przypięcia Vacation Tracker jako zakładki dla łatwiejszego i szybszego dostępu!",
  "components.setupMsBot.stepByStep": "Aby uzyskać szczegółowe instrukcje krok po kroku, obejrzyj ten film lub odwiedź nasz <helpDesk>Helpdesk</helpDesk>.",
  "components.setupMsBot.title": "Skonfiguruj bota i zakładki Microsoft Teams",
  "components.signupWithAnotherPlatform.connectWith": " Zarejestruj się za pomocą {platform}",
  "components.signupWithAnotherPlatform.info": "Czy chcesz połączyć się ze swoim kontem {platform}? Dzięki temu będziesz mógł importować i synchronizować użytkowników z {importUsersPlatform} oraz zarządzać urlopami bezpośrednio z aplikacji {platform}. Aby uzyskać więcej informacji, sprawdź nasz <helpDesk>Helpdesk</helpDesk>.",
  "components.signupWithAnotherPlatform.or": "Lub",
  "components.signupWithAnotherPlatform.title": "Wygląda na to, że korzystasz z {platform}",
  "components.switchPlanModal.downgradeProbationPeriodWarning": "Dodatek Okres Próbny jest płatną funkcją dla planu Core. Jeśli downgradujesz i nie zrezygnujesz z tego dodatku, będzie on kosztował 0,50 USD za użytkownika miesięcznie.",
  "components.switchPlanModal.switch": "Przełącz",
  "components.switchPlanModal.switchCoreMonthlyToCompleteMonthly": "Plan miesięczny <strong>Complete</strong> zostanie aktywowany natychmiast, a cena zostanie proporcjonalnie obliczona dla bieżącego cyklu rozliczeniowego.<legacyPlan> Pamiętaj, że nie będziesz mógł wrócić do starego planu.</legacyPlan>",
  "components.switchPlanModal.switchFromAnnuallyToMonthly": "Plan miesięczny <strong>{newPlan}</strong> zostanie aktywowany i zafakturowany po zakończeniu bieżącego rocznego cyklu rozliczeniowego ({endDatePeriod}).<legacyPlan> Pamiętaj, że nie będziesz mógł wrócić do starego planu.</legacyPlan>",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreAnnual": "Plan roczny <strong>Core</strong> zostanie aktywowany po zakończeniu bieżącego rocznego cyklu rozliczeniowego ({endDatePeriod}), do tego czasu możesz nadal korzystać z funkcji planu <strong>Complete</strong>",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreMonthly": "Plan <strong>Core</strong> zostanie aktywowany po zakończeniu bieżącego rocznego cyklu rozliczeniowego ({endDatePeriod}), do tego czasu możesz nadal korzystać z funkcji planu <strong>Complete</strong>.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreAnnual": "Plan roczny <strong>Core</strong> zostanie aktywowany po zakończeniu bieżącego miesięcznego cyklu rozliczeniowego ({endDatePeriod}), do tego czasu możesz nadal korzystać z funkcji planu <strong>Complete</strong>.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreMonthly": "Plan miesięczny <strong>Core</strong> zostanie aktywowany po zakończeniu bieżącego cyklu rozliczeniowego ({endDatePeriod}), do tego czasu możesz nadal korzystać z funkcji planu <strong>Complete</strong>.",
  "components.switchPlanModal.switchFromCoreAnnualToCompleteAnnual": "Plan roczny <strong>Complete</strong> zostanie aktywowany natychmiast, a zostaniesz obciążony proporcjonalną różnicą w cenie do końca bieżącego rocznego cyklu rozliczeniowego.<legacyPlan> Pamiętaj, że nie będziesz mógł wrócić do starego planu.</legacyPlan>",
  "components.switchPlanModal.switchFromCoreMonthlyToCompleteAnnual": "Plan roczny <strong>Complete</strong> zostanie aktywowany natychmiast. Twoja roczna cena zostanie obniżona o pozostałą kwotę z bieżącej miesięcznej faktury.<legacyPlan> Pamiętaj, że nie będziesz mógł wrócić do starego planu.</legacyPlan>",
  "components.switchPlanModal.switchFromMonthlyToAnnually": "Plan roczny <strong>{newPlan}</strong> zostanie aktywowany natychmiast. Twoja roczna cena zostanie obniżona o pozostałą kwotę z bieżącej miesięcznej faktury.<legacyPlan> Pamiętaj, że nie będziesz mógł wrócić do starego planu.</legacyPlan>",
  "components.switchPlanModal.title": "Przełącz na plan <strong>{plan}</strong>",
  "components.switchPlanModal.unsubscribeProbationPeriodAddons": "Zrezygnuj z okresu próbnego",
  "components.teamForm.approvers": "Zatwierdzający",
  "components.teamForm.generalSettings": "Ustawienia ogólne",
  "components.teamForm.isDefault": "Ustaw ten dział jako domyślny",
  "components.teamForm.isDefaultExtra": "Ustawiając ten dział jako domyślny, wszyscy nowi członkowie zespołu będą automatycznie dodawani do tego działu",
  "components.teamForm.name": "Nazwa",
  "components.teamForm.pleaseEnterTeamName": "Wprowadź nazwę działu",
  "components.teamForm.users": "Użytkownicy",
  "components.toil.accrualsToilsTotal": "Łącznie zarobione {hours, plural, =1 {godziny} other {dni}} (TOIL + naliczenia): {total}",
  "components.toil.added": "Dodano TOIL",
  "components.toil.addToil": "Dodaj TOIL",
  "components.toil.addToilProgress": "Dodawanie TOIL",
  "components.toil.anotherAdd": "Dodaj kolejny TOIL",
  "components.toil.anotherRequest": "Poproś o kolejny TOIL",
  "components.toil.approveInProgress": "Zatwierdzanie prośby o TOIL",
  "components.toil.bot.message.editedToilRequest": "Cześć, <@{userSlackId}> edytował następującą prośbę o TOIL:",
  "components.toil.bot.message.leaveTypeName": "*Typ urlopu:*\nTOIL - {leaveTypeName}",
  "components.toil.bot.message.requestToil": "Poproś o TOIL",
  "components.toil.bot.message.requestToilSummary": "Proszę rozpocząć prośbę o czas wolny w zamian za nadgodziny, określając daty, w których wykonałeś pracę w nadgodzinach i dołącz wszelkie istotne uwagi. Pamiętaj, że prośby o czas wolny w zamian za nadgodziny mogą być składane tylko za daty w przeszłości, włącznie z dniem dzisiejszym.",
  "components.toil.bot.message.submittedToilRequest": "Witaj, <@{userSlackId}> złożył następujący wniosek o TOIL:",
  "components.toil.bot.notification.slack.toilRequestAdded": "Urlop TOIL został utworzony dla Ciebie przez osobę zatwierdzającą",
  "components.toil.bot.notification.slack.toilRequestAddedSelf": "Dodałeś urlop TOIL",
  "components.toil.bot.notification.slack.toilRequestApproved": "✅ <@{approverName}> zatwierdził wniosek, nie są wymagane dalsze działania.",
  "components.toil.bot.notification.slack.toilRequestApprovedTitle": "Twój wniosek o urlop został zatwierdzony",
  "components.toil.bot.notification.slack.toilRequestCreated": "Witaj, <@{userSlackId}> złożył wniosek o TOIL.",
  "components.toil.bot.notification.slack.toilRequestEdited": "Witaj, <@{userSlackId}> edytował wniosek o TOIL.",
  "components.toil.cancelInProgress": "Anulowanie wniosku o TOIL",
  "components.toil.deleteInProgress": "Usuwanie TOIL",
  "components.toil.denied": "Twój wniosek o TOIL został odrzucony",
  "components.toil.deniedBy": "Wniosek o TOIL został odrzucony przez {approverName}.",
  "components.toil.denyInProgress": "Odrzucanie wniosku o TOIL",
  "components.toil.durationLabel": "Najkrótszy czas trwania urlopu w zamian",
  "components.toil.durationLabelTooltip": "Wybierz najkrótszy czas trwania, który ma zastosowanie do wniosku o TOIL. Ten wybór powinien odzwierciedlać minimalną ilość nadgodzin, jaką użytkownik może zarejestrować.",
  "components.toil.earned": "Zdobyty TOIL",
  "components.toil.edit": "Aktualizuj TOIL",
  "components.toil.edited": "TOIL zaktualizowany",
  "components.toil.editRequestToil": "Edytuj wniosek o urlop w zamian",
  "components.toil.editToilProgress": "Edycja urlopu w zamian",
  "components.toil.enableLabel": "Włącz urlop w zamian:",
  "components.toil.enableLabelTooltip": "TOIL to praktyka, w której pracownicy są wynagradzani za pracę w nadgodzinach poprzez otrzymywanie równoważnego czasu wolnego od pracy, zamiast otrzymywania dodatkowej zapłaty za przepracowane godziny.",
  "components.toil.expiration": "Wygaśnięcie",
  "components.toil.expirationDuration": "{noOfMonths} {noOfMonths, plural, =1 {miesiąc} other {miesiące}}",
  "components.toil.expirationDurationCalendar": "Koniec roku kalendarzowego",
  "components.toil.expirationLabel": "Czy dni urlopu w zamian powinny wygasać?",
  "components.toil.expirationLabelTooltip": "Włącz to ustawienie, jeśli chcesz, aby dni TOIL miały datę wygaśnięcia, po której nie będą mogły być już wykorzystane. Wybranie 'Tak' spowoduje wyświetlenie monitu o wybór zasad wygaśnięcia. Jeśli wybierzesz 'Nie', dni TOIL nie wygasną i pozostaną dostępne do wykorzystania.",
  "components.toil.featurePlan": "Funkcja TOIL jest dostępna tylko w planie Complete.",
  "components.toil.fullDayLeavePeriod": "{date}{endDate} ",
  "components.toil.halfDay": "Pół dnia TOIL",
  "components.toil.hourly": "Godzinowy TOIL",
  "components.toil.lapsed": "wygasły",
  "components.toil.locationNotHaveToil": "Brak polityki urlopowej z TOIL dla {me, plural, =1 {Twojej} other {lokalizacji użytkownika}}",
  "components.toil.notification.added": "TOIL <forUser>dla </forUser>{requestor} dodany <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.toil.notification.approved": "TOIL {requestor} zatwierdzony <byActionCreator>przez {actionCreator}</byActionCreator> ✅",
  "components.toil.notification.cancelled": "TOIL {requestor} anulowany <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.toil.notification.created": "TOIL wnioskowany <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.toil.notification.deleted": "TOIL usunięty <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.toil.notification.denied": "TOIL {requestor} odrzucony <byActionCreator>przez {actionCreator}</byActionCreator> ❌",
  "components.toil.notification.edited": "TOIL {requestor} edytowany <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.toil.notification.editedApproved": "Zaplanowany TOIL został edytowany <byActionCreator>przez {actionCreator}</byActionCreator> ✅",
  "components.toil.notification.editedDenied": "Edytowany TOIL odrzucony <byActionCreator>przez {actionCreator}</byActionCreator>",
  "components.toil.partDayPeriod": "{date} - od {partDayStartHour}{hourFormat24, select, true {:00} other {}}  do {partDayEndHour}{hourFormat24, select, true {:00} other {}} ",
  "components.toil.reasonDefault": "Dodatkowe godziny przepracowane w określonym powyżej okresie.",
  "components.toil.request": "Wniosek o TOIL",
  "components.toil.requested": "TOIL wnioskowany",
  "components.toil.requestedOrAddedDaysHours": "{action, plural, =1 {Wnioskowano} other {Dodano}} {period}",
  "components.toil.requester": "Kto może wnioskować o czas wolny w zamian?",
  "components.toil.requesterAdmin": "Tylko administratorzy i zatwierdzający",
  "components.toil.requesterAll": "Wszyscy użytkownicy",
  "components.toil.requesterTooltip": "Wybierz 'Wszyscy użytkownicy', aby umożliwić każdemu pracownikowi składanie własnych wniosków o TOIL po przepracowaniu nadgodzin. Wybierz 'Tylko administratorzy i zatwierdzający', jeśli wolisz scentralizować zarządzanie TOIL i utrzymać ściślejszą kontrolę nad jego zatwierdzaniem i rejestrowaniem.",
  "components.toil.requestNotFound": "Nie znaleziono wniosku o TOIL",
  "components.toil.requestToil": "Wniosek o czas wolny w zamian",
  "components.toil.requestToilProgress": "Wnioskowanie o czas wolny w zamian",
  "components.toil.submitError": "Błąd przesyłania TOIL",
  "components.toil.submitErrorDate": "Nie można złożyć wniosku o TOIL, ponieważ wybrana data początkowa jest późniejsza niż data końcowa. Upewnij się, że data początkowa jest wcześniejsza niż data końcowa i złóż wniosek ponownie",
  "components.toil.submitErrorDateFuture": "Wnioskowana data TOIL musi być w przeszłości",
  "components.toil.submitErrorLongerThanWorkday": "Wnioskowane godziny TOIL są dłuższe niż godziny pracy",
  "components.toil.submitErrorNotOpen": "TOIL nie jest otwarty",
  "components.toil.submitErrorOverlap": "Wnioskowane dni TOIL nakładają się na istniejący TOIL",
  "components.toil.submitErrorPlan": "Ta funkcja jest dostępna wyłącznie dla użytkowników z subskrypcją naszego planu Complete.",
  "components.toil.submitErrorPolicy": "Polityka urlopowa dla tego typu urlopu nie zezwala na TOIL",
  "components.toil.submitErrorPolicyDuration": "Użytkownicy z niestandardowymi godzinami pracy nie mogą wnioskować o wielodniowy TOIL",
  "components.toil.submitErrorPolicyLocation": "Opcja TOIL nie jest włączona dla Twojej lokalizacji. Możesz ją włączyć na stronie polityk urlopowych lub skontaktować się z administratorem w celu uzyskania wsparcia.",
  "components.toil.submitErrorReasonNotSet": "Powód TOIL jest wymagany",
  "components.toil.submitErrorRole": "Wniosek o TOIL nie jest dozwolony",
  "components.updateWorkweekForm.cancel": "Anuluj",
  "components.updateWorkweekForm.form.error": "Proszę wybrać co najmniej jeden dzień roboczy",
  "components.updateWorkweekForm.title": "Aktualizuj tydzień pracy",
  "components.updateWorkweekForm.update": "Aktualizuj",
  "components.updateWorkweekForm.workWeek": "Tydzień pracy",
  "components.userBroughtForwardForm.customRolloverDays": "Dni",
  "components.userBroughtForwardForm.leaveTypeEditInfo": "Edytujesz typ urlopu \"{leaveTypeName}\" dla okresu {dateRangeName}.",
  "components.userBroughtForwardForm.update": "Aktualizuj",
  "components.userBroughtForwardForm.updateUserLeaveType": "Aktualizuj przeniesione dni dla typu urlopu {name}",
  "components.userLabes.addLabel": "Dodaj etykietę",
  "components.userLabes.manageLabels": "Zarządzaj etykietami",
  "components.userLabes.newLabel": "(nowa etykieta)",
  "components.userLabes.noLabelsFound": "Nie znaleziono etykiet",
  "components.userLeaveQuotas.accrualsTooltipTitle": "Naliczanie to akumulacja lub stopniowy wzrost godzin płatnego urlopu w ciągu roku.",
  "components.userLeaveQuotas.accrualsTooltipTitle-bi-weekly": "Dwutygodniowe naliczanie {leaveType} następuje co dwa tygodnie",
  "components.userLeaveQuotas.accrualsTooltipTitle-monthly": "Miesięczne naliczanie {leaveType} odbywa się raz w miesiącu.",
  "components.userLeaveQuotas.accrualsTooltipTitle-semi-monthly": "Półmiesięczne naliczanie {leaveType} odbywa się dwa razy w miesiącu.",
  "components.userLeaveQuotas.accrualsTooltipTitle-weekly": "Tygodniowe naliczanie {leaveType} odbywa się co tydzień",
  "components.userLeaveQuotas.accruedDays": "Naliczone dni",
  "components.userLeaveQuotas.accruedHours": "Naliczone godziny",
  "components.userLeaveQuotas.accruedInfo1": "{userName} naliczył(a) {earnedDays} dni ({earnedDaysInHours} godzin) z {defaultDays} dni ({defaultDaysInHours} godzin)",
  "components.userLeaveQuotas.accruedInfo1Days": "{userName} naliczył(a) {earnedDays} z {defaultDays} dni",
  "components.userLeaveQuotas.accruedInfo1Hours": "{userName} naliczył(a) {earnedDaysInHours} z {defaultDaysInHours} godzin",
  "components.userLeaveQuotas.accruedInfo2": "{userName} naliczy {toAccrueDays} dni ({toAccrueHours} godzin) w dniu {earnDate}",
  "components.userLeaveQuotas.accruedInfo2Days": "{userName} naliczy {toAccrueDays} dni w dniu {earnDate}",
  "components.userLeaveQuotas.accruedInfo2Hours": "{userName} naliczy {toAccrueHours} godzin w dniu {earnDate}",
  "components.userLeaveQuotas.baseQuota": "Podstawowy limit",
  "components.userLeaveQuotas.broughtForward": "Przeniesione",
  "components.userLeaveQuotas.broughtForwardDaysInfo": "<rolloverDays>Maksymalnie {maxRolloverDays} dni<br></br></rolloverDays><expiresDate>Wygasa {date}</expiresDate>",
  "components.userLeaveQuotas.broughtForwardInfo": "Przeniesione reprezentuje liczbę niewykorzystanych dni przeniesionych z poprzedniego okresu.",
  "components.userLeaveQuotas.broughtForwardInfoDetails": "Dla {leaveTypeName} w lokalizacji {locationName} pracownicy mogą przenieść do {broughtForwardLimit} niewykorzystanych {broughtForwardLimit, plural, =1 {dnia} other {dni}}.",
  "components.userLeaveQuotas.broughtForwardInfoDetailsAll": "Dla {leaveTypeName} w lokalizacji {locationName} wszystkie niewykorzystane dni mogą być przeniesione na następny okres.",
  "components.userLeaveQuotas.broughtForwardValue": "Przeniesione",
  "components.userLeaveQuotas.editInitialQuotaTitle": "Edytuj początkowe przeniesione dni dla {leaveTypeName}",
  "components.userLeaveQuotas.editInitialQuotaTooltip": "Początkowe przeniesione dni pozwalają na ręczne wprowadzenie początkowych sald pracowników z poprzedniego systemu śledzenia lub systemu HR",
  "components.userLeaveQuotas.editQuotaButton": "Zmień",
  "components.userLeaveQuotas.expirationTooltip": "Dni, które pracownik przeniósł z poprzedniego okresu, ale które nie zostały wykorzystane przed datą wygaśnięcia",
  "components.userLeaveQuotas.expiredDateInfo": "w dniu {date}",
  "components.userLeaveQuotas.forMoreDetails": "Aby uzyskać więcej szczegółów, zobacz",
  "components.userLeaveQuotas.initialBroughtForward": "Początkowe przeniesione",
  "components.userLeaveQuotas.initialBroughtForwardInfo": "Początkowe przeniesione to saldo urlopu, które użytkownik przeniósł z zeszłego roku, kiedy Vacation Tracker nie był jeszcze częścią Twojej organizacji. Pamiętaj, że możesz edytować tylko początkowe saldo przeniesionego urlopu, podczas gdy dostępny urlop, który możesz przenieść w kolejnych latach, będzie obliczany przez Vacation Tracker.",
  "components.userLeaveQuotas.leavePoliciesNotExistInfo": "Obecnie nie ma dostępnych typów urlopów. Przypisz polityki urlopowe do lokalizacji, do której należy ten użytkownik.",
  "components.userLeaveQuotas.leaveTypes": "Typy urlopów",
  "components.userLeaveQuotas.logsTab": "zakładka logów.",
  "components.userLeaveQuotas.noLimit": "Masz nieograniczony limit.",
  "components.userLeaveQuotas.ofDefaultDays": "z {value} dni",
  "components.userLeaveQuotas.ofDefaultHours": "z {value} godzin",
  "components.userLeaveQuotas.overlapDaysContactSupport": "Nakładanie się dat, skontaktuj się z Pomocą Techniczną, aby uzyskać więcej informacji.",
  "components.userLeaveQuotas.paidDays": "Wypłacone dni",
  "components.userLeaveQuotas.quota": "Limit",
  "components.userLeaveQuotas.quotaInfo": "Limit reprezentuje liczbę dni płatnego urlopu dostępnych w bieżącym okresie. Ta liczba obejmuje podstawowy limit, czas wolny w zamian oraz dni przyznane na podstawie roli lub stażu pracownika.",
  "components.userLeaveQuotas.quotaInfoSummary": "Limit na bieżący okres:",
  "components.userLeaveQuotas.remaining": "Pozostało",
  "components.userLeaveQuotas.remainingAfterRequest": "Pozostało po wniosku",
  "components.userLeaveQuotas.remainingTooltip": "Pozostałe dni {leaveTypeName} na bieżący okres. Łącznie:",
  "components.userLeaveQuotas.remainingTooltipFooter": "Do {broughtForwardLimit} niewykorzystanych {period} zostanie przeniesione na następny okres",
  "components.userLeaveQuotas.remainingTooltipFooterAll": "Wszystkie niewykorzystane dni mogą być przeniesione na następny okres.",
  "components.userLeaveQuotas.remainingTooltipFooterZero": "Żadne niewykorzystane dni nie mogą być przeniesione na następny okres.",
  "components.userLeaveQuotas.requested": "Wnioskowane",
  "components.userLeaveQuotas.saveQuotaButton": "Zapisz",
  "components.userLeaveQuotas.scheduled": "Zaplanowane",
  "components.userLeaveQuotas.scheduledDays": "Zaplanowane dni",
  "components.userLeaveQuotas.sectionTitle": "Limit urlopów",
  "components.userLeaveQuotas.selectPeriod": "Wybierz okres:",
  "components.userLeaveQuotas.unlock": "Odblokuj",
  "components.userLeaveQuotas.unlockBroughtForwardInfo": "Aby odblokować, przejdź do Ustawienia → Lokalizacje → {locationName} → Wyświetl zasady urlopowe i edytuj zasadę {leaveTypeName}, aby zezwolić na przeniesienie dni.",
  "components.userLeaveQuotas.used": "Wykorzystane",
  "components.userLeaveQuotas.usedAlready": "Już wykorzystane",
  "components.userLeaveQuotas.usedDays": "Wykorzystane dni",
  "components.userLeaveQuotas.usedTooltip": "Wykorzystane, zaplanowane i wypłacone dni {leaveTypeName} na bieżący okres. Łącznie:",
  "components.userLeaveQuotas.userInactiveInfo": "Ponieważ konto jest teraz nieaktywne, żadne dodatkowe dni nie będą dodawane do momentu reaktywacji.",
  "components.userLeaveQuotasCompact.title": "Twój bilans urlopowy",
  "components.userLeaveQuotasCompact.titleForApprover": "Bilans urlopowy",
  "components.userLeaveTypeForm.applyForFutureYears": "Czy chcesz zastosować podstawowy limit urlopu na przyszłe lata?",
  "components.userLeaveTypeForm.cancel": "Anuluj",
  "components.userLeaveTypeForm.daysPerYear": "Jaki jest Twój nowy limit urlopu?",
  "components.userLeaveTypeForm.hasUnlimitedDays": "Czy chcesz zezwolić na nieograniczone urlopy?",
  "components.userLeaveTypeForm.leaveTypeEditInfo": "Edytujesz typ urlopu \"{leaveTypeName}\" dla okresu {dateRangeName}.",
  "components.userLeaveTypeForm.prorateInfo": "{user} będzie mieć {proratedQuota} ({preciseQuota} dni, dokładnie) na początkowy rok ({yearPeriod}) i {fullQuota} na wszystkie lata rozpoczynające się od {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateInfoDays": "{user} będzie mieć {proratedQuota} ({preciseQuota} dni, dokładnie) na początkowy rok ({yearPeriod}) i {fullQuota} dni na wszystkie lata rozpoczynające się od {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateInfoHours": "{user} będzie mieć {proratedQuota} ({preciseQuota} godzin, dokładnie) na początkowy rok ({yearPeriod}) i {fullQuota} godzin na wszystkie lata rozpoczynające się od {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateLabel": "Przelicz proporcjonalnie na początkowy rok na podstawie daty rozpoczęcia pracy użytkownika ({startDate})",
  "components.userLeaveTypeForm.quotaAdditionalRole": "Ustaw dodatkowe dni na podstawie roli",
  "components.userLeaveTypeForm.quotaAdditionalRoleInfo": "Przydziel dodatkowe dni urlopu pracownikom w zależności od ich stanowiska. To pole pozwala dostosować uprawnienia urlopowe do odpowiedzialności lub specyficznych wymagań różnych stanowisk w organizacji.",
  "components.userLeaveTypeForm.quotaAdditionalSeniority": "Ustaw dodatkowe dni na podstawie stażu pracy",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfo": "Dni bazujące na stażu pracy są dodawane przez automatyzację uprawnień ze względu na staż. Możesz nadpisać dni {leaveTypeName} bazujące na stażu, ustawiając wartość w tym polu.",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfoUpcomingYear": "Dni bazujące na stażu pracy są dodawane przez automatyzację uprawnień ze względu na staż. Możesz nadpisać dni {leaveTypeName} bazujące na stażu, ustawiając wartość w tym polu. Jednak to pole jest zablokowane, ponieważ nie możesz edytować tej wartości dla przyszłych lat przed przyznaniem dni.",
  "components.userLeaveTypeForm.quotaAdditionalSummary": "Ustawisz limit urlopu {leaveTypeName} dla {user} na wybrany okres na {total} {hourlyLeaveAccounting, plural, =1 {godzin} other {dni}}. Podstawowy limit i dodatkowe dni oparte na uprawnieniach ze względu na staż i role ({total} {hourlyLeaveAccounting, plural, =1 {godzin} other {dni}} łącznie) zostaną zastosowane dla {future, plural, =1 {wybranego okresu i przyszłych okresów} other {wybranego okresu}}. {prorateText}",
  "components.userLeaveTypeForm.quotaAdditionalSummaryUnlimited": "Ustawisz limit urlopu {leaveTypeName} dla {user} na wybrany okres jako Nieograniczony.",
  "components.userLeaveTypeForm.quotaBase": "Ustaw podstawowy limit urlopu",
  "components.userLeaveTypeForm.quotaBaseTooltip": "Podstawowy limit to domyślny limit użytkownika, który nie uwzględnia dodatkowych dni otrzymanych na podstawie ich stażu pracy, roli, TOIL lub innych dodatkowych dni. Możesz zmienić podstawowy limit dla wszystkich w jednej lokalizacji na stronie zasad urlopowych lub użyć tego pola, aby ustawić niestandardowy limit dla wybranego użytkownika.",
  "components.userLeaveTypeForm.required": "To pole jest wymagane.",
  "components.userLeaveTypeForm.update": "Aktualizuj",
  "components.userLeaveTypeForm.updateUserLeaveType": "Aktualizuj limity użytkownika dla typu urlopu {name}",
  "components.userLogsTab.ACCRUED_DAYS": "Naliczone dni",
  "components.userLogsTab.ALL": "Wszystkie",
  "components.userLogsTab.APPROVER_STATUS": "Zmiana roli",
  "components.userLogsTab.filter": "Filtruj:",
  "components.userLogsTab.LOCATION_USER_MOVED": "Zmiana lokalizacji",
  "components.userLogsTab.logsNotFound": "Nie znaleziono logów dla wybranego zakresu dat.",
  "components.userLogsTab.ROLLOVER_EXPIRED": "Wygasły przeniesione dni",
  "components.userLogsTab.selectFromTo": "Wybierz zakres dat:",
  "components.userLogsTab.TEAM_USER_MOVED": "Zmiana działu",
  "components.userLogsTab.TOIL_REQUEST_APPROVED": "TOIL",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE": "Uprawnienie według roli",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_DELETED": "Usunięto uprawnienie według roli",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_OVERRIDE": "Nadpisanie uprawnienia według roli",
  "components.userLogsTab.USER_LEAVE_TYPES_DELETED": "Przywrócono domyślny limit urlopu",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_DELETED": "Usunięto początkowe przeniesione dni",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_UPDATED": "Zaktualizowano początkowe przeniesione dni",
  "components.userLogsTab.USER_LEAVE_TYPES_UPDATED": "Zaktualizowano limit urlopu",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT": "Uprawnienie ze względu na staż",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT_OVERRIDE": "Nadpisanie uprawnienia ze względu na staż",
  "components.userLogsTab.USER_STATUS_CHANGED": "Zmiana statusu",
  "components.userLogsTab.USER_UPDATED": "Aktualizacja informacji o użytkowniku",
  "components.userLogsTab.USER_WORK_WEEK_DELETED": "Usunięto niestandardowy tydzień pracy",
  "components.userLogsTab.USER_WORK_WEEK_UPDATED": "Zaktualizowano tydzień pracy",
  "components.userLogsTab.YEAR_START": "Początek roku",
  "components.userManageLabels.actions": "Działania",
  "components.userManageLabels.color": "Kolor",
  "components.userManageLabels.deleteLabel": "Czy na pewno chcesz usunąć tę etykietę?\n\nSpowoduje to usunięcie etykiety dla wszystkich przypisanych użytkowników.",
  "components.userManageLabels.findLabel": "Znajdź etykietę",
  "components.userManageLabels.isRequired": "{title} jest wymagane",
  "components.userManageLabels.manageLabels": "Zarządzaj etykietami",
  "components.userManageLabels.name": "Nazwa",
  "components.userManageLabels.userCount": "Liczba użytkowników",
  "components.userProfileTab.admin": "Administrator",
  "components.userProfileTab.approver": "Zatwierdzający",
  "components.userProfileTab.approvers": "Zatwierdzający:",
  "components.userProfileTab.approverSuffix": " (Zatwierdzający)",
  "components.userProfileTab.customWorkWeekEditedBefore": "Tydzień pracy {userName} został zmodyfikowany w poprzedniej wersji Vacation Tracker (przed 1 stycznia 2021)",
  "components.userProfileTab.customWorkWeekLabel": "niestandardowy tydzień pracy",
  "components.userProfileTab.customWorkWeekTooltip": "Tydzień pracy {userName} został edytowany przez {adminName} dnia {timestamp}",
  "components.userProfileTab.employeeIdNotSet": "Nie ustawiono",
  "components.userProfileTab.employeeIdTooltip": "ID pracownika w systemie zewnętrznym",
  "components.userProfileTab.employeeSince": "Pracownik od:",
  "components.userProfileTab.endDate": "Data końcowa:",
  "components.userProfileTab.location": "Lokalizacja:",
  "components.userProfileTab.name": "Imię:",
  "components.userProfileTab.nameEdited": "Imię zostało zmienione ręcznie i nie będzie już automatycznie synchronizowane. To imię jest używane tylko wewnątrz Vacation Tracker.",
  "components.userProfileTab.role": "Rola:",
  "components.userProfileTab.status": "Status:",
  "components.userProfileTab.team": "Dział:",
  "components.userProfileTab.user": "Użytkownik",
  "components.userProfileTab.userProfile": "Profil użytkownika",
  "components.userProfileTab.workingDays": "Dni robocze",
  "components.userTodayOff.cancelLeave": "Anuluj urlop",
  "components.userTodayOff.editLeave": "Edytuj urlop",
  "components.userTodayOff.leaveInfo": "Informacje o urlopie",
  "components.userTodayOff.offFromTo": "Użytkownik wziął {leaveTypeName} od {startDate} do {endDate}",
  "components.userTodayOff.offTodayFullDay": "Użytkownik wziął {leaveTypeName} dzisiaj, {today}",
  "components.userTodayOff.offTodayPartDay": "Użytkownik wziął {leaveTypeName} dzisiaj od {from}:00 do {to}:00.",
  "components.userTodayOff.offTodayReason": "Powód: {reason}",
  "components.userUpdateForm.active": "Aktywny",
  "components.userUpdateForm.admin": "Administrator",
  "components.userUpdateForm.approver": "Zatwierdzający",
  "components.userUpdateForm.approverSuffix": " (Zatwierdzający)",
  "components.userUpdateForm.cancel": "Anuluj",
  "components.userUpdateForm.employeeSince": "Pracownik od",
  "components.userUpdateForm.location": "Lokalizacja",
  "components.userUpdateForm.name": "Imię",
  "components.userUpdateForm.pleaseSelectALocation": "Wybierz lokalizację",
  "components.userUpdateForm.pleaseSelectATeam": "Wybierz dział",
  "components.userUpdateForm.pleaseSelectAUserRole": "Wybierz rolę użytkownika",
  "components.userUpdateForm.pleaseSelectEmployeeSince": "Dodaj datę rozpoczęcia pracy",
  "components.userUpdateForm.pleaseSelectUserEndDate": "Dodaj datę końcową pracownika",
  "components.userUpdateForm.role": "Rola",
  "components.userUpdateForm.team": "Dział",
  "components.userUpdateForm.update": "Aktualizuj",
  "components.userUpdateForm.updateUser": "Aktualizuj użytkownika",
  "components.userUpdateForm.user": "Użytkownik",
  "components.userUpdateForm.userEndDate": "Data końcowa pracownika",
  "components.userUpdateForm.warningInitialRolloverForChangeLocation": "Zmiana lokalizacji użytkownika może wpłynąć na konfigurację użytkownika i informacje o urlopach (np. początkowe dni przeniesione).",
  "components.userUpdateForm.warningInitialRolloverForChangeStartDate": "Zmiana daty rozpoczęcia pracy użytkownika może wpłynąć na informacje o urlopach użytkownika (np. początkowe dni przeniesione).",
  "components.whatsNew.newMessage": "Nowa wiadomość",
  "components.whatsNew.whatsNew": "Co nowego",
  "connect.alreadyHaveAnAccount": "Masz już konto?",
  "connect.alreadyHaveAnAccountWithLink": "Masz już konto? <b><link>Zaloguj się</link></b>",
  "connect.backToLoginWithOtherPlatofrm": "Powrót do logowania na innej platformie",
  "connect.companyExists.line1": "{companyName} jest już zarejestrowana w Vacation Tracker",
  "connect.companyExists.line2Other": "Czy używasz właściwego adresu e-mail?",
  "connect.companyExists.line2Slack": "Czy używasz właściwych danych logowania?",
  "connect.companyExists.line3aSlack": "Twoja przestrzeń robocza Slack: {workspaceName}",
  "connect.companyExists.line3Other": "Twój adres e-mail: {email}",
  "connect.companyExists.line3Slack": "Twój adres e-mail: {email}",
  "connect.companyExists.line4Other": "Jeśli ten adres e-mail jest poprawny, Twoje konto nie jest aktywowane. Skontaktuj się z administratorem Twojej organizacji Vacation Tracker:",
  "connect.companyExists.line4Slack": "Jeśli te dane logowania są poprawne, Twoje konto nie jest aktywowane. Skontaktuj się z administratorem Twojej organizacji Vacation Tracker:",
  "connect.companyExists.line5": "W przeciwnym razie zaloguj się na inne konto <capitalize>{platform}</capitalize> lub zaloguj się w trybie incognito w swojej przeglądarce.",
  "connect.companyExists.line6Microsoft": "Skontaktuj się z działem wsparcia, aby utworzyć nowy dział w {companyName}",
  "connect.companyExists.lineEmailAddress": "Twój adres e-mail",
  "connect.companyExists.title": "Firma już istnieje",
  "connect.connect": "Połącz",
  "connect.connectWithWorkEmail": "Zaloguj się za pomocą służbowego adresu e-mail",
  "connect.connectWithYourWorkEmail": "Zaloguj się używając służbowego adresu e-mail",
  "connect.copyEmail": "Kliknij, aby skopiować adres e-mail",
  "connect.copyTextEnd": "Udanych wakacji! 🌴",
  "connect.createAnOrganization": "Utwórz organizację",
  "connect.description": "Aby uzyskać dostęp do Panelu Vacation Tracker, wybierz przycisk \"Połącz z\", który odpowiada platformie używanej przez Twój zespół.",
  "connect.dontHaveAnAccount": "Nie masz konta?",
  "connect.doYouWantToLoginInToOrganization": "Czy chcesz się zalogować do {existingOrganizationName}?",
  "connect.forgotYourPassword": "Zapomniałeś hasła?",
  "connect.forgotYourPasswordWhatsYourEmail": "Jaki jest Twój adres e-mail?",
  "connect.google.notWorkspaceUserDescription": "Wygląda na to, że próbujesz zarejestrować się za pomocą osobistego konta, a nie konta Google Workspace. W tej chwili Vacation Tracker obsługuje tylko konta Google Workspace.",
  "connect.google.notWorkspaceUserTitle": "Wymagane jest konto Google Workspace",
  "connect.google.sharedPermissionsConsent": "Klikając przycisk poniżej, udzielasz Vacation Tracker pozwolenia na dostęp do katalogu użytkowników administratora Google.",
  "connect.google.sharedPermissionsError": "Nie udało się udostępnić uprawnień Google, Twój link wygasł.",
  "connect.google.sharedPermissionsRequired": "Niewystarczające uprawnienia",
  "connect.google.sharedPermissionsRequiredDescription": "Aby kontynuować importowanie użytkowników, musisz uzyskać uprawnienia od administratora Google. Udostępnij ten link swojemu administratorowi, aby udzielić niezbędnej zgody: ",
  "connect.google.sharedPermissionsSuccess": "Pomyślnie udostępniono uprawnienia Google!",
  "connect.google.sharedPermissionsSuccessRedirect": "Zostaniesz przekierowany na stronę główną Vacation Tracker za kilka sekund.",
  "connect.howShouldIConnect": "Jak powinienem się połączyć?",
  "connect.inputEmailPlaceholder": "Wprowadź swój służbowy adres e-mail",
  "connect.inputPasswordPlaceholder": "Wprowadź swoje hasło",
  "connect.interactionRequiredMSErrorDescription1": "Wygląda na to, że Twoje konto Microsoft wymaga dodatkowych uprawnień. Kliknij przycisk Zaloguj się poniżej, aby udzielić dodatkowych uprawnień.",
  "connect.interactionRequiredMSErrorDescription2": "Jeśli problem będzie się powtarzał, skontaktuj się z naszym wsparciem i przekaż im następujący kod:",
  "connect.interactionRequiredMSErrorTitle": "Microsoft wymaga dodatkowych uprawnień",
  "connect.login": "Zaloguj się",
  "connect.meta.description": "Uzyskaj dostęp do Panelu Vacation Tracker i odblokuj pełną moc łatwego zarządzania urlopami.",
  "connect.meta.title": "Rejestracja/Logowanie - Vacation Tracker",
  "connect.meta.title.signin": "Zaloguj się - Vacation Tracker",
  "connect.meta.title.signup": "Zarejestruj się - Vacation Tracker",
  "connect.or": "Lub",
  "connect.platformErrorDescription1": "Wystąpił następujący błąd:",
  "connect.platformErrorDescription2": "To może być błąd platformy {platform}. Jednak zachęcamy do kontaktu z naszym wsparciem w celu uzyskania dalszej pomocy.",
  "connect.platformErrorTitle": "Coś poszło nie tak",
  "connect.pleaseInputValidEmail": "Proszę wprowadzić prawidłowy adres e-mail",
  "connect.pleaseInputValidPassword": "Proszę wprowadzić prawidłowe hasło",
  "connect.pleaseLogIn": "Proszę się zalogować",
  "connect.readMore": "Aby uzyskać więcej informacji, odwiedź nasz <helpdesk>Helpdesk</helpdesk> lub przejdź na naszą <website>stronę internetową</website>.",
  "connect.signInWithEmail": "Zaloguj się za pomocą e-maila",
  "connect.signInWithGoogle": "Zaloguj się przez Google",
  "connect.signInWithGoogleToOrganization": "Zaloguj się do Google Workspace dla {existingOrganizationName}",
  "connect.signInWithGoogleVerification": "Zaloguj się przez Google",
  "connect.signInWithMS": "Zaloguj się przez Microsoft Teams",
  "connect.signInWithMSToOrganization": "Zaloguj się przez Microsoft Teams do {existingOrganizationName}",
  "connect.signInWithSlack": "Zaloguj się przez Slack",
  "connect.signInWithSlackInstallBot": "Dodaj Vacation Tracker do Slack",
  "connect.signInWithSlackToOrganization": "Zaloguj się przez Slack do {existingOrganizationName}",
  "connect.signUp": "Zarejestruj się używając służbowego adresu e-mail",
  "connect.signUpInfo": "Zarejestruj się, aby rozpocząć bezpłatny okres próbny",
  "connect.signupNewOrganizationUsingNewPlatform": "Zarejestruj się jako nowa organizacja używając konta {selectedPlatform}",
  "connect.signUpWithEmail": "Zarejestruj się za pomocą e-maila",
  "connect.signUpWithGoogle": "Zarejestruj się przez Google",
  "connect.signUpWithMicrosoft": "Zarejestruj się przez Microsoft",
  "connect.signUpWithSlack": "Zarejestruj się przez Slack",
  "connect.slack.usersLoginRequiredButton": "Przyznaj uprawnienia Slack",
  "connect.slack.usersLoginRequiredDescription1": "Twój token bota Slack wygasł lub jest już nieważny. Nie będziesz mógł korzystać z bota Vacation Tracker ani otrzymywać powiadomień, dopóki nie aktywujesz nowego tokenu.",
  "connect.slack.usersLoginRequiredDescription2": "Aby aktywować nowy token bota Slack, kliknij OK.",
  "connect.slack.usersLoginRequiredDescription3": "Aby pominąć ten krok i przejść do Panelu online, kliknij Pomiń.",
  "connect.slack.usersLoginRequiredTitle": "Wymagane jest zalogowanie się do konta Slack",
  "connect.slackPostInstallation1": "Pomyślnie zainstalowałeś aplikację Slack Vacation Tracker 🎉",
  "connect.slackPostInstallation2": "Aby rozpocząć korzystanie z aplikacji, zaloguj się do Panelu Vacation Tracker i dokończ konfigurację.",
  "connect.slackPostInstallation3": "Zadamy Ci kilka prostych pytań. Nie powinno to zająć więcej niż kilka minut. Mrugniesz okiem i gotowe (tak jak Twój ostatni urlop).",
  "connect.slackPostInstallationError.generic": "Hej, musimy najpierw zainstalować bota Vacation Tracker. W następnym kroku będziesz mógł przejrzeć i przyznać uprawnienia aplikacji Slack Vacation Tracker.",
  "connect.slackPostInstallationError.invalidCode": "Kod przekierowania jest już nieważny. Może to się zdarzyć, jeśli kod wygasł, został już użyty lub odświeżyłeś stronę przed zakończeniem procesu logowania. Ale nie martw się, zdobądźmy nowy!",
  "connect.subscriptionExpired": "Ups, wygląda na to, że subskrypcja Twojej firmy na Vacation Tracker wygasła! Skontaktuj się z administratorem, aby ponownie aktywować subskrypcję Twojej firmy.",
  "connect.subscriptionExpiredTitle": "Subskrypcja wygasła",
  "connect.thisOrganizationUsePlatform": "Jednak {existingOrganizationName} korzysta z {existsOnAnotherPlatform}, więc musisz zalogować się za pomocą {existsOnAnotherPlatform}, aby uzyskać dostęp do swojego konta",
  "connect.welcome": "Witaj, {name}!",
  "connect.youAreAlreadyMember": "Jesteś już członkiem organizacji {existingOrganizationName}",
  "connectCalendar.connect": "Połącz z",
  "connectCalendar.disconnect": "Rozłącz",
  "connectCalendar.disconnectDescription": "Czy na pewno chcesz odłączyć kalendarz? Vacation Tracker nie będzie już aktualizować go o Twoje przyszłe urlopy",
  "connectCalendar.disconnectError": "Błąd odłączania kalendarza",
  "connectCalendar.disconnectErrorDescription": "Wystąpił błąd podczas próby odłączenia Twojego kalendarza od Vacation Tracker. Kliknij OK, aby spróbować ponownie.",
  "connectCalendar.disconnectSuccess": "Pomyślnie odłączono kalendarz {calendar}.",
  "connectCalendar.disconnectTitle": "Odłącz kalendarz {calendarPlatform}",
  "connectCalendar.error": "Błąd łączenia kalendarza",
  "connectCalendar.errorDescription": "Wystąpił błąd podczas próby połączenia Twojego kalendarza z Vacation Tracker. Kliknij OK, aby spróbować ponownie.",
  "connectCalendar.errorReconnectDescription": "Wystąpił błąd podczas próby ponownego połączenia Twojego kalendarza z Vacation Tracker. Kliknij OK, aby spróbować ponownie.",
  "connectCalendar.reconnect": "Połącz ponownie",
  "connectCalendar.reconnectError": "Błąd ponownego łączenia kalendarza",
  "connectCalendar.reconnectInfo": "Aby upewnić się, że Twój urlop jest widoczny, połącz się ponownie z kalendarzem.",
  "connectCalendar.reconnectNotification": "Połączenie z Twoim kalendarzem wygasło. Kliknij tutaj, aby połączyć się ponownie i upewnić się, że Twój urlop jest widoczny w kalendarzu.",
  "connectCalendar.reconnectNotificationSharedCalendar": "Twoje połączenie z udostępnionym kalendarzem: {calendarName} wygasło. Kliknij tutaj, aby połączyć się ponownie i upewnić się, że urlopy są widoczne w Twoim kalendarzu.",
  "connectCalendar.reconnectSuccess": "Pomyślnie ponownie połączono kalendarz {calendar}.",
  "connectCalendar.success": "Pomyślnie połączono kalendarz {calendar}.",
  "connectedCalendar.eventDescription": "Dodano przez <link>Vacation Tracker</link>",
  "connectedCalendar.eventDescriptionShared": "Wydarzenie udostępnionego kalendarza dodane przez <link>Vacation Tracker</link>",
  "containers.google.topbar.adminProductTourInfo": "Jako Administrator masz najwyższy poziom uprawnień w Vacation Tracker i możesz dostosować wszystkie ustawienia organizacji poprzez Panel Administratora.\n\nPanel umożliwia:\n- Wnioskowanie o urlop lub dodawanie urlopu dla innych członków działu.\n- Pełny przegląd poprzez Stronę Główną Panelu i Kalendarz.\n- Tworzenie i konfigurowanie wielu działów (Przydzielanie Zatwierdzających).\n- Ustawianie Powiadomień.\n- Zarządzanie Użytkownikami (Aktywni, Nieaktywni, Usunięci).\n- Tworzenie i zarządzanie Lokalizacjami (Typy Urlopów, Święta, Powiadomienia).\n\nJeśli masz jakiekolwiek pytania lub wątpliwości, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia:",
  "containers.google.topbar.approverProductTourInfo": "Jako Zatwierdzający masz drugi najwyższy poziom uprawnień w Vacation Tracker i możesz zatwierdzać lub odrzucać wnioski urlopowe złożone przez Użytkowników z działu, do którego zostałeś przypisany jako Zatwierdzający.\n\nPanel umożliwia:\n- Wnioskowanie o urlop lub dodawanie urlopu dla innych członków działu.\n- Pełny przegląd poprzez Stronę Główną Panelu i Kalendarz.\n- Ustawianie Powiadomień.\n- Przeglądanie Twojego Profilu Użytkownika.\n- Zarządzanie Wnioskami (Zatwierdzanie/Odrzucanie Wniosków, Przeglądanie Historii).\n\nJeśli masz jakiekolwiek pytania lub wątpliwości, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia:",
  "containers.google.topbar.userProductTourInfo": "Jako Użytkownik Vacation Tracker możesz składać i zarządzać swoimi Wnioskami Urlopowymi poprzez Panel, a także mieć pełny przegląd zaplanowanych nieobecności w Twojej organizacji.\n\nPanel umożliwia:\n- Wnioskowanie o Urlop\n- Pełny przegląd poprzez Stronę Główną Panelu i Kalendarz.\n- Przeglądanie Twojego Profilu Użytkownika.\n\nJeśli masz jakiekolwiek pytania lub wątpliwości, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia:",
  "containers.topbar.adminProductTourInfo": "Jako Administrator masz najwyższy poziom uprawnień w Vacation Tracker i możesz dostosować wszystkie ustawienia organizacji poprzez Panel Administratora.\n\nPanel umożliwia:\n- Wnioskowanie o urlop lub dodawanie urlopu dla innych członków działu.\n- Pełny przegląd poprzez Stronę Główną Panelu i Kalendarz.\n- Tworzenie i konfigurowanie wielu działów (Przydzielanie Zatwierdzających).\n- Ustawianie Powiadomień.\n- Zarządzanie Użytkownikami (Aktywni, Nieaktywni, Usunięci).\n- Tworzenie i zarządzanie Lokalizacjami (Typy Urlopów, Święta, Powiadomienia).\n\nBot umożliwia:\n- Wnioskowanie o Urlop.\n- Zatwierdzanie/Odrzucanie Wniosków Urlopowych.\n- Otrzymywanie Codziennych/Tygodniowych Powiadomień.\n\nJeśli masz jakiekolwiek pytania lub wątpliwości, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia:",
  "containers.topbar.adminProductTourTitle": "Administrator",
  "containers.topbar.announcement": "Ogłoszenie",
  "containers.topbar.approverProductTourInfo": "Jako Zatwierdzający masz drugi najwyższy poziom uprawnień w Vacation Tracker i możesz zatwierdzać lub odrzucać wnioski urlopowe złożone przez Użytkowników z działu, do którego zostałeś przypisany jako Zatwierdzający.\n\nPanel umożliwia:\n- Wnioskowanie o urlop lub dodawanie urlopu dla innych członków działu.\n- Pełny przegląd poprzez Stronę Główną Panelu i Kalendarz.\n- Ustawianie Powiadomień.\n- Przeglądanie Twojego Profilu Użytkownika.\n- Zarządzanie Wnioskami (Zatwierdzanie/Odrzucanie Wniosków, Przeglądanie Historii).\n\nBot umożliwia:\n- Wnioskowanie o Urlop.\n- Zatwierdzanie/Odrzucanie Wniosków Urlopowych.\n- Otrzymywanie Codziennych/Tygodniowych Powiadomień.\n\nJeśli masz jakiekolwiek pytania lub wątpliwości, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia:",
  "containers.topbar.approverProductTourTitle": "Zatwierdzający",
  "containers.topbar.giveUsFeedback": "Przekaż nam opinię",
  "containers.topbar.help": "Pomoc",
  "containers.topbar.logout": "Wyloguj",
  "containers.topbar.profile.info": "Możesz uzyskać dostęp do swojego profilu, zmienić motyw i rozpocząć szybki przewodnik z tego nowego menu.",
  "containers.topbar.setUpTheBot": "Skonfiguruj bota",
  "containers.topbar.userProductTourInfo": "Jako Użytkownik Vacation Tracker możesz składać i zarządzać swoimi Wnioskami Urlopowymi poprzez Panel lub Bota, a także mieć pełny przegląd zaplanowanych nieobecności w Twojej organizacji.\n\nPanel umożliwia:\n- Wnioskowanie o Urlop\n- Pełny przegląd poprzez Stronę Główną Panelu i Kalendarz.\n- Przeglądanie Twojego Profilu Użytkownika.\n\nBot umożliwia:\n- Wnioskowanie o Urlop\n- Otrzymywanie Codziennych/Tygodniowych Powiadomień\n\nJeśli masz jakiekolwiek pytania lub wątpliwości, skontaktuj się z nami w dowolnym momencie. Rozmowa z naszymi klientami to najlepsza część naszego dnia:",
  "containers.topbar.userProductTourTitle": "Użytkownik",
  "containers.topbar.whatsNewUnread": "Co nowego - {unread} nieprzeczytane",
  "Conversation not found.": "Wybrany kanał został usunięty lub nie jest już dostępny. Proszę wybrać inny kanał do otrzymywania powiadomień.",
  "createCompany.signupError": "Coś poszło nie tak",
  "createCompany.steps.back": "Wstecz",
  "createCompany.steps.cancel": "Anuluj",
  "createCompany.steps.companyForm": "Szczegóły firmy",
  "createCompany.steps.companyForm.agreeTerms": "Akceptuję <aPP>Politykę Prywatności</aPP>, <aTOS>Warunki Korzystania z Usług</aTOS> i <aDPA>DPA</aDPA>. Potwierdzam również i zgadzam się na przetwarzanie moich danych osobowych zgodnie z warunkami określonymi w Polityce Prywatności",
  "createCompany.steps.companyForm.agreeToTerms.error": "Musisz zaakceptować nasze Warunki Korzystania z Usług i Politykę Prywatności, aby kontynuować.",
  "createCompany.steps.companyForm.companyInfoSectionDescription": "Powiedz nam o swojej firmie i lokalizacji. Użyjemy tych informacji do utworzenia konta firmowego i pierwszej lokalizacji. Zawsze możesz dodać więcej lokalizacji później.",
  "createCompany.steps.companyForm.companyLeaveSection": "Domyślny typ urlopu",
  "createCompany.steps.companyForm.companyName": "Nazwa firmy",
  "createCompany.steps.companyForm.companyNameInput.validation.required": "To pole jest wymagane.",
  "createCompany.steps.companyForm.contactEmail.validation.country": "To pole jest wymagane.",
  "createCompany.steps.companyForm.contactEmail.validation.required": "Niezbędne do ważnej komunikacji produktowej.",
  "createCompany.steps.companyForm.contactEmail.validation.state": "To pole jest wymagane.",
  "createCompany.steps.companyForm.contactEmail.validation.type": "Proszę wprowadzić prawidłowy adres e-mail.",
  "createCompany.steps.companyForm.contactEmailExtra": "Podany adres e-mail będzie używany tylko do ważnej komunikacji produktowej. Bez spamu, obiecujemy.",
  "createCompany.steps.companyForm.country": "Kraj",
  "createCompany.steps.companyForm.defaultPtoQuota": "Limit płatnego urlopu (PTO)",
  "createCompany.steps.companyForm.defaultPtoQuota.validation": "Proszę wprowadzić domyślny limit PTO",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.maxDefaultPtoQuota": "Twój domyślny limit PTO nie może przekraczać 366 dni",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.minDefaultPtoQuota": "Twój domyślny limit PTO nie może być mniejszy niż 0 dni",
  "createCompany.steps.companyForm.defaultPtoQuotaExtra": "Możesz to zmienić później w Ustawieniach.",
  "createCompany.steps.companyForm.email": "Email kontaktowy",
  "createCompany.steps.companyForm.location": "Informacje o lokalizacji: ",
  "createCompany.steps.companyForm.state": "Stan",
  "createCompany.steps.companyForm.step1Title": "Szczegóły firmy",
  "createCompany.steps.companyForm.step2Title": "Wybierz użytkowników",
  "createCompany.steps.companyForm.step3Title": "Wybierz plan",
  "createCompany.steps.companyForm.unlimitedLeavePolicy": "Nieograniczony PTO",
  "createCompany.steps.companyForm.UserInfoSectionDescription": "Powiedz nam, kim jesteś. Użyjemy tych informacji do utworzenia Twojego profilu użytkownika. Domyślnie zostaniesz przypisany do roli administratora i osoby zatwierdzającej. Możesz dodać więcej użytkowników i zmienić ich role później.",
  "createCompany.steps.inviteUsers.next": "Zaproś {noOfUsers} użytkownika{postfix}",
  "createCompany.steps.inviteUsers.title": "Zaproś członków swojego zespołu",
  "createCompany.steps.inviteUsers.title.info": "Zaproś użytkowników, których chcesz dodać do konta Vacation Tracker swojej organizacji. Zawsze możesz wprowadzić zmiany później poprzez panel kontrolny.",
  "createCompany.steps.next": "Kontynuuj",
  "createCompany.steps.selectPlan.noCC": "Karta kredytowa nie jest wymagana dla 7-dniowego okresu próbnego",
  "createCompany.steps.selectPlan.title": "Zaczynajmy!",
  "createCompany.steps.selectPlan.youCanSwitchPlanDuringTrial": "Możesz zmienić plan w dowolnym momencie podczas okresu próbnego",
  "createCompany.steps.selectUsers": "Importuj użytkowników",
  "createCompany.steps.selectUsers.deselectAll": "Odznacz wszystko",
  "createCompany.steps.selectUsers.doneButton": "Importuj {noOfUsers} użytkownik{postfix}",
  "createCompany.steps.selectUsers.doneButtonTryItOut": "Rozpocznij 14-dniowy okres próbny",
  "createCompany.steps.selectUsers.loadingAll": "Ładowanie wszystkich użytkowników",
  "createCompany.steps.selectUsers.searchUsers": "Znajdź Nemo...",
  "createCompany.steps.selectUsers.selectAll": "Zaznacz wszystko",
  "createCompany.steps.selectUsers.title": "Wybierz członków swojego zespołu",
  "createCompany.steps.selectUsers.title.info": "Wybierz użytkowników, których chcesz dodać do konta Vacation Tracker swojej organizacji. Zawsze możesz wprowadzić zmiany później przez panel zarządzania. Wybrani użytkownicy nie zostaną powiadomieni, dopóki nie dokonasz ogłoszenia.",
  "dashboard.accountSettingsLanguageLabel": "Wybierz preferowany język",
  "dashboard.accountSettingsOutOfOfficeChangeModalInfo": "Vacation Tracker ustawi wszystkie przyszłe wiadomości poza biurem dla adresu e-mail {email}.\nJednak jeśli zaplanowałeś wiadomość poza biurem przed tą zmianą, nadal ustawimy ją dla starego adresu e-mail.",
  "dashboard.accountSettingsOutOfOfficeChangeModalTitle": "Zmień adres e-mail dla przyszłych wiadomości poza biurem",
  "dashboard.accountSettingsOutOfOfficeConnectedWith": "Połączono z",
  "dashboard.accountSettingsOutOfOfficeTitle": "Skonfiguruj automatyczną odpowiedź poza biurem (OOO)",
  "dashboard.accountSettingsPageOOODescription": "Po włączeniu, Vacation Tracker powiadomi Cię dwa dni przed planowanym urlopem i da Ci możliwość ustawienia automatycznej wiadomości odpowiedzi poza biurem (OOO).",
  "dashboard.accountSettingsTimeDisplayLabel": "Wybierz preferowany format wyświetlania czasu",
  "dashboard.accountSettingsUIModeLabel": "Tryb ciemny vs. Tryb jasny",
  "dashboard.b2bSoftware": "Prosimy zauważyć, że jest to oprogramowanie B2B i dlatego nie możemy zmieniać ani usuwać Twoich danych na żądanie. Aby uzyskać więcej informacji, skontaktuj się z dedykowanym menadżerem. Jeśli potrzebujesz pomocy, nasz zespół wsparcia jest dostępny pod adresem <mail>hello@vacationtracker.io</mail>",
  "dashboard.days": "Dzień/dni",
  "dashboard.daysSmallCase": "dzień/dni",
  "dashboard.daysSmallCaseSingle": "d",
  "dashboard.googlePermissionInfo2": "- Dostęp do ustawień Kalendarza Google jest wymagany, aby uzyskać ustawienia strefy czasowej Google.",
  "dashboard.googlePermissionInfo3": "- Dostęp do ustawień Gmaila jest potrzebny do skonfigurowania odpowiedzi OOO i zdefiniowania odpowiedniego zakresu dat.",
  "dashboard.hour": "godzina(y)",
  "dashboard.hourSingle": "h",
  "dashboard.minuteSingle": "m",
  "dashboard.noLeaveRequest": "Ta prośba o urlop nie jest aktywna.",
  "dashboard.noOneIsOffToday": "Nie ma zaplanowanych urlopów na dziś.",
  "dashboard.noOneIsTackingLeaves": "W tej chwili nie ma zaplanowanych urlopów.",
  "dashboard.noOpenLeavesRequests": "Nie ma oczekujących próśb.",
  "dashboard.noScheduledLeavesHolidays": "Brak zaplanowanych urlopów lub świąt",
  "dashboard.openLeavesRequests": "Oczekujące prośby",
  "dashboard.outlookPermissionInfo2": "- Dostęp offline: Jest to konieczne, aby Vacation Tracker mógł ustawić Twoją odpowiedź OOO.",
  "dashboard.outlookPermissionInfo3": "- Ustawienia skrzynki pocztowej Outlook: Potrzebujemy dostępu do ustawień strefy czasowej Outlook, aby zapewnić dokładne określenie czasu. Dodatkowo wymagamy pozwolenia na ustawienie Twojej wiadomości OOO i zdefiniowanie pożądanego zakresu dat.",
  "dashboard.outOfOffice": "Ustaw wiadomość OOO",
  "dashboard.outOfOffice.requestIntegrationButton": "Poproś o integrację",
  "dashboard.outOfOfficeActiveLabel": "Poza biurem",
  "dashboard.outOfOfficeGoogleMailButton": "E-mail Google",
  "dashboard.outOfOfficeGoogleSigninModalTitle": "Wymagane zalogowanie do konta Google",
  "dashboard.outOfOfficeMicrosoftSigninModalTitle": "Wymagane zalogowanie do konta Microsoft",
  "dashboard.outOfOfficeNotActiveInfo": "Wygląda na to, że nie włączyłeś jeszcze funkcji automatycznej odpowiedzi e-mail poza biurem na swoim koncie. To narzędzie pozwala na ustawienie automatycznych e-maili dla konkretnych typów urlopów, zapewniając, że Twoje kontakty wiedzą, kiedy jesteś niedostępny. Czy chcesz włączyć tę funkcję?",
  "dashboard.outOfOfficeOtherEmail": "Czy masz innego dostawcę poczty e-mail?",
  "dashboard.outOfOfficeOtherEmailInfo": "W tej chwili obsługujemy Gmail i Outlook. Jednak stale dążymy do ulepszania naszego oprogramowania. Twoja opinia na temat używanego przez Ciebie dostawcy poczty e-mail byłaby bardzo pomocna w ustalaniu naszych priorytetów rozwojowych. Dopóki nie rozszerzymy wsparcia na innych dostawców, możesz ręcznie ustawić automatyczną odpowiedź 'Poza biurem' w swoim serwisie pocztowym.",
  "dashboard.outOfOfficeOtherEmailPlaceholder": "Wpisz swojego dostawcę, np. ProtonMail, Yahoo! Mail...",
  "dashboard.outOfOfficeOutlookButton": "Outlook",
  "dashboard.outOfOfficePageSettingsTitle": "WYBIERZ SWOJEGO DOSTAWCĘ E-MAIL",
  "dashboard.outOfOfficePageTitle": "Poza biurem",
  "dashboard.outOfOfficeSet": "Ustaw wiadomość OOO",
  "dashboard.outOfOfficeSetError": "Wystąpił błąd podczas ustawiania automatycznej odpowiedzi 'Poza biurem'. Proszę spróbować ponownie.",
  "dashboard.outOfOfficeSetInfo": "Tutaj możesz ustawić swoją odpowiedź e-mail 'Poza biurem'. Podczas Twojej nieobecności, ta wiadomość będzie automatycznie wysyłana do osób, które próbują się z Tobą skontaktować za pośrednictwem e-maila. Jeśli zdecydujesz się zmienić tę wiadomość w przyszłości, możesz to łatwo zrobić poprzez ustawienia swojego e-maila.",
  "dashboard.outOfOfficeSetInfoBot": "Twoja odpowiedź e-mail 'Poza biurem' została ustawiona. Podczas Twojej nieobecności, ta wiadomość będzie automatycznie wysyłana do osób, które próbują się z Tobą skontaktować za pośrednictwem e-maila. Jeśli zdecydujesz się zmienić tę wiadomość w przyszłości, możesz to łatwo zrobić poprzez ustawienia swojego e-maila.",
  "dashboard.outOfOfficeSetSuccess": "Gratulacje — pomyślnie ustawiłeś swoją automatyczną wiadomość 'Poza biurem'. Będzie ona aktywna, gdy rozpocznie się Twój urlop.",
  "dashboard.peopleOffToday": "Dzisiejsze nieobecności",
  "dashboard.permissionModalInfo": "Aby Vacation Tracker mógł automatycznie ustawić Twoją odpowiedź 'Poza biurem' (OOO), potrzebujemy dodatkowych uprawnień:",
  "dashboard.selectSnoozeLeaveTypesPlaceholder": "Wybierz rodzaje urlopów...",
  "dashboard.snoozedLeaveTypesLabel": "Dezaktywuj dla ",
  "dashboard.upcomingDaysOff": "Zaplanowane nieobecności ({total})",
  "dashboard.upcomingHolidays": "Nadchodzące święta",
  "departments.viewDepartment": "Zobacz dział",
  "disconnectCalendar.success": "Pomyślnie odłączono kalendarz.",
  "editLeave.editLeave": "Edytuj urlop",
  "editLeave.handleSubmitErrorTitle": "Błąd edycji urlopu",
  "editLeave.inProgress": "Edytowanie urlopu",
  "editLeave.requestLeave": "Edytuj urlop",
  "editLeave.title": "Urlop edytowany",
  "email.notification.accruedDaysInfoForApprover": "ℹ️ Ta prośba o urlop opiera się na przyszłych naliczonych dniach wolnych, a nie na obecnej ilości. {name} ma obecnie {amount} dzień/dni pozostałych. Naliczą {accrued} w dniu {earningDate}.",
  "email.notification.accruedDaysInfoForUser": "ℹ️ Twoja prośba o urlop opiera się na przyszłych naliczonych dniach wolnych, a nie na obecnej ilości. Masz {amount} dzień/dni pozostałych dzisiaj, a naliczysz {accrued} w dniu {earningDate}.",
  "email.notification.holiday.plural": "Lokalizacje <strong>{locationNames}</strong> będą nieczynne z powodu <strong>{holidayName}</strong> {holidayPeriodText}",
  "email.notification.holiday.singular": "Lokalizacja <strong>{locationNames}</strong> będzie nieczynna z powodu <strong>{holidayName}</strong> {holidayPeriodText}",
  "email.notification.title": "Powiadomienie o urlopie",
  "error.auth.companyExists.line1": "Twoje konto nie jest aktywowane. Aby korzystać z Vacation Tracker, skontaktuj się z administratorem i poproś o aktywację konta.",
  "error.auth.companyExists.line2": "Administratorzy:",
  "error.auth.companyExists.line3": "Lub skontaktuj się z naszym wsparciem ({support}), jeśli chcesz używać Vacation Tracker jako niezależny dział w tej samej firmie.",
  "error.auth.companyExists.title": "Twoja organizacja jest już zarejestrowana",
  "error.automations.automationSubmitError": "Błąd przesyłania automatyzacji",
  "error.automations.insufficientPrivileges.description": "Nie masz uprawnień do wykonania tej akcji. Skontaktuj się z administratorem.",
  "error.automations.insufficientPrivileges.title": "Błąd uprawnień",
  "error.blackOutPeriod.isntCreated": "Okres blokady nie został utworzony",
  "error.blackOutPeriod.isntDeleted": "Okres blokady nie został usunięty",
  "error.blackOutPeriod.isntUpdated": "Okres blokady nie został zaktualizowany",
  "error.blackOutPeriod.UserNotAdminOrApprover": "Użytkownik nie jest administratorem ani zatwierdzającym",
  "error.blackOutPeriod.UserNotFound": "Nie znaleziono użytkownika",
  "error.botTokenNotFound": "Nie znaleziono tokenu bota, zaloguj się ponownie do naszego panelu i spróbuj ponownie.",
  "error.CannotDuplicateName": "Podana nazwa jest już w użyciu. Proszę podać inną nazwę.",
  "error.channelNotFound": "Wybrany kanał został usunięty lub nie jest już dostępny. Proszę wybrać inny kanał do otrzymywania powiadomień.",
  "error.codeDeliveryFailureException": "Błąd dostarczenia kodu",
  "error.codeDeliveryFailureExceptionMessage": "Nie możemy dostarczyć kodu weryfikacyjnego. Proszę sprawdzić, czy podany adres e-mail jest prawidłowy. Jeśli adres e-mail jest prawidłowy, proszę spróbować ponownie później.",
  "error.codeMismatchException": "Nieprawidłowy kod weryfikacyjny",
  "error.codeMismatchExceptionMessage": "Podano nieprawidłowy kod weryfikacyjny. Proszę spróbować ponownie lub poprosić o ponowne wysłanie e-maila.",
  "error.companyExists": "Firma już istnieje",
  "error.CustomWorkday": "Przejście na plan bez niestandardowych godzin pracy jest niemożliwe. Proszę usunąć niestandardowe godziny pracy z ustawień lub skontaktować się z pomocą techniczną.",
  "error.deletedLocation": "Wybrana lokalizacja została usunięta lub nie jest już dostępna. Proszę wybrać inną lokalizację do otrzymywania powiadomień.",
  "error.deletedTeam": "Wybrany dział został usunięty lub nie jest już dostępny. Proszę wybrać inny dział do otrzymywania powiadomień.",
  "error.DepartmentWithChildrenDeleteForbidden": "Nie można usunąć działu, który ma poddziały. Proszę najpierw usunąć poddziały.",
  "error.emailError": "Nieprawidłowy adres e-mail",
  "error.emailError.description": "Proszę podać prawidłowy adres e-mail",
  "error.endTimeCantBeBeforeStartTime": "Wygląda na to, że wybrana godzina rozpoczęcia jest późniejsza niż godzina zakończenia. Proszę wybrać godzinę rozpoczęcia wcześniejszą niż godzina zakończenia.",
  "error.entitlementByRole.isntCreated": "Nie utworzono uprawnienia według roli",
  "error.entitlementByRole.isntDeleted": "Nie usunięto uprawnienia według roli",
  "error.entitlementByRole.isntUpdated": "Nie zaktualizowano uprawnienia według roli",
  "error.expiredCodeException": "Kod weryfikacyjny wygasł",
  "error.expiredCodeExceptionMessage": "Podany kod weryfikacyjny wygasł.",
  "error.fetchingConnectedCalendarInfo": "Wystąpił problem z pobieraniem informacji o połączonym kalendarzu. Proszę spróbować ponownie. Jeśli problem będzie się powtarzał, proszę skontaktować się z naszym wsparciem technicznym",
  "error.fetchingUserOutOfOfficeSettings": "Wystąpił problem z pobieraniem ustawień nieobecności. Proszę spróbować ponownie. Jeśli problem będzie się powtarzał, proszę skontaktować się z naszym wsparciem technicznym",
  "error.fieldIsRequired": "To pole jest wymagane.",
  "error.firstEarningDateMissing": "Brak daty pierwszego zarobku",
  "error.generic": "Wystąpił błąd",
  "error.generic.description": "Proszę skontaktować się z pomocą techniczną, podając następującą wiadomość:",
  "error.google.directoryApiDisabledP1": "Vacation Tracker potrzebuje dostępu do danych katalogu, aby importować nowych użytkowników.",
  "error.google.directoryApiDisabledP2": "Administrator Twojego Google Workspace może zezwolić na dostęp do katalogu, postępując zgodnie z krokami z artykułu pomocy: ",
  "error.google.directoryApiDisabledP3": "Możesz zarejestrować się bez włączania tej funkcji. Jednak nie będziesz mógł importować użytkowników z Google Workspace.",
  "error.google.directoryApiDisabledP4": "Jeśli potrzebujesz dodatkowej pomocy, skontaktuj się z nami pod adresem hello@vacationtracker.io",
  "error.google.directoryApiDisabledTitle": "Udostępnianie kontaktów wyłączone",
  "error.google.directoryApiPermissionDeniedP1": "Vacation Tracker musi mieć możliwość listowania użytkowników z katalogu Google Workspace. Kliknij przycisk \"Zaloguj się przez Google\" i zaznacz pole wyboru pokazane na poniższym obrazku, aby móc się zarejestrować.",
  "error.google.directoryApiPermissionDeniedP2": "Przechowamy tylko następujące informacje dla użytkowników, których wyraźnie wybierzesz do importu: imię i nazwisko, zdjęcie profilowe i adres e-mail.",
  "error.google.directoryApiPermissionDeniedP3": "Jeśli nie widzisz pola wyboru po kliknięciu przycisku \"Zaloguj się przez Google\", kliknij przycisk \"Kontynuuj\" w oknie uprawnień Google, aby kontynuować proces rejestracji.",
  "error.google.directoryApiPermissionDeniedTitle": "Udziel brakujących uprawnień, aby kontynuować",
  "error.google.loginRequiredTitle": "Wymagane logowanie do konta Google",
  "error.google.usersLoginRequiredDescription1": "Zarządzanie użytkownikami wymaga uprawnień Google. Jeśli klikniesz przycisk OK, przekierujemy Cię na stronę logowania Google. W zależności od ustawień przeglądarki, możesz być już zalogowany i automatycznie przekierowany z powrotem.",
  "error.google.usersLoginRequiredDescription2": "Po zalogowaniu się na konto Google, przeniesiemy Cię z powrotem na tę stronę, abyś mógł zarządzać użytkownikami.",
  "error.goToRequestLeaveTab": "Błąd przejścia do zakładki Wniosek o urlop",
  "error.holidayDateInvalid": "Nieprawidłowa data święta {date} dla roku {year} ",
  "error.incorrectPassword": "Nieprawidłowe hasło",
  "error.incorrectPasswordMessage": "Wprowadzone hasło jest nieprawidłowe lub wygasło. Spróbuj ponownie (upewnij się, że Caps Lock jest wyłączony). Zapomniałeś hasła?",
  "error.invalidFileType": "Nieprawidłowy typ pliku",
  "error.invalidFileType.description": "Przesłano nieprawidłowy typ pliku. Proszę przesłać obraz w formacie JPEG, PNG lub GIF.",
  "error.invalidPasswordException": "Nieprawidłowe hasło",
  "error.invalidPasswordExceptionMessage": "Proszę podać prawidłowe hasło zawierające co najmniej 8 znaków.",
  "error.leavePolicyMissing": "Brak polityki urlopowej",
  "error.leaveRequestNotEditable": "Nie masz uprawnień do edycji tego wniosku urlopowego.",
  "error.leaveRequestOverlap": "Wybrane daty pokrywają się z istniejącym urlopem. Proszę wybrać inny okres.",
  "error.leaveRequestsLoadingError.description": "Twoje urlopy istnieją, ale nie mogliśmy ich załadować z powodu problemu technicznego po naszej stronie. Spróbuj odświeżyć stronę. Jeśli problem będzie się powtarzał, skontaktuj się z naszym <link>Działem Obsługi Klienta</link>.",
  "error.leaveRequestsLoadingError.title": "Nie można załadować urlopów",
  "error.leaveRequestStatusNotApproved": "Status wniosku urlopowego: niezatwierdzony.",
  "error.leaveRequestStatusNotOpen": "Status wniosku urlopowego: niezatwierdzony",
  "error.leaveSubmitError": "Błąd przy składaniu wniosku urlopowego",
  "error.leaveTypeMissing": "Brak typu urlopu",
  "error.LocationLimitReached": "Przepraszamy, ale nie możesz przejść na plan CORE, ponieważ obecnie przekraczasz limit {limitNumber} lokalizacji. Dostosuj swoje lokalizacje, aby spełnić kryteria planu CORE lub skontaktuj się z działem wsparcia, aby uzyskać dalsze wskazówki.",
  "error.microsoft.channelNotFound": "Nie jesteś członkiem wybranego zespołu Microsoft Teams",
  "error.microsoft.logInWithDifferentAccount": "Zaloguj się na inne konto",
  "error.microsoft.microsoftPermissionsNotGranted": "Nie przyznano uprawnień Microsoft",
  "error.microsoft.notificationsLoginRequiredTitle": "Wymagane jest zalogowanie się na konto Microsoft",
  "error.microsoft.notMemberOfTeam": "Twoja organizacja zarejestrowała się z pojedynczym zespołem Microsoft Teams, a ty nie jesteś członkiem zespołu o następującym ID: {msTeamId}.\n\nMożesz dołączyć do tego zespołu w aplikacji Microsoft Teams i spróbować ponownie lub skontaktować się z naszym wsparciem, aby zmienić typ instalacji.",
  "error.microsoft.wrongAccount": "Nieprawidłowe konto Microsoft",
  "error.missingSlackBotToken": "Akcja została przetworzona, jednak brakuje nam tokena bota, aby powiadomić użytkownika. Proszę wylogować się i zalogować ponownie, aby uzyskać token bota.",
  "error.missingSlackBotTokenError": "Nie możemy przetworzyć żądania, ponieważ brakuje tokena bota. Proszę wylogować się i zalogować ponownie, aby otrzymać token bota, a następnie spróbować ponownie.",
  "error.mustBeApproverForTeam": "Nie masz uprawnień do wykonania tej czynności, musisz być Zatwierdzającym dla tego działu.",
  "error.mustBeCreatorOrApprover": "Musisz być twórcą lub zatwierdzającym, aby zmienić status wniosku urlopowego.",
  "error.negativeBallanceNotAllowed": "Ujemne saldo nie jest dozwolone dla tego typu urlopu.",
  "error.notificationGeneral": "Błąd powiadomienia",
  "error.oneHoureIntervalNotAllowed": "Najkrótszy możliwy przedział czasowy dla tego typu urlopu to 4 godziny (pół dnia).",
  "error.openApi.isntCreated": "Klucz Open API nie został utworzony",
  "error.openApi.isntDeleted": "Klucz Open API nie został usunięty",
  "error.partTimeIsntAllowed": "Praca w niepełnym wymiarze godzin nie jest dozwolona",
  "error.passwordResetLimitExceededException": "Przekroczono limit",
  "error.passwordResetLimitExceededExceptionMessage": "Przekroczono limit prób zmiany hasła. Spróbuj ponownie za 15 minut.",
  "error.paymentFailed": "Płatność nie powiodła się",
  "error.paymentNotProcessed": "Płatność nie powiodła się, proszę spróbować z inną kartą.",
  "error.probationPeriod.isntCreated": "Okres próbny nie został utworzony",
  "error.probationPeriod.isntDeleted": "Okres próbny nie został usunięty",
  "error.probationPeriod.isntUpdated": "Okres próbny nie został zaktualizowany",
  "error.PromoCodeDoesntExist": "Kod promocyjny {promoCode} nie istnieje",
  "error.PromoCodeIsntValid": "Kod promocyjny {promoCode} jest nieprawidłowy",
  "error.reasonRequired": "Pole powodu jest wymagane.",
  "error.requestedMoreThanFullDay": "Zażądałeś więcej niż jeden pełny dzień urlopu w ciągu jednego dnia roboczego. Proszę wybrać inny dzień.",
  "error.samePasswordException": "Hasło nie zostało zmienione",
  "error.samePasswordExceptionMessage": "Nowe hasło jest takie samo jak stare. Proszę wprowadzić inne hasło, jeśli chcesz je zmienić.",
  "error.saveFailed": "Zapisywanie nie powiodło się",
  "error.seniorityEntitlement.isntCreated": "Uprawnienie stażowe nie zostało utworzone",
  "error.seniorityEntitlement.isntDeleted": "Uprawnienie stażowe nie zostało usunięte",
  "error.seniorityEntitlement.isntUpdated": "Uprawnienie stażowe nie zostało zaktualizowane",
  "error.signin.inactiveUser": "Użytkownik nieaktywny",
  "error.signup.alreadySignedUp.descriptionLine1": "Jesteś już zarejestrowany z następującym adresem e-mail: <text>{ email }</text>. Proszę się zalogować.",
  "error.signup.alreadySignedUp.descriptionLine2": "Jeśli zapomniałeś hasła, kliknij przycisk poniżej, aby je zresetować.",
  "error.signup.alreadySignedUp.title": "Jesteś już zarejestrowany",
  "error.signup.alreadySignedUpInactive.descriptionLine1": "Jesteś już zarejestrowany z następującym adresem e-mail: <text>{ email }</text>.",
  "error.signup.alreadySignedUpInactive.descriptionLine2": "Jednak twoje konto jest nieaktywne. Skontaktuj się z naszym wsparciem, aby uzyskać więcej informacji i wyślij im następujące informacje:",
  "error.slack.allowConversationWithSlackBotDescription": "Ta funkcja wymaga dodatkowego uprawnienia Slack, które pozwala botowi Vacation Tracker dla Slack na odbieranie bezpośrednich wiadomości. Nasz bot będzie mógł czytać wiadomości, które ty i inni użytkownicy z twojego Workspace Slack do niego wysyłacie, ale nie będzie miał uprawnień do czytania waszych kanałów, grup ani bezpośrednich wiadomości.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine1": "Bot Vacation Tracker obsługuje polecenie ukośnika i skrót do wniosków urlopowych. Dodatkowo możesz włączyć konwersację w języku naturalnym w panelu internetowym (i wyrazić zgodę na użycie LLM).",
  "error.slack.allowConversationWithSlackBotLlmWarningLine2": "Włączenie tej opcji może spowodować, że bot czasami zasugeruje niepoprawne daty lub typy urlopu. Ważne jest, aby dokładnie sprawdzić jego zalecenia przed złożeniem wniosku urlopowego.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine3": "Ani Vacation Tracker, ani jego strony trzecie nie wykorzystują twoich informacji do trenowania modeli języka naturalnego.",
  "error.slack.allowConversationWithSlackBotLlmWarningTitle": "Zastrzeżenie dotyczące modelu języka naturalnego (LLM)",
  "error.slack.allowConversationWithSlackBotTitle": "Zezwól na rozmowę z botem Vacation Tracker",
  "error.slack.logInWithDifferentAccount": "Zaloguj się na inne konto",
  "error.slack.usersLoginRequiredTitle": "Wymagane jest zalogowanie do konta Slack",
  "error.slack.wrongAccount": "Nieprawidłowe konto Slack",
  "error.somethingWentWrong": "O nie! 😕 Coś poszło nie tak. ",
  "error.startDateCantBeInPast": "Data rozpoczęcia nie może być w przeszłości, proszę wybrać datę w przyszłości",
  "error.subscriptionExpired": "Ups, wygląda na to, że subskrypcja twojej firmy w Vacation Tracker wygasła! Skontaktuj się z administratorem, aby ponownie aktywować subskrypcję firmy.",
  "error.subscriptionExpiredTitle": "Subskrypcja wygasła",
  "error.team.delete": "Dział nie może zostać usunięty",
  "error.TeamLimitReached": "Twoja obecna liczba działów przekracza limit dozwolony w planie CORE. Aby obniżyć plan, zmniejsz liczbę działów do {limitNumber} lub mniej, lub skontaktuj się z naszym wsparciem w celu uzyskania pomocy.",
  "error.token.button": "Zaloguj się za pomocą {platform}",
  "error.token.description": "Twój token {platform} wygasł. Proszę kliknąć przycisk poniżej, aby uzyskać nowy token",
  "error.token.permissionDenied": "W oknie logowania Google kliknąłeś Kontynuuj bez zezwolenia na wymagane zakresy uwierzytelniania. Proszę kliknąć Wybierz wszystko lub wybrać odpowiednie uprawnienia (Zobacz, edytuj, udostępniaj i trwale usuwaj wszystkie kalendarze, do których masz dostęp za pomocą Kalendarza Google oraz Zobacz, edytuj, twórz lub zmieniaj ustawienia i filtry poczty e-mail w Gmailu), a następnie kliknij Kontynuuj.",
  "error.userEndDateInFuture": "Żądane daty są po dacie zakończenia zatrudnienia użytkownika. Skontaktuj się z administratorem swojej organizacji lub wsparciem Vacation Tracker, aby uzyskać więcej informacji.",
  "error.UserNotAdminOrApprover": "Użytkownik nie jest administratorem ani zatwierdzającym",
  "error.userNotFound": "Nie znaleziono użytkownika",
  "error.UserNotFound": "Nie znaleziono użytkownika",
  "error.UserNotFoundApprover": "Nie znaleziono zatwierdzającego",
  "error.userSignup.invitationAccepted.description": "Kod zaproszenia został już zaakceptowany, proszę się zalogować",
  "error.userSignup.invitationAccepted.title": "Kod zaproszenia już zaakceptowany",
  "error.userSignup.invitationExpired.description": "Kod zaproszenia wygasł, poproś administratora o ponowne wysłanie zaproszenia",
  "error.userSignup.invitationExpired.title": "Kod zaproszenia wygasł",
  "error.userSignup.invitationNotFound.description": "Sprawdź, czy link do zaproszenia jest poprawny lub skontaktuj się z administratorem, aby ponownie wysłał zaproszenie.",
  "error.userSignup.invitationNotFound.title": "Nie znaleziono zaproszenia",
  "error.userStartDateInFuture": "Wybrane daty są przed datą rozpoczęcia zatrudnienia użytkownika. Skontaktuj się z administratorem organizacji lub obsługą klienta Vacation Tracker, aby uzyskać więcej informacji.",
  "error.zeroDaysLeaveRequest": "Wybrano dzień świąteczny lub niepracujący. Wybierz prawidłowy zakres dat.",
  "errors.microsoft.permissions.microsoftPermissionsNotGrantedDescription": "Wygląda na to, że anulowałeś lub zamknąłeś stronę \"Zaloguj się z Microsoft\" przed udzieleniem wymaganych uprawnień. Kliknij przycisk poniżej, aby zalogować się za pomocą Microsoft i zakończyć konfigurację Vacation Tracker.\n\nJeśli nie masz uprawnień do logowania się przez Microsoft, skontaktuj się z administratorem Microsoft 365 w Twojej organizacji.",
  "errors.microsoft.subscriptionExpiredDescription": "Wygląda na to, że Twoja subskrypcja wygasła. Skontaktuj się z administratorem Twojej organizacji Vacation Tracker, aby uzyskać więcej szczegółów.\n\nSkontaktuj się z naszym wsparciem, jeśli potrzebujesz zmienić administratora lub masz inne pytania.",
  "errors.microsoft.wrongMicrosoftAccountDescription": "Wygląda na to, że zalogowałeś się za pomocą innego konta Microsoft. Zakupiłeś licencje używając następującego adresu e-mail:\n\n{email}.\n\nZaloguj się za pomocą konta, którego użyłeś do zakupu licencji, aby zakończyć konfigurację.",
  "errors.slack.wrongSlackAccountDescription": "Wygląda na to, że zalogowałeś się za pomocą innego konta Slack.",
  "events.logs.AUTOMATION": "Automatyzacje",
  "events.logs.BILLING": "Fakturowanie",
  "events.logs.BULK_ACTIONS": "Import użytkowników",
  "events.logs.COMPANY": "Firma",
  "events.logs.LABEL": "Etykieta",
  "events.logs.LEAVE_POLICY": "Polityka urlopowa",
  "events.logs.LEAVE_TYPE": "Typ urlopu",
  "events.logs.LOCATION": "Lokalizacja",
  "events.logs.LOCATION_YEAR_ROLLOVER": "Przełom roku dla lokalizacji",
  "events.logs.NOTIFICATION": "Powiadomienia",
  "events.logs.REPORT": "Raport",
  "events.logs.TEAM": "Dział",
  "events.logs.TEAM_POLICY": "Polityka zespołu",
  "export.approved": "Zatwierdzony",
  "export.currentYear": "Bieżący rok",
  "export.denied": "Odrzucony",
  "export.export": "Eksportuj",
  "export.exportPeriod": "Okres eksportu",
  "export.fileType": "Format eksportu",
  "export.fileTypeCsv": "CSV",
  "export.fileTypeExcelXlsx": "Excel",
  "export.labels": "Etykiety",
  "export.lastMonth": "Ostatni miesiąc",
  "export.leaveBalanceReport": "Raport salda urlopowego",
  "export.leaveBalanceReportCustomDaysInfo": "Pamiętaj, że raport wyświetli tylko wykorzystane dni lub godziny, jeśli wybierzesz niestandardowy okres. Nie będziesz mógł wyeksportować całkowitego lub dostępnego limitu, chyba że wybierzesz jedną z predefiniowanych opcji.",
  "export.leaveBalanceReportDescription": "Ilość przysługujących i wykorzystanych urlopów dla każdego typu urlopu dla każdego pracownika w określonym okresie czasu.",
  "export.leaveHistoryReport": "Raport wniosków urlopowych",
  "export.leaveHistoryReportDescription": "Historia wszystkich wniosków urlopowych (zatwierdzonych, odrzuconych, wygasłych) dla każdego typu urlopu dla każdego pracownika w określonym okresie czasu.",
  "export.locations": "Lokalizacje",
  "export.monthlyLeaveBalanceReport": "Miesięczny raport bilansu urlopowego",
  "export.monthlyLeaveBalanceReportDescription": "Ilość wykorzystanych (lub zaplanowanych) urlopów dla każdego typu urlopu dla każdego pracownika na bieżący i kolejne 11 miesięcy.",
  "export.nextYear": "Następny rok",
  "export.previousYear": "Poprzedni rok",
  "export.rangeDate": "Data początkowa i końcowa",
  "export.reportType": "Typ raportu",
  "export.required": "To pole jest wymagane.",
  "export.status": "Status",
  "export.teams": "Działy",
  "externalConnect.faildLogin": "Twój jednorazowy token wygasł lub został już użyty. Za kilka sekund przekierujemy Cię na stronę docelową, ale może być konieczne ponowne zalogowanie.\n\nJeśli się spieszysz, kliknij przycisk poniżej.",
  "externalConnect.parseError": "O nie! 😕 Coś poszło nie tak.\n\nZostaniesz przekierowany na stronę logowania za 10 sekund.",
  "externalConnect.pleaseWait": "Proszę czekać, Twój komputer nie jest supermanem!",
  "form.confirmPasswordInfo": "Proszę potwierdzić hasło!",
  "form.department.generalDescriptionCreate": "Ustaw nazwę swojego działu i wybierz jego użytkowników oraz osoby zatwierdzające. Jeśli chcesz skonfigurować dział przed dodaniem użytkowników, możesz najpierw utworzyć dział, a później dodać użytkowników, ale dodanie co najmniej jednej osoby zatwierdzającej jest wymagane.",
  "form.department.generalDescriptionEdit": "Zmień nazwę swojego działu lub jego użytkowników i osoby zatwierdzające.",
  "form.department.generalTitle": "Ustawienia działu",
  "form.department.setDefaultDescription": "Domyślny dział",
  "form.department.setDefaultTitle": "Domyślny dział",
  "form.department.subdepartmentDescription": "Możesz zagnieżdżać działy, aby stworzyć strukturę drzewiastą organizacji. Jeśli to jest poddział, możesz ustawić jego nadrzędny dział w tej sekcji.",
  "form.department.subdepartmentTitle": "Ustawienia poddziału",
  "form.general.companySettingsDescription": "Ustaw nazwę swojej firmy i główny adres e-mail kontaktowy. Podany adres e-mail będzie używany tylko do ważnej komunikacji produktowej. Bez spamu, obiecujemy.",
  "form.general.companySettingsTitle": "Ustawienia firmy",
  "form.general.configurationDescription": "Ta sekcja pozwala na dostosowanie konkretnych aspektów śledzenia urlopów w Twojej firmie. Włącz daty końcowe użytkowników, aby automatycznie dezaktywować pracowników z datą końcową, uniemożliwiając im składanie wniosków urlopowych po ich odejściu. Wybierz śledzenie limitów urlopowych w dniach lub godzinach, zgodnie z polityką Twojej firmy. Dodatkowo, określ rok początkowy dla śledzenia urlopów. Domyślnie obliczenia urlopów zaczynają się od roku, w którym się zarejestrowałeś, ale możesz to dostosować, aby uwzględnić dane historyczne z innych systemów lub arkuszy kalkulacyjnych.",
  "form.general.configurationTitle": "Konfiguracja",
  "form.general.migrationDescription": "Czy korzystasz ze Slack, Microsoft Teams lub Google Workspace? Możesz przejść z uwierzytelniania opartego na e-mailu i haśle na integrację specyficzną dla platformy. Przejście na jedną z naszych integracji uprościłoby proces logowania i zapraszania użytkowników (import użytkowników z katalogu użytkowników) i dałoby dodatkowe integracje, takie jak karty Microsoft Teams oraz boty Microsoft Teams lub Slack.",
  "form.general.migrationFeatureDescription": "To rozpocznie proces migracji. Zostaniesz przekierowany na platformę, gdzie będziesz mógł połączyć swoje konto.",
  "form.general.migrationFeatureTitle": "Rozpocznij migrację na inną platformę",
  "form.general.migrationTitle": "Przełącz na inną platformę",
  "form.inputRequired": "To pole jest wymagane",
  "form.inputSize": "Pole musi mieć {len} cyfr",
  "form.passwordDoNotMatch": "Wprowadzone hasła nie są zgodne!",
  "form.userNameRequired": "Proszę wprowadzić swoje imię",
  "form.validEmailRequired": "Proszę wprowadzić poprawny adres e-mail.",
  "general.announceNewUsers": "Ogłoś nowym użytkownikom",
  "general.announceNewUsersInfo": "Wyślij wiadomość powitalną o Vacation Tracker do nowych użytkowników Slack/Teams/Google Workspace",
  "general.announceNewUsersInfoTooltip": "Gdy to pole jest zaznaczone, za każdym razem, gdy dodasz nowego użytkownika do swojej organizacji Slack/Teams/GW, otrzyma on krótką wiadomość od Vacation Tracker wyjaśniającą, jak to działa. Jeśli to pole nie jest zaznaczone, nowi użytkownicy nie otrzymają żadnej wiadomości powitalnej od Vacation Tracker.",
  "general.announceNewUsersTooltip": "Za każdym razem, gdy dodasz nowego użytkownika do swojej {platform}, otrzyma on krótką wiadomość od Vacation Tracker wyjaśniającą, jak to działa. Jeśli wybierzesz opcję Nie wysyłaj, nowi użytkownicy nie otrzymają żadnej wiadomości powitalnej od Vacation Tracker.",
  "general.calculationStartYear": "Vacation Tracker śledzi Twoje urlopy od",
  "general.calculationStartYearTooltipLine1": "Musisz zmienić to ustawienie tylko wtedy, gdy chcesz zaimportować historyczne urlopy z innego systemu lub arkuszy kalkulacyjnych i uwzględnić te urlopy w obliczeniach Vacation Tracker.",
  "general.calculationStartYearTooltipLine2": "Vacation Tracker oblicza urlopy od roku, w którym się zarejestrowałeś. Oznacza to, że automatycznie obliczymy pozostałe i przeniesione dni zgodnie z Twoimi ustawieniami, a Ty będziesz mógł ustawić przeniesione dni z poprzedniego systemu dla każdego użytkownika na ich stronach profilowych. Jednak jeśli chcesz, aby Vacation Tracker obliczał urlopy za minione lata, możesz zmienić tę datę. Jeśli potrzebujesz więcej informacji, <support>skontaktuj się z naszym wsparciem</support>.",
  "general.clickToSelectPlatform": "Kliknij, aby wybrać platformę",
  "general.companyId": "ID firmy:",
  "general.companyName": "Nazwa firmy",
  "general.confirmModalTitle": "Potwierdź aktualizacje ustawień firmy",
  "general.connectSync": "Połącz synchronizację",
  "general.connectToAnotherPlatform": "Przełącz na inną platformę",
  "general.connectWith": "Przełącz na {platform}",
  "general.connectWithHelpdeskInfo": "Nie wiesz, którą platformę wybrać? Ten <link>artykuł</link> może pomóc.",
  "general.days": "Dni",
  "general.deleteAccount": "Jeśli chcesz zmodyfikować lub usunąć swoje dane, skontaktuj się z naszym zespołem wsparcia pod adresem <mail>hello@vacationtracker.io</mail>",
  "general.enableUserEndDate": "Włącz datę końcową użytkownika",
  "general.enableUserEndDateInfo": "Po aktywacji, użytkownicy z datą końcową nie będą mogli składać wniosków urlopowych po tej dacie, a my automatycznie ich dezaktywujemy.",
  "general.enableUserEndDateTooltip": "Gdy Data Końcowa Użytkownika jest aktywowana, Użytkownicy nie będą mogli składać wniosków urlopowych na okres po ich dacie końcowej. Zostaną automatycznie dezaktywowani i nie będą mogli zalogować się do Vacation Tracker.",
  "general.googleConsent": "Logowanie Google",
  "general.googleConsentInfo": "Zgoda Google",
  "general.googleMorePermission": "Google zezwala na imiona, jeśli chcesz mieć pewność, że masz pełne imię i nazwisko, potrzebujemy więcej uprawnień, kliknij tutaj, aby dać nam więcej uprawnień",
  "general.hourlyLeaveAccounting": "Ustaw limity urlopowe w",
  "general.hourlyLeaveAccountingTooltip": "Po aktywacji, ta opcja śledzi czas trwania wszystkich urlopów i limitów w godzinach. Dostępne tylko w planie Complete.",
  "general.hours": "Godziny",
  "general.importUsersAutomatically": "Importuj użytkowników automatycznie",
  "general.importUsersAutomaticallyInfo": "Przy włączonym automatycznym imporcie użytkowników, będziemy automatycznie importować i aktywować wszystkich nowych regularnych użytkowników z {platform}.",
  "general.importUsersAutomaticallyInfoTooltip": "Na przykład, gdy zaprosisz nowego użytkownika do swojej przestrzeni roboczej, ten użytkownik będzie dostępny w Vacation Tracker kilka minut po zaproszeniu. Jeśli chcesz aktywować użytkownika gościnnego wielokanałowego, będziesz musiał zaprosić go ręcznie.",
  "general.importUsersAutomaticallyTurnOffInfo": "Nie będziemy już automatycznie importować i aktywować wszystkich nowych regularnych użytkowników z {platform}.",
  "general.importUsersConfigSection": "Konfiguracja importu użytkowników",
  "general.importUsersConfigSectionDescription": "Ta sekcja pozwala włączyć automatyczny import użytkowników z katalogu użytkowników i skonfigurować ustawienia importu użytkowników, takie jak wiadomość powitalna, domyślny dział i domyślna lokalizacja dla nowych użytkowników.",
  "general.pleaseEnterCompanyName": "Proszę wprowadzić nazwę firmy",
  "general.pleaseEnterContactEmail": "Proszę wprowadzić e-mail firmowy",
  "general.syncGoogleUsers": "Synchronizuj użytkowników Google",
  "general.syncGoogleUsersInfo": "Synchronizuj użytkowników Google automatyczne usuwanie, aktualizacja i dodawanie użytkowników",
  "general.updateInProgress": "Aktualizacja ustawień firmy",
  "general.updateSuccessTitle": "Ustawienia firmy zaktualizowane",
  "general.usefulLinks": "<healthcheck>Kontrola stanu</healthcheck>, <helpdesk>Centrum pomocy</helpdesk>",
  "general.usefulLinksTitle": "Przydatne linki: ",
  "holidays.filterByType": "Filtruj według typu święta",
  "holidays.holidaysName": "Nazwa święta",
  "holidays.holidaysTab": "Święta na rok {year}",
  "holidays.holidaysType": "Typ święta",
  "holidays.importHolidays": "Importuj święta",
  "holidays.importHolidaysAutomaticallyQuestion": "Czy chcesz automatycznie importować wybrane święta na przyszłe lata?",
  "holidays.importHolidaysAutomaticallyTooltip": "Vacation Tracker automatycznie dostosuje dni dla świąt publicznych, które nie przypadają na tę samą datę każdego roku, takich jak Święto Dziękczynienia, Wielkanoc itp.",
  "holidays.importHolidaysAutomaticallyTooltipForMissingHolidays": "Niestety, ta funkcja nie jest obecnie obsługiwana dla tego kraju. Pracujemy nad rozszerzeniem naszych usług, więc prosimy o cierpliwość i śledzenie aktualizacji.",
  "holidays.importHolidaysAutomaticallyTurnedOff": "Automatyczny import świąt wyłączony",
  "holidays.importHolidaysAutomaticallyTurnedOn": "Automatyczny import świąt włączony",
  "holidays.importHolidaysCount": "Importuj {count} wybranych świąt",
  "holidays.importHolidaysFor": "Importuj święta na rok <b>{year}</b> dla <b>{countryState}</b>",
  "holidays.importHolidaysSummary": "Wybrano następujące święta na rok <b>{year}</b> dla <b>{countryState}</b>",
  "holidays.pastHolidays": "Minione święta",
  "holidays.totalSelectedHolidays": "Wybrano {total} świąt",
  "holidays.totalSelectedHolidaysTip": "({total} niewidoczne z powodu zastosowanego filtra)",
  "holidays.typeBank": "Bankowe",
  "holidays.typeObservance": "Obchodzone",
  "holidays.typeOptional": "Opcjonalne",
  "holidays.typePublic": "Państwowe",
  "holidays.typeSchool": "Szkolne",
  "importLeaves.importFail": "Import nie powiódł się z następującym komunikatem błędu:",
  "importLeaves.importSuccess": "Przesłano urlopy do importu. Sprawdź za kilka minut, czy zostały pomyślnie zaimportowane.",
  "importUsers.allUsersImported.description": "Wygląda na to, że zaimportowałeś już wszystkich użytkowników z twojego katalogu! Powinieneś zobaczyć wszystkich użytkowników na stronie Użytkownicy. Jeśli kogoś nie widzisz, sprawdź zakładkę Nieaktywni. W razie pytań lub potrzeby pomocy, skontaktuj się z naszym wsparciem.",
  "importUsers.allUsersImported.goToUsersPageButton": "Przejdź do strony Użytkownicy",
  "importUsers.allUsersImported.title": "Wszyscy użytkownicy już zaimportowani!",
  "importUsers.form.backButton": "Powrót do strony Użytkownicy",
  "importUsers.form.searchGoogleUsersPlaceholder": "Wyszukaj użytkowników w swoim Google Workspace",
  "importUsers.form.searchMicrosoftUsersPlaceholder": "Wpisz co najmniej jeden znak, aby rozpocząć wyszukiwanie użytkowników w Microsoft 365 Active Directory",
  "importUsers.form.searchSlackUsersPlaceholder": "Wyszukaj użytkowników w swojej przestrzeni roboczej Slack",
  "importUsers.form.searchUsersNotFound": "Użytkownik nie znaleziony lub już zaimportowany",
  "importUsers.form.searchUsersPlaceholder": "Wyszukaj użytkowników w swoim Microsoft 365 Active Directory",
  "importUsers.form.selectedUsersLabel": "Wybrani użytkownicy ({selectedUsers})",
  "importUsers.form.showSelectedUsersAsNumber": "Wybrani użytkownicy",
  "importUsers.statistics.imported": "Użytkownicy zaimportowani do Vacation Tracker",
  "importUsers.statistics.total": "Łączna liczba użytkowników w twoim {directoryName}",
  "leaveRequest.halfDay": "Pół dnia",
  "leaveRequest.hourly": "Godzinowy",
  "leaveRequest.sent": "Wniosek urlopowy wysłany",
  "leaveRequest.simpleQuota.addAnotherLeave": "Dodaj kolejny urlop",
  "leaveRequest.simpleQuota.info": "Oto twój bilans urlopowy dla {leaveTypeName} na okres {start} - {end}.",
  "leaveRequest.simpleQuota.requestAnotherLeave": "Złóż kolejny wniosek urlopowy",
  "leaveRequest.simpleQuota.summaryInfo": "Wysłaliśmy następujący wniosek do zatwierdzenia przez twojego przełożonego",
  "leaveRequests.addedCurrentYear": "Dodano na bieżący rok",
  "leaveRequests.addeddNextYear": "Dodano na następny rok",
  "leaveRequests.cancelThisAction": "Anuluj tę akcję",
  "leaveRequests.dates": "Data{plural}",
  "leaveRequests.daysRemainingForCurrentYear": "Pozostałe dni na bieżący rok",
  "leaveRequests.daysRemainingForNextYear": "Pozostałe dni na następny rok",
  "leaveRequests.daysRequestedForCurrentYear": "Dni wykorzystane w bieżącym roku",
  "leaveRequests.daysRequestedForNextYear": "Dni wykorzystane w następnym roku",
  "leaveRequests.denyWithReason": "Odrzuć z podaniem powodu",
  "leaveRequests.hoursRemainingForCurrentYear": "Pozostałe godziny na bieżący rok",
  "leaveRequests.hoursRemainingForNextYear": "Pozostałe godziny na następny rok",
  "leaveRequests.hoursRequestedForCurrentYear": "Godziny wykorzystane w bieżącym roku",
  "leaveRequests.hoursRequestedForNextYear": "Godziny wykorzystane w następnym roku",
  "leaveRequests.leaveRequestInfoTitle": "<strong>{name}</strong> złożył(a) następujący wniosek o urlop:",
  "leaveRequests.leaveRequestInfoTitleToil": "<strong>{name}</strong> złożył(a) następujący wniosek o czas wolny w zamian za nadgodziny (TOIL):",
  "leaveRequests.leaveTypeName": "Typ urlopu",
  "leaveRequests.pending": "Oczekujący wniosek",
  "leaveRequests.reason": "Powód",
  "leaveRequests.reasonForDenying": "Powód odrzucenia",
  "leaveRequests.remainingCurrentYear": "Pozostało na bieżący rok",
  "leaveRequests.remainingNextYear": "Pozostało na następny rok",
  "leaveRequests.requestedCurrentYear": "Wykorzystano w bieżącym roku",
  "leaveRequests.requestedNextYear": "Wykorzystano w następnym roku",
  "leaveRequests.requestSummary": "Podsumowanie wniosku",
  "leaveRequests.wrongAction": "Ta akcja jest nam nieznana...🤔 Proszę spróbować innej! Zostaniesz przekierowany(a) do Panelu w ciągu 7 sekund.",
  "leaveRequests.wrongLeaveRequestId": "Ups! Nie można znaleźć tego wniosku o urlop! 🔎 Zostaniesz przekierowany(a) do Panelu w ciągu 7 sekund.",
  "leaveRequestStatus.approved": "zatwierdzony",
  "leaveRequestStatus.APPROVED": "zatwierdzony",
  "leaveRequestStatus.cancelled": "anulowany",
  "leaveRequestStatus.CANCELLED": "anulowany",
  "leaveRequestStatus.deleted": "usunięty",
  "leaveRequestStatus.DELETED": "usunięty",
  "leaveRequestStatus.denied": "odrzucony",
  "leaveRequestStatus.DENIED": "odrzucony",
  "leaveRequestStatus.open": "otwarty",
  "leaveRequestStatus.OPEN": "otwarty",
  "leaveTypes.activatingLeaveType": "Aktywuj typ urlopu",
  "leaveTypes.activatingLeaveTypeInfo": "Aktywując ten typ urlopu, przywracasz jego poprzednie umiejscowienie w lokalizacjach, a także jego wcześniejsze ustawienia i dane.",
  "leaveTypes.activatingLeaveTypeTitle": "Aktywacja typu urlopu",
  "leaveTypes.addLeaveTypeInfo": "Utwórz niestandardowy typ urlopu, dodając nazwę, kolor dla łatwiejszej nawigacji i status (Aktywny/Nieaktywny). Nie martw się, możesz później edytować wszystkie ustawienia typu urlopu. \n\nAby przypisać niestandardową politykę urlopową do typu urlopu, przejdź do Lokalizacje -> Przypisz politykę urlopową.",
  "leaveTypes.addTypeToLocationButton": "Dodaj",
  "leaveTypes.assignedToLocationsColumn": "Przypisane do lokalizacji",
  "leaveTypes.assignedToLocationsColumnWarning": "Ten rodzaj urlopu nie jest przypisany do żadnej lokalizacji.",
  "leaveTypes.color": "Kolor",
  "leaveTypes.createInProgress": "Tworzenie rodzaju urlopu {leaveTypeName}",
  "leaveTypes.createNewLeaveType": "Utwórz nowy rodzaj urlopu",
  "leaveTypes.deactivateLeaveType": "Dezaktywuj rodzaj urlopu",
  "leaveTypes.deactivateLeaveTypeInfo": "Dezaktywując ten rodzaj urlopu, usuwasz go ze wszystkich lokalizacji, które go obecnie używają. Po ponownej aktywacji rodzaju urlopu, pojawi się on w tych samych lokalizacjach z istniejącymi ustawieniami i danymi.",
  "leaveTypes.deactivateLeaveTypeTitle": "Dezaktywuj rodzaj urlopu",
  "leaveTypes.deleteInProgress": "Usuwanie rodzaju urlopu {leaveTypeName}",
  "leaveTypes.deleteLeaveType": "Usuń rodzaj urlopu",
  "leaveTypes.deleteLeaveTypeConfirm": "Czy na pewno chcesz usunąć rodzaj urlopu {name}?",
  "leaveTypes.deleteLeaveTypeTitle": "Usuń rodzaj urlopu",
  "leaveTypes.editLeaveType": "Edytuj rodzaj urlopu",
  "leaveTypes.isActive": "Aktywny",
  "leaveTypes.sort": "Sortuj",
  "leaveTypes.typeName": "Nazwa",
  "leaveTypes.updateInProgress": "Aktualizacja rodzaju urlopu {leaveTypeName}",
  "location.createInProgress": "Tworzenie lokalizacji {locationName}",
  "location.deleteInProgress": "Usuwanie lokalizacji {locationName}",
  "location.editLocation": "Edytuj lokalizację",
  "location.fiscalYearStart": "Początek roku fiskalnego",
  "location.general": "Ogólne",
  "location.hasNoUsers": "Lokalizacja nie ma użytkowników.",
  "location.holidays": "Święta",
  "location.holidays.autoimportTitle": "Zmieniono ustawienia automatycznego importu świąt",
  "location.holidays.brokenHolidays": "To święto nie może zostać dodane, skontaktuj się z pomocą techniczną, aby rozwiązać problem",
  "location.holidays.successTitle": "Święta zostały pomyślnie zaktualizowane",
  "location.holidays.updateInProgress": "Aktualizacja świąt",
  "location.holidaysUpdated": "Święta zaktualizowane",
  "location.leavePolicies": "Zasady urlopowe",
  "location.leavePoliciesNotExistInfo": "Obecnie nie ma dostępnych zasad urlopowych. Przypisz zasadę urlopową do lokalizacji.",
  "location.name": "Nazwa",
  "location.never": "Nigdy",
  "location.resetQuotas": "Reset limitu urlopowego na podstawie",
  "location.resetQuotasInfo": "To ustawienie określi, czy twój roczny limit urlopowy zostanie zresetowany na podstawie roku księgowego (rok fiskalny firmy) czy na podstawie daty rozpoczęcia pracy pracownika. Oprócz limitów, twoja polityka przenoszenia dni również będzie zależeć od tego ustawienia.",
  "location.rolloverExpiryAfterDays": "Wygaśnięcie przeniesionych dni",
  "location.rolloverExpiryAfterHours": "Wygaśnięcie przeniesionych godzin",
  "location.rolloverExpiryDay": "Czy przenoszone dni powinny wygasać?",
  "location.rolloverExpiryHour": "Czy przenoszone godziny powinny wygasać?",
  "location.rolloverExpiryOnDate": "Przenoszone dni wygasają dnia",
  "location.rolloverExpiryOnDate.extra": "Dni wygasną na koniec dnia.",
  "location.rolloverExpiryOnDateHours": "Data wygaśnięcia przeniesionych godzin",
  "location.rolloverExpiryOnDateHours.extra": "Godziny wygasną na koniec dnia.",
  "location.startYear": "Początek roku fiskalnego",
  "location.startYearInfo": "Rok fiskalny organizacji to rok liczony dla celów podatkowych lub księgowych. Daty resetowania okresów urlopowych zależą od początku i końca roku fiskalnego organizacji.",
  "location.timezone": "Strefa czasowa",
  "location.timezoneDefault": "(GMT+00:00) GMT (bez czasu letniego)",
  "location.updateInProgress": "Aktualizowanie lokalizacji {locationName}",
  "location.users": "Użytkownik(cy)",
  "location.workWeek": "Tydzień pracy",
  "locationForm.assignLeaveTypesInfo": "Po utworzeniu Lokalizacji, przypisz do niej Politykę Urlopową, aby umożliwić Użytkownikom wnioskowanie o urlop. \nAby przypisać Politykę Urlopową, przejdź do Lokalizacja -> Polityki Urlopowe -> Przypisz Politykę Urlopową.",
  "locationForm.createdSuccessfully": "Prosimy o chwilę cierpliwości, tworzymy Twoją Lokalizację.",
  "locationForm.createLocation": "Utwórz Lokalizację",
  "locationForm.editLocation": "Edytuj Lokalizację",
  "locationForm.locationLimitReached": "Ups! Wygląda na to, że osiągnąłeś limit {limitNumber} lokalizacji dla swojego obecnego planu. Aby zarządzać lub ulepszyć swój plan, odwiedź <billingPage>stronę Rozliczeń</billingPage>.",
  "locationForm.updateLocation": "Zaktualizuj Lokalizację",
  "locations.automaticallyGeneratedName": "Automatycznie wygenerowaliśmy tę lokalizację, grupując następujące zespoły: <t>{team}</t>.",
  "locations.automaticallyTransferredLeavePoliciesAndHolidays": "Polityki urlopowe i święta zostały automatycznie przeniesione, więc <b>{not}</b> musisz ich odtwarzać.",
  "locations.created": "Utworzono",
  "locations.createNewLocation": "Utwórz nową Lokalizację",
  "locations.default": "Domyślna",
  "locations.defaultLocation": "Lokalizacja domyślna",
  "locations.defaultLocationDescription": "Wszyscy nowi użytkownicy będą automatycznie dodawani do tej lokalizacji.",
  "locations.deleteLocation": "Usuń Lokalizację",
  "locations.deleteLocation.disabled": "Nie możesz usunąć domyślnej lokalizacji",
  "locations.deleteLocationConfirm": "Proszę potwierdź, że chcesz usunąć lokalizację {name}.",
  "locations.deleteLocationConfirmInitialRollover": "Usunięcie lokalizacji może wpłynąć na konfigurację użytkowników i informacje o urlopach (np. początkowe dni przeniesione).",
  "locations.deleteLocationTitle": "Usuń Lokalizację {name}",
  "locations.editLocation": "Edytuj",
  "locations.hasNoUsers": "Brak",
  "locations.holidays": "Święta",
  "locations.leavePolicies": "Polityki Urlopowe",
  "locations.leavePolicies.quotaDays": "{value} dni",
  "locations.leavePolicies.quotaHours": "{value} godzin",
  "locations.leavePolicies.yearlyQuota": "Roczny Limit Urlopowy",
  "locations.locationLimitReached": "Aktualnie wykorzystujesz wszystkie {limitNumber} dostępne sloty lokalizacji w planie CORE. Aby zarządzać lub ulepszyć swój plan, odwiedź <billingPage>stronę Rozliczeń</billingPage>.",
  "locations.name": "Nazwa",
  "locations.pleaseRenameLocation": "Proszę zmień nazwę tej lokalizacji!",
  "locations.pleaseRenameLocationName": "Proszę zmień automatycznie wygenerowane nazwy lokalizacji *",
  "locations.resetQuotas": "Reset Limitu Urlopowego Oparty Na",
  "locations.selectLocation": "Wybierz lokalizację",
  "locations.unableToNameThisLocation": "Jednak nasz system nie był w stanie poprawnie nazwać tej lokalizacji.",
  "locations.users": "Użytkownik(cy)",
  "locations.viewHolidays": "Zobacz święta",
  "locations.viewLeavePolicies": "Zobacz polityki urlopowe",
  "locations.viewLocation": "Zobacz",
  "locations.weAutomaticallyCreatedLocations": "Automatycznie utworzyliśmy lokalizacje dla Twojej organizacji, grupując zespoły według świąt. Wszystkie polityki urlopowe zostały przeniesione, musisz tylko zmienić nazwy lokalizacji.",
  "manageMicrosoftLicenses.assignLicencesSuccessTitle": "Licencje zostały pomyślnie przypisane.",
  "manageMicrosoftLicenses.weAreAssignLicenses": "Przypisujemy licencje.",
  "microsoft.adminConsentErrorButton": "Uzyskaj dostęp",
  "microsoft.adminConsentErrorDescription1": "Wygląda na to, że nie jesteś administratorem swojej organizacji Microsoft Teams. Konfiguracja powiadomień wymaga zgody administratora. Prosimy o przesłanie poniższego linku do administratora Twojej organizacji, aby uzyskać zgodę administratora: ",
  "microsoft.adminConsentErrorDescription2": "Po uzyskaniu zgody administratora kliknij poniższy przycisk, aby uzyskać dostęp do powiadomień. Jeśli jesteś administratorem, odwiedź ten sam link, udziel zgody administratora, a będziesz mógł skonfigurować powiadomienia.",
  "microsoft.adminConsentErrorTitle": "Wymagana zgoda administratora Microsoft Teams.",
  "microsoft.createCompanyTeamLoginAdminConsentError1": "Wygląda na to, że nie jesteś administratorem swojej organizacji Microsoft Teams. Importowanie członków zespołu z pojedynczego zespołu Microsoft Teams wymaga zgody administratora. Prosimy o przesłanie poniższego linku do administratora Twojej organizacji, aby uzyskać zgodę administratora: ",
  "microsoft.createCompanyTeamLoginAdminConsentError2": "Po uzyskaniu zgody administratora możesz kontynuować proces rejestracji. Jeśli jesteś administratorem, odwiedź ten sam link, udziel zgody administratora, a będziesz mógł kontynuować rejestrację.",
  "microsoft.createCompanyTeamLoginRequiredDescription1": "Potrzebujemy dodatkowego uprawnienia Microsoft Teams, aby wyświetlić listę użytkowników z wybranego zespołu. Jeśli klikniesz przycisk OK, przekierujemy Cię na stronę logowania Microsoft. W zależności od ustawień przeglądarki, możesz być już zalogowany i automatycznie przekierowany z powrotem.",
  "microsoft.createCompanyTeamLoginRequiredDescription2": "Po zalogowaniu się na konto Microsoft, przeniesiemy Cię z powrotem na tę stronę, abyś mógł wybrać użytkowników do importu. Kliknięcie przycisku Anuluj nadal pozwoli Ci zarejestrować się z całą organizacją.",
  "microsoft.createCompanyTeamLoginRequiredTitle": "Wymagane dodatkowe uprawnienia Microsoft Teams.",
  "microsoft.manageLicenses.loginMicrosoft": "Zaloguj się na swoje konto Microsoft 365, aby przypisać licencje.",
  "microsoft.manageLicenses.loginMicrosoftInfo": "Logowanie na konto wyświetli listę użytkowników w Twoim katalogu aktywnym Microsoft. Będziesz mógł przypisać zakupione licencje i opcjonalnie powiadomić zaimportowanych użytkowników.",
  "microsoft.notificationsLoginRequiredDescription1": "Zarządzanie powiadomieniami wymaga tokenu Microsoft Teams. Jeśli klikniesz przycisk OK, przekierujemy Cię na stronę logowania Microsoft. W zależności od ustawień przeglądarki, możesz być już zalogowany i automatycznie przekierowany z powrotem.",
  "microsoft.notificationsLoginRequiredDescription2": "Po zalogowaniu się na konto Microsoft, przeniesiemy Cię z powrotem na tę stronę, abyś mógł zarządzać powiadomieniami. Kliknięcie przycisku Anuluj przeniesie Cię z powrotem na ostatnio odwiedzoną stronę w naszym panelu.",
  "microsoft.notificationsLoginRequiredTitle": "Wymagane logowanie na konto Microsoft",
  "microsoft.universalLoginRequiredDescription": "Wygląda na to, że Twój token wygasł. Zaloguj się ponownie na swoje konto Microsoft Teams, aby kontynuować.",
  "microsoft.universalLoginRequiredTitle": "Token wygasł",
  "microsoft.usersLoginRequiredDescription1": "Zarządzanie użytkownikami wymaga tokenu Microsoft Teams. Jeśli klikniesz przycisk OK, przekierujemy Cię na stronę logowania Microsoft. W zależności od ustawień przeglądarki, możesz być już zalogowany i automatycznie przekierowany z powrotem.",
  "microsoft.usersLoginRequiredDescription2": "Po zalogowaniu się na konto Microsoft, przeniesiemy Cię z powrotem na tę stronę, abyś mógł zarządzać użytkownikami.",
  "microsoft.usersLoginRequiredTitle": "Wymagane logowanie na konto Microsoft",
  "microsoftSass.createCompany.alertError.companyAlreadyExistsDescription": "Twoja firma jest już zarejestrowana, ale Twoje konto nie jest aktywne. Czy używasz poprawnego adresu e-mail?\nTwój adres e-mail: {email}\nJeśli ten adres e-mail jest poprawny, Twoje konto nie zostało aktywowane. Skontaktuj się z administratorem Twojej organizacji Vacation Tracker:\n{admins}\nLub skontaktuj się z naszym wsparciem, jeśli chcesz zarejestrować się jako oddzielny, niezależny zespół w tej samej organizacji.\nJeśli Twój adres e-mail nie jest poprawny, zaloguj się używając innego konta Microsoft lub zaloguj się w oknie incognito przeglądarki.",
  "microsoftSass.createCompany.alertError.errorTitle": "Błąd",
  "microsoftSass.createCompany.alertError.goToAdminCenter": "Przejdź do Centrum administracyjnego",
  "microsoftSass.createCompany.alertError.goToConnectPage": "Przejdź do strony połączenia",
  "microsoftSass.createCompany.alertError.logInWithPlatform": "Zaloguj się za pomocą {platform}",
  "microsoftSass.createCompany.alertError.resubscribedDescription": "Zostałeś pomyślnie ponownie subskrybowany jako firma - {companyName}.\n\nKliknij przycisk, aby zostać przekierowanym na stronę logowania.",
  "microsoftSass.createCompany.alertError.resubscribedTitle": "Pomyślnie ponownie subskrybowano",
  "microsoftSass.createCompany.alertError.tokenExpiredDescription": "Twój token zakupu wygasł. Przejdź do Centrum administracyjnego Microsoft Teams i kliknij przycisk \"Skonfiguruj konto\", aby kontynuować.",
  "microsoftSass.createCompany.alertError.tokenExpiredTitle": "Token zakupu wygasł",
  "microsoftSass.createCompany.alertError.tokenInvalidDescription": "Twój token zakupu Microsoft jest nieprawidłowy. Jeśli zakupiłeś plan Vacation Tracker przez sklep aplikacji Microsoft Teams, przejdź do Centrum administracyjnego Microsoft Teams i kliknij przycisk \"Skonfiguruj konto\", aby kontynuować. W przeciwnym razie przejdź do naszej strony logowania, aby zalogować się lub utworzyć konto.",
  "microsoftSass.createCompany.alertError.tokenInvalidTitle": "Nieprawidłowy token zakupu",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountDescription": "Masz już konto. Twoja firma ({companyName}) zarejestrowała się używając {platform}. Kliknij poniższy przycisk, aby zalogować się za pomocą konta {platform}.\n\nNie martw się. Anulowaliśmy Twój zakup i nie zostaniesz obciążony za licencje, które właśnie kupiłeś. Stara subskrypcja dla Twojej firmy jest nadal aktywna. Możesz odwiedzić Centrum administracyjne Microsoft, aby sprawdzić, czy Twoja nowa subskrypcja została anulowana.\n\nJeśli chcesz celowo kupić inną subskrypcję, skontaktuj się z naszym wsparciem.",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountTitle": "Masz już konto",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicenses": "PRZYPISZ ZAKUPIONE LICENCJE",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicensesInfo": "Przypiszmy zakupione licencje! Masz {totalLicenses} licencji. Przypisz je do użytkowników, którzy będą mogli korzystać z Vacation Tracker.",
  "microsoftSass.createCompany.assignLicenses.buyMoreLicensesLater": "Możesz kupić więcej licencji później na stronie Użytkownicy w panelu Vacation Tracker.",
  "microsoftSass.createCompany.assignLicenses.loginMicrosoftDontWorry": "Nie martw się! Nie zaimportujemy ani nie powiadomimy nikogo bez Twojej zgody.",
  "microsoftSass.createCompany.completed": "Ukończono",
  "microsoftSass.createCompany.createCompanySetupInfo": "Ustawmy adres e-mail kontaktowy i domyślne ustawienia dla Twojej organizacji w Vacation Tracker.",
  "microsoftSass.createCompany.createCompanyTitle": "ZAKOŃCZ KONFIGURACJĘ FIRMY",
  "microsoftSass.createCompany.finishFristStep": "Proszę zakończyć konfigurację firmy, aby kontynuować.",
  "microsoftSass.createCompany.finishSecondStep": "Proszę przypisać licencje, aby kontynuować.",
  "microsoftSass.createCompany.finishSetup": "Zakończ konfigurację",
  "microsoftSass.createCompany.help": "Jeśli potrzebujesz pomocy, odwiedź nasze <helpdesk>Centrum pomocy</helpdesk> lub <support>skontaktuj się z naszym wsparciem</support>.",
  "microsoftSass.createCompany.importAllUsers": "Przypisano do wszystkich użytkowników",
  "microsoftSass.createCompany.letsCompleteSetup": "Dokończmy konfigurację, przypisz licencje do użytkowników i zainstaluj integrację Vacation Tracker dla Microsoft Teams.",
  "microsoftSass.createCompany.numberOfLicensesAssigned": "Przypisano {assignedLicenses} licencji",
  "microsoftSass.createCompany.setupMsBot.areYouAdmin": "Czy jesteś administratorem integracji Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLogin": "Wygląda na to, że nie przyznałeś dodatkowych dwóch uprawnień Microsoft Teams, których potrzebujemy do skonfigurowania bota i kart kanałów. Bez tego kroku nie będziemy mogli wysłać wiadomości powitalnej do wybranych członków zespołu i nie będziesz mógł korzystać z aplikacji Vacation Tracker z poziomu Microsoft Teams.",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLoginSkip": "Możesz pominąć ten krok i zainstalować bota i karty ręcznie. Sprawdź nasze Centrum pomocy, aby uzyskać szczegółowe instrukcje.",
  "microsoftSass.createCompany.setupMsBot.installBotAndTabs": "Zainstaluj bota i karty",
  "microsoftSass.createCompany.setupMsBot.installManuallyBotAdnTabs": "Zainstaluję bota i karty ręcznie",
  "microsoftSass.createCompany.setupMsBot.microsoftTeamsLogin": "Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.morePermissionInfo": "Potrzebujemy dodatkowych uprawnień, aby zainstalować chatbota i karty Vacation Tracker w Twoim Microsoft Teams. Przekierujemy Cię do ekranu uprawnień Microsoft po kliknięciu przycisku Kontynuuj poniżej.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyDescription": "Proszę zainstalować bota i karty Microsoft Teams Vacation Tracker ręcznie, postępując zgodnie z krokami opisanymi w <helpdesk>tym artykule Centrum pomocy</helpdesk>. Nie powinieneś potrzebować uprawnień administratora do ręcznej instalacji. Nie powinieneś potrzebować pomocy administratora przy ręcznej konfiguracji. Jeśli potrzebujesz pomocy, skontaktuj się z <support>naszym wsparciem</support>.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyTitle": "Musisz zainstalować integrację Microsoft ręcznie",
  "microsoftSass.createCompany.setupMsBot.notAdmin.weNeedAdminConsent": "Potrzebujemy zgody administratora, aby skonfigurować bota i karty. Nie możemy wysyłać wiadomości powitalnych, dopóki nie zainstalujesz bota.",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabs": "Wybierz kanał Microsoft Teams, w którym chcesz zainstalować karty",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabsPlaceholder": "Wybierz kanał Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBot": "Wybierz zespół Microsoft Teams, w którym chcesz zainstalować bota",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBotPlaceholder": "Wybierz zespół Microsoft Teams",
  "microsoftSass.createCompany.setupMsBotAndTabs": "SKONFIGURUJ BOTA I KARTY VACATION TRACKER",
  "microsoftSass.createCompany.setupMsBotAndTabsInfo": "Zainstalujmy bota i karty Vacation Tracker dla Microsoft Teams!",
  "microsoftSass.createCompany.stepAssignLicenses": "2. Przypisz licencje",
  "microsoftSass.createCompany.stepCreateComapny": "1. Zakończ konfigurację firmy",
  "microsoftSass.createCompany.stepSetupBotAndTabs": "3. Skonfiguruj bota i karty Microsoft Teams",
  "microsoftSass.createCompany.welcomeMessage": "Witaj w Vacation Tracker! Dziękujemy za zakup <bold>{totalLicenses} licencji</bold>.",
  "microsoftSass.createCompany.welcomeTitle": "SKONFIGURUJMY VACATION TRACKER",
  "microsoftSubscription.adminInitialContent": "Twoja subskrypcja wygasła. Przejdź do sklepu Microsoft AppSource, aby ponownie aktywować subskrypcję. Jeśli masz pytania lub potrzebujesz pomocy, skontaktuj się z naszym wsparciem.",
  "microsoftSubscription.goToAppSource": "Kup nową subskrypcję",
  "modal.dontHaveAnAccount.accountNotFound": "Nie znaleziono konta",
  "modal.dontHaveAnAccount.asCompany": "Jako firma",
  "modal.dontHaveAnAccount.asCompanyDescription": "Jesteś administratorem lub właścicielem firmy, który chce wypróbować Vacation Tracker.",
  "modal.dontHaveAnAccount.asEmployee": "Jako pracownik",
  "modal.dontHaveAnAccount.asEmployeeDescription": "Twoja firma korzysta z Vacation Tracker, ale nie masz do niego dostępu.",
  "modal.dontHaveAnAccount.employeeSignUp": "Rejestracja pracownika",
  "modal.dontHaveAnAccount.employeeSignupDescription": "Jeśli Twoja firma już korzysta z Vacation Tracker, nie musisz się rejestrować! Skontaktuj się z administratorem, aby Cię aktywować, a będziesz mieć pełny dostęp do panelu Vacation Tracker i integracji.",
  "modal.dontHaveAnAccount.employeeSignupSupport": "Jeśli potrzebujesz pomocy lub nie wiesz, kto jest Twoim administratorem, śmiało skontaktuj się z naszym wsparciem.",
  "modal.dontHaveAnAccount.introParagraph": "Wygląda na to, że nie masz konta. Jak chcesz korzystać z Vacation Tracker?",
  "modal.forgotPassword.step2Intro": "Wysłaliśmy kod na Twój adres e-mail. Proszę wprowadź go poniżej i ustaw nowe hasło. Jeśli nie otrzymałeś wiadomości e-mail w ciągu 5 minut, sprawdź folder spam i upewnij się, że podałeś poprawny adres e-mail.",
  "myProfile.cancel": "Anuluj",
  "myProfile.cancelLeave": "Anuluj",
  "myProfile.cancelLeaveConfirmTitle": "Anuluj urlop",
  "myProfile.dontHaveAnyHistory": "{name} nie wziął jeszcze żadnego urlopu.",
  "myProfile.edit.title": "Edytuj mój profil",
  "myProfile.editLeave": "Edytuj urlop",
  "myProfile.editLeaveWord": "Edytuj",
  "myProfile.general": "Ogólne",
  "myProfile.history": "Historia",
  "myProfile.historyLeaves": "Historia",
  "myProfile.leaveHistory": "Historia urlopów",
  "myProfile.leaveTypes": "Rodzaje urlopów",
  "myProfile.noUpcomingLeaves": "Brak zaplanowanych urlopów",
  "myProfile.openMyProfile": "Zobacz mój profil",
  "myProfile.upcomingLeaves": "Zaplanowane urlopy",
  "myProfile.userWorkWeek": "Tydzień pracy",
  "not_in_channel": "Wybrany kanał został usunięty lub nie jest już dostępny. Wybierz inny kanał, aby otrzymywać powiadomienia.",
  "notification.codeResent": "Kod weryfikacyjny wysłany ponownie",
  "notification.codeResentMessage": "Sprawdź swoją skrzynkę e-mail",
  "notification.passwordReset": "Hasło zostało pomyślnie zresetowane",
  "notification.passwordResetMessage": "Możesz teraz zalogować się używając nowego hasła.",
  "notifications.add": "Nowe powiadomienie",
  "notifications.cantOpenNoptification": "To powiadomienie zostało utworzone przez innego użytkownika i nie możesz go edytować.",
  "notifications.createDailyRequestSubmitted": "Ustawianie powiadomień codziennych",
  "notifications.createWeeklyRequestSubmitted": "Ustawianie powiadomień tygodniowych",
  "notifications.deleteAutomationConfirm": "Proszę potwierdź, że chcesz usunąć automatyzację <strong>{name}</strong>.",
  "notifications.deleteAutomationTitle": "Usuń automatyzację",
  "notifications.deleteDailyRequestSubmitted": "Usuwanie powiadomień codziennych",
  "notifications.deleteEntitlementByRoleAutomationConfirm": "Czy na pewno chcesz usunąć tę automatyzację uprawnień według roli? Nie można cofnąć tej akcji. Jednak usunięcie automatyzacji nie wpłynie na już przyznane dni {leaveTypeName}.",
  "notifications.deleteNotificationConfirm": "Proszę potwierdź, że chcesz usunąć powiadomienie <strong>{name}</strong>.",
  "notifications.deleteNotificationOk": "Usuń",
  "notifications.deleteNotificationTitle": "Usuń powiadomienie",
  "notifications.deleteSeniorityEntitlementAutomationConfirm": "Czy na pewno chcesz usunąć tę automatyzację uprawnień stażowych? Nie można cofnąć tej akcji. Jednak usunięcie automatyzacji nie wpłynie na już przyznane dni {leaveTypeName}.",
  "notifications.deleteWeeklyRequestSubmitted": "Usuwanie cotygodniowych powiadomień",
  "notifications.emptyViewButton": "Utwórz powiadomienie dzienne lub tygodniowe",
  "notifications.emptyViewMessage": "wysyłając automatyczne powiadomienia o nadchodzących urlopach",
  "notifications.emptyViewTitle": "Zwiększ widoczność i informuj swój zespół",
  "notifications.emptyViewVideoLink": "Aby uzyskać więcej informacji, odwiedź nasze <link>Centrum pomocy</link> lub obejrzyj poniższy film:",
  "notifications.error": "Jeśli problem będzie się powtarzał, skontaktuj się z pomocą techniczną i podaj następujący kod błędu: {correlationId}.",
  "notifications.frequency": "Częstotliwość",
  "notifications.handleSubmitErrorTitle": "Błąd powiadomienia",
  "notifications.microsoftTokenMissing": "Nie możemy ustawić powiadomień, ponieważ brakuje tokenu Microsoft. Wyloguj się i zaloguj ponownie, aby otrzymać token Microsoft, a następnie spróbuj ponownie.",
  "notifications.notificationInfo": "Jeśli chcesz skonfigurować powiadomienia dla konkretnego działu, przejdź do strony Działy i edytuj dział, dla którego chcesz ustawić powiadomienia.",
  "notifications.notificationSendNow": "Twoje powiadomienie jest w drodze! 🚀",
  "notifications.notificationSendNowFailed": "Nieudane dostarczenie",
  "notifications.notificationSendNowFailedInfo": "O nie! 😕 Coś poszło nie tak. ",
  "notifications.notificationTitle": "Powiadomienia",
  "notifications.passwordChangedDescription": "Pomyślnie zmieniłeś hasło, zaloguj się",
  "notifications.passwordChangedTitle": "Hasło zostało zmienione",
  "notifications.sendNotificationNowCofirmModalDesc": "Powiadomienie <strong>{name}</strong> nie jest aktywne. Potwierdź, że chcesz je wysłać teraz mimo to.",
  "notifications.sendNotificationNowCofirmModalTitle": "Wyślij powiadomienie",
  "notifications.sendNotificationNowOk": "Wyślij",
  "notifications.table.actions": "Akcje",
  "notifications.table.active": "Aktywne",
  "notifications.table.DAILY": "Codziennie",
  "notifications.table.frequency": "Częstotliwość",
  "notifications.table.name": "Nazwa",
  "notifications.table.owner": "Właściciel",
  "notifications.table.scheduledTime": "Zaplanowany czas",
  "notifications.table.sendNotificationNow": "Wyślij teraz",
  "notifications.table.WEEKLY": "Tygodniowo",
  "notifications.title": "Powiadomienia",
  "notifications.tooltipInfo": "Wszystkie Twoje powiadomienia są zebrane na tej stronie dla łatwiejszego zarządzania. Aby dowiedzieć się więcej, zapoznaj się z naszym <helpDesk>artykułem w Centrum pomocy.</helpDesk>",
  "notifications.update": "Aktualizuj powiadomienie",
  "notifications.updateAutomationConfirm": "Wyłączenie automatyzacji nie anuluje subskrypcji. Jeśli chcesz wyłączyć subskrypcję, musisz uzyskać dostęp do listy automatyzacji, klikając \"Utwórz automatyzację\", a następnie kliknąć przycisk \"Anuluj subskrypcję\"",
  "notifications.updateAutomationTitle": "Aktualizuj automatyzację",
  "notifications.updateNotification": "Aktualizuj",
  "notifications.weekStartsOn": "Tydzień zaczyna się w",
  "notificationsForm.advancedFilter.tooltipInfo": "Jeśli nie wybrano żadnych filtrów, powiadomienie będzie obejmować wszystkich użytkowników.",
  "notificationsForm.createInProgress": "Tworzenie powiadomienia {notificationName}",
  "notificationsForm.currentWeek": "Bieżący tydzień",
  "notificationsForm.DAILY": "Codziennie",
  "notificationsForm.deleteFailed": "Usunięcie nie powiodło się",
  "notificationsForm.deleteInProgress": "Usuwanie powiadomienia {notificationName}",
  "notificationsForm.frequency": "Częstotliwość",
  "notificationsForm.groupBy": "Grupuj urlopy według",
  "notificationsForm.groupBy.tooltip": "Urlopy mogą być wyświetlane zgodnie z Twoimi preferencjami. Mogą być sortowane według Lokalizacji Użytkowników, Działu lub Alfabetycznie (Brak).",
  "notificationsForm.locale": "Język",
  "notificationsForm.LOCATION": "Lokalizacja",
  "notificationsForm.name": "Nazwa powiadomienia",
  "notificationsForm.newNotificationTitle": "Nowe powiadomienie",
  "notificationsForm.nextWeek": "Następny tydzień",
  "notificationsForm.NONE": "Brak",
  "notificationsForm.periodCovered": "Obejmowany okres",
  "notificationsForm.saveFailed": "Zapisywanie nie powiodło się",
  "notificationsForm.selectChannel": "Wybierz kanał",
  "notificationsForm.sendEmpty": "Jeśli nie ma nadchodzących urlopów",
  "notificationsForm.sendEmptyNo": "Nie, nie wysyłaj powiadomienia",
  "notificationsForm.sendEmptyYes": "Tak, wyślij powiadomienie",
  "notificationsForm.sendOnDays": "Wyślij w",
  "notificationsForm.sendOnDaysInfo": "Powiadomienie zostanie wysłane w wybrane dni.",
  "notificationsForm.submit": "Zapisz powiadomienie",
  "notificationsForm.TEAM": "Dział",
  "notificationsForm.time": "Czas",
  "notificationsForm.timezone": "Strefa czasowa",
  "notificationsForm.update": "Aktualizuj powiadomienie",
  "notificationsForm.updateInProgress": "Aktualizowanie powiadomienia {notificationName}",
  "notificationsForm.updateNotificationTitle": "Aktualizuj powiadomienie",
  "notificationsForm.WEEKLY": "Tygodniowo",
  "notificationsForm.weekStartsOn": "Tydzień zaczyna się od",
  "overlapInfo.moreUsersAreOnLeaves": "ℹ️ Wygląda na to, że {overlapperNames} będą na urlopie w tym samym okresie.",
  "overlapInfo.youCanSafelyAcceptTheRequest": "Możesz bezpiecznie zaakceptować prośbę, nikt inny z {teamName} nie jest na urlopie w tym okresie.",
  "overlapInfo.youCanSafelyAcceptTheRequestAutoApproved": "Nikt inny z {teamName} nie jest na urlopie w tym okresie.",
  "page404.message": "Strona, której szukasz, korzysta z zasłużonego odpoczynku.",
  "page404.title": "Strona na urlopie.",
  "quickTour.admin.buttonTitle": "Szybki przewodnik",
  "quickTour.buttons.back": "Wstecz",
  "quickTour.buttons.close": "Zamknij",
  "quickTour.buttons.last": "Zamknij",
  "quickTour.buttons.next": "Dalej",
  "quickTour.buttons.skip": "Pomiń",
  "registration.flowB.progress": "Krok {current} z {total}",
  "registration.flowB.step1.list1": "<b>Darmowy 7-dniowy okres próbny</b>, wszystkie funkcje w cenie",
  "registration.flowB.step1.list2": "Karta kredytowa nie jest wymagana podczas okresu próbnego",
  "registration.flowB.step1.list3": "Integracja z Twoim narzędziem do współpracy",
  "registration.flowB.step1.submitButton": "Rozpocznij",
  "registration.flowB.step1.subtitle": "Jaki jest adres e-mail Twojej firmy?",
  "registration.flowB.step1.title": "Dołącz do tysięcy zespołów na całym świecie, które usprawniają śledzenie urlopów z Vacation Tracker.",
  "registration.flowB.step2.submitButton": "Utwórz konto",
  "registration.flowB.step2.subtitle": "Witaj w Vacation Tracker",
  "registration.flowB.step3.continueWithEmailPlatform": "Kontynuuj z służbowym e-mailem",
  "registration.flowB.step3.continueWithPlatform": "Kontynuuj z {platform}",
  "registration.flowB.step3.list1": "Zarządzaj wnioskami urlopowymi",
  "registration.flowB.step3.list10": "i wiele więcej!",
  "registration.flowB.step3.list2": "Wysyłaj powiadomienia o urlopach",
  "registration.flowB.step3.list3": "Dostosowuj typy urlopów",
  "registration.flowB.step3.list4": "Twórz lokalizacje i działy",
  "registration.flowB.step3.list5": "Generuj raporty",
  "registration.flowB.step3.list6": "Ustawiaj naliczanie urlopów",
  "registration.flowB.step3.list7": "Grupuj członków zespołu według etykiet",
  "registration.flowB.step3.list8": "Planuj raporty",
  "registration.flowB.step3.list9": "Wnioskuj o urlop godzinowy",
  "registration.flowB.step3.notice": "Nie będziemy komunikować się z Twoim zespołem w żaden sposób, dopóki nie będziesz gotowy.",
  "registration.flowB.step3.submitButton": "OSTATNI KROK",
  "registration.flowB.step3.subtitle": "Z jakim narzędziem chciałbyś się zintegrować, {name}?",
  "registration.flowB.step3.title": "7-dniowy okres próbny pełnego planu",
  "registration.flowB.step3.title2": "Otrzymasz <b>darmowy okres próbny</b> naszego <b>Pełnego planu</b>, który obejmuje:",
  "registration.flowB.testimonial1.profession": "Producent",
  "registration.flowB.testimonial1.title": "\"Łatwość, z jaką wszyscy przeszli na korzystanie z Vacation Tracker bez zadawania miliona pytań, była imponująca!\"",
  "registration.flowB.testimonial2.profession": "CEO",
  "registration.flowB.testimonial2.title": "\"Bardzo proste w konfiguracji, sprawia, że wnioskowanie o dni wolne i ich zatwierdzanie jest super proste, a zespół jest bardzo responsywny i poważnie traktuje opinie.\"",
  "registration.flowB.testimonial3.profession": "Dyrektor Usług Administracyjnych",
  "registration.flowB.testimonial3.title": "\"Mogę teraz skupić się na moim personelu i wielu innych rzeczach - Vacation Tracker zaoszczędził mi co najmniej 10 godzin tygodniowo plus wiele godzin naszym menedżerom i pracownikom, ponieważ wszystko jest elektroniczne.\"",
  "requestLeave.handleSubmitErrorTitle": "Błąd przesyłania wniosku urlopowego",
  "requestLeave.inProgress": "Wnioskowanie o urlop",
  "requestLeave.requestLeave": "Wniosek o urlop",
  "requestLeave.title": "Wniosek o urlop złożony",
  "scheduledReportForm.addNewEmail": "Dodaj odbiorcę",
  "scheduledReportForm.approved": "Zatwierdzony",
  "scheduledReportForm.createAScheduledReport": "Nowy raport",
  "scheduledReportForm.createInProgress": "Tworzenie zaplanowanego raportu {scheduledReportName}",
  "scheduledReportForm.deleteFailed": "Usunięcie nie powiodło się",
  "scheduledReportForm.deleteInProgress": "Usuwanie zaplanowanego raportu {scheduledReportName}",
  "scheduledReportForm.deleteReport": "Usuń raport",
  "scheduledReportForm.deleteScheduledReport": "Usuń zaplanowany raport",
  "scheduledReportForm.deleteScheduledReportConfirm": "Proszę potwierdzić, że chcesz usunąć zaplanowany raport {name}.",
  "scheduledReportForm.deleteScheduledReportTitle": "Usuń zaplanowany raport {name}",
  "scheduledReportForm.denied": "Odrzucony",
  "scheduledReportForm.emails": "Odbiorcy",
  "scheduledReportForm.lastMonth": "Ostatni miesiąc",
  "scheduledReportForm.leaveBalanceReport": "Raport salda urlopowego",
  "scheduledReportForm.leaveHistoryReport": "Raport historii urlopów",
  "scheduledReportForm.leaveStatus": "Status urlopu",
  "scheduledReportForm.locations": "Lokalizacja(e)",
  "scheduledReportForm.monthlyLeaveBalanceReport": "Miesięczny raport salda urlopowego",
  "scheduledReportForm.period": "Okres raportowania",
  "scheduledReportForm.periodInfo": "Ostatni miesiąc ",
  "scheduledReportForm.periodInfoTootltip": "Raport jest obecnie ograniczony do poprzedniego miesiąca. Aby uzyskać więcej opcji, wyślij e-mail na adres hello@vacationtracker.io, a my zanotujemy Twoją prośbę o możliwe przyszłe ulepszenia.",
  "scheduledReportForm.pleaseInputEmailOrDeleteThisField": "Proszę wprowadzić prawidłowy adres e-mail lub usunąć to pole.",
  "scheduledReportForm.pleaseInputValidEmail": "Proszę wprowadzić prawidłowy adres e-mail.",
  "scheduledReportForm.reportName": "Nazwa",
  "scheduledReportForm.reportSummary": "Podsumowanie raportu",
  "scheduledReportForm.reportSummaryInfo": "Okres raportowania: {from} - {to}\nTyp raportu: {reportType}\nNastępny raport zostanie wysłany {dateReport} o {timeReport}",
  "scheduledReportForm.reportSummaryInfoMonthlyBalance": "Okres raportowania: Następne 12 miesięcy. \nTyp raportu: {reportType}\nNastępny raport zostanie wysłany {dateReport} o {timeReport}",
  "scheduledReportForm.reportType": "Typ raportu",
  "scheduledReportForm.required": "To pole jest wymagane.",
  "scheduledReportForm.saveFailed": "Zapisywanie nie powiodło się",
  "scheduledReportForm.saveReport": "Zaplanuj raport",
  "scheduledReportForm.sendDayOfMonth": "Data/Dzień dostawy",
  "scheduledReportForm.sendDayOfMonthInfo": "Skonfiguruj preferencje dostarczania raportów, określając dokładny dzień, w którym raport zostanie wysłany do przypisanych odbiorców.",
  "scheduledReportForm.sendHourAndMinute": "Czas dostawy",
  "scheduledReportForm.sendHourAndMinuteInfo": "Skonfiguruj preferencje dostarczania raportów, określając dokładny czas dnia, w którym raport zostanie wysłany do przypisanych odbiorców.",
  "scheduledReportForm.sendReportFailed": "Dostarczenie nie powiodło się",
  "scheduledReportForm.sendReportFailedInfo": "O nie! 😕 Coś poszło nie tak. ",
  "scheduledReportForm.sendReportNow": "Wyślij teraz",
  "scheduledReportForm.sendReportSuccessfully": "Twój raport jest w drodze! 🚀",
  "scheduledReportForm.teams": "Dział(y)",
  "scheduledReportForm.timezone": "Strefa czasowa",
  "scheduledReportForm.updateAScheduledReport": "Aktualizuj raport",
  "scheduledReportForm.updateInProgress": "Aktualizowanie zaplanowanego raportu {scheduledReportName}",
  "scheduledReportForm.updateReport": "Aktualizuj raport",
  "scheduledReports.add": "Nowy raport",
  "scheduledReports.allLocations": "Wszystkie lokalizacje",
  "scheduledReports.allTeams": "Wszystkie działy",
  "scheduledReports.createScheduledReports": "Nowy raport",
  "scheduledReports.deleteFailed": "Usunięcie nie powiodło się",
  "scheduledReports.deleteInProgress": "Usuwanie zaplanowanego raportu {scheduledReportName}",
  "scheduledReports.deleteReportConfirm": "Usunąć raport {name}?",
  "scheduledReports.deleteReportOkText": "Usuń",
  "scheduledReports.deleteReportTitle": "Potwierdź usunięcie",
  "scheduledReports.deliveryDate": "Data dostarczenia",
  "scheduledReports.edit": "Edytuj",
  "scheduledReports.lastMonth": "Ostatni miesiąc",
  "scheduledReports.leaveBalanceReport": "Raport salda urlopowego",
  "scheduledReports.leaveHistoryReport": "Raport historii urlopów",
  "scheduledReports.locations": "Lokalizacja(e)",
  "scheduledReports.monthlyLeaveBalanceReport": "Miesięczny raport salda urlopowego",
  "scheduledReports.name": "Nazwa",
  "scheduledReports.period": "Okres raportowania",
  "scheduledReports.receivers": "Odbiorcy",
  "scheduledReports.reportSentSuccessfully": "Twój raport jest w drodze! 🚀",
  "scheduledReports.reportType": "Typ raportu",
  "scheduledReports.sendReportFailed": "Dostarczenie nie powiodło się",
  "scheduledReports.sendReportFailedInfo": "O nie! 😕 Coś poszło nie tak. ",
  "scheduledReports.sendReportNow": "Wyślij teraz",
  "scheduledReports.sendReportSuccessfully": "Twój raport jest w drodze! 🚀",
  "scheduledReports.teams": "Dział(y)",
  "sidebar.addLeave": "Dodaj urlop",
  "sidebar.addons": "Dodatki",
  "sidebar.billing": "Rozliczenia",
  "sidebar.calendar": "Kalendarz",
  "sidebar.changelog": "Dziennik zmian",
  "sidebar.dashboard": "Panel główny",
  "sidebar.export": "Eksport",
  "sidebar.faq": "Centrum pomocy",
  "sidebar.general": "Ogólne",
  "sidebar.holidays": "Święta",
  "sidebar.leaves": "Wnioski urlopowe",
  "sidebar.leaveTypes": "Typy urlopów",
  "sidebar.location": "Lokalizacja",
  "sidebar.locations": "Lokalizacje",
  "sidebar.main": "Główne",
  "sidebar.myProfile": "Mój profil",
  "sidebar.notifications": "Powiadomienia",
  "sidebar.onboarding": "Wdrażanie",
  "sidebar.reports": "Raporty",
  "sidebar.requestLeave": "Złóż wniosek o urlop",
  "sidebar.scheduledReports": "Zaplanowane raporty",
  "sidebar.settings": "Ustawienia",
  "sidebar.teams": "Działy",
  "sidebar.user": "Użytkownik",
  "sidebar.users": "Użytkownicy",
  "signInAsUser.codeIsRequired": "Kod jest wymagany",
  "signInAsUser.email": "Wprowadź swój adres e-mail",
  "signInAsUser.emailIsRequired": "Prawidłowy adres e-mail jest wymagany",
  "signInAsUser.enterTheCode": "Wprowadź kod",
  "signInAsUser.userId": "Wprowadź ID użytkownika",
  "signInAsUser.userIdIsRequired": "ID użytkownika jest wymagane",
  "slack.notificationsLoginRequiredDescription1": "Zarządzanie powiadomieniami wymaga uprawnień Slack. Po kliknięciu przycisku OK zostaniesz przekierowany na stronę logowania Slack. W zależności od ustawień przeglądarki, możesz być już zalogowany i automatycznie przekierowany z powrotem.",
  "slack.notificationsLoginRequiredDescription2": "Po zalogowaniu się na konto Slack, wrócimy na tę stronę, abyś mógł zarządzać powiadomieniami. Kliknięcie przycisku Anuluj przeniesie Cię z powrotem na ostatnią odwiedzoną stronę na naszym panelu.",
  "slack.notificationsLoginRequiredTitle": "Wymagane jest zalogowanie do konta Slack",
  "slack.usersLoginRequiredDescription1": "Zarządzanie użytkownikami wymaga uprawnień Slack. Po kliknięciu przycisku OK zostaniesz przekierowany na stronę logowania Slack. W zależności od ustawień przeglądarki, możesz być już zalogowany i automatycznie przekierowany z powrotem.",
  "slack.usersLoginRequiredDescription2": "Po zalogowaniu się na konto Slack, wrócimy na tę stronę, abyś mógł zarządzać użytkownikami.",
  "slack.usersLoginRequiredTitle": "Wymagane jest zalogowanie do konta Slack",
  "stripe.dynamicMessage": "{message}",
  "subscription.annual.info": "Aktywujesz plan {plan} {period} - ${price}",
  "subscription.hidePlansButton": "Ukryj plany",
  "subscription.logout": "Wyloguj",
  "subscription.monthly.info": "Aktywujesz plan {plan} {period} - ${usersPrice} ({noOfActiveUsers} użytkowników x {dollarValue})",
  "subscription.monthly.infoUnderUserPLanLimit": "Aktywujesz plan {plan} {period} - ${usersPrice}",
  "subscription.showPlansButton": "Pokaż plany",
  "subscription.stripeCustomerPortalLink": "Kliknij tutaj, aby przejść do portalu klienta",
  "subscription.subscribe": "Subskrybuj",
  "subscription.title": "Subskrypcja",
  "subscription.updateInProgress": "Próba płatności",
  "subscriptions.switchPlanTitle": "Przełącz na plan <strong>{plan}</strong>",
  "surveyAboutUs.description": "Czy możesz nam powiedzieć, jak się o nas dowiedziałeś? Chcielibyśmy dowiedzieć się więcej o Twojej drodze do Vacation Tracker. To opcjonalne, ale bardzo nam pomoże. Dziękujemy!",
  "surveyAboutUs.OptionQuestion1-1": "Wyszukiwarka Google",
  "surveyAboutUs.OptionQuestion1-1-1": "Czego szukałeś w Google (np. \"śledzenie urlopów\")?",
  "surveyAboutUs.OptionQuestion1-2": "Sklep z aplikacjami (Slack - Katalog aplikacji, MS Teams - App Source, itp...)",
  "surveyAboutUs.OptionQuestion1-3": "Media społecznościowe, platforma z recenzjami lub inna strona internetowa",
  "surveyAboutUs.OptionQuestion1-3-1": "Która platforma lub strona?",
  "surveyAboutUs.OptionQuestion1-4": "Rekomendacja",
  "surveyAboutUs.OptionQuestion1-4-1": "Świetnie! Kto nas polecił?",
  "surveyAboutUs.OptionQuestion1-4-1-1": "Kolega lub przyjaciel",
  "surveyAboutUs.OptionQuestion1-4-1-2": "Inna firma to zasugerowała",
  "surveyAboutUs.OptionQuestion1-4-1-3": "Korzystałem/am z tego w przeszłości",
  "surveyAboutUs.OptionQuestion1-5": "Inne",
  "surveyAboutUs.Question1": "Jak dowiedziałeś/aś się o Vacation Tracker?",
  "surveyAboutUs.textarea": "Proszę sprecyzować",
  "surveyAboutUs.title": "Jak dowiedziałeś/aś się o Vacation Tracker?",
  "team.approvers": "Zatwierdzający",
  "team.createInProgress": "Tworzenie działu {teamName}",
  "team.editTeam": "Edytuj Dział",
  "team.hasNoUsers": "Dział nie ma użytkowników.",
  "team.name": "Nazwa",
  "team.team": "Dział",
  "team.updateInProgress": "Aktualizacja działu {teamName}",
  "team.users": "Użytkownik(cy)",
  "teamForm.createTeam": "Utwórz Dział",
  "teamForm.createTeamError": "O nie! 😕 Coś poszło nie tak.",
  "teamForm.editTeam": "Edytuj Dział",
  "teamForm.name": "Nazwa",
  "teamForm.pleaseEnterTeamName": "Wprowadź nazwę działu",
  "teamForm.setAsSubdepartment": "Wybierz dział nadrzędny",
  "teamForm.teamLimitReached": "Ups! Osiągnąłeś limit {limitNumber} działów w swoim obecnym planie. Aby zarządzać lub ulepszyć swój plan, odwiedź <billingPage>stronę rozliczeń</billingPage>.",
  "teamForm.updateTeam": "Zaktualizuj Dział",
  "teams.approvers": "Zatwierdzający",
  "teams.created": "Utworzono",
  "teams.createNewTeam": "Utwórz Nowy Dział",
  "teams.defaultTeam": "Wszyscy nowi użytkownicy będą automatycznie dodawani do tego działu.",
  "teams.deleteInProgress": "Usuwanie działu {teamName}",
  "teams.deleteTeam": "Usuń",
  "teams.deleteTeamConfirm": "Czy na pewno chcesz usunąć dział {name}?",
  "teams.deleteTeamTitle": "Usuń Dział",
  "teams.editTeam": "Edytuj Dział",
  "teams.handleDeleteError": "Błąd usuwania",
  "teams.handleDeleteErrorTitle": "Błąd usuwania działu",
  "teams.name": "Nazwa",
  "teams.removeUserInfo": "Nie możesz usunąć użytkownika z domyślnego działu. Jeśli chcesz przenieść użytkownika do innego działu, możesz edytować inny dział i dodać tam użytkownika.",
  "teams.teamsLimitReached": "Obecnie wykorzystujesz wszystkie {limitNumber} dostępne sloty działów w planie CORE. Aby zarządzać lub ulepszyć swój plan, odwiedź <billingPage>stronę rozliczeń</billingPage>.",
  "teams.users": "Użytkownik(cy)",
  "teams.viewTeam": "Wyświetl Dział",
  "user.accrualsCalculator": "Oblicz",
  "user.accrualsCalculatorInfo": "Ile dni urlopu będę mieć naliczone do konkretnej daty?",
  "user.addLeave": "Dodaj urlop",
  "user.approve": "Zatwierdź",
  "user.approveInProgress": "Zatwierdzanie wniosku urlopowego",
  "user.approveLeaveText": "Proszę potwierdzić, że chcesz zatwierdzić wniosek urlopowy.",
  "user.approveLeaveTitle": "Zatwierdź wniosek urlopowy",
  "user.approveSuccessTitle": "Wniosek urlopowy zatwierdzony",
  "user.bulkChangeStatusInProgress": "Aktualizowanie statusów użytkowników.",
  "user.bulkChangeStatusSuccessTitle": "Statusy użytkowników zostały zaktualizowane.",
  "user.cancelLeave": "Anuluj urlop",
  "user.cancelLeave.buttonLink": "Anuluj",
  "user.cancelLeaveConfirmTitle": "Anuluj urlop",
  "user.cancelLeaveRequestInProgress": "Anulowanie wniosku urlopowego",
  "user.cancelLeaveRequestTitle": "Wniosek urlopowy został anulowany",
  "user.currentLeaves": "Aktualne urlopy",
  "user.deleteLeave": "Usuń urlop",
  "user.deleteLeave.buttonLabel": "Usuń",
  "user.deleteLeaveConfirmText": "Proszę potwierdzić, że chcesz usunąć urlop.",
  "user.deleteLeaveConfirmTitle": "Usuń urlop",
  "user.deleteLeaveInProgress": "Usuwanie urlopu",
  "user.deleteLeaveSuccessTitle": "Urlop został usunięty",
  "user.deleteToil": "Usuń TOIL",
  "user.deleteToilConfirmText": "Proszę potwierdzić, że chcesz usunąć TOIL.",
  "user.deny": "Odrzuć",
  "user.denyInProgress": "Odrzucanie wniosku urlopowego",
  "user.denyLeaveConfirm": "Proszę potwierdzić, że chcesz odrzucić wniosek urlopowy.",
  "user.denyLeaveTitle": "Odrzuć wniosek urlopowy",
  "user.denySuccessTitle": "Wniosek urlopowy odrzucony",
  "user.denyWithReason": "Odrzuć z podaniem powodu",
  "user.dontHaveAnyHistory": "{name} nie wykorzystał jeszcze żadnego urlopu.",
  "user.editLeave": "Edytuj urlop",
  "user.editLeave.buttonLabel": "Edytuj",
  "user.editLeaveWord": "Edytuj",
  "user.editUser": "Edytuj",
  "user.editWorkWeek": "Edytuj",
  "user.employeeEndDate": "Data zakończenia zatrudnienia",
  "user.employeeId": "ID pracownika",
  "user.employeeSince": "Pracownik od",
  "user.general": "Ogólne",
  "user.history": "Historia",
  "user.historyLeaves": "Historia",
  "user.invitationDeletedTitle": "Zaproszenie usunięte",
  "user.invitationSuccessTitle": "Zaproszenia wysłane",
  "user.leaves": "Urlopy",
  "user.leaveTypes": "Typy urlopów",
  "user.location": "Lokalizacja",
  "user.location.moveInProgress": "Przenoszenie użytkownika do lokalizacji {name}",
  "user.location.moveSuccessTitle": "Użytkownik został przeniesiony do lokalizacji {name}",
  "user.logs": "Logi",
  "user.noUpcomingLeaves": "Brak zaplanowanych urlopów",
  "user.pendingLeaves": "Oczekujące wnioski urlopowe",
  "user.probationPeriodDescription": "Użytkownik jest na okresie próbnym według polityki <probationPeriodName></probationPeriodName> dla <leaveTypeNames></leaveTypeNames>, trwającym <lengthDays></lengthDays> dni, do <date></date>.",
  "user.profile": "Profil",
  "user.resend": "Wyślij ponownie wniosek",
  "user.resending": "Ponowne wysyłanie wniosku urlopowego...",
  "user.resent": "Wniosek wysłany ponownie",
  "user.role": "Rola",
  "user.signUp": "Zarejestruj się",
  "user.signup.intro": "{invitedByName} zaprosił Cię do dołączenia do organizacji Vacation Tracker. Zarejestruj się poniżej, aby zaakceptować zaproszenie i uzyskać dostęp do panelu Vacation Tracker.",
  "user.signup.introHelpdesk": "Jeśli masz pytania lub potrzebujesz pomocy, skontaktuj się z naszym wsparciem lub odwiedź <helpdesk>Centrum Pomocy</helpdesk>.",
  "user.status": "Status",
  "user.status.updateInProgress": "Aktualizacja statusu użytkownika.",
  "user.status.updateSuccessTitle": "Status użytkownika został zaktualizowany.",
  "user.statusReason": "Dodaj powód",
  "user.team.moveInProgress": "Przenoszenie użytkownika do działu {name}",
  "user.team.moveSuccessTitle": "Użytkownik został przeniesiony do działu {name}",
  "user.upcomingLeaves": "Zaplanowane urlopy",
  "user.updateInProgress": "Aktualizacja użytkownika",
  "user.updateSuccessTitle": "Użytkownik zaktualizowany",
  "user.userWorkWeek": "Tydzień pracy użytkownika",
  "user.workWeek.updateInProgress": "Aktualizacja tygodnia pracy",
  "user.workWeek.updateSuccessTitle": "Tydzień pracy został zaktualizowany",
  "users.aboutToSync": "Synchronizując wszystkich istniejących użytkowników, synchronizujesz dane użytkownika z platformy współpracy używanej przez Twój zespół z ich profilem w Vacation Tracker (np. Imię i nazwisko, Zdjęcie profilowe itp.)",
  "users.activateUsers": "Aktywuj",
  "users.activeUser": "Aktywuj",
  "users.activeUsers": "Aktywni",
  "users.assignLicenses": "Przypisz licencje",
  "users.cancel": "Anuluj",
  "users.changeUserLocation": "Zmień lokalizację",
  "users.changeUserTeam": "Zmień dział",
  "users.deactivate": "Dezaktywuj",
  "users.deactivateUser": "Dezaktywuj",
  "users.deactivateUserConfirm": "Czy na pewno chcesz dezaktywować {name}?",
  "users.deactivateUsers": "Dezaktywuj",
  "users.deactivateUserTitle": "Dezaktywuj",
  "users.deletedUsers": "Usunięci",
  "users.deleteInvite": "Usuń zaproszenie",
  "users.deleteInviteConfirm": "Czy na pewno chcesz usunąć zaproszenie wysłane do {email}?",
  "users.deleteUser": "Usuń użytkownika",
  "users.deleteUserUserConfirm": "Czy na pewno chcesz usunąć {name}?",
  "users.employmentDate": "Data zatrudnienia użytkownika",
  "users.endDate": "Data zakończenia",
  "users.import.description": "Import może zająć trochę czasu. Jeśli nie widzisz nowych użytkowników na liście, odśwież stronę po 10-15 sekundach.",
  "users.importedSuccessfully": "Użytkownicy zostali pomyślnie zaimportowani",
  "users.importMany.InProgress": "Importowanie użytkowników",
  "users.importMany.successTitle": "Użytkownicy zostali pomyślnie zaimportowani",
  "users.importNewUsers": "Dodaj użytkowników",
  "users.importOne.InProgress": "Importowanie nowego użytkownika",
  "users.importOne.successTitle": "Użytkownik został pomyślnie zaimportowany",
  "users.importUsers": "Importuj użytkowników",
  "users.inactiveUsers": "Nieaktywni",
  "users.invitations": "Zaproszenia",
  "users.inviteUsers": "Zaproś użytkowników",
  "users.location": "Lokalizacja",
  "users.name": "Imię i nazwisko",
  "users.next": "Następny",
  "users.prev": "Poprzedni",
  "users.reinvite": "Ponownie zaproś",
  "users.reInviteConfirm": "Czy na pewno chcesz ponownie zaprosić {email}?",
  "users.role": "Rola",
  "users.search": "Wyszukaj użytkowników",
  "users.sendInvitationsInLanguage": "Wyślij zaproszenia w języku",
  "users.startDate": "Data rozpoczęcia",
  "users.syncExistingUsers": "Synchronizuj istniejących użytkowników",
  "users.syncExistingUsers.InProgress": "Synchronizacja istniejących użytkowników",
  "users.syncExistingUsers.successTitle": "Istniejący użytkownicy zostali pomyślnie zsynchronizowani.",
  "users.syncWithGoogleConsent": "Synchronizuj za zgodą Google",
  "users.syncWithoutGoogleConsent": "Synchronizuj bez zgody Google",
  "users.team": "Dział",
  "users.updateQuota.InProgress": "Aktualizacja limitu użytkownika w toku",
  "users.updateQuota.successTitle": "Limit użytkownika został pomyślnie zaktualizowany",
  "users.updateRolloverQuota.successTitle": "Początkowy limit przeniesiony został pomyślnie zaktualizowany.",
  "users.viewUser": "Wyświetl użytkownika",
  "wallchart.range.next4Weeks": "Następne 4 tygodnie",
  "wallchart.range.next5Weeks": "Następne 5 tygodni",
  "wallchart.range.nextMonth": "Następny miesiąc",
  "wallchart.range.previousMonth": "Poprzedni miesiąc",
  "wallchart.range.thisMonth": "Ten miesiąc"
}
