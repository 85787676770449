import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'

interface DateFormatState {
  dateFormat: string
}

const initialState: DateFormatState = {
  dateFormat: 'YYYY-MM-DD',
}

export const dateFormatSlice = createSlice({
  name: 'dateFormat',
  initialState,
  reducers: {
    setDateFormat: (state, action: PayloadAction<string>) => {
      state.dateFormat = action.payload
    },
  },
})

export const { setDateFormat } = dateFormatSlice.actions

export const selectDateFormatSlice = (state: RootState) => state.dateFormatSlice

export default dateFormatSlice.reducer
