import React, { useState, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { Link, useHistory, useLocation } from 'react-router-dom'
import FullCalendar from '@fullcalendar/react'
import { DatesSetArg } from '@fullcalendar/core'
import dayGridPlugin from '@fullcalendar/daygrid'
import listPlugin from '@fullcalendar/list'
import { App, Breadcrumb, Button, Typography } from 'antd'
import { CalendarFilled } from '@ant-design/icons'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isBetween from 'dayjs/plugin/isBetween'
import zonedTimeToUtc from 'date-fns-tz/zonedTimeToUtc'
import ReactTooltip from 'react-tooltip'
import { difference, isEqual, isEmpty, uniqBy } from 'lodash'
import { useManualQuery } from 'graphql-hooks'
import * as Sentry from '@sentry/react'
import { getCalendarData, getCalendarInfo } from '../../graphql/custom-queries'

import { filterData } from './filter-data'
import { invertHexColor } from '../../util/invert-color-wrapper'
import allLocales from '@fullcalendar/core/locales-all'
import getDateInUserTimezone from '@vacationtracker/shared/functions/get-date-in-user-timezone'
import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { useShouldEnableFeatures } from '../../store/use-should-enable-features'
import { selectLocaleSlice } from '../../store/locale-slice'
import { openSupportChat } from '../../util/open-support-chat'

import CircularProgress from '../../components/circular-progress'
import LocationLeaveTypesTag, { ILeaveTypesShort } from '@vacationtracker/shared/components/location-leave-type-tag'
import IntlMessages from '../../util/IntlMessages'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'

import { IFilter } from '@vacationtracker/shared/types/filter'
import { ICalendarLeavesData, ICalendarLeaves, ICalendarEvent, IHolidaysData } from '../../types/calendar'
import { IGetTeamsShort } from '../../types/teams'
import { IGetLabelsShort } from '../../types/labels'
import { IGetBlackoutPeriodForUser, IGetCalendarInfo, IGetLocationListForCalendar, IGetUserIdsForApproverTo } from '../../types/custom-queries'
import { shortDayNames, allDaysAsNumbers } from '@vacationtracker/shared/types/calendar'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'
import { FrontendUrls } from '../../types/urls'

dayjs.extend(utc)
dayjs.extend(customParseFormat)
dayjs.extend(isSameOrBefore)
dayjs.extend(isBetween)

const { Paragraph } = Typography

const computeNotWorkingDays = (workWeekDays: number[]): number[] => {
  if (workWeekDays?.length === 0) {
    return []
  }
  return difference(allDaysAsNumbers, workWeekDays)
}

const getLoggedInUserNotWorkWeekSelectors = (workWeekDays: string[]): string => {
  if (workWeekDays?.length === 0) {
    return ''
  }
  return workWeekDays.map(day => `user-not-work-day-${day.toLowerCase()}`).join(' ')
}

const getHolidayName = (holidays, current) => {
  const holidaysWithTheSameName = holidays
    .filter((holiday) => {
      if (holiday.name === current.name) {
        return holiday.multiDayId || current.multiDayId 
          ? holiday.date === current.date && holiday.endDate === current.endDate
          : holiday.date === current.date
      }
    })

  if (holidaysWithTheSameName.length > 1) {
    return `${current.name} (${holidaysWithTheSameName.map(h => h.locationName).join(', ')})`
  }
  return `${current.name} (${current.locationName})`
}

const CalendarPage: React.FC = () => {
  const { formatMessage } = useIntl()
  const { notification } = App.useApp()
  const calendarRef = useRef<FullCalendar>(null)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { locale } = useAppSelector(selectLocaleSlice)
  const location = useLocation()
  const history = useHistory()

  const abortControllerRef = useRef<AbortController>(new AbortController())

  const [startWeek, setStartOfWeek] = useState(() => {
    if (localStorage.getItem('calendarStartOfWeek')) {
      const startOfWeek = localStorage.getItem('calendarStartOfWeek')
      if (startOfWeek && ['mon-fri', 'sun-thu'].includes(startOfWeek)) {
        return startOfWeek
      }
    }
    return 'mon-fri'
  })
  const [calendarView, setCalendarView] = useState(() => {
    if (localStorage.getItem('calendarView')) {
      const view = localStorage.getItem('calendarView')
      if (view && ['dayGridMonth', 'listMonth'].includes(view)) {
        return view
      }
    }
    return 'dayGridMonth'
  })
  const [approverToUsers, setApproverToUsers] = useState<IGetUserIdsForApproverTo[]>([])
  const [leaves, setLeaves] = useState<ICalendarEvent[]>([])
  const [calendarEvents, setCalendarEvent] = useState<ICalendarEvent[]>([])
  const [calendarHolidaysEvents, setCalendarHolidaysEvent] = useState<ICalendarEvent[]>([])
  const [teams, setTeams] = useState<IGetTeamsShort[]>([])
  const [leaveTypes, setLeaveTypes] = useState<ILeaveTypesShort[]>([])
  const [isCalendarDataLoading, setCalendarDataLoading] = useState(true)
  const [isCalendarInfoLoading, setCalendarInfoLoading] = useState(true)
  const [loggedInUserNotWorkingDays, setLoggedInUserNotWorkingDays] = useState<string[]>([])
  const [allLocations, setAllLocations] = useState<IGetLocationListForCalendar[]>([])
  const [locations, setLocations] = useState<IGetLocationListForCalendar[]>([])
  const [labels, setLabels] = useState<IGetLabelsShort[]>([])
  const [initialFilterValues, setInitialFilterValues] = useState([{}])
  const [filters, setFilters] = useState<IFilter>({
    locationIds: [],
    teamIds: [],
    labelIds: [],
  })
  const [blackoutPeirodEvents, setBlackoutPeirodEvents] = useState<ICalendarEvent[]>([])
  const [recurringBlackoutPeirods, setRecurringBlackoutPeirods] = useState<IGetBlackoutPeriodForUser[]>([])
  const [recurringBlackoutPeirodsEvents, setRecurringBlackoutPeirodsEvents] = useState<ICalendarEvent[]>([])
  const shouldEnableFeatures = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)
  const [isInitialLoad, setIsInitialLoad] =  useState(true)
  const now = (new URLSearchParams(location.search).get('date') && new Date(new URLSearchParams(location.search).get('date') as string)) || new Date()

  const [getCalendarInfoQuery] = useManualQuery<IGetCalendarInfo, { id: string }>(getCalendarInfo)
  const [getCalendarDataQuery] = useManualQuery<ICalendarLeavesData, {
    dateStart: string
    dateEnd: string
    status: string
    limit: number
    nextToken: string
  }>(getCalendarData)

  useEffect(() => {
    abortControllerRef.current = new AbortController()
    if (isInitialLoad) {
      const [ locationIds = [], teamIds = [], labelIds = [] ] = ['locations', 'departments', 'labels'].map(name => new URLSearchParams(location.search).get(name)?.split(','))
      setFilters(prevValues => {
        return {
          ...prevValues,
          locationIds,
          teamIds,
          labelIds,
        }
      })

      setInitialFilterValues(() => {
        const data: ISelected[] = []

        if (locationIds?.length) {
          data.push({ type: 'Locations', values: locationIds as string[] })
        }
        if (teamIds?.length) {
          data.push({ type: 'Departments', values: teamIds as string[] })
        }
        if (labelIds?.length) {
          data.push({ type: 'Labels', values: labelIds as string[]})
        }

        if(data.length === 0) {
          return [{}]
        }
        return data
      })

      fetchCalendarInfo(locationIds)
    }
    return () => {
      abortControllerRef.current?.abort()
    }
  }, [])

  useEffect(() => {
    if (location.search.includes('openSyncCalendar=true')) {
      // onSyncCalendarButtonClick()
      handleTransitionToCalendarSyncPage()
    }
  }, [location.search])

  useEffect(() => {
    handleLeaves(filters, leaves)
    handleHolidays(filters.locationIds)
  }, [filters])

  useEffect(() => {
    changeView(calendarView)
  }, [calendarView])

  const fetchCalendarInfo = async (locationIds: string[]|[] = []) => {
    const response = await getCalendarInfoQuery({
      variables: {id: authUser.id },
      fetchOptionsOverrides: {
        signal: abortControllerRef?.current?.signal,
      },
    })
    if (!response.data || response.error) throw response.error

    setLoggedInUserNotWorkingDays(
      computeNotWorkingDays(response.data.getUser?.workWeek || [1, 2, 3, 4, 5])
        .map(dayNumber => formatMessage({ id: shortDayNames[dayNumber] }))
    )
    setApproverToUsers(response.data.getUser.approverTo)

    const locationsWithHolidays = response.data.getLocationList.map(location => {
      return {
        ...location,
        holidays: location.holidays.map(holidaysForYear => {
          return {
            year: holidaysForYear.year,
            holidays: holidaysForYear.holidays.map(holiday => {
              return {
                ...holiday,
                locationName: location.name,
              }
            }),
          }
        }),
      }
    })
    setLocations(response.data.getLocationList)
    setTeams(response.data.getTeamListV2)
    setAllLocations(locationsWithHolidays)
    handleHolidays(locationIds, locationsWithHolidays)
    setRecurringBlackoutPeirods(
      response.data.getBlackoutPeriodForUser
        .filter(blackoutPeriod => blackoutPeriod.recurring)
        .map(blackoutPeriod => {
          return {
            ...blackoutPeriod,
            startDate: dayjs(blackoutPeriod.startDate).add(1, 'year').format('YYYY-MM-DD'),
            endDate: dayjs(blackoutPeriod.endDate).add(1, 'year').format('YYYY-MM-DD'),
          }
        })
    )
    setBlackoutPeirodEvents(handleBlackoutPeriodEvents(response.data.getBlackoutPeriodForUser))
    setLabels(response.data.getLabels)
    setCalendarInfoLoading(false)
    fetchDataAndSetState(dayjs(now).utcOffset(0).startOf('month').format('YYYY-MM-DD'), dayjs(now).utcOffset(0).endOf('month').format('YYYY-MM-DD'))
    if (isInitialLoad) {
      setIsInitialLoad(false)
    }
  }

  const leftPad = (n?: number) => (`0${n}`).slice(-2)

  const getStartAndEndTimes = (leave): {
    start: string
    end: string
  } => {
    if (leave.start && leave.end) {
      const { start, end } = leave
      return {
        start,
        end,
      }
    } else {
      const requestorTimeZone = locations.find(loc => loc.id === leave.locationId)?.timezone
      const formatStr = 'YYYY-MM-DDTHH:mm:ss.SSSZ'
      return {
        start: dayjs(zonedTimeToUtc(`${leave.startDate}T${leftPad(leave.partDayStartHour as number | undefined)}:00:00.000`, requestorTimeZone as string)).format(formatStr),
        end: dayjs(zonedTimeToUtc(`${leave.startDate}T${leftPad(leave.partDayEndHour as number | undefined)}:00:00.000`, requestorTimeZone as string)).format(formatStr),
      }
    }
  }

  const fetchData = async (dateStart: string, dateEnd: string, limit = 700, nextToken = 'NONE', leaves: ICalendarEvent[] = []): Promise<ICalendarEvent[]> => {
    try {
      const response = await getCalendarDataQuery({
        variables: {
          dateStart: dayjs(dateStart).utcOffset(0).subtract(1, 'month').format('YYYY-MM-DD'),
          dateEnd: dayjs(dateEnd).utcOffset(0).add(1, 'month').format('YYYY-MM-DD'),
          status: 'APPROVED',
          limit,
          nextToken,
        },
        fetchOptionsOverrides: {
          signal: abortControllerRef?.current?.signal,
        },
      })

      if (!response || !response.data || !response.data.getLeaveRequestByDate || response.error?.graphQLErrors?.length) {
        throw new Error(JSON.stringify(response))
      }

      const newLeaves = leavesDataWrapper([
        ...response.data.getLeaveRequestByDate.leaveRequests,
      ])

      if (response.data.getLeaveRequestByDate.nextToken) {
        return await fetchData(dateStart, dateEnd, limit, response.data.getLeaveRequestByDate.nextToken, [
          ...leaves,
          ...newLeaves,
        ])
      }

      return [
        ...leaves,
        ...newLeaves,
      ]
    } catch(err) {
      Sentry.captureException(err)

      notification.error({
        key: 'calendar-loading-error',
        message: formatMessage({ id: 'error.leaveRequestsLoadingError.title' }),
        description: (<>
          <Paragraph>{
            formatMessage({ id: 'error.leaveRequestsLoadingError.description' }, {
              link: (...chunks) => <a onClick={() => openSupportChat()}>{chunks}</a>,
            })
          }</Paragraph>
        </>),
        btn: (<Button onClick={() => {
          window.location.reload()
        }}>
          { formatMessage({ id: 'app.reload'} )}
        </Button>),
        duration: 0,
      })

      return []
    }
  }

  const [selectedStartDate, setSelectedStartDate] = useState<string | null>(null)
  const [selectedEndDate, setSelectedEndDate] = useState<string | null>(null)

  const fetchDataAndSetState = async (dateStart: string, dateEnd: string, limit = 700, nextToken = 'NONE') => {
    if (selectedStartDate && selectedEndDate && selectedStartDate === dateStart && selectedEndDate === dateEnd) {
      return
    }
    setSelectedStartDate(dateStart)
    setSelectedEndDate(dateEnd)
    const leaves = await fetchData(dateStart, dateEnd, limit, nextToken)

    setLeaves(leaves)
    handleLeaves(filters, leaves)
    setRecurringBlackoutPeirodsEvents(handleRecurringBlackoutPeirods(dateStart, dateEnd))
    setCalendarDataLoading(false)
  }

  const handleRecurringBlackoutPeirods = (dateStart: string, dateEnd: string) => {
    const eventsRaw: IGetBlackoutPeriodForUser[] = []
    
    const isCalendarPeriodInSameYear = dayjs(dateStart).year() === dayjs(dateEnd).year()

    recurringBlackoutPeirods.forEach(blackoutPeriod => {
      // Skip if blackout period is not created in the current calendar showing dates
      if (blackoutPeriod.startDate > dateEnd) {
        return
      }

      const isBlackoutPeriodInSameYear = dayjs(blackoutPeriod.startDate).year() === dayjs(blackoutPeriod.endDate).year()
      let blackoutStartDate = blackoutPeriod.startDate
      let blackoutEndDate = blackoutPeriod.endDate

      if ((isBlackoutPeriodInSameYear && isCalendarPeriodInSameYear) || (!isBlackoutPeriodInSameYear && !isCalendarPeriodInSameYear)) {
        blackoutStartDate = dayjs(blackoutPeriod.startDate).set('year', dayjs(dateStart).year()).format('YYYY-MM-DD')
        blackoutEndDate = dayjs(blackoutPeriod.endDate).set('year', dayjs(dateEnd).year()).format('YYYY-MM-DD')
      } else if (isBlackoutPeriodInSameYear && !isCalendarPeriodInSameYear) {
        
        if (dayjs(dateStart).isBetween(
          dayjs(blackoutPeriod.startDate).set('year', dayjs(dateStart).year()).format('YYYY-MM-DD'), 
          dayjs(blackoutPeriod.endDate).set('year', dayjs(dateStart).year()).format('YYYY-MM-DD'),
          'day',
          '[]'
        )) {
          blackoutStartDate = dayjs(blackoutPeriod.startDate).set('year', dayjs(dateStart).year()).format('YYYY-MM-DD')
          blackoutEndDate = dayjs(blackoutPeriod.endDate).set('year', dayjs(dateStart).year()).format('YYYY-MM-DD')
        } else {
          blackoutStartDate = dayjs(blackoutPeriod.startDate).set('year', dayjs(dateEnd).year()).format('YYYY-MM-DD')
          blackoutEndDate = dayjs(blackoutPeriod.endDate).set('year', dayjs(dateEnd).year()).format('YYYY-MM-DD')
        }
      } else if (!isBlackoutPeriodInSameYear && isCalendarPeriodInSameYear) {
        blackoutStartDate = dayjs(blackoutPeriod.startDate).set('year', dayjs(dateStart).year()).format('YYYY-MM-DD')
        blackoutEndDate = dayjs(blackoutPeriod.endDate).set('year', dayjs(dateEnd).year() + 1).format('YYYY-MM-DD')
      }
  
      if (
        (dayjs(dateStart).isSameOrBefore(blackoutStartDate) && dayjs(blackoutStartDate).isSameOrBefore(dateEnd)) ||
        (dayjs(blackoutStartDate).isSameOrBefore(dateStart) && dayjs(blackoutStartDate).isSameOrBefore(dateEnd)) ||
        (dayjs(dateStart).isSameOrBefore(blackoutStartDate) && dayjs(dateEnd).isSameOrBefore(blackoutStartDate)) ||
        (dayjs(blackoutStartDate).isSameOrBefore(dateStart) && dayjs(dateEnd).isSameOrBefore(blackoutStartDate))
      ) {

        eventsRaw.push({
          ...blackoutPeriod,
          startDate: dayjs(blackoutStartDate).format('YYYY-MM-DD'),
          endDate: dayjs(blackoutEndDate).add(1, 'day').format('YYYY-MM-DD'),
        })
      }
    })

    return handleBlackoutPeriodEvents(eventsRaw)
  }

  const leavesDataWrapper = (data: ICalendarLeaves[]) => {
    const events: ICalendarEvent[] = []

    data.filter(leave => leave.leaveType && leave.leaveType.isActive && !leave.leaveType.deleted)
      .forEach(leave => {
        const extraClass = leave.isPartDay && calendarView !== 'listMonth'  ? 'striped' : 'regular'
        const colorSuffix = leave.leaveType.isActive ? 'FF' : '80'
        const isHiddenLeaveType = leave.leaveType.leavePolicies.find(lp => lp.locationId === leave.locationId)?.hideLeaveType ?? false
        const isAdminOrUsersApprover = authUser.role === 'Admin' || (authUser.role === 'Approver' && approverToUsers.find(user => user.id === leave.user.id))
        const hideThisLeaveType = leave.user.id !== authUser.id && isHiddenLeaveType && !isAdminOrUsersApprover
        const leaveTypeName = hideThisLeaveType ? 'Hidden Leave Type' : leave.leaveType.name

        const calendarEvent: ICalendarEvent = {
          userId: leave.user.id,
          leaveRequestId: leave.id,
          title: calendarView === 'listMonth' ? `[${leaveTypeName}] ${leave.user.name}` : leave.user.name,
          orginalTitle: leave.user.name,
          description: isAdminOrUsersApprover && leave.reason ? leave.reason : '',
          time: leave.isPartDay ? `${leave.partDayStartHour} - ${leave.partDayEndHour}` : '',
          start: dayjs(getDateInUserTimezone(leave.startDate)).format('YYYY-MM-DD'),
          end: dayjs(getDateInUserTimezone(leave.endDate)).add(1, 'day').format('YYYY-MM-DD'),
          allDay: !leave.isPartDay,
          isHalfDays: leave.isPartDay,
          // last pair in HEX color is for Alpha
          backgroundColor: hideThisLeaveType ? '#777777' : leave.leaveType.color + colorSuffix,
          borderColor: hideThisLeaveType ? '#777777' : leave.leaveType.color,
          textColor: invertHexColor(hideThisLeaveType ? '#000000' : leave.leaveType.color, true),
          leaveTypeName,
          teamId: leave.user.teamId,
          typeDisabled: !leave.leaveType.isActive,
          locationId: leave.user.locationId,
          typeDeleted: leave.leaveType.deleted,
          labelIds: leave.user.labels && leave.user.labels.map(label => label.id),
          classNames: [extraClass],
          isHoliday: false,
          leaveTypePosition: hideThisLeaveType ? 999 : leave.leaveType.position,
          hideLeaveType: hideThisLeaveType,
          leaveTypeId: leave.leaveType.id,
        }

        if (leave.isPartDay) {
          const partDayEvent: ICalendarEvent = {
            ...calendarEvent,
            ...getStartAndEndTimes(leave),
          }
          events.push(partDayEvent)
        } else {
          events.push(calendarEvent)
        }
      })

    return events
  }

  const holidayDataWrapper = (data: IHolidaysData[]) => {
    const holidaysArray: IHolidaysData[] = []
    data.forEach((single => {
      if (single.multiDayId) {
        if (holidaysArray.filter(holiday => single.multiDayId === holiday.multiDayId).length === 0) {
          const filtered = data
            .filter(holiday => holiday.multiDayId === single.multiDayId)
            .sort((a, b) => dayjs(a.date).format('YYYY-MM-DD') < dayjs(b.date).format('YYYY-MM-DD') ? -1 : 1)
          holidaysArray.push({ ...single, endDate: filtered[filtered.length - 1].date })
        }
      } else {
        delete single.multiDayId
        holidaysArray.push(single)
      }
    }))

    const holidaysEvent: ICalendarEvent[] = holidaysArray
      .filter((holiday, index, holidaysArray) => {
        // do not show holidays with the same name
        return index === holidaysArray.findIndex((h) => {
          if (h.name === holiday.name) {
            return h.multiDayId || holiday.multiDayId
              ? h.date === holiday.date && h.endDate === holiday.endDate
              : h.date === holiday.date
          }
        })
      })
      .map((holiday: IHolidaysData) => {
        const calendarViewClass = holiday.isHalfDay && calendarView !== 'listMonth'  ? 'striped' : 'regular'
        const isHalfDayHolidayClass = holiday.isHalfDay ? 'half-day-holiday' : 'full-day-holiday'
        const extraClass = `${calendarViewClass} ${isHalfDayHolidayClass}`
        const holidayName = getHolidayName(holidaysArray, holiday)

        return {
          userId: '',
          // display one holiday if it is the same for diff locatiions
          title: calendarView === 'listMonth' ?
            `[${formatMessage({ id: 'app.holidays' })}${holiday.isHalfDay ? formatMessage({ id: 'app.halfDayInParenthesis' }) : ''}] ${holidayName}` : holidayName,
          description: '',
          orginalTitle: holidayName,
          time: '',
          start: dayjs(holiday.date).format('YYYY-MM-DD'),
          end: dayjs(holiday.endDate || holiday.date).add(1, 'day').format('YYYY-MM-DD'),
          allDay: true,
          isHalfDays: holiday.isHalfDay as boolean,
          className: '',
          backgroundColor: '#7266ba',
          borderColor: '#7266ba',
          leaveTypeName: 'Holiday',
          teamId: '',
          typeDisabled: false,
          textColor: '#fff',
          locationId: '',
          classNames: [extraClass],
          typeDeleted: false,
          isHoliday: true,
        }
      })
    return holidaysEvent
  }

  const changeStartOfWeek = (week: string) => {
    localStorage.setItem('calendarStartOfWeek', week)
    setStartOfWeek(week)
  }

  const changeClanedarView = (view: string) => {
    setCalendarEvent(prevState => {
      return prevState.map(leaveCalEvent => {
        let calEvent = {
          ...leaveCalEvent,
          title: view === 'listMonth' ? `[${leaveCalEvent.leaveTypeName}] ${leaveCalEvent.orginalTitle}` : leaveCalEvent.orginalTitle || '',
        }

        if (leaveCalEvent.isHalfDays) {
          calEvent = {
            ...calEvent,
            classNames: ['regular'],
            ...getStartAndEndTimes(leaveCalEvent),
          }
        }

        return calEvent
      })
    })
    setCalendarHolidaysEvent(prevState => {
      return prevState.map(holiday => {
        const calendarViewClass = holiday.isHalfDays && view !== 'listMonth'  ? 'striped' : 'regular'
        const isHalfDayHolidayClass = holiday.isHalfDays ? 'half-day-holiday' : 'full-day-holiday'
        const extraClass = `${calendarViewClass} ${isHalfDayHolidayClass}`
        const title = view === 'listMonth' ?
          `[${formatMessage({ id: 'app.holidays' })}${holiday.isHalfDays ? formatMessage({ id: 'app.halfDayInParenthesis' }) : ''}] ${holiday.orginalTitle}` :
          holiday.orginalTitle

        return {
          ...holiday,
          className: isHalfDayHolidayClass,
          classNames: [extraClass],
          title: title as string,
        }
      })
    })
    localStorage.setItem('calendarView', view)
    setCalendarView(view)
  }

  const handleEventPositioned = (info) => {
    if (authUser.role === 'Admin' || authUser.role === 'Approver') {
      const tooltipText = `<p>${formatMessage({ id: 'app.reason' })}:
      ${info.event._def.extendedProps.description} ${info.event._def.extendedProps.typeDisabled ? '</br> This leave type has been disabled' : ''}</p>`
      info.el.setAttribute('data-tip', info.event._def.extendedProps.description ? tooltipText : '')
    }
    if (info?.event?.extendedProps?.leaveTypeName === 'Holiday') {
      // Hide tooltip if calendarView list
      const tooltipText = calendarView === 'dayGridMonth' ? info.event.extendedProps?.orginalTitle : ''
      info.el.setAttribute('data-tip', info.event.extendedProps?.orginalTitle ? tooltipText : '')
    }
    if (info?.event?.extendedProps?.leaveTypeName === 'BlackoutPeriod') {
      const extendedProps = info?.event?.extendedProps
      let extraInfo = '<br/>'
      if (extendedProps.locations.length > 0) {
        const locationsNames = locations.filter(location => extendedProps.locations.includes(location.id)).map(location => location.name)
        extraInfo += `${formatMessage({ id: 'app.locations' })}: ${locationsNames.join(', ')}<br/>`
      }
      if (extendedProps.teams.length > 0) {
        const teamNames = teams.filter(team => extendedProps.teams.includes(team.id)).map(team => team.name)
        extraInfo += `${formatMessage({ id: 'app.departments' })}: ${teamNames.join(', ')}<br/>`
      }
      if (extendedProps.labels.length > 0) {
        const labelsName = labels.filter(label => extendedProps.labels.includes(label.id)).map(label => label.name)
        extraInfo += `${formatMessage({ id: 'app.departments' })}: ${labelsName.join(', ')}<br/>`
      }
      if (extendedProps.locations.length === 0 && extendedProps.teams.length === 0 && extendedProps.labels.length === 0) {
        extraInfo += `${formatMessage({ id: 'calendar.forAllUsers' })}<br/>`
      }
      // Hide tooltip if calendarView list
      const tooltipText = calendarView === 'dayGridMonth' ? `${info.event.extendedProps?.orginalTitle}\n${extraInfo}` : ''
      info.el.setAttribute('data-tip', info.event.extendedProps?.orginalTitle ? tooltipText : '')
    }
    if (info?.event?.extendedProps?.isHalfDays && calendarView === 'listMonth') {
      // Show tooltip if calendarView is list and is part day leave
      const tooltipText = `<p>${formatMessage({id: 'calendar.timezone.info'})}</p>`
      info.el.firstChild.setAttribute('data-tip', tooltipText)
      if (authUser.role === 'Admin' || authUser.role === 'Approver') {
        const tooltipText = `<p>${formatMessage({ id: 'app.reason' })}:
        ${info.event._def.extendedProps.description} ${info.event._def.extendedProps.typeDisabled ? '</br> This leave type has been disabled' : ''}</p>`
        info.el.lastChild.setAttribute('data-tip', info.event._def.extendedProps.description ? tooltipText : '')
      }
    }
    ReactTooltip.rebuild()
  }

  const onCalendarSelectionUpdate = (dateInfo: DatesSetArg) => {
    (async () => {
      const start = dayjs(dateInfo.view.currentStart).utcOffset(0).format('YYYY-MM-DD')
      const end = dayjs(dateInfo.view.currentEnd).utcOffset(0).format('YYYY-MM-DD')
      await fetchDataAndSetState(start, end)
    })()
  }

  const handleHolidays = (locationIds: string[], locationsHolidays?: IGetLocationListForCalendar[]) => {
    let locations: IGetLocationListForCalendar[] = locationsHolidays ? locationsHolidays : allLocations
    if (!isEmpty(locationsHolidays) && locationsHolidays) {
      locations = locationsHolidays
    }

    let holidaysData: IHolidaysData[] = []
    if(!isEmpty(locationIds)) {
      locations = locations.filter(location => locationIds.includes(location.id))
    }

    locations.forEach(location => {
      if (location.holidays.length > 0) {
        location.holidays.forEach(holiday => {
          holidaysData = holidaysData.concat((holiday.holidays as IHolidaysData[]))
        })
      }
    })

    setCalendarHolidaysEvent(holidayDataWrapper(holidaysData))
    return
  }

  const handleBlackoutPeriodEvents = (blackoutPeirodEvents: IGetBlackoutPeriodForUser[]): ICalendarEvent[] => {
    const events: ICalendarEvent[] = []

    blackoutPeirodEvents.forEach(blackoutPeriod => {
      events.push({
        userId: '',
        title: `[${formatMessage({ id: 'automations.BLACKOUT_PERIOD' })}] ${blackoutPeriod.name}`,
        description: '',
        orginalTitle: blackoutPeriod.name,
        time: '',
        start: dayjs(blackoutPeriod.startDate).format('YYYY-MM-DD'),
        end: dayjs(blackoutPeriod.endDate).add(1, 'day').format('YYYY-MM-DD'),
        allDay: true,
        isHalfDays: false,
        className: '',
        backgroundColor: '#545454',
        borderColor: '#545454',
        leaveTypeName: 'BlackoutPeriod',
        teamId: '',
        typeDisabled: false,
        textColor: '#fff',
        locationId: '',
        classNames: [],
        typeDeleted: false,
        isHoliday: false,
        teams: blackoutPeriod.teams,
        locations: blackoutPeriod.locations,
        labels: blackoutPeriod.labels,
      } as ICalendarEvent)
    })
  
    return(events)
  }

  const handleSetLeaveTypesAsLabels = (leavesData: ICalendarEvent[]) => {
    const leaveTypesToShowAsLabels: ILeaveTypesShort[] = []
    leavesData
      .map((leave: ICalendarEvent) => {
        leaveTypesToShowAsLabels.push({
          position: leave.leaveTypePosition as number,
          name: leave.leaveTypeName,
          color: leave.borderColor,
          hideLeaveType: leave.hideLeaveType as boolean,
          id: leave.leaveTypeId,
        })
      })
    setLeaveTypes(uniqBy(leaveTypesToShowAsLabels, 'name'))
  }

  const handleLeaves = (filters: IFilter, orginalLeaves?: ICalendarEvent[]) => {
    let leavesData: ICalendarEvent[] = leaves
    if (orginalLeaves) {
      leavesData = orginalLeaves
    }

    if (isEmpty(filters.teamIds) && isEmpty(filters.locationIds) && isEmpty(filters.labelIds)) {
      handleSetLeaveTypesAsLabels(leavesData)
      setCalendarEvent(leavesData)
      return
    }
    const filteredLeaves = filterData(filters, leavesData) as ICalendarEvent[]
    handleSetLeaveTypesAsLabels(filteredLeaves)
    setCalendarEvent(filteredLeaves)
  }

  const filterChanged = (data: IFilter) => {
    if(!isEqual(data, filters)) {
      let reject = true
      for (const [key] of Object.entries(data)) {
        if (!isEqual(filters[key], data[key]) && !isInitialLoad) {
          reject = false
        }
      }
      if (reject) return

      const { locationIds, teamIds, labelIds } = data
      let url = location.pathname
      let searchParams = ''
      if (locationIds?.length) {
        searchParams += `&locations=${locationIds.join(',')}`
      }
      if (teamIds?.length) {
        searchParams += `&departments=${teamIds.join(',')}`
      }
      if (labelIds?.length) {
        searchParams += `&labels=${labelIds.join(',')}`
      }
      url += searchParams.replace('&', '?')
      window.history.pushState({}, '', url)

      setFilters(prevState => {
        return {
          ...prevState,
          ...data,
        }
      })
    }
  }

  const changeView = view => {
    const API = getApi()

    API && API.changeView(view as string)
  }

  const getApi = () => {
    const { current: calendarDom } = calendarRef

    return calendarDom ? calendarDom.getApi() : null
  }

  const handleTransitionToCalendarSyncPage = () => {
    let filterParams = ''
    if (filters.locationIds.length > 0) {
      filterParams += `&locations=${filters.locationIds.join(',')}`
    }

    if (filters.teamIds.length > 0) {
      filterParams += `&departments=${filters.teamIds.join(',')}`
    }

    if (filters.labelIds.length > 0) {
      filterParams += `&labels=${filters.labelIds.join(',')}`
    }
    history.push({
      pathname: FrontendUrls.calendarSyncPage,
      search: filterParams.replace('&', '?'),
    })
  }

  return (
    <div className="main-content">
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span className="vt-callendar-sync-button" >
            <IntlMessages id="app.calendar" />
            <Button
              onClick={handleTransitionToCalendarSyncPage}
              style={{ marginLeft: 10 }}
            >
              <CalendarFilled style={{ marginRight: 5 }} />
              <IntlMessages id="calendar.syncButton" />
            </Button>
          </span>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb
            items={[
              {
                title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
              },
              {
                title: <IntlMessages id="app.calendar" />,
              },
            ]}
          />
        </div>
      </div>
      <div className="main-content-body">
        {!isCalendarInfoLoading &&
          <div className="calendar-header">
            <div className="leave-types-tags">
              <LocationLeaveTypesTag leaveTypes={leaveTypes} isBlackoutPeriodExist={Boolean(blackoutPeirodEvents.length === 1)} />
              <p className="m-0"><IntlMessages id="calendar.halfdayTags" /></p>
            </div>
            <div className="filters">
              <div>
                {!isInitialLoad &&
                  <FilterAdvanced
                    page='calendar'
                    data={{
                      Locations: locations,
                      Departments: teams,
                      Labels: labels,
                    }}
                    onChangeFilter={filterChanged}
                    initialValues={initialFilterValues}
                    showLabels={shouldEnableFeatures}
                    currentUserId={authUser.id}
                  />
                }
              </div>
            </div>
          </div>
        }
        {isCalendarDataLoading ?
          <CircularProgress /> :
          <div className={`calendar-body start-week-${startWeek} calendar-view-${calendarView} ${getLoggedInUserNotWorkWeekSelectors(loggedInUserNotWorkingDays)}`} >
            <FullCalendar
              locales={allLocales}
              locale={locale.locale}
              allDayText={formatMessage({id: 'app.allDay'})}
              ref={calendarRef}
              timeZone={authUser.location.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone}
              fixedWeekCount={false}
              plugins={[ dayGridPlugin, listPlugin ]}
              contentHeight={'auto'}
              displayEventTime={calendarView === 'listMonth'}
              initialView={localStorage.getItem('calendarView') && localStorage.getItem('calendarView') === 'listMonth' ? 'listMonth' : 'dayGridMonth'}
              dayMaxEventRows={6}
              dayMaxEvents={6}
              eventDisplay={'block'}
              firstDay={startWeek === 'mon-fri' ? 1 : 0}
              datesSet={onCalendarSelectionUpdate}
              initialDate={now}
              eventOrder={'-isHoliday,start,-duration,allDay,title'}
              eventOrderStrict={false}
              customButtons={{
                changeWeekMonFri: {
                  text: formatMessage({ id: 'calendar.week.monFri' }),
                  click: () => { changeStartOfWeek('mon-fri') },
                },
                changeWeekSunThu: {
                  text: formatMessage({ id: 'calendar.week.sunThu' }),
                  click: () => { changeStartOfWeek('sun-thu') },
                },
                changeViewMonth: {
                  text: formatMessage({ id: 'calendar.monthView' }),
                  click: () => { changeClanedarView('dayGridMonth') },
                },
                changeViewList: {
                  text: formatMessage({ id: 'calendar.listView' }),
                  click: () => { changeClanedarView('listMonth') },
                },
              }}
              headerToolbar={{
                left: 'prev,next today',
                center: 'title',
                right: 'changeWeekMonFri,changeWeekSunThu changeViewMonth,changeViewList',
              }}
              events={[
                ...calendarEvents, 
                ...calendarHolidaysEvents, 
                ...blackoutPeirodEvents,
                ...recurringBlackoutPeirods,
                ...recurringBlackoutPeirodsEvents,
              ]}
              eventMouseEnter={handleEventPositioned}
            />
            <ReactTooltip html={true} className="on-top" />
          </div>
        }
      </div>
    </div>
  )
}

export default CalendarPage
