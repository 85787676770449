import { useState } from 'react'
import { useManualQuery } from 'graphql-hooks'
import { IGetCalendarInfo } from '../../../types/custom-queries'
import { getCalendarInfo } from '../../../graphql/custom-queries'
import { IUserInfo } from '../../../types/users'

const useGetCalendarInfo = (isMounted) => {

  const [calendarInfo, setCalendarInfo] = useState<IGetCalendarInfo>()

  const [ getCalendarInfoQuery ] = useManualQuery<IGetCalendarInfo, { id: string }>(getCalendarInfo)

  const getCalendarInfoData = async (user: IUserInfo) => {
    const response = await getCalendarInfoQuery({ variables:{
      id: user.id,
    }})
    if (!isMounted.current) return
    if (!response.data || response.error) throw response.error
    //filter the response.data.getTeamListV2 property and remove team from the array if user is not approver to that team
    const teamList =
      user.role !== 'Admin'
        ? response.data.getTeamListV2.filter((team) =>
          user.approverToTeams?.some(
            (approverTeams) => approverTeams.id === team.id
          )
        )
        : response.data.getTeamListV2
    setCalendarInfo({...response.data, getTeamListV2: teamList})
  }

  return {calendarInfo, getCalendarInfoData}
}

export default useGetCalendarInfo