import React, { useEffect, useRef, useState } from 'react'
import { Form, Modal, QRCode, Typography } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import MFACodeInput from '../mfa-code'
import * as logger from '../../services/logger'
import { OTPRef } from 'antd/es/input/OTP'

interface EnableMFAModalProps {
  secretCode: string
  onClose: () => void
  onSave: (code: string) => void
}

const EnableMFAModal = ({ secretCode, onClose, onSave }: EnableMFAModalProps): React.ReactElement => {

  const [form] = Form.useForm()
  const { Text } = Typography
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoading(true)
        onSave(values.code as string)
      })
      .catch(info => {
        logger.debug('Validate Failed:', info)
      })
  }

  const codeInputRef = useRef<OTPRef>(null)

  // Auto-focus the OTP input field
  useEffect(() => {
    codeInputRef.current?.focus()
  }, [])

  return (
    <Modal
      open={true}
      onCancel={onClose}
      onOk={onFinish}
      confirmLoading={isLoading}
      title={<IntlMessages id="app.mfaTitle" />}  
      closeIcon={<CloseCircleOutlined />}
      okText={<IntlMessages id="app.save" />}
      cancelText={<IntlMessages id="app.cancel" />}
    >
      <div><IntlMessages id="app.mfaSubTitle" /></div>
      <div style={{ marginTop: '10px' }}>
        <QRCode
          size={240}
          value={`otpauth://totp/VacationTracker?secret=${secretCode}`}
          type="svg" 
          style={{ margin: '0 auto', border: '5px solid white' }}
        />
      </div>
      <div style={{ marginTop: '10px' }}><IntlMessages id="app.mfaSecretKey"/></div>
      <Form
        key="EnableMFAForm"
        form={form}
        name="EnableMFAForm"
      >
        <Form.Item name="secret">
          <Text style={{ fontSize: '13px' }} type="secondary" copyable={{text: secretCode}} >
            {secretCode}&nbsp;
          </Text>
        </Form.Item>
        <div style={{ marginBottom: '15px' }}><IntlMessages id="app.mfaTooltip"/></div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <MFACodeInput handleEnter={onFinish} inputRef={codeInputRef} />
        </div>
      </Form>
    </Modal>
  )
}

export default EnableMFAModal