import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'

import dayjs from 'dayjs'

import { Col, Divider, List, Radio, Row, Tooltip, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { ImportHolidaysNavigation } from '../components'

import { IImportHolidaysStepThreeFormProps, ImportHolidaysStepEnum } from '../types'

import { dayOfWeekMap } from '../../../../../constants'
import { wait } from '@vacationtracker/shared/functions/wait'

const { Text } = Typography

const SummaryForm = ({
  setSteps,
  saveNewHolidays,
  holidays,
  year,
  country,
  state,
  locationId,
}: IImportHolidaysStepThreeFormProps): React.ReactElement => {  
  
  const { formatMessage } = useIntl()  
  const autoImportDisabled = holidays[0].google === true
  const history = useHistory()
  const [autoImport, setAutoImport] = useState(!autoImportDisabled)
  const [loading, setLoading] = useState(false)

  const backToSecondStep = () => {
    setSteps(ImportHolidaysStepEnum.stepTwo, 1)
  }

  const onClickSave = async () => {   
    setLoading(true)
    await saveNewHolidays(autoImport)
    await wait(3000)
    setLoading(false)
    history.push(`/app/settings/locations/${locationId}/holidays`)
  }

  return <>
    <p><IntlMessages id="holidays.importHolidaysSummary" values={{
      year: year,
      countryState: state ? `${state.name}, ${country.name}` : country.name,
      b: (...chunks) => (
        <Text strong>{chunks}</Text>
      ),
    }} /></p>
    <List
      size="large"
      dataSource={holidays}
      renderItem={(item, key) => <List.Item>
        <Text>{key+1}. </Text>
        <Text strong>{item.name}, </Text>
        <IntlMessages id={dayOfWeekMap.find((day) => day.value === new Date(item.date).getDay())?.name} />,
        <Text> </Text>
        <IntlMessages id={`app.${dayjs().month(new Date(item.date).getMonth()).locale('en-US').format('MMMM').toLowerCase()}`}></IntlMessages>{' '}{new Date(item.date).getDate()}
      </List.Item>
      }
    />
    <Divider />
    <div>
      <IntlMessages id="holidays.importHolidaysAutomaticallyQuestion"/>
      <Tooltip className="info-tooltip" title={<IntlMessages id="holidays.importHolidaysAutomaticallyTooltip"/>}>
        <InfoCircleOutlined />
      </Tooltip>
      <div>{autoImportDisabled && <IntlMessages id="holidays.importHolidaysAutomaticallyTooltipForMissingHolidays"/>}</div>
    </div>
    <div style={{ marginTop: 12 }}>
      <Radio.Group disabled={autoImportDisabled} onChange={(e) => setAutoImport(e.target.value as boolean)} defaultValue={!autoImportDisabled}>
        <Radio.Button value={true}><IntlMessages id="app.yes"/></Radio.Button>
        <Radio.Button value={false}><IntlMessages id="app.no"/></Radio.Button>
      </Radio.Group>
    </div>
    <Divider />
    <IntlMessages id="components.insertHolidaysForm.title" />
    <IntlMessages id="components.insertHolidaysForm.subtitle" />
    <Divider />
    <ImportHolidaysNavigation
      stepComplete={!loading}
      handleNext={onClickSave}
      handleBack={backToSecondStep}
      nextButtonTitle={formatMessage({id: 'holidays.importHolidaysCount'}, {count: holidays.length})}
    />
  </>
    
}

export default SummaryForm