import React, { ReactElement, useContext, useEffect, useRef, useState } from 'react'
import { useIntl } from 'react-intl'
import { Chart, ChartDataset, registerables } from 'chart.js'
import pattern from 'patternomaly'
import { getNumberOfDaysInMonth } from './helpers'
import { IHeatmapChartMonthProps, HeatmapEventType } from './types'
import { ThemeContext } from '../../context/themeContext'
import { DARK_THEME } from '@vacationtracker/shared/constants/ThemeSetting'

export const HeatmapChartMonth = ({ month, year, workingDays, data, onSelect }: IHeatmapChartMonthProps): ReactElement => {
  const { formatMessage } = useIntl()
  const { theme } = useContext(ThemeContext)
  const canvasRef = useRef<HTMLCanvasElement | null>(null)
  const [ daysInMonth, setDaysInMonth ] = useState(0)
  const [ selectedIndex, setSelectedIndex ] = useState(0)
  const [ chart, setChart ] = useState<Chart<'bar', number[], number> | null>(null)

  const selectDay = (day: number) => {
    setSelectedIndex(day - 1)
    onSelect(day)
  }

  const getXAxisOptions = () => {
    return {
      stacked: true,
      ticks: {
        backdropPadding: 3,
        color: ({ index }) => {
          switch (theme) {
            case DARK_THEME:
              return index === selectedIndex ? 'Black' : 'White'
            default:
              return index === selectedIndex ? 'White' : 'Black'
          }
        },
        backdropColor: ({ index }) => {
          return index === selectedIndex ? '#7f00ff' : ''
        },
        showLabelBackdrop: ({ index }) => {
          return index === selectedIndex ? true : false
        },
        major: {
          enabled: true,
        },
      },
    }
  }

  useEffect(() => {
    setDaysInMonth(getNumberOfDaysInMonth(year, month))
  }, [month, year])

  useEffect(() => {
    Chart.register(...registerables)

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [])

  useEffect(() => {
    const ctx = canvasRef.current?.getContext('2d')
    if (!ctx || !daysInMonth) {
      return
    }

    if (chart) {
      chart.destroy()
      setChart(null)
    }

    const nonWorkingDays = Array.from(Array(daysInMonth + 1).keys()).map(day => {
      const date = new Date(year, month, day)
      return workingDays.includes(date.getDay()) ? 0 : 1
    }).slice(1)

    const todayHighlight = Array(daysInMonth).fill(0)
    const today = new Date()
    const todayYear = today.getFullYear()
    const todayMonth = today.getMonth()
    if (month === todayMonth && year === todayYear) {
      todayHighlight[today.getDate() - 1] = 1
      selectDay(today.getDate())
    } else if (year > todayYear || year === todayYear && month > todayMonth) {
      selectDay(1)
    } else {
      selectDay(getNumberOfDaysInMonth(year, month))
    }

    const holidays = {
      data: Array(daysInMonth).fill(0),
      label: formatMessage({ id: 'components.heatmap.holiday' }),
      backgroundColor: 'rgb(114, 102, 186)',
      borderRadius: 4,
      xAxisID: 'x',
      categoryPercentage: 0.8,
      barPercentage: 1,
    }
    const leaveTypes: { [key: string]: ChartDataset<'bar', number[]> } = {}
    data.forEach((day, index) => {
      day.forEach(item => {
        if (item.type === HeatmapEventType.leave) {
          if (!leaveTypes[item.name]) {
            leaveTypes[item.name] = {
              data: Array(daysInMonth).fill(0),
              label: item.name,
              backgroundColor: item.color,
              borderRadius: 4,
              xAxisID: 'x',
              categoryPercentage: 0.8,
              barPercentage: 1,
            }
          }
          if (item.isPartDay) {
            leaveTypes[item.name].data[index] += item.workingDays
          } else {
            leaveTypes[item.name].data[index]++
          }
        } else if (item.type === HeatmapEventType.holiday) {
          holidays.data[index]++
        }
      })
    })

    const newChart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels: Array.from(Array(daysInMonth + 1).keys()).slice(1),
        datasets: [
          ...Object.values(leaveTypes),
          holidays,
          {
            label: formatMessage({ id: 'app.today' }),
            backgroundColor: pattern.draw('dot', 'rgba(39, 194, 76, 0.2)'),
            borderColor: 'rgb(127, 0, 255, 1)',
            data: todayHighlight,
            yAxisID: 'today',
            categoryPercentage: 1,
            barPercentage: 1,
          },
          {
            label: formatMessage({ id: 'app.nonWorkingDay' }),
            backgroundColor: 'rgba(0,0,0,0.1)',
            data: nonWorkingDays,
            yAxisID: 'nonWorking',
            categoryPercentage: 1,
            barPercentage: 1,
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0,
        },
        scales: {
          x: getXAxisOptions(),
          nonWorking: {
            display: false,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            min: 0,
            max: 1,
          },
          today: {
            display: false,
            grid: {
              display: false,
            },
            ticks: {
              display: false,
            },
            min: 0,
            max: 1,
          },
          y: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              display: true,
              color: theme === DARK_THEME ? 'White' : 'Black',
              callback: val => {
                return Number(val) % 1 === 0 ? Math.round(Number(val)) : ''
              },
            },
          },
        },
        plugins: {
          legend: {
            position: 'bottom',
            align: 'start',
            labels: {
              pointStyle: 'circle',
              usePointStyle: true,
              color: theme === DARK_THEME ? 'White' : 'Black',
            },
          },
          tooltip: {
            // Disable the on-canvas tooltip
            enabled: true,
            mode: 'index',
            intersect: false,
            filter: (e, index, array) => {
              return Boolean(array[index].raw) && array[index].dataset.yAxisID !== 'today' && array[index].dataset.yAxisID !== 'nonWorking'
            },
            callbacks: {
              footer: (tooltipItems) => {
                return (tooltipItems.length > 1 || tooltipItems.length === 1 && tooltipItems[0].dataset.yAxisID !== 'nonWorking')
                  ? formatMessage({ id: 'components.heatmap.clickForMore' })
                  : formatMessage({ id: 'app.noLeaves' })
              },
            },
          },
        },
        onClick: (e, elements, chart) => {
          if (e.native) {
            const data = chart.getElementsAtEventForMode(e.native, 'x', { intersect: false }, false)
            const index = data[0].index
            selectDay(index + 1) // Day index starts at 0
          }
        },
      },
    })

    setChart(newChart)

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [canvasRef, daysInMonth, data])

  useEffect(() => {
    if (chart?.options.scales?.x) {
      chart.options.scales.x = getXAxisOptions()
      chart.update()
    }
  }, [selectedIndex])

  useEffect(() => {
    if (
      chart?.options.scales?.x &&
      chart?.options.scales?.y?.ticks &&
      chart.options.plugins?.legend?.labels
    ) {
      chart.options.scales.x = getXAxisOptions()
      chart.options.scales.y.ticks.color = theme === DARK_THEME ? 'White' : 'Black'
      chart.options.plugins.legend.labels.color = theme === DARK_THEME ? 'White' : 'Black'
      chart.update()
    }
  }, [theme])

  return (
    <canvas ref={canvasRef} width="100%" height="200" />
  )
}
