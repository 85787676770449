import React from 'react'
import { Alert, Button } from 'antd'
import { format } from 'date-fns'

import IntlMessages from '../../util/IntlMessages'

interface IBillingResubscribeAlert {
  stripePaymentStatus: string
  subscriptionCurrentPeriodEnd: Date // actually string
  resubscribe: () => void
}

const BillingResubscribeAlert = ({
  stripePaymentStatus,
  subscriptionCurrentPeriodEnd,
  resubscribe,
}: IBillingResubscribeAlert): React.ReactElement => {

  return (
    <Alert
      type='warning'
      message={<IntlMessages
        id={`components.billingInfo.${stripePaymentStatus === 'trialing' ? 'canceledTrialDescription' : 'canceledDescription'}`}
        values={{ periodEnd: format(new Date(subscriptionCurrentPeriodEnd), 'yyyy-MM-dd') }}
      />}
      action={
        <Button size="large" type="primary" onClick={() => resubscribe()}>
          <IntlMessages id="components.billing.resubscribe" />
        </Button>
      }
    />
  )
}

export default BillingResubscribeAlert
