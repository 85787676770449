/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useIntl } from 'react-intl'
import { List, Checkbox, Input, Row, Col, Typography, Card, Button, Spin, Radio, Tooltip } from 'antd'
import { InfoCircleOutlined, CheckSquareOutlined, MinusSquareOutlined, SearchOutlined, LoadingOutlined } from '@ant-design/icons'
import { find } from 'lodash'
import InfiniteScroll from 'react-infinite-scroller'

import IntlMessages from '../../../util/IntlMessages'
import useDebounce from '../../../util/use-debounce'
import { SlackUserTags } from '../../../components/slack-user-tags'
import { track } from '../../../services/analytics/analytics'
import { IImportUser } from '@vacationtracker/shared/types/user'
import { actions } from '../actions'
import { ICreateCompanyState, SignupVariation } from '../types'
import { Platform } from '@vacationtracker/shared/types/core-event'
import { setCrispSessionParams } from '../helpers'
import { InviteAndManageUsersWithEmail } from '../../../components/invite-and-manage-users-with-email'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'

const { Title, Text } = Typography

const SHOWING_USERS_LIMIT = 50

interface IImportUsersProps {
  state: ICreateCompanyState
  dispatch: (e) => void
  variation: SignupVariation
  isGoogleDirectoryApiEnabled: boolean
  onFinishSetup: () => void
}

export const ImportUsers = ({ state, dispatch, variation, isGoogleDirectoryApiEnabled, onFinishSetup }: IImportUsersProps): React.ReactElement => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const [ showingUsers, setNewShowingUsers ] = useState<IImportUser[]>([])
  const [ searchUsersText, setSearchUsersText ] = useState<string>('')
  const [ hasMore, setHasMore ] = useState(true)
  const [ announceNewUsers, setAnnounceNewUsers ] = useState(false)
  const [ loadingMoreInfinite, setLoadingMoreInfinite ] = useState<boolean>(false)
  const [ platform, setPlatform ] = useState<Platform>()
  const [invitationLocale, setInvitationLocale] = useState<LocaleEnum>(LocaleEnum.en)

  const {
    selectedUsers,
    allUsers,
    createUser,
    importAllUsers,
    allUsersLoaded,
    createCompany: { plan, contactEmail },
    emailInvites,
  } = state

  const debouncedSearchTerm = useDebounce(searchUsersText.toLowerCase(), 500)

  useEffect(() => {
    if (createUser.platform) {
      setPlatform(createUser.platform)
    }
  }, [createUser.platform])

  useEffect(() => {
    if (platform) {
      track('SIGNUP_IMPORT_USERS_FORM_VIEWED', {
        platform,
        variation,
        plan,
        email: contactEmail,
        timestamp: new Date().toISOString(),
        source: 'app',
        status: 'step2',
      })
      setCrispSessionParams('step 2: import users')
    }
  }, [platform])

  useEffect(() => {
    if(showingUsers.length < SHOWING_USERS_LIMIT) {
      setNewShowingUsers(allUsers.slice(0, SHOWING_USERS_LIMIT))
    }
  }, [allUsers])

  useEffect(() => {
    if(allUsersLoaded && allUsers.length === showingUsers.length) {
      setHasMore(false)
    }
  }, [allUsersLoaded])

  useEffect(() =>  {
    if (debouncedSearchTerm) {
      setNewShowingUsers(allUsers.filter(user => user.name.toLowerCase().includes(debouncedSearchTerm)).slice(0, 200))
    } else {
      setNewShowingUsers(allUsers.slice(0, SHOWING_USERS_LIMIT))
    }
  }, [debouncedSearchTerm])

  const importUsers = () => {
    track('SIGNUP_IMPORT_USERS_FORM_USERS_IMPORTED', {
      importedUsers: importAllUsers ? allUsers.length : selectedUsers.length,
      totalUsers: allUsers.length,
      importAllUsers,
      platform,
      variation,
      plan,
      email: contactEmail,
    })
    track('SIGNUP_IMPORT_USERS_FORM_COMPLETED', {
      platform,
      variation,
      plan,
      email: contactEmail,
    })

    if ((variation === 'PLAN_ACCOUNT_USERS' || state.skipSelectPlan)) {
      onFinishSetup()
      return
    } else {
      history.push('/create-company/step3')
      dispatch(actions.onNextStepChange())
    }
  }

  const backStep = () => {
    track('SIGNUP_IMPORT_USERS_FORM_BACK', {
      platform,
      variation,
      plan,
      email: contactEmail,
    })

    if (variation === 'ACCOUNT_USERS_PLAN') {
      history.push('/create-company/step1')
    } else {
      history.push('/create-company/step2')
    }
    dispatch(actions.onPrevStepChange())
  }

  const handleInfiniteOnLoad = () => {
    setLoadingMoreInfinite(true)
    if(allUsersLoaded && allUsers.length === showingUsers.length) {
      setHasMore(false)
    }
    setTimeout(() => {
      setNewShowingUsers(allUsers.slice(0, showingUsers.length + SHOWING_USERS_LIMIT))
      setLoadingMoreInfinite(false)
    }, 200)
  }

  const changeLocale = (newLocale: LocaleEnum) => {
    setInvitationLocale(newLocale)
  }


  const xxl = { span: 14, offset: 5 }
  const xl = { span: 16, offset: 4 }
  const lg = { span: 20, offset: 2 }
  const md = { span: 22, offset: 1 }
  const xs = { span: 24, offset: 0}

  return (
    <div className="import-users-step">
      {
        platform === 'email' ? (<>
          <Title level={5} data-qa='invite-users-title'>
            <IntlMessages id="createCompany.steps.inviteUsers.title" />
          </Title>
          <p style={{ marginBottom: 30 }} className="ant-typography ant-typography-secondary">
            <IntlMessages id="createCompany.steps.inviteUsers.title.info" />
          </p>
          <InviteAndManageUsersWithEmail
            invitedUsers={emailInvites}
            addInvite={(data) => dispatch(actions.onAddEmailInvite(data))}
            removeInvite={(data) => dispatch(actions.onRemoveEmailInvite(data))}
            authUserEmail={createUser.mail}
            changeLocale={changeLocale}
            selectedLocale={invitationLocale}
          />
        </>) : (<>
          <Title level={5} data-qa='import-users-title'>
            <IntlMessages id="createCompany.steps.selectUsers.title" />
          </Title>
          <p style={{ marginBottom: 30 }} className="ant-typography ant-typography-secondary">
            <IntlMessages id="createCompany.steps.selectUsers.title.info" />
          </p>
          <div style={{ display: 'flex', alignItems: 'center', marginBottom: 30 }}>
            <span><IntlMessages id="general.announceNewUsers" /></span>
            <Tooltip
              className='info-tooltip'
              title={<IntlMessages
                id="general.announceNewUsersTooltip"
                values={{ platform: createUser.platform }}
              />}>
              <InfoCircleOutlined />
            </Tooltip>
            <div style={{ marginLeft: 16 }}>
              <Radio.Group
                onChange={(e) => {
                  const value = e.target.value as boolean
                  setAnnounceNewUsers(value)
                  dispatch(actions.setAnnounceNewUsers(value))
                }}
                value={announceNewUsers}
                style={{ width: '100%' }}
              >
                <Radio.Button value={true} style={{boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.2)'}}><IntlMessages id="app.yes" /></Radio.Button>
                <Radio.Button value={false} style={{boxShadow: '0px 0px 2px 2px rgba(0, 0, 0, 0.2)'}}><IntlMessages id="app.no" /></Radio.Button>
              </Radio.Group>
            </div>
          </div>          
          <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: 15 }}>
            <span
              style={{ fontSize: 16, paddingTop: 4, cursor: 'pointer' }}
              onClick={() => dispatch(actions.onSelectDeselectAllUsers()) }
              data-qa='deselect-all'
            >
              {importAllUsers ? <CheckSquareOutlined /> : <MinusSquareOutlined />}
              <span style={{ paddingLeft: 5 }}>
                {importAllUsers
                  ? <IntlMessages id="app.deselectAll" />
                  : <IntlMessages id="app.selectAll" />
                }
                <span style={{ paddingLeft: 5 }}>{`(${selectedUsers.length})`}</span>
              </span>
            </span>
            {!allUsersLoaded && !isGoogleDirectoryApiEnabled &&
          <Text type='secondary' style={{ lineHeight: '32px' }}>
            <LoadingOutlined style={{ paddingRight: 5 }} />
            <IntlMessages id="createCompany.steps.selectUsers.loadingAll" />
          </Text>
            }
            <Input
              placeholder={`${formatMessage({ id: 'createCompany.steps.selectUsers.searchUsers' })}`}
              suffix={searchUsersText ? null : <SearchOutlined />}
              allowClear
              style={{width: 250}}
              size='middle'
              value={searchUsersText}
              onChange={e => {
                setSearchUsersText(e.target.value)
              }}
              className="data-hj-allow"
            />
          </div>

          <div style={{ height: selectedUsers.length > 10 ? 425 : 360, overflowX: 'hidden'}}>
            <InfiniteScroll
              initialLoad={false}
              pageStart={0}
              loadMore={handleInfiniteOnLoad}
              hasMore={hasMore}
              useWindow={false}
            >
              <List
                grid={{ gutter: 6, xs: 1, sm: 1, md: 2, lg: 2, xl: 2, xxl: 2 }}
                dataSource={showingUsers}
                renderItem={(user) => (
                  <List.Item style={{ marginBottom: 10 }}>
                    <Card bordered size='small'
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        if (user.id !== (createUser.slackUserId || createUser.id)) {
                          dispatch(actions.onSelectUser(user))
                        }
                      }}
                    >
                      <Checkbox
                        style={{ paddingRight: 5 }}
                        disabled={user.id === (createUser.slackUserId || createUser.id)}
                        checked={Boolean(find(selectedUsers, selectedUser => selectedUser.id === user.id))}
                      />
                      {/* // TODO load google images */}
                      {((createUser.platform === 'slack') && allUsers.length < 200 && allUsersLoaded) && <UserAvatar
                        avatarSize="small"
                        style={{ marginRight: 5 }}
                        avatar={user.image}
                        id={user.id}
                        name={user.name}
                        shape="circle"
                      />}
                      {user.name}
                      {createUser.platform === 'slack' && <SlackUserTags user={user} />}
                    </Card>
                  </List.Item>
                )}
              >
                {loadingMoreInfinite && hasMore && (
                  <div style={{ position: 'absolute', bottom: 40, width: '100%', textAlign: 'center' }}>
                    <Spin />
                  </div>
                )}
              </List>
            </InfiniteScroll>
          </div>
        </>)
      }

      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>
        <Col span={24} className="footer-import-users">
          <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', gap: '0 12px', textAlign: 'right' }}>
            <Button
              disabled={state.isCreateCompanyLoader}
              className="button-right"
              style={{ display: 'block' }}
              size="large"
              onClick={backStep}
            >
              <IntlMessages id="app.back" />
            </Button>
            <Button
              type="primary"
              data-qa='go-to-plans-step-button'
              disabled={state.isCreateCompanyLoader}
              loading={state.isCreateCompanyLoader}
              className="button-right"
              style={{ display: 'block' }}
              size="large"
              onClick={importUsers}
            >
              {(variation === 'PLAN_ACCOUNT_USERS' || state.skipSelectPlan)
                ? <IntlMessages id="app.startFreeTrial" />
                : createUser.platform === 'email'
                  ? emailInvites.length > 0
                    ? <IntlMessages
                      id="createCompany.steps.inviteUsers.next"
                      values={{
                        noOfUsers: emailInvites.length,
                        postfix: emailInvites.length === 1 ? '' : 's',
                      }}
                    />
                    : <IntlMessages id="app.skip" />
                  : <IntlMessages
                    id="createCompany.steps.selectUsers.doneButton"
                    values={{
                      noOfUsers: selectedUsers.length,
                      postfix: selectedUsers.length === 1 ? '' : 's',
                    }}
                  />
              }
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}
