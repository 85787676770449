import antdEs from 'antd/locale/es_ES'
import esMessages from '@vacationtracker/shared/i18n/es-es.json'

const EsLang = {
  messages: {
    ...esMessages,
  },
  antd: antdEs,
  locale: 'es-ES',
}
export default EsLang
