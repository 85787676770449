import { IAddonsData } from './automations'
import { ICoreEvent, Source } from './core-event'

export type AddonsStatus = 'ACTIVE' | 'CANCEL_AT_PERIOD_END' | 'CANCELED' | 'GRANT_PERIOD' | 'BUY'
export enum AddonsStatusEnum {
  ACTIVE                = 'ACTIVE',
  CANCEL_AT_PERIOD_END  = 'CANCEL_AT_PERIOD_END',
  CANCELED              = 'CANCELED',
  GRANT_PERIOD          = 'GRANT_PERIOD',
  BUY                   = 'BUY',
}

export enum VisibilityLevel {
  DEPARTMENT = 'DEPARTMENT',
  // DEPARTMENT_LEVEL = 'DEPARTMENT_LEVEL',
  // PARENT_DEPARTMENT = 'PARENT_DEPARTMENT',
  // PARENT_DEPARTMENT_LEVEL = 'PARENT_DEPARTMENT_LEVEL',
  // CHILD_DEPARTMENT = 'CHILD_DEPARTMENT',
  // CHILD_DEPARTMENT_LEVEL = 'CHILD_DEPARTMENT_LEVEL',
}

export enum AddonTypeEnum {
  OPEN_API                = 'OPEN_API',
  SHORTEST_LEAVE_INTERVAL = 'SHORTEST_LEAVE_INTERVAL',
  SYNC_SHARED_CALENDARS   = 'SYNC_SHARED_CALENDARS',
  VISIBILITY              = 'VISIBILITY',
}
export type AddonsType = 'OPEN_API' | 'SHORTEST_LEAVE_INTERVAL' | 'SYNC_SHARED_CALENDARS' | 'VISIBILITY'
export type AutomationsType = 'BLACKOUT_PERIOD' | 'SENIORITY_ENTITLEMENT' | 'ENTITLEMENT_BY_ROLE' | 'PROBATION_PERIOD'
export type IntegrationsType = 'TODO_ADD_INTEGRATION_TYPE'

export interface IAddons {
  companyId: string
  automations: IAddon[]
  addons: IAddon[]
  integrations: IAddon[]
}

export interface IAddon {
  type: AddonsType | AutomationsType | IntegrationsType
  status: AddonsStatus
  isFeatureFlag?: boolean
}

export interface IUpdateAddons {
  automations?: IAddon[]
  addons?: IAddon[]
  integrations?: IAddon[]
}

export interface IDisableCanceledAddons {
  type: 'DISABLE-CANCELED-ADDONS'
  meta: {}
  payload: IDisableCanceledAddonsPayload
}

export interface IDisableCanceledAddonsPayload {
  correlationId: string
  source: Source
  companyId: string
  creator: string
}

export interface IAddonVisibilityForm {
  formatMessage: Function
  amIAdmin: boolean
  authUserId: string
  dateFormat: string
  handleSubscribe: (id: string, isActive: boolean) => void
  status?: AddonsStatus
  userCount: number
  backToAddons: () => void
  addonDetails?: IAddonsData
}
export interface IAddonVisibilityCreatedUpdatedEvent extends ICoreEvent {
  automationId: string
  creator: string
  eventType: 'VISIBILITY_ADDON_CREATED' | 'VISIBILITY_ADDON_UPDATED'
  validityStart: string
  automationType: 'VISIBILITY'
  isActive: boolean
  groupKey: string
  companyId: string
  visibilityLevel: VisibilityLevel[]
  correlationId: string
  eventGroup: 'AUTOMATION'
  timestamp: string
}

export interface IAddonVisibility {
  companyId: string
  id: string
  automationType: 'VISIBILITY'
  createdAt: string
  isActive: boolean
  owner: string
  visibilityLevel: string[]
}

export interface IAddonOpenApiCreatedEvent extends ICoreEvent {
  automationId: string
  creator: string
  eventType: 'OPEN_API_ADDON_CREATED'
  validityStart: string
  automationType: 'OPEN_API'
  isActive: boolean
  groupKey: string
  companyId: string
  correlationId: string
  eventGroup: 'AUTOMATION'
  timestamp: string
  name: string
}

export interface IAddonOpenApiDeletedEvent extends ICoreEvent {
  eventType: 'OPEN_API_ADDON_DELETED'
  eventGroup: 'AUTOMATION'
  automationId: string
}

export interface IAddonOpenApiKey {
  companyId: string
  id: string
  automationType: 'OPEN_API'
  createdAt: string
  owner: string
  name: string
  apiKeyValue: string
  apiKeyId: string
}
