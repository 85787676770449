import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import addDays from 'date-fns/addDays'
import isBefore from 'date-fns/isBefore'
import { RootState } from './configure'

import { IUserActionFlowOnboardingActions, IGetUserActionFlow } from '@vacationtracker/shared/types/user-action-flow'

interface IOnboardingState {
  actions: IUserActionFlowOnboardingActions
  version: number
  visibleOnboardingDrawer: boolean
  progressCompleted: number
  totalChecklist: number
  onboardingActiveKey: string
  onboardingCompleted: boolean
  onboardingSkipped: boolean
  hideOnboardingFlow: boolean
  fetchOnboardingString?: string
}

const initialState: IOnboardingState = {
  actions: {
    ONBOARDING_CHECKLIST_BOT_VIDEO_WATCHED: false,
    ONBOARDING_CHECKLIST_BOT_BUTTON_CLICKED: false,
    ONBOARDING_CHECKLIST_BOT_COMPLETED: false,
    ONBOARDING_CHECKLIST_LEAVE_REQUEST_VIDEO_WATCHED: false,
    ONBOARDING_CHECKLIST_LEAVE_REQUEST_BUTTON_CLICKED: false,
    ONBOARDING_CHECKLIST_LEAVE_REQUEST_COMPLETED: false,
    ONBOARDING_CHECKLIST_ICAL_VIDEO_WATCHED: false,
    ONBOARDING_CHECKLIST_ICAL_BUTTON_CLICKED: false,
    ONBOARDING_CHECKLIST_ICAL_COMPLETED: false,
    ONBOARDING_CHECKLIST_NOTIFICATIONS_VIDEO_WATCHED: false,
    ONBOARDING_CHECKLIST_NOTIFICATIONS_BUTTON_CLICKED: false,
    ONBOARDING_CHECKLIST_NOTIFICATIONS_COMPLETED: false,
    ONBOARDING_CHECKLIST_IMPORT_USERS_VIDEO_WATCHED: false,
    ONBOARDING_CHECKLIST_IMPORT_USERS_BUTTON_CLICKED: false,
    ONBOARDING_CHECKLIST_IMPORT_USERS_COMPLETED: false,
  },
  version: 1,
  progressCompleted: 0,
  totalChecklist: 0,
  visibleOnboardingDrawer: false,
  onboardingActiveKey: 'install-bot',
  onboardingCompleted: true,
  onboardingSkipped: false,
  hideOnboardingFlow: false,
  fetchOnboardingString: '',
}

export const onboardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    setOnboardingActions: (state, action: PayloadAction<IGetUserActionFlow>) => {
      let progressCompleted = 0
      let totalChecklist = 0
      for (const [key] of Object.entries(state.actions)) {
        if (action.payload && action.payload.actions && action.payload.actions[key]) {
          if (key.includes('_COMPLETED')) {
            progressCompleted++
          }
          state.actions[key] = true
        }
        if (key.includes('_COMPLETED')) {
          totalChecklist++
        }
      }

      let showOnboardingDrawer = action.payload.skipped || totalChecklist === progressCompleted ? false : true

      // // Auto show onboarding once per day if isn't skipped or completed.
      if (showOnboardingDrawer && localStorage.getItem('vtOnboardingClosedDate')) {
        showOnboardingDrawer = isBefore(addDays(new Date(localStorage.getItem('vtOnboardingClosedDate') as string), 1), new Date())
      }

      state.onboardingActiveKey = localStorage.getItem('onboardingKey') || 'install-bot'
      state.totalChecklist = totalChecklist
      state.progressCompleted = progressCompleted
      state.onboardingCompleted = totalChecklist === progressCompleted
      state.version = action.payload.version || 1
      state.onboardingSkipped = action.payload.skipped
      state.visibleOnboardingDrawer = showOnboardingDrawer
    },
    setOnboardingActiveKey: (state, action: PayloadAction<string>) => {
      state.onboardingActiveKey = action.payload
    },
    handleOnboardingVisible: (state) => {
      localStorage.setItem('vtOnboardingClosedDate', new Date().toISOString().split('T')[0])
      state.visibleOnboardingDrawer = !state.visibleOnboardingDrawer
    },
    hideOnboardingFlow: (state) => {
      state.hideOnboardingFlow = true
    },
    skipOnboardingActions: (state) => {
      state.onboardingSkipped = true
      state.visibleOnboardingDrawer = false
    },
    setFetchOnboarding: (state, action) => {
      state.fetchOnboardingString = action.payload
    },
  },
})

export const {
  setOnboardingActions,
  setOnboardingActiveKey,
  handleOnboardingVisible,
  hideOnboardingFlow,
  skipOnboardingActions,
  setFetchOnboarding,
} = onboardingSlice.actions

export const selectOnboardingSlice = (state: RootState) => state.onboardingSlice

export default onboardingSlice.reducer
