/* eslint-disable react/display-name */
import React from 'react'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'
import { getDayOfYear } from 'date-fns'
import { format, zonedTimeToUtc, getTimezoneOffset } from 'date-fns-tz'

import { IUserLogs } from '../../types/custom-queries'
import getDateInUserTimezone from '@vacationtracker/shared/functions/get-date-in-user-timezone'
import { formatDaysHoursValue, getDaysOrHours } from '@vacationtracker/shared/functions/get-days-or-hours'

import IntlMessages from '../../util/IntlMessages'
import WorkWeek from '../work-week'
import { DisplayLeaveInDaysAndHours } from '@vacationtracker/shared/components/display-leave-in-days-and-hours'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { addTimezoneOffsetToDate } from '@vacationtracker/shared/components/utils/add-offset-to-date'
import { roundTo2Decimals } from '@vacationtracker/shared/functions/calculation-shared'

const { Text } = Typography
interface ILogLine extends IUserLogs {
  amIAdmin: boolean
  timezone: string
  hourlyLeaveAccounting: boolean
  hourFormat: HourFormatEnum
  numberOfWorkingHoursPerDay: number
  index: number
}

interface IParams {
  startDate?: string
  endDate?: string
  quota?: number
  amount?: number
  leaveTypeName?: string
  isActive?: boolean
  yearStart?: string
  yearEnd?: string
  defaultDaysPerYear?: number
  customRolloverDays?: number
  hasUnlimitedDays?: boolean
  days?: number[]
  changedAvatar?: boolean
  [key: string]: string | number | boolean | undefined | null | string[] | number[]
}

export interface IUserLogLineResult {
  key: string
  color: string
  children: string | JSX.Element
  label: string
}

const getPeriodForNonAccrual = (leaveTypeYearStart: string, leaveTypeYearEnd: string) => {
  if (getDayOfYear(new Date(leaveTypeYearStart)) === 1) {
    return format(new Date(leaveTypeYearStart), 'yyyy')
  } else {
    return `${format(new Date(leaveTypeYearStart.slice(0, 10)), 'MMM dd, yyyy')} - ${format(new Date(leaveTypeYearEnd.slice(0, 10)), 'MMM dd, yyyy')}`
  }
}

const getUserParams = (params: IParams | null) => {
  if (!params) {
    return null
  }
  let name: string | JSX.Element = ''
  let endDate: string | JSX.Element = ''
  let startDate: string | JSX.Element = ''
  let userStatus: string | JSX.Element = ''
  let isAvatarChanged = false

  if (params?.name) {
    let separator
    const paramsProperties = Object.entries(params)
    if (paramsProperties.length === 2) {
      separator = <> <IntlMessages id="app.and" /></>
    } else if (paramsProperties.length > 2) {
      separator = <>,</>
    }

    name = <>
      <IntlMessages
        id="components.logs.userUpdatedName"
        values={{
          name: params?.name,
          wrappedName: (...chunks) => <b>{chunks}</b>,
        }}
      />
      {separator}
    </>
  }

  if (params?.startDate && params?.startDate.length) {
    let separator
    if (typeof params?.isAdmin === 'boolean' && params?.endDate && params?.endDate.length) {
      separator = <>,</>
    } else if (typeof params?.isAdmin === 'boolean') {
      separator = <> <IntlMessages id="app.and" /></>
    } else if (params?.endDate && params?.endDate.length) {
      separator = <> <IntlMessages id="app.and" /></>
    }

    startDate = <>
      <IntlMessages id="components.logs.userUpdatedStartDate" values={{ startDate: format(getDateInUserTimezone(params?.startDate as string), 'MMM d, yyyy')}} />
      {separator}
    </>
  }

  if (params?.endDate && params?.endDate.length) {
    endDate = <>
      <IntlMessages id="components.logs.userUpdatedEndDate" values={{ endDate: format(getDateInUserTimezone(params?.endDate as string), 'MMM d, yyyy')}} />
      {typeof params?.isAdmin === 'boolean' && <> <IntlMessages id="app.and" /></>}
    </>
  }

  if (typeof params?.isAdmin === 'boolean') {
    userStatus = params?.isAdmin ?
      <IntlMessages id="components.logs.userUpdatedChangeStatusToAdmin"/> :
      <IntlMessages id="components.logs.userUpdatedChangeStatusToUser"/>
  }

  if (params?.changedAvatar) {
    isAvatarChanged = params?.changedAvatar
  }

  return {
    name: () => name,
    endDate: () => endDate,
    startDate: () => startDate,
    userStatus: () => userStatus,
    isAvatarChanged,
  }
}

const getLeaveTypeName = (leaveTypeName, isActive) => {
  if (!isActive) {
    return `${leaveTypeName} (inactive)`
  }

  return leaveTypeName
}

export const getLogLine = ({
  type,
  timestamp,
  creatorName = '<Unknown>',
  creatorId,
  paramsJson,
  amIAdmin,
  timezone,
  hourlyLeaveAccounting,
  hourFormat,
  //TODO workday - change this once we implement workweek
  numberOfWorkingHoursPerDay,
  index,
}: ILogLine): IUserLogLineResult | null => {
  // Let's not convert system originated logs
  let dateInTimezone = creatorName !== 'system' ? zonedTimeToUtc(new Date(timestamp), timezone) : new Date(new Date(timestamp).toISOString().slice(0, -1))

  if (type === 'ACCRUED_DAYS') {
    // We receive accruals timestamp with time zone information, we need different logic to display the correct time in the logs.
    dateInTimezone = addTimezoneOffsetToDate(new Date(timestamp), getTimezoneOffset(timezone))
  }

  const dateTimeFormat = `MMM d, yyyy ${hourFormat === HourFormatEnum.twentyFour ? 'HH' : 'h'}:mm zzz ${hourFormat === HourFormatEnum.twentyFour ? '' : 'a'}`
  const time = format(dateInTimezone, dateTimeFormat, { timeZone: timezone })

  let text: string | JSX.Element = ''
  let color = 'blue'
  let params: IParams | null = null

  try {
    params = JSON.parse(paramsJson)
  } catch (err) {
    // Swallow the error
  }

  let creatorNameOrSystem: string | JSX.Element = <IntlMessages id="app.unknown" />

  if (creatorName !== 'system') {
    creatorNameOrSystem = creatorName
  } else if ('USER_STATUS_CHANGED' === type && params?.source === 'slack-event') {
    creatorNameOrSystem = <IntlMessages id="app.autoDeleted" />
  } else if (['LOCATION_USER_MOVED', 'TEAM_USER_MOVED', 'USER_UPDATED', 'USER_STATUS_CHANGED'].includes(type)) {
    creatorNameOrSystem = <IntlMessages id="app.autoImport" />
  }

  const setLogs = () => {
    switch (type) {
      case 'USER_STATUS_CHANGED':
        if(params?.status === 'ACTIVE') {
          color = 'green'
          text = (
            <IntlMessages id="components.logs.userActivatedBy" values={{
              creatorName: creatorNameOrSystem,
              link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
            }} />
          )
        }
        if (params?.status === 'INACTIVE') {
          color = 'red'
          text = (
            <IntlMessages id="components.logs.userDeactivatedBy" values={{
              creatorName: creatorNameOrSystem,
              link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
            }} />
          )
        }
        if (params?.status === 'DELETED') {
          color = 'red'
          text = (
            <IntlMessages id="components.logs.userDeletedBy" values={{
              creatorName: creatorNameOrSystem,
              link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
            }} />
          )
        }
        break
      case 'LOCATION_USER_MOVED':
        text = (
          <IntlMessages id="components.logs.userMovedToLocation" values={{
            creatorName: creatorNameOrSystem,
            link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
            location: params?.locationName !== 'deleted' ? params?.locationName : <IntlMessages id="components.logs.deleted"/>,
            locationLink: (...chunks) => amIAdmin && params?.locationName !== 'deleted' ?
              <Link to={`/app/settings/locations/${params?.locationId}/general`}>{chunks}</Link> : <strong>{chunks}</strong>,
          }} />
        )
        break
      case 'TEAM_USER_MOVED':
        text = (
          <IntlMessages id="components.logs.userMovedToTeam" values={{
            creatorName: creatorNameOrSystem,
            link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
            team: params?.teamName !== 'deleted' ? params?.teamName : <IntlMessages id="components.logs.deleted"/>,
            teamLink: (...chunks) => amIAdmin && params?.teamName !== 'deleted' ?
              <Link to={`/app/settings/departments/${params?.teamId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
          }} />
        )
        break
      case 'USER_UPDATED': {
        const userParams = getUserParams(params)
        text = (
          <>
            {userParams?.isAvatarChanged && !params?.name && <><IntlMessages id="components.logs.userChangedAvatar" /><br/></>}
            <IntlMessages id="components.logs.userUpdated" values={{
              creatorName: creatorNameOrSystem,
              link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
              ...userParams,
            }} />
          </>
        )
        break
      }
      case 'USER_INVITATION_ACCEPTED':
        text = (
          <IntlMessages id="components.logs.userInvitationAccepted" values={{
            creatorName: creatorNameOrSystem,
            link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
          }} />
        )
        break
      case 'USER_WORK_WEEK_UPDATED':
        text = (
          <IntlMessages id="components.logs.userWorkWeek" values={{
            creatorName: creatorNameOrSystem,
            link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
            workDays: <WorkWeek days={params?.days as number[]} firstDayOfWeek={0} separator=", " withAnd="and " />,
          }} />
        )
        break
      case 'USER_WORK_WEEK_DELETED':
        text = (
          <IntlMessages id="components.logs.userWorkWeekDefaultLocation" values={{
            creatorName: creatorNameOrSystem,
            link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
          }} />
        )
        break
      case 'ROLLOVER_EXPIRED':
        text = (
          <IntlMessages
            id='components.logs.userRolloverExpired'
            values={{
              leaveTypeName: getLeaveTypeName(params?.leaveTypeName, params?.isActive),
              total: params?.amount,
              b: (...chunks) => (
                <Text strong>{chunks}</Text>
              ),
            }} />
        )
        break
      case 'YEAR_START':
        text = (
          <IntlMessages
            id='components.logs.userRolloverDisplay'
            values={{
              leaveTypeName: getLeaveTypeName(params?.leaveTypeName, params?.isActive),
              value: <DisplayLeaveInDaysAndHours value={params?.amount as number} numberOfWorkingHoursPerDay={numberOfWorkingHoursPerDay} />,
              b: (...chunks) => (
                <Text strong>{chunks}</Text>
              ),
            }} />
        )
        break
      case 'ACCRUED_DAYS':
        if (params?.accrualType === 'NONE') {
          text = (
            <IntlMessages
              id='components.logs.nonAccrualLeaveType'
              values={{
                earned: <DisplayLeaveInDaysAndHours value={params?.amount as number} numberOfWorkingHoursPerDay={numberOfWorkingHoursPerDay} />,
                leaveTypeName: getLeaveTypeName(params?.leaveTypeName, params?.isActive),
                period: getPeriodForNonAccrual(params?.yearStart as string, params?.yearEnd as string),
                b: (...chunks) => (
                  <Text strong>{chunks}</Text>
                ),
              }} />
          )
        } else {
          text = (
            <IntlMessages
              id='components.logs.accrualDisplay'
              values={{
                earned:  <DisplayLeaveInDaysAndHours value={params?.amount as number} numberOfWorkingHoursPerDay={numberOfWorkingHoursPerDay} />,
                leaveTypeName: getLeaveTypeName(params?.leaveTypeName, params?.isActive),
                defaultDaysPerYear:<DisplayLeaveInDaysAndHours value={params?.defaultDaysPerYear as number} numberOfWorkingHoursPerDay={numberOfWorkingHoursPerDay} />,
                b: (...chunks) => (
                  <Text strong>{chunks}</Text>
                ),
              }} />
          )
        }
        break
      case 'USER_LEAVE_TYPES_UPDATED':
        text = (
          <IntlMessages id={hourlyLeaveAccounting ? 'components.logs.userLeaveTypeUpdatedQuotaHours' : 'components.logs.userLeaveTypeUpdatedQuotaDays'} values={{
            creatorName: creatorNameOrSystem,
            link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
            leaveTypeName: params?.leaveTypeName,
            quota: () => formatDaysHoursValue(params?.quota as number, params?.hasUnlimitedDays as boolean, hourlyLeaveAccounting, numberOfWorkingHoursPerDay),
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />
        )
        break
      case 'USER_LEAVE_TYPES_DELETED':
        text = (
          <IntlMessages id="components.logs.userLeaveTypeDeleteQuota" values={{
            creatorName: creatorNameOrSystem,
            link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
            leaveTypeName: params?.leaveTypeName,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />
        )
        break
      case 'APPROVER_STATUS':
        text = (
          <IntlMessages id="components.logs.userApproverStatus" values={{
            creatorName: creatorNameOrSystem,
            link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
            status: () => params?.statusChange === 'added' ? <IntlMessages id="app.set" /> : <IntlMessages id="app.removed" />,
            teamName: params?.teamName,
            team: (...chunks) => amIAdmin && params?.teamName !== 'deleted' ?
              <Link to={`/app/settings/departments/${params?.teamId}`}>{chunks}</Link> : <strong><IntlMessages id="components.logs.deleted"/></strong>,
          }} />
        )
        break
      case 'USER_LEAVE_TYPES_ROLLOVER_UPDATED':
        text = (
          <IntlMessages id="components.logs.initialRolloverUpdated" values={{
            creatorName: creatorNameOrSystem,
            customRolloverDays: getDaysOrHours(params?.customRolloverDays as number, hourlyLeaveAccounting, numberOfWorkingHoursPerDay),
            leaveTypeName: params?.leaveTypeName,
            link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />
        )
        break
      case 'USER_LEAVE_TYPES_ROLLOVER_DELETED':
        text = (
          <IntlMessages id="components.logs.initialRolloverDeleted" values={{
            creatorName: creatorNameOrSystem,
            leaveTypeName: params?.leaveTypeName,
            link: (...chunks) => amIAdmin && creatorName !== 'system' ? <Link to={`/app/users/${creatorId}`}>{chunks}</Link> : <strong>{chunks}</strong>,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />
        )
        break
      case 'TOIL_REQUEST_APPROVED':
      case 'TOIL_REQUEST_ADDED':
        text = (
          <IntlMessages id="components.logs.earned" values={{
            total: roundTo2Decimals(params?.total as number || 0),
            leaveTypeName: params?.leaveTypeName,
            showExpiration: params?.expirationDate ? 1 : 2,
            period: params?.toilPeriod,
            expiration: params?.expirationDate,
          }} />
        )
        break
      case 'USER_SENIORITY_ENTITLEMENT':
        text = (
          <IntlMessages id="components.logs.userSeniorityEntitlement" values={{
            leaveTypeName: params?.leaveTypeName,
            totalDays: params?.receivedProratedAmount || params?.days,
          }} />
        )
        break
      case 'USER_SENIORITY_ENTITLEMENT_OVERRIDE':
        text = (
          <IntlMessages id="components.logs.userSeniorityEntitlementOverride" values={{
            leaveTypeName: params?.leaveTypeName,
            totalDays: params?.days,
          }} />
        )
        break
      case 'USER_ENTITLEMENT_BY_ROLE':
        text = (
          <IntlMessages id="components.logs.userEntitlementByRole" values={{
            leaveTypeName: params?.leaveTypeName,
            totalDays: params?.receivedProratedAmount || params?.days,
          }} />
        )
        break
      case 'USER_ENTITLEMENT_BY_ROLE_OVERRIDE':
        text = (
          <IntlMessages id="components.logs.userEntitlementByRoleOverride" values={{
            leaveTypeName: params?.leaveTypeName,
            totalDays: params?.days,
          }} />
        )
        break
      case 'USER_ENTITLEMENT_BY_ROLE_DELETED':
        text = (
          <IntlMessages id="components.logs.userEntitlementByRoleDeleted" values={{
            leaveTypeName: params?.leaveTypeName,
            labelName: params?.labelName,
            b: (...chunks) => (
              <Text strong>{chunks}</Text>
            ),
          }} />
        )
        break
      default:
        text = (type)
        break
    }
  }

  setLogs()


  return {
    key: `${time}-${index}`,
    color,
    children: text,
    label: time,
  }
}