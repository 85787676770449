// Customizer const
export const LIGHT_THEME = 'light'
export const DARK_THEME = 'dark'
export type TeamsThemeType = 'default' | 'dark' | 'contrast'

// Ant Design v5 theme customization
export const lightThemeToken = {
  token: {
    colorPrimary: '#7f00ff',
    colorLink: '#7f00ff',
    colorInfo: '#7f00ff',
    fontFamily: 'NoirPro, sans-serif',
  },
  components: {
    Dropdown: {
      zIndexPopup: 10000,
    },
    Statistic: {
      contentFontSize: 18,
    },
  },
  inherit: true,
}

export const darkThemeToken = {
  token: {
    colorPrimary: '#38424b',
    colorInfo: '#38424b',
    colorTextBase: '#e0e0e0',
    colorBgBase: '#38434b',
    fontFamily: 'NoirPro, sans-serif',
  },
  components: {
    Button: {
      colorBgContainer: '#42505a',
      colorPrimaryHover: '#d1a3ff',
    },
    Input: {
      colorBorder: '#e0e0e0',
      colorPrimaryHover: '#ffffff',
    },
    Select: {
      colorBorder: '#e0e0e0',
      colorPrimaryHover: '#ffffff',
    },
    Statistic: {
      contentFontSize: 18,
    },
    Dropdown: {
      zIndexPopup: 10000,
    },
  },
  inherit: true,
}