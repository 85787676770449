import React, { ReactElement } from 'react'
import { Link } from 'react-router-dom'
import { Typography } from 'antd'
import { useIntl } from 'react-intl'

import { getNumberToDay } from '../../util/notifications'

import IntlMessages from '../../util/IntlMessages'

import { IFilter } from '@vacationtracker/shared/types/filter'


const { Text } = Typography
export interface ISummaryInfoProps {
  filters: IFilter
  noOfUsers: number
  numberOfEmailAddress: number
  channelName: string
  sendOnDays: number[] | undefined
  day: number | undefined
  hour: number
  minute: number
  frequency: 'DAILY' | 'WEEKLY' | undefined
}

const NotificationSummaryInfo = ({
  filters,
  noOfUsers,
  channelName,
  numberOfEmailAddress,
  sendOnDays,
  day,
  hour,
  minute,
  frequency,
}: ISummaryInfoProps): ReactElement => {
  const min = minute === 0 ? '00' : `${minute}`
  const time = `${hour}:${min}`
  const { formatMessage } = useIntl()
  let sendingDaysInfo
  if (frequency === 'DAILY') {
    sendingDaysInfo = `${sendOnDays && sendOnDays.map(e => getNumberToDay(e, formatMessage).full).join(', ')}`
  }

  if (frequency === 'WEEKLY' && day) {
    sendingDaysInfo = `${getNumberToDay(day, formatMessage).full}s`
  }

  let linkTo = '/app/users?saveFilters=false'

  if (filters.locationIds?.length) {
    linkTo += `&locations=${filters.locationIds.join(',')}`
  }
  if (filters.teamIds?.length) {
    linkTo += `&departments=${filters.teamIds.join(',')}`
  }
  if (filters.labelIds?.length) {
    linkTo += `&labels=${filters.labelIds.join(',')}`
  }

  return (
    <div className='vt-primary-color' style={{ textAlign: 'center' }}>
      <IntlMessages id="components.notificationSummaryInfo.notificationSummary"
        values={{
          channelWrapper: (...chunks) => channelName ? <><strong>{chunks}</strong> {formatMessage({ id: 'components.selectChannelForm.channel'}).toLowerCase()}</> : '',
          addressesWrapper: (...chunks) => numberOfEmailAddress ? <>{chunks}</> : '',
          to: (...chunks) => numberOfEmailAddress || channelName ? <>{chunks}</> : '',
          and: (...chunks) => numberOfEmailAddress && channelName ? <>{chunks}</> : '',
          multiEmailAddress: (...chunks) => numberOfEmailAddress > 1 ? <>{chunks}</> : '',
          multiUsers: (...chunks) => noOfUsers > 1 || noOfUsers === 0 ? <>{chunks}</> : '',
          danger: (...chunks) => noOfUsers === 0 ? <Text type='danger'>{...chunks}</Text> : chunks,
          link: (...chunks) => noOfUsers >= 1 ? <Link target="_blank" style={{ textDecoration: 'underline' }} to={linkTo}>{chunks}</Link> : '',
          noOfUsers,
          channelName,
          numberOfEmailAddress,
          sendingDaysInfo,
          time,
        }} />
    </div>
  )
}

export default NotificationSummaryInfo