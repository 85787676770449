import React, { useEffect } from 'react'
import {  Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'
import yaml from 'yaml'
import SwaggerUI from 'swagger-ui-react'
import 'swagger-ui-react/swagger-ui.css'

import specOpenApi from '@vacationtracker/open-api/swagger/open-api.yaml'

import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../circular-progress'

import { FrontendUrls } from '../../types/urls'

const OpenApiDocumentation = () => {
  const [spec, setSpec] = React.useState<string | null>(null)

  function removeOptionsFromPaths(paths) {
    for (const path in paths) {
      // eslint-disable-next-line no-prototype-builtins
      if (paths[path].hasOwnProperty('options')) {
        delete paths[path]['options']
      }
    }
    return paths
  }

  useEffect(() => {
    const spec = yaml.parse(specOpenApi as string)

    if (process.env.REACT_APP_OPEN_API_URL) {
      spec.servers[0].url = process.env.REACT_APP_OPEN_API_URL
    }

    spec.paths = removeOptionsFromPaths(spec.paths)

    setSpec(yaml.stringify(spec))
  }, [])

  return (
    <div className='main-content addons-view-page'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id="automations.OPEN_API_DOCUMENTATION" />
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb
            items={[
              {
                title: <IntlMessages id="sidebar.dashboard" />,
              },
              {
                title: <Link to={FrontendUrls.addons}><IntlMessages id="sidebar.addons" /></Link>,
              },
              {
                title: <Link to={`${FrontendUrls.addons}/open-api/view`}><IntlMessages id="automations.OPEN_API" /></Link>,
              },
              {
                title: <IntlMessages id="app.documentation" />,
              },
            ]}
          />
        </div>
      </div>
      <div className="main-content-body">
        {!spec ?
          <CircularProgress /> :
          <SwaggerUI 
            spec={spec}
            persistAuthorization={true}
          />
        }
      </div>
    </div>
  )
}

export default OpenApiDocumentation
