import antdTr from 'antd/locale/tr_TR'
import trMessages from '@vacationtracker/shared/i18n/tr-tr.json'

const TrLang = {
  messages: {
    ...trMessages,
  },
  antd: antdTr,
  locale: 'tr-TR',
}
export default TrLang
