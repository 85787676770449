// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function filterOptions(input: string, option: any): boolean {
  if (!option) return false

  if (typeof option.children === 'string') return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0

  // If 'childer' is an object, it means that the <IntlMessages object has been passed in the option select. Therefore, we take the value for searching.
  if (typeof option.children === 'object') return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
  
  return false
}
