import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'

interface FeatureFlagsState {
  featureFlags: string[]
  isLoadingFeatureFlags: boolean
}

const initialState: FeatureFlagsState = {
  featureFlags: [],
  isLoadingFeatureFlags: true,
}

export const featureFlagsSlice = createSlice({
  name: 'featureFlags',
  initialState,
  reducers: {
    setFeatureFlags: (state, action: PayloadAction<string[]>) => {
      state.featureFlags = action.payload
      state.isLoadingFeatureFlags = false
    },
  },
})

export const { setFeatureFlags } = featureFlagsSlice.actions

export const selectFeatureFlagsSlice = (state: RootState) => state.featureFlagsSlice

export default featureFlagsSlice.reducer
