import React from 'react'
import { Button, Form, Space } from 'antd'
import { useIntl } from 'react-intl'
import { IImportHolidaysNavigationProps } from './types'

export const ImportHolidaysNavigation = ({
  stepComplete,
  handleNext,
  handleBack,
  backButtonTitle,
  nextButtonTitle,
}: IImportHolidaysNavigationProps) : React.ReactElement=> {
  const { formatMessage } = useIntl()
  const backButton = backButtonTitle || formatMessage({ id: 'app.back' })

  return <Form.Item className='wrap-label'>
    <div
      style={{
        display: 'flex',
        alignItems: 'right',
        justifyContent: 'space-between',
        position: 'sticky',
        bottom: 0,
      }}>
      <Space direction='horizontal'>
        <Button
          type="primary"
          onClick={() => handleBack && handleBack()}>
          {backButton}
        </Button>
        {<Button
          disabled={!stepComplete}
          type="primary"
          onClick={() => handleNext()}>
          {nextButtonTitle}
        </Button>}
      </Space>
    </div>
  </Form.Item>
}
