import { IFilter } from '@vacationtracker/shared/types/filter'
import { IUsersInvitedEvent } from '@vacationtracker/shared/types/user'

export interface IImportData {
  newUsers: string[]
  importAllUsers: boolean
}

export interface IUsersFilter extends IFilter {
  status: string
  name: string
  limit: number
  nextToken: string
}

export enum ActiveTabEnum {
  active = 'ACTIVE',
  invites = 'INVITES',
  inactive = 'INACTIVE',
  deleted = 'DELETED'
}

export interface IUserInvitedEventParams extends IUsersInvitedEvent {
  userId: string
}
