import enLang from './entries/en-US'
import frLang from './entries/fr-FR'
import esLang from './entries/es-ES'
import ptLang from './entries/pt-BR'
import deLang from './entries/de-DE'
import itLang from './entries/it-IT'
import plLang from './entries/pl-PL'
import trLang from './entries/tr-TR'

const AppLocale = {
  en: enLang,
  fr: frLang,
  es: esLang,
  pt: ptLang,
  de: deLang,
  it: itLang,
  pl: plLang,
  tr: trLang,
}

export default AppLocale
