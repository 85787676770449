import { Middleware } from '@reduxjs/toolkit'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'

import { setAuthCompany, setAuthCompanyPlan } from './auth-company-slice'
import { setAllBadgeFeatures, setBadgeFeature } from './badge-features-slice'
import { setLocale } from './locale-slice'
import { setOnboardingActiveKey } from './onboarding-slice'

export const saveToLocalStorageMiddleware: Middleware = ({ getState }) => (next) => (action) => {
  if (setBadgeFeature.match(action)) {
    const { feature, value } = action.payload
    localStorage.setItem('badgeFeatures', JSON.stringify({
      [feature]: value,
    }))
  }

  if (setAllBadgeFeatures.match(action)) {
    localStorage.setItem('badgeFeatures', JSON.stringify(action.payload))
  }

  if (setAuthCompany.match(action)) {
    localStorage.setItem('company', JSON.stringify(action.payload))
  }

  const authCompanyState = getState().authCompanySlice?.authCompany
  if (authCompanyState?.plan && setAuthCompanyPlan.match(action)) {
    localStorage.setItem('company', JSON.stringify({
      ...authCompanyState,
      plan: action.payload,
      billing: {
        ...authCompanyState.billing,
        subscriptionPlan: action.payload,
      },
    }))
  }

  if (setOnboardingActiveKey.match(action)) {
    localStorage.setItem('onboardingKey', action.payload)
  }

  if (setLocale.match(action)) {
    if (action.payload?.locale) {
      localStorage.setItem('vtLocale', action.payload.locale)
    } else {
      localStorage.setItem('vtLocale', LocaleEnum.en)
    }
  }

  return next(action)
}
