import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { App, Tabs, Button, Table, Tooltip, TabsProps } from 'antd'
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons'
import { v4 as uuidv4 } from 'uuid'
import dayjs from 'dayjs'

import { dayjsIterator } from '../../util/dayjs-iterator'

import FormattedDate from '@vacationtracker/shared/components/formatted-date'
import AddHolidayForm from '../../components/add-holiday-form'
import IntlMessages from '../../util/IntlMessages'

import { IHoliday } from '../../types/holidays'
import { IHolidaysByYear } from '../../types/locations'
import { useHistory } from 'react-router'

enum ActiveTab {
  pastYear = '3',
  currentYear = '1',
  nextYear = '2'
}
interface ILocationHolidaysProps {
  holidays?: IHolidaysByYear[]
  onSaveHolidays: (data: IHolidaysByYear) => Promise<void>
  locationName: string
  locationId: string
}

interface IMultiDayPayload {
  multiDayId: string
  endDate: string
}

const LocationHolidays = ({ holidays, onSaveHolidays, locationName, locationId }: ILocationHolidaysProps) => {
  const { formatMessage } = useIntl()
  const { message, modal } = App.useApp()
  const [currentYear] = useState(Number(dayjs().format('YYYY')))
  const [nextYear] = useState(dayjs().add(1, 'year').format('YYYY'))
  const [holidaysListCurrentYear, setHolidaysListCurrentYear] = useState<IHoliday[]>([])
  const [holidaysListNextYear, setHolidaysListNextYear] = useState<IHoliday[]>([])
  const [holidaysListPastYear, setHolidaysListPastYear] = useState<IHoliday[]>([])
  const [loadedData, setLoadedData] = useState(true)
  const [showHolidayForm, handleAddHolidayForm] = useState(false)
  const [selectedHoliday, setSelectedHoliday] = useState<IHoliday | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [year, setYear] = useState(Number(dayjs().format('YYYY')))
  const history = useHistory()

  useEffect(() => {
    if (loadedData) {
      holidays?.forEach(holidayData => {
        updateHolidaysList(holidayData.year, holidayData.holidays)
      })
      setLoadedData(false)
    }
  }, [holidays, loadedData, currentYear, nextYear])

  const handleEditHoliday = (holiday) => {
    let multiDayPayload: IMultiDayPayload | {} = {}
    if (holiday.multiDayId) {
      multiDayPayload = {
        multiDayId: holiday.multiDayId,
        endDate: holiday.endDate,
      }
    }
    setSelectedHoliday({
      name: holiday.name,
      date: holiday.date,
      isHalfDay: holiday.isHalfDay,
      ...multiDayPayload,
    })
    handleAddHolidayForm(true)
  }

  const handleDeleteHoliday = ({ date, name, multiDayId }: IHoliday) => {
    modal.confirm({
      title: formatMessage({ id: 'components.locationHolidays.deleteHolidaysTitle' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'components.locationHolidays.deleteHolidaysConfirm' }, { name }),
      okType: 'danger',
      okText: formatMessage({ id: 'app.delete' }),
      onOk: async () => {
        const year = dayjs(date).format('YYYY')
        let deleteHolidays = getHolidaysForYear(year)
        if (multiDayId) {
          deleteHolidays = deleteHolidays.filter(holiday => holiday.multiDayId !== multiDayId)
        } else {
          deleteHolidays = deleteHolidays.filter(holiday => holiday.date !== date)
        }

        const payload: IHoliday[] = []
        deleteHolidays.forEach(holiday => {
          if (holiday.multiDayId) {
            for (const day of dayjsIterator(holiday.date, holiday.endDate)) {
              payload.push({
                name: holiday.name,
                date: day.format('YYYY-MM-DD'),
                multiDayId: holiday.multiDayId,
              })
            }
          } else {
            payload.push(holiday)
          }
        })

        await onSaveHolidays({
          year,
          holidays: payload,
        })
        updateHolidaysList(year, deleteHolidays)
      },
    })
  }

  const closeModal = () => {
    handleAddHolidayForm(false)
    setSelectedHoliday(null)
  }

  const getHolidaysForYear = (year) => {
    if (currentYear === Number(year)) {
      return [...holidaysListCurrentYear]
    } else if (currentYear < Number(year)) {
      return [...holidaysListNextYear]
    } else {
      return [...holidaysListPastYear]
    }
  }

  let pastHolidays = []
  const updateHolidaysList = (year, holidaysForYear) => {
    holidaysForYear = holidaysForYear.filter(holiday => holiday.date.substring(0,4) < currentYear + 2)    
    if (currentYear === Number(year)) {
      setHolidaysListCurrentYear(holidaysForYear.sort((a: IHoliday, b: IHoliday) => dayjs(a.date).format() < dayjs(b.date).format() ? -1 : 1))
    } else if (currentYear + 1 === Number(year)) {
      setHolidaysListNextYear(holidaysForYear.sort((a: IHoliday, b: IHoliday) => dayjs(a.date).format() < dayjs(b.date).format() ? -1 : 1))
    } else {
      pastHolidays = pastHolidays.concat(holidaysForYear).filter((v,i,a)=>a.findIndex(t=>(JSON.stringify(t) === JSON.stringify(v)))===i)
      setHolidaysListPastYear(pastHolidays.sort((a: IHoliday, b: IHoliday) => dayjs(a.date).format() > dayjs(b.date).format() ? -1 : 1))
    }
    setSelectedHoliday(null)
  }

  const checkOverlap = (holidaysInYear, date) => {
    holidaysInYear.forEach(holiday => {
      if (holiday.multiDayId) {
        for (const day of dayjsIterator(holiday.date, holiday.endDate)) {
          if(day.format('YYYY-MM-DD') === date) {
            throw Error('overlap')
          }
        }
      } else {
        if (holiday.date === date) {
          throw Error('overlap')
        }
      }
    })
  }

  const getHolidayData = (holiday, holidayList) => {
    const data: IHoliday[] = []

    if (holiday.multiday) {
      const uuid = uuidv4()
      data.push({ date: holiday.startDate, endDate: holiday.endDate, multiDayId: uuid, name: holiday.name })
      for (const day of dayjsIterator(holiday.startDate, holiday.endDate)) {
        checkOverlap(holidayList, day.format('YYYY-MM-DD'))
      }
    } else {
      checkOverlap(holidayList, holiday.date)
      data.push({ name: holiday.name, date: holiday.date, isHalfDay: holiday.isHalfDay })
    }

    return data
  }

  const saveHolidays = async (year, newHolidayList) => {
    const payload: IHoliday[] = []
    newHolidayList.forEach(holiday => {
      if (holiday.multiDayId) {
        for (const day of dayjsIterator(holiday.date, holiday.endDate)) {
          payload.push({
            name: holiday.name,
            date: day.format('YYYY-MM-DD'),
            multiDayId: holiday.multiDayId,
          })
        }
      } else {
        payload.push(holiday)
      }
    })

    await onSaveHolidays({
      year,
      holidays: payload,
    })
  }

  const addHolidays = async (data) => {
    try {
      const year = data.multiday ? dayjs(data.startDate).format('YYYY') : dayjs(data.date).format('YYYY')
      setIsLoading(false)
      if (selectedHoliday) {
        const selectedHolidayYear = dayjs(selectedHoliday.date).format('YYYY')
        let holidaysForYear = getHolidaysForYear(year)

        if (selectedHolidayYear !== year) {
          const updateHolidayData = getHolidaysForYear(selectedHolidayYear)
          const indexSelectedholidays = updateHolidayData.findIndex(holiday => holiday.date === selectedHoliday.date)
          updateHolidayData.splice(indexSelectedholidays, 1)

          await saveHolidays(selectedHolidayYear, updateHolidayData)
          updateHolidaysList(selectedHolidayYear, updateHolidayData)
        } else {
          const indexSelectedholidays = holidaysForYear.findIndex(holiday => holiday.date === selectedHoliday.date)
          holidaysForYear.splice(indexSelectedholidays, 1)
        }

        const newHoliday = getHolidayData(data, holidaysForYear)
        holidaysForYear = newHoliday.concat(holidaysForYear)

        await saveHolidays(year, holidaysForYear)
        updateHolidaysList(year, holidaysForYear)
      } else {
        let holidaysForYear: IHoliday[] = getHolidaysForYear(year)

        const newHoliday = getHolidayData(data, holidaysForYear)
        holidaysForYear = newHoliday.concat(holidaysForYear)

        await saveHolidays(year, holidaysForYear)
        updateHolidaysList(year, holidaysForYear)
      }
      setIsLoading(false)
      handleAddHolidayForm(false)
    } catch (error) {
      if (error.message === 'overlap') {
        message.error(formatMessage({ id: 'components.locationHolidays.overlap' }), 5)
      } else {
        message.error(error.message)
      }
      setIsLoading(false)
      handleAddHolidayForm(false)
    }
  }

  const columns = [{
    title: <IntlMessages id="holidays.holidaysName" />,
    dataIndex: 'name',
    key: 'name',
  }, {
    title: <IntlMessages id="components.leavesColumns.dates" />,
    dataIndex: 'date',
    key: 'date',
    // eslint-disable-next-line react/display-name
    render: (date: string, row: IHoliday) => {
      return (
        <>
          <FormattedDate value={date} format='MMMM D' />
          {' '}{row.isHalfDay && <IntlMessages id="components.leavesColumns.halfDayHoliday" />}{' '}
          {row?.multiDayId && row?.endDate && (
            <>
              - <FormattedDate value={row?.endDate} format='MMMM D' />
            </>
          )}
        </>
      )
    },
  }, {
    title: <IntlMessages id="dashboard.days" />,
    dataIndex: 'date',
    key: 'day',
    // eslint-disable-next-line react/display-name
    render: (date: string, row: IHoliday) => {
      return (<>
        <FormattedDate value={date} format="dddd" /> {row.multiDayId && <>- <FormattedDate value={row.endDate as string} format="dddd" /></> }
      </>)
    },
  }, {
    title: ' ',
    dataIndex: 'id',
    className:'actions',
    key: 'id',
    // eslint-disable-next-line react/display-name
    render: (id: string, row: IHoliday) => {
      return (<>
        <Tooltip title={<IntlMessages id="components.addHolidayForm.editHoliday" />}>
          <Button type="link" onClick={() => handleEditHoliday(row)}>
            <EditOutlined style={{ color: '#87d068' }} />
          </Button>
        </Tooltip>
        <Tooltip title={<IntlMessages id="components.locationHolidays.deleteHolidaysTitle" />}>
          <Button type="link" onClick={() => handleDeleteHoliday(row)}>
            <DeleteOutlined style={{ color: '#f50' }} />
          </Button>
        </Tooltip>
      </>)
    },
  }]

  const locationTabs: TabsProps['items'] = [
    {
      key: ActiveTab.currentYear,
      label: formatMessage({ id: 'holidays.holidaysTab' }, { year: currentYear }),
      children: <Table
        dataSource={holidaysListCurrentYear}
        columns={columns}
        loading={isLoading}
        rowKey={record => `${record.date}-${record.name}`}
        pagination={false}
      />,
    },
    {
      key: ActiveTab.nextYear,
      label: formatMessage({ id: 'holidays.holidaysTab' }, { year: nextYear }),
      children: <Table
        dataSource={holidaysListNextYear}
        columns={columns}
        loading={isLoading}
        rowKey={record => `${record.date}-${record.name}`}
        pagination={false}
      />,
    },
    {
      key: ActiveTab.pastYear,
      label: formatMessage({ id: 'holidays.pastHolidays' }),
      children: <Table
        dataSource={holidaysListPastYear}
        columns={columns}
        loading={isLoading}
        rowKey={record => `${record.date}-${record.name}`}
        pagination={false}
      />,
    },
  ]

  return (
    <>
      <IntlMessages id="components.locationHolidays.holidaysForLocation" values={{ locationName }} />
      <Tabs
        onChange={(activeKey) => {
          switch (activeKey) {
            case ActiveTab.currentYear:
              setYear(currentYear)
              break
            case ActiveTab.nextYear:
              setYear(Number(nextYear))
              break
            case ActiveTab.pastYear:
              setYear(Number(dayjs().subtract(1, 'year').format('YYYY')))
              break
            default:
              setYear(Number(dayjs().format('YYYY')))
          }
        }}
        defaultActiveKey="1"
        tabBarExtraContent={<>
          <Button type="default" onClick={() => history.push(`/app/import-holidays/${locationId}/${year}`)} style={{ marginRight: 5 }} className="joyride-import-holidays">
            <IntlMessages id="holidays.importHolidays" />
          </Button>
          <Button type="default" onClick={() => handleAddHolidayForm(true)}><IntlMessages id="components.locationHolidays.addHolidays" /></Button>
        </>}
        items={locationTabs}
      />
      {showHolidayForm &&
        <AddHolidayForm
          visibleModal={showHolidayForm}
          handleCancel={closeModal}
          holiday={selectedHoliday}
          onSave={addHolidays}
          defaultDay={year === currentYear
            ? dayjs()
            : year === Number(nextYear)
              ? dayjs(`${nextYear}-01-01`)
              : dayjs(`${dayjs().subtract(1, 'year').format('YYYY')}-01-01`)
          }
        />
      }
    </>
  )
}

export default LocationHolidays
