import React, { useState, useEffect, useContext } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { App, Form, Button, Row, Col, Space, Switch, Typography, Divider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { loadStripe } from '@stripe/stripe-js'
import { useStripe, useElements, CardElement, Elements } from '@stripe/react-stripe-js'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import { getCompanyInfo, getCompanyBillingInfo } from '../../graphql/custom-queries'
import * as logger from '../../services/logger'
import { track } from '../../services/analytics/analytics'
import { wait } from '@vacationtracker/shared/functions/wait'
import { PlanPricePerUser } from '../../util/get-price-plan-bucket-info'
import { useAppSelector, useAppDispatch } from '../../store/hooks'
import { selectAuthCompanySlice, setAuthCompany } from '../../store/auth-company-slice'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { notificationStore } from '../../context/notificationsContext/store'
import { logout } from '../../services/auth/logout-handler'

import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../../components/circular-progress'
import BillingForm from '../../components/billing-form'
import PricePlanCard from '../../components/price-plan-card'
import BillingCouponCode from '../../components/billing-coupon-code'

import { BillingTypesEnum, IBillingAddress, SubscriptionPeriod, USER_PLAN_LIMIT } from '@vacationtracker/shared/types/billing'
import { IGetCompanyBillingInfoData } from '../../types/company'
import { SubscriptionPlan } from '@vacationtracker/shared/types/company'
import { IGetCompanyInfoData } from '../../types/custom-queries'

import { openSupportChat } from '../../util/open-support-chat'
import CurrentPlanMessage from '../../components/current-plan-message'
import { FrontendUrls } from '../../types/urls'


// Make sure to call `loadStripe` outside of a component’s render to avoid recreating the `Stripe` object on every render.
const stripePromise = process.env.REACT_APP_STRIPE_KEY ? loadStripe(process.env.REACT_APP_STRIPE_KEY) : Promise.reject('No Stripe Key')

const { Paragraph, Title } = Typography

const CheckoutForm = () => {
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const history = useHistory()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const dispatch = useAppDispatch()
  const { notification } = App.useApp()

  const [isLoading, setIsLoading] = useState(true)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [stripeCustomerId, setStripeCustomerId] = useState('')
  const [billingType, setBillingType] = useState('')
  const [seats, setSeats] = useState<number | null>(null)
  const [customerPortalLink, setCustomerPortalLink] = useState<string | undefined>()
  const [correlationId, setCorrelationId] = useState('')
  const [newPlan, setNewPlan] = useState<SubscriptionPlan>('Core')
  const [currentPeriod, setCurrentPeriod] = useState<SubscriptionPeriod>('monthly')
  const [newPeriod, setNewPeriod] = useState<SubscriptionPeriod>('monthly')
  const [numberOfUsers, setNumberOfUsers] = useState<number>(0)
  const [showPlans, setShowPlans] = useState<boolean>(false)
  const [isCouponDirty, setIsCouponDirty] = useState<boolean>(false)

  const stripe = useStripe()
  const elements = useElements()

  const [ getCompanyBillingInfoQuery ] = useManualQuery<IGetCompanyBillingInfoData>(getCompanyBillingInfo)
  const [ getCompanyInfoQuery ] = useManualQuery<IGetCompanyInfoData>(getCompanyInfo)

  useEffect(() => {
    track('RESUBSCRIPTION_PAGE_VIEWED', {})
    fetchBilling()
  }, [])

  useEffect(() => {
    if (Array.isArray(actionNotifications) &&
      (
        correlationId &&
        !actionNotifications.includes(correlationId)
      )) {
      getCompanyData()
    }
  }, [actionNotifications])

  useEffect(() => {
    if (authCompany && ['active', 'trialing'].includes(authCompany.subscriptionStatus)) {
      history.replace(FrontendUrls.dashboard)
    }
  }, [authCompany])

  useEffect(() => {
    if (stripeCustomerId) {
      getCustomerPortalLink()
        .then((link: string) => {
          setCustomerPortalLink(link)
        })
    }
  }, [stripeCustomerId])

  const fetchBilling = async () => {
    try {
      const response = await getCompanyBillingInfoQuery()
      if (!response.data || response.error) throw response.error
      const billing = response.data.getCompany.billing
      setNumberOfUsers(response.data.getCompany.numberOfActiveUsers || 1)

      setCurrentPeriod(billing.nextSubscriptionPeriod || billing.subscriptionPeriod || 'monthly')
      setNewPlan(billing.nextSubscriptionPlan || billing.subscriptionPlan || 'Core')
      setNewPeriod(billing.nextSubscriptionPeriod || billing.subscriptionPeriod || 'monthly')
      setSubmitLoader(false)

      if (billing) {
        form.setFieldsValue({
          billingName: billing.billingName,
          billingEmails: billing.billingEmails,
          city: billing.address && billing.address.city,
          country: billing.address && billing.address.country,
          state: billing.address && billing.address.state,
          address: billing.address && billing.address.address,
          addressLine2: billing.address && billing.address.addressLine2,
        })
      }

      setStripeCustomerId(billing?.stripeCustomerId)
      setBillingType(billing?.billingType || 'PAY_AS_YOU_GO')
      setSeats(billing?.seats || null)
      setIsLoading(false)
    } catch (err) {
      logger.error('error fetching billing', err)
      setSubmitLoader(false)
    }
  }

  const submit = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    try {
      const values = await form.validateFields()
      setSubmitLoader(true)

      let stripePaymentMethodId

      if (!values.sendInvoice) {
        const cardElement = elements.getElement(CardElement)

        if (!cardElement) {
          return
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
          type: 'card',
          card: cardElement,
        })

        if (error || !paymentMethod) {
          logger.error(error)
          setSubmitLoader(false)
          throw error
        } else {
          logger.error('[PaymentMethod]', paymentMethod)
        }

        stripePaymentMethodId = paymentMethod.id
      }

      const address: IBillingAddress = {
        city: values.city,
        country: values.country,
        state: values.state,
        address: values.address,
      }
      if (values.addressLine2) {
        address.addressLine2 = values.addressLine2
      }

      const response = await Api.post('/core/event', {
        eventType: 'BILLING_UPDATED',
        eventGroup: 'BILLING',
        paymentProcessor: 'stripe',
        billingName: values.billingName,
        billingEmails: values.billingEmails,
        billingType,
        seats: billingType === BillingTypesEnum.payAsYouGo ? undefined : seats,
        address,
        stripePaymentMethodId,
        stripeCouponId: values.promoCode,
        sendInvoice: values.sendInvoice,
        subscriptionPlan: newPlan,
        subscriptionPeriod: newPeriod,
        keepLegacyPlan: false,
        activeUsers: numberOfUsers,
      })

      track('RESUBSCRIPTION_PAGE_SUBMIT_BUTTON_CLICKED', {})
      track('CARD_ADDED_TOTAL', {})

      setCorrelationId(response.correlationId)

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'subscription.updateInProgress' }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])

    } catch (error) {
      if (error.message) {
        notification.error({
          message: error.message,
        })
      } else {
        notification.error({
          message: JSON.stringify(error) ? JSON.stringify(error) : error,
        })
      }
      setSubmitLoader(false)
    }
  }

  const getCustomerPortalLink = async (): Promise<string> => {
    const portalSession = await Api.post('/stripe/create-customer-portal-session', {
      stripeCustomerId,
      redirectUrl: window.location.href,
    })

    return portalSession.url
  }

  const onLogout = () => {
    logout({
      history,
      reduxDispatch: dispatch,
      userId: authUser.id,
    })
  }

  const layout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 24 },
  }

  let numberOfRetry = 1
  const getCompanyData = async () => {
    try {
      const response = await getCompanyInfoQuery()
      if (!response.data || response.error) throw response.error
      if (response.data.getCompany.subscriptionStatus !== 'active' && numberOfRetry < 8) {
        numberOfRetry++
        await wait(200 * numberOfRetry)
        return await getCompanyData()
      }
      if (numberOfRetry >= 8) {
        throw new Error('Get company data failed')
      }
      dispatch(setAuthCompany(response.data.getCompany))
    } catch (err) {
      setSubmitLoader(false)
      logger.error('ERROR GET COMPANY DATA', err)
    }
  }

  const changePeriod = (period) => {
    setNewPeriod(period ? 'annual' : 'monthly')
  }

  const changePlanHandler = (plan: SubscriptionPlan) => {
    setNewPlan(plan)
  }

  return (
    <section className="ant-layout ant-layout-has-sider app-layout">
      <section className="ant-layout content-layout">
        <main className="ant-layout-content">
          <div className="main-content-wrapper" style={{ padding: '10px' }}>
            <div className="main-content form-background" style={{ padding: '0px' }}>
              <div className="main-content-wrapper subscription-wrapper">
                <div className="main-content-header form-layout">
                  <div className="main-content-header-title">
                    <span><IntlMessages id="subscription.title" /></span>
                  </div>
                  <div className="ant-card-extra">
                    <Button type="text" onClick={() => onLogout()}><IntlMessages id="app.logout" /></Button>
                  </div>
                </div>
                <div className="main-content-body form-layout">
                  <Paragraph>
                    <IntlMessages
                      id="billing.subscriptionExpiredText"
                      values={{
                        supportLink: <a onClick={() => openSupportChat()}><IntlMessages id="components.helpDrawer.supportTitle" /></a>,
                      }}
                    />
                  </Paragraph>
                  <Divider />
                  <div className="billing-body form-layout">
                    {isLoading ? <CircularProgress /> :
                      <Form
                        form={form}
                        layout="horizontal"
                        name="billingForm"
                        size="large"
                        onFinish={submit}
                        {...layout}
                      >
                        <Row gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 32,
                        }}>
                          <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
                            <Title level={4}>
                              <IntlMessages id="components.billingForm.planTitle" />
                            </Title>
                            <Paragraph type="secondary">
                              <IntlMessages id="billing.pricingPlansText" />
                            </Paragraph>
                          </Col>
                          <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
                            <Form.Item label={<IntlMessages id="billing.SubscriptionPlan" />} colon={false}>
                              <div className='switch-plan-wrapper'>
                                <div className='wrapper-plan-vt'>
                                  <Paragraph>
                                    <CurrentPlanMessage period={newPeriod} plan={newPlan} noOfActiveUsers={numberOfUsers} />
                                  </Paragraph>
                                  <Button size="large" type="primary"  disabled={isLoading} onClick={() => setShowPlans(!showPlans)} ghost>
                                    <IntlMessages id={showPlans ? 'subscription.hidePlansButton' : 'subscription.showPlansButton'} />
                                  </Button>
                                </div>
                              </div>
                            </Form.Item>
                            {showPlans && <>
                              <Row justify='center' style={{ marginBottom: 20 }}>
                                <Col>
                                  <Space>
                                    <IntlMessages id="app.monthly" />
                                    <Switch
                                      style={{ backgroundColor: 'grey' }}
                                      checked={newPeriod === 'annual'}
                                      onChange={changePeriod}
                                    />
                                    <IntlMessages id="app.billing.periodAnnual" />
                                  </Space>
                                </Col>
                              </Row>

                              <Row justify='end' style={{ marginBottom: 10 }} gutter={16}>
                                <Col xxl={{ span: 12, offset: 0 }} xl={12} lg={12} md={12} sm={24} xs={24} style={{ display: 'flex' }}>
                                  <PricePlanCard
                                    plan='Core'
                                    totalPrice={numberOfUsers > USER_PLAN_LIMIT ? numberOfUsers : USER_PLAN_LIMIT}
                                    totalUsers={numberOfUsers}
                                    pricePerUser={PlanPricePerUser.Core}
                                    currentPeriod={currentPeriod}
                                    newPeriod={newPeriod}
                                    currentPlan={newPlan}
                                    showRevertingInfo={true}
                                    isSignup={false}
                                    isResubscribe={true}
                                    onSelectPLan={() => changePlanHandler('Core')}
                                    isTrialPeriod={false}
                                  />
                                </Col>
                                <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24} style={{ display: 'flex' }}>
                                  <PricePlanCard
                                    plan='Complete'
                                    totalPrice={numberOfUsers > USER_PLAN_LIMIT ? numberOfUsers * PlanPricePerUser.Complete : USER_PLAN_LIMIT * PlanPricePerUser.Complete}
                                    totalUsers={numberOfUsers}
                                    pricePerUser={PlanPricePerUser.Complete}
                                    currentPeriod={currentPeriod}
                                    newPeriod={newPeriod}
                                    currentPlan={newPlan}
                                    showRevertingInfo={true}
                                    isSignup={false}
                                    isResubscribe={true}
                                    onSelectPLan={() => changePlanHandler('Complete')}
                                    isTrialPeriod={false}
                                  />
                                </Col>
                              </Row>
                            </>
                            }
                          </Col>
                        </Row>

                        <Divider />

                        <Row gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 32,
                        }}>
                          <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
                            <Title level={4}>
                              <IntlMessages id="components.billingInfo.title" />
                            </Title>
                            <Paragraph type="secondary">
                              <IntlMessages id="components.billingInfo.text" />
                            </Paragraph>
                          </Col>
                          <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
                            <BillingForm form={form} />
                          </Col>
                        </Row>

                        <Divider />

                        <Row gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 32,
                        }}>
                          <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
                            <Title level={4}>
                              <IntlMessages id="components.billingCouponCode.title" />
                            </Title>
                            <Paragraph type="secondary">
                              <IntlMessages id="components.billingCouponCode.text" />
                            </Paragraph>
                          </Col>
                          <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
                            <BillingCouponCode
                              subscriptionCanceled={true}
                              isApplingCoupon={submitLoader}
                              isCouponDirty={isCouponDirty}
                              setIsCouponDirty={setIsCouponDirty}
                            />
                          </Col>
                        </Row>

                        <Divider />
                        <Row gutter={{
                          xs: 8,
                          sm: 16,
                          md: 24,
                          lg: 32,
                        }}>
                          <Col span={24}>
                            <Form.Item style={{ textAlign: 'right' }}>
                              {customerPortalLink &&
                                <Button className="button-right" type="link" href={customerPortalLink} size='large'>
                                  <IntlMessages id="subscription.stripeCustomerPortalLink" />
                                </Button>
                              }
                              <Button className="button-right" size="large" type="primary" loading={submitLoader} htmlType="submit" disabled={submitLoader}>
                                <IntlMessages id="subscription.subscribe" />
                              </Button>
                            </Form.Item>
                          </Col>
                        </Row>
                      </Form>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </section>
    </section>
  )
}

const SubscriptionPage = () => {
  return (
    <Elements stripe={stripePromise}>
      <CheckoutForm />
    </Elements>
  )
}

export default SubscriptionPage
