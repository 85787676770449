import React from 'react'
import { useIntl } from 'react-intl'
import { Button, Modal, Typography } from 'antd'
import IntlMessages from '../../util/IntlMessages'
import { openSupportChat } from '../../util/open-support-chat'
import { track } from '../../services/analytics/analytics'
import { ICompanyExistsModalProps } from '../../types/auth'

const { Paragraph, Title } = Typography

const CompanyExistsModal = ({
  companyExists,
  setCompanyExists,
  contactAdminModal,
  userEmail,
  userWorkspaceName,
}: ICompanyExistsModalProps) => {
  const { formatMessage } = useIntl()

  const contactSupport = () => openSupportChat('ORG_ALREADY_EXISTS_PAGE_SUPPORT_BUTTON_CLICKED')
  
  return (
    <Modal
      title={formatMessage({ id: 'error.companyExists' })}
      open={companyExists}
      closable={false}
      footer={[
        <Button
          key="submit"
          type="primary"
          onClick={() => setCompanyExists(false) }
        >
          <IntlMessages id="app.cancel" />
        </Button>,
      ]}
    >
      <Title level={4}><IntlMessages id="connect.companyExists.line1" values={{ companyName: contactAdminModal.organizationName }} /></Title>

      {(userEmail && userEmail.length > 0) &&
        <>
          { contactAdminModal.platform === 'slack' &&
            <>
              <Title level={5}><IntlMessages id="connect.companyExists.line2Slack" /></Title>
              <Title level={5}><IntlMessages id="connect.companyExists.lineEmailAddress" />: {userEmail}</Title>
              <Title level={5}><IntlMessages id="connect.companyExists.line3aSlack" values={{ workspaceName: userWorkspaceName }} /></Title>
              <Paragraph><IntlMessages id="connect.companyExists.line4Slack" /></Paragraph>
            </>
          }
          { contactAdminModal.platform !== 'slack' &&
            <>
              <Title level={5}><IntlMessages id="connect.companyExists.line2Other" /></Title>
              <Title level={5}><IntlMessages id="connect.companyExists.lineEmailAddress" />: {userEmail}</Title>
              <Paragraph><IntlMessages id="connect.companyExists.line4Other" /></Paragraph>
            </>
          }
        </>
      }

      {contactAdminModal.adminContacts && contactAdminModal.adminContacts.length > 0 && contactAdminModal.adminContacts.map(adminContact =>
        <Paragraph key={adminContact.email} style={{ textAlign: 'center' }} copyable={{
          onCopy: () => track('ORG_ALREADY_EXISTS_PAGE_EMAIL_BUTTON_CLICKED', {}),
          tooltips: <IntlMessages id="connect.copyEmail" />,
          text: adminContact.email,
        }}>
          {adminContact.name}: {adminContact.email}
        </Paragraph>
      )}
      {(!contactAdminModal.adminContacts || contactAdminModal.adminContacts.length === 0) &&
        <Paragraph key={contactAdminModal.contactEmail} style={{ textAlign: 'center' }} copyable={{
          onCopy: () => track('ORG_ALREADY_EXISTS_PAGE_EMAIL_BUTTON_CLICKED', {}),
          tooltips: <IntlMessages id="connect.copyEmail" />,
          text: contactAdminModal.contactEmail,
        }}>
          {contactAdminModal.contactEmail}
        </Paragraph>
      }

      <IntlMessages id="connect.companyExists.line5" values={{
        platform: contactAdminModal.platform,
        // eslint-disable-next-line
        capitalize: (...chunks) => (
          <span style={{ textTransform: 'capitalize' }}>
            {chunks}
          </span>
        ),
      }} />

      {contactAdminModal.platform === 'microsoft' &&
        <>
          <Paragraph style={{ textAlign: 'center' }}>{ formatMessage({id: 'app.or'}).toLowerCase() }</Paragraph>
          <Paragraph>
            <Button
              block
              style={{whiteSpace:'normal', height: 'auto'}}
              onClick={contactSupport}
            >
              <IntlMessages id="connect.companyExists.line6Microsoft" values={{ companyName: contactAdminModal.organizationName }} />
            </Button>
          </Paragraph>
        </>
      }
    </Modal>
  )
}

export default CompanyExistsModal
