import { SubscriptionPlanEnum } from '../../types/company'

export enum ProductName {
  CORE_MONTHLY      = 'CORE_MONTHLY',
  CORE_ANNUAL       = 'CORE_ANNUAL',
  COMPLETE_MONTHLY  = 'COMPLETE_MONTHLY',
  COMPLETE_ANNUAL   = 'COMPLETE_ANNUAL',
  ADDONS            = 'ADDONS',
  AUTOMATIONS       = 'AUTOMATIONS',
}

export enum ProductId {
  CORE_MONTHLY_TEST     = 'prod_HrVH7yZjNc36Ub',
  CORE_MONTHLY_PROD     = 'prod_HrUeBQozbmPTqb',
  CORE_ANNUAL_TEST      = 'prod_KTMy2Po9CpwOHL',
  CORE_ANNUAL_PROD      = 'prod_KTMwKrh2RHR9fK',
  COMPLETE_MONTHLY_TEST = 'prod_HrVJlfO91RbpjT',
  COMPLETE_MONTHLY_PROD = 'prod_I82uFVxfsAjLzL',
  COMPLETE_ANNUAL_TEST  = 'prod_KTMyz42oGZE7Fi',
  COMPLETE_ANNUAL_PROD  = 'prod_KTMsQGqvZdSD69',
  ADDONS_TEST           = 'prod_Ozy7ZOzEbgq2A6',
  ADDONS_PROD           = 'prod_OqH7dTOU7SFJvi',
  AUTOMATIONS_TEST      = 'prod_PBBX3BJSCQALSC',
  AUTOMATIONS_PROD      = 'prod_PBBejqIMqh3eVj',
}

export enum PriceName {
  CORE_MONTHLY_LEGACY         = 'CORE_MONTHLY_LEGACY',
  CORE_MONTHLY_SLACK          = 'CORE_MONTHLY_SLACK',
  CORE_MONTHLY_MICROSOFT      = 'CORE_MONTHLY_MICROSOFT',
  CORE_MONTHLY_GOOGLE         = 'CORE_MONTHLY_GOOGLE',
  CORE_MONTHLY_EMAIL          = 'CORE_MONTHLY_EMAIL',
  CORE_ANNUAL_LEGACY          = 'CORE_ANNUAL_LEGACY',
  CORE_ANNUAL_SLACK           = 'CORE_ANNUAL_SLACK',
  CORE_ANNUAL_MICROSOFT       = 'CORE_ANNUAL_MICROSOFT',
  CORE_ANNUAL_GOOGLE          = 'CORE_ANNUAL_GOOGLE',
  CORE_ANNUAL_EMAIL           = 'CORE_ANNUAL_EMAIL',
  CORE_ANNUAL_2024            = 'CORE_ANNUAL_2024',
  COMPLETE_MONTHLY_SLACK      = 'COMPLETE_MONTHLY_SLACK',
  COMPLETE_MONTHLY_GOOGLE     = 'COMPLETE_MONTHLY_GOOGLE',
  COMPLETE_MONTHLY_EMAIL      = 'COMPLETE_MONTHLY_EMAIL',
  COMPLETE_MONTHLY_MICROSOFT  = 'COMPLETE_MONTHLY_MICROSOFT',
  COMPLETE_MONTHLY_2023       = 'COMPLETE_MONTHLY_2023',
  COMPLETE_MONTHLY_2024_2_5   = 'COMPLETE_MONTHLY_2024_2_5',
  COMPLETE_ANNUAL_SLACK       = 'COMPLETE_ANNUAL_SLACK',
  COMPLETE_ANNUAL_GOOGLE      = 'COMPLETE_ANNUAL_GOOGLE',
  COMPLETE_ANNUAL_EMAIL       = 'COMPLETE_ANNUAL_EMAIL',
  COMPLETE_ANNUAL_MICROSOFT   = 'COMPLETE_ANNUAL_MICROSOFT',
  COMPLETE_ANNUAL_2023        = 'COMPLETE_ANNUAL_2023',
  COMPLETE_ANNUAL_2024        = 'COMPLETE_ANNUAL_2024',
  COMPLETE_ANNUAL_2024_2_5    = 'COMPLETE_ANNUAL_2024_2_5',
  ADDONS_MONTHLY              = 'ADDONS_MONTHLY',
  ADDONS_ANNUAL               = 'ADDONS_ANNUAL',
  ADDONS_ANNUAL_LEGACY        = 'ADDONS_ANNUAL_LEGACY',
  AUTOMATIONS_MONTHLY         = 'AUTOMATIONS_MONTHLY',
  AUTOMATIONS_ANNUAL          = 'AUTOMATIONS_ANNUAL'
}

export enum PriceId {
  CORE_MONTHLY_LEGACY_TEST          = 'price_1HlZSVCZGLYlCm3DeSZsWGlr',
  CORE_MONTHLY_LEGACY_PROD          = 'price_1HyHM8CZGLYlCm3DsDNc5Rgh',
  CORE_MONTHLY_SLACK_TEST           = 'price_1HyaReCZGLYlCm3DyrqwDWEz',
  CORE_MONTHLY_SLACK_PROD           = 'price_1HyaUoCZGLYlCm3De8guIynx',
  CORE_MONTHLY_MICROSOFT_TEST       = 'price_1HdtBhCZGLYlCm3Dhof4ODgK',
  CORE_MONTHLY_MICROSOFT_PROD       = 'price_1HeHvtCZGLYlCm3DtjLSsKDR',
  CORE_MONTHLY_GOOGLE_TEST          = 'price_1IctMTCZGLYlCm3DdL8TxqMs',
  CORE_MONTHLY_GOOGLE_PROD          = 'price_1IctNZCZGLYlCm3DKxJpMBJ1',
  CORE_MONTHLY_EMAIL_TEST           = 'price_1L6WXcCZGLYlCm3DdfSBT7Ob',
  CORE_MONTHLY_EMAIL_PROD           = 'price_1L6WhUCZGLYlCm3D14LJPwNw',
  CORE_ANNUAL_SLACK_TEST            = 'price_1JoQF9CZGLYlCm3Ds2rzNaFH',
  CORE_ANNUAL_SLACK_PROD            = 'price_1JoQDcCZGLYlCm3DFsiK8daM',
  CORE_ANNUAL_MICROSOFT_TEST        = 'price_1JoQFXCZGLYlCm3Dh2FE8zDy',
  CORE_ANNUAL_MICROSOFT_PROD        = 'price_1JoQDGCZGLYlCm3DXRKbskyt',
  CORE_ANNUAL_GOOGLE_TEST           = 'price_1JoQFMCZGLYlCm3D2bdXmN8b',
  CORE_ANNUAL_GOOGLE_PROD           = 'price_1JoQDSCZGLYlCm3DTjh8hfzV',
  CORE_ANNUAL_EMAIL_TEST            = 'price_1L6Wd5CZGLYlCm3DQOMPVxed',
  CORE_ANNUAL_EMAIL_PROD            = 'price_1L6WkvCZGLYlCm3Dne2mNrdl',
  CORE_ANNUAL_2024_TEST             = 'price_1P0l9BCZGLYlCm3DMEUOtSdL',
  CORE_ANNUAL_2024_PROD             = 'price_1PDk2NCZGLYlCm3DFxp3OvM5',
  CORE_ANNUAL_LEGACY_TEST           = 'price_1HlZ75CZGLYlCm3Dx01szoSj',
  CORE_ANNUAL_LEGACY_PROD           = 'price_1HyIJYCZGLYlCm3DSBaYeqVA',
  COMPLETE_MONTHLY_SLACK_TEST       = 'price_1JZXkqCZGLYlCm3DDwGSsX2t',
  COMPLETE_MONTHLY_SLACK_PROD       = 'price_1JZYwQCZGLYlCm3DKj36BW5m',
  COMPLETE_MONTHLY_GOOGLE_TEST      = 'price_1JZXhrCZGLYlCm3D89R5dHGs',
  COMPLETE_MONTHLY_GOOGLE_PROD      = 'price_1JZYwtCZGLYlCm3DFadfKGvG',
  COMPLETE_MONTHLY_EMAIL_TEST       = 'price_1L6WYuCZGLYlCm3DfQo1laUj',
  COMPLETE_MONTHLY_EMAIL_PROD       = 'price_1L6WiuCZGLYlCm3DKXb54qvX',
  COMPLETE_MONTHLY_MICROSOFT_TEST   = 'price_1JZXilCZGLYlCm3DeIbQh0KV',
  COMPLETE_MONTHLY_MICROSOFT_PROD   = 'price_1JZYvnCZGLYlCm3D7qJWqMWx',
  COMPLETE_MONTHLY_2023_TEST        = 'price_1O5OMyCZGLYlCm3DTddTdH12',
  COMPLETE_MONTHLY_2023_PROD        = 'price_1O1mGWCZGLYlCm3DC9Advl1K',
  COMPLETE_MONTHLY_2024_2_5_TEST    = 'price_1P0lnvCZGLYlCm3Da7Gm91Es',
  COMPLETE_MONTHLY_2024_2_5_PROD    = 'price_1PDjxXCZGLYlCm3DnsbI8yCn',
  COMPLETE_ANNUAL_SLACK_TEST        = 'price_1JoQGXCZGLYlCm3Di4Rul375',
  COMPLETE_ANNUAL_SLACK_PROD        = 'price_1JoQBeCZGLYlCm3DhuSSJaxT',
  COMPLETE_ANNUAL_GOOGLE_TEST       = 'price_1JoQGOCZGLYlCm3DhSjwd0g0',
  COMPLETE_ANNUAL_GOOGLE_PROD       = 'price_1JoQBOCZGLYlCm3Dfxy4DpW0',
  COMPLETE_ANNUAL_EMAIL_TEST        = 'price_1L6WdvCZGLYlCm3DECujLTxW',
  COMPLETE_ANNUAL_EMAIL_PROD        = 'price_1L6WmBCZGLYlCm3DVyZ6NChW',
  COMPLETE_ANNUAL_MICROSOFT_TEST    = 'price_1JoQGGCZGLYlCm3DtIRh2F0F',
  COMPLETE_ANNUAL_MICROSOFT_PROD    = 'price_1JoQB1CZGLYlCm3D2rgy51Po',
  COMPLETE_ANNUAL_2023_TEST         = 'price_1NxsqdCZGLYlCm3DVVhTf8h9',
  COMPLETE_ANNUAL_2023_PROD         = 'price_1O1mUVCZGLYlCm3DWOPnkmSa',
  COMPLETE_ANNUAL_2024_TEST         = 'price_1P13auCZGLYlCm3DBw97posN',
  COMPLETE_ANNUAL_2024_PROD         = 'price_1PDjynCZGLYlCm3D4SVjwXRn',
  COMPLETE_ANNUAL_2024_2_5_TEST     = 'price_1P13bYCZGLYlCm3DuBGtwqOO',
  COMPLETE_ANNUAL_2024_2_5_PROD     = 'price_1PDk1JCZGLYlCm3DS06RyaES',
  ADDONS_MONTHLY_TEST               = 'price_1OByB8CZGLYlCm3D5h3P890l',
  ADDONS_MONTHLY_PROD               = 'price_1O2aZPCZGLYlCm3DWYNbnBuN',
  ADDONS_ANNUAL_TEST                = 'price_1OMpKTCZGLYlCm3D4Dklx4EC',
  ADDONS_ANNUAL_PROD                = 'price_1OMpONCZGLYlCm3Dfc3YNaFg',
  ADDONS_ANNUAL_LEGACY_TEST         = 'not_set________!!!_________ADDONS_ANNUAL_LEGACY_TEST',
  ADDONS_ANNUAL_LEGACY_PROD         = 'price_1O2aZPCZGLYlCm3DBID7ZsTl',
  AUTOMATIONS_MONTHLY_TEST          = 'price_1OMpAdCZGLYlCm3DyrX3ELmm',
  AUTOMATIONS_MONTHLY_PROD          = 'price_1OMpHdCZGLYlCm3DYcbXF3uF',
  AUTOMATIONS_ANNUAL_TEST           = 'price_1P1rMjCZGLYlCm3DKI2GiRQJ',
  AUTOMATIONS_ANNUAL_PROD           = 'price_1OMpHdCZGLYlCm3DZ6QRQgtQ',
}

type Period = 'annual' | 'monthly'

type Currency = 'usd'

type UsageType = 'recurring'

type BillingScheme = 'per_unit' | 'tiered'

interface ITier {
  up_to: number | 'inf'
  flat_amount?: number
  unit_amount?: number
}

export interface IProduct {
  id: ProductId
  name: ProductName
  prices: IPrice[]
  description?: string
  isMainProduct: boolean
}

export interface IPrice {
  id: PriceId
  name: PriceName
  period: Period
  legacy: boolean
  currency: Currency
  usageType: UsageType
  billingScheme: BillingScheme
  tiers: ITier[]
  plan?: SubscriptionPlanEnum
  description?: string
}
/**
 * this is configuration for the product and prices, products should be used in code with ProductService
 * @param test 
 * @private
 * @returns IProduct[]
 */
export function getProducts(test: boolean): IProduct[] {
  return [
    {
      name: ProductName.CORE_MONTHLY,
      description: 'V2 Core Monthly',
      id: test ? ProductId.CORE_MONTHLY_TEST : ProductId.CORE_MONTHLY_PROD,
      isMainProduct: true,
      prices: [
        {
          name: PriceName.CORE_MONTHLY_SLACK,
          description: 'Core - Monthly Slack',
          id: test ? PriceId.CORE_MONTHLY_SLACK_TEST : PriceId.CORE_MONTHLY_SLACK_PROD,
          period: 'monthly',
          plan: SubscriptionPlanEnum.core,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createUnitTiers(1, 25),
        },
        {
          name: PriceName.CORE_MONTHLY_MICROSOFT,
          description: 'Core - Monthly Microsoft',
          id: test ? PriceId.CORE_MONTHLY_MICROSOFT_TEST : PriceId.CORE_MONTHLY_MICROSOFT_PROD,
          period: 'monthly',
          plan: SubscriptionPlanEnum.core,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createUnitTiers(1, 25),
        },
        {
          name: PriceName.CORE_MONTHLY_GOOGLE,
          description: 'Core - Monthly Google',
          id: test ? PriceId.CORE_MONTHLY_GOOGLE_TEST : PriceId.CORE_MONTHLY_GOOGLE_PROD,
          period: 'monthly',
          plan: SubscriptionPlanEnum.core,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createUnitTiers(1, 25),
        },
        {
          name: PriceName.CORE_MONTHLY_EMAIL,
          description: 'Core - Monthly Email',
          id: test ? PriceId.CORE_MONTHLY_EMAIL_TEST : PriceId.CORE_MONTHLY_EMAIL_PROD,
          period: 'monthly',
          plan: SubscriptionPlanEnum.core,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createUnitTiers(1, 25),
        },
        {
          name: PriceName.CORE_MONTHLY_LEGACY,
          description: 'Core - V1 Legacy',
          id: test ? PriceId.CORE_MONTHLY_LEGACY_TEST : PriceId.CORE_MONTHLY_LEGACY_PROD,
          period: 'monthly',
          plan: SubscriptionPlanEnum.core,
          legacy: true,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: [
            { flat_amount: 2500, up_to: 50 },
            { unit_amount: 100, up_to: 'inf' },
          ],
        },
      ],
    },
    {
      name: ProductName.CORE_ANNUAL,
      description: 'V2 Core Annual',
      id: test ? ProductId.CORE_ANNUAL_TEST : ProductId.CORE_ANNUAL_PROD,
      isMainProduct: true,
      prices: [
        {
          name: PriceName.CORE_ANNUAL_SLACK,
          description: 'Core - Annual Slack',
          id: test ? PriceId.CORE_ANNUAL_SLACK_TEST : PriceId.CORE_ANNUAL_SLACK_PROD,
          period: 'annual',
          plan: SubscriptionPlanEnum.core,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: getLegacyCoreAnnualTiers(),
        },
        {
          name: PriceName.CORE_ANNUAL_MICROSOFT,
          description: 'Core - Annual Microsoft',
          id: test ? PriceId.CORE_ANNUAL_MICROSOFT_TEST : PriceId.CORE_ANNUAL_MICROSOFT_PROD,
          period: 'annual',
          plan: SubscriptionPlanEnum.core,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: getLegacyCoreAnnualTiers(),
        },
        {
          name: PriceName.CORE_ANNUAL_GOOGLE,
          description: 'Core - Annual Google',
          id: test ? PriceId.CORE_ANNUAL_GOOGLE_TEST : PriceId.CORE_ANNUAL_GOOGLE_PROD,
          period: 'annual',
          plan: SubscriptionPlanEnum.core,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: getLegacyCoreAnnualTiers(),
        },
        {
          name: PriceName.CORE_ANNUAL_EMAIL,
          description: 'Core - Annual Email',
          id: test ? PriceId.CORE_ANNUAL_EMAIL_TEST : PriceId.CORE_ANNUAL_EMAIL_PROD,
          period: 'annual',
          plan: SubscriptionPlanEnum.core,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: getLegacyCoreAnnualTiers(),
        },
        {
          name: PriceName.CORE_ANNUAL_2024,
          description: 'Core - Annual 2024',
          id: test ? PriceId.CORE_ANNUAL_2024_TEST : PriceId.CORE_ANNUAL_2024_PROD,
          period: 'annual',
          plan: SubscriptionPlanEnum.core,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createTiers(1, 25, 3001, [100, 200, 500, 1000, 2000, 3000, 3001], 20, 1),
        },
        {
          name: PriceName.CORE_ANNUAL_LEGACY,
          description: 'Core - V1 Legacy Annual',
          id: test ? PriceId.CORE_ANNUAL_LEGACY_TEST : PriceId.CORE_ANNUAL_LEGACY_PROD,
          period: 'annual',
          plan: SubscriptionPlanEnum.core,
          legacy: true,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: [
            { unit_amount: 1000, up_to: 1 },
            { unit_amount: 1000, up_to: 'inf' },
          ],
        },
      ],
    },
    {
      name: ProductName.COMPLETE_MONTHLY,
      description: 'V2 Complete Monthly',
      id: test ? ProductId.COMPLETE_MONTHLY_TEST : ProductId.COMPLETE_MONTHLY_PROD,
      isMainProduct: true,
      prices: [
        {
          name: PriceName.COMPLETE_MONTHLY_SLACK,
          description: 'Complete - Monthly Slack',
          id: test ? PriceId.COMPLETE_MONTHLY_SLACK_TEST : PriceId.COMPLETE_MONTHLY_SLACK_PROD,
          period: 'monthly',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createUnitTiers(2, 25),
        },
        {
          name: PriceName.COMPLETE_MONTHLY_GOOGLE,
          description: 'Complete - Monthly Google',
          id: test ? PriceId.COMPLETE_MONTHLY_GOOGLE_TEST : PriceId.COMPLETE_MONTHLY_GOOGLE_PROD,
          period: 'monthly',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createUnitTiers(2, 25),
        },
        {
          name: PriceName.COMPLETE_MONTHLY_EMAIL,
          description: 'Complete - Monthly Email',
          id: test ? PriceId.COMPLETE_MONTHLY_EMAIL_TEST : PriceId.COMPLETE_MONTHLY_EMAIL_PROD,
          period: 'monthly',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createUnitTiers(2, 25),
        },
        {
          name: PriceName.COMPLETE_MONTHLY_MICROSOFT,
          description: 'Complete - Monthly Microsoft',
          id: test ? PriceId.COMPLETE_MONTHLY_MICROSOFT_TEST : PriceId.COMPLETE_MONTHLY_MICROSOFT_PROD,
          period: 'monthly',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createUnitTiers(2, 25),
        },
        {
          name: PriceName.COMPLETE_MONTHLY_2023,
          description: 'Complete - Monthly 2023',
          id: test ? PriceId.COMPLETE_MONTHLY_2023_TEST : PriceId.COMPLETE_MONTHLY_2023_PROD,
          period: 'monthly',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createUnitTiers(3, 25),
        },
        {
          name: PriceName.COMPLETE_MONTHLY_2024_2_5,
          description: 'Complete - Monthly 2024 2.5',
          id: test ? PriceId.COMPLETE_MONTHLY_2024_2_5_TEST : PriceId.COMPLETE_MONTHLY_2024_2_5_PROD,
          period: 'monthly',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createUnitTiers(2.5, 25),
        },
      ],
    },
    {
      name: ProductName.COMPLETE_ANNUAL,
      description: 'V2 Complete Annual',
      id: test ? ProductId.COMPLETE_ANNUAL_TEST : ProductId.COMPLETE_ANNUAL_PROD,
      isMainProduct: true,
      prices: [
        {
          id: test ? PriceId.COMPLETE_ANNUAL_SLACK_TEST : PriceId.COMPLETE_ANNUAL_SLACK_PROD,
          name: PriceName.COMPLETE_ANNUAL_SLACK,
          description: 'Complete - Annual Slack',
          period: 'annual',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: getLegacyCompleteAnnualTiers(),
        },
        {
          id: test ? PriceId.COMPLETE_ANNUAL_GOOGLE_TEST : PriceId.COMPLETE_ANNUAL_GOOGLE_PROD,
          name: PriceName.COMPLETE_ANNUAL_GOOGLE,
          description: 'Complete - Annual Google',
          period: 'annual',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: getLegacyCompleteAnnualTiers(),
        },
        {
          id: test ? PriceId.COMPLETE_ANNUAL_EMAIL_TEST : PriceId.COMPLETE_ANNUAL_EMAIL_PROD,
          name: PriceName.COMPLETE_ANNUAL_EMAIL,
          description: 'Complete - Annual Email',
          period: 'annual',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: getLegacyCompleteAnnualTiers(),
        },
        {
          id: test ? PriceId.COMPLETE_ANNUAL_MICROSOFT_TEST : PriceId.COMPLETE_ANNUAL_MICROSOFT_PROD,
          name: PriceName.COMPLETE_ANNUAL_MICROSOFT,
          description: 'Complete - Annual Microsoft',
          period: 'annual',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: getLegacyCompleteAnnualTiers(),
        },
        {
          id: test ? PriceId.COMPLETE_ANNUAL_2023_TEST : PriceId.COMPLETE_ANNUAL_2023_PROD,
          name: PriceName.COMPLETE_ANNUAL_2023,
          description: 'Complete - Annual 2023',
          period: 'annual',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createTiers(3, 25, 3001, [100, 200, 500, 1000, 2000, 3000, 3001], 20, 2),
        },
        {
          id: test ? PriceId.COMPLETE_ANNUAL_2024_TEST : PriceId.COMPLETE_ANNUAL_2024_PROD,
          name: PriceName.COMPLETE_ANNUAL_2024,
          description: 'Complete - Annual 2024',
          period: 'annual',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createTiers(3, 25, 3001, [100, 200, 500, 1000, 2000, 3000, 3001], 20, 1),
        },
        {
          id: test ? PriceId.COMPLETE_ANNUAL_2024_2_5_TEST : PriceId.COMPLETE_ANNUAL_2024_2_5_PROD,
          name: PriceName.COMPLETE_ANNUAL_2024_2_5,
          description: 'Complete - Annual 2024 2.5',
          period: 'annual',
          plan: SubscriptionPlanEnum.complete,
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createTiers(2.5, 25, 3001, [100, 200, 500, 1000, 2000, 3000, 3001], 20, 1),
        },
      ],
    },
    {
      name: ProductName.ADDONS,
      description: 'Add-ons',
      id: test ? ProductId.ADDONS_TEST : ProductId.ADDONS_PROD,
      isMainProduct: false,
      prices: [
        {
          name: PriceName.ADDONS_MONTHLY,
          description: 'Add-ons - Monthly',
          id: test ? PriceId.ADDONS_MONTHLY_TEST : PriceId.ADDONS_MONTHLY_PROD,
          period: 'monthly',
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: [{ unit_amount: 50, up_to: 'inf' }],
        },
        {
          name: PriceName.ADDONS_ANNUAL,
          description: 'Add-ons - Annual',
          id: test ? PriceId.ADDONS_ANNUAL_TEST : PriceId.ADDONS_ANNUAL_PROD,
          period: 'annual',
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createTiers(0.5, 25, 3001, [100, 200, 500, 1000, 2000, 3000, 3001], 20, 2, 5),
        },
        {
          name: PriceName.ADDONS_ANNUAL_LEGACY,
          description: 'Add-ons - Annual (legacy)',
          id: test ? PriceId.ADDONS_ANNUAL_LEGACY_TEST : PriceId.ADDONS_ANNUAL_LEGACY_PROD,
          period: 'annual',
          legacy: true,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: [{ unit_amount: 500, up_to: 'inf' }], //for some reason this is not set in tiers on production
        },
      ],
    },
    {
      name: ProductName.AUTOMATIONS,
      description: 'Automations',
      id: test ? ProductId.AUTOMATIONS_TEST : ProductId.AUTOMATIONS_PROD,
      isMainProduct: false,
      prices: [
        {
          name: PriceName.AUTOMATIONS_MONTHLY,
          description: 'Automations - Monthly',
          id: test ? PriceId.AUTOMATIONS_MONTHLY_TEST : PriceId.AUTOMATIONS_MONTHLY_PROD,
          period: 'monthly',
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: [{ unit_amount: 50, up_to: 'inf' }],
        },
        {
          name: PriceName.AUTOMATIONS_ANNUAL,
          description: 'Automations - Annual',
          id: test ? PriceId.AUTOMATIONS_ANNUAL_TEST : PriceId.AUTOMATIONS_ANNUAL_PROD,
          period: 'annual',
          legacy: false,
          currency: 'usd',
          usageType: 'recurring',
          billingScheme: 'tiered',
          tiers: createTiers(0.5, 25, 3001, [100, 200, 500, 1000, 2000, 3000, 3001], 20, 2, 5),
        },
      ],
    },
  ]
}

function createTiers(priceUsd: number, minUsers: number, maxUsers: number, crossSections: number[], progression: number, discountMonths = 0, firstUnitAmount = 0): ITier[] {

  const priceUsdCents = priceUsd * 100

  let users = minUsers
  const tiers: ITier[] = []
  while (users < crossSections[crossSections.length - 1]) {
    if (users === minUsers) {
      tiers.push({
        flat_amount: getPrice(users),
        up_to: users,
      })
      users += crossSections[0] / progression
    } else {
      for (let j = 0; j <= crossSections.length; j++) {
        if (users < crossSections[j]) {
          tiers.push({
            flat_amount: getPrice(users),
            up_to: users,
          })
          users += crossSections[j] / (crossSections[j] <= 2000 ? progression : 12) //not linear from 2000
          break
        }
      }
    }
  }
  tiers.push({
    flat_amount: getPrice(maxUsers),
    up_to: 'inf',
  })

  function getPrice(totalUsers: number) {
    const months = 12
    return totalUsers * priceUsdCents * (months - discountMonths)
  }
  if (firstUnitAmount) {
    tiers[0].unit_amount = firstUnitAmount * 100
    tiers[0].flat_amount = 0
  }

  return tiers
}

function createUnitTiers(priceUsd: number, minUsers: number): ITier[] {
  return [
    {
      flat_amount: minUsers * priceUsd * 100,
      up_to: minUsers,
    },
    {
      unit_amount: priceUsd * 100,
      up_to: 'inf',
    },
  ]
}

//these tiers are not in natural progression, they are stepwise without pattern
function getLegacyCoreAnnualTiers(): ITier[] {
  return [
    {
      'flat_amount': 25000,
      'up_to': 25,
    },
    {
      'flat_amount': 35000,
      'up_to': 35,
    },
    {
      'flat_amount': 45000,
      'up_to': 45,
    },
    {
      'flat_amount': 55000,
      'up_to': 55,
    },
    {
      'flat_amount': 65000,
      'up_to': 65,
    },
    {
      'flat_amount': 75000,
      'up_to': 75,
    },
    {
      'flat_amount': 85000,
      'up_to': 85,
    },
    {
      'flat_amount': 100000,
      'up_to': 100,
    },
    {
      'flat_amount': 125000,
      'up_to': 125,
    },
    {
      'flat_amount': 150000,
      'up_to': 150,
    },
    {
      'flat_amount': 175000,
      'up_to': 175,
    },
    {
      'flat_amount': 200000,
      'up_to': 200,
    },
    {
      'flat_amount': 225000,
      'up_to': 225,
    },
    {
      'flat_amount': 250000,
      'up_to': 250,
    },
    {
      'flat_amount': 295000,
      'up_to': 300,
    },
    {
      'flat_amount': 335000,
      'up_to': 350,
    },
    {
      'flat_amount': 375000,
      'up_to': 400,
    },
    {
      'flat_amount': 415000,
      'up_to': 450,
    },
    {
      'flat_amount': 450000,
      'up_to': 500,
    },
    {
      'flat_amount': 530000,
      'up_to': 600,
    },
    {
      'flat_amount': 605000,
      'up_to': 700,
    },
    {
      'flat_amount': 680000,
      'up_to': 800,
    },
    {
      'flat_amount': 750000,
      'up_to': 900,
    },
    {
      'flat_amount': 815000,
      'up_to': 1000,
    },
    {
      'flat_amount': 960000,
      'up_to': 1200,
    },
    {
      'flat_amount': 1100000,
      'up_to': 1400,
    },
    {
      'flat_amount': 1230000,
      'up_to': 1600,
    },
    {
      'flat_amount': 1355000,
      'up_to': 1800,
    },
    {
      'flat_amount': 1475000,
      'up_to': 2000,
    },
    {
      'flat_amount': 1630000,
      'up_to': 2250,
    },
    {
      'flat_amount': 1770000,
      'up_to': 2500,
    },
    {
      'flat_amount': 1910000,
      'up_to': 2750,
    },
    {
      'flat_amount': 2040000,
      'up_to': 3000,
    },
    {
      'flat_amount': 2335000,
      'up_to': 3500,
    },
    {
      'flat_amount': 2615000,
      'up_to': 4000,
    },
    {
      'flat_amount': 2885000,
      'up_to': 4500,
    },
    {
      'flat_amount': 3140000,
      'up_to': 5000,
    },
    {
      'flat_amount': 4000000,
      'up_to': 'inf',
    },
  ]
}

//these tiers are not in natural progression, they are stepwise without pattern
function getLegacyCompleteAnnualTiers(): ITier[] {
  return [
    {
      'flat_amount': 50000,
      'up_to': 25,
    },
    {
      'flat_amount': 70000,
      'up_to': 35,
    },
    {
      'flat_amount': 90000,
      'up_to': 45,
    },
    {
      'flat_amount': 110000,
      'up_to': 55,
    },
    {
      'flat_amount': 130000,
      'up_to': 65,
    },
    {
      'flat_amount': 150000,
      'up_to': 75,
    },
    {
      'flat_amount': 170000,
      'up_to': 85,
    },
    {
      'flat_amount': 200000,
      'up_to': 100,
    },
    {
      'flat_amount': 250000,
      'up_to': 125,
    },
    {
      'flat_amount': 300000,
      'up_to': 150,
    },
    {
      'flat_amount': 350000,
      'up_to': 175,
    },
    {
      'flat_amount': 400000,
      'up_to': 200,
    },
    {
      'flat_amount': 450000,
      'up_to': 225,
    },
    {
      'flat_amount': 500000,
      'up_to': 250,
    },
    {
      'flat_amount': 585000,
      'up_to': 300,
    },
    {
      'flat_amount': 670000,
      'up_to': 350,
    },
    {
      'flat_amount': 750000,
      'up_to': 400,
    },
    {
      'flat_amount': 830000,
      'up_to': 450,
    },
    {
      'flat_amount': 900000,
      'up_to': 500,
    },
    {
      'flat_amount': 1060000,
      'up_to': 600,
    },
    {
      'flat_amount': 1215000,
      'up_to': 700,
    },
    {
      'flat_amount': 1360000,
      'up_to': 800,
    },
    {
      'flat_amount': 1500000,
      'up_to': 900,
    },
    {
      'flat_amount': 1630000,
      'up_to': 1000,
    },
    {
      'flat_amount': 1920000,
      'up_to': 1200,
    },
    {
      'flat_amount': 2200000,
      'up_to': 1400,
    },
    {
      'flat_amount': 2460000,
      'up_to': 1600,
    },
    {
      'flat_amount': 2710000,
      'up_to': 1800,
    },
    {
      'flat_amount': 2955000,
      'up_to': 2000,
    },
    {
      'flat_amount': 3255000,
      'up_to': 2250,
    },
    {
      'flat_amount': 3545000,
      'up_to': 2500,
    },
    {
      'flat_amount': 3820000,
      'up_to': 2750,
    },
    {
      'flat_amount': 4085000,
      'up_to': 3000,
    },
    {
      'flat_amount': 4670000,
      'up_to': 3500,
    },
    {
      'flat_amount': 5230000,
      'up_to': 4000,
    },
    {
      'flat_amount': 5770000,
      'up_to': 4500,
    },
    {
      'flat_amount': 6280000,
      'up_to': 5000,
    },
    {
      'flat_amount': 8000000,
      'up_to': 'inf',
    },
  ]
}

