// implements section 4.1 of https://tools.ietf.org/html/rfc7636
//
// because browser crypto works async, this class creates and caches one challenge for
// use cases that need to use the challenge in a synchronous click flow
import { urlEncodeB64 } from './url-encode-b64'
import { Observable } from './observable'
export function PKCEChallengeGenerator(this: any, {runtime}) {
  if (!runtime) {
    throw new Error('invalid-args')
  }
  let currentChallenge
  const generated = new Observable(),
    createAsync = async () => {
      const verifier = runtime.uniqueString(32),
        codeBuffer = await runtime.sha256Digest(verifier),
        code = urlEncodeB64(runtime, codeBuffer)
      return {code, verifier}
    },
    createSync = () => {
      const verifier = runtime.uniqueString(32),
        codeBuffer = runtime.sha256DigestSync(verifier),
        code = urlEncodeB64(runtime, codeBuffer)
      return {code, verifier}
    },
    recreate = () => {
      currentChallenge = createSync()
      generated.notify()
    },
    create = () => {
      currentChallenge = createSync()
      if (!currentChallenge) {
        throw new Error('not-ready')
      }
      // const oldChallenge = currentChallenge;
      // recreate(); // don't await here!
      return currentChallenge
    }
  recreate()
  Object.freeze(Object.assign(this, {generated, createAsync, create, createSync}))
}


