import React from 'react'
import { useSubscription } from 'graphql-hooks'

import { whatsNewResponseSubscription } from '../../graphql/custom-queries'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectUserIdSlice } from '../../store/user-id-slice'
import { addWhatsNewId } from '../../store/whats-news-slice'

import { IWhatsNewExtendEvent } from '@vacationtracker/shared/types/whats-new'
import { ISubscriptionEvent } from '@vacationtracker/shared/types/notification'
import logger from '@vacationtracker/shared/functions/logger'

type WhatsNewEventResponse = {
  whatsNewResponse: ISubscriptionEvent
}

const WhatsNewNotifications = () => {
  const { userId } = useAppSelector(selectUserIdSlice)
  const dispatch = useAppDispatch()

  useSubscription<WhatsNewEventResponse>({
    query: whatsNewResponseSubscription,
    variables: { userId },
  }, ({ data, errors }) => {
    // The "errors" type is `object[] | undefined`, but WebSocket error returns just one object with `type: 'error'`
    const err: any = errors
    if (err && (err.type === 'error' || !Array.isArray(err))) {
      // Handled in the <Notifications /> component
      return
    }
    let subscriptionEvent: IWhatsNewExtendEvent | null = null
    try {
      if (!data || !data.whatsNewResponse) {
        throw new Error('No data')
      }
      subscriptionEvent = JSON.parse(data.whatsNewResponse.originalEvent)
    } catch(err) {
      logger.error('Error parsing subscription event (Whats new)', err)
      return
    }

    if (subscriptionEvent) {
      dispatch(addWhatsNewId(subscriptionEvent.announcementId as string))
    }
  })
  
  return (<></>)
}

export default WhatsNewNotifications
