
import React from 'react'
import { getTimezoneText } from '../../functions/timezone'

interface ITimezoneInfoProps {
  userTimezone: string | undefined
}

export const TimezoneInfo = ({ userTimezone }: ITimezoneInfoProps): JSX.Element | null => {
  if (!userTimezone) {
    return null
  }

  return <span data-testid="timezone-text">
    {getTimezoneText(userTimezone)}
  </span>
}
