import Analytics, { AnalyticsInstance } from 'analytics'
import amplitudePlugin from '@analytics/amplitude'
import googleTagManager from '@analytics/google-tag-manager'
import { posthog } from 'posthog-js'
import customAnalyiticsEvents from './custom-analytics-events-plugin'
import testPlugin from './test-plugin'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { SignupVariation } from '@vacationtracker/shared/types/company'
import { AnalyticsPlatformEnum } from '@vacationtracker/shared/types/analytics-event'
const VERSION = '2.0'

type EventPayload = {
  [key: string]: string | string[] | number | boolean | undefined | null | LocaleEnum
}

const gtmContainerId = process.env.REACT_APP_GTM_CONTAINER_ID || ''
const amplitudeApiKey = process.env.REACT_APP_AMPLITUDE_API_KEY || ''

const IS_PRODUCTION = process.env.REACT_APP_ENVIRONMENT === 'production'

let analytics: AnalyticsInstance

if (IS_PRODUCTION) {
  analytics = Analytics({
    app: 'vacation-tracker',
    plugins: [
      amplitudePlugin({
        apiKey: amplitudeApiKey,
        options: {
          includeUtm: true,
          trackingOptions: {
            ip_address: false,
          },
        },
      }),
      googleTagManager({
        containerId: gtmContainerId,
      }),
      customAnalyiticsEvents(),
    ],
  })
} else {
  analytics = Analytics({
    app: 'vacation-tracker',
    plugins: [
      customAnalyiticsEvents(),
      testPlugin({
        logging: false,
      }),
    ],
  })
}

export async function page(url?: string): Promise<void> {
  try {
    const payload: EventPayload = {}
    if (url) {
      payload.url = `https://vacationtracker.io/v2${url}`
    }
    await analytics.page(payload)
    posthog.capture('$pageview', {
      '$current_url': url,
    })
  } catch (err) {
    // Ignore analytics errors
  }
}

export async function track(eventName: string, payload: EventPayload): Promise<void> {
  try {
    await analytics.track(eventName, {
      ...payload,
      version: VERSION,
      ignore: [AnalyticsPlatformEnum.POSTHOG], // Skip PostHog because we already sent this event to PostHog (see "posthog.capture" below)
    })
    posthog.capture(eventName, payload)
  } catch (err) {
    // Ignore analytics errors
  }
}

export async function identifySingleUser(userId: string, traits: EventPayload, companyId?: string): Promise<void> {
  try {
    posthog.identify(userId, traits)
    if (companyId) {
      posthog.group('company', companyId)
    }
  } catch(err) {
    // Ignore errors
  }
  // This is not an async function at the moment, but it might be in the future, and we do not want to change the function signature
  // For example, if we start using something else instead of PostHog, we might need to make this function async
  return await Promise.resolve()
}

export async function identifyCompany(companyId: string, traits: EventPayload): Promise<void> {
  try {
    posthog.group('company', companyId, traits)
  } catch(err) {
    // Ignore errors
  }
  // This is not an async function at the moment, but it might be in the future, and we do not want to change the function signature
  // For example, if we start using something else instead of PostHog, we might need to make this function async
  return await Promise.resolve()
}

export async function identify(userId: string, traits: EventPayload): Promise<void> {
  try {
    const params = {
      ...traits,
    }
    if (traits.email && typeof traits.email === 'string') {
      params.email = traits.email.toLowerCase()
    }
    await analytics.identify(userId, {
      ...params,
      version: VERSION,
    })
  } catch (err) {
    // Ignore analytics errors
  }
}

export function analyticsSignupDecorator(data: EventPayload, signupVariation: SignupVariation = 'B'): EventPayload  {
  let email
  try {
    email = JSON.parse(localStorage.getItem('vt-create-company') as string).createCompany.companyEmail
  } catch {
    email = ''
  }
  return {
    ...data,
    email,
    anonymousId: localStorage.getItem('__anon_id'),
    signupVariation,
  }
}

export function trackConversionEvent(eventType: 'SIGNUP_STARTED' | 'SIGNUP_COMPLETED') {
  if (window.gtag && IS_PRODUCTION) {
    if (eventType === 'SIGNUP_STARTED') { 
      window.gtag('event', 'conversion', {'send_to': 'AW-873532163/q0ACIXsj_0YEIOWxKAD'})
    } else if (eventType === 'SIGNUP_COMPLETED') { 
      window.gtag('event', 'conversion', {'send_to': 'AW-873532163/mcj9CILsj_0YEIOWxKAD'})
    }
  }
}
