/* eslint-disable max-len */
import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input, Popover, Typography } from 'antd'
import { AlertOutlined, EyeTwoTone, EyeInvisibleOutlined, LikeOutlined, TrophyOutlined } from '@ant-design/icons'

import { calculatePasswordStrength } from '../../util/calculate-password-strength'

import IntlMessages from '../../util/IntlMessages'

const { Paragraph, Text } = Typography

interface ISetPasswordProps {
  changePassword?: boolean
  noFeedback?: boolean
  onBlurNewPassword?: () => void
  onBlurConfirmedNewPassword?: () => void
}
const cognitoPasswordRequirements = /^(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/

const SetPassword = ({ changePassword, noFeedback, onBlurNewPassword, onBlurConfirmedNewPassword }: ISetPasswordProps) => {
  const { formatMessage } = useIntl()
  const [password, setPassword] = useState<string | null>(null)
  const [timeToHack, setTimeToHack] = useState<JSX.Element | null>(null)

  const formatTimeToHack = (timeInSeconds: number) => {
    if (timeInSeconds >= (100 * 365 * 24 * 60 * 60)) {
      return (<Text type="success">
        <TrophyOutlined /> <IntlMessages id="component.password.timeToHack.label" /> <IntlMessages id="component.password.timeToHack.perfect" />
      </Text>)
    }

    if (timeInSeconds >= (365 * 24 * 60 * 60)) {
      return (<Text type="success">
        <TrophyOutlined /> <IntlMessages id="component.password.timeToHack.label" /> <IntlMessages id="component.password.timeToHack.years" values={{ duration: Math.round(timeInSeconds / (365 * 24 * 60 * 60)) }}/>
      </Text>)
    }

    if (timeInSeconds >= (60 * 24 * 60 * 60)) {
      return (<Text type="success">
        <LikeOutlined /> <IntlMessages id="component.password.timeToHack.label" /> <IntlMessages id="component.password.timeToHack.days" values={{ duration: Math.round(timeInSeconds / (24 * 60 * 60)) }}/>
      </Text>)
    }

    if (timeInSeconds >= (24 * 60 * 60)) {
      return (<Text type="secondary">
        <LikeOutlined /> <IntlMessages id="component.password.timeToHack.label" /> <IntlMessages id="component.password.timeToHack.days" values={{ duration: Math.round(timeInSeconds / (24 * 60 * 60)) }}/>
      </Text>)
    }

    if (timeInSeconds > (60 * 60 - 1)) {
      return (<Text type="warning">
        <LikeOutlined /> <IntlMessages id="component.password.timeToHack.label" /> <IntlMessages id="component.password.timeToHack.hours" values={{ duration: Math.round(timeInSeconds / (60 * 60)) }}/>
      </Text>)
    }

    if (timeInSeconds > 59) {
      return (<Text type="warning">
        <AlertOutlined /> <IntlMessages id="component.password.timeToHack.label" /> <IntlMessages id="component.password.timeToHack.minutes" values={{ duration: Math.round(timeInSeconds / 60) }}/>
      </Text>)
    }

    if (timeInSeconds > 5) {
      return (<Text type="danger">
        <AlertOutlined /> <IntlMessages id="component.password.timeToHack.label" /> <IntlMessages id="component.password.timeToHack.seconds" values={{ duration: Math.round(timeInSeconds) }}/>
      </Text>)
    }

    return (<Text type="danger">
      <AlertOutlined /> <IntlMessages id="component.password.timeToHack.label" /> <IntlMessages id="component.password.timeToHack.instantly" />
    </Text>)
  }

  useEffect(() => {
    if (!password) {
      setTimeToHack(null)
      return
    }

    if (!password.match(cognitoPasswordRequirements)) {
      return
    }

    const { timeInSeconds } = calculatePasswordStrength(password)
    setTimeToHack(<Popover
      placement="bottom"
      title={ formatMessage({ id: 'component.setPassword.tooltip.title' }) }
      content={<>
        <Paragraph style={{ maxWidth: 420 }}>
          <IntlMessages id="component.setPassword.tooltip.description.line1" values={{
            link: (chunks) => (
              <a href="https://www.hivesystems.io/blog/are-your-passwords-in-the-green" target="_blank" rel="noreferrer">{ ...chunks }</a>
            ),
            italic: (chunks) => (
              <Text italic>{ ...chunks }</Text>
            ),
          }} />,
        </Paragraph>
        <Paragraph style={{ maxWidth: 420 }}>
          <IntlMessages id="component.setPassword.tooltip.description.line2" values={{
            link: (chunks) => (
              <a href="https://xkcd.com/936/" target="_blank" rel="noreferrer">{ ...chunks }</a>
            ),
          }} />
        </Paragraph>
      </>}
      trigger="hover"
      style={{ cursor: 'pointer' }}
    >
      <Text style={{ cursor: 'pointer' }}>
        { formatTimeToHack(timeInSeconds) }
      </Text>
    </Popover>)
  }, [password])

  return <>
    { changePassword && (
      <Form.Item
        className='wrap-label'
        label={<IntlMessages id="app.oldPassword" />}
        name="oldPassword"
        rules={[
          {
            required: true,
            message: <IntlMessages id="error.fieldIsRequired" />,
          },
        ]}
      >
        <Input.Password
          type='password'
          data-qa='old-password-input'
          placeholder={ formatMessage({ id: 'component.setPassword.enterOldPassword' }) }
          iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
        />
      </Form.Item>
    ) }
    <Form.Item
      className='wrap-label'
      label={<IntlMessages id={changePassword ? 'app.newPassword' : 'app.password' } />}
      extra={timeToHack}
      name="password"
      rules={[
        {
          required: true,
          message: <IntlMessages id="error.fieldIsRequired" />,
        },
        {
          pattern: cognitoPasswordRequirements,
          message: <IntlMessages id="component.setPassword.validation" />,
        },
      ]}
    >
      <Input.Password
        type='password'
        data-qa='new-password-input'
        placeholder={ formatMessage({ id: 'component.setPassword.enterPassword' }) }
        onBlur={onBlurNewPassword}
        onChange={(e) => setPassword(e.currentTarget.value)}
        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      />
    </Form.Item>
    <Form.Item
      className='wrap-label'
      label={<IntlMessages id={changePassword ? 'app.confirmNewPassword' : 'app.confirmPassword' } />}
      name="repeatPassword"
      dependencies={['password']}
      hasFeedback={!noFeedback}
      rules={[
        {
          required: true,
          message: <IntlMessages id='form.confirmPasswordInfo' />,
        },
        ({ getFieldValue }) => ({
          validator(_, value) {
            if (!value || getFieldValue('password') === value) {
              return Promise.resolve()
            }
            return Promise.reject(new Error(formatMessage({ id: 'form.passwordDoNotMatch' })))
          },
        }),
      ]}
    >
      <Input.Password
        onBlur={onBlurConfirmedNewPassword}
        type='password'
        data-qa='repeat-password-input'
        placeholder={ formatMessage({ id: 'app.confirmPassword' }) }
        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      />
    </Form.Item>
  </>
}

export default SetPassword
