import React from 'react'
import { Helmet } from 'react-helmet'
import { useIntl } from 'react-intl'

interface ISeoTagsProps {
  title: string
  description: string
}

// will override only those seo/head tags set in index.html
// that have data-react-helmet="true" attribute
export const SeoTags = ({
  title,
  description,
}: ISeoTagsProps) => {
  const { formatMessage } = useIntl()

  return <Helmet
    title={formatMessage({id: title})}
    meta={[
      {
        itemprop: 'description',
        content: formatMessage({id: description}),
      },
      {
        itemprop: 'name',
        content: formatMessage({id: title}),
      },
      {
        name: 'description',
        content: formatMessage({id: description}),
      },
      {
        property: 'og:title',
        content: formatMessage({id: title}),
      },
      {
        property: 'twitter:title',
        content: formatMessage({id: title}),
      },
      {
        property: 'twitter:description',
        content: formatMessage({id: description}),
      },
      {
        property: 'og:description',
        content: formatMessage({id: description}),
      },
    ]}
  >
  </Helmet>
}