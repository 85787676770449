import { isNumber } from 'lodash'
import dayjs from 'dayjs'

import { getSafeLocale } from '../i18n'
import getDateInUserTimezone from './get-date-in-user-timezone'
import { convertHourFormats } from './convert-between-hour-formats'

import { HourFormatEnum } from '../types/user'

export const getLeavePeriod = ({
  isPartDay,
  startDate,
  endDate,
  partDayStartHour,
  partDayEndHour,
  formatMessage,
  leaveRequestWorkingDays,
  locale,
  hourFormat,
}) => {
  const safeLocale = getSafeLocale(locale)
  const beginningDate = dayjs(getDateInUserTimezone(startDate)).locale(safeLocale).format('MMMM Do YYYY')
  let convertedStartHour
  let convertedEndHour
  let amOrPmStart
  let amOrPmEnd
  if (isPartDay) {
    if (!isNumber(partDayStartHour) || !isNumber(partDayEndHour)) {
      return ''
    }
    const { value: convertedStartHourVal, amOrPm: amOrPmStartVal } = convertHourFormats(hourFormat, partDayStartHour)
    const { value: convertedEndHourVal, amOrPm: amOrPmEndVal } = convertHourFormats(hourFormat, partDayEndHour)
    convertedStartHour = convertedStartHourVal
    convertedEndHour = convertedEndHourVal
    amOrPmStart = amOrPmStartVal
    amOrPmEnd = amOrPmEndVal
  }
  return isPartDay ?
    formatMessage({id: 'components.leaveRequestAction.partDayLeavePeriod'}, {
      date: beginningDate,
      partDayStartHour: `${convertedStartHour}${amOrPmStart}`,
      partDayEndHour: `${convertedEndHour}${amOrPmEnd}`,
      hourFormat24: hourFormat === HourFormatEnum.twentyFour,
    }) :
    formatMessage({id: 'components.leaveRequestAction.fullDayLeavePeriod'}, {
      date: beginningDate,
      endDate: leaveRequestWorkingDays > 1 ? ' - ' + dayjs(getDateInUserTimezone(endDate)).locale(safeLocale).format('MMMM Do YYYY') : '',
    })
}