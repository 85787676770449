import dayjs from 'dayjs'
import { IGetImportPageValidationData, IUserSlim } from '../../../types/users'
import { IUserLeaves } from '@vacationtracker/shared/types/leave-request'
import { findSameDateLeaves } from '../helpers'
import { ShortestLeaveIntervalEnum } from '@vacationtracker/shared/types/leave-policy'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'

export const validateRow = (companyUsers:  IUserSlim[], validationData: IGetImportPageValidationData, existingLeaves: IUserLeaves[], formatMessage) => {

  // Bellow is the hook that handles validation logic for each row of the provided  spreadsheet
  return (data, addError) => {
    let dateFrom: string | undefined
    let dateTo: string | undefined
    
    // User validation
    const user = companyUsers.find(user => user.email.toLowerCase() === (data.email && data.email.toLowerCase()))
    if (!user) {
      addError('email', { message: formatMessage({ id: 'error.UserNotFound' }), level: 'error' })
    }

    if (user) {
      // Approver validation
      
      const approver = companyUsers.find(user => user.email.toLowerCase() === data.approverEmail.toLowerCase())
      const approversForTeam = validationData.getTeamList?.find(team => team.id === user.teamId)?.approvers

      if (!approver || !approversForTeam?.find(approverForTeam => approverForTeam.id === approver.id)) {
        addError('approverEmail', { message: formatMessage({ id: 'error.UserNotFoundApprover' }), level: 'error' })
      }
    }

    // validate leave type
    const leaveType = validationData.getLeaveTypeList.find(lt => data.leaveType && lt.name.toLowerCase() === (data.leaveType && data.leaveType.toLowerCase()))
    if (!leaveType) {
      addError('leaveType', { message: formatMessage({ id: 'app.importLeaves.errors.leaveTypeNotFound' }), level: 'error' })      
    }

    // validate leave policy
    const userLocation = validationData.getLocationList.find(location => location.id === user?.locationId)
    const leavePolicy = userLocation?.leavePolicies.find(policy => policy.id === `${userLocation.id}#${leaveType?.id}`)

    if (!leavePolicy) {
      addError('leaveType', { message: formatMessage({ id: 'app.importLeaves.errors.leaveTypeNotInUserLocation' }), level: 'error' })      
    }
    if (leavePolicy && leavePolicy.isReasonRequired && !data.reason) {
      addError('reason', { message: formatMessage({ id: 'components.leaveForm.pleaseInputReason' }), level: 'error' })      
    }
    if (leavePolicy && leavePolicy.shortestLeaveInterval === ShortestLeaveIntervalEnum.fullDay && data.isHalfDay) {
      addError('hourFrom', { message: formatMessage({ id: 'app.importLeaves.errors.leavePolicyError' }), level: 'error' })      
      addError('hourTo', { message: formatMessage({ id: 'app.importLeaves.errors.leavePolicyError' }), level: 'error' })      
    }

    // Date validation
    //TODO workday change this when we implement user hours
    const workingHours = getUserWorkingHoursPerDay(userLocation?.workHours)
    const sameDayLeaves = findSameDateLeaves(companyUsers, existingLeaves, data, workingHours)

    if (sameDayLeaves) {
      addError('dateFrom', sameDayLeaves)
    }

    if (!data.dateFrom || !data.dateTo) {
      if (!data.dateFrom) {
        addError('dateFrom', { message: formatMessage({ id: 'app.importLeaves.errors.dateFromRequired' }), level: 'error' })
      } else {
        dateFrom = dayjs(data.dateFrom).format('YYYY-MM-DD')
      }
      if (!data.dateTo) {
        addError('dateTo', { message: formatMessage({ id: 'app.importLeaves.errors.dateToRequired' }), level: 'error' })
      } else {
        dateTo = dayjs(data.dateTo).format('YYYY-MM-DD')
      }
    } else {
      dateFrom = dayjs(data.dateFrom).format('YYYY-MM-DD')
      dateTo = dayjs(data.dateTo).format('YYYY-MM-DD')

      if (!dayjs(dateFrom).isValid()) {
        addError('dateFrom', { message: formatMessage({ id: 'app.importLeaves.errors.dateFromNotValid' }), level: 'error'})
      }

      if (!dayjs(dateTo).isValid()) {
        addError('dateTo', { message: formatMessage({ id: 'app.importLeaves.errors.dateToNotValid' }), level: 'error'})
      }

      // validate date range
      if (dayjs(dateFrom).isAfter(dateTo)) {
        addError('dateFrom', { message: formatMessage({ id: 'app.importLeaves.errors.dateFromAfterDateTo' }), level: 'error'})
      }
    }

    if (data.isHalfDay) {
      //convert hours to number
      if (!data.hourFrom || isNaN(Number(data.hourFrom))) {
        addError('hourFrom', { message: formatMessage({ id: 'app.importLeaves.errors.hourFromRequired' }), level: 'error'})
      } else if (/^(0?[0-9]|1[0-9]|2[0-3])$/.test(data.hourFrom) === false) {
        addError('hourFrom', { message: formatMessage({ id: 'app.importLeaves.errors.hourFromNotValid' }), level: 'error'})
      }
      if (!data.hourTo || isNaN(Number(data.hourTo))) {
        addError('hourTo', { message: formatMessage({ id: 'app.importLeaves.errors.hourToRequired' }), level: 'error'})
      } else if (/^(0?[0-9]|1[0-9]|2[0-3])$/.test(data.hourTo) === false) {
        addError('hourTo', { message: formatMessage({ id: 'app.importLeaves.errors.hourToNotValid' }), level: 'error' })
      }
      // validate hours
      if (data.hourFrom && data.hourTo) {
        if (Number(data.hourFrom) > Number(data.hourTo)) {
          addError('hourFrom', { message: formatMessage({ id: 'app.importLeaves.errors.hourFromAfterHourTo' }), level: 'error' })
        }
        if (Number(data.hourTo) - Number(data.hourFrom) > workingHours) {
          addError('hourFrom', { message: formatMessage({ id: 'app.importLeaves.errors.hourRange' }, { hours: 2 }), level: 'error' })
        }
      }
      if (data.dateFrom !== data.dateTo) {
        addError('isHalfDay', { message: formatMessage({ id: 'app.importLeaves.errors.halfDay' }), level: 'error'})
      }
    }

    return {
      ...data,
      fullName: data.fullName?.length > 0 ? data.fullName : undefined,
      leaveType: leaveType?.name,
      hourFrom: (data.isHalfDay && data.hourFrom && !isNaN(Number(data.hourFrom))) ? Number(data.hourFrom) : undefined,
      hourTo: (data.isHalfDay && data.hourTo && !isNaN(Number(data.hourTo))) ? Number(data.hourTo) : undefined,
      dateFrom,
      dateTo,
    }
  }
}
