import React, { useState } from 'react'
import { Card, Typography, Tooltip } from 'antd'
import { QuestionCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import {
  format,
  addYears,
  isAfter,
  isBefore,
  isEqual
} from 'date-fns'

import QuotaBox from '../quota-box'
import { DisplayLeaveInDaysAndHours } from '../display-leave-in-days-and-hours'
import FormattedDate from '../formatted-date'
import RolloverExpiry from './rollover-expiry'
import { AccruedTooltip } from './accrued-tooltip'

import { AccrualTypeEnum } from '@vacationtracker/shared/types/leave-policy'
import { LeaveDisplayFormatEnum } from '../../types/display-in-days-hours'
import { IUserLeaveQuotasCompact } from './types'
import { IUserLeaveTypeResult, IMyProfileLeaveType } from '../../../frontend/src/types/custom-queries'
import { ILeaveTypeResult } from '../../types/calculations'
import { getUserWorkingHoursPerDay } from '../../functions/work-week'


const { Text, Paragraph } = Typography

const UserLeaveQuotasCompact = ({
  IntlMessages,
  location,
  userName,
  setActiveTab,
  hourlyLeaveAccounting,
  locale,
  currentQuota,
  selectedLeaveType,
  requested = 0,
  title,
  workingHours,
}: IUserLeaveQuotasCompact): React.ReactElement => {
  const { formatMessage } = useIntl()
  const [isAccrualInfoVisible, setIsAccrualInfoVisible] = useState(false)

  const calculateRemainingDays = ({
    requested,
    quota,
  }: {
    requested?: number
    quota?: IUserLeaveTypeResult | IMyProfileLeaveType
  }): number | string => {
    if (!quota) {
      return 0
    }
    if (quota.hasUnlimitedDays) {
      return '∞'
    }

    if (!requested) {
      return quota.currentDays
    } else {
      return quota.currentDays - requested
    }
  }

  const handleGoToUserLogPage = () => {
    setIsAccrualInfoVisible(false)
    if (setActiveTab) {
      setActiveTab({key: 'logs'})
    }
  }

  const getExpiredRolloverFooter = (rolloverExpiryDate: string) => {
    if (rolloverExpiryDate === '') {
      return ''
    }

    let isExpired = false
    if (isAfter(new Date(), new Date(rolloverExpiryDate)) && !isEqual(new Date(), new Date(rolloverExpiryDate))) {
      isExpired = true
    }

    if (!isExpired) {
      return ''
    }

    return (
      <Paragraph>
        <IntlMessages id="components.userLeaveQuotas.expiredDateInfo" values={{
          date: <FormattedDate value={rolloverExpiryDate?.slice(0,10)} format="MMMM Do YYYY." locale={locale} />,
        }}/>
      </Paragraph>
    )
  }

  const getQuotaTitle = (): JSX.Element => {
    if (selectedLeaveType?.accrualType !== AccrualTypeEnum.none && selectedLeaveType?.firstEarningDate) {
      return <>
        {hourlyLeaveAccounting ? <IntlMessages id="components.userLeaveQuotas.accruedHours" /> : <IntlMessages id="components.userLeaveQuotas.accruedDays" />}
        <Tooltip
          open={isAccrualInfoVisible}
          title={
            <AccruedTooltip
              selectedLeaveType={selectedLeaveType}
              userName={userName}
              policyFirstEarningDate={selectedLeaveType?.firstEarningDate as string}
              handleGoToUserLogPage={handleGoToUserLogPage}
              formatMessage={formatMessage}
              IntlMessages={IntlMessages}
              yearStartDay={location.yearStartDay as number}
              accrualPeriodStart={selectedLeaveType?.accrualPeriodStart as string}
              isCurrentYearSelected
              hourlyLeaveAccounting={hourlyLeaveAccounting}
              userLocale={locale}
              capHit={Boolean(selectedLeaveType?.capHit)}
            />
          }
          onOpenChange={() => setIsAccrualInfoVisible(!isAccrualInfoVisible)}
        >
          <QuestionCircleOutlined style={{ marginLeft: '10px' }} />
        </Tooltip>
      </>
    }
    else {
      return <IntlMessages id="components.userLeaveQuotas.quota" />
    }
  }

  const getQuotaFooter = (selectedLeaveType: ILeaveTypeResult): JSX.Element => {
    if (selectedLeaveType?.accrualType !== AccrualTypeEnum.none) {
      return <Paragraph data-testid="quota-default-days-per-year">
        <IntlMessages id="app.of" />&nbsp;
        <DisplayLeaveInDaysAndHours
          value={selectedLeaveType?.quota}
          format={LeaveDisplayFormatEnum.short}
          numberOfWorkingHoursPerDay={getUserWorkingHoursPerDay(workingHours)}
        />
      </Paragraph>
    } else {
      return <></>
    }
  }

  return (
    <div>
      <Card
        title={title}
        styles={{ body: { padding: 0, paddingTop: 10 } }}
        className="user-leave-quotas user-leave-quotas-compact"
        bordered={false}
      >
        <IntlMessages
          id="leaveRequest.simpleQuota.info"
          values={{
            leaveTypeName: selectedLeaveType.leaveTypeName,
            start: format(new Date(typeof currentQuota.yearStart === 'string' ? currentQuota.yearStart.split('T')[0] : currentQuota.yearStart), 'PPP'),
            end: format(new Date(typeof currentQuota.yearEnd === 'string' ? currentQuota.yearEnd.split('T')[0] : currentQuota.yearEnd), 'PPP'),
          }}
        />
        {selectedLeaveType &&
          <>
            <div className="quota-box-container">
              {!selectedLeaveType?.hasUnlimitedDays &&
                <>
                  {(selectedLeaveType?.maxRolloverDays !== 0 || selectedLeaveType?.rolloverFromPreviousYear !== 0) &&
                    <>
                      <QuotaBox
                        title={<IntlMessages id="app.broughtForward" />}
                        dataTestId={'quota-brought-forward-days'}
                        value={selectedLeaveType?.rolloverFromPreviousYear}
                        footer={<RolloverExpiry
                          IntlMessages={IntlMessages}
                          locale={locale}
                          isCurrentYearSelected
                          yearEndDate={typeof currentQuota.yearEnd === 'string' ? new Date(currentQuota.yearEnd) : currentQuota.yearEnd}
                          rolloverExpiryDate={selectedLeaveType.rolloverExpiryDate}
                          isNextYearSelected={false}
                          maxRolloverDays={selectedLeaveType?.maxRolloverDays || 0}
                        />}
                        hourlyLeaveAccounting={hourlyLeaveAccounting}
                        leaveTypeName={selectedLeaveType.leaveTypeName}
                        locationName={location.name}
                        broughtForwardLimit={selectedLeaveType.maxRolloverDays}
                        workingHours={workingHours}
                      />
                      <div className="quota-formula">
                        <Text type="secondary" strong>+</Text>
                      </div>
                    </>
                  }
                </>
              }
              <QuotaBox
                title={getQuotaTitle()}
                dataTestId={'quota-value'}
                value={selectedLeaveType?.hasUnlimitedDays ? '∞' : selectedLeaveType?.earnedDaysWithoutRollover}
                quota={{
                  baseQuota: selectedLeaveType.baseQuota,
                  seniorityEntitlementDaysForYear: selectedLeaveType?.seniorityEntitlementDaysForYear,
                  hasUnlimitedDays: selectedLeaveType.hasUnlimitedDays,
                  entitlementByRoleDaysForYear: selectedLeaveType?.entitlementByRoleDaysForYear,
                  toilDays: selectedLeaveType?.toilDays,
                  toilDaysLapsed: selectedLeaveType?.toilDaysLapsed,
                }}
                footer={getQuotaFooter(selectedLeaveType)}
                hourlyLeaveAccounting={hourlyLeaveAccounting}
                doNotShowDisplayInDaysOrHoursTooltip={Boolean(selectedLeaveType?.accrualType !== AccrualTypeEnum.none && selectedLeaveType?.firstEarningDate)}
                workingHours={workingHours}
              />
              {/* If rollover never expired in that case we return an empty string or we add 1000 years,
                  that's why we check is rollover Expiry Date emptry string and is rolloverExpiryDate is before the current year plus 5 year */}
              {selectedLeaveType?.rolloverExpiryDate !== '' && isBefore(new Date(selectedLeaveType?.rolloverExpiryDate), addYears(new Date(), 5)) &&
                <>
                  <div className="quota-formula" data-testid="formula">
                    <Text type="secondary" strong>-</Text>
                  </div>
                  <QuotaBox
                    title={<IntlMessages id="app.expired" />}
                    dataTestId={'quota-expired'}
                    value={selectedLeaveType?.expiredDays}
                    footer={getExpiredRolloverFooter(selectedLeaveType.rolloverExpiryDate)}
                    hourlyLeaveAccounting={hourlyLeaveAccounting}
                    expirationDate={getExpiredRolloverFooter(selectedLeaveType.rolloverExpiryDate)}
                    workingHours={workingHours}
                  />
                </>
              }
              <div className="quota-formula">
                <Text type="secondary" strong>-</Text>
              </div>
              <QuotaBox
                title={<IntlMessages id="components.userLeaveQuotas.used" />}
                dataTestId={'quota-used-days'}
                scheduledDays={selectedLeaveType?.scheduledDays}
                value={selectedLeaveType?.usedDays}
                footer={''}
                hourlyLeaveAccounting={hourlyLeaveAccounting}
                leaveTypeName={selectedLeaveType.leaveTypeName}
                workingHours={workingHours}
              />
              {requested > 0 && 
                <>
                  <div className="quota-formula">
                    <Text type="secondary" strong>-</Text>
                  </div>
                  <QuotaBox
                    title={<IntlMessages id="components.userLeaveQuotas.requested" />}
                    dataTestId={'quota-requested'}
                    value={requested}
                    footer={''}
                    hourlyLeaveAccounting={hourlyLeaveAccounting}
                    hasRequestedDays={requested > 0}
                    workingHours={workingHours}
                  />
                </>
              }
              <div className="quota-formula">
                <Text type="secondary" strong>=</Text>
              </div>
              <QuotaBox
                title={<IntlMessages id={requested > 0 ? 'components.userLeaveQuotas.remainingAfterRequest' : 'components.userLeaveQuotas.remaining'} />}
                dataTestId={'quota-remaining-days'}
                value={calculateRemainingDays({
                  requested,
                  quota: selectedLeaveType,
                })}
                footer={''}
                hourlyLeaveAccounting={hourlyLeaveAccounting}
                hasRequestedDays={requested > 0}
                broughtForwardLimit={selectedLeaveType.maxRolloverDays}
                workingHours={workingHours}
              />
            </div>
          </>
        }
      </Card>
    </div>
  )
}

export default UserLeaveQuotasCompact
