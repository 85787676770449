/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react'
import { Divider, Button, Tag, Tooltip, Select } from 'antd'
import { LabeledValue } from 'antd/lib/select'
import { PlusOutlined, CloseOutlined } from '@ant-design/icons'

import IntlMessages from '../../util/IntlMessages'
import UserManageLabels from '../user-manage-labels'
import { invertHexColor } from '../../util/invert-color-wrapper'
import { filterOptions } from '../../util/filter-options'

const { Option } = Select

interface ILabels {
  id: string
  name: string
  color: string
  userCount?: number
}

interface IUserLabels {
  userLabels: ILabels[]
  allLabels: ILabels[]
  handleSelectLabel?: (e) => void
  handleDeselectLabel?: (e) => void
  updateLabel?: (e, id) => void
  deleteLabel?: (e) => void
  isEditable?: boolean
}

const UserLabels = ({ allLabels, userLabels, handleSelectLabel, handleDeselectLabel, updateLabel, deleteLabel, isEditable = true }: IUserLabels): React.ReactElement => {

  const [userLabelsData, setUserLabels] = useState<ILabels[]>([])
  const [userLabelsIds, setUserLabelsIds] = useState<string[]>([])
  const [allLabelsData, setAllLabelsData] = useState<ILabels[]>([])
  const [showUserManageLabels, setShowUserManageLabels] = useState(false)
  const [inputVisible, setInputVisible] = useState(false)

  useEffect(() => {
    setAllLabelsData(allLabels)
  }, [allLabels])

  useEffect(() => {
    setUserLabels(userLabels)
    setUserLabelsIds(() => userLabels.map(label => label.id))
  }, [userLabels])

  function handleManageLabels() {
    setShowUserManageLabels(!showUserManageLabels)
  }

  return (
    <>
      {userLabelsData.map(label => {
        const isLongTag = label.name.length > 20
        // TODO: Extract to a small component
        const labelTag = (
          <span className="user-labels-tag-bg" key={label.id}>
            <Tag
              className="user-labels-tag"
              key={label.id}
              closable={isEditable}
              closeIcon={<CloseOutlined style={{color: invertHexColor(label.color, true)}} />}
              color={`${label.color}cc`}
              style={{ borderColor: label.color}}
              onClose={() => {
                const removeLabel = allLabelsData.find(item => item.id === label.id)
                handleDeselectLabel && handleDeselectLabel(removeLabel)
              }}
            >
              <span style={{color: invertHexColor(label.color, true)}}>
                {isLongTag ? `${label.name.slice(0, 20)}...` : label.name}
              </span>
            </Tag>
          </span>
        )

        return isLongTag ? (
          <Tooltip title={label.name} key={label.id}>
            {labelTag}
          </Tooltip>
        ) : (
          labelTag
        )
      })}
      {inputVisible &&
        <Select
          labelInValue
          autoFocus={true}
          open={inputVisible}
          mode="tags"
          className="user-labels-select"
          optionLabelProp="label"
          onDropdownVisibleChange={() => {
            setInputVisible(false)
          }}
          notFoundContent={<IntlMessages id="components.userLabes.noLabelsFound" />}
          onSelect={(data: LabeledValue) => {
            setInputVisible(false)
            const label = allLabelsData.find(l => l.id === data.key)
            if (label) {
              handleSelectLabel && handleSelectLabel(label)
            } else {
              handleSelectLabel && handleSelectLabel({
                name: data?.label ? (data.label as string).replace(' (new label)', '') : data.value,
                color: '#545454',
              })
            }
          }}
          popupClassName="user-labels-dropdown"
          filterOption={filterOptions}
          filterSort={(optionA) => {
            // (new label) sort at the bottom
            if (optionA.key !== optionA.value) {
              return -1
            }
            return 1
          }}
          dropdownRender={menu => {
            // TODO: VT-4976
            // menu.props.options.map(item => {
            //   if (item.key === '__RC_SELECT_TAG_PLACEHOLDER__' && !item.value.includes('new label')) {
            //     item.value = `${item.value} ${formatMessage({ id: 'components.userLabes.newLabel' })}`
            //     item.name = item.value
            //   }
            //   return item
            // })
            return (
              <>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                <Button type="link" size="small" block
                  onClick={() => setShowUserManageLabels(!showUserManageLabels)}
                >
                  <IntlMessages id="app.manageLabels" />
                </Button>
              </>
            )
          }}
        >
          {allLabelsData
            .filter(label => !userLabelsIds.includes(label.id))
            .map(label => <Option className="label" value={label.name} label={label.id} key={label.id}>{label.name}</Option>)
          }
        </Select>
      }
      {!inputVisible && isEditable &&
        <Tag
          className="user-labels-plus"
          onClick={() => {setInputVisible(true)}}
        >
          <PlusOutlined /> <IntlMessages id="components.userLabes.addLabel" />
        </Tag>
      }
      <UserManageLabels
        labels={allLabelsData}
        visibleModal={showUserManageLabels}
        closeModal={handleManageLabels}
        updateLabel={updateLabel}
        deleteLabel={deleteLabel}
      />
    </>
  )
}

export default UserLabels