import { LeaveRequestStatus } from '@vacationtracker/shared/types/leave-request'
import { IHeatmapHoliday } from '../../components/heatmap-chart/types'

export enum ActiveTabEnum {
  openLeavesRequests = 'openLeavesRequests',
  peopleOffToday = 'peopleOfToday',
  upcomingDaysOff = 'upcomingDaysOff'
}

interface ILocation {
  id: string
  name: string
}

interface IDepartment {
  id: string
  name: string
}

export interface ILabel {
  id: string
  name: string
  color: string
}

interface IHoliday {
  name: string
  date: string
  isHalfDay?: boolean
}

interface IHolidayYear {
  year: number
  holidays: IHoliday[]
}

interface LocationWithHolidays extends ILocation {
  holidays: IHolidayYear[]
}

export interface HeatmapDataResponse {
  locations: LocationWithHolidays[]
  departments: IDepartment[]
  labels: ILabel[]
}

export interface HeatmapData {
  holidays: IHeatmapHoliday[]
  locations: ILocation[]
  departments: IDepartment[]
  labels: ILabel[]
}

export interface IHeatmapQueryParams {
  dateStart: string
  dateEnd: string
  status: LeaveRequestStatus
  limit?: number
  nextToken?: string
}
