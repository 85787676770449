import React, { useEffect, useRef, useState } from 'react'
import { Modal, Form } from 'antd'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import MFACodeInput from '../mfa-code'
import * as logger from '../../services/logger'
import { OTPRef } from 'antd/es/input/OTP'

interface EnableMFAModalProps {
  onSave: (code: string) => void
}

const MFACodeModal = ({ onSave }: EnableMFAModalProps): React.ReactElement => {

  const [form] = Form.useForm()
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoading(true)
        onSave(values.code as string)
      })
      .catch(info => {
        logger.debug('Validate Failed:', info)
      })
  }

  const codeInputRef = useRef<OTPRef>(null)
  // Auto-focus the OTP input field
  useEffect(() => {
    codeInputRef.current?.focus()
  }, [])

  return (
    <Modal
      closable={false}
      open={true}
      onOk={onFinish}
      cancelButtonProps={{ style: { display: 'none' } }}
      confirmLoading={isLoading}
      title={<IntlMessages id="app.mfaEnterCode" />}
      okText={<IntlMessages id="components.modal.verify" />}
    >
      <Form
        key="MFACodeForm"
        form={form}
        name="MFACodeForm"
      >
        <div style={{ marginBottom: '15px' }}><IntlMessages id="app.mfaCodePlaceholder"/></div>
        <div>
          <MFACodeInput handleEnter={onFinish} inputRef={codeInputRef} />
        </div>
      </Form>
    </Modal>
  )
}

export default MFACodeModal