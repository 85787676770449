import axios from 'axios'
import Api from '@vacationtracker/shared/services/api'
import { getCurrentUser } from '@vacationtracker/shared/services/auth'

export default function customAnalyiticsEvents(userConfig = {}) {
  return {
    name: 'custom-analytics-events',
    config: userConfig,
    track: async ({ payload }) => {
      // only call the API if user is logged in
      if (await hasCurrentUser()) {
        await Api.post('/core/analytics-track', {
          eventType: payload.event,
          ...payload.properties,
        })
      } else {
        await axios.post(`${process.env.REACT_APP_API_URL}/core/analytics-track-unauth`, {
          eventType: payload.event,
          ...payload.properties,
        })
      }
    },
  }
}

async function hasCurrentUser(): Promise<boolean> {
  try {
    await getCurrentUser()
    return true
  } catch(err) {
    return false
  }
}
