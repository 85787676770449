import React, { useState } from 'react'
import { App, Modal, Form, Input, Upload } from 'antd'
import { CloseCircleOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'
import ImgCrop from 'antd-img-crop'

import IntlMessages from '../../util/IntlMessages'

import { RcFile } from 'antd/lib/upload/interface'

interface IUserMyProfileModalProps {
  name: string
  visible: boolean
  profilePhoto?: string
  onSave: (name: string, profilePicture?: RcFile) => Promise<void>
  onCancel: () => void
}

interface IFormValues {
  name: string
  profilePhoto?: RcFile
}

const UserMyProfileModal = ({ name, profilePhoto, visible, onSave, onCancel }: IUserMyProfileModalProps) => {
  const [form] = Form.useForm<IFormValues>()
  const { formatMessage } = useIntl()
  const { notification } = App.useApp()
  const defaultValue = {
    name: name,
    imageUrl: profilePhoto,
  }

  const [confirmLoading, setConfirmLoading] = useState(false)
  const [uploadInProgress, setUploadInProgress] = useState(false)

  const onFinish = async () => {
    try {
      setConfirmLoading(true)
      setUploadInProgress(true)
      const { name, profilePhoto } = await form.validateFields()
      await onSave(name.trimEnd(), profilePhoto)
      setUploadInProgress(false)
      setConfirmLoading(false)
    } catch(info) {
      setConfirmLoading(false)
    }
  }

  const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e
    }
    return e?.fileList
  }

  const beforePictureUpload = (file: RcFile) => {
    notification.destroy('INVALID_FILE_TYPE')
    const allowedFileFormats = ['image/png', 'image/jpeg', 'image/gif']
    if (!allowedFileFormats.includes(file.type)) {
      notification.error({
        message: formatMessage({ id: 'error.invalidFileType' }),
        key: 'INVALID_FILE_TYPE',
        description: formatMessage({ id: 'error.invalidFileType.description' }),
        duration: 0,
      })
      return
    }

    form.setFieldsValue({ profilePhoto: file })

    return false
  }

  return (
    <Modal
      title={<IntlMessages id="myProfile.edit.title" />}
      open={visible}
      closeIcon={<CloseCircleOutlined />}
      confirmLoading={confirmLoading}
      okText={<IntlMessages id="app.update" />}
      onOk={onFinish}
      cancelText={<IntlMessages id="app.cancel" />}
      onCancel={onCancel}
    >
      <Form
        key="userUpdateForm"
        form={form}
        name="userUpdateForm"
        onFinish={onFinish}
        initialValues={defaultValue}
      >
        <Form.Item
          name="name"
          label={<IntlMessages id="app.name" />}
          {...formItemLayout}
          rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
        >
          <Input maxLength={100} style={{ width: 300 }} />
        </Form.Item>

        <Form.Item
          name="profilePhoto"
          label={<IntlMessages id="app.profilePicture" />}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          {...formItemLayout}
        >
          <ImgCrop rotationSlider>
            <Upload
              listType="picture-card"
              maxCount={1}
              beforeUpload={beforePictureUpload}
              showUploadList={{
                showPreviewIcon: false,
                showRemoveIcon: false,
              }}
            >
              <div>
                { uploadInProgress ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}><IntlMessages id="app.upload" /></div>
              </div>
            </Upload>
          </ImgCrop>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default UserMyProfileModal
