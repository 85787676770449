import { format, setDate, addMonths, subMonths, endOfMonth } from 'date-fns'
import { IAccrualsDates } from './types'

interface IParams {
  yearStartDay: number
  today: Date
}

export function getDatesForAccrualsSemiMonthly({ yearStartDay, today}: IParams): IAccrualsDates {
  const currentDayOfMonth = today.getDate()
  const lastDayOfMonth = endOfMonth(today).getDate()
  const yearStartsAt = yearStartDay >= lastDayOfMonth ? lastDayOfMonth : yearStartDay

  const firstPaydayOfMonth = yearStartsAt < 16 ? yearStartsAt : ((yearStartDay > 30) ? 15 : yearStartsAt - 15)
  const secondPaydayOfMonth = firstPaydayOfMonth + 15

  if (currentDayOfMonth >= firstPaydayOfMonth && currentDayOfMonth <= secondPaydayOfMonth) {
    const secondPaydayOfCurrentMonth = (secondPaydayOfMonth < lastDayOfMonth && secondPaydayOfMonth < 30) ? secondPaydayOfMonth : lastDayOfMonth
    const dates = {
      accrualPeriodStart: format(setDate(today, firstPaydayOfMonth), 'yyyy-MM-dd'),
      accrualPeriodEnd: format(setDate(today, secondPaydayOfCurrentMonth - 1), 'yyyy-MM-dd'),
      pickerStartDate: format(setDate(today, firstPaydayOfMonth), 'yyyy-MM-dd'),
      pickerEndDate: format(setDate(today, secondPaydayOfCurrentMonth + 11), 'yyyy-MM-dd'),
      nextAccrualPeriod: format(setDate(today, secondPaydayOfCurrentMonth), 'yyyy-MM-dd'),
    }
    return dates
  } else if (currentDayOfMonth < firstPaydayOfMonth) {
    const dates = {
      accrualPeriodStart: format(setDate(subMonths(today, 1), secondPaydayOfMonth), 'yyyy-MM-dd'),
      accrualPeriodEnd: format(setDate(today, firstPaydayOfMonth - 1), 'yyyy-MM-dd'),
      pickerStartDate: format(setDate(subMonths(today, 1), secondPaydayOfMonth), 'yyyy-MM-dd'),
      pickerEndDate: format(setDate(today, firstPaydayOfMonth + 13), 'yyyy-MM-dd'),
      nextAccrualPeriod: format(setDate(today, firstPaydayOfMonth), 'yyyy-MM-dd'),
    }
    return dates
  } else if (currentDayOfMonth > secondPaydayOfMonth) {
    const secondPaydayOfCurrentMonth = (secondPaydayOfMonth < lastDayOfMonth) ? secondPaydayOfMonth : lastDayOfMonth
    const dates = {
      accrualPeriodStart: format(setDate(today, secondPaydayOfCurrentMonth), 'yyyy-MM-dd'),
      accrualPeriodEnd: format(setDate(addMonths(today, 1), firstPaydayOfMonth - 1), 'yyyy-MM-dd'),
      pickerStartDate: format(setDate(today, secondPaydayOfCurrentMonth), 'yyyy-MM-dd'),
      pickerEndDate: format(setDate(addMonths(today, 1), firstPaydayOfMonth + 13), 'yyyy-MM-dd'),
      nextAccrualPeriod: format(setDate(addMonths(today, 1), firstPaydayOfMonth), 'yyyy-MM-dd'),
    }
    return dates
  } else {
    throw new Error(`Invalid dates: currentDayOfMonth: ${currentDayOfMonth}, yearStartDate: ${yearStartDay}`)
  }
}