import React, { useContext, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { App, Button, Table, Tooltip, Switch, Breadcrumb, Tag, Typography } from 'antd'
import { WarningTwoTone, DeleteOutlined, ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import { getAutomations, entitlementByRoleData } from '../../graphql/custom-queries'
import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../circular-progress'
import { notificationStore } from '../../context/notificationsContext/store'
import HandleSubscribeAutomations from '../handle-subscribe-automations'

import { track } from '../../services/analytics/analytics'
import FormattedDate from '@vacationtracker/shared/components/formatted-date'
import EmptyData from '../empty-data'
import { openSupportChat } from '../../util/open-support-chat'

import { IAutomations, IEntitlementByRoleData, IGetAutomations, ILeaveTypeListShort } from '../../types/custom-queries'
import { ITableSort } from '../../types/data'
import { IAutomationViewPage } from '@vacationtracker/shared/types/automations'
import { FrontendUrls } from '../../types/urls'
import { ILabelShort } from '@vacationtracker/shared/types/label'
import { IGetLabelsShort } from '../../types/labels'
import { AddonsStatusEnum } from '@vacationtracker/shared/types/addons'

const { Text } = Typography

const EntitlementByRoleView = ({
  formatMessage,
  amIAdmin,
  deleteAutomation,
  authUserId,
  dateFormat,
  handleSubscribe,
  status,
  userCount,
}: IAutomationViewPage) => {
  const history = useHistory()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { message, modal, notification } = App.useApp()

  const [isLoading, setIsLoading] = useState(true)
  const [activeAddonStatus, setActiveAddonStatus] = useState<{id: string; isActive: boolean} | null>(null)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [sortedInfo, setSortedInfo] = useState<ITableSort>({
    columnKey: 'name',
    order: 'ascend',
  })
  const [addons, setAddons] = useState<IAutomations[]>([])
  const [correlationId, setCorrelationId] = useState<string | null>()
  const [deleteAutomationId, setDeleteAutomationId] = useState<string | null>(null)
  const [leaveTypeList, setLeaveTypeList] = useState<ILeaveTypeListShort[] | []>([])
  const [labelList, setLabelList] = useState<IGetLabelsShort[] | []>([])

  const [getAutomationsQuery] = useManualQuery<IGetAutomations>(getAutomations)
  const [entitlementByRoleDataQuery] = useManualQuery<IEntitlementByRoleData>(entitlementByRoleData)

  useEffect(() => {
    fetchData()
    if (correlationId && !actionNotifications.includes(correlationId) && activeAddonStatus) {
      setActiveAddonStatus(null)
      setCorrelationId(null)
      setDeleteAutomationId(null)
    }
  }, [actionNotifications])

  const fetchData = async () => {
    const response = await getAutomationsQuery()
    if (!response.data || response.error) throw response.error
    setAddons(response.data.getAutomations.filter(automation => automation.automationType === 'ENTITLEMENT_BY_ROLE'))

    const responseData = await entitlementByRoleDataQuery()
    if (!responseData.data || responseData.error) throw response.error
    setLabelList(responseData.data.getLabels)
    setLeaveTypeList(responseData.data.getLeaveTypeList)
  
    setIsLoading(false)
  }

  const createAuomation = () => {
    history.push(`${FrontendUrls.addons}/entitlement-by-role/create`)
  }

  const showConfirmDelete = (row: IAutomations, event) => {
    event.stopPropagation()

    modal.confirm({
      title: formatMessage({ id: 'notifications.deleteAutomationTitle' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage(
        { id: 'notifications.deleteAutomationConfirm' },
        { name: row.name, strong: (...chunks) => <strong>{chunks}</strong> }
      ),
      okText: formatMessage({ id: 'app.delete' }),
      okType: 'danger',
      width: 500,
      maskClosable: true,
      onOk() {
        setDeleteAutomationId(row.id)
        deleteAutomation(row.id as string, row.name as string, row.automationType as string)
      },
    })
  }

  const updateAutomations = async (row, status) => {
    setActiveAddonStatus({
      id: row.id,
      isActive: status,
    })
    track('AUTOMATION_TOGGLE_CHANGED', {
      automationType: row.automationType,
      automationStatus: status ? 'enabled' : 'disabled',
    })

    const response = await Api.post('/core/event', {
      eventType: 'ENTITLEMENT_BY_ROLE_UPDATED',
      eventGroup: 'AUTOMATION',
      name: row.name,
      leaveTypeId: row.leaveTypeId,
      startDate: row.startDate,
      days: row.days,
      labelId: row.labelId,
      automationId: row.id,
      isActive: status,
    })
    setCorrelationId(response.correlationId as string)

    notification.open({
      key: response.correlationId,
      message: formatMessage({ id: 'automations.entitlementByRole.updateInProgress' }),
      icon: (<LoadingOutlined />),
      duration: 0,
    })
    setActionNotifications([ ...actionNotifications, response.correlationId ])
  }

  const confirmUpdateAutomations = (row, checked) => {
    if (!checked) {
      modal.confirm({
        title: formatMessage({ id: 'notifications.updateAutomationTitle' }),
        icon: <ExclamationCircleOutlined />,
        content: formatMessage({ id: 'notifications.updateAutomationConfirm' }),
        okText: formatMessage({ id: 'app.ok' }),
        okType: 'danger',
        width: 500,
        maskClosable: true,
        onOk() {
          updateAutomations(row, checked)
        },
      })
    } else {
      updateAutomations(row, checked)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleTableChange = (pagination, filters, sorter: ITableSort) => {
    setSortedInfo(sorter)
  }

  const automationsColumns = [
    {
      title: <IntlMessages id="app.active" />,
      dataIndex: 'isActive',
      key: 'isActive',
      sorter: ((a, b) => b.isActive - a.isActive),
      sortOrder: (sortedInfo.columnKey === 'isActive' && sortedInfo.order) || null,
      render: (id: string, row) => {
        if (row.error) {
          return (
            <Tooltip title={<IntlMessages id={row.error} />}>
              <div style={{ width: '100%', height: '100', textAlign: 'center'}}>
                <WarningTwoTone twoToneColor="red" style={{ fontSize: 20 }} />
              </div>
            </Tooltip>
          )
        }
        return (
          <Switch checked={row.isActive}
            loading={Boolean(activeAddonStatus && activeAddonStatus.id === id)}
            onClick={(checked, event) => {
              event.stopPropagation()
              confirmUpdateAutomations(row, checked)
            }}
            disabled={(!amIAdmin && authUserId !== row.owner?.id) || status === AddonsStatusEnum.CANCELED}
          />
        )
      },
    },
    {
      title: <IntlMessages id="app.name" />,
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
      sortOrder: (sortedInfo.columnKey === 'name' && sortedInfo.order) || null,
    },
    {
      title: <IntlMessages id="app.leaveType" />,
      dataIndex: 'leaveTypeId',
      key: 'leaveTypeId',
      render: (leaveTypeId: string) => {
        return leaveTypeList.find((leaveType: ILeaveTypeListShort) => leaveType.id === leaveTypeId)?.name || <IntlMessages id="app.deleted" />
      },
    },
    {
      title: <IntlMessages id="app.label" />,
      dataIndex: 'labelId',
      key: 'labelId',
      render: (labelId: string) => {
        const label = labelList.find((label: ILabelShort) => label.id === labelId)
        return label ? 
          <Tag color={label.color}>{label.name}</Tag> :
          <IntlMessages id="app.deleted" />
      },
    },
    {
      title: <IntlMessages id="automations.entitlementByRole.days" />,
      dataIndex: 'days',
      key: 'days',
    },
    {
      title: <IntlMessages id="app.created" />,
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt.localeCompare(b.createdAt),
      sortOrder: (sortedInfo.columnKey === 'createdAt' && sortedInfo.order) || null,
      render: (createdAt) => <FormattedDate value={createdAt.split('T')[0]} format={dateFormat} />,
    },
    {
      title: '',
      className: 'action',
      width: 78,
      dataIndex: 'id',
      key: 'id',
      render: (id: string, row: IAutomations) => (
        <Button
          type="link"
          disabled={(!amIAdmin && authUserId !== row.owner?.id) || status === AddonsStatusEnum.CANCELED}
          onClick={(event) => showConfirmDelete(row, event)}
          loading={Boolean(deleteAutomationId && deleteAutomationId === id)}
        >
          {!(deleteAutomationId && deleteAutomationId === id) && <DeleteOutlined />}
        </Button>
      ),
    },
  ]

  const openCrisp = (e) => {
    e.preventDefault()
    openSupportChat()
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id="automations.ENTITLEMENT_BY_ROLE" />
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb
            items={[
              {
                title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
              },
              {
                title: <Link to={FrontendUrls.addons}><IntlMessages id="sidebar.addons" /></Link>,
              },
              {
                title: <IntlMessages id="automations.ENTITLEMENT_BY_ROLE" />,
              },
            ]}
          />
        </div>
      </div>
      <div className="main-content-body">
        {amIAdmin && status !== AddonsStatusEnum.GRANT_PERIOD &&
          <HandleSubscribeAutomations 
            handleSubscribe={handleSubscribe}
            userCount={userCount}
            status={status}
            automationType="ENTITLEMENT_BY_ROLE"
            formatMessage={formatMessage}
          />
        }
        <div className="main-content-info">
          <Text><IntlMessages id="choiceAutomations.entitlementByRoleInfo" values={{ link: (...chunks) => <a onClick={openCrisp}>{chunks}</a> }}/></Text>
          <Button type="primary" disabled={status === AddonsStatusEnum.CANCELED} onClick={createAuomation}>
            <IntlMessages id="automations.createAutomation" />
          </Button>
        </div>
        {isLoading ?
          <CircularProgress /> :
          <>
            {addons.length > 0 ?
              <Table
                dataSource={addons}
                columns={automationsColumns}
                className="clickable-table"
                onChange={(pagination, filters, sorter) => {
                  handleTableChange(pagination, filters, sorter as ITableSort)
                }}
                rowClassName={(record) => {
                  if(record.error) {
                    return 'clickable-table-error'
                  }
                  return ''
                }}
                onRow={(record) => {
                  if (record.error) {
                    return {onClick: () => { return }}
                  }
                  if (amIAdmin || authUserId === record.owner?.id) {
                    return {
                      onClick: () => history.push(`${FrontendUrls.addons}/${record.automationType.replace(/_/g, '-').toLowerCase()}/${record.id}/edit`),
                    }
                  } else {
                    return {onClick: () => {
                      message.error(formatMessage({ id: 'automations.cantOpenAutomations' }) as string)
                    }}
                  }
                }}
                rowKey="id"
                pagination={false}
              /> :
              <EmptyData
                title={<IntlMessages id="automations.emptyViewTitle" />}
                subTitle={<IntlMessages id="automations.emptyViewMessage" />}
              />
            }
          </>
        }
      </div>
    </div>
  )
}

export default EntitlementByRoleView
