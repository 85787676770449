/* eslint-disable no-console */
interface LogLevelsLegend {
  [key: string]: number
}

type LogLevel = 'error' | 'warning' | 'info' | 'debug' | 'none'

const logLevels: LogLevelsLegend = {
  none: -1,
  error: 0,
  warning: 1,
  info: 2,
  debug: 3,
}

const defaultLogLevel = process.env.LOG_LEVEL || 'error'
let maxLogLevel = logLevels[defaultLogLevel]
const startTime: number = Date.now()
const getTimestamp: Function = Date.now

function setLogLevel(logLevel?: LogLevel | string): void {
  const level = logLevel || process.env.LOG_LEVEL || 'error'
  maxLogLevel = logLevels[level]
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function log(level: LogLevel, ...messages: any[]): void {
  try {
    if (maxLogLevel < logLevels[level]) {
      return
    }

    const now = getTimestamp()

    switch (level) {
      case 'none':
        // Disable logs
        break
      case 'error':
        console.error(`ERROR (${now - startTime}ms)`, ...messages)
        break
      case 'warning':
        console.warn(`WARNING (${now - startTime}ms)`, ...messages)
        break
      case 'debug':
        try {
          console.debug(JSON.stringify({
            level,
            durationMs: now - startTime,
            message: messages,
          }))
        } catch(err) {
          console.debug(`DEBUG (${now - startTime}ms)`, ...messages)
        }
        break
      default:
        console.log(`INFO (${now - startTime}ms)`, ...messages)
        break
    }
  } catch (err) {
    // Do not throw errors from logger
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function error(...messages: any[]): void {
  log('error', ...messages)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function warning(...messages: any[]): void {
  log('warning', ...messages)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function info(...messages: any[]): void {
  log('info', ...messages)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function debug(...messages: any[]): void {
  log('debug', ...messages)
}

export default {
  setLogLevel,
  error,
  warning,
  info,
  debug,
}
