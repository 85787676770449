import React from 'react'

import IntlMessages from '../../util/IntlMessages'

const NotFoundPage: React.FC = () => {

  return (
    <div className="not-found-content">
      <h1 className="title"><IntlMessages id="page404.title" /></h1>
      <p className="sub-title"><IntlMessages id="page404.message" /></p>
    </div>
  )
}

export default NotFoundPage
