import { GraphQLClient } from 'graphql-hooks'
import { getAuth } from '@vacationtracker/shared/graphql/helpers/get-auth-for-appsync'
import { getAppSyncClient } from '@vacationtracker/shared/graphql/helpers/appsync-graphql-subscription-client'

interface IUpdateGraphQlAuthHeaderAndSubscription {
  graphQlClient: GraphQLClient
  force?: boolean
}

export async function updateGraphQlAuthHeaderAndSubscription({
  force,
  graphQlClient,
}: IUpdateGraphQlAuthHeaderAndSubscription): Promise<{ token?: string }> {
  if (graphQlClient) {
    const { jwtToken, subscriptionUrl, header } = await getAuth(force || false)

    if (!jwtToken || !subscriptionUrl || !header) {
      throw new Error('no auth data, load fresh')
    }

    graphQlClient.setHeader('Authorization', jwtToken)

    if (!graphQlClient.subscriptionClient) {
      graphQlClient.subscriptionClient = getAppSyncClient(subscriptionUrl, header)  
    }

    return {
      token: jwtToken,
    }
  }

  return {}
}
