import { IGoogleCalendar, IOutlookCalendar, ISyncedCalendar } from '@vacationtracker/shared/types/calendar'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { ILabel, ILabelShort } from '@vacationtracker/shared/types/label'
import { ILocationShort } from '@vacationtracker/shared/types/location'
import { ITeamShort } from '@vacationtracker/shared/types/team'
import { IGetLocationsShort } from '../../types/locations'
import { IGetTeamsShort } from '../../types/teams'
import { IFormatMessage } from '@vacationtracker/shared/types/display-in-days-hours'

export enum CalendarSyncActionsEnum {
  getCalendarsStart = 'GET_CALENDARS_START',
  getCalendarsSuccess = 'GET_CALENDARS_SUCCESS',
  setFilteredCalendars = 'SET_FILTERED_CALENDARS',
  setCalendarToConnectType = 'SET_CALENDAR_TO_CONNECT_TYPE',
  setWhatToRender = 'SET_WHAT_TO_RENDER',
  setFilters = 'SET_FILTERS',
  setInitialFilters = 'SET_INITIAL_FILTERS',
  setCalendar = 'SET_CALENDAR',
  setIsCreatingCalendar = 'SET_IS_CREATING_CALENDAR',
  setGoogleSyncCalendarData = 'SET_GOOGLE_SYNC_CALENDAR_DATA',
  setOutlookSyncCalendarData = 'SET_OUTLOOK_SYNC_CALENDAR_DATA',
  setCalendarToConnect = 'SET_CALENDAR_TO_CONNECT',
  setCalendarForAdminConsent = 'SET_CALENDAR_FOR_ADMIN_CONSENT_MODAL',
  setOutlookCalendarOwnerAndUserWhoConnectedCalendar = 'SET_OUTLOOK_CALENDAR_OWNER_AND_USER_WHO_CONNECTED_CALENDAR',
  toggleGrantAdminConsentModal = 'TOGGLE_GRANT_ADMIN_CONSENT_MODAL',
  toggleMsLoginModal = 'TOGGLE_MS_LOGIN_MODAL',
  setIsReconnecting = 'SET_IS_RECONNECTING',
}

export type GetCalendarsSuccessPayload = {
  calendars: Omit<ISyncedCalendar, 'timezone' | 'calendarId' | 'companyId' | 'event'>[]
  locations: IGetLocationsShort[]
  departments: IGetTeamsShort[]
  labels: ILabel[]
}

export enum CalendarSyncStorageEnum {
  googleSyncCalendar = 'googleSyncCalendar',
  outlookSyncCalendar = 'outlookSyncCalendar',
}

export interface ICreateUpdateProps  {
  provider: 'Google' | 'Outlook'
  state: ICalendarSyncState
  filterChanged: (values: any) => void
  handleSelectCalendarToSync: (value: string) => void
  handleLoginWihDifferentAccount: (provider: 'Google' | 'Outlook') => void
  handleGoogleCreate: () => void
  handleOutlookCreate: () => void
  handleGoogleUpdate: () => void
  handleOutlookUpdate: () => void
  confirmDeleteCalendar: (calendar: ISyncedCalendar, event: any) => void
  handleCancel: () => void
  refreshCalendarList: (provider: 'Google' | 'Outlook') => void
}


export interface ICalendarSyncState {
  isFetchingData: boolean
  calendars: ISyncedCalendar[]
  locations: ILocationShort[]
  departments: ITeamShort[]
  labels: ILabelShort[]
  initialFilterValues: ISelected[] | {}[]
  filters: IFilter
  filteredCalendars: ISyncedCalendar[]
  calendar: ISyncedCalendar | null
  calendarToConnect: any | null // TODO: Ivan add google and outlook types
  whatToRender: CalendarSyncViewEnum
  googleSyncCalendarData: IGoogleSyncCalendarData | null
  outlookSyncCalendarData: IOutlookSyncCalendarData | null
  calendarToConnectType: string | null
  isGrantAdminConsentModalOpen: boolean
  calendarForAdminConsent: ISyncedCalendar | null
  showMsLoginRequiredModal: boolean
  calendarOwnerEmail: string | null
  userWhoConnectedCalendar: string | null
  isReconnecting: boolean
}

export enum CalendarSyncViewEnum {
  calendars = 'calendars',
  icalCreate = 'icalCreate',
  icalSummary = 'icalSummary',
  googleConnect = 'googleConnect',
  googleCreate = 'googleCreate',
  googleUpdate = 'googleUpdate',
  outlookConnect = 'outlookConnect',
  outlookCreate = 'outlookCreate',
  outlookUpdate = 'outlookUpdate',
  googleReconnect = 'googleReonnect',
  outlookReconnect = 'outlookReconnect',
}

export interface IGoogleSyncCalendarData {
  calendarList: IGoogleCalendar[]
  accessToken: string
  refreshToken: string
}

export interface IOutlookSyncCalendarData {
  calendarList: IOutlookCalendar[]
  accessToken: string
  refreshToken: string
}

export interface IEmptyProps {
  state: {
    whatToRender: CalendarSyncViewEnum
    isFetchingData: boolean
    calendars: ISyncedCalendar[]
    calendarToConnectType: string | null
  }
  shouldEnableSyncedSharedCalendar: boolean
  handleSelectCalendarToSyncType: (value: any) => void
}

export interface IConnectCalendarActionProps {
  shouldEnableSyncedSharedCalendar: boolean
  handleSelectCalendarToSyncType: (value: any) => void
}

export interface ICalendarsProps {
  state: ICalendarSyncState
  history: any
  confirmDeleteCalendar: (calendar: ISyncedCalendar, event: any) => void
  onGrantAdminConsentClick: (calendar: ISyncedCalendar) => void
}

export interface ICalCreateProps {
  state: {
    whatToRender: CalendarSyncViewEnum
    isFetchingData: boolean
    locations: ILocationShort[]
    departments: ITeamShort[]
    labels: ILabelShort[]
    initialFilterValues: ISelected[] | {}[]
  }
  filterChanged: (values: any) => void
  handleCreateIcalCalendarSync: () => void
  handleCancel: () => void
}

export interface ICalSummaryProps {
  state: {
    calendar: ISyncedCalendar | null
    labels: ILabelShort[]
    locations: ILocationShort[]
    departments: ITeamShort[]
    whatToRender: CalendarSyncViewEnum
  }
  confirmDeleteCalendar: (calendar: ISyncedCalendar, event: any) => void
  handleCancel: () => void
}

export interface IConnectProps {
  provider: 'Google' | 'Outlook'
  whatToRender: CalendarSyncViewEnum
  handleGoogleConnect: () => void
  handleOutlookConnect: () => void
  handleCancel: () => void
}

export interface IReconnectProps {
  provider: 'Google' | 'Outlook'
  whatToRender: CalendarSyncViewEnum
  handleGoogleReconnect: () => void
  handleOutlookReconnect: () => void
  handleCancel: () => void
}

export interface IEmptySearchProps {
  state: {
    whatToRender: CalendarSyncViewEnum
    isFetchingData: boolean
    filteredCalendars: ISyncedCalendar[]
    calendars: ISyncedCalendar[]
  }
}

export type CalendarColumnType = Omit<ISyncedCalendar, 'accessToken' | 'refreshToken'>

export interface IOutlookAdminConsentProps {
  formatMessage: (d: IFormatMessage) => string
  calendarName: string
  showModal: boolean
  tenant: string
  clientId: string
  isReconnecting: boolean
  handleCancelOutlookAdminConsentModal: () => void
  handleOutlookAdminConsent: () => void
  handleReconnectAndSync: () => void
}

export interface IOutlookCalendarOwnerAndUserWhoConnectedCalendar {
  calendarOwnerEmail: string
  userWhoConnectedCalendar: string
}