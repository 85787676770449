import { AddonsType, AutomationsType, IntegrationsType } from '../../types/addons'
import { IStripeTaxes } from '../../types/stripe'
import { addons, automations } from '../product-addons'
import { IPrice, IProduct, PriceName, ProductName, getProducts } from './prices'
import { getStripeTaxes } from './stripe-taxes'

export class ProductService {

  private products: IProduct[]
  private taxRates: IStripeTaxes

  public constructor(env: string) {
    this.products = getProducts(env !== 'prod')
    this.taxRates = getStripeTaxes(env)
  }

  public getAllProducts(): IProduct[] {
    return this.products
  }

  public getProductByName(name: ProductName): IProduct {
    return this.products.find(product => product.name === name) as IProduct
  }

  public getAllPrices(): IPrice[]{
    return this.products.reduce((prices: IPrice[], currentValue) => {
      const pricesArray: IPrice[] = currentValue.prices
      return prices.concat(pricesArray)
    }, [])
  }

  /**
   * @returns All prices for main products(CORE_MONTHLY, CORE_ANNUAL, COMPLETE_MONTHLY, COMPLETE_ANNUAL)
   */
  public getAllPricesForMainProducts(): IPrice[] {
    return this.products.flatMap(product => product.isMainProduct ? product.prices : [])
  }

  public getProductByPriceId(priceId: string): IProduct {
    return this.products.find(product => product.prices.some(price => price.id === priceId)) as IProduct
  }

  /**
   * @returns All prices for additional products(ADDONS, AUTOMATIONS, INTEGRATIONS)
   */
  public getAllPricesForAdditionalProducts(): IPrice[] {
    return this.products.flatMap(product => !product.isMainProduct ? product.prices : [])
  }
  
  public getPriceById(priceId: string): IPrice {
    return this.getAllPrices().find(price => price.id === priceId) as IPrice
  }

  public getPriceByName(name: PriceName): IPrice {
    return this.getAllPrices().find(price => price.name === name) as IPrice
  }

  public getAllPricesForScheduledUpgrade(): IPrice[] {
    const names = [PriceName.CORE_ANNUAL_MICROSOFT, PriceName.CORE_ANNUAL_GOOGLE, PriceName.CORE_ANNUAL_SLACK, PriceName.CORE_ANNUAL_GOOGLE,
      PriceName.COMPLETE_MONTHLY_MICROSOFT, PriceName.COMPLETE_MONTHLY_GOOGLE, PriceName.COMPLETE_MONTHLY_SLACK, PriceName.COMPLETE_MONTHLY_EMAIL,
      PriceName.COMPLETE_ANNUAL_MICROSOFT, PriceName.COMPLETE_ANNUAL_GOOGLE, PriceName.COMPLETE_ANNUAL_SLACK, PriceName.COMPLETE_ANNUAL_EMAIL,
      PriceName.COMPLETE_ANNUAL_2023]
    return this.getAllPrices().filter(price => names.includes(price.name))
  }

  public getAllComplete2024TransitionalPrice(): IPrice[] {
    const names = [
      PriceName.COMPLETE_MONTHLY_MICROSOFT, PriceName.COMPLETE_MONTHLY_GOOGLE, PriceName.COMPLETE_MONTHLY_SLACK, PriceName.COMPLETE_MONTHLY_EMAIL,
      PriceName.COMPLETE_ANNUAL_MICROSOFT, PriceName.COMPLETE_ANNUAL_GOOGLE, PriceName.COMPLETE_ANNUAL_SLACK, PriceName.COMPLETE_ANNUAL_EMAIL,
      PriceName.COMPLETE_ANNUAL_2023]
    return this.getAllPrices().filter(price => names.includes(price.name))
  }

  public getTaxes(country?: string, state?: string | null): string[] {
    const canadaCountryCode = 'CA'
    if (country && country.toUpperCase() === canadaCountryCode && state) {
      return this.taxRates[this.getStateName(state)]
    } 
    return this.taxRates.noTax
  }

  public getAllTaxes(): IStripeTaxes {
    return this.taxRates
  }

  public getPriceUnitByProductName(name: ProductName): number {
    const addonsProduct = this.getProductByName(name)
    const addonsPrice = this.getPriceById(addonsProduct?.id as string)

    return addonsPrice?.tiers[0]?.unit_amount ? addonsPrice?.tiers[0]?.unit_amount / 100 : 0.5
  }

  public getProductByAddonType(type: AddonsType | AutomationsType | IntegrationsType) {
    if (addons.includes(type as AddonsType)) {
      return this.getProductByName(ProductName.ADDONS)
    }
    if (automations.includes(type as AutomationsType)) {
      return this.getProductByName(ProductName.AUTOMATIONS)
    }
    //todo add integration
  }

  private getStateName(name: string): string {
    const state = name.toLowerCase()

    switch (state) {
      case 'alberta':
      case 'ab':
        return 'Alberta'
      case 'british columbia':
      case 'bc':
        return 'British Columbia'
      case 'manitoba':
      case 'mb':
        return 'Manitoba'
      case 'new brunswick':
      case 'nb':
        return 'New Brunswick'
      case 'newfoundland and labrador':
      case 'nl':
        return 'Newfoundland and Labrador'
      case 'nova scotia':
      case 'ns':
        return 'Nova Scotia'
      case 'nunavut':
      case 'nu':
        return 'Nunavut'
      case 'ontario':
      case 'on':
        return 'Ontario'
      case 'prince edward island':
      case 'pe':
        return 'Prince Edward Island'
      case 'quebec':
      case 'québec':
      case 'qc':
        return 'Quebec'
      case 'saskatchewan':
      case 'sk':
        return 'Saskatchewan'
      case 'yukon':
      case 'yt':
      case 'yu':
        return 'Yukon'
      default:
        throw new Error('State not found: ' + state)
    }
  
  }
}
