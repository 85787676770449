import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'

interface WhatsNewsState {
  whatsNewsIds: string[]
}

const initialState: WhatsNewsState = {
  whatsNewsIds: [],
}

export const whatsNewsSlice = createSlice({
  name: 'whatsNews',
  initialState,
  reducers: {
    setWhatsNewId: (state, action: PayloadAction<string[]>) => {
      state.whatsNewsIds = action.payload
    },
    addWhatsNewId: (state, action: PayloadAction<string>) => {
      state.whatsNewsIds = [
        ...state.whatsNewsIds,
        action.payload,
      ]
    },
  },
})

export const { setWhatsNewId, addWhatsNewId } = whatsNewsSlice.actions

export const selectWhatsNewsSlice = (state: RootState) => state.whatsNewsSlice

export default whatsNewsSlice.reducer
