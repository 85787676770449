import React, { useContext, useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { App } from 'antd'
import { ClientContext, useSubscription } from 'graphql-hooks'
import { cloneDeep } from 'lodash'

import { notificationStore } from '../../context/notificationsContext/store'
import { errorSubscription } from '../../graphql/custom-queries'
import { generateNotificationInfo } from '../../util/notifications'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectUserIdSlice } from '../../store/user-id-slice'

import { ISubscriptionEvent, ISubscriptionEventMessage } from '@vacationtracker/shared/types/notification'
import { setFetchOnboarding } from '../../store/onboarding-slice'
import logger from '@vacationtracker/shared/functions/logger'
import { updateGraphQlAuthHeaderAndSubscription } from '../../util/update-graphql-header-and-subscription'

type EventResponse = {
  eventResponses: ISubscriptionEvent
}

const Notifications = () => {
  const { actionNotifications, setActionNotifications, eventsNotifications, setEventsNotifications } = useContext(notificationStore)
  const { userId } = useAppSelector(selectUserIdSlice)
  const gqlClient = useContext(ClientContext)
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const actionNotificationsRef = useRef(actionNotifications)
  const eventsNotificationsRef = useRef(eventsNotifications)
  const { notification } = App.useApp()

  useSubscription<EventResponse>({
    query: errorSubscription,
    variables: { userId },
  }, ({ data, errors }) => {
    // The "errors" type is `object[] | undefined`, but WebSocket error returns just one object with `type: 'error'`
    const err: any = errors
    if (errors && (err instanceof Error || !Array.isArray(err))) {
      if (gqlClient) {
        // Renew the token
        updateGraphQlAuthHeaderAndSubscription({
          force: true,
          graphQlClient: gqlClient,
        })
      }
      return
    } else if (errors || !data) {
      logger.error(...errors || [])
      return
    }

    let subscriptionEvent: ISubscriptionEventMessage | null = null
    try {
      subscriptionEvent = JSON.parse(data.eventResponses.originalEvent)
    } catch (err) {
      logger.error('Error parsing subscription event (Notifications)', err)
    }

    // Ignore if event not created from dashboard
    if (subscriptionEvent?.source !== 'dashboard') {
      return
    }

    const eventType = subscriptionEvent.eventType
    if (eventType === 'LEAVE_REQUEST_CREATED' || eventType === 'NOTIFICATION_CREATED' || eventType === 'USER_STATUS_CHANGED') {
      dispatch(setFetchOnboarding(subscriptionEvent.correlationId))
    }

    if (
      data?.eventResponses?.code === 'error' ||
      (
        subscriptionEvent &&
        Array.isArray(actionNotificationsRef.current) &&
        (
          subscriptionEvent.correlationId &&
          actionNotificationsRef.current.includes(subscriptionEvent.correlationId)
        )
      )
    ) {
      const notifications = updateNotification(data, cloneDeep(actionNotificationsRef.current))
      setActionNotifications(notifications ?? [])
    }

    if (
      data?.eventResponses?.code === 'error' ||
      (
        subscriptionEvent && subscriptionEvent.correlationId && eventsNotificationsRef.current &&
        (
          eventsNotificationsRef.current[subscriptionEvent.correlationId] &&
          eventsNotificationsRef.current[subscriptionEvent.correlationId].eventType === subscriptionEvent.eventType
        ) || eventsNotificationsRef.current[`${subscriptionEvent.correlationId}#${subscriptionEvent.eventType}`]
      )
    ) {
      const notifications = updateNotification(data, cloneDeep(eventsNotificationsRef.current))
      setEventsNotifications(notifications ?? [])
    }
  })

  useEffect(() => {
    actionNotificationsRef.current = actionNotifications
  }, [actionNotifications])

  useEffect(() => {
    eventsNotificationsRef.current = eventsNotifications
  }, [eventsNotifications])

  const updateNotification = (data, eventNotificationsRef): { key: string } | void | object => {
    const { action, notifications, options: { title,
      titleValues,
      message,
      description,
      descriptionValues,
      btn,
      duration,
      key,
      updateNotification,
      forceErrorMessage = false,
    } } = generateNotificationInfo(data.eventResponses, eventNotificationsRef, formatMessage)
    if (!updateNotification || (!title && action !== 'error')) {
      return
    }
    const messageValue = title && formatMessage({ id: title }, titleValues) ?
      formatMessage({ id: title }, titleValues) :
      /^error\.[a-zA-Z0-9]+$/.test(message) ?
        formatMessage({ id: message }) :
        message

    const errorMessage = action === 'error' ? formatMessage({ id: 'notifications.error' }, { correlationId: key }) : ''

    let descriptionValue

    if (typeof description !== 'object') {
      descriptionValue = description && `${formatMessage({ id: description }, descriptionValues)} ${errorMessage}`
    } else {
      descriptionValue = <>{description} {errorMessage}</>
    }
    if (forceErrorMessage) {
      descriptionValue = <>{errorMessage}</>
    }

    if (action === 'close') {
      notification.destroy(key)
      return notifications
    }

    notification[action]({
      key,
      message: messageValue,
      description: descriptionValue,
      btn,
      duration,
    })

    return notifications
  }

  return (<></>)
}

export default Notifications
