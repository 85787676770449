import React from 'react'
import { Typography, Row, Col, Divider } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

import IntlMessages from '../../util/IntlMessages'

const { Paragraph, Text, Title } = Typography

interface IBillingInvoice {
  upcomingInvoiceInfoMessage?: string | React.ReactElement
  isLoadingUpcomingInvoiceInfo: boolean
  customerPortalLink?: string
}

const BillingInvoice = ({ customerPortalLink, upcomingInvoiceInfoMessage, isLoadingUpcomingInvoiceInfo }: IBillingInvoice): React.ReactElement => {

  return (
    <>
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <Title level={4}>
            <IntlMessages id="components.billingInvoice.title" />
          </Title>
        </Col>
        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          <Paragraph>
            <IntlMessages id="components.billingInvoice.description" values={{
              link: (...chunks) => customerPortalLink ? <a href={customerPortalLink} target="_blank" rel="noopener noreferrer">{chunks}</a> : '',
              creatingLink: (...chunks) => !customerPortalLink ? <Text disabled><LoadingOutlined /> {chunks}</Text> : '',
            }} />
          </Paragraph>
          {!isLoadingUpcomingInvoiceInfo && upcomingInvoiceInfoMessage && <Paragraph>{upcomingInvoiceInfoMessage}</Paragraph>}
        </Col>
      </Row>
      <Divider />
    </>
  )
}

export default BillingInvoice
