import antdIt from 'antd/locale/it_IT'
import itMessages from '@vacationtracker/shared/i18n/it-it.json'

const ItLang = {
  messages: {
    ...itMessages,
  },
  antd: antdIt,
  locale: 'it-IT',
}
export default ItLang
