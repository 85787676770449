import {
  IMyProfileLeaveType,
  IUserLeaveTypeResult
} from '../../../frontend/src/types/custom-queries'
import {
  ShortestLeaveIntervalEnum
} from '../../types/leave-policy'

export const isFullDayInterval = (
  selectedLeaveTypePolicy: IUserLeaveTypeResult | IMyProfileLeaveType
) =>
  selectedLeaveTypePolicy.shortestLeaveInterval ===
  ShortestLeaveIntervalEnum.fullDay
