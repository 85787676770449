import React from 'react'
import { Modal, Button, Typography } from 'antd'

import IntlMessages from '../../util/IntlMessages'
import { track } from '../../services/analytics/analytics'
import YoutubeEmbed from '../youtube-embed'

import { IWhatsNewComponent } from '@vacationtracker/shared/types/whats-new'

interface Props {
  showPopupNewsModal: boolean
  onCancel: () => void
  news: IWhatsNewComponent | null
}

const { Title, Paragraph } = Typography

export const PopupNewsModal: React.FC<Props> = ({ 
  showPopupNewsModal,
  onCancel,
  news,
}: Props) => {

  if(!news) {
    return <></>
  }

  const handleCancel = () => {
    track('ANNOUNCEMENT_POPUP_CLOSED', {})
    onCancel()
  }

  const handleOpenNews = () => {
    track('LEARN_MORE_BUTTON_CLICKED', {})
    onCancel()
  }

  return (
    <Modal
      open={showPopupNewsModal && Boolean(news)}
      footer={null}
      keyboard={false}
      onCancel={handleCancel}
      width={800}
      className='popup-news-modal'
    >
      {news.imageUrl && !news.videoEmbedId &&
        <img src={news.imageUrl} className='img-responsive' />
      }
      {news.videoEmbedId &&
        <YoutubeEmbed embedId={news.videoEmbedId} />
      }
      {news.subtitle && <Title level={4} className='news-type'>{news.subtitle}</Title>}
      <Title level={2} className='title'>{news.title}</Title>
      <Paragraph style={{ whiteSpace: 'break-spaces', fontSize: 18 }}>
        <div dangerouslySetInnerHTML={{__html: news.description}}></div>
      </Paragraph>
      {news.linkUrl &&
        <div>
          <Button 
            type='primary'
            size='large'
            href={news.linkUrl}
            target={news.openLinkInSameTab ? '_self' : '_blank'}
            onClick={handleOpenNews}
          >
            {news.linkText ? news.linkText : <IntlMessages id="app.learnMore" />}
          </Button>
        </div>
      }

    </Modal>
  )
}
