import { configureStore, combineReducers } from '@reduxjs/toolkit'

import featureFlagsSlice from './feature-flags-slice'
import authUserSlice from './auth-user-slice'
import dateFormatSlice from './date-format-slice'
import localeSlice from './locale-slice'
import leaveRequestActionEventSlice from './leave-request-action-event-slice'
import toilRequestActionEventSlice from './toil-request-action-event-slice'
import companySettingsBillingUpdatedActionEventSlice from './company-settings-billing-updated-action-event-slice'
import authCompanySlice from './auth-company-slice'
import userIdSlice from './user-id-slice'
import badgeFeaturesSlice from './badge-features-slice'
import onboardingSlice from './onboarding-slice'
import helpInfoSlice from './help-info-slice'
import whatsNewsSlice from './whats-news-slice'
import authStateSlice from './auth-state-slice'
import authTokenSlice from './auth-token-slice'

import { saveToLocalStorageMiddleware } from './custom-middleware'

const appReducer = combineReducers({
  userIdSlice,
  featureFlagsSlice,
  whatsNewsSlice,
  authCompanySlice,
  authUserSlice,
  dateFormatSlice,
  localeSlice,
  leaveRequestActionEventSlice,
  toilRequestActionEventSlice,
  companySettingsBillingUpdatedActionEventSlice,
  badgeFeaturesSlice,
  onboardingSlice,
  helpInfoSlice,
  authStateSlice,
  authTokenSlice,
})

type LogOut = {
  type: 'USER_LOGGED_OUT'
  payload: null
}

export const logoutAction: LogOut = {
  type: 'USER_LOGGED_OUT',
  payload: null,
}

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === 'USER_LOGGED_OUT') {
    state = undefined
  }

  return appReducer(state, action)
}

export default rootReducer

export const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware => [...getDefaultMiddleware(), saveToLocalStorageMiddleware],
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
