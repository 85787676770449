import React, { ReactElement, useEffect, useState } from 'react'
import { Alert, Button, Card, Drawer, Typography } from 'antd'
import { useManualQuery } from 'graphql-hooks'
import { useIntl } from 'react-intl'

import * as logger from '../../../services/logger'
import { getAiAssistedOnboardingSetupCoreEventsData, getLeaveTypesListAndLocations } from '../../../graphql/custom-queries'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { useAppSelector } from '../../../store/hooks'
import { generateEventMessage, matchEventsToEntities, translateEventGroup, getLeaveTypeAndLocationNames } from './utils'

import CircularProgress from '../../../components/circular-progress'
import IntlMessages from '../../../util/IntlMessages'
import EmptyData from '../../../components/empty-data'

import { AIAssistedOnboardingCoreEventStatusEnum, IAIAssistedOnboardingSetupItem, IBasicEntitiesAIAssistentResponse } from '@vacationtracker/shared/types/ai-assisted-onboarding'
import { IGetLeaveTypesListAndLocationsData } from '../../../types/leave-types'
import { IGetLeaveTypeList, IGetLocationsShort } from '../../../types/locations'
import { FrontendUrls } from '../../../types/urls'


interface IAiAssistedOnboardingSetupCoreEventsData {
  getAiAssistedOnboardingSetupCoreEvents: IAIAssistedOnboardingSetupItem[]
}

interface IOnboardingDoneNotifications {
  visible: boolean
  handleIsOnboardingNotificationVisible: () => void
}

export interface IMatchedEventsToEntities {
  locations: IAIAssistedOnboardingSetupItem[]
  leaveTypes: IAIAssistedOnboardingSetupItem[]
  departments: IAIAssistedOnboardingSetupItem[]
  leavePolicies: IAIAssistedOnboardingSetupItem[]
}

const { Paragraph, Title } = Typography

const OnboardingDoneNotifications = ({
  visible,
  handleIsOnboardingNotificationVisible,
}: IOnboardingDoneNotifications): ReactElement => {
  const {authCompany} = useAppSelector(selectAuthCompanySlice)
  const {formatMessage} = useIntl()
  const [matchedEvents, setMatchedEvents] = useState<IMatchedEventsToEntities | {}>({})
  const [onboardingSetupEvent, setOnboardingSetupEvent] = useState<IBasicEntitiesAIAssistentResponse | null>(null)
  const [leaveTypes, setLeaveTypes] = useState<IGetLeaveTypeList[] | null>(null)
  const [locations, setLocations] = useState<IGetLocationsShort[] | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [allCompleted, setAllCompleted] = useState(false)
  const [isPolling, setIsPolling] = useState(false)

  const [ getAiAssistedOnboardingSetupCoreEventsDataQuery ] = useManualQuery<
  IAiAssistedOnboardingSetupCoreEventsData,
  { companyId: string }
  >(getAiAssistedOnboardingSetupCoreEventsData)
  const [ getLeaveTypesListAndLocationsQuery ] = useManualQuery<IGetLeaveTypesListAndLocationsData>(getLeaveTypesListAndLocations)

  useEffect(() => {
    if (!allCompleted && isPolling && visible) {
      const interval = setInterval(fetchSetupData, 2000)
      return () => clearInterval(interval)
    }
  }, [allCompleted, isPolling, visible])

  useEffect(() => {
    if (visible) {
      fetchData()
    }
  }, [visible])


  const fetchSetupData = async () => {
    let parsedSetupJSON: IBasicEntitiesAIAssistentResponse = {
      leavePolicies: [],
      locations: [],
      departments: [],
      leaveTypes: [],
    }
    try {
      const setupData = await getAiAssistedOnboardingSetupCoreEventsDataQuery({ variables: {
        companyId: authCompany?.id as string,
      }})
      if (!setupData.data || setupData.error) throw setupData.error
      try {
        const setup = setupData.data.getAiAssistedOnboardingSetupCoreEvents.find(item => item.setupJSON)
        if (!setup || typeof setup.setupJSON !== 'string') {
          setIsPolling(true)
          // start the polling mechanism if no setup data is found
          return
        }
        parsedSetupJSON = JSON.parse(setup?.setupJSON as string) as IBasicEntitiesAIAssistentResponse
        setOnboardingSetupEvent(parsedSetupJSON)
      } catch (error) {
        setOnboardingSetupEvent(null)
      }
      const eventsWithoutSetupJson = setupData.data.getAiAssistedOnboardingSetupCoreEvents.filter(item => !item.setupJSON)
      setAllCompleted(eventsWithoutSetupJson.every(item => item.status === AIAssistedOnboardingCoreEventStatusEnum.completed))
      setMatchedEvents(matchEventsToEntities(parsedSetupJSON, eventsWithoutSetupJson))
      setIsLoading(false)
    } catch (error) {
      logger.error(error)
      setOnboardingSetupEvent(null)
    }
  }

  const fetchData = async () => {
    setIsLoading(true)
    try {
      const locationsLeaveTypesResponse = await getLeaveTypesListAndLocationsQuery()
      if (!locationsLeaveTypesResponse.data || locationsLeaveTypesResponse.error) throw locationsLeaveTypesResponse.error
      setLeaveTypes(locationsLeaveTypesResponse.data.getLeaveTypeList)
      setLocations(locationsLeaveTypesResponse.data.getLocationList)
      await fetchSetupData()
    } catch (error) {
      logger.error(error)
      setIsLoading(false)
    }
  }

  if (!onboardingSetupEvent && !isLoading && !isPolling) {
    return <Drawer
      open={visible}
      title={<IntlMessages id='ai.assisted.onboarding.openSetupNotificationTitle' />}
      onClose={() => handleIsOnboardingNotificationVisible()}
    >
      <EmptyData
        title={<IntlMessages id="ai.assisted.onboarding.openSetupNotificationNoData" />}
        subTitle={<IntlMessages id="ai.assisted.onboarding.openSetupNotificationNoDataSubtitle" />}
      />
      <Button
        type='primary'
        onClick={() => {
          location.reload()
        }}
      >
        <IntlMessages id="app.reload" />
      </Button>
    </Drawer>
  }
  return <Drawer
    open={visible}
    title={<IntlMessages id='ai.assisted.onboarding.openSetupNotificationTitle' />}
    onClose={() => handleIsOnboardingNotificationVisible()}
  >
    {isLoading && <div style={{ textAlign: 'center' }}>
      <CircularProgress />
      <div><IntlMessages id='ai.assisted.onboarding.openSetupNotificationFetchingSetup' /></div>
    </div>}
    {!isLoading && <>
      <div style={{ padding: '20px 20px 0px 20px' }}>
        <Paragraph>
          <IntlMessages id="ai.assisted.onboarding.summary.intro" />
        </Paragraph>
        <Button type="primary" size="large" href={FrontendUrls.dashboard}>
          <IntlMessages id='app.openDashboard' />
        </Button>
        <Paragraph style={{ marginTop: 20 }}>
          <IntlMessages id="ai.assisted.onboarding.summary.introSummary" />
        </Paragraph>
      </div>
      {
        Object.keys(matchedEvents).map((key) => {
          const title = translateEventGroup(key, formatMessage)
          return <div key={key} style={{ padding: 10 }}>
            <Card
              actions={[
                key !== 'leavePolicies' ? (
                  <Button href={FrontendUrls[key]} type="link"><IntlMessages id={`ai.assisted.onboarding.summary.view.${key}`} /></Button>
                ) : (
                  <Button href={FrontendUrls.locations} type="link"><IntlMessages id="ai.assisted.onboarding.summary.view.locations" /></Button>
                ),
              ]}
              className='help-drawer-card'
            >
              <Title level={5}>{title}</Title>
              {matchedEvents[key].map((event: IAIAssistedOnboardingSetupItem) => {
                const { leaveTypeName, locationName } = getLeaveTypeAndLocationNames(
                  event,
                  leaveTypes as IGetLeaveTypeList[],
                  locations as IGetLocationsShort[],
                  (matchedEvents as IMatchedEventsToEntities)?.leaveTypes,
                  (matchedEvents as IMatchedEventsToEntities)?.locations
                )
                return <div key={event.id}>
                  <Paragraph style={{ whiteSpace: 'break-spaces' }}>
                    {generateEventMessage(
                      event.eventType,
                      event.status,
                      formatMessage,
                      {
                        leaveTypeName,
                        locationName,
                      },
                      event.name
                    )}
                  </Paragraph>
                </div>
              })}
              {
                key === 'leavePolicies' && <Alert type="info" message={<IntlMessages id="ai.assisted.onboarding.summary.leavePoliciesInfo" />} />
              }
            </Card>
          </div>
        })
      }
    </>}
  </Drawer>
}

export default OnboardingDoneNotifications