import React, { useEffect, useState, useContext } from 'react'
import { Link, useHistory } from 'react-router-dom'
import {
  App,
  Form,
  Select,
  Breadcrumb,
  Button,
  TimePicker,
  Input,
  Spin,
  Typography,
  Tooltip
} from 'antd'
import {
  LoadingOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined
} from '@ant-design/icons'
import { useIntl } from 'react-intl'
import {
  format,
  startOfMonth,
  subMonths,
  endOfMonth,
  addMonths,
  setDate,
  setHours,
  setMinutes,
  isBefore,
  setMonth
} from 'date-fns'
import dayjs from 'dayjs'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import * as logger from '../../../../services/logger'
import timezone from '@vacationtracker/shared/data/timezone'
import { selectAuthUserSlice } from '../../../../store/auth-user-slice'
import { useAppSelector } from '../../../../store/hooks'
import { notificationStore } from '../../../../context/notificationsContext/store'
import { useShouldEnableFeatures } from '../../../../store/use-should-enable-features'
import { getLocationsTeamsAndLabelsShort, getScheduledReportById } from '../../../../graphql/custom-queries'
import { TimeLocalToUtc, TimeUtcToLocal } from '../../../../util/NotificationTimezoneConverter'
import { filterOptions } from '../../../../util/filter-options'

import CircularProgress from '../../../../components/circular-progress'
import DynamicFields from '../../../../components/dynamic-fields'
import IntlMessages from '../../../../util/IntlMessages'
import NotFoundPage from '../../../NotFoundPage'
import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'

import { IGetScheduledReportByIdData, IScheduledReport, ISendReportMailPayload } from '@vacationtracker/shared/types/scheduled-report'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { ITeamShort } from '@vacationtracker/shared/types/team'
import { ILocationShort } from '@vacationtracker/shared/types/location'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { IGetLabelsShort } from '../../../../types/labels'
import { IGetLocationsTeamsAndLabelsShort } from '../../../../types/custom-queries'
import { NotificationType } from '@vacationtracker/shared/types/notification'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { FrontendUrls } from '../../../../types/urls'

const { Option } = Select
const { Text } = Typography
interface IScheduledReportFormPage {
  match: {
    params: {
      id: string
    }
  }
}

const ScheduledReportFormPage = ({ match }: IScheduledReportFormPage): React.ReactElement => {
  const { modal, notification } = App.useApp()
  const now = new Date()
  const todayDay = format(now, 'dd')

  const { formatMessage } = useIntl()
  const [form] = Form.useForm()
  const history = useHistory()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { authUser: { hourFormat } } = useAppSelector(selectAuthUserSlice)

  const [isLoading, setIsLoading] = useState(true)
  const [isLoadingScheduledReport, setIsLoadingScheduledReport] = useState(true)
  const [submitLoader, setSubmitLoader] = useState(false)
  const [sendingMail, setSendingMail] = useState(false)
  const [reportType, setReportType] = useState(NotificationType.leaveHistoryReport)
  const [teams, setTeams] = useState<ITeamShort[]>([])
  const [locations, setLocations] = useState<ILocationShort[]>([])
  const [labels, setLabels] = useState<IGetLabelsShort[]>([])
  const [filter, setFilter] = useState<IFilter>({
    locationIds: [],
    teamIds: [],
    labelIds: [],
  })
  const [initialFilterValues, setInitialFilterValues] = useState([{}])
  const [timeReport, setTimeReport] = useState('10:00')
  const [dateReport, setDateReport] = useState(() => {
    if(Number(todayDay) > 5) {
      return `${format(addMonths(now, 1), 'MMM')} 5`
    }
    return `${format(now, 'MMM')} 5`
  })
  const [scheduledReport, serScheduledReport] = useState({
    name: '',
    reportType: NotificationType.leaveHistoryReport,
    period: 'LAST_MONTH',
    leaveStatus: ['APPROVED', 'DENIED', 'EXPIRED'],
    sendDayOfMonth: '5',
    sendHourAndMinute: dayjs('10:00', 'HH:mm'),
    emails: [{
      name: '',
    }],
    timezone: '',
  })
  const shouldEnableLabels = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.labels)
  const shouldEnableScheduledReports = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.scheduledReports)

  const [ getLocationsTeamsAndLabelsShortQuery ] = useManualQuery<IGetLocationsTeamsAndLabelsShort>(getLocationsTeamsAndLabelsShort)
  const [ getScheduledReportByIdQuery ] = useManualQuery<IGetScheduledReportByIdData, { id: string }>(getScheduledReportById)

  useEffect(() => {
    if (match.params.id && isLoadingScheduledReport) {
      fetchScheduledReport(match.params.id)
    }
  }, [match.params.id])

  useEffect(() => {
    if (isLoading) {
      fetchData()
    }
  }, [isLoading])

  const fetchData = async () => {
    try {
      const response = await getLocationsTeamsAndLabelsShortQuery()
      if (!response.data || response.error) throw response.error

      setTeams(response.data.getTeamListV2)
      setLocations(response.data.getLocationList)
      setLabels(response.data.getLabels)
      setIsLoading(false)
    } catch (err) {
      logger.error('ERROR FATCH DATA', err)
    }
  }

  const fetchScheduledReport = async (id) => {
    try {
      const response = await getScheduledReportByIdQuery({ variables: { id }})
      if (!response.data || response.error) throw response.error
      const scheduledReportData = response.data.getScheduledReport
      const { hour, minute } = TimeUtcToLocal(scheduledReportData.sendMinute, scheduledReportData.sendHour, scheduledReportData.timezone)

      setTimeReport(`${hour}:${minute}`)
      setDateReport(() => {
        const nextReport = setDate(setHours(setMinutes(now, Number(minute)), Number(hour)), Number(todayDay))
        if(isBefore(nextReport, now)) {
          return `${format(addMonths(now, 1), 'MMM')} ${scheduledReportData.sendDayOfMonth}`
        }
        return `${format(now, 'MMM')} ${scheduledReportData.sendDayOfMonth}`
      })

      setInitialFilterValues(() => {
        const data: ISelected[] = []
        if (scheduledReportData.locations.length > 0) {
          data.push({ type: 'Locations', values: scheduledReportData.locations.map(location => location.id) })
        }
        if (scheduledReportData.teams.length > 0) {
          data.push({ type: 'Departments', values: scheduledReportData.teams.map(team => team.id) })
        }
        if (scheduledReportData?.labels && scheduledReportData.labels.length > 0) {
          data.push({ type: 'Labels', values: scheduledReportData.labels.map(label => label.id) })
        }

        if(data.length === 0) {
          return [{}]
        }
        return data
      })
      serScheduledReport({
        name: scheduledReportData.name,
        reportType: scheduledReportData.reportType as NotificationType,
        period: scheduledReportData.period || 'LAST_MONTH',
        leaveStatus: scheduledReportData.leaveStatus ? scheduledReportData.leaveStatus : ['APPROVED', 'DENIED', 'EXPIRED'],
        sendDayOfMonth: scheduledReportData.sendDayOfMonth,
        sendHourAndMinute: dayjs(`${hour}:${minute}`, 'HH:mm'),
        emails: scheduledReportData.receivers.map((receiver) => {
          return { name: receiver }
        }),
        timezone: scheduledReportData.timezone,
      })
      handleChangeReportType(scheduledReportData.reportType)
      setIsLoadingScheduledReport(false)
    } catch (err) {
      logger.error('ERROR FETCH SHCEDULED REPORT', err)
    }
  }

  const handleChangeReportType = (value) => {
    setReportType(value)
  }

  const onFinish = async () => {
    setSubmitLoader(true)
    let response
    try {
      const values = await form.validateFields()
      const { hour, minute } = TimeLocalToUtc(dayjs(values.sendHourAndMinute).format('mm'), dayjs(values.sendHourAndMinute).format('HH'), values.timezone)
      const data = {
        name: values.name,
        teams: filter.teamIds,
        locations: filter.locationIds,
        labels: filter.labelIds,
        reportType: values.reportType,
        period: values.period || 'LAST_MONTH',
        receivers: values.emails.map(mail => mail.name),
        timezone: values.timezone,
        sendDayOfMonth: values.sendDayOfMonth,
        sendHour: hour,
        sendMinute: minute,
      } as IScheduledReport

      if (values.leaveStatus) {
        data.leaveStatus = values.leaveStatus
      }

      response = await Api.post('/core/event', {
        eventType: match.params.id ? 'SCHEDULED_REPORT_UPDATED' : 'SCHEDULED_REPORT_CREATED',
        eventGroup: 'REPORT',
        scheduledReportId: match.params.id,
        ...data,
      })

      notification.open({
        key: response.correlationId,
        message: match.params.id ?
          formatMessage({ id: 'scheduledReportForm.updateInProgress'}, { scheduledReportName: response.name }) :
          formatMessage({ id: 'scheduledReportForm.createInProgress' }, { scheduledReportName: response.name }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([
        ...actionNotifications,
        response.correlationId,
      ])

      setSubmitLoader(false)
      history.push('/app/reports/scheduled-reports')

      if (!match.params.id) {
        form.resetFields()
      }
    } catch (error) {
      logger.error('ERROR HANDLE SUBMIT', error)
      setSubmitLoader(false)
      if (error.response?.data?.error) {
        notification.error({
          message: formatMessage({ id: 'error.saveFailed' }),
          description: formatMessage({ id: error.response.data.error }),
          duration: 0,
        })
      } else {
        const description = response.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'error.saveFailed' }),
          description,
          duration: 0,
        })
      }
    }
  }

  const onChangeTime = (time) => {
    setTimeReport(dayjs(time).format('HH:mm'))

    setDateReport(() => {
      const nextDeliveryTime = dayjs(time).format()
      const sendDayOfMonth = Number(form.getFieldValue('sendDayOfMonth'))
      const nextDeliveryDate = setDate(new Date(nextDeliveryTime), sendDayOfMonth)
      if(isBefore(new Date(nextDeliveryDate), now)) {
        return `${format(addMonths(now, 1), 'MMM')} ${sendDayOfMonth}`
      }
      return `${format(now, 'MMM')} ${sendDayOfMonth}`
    })

    form.setFieldsValue({
      sendHourAndMinute: dayjs(time),
    })
  }

  const sendReportNow = async () => {
    try {
      setSendingMail(true)
      const values = await form.validateFields()
      const body: ISendReportMailPayload = {
        reportType: values.reportType,
        name: values.name,
        teams: filter.teamIds,
        locations: filter.locationIds,
        labels: filter.labelIds,
        receivers: values.emails.map(mail => mail.name),
        period: values.period,
      }

      if (reportType === NotificationType.leaveHistoryReport) {
        body.leaveStatus = values.leaveStatus
      }

      await Api.post('/core/send-report-mail', body)
      setSendingMail(false)
      notification.success({
        message: formatMessage({ id: 'scheduledReports.reportSentSuccessfully' }),
      })
    } catch (error) {
      logger.error('ERROR HANDLE SEND REPORT NOW', error)
      if (error.errorFields) {
        setSendingMail(false)
        return
      }
      notification.error({
        message: formatMessage({ id: 'app.deliveryFailed' }),
        description: formatMessage({ id: 'error.somethingWentWrong' }),
      })
    }
  }

  const deleteReport =  async (scheduledReportId, name) => {
    let response
    try {
      response = await Api.post('/core/event', {
        eventType: 'SCHEDULED_REPORT_DELETED',
        eventGroup: 'REPORT',
        scheduledReportId,
      })

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'scheduledReports.deleteInProgress' }, { scheduledReportName: name }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([
        ...actionNotifications,
        response.correlationId,
      ])
    } catch (error) {
      logger.error(error)
      if (error.response?.data?.error) {
        notification.error({
          message: formatMessage({ id: 'app.deleteFailed' }),
          description: formatMessage({ id: error.response.data.error }),
          duration: 0,
        })
      } else {
        const description = response.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'app.deleteFailed' }),
          description,
          duration: 0,
        })
      }
    }
  }

  const showConfirmDelete = () => {
    modal.confirm({
      title: formatMessage({ id: 'scheduledReportForm.deleteScheduledReportTitle' }, { name: scheduledReport.name }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage({ id: 'scheduledReportForm.deleteScheduledReportConfirm'}, { name: scheduledReport.name }),
      okText: formatMessage({ id: 'scheduledReportForm.deleteScheduledReport' }),
      okType: 'danger',
      maskClosable: true,
      async onOk() {
        await deleteReport(match.params.id, scheduledReport.name)
        history.push('/app/reports/scheduled-reports')
      },
    })
  }

  const backToScheduledReports = () => {
    history.push('/app/reports/scheduled-reports')
  }

  const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 10 },
  }

  const tailLayout = {
    wrapperCol: match.params.id ? {  xs: { span: 12, offset: 0 }, lg: { span: 12, offset: 3 } } : { xs: { span: 9, offset: 0 }, lg: { span: 9, offset: 3 }},
  }

  if (!shouldEnableScheduledReports) {
    return <NotFoundPage />
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <span>
            {match.params.id ? <IntlMessages id="scheduledReportForm.updateReport" /> : <IntlMessages id="scheduledReports.add" />}
          </span>
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb
            items={[
              {
                title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
              },
              {
                title: <IntlMessages id="sidebar.reports" />,
              },
              {
                title: <Link to='/app/reports/scheduled-reports'><IntlMessages id="app.scheduledReports" /></Link>,
              },
              {
                title: match.params.id ? scheduledReport.name : <IntlMessages id="scheduledReports.add" />,
              },
            ]}
          />
        </div>
      </div>
      <div className="main-content-body">
        {((match.params.id && isLoadingScheduledReport) || isLoading) ?
          <CircularProgress /> :
          <Form
            form={form}
            layout="horizontal"
            onFinish={onFinish}
            initialValues={scheduledReport}
            {...layout}
          >
            <Form.Item
              label={<IntlMessages id="app.name" />}
              name="name"
              rules={[{ required: true, message: <IntlMessages id="scheduledReportForm.required" /> }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="app.reportType" />}
              name="reportType"
              rules={[{ required: true, message: <IntlMessages id="scheduledReportForm.required" /> }]}
            >
              <Select onChange={handleChangeReportType}>
                <Option value="leaveHistoryReport" key="leaveHistoryReport" ><IntlMessages id="app.leaveHistoryReport" /></Option>
                <Option value="leaveBalanceReport" key="leaveBalanceReport" ><IntlMessages id="app.leaveBalanceReport" /></Option>
                <Option value="monthlyLeaveBalanceReport" key="monthlyLeaveBalanceReport" ><IntlMessages id="app.monthlyLeaveBalanceReport" /></Option>
              </Select>
            </Form.Item>

            {(reportType === NotificationType.leaveHistoryReport || reportType === NotificationType.leaveBalanceReport) &&
              <Form.Item label={<IntlMessages id="scheduledReportForm.period" />}>
                <Text type='secondary'>
                  <IntlMessages id="scheduledReportForm.periodInfo" />
                  <Tooltip
                    className="info-tooltip"
                    title={
                      <IntlMessages id="scheduledReportForm.periodInfoTootltip" values={{
                        mail: (...chunks) => (
                          <Text style={{color: 'white'}} copyable>
                            {chunks}
                          </Text>
                        ),
                      }} />}
                  >
                    <QuestionCircleOutlined />
                  </Tooltip>
                </Text>
              </Form.Item>
            }

            {reportType === NotificationType.leaveHistoryReport &&
            <Form.Item
              label={<IntlMessages id="scheduledReportForm.leaveStatus" />}
              name="leaveStatus"
              rules={[{ required: true, message: <IntlMessages id="scheduledReportForm.required" /> }]}
            >
              <Select style={{ width: '270px' }} mode="multiple">
                <Option value="APPROVED" key="APPROVED"><IntlMessages id="app.approved" /></Option>
                <Option value="DENIED" key="DENIED"><IntlMessages id="app.denied" /></Option>
                <Option value="EXPIRED" key="EXPIRED"><IntlMessages id="app.expired" /></Option>
              </Select>
            </Form.Item>}

            <FilterAdvanced
              isForm={true}
              data={{
                Locations: locations,
                Departments: teams,
                Labels: labels,
              }}
              onChangeFilter={(data) => setFilter(data)}
              showLabels={shouldEnableLabels}
              toolTipInfo="components.filterSimple.tooltipInfo"
              initialValues={initialFilterValues}
            />

            <Form.Item
              name="timezone"
              label={<IntlMessages id="app.timezone" />}
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
            >
              <Select style={{ width: 400 }}
                showSearch
                filterOption={filterOptions}>
                {timezone.map(tz => <Option key={tz.id} value={tz.id}>{tz.text}</Option>)}
              </Select>
            </Form.Item>

            <Form.Item
              name="sendDayOfMonth"
              label={
                <span>
                  <IntlMessages id="scheduledReportForm.sendDayOfMonth" />&nbsp;
                  <Tooltip title={<IntlMessages id="scheduledReportForm.sendDayOfMonthInfo" />}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
            >
              <Select style={{ width: 400 }}
                onChange={(day) => setDateReport(() => {
                  const sendHourAndMinuteDate = dayjs(form.getFieldValue('sendHourAndMinute'), 'HH:mm').format()
                  const currentMonth = format(now, 'M')

                  const nextDeliveryDate = setMonth(setDate(new Date(sendHourAndMinuteDate), Number(day)), Number(currentMonth) - 1)
                  if(isBefore(nextDeliveryDate, now)) {
                    return `${format(addMonths(now, 1), 'MMM')} ${day}`
                  }
                  return `${format(now, 'MMM')} ${day}`
                })}
                showSearch>
                {[... new Array(31)].map((day, index) => <Option key={index} value={`${index+1}`}>{index+1}</Option>)}
              </Select>
            </Form.Item>

            <Form.Item
              name="sendHourAndMinute"
              label={
                <span>
                  <IntlMessages id="scheduledReportForm.sendHourAndMinute" />&nbsp;
                  <Tooltip title={<IntlMessages id="scheduledReportForm.sendHourAndMinuteInfo" />}>
                    <QuestionCircleOutlined />
                  </Tooltip>
                </span>
              }
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
            >
              <TimePicker
                minuteStep={15}
                format={hourFormat === HourFormatEnum.twelve ? 'h:mm A' : 'HH:mm'}
                onCalendarChange={onChangeTime}
                use12Hours={hourFormat === HourFormatEnum.twelve}
              />
            </Form.Item>

            <DynamicFields
              name="emails"
              labelText="app.recipients"
              addNewText="app.addNewEmail"
              type="email"
              maxLength={10}
              required={true}
              rules={[{
                required: true,
                whitespace: true,
                message: <IntlMessages id="scheduledReportForm.pleaseInputEmailOrDeleteThisField" />,
              }, {
                type: 'email',
                message: <IntlMessages id="scheduledReportForm.pleaseInputValidEmail" />,
              }]}
            />

            <Form.Item
              label={<IntlMessages id="scheduledReportForm.reportSummary" />}
            >
              <p style={{ whiteSpace: 'pre-line', marginTop: 5 }}>
                {reportType === NotificationType.leaveBalanceReport ?
                  <IntlMessages id="scheduledReportForm.reportSummaryInfoMonthlyBalance" values={{
                    reportType: <IntlMessages id={`export.${reportType}`} />,
                    dateReport,
                    timeReport,
                  }} /> :
                  <IntlMessages id="scheduledReportForm.reportSummaryInfo" values={{
                    from: format(startOfMonth(subMonths(now, 1)), 'EEEE, MMM d'),
                    to: format(endOfMonth(subMonths(now, 1)), 'EEEE, MMM d'),
                    reportType: <IntlMessages id={`export.${reportType}`} />,
                    dateReport,
                    timeReport,
                  }} />
                }
              </p>
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button onClick={sendReportNow} style={{ marginBottom: 10 }} type="dashed" disabled={sendingMail}>
                {sendingMail && <Spin size="small" style={{ paddingRight: 5 }} />}<IntlMessages id="app.sendNow" />
              </Button>
              <div className='d-flex f-right'>
                <Button type="default" style={{ marginRight: 10 }} onClick={backToScheduledReports}>
                  <IntlMessages id="app.cancel" />
                </Button>
                {match.params.id &&
                  <Button style={{ marginRight: 10 }} danger onClick={showConfirmDelete}>
                    <IntlMessages id="scheduledReportForm.deleteReport" />
                  </Button>
                }
                <Button type="primary" loading={submitLoader} htmlType="submit">
                  {match.params.id ?
                    <IntlMessages id="scheduledReportForm.updateReport" /> :
                    <IntlMessages id="scheduledReportForm.saveReport" />
                  }
                </Button>
              </div>
            </Form.Item>
          </Form>
        }
      </div>
    </div>
  )
}

export default ScheduledReportFormPage
