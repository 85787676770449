import { HourFormatEnum } from '../types/user'

export default [
  { 'id': 'Pacific/Niue', 'text': '(GMT-11:00) Niue', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Pago_Pago', 'text': '(GMT-11:00) Pago Pago', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Honolulu', 'text': '(GMT-10:00) Hawaii Time', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Rarotonga', 'text': '(GMT-10:00) Rarotonga', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Tahiti', 'text': '(GMT-10:00) Tahiti', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Marquesas', 'text': '(GMT-09:30) Marquesas', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Anchorage', 'text': '(GMT-09:00) Alaska Time', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Gambier', 'text': '(GMT-09:00) Gambier', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Los_Angeles', 'text': '(GMT-08:00) Pacific Time', hourFormat: HourFormatEnum.twelve },
  { 'id': 'America/Tijuana', 'text': '(GMT-08:00) Pacific Time - Tijuana', hourFormat: HourFormatEnum.twelve },
  { 'id': 'America/Vancouver', 'text': '(GMT-08:00) Pacific Time - Vancouver', hourFormat: HourFormatEnum.twelve },
  { 'id': 'America/Whitehorse', 'text': '(GMT-08:00) Pacific Time - Whitehorse', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Pitcairn', 'text': '(GMT-08:00) Pitcairn', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Dawson_Creek', 'text': '(GMT-07:00) Mountain Time - Dawson Creek', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Denver', 'text': '(GMT-07:00) Mountain Time', hourFormat: HourFormatEnum.twelve },
  { 'id': 'America/Edmonton', 'text': '(GMT-07:00) Mountain Time - Edmonton', hourFormat: HourFormatEnum.twelve },
  { 'id': 'America/Hermosillo', 'text': '(GMT-07:00) Mountain Time - Hermosillo', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Mazatlan', 'text': '(GMT-07:00) Mountain Time - Chihuahua, Mazatlan', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Phoenix', 'text': '(GMT-07:00) Mountain Time - Arizona',  hourFormat: HourFormatEnum.twelve },
  { 'id': 'America/Yellowknife', 'text': '(GMT-07:00) Mountain Time - Yellowknife', hourFormat: HourFormatEnum.twelve },
  { 'id': 'America/Belize', 'text': '(GMT-06:00) Belize', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Chicago', 'text': '(GMT-06:00) Central Time', hourFormat: HourFormatEnum.twelve },
  { 'id': 'America/Costa_Rica', 'text': '(GMT-06:00) Costa Rica', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/El_Salvador', 'text': '(GMT-06:00) El Salvador', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Guatemala', 'text': '(GMT-06:00) Guatemala', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Managua', 'text': '(GMT-06:00) Managua', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Mexico_City', 'text': '(GMT-06:00) Central Time - Mexico City', hourFormat: HourFormatEnum.twelve },
  { 'id': 'America/Regina', 'text': '(GMT-06:00) Central Time - Regina', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Tegucigalpa', 'text': '(GMT-06:00) Central Time - Tegucigalpa', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Winnipeg', 'text': '(GMT-06:00) Central Time - Winnipeg', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Pacific/Galapagos', 'text': '(GMT-06:00) Galapagos', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Bogota', 'text': '(GMT-05:00) Bogota', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Cancun', 'text': '(GMT-05:00) America Cancun', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Cayman', 'text': '(GMT-05:00) Cayman', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Guayaquil', 'text': '(GMT-05:00) Guayaquil', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Havana', 'text': '(GMT-05:00) Havana', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Iqaluit', 'text': '(GMT-05:00) Eastern Time - Iqaluit', hourFormat: HourFormatEnum.twelve },
  { 'id': 'America/Jamaica', 'text': '(GMT-05:00) Jamaica', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Lima', 'text': '(GMT-05:00) Lima', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Nassau', 'text': '(GMT-05:00) Nassau', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/New_York', 'text': '(GMT-05:00) Eastern Time', hourFormat: HourFormatEnum.twelve },
  { 'id': 'America/Panama', 'text': '(GMT-05:00) Panama', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Port-au-Prince', 'text': '(GMT-05:00) Port-au-Prince', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Rio_Branco', 'text': '(GMT-05:00) Rio Branco', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Toronto', 'text': '(GMT-05:00) Eastern Time - Toronto', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Pacific/Easter', 'text': '(GMT-05:00) Easter Island', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Caracas', 'text': '(GMT-04:30) Caracas', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Asuncion', 'text': '(GMT-03:00) Asuncion', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Barbados', 'text': '(GMT-04:00) Barbados', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Boa_Vista', 'text': '(GMT-04:00) Boa Vista', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Campo_Grande', 'text': '(GMT-03:00) Campo Grande', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Cuiaba', 'text': '(GMT-03:00) Cuiaba', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Curacao', 'text': '(GMT-04:00) Curacao', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Grand_Turk', 'text': '(GMT-04:00) Grand Turk', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Guyana', 'text': '(GMT-04:00) Guyana', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Halifax', 'text': '(GMT-04:00) Atlantic Time - Halifax', hourFormat: HourFormatEnum.twelve },
  { 'id': 'America/La_Paz', 'text': '(GMT-04:00) La Paz', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Manaus', 'text': '(GMT-04:00) Manaus', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Martinique', 'text': '(GMT-04:00) Martinique', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Port_of_Spain', 'text': '(GMT-04:00) Port of Spain', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Porto_Velho', 'text': '(GMT-04:00) Porto Velho', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Puerto_Rico', 'text': '(GMT-04:00) Puerto Rico', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Santo_Domingo', 'text': '(GMT-04:00) Santo Domingo', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Thule', 'text': '(GMT-04:00) Thule', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Atlantic/Bermuda', 'text': '(GMT-04:00) Bermuda', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/St_Johns', 'text': '(GMT-03:30) Newfoundland Time - St. Johns', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Araguaina', 'text': '(GMT-03:00) Araguaina', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Argentina/Buenos_Aires', 'text': '(GMT-03:00) Buenos Aires', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Bahia', 'text': '(GMT-03:00) Salvador', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Belem', 'text': '(GMT-03:00) Belem', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Cayenne', 'text': '(GMT-03:00) Cayenne, hourFormat: HourFormatEnum.twentyFour' },
  { 'id': 'America/Fortaleza', 'text': '(GMT-03:00) Fortaleza', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Godthab', 'text': '(GMT-03:00) Godthab', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Maceio', 'text': '(GMT-03:00) Maceio', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Miquelon', 'text': '(GMT-03:00) Miquelon', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Montevideo', 'text': '(GMT-03:00) Montevideo', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Paramaribo', 'text': '(GMT-03:00) Paramaribo', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Recife', 'text': '(GMT-03:00) Recife', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Santiago', 'text': '(GMT-03:00) Santiago', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Sao_Paulo', 'text': '(GMT-02:00) Sao Paulo', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Antarctica/Palmer', 'text': '(GMT-03:00) Palmer', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Antarctica/Rothera', 'text': '(GMT-03:00) Rothera', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Atlantic/Stanley', 'text': '(GMT-03:00) Stanley', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Noronha', 'text': '(GMT-02:00) Noronha', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Atlantic/South_Georgia', 'text': '(GMT-02:00) South Georgia', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Scoresbysund', 'text': '(GMT-01:00) Scoresbysund', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Atlantic/Azores', 'text': '(GMT-01:00) Azores', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Atlantic/Cape_Verde', 'text': '(GMT-01:00) Cape Verde', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Abidjan', 'text': '(GMT+00:00) Abidjan', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Accra', 'text': '(GMT+00:00) Accra', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Bissau', 'text': '(GMT+00:00) Bissau', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Casablanca', 'text': '(GMT+00:00) Casablanca', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/El_Aaiun', 'text': '(GMT+00:00) El Aaiun', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Monrovia', 'text': '(GMT+00:00) Monrovia', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'America/Danmarkshavn', 'text': '(GMT+00:00) Danmarkshavn', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Atlantic/Canary', 'text': '(GMT+00:00) Canary Islands', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Atlantic/Faroe', 'text': '(GMT+00:00) Faeroe', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Atlantic/Reykjavik', 'text': '(GMT+00:00) Reykjavik', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Etc/GMT', 'text': '(GMT+00:00) GMT (no daylight saving)', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Dublin', 'text': '(GMT+00:00) Dublin', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Europe/Lisbon', 'text': '(GMT+00:00) Lisbon', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/London', 'text': '(GMT+00:00) London', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Africa/Algiers', 'text': '(GMT+01:00) Algiers', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Ceuta', 'text': '(GMT+01:00) Ceuta', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Lagos', 'text': '(GMT+01:00) Lagos', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Ndjamena', 'text': '(GMT+01:00) Ndjamena', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Tunis', 'text': '(GMT+01:00) Tunis', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Windhoek', 'text': '(GMT+02:00) Windhoek', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Amsterdam', 'text': '(GMT+01:00) Amsterdam', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Andorra', 'text': '(GMT+01:00) Andorra', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Belgrade', 'text': '(GMT+01:00) Central European Time - Belgrade', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Berlin', 'text': '(GMT+01:00) Berlin', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Brussels', 'text': '(GMT+01:00) Brussels', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Budapest', 'text': '(GMT+01:00) Budapest', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Copenhagen', 'text': '(GMT+01:00) Copenhagen', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Gibraltar', 'text': '(GMT+01:00) Gibraltar', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Luxembourg', 'text': '(GMT+01:00) Luxembourg', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Madrid', 'text': '(GMT+01:00) Madrid', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Malta', 'text': '(GMT+01:00) Malta', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Monaco', 'text': '(GMT+01:00) Monaco', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Oslo', 'text': '(GMT+01:00) Oslo', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Paris', 'text': '(GMT+01:00) Paris', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Prague', 'text': '(GMT+01:00) Central European Time - Prague', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Rome', 'text': '(GMT+01:00) Rome', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Stockholm', 'text': '(GMT+01:00) Stockholm', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Tirane', 'text': '(GMT+01:00) Tirane', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Vienna', 'text': '(GMT+01:00) Vienna', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Warsaw', 'text': '(GMT+01:00) Warsaw', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Zurich', 'text': '(GMT+01:00) Zurich', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Cairo', 'text': '(GMT+02:00) Cairo', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Johannesburg', 'text': '(GMT+02:00) Johannesburg', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Africa/Maputo', 'text': '(GMT+02:00) Maputo', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Tripoli', 'text': '(GMT+02:00) Tripoli', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Amman', 'text': '(GMT+02:00) Amman', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Beirut', 'text': '(GMT+02:00) Beirut', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Damascus', 'text': '(GMT+02:00) Damascus', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Gaza', 'text': '(GMT+02:00) Gaza', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Jerusalem', 'text': '(GMT+02:00) Jerusalem', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Nicosia', 'text': '(GMT+02:00) Nicosia', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Athens', 'text': '(GMT+02:00) Athens', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Bucharest', 'text': '(GMT+02:00) Bucharest', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Chisinau', 'text': '(GMT+02:00) Chisinau', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Helsinki', 'text': '(GMT+02:00) Helsinki', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Istanbul', 'text': '(GMT+02:00) Istanbul', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Kaliningrad', 'text': '(GMT+02:00) Moscow-01 - Kaliningrad', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Kiev', 'text': '(GMT+02:00) Kyiv', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Riga', 'text': '(GMT+02:00) Riga', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Sofia', 'text': '(GMT+02:00) Sofia', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Tallinn', 'text': '(GMT+02:00) Tallinn', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Vilnius', 'text': '(GMT+02:00) Vilnius', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Khartoum', 'text': '(GMT+03:00) Khartoum', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Africa/Nairobi', 'text': '(GMT+03:00) Nairobi', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Antarctica/Syowa', 'text': '(GMT+03:00) Syowa', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Baghdad', 'text': '(GMT+03:00) Baghdad', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Qatar', 'text': '(GMT+03:00) Qatar', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Riyadh', 'text': '(GMT+03:00) Riyadh', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Minsk', 'text': '(GMT+03:00) Minsk', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Moscow', 'text': '(GMT+03:00) Moscow+00 - Moscow', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Tehran', 'text': '(GMT+03:30) Tehran', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Baku', 'text': '(GMT+04:00) Baku', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Dubai', 'text': '(GMT+04:00) Dubai', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Tbilisi', 'text': '(GMT+04:00) Tbilisi', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Yerevan', 'text': '(GMT+04:00) Yerevan', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Europe/Samara', 'text': '(GMT+04:00) Moscow+01 - Samara', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Indian/Mahe', 'text': '(GMT+04:00) Mahe', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Indian/Mauritius', 'text': '(GMT+04:00) Mauritius', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Indian/Reunion', 'text': '(GMT+04:00) Reunion', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Kabul', 'text': '(GMT+04:30) Kabul', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Antarctica/Mawson', 'text': '(GMT+05:00) Mawson', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Aqtau', 'text': '(GMT+05:00) Aqtau', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Aqtobe', 'text': '(GMT+05:00) Aqtobe', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Ashgabat', 'text': '(GMT+05:00) Ashgabat', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Dushanbe', 'text': '(GMT+05:00) Dushanbe' , hourFormat: HourFormatEnum.twentyFour},
  { 'id': 'Asia/Karachi', 'text': '(GMT+05:00) Karachi', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Asia/Tashkent', 'text': '(GMT+05:00) Tashkent', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Yekaterinburg', 'text': '(GMT+05:00) Moscow+02 - Yekaterinburg', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Indian/Kerguelen', 'text': '(GMT+05:00) Kerguelen', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Indian/Maldives', 'text': '(GMT+05:00) Maldives', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Calcutta', 'text': '(GMT+05:30) India Standard Time', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Asia/Colombo', 'text': '(GMT+05:30) Colombo', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Katmandu', 'text': '(GMT+05:45) Katmandu', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Antarctica/Vostok', 'text': '(GMT+06:00) Vostok', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Almaty', 'text': '(GMT+06:00) Almaty', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Bishkek', 'text': '(GMT+06:00) Bishkek', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Dhaka', 'text': '(GMT+06:00) Dhaka', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Asia/Omsk', 'text': '(GMT+06:00) Moscow+03 - Omsk, Novosibirsk', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Thimphu', 'text': '(GMT+06:00) Thimphu', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Indian/Chagos', 'text': '(GMT+06:00) Chagos', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Rangoon', 'text': '(GMT+06:30) Rangoon', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Indian/Cocos', 'text': '(GMT+06:30) Cocos', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Antarctica/Davis', 'text': '(GMT+07:00) Davis', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Bangkok', 'text': '(GMT+07:00) Bangkok', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Hovd', 'text': '(GMT+07:00) Hovd', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Jakarta', 'text': '(GMT+07:00) Jakarta', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Krasnoyarsk', 'text': '(GMT+07:00) Moscow+04 - Krasnoyarsk', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Saigon', 'text': '(GMT+07:00) Hanoi', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Indian/Christmas', 'text': '(GMT+07:00) Christmas', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Antarctica/Casey', 'text': '(GMT+08:00) Casey', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Brunei', 'text': '(GMT+08:00) Brunei', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Choibalsan', 'text': '(GMT+08:00) Choibalsan', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Hong_Kong', 'text': '(GMT+08:00) Hong Kong', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Irkutsk', 'text': '(GMT+08:00) Moscow+05 - Irkutsk', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Kuala_Lumpur', 'text': '(GMT+08:00) Kuala Lumpur', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Macau', 'text': '(GMT+08:00) Macau', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Makassar', 'text': '(GMT+08:00) Makassar', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Manila', 'text': '(GMT+08:00) Manila', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Asia/Shanghai', 'text': '(GMT+08:00) China Time - Beijing', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Singapore', 'text': '(GMT+08:00) Singapore', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Taipei', 'text': '(GMT+08:00) Taipei', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Ulaanbaatar', 'text': '(GMT+08:00) Ulaanbaatar', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Australia/Perth', 'text': '(GMT+08:00) Western Time - Perth', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Asia/Pyongyang', 'text': '(GMT+08:30) Pyongyang', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Dili', 'text': '(GMT+09:00) Dili', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Jayapura', 'text': '(GMT+09:00) Jayapura', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Seoul', 'text': '(GMT+09:00) Seoul', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Tokyo', 'text': '(GMT+09:00) Tokyo', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Yakutsk', 'text': '(GMT+09:00) Moscow+06 - Yakutsk', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Palau', 'text': '(GMT+09:00) Palau', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Australia/Adelaide', 'text': '(GMT+10:30) Central Time - Adelaide', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Australia/Darwin', 'text': '(GMT+09:30) Central Time - Darwin', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Antarctica/DumontDUrville', 'text': '(GMT+10:00) Dumont D Urville', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Magadan', 'text': '(GMT+10:00) Moscow+07 - Magadan', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Vladivostok', 'text': '(GMT+10:00) Moscow+07 - Yuzhno-Sakhalinsk', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Australia/Brisbane', 'text': '(GMT+10:00) Eastern Time - Brisbane', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Australia/Hobart', 'text': '(GMT+11:00) Eastern Time - Hobart', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Australia/Sydney', 'text': '(GMT+11:00) Eastern Time - Melbourne, Sydney', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Pacific/Chuuk', 'text': '(GMT+10:00) Truk', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Guam', 'text': '(GMT+10:00) Guam', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Port_Moresby', 'text': '(GMT+10:00) Port Moresby', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Efate', 'text': '(GMT+11:00) Efate, hourFormat: HourFormatEnum.twentyFour' },
  { 'id': 'Pacific/Guadalcanal', 'text': '(GMT+11:00) Guadalcanal', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Kosrae', 'text': '(GMT+11:00) Kosrae', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Norfolk', 'text': '(GMT+11:00) Norfolk', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Noumea', 'text': '(GMT+11:00) Noumea', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Pohnpei', 'text': '(GMT+11:00) Ponape', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Asia/Kamchatka', 'text': '(GMT+12:00) Moscow+09 - Petropavlovsk-Kamchatskiy', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Auckland', 'text': '(GMT+13:00) Auckland', hourFormat: HourFormatEnum.twelve },
  { 'id': 'Pacific/Fiji', 'text': '(GMT+13:00) Fiji', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Funafuti', 'text': '(GMT+12:00) Funafuti', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Kwajalein', 'text': '(GMT+12:00) Kwajalein', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Majuro', 'text': '(GMT+12:00) Majuro', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Nauru', 'text': '(GMT+12:00) Nauru', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Tarawa', 'text': '(GMT+12:00) Tarawa', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Wake', 'text': '(GMT+12:00) Wake', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Wallis', 'text': '(GMT+12:00) Wallis', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Apia', 'text': '(GMT+14:00) Apia', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Enderbury', 'text': '(GMT+13:00) Enderbury', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Fakaofo', 'text': '(GMT+13:00) Fakaofo', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Tongatapu', 'text': '(GMT+13:00) Tongatapu', hourFormat: HourFormatEnum.twentyFour },
  { 'id': 'Pacific/Kiritimati', 'text': '(GMT+14:00) Kiritimati', hourFormat: HourFormatEnum.twentyFour },
]
