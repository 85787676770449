import React, { useEffect, useRef } from 'react'
import './styles/theme.less'
import { BrowserRouter, useHistory } from 'react-router-dom'
import { GraphQLClient, ClientContext } from 'graphql-hooks'
import App from './containers/App/index'
import { Amplify } from 'aws-amplify'
import { useAppDispatch } from './store/hooks'
import { setAuthState } from './store/auth-state-slice'
import { AuthStateEnum } from '@vacationtracker/shared/types/auth-state'
import { NoTokens } from '@vacationtracker/shared/errors/auth'
import { logout } from './services/auth/logout-handler'
import { FrontendUrls } from './types/urls'
import { updateGraphQlAuthHeaderAndSubscription } from './util/update-graphql-header-and-subscription'

Amplify.configure({
  Auth: {
    Cognito: {
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID!,
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID!,
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID!,
      allowGuestAccess: false,
    },
  },
})

const GraphQlApiUrl = process.env.REACT_APP_GRAPHQL_ENDPOINT || ''

const VtApp = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()

  const tokenRefreshAbortControllerRef = useRef<AbortController>(new AbortController())

  const graphQlClient = new GraphQLClient({
    url: GraphQlApiUrl,
    fetch: async (uri, options) => {
      const response = await fetch(uri, options)
      if (response.status === 401) {
        const { token } = await updateGraphQlAuthHeaderAndSubscription({
          force: true,
          graphQlClient,
        })
        if (token && options?.headers) {
          options.headers['Authorization'] = token
        }
        return fetch(uri, options)
      }
      return response
    },
    // Hide graphql-hooks error logs in production
    logErrors: process.env.REACT_APP_ENVIRONMENT !== 'production',
  })
  // Increase the number of listeners, because we have 11 queries/mutations
  // in the app at the same time and the default max listeners is 10
  graphQlClient.mutationsEmitter.setMaxListeners(20)

  const getAuthState = async () => {
    try {
      await updateGraphQlAuthHeaderAndSubscription({
        graphQlClient,
      })
      dispatch(setAuthState(AuthStateEnum.signedIn))
    } catch(err) {
      dispatch(setAuthState(AuthStateEnum.signUp))
      // Swallow the error
    }
  }

  useEffect(() => {
    tokenRefreshAbortControllerRef.current = new AbortController()
    // We need to handle GraphQL errors inside React component.
    // If the error is a fetchError with `NoTokens` code we need to log out user because the token is no longer valid.
    // We'll handle expired access tokens with active refresh token in the fetch interceptor in the `GraphQLClient.fetch` section.
    graphQlClient.onError = ({result}) => {
      if (result.error?.fetchError?.name === NoTokens.code) {
        logout({
          history: history,
          reduxDispatch: dispatch,
          redirectRoute: FrontendUrls.signin,
        })
        dispatch(setAuthState(AuthStateEnum.signUp))
      }
    }
    getAuthState()
    return () => {
      tokenRefreshAbortControllerRef.current?.abort()
    }
  }, [])

  return <ClientContext.Provider value={graphQlClient}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ClientContext.Provider>
}

export default VtApp
