import { IFilter } from '@vacationtracker/shared/types/filter'
import { isEmpty } from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterData = (filter: IFilter, data: any[]) => {
  const { labelIds, locationIds, teamIds } = filter
  const dataFiltered = data
    .filter((calendar) => {
      if (isEmpty(locationIds)) {
        return calendar
      } else if (locationIds.find(id => calendar?.locations?.includes(id))) {
        return calendar
      }
    })
    .filter((calendar) => {
      if (isEmpty(teamIds)) {
        return calendar
      } else if (teamIds.find(id => calendar?.departments?.includes(id))) {
        return calendar
      }
    })
    .filter((calendar) => {
      if (isEmpty(labelIds)) {
        return calendar
      } else if (labelIds.find(id => calendar?.labels && calendar.labels.includes(id))) {
        return calendar
      }
    })
  return dataFiltered
}