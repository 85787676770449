export enum PaymentMethodTypeEnum {
  card = 'card',
  link = 'link',
}

export interface IPaymentInfoResponse {
  card: string
  expirationDate: string
  stripePaymentMethodId: string
  subscriptionCancelAtPeriodEnd?: boolean
  subscriptionCurrentPeriodEnd?: Date
  type: PaymentMethodTypeEnum
  discount?: IPaymentInfoResponseDiscount
}

export interface IPaymentInfoResponseDiscount {
  start: number
  percent_off?: string
  amount_off?: number
  duration?: string
  duration_in_months?: string
}