import { DEFAULT_WORKING_HOURS_IN_DAY } from '../../data/app-parameters'
import { SubscriptionPlan, SubscriptionPlanEnum } from '../../types/company'
import { FeatureFlagEnum } from '../../types/feature-flags'
import { WorkingHours } from '../../types/work-week'

export const HOURS_MIN = 2
export const HOURS_MAX = 12
export const HOURS_STEP = 0.5

export function userHasDefaultWorkingHours(workHours?: WorkingHours) {
  if (!workHours) {
    return true
  }
  return workHours.every(workDay => workDay[1] === DEFAULT_WORKING_HOURS_IN_DAY)
}

export function getUserWorkingHoursOnDay(workHours: WorkingHours, date: Date): number {
  const workDay = workHours?.find(day => day[0] === date.getDay())
  return workDay ? workDay[1] : 0
}

export function isWorkDayFeatureAllowed(featureFlags: string[], plan?: SubscriptionPlan) {
  return featureFlags?.includes(FeatureFlagEnum.workday)
  // || plan === 'Complete'
}

export function countWorkingHoursInWeek(workHours: WorkingHours): number {
  return workHours.reduce((total, [, hours]) => total + hours, 0)
}

//TODO workday - change this function when we implement user workweek schedule
export function getUserWorkingHoursPerDay(workingHours: WorkingHours | undefined): number {
  return workingHours && workingHours[0] && workingHours[0][1] 
    ? workingHours[0][1]
    : DEFAULT_WORKING_HOURS_IN_DAY
}