import axios from 'axios'

import { ICheckUserId } from '@vacationtracker/shared/types/company'

const url = `${process.env.REACT_APP_API_URL}/google`

export const getUserId = async (idToken: string): Promise<ICheckUserId> => {
  const response = await axios.post(`${url}/get-user`, {
    idToken,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}