import { CreateCompanyActionsEnum, ICreateCompany, ICreateUser } from './types'
import { IEmailInvite, IImportUser } from '@vacationtracker/shared/types/user'

export const actions = {
  setCurrentStep: (payload: number) => ({
    type: CreateCompanyActionsEnum.setCurrentStep,
    payload,
  }),
  onNextStepChange: () => ({
    type: CreateCompanyActionsEnum.onNextStepChange,
    payload: null,
  }),
  onPrevStepChange: () => ({
    type: CreateCompanyActionsEnum.onPrevStepChange,
    payload: null,
  }),
  setCreateCompanyDetails: (payload: ICreateCompany) => ({
    type: CreateCompanyActionsEnum.setCreateCompanyDetails,
    payload,
  }),
  setAnnounceNewUsers: (value: boolean) => ({
    type: CreateCompanyActionsEnum.setAnnounceNewUsers,
    payload: value,
  }),
  setCreateUser: (payload: ICreateUser) => ({
    type: CreateCompanyActionsEnum.setCreateUser,
    payload,
  }),
  loadAllUsersSuccess: () => ({
    type: CreateCompanyActionsEnum.loadAllUsersSuccess,
  }),
  onSelectDeselectAllUsers: () => ({
    type: CreateCompanyActionsEnum.onSelectDeselectAllUsers,
  }),
  onSelectUser: (payload: IImportUser) => ({
    type: CreateCompanyActionsEnum.onSelectUser,
    payload,
  }),
  onAddEmailInvite: (payload: IEmailInvite) => ({
    type: CreateCompanyActionsEnum.onAddEmailInvite,
    payload,
  }),
  onRemoveEmailInvite: (payload: IEmailInvite) => ({
    type: CreateCompanyActionsEnum.onRemoveEmailInvite,
    payload,
  }),
  setEmailInvites: (payload: IEmailInvite[]) => ({
    type: CreateCompanyActionsEnum.setEmailInvites,
    payload,
  }),
  isCreateCompanyLoader: (payload: boolean) => ({
    type: CreateCompanyActionsEnum.isCreateCompanyLoader,
    payload,
  }),
  createCompanyRequest: () => ({
    type: CreateCompanyActionsEnum.createCompanyRequest,
    payload: null,
  }),
  createCompanySuccess: (payload: ICreateCompany) => ({
    type: CreateCompanyActionsEnum.createCompanySuccess,
    payload,
  }),
  createCompanyError: () => ({
    type: CreateCompanyActionsEnum.createCompanyError,
    payload: null,
  }),
  insertLoadedUsers: (payload: IImportUser[]) => ({
    type: CreateCompanyActionsEnum.insertLoadedUsers,
    payload,
  }),
  setSubscriptionPlan: (payload: string) => ({
    type: CreateCompanyActionsEnum.setSubscriptionPlan,
    payload,
  }),
  setSkipSelectPlan: () => ({
    type: CreateCompanyActionsEnum.setSkipSelectPlan,
  }),
}