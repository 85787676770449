import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'

import { ILocationWorkWeekUpdatedEvent, IUserWorkWeekUpdatedEvent } from '@vacationtracker/shared/types/work-week'
import { IUserInfo } from '../types/users'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
interface AuthUserState {
  authUser: IUserInfo
  isLoadingAuthUser: boolean
  isSigningUp: boolean
}

const initialState: AuthUserState = {
  isLoadingAuthUser: true,
  isSigningUp: false,
  authUser: {
    id: '',
    name: '',
    initialUser: false,
    role: 'User',
    startDate: '',
    email: '',
    platform: 'slack',
    imageUrl: '',
    approverTo: [],
    location: {
      id: '',
      name: '',
      workWeek: [1,2,3,4,5],
    },
    workWeek: [1,2,3,4,5],
    workWeekType: 'LOCATION',
    team: {
      id: '',
      name: '',
    },
    status: '',
    isNameLocked: false,
    hourFormat: HourFormatEnum.twentyFour,
    approverToTeams: [],
  },
}

const roleSelector = (state: RootState) => state.authUserSlice.authUser.role

export const roleAdmin = createSelector([roleSelector], role => role.toUpperCase() === 'ADMIN')
export const roleApprover = createSelector([roleSelector], role => role.toUpperCase() === 'APPROVER')
export const roleUser = createSelector([roleSelector], role => role.toUpperCase() === 'USER')

export const authUserSlice = createSlice({
  name: 'authUser',
  initialState,
  reducers: {
    setAuthUser: (state, action: PayloadAction<IUserInfo>) => {
      state.authUser = action.payload
      state.isLoadingAuthUser = false
    },
    setAuthUserWorkWeek: (state, action: PayloadAction<IUserWorkWeekUpdatedEvent>) => {
      if (state.authUser.id === action.payload.userId) {
        state.authUser.workWeek = action.payload.days
        state.authUser.workHours = action.payload.workHours
        state.authUser.workWeekType = 'USER'
      }
    },
    setAuthUserLocationWorkWeek: (state, action: PayloadAction<ILocationWorkWeekUpdatedEvent>) => {
      if (state.authUser.location.id === action.payload.locationId &&
          (action.payload.cancelUserSettings || (!action.payload.cancelUserSettings && state.authUser.workWeekType === 'LOCATION'))) {
        state.authUser.workWeek = action.payload.days
        state.authUser.workHours = action.payload.workHours
        state.authUser.workWeekType = 'LOCATION'
        state.authUser.location.workWeek = action.payload.days
      }
    },
    setIsSigningUp: (state, action: PayloadAction<boolean>) => {
      state.isSigningUp = action.payload
    },
  },
})

export const { setAuthUser, setAuthUserWorkWeek, setAuthUserLocationWorkWeek, setIsSigningUp } = authUserSlice.actions

export const selectAuthUserSlice = (state: RootState) => state.authUserSlice

export default authUserSlice.reducer
