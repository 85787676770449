import React, { useEffect, useState } from 'react'
import { Form, Input, Select } from 'antd'
import { useIntl } from 'react-intl'
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'

import IntlMessages from '../../util/IntlMessages'
import { SelectChannelFromSlack } from '../select-channel-from-slack'

import { INotificationDestinations, IDestination, ISelected } from './types'
import { SelectChannelFromMicrosoft } from '../select-channel-from-microsoft'

const { Option } = Select

const Destination = ({
  index,
  selectedDestinations,
  showAddButton,
  selected,
  onSelect,
  onAdd,
  onRemove,
  onValueChange,
  lockTypeSelector,
  filterByWidth,
  form,
  platform,
  setChannelName,
  notification,
}: IDestination) : React.ReactElement => {
  const { formatMessage } = useIntl()
  const onSelectValues = (value) => {
    if (value) {
      // Reset value if you change selected type
      const formData = form.getFieldsValue()
      for (const [key, keyValue] of Object.entries(formData)) {
        if(key.includes('destination-') && keyValue as ISelected && (keyValue as ISelected)?.type === value) {
          const value = form.getFieldValue(key)
          setTimeout(() => {
            form.setFieldsValue({[key]: {...value, ['addresses']: []}})
          })
        }
      }
      onSelect(selected.type, value)
    } else {
      onSelect(selected.type)
    }
  }

  const onRemoveDestiation = () => {
    const formData = form.getFieldsValue()

    if(formData['destination-1']) {
      for (const [key, val] of Object.entries(formData)) {
        if(key.includes('destination-')) {
          const keyIndex = Number(key.replace('destination-', ''))
          const newFormData = omit(form.getFieldsValue(), [`destination-${keyIndex}`])

          if (index < keyIndex) {
            setTimeout(() => { // This is need to set value, I don't know why not working without timeout (DO NOT DELETE) Author: Srdjan Ljubi brt
              form.setFieldsValue({
                [`destination-${keyIndex - 1}`]: val,
                [`destination-${keyIndex}`]: undefined,
              })
              onValueChange({
                ...newFormData,
                [`destination-${keyIndex - 1}`]: val,
              })
            })
          } else {
            onValueChange(newFormData)
          }
        }
      }
    } else {
      setTimeout(() => { // This is need to set value, I don't know why not working without timeout (DO NOT DELETE) Author: Srdjan Ljubi brt
        form.setFieldsValue({
          ['destination-0']: undefined,
        })
        onValueChange(omit(form.getFieldsValue(), ['destination-0']))
      })
    }

    if (formData[`destination-${index}`]?.type === formatMessage({ id: 'components.selectChannelForm.channel' })) {
      setChannelName('')
    }
    // because of setTimeouts force form fields with shouldUpdate to rerender
    form.setFieldsValue(form.getFieldsValue(true))
    onRemove()
  }

  return (
    <Input.Group compact>
      <Form.Item
        name={[`destination-${index}`, 'type']}
        noStyle
        rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
      >
        <Select style={{ width: filterByWidth }}
          onSelect={(type) => onSelect(type)}
          onChange={(value) => onSelectValues(value)}
          disabled={lockTypeSelector}
          notFoundContent=""
        >
          {selectedDestinations.map(item => (<Option key={item} value={item}>{item}</Option>))}
        </Select>
      </Form.Item>
      {isEmpty(selected) ?
        <Input disabled className="fake-input" /> :
        selected.type === formatMessage({ id: 'components.selectChannelForm.channel' }) ?
          <>
            { platform === 'slack' &&
              <SelectChannelFromSlack
                name={[`destination-${index}`, 'addresses']}
                onSelectDestination={onSelectValues}
                setChannelName={setChannelName}
                form={form}
              />
            }
            { platform === 'microsoft' &&
              <SelectChannelFromMicrosoft
                name={[`destination-${index}`, 'addresses']}
                msGroupName={[`destination-${index}`, 'msGroup']}
                onSelectDestination={onSelectValues}
                setChannelName={setChannelName}
                notification={notification}
                form={form}
              />
            }
          </> :
          <Form.Item
            preserve={false}
            name={[`destination-${index}`, 'addresses']}
            rules={[{
              required: true,
              type: 'array',
              message: (<IntlMessages id="form.inputRequired" />),
              defaultField: { type: 'email', message: <IntlMessages id="form.validEmailRequired" /> },
            }]}
          >
            <Select style={{ width: 250, marginLeft: 5 }}
              mode="tags"
              placeholder={<IntlMessages id="components.notificationDestination.label" />}
              onChange={(email) => onSelectValues(email)}
              className="select-emails"
            />
          </Form.Item>
      }
      {selected.type && platform !== 'google' && (
        <MinusCircleOutlined onClick={onRemoveDestiation} style={{ color: 'red', marginLeft: 5, marginTop: 9 }}/>
      )}
      {showAddButton && selected.type && selected.values && selected.values.length > 0 && (
        <PlusCircleOutlined onClick={onAdd} style={{ color: 'green', marginLeft: 5, marginTop: 9 }} />
      )}
    </Input.Group>
  )
}

const NotificationDestinations = ({
  platform,
  form,
  setChannelName,
  onValueChange,
  filterByWidth = 160,
  initialDestination = [{}],
  notification,
  shouldReset,
}: INotificationDestinations): React.ReactElement => {
  const { formatMessage } = useIntl()
  const [ selectedTypes, setSelectedTypes ] = useState<string[]>(initialDestination.map(destination => destination.type))
  const [ selected, setSelected ] = useState<ISelected[] | {}[]>(initialDestination)
  const [ selectedDestinations ] = useState(platform === 'google' || platform ===  'email' ? ['Email'] : [formatMessage({ id: 'components.selectChannelForm.channel' }), 'Email'])

  useEffect(() => {
    if (shouldReset) {
      setSelectedTypes(initialDestination.map(destination => destination.type))
      setSelected([{}])
    }
  }, [shouldReset])

  const onSelect = (type: string, values) => {
    if (selectedTypes.includes(type)) {
      if(selectedDestinations.includes(values)) {
        setSelected(prevState => {
          const newStateSelected = prevState.filter(state => state && (state as ISelected).type !== type)
          return [...newStateSelected, { type: values, values: {} }]
        })
        setSelectedTypes(prevState => {
          const newStateSelectedTypes = prevState.filter(state => state !== type)
          return [...newStateSelectedTypes, values]
        })
      } else {
        const newSelected = selected
          .map(item => (item.type === type) ? { type, values } : item)
          .filter(f => !isEmpty(f))
        setSelected(newSelected)
      }
    } else if (type) {
      setSelected((prevState) => {
        const newSelected = uniqBy([...prevState.filter(f => !isEmpty(f)), { type, values }], 'type')
          .map(item => {
            if (isEmpty((item as ISelected).values)) {
              return {
                type: (item as ISelected).type,
                values: undefined,
              }
            }
            return item
          })
        return newSelected
      })
      setSelectedTypes((prevState) => {
        return [...new Set([...prevState, type])]
      })
    }
    onValueChange(form.getFieldsValue(true))
  }

  const onAdd = () => {
    const newSelected = [
      ...selected,
      {},
    ]
    setSelected(newSelected)
  }

  const onRemove = (index) => {
    const newSelected = cloneDeep(selected)
    if(!isEmpty(newSelected)) {
      const removed = newSelected.splice(index, 1) as ISelected[]
      setSelected(newSelected.length ? newSelected : [{}])
      setSelectedTypes(selectedTypes.filter(type => type !== removed[0].type))
    }
  }

  return <>
    <Form.Item
      tooltip={<IntlMessages id="components.notificationDestination.tooltip" />}
      label={<IntlMessages id="components.notificationDestination.label" />}
      className="notification-destinations"
      wrapperCol={{ xs: { span: 24 }, sm: { span: 24 }, md: { span: 16 }, lg: { span: 17 }, xl: { span: 14 } }}
    >
      {selected.map((filter, i) => (
        <Destination
          selectedDestinations={difference(selectedDestinations, selectedTypes)}
          showAddButton={ i !== (selectedDestinations.length - 1) && i === (selected.length - 1) }
          lockTypeSelector={ i < (selected.length - 1) }
          selected={selected[i] as ISelected}
          onSelect={onSelect}
          onAdd={onAdd}
          onRemove={() => onRemove(i)}
          onValueChange={onValueChange}
          key={i}
          filterByWidth={filterByWidth}
          index={i}
          form={form}
          platform={platform}
          setChannelName={setChannelName}
          notification={notification}
        />
      ))}
    </Form.Item>
  </>
}

export default NotificationDestinations