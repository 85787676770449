{
    "app.save": "Enregistrer",
    "app.next": "Suivant",
    "app.prev": "Précédent",
    "app.back": "Retour",
    "app.yes": "Oui",
    "app.no": "Non",
    "app.ok": "OK",
    "app.all": "Tout",
    "app.create": "Créer",
    "app.delete": "Supprimer",
    "app.cancel": "Annuler",
    "app.close": "Fermer",
    "app.edit": "Modifier",
    "app.done": "Terminé",
    "app.and": "et",
    "app.set": "défini",
    "app.by": "par",
    "app.removed": "Supprimé",
    "app.unlimited": "Illimité",
    "app.update": "Modifier",
    "app.updatedInProgress": "Traitement…",
    "app.updatedSuccessfully": "Demande envoyée",
    "app.correlationIdError": "Une erreur s'est produite. Veuillez contacter l'assistance à l'adresse hello@vacationtracker.io en précisant le numéro {correlationId}",
    "app.notificationError": "Mince 😕 Un problème est survenu. ",
    "app.tryLateOrContactSupport": "Un problème est survenu. Veuillez réessayer ultérieurement, ou contacter l'assistance à l'adresse hello@vacationtracker.io en précisant le numéro {code}",
    "app.logs": "Journaux",
    "app.home": "Accueil",
    "app.name": "Nom",
    "app.monday": "lundi",
    "app.tuesday": "mardi",
    "app.wednesday": "mercredi",
    "app.thursday": "jeudi",
    "app.friday": "vendredi",
    "app.saturday": "samedi",
    "app.sunday": "dimanche",
    "app.january": "janvier",
    "app.february": "février",
    "app.march": "mars",
    "app.april": "avril",
    "app.may": "mai",
    "app.june": "juin",
    "app.july": "juillet",
    "app.august": "août",
    "app.september": "septembre",
    "app.october": "octobre",
    "app.november": "novembre",
    "app.december": "décembre",
    "app.comingSoon": "Bientôt disponible",
    "app.export.comingSoon": "Nos équipes travaillent sur cette fonctionnalité, qui devrait bientôt être lancée.",
    "app.currentYear": "Année en cours",
    "app.nextYear": "Année prochaine",
    "app.or": "Ou",
    "app.trialExpired": "La version d'essai expire dans {days} {daysInfo}. Si cela vous a plu, pensez à vous <a>inscrire</a> pour continuer à utiliser Vacation Tracker au terme de la période d'essai.",
    "app.pastDue": "Vous n'aurez bientôt plus accès à Vacation Tracker car le paiement a échoué. Pour continuer à utiliser Vacation Tracker, <a>renseignez une carte de paiement valide</a>.",
    "app.copyright": "Droits d'auteur Vacation Tracker",
    "button.yes": "Oui",
    "button.no": "Non",
    "form.inputRequired": "Ce champ est obligatoire",
    "form.validEmailRequired": "Veuillez saisir une adresse e-mail valide.",
    "containers.topbar.setUpTheBot": "Configurer le bot",
    "containers.topbar.userProductTourTitle": "Utilisateur",
    "containers.topbar.approverProductTourTitle": "Approbateur",
    "containers.topbar.adminProductTourTitle": "Administrateur",
    "containers.topbar.userProductTourInfo": "En tant qu'Utilisateur Vacation Tracker, vous pouvez poser des demandes de congés et les gérer depuis le tableau de bord ou le bot, et bénéficier d'une vue d'ensemble des absences planifiées au sein de votre organisation.\n\nLe tableau de bord vous permet de :\n- poser des demandes de congés ;\n- bénéficier d'une vue d'ensemble depuis la page d'accueil et le calendrier ;\n- consulter votre profil utilisateur.\n\nLe bot vous permet de :\n- poser des demandes de congés ;\n- recevoir des notifications quotidiennes ou hebdomadaires.\n\nSi vous avez d'autres questions ou remarques, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle : ",
    "containers.topbar.approverProductTourInfo": "En tant qu'Approbateur, vous possédez le deuxième niveau d'autorisation le plus élevé de Vacation Tracker, et vous pouvez accepter ou rejeter des demandes de congés envoyées par les utilisateurs du service pour lequel vous avez été désigné comme Approbateur.\n\nLe tableau de bord vous permet de :\n- poser des demandes de congés, ou en ajouter pour d'autres membres du service ;\n- bénéficier d'une vue d'ensemble depuis la page d'accueil et le calendrier ;\n- définir des notifications ;\n- consulter votre profil utilisateur ;\n- gérer les demandes (en consulter l'historique, les approuver ou les rejeter).\n\nLe bot vous permet de :\n- poser des demandes de congés ;\n- approuver ou rejeter des demandes de congés ;\n- recevoir des notifications quotidiennes ou hebdomadaires.\n\nSi vous avez d'autres questions ou remarques, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle : ",
    "containers.topbar.adminProductTourInfo": "En tant qu'Administrateur, vous possédez le niveau d'autorisation le plus élevé de Vacation Tracker, et vous pouvez personnaliser tous les paramètres depuis le tableau de bord d'administration.\n\nLe tableau de bord vous permet de :\n- poser des demandes de congés, ou en ajouter pour d'autres membres du service ;\n- bénéficier d'une vue d'ensemble depuis la page d'accueil et le calendrier ;\n- créer et configurer plusieurs services (définir des Approbateurs) ;\n- définir des notifications ;\n- gérer les utilisateurs (actifs, inactifs ou supprimés) ;\n- créer et gérer les sites (types de congés, jours fériés, notifications).\n\nLe bot vous permet de :\n- poser des demandes de congés ;\n- approuver ou rejeter des demandes de congés ;\n- recevoir des notifications quotidiennes ou hebdomadaires.\n\nSi vous avez d'autres questions ou remarques, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle : ",
    "containers.google.topbar.userProductTourInfo": "En tant qu'Utilisateur Vacation Tracker, vous pouvez poser des demandes de congés et les gérer depuis le tableau de bord ou le bot, et bénéficier d'une vue d'ensemble des absences planifiées au sein de votre organisation.\n\nLe tableau de bord vous permet de :\n- poser des demandes de congés ;\n- bénéficier d'une vue d'ensemble depuis la page d'accueil et le calendrier ;\n- consulter votre profil utilisateur.\n\nSi vous avez d'autres questions ou remarques, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle : ",
    "containers.google.topbar.approverProductTourInfo": "En tant qu'Approbateur, vous possédez le deuxième niveau d'autorisation le plus élevé de Vacation Tracker, et vous pouvez accepter ou rejeter des demandes de congés envoyées par les utilisateurs du service pour lequel vous avez été désigné comme Approbateur.\n\nLe tableau de bord vous permet de :\n- poser des demandes de congés, ou en ajouter pour d'autres membres du service ;\n- bénéficier d'une vue d'ensemble depuis la page d'accueil et le calendrier ;\n- définir des notifications ;\n- consulter votre profil utilisateur ;\n- gérer les demandes (les approuver ou les rejeter, en consulter l'historique).\n\nSi vous avez d'autres questions ou remarques, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle : ",
    "containers.google.topbar.adminProductTourInfo": "En tant qu'Administrateur, vous possédez le niveau d'autorisation le plus élevé de Vacation Tracker, et vous pouvez personnaliser tous les paramètres depuis le tableau de bord d'administration.\n\nLe tableau de bord vous permet de :\n- poser des demandes de congés, ou en ajouter pour d'autres membres du service ;\n- bénéficier d'une vue d'ensemble depuis la page d'accueil et le calendrier ;\n- créer et configurer plusieurs services (définir des Approbateurs) ;\n- définir des notifications ;\n- gérer les utilisateurs (actifs, inactifs ou supprimés) ;\n- créer et gérer les sites (types de congés, jours fériés, notifications).\n\nSi vous avez d'autres questions ou remarques, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle : ",
    "sidebar.main": "Principal(e)",
    "sidebar.dashboard": "Tableau de bord",
    "sidebar.teams": "Services",
    "sidebar.location": "Site",
    "sidebar.locations": "Sites",
    "sidebar.holidays": "Jours fériés",
    "sidebar.settings": "Paramètres",
    "sidebar.leaves": "Demandes de congés",
    "sidebar.requestLeave": "Poser une demande de congés",
    "sidebar.addLeave": "Ajouter une demande de congés",
    "sidebar.changelog": "Journal des modifications",
    "sidebar.calendar": "Calendrier",
    "sidebar.general": "Paramètres généraux",
    "sidebar.leaveTypes": "Types de congés",
    "sidebar.export": "Exporter",
    "sidebar.notifications": "Notifications",
    "sidebar.billing": "Facturation",
    "sidebar.faq": "Centre d'assistance",
    "sidebar.users": "Utilisateurs",
    "sidebar.user": "Utilisateur",
    "sidebar.reports": "Rapports",
    "sidebar.scheduledReports": "Rapports planifiés",
    "sidebar.myProfile": "Mon profil",
    "connect.companyExists.title": "Cette entreprise existe déjà",
    "connect.companyExists.line1": "L'entreprise {companyName} est déjà inscrite à Vacation Tracker",
    "connect.companyExists.line2Slack": "Utilisez-vous les bons identifiants ?",
    "connect.companyExists.line3Slack": "Votre adresse e-mail : {email}",
    "connect.companyExists.line3aSlack": "Votre espace de travail Slack : {workspaceName}",
    "connect.companyExists.line2Other": "Utilisez-vous la bonne adresse e-mail ?",
    "connect.companyExists.line3Other": "Votre adresse e-mail : {email}",
    "connect.companyExists.line4Slack": "Si ces identifiants sont corrects, votre compte n'est pas activé. Veuillez contacter l'administrateur de votre organisation Vacation Tracker :",
    "connect.companyExists.line4Other": "Si cette adresse e-mail est correcte, votre compte n'est pas activé. Veuillez contacter l'administrateur de votre organisation Vacation Tracker :",
    "connect.companyExists.line5": "Veuillez sinon vous connecter avec un autre compte <capitalize>{platform}</capitalize> ou depuis une fenêtre de navigation privée de votre navigateur.",
    "connect.companyExists.line6Microsoft": "Contactez l'assistance pour créer un nouveau service dans {companyName}",
    "connect.copyEmail": "Cliquez pour copier l'adresse e-mail",
    "connect.login": "Se connecter",
    "connect.platformErrorTitle": "Un problème est survenu",
    "connect.interactionRequiredMSErrorTitle": "Microsoft nécessite des autorisations supplémentaires",
    "connect.interactionRequiredMSErrorDescription1": "Votre compte Microsoft semble nécessiter des autorisations supplémentaires. Veuillez cliquer sur le bouton « Se connecter » ci-dessous pour en octroyer.",
    "connect.interactionRequiredMSErrorDescription2": "Si le problème persiste, veuillez contacter l'assistance en leur envoyant le code suivant :",
    "connect.platformErrorDescription1": "L'erreur suivante s'est produite :",
    "connect.platformErrorDescription2": "Il peut s'agir d'une erreur {platform}, mais n'hésitez pas à contacter notre service d'assistance si besoin.",
    "connect.description": "Pour accéder au tableau de bord Vacation Tracker, sélectionner le bouton « Se connecter avec » correspondant à la plateforme utilisée par votre équipe.",
    "connect.readMore": "Pour en savoir plus, consultez notre <helpdesk>Centre d'assistance</helpdesk> ou notre <website>site Web</website>.",
    "connect.copyTextEnd": "Bonnes vacances ! 🌴",
    "connect.signInWithSlack": "Se connecter avec Slack",
    "connect.signInWithMS": "Se connecter avec Microsoft Teams",
    "connect.signInWithGoogle": "Se connecter avec Google Workspace",
    "connect.subscriptionExpiredTitle": "Abonnement expiré",
    "connect.subscriptionExpired": "L'abonnement Vacation Tracker de votre entreprise semble arrivé à expiration ! Veuillez contacter un Administrateur pour réactiver l'abonnement de votre entreprise.",
    "connect.doYouWantToLoginInToOrganization": "Voulez-vous vous connecter à {existingOrganizationName} ?",
    "connect.youAreAlreadyMember": "Vous êtes déjà membre de l'organisation {existingOrganizationName}",
    "connect.thisOrganizationUsePlatform": "Toutefois, {existingOrganizationName} utilise {existsOnAnotherPlatform}. Vous devez donc vous connecter avec {existsOnAnotherPlatform} pour accéder à votre compte",
    "connect.signInWithSlackToOrganization": "Se connecter avec Slack à {existingOrganizationName}",
    "connect.signInWithMSToOrganization": "Se connecter avec Microsoft Teams à {existingOrganizationName}",
    "connect.signInWithGoogleToOrganization": "Se connecter avec Google Workspace à {existingOrganizationName}",
    "connect.signupNewOrganizationUsingNewPlatform": "S'inscrire en tant que nouvelle organisation à l'aide de votre compte {selectedPlatform}",
    "connect.slack.usersLoginRequiredTitle": "Connexion à votre compte Slack requise",
    "connect.slack.usersLoginRequiredDescription1": "Le jeton de votre bot Slack a expiré ou n'est plus valide. Vous devez en activer un nouveau pour pouvoir à nouveau accéder au bot Vacation Tracker ou recevoir des notifications.",
    "connect.slack.usersLoginRequiredDescription2": "Pour activer un nouveau jeton de bot Slack, cliquez sur « OK ».",
    "connect.slack.usersLoginRequiredDescription3": "Pour passer cette étape et accéder au tableau de bord en ligne, cliquez sur « Passer ».",
    "connect.google.notWorkspaceUserTitle": "Compte Google Workspace requis",
    "connect.google.notWorkspaceUserDescription": "Vous semblez essayer de vous inscrire à l'aide d'un compte personnel et non d'un compte Google Workspace. Vacation Tracker est actuellement uniquement compatible avec les comptes Google Workspace.",
    "connect.or": "ou",
    "connect.connectWithYourWorkEmail": "Connectez-vous avec votre adresse e-mail professionnelle",
    "connect.connect": "Se connecter",
    "connect.howShouldIConnect": "Comment dois-je me connecter ?",
    "connect.pleaseInputValidEmail": "Veuillez saisir une adresse e-mail valide",
    "externalConnect.parseError": "Mince 😕 Un problème est survenu.\n\nLa page de connexion s'affichera dans 10 secondes.",
    "externalConnect.pleaseWait": "Veuillez patienter, votre PC fait ce qu'il peut !",
    "externalConnect.faildLogin": "Votre jeton à usage unique a expiré ou a déjà été utilisé. Nous vous redirigerons vers la page de destination dans quelques secondes, mais il se peut que vous deviez vous connecter à nouveau.\n\nSi vous êtes pressé, cliquez sur le bouton ci-dessous.",
    "createCompany.steps.back": "Retour",
    "createCompany.steps.next": "Continuer",
    "createCompany.steps.cancel": "Annuler",
    "createCompany.steps.companyForm": "Informations sur l'entreprise",
    "createCompany.steps.companyForm.email": "Adresse e-mail de contact",
    "createCompany.steps.companyForm.country": "Pays",
    "createCompany.steps.companyForm.state": "État",
    "createCompany.steps.companyForm.contactEmailExtra": "L'adresse e-mail renseignée vous permettra uniquement de recevoir les communications importantes sur le produit : pas de spam, promis !",
    "createCompany.steps.companyForm.contactEmail.validation.required": "Nécessaire pour les communications importantes sur le produit",
    "createCompany.steps.companyForm.contactEmail.validation.type": "Veuillez saisir une adresse e-mail valide.",
    "createCompany.steps.companyForm.contactEmail.validation.country": "Ce champ est obligatoire",
    "createCompany.steps.companyForm.contactEmail.validation.state": "Ce champ est obligatoire",
    "createCompany.steps.companyForm.companyName": "Nom de l'entreprise",
    "createCompany.steps.companyForm.companyNameInput.validation.required": "Ce champ est obligatoire",
    "createCompany.steps.companyForm.location": "Site géographique :",
    "createCompany.steps.companyForm.defaultPtoQuota": "Solde annuel de congés payés",
    "createCompany.steps.companyForm.defaultPtoQuotaExtra": "Vous pourrez le modifier ultérieurement dans les paramètres.",
    "createCompany.steps.companyForm.defaultPtoQuota.validation": "Veuillez saisir votre solde annuel de congés payés par défaut",
    "createCompany.steps.companyForm.defaultPtoQuota.validation.minDefaultPtoQuota": "Votre solde annuel de congés payés ne peut pas être inférieur à 0 jour",
    "createCompany.steps.companyForm.defaultPtoQuota.validation.maxDefaultPtoQuota": "Votre solde annuel de congés payés ne peut pas excéder 366 jours",
    "createCompany.steps.companyForm.unlimitedLeavePolicy": "Congés payés illimités",
    "createCompany.steps.companyForm.companyInfoSection": "Entreprise",
    "createCompany.steps.companyForm.companyLocationSection": "Site",
    "createCompany.steps.companyForm.companyLeaveSection": "Type de congés par défaut",
    "createCompany.steps.companyForm.agreeTerms": "J'accepte la <aPP>Politique de Confidentialité</aPP>, les <aTOS>Conditions de Service</aTOS> et la <aDPA>DPA</aDPA>. Je reconnais également et accepte le traitement de mes données personnelles conformément aux termes énoncés dans la Politique de Confidentialité",
    "createCompany.steps.companyForm.agreeToTerms.error": "Vous devez accepter nos Conditions d'utilisation et notre Politique de confidentialité pour continuer.",
    "createCompany.steps.selectUsers": "Importer des utilisateurs",
    "createCompany.steps.selectUsers.searchUsers": "Recherchez des utilisateurs",
    "createCompany.steps.selectUsers.title": "Sélectionnez les membres de votre équipe",
    "createCompany.steps.selectUsers.title.info": "Sélectionnez les utilisateurs à ajouter au compte Vacation Tracker de votre organisation. Vous pourrez les modifier ultérieurement depuis le tableau de bord. Les utilisateurs sélectionnés ne seront informés qu'à partir de votre annonce.",
    "createCompany.steps.selectUsers.selectAll": "Tout sélectionner",
    "createCompany.steps.selectUsers.deselectAll": "Tout désélectionner",
    "createCompany.steps.selectUsers.loadingAll": "Chargement de tous les utilisateurs…",
    "createCompany.steps.selectUsers.doneButtonTryItOut": "Commencer votre essai gratuit de 7 jours",
    "createCompany.steps.selectUsers.doneButton": "Importer {noOfUsers} utilisateur{postfix}",
    "createCompany.steps.selectPlan.title": "C'est parti !",
    "createCompany.steps.selectPlan.noCC": "Aucune carte de crédit n'est requise pour l'essai de 7 jours",
    "createCompany.steps.selectPlan.youCanSwitchPlanDuringTrial": "Vous pouvez changer d'offre à tout moment au cours de l'essai",
    "createCompany.signupError": "Un problème est survenu",
    "dashboard.openLeavesRequests": "Demandes en attente",
    "dashboard.peopleOffToday": "Congés ce jour",
    "dashboard.upcomingDaysOff": "Congés planifiés ({total})",
    "dashboard.noOpenLeavesRequests": "Il n'y a aucune demande en attente.",
    "dashboard.noOneIsOffToday": "Il n'y a aucun congé prévu aujourd'hui.",
    "dashboard.noOneIsTackingLeaves": "Il n'y a aucun congé planifié actuellement.",
    "components.userLabes.manageLabels": "Gérer les libellés",
    "components.userLabes.noLabelsFound": "Aucun libellé trouvé",
    "components.userLabes.newLabel": "(nouveau libellé)",
    "components.userLabes.addLabel": "Ajouter un libellé",
    "components.userManageLabels.manageLabels": "Gérer les libellés",
    "components.userManageLabels.deleteLabel": "Voulez-vous vraiment supprimer ce libellé ?\n\nIl sera supprimé pour tous les utilisateurs auxquels il a été attribué.",
    "components.userManageLabels.findLabel": "Rechercher un libellé",
    "components.userManageLabels.isRequired": "Veuillez ajouter un {title}",
    "components.userManageLabels.name": "Nom",
    "components.userManageLabels.color": "Couleur",
    "components.userManageLabels.userCount": "Nombre d'utilisateurs",
    "components.userManageLabels.actions": "Actions",
    "component.filterSimple.filterBy": "Filtrer par",
    "component.filterSimple.selectType": "Sélectionner un type",
    "components.billingForm.planTitle": "Offre",
    "component.filterSimple.tooltipInfo": "Si vous ne sélectionnez aucun filtre, l'exportation comprendra tous les utilisateurs.",
    "components.billingForm.title": "Informations de facturation ",
    "components.billingForm.companyName": "Nom de l'entreprise",
    "components.billingForm.coupons": "Bons",
    "components.billingForm.couponCode": "Bon de réduction",
    "components.billingForm.paymentTitle": "Informations de paiement",
    "components.billingForm.paymentCC": "Carte de crédit",
    "components.billingForm.description": "Veuillez renseigner vos informations de paiement dans le formulaire ci-dessus pour régler votre abonnement à l'aide d'une carte de crédit.",
    "components.billingForm.invoiceTitle": "Paiement avec facture transmise par e-mail",
    "components.billingForm.invoiceLabel": "Envoyer les factures par e-mail",
    "components.billingForm.invoiceDescription": "Si la personne chargée de régler l'abonnement n'est pas utilisatrice de Slack ou de Microsoft Teams, nous pouvons lui transmettre une facture par e-mail à payer à l'aide d'une carte de crédit.",
    "components.billingForm.companyNameValidationRequired": "Veuillez renseigner le nom de l'entreprise.",
    "components.billingForm.billingEmails": "Adresse e-mail de facturation",
    "components.billingForm.billingEmailsPlaceholder": "Saisissez une adresse e-mail.",
    "components.billingForm.billingEmailsValidationRequired": "Une adresse e-mail est requise pour la facturation.",
    "components.billingForm.city": "Ville",
    "components.billingForm.cityValidationRequired": "Veuillez renseigner votre ville.",
    "components.billingForm.country": "Pays",
    "components.billingForm.countryValidationRequired": "Veuillez renseigner votre pays.",
    "components.billingForm.state": "État",
    "components.billingForm.stateValidationRequired": "Veuillez renseigner votre État.",
    "components.billingForm.address1": "Adresse (ligne 1)",
    "components.billingForm.address1ValidationRequired": "Veuillez renseigner votre adresse.",
    "components.billingForm.address2": "Adresse (ligne 2)",
    "components.billingForm.ccRequired": "Veuillez renseigner votre numéro de carte.",
    "components.leaveBox.approvedBy": "Approuvé par",
    "components.leaveBox.cancelLeave": "Annuler ces congés",
    "components.leaveBox.cancelLeaveTitle": "Annuler ces congés",
    "components.leaveBox.cancelLeaveConfirm": "Voulez-vous vraiment annuler ces congés ?",
    "components.leaveBox.denyLeaveTitle": "Rejeter une demande de congés",
    "components.leaveBox.denyLeaveConfirm": "Veuillez confirmer que vous souhaitez rejeter cette demande.",
    "components.leaveBox.approveLeaveTitle": "Approuver une demande de congés",
    "components.leaveBox.approveLeaveText": "Veuillez confirmer que vous souhaitez approuver cette demande.",
    "components.leaveBox.leavesUpcomingOneDayInfo": "Sera en congés de type {leaveTypeName} le {leaveStartDate}.",
    "components.leaveBox.leavesUpcomingOneDayWithoutReasonInfo": "Sera en congés le {leaveStartDate}.",
    "components.leaveBox.leavesOffTodayInfo": "Sera en congés de type {leaveTypeName} du {leaveStartDate} au {leaveEndDate} (deuxdates incluses).",
    "components.leaveBox.leavesOffTodayWithoutReasonInfo": "Sera en congés du {leaveStartDate} au {leaveEndDate} (deuxdates incluses).",
    "components.leaveBox.leavesPendingInfo": "Demande de congés de type {leaveTypeName} du {leaveStartDate} au {leaveEndDate} (deuxdates incluses).",
    "components.leaveBox.leavesPendingOneDay": "Demande de congés pour le {leaveStartDate}.",
    "components.leaveBox.leavesBoxHalfDay": "Prend une demi-journée de congés, le {leaveStartDate} de {startHour}{hourFormat24, select, true {:00} other {}} à {endHour}{hourFormat24, select, true {:00} other {}}.",
    "components.leaveBox.leavesBoxHalfDayWithoutReason": "Prend une demi-journée d'absence, le {leaveStartDate} de {startHour}{hourFormat24, select, true {:00} other {}} à {endHour}{hourFormat24, select, true {:00} other {}}.",
    "components.leaveBox.leavesBoxHalfDayPending": "Demande pour une demi-journée de congés {leaveTypeName} le {leaveStartDate}, de {startHour}{hourFormat24, select, true {:00} other {}} à {endHour}{hourFormat24, select, true {:00} other {}}.",
    "components.leaveBox.leavesBoxHalfDayTodayWithoutReason": "En demi-journée d'absence, de {startHour}{hourFormat24, select, true {:00h} other {}} à {endHour}{hourFormat24, select, true {:00h} other {}}.",
    "components.leaveBox.leavesBoxHalfDayToday": "En demi-journée de congés, de {startHour}{hourFormat24, select, true {:00h} other {}} à {endHour}{hourFormat24, select, true {:00h} other {}}.",
    "components.leaveBox.daysOut": "Cela représente {value} {value, plural, =1 {jour} other {journées}} d'absence.",
    "components.leaveBox.deny": "Rejeter",
    "components.leaveBox.reason": "Motif",
    "components.leaveBox.denyWithReason": "Motiver votre rejet",
    "components.leaveBox.approve": "Approuver",
    "components.leaveBox.daysLeft": "Congés restants :",
    "components.leaveBox.autoApproved": "Approuvé automatiquement",
    "components.leaveBox.edit": "Modifier",
    "components.leaveForm.user": "Sélectionner un utilisateur",
    "components.leaveForm.rangeDate": "Dates de début et de fin",
    "components.leaveForm.date": "Date",
    "components.leaveForm.time": "Heure",
    "components.leaveForm.request": "Demande",
    "components.leaveForm.reason": "Motif",
    "components.leaveForm.pleaseSelectUser": "Sélectionner un utilisateur",
    "components.leaveForm.pleaseSelectLeaveType": "Sélectionner un type de congés",
    "components.leaveForm.pleaseSelectstartDate": "Sélectionner une date de début",
    "components.leaveForm.pleaseSelectRangeTime": "Sélectionner une plage horaire",
    "components.leaveForm.pleaseSelectEndDate": "Sélectionner une date de fin",
    "components.leaveForm.pleaseInputReason": "Le motif est obligatoire",
    "components.leaveForm.addLeave": "Ajouter une demande de congés",
    "components.leaveForm.requestLeave": "Poser une demande de congés",
    "components.leaveForm.editLeave": "Modifier une demande de congés",
    "components.leaveForm.addLeaveDescription": "Utilisez ce formulaire pour ajouter une demande de congés pour un autre utilisateur, ou pour en poser une pour vous-même si elle ne nécessite aucune approbation.",
    "components.leaveForm.requestLeaveDescription": "Utiliser ce formulaire si votre demande doit être approuvée par quelqu'un d'autre.",
    "components.leaveForm.editLeaveDescription.user": "Vous pouvez modifier les dates et le motif de cette demande. Si vous souhaitez modifier le type de congés, veuillez annuler cette demande et en recréer une.",
    "components.leaveForm.editLeaveDescription.adminAndApprover": "Vous pouvez modifier les dates de cette demande. Si vous souhaitez modifier le type de congés, veuillez annuler cette demande et en ajouter une nouvelle.",
    "components.locationHolidays.deleteHolidaysTitle": "Supprimer le jour férié",
    "components.locationHolidays.deleteHolidaysConfirm": "Voulez-vous vraiment supprimer le jour férié {name} ?",
    "components.locationHolidays.holidaysForLocation": "Jours fériés pour le site {locationName}",
    "components.locationHolidays.insertHolidays": "Importer des jours fériés",
    "components.locationHolidays.addHolidays": "Ajouter un jour férié",
    "components.locationHolidays.overlap": "Ce jour férié en chevauche un autre",
    "components.addHolidayForm.editHolidays": "Modifier les jours fériés",
    "components.addHolidayForm.holidaysName": "Nom du jour férié",
    "components.insertHolidaysForm.back": "Retour",
    "components.insertHolidaysForm.insertHolidays": "Importer des jours fériés",
    "components.insertHolidaysForm.title": "L'importation de jours fériés remplacera tous les jours fériés ajoutés précédemment.",
    "components.insertHolidaysForm.subtitle": "Cela modifiera le nombre de jours de congés de certains utilisateurs. Ce ou ces jours seront reportés dans leur solde actuel de jours à poser. Voulez-vous vraiment continuer ?",
    "components.insertHolidaysForm.findHolidays": "Rechercher des jours fériés",
    "components.insertHolidaysForm.selectHolidays": "Sélectionner des jours fériés",
    "components.insertHolidaysForm.saveHolidays": "Enregistrer des jours fériés",
    "components.insertHolidaysForm.selectAll": "Tout sélectionner",
    "components.insertHolidaysForm.deselectAll": "Tout désélectionner",
    "components.insertHolidaysForm.pleaseChooseACountry": "Choisir un pays",
    "components.modal.confirm": "Confirmer",
    "components.inviteBotToPrivateChannel.title": "Ajouter un canal privé",
    "components.inviteBotToPrivateChannel.text": "Voulez-vous sélectionner un canal privé ?",
    "components.inviteBotToPrivateChannel.descriptionOne": "Pour ajouter Vacation Tracker à un canal privé, veuillez saisir",
    "components.inviteBotToPrivateChannel.descriptionTwo": "dans le canal, puis actualiser la liste",
    "components.locationForm.selectLocation": "Sélectionner un site",
    "components.locationForm.selectLocationError": "Veuillez sélectionner un site.",
    "components.locationForm.changeLocationWarning": "Sélectionnez le site vers lequel transférer cet utilisateur.",
    "components.locationForm.changeUserLocation": "Modifier le site d'un utilisateur",
    "components.locationForm.overrideUserSettings": "Remplacer les paramètres utilisateur",
    "components.locationForm.overrideUserSettingsInfo": "La configuration des jours de travail s'applique uniquement aux utilisateurs dont le profil ne présente pas de paramètres personnalisés. Pour appliquer ces nouveaux jours de travail à tous les utilisateurs, y compris si leurs jours de travail sont personnalisés, sélectionnez l'option « Remplacer les paramètres utilisateur ».",
    "components.changeTeamForm.changeTeamWarning": "Sélectionner le service vers lequel transférer cet utilisateur.",
    "components.changeTeamForm.selectTeam": "Sélectionner un service",
    "components.changeTeamForm.selectTeamError": "Veuillez sélectionner un service.",
    "components.changeTeamForm.changeUserTeam": "Modifier le service d'un utilisateur",
    "components.teamForm.generalSettings": "Paramètres généraux",
    "components.teamForm.name": "Nom",
    "components.teamForm.pleaseEnterTeamName": "Saisissez le nom du service",
    "components.teamForm.users": "Utilisateurs",
    "components.teamForm.approvers": "Approbateur(s)",
    "components.teamForm.isDefault": "Définir ce service par défaut",
    "components.teamForm.isDefaultExtra": "En définissant ce service par défaut, tous les nouveaux membres de l'équipe y seront automatiquement ajoutés.",
    "components.denyWithReasonForm.denyLeaveRequest": "Rejeter la demande de congés",
    "components.denyWithReasonForm.reason": "Ajouter un motif",
    "components.denyWithReasonForm.reasonIsRequired": "Ce champ est obligatoire.",
    "components.denyWithReasonForm.deny": "Rejeter",
    "components.importUsersForm.importUsersTitle": "Importer des utilisateurs",
    "components.importUsersForm.selectUsers": "Sélectionnez des utilisateurs à importer :",
    "components.importUsersForm.cancel": "Annuler",
    "components.importUsersForm.button.importAllUsers": "Importer tous les utilisateurs",
    "components.importUsersForm.button.importSelectedUsers": "Importer les utilisateurs sélectionnés",
    "components.importUsersForm.consent.line1": "Vous devez avoir l'autorisation de votre administrateur Microsoft Teams pour importer des utilisateurs. Pour obtenir cette autorisation ponctuelle, transmettez le lien suivant à votre administrateur Microsoft Teams :",
    "components.importUsersForm.consent.line2": "Une fois que vous avez obtenu l'autorisation, fermez et rouvrez ce modal, et ce message disparaîtra.",
    "components.importUsersForm.consent.warning": "Veuillez fermer et rouvrir ce modal une fois que vous aurez obtenu l'autorisation pour pouvoir importer tous les utilisateurs.",
    "components.importUsersForm.importAllUsers": "Voulez-vous importer tous les utilisateurs ?",
    "components.importUsersForm.usersIsRequired": "Ce champ est obligatoire.",
    "components.importUsersForm.chooseUsersPlaceholder": "Nom du membre de l'équipe",
    "components.addHolidayForm.addHolidays": "Ajouter un jour férié",
    "components.addHolidayForm.addHolidays.saveHoliday": "Enregistrer",
    "components.addHolidayForm.addHolidays.cancel": "Annuler",
    "components.addHolidayForm.addHolidays.isHolidayMultiday": "Plusieurs jours ?",
    "components.leaveTypeForm.title": "Type de congés",
    "components.leaveTypeForm.save": "Enregistrer",
    "components.leaveTypeForm.cancel": "Annuler",
    "components.leaveTypeForm.name": "Nom",
    "components.leaveTypeForm.nameIsRequired": "Ce champ est obligatoire.",
    "components.leaveTypeForm.maxLength": "Le nom du type de congés est trop long.",
    "components.leaveTypeForm.forbiddenCharacters": "Le nom du type de congés ne peut contenir ni ponctuation, ni caractères spéciaux.",
    "components.leaveTypeForm.color": "Couleur",
    "components.leaveTypeForm.isActive": "Actif",
    "components.leaveTypeForm.setSlackStatus": "Définir le statut Slack",
    "components.leaveTypeForm.setSlackStatusPlaceholder": "Veuillez définir le statut Slack",
    "components.leaveTypeForm.slackStatus": "Statut Slack",
    "components.leavePolicyForm.accruals": "Cumul de congés",
    "components.leavePolicyForm.rolloverType": "Report de congés",
    "components.leavePolicyForm.noLeaveTypes": "Aucun type de congés n'est disponible. Pour en ajouter un nouveau associé à une politique de congés, accédez à l'onglet « Type de congés » pour créer un type de congés, puis ajoutez-le au site souhaité.",
    "components.leavePolicyForm.leavePolicy": "Politique de congés",
    "components.leavePolicyForm.copyLeavePolicyDivider": "Vous pouvez utiliser le modèle d'un autre type de congés ou site.",
    "components.leavePolicyForm.copyLeavePolicy": "Copier une politique de congés existante",
    "components.leavePolicyForm.defaultLeavePolicy": "Ne rien copier et créer une politique de zéro",
    "components.leavePolicyForm.copyLeavePolicyInfo": "Copiez les paramètres de la politique de congés d'un type de congés existant. Dans le menu déroulant, choisissez le type de congés associé à la politique de congés souhaitée pour configurer automatiquement les paramètres. Vous pouvez aussi configurer manuellement les paramètres ci-dessous.",
    "components.leavePolicyForm.selectLeaveType": "Sélectionner un type de congés",
    "components.leavePolicyForm.assignLeavePolicy": "Attribuer une politique de congés",
    "components.leavePolicyForm.addTitle": "Attribuer une politique de congés",
    "components.leavePolicyForm.editTitle": "Modifier la politique de congés {leavePolicyName} pour {locationName}",
    "components.leavePolicyForm.save": "Enregistrer",
    "components.leavePolicyForm.cancel": "Annuler",
    "components.leavePolicyForm.approvalRequired": "Approbation requise",
    "components.leavePolicyForm.hasUnlimitedDays": "Jours illimités",
    "components.leavePolicyForm.negativeBalance": "Autoriser les soldes négatifs",
    "components.leavePolicyForm.negativeBalanceInfo": "Définissez si vous souhaitez autoriser les utilisateurs à consulter le solde de congés qu'ils peuvent poser pour ce type de congés. Laissez cette case décochée pour les empêcher de le consulter.",
    "components.leavePolicyForm.reasonRequired": "Motif requis",
    "components.leavePolicyForm.halfDays": "Autoriser les demi-journées",
    "components.leavePolicyForm.daysPerYear": "Solde annuel de congés",
    "components.leavePolicyForm.required": "Obligatoire",
    "components.leavePolicyForm.hideLeaveType": "Masquer le type de congés",
    "components.leavePolicyForm.hideLeaveTypeInfo": "Choisissez si vous souhaitez masquer ce type de congés du calendrier et des notifications. Seuls les Administrateurs, les Approbateurs et l'utilisateur ayant demandé ce type de congés pourront les consulter sur le calendrier.",
    "components.leavePolicyForm.maxRolloverDays": "Nombre maximal de jours de report",
    "components.leavePolicyForm.maxRolloverDaysInfo": "Sélectionnez le nombre de jours pouvant être reportés sur la prochaine année civile pour ce type de congés.",
    "components.leavePolicyForm.maxRolloverDaysError": "Obligatoire",
    "components.leavePolicyForm.rolloverDaysPerYear": "Nombre de jours maximal",
    "components.leavePolicyForm.rolloverSettings": "Paramètres de report",
    "components.leavePolicyForm.noRollover": "Désactivé",
    "components.leavePolicyForm.limitedNumberOfDays": "Nombre de jours limité",
    "components.leavePolicyForm.allDays": "Tous les jours",
    "components.leavePolicyForm.accrualType": "Cumul de congés",
    "components.leavePolicyForm.accrualTypeInfo": "Selon la politique de votre entreprise, les congés peuvent se cumuler progressivement tout au long de l'année. Si votre entreprise autorise ce cumul, sélectionnez le type « Cumul de congés » défini par vos responsables.",
    "components.leavePolicyForm.accrualTypeError": "Obligatoire",
    "components.leavePolicyForm.accrualBiWeeklySummary": "Les utilisateurs cumulent {amount} ({quota}/26) jours un {day} sur deux",
    "components.leavePolicyForm.accrualMonthlySummary": "Les utilisateurs cumulent {amount} ({quota}/12) jours tous les {date} du mois",
    "components.leavePolicyForm.accrualSemiMonthlySummary": "Les utilisateurs cumulent {amount} ({quota}/24) jours tous les {firstDate} et les {secondDate} du mois",
    "components.leavePolicyForm.isActive": "Actif",
    "components.leavePolicyForm.NONE": "Aucun",
    "components.leavePolicyForm.MONTHLY": "Mensuel",
    "components.leavePolicyForm.SEMI-MONTHLY": "Bimensuel",
    "components.leavePolicyForm.BI-WEEKLY": "Bihebdomadaire",
    "components.leavePolicyForm.currentAccrualPeriod": "Période de cumul actuelle",
    "components.leavePolicyForm.nextAccrualDay": "Prochain cumul délivré le",
    "components.leavePolicyForm.accrualDayBiWeekly": "Cumul délivré le",
    "components.leavePolicyForm.accrualDay": "Jour du cumul",
    "components.leavePolicyForm.accrualDisabledInfo": "Désactivez l'option de jours illimités et définissez le solde sur un nombre supérieur à zéro pour permettre les cumuls.",
    "components.leavePolicyForm.accrualsLockedWarning": "Notez que le cumul de jours ne peut être défini qu'une seule fois.",
    "components.leavePolicyForm.accrualsLockedInfo": "Vous pouvez désactiver le cumul de jours, mais pas le modifier. Veuillez contacter l'assistance si nécessaire.",
    "components.leavePolicyForm.overrideUserSettings": "Remplacer les paramètres utilisateur",
    "components.leavePolicyForm.overrideUserSettingsInfo": "Les paramètres configurés s'appliquent uniquement aux utilisateurs dont le profil ne présente pas de paramètres personnalisés. Pour appliquer ces modifications à tous les utilisateurs, y compris si leurs jours de travail sont personnalisés, sélectionnez l'option « Remplacer les paramètres utilisateur ».",
    "components.leavePolicyForm.back": "Retour",
    "components.leavePolicyForm.saveLeavePolicy": "Enregistrer",
    "components.leavePolicyForm.updateLeavePolicy": "Modifier",
    "components.leavesColumns.dates": "Date(s)",
    "components.leavesColumns.leaveType": "Type de congés",
    "components.leavesColumns.duration": "Durée",
    "components.leavesColumns.reason": "Motif du demandeur",
    "components.leavesColumns.denyReason": "Motif de l'Approbateur",
    "components.leavesColumns.status": "État",
    "components.leavesColumns.approver": "Approbateur",
    "components.leavesColumns.reviewedby": "Examiné par",
    "components.leavesColumns.autoApproved": "Approuvé automatiquement",
    "components.leavesColumns.cancelledBeforeReview": "Annulé avant examen",
    "components.updateWorkweekForm.title": "Modifier les jours de travail",
    "components.updateWorkweekForm.update": "Modifier",
    "components.updateWorkweekForm.cancel": "Annuler",
    "components.updateWorkweekForm.workWeek": "Jours de travail",
    "components.updateWorkweekForm.form.error": "Veuillez sélectionner au moins unjour de travail",
    "components.userTodayOff.leaveInfo": "Informations sur les congés",
    "components.userTodayOff.offTodayPartDay": "L'utilisateur a posé un congé de type {leaveTypeName} aujourd'hui de {from}:00 à {to}:00.",
    "components.userTodayOff.offTodayFullDay": "L'utilisateur a posé un congé de type {leaveTypeName} aujourd'hui, {today}",
    "components.userTodayOff.offFromTo": "L'utilisateur a posé un congé de type {leaveTypeName} du {startDate} au {endDate}",
    "components.userTodayOff.cancelLeave": "Annuler le congé",
    "components.userTodayOff.offTodayReason": "Motif : {reason}",
    "components.userTodayOff.editLeave": "Modifier le congé",
    "components.fakeDoorMailLoginModal.title": "Vous souhaitez en bénéficier en accès anticipé ? 🚀",
    "components.fakeDoorMailLoginModal.info": "Nous essayons actuellement de permettre la connexion à l'aide d'une adresse e-mail. Pour en bénéficier en accès anticipé, inscrivez-vous ci-dessous : nous vous contacterons prochainement.",
    "components.fakeDoorMailLoginModal.discount": "Dans le cadre de cet accès anticipé, vous bénéficiez également d'une remise de 50 % sur Vacation Tracker pour les trois premiers mois.",
    "components.fakeDoorMailLoginModal.yes": "Je veux bénéficier de l'accès anticipé.",
    "components.fakeDoorMailLoginModal.no": "Je vais patienter.",
    "components.userProfileTab.userProfile": "Profil utilisateur",
    "components.userProfileTab.name": "Nom :",
    "components.userProfileTab.team": "Service :",
    "components.userProfileTab.location": "Site :",
    "components.userProfileTab.employeeSince": "Dans l'entreprise depuis :",
    "components.userProfileTab.role": "Rôle :",
    "components.userProfileTab.status": "État :",
    "components.userProfileTab.endDate": "Date de fin de contrat :",
    "components.userProfileTab.approvers": "Approbateurs :",
    "components.userProfileTab.approverSuffix": " (Approbateur)",
    "components.userProfileTab.approver": "Approbateur",
    "components.userProfileTab.user": "Utilisateur",
    "components.userProfileTab.admin": "Administrateur",
    "components.userProfileTab.customWorkWeekTooltip": "Les jours de travail de {userName} ont été modifié par {adminName} le {timestamp}",
    "components.userProfileTab.customWorkWeekEditedBefore": "Les jours de travail de {userName} ont été modifiés dans la précédente version de Vacation Tracker (avant le 1er janvier 2021)",
    "components.userProfileTab.customWorkWeekLabel": "jours de travail personnalisés",
    "components.userProfileTab.workingDays": "Jours de travail",
    "components.userProfileTab.nameEdited": "Le nom a été modifié manuellement et ne sera plus synchronisé automatiquement. Ce nom n'est utilisé que dans Vacation Tracker.",
    "components.createLeaveType.createInProgress": "Création du type de congés {leaveTypeName}…",
    "components.createLeaveType.assignToLocationOneInProgress": "Attribution de la politique de congés au site…",
    "components.createLeaveType.assignToLocationOneInProgressCompleted": "La politique de congés a bien été attribuée au site",
    "components.createLeaveType.assignToLocationManyInProgress": "Attribution de la politique de congés aux sites…",
    "components.createLeaveType.assignToLocationManyInProgressCompleted": "La politique de congés a bien été attribuée aux sites",
    "components.createLeaveTypeForm.title": "Créer un type de congés et attribuer une politique de congés",
    "components.createLeaveTypeForm.justLastStep.assign": "Attribuer",
    "components.createLeaveTypeForm.justLastStep.title": "Attribuer une politique de congés",
    "components.createLeaveTypeForm.Locations": "Sites",
    "components.createLeaveTypeForm.selectLocation": "Sélectionner un site",
    "components.createLeaveTypeForm.locationsInfo": "Attribuez le type de congés <strong>{leaveTypeName}</strong> à tous les sites ou à un site spécifique.",
    "components.createLeaveTypeForm.createLeaveType": "Créer un type de congés",
    "components.createLeaveTypeForm.createAndAssign": "Créer et attribuer",
    "components.createLeaveTypeForm.assignToLocations": "Attribuer à des sites",
    "components.pricePlanCard.pricePerUser": "<price><oldPrice>{pricePerUser} $</oldPrice><newPrice>{newPricePerUser} $</newPrice></price> par utilisateur par mois",
    "components.pricePlanCard.minimumPlanPrice": "<price><oldPrice>{totalPrice} $</oldPrice><newPrice>{newTotalPrice}</newPrice> $</price> (tarif minimum)",
    "components.pricePlanCard.totalPrice": "<price><oldPrice>{totalPrice} $</oldPrice><newPrice>{newTotalPrice} $</newPrice></price> par mois pour {totalUsers} utilisateurs actifs",
    "components.pricePlanCard.forUsers": "si moins de {users} utilisateurs",
    "components.pricePlanCard.totalPricePerUser": "<price><oldPrice>{totalPrice} $</oldPrice><newPrice>{newTotalPrice} $</newPrice></price> par an",
    "components.pricePlanCard.totalPriceForLessThan25Users": "<price><oldPrice>{totalPrice} $</oldPrice><newPrice>{newTotalPrice}$</newPrice></price> par mois si moins de 25 utilisateurs",
    "components.pricePlanCard.customLocationsDepartmentsLeaveTypes": "Nombre de sites, services et types de congés personnalisés illimité",
    "components.pricePlanCard.notifications": "Envoyez des notifications de congés",
    "components.pricePlanCard.calendarIntegrations": "Intégration au calendrier",
    "components.pricePlanCard.exportsAndReports": "Exportations et rapports",
    "components.pricePlanCard.EverythingInTheCorePlan": "Toutes les fonctionnalités de l'offre Core",
    "components.pricePlanCard.accruals": "Cumulez des congés",
    "components.pricePlanCard.labels": "Organisez les Utilisateurs par Libellés",
    "components.pricePlanCard.scheduledReport": "Rapports planifiés",
    "components.pricePlanCard.HRFields": "Champs RH (fonctionnalité bientôt disponible)",
    "components.pricePlanCard.everythingInTheCompletePlan": "Toutes les fonctionnalités de l'offre Complete",
    "components.pricePlanCard.dedicatedSupport": "Service d'assistance dédié",
    "components.pricePlanCard.customOnboardingAndSetup": "Intégration et configuration personnalisées",
    "components.pricePlanCard.APISupport": "Assistance relative à l'API",
    "components.pricePlanCard.SSOIntegration": "Intégration de l'authentification unique",
    "components.pricePlanCard.customContract": "Contrat personnalisé",
    "components.pricePlanCard.currentPlan": "Offre actuelle",
    "components.pricePlanCard.switchPlan": "Passer à <period>un abonnement {newPeriod}</period>",
    "components.pricePlanCard.selectPlan": "Sélectionner une offre",
    "components.pricePlanCard.scheduleAConsultation": "Demander une consultation",
    "components.pricePlanCard.upgradePlan": "Cette offre sera activée immédiatement, et le prix sera calculé au prorata",
    "components.pricePlanCard.duringTheTrial": "Tout changement d'offre pendant la période d'essai sera appliqué immédiatement.",
    "components.pricePlanCard.downgradePlan": "Cette offre repassera à une version inférieure lors du prochain cycle de facturation.",
    "components.pricePlanCard.startFreeTrial": "Commencer votre essai gratuit de 7 jours",
    "components.pricePlanCard.contactUs": "Nous contacter",
    "components.billingInvoice.title": "Factures",
    "components.billingInvoice.description": "Vous pouvez gérer les modes de paiement et les factures depuis le portail client. <link>Cliquez ici pour consulter le portail client</link><creatingLink>Création du lien vers le portail client…</creatingLink>",
    "components.paymentInformation.title": "Informations de paiement ",
    "components.paymentInformation.labelCreditCard": "Carte de crédit",
    "components.paymentInformation.expiryDate": "Date d'expiration",
    "components.billingEmails.title": "Adresses e-mail de facturation",
    "components.billingEmails.emails": "Adresses e-mail",
    "components.billingEmails.emailPlaceholder": "Veuillez renseigner une adresse e-mail.",
    "components.billingEmails.emailsValidationRequired": "Une adresse e-mail est requise pour la facturation.",
    "components.cancelSubscriptionModal.title": "Nous regrettons de vous voir partir…",
    "components.cancelSubscriptionModal.okText": "Résilier",
    "components.cancelSubscriptionModal.cancelText": "Ne pas résilier l'abonnement",
    "components.cancelSubscriptionModal.info": "Pouvez-vous nous indiquer pourquoi vous résiliez votre abonnement Vacation Tracker ?",
    "components.cancelSubscriptionModal.option1": "Nous utilisons désormais un logiciel de ressources humaines",
    "components.cancelSubscriptionModal.option2": "Nous avons trouvé un autre outil de suivi de congés",
    "components.cancelSubscriptionModal.option3": "L'outil ne dispose pas des fonctionnalités nécessaires à mon équipe",
    "components.cancelSubscriptionModal.option4": "Le prix dépasse notre budget",
    "components.cancelSubscriptionModal.option5": "Autre",
    "components.cancelSubscriptionModal.moreInfo": "Qu'aurions-nous pu faire pour continuer à vous compter parmi notre clientèle ?",
    "components.cancelSubscriptionModal.contactInfo": "Pour toute autre remarque, merci de contacter l'assistance à l'adresse {email}",
    "components.cancelTrialSubscriptionModal.title": "Nous regrettons de vous voir partir…",
    "components.cancelTrialSubscriptionModal.okText": "Annuler l'essai",
    "components.cancelTrialSubscriptionModal.cancelText": "Poursuivre l'essai",
    "components.cancelTrialSubscriptionModal.info": "En une phrase, pourquoi voulez-vous annuler votre essai ?",
    "components.billingCouponCode.title": "Bons",
    "components.billingCouponCode.couponCode": "Bon de réduction",
    "components.billingCouponCode.applyCodeAndResubscribe": "Appliquer le bon et se réinscrire",
    "components.billingCouponCode.applyCode": "Appliquer le bon",
    "components.billingInfo.title": "Informations de facturation",
    "components.billingInfo.resubscribe": "Se réinscrire",
    "components.billingInfo.companyName": "Nom de l'entreprise",
    "components.billingInfo.address": "Adresse",
    "components.billingInfo.email": "Adresse e-mail de facturation",
    "components.billingInfo.canceledDescription": "Vous avez résilié votre abonnement Vacation Tracker, qui restera actif jusqu'au {periodEnd}",
    "components.billingInfo.canceledTrialDescription": "Votre période d'essai restera active jusqu'au {periodEnd}. Après cette date, votre abonnement Vacation Tracker sera résilié.",
    "locations.name": "Nom",
    "locations.users": "Utilisateur(s)",
    "locations.hasNoUsers": "Aucun utilisateur",
    "locations.created": "Créez",
    "locations.holidays": "Jours fériés",
    "locations.viewHolidays": "Afficher les jours fériés",
    "locations.leavePolicies": "Politiques de congés",
    "locations.viewLeavePolicies": "Afficher les politiques de congés",
    "locations.editLocation": "Modifier",
    "locations.viewLocation": "Afficher",
    "locations.deleteLocation": "Supprimer le site",
    "locations.deleteLocation.disabled": "Vous ne pouvez pas supprimer le site par défaut",
    "locations.defaultLocation": "Site par défaut",
    "locations.deleteLocationTitle": "Supprimer le site {name}",
    "locations.deleteLocationConfirm": "Voulez-vous vraiment supprimer le site {name} ?",
    "locations.deleteLocationConfirmInitialRollover": "La suppression d'un site peut affecter la configuration et les informations liées aux congés des utilisateurs (report de jours de congés initial, p. ex.)",
    "locations.createNewLocation": "Créer un site",
    "locations.defaultLocationDescription": "Tous les nouveaux utilisateurs seront automatiquement ajoutés à ce site.",
    "location.name": "Nom",
    "location.general": "Général",
    "location.leavePolicies": "Politiques de congés",
    "location.holidays": "Jours fériés",
    "location.startYear": "Début de l'année fiscale",
    "location.startYearInfo": "Pour une organisation, une année fiscale s'entend en matière de taxes et de comptabilité. La période de réinitialisation des congés dépend des dates de début et de fin de l'année fiscale.",
    "location.workWeek": "Jours de travail",
    "location.timezone": "Fuseau horaire",
    "location.timezoneDefault": "(GMT+00:00) GMT (sans heure d'été)",
    "location.users": "Utilisateur(s)",
    "location.hasNoUsers": "Ce site ne comporte aucun utilisateur.",
    "location.rolloverExpiryDay": "Les jours de congés reportés devraient-ils expirer?",
    "location.resetQuotas": "Date de réinitialisation du solde annuel de congés",
    "location.resetQuotasInfo": "Ce paramètre définit si votre solde annuel de congés se réinitialise selon la date de l'exercice comptable (année fiscale de l'entreprise) ou la date de prise de fonction de l'utilisateur. Ce paramètre aura aussi un impact sur votre politique de report de congés.",
    "location.rolloverExpiryAfterDays": "Expiration des jours de congés reportés",
    "location.never": "Jamais",
    "location.editLocation": "Modifier le site",
    "location.holidaysUpdated": "Jours fériés modifiés",
    "location.holidays.updateInProgress": "Modification des jours fériés…",
    "location.holidays.successTitle": "Les jours fériés ont bien été modifiés",
    "location.deleteInProgress": "Suppression du site {locationName}…",
    "location.updateInProgress": "Modification du site {locationName}…",
    "location.createInProgress": "Création du site {locationName}…",
    "location.leavePoliciesNotExistInfo": "Il n'existe pour l'instant aucune politique de congés. Veuillez en attribuer une au site.",
    "locations.pleaseRenameLocation": "Veuillez renommer ce site.",
    "locations.default": "Par défaut",
    "locations.automaticallyGeneratedName": "Nous avons automatiquement généré ce site pour vous en regroupant les équipes suivantes : <t>{team}</t>.",
    "locations.unableToNameThisLocation": "Notre système n'a cependant pas pu nommer ce site correctement.",
    "locations.pleaseRenameLocationName": "Veuillez renommer les sites générés automatiquement*",
    "locations.weAutomaticallyCreatedLocations": "Nous avons créé des sites automatiquement pour votre organisation en regroupant des équipes selon leurs jours fériés. Toutes les politiques de congés ont été transférées, vous n'avez plus qu'à renommer vos sites.",
    "locations.automaticallyTransferredLeavePoliciesAndHolidays": "Les politiques de congés et les jours fériés vous sont automatiquement transférés afin que vous <b>{not}</b> à les recréer.",
    "components.resetQuotas.fiscalYear": "Année fiscale ({date})",
    "components.resetQuotas.userYear": "Date de prise de fonction de l'utilisateur",
    "components.locationForm.name": "Nom",
    "components.locationForm.pleaseEnterLocationName": "Nommez ce site",
    "components.locationForm.workWeek": "Jours de travail",
    "components.locationForm.resetQuotas": "Date de réinitialisation du solde annuel de congés",
    "components.locationForm.resetQuotasInfo": "Ce paramètre définit si votre solde annuel de congés se réinitialise selon la date de l'exercice comptable (année fiscale de l'entreprise) ou la date de prise de fonction de l'utilisateur. Ce paramètre aura aussi un impact sur votre politique de report de congés.",
    "components.locationForm.fiscalYear": "Année fiscale",
    "components.locationForm.userYear": "Date de prise de fonction de l'utilisateur",
    "components.locationForm.fiscalYearStart": "Date de début de l'année fiscale",
    "components.locationForm.rolloverExpiryDay": "Aucune date d'expiration des jours de congés reportés",
    "components.locationForm.rolloverExpiryDayInfo": "Chaque année, les jours de congés reportés arrivent à expiration à la date définie pour les utilisateurs. Le solde de jours pour chaque type de congés est configuré dans la section « Types de congés » du site, et peut être paramétré individuellement pour permettre ou non leur report.",
    "components.locationForm.rolloverExpiryAfterDays": "Aucune date d'expiration des jours de congés reportés",
    "components.locationForm.rolloverExpiryAfterDaysInfo": "Chaque année, les jours de congés reportés des utilisateurs arrivent à expiration un certain nombre de jours après leur date de prise de fonction. Le solde de jours pour chaque type de congés est configuré dans la section « Types de congés » du site, et peut être paramétré individuellement pour permettre ou non leur report.",
    "components.locationForm.days": "Jours",
    "components.locationForm.daysInfo": "Les jours de congés non utilisés reportés sur l'année civile suivante arrivent à expiration au terme du nombre de jours défini suivant la date de prise de fonction de l'utilisateur. Le solde de jours pour chaque type de congés est configuré dans la section « Types de congés » du site, et peut être paramétré individuellement pour permettre ou non leur report.",
    "components.locationForm.daysAfterEmploymentDate": "jours après la date de prise de fonction",
    "components.locationForm.firstDayOfWeek": "Premier jour de travail",
    "components.locationForm.users": "Utilisateurs",
    "components.locationForm.defaultLocation": "Définir ce site par défaut",
    "components.locationForm.defaultLocationInfo": "En définissant ce site par défaut, tous les nouveaux membres d'une équipe y seront automatiquement ajoutés.",
    "components.locationForm.timezoneInfo": "Le fuseau horaire par défaut est utilisé dans tout le système (pour afficher les informations précises liées aux congés dans le calendrier et dans les événements répertoriés dans les journaux, par exemple).",
    "components.locationForm.warningInitialRolloverUsers": "L'ajout ou la suppression d'un utilisateur peut avoir un impact sur sa configuration et ses informations liées aux congés (report de jours de congés initial, par exemple).",
    "components.locationForm.warningInitialRolloverResetQuotas": "Toute modification des paramètres d'un site peut affecter la configuration et les informations liées aux congés des utilisateurs (solde et compte de jours congés, par exemple).",
    "components.locationForm.warningInitialRolloverFiscalYear": "Toute modification des paramètres d'un site peut affecter la configuration et les informations liées aux congés des utilisateurs (solde et compte de jours congés, par exemple).",
    "locationForm.editLocation": "Modifier le site",
    "locationForm.createLocation": "Créer un site",
    "locationForm.updateLocation": "Modifier le site",
    "locationForm.createdSuccessfully": "Un instant, le site est en cours de création.",
    "locationForm.assignLeaveTypesInfo": "Une fois un site créé, attribuez-lui une politique de congés pour permettre aux utilisateurs de poser des demandes de congés.\nPour attribuer une politique de congés, accédez à Site > Politiques de congés > Attribuer une politique de congés.",
    "calendar.calendar": "Calendrier",
    "calendar.halfdayTags": "Les licences et jours fériés de moins d’un jour complet sont représentés par des blocs rayés.",
    "calendar.filterByTeam": "Filtrer par service :",
    "calendar.filterByLocation": "Filtrer par site :",
    "calendar.all": "Tout",
    "calendar.team": "Service(s)",
    "calendar.location": "Site(s)",
    "calendar.labels": "Libellé(s)",
    "calendar.timezone.info": "Heures correspondant à votre fuseau horaire",
    "components.locationLeaveTypesTag.holidays": "Jours fériés",
    "components.locationLeaveTypesTag.hiddenLeaveType": "Type de congés masqué",
    "calendar.getCalendarLink": "Obtenir le lien du calendrier",
    "teams.editTeam": "Modifier le service",
    "teams.viewTeam": "Consulter le service",
    "teams.name": "Nom",
    "teams.users": "Utilisateur(s)",
    "teams.approvers": "Approbateur(s)",
    "teams.created": "Créé le",
    "teams.createNewTeam": "Créer un nouveau service",
    "teams.defaultTeam": "Tous les nouveaux utilisateurs seront automatiquement ajoutés à ce service.",
    "teams.deleteTeamTitle": "Supprimer le service",
    "teams.deleteTeamConfirm": "Voulez-vous vraiment supprimer le service {name} ?",
    "teams.deleteTeam": "Supprimer",
    "teams.deleteInProgress": "Supprimer le service {teamName}",
    "teams.handleDeleteError": "Erreur lors de la suppression",
    "teams.handleDeleteErrorTitle": "Erreur lors de la suppression du service",
    "team.name": "Nom",
    "team.users": "Utilisateur(s)",
    "team.approvers": "Approbateur(s)",
    "team.hasNoUsers": "Ce service ne contient aucun utilisateur.",
    "team.editTeam": "Modifier le service",
    "team.team": "Service",
    "team.updateInProgress": "Modification du service {teamName}…",
    "team.createInProgress": "Création du service {teamName}…",
    "teamForm.editTeam": "Modifier le service",
    "teamForm.createTeam": "Créer un service",
    "teamForm.updateTeam": "Modifier le service",
    "teamForm.name": "Nom",
    "teamForm.pleaseEnterTeamName": "Nommez ce service",
    "teamForm.createTeamError": "Mince 😕 Un problème est survenu. ",
    "leaveTypes.createNewLeaveType": "Créer un type de congés",
    "leaveTypes.deleteLeaveTypeTitle": "Supprimer le type de congés",
    "leaveTypes.deleteLeaveTypeConfirm": "Voulez-vous vraiment supprimer le type de congés {name} ?",
    "leaveTypes.deleteLeaveType": "Supprimer le type de congés",
    "leaveTypes.editLeaveType": "Modifier le type de congés",
    "leaveTypes.deactivateLeaveTypeTitle": "Désactiver le type de congés",
    "leaveTypes.activatingLeaveTypeTitle": "Activer le type de congés",
    "leaveTypes.deactivateLeaveTypeInfo": "En désactivant ce type de congés, vous allez le retirer de tous les sites qui l'utilisent actuellement. Lorsque vous le réactiverez, il s'affichera à nouveau dans ces sites, en conservant ses paramètres et données existants.",
    "leaveTypes.activatingLeaveTypeInfo": "En activant ce type de congés, vous allez restaurer son précédent placement dans les sites, ainsi que ses anciens paramètres et données.",
    "leaveTypes.addLeaveTypeInfo": "Créez un type de congés personnalisé en lui attribuant un nom, une couleur pour le repérer plus facilement, ainsi qu'un état (actif ou inactif). Vous pourrez modifier tous les paramètres de ce type de congés ultérieurement.\n\nPour attribuer une politique de congés personnalisée à un type de congés, consultez Sites > Attribuer une politique de congés.",
    "leaveTypes.updateInProgress": "Modification du type de congés {leaveTypeName}…",
    "leaveTypes.createInProgress": "Création du type de congés {leaveTypeName}…",
    "leaveTypes.deleteInProgress": "Suppression du type de congés {leaveTypeName}…",
    "leaveTypes.sort": "Trier",
    "leaveTypes.isActive": "Actif",
    "leaveTypes.typeName": "Nom",
    "leaveTypes.assignedToLocationsColumn": "Assigné aux Sites",
    "leaveTypes.assignedToLocationsColumnWarning": "Ce type de congés n'est attribué à aucun site.",
    "leaveTypes.color": "Couleur",
    "leaveTypes.addTypeToLocationButton": "Ajouter",
    "components.locationLeavePolicies.status": "État",
    "components.locationLeavePolicies.leaveTypeName": "Nom du type de congés",
    "components.locationLeavePolicies.daysPerYear": "Solde de congés annuel",
    "components.locationLeavePolicies.hasUnlimitedDays": "Jours illimités",
    "components.locationLeavePolicies.isApprovalRequired": "Approbation requise",
    "components.locationLeavePolicies.allowHalfDays": "Autoriser les demi-journées",
    "components.locationLeavePolicies.createNewLeavePolicyInfo": "Pour ajouter un type de congés personnalisé à un site, accédez à « Attribuer une politique de congés ». Sélectionnez le type de congés créé via l'onglet « Types de congés », puis configurez les paramètres de la politique de congés.",
    "components.locationLeavePolicies.createNewLeavePolicy": "Attribuer une politique de congés",
    "components.locationLeavePolicies.deactivateLeaveTypeTitle": "Désactiver le type de congés",
    "components.locationLeavePolicies.deactivateLeaveTypeInfo": "Une fois ce type de congés désactivé, les utilisateurs de ce site ne pourront plus demander ce type de congés spécifique. Une fois réactivé, le type de congés sera restauré et ses anciens paramètres et données seront réactivés.\n\nPour désactiver ce type de congés pour tous les sites, accédez à l'onglet « Types de congés ».",
    "components.locationLeavePolicies.activatingLeaveTypeTitle": "Activer le type de congés",
    "components.locationLeavePolicies.activatingLeaveTypeInfo": "En activant ce type de congés, vous permettez aux utilisateurs de demander ce type de congés spécifique.\n\nSes anciens paramètres et données seront restaurés.",
    "components.locationLeavePolicy.updateInProgress": "Modification de la politique de congés…",
    "components.locationLeavePolicy.successTitle": "La politique de congés est définie",
    "components.locationLeavePolicy.createInProgress": "Création de la politique de congés {name}…",
    "components.userUpdateForm.updateUser": "Modifier l'utilisateur",
    "components.userUpdateForm.update": "Modifier",
    "components.userUpdateForm.cancel": "Annuler",
    "components.userUpdateForm.active": "Actif",
    "components.userUpdateForm.role": "Rôle",
    "components.userUpdateForm.location": "Site",
    "components.userUpdateForm.team": "Service",
    "components.userUpdateForm.employeeSince": "Prise de fonction",
    "components.userUpdateForm.userEndDate": "Fin de contrat",
    "components.userUpdateForm.pleaseSelectATeam": "Sélectionner un service",
    "components.userUpdateForm.pleaseSelectALocation": "Sélectionner un site",
    "components.userUpdateForm.pleaseSelectEmployeeSince": "Ajouter la date de prise de fonction",
    "components.userUpdateForm.pleaseSelectUserEndDate": "Ajouter la date de fin de contrat",
    "components.userUpdateForm.pleaseSelectAUserRole": "Sélectionner le rôle de l'utilisateur",
    "components.userUpdateForm.admin": "Administrateur",
    "components.userUpdateForm.approverSuffix": " (Approbateur)",
    "components.userUpdateForm.approver": "Approbateur",
    "components.userUpdateForm.user": "Utilisateur",
    "components.userUpdateForm.name": "Nom",
    "components.userUpdateForm.warningInitialRolloverForChangeLocation": "La modification du site d'un utilisateur peut affecter sa configuration et ses informations liées aux congés (report de jours de congés initial, par exemple).",
    "components.userUpdateForm.warningInitialRolloverForChangeStartDate": "La modification de la date de prise de fonction d'un utilisateur peut affecter ses informations liées aux congés (report de jours de congés initial, par exemple).",
    "components.userLeaveTypeForm.leaveTypeEditInfo": "Vous modifiez actuellement le type de congés « {leaveTypeName} » pour la période {dateRangeName}.",
    "components.userLeaveTypeForm.updateUserLeaveType": "Mettre à jour le solde pour le type de congé {name}",
    "components.userLeaveTypeForm.applyForFutureYears": "Voulez-vous appliquer cette base de congé pour les autres années ?",
    "components.userLeaveTypeForm.update": "Modifier",
    "components.userLeaveTypeForm.cancel": "Annuler",
    "components.userLeaveTypeForm.daysPerYear": "Quel est votre nouveau solde de congés ?",
    "components.userLeaveTypeForm.required": "Ce champ est obligatoire.",
    "components.userLeaveTypeForm.hasUnlimitedDays": "Souhaitez-vous autoriser les congés illimités ?",
    "components.notificationForm.channel": "Canal",
    "components.notificationForm.channelSlackInfo": "Les notifications peuvent uniquement être envoyées dans les canaux dont vous êtes membre.",
    "components.notificationForm.refreshTheList": "Actualiser la liste",
    "components.notificationForm.timezone": "Fuseau horaire",
    "components.notificationForm.weekly": "Chaque semaine",
    "components.notificationForm.day": "Jour",
    "components.notificationForm.time": "Heure",
    "components.notificationForm.daily": "Chaque jour",
    "components.notificationForm.team": "Équipe Microsoft Teams",
    "components.notificationForm.firstDayOfWeek": "Premier jour de travail",
    "components.setupMsBot.title": "Configurer le bot et les onglets Microsoft Teams",
    "components.setupMsBot.followDirectLink": "1) Cliquez sur <directLink>ce lien</directLink> pour accéder à Vacation Tracker dans le catalogue d'applications Microsoft Teams.",
    "components.setupMsBot.inMSAppPreviewWindows": "2) Dans la fenêtre de prévisualisation de l'application, cliquez sur la flèche à côté du bouton « Ajouter », puis cliquez sur « Ajouter à une équipe ».",
    "components.setupMsBot.setUpInMS": "3) Vous devrez sélectionner l'équipe et le canal où vous souhaitez installer Vacation Tracker. Vous pourrez aussi épingler Vacation Tracker dans un onglet pour pouvoir y accéder plus rapidement et facilement.",
    "components.setupMsBot.stepByStep": "Pour obtenir des instructions détaillées, visionnez cette vidéo ou consultez notre <helpDesk>Centre d'assistance</helpDesk>.",
    "components.selectChannelForm.errorNotInTeams": "Accès refusé. Pour pouvoir utiliser ce canal, vous devez être membre du service.",
    "components.selectChannelForm.errorNotification": "Erreur de notification",
    "components.selectChannelForm.team": "Team",
    "components.selectChannelForm.channel": "Canal",
    "components.selectChannelForm.email": "Destinataires",
    "components.selectChannelForm.addNewEmail": "Ajouter un destinataire",
    "components.selectChannelForm.channelSlackInfo": "Les notifications peuvent uniquement être envoyées dans les canaux dont vous êtes membre.",
    "components.selectChannelForm.notFoundTitle": "Vous n'êtes pas membre de l'équipe Microsoft Teams sélectionnée",
    "components.selectChannelForm.notFound": "Vous n'êtes pas membre de l'équipe Microsoft Teams avec laquelle votre organisation s'est inscrite qui présente l'ID suivant : {msTeamId}.\n\nVous pouvez rejoindre cette équipe depuis votre application Microsoft Teams puis réessayer, ou contacter notre service d'assistance pour modifier votre type d'installation.",
    "components.selectChannelFormSlack.privateChannel": "(canal privé)",
    "components.selectChannelFormSlack.errorNotification": "Erreur de notification",
    "components.selectChannelFormSlack.slackBotIdIsRequred": "Erreur Slack : l'ID du bot Slack est requis ! Veuillez actualiser la page.",
    "components.selectChannelFormSlack.youCanAddTheBotOnlyOnTheChannels": "Vous n'êtes pas membre du canal {channelName}. Pour envoyer la notification dans ce canal, vous devez en être membre.",
    "components.selectChannelFormSlack.toProccedPleaseLogin": "Pour poursuivre, veuillez vous connecter à votre compte Slack",
    "components.selectChannelFormSlack.missingSlackScopes": "Autorisation Slack requise",
    "components.selectChannelFormSlack.slackError": "Erreur Slack",
    "components.selectChannelFormMicrosoft.errorNotInTeams": "Accès refusé. Pour pouvoir utiliser ce canal, vous devez être membre du service.",
    "components.selectChannelFormMicrosoft.notFoundTitle": "Vous n'êtes pas membre de l'équipe Microsoft Teams sélectionnée",
    "components.selectChannelFormMicrosoft.notFound": "Vous n'êtes pas membre de l'équipe Microsoft Teams avec laquelle votre organisation s'est inscrite qui présente l'ID suivant : {msTeamId}.\n\nVous pouvez rejoindre cette équipe depuis votre application Microsoft Teams puis réessayer, ou contacter notre service d'assistance pour modifier votre type d'installation.",
    "components.selectChannelFormMicrosoft.teamPlaceholder": "Veuillez sélectionner une team",
    "components.selectChannelFormMicrosoft.channelPlaceholder": "Veuillez sélectionner un canal",
    "component.notificationDestination.label": "Envoyer à",
    "component.notificationDestination.tooltip": "Notez que vous pouvez envoyer une notification à plusieurs adresses e-mail, mais uniquement vers un canal à la fois",
    "components.notificationSummaryInfo.notificationSummary": "La notification sera envoyée <to>à</to> <channelWrapper>{channelName} </channelWrapper> <and>et à</and> <addressesWrapper>{numberOfEmailAddress} adresse<multiEmailAddress>s</multiEmailAddress> e-mail</addressesWrapper> le {sendingDaysInfo} à {time} h. Elle présentera les informations liées aux congés de <danger>{noOfUsers} utilisateur<multiUsers>s</multiUsers></danger> <link>(afficher la liste d'utilisateur<multiUsers>s</multiUsers>)</link>",
    "components.announcementModal.announceToCompany": "Voulez-vous informer votre équipe que vous utilisez Vacation Tracker ?",
    "components.announcementModal.send": "Envoyer une annonce",
    "components.userLogsTab.filter": "Filtrer :",
    "components.userLogsTab.selectFromTo": "Sélectionner une période :",
    "components.userLogsTab.logsNotFound": "Aucun journal trouvé pour la période sélectionnée",
    "components.userLogsTab.ALL": "Tous les résultats",
    "components.userLogsTab.USER_STATUS_CHANGED": "État modifié",
    "components.userLogsTab.LOCATION_USER_MOVED": "Site modifié",
    "components.userLogsTab.TEAM_USER_MOVED": "Service modifié",
    "components.userLogsTab.USER_UPDATED": "Informations utilisateur modifiées",
    "components.userLogsTab.USER_WORK_WEEK_UPDATED": "Jours de travail modifiés",
    "components.userLogsTab.USER_WORK_WEEK_DELETED": "Jours de travail personnalisés supprimés",
    "components.userLogsTab.ROLLOVER_EXPIRED": "Jours de congés reportés expirés",
    "components.userLogsTab.YEAR_START": "Début d'année",
    "components.userLogsTab.ACCRUED_DAYS": "Jours cumulés",
    "components.userLogsTab.USER_LEAVE_TYPES_UPDATED": "Solde de jours de congés modifié",
    "components.userLogsTab.USER_LEAVE_TYPES_DELETED": "Solde de jours de congés réinitialisé",
    "components.userLogsTab.APPROVER_STATUS": "Rôle modifié",
    "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_UPDATED": "Report de jours de congés initial modifié",
    "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_DELETED": "Report de jours de congés initial supprimé",
    "components.logs.userActivatedBy": "L'utilisateur a été activé par <link>{creatorName}</link>",
    "components.logs.userDeactivatedBy": "L'utilisateur a été désactivé par <link>{creatorName}</link>",
    "components.logs.userDeletedBy": "L'utilisateur a été supprimé par <link>{creatorName}</link>",
    "components.logs.userMovedToLocation": "L'utilisateur a été déplacé vers le site <locationLink>{location}</locationLink> par <link>{creatorName}</link>",
    "components.logs.userMovedToTeam": "L'utilisateur a été déplacé vers le service <teamLink>{team}</teamLink> par <link>{creatorName}</link>",
    "components.logs.userWorkWeek": "<link>{creatorName}</link> a fait passer les jours de travail de l'utilisateur à {workDays}",
    "components.logs.userWorkWeekDefaultLocation": "<link>{creatorName}</link> a restauré les paramètres par défaut du site pour les jours de travail de l'utilisateur",
    "components.logs.userUpdated": "Utilisateur <name></name> <startDate></startDate> <endDate></endDate> <userStatus></userStatus> par <link>{creatorName}</link>",
    "components.logs.userUpdatedName": "Nom modifié à <wrappedName>{name}</wrappedName>",
    "components.logs.userUpdatedEndDate": "date de fin de contrat passée au {endDate}",
    "components.logs.userUpdatedStartDate": "Date de début modifiée à {startDate}",
    "components.logs.userUpdatedChangeStatusToAdmin": "rôle changé en Administrateur",
    "components.logs.userUpdatedChangeStatusToUser": "rôle changé en utilisateur",
    "components.logs.userRolloverExpired": "Les jours de l’année précédente ont expiré: <b>{leaveTypeName}</b> - {value}",
    "components.logs.userRolloverDays": "Jours de congés de l'année précédente reportés ({leaveTypeName}) : {value} jour{s}",
    "components.logs.userLeaveTypeUpdatedQuota": "<link>{creatorName}</link> a modifié le solde de jours de congés de cet utilisateur pour <b>{leaveTypeName}</b> à <quota></quota> jours",
    "components.logs.accrual": "L'utilisateur a cumulé {earned} {earnedDayInGrammaticalNumber} de type {leaveTypeName} (sur un solde de {defaultDaysPerYear} {defaultDayInGrammaticalNumber})",
    "components.logs.nonAccrualLeaveType": "L'utilisateur a reçu {earned} <b>{leaveTypeName}</b> pour {period}",
    "components.logs.userLeaveTypeUnlimitedDays": "jours illimités",
    "components.logs.userLeaveTypeDeleteQuota": "Le solde de congés de type <b>{leaveTypeName}</b> a été réitinialisé à sa valeur par défaut par <link>{creatorName}</link>",
    "components.logs.userApproverStatus": "L'utilisateur a été <status></status> comme Approbateur pour le service <team>{teamName}</team> par <link>{creatorName}</link>",
    "components.logs.initialRolloverUpdated": "<link>{creatorName}</link> a passé le report de jours de congés initial de type {leaveTypeName} à {customRolloverDays}",
    "components.logs.initialRolloverDeleted": "<link>{creatorName}</link> a défini le report de jours de congés initial de type {leaveTypeName} sur zéro",
    "components.logs.deleted": "<Supprimé>",
    "components.userLeaveQuotas.sectionTitle": "Solde de jours de congés",
    "components.userLeaveQuotas.selectPeriod": "Sélectionner une période :",
    "components.userLeaveQuotas.leavePoliciesNotExistInfo": "Il n'existe aucun type de congés disponible pour l'instant. Veuillez attribuer une politique de congés au site auquel appartient cet utilisateur.",
    "components.userLeaveQuotas.leaveTypes": "Types de congés",
    "components.userLeaveQuotas.broughtForward": "Report de jours de congés",
    "components.userLeaveQuotas.initialBroughtForward": "Report de jours de congés initial",
    "components.userLeaveQuotas.editQuotaButton": "Modifier",
    "components.userLeaveQuotas.saveQuotaButton": "Enregistrer",
    "components.userLeaveQuotas.quota": "Solde",
    "components.userLeaveQuotas.accruedDays": "Jours cumulés",
    "components.userLeaveQuotas.expired": "Expiré",
    "components.userLeaveQuotas.used": "Utilisé",
    "components.userLeaveQuotas.remaining": "Restant",
    "components.userLeaveQuotas.accruedInfo1": "{userName} a cumulé {earnedDays} jours ({earnedDaysInHours} heures) sur {defaultDays} jours ({defaultDaysInHours} heures)",
    "components.userLeaveQuotas.accruedInfo2": "{userName} cumulera {toAccrueDays} jours ({toAccrueHours} heures) le {earnDate}",
    "components.userLeaveQuotas.forMoreDetails": "Pour en savoir plus, consultez",
    "components.userLeaveQuotas.logsTab": "l'onglet des journaux.",
    "components.userLeaveQuotas.unlock": "Déverrouiller",
    "components.userLeaveQuotas.unlockBroughtForwardInfo": "Pour déverrouiller cette option, accédez à Paramètres > Sites > {locationName} > Consulter les politiques de congés, puis modifiez la politique {leaveTypeName} pour permettre le report de congés.",
    "components.userLeaveQuotas.initialBroughtForwardInfo": "Le report de jours de congés initial correspond au solde de congés d'un utilisateur reporté depuis l'année précédente, alors que votre organisation n'avait pas encore opté pour Vacation Tracker. Notez que vous pouvez modifier ce solde uniquement, tandis que les jours disponibles à reporter sur les années suivantes seront calculés par Vacation Tracker.",
    "components.userLeaveQuotas.broughtForwardDaysInfo": "<rolloverDays>{maxRolloverDays} jours maximum</rolloverDays><expiresDate>\n\nExpiration le {date}</expiresDate>",
    "components.userLeaveQuotas.expiredDateInfo": "le {date}",
    "components.userLeaveQuotas.overlapDaysContactSupport": "Des dates se chevauchent, veuillez contacter l'assistance pour en savoir plus.",
    "components.userBroughtForwardForm.updateUserLeaveType": "Modifier un report de jours de congés de type {name}",
    "components.userBroughtForwardForm.update": "Modifier",
    "components.userBroughtForwardForm.leaveTypeEditInfo": "Vous modifiez le type de congés « {leaveTypeName} » pour la période {dateRangeName}.",
    "components.userBroughtForwardForm.customRolloverDays": "Jours",
    "components.switchPlanModal.switchCoreMonthlyToCompleteMonthly": "L'offre mensuelle <strong>Complete</strong> sera activée immédiatement, et son prix sera calculé au prorata pour le cycle de facturation en cours. <legacyPlan>Notez que vous ne pourrez pas revenir à votre ancienne offre.</legacyPlan>",
    "components.switchPlanModal.switchFromMonthlyToAnnually": "L'offre annuelle <strong>{newPlan}</strong> sera activée immédiatement. Le prix annuel sera déduit du montant du mois en cours déjà facturé, au prorata. <legacyPlan>Notez que vous ne pourrez pas revenir à votre ancienne offre.</legacyPlan>",
    "components.switchPlanModal.switchFromCoreMonthlyToCompleteAnnual": "L'offre annuelle <strong>Complete</strong> sera activée immédiatement. Le prix annuel sera déduit du montant du mois en cours déjà facturé, au prorata. <legacyPlan>Notez que vous ne pourrez pas revenir à votre ancienne offre.</legacyPlan>",
    "components.switchPlanModal.switchFromCompleteMonthlyToCoreMonthly": "L'offre mensuelle <strong>Core</strong> sera activée au terme du cycle de facturation en cours ({endDatePeriod}). Vous pouvez jusque là continuer à bénéficier des fonctionnalités de l'offre <strong>Complete</strong>.",
    "components.switchPlanModal.switchFromCompleteMonthlyToCoreAnnual": "L'offre annuelle <strong>Core</strong> sera activée au terme du cycle de facturation en cours ({endDatePeriod}). Vous pouvez jusque là continuer à bénéficier des fonctionnalités de l'offre <strong>Complete</strong>.",
    "components.switchPlanModal.switchFromAnnuallyToMonthly": "L'offre mensuelle <strong>{newPlan}</strong> sera activée et facturée au terme du cycle de facturation en cours ({endDatePeriod}). <legacyPlan>Notez que vous ne pourrez pas revenir à votre ancienne offre.</legacyPlan>",
    "components.switchPlanModal.switchFromCoreAnnualToCompleteAnnual": "L'offre annuelle <strong>Complete</strong> sera activée immédiatement. Le cycle de facturation annuel restant sera calculé au prorata, et déduit de votre nouvelle facture. <legacyPlan>Notez que vous ne pourrez pas revenir à votre ancienne offre.</legacyPlan>",
    "components.switchPlanModal.switchFromCompleteAnnualToCoreMonthly": "L'offre <strong>Core</strong> sera activée au terme du cycle de facturation annuel en cours ({endDatePeriod}). Vous pouvez jusque là continuer à bénéficier des fonctionnalités de l'offre <strong>Complete</strong>.",
    "components.switchPlanModal.switchFromCompleteAnnualToCoreAnnual": "L'offre annuelle <strong>Core</strong> sera activée au terme du cycle de facturation en cours ({endDatePeriod}). Vous pouvez jusque là continuer à bénéficier des fonctionnalités de l'offre <strong>Complete</strong>.",
    "components.switchPlanModal.title": "Passer à l'offre <strong>{plan}</strong>",
    "components.switchPlanModal.switch": "Changer d'offre",
    "components.downgradeAlert.cancelChangeToPlan": "Annuler le passage à l'offre <strong>{nextPlan}</strong>",
    "components.downgradeAlert.cancelSwitchPlan": "Voulez-vous annuler le passage prévu à l'offre {nextPeriod} <strong>{nextPlan}</strong>?",
    "components.downgradeAlert.yesIWantToStay": "Oui, je souhaite continuer à utiliser l'offre {currentPeriod} <strong>{currentPlan}</strong>",
    "components.downgradeAlert.noIWantToSwitch": "Non, je veux passer à l'offre {nextPeriod} <strong>{nextPlan}</strong>",
    "components.legacyPlanAlert.warning": "Vous utilisez actuellement votre ancienne offre. Si vous en changez, vous ne pourrez plus la réactiver. Pour en savoir plus, contactez l'<link>assistance</link>.",
    "billing.updateBillingInfo": "Modifier les informations de facturation",
    "billing.cancelSubscription": "Résilier l'abonnement",
    "billing.cancelTrialButton": "Annuler l'essai",
    "billing.cancelTrial": "Votre période d'essai restera active jusqu'au {stripeCurrentPeriodEnd}. Pour continuer à utiliser Vacation Tracker, veuillez saisir vos informations de facturation.",
    "billing.resubscribeCTA": "Se réinscrire",
    "billing.pricingPlansTitle": "Offres",
    "billing.pricingPlanPeriodMonthly": "Mensuelle",
    "billing.pricingPlanPeriodAnnual": "Annuelle",
    "billing.updateInProgress": "Modification des informations de facturation…",
    "billing.cancelUpdateInProgress": "Résiliation…",
    "billing.resubscribingUpdateInProgress": "Réinscription…",
    "billing.applyingCoupon": "Application du bon",
    "billing.applyingCouponAndResubscribing": "Application du bon et réinscription",
    "billing.updatedSuccessfully": "Les informations de facturation ont été modifiées",
    "billing.subscriptionCanceled": "L'abonnement a été résilié",
    "billing.promoCodeErrorDescription": "Le code promotionnel {code} n'est pas valide.",
    "billing.updatedFaild": "La modification des informations de facturation a échoué",
    "general.importUsersAutomatically": "Importer des utilisateurs automatiquement",
    "general.importUsersAutomaticallyInfo": "En activant cette fonctionnalité, tous les nouveaux utilisateurs réguliers de votre espace de travail Slack seront automatiquement importés et activés.",
    "general.importUsersAutomaticallyInfoTooltip": "Ainsi, si vous invitez un nouvel utilisateur dans votre espace de travail, cet utilisateur sera visible dans Vacation Tracker quelques minutes après l'invitation. Vous devez activer manuellement les utilisateurs invités dans plusieurs canaux.",
    "general.enableUserEndDate": "Autoriser les dates de fin de contrat",
    "general.enableUserEndDateInfo": "Une fois activée, cette fonctionnalité empêche les utilisateurs de poser des demandes de congés une fois cette date passée et désactive automatiquement ces utilisateurs.",
    "general.enableUserEndDateTooltip": "Une fois activée, cette fonctionnalité empêche les utilisateurs de poser des demandes de congés une fois cette date passée. Ces utilisateurs seront désactivés et ne pourront plus se connecter à Vacation Tracker.",
    "general.updateInProgress": "Modification de l'entreprise…",
    "general.updateSuccessTitle": "L'entreprise a été modifiée",
    "general.companyName": "Nom de l'entreprise",
    "general.pleaseEnterCompanyName": "Veuillez saisir le nom de l'entreprise",
    "general.announceNewUsers": "Informer les nouveaux utilisateurs",
    "general.announceNewUsersInfoTooltip": "Si cette case est cochée, les nouveaux utilisateurs ajoutés à votre organisation Slack, Teams ou Workspace recevront un court message de Vacation Tracker leur en expliquant le fonctionnement. Si elle est décochée, ces utilisateurs ne recevront aucune annonce de Vacation Tracker.",
    "general.announceNewUsersInfo": "Envoyer une annonce relative à Vacation Tracker aux nouveaux utilisateurs Slack/Teams/Google Workspace",
    "general.usefulLinksTitle": "Liens utiles : ",
    "general.usefulLinks": "<healthcheck>Vérification de l'état</healthcheck>, <helpdesk>Centre d'assistance</helpdesk>",
    "general.googleMorePermission": "Google peut modifier les noms des fois. Si vous voulez vous assurer que vous avez votre nom complet, nous avons besoin de plus d'autorisations pour le mettre à jour. Cliquez simplement ici pour nous donner les autorisations supplémentaires.",
    "general.syncGoogleUsers": "Synchroniser les utilisateurs Google",
    "general.syncGoogleUsersInfo": "Synchronisation des utilisateurs de Google, suppression, mise à jour et ajout automatiques d'utilisateurs.",
    "general.connectSync": "Connecter Sync",
    "general.googleConsentInfo": "Autorisation Google",
    "general.googleConsent": "Connexion Google",
    "notifications.title": "Notifications",
    "notifications.add": "Nouvelle notification",
    "notifications.table.DAILY": "Chaque jour",
    "notifications.table.WEEKLY": "Chaque semaine",
    "notifications.table.active": "Active",
    "notifications.table.name": "Nom",
    "notifications.table.owner": "Propriétaire",
    "notifications.table.frequency": "Fréquence",
    "notifications.table.scheduledTime": "Heure prévue",
    "notifications.table.actions": "Actions",
    "notifications.table.sendNotificationNow": "Envoyer",
    "notifications.deleteNotificationTitle": "Supprimer la notification",
    "notifications.deleteNotificationConfirm": "Voulez-vous vraiment supprimer la notification <strong>{name}</strong> ?",
    "notifications.updateNotification": "Modifier",
    "notifications.notificationSendNow": "Votre notification est partie ! 🚀",
    "notifications.notificationSendNowFailed": "L'envoi a échoué",
    "notifications.notificationSendNowFailedInfo": "Mince 😕 Un problème est survenu. ",
    "notifications.sendNotificationNowCofirmModalTitle": "Envoyer la notification",
    "notifications.sendNotificationNowCofirmModalDesc": "La notification <strong>{name}</strong> n'est pas active. Veuillez confirmer que vous voulez quand même l'envoyer maintenant.",
    "notifications.sendNotificationNowOk": "Envoyer",
    "notifications.deleteNotificationOk": "Supprimer",
    "notifications.handleSubmitErrorTitle": "Erreur de notification",
    "notifications.createWeeklyRequestSubmitted": "Définition des notifications hebdomadaires",
    "notifications.createDailyRequestSubmitted": "Définition des notifications quotidiennes",
    "notifications.deleteWeeklyRequestSubmitted": "Suppression des notifications hebdomadaires",
    "notifications.deleteDailyRequestSubmitted": "Suppression des notifications quotidiennes",
    "notifications.notificationTitle": "Notifications",
    "notifications.cantOpenNoptification": "Cette notification a été créée par un autre utilisateur, vous ne pouvez pas la modifier",
    "notifications.notificationInfo": "Pour configurer des notifications pour un service spécifique, accédez à la page « Services », puis modifiez le service pour lequel vous souhaitez définir des notifications.",
    "notifications.error": "Si le problème persiste, veuillez contacter l'assistance en précisant le code d'erreur suivant : {correlationId}.",
    "notifications.microsoftTokenMissing": "Les notifications ne peuvent pas être définies car il vous manque un jeton Microsoft. Veuillez vous déconnecter, puis vous reconnecter pour le recevoir et réessayer.",
    "notifications.tooltipInfo": "Toutes vos notifications s'affichent sur cette page pour vous simplifier la tâche. Pour en savoir plus, veuillez consulter <helpDesk>cet article du Centre d'assistance</helpDesk>.",
    "notificationsForm.newNotificationTitle": "Nouvelle notification",
    "notificationsForm.updateNotificationTitle": "Modifier la notification",
    "notificationsForm.submit": "Enregistrer la notification",
    "notificationsForm.update": "Modifier la notification",
    "notificationsForm.DAILY": "Chaque jour",
    "notificationsForm.WEEKLY": "Chaque semaine",
    "notificationsForm.currentWeek": "Semaine en cours",
    "notificationsForm.nextWeek": "Semaine prochaine",
    "notificationsForm.TEAM": "Service",
    "notificationsForm.LOCATION": "Site",
    "notificationsForm.NONE": "Aucun filtre",
    "notificationsForm.name": "Nom de la notification",
    "notificationsForm.frequency": "Fréquence",
    "notificationsForm.periodCovered": "Période couverte",
    "notificationsForm.weekStartsOn": "Premier jour de travail",
    "notificationsForm.sendOnDays": "Envoyée le",
    "notificationsForm.sendOnDaysInfo": "La notification sera envoyée le ou les jours sélectionnés.",
    "notificationsForm.time": "Heure",
    "notificationsForm.groupBy": "Regrouper les congés par",
    "notificationsForm.groupBy.tooltip": "Les congés peuvent être affichés selon vos préférences. Ils peuvent être triés par site, par service ou par ordre alphabétique (si aucun filtre n'est sélectionné).",
    "notificationsForm.sendEmpty": "S'il n'y a pas de congés à venir",
    "notificationsForm.sendEmptyYes": "Envoyer la notification",
    "notificationsForm.sendEmptyNo": "Ne pas envoyer la notification",
    "notificationsForm.updateInProgress": "Modification de la notification {notificationName}…",
    "notificationsForm.createInProgress": "Création de la notification {notificationName}…",
    "notificationsForm.deleteInProgress": "Suppression de la notification {notificationName}…",
    "notificationsForm.saveFailed": "L'enregistrement a échoué",
    "notificationsForm.deleteFailed": "La suppression a échoué",
    "notificationsForm.advancedFilter.tooltipInfo": "Si aucun filtre n'est sélectionné, la notification comprendra tous les utilisateurs.",
    "notificationsForm.timezone": "Fuseau horaire",
    "users.viewUser": "Voir l'utilisateur",
    "users.name": "Nom",
    "users.team": "Service",
    "users.location": "Site",
    "users.role": "Rôle",
    "users.startDate": "Prise de fonction",
    "users.endDate": "Fin de contrat",
    "users.changeUserTeam": "Modifier le service",
    "users.changeUserLocation": "Modifier le site",
    "users.activeUser": "Activer",
    "users.deactivateUser": "Désactiver",
    "users.deactivateUserTitle": "Désactiver",
    "users.deactivateUserConfirm": "Voulez-vous vraiment désactiver {name} ?",
    "users.deactivate": "Désactiver",
    "users.deactivateUsers": "Désactiver",
    "users.activateUsers": "Activer",
    "users.activeUsers": "Actifs",
    "users.inactiveUsers": "Inactifs",
    "users.deletedUsers": "Supprimés",
    "users.updateQuota.InProgress": "Modification du solde de congés utilisateur…",
    "users.updateQuota.successTitle": "Le solde de congés utilisateur a bien été modifié",
    "users.updateRolloverQuota.successTitle": "Le report de jours de congés initial a bien été modifié.",
    "users.syncExistingUsers": "Synchronisation des utilisateurs existants",
    "users.syncExistingUsers.InProgress": "Synchronisation des utilisateurs existants…",
    "users.syncExistingUsers.successTitle": "Les utilisateurs existants ont bien été synchronisés.",
    "users.syncWithGoogleConsent": "Synchronisation avec autorisation Google",
    "users.syncWithoutGoogleConsent": "Synchronisation sans autorisation Google",
    "users.cancel": "Annuler",
    "users.importNewUsers": "Ajouter des utilisateurs",
    "users.importUsers": "Importer des utilisateurs",
    "users.importOne.InProgress": "Importation d'un nouvel utilisateur…",
    "users.importMany.InProgress": "Importation d'utilisateurs…",
    "users.importMany.successTitle": "Les utilisateurs ont bien été importés",
    "users.importOne.successTitle": "L'utilisateur a bien été importé",
    "users.import.description": "L'importation peut prendre du temps. Si les nouveaux utilisateurs ne s'affichent pas dans la liste au bout de 10 à 15 secondes, veuillez actualiser la page.",
    "users.prev": "Précédent",
    "users.next": "Suivant",
    "users.aboutToSync": "En synchronisant tous les utilisateurs existants, vous synchronisez leurs données présentes sur la plateforme collaborative utilisée par votre équipe avec leur profil Vacation Tracker (nom, photo de profil, etc.)",
    "users.search": "Rechercher des utilisateurs",
    "user.profile": "Profil",
    "user.leaves": "Congés",
    "user.general": "Général",
    "user.history": "Historique",
    "user.logs": "Journaux",
    "user.addLeave": "Ajouter des congés",
    "user.editUser": "Modifier",
    "user.workingDays": "Jours de travail",
    "user.status": "État",
    "user.location": "Site",
    "user.userWorkWeek": "Jours de travail de l'utilisateur",
    "user.workWeek.updateInProgress": "Modification des jours de travail…",
    "user.workWeek.updateSuccessTitle": "Les jours de travail ont été modifiés",
    "user.upcomingLeaves": "Congés planifiés",
    "user.currentLeaves": "Congés actuels",
    "user.pendingLeaves": "Demandes de congés en attente",
    "user.deny": "Rejeter",
    "user.denyWithReason": "Motiver votre rejet",
    "user.approveInProgress": "Approbation de la demande de congés…",
    "user.approveSuccessTitle": "La demande de congés a été approuvée",
    "user.denyInProgress": "Rejet de la demande de congés…",
    "user.denySuccessTitle": "La demande de congés a été rejetée",
    "user.approve": "Approuver",
    "user.denyLeaveTitle": "Rejeter la demande de congés",
    "user.denyLeaveConfirm": "Voulez-vous vraiment rejeter la demande de congés ?",
    "user.approveLeaveTitle": "Approuver la demande de congés",
    "user.statusReason": "Ajouter un motif",
    "user.approveLeaveText": "Voulez-vous vraiment approuver cette demande de congés ?",
    "user.employeeSince": "Prise de fonction",
    "user.employeeEndDate": "Fin de contrat",
    "user.editLeaveWord": "Modifier",
    "user.editLeave.buttonLabel": "Modifier",
    "user.editLeave": "Modifier les congés",
    "user.role": "Rôle",
    "user.leaveTypes": "Types de congés",
    "user.cancelLeave.buttonLink": "Annuler",
    "user.cancelLeave": "Annuler les congés",
    "user.cancelLeaveConfirmTitle": "Annuler les congés",
    "user.cancelLeaveConfirmText": "Voulez-vous vraiment annuler ces congés planifiés ?",
    "user.cancelLeaveRequestInProgress": "Annulation de la demande de congés…",
    "user.cancelLeaveRequestTitle": "La demande de congés a été annulée",
    "user.dontHaveAnyHistory": "{name} n'a pas encore pris de congés.",
    "user.historyLeaves": "Historique",
    "user.noUpcomingLeaves": "Aucun congé planifié",
    "user.editWorkWeek": "Modifier",
    "user.deleteLeave.buttonLabel": "Supprimer",
    "user.deleteLeave": "Supprimer les congés",
    "user.deleteLeaveConfirmTitle": "Supprimer les congés",
    "user.deleteLeaveConfirmText": "Voulez-vous vraiment supprimer ces congés ?",
    "user.deleteLeaveInProgress": "Suppression des congés…",
    "user.deleteLeaveSuccessTitle": "Les congés ont été supprimés",
    "user.updateInProgress": "Modification de l'utilisateur…",
    "user.updateSuccessTitle": "L'utilisateur a été modifié",
    "user.status.updateInProgress": "Modification de l'état de l'utilisateur…",
    "user.status.updateSuccessTitle": "L'état de l'utilisateur a été modifié.",
    "user.team.moveInProgress": "Déplacement de l'utilisateur vers le service {name}…",
    "user.team.moveSuccessTitle": "L'utilisateur a été déplacé vers le service {name}",
    "user.location.moveInProgress": "Déplacement de l'utilisateur vers le site {name}…",
    "user.location.moveSuccessTitle": "L'utilisateur a été déplacé vers le site {name}",
    "user.bulkChangeStatusInProgress": "Modification de l'état des utilisateurs…",
    "user.bulkChangeStatusSuccessTitle": "L'état des utilisateurs a été modifié.",
    "myProfile.cancelLeave": "Annuler",
    "myProfile.general": "Général",
    "myProfile.leaveTypes": "Types de congés",
    "myProfile.history": "Historique",
    "myProfile.upcomingLeaves": "Congés planifiés",
    "myProfile.noUpcomingLeaves": "Aucun congé planifié",
    "myProfile.userWorkWeek": "Jours de travail",
    "myProfile.historyLeaves": "Historique",
    "myProfile.dontHaveAnyHistory": "{name} n'a pas encore pris de congés.",
    "myProfile.cancel": "Annuler",
    "myProfile.cancelLeaveConfirmTitle": "Annuler les congés",
    "myProfile.cancelLeaveConfirmText": "Voulez-vous vraiment annuler ces congés planifiés ?",
    "myProfile.editLeaveWord": "Modifier",
    "myProfile.editLeave": "Modifier les congés",
    "export.reportType": "Type de rapport",
    "export.leaveHistoryReport": "Rapport de demandes de congés",
    "export.leaveBalanceReport": "Rapport de soldes de congés",
    "export.monthlyLeaveBalanceReport": "Rapport de soldes de congés mensuel",
    "export.leaveHistoryReportDescription": "Historique de toutes les demandes de congés (approuvées, rejetées et expirées) par type de congés, pour chaque utilisateur, sur une période donnée",
    "export.leaveBalanceReportDescription": "Nombre de jours de congés accordés et pris par type de congés, pour chaque utilisateur, sur une période donnée",
    "export.monthlyLeaveBalanceReportDescription": "Nombre de jours de congés pris (ou prévus) par type de congés, pour chaque utilisateur, pendant les 11 mois en cours et les 11 mois suivants",
    "export.locations": "Sites",
    "export.teams": "Services",
    "export.labels": "Libellés",
    "export.export": "Exportation",
    "export.rangeDate": "Dates de début et de fin",
    "export.required": "Ce champ est obligatoire.",
    "export.status": "État",
    "export.approved": "Approuvé",
    "export.denied": "Rejeté",
    "export.expired": "Expiré",
    "export.exportPeriod": "Période couverte par l'exportation",
    "export.currentYear": "Année en cours",
    "export.lastMonth": "Mois dernier",
    "export.nextYear": "Année suivante",
    "export.previousYear": "Année précédente",
    "scheduledReports.name": "Nom",
    "scheduledReports.edit": "Modifier",
    "scheduledReports.teams": "Service(s)",
    "scheduledReports.allTeams": "Tous les services",
    "scheduledReports.locations": "Site(s)",
    "scheduledReports.allLocations": "Tous les sites",
    "scheduledReports.createScheduledReports": "Nouveau rapport",
    "scheduledReports.reportType": "Type de rapport",
    "scheduledReports.period": "Période de référence",
    "scheduledReports.lastMonth": "Mois dernier",
    "scheduledReports.receivers": "Destinataires",
    "scheduledReports.sendReportNow": "Envoyer",
    "scheduledReports.leaveBalanceReport": "Rapport de soldes de congés",
    "scheduledReports.leaveHistoryReport": "Rapport de demandes de congés",
    "scheduledReports.monthlyLeaveBalanceReport": "Rapport de soldes de congés mensuel",
    "scheduledReports.deleteReportTitle": "Confirmer la suppression",
    "scheduledReports.deleteReportConfirm": "Supprimer le rapport {name} ?",
    "scheduledReports.deleteReportOkText": "Supprimer",
    "scheduledReports.deleteInProgress": "Suppression du rapport planifié {scheduledReportName}…",
    "scheduledReports.deleteFailed": "La suppression a échoué",
    "scheduledReports.deliveryDate": "Date d'envoi",
    "scheduledReports.sendReportFailed": "L'envoi a échoué",
    "scheduledReports.sendReportFailedInfo": "Mince 😕 Un problème est survenu. ",
    "scheduledReports.sendReportSuccessfully": "Votre rapport est parti ! 🚀",
    "scheduledReportForm.createAScheduledReport": "Nouveau rapport",
    "scheduledReportForm.updateAScheduledReport": "Modifier le rapport",
    "scheduledReportForm.timezone": "Fuseau horaire",
    "scheduledReportForm.emails": "Destinataires",
    "scheduledReportForm.sendReportSuccessfully": "Votre rapport est parti ! 🚀",
    "scheduledReportForm.sendReportFailed": "L'envoi a échoué",
    "scheduledReportForm.sendReportFailedInfo": "Mince 😕 Un problème est survenu. ",
    "scheduledReportForm.addNewEmail": "Ajouter un destinataire",
    "scheduledReportForm.sendDayOfMonth": "Date ou jour de l'envoi",
    "scheduledReportForm.sendDayOfMonthInfo": "Définissez vos préférences d'envoi des rapports en indiquant le jour exact où ils doivent être transmis aux destinataires sélectionnés.",
    "scheduledReportForm.sendHourAndMinute": "Heure d'envoi",
    "scheduledReportForm.sendHourAndMinuteInfo": "Définissez vos préférences d'envoi des rapports en indiquant l'heure exacte à laquelle ils doivent être transmis aux destinataires sélectionnés.",
    "scheduledReportForm.sendReportNow": "Envoyer",
    "scheduledReportForm.saveReport": "Rapport planifié",
    "scheduledReportForm.updateReport": "Modifier le rapport",
    "scheduledReportForm.deleteReport": "Supprimer le rapport",
    "scheduledReportForm.reportSummary": "Résumé du rapport",
    "scheduledReportForm.reportSummaryInfo": "Période de référence : {from} - {to}\nType de rapport : {reportType}\nProchain rapport envoyé le {dateReport} à {timeReport}",
    "scheduledReportForm.reportSummaryInfoMonthlyBalance": "Période de référence : 12 prochains mois\nType de rapport : {reportType}\nProchain rapport envoyé le {dateReport} à {timeReport}",
    "scheduledReportForm.reportName": "Nom",
    "scheduledReportForm.required": "Ce champ est obligatoire.",
    "scheduledReportForm.teams": "Service(s)",
    "scheduledReportForm.reportType": "Type de rapport",
    "scheduledReportForm.leaveStatus": "État de la demande de congés",
    "scheduledReportForm.period": "Période de référence",
    "scheduledReportForm.periodInfo": "Mois dernier",
    "scheduledReportForm.periodInfoTootltip": "Le rapport est actuellement limité au mois précédent. Pour plus d'options, veuillez envoyer un courriel à hello@vacationtracker.io et nous prendrons note des améliorations possibles pour l'avenir.",
    "scheduledReportForm.saveFailed": "L'enregistrement a échoué",
    "scheduledReportForm.deleteFailed": "La suppression a échoué",
    "scheduledReportForm.locations": "Site(s)",
    "scheduledReportForm.lastMonth": "Mois dernier",
    "scheduledReportForm.approved": "Approuvé",
    "scheduledReportForm.denied": "Rejeté",
    "scheduledReportForm.expired": "Expiré",
    "scheduledReportForm.pleaseInputEmailOrDeleteThisField": "Veuillez saisir une adresse e-mail valide ou supprimer ce champ.",
    "scheduledReportForm.pleaseInputValidEmail": "Veuillez saisir une adresse e-mail valide.",
    "scheduledReportForm.updateInProgress": "Modification du rapport planifié {scheduledReportName}…",
    "scheduledReportForm.createInProgress": "Création du rapport planifié {scheduledReportName}…",
    "scheduledReportForm.deleteInProgress": "Suppression du rapport planifié {scheduledReportName}…",
    "scheduledReportForm.deleteScheduledReportTitle": "Supprimer le rapport planifié {name}",
    "scheduledReportForm.deleteScheduledReportConfirm": "Voulez-vous vraiment supprimer le rapport planifié {name} ?",
    "scheduledReportForm.deleteScheduledReport": "Supprimer le rapport planifié",
    "scheduledReportForm.leaveHistoryReport": "Rapport des demandes de congés",
    "scheduledReportForm.leaveBalanceReport": "Rapport des soldes de congés",
    "scheduledReportForm.monthlyLeaveBalanceReport": "Rapport des soldes de congés mensuels",
    "addLeave.addLeave": "Ajouter des congés",
    "addLeave.noApproverUsers": "Vous ne pourrez pas ajouter de congés pour vous-même sans être membre d'un service pour lequel vous disposez de droits d'Approbateur. Le cas échéant, ouvrez le formulaire « Poser une demande de congés », puis envoyez votre demande.",
    "addLeave.handleSubmitErrorTitle": "Erreur d'envoi de la demande",
    "addLeave.inProgress": "Ajout des congés…",
    "addLeave.title": "Les congés ont été ajoutés",
    "requestLeave.requestLeave": "Poser une demande de congés",
    "requestLeave.handleSubmitErrorTitle": "Erreur d'envoi de la demande",
    "requestLeave.inProgress": "Envoi de la demande…",
    "requestLeave.title": "La demande a été envoyée",
    "editLeave.requestLeave": "Modifier les congés",
    "components.leaveRequestAlertStatus.leaveRequestApprovedByYouInfo": "Vous avez approuvé cette demande. Une notification sera envoyée à {name} pour l'en informer.",
    "components.leaveRequestAlertStatus.leaveRequestHasApprovedByYouInfo": "Cette demande de congés a été approuvée.",
    "components.leaveRequestAlertStatus.leaveRequestApprovedInfo": "La demande de congés a été approuvée par {approverName}.",
    "components.leaveRequestAlertStatus.leaveRequestDeniedByYouInfo": "Vous avez rejeté cette demande. Une notification sera envoyée à {name} pour l'en informer.",
    "components.leaveRequestAlertStatus.leaveRequestHasDeniedByYouInfo": "Cette demande de congés a été rejetée.",
    "components.leaveRequestAlertStatus.leaveRequestDeniedInfo": "La demande de congés a été rejetée par {approverName}.",
    "components.leaveRequestAlertStatus.leaveRequestExpiredInfo": "La demande est expirée.",
    "components.leaveRequestAlertStatus.leaveRequestCancelledInfo": "Cette demande de congés a été annulée.",
    "leaveRequests.wrongLeaveRequestId": "Oups, cette demande de congés est introuvable ! 🔎 Le tableau de bord s'affichera d'ici 7 secondes.",
    "leaveRequests.wrongAction": "Cette action nous est inconnue… 🤔 Veuillez en essayer une autre. Le tableau de bord s'affichera d'ici 7 secondes.",
    "leaveRequests.requestSummary": "Résumé de la demande",
    "leaveRequests.leaveRequestInfoTitle": "<strong>{name}</strong> a posé la demande de congés suivante :",
    "leaveRequests.leaveTypeName": "Type de congés",
    "leaveRequests.dates": "Date{plural}",
    "leaveRequests.daysRequestedForCurrentYear": "Jours demandés cette année",
    "leaveRequests.daysRemainingForCurrentYear": "Jours restants cette année",
    "leaveRequests.daysRequestedForNextYear": "Jours demandés l'année prochaine",
    "leaveRequests.daysRemainingForNextYear": "Jours restants pour l'année prochaine",
    "leaveRequests.reasonForDenying": "Motif du rejet",
    "leaveRequests.denyWithReason": "Motiver votre rejet",
    "leaveRequests.cancelThisAction": "Annuler cette action",
    "leaveRequests.reason": "Motif",
    "editLeave.handleSubmitErrorTitle": "Erreur lors de la modification des congés",
    "editLeave.inProgress": "Modification des congés…",
    "editLeave.title": "Les congés ont été modifiés",
    "editLeave.editLeave": "Modifier les congés",
    "notificationsForm.selectChannel": "Sélectionner un canal",
    "microsoft.notificationsLoginRequiredTitle": "Vous devez vous connecter à votre compte Microsoft",
    "microsoft.notificationsLoginRequiredDescription1": "Vous devez bénéficier d'un jeton Microsoft Teams pour pouvoir gérer les notifications. Cliquez sur le bouton « OK » pour afficher la page de connexion Microsoft. Selon les paramètres de votre navigateur, il se peut que la connexion soit déjà établie : la redirection se fera alors automatiquement.",
    "microsoft.notificationsLoginRequiredDescription2": "Une fois la connexion à votre compte Microsoft établie, cette page s'affichera de nouveau pour vous permettre de gérer les notifications. Cliquez sur le bouton « Annuler » pour revenir à la dernière page visitée du tableau de bord.",
    "microsoft.usersLoginRequiredTitle": "Vous devez vous connecter à votre compte Microsoft",
    "microsoft.usersLoginRequiredDescription1": "Vous devez bénéficier d'un jeton Microsoft Teams pour pouvoir gérer les notifications. Cliquez sur le bouton « OK » pour afficher la page de connexion Microsoft. Selon les paramètres de votre navigateur, il se peut que la connexion soit déjà établie : la redirection se fera alors automatiquement.",
    "microsoft.usersLoginRequiredDescription2": "Une fois la connexion à votre compte Microsoft établie, cette page s'affichera de nouveau pour vous permettre de gérer les utilisateurs.",
    "microsoft.adminConsentErrorTitle": "Autorisation de l'administrateur Microsoft Teams requise",
    "microsoft.adminConsentErrorDescription1": "Vous ne semblez pas être l'administrateur de votre organisation Microsoft Teams. Une autorisation administrateur est requise pour configurer les notifications. Veuillez envoyer le lien suivant à l'administrateur de votre organisation pour obtenir son autorisation :",
    "microsoft.adminConsentErrorDescription2": "Une fois cette autorisation reçue, cliquez sur le bouton ci-dessous pour accéder aux notifications. Si vous êtes un administrateur, consultez ce même lien, puis octroyez votre autorisation. Vous pourrez alors configurer les notifications.",
    "microsoft.createCompanyTeamLoginRequiredTitle": "Autorisation Microsoft Teams supplémentaire requise",
    "microsoft.createCompanyTeamLoginRequiredDescription1": "Nous avons besoin d'une autorisation Microsoft Teams supplémentaire pour répertorier les utilisateurs sélectionnés de votre équipe. Cliquez sur le bouton « OK » pour afficher la page de connexion Microsoft. Selon les paramètres de votre navigateur, il se peut que la connexion soit déjà établie : la redirection se fera alors automatiquement.",
    "microsoft.createCompanyTeamLoginRequiredDescription2": "Une fois la connexion à votre compte Microsoft établie, cette page s'affichera de nouveau pour vous permettre de sélectionner les utilisateurs à importer. En cliquant sur le bouton « Annuler », vous pourrez toujours permettre l'inscription de l'ensemble de votre organisation.",
    "microsoft.createCompanyTeamLoginAdminConsentError1": "Vous ne semblez pas être l'administrateur de votre organisation Microsoft Teams. Une autorisation administrateur est requise pour importer des membres de votre équipe. Veuillez envoyer le lien suivant à l'administrateur de votre organisation pour obtenir son autorisation :",
    "microsoft.createCompanyTeamLoginAdminConsentError2": "Une fois cette autorisation reçue, vous pouvez poursuivre le processus d'inscription. Si vous êtes un administrateur, consultez ce même lien, puis octroyez votre autorisation. Vous pourrez alors poursuivre le processus d'inscription.",
    "microsoft.adminConsentErrorButton": "Obtenir l'accès",
    "slack.notificationsLoginRequiredTitle": "Vous devez vous connecter à votre compte Slack",
    "slack.notificationsLoginRequiredDescription1": "Vous devez bénéficier d'autorisations Slack pour gérer les notifications. Cliquez sur le bouton « OK » pour afficher la page de connexion Slack. Selon les paramètres de votre navigateur, il se peut que la connexion soit déjà établie : la redirection se fera alors automatiquement.",
    "slack.notificationsLoginRequiredDescription2": "Une fois la connexion à votre compte Slack établie, cette page s'affichera de nouveau pour vous permettre de gérer les notifications. Cliquez sur le bouton « Annuler » pour revenir à la dernière page visitée du tableau de bord.",
    "slack.usersLoginRequiredTitle": "Vous devez vous connecter à votre compte Slack",
    "slack.usersLoginRequiredDescription1": "Vous devez bénéficier d'autorisations Slack pour gérer les utilisateurs. Cliquez sur le bouton « OK » pour afficher la page de connexion Slack. Selon les paramètres de votre navigateur, il se peut que la connexion soit déjà établie : la redirection se fera alors automatiquement.",
    "slack.usersLoginRequiredDescription2": "Une fois la connexion à votre compte Slack établie, cette page s'affichera de nouveau pour vous permettre de gérer les utilisateurs.",
    "page404.title": "Page en congés",
    "page404.message": "La page que vous recherchez prend des vacances bien méritées.",
    "error.generic": "Une erreur est survenue.",
    "error.token.description": "Votre jeton {platform} est arrivé à expiration. Veuillez cliquer sur le bouton ci-dessous pour en obtenir un nouveau.",
    "error.token.button": "Se connecter avec {platform}",
    "error.leaveTypeMissing": "Type de congés manquant",
    "error.firstEarningDateMissing": "Date du premier cumul de congés manquante",
    "error.reasonRequired": "Le champ Motif est obligatoire.",
    "error.zeroDaysLeaveRequest": "Vous avez sélectionné un jour férié ou un jour de repos. Veuillez sélectionner une période valide.",
    "error.leaveRequestOverlap": "Les dates choisies se chevauchent avec un congé existant. Veuillez sélectionner une autre période.",
    "error.requestedMoreThanFullDay": "Vous avez demandé plusieurs journées complètes de congés sur un même jour de travail. Veuillez sélectionner un autre jour.",
    "error.leaveRequestStatusNotOpen": "État de la demande de congés : non posée",
    "error.mustBeApproverForTeam": "Vous ne pouvez pas effectuer cette action, car vous devez pour cela être un Approbateur pour ce service.",
    "error.leaveRequestStatusNotApproved": "État de la demande de congés : non approuvée",
    "error.mustBeCreatorOrApprover": "Vous devez être le créateur ou l'Approbateur de la demande pour en modifier l'état.",
    "error.negativeBallanceNotAllowed": "Solde négatif non autorisé pour ce type de congés",
    "error.userStartDateInFuture": "Les dates demandées précèdent la date de prise de fonction de l'utilisateur. Pour en savoir plus, contacter l'Administrateur de votre organisation ou l'assistance Vacation Tracker.",
    "error.userEndDateInFuture": "Les dates demandées suivent la date de fin de contrat de l'utilisateur. Pour en savoir plus, contacter l'Administrateur de votre organisation ou l'assistance Vacation Tracker.",
    "error.leaveRequestNotEditable": "Vous ne pouvez pas modifier cette demande de congés.",
    "error.paymentNotProcessed": "Le paiement a échoué, veuillez réessayer avec une autre carte.",
    "error.deletedTeam": "Le service sélectionné a été supprimé ou n'est plus disponible. Veuillez en sélectionner un ou plusieurs autres auxquels envoyer les notifications.",
    "error.deletedLocation": "Le site sélectionné a été supprimé ou n'est plus disponible. Veuillez en sélectionner un ou plusieurs autres auxquels envoyer les notifications.",
    "error.google.directoryApiDisabledTitle": "Partage des contacts désactivé",
    "error.google.directoryApiDisabledP1": "Vacation Tracker doit accéder aux données de votre répertoire pour importer de nouveaux utilisateurs.",
    "error.google.directoryApiDisabledP2": "Votre administrateur Google Workspace peut permettre l'accès au répertoire en suivant la procédure décrite dans l'article suivant :",
    "error.google.directoryApiDisabledP3": "Vous pouvez vous inscrire sans activer cette fonctionnalité. Vous ne pourrez toutefois pas importer d'utilisateurs depuis Google Workspace.",
    "error.google.directoryApiDisabledP4": "Si vous avez encore besoin d'aide, contactez-nous à l'adresse hello@vacationtracker.io",
    "error.missingSlackBotToken": "Cette action a été traitée, mais nous avons besoin du jeton du bot pour informer l'utilisateur. Veuillez vous déconnecter, puis vous reconnecter pour l'obtenir.",
    "error.missingSlackBotTokenError": "Nous ne pouvons pas traiter cette requête, car nous avons besoin du jeton du bot. Veuillez vous déconnecter et vous reconnecter pour obtenir le jeton du bot, puis réessayez.",
    "not_in_channel": "Le canal sélectionné a été supprimé ou n'est plus disponible. Veuillez en sélectionner un nouveau vers lequel envoyer les notifications.",
    "channel_not_found": "Le canal sélectionné a été supprimé ou n'est plus disponible. Veuillez en sélectionner un nouveau vers lequel envoyer les notifications.",
    "Conversation not found.": "Le canal sélectionné a été supprimé ou n'est plus disponible. Veuillez en sélectionner un nouveau vers lequel envoyer les notifications. ",
    "actionNotifications.location.view": "Voir le site",
    "actionNotifications.location.createdTitle": "Le site {locationName} a été créé",
    "actionNotifications.location.updatedTitle": "Le site {locationName} a été modifié",
    "actionNotifications.location.deletedTitle": "Le site a été supprimé",
    "actionNotifications.team.view": "Voir le service",
    "actionNotifications.team.createdTitle": "Le service {teamName} a été créé",
    "actionNotifications.team.updatedTitle": "Le service {teamName} a été modifié",
    "actionNotifications.team.deletedTitle": "Le service a été supprimé",
    "actionNotifications.leave.requestApproved": "La demande de congés a été approuvée",
    "actionNotifications.leave.requestDenied": "La demande de congés a été rejetée.",
    "actionNotifications.leave.editedRequestDenied": "La modification de demande de congés a été rejetée.",
    "actionNotifications.leave.editedRequestDeniedDescription": "La modification de votre demande de congés a été rejetée, mais la demande d'origine reste active.",
    "actionNotifications.leaveType.updated": "Le type de congés {leaveTypeName} a été modifié",
    "actionNotifications.leaveType.created": "Le type de congés {leaveTypeName} a été créé",
    "actionNotifications.notification.created": "La notification a été créée",
    "actionNotifications.notification.updated": "La notification a été modifiée",
    "actionNotifications.notification.deleted": "La notification a été supprimée",
    "actionNotifications.leaveType.deleted": "Le type de congés a été supprimé",
    "actionNotifications.scheduledReport.createdTitle": "Le rapport planifié {scheduledReportName} a été créé",
    "actionNotifications.scheduledReport.updatedTitle": "Le rapport planifié {scheduledReportName} a été modifié",
    "actionNotifications.scheduledReport.deletedTitle": "Le rapport planifié a été supprimé",
    "actionNotifications.companyAnnouncement.success": "Annonce envoyée",
    "components.leaveRequestAction.approved": "Congés de {requestor} approuvés {actionCreator} ✅",
    "components.leaveRequestAction.editedAutoApproved": "Modification des congés approuvée automatiquement {actionCreator}",
    "components.leaveRequestAction.autoApproved": "Congés de {requestor} approuvés automatiquement",
    "components.leaveRequestAction.editedApproved": "Modification de congés planifiés {actionCreator} ✅",
    "components.leaveRequestAction.edited": "Congés de {requestor} modifiés {actionCreator}",
    "components.leaveRequestAction.cancelled": "Congés de {requestor} annulés {actionCreator}",
    "components.leaveRequestAction.editedDenied": "Modification des congés rejetée {actionCreator}",
    "components.leaveRequestAction.denied": "Congés de {requestor} rejetés {actionCreator} ❌",
    "components.leaveRequestAction.added": "Congés de {requestor} ajoutés {actionCreator}",
    "components.leaveRequestAction.created": "Demande de congés posée {actionCreator}",
    "components.leaveRequestAction.deleted": "Congés supprimés {actionCreator}",
    "components.leaveRequestAction.leaveTypeLabel": "Type de congés : ",
    "components.leaveRequestAction.periodLabel": "Date : ",
    "components.leaveRequestAction.leaveRequested": "Congés demandés : ",
    "components.leaveRequestAction.leaveRemaining": "Congés restants : ",
    "components.leaveRequestAction.approversLabel": "Approbateurs : ",
    "components.leaveRequestAction.partDayLeavePeriod": "{date} - de {partDayStartHour}{hourFormat24, select, true {:00h} other {}} à {partDayEndHour}{hourFormat24, select, true {:00h} other {}}.",
    "components.leaveRequestAction.fullDayLeavePeriod": "{date}{endDate}",
    "quickTour.admin.buttonTitle": "Visite guidée",
    "quickTour.buttons.back": "Retour",
    "quickTour.buttons.close": "Fermer",
    "quickTour.buttons.last": "Fermer",
    "quickTour.buttons.next": "Suivant",
    "quickTour.buttons.skip": "Passer",
    "subscription.title": "Abonnement",
    "subscription.subscribe": "S'abonner",
    "subscription.showPlansButton": "Afficher les offres",
    "subscription.hidePlansButton": "Masquer les offres",
    "subscription.annual.info": "Vous allez activer l'offre {period} {plan} à {price} $",
    "subscription.monthly.info": "Vous allez activer l'offre {period} {plan} à {usersPrice} $ ({noOfActiveUsers} utilisateurs x {dollarValue})",
    "subscription.monthly.infoUnderUserPLanLimit": "Vous allez activer l'offre {period} {plan} à {usersPrice} $",
    "subscription.stripeCustomerPortalLink": "Cliquez ici pour consulter le portail client",
    "subscription.logout": "Se déconnecter",
    "subscription.updateInProgress": "Paiement…",
    "components.heatmap.previousMonth": "Mois précédent",
    "components.heatmap.nextMonth": "Mois suivant",
    "components.heatmap.today": "Date du jour",
    "components.heatmap.nonWorkingDay": "Jour de repos",
    "components.heatmap.holiday": "Jour férié",
    "components.heatmap.holidayDescription": "Le site {location} ne travaille pas, car c'est {holiday}",
    "components.heatmap.showMore": "Voir {amount} de plus",
    "components.heatmap.showLess": "Voir moins",
    "components.heatmap.loading": "Chargement de {day}",
    "botUpdatePermissions.thanks": "Merci ! Tout a bien été configuré. Vous pouvez fermer le tableau de bord.",
    "components.companyAlreadySignup.title": "Votre entreprise utilise déjà Vacation Tracker.",
    "components.companyAlreadySignup.info": "Nous avons remarqué que votre entreprise possède déjà un compte Vacation Tracker. Si vous vouliez vous connecter, il vous suffit de cliquer ici :",
    "components.companyAlreadySignup.connectWith": "Se connecter avec {existsOnAnotherPlatform}",
    "components.companyAlreadySignup.signupWithMail": "Se connecter avec votre adresse e-mail professionnelle",
    "components.signupWithAnotherPlatform.title": "Vous semblez utiliser {platform}",
    "components.signupWithAnotherPlatform.info": "Voulez-vous vous connecter avec votre compte {platform} ? Vous pourrez ainsi importer et synchroniser vos utilisateurs depuis {importUsersPlatform} et gérer les congés directement depuis l'application {platform}. Pour en savoir plus, consultez notre <helpDesk>Centre d'assistance</helpDesk>.",
    "components.signupWithAnotherPlatform.connectWith": "S'inscrire avec {platform}",
    "components.signupWithAnotherPlatform.or": "Ou",
    "components.comparePlatformsModal.title": "Quelle intégration Vacation Tracker est faite pour moi ?",
    "components.comparePlatformsModal.info": "Quelle que soit la plateforme choisie, vous aurez accès à toutes les fonctionnalités principales de Vacation Tracker. Certaines présentent toutefois des avantages que les autres n'ont pas. Consultez le tableau de comparaison ci-dessous.",
    "components.comparePlatformsModal.table.feature": "Fonctionnalités",
    "components.comparePlatformsModal.table.slack": "Slack",
    "components.comparePlatformsModal.table.microsoft": "Microsoft",
    "components.comparePlatformsModal.table.google": "Google",
    "components.comparePlatformsModal.table.email": "Adresse e-mail",
    "components.comparePlatformsModal.table.manageLeaveTypes": "Gérer les types de congés, les jours fériés et des demandes de congés",
    "components.comparePlatformsModal.table.multiTeamLocation": "Configurer plusieurs services et sites présentant des politiques de congés différentes",
    "components.comparePlatformsModal.table.syncToGoogleCalendar": "Synchroniser Vacation Tracker avec Google Agenda",
    "components.comparePlatformsModal.table.syncToOutlookCalendar": "Synchroniser Vacation Tracker avec le calendrier Outlook",
    "components.comparePlatformsModal.table.requestAndManageLeaveFromSlack": "Envoyer et gérer des demandes de congés directement depuis Slack",
    "components.comparePlatformsModal.table.requestAndManageLeaveFromMicrosoftTeams": "Envoyer et gérer des demandes de congés directement depuis Microsoft Teams",
    "components.comparePlatformsModal.table.importUsersFromSlack": "Importer des utilisateurs depuis votre espace de travail Slack",
    "components.comparePlatformsModal.table.importUsersFromMicrosoftTeams": "Importer des utilisateurs depuis Microsoft Active Directory",
    "components.comparePlatformsModal.table.importUsersFromGoogleWorkspace": "Importer des utilisateurs depuis Google Workspace",
    "components.comparePlatformsModal.table.inviteUsersViaEmail": "Inviter des utilisateurs par e-mail",
    "components.comparePlatformsModal.table.comingSoon": "Bientôt disponible",
    "components.comparePlatformsModal.forMoreInfoSeeOurHelpdesk": "Pour en savoir plus, consultez notre <helpDesk>Centre d'assistance</helpDesk>.",
    "components.leavePolicyForm.override.leaveType": "Type de congés : <strong>{name}</strong>",
    "components.leavePolicyForm.override.location": "Site : <strong>{name}</strong>",
    "components.leavePolicyForm.override.change": "Modification : <unlimited>Politique de congés illimités modifiée</unlimited><customDays>Solde de congés modifié</customDays>",
    "components.leavePolicyForm.override.affectedUsers": "Utilisateurs concernés : <strong>{totalUsers}</strong>",
    "components.leavePolicyForm.override.customLeaveQuotaInfo": "Certains utilisateurs concernés (<strong>{totalUsers}</strong>) présentent un solde de congés personnalisé. Souhaitez-vous remplacer ce paramètre ?",
    "components.leavePolicyForm.override.overrideEnable": "Conserver le solde de congés existant",
    "components.leavePolicyForm.override.overrideDisable": "Remplacer les soldes de congés personnalisés",
    "components.leavePolicyForm.override.note": "Remarque : cette modification aura un impact sur tous les utilisateurs ne présentant pas de solde de congé personnalisé, quelle que soit l'option choisie.",
    "app.aboutMe": "Mes informations",
    "app.accruals": "Cumuls de congés",
    "app.actions": "Actions",
    "app.activate": "Activer",
    "app.active": "Actif",
    "app.addLeave": "Ajouter une demande de congés",
    "app.addNewEmail": "Ajouter un destinataire",
    "app.addToGoogleCalendar": "Google Calendar",
    "app.addToMicrosoft365Calendar": "Microsoft 365 Calendar",
    "app.allDay": "Toute la journée",
    "app.allowHalfDays": "Autoriser les demi-journées",
    "app.approvalRequired": "Approbation requise",
    "app.approve": "Approuver",
    "app.approved": "Approuvé",
    "app.approveLeaveText": "Voulez-vous vraiment approuver cette demande de congés ?",
    "app.approveLeaveTitle": "Approuver une demande de congés",
    "app.assignLeavePolicy": "Attribuer une politique de congés",
    "app.autoApproved": "Approuvé automatiquement",
    "app.backToMenu": "Retour au menu",
    "app.bi-weekly": "Bihebdomadaire",
    "app.billing.periodAnnual": "Annuel",
    "app.broughtForward": "Report de congés",
    "app.calendar": "Calendrier",
    "app.cancelLeave": "Annuler ces congés",
    "app.cancelLeaveConfirmText": "Voulez-vous vraiment annuler ces congés planifiés ?",
    "app.color": "Couleur",
    "app.companyNotExist.description1": "Vous avez à présent ajouté le bot et l'onglet Vacation Tracker à votre espace de travail Microsoft Teams.",
    "app.companyNotExist.description2": "Veuillez consulter notre site Web pour finaliser votre inscription et configurer les paramètres de votre organisation.",
    "app.companyNotExist.getStarted": "Finaliser l'inscription",
    "app.companyNotExist": "Oups, vous ne semblez pas avoir finalisé votre inscription à Vacation Tracker ! Veuillez consulter app.vacationtracker.io pour finaliser le processus.",
    "app.completeRegistration": "Finaliser l'enregistrement",
    "app.configuration.moreInfo": "Pour en savoir plus, consultez notre Centre d'assistance : <link>https://vacationtracker.crisp.help/en/</link>",
    "app.configuration.option.overview": "Aperçu",
    "app.configuration.options.info": "Sélectionnez le type d'onglet : ",
    "app.configuration.welcome": "Bienvenue dans Vacation Tracker ! Cet onglet vous permet de poser des demandes de congés facilement directement depuis Microsoft Teams, de gérer vos congés, de consulter leur état, de voir qui est absent ainsi que les prochains congés de votre équipe.",
    "app.consentRequired": "Une autorisation administrateur Microsoft Teams est requise. Vous ne semblez pas être administrateur de votre organisation Microsoft Teams. Pour accéder à l'onglet Vacation Tracker, vous devez bénéficier d'une autorisation administrateur. Veuillez transmettre le lien suivant à l'administrateur de votre organisation pour obtenir son autorisation : {link}",
    "app.contactSupport": "Contactez l'assistance",
    "app.country": "Pays",
    "app.created": "Créé",
    "app.createdSuccessfully": "Demande envoyée",
    "app.creditCard": "Carte de crédit",
    "app.daily": "Chaque jour",
    "app.dateRange": "Dates de début et de fin",
    "app.deleteFailed": "La suppression a échoué",
    "app.deliveryFailed": "L'envoi a échoué",
    "app.denied": "Rejeté",
    "app.deny": "Rejeter",
    "app.denyLeaveRequest": "Rejeter la demande de congés",
    "app.denyLeaveRequestText": "Voulez-vous vraiment rejeter la demande de congés ?",
    "app.denyWithReason": "Motiver votre rejet",
    "app.department": "Service",
    "app.departments": "Services",
    "app.deselectAll": "Tout désélectionner",
    "app.download": "Télécharger",
    "app.edited": "Modifié",
    "app.editLeave": "Modifier le congé",
    "app.email": "Adresse e-mail",
    "app.employeeEndDate": "Fin de contrat",
    "app.expired": "Expiré",
    "app.export": "Exportation",
    "app.general": "Général",
    "app.happyVacationing": "Bonnes vacances 🌴",
    "app.helpdesk": "Centre d'assistance",
    "app.holidays": "Jours fériés",
    "app.labels": "Libellés",
    "app.lastMonth": "Mois dernier",
    "app.leaveBalanceReport": "Rapport de soldes de congés",
    "app.leaveCanceled": "Le congé a été annulé",
    "app.leaveHistoryReport": "Rapport de demandes de congés",
    "app.leaveRequestCreated": "La demande de congés a été créée",
    "app.leaveType": "Type de congés",
    "app.leaveTypes": "Types de congés",
    "app.location": "Site",
    "app.locations": "Sites",
    "app.logInWithMicrosoft": "Se connecter avec Microsoft",
    "app.logout": "Se déconnecter",
    "app.manageLabels": "Gérer les libellés",
    "app.meta.description": "Aimé par plus de 1,000 entreprises dans le monde entier, Vacation Tracker rend incroyablement facile la gestion de vos congés. Disponible pour Slack, Microsoft Teams et Google Workspace.",
    "app.meta.title": "Vacation Tracker, une solution de suivi de congés idéale pour équipes dispersées",
    "app.microsoft.manageLicenses": "Gérer les licences",
    "app.monthly": "Chaque mois",
    "app.monthlyLeaveBalanceReport": "Rapport de soldes de congés mensuel",
    "app.myProfile": "Mon profil",
    "app.myStatus": "Mon état",
    "app.nextMonth": "Mois prochain",
    "app.none": "Aucun",
    "app.notifications": "Notifications",
    "app.openCalendar": "Ouvrir le calendrier",
    "app.openDashboard": "Ouvrir le tableau de bord",
    "app.openWebDashboard": "Ouvrir le tableau de bord Web",
    "app.pendingLeaveRequests": "Demandes de congés en attente",
    "app.previous": "Précédent",
    "app.proceed": "Continuer",
    "app.recipients": "Destinataires",
    "app.recommended": "recommandé",
    "app.reportType": "Type de rapport",
    "app.requestLeave": "Poser une demande de congés",
    "app.required": "Obligatoire",
    "app.role.administrator": "Administrateur",
    "app.role.approver": "Approbateur",
    "app.role.approvers": "Approbateurs",
    "app.scheduledLeaves": "Congés planifiés",
    "app.scheduledReports": "Rapports planifiés",
    "app.seeMoreOptions": "Voir plus d'options",
    "app.select": "Sélectionner",
    "app.selectAll": "Tout sélectionner",
    "app.semi-monthly": "Bimensuel",
    "app.sendNow": "Envoyer",
    "app.showLess": "Voir moins",
    "app.showMore": "Voir plus",
    "app.skip": "Passer",
    "app.startFreeTrial": "Commencer votre essai gratuit de 7 jours",
    "app.state": "État",
    "app.status": "État",
    "app.submit": "Envoyer",
    "app.switch": "Changer d'offre",
    "app.takeQuickTour": "Visite guidée",
    "app.thisMonth": "Ce mois",
    "app.thisWeek": "Cette semaine",
    "app.time": "Heure",
    "app.timezone": "Fuseau horaire",
    "app.today": "Ce jour",
    "app.tryAgain": "Réessayer",
    "app.user": "Utilisateur",
    "app.users.role": "Rôle",
    "app.users": "Utilisateurs",
    "app.viewAll": "Voir tout",
    "app.visitHelpdesk": "Consulter le Centre d'assistance",
    "app.was": "était",
    "app.watchVideo": "Regarder la vidéo",
    "app.weekly": "Chaque semaine",
    "app.willBe": "sera",
    "app.workweek": "Jours de travail",
    "billing.microsoft.billedLabel": "Facturé",
    "billing.microsoft.billingCycle": "Le cycle de facturation en cours se termine le {date}",
    "billing.microsoft.billingCycleEnds": "Votre abonnement sera résilié au terme du cycle de facturation. Vous ne pourrez alors plus utiliser Vacation Tracker.",
    "billing.microsoft.billingCycleLabel": "Cycle de facturation",
    "billing.microsoft.buyLessLicensesConfirmModalContent": "Vous avez {currentNoOfLicenses} licences. Vous vous apprêtez à en supprimer {additionalLicences}. Voulez-vous continuer ?",
    "billing.microsoft.buyMoreLicensesConfirmModalContent": "Vous avez {currentNoOfLicenses} licences. Vous vous apprêtez à en acheter {additionalLicences} autres. Voulez-vous continuer ?",
    "billing.microsoft.canceledSubscriptionMessage": "Votre abonnement sera résilié dans 15 minutes environ.",
    "billing.microsoft.changePlanModalTitle": "Modifier votre offre <strong>{plan}</strong> actuelle",
    "billing.microsoft.info": "Vos informations de paiement sont gérées par Microsoft. Consultez votre <adminCenterLink>Centre d'administration</adminCenterLink> pour modifier vos {labelInfo}",
    "billing.microsoft.licensesSideActionLabel": "Consulter l'historique",
    "billing.microsoft.licensesTitle": "Licences",
    "billing.microsoft.pricingPlanLabel": "Offre",
    "billing.microsoft.switchPlanModal.confirm": "Voulez-vous vraiment changer d'offre ?",
    "billing.paymentInformation": "Informations de paiement",
    "billing.subscriptionExpired": "L'abonnement n'est pas actif",
    "billing.subscriptionExpiredDescription": "Votre abonnement est résilié ou arrivé à expiration. Vous ne pouvez pas le modifier actuellement.",
    "billing.subscriptionUpdateInProgress": "Modification de l'abonnement…",
    "billing.subscriptionUpdateInProgressDescription": "La précédente opération est toujours en cours. Veuillez réessayer d'ici quelques instants.",
    "bot.announce.slack.info": "Bonjour <@{slackUserId}>, je suis Vacation Tracker.",
    "bot.announce.slack": "Bonjour <@{slackUserId}>,\n\nJe suis Vacation Tracker. Je vais vous aider à planifier vos congés depuis Slack et à informer l'ensemble de votre organisation des congés à venir.\n\nSaisissez « /vacation » dans n'importe quel canal ou message direct Slack pour vous lancer, ou découvrez le tutoriel YouTube pour en savoir plus :\nhttps://youtu.be/9e6J1AmfdwM",
    "bot.announce.slackCompany": "Bonjour à toute l'équipe,\n\nJe suis le bot Vacation Tracker !\n\nJe vous permets de poser des congés depuis Slack, et je vous informe des congés planifiés des membres de votre équipe. Commençons le suivi des congés !\n\nSaisissez « /vacation » dans n'importe quel canal ou message direct Slack ou découvrez le tutoriel YouTube pour en savoir plus :\nhttps://youtu.be/9e6J1AmfdwM",
    "bot.message.about.commandDescription": "Vacation Tracker simplifie la pose de demandes de congés pour votre organisation. Les membres d'équipes peuvent demander n'importe quel type de congés, qu'ils soient payés ou non, directement depuis Slack, sans avoir à retenir d'autres identifiants.\n\nDéfinissez des types de congés personnalisés et permettez à chaque membre de votre organisation de contrôler ses absences. Avec Vacation Tracker, chaque membre d'équipe peut accéder au tableau de bord (http://app.vacationtracker.io) qui permet de poser des demandes de congés, de consulter l'état actuel ou l'historique de leurs congés, et de voir les congés planifiés de l'ensemble de l'équipe.\n\nSelon les paramètres définis par les Administrateurs ou les Approbateurs, les membres d'équipes recevront chaque jour ou chaque semaine des notifications les informant des absences à venir. Un système simple qui vous évite de mauvaises surprises !\n\nVous voulez en savoir plus sur Vacation Tracker ? Notre <https://vacationtracker.crisp.help/en/|Centre d'assistance> ainsi qu'une <https://vacationtracker.io/request-a-demo/|démonstration> peuvent vous aider.\n\nSi vous avez des questions ou des avis à nous transmettre, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle : hello@vacationtracker.io",
    "bot.message.accessExpired": "Mince ! Votre accès à Vacation Tracker est arrivé à expiration. Veuillez contacter votre Administrateur pour en savoir plus.",
    "bot.message.approveLeaves.approvedRequest": "✅ Vous avez approuvé cette demande. Une notification sera envoyée à <@{userSlackId}> pour l'en informer.",
    "bot.message.approveLeaves.deniedRequest": "❌ Vous avez rejeté cette demande. Une notification sera envoyée à <@{userSlackId}> pour l'en informer.",
    "bot.message.backToOptions": "Retour aux options",
    "bot.message.couldNotProcessRequest": "Oups, nous n'avons pas pu traiter votre demande.",
    "bot.message.date": "*Date*",
    "bot.message.dateEdited": "*Date (modifiée)*",
    "bot.message.dates": "*Dates*",
    "bot.message.datesEdited": "*Dates (modifiées)*",
    "bot.message.deniedLeaveDate": "*Date*\n{leaveDate}",
    "bot.message.deniedLeaveDates": "*Dates*\n{leaveDate}",
    "bot.message.deniedLeaveLeaveType": "*Type de congés :*\n{leaveTypeName}",
    "bot.message.deniedLeaveWithReason": "Bonjour, <@{userSlackId}> a envoyé la demande de congés suivante :",
    "bot.message.error.endDateCantBeInPast": "La date de fin ne peut pas précéder celle de début. Veuillez choisir une date ultérieure.",
    "bot.message.error.leaveRequestOverlap": "Les dates sélectionnées chevauchent celles d'un congé existant*.\n* Veuillez sélectionner une autre période.",
    "bot.message.error.negativeBallanceNotAllowed": "Vous ne pouvez pas avoir de solde négatif pour ce type de congés.",
    "bot.message.error.reasonRequired": "Vous devez renseigner un motif.",
    "bot.message.error.requestedMoreThanFullDay": "Vous avez demandé plusieurs journées complètes de congés sur un même jour de travail. Veuillez sélectionner un autre jour.",
    "bot.message.error.startDateCantBeInPast": "La date de début ne peut pas être passée. Veuillez sélectionner une date ultérieure",
    "bot.message.error.zeroDaysLeaveRequest": "Vous avez sélectionné un jour férié ou de repos*.\n* Veuillez sélectionner une plage de dates valide.",
    "bot.message.feedback.chooseAnOption": "Choisissez une option…",
    "bot.message.feedback.form": "Retour d'information",
    "bot.message.feedback.howDoYouLikeOurApp": "À quel point appréciez-vous notre application ?",
    "bot.message.feedback.info": "Merci de vos retours :heart:",
    "bot.message.feedback.itMakesMeCry": "Je ne l'aime vraiment pas 😢",
    "bot.message.feedback.itsOk": "Elle est correcte 😐",
    "bot.message.feedback.likeIt": "Je l'aime bien 😀",
    "bot.message.feedback.loveIt": "Je l'adore 😍",
    "bot.message.feedback.message": "Commentaire",
    "bot.message.feedback.nah": "Je ne l'aime pas 🙁",
    "bot.message.feedback.response": "Si votre commentaire nécessite une réponse, veuillez saisir votre adresse e-mail pour que nous puissions vous contacter.",
    "bot.message.feedback.thanks": "Merci pour votre avis ! Nous pouvez continuer à nous joindre à l'adresse support@vacationtracker.io.",
    "bot.message.feedback.youEmailAddress": "Votre adresse e-mail",
    "bot.message.genericError": "Mince, une erreur inattendue s'est produite. 😱 Si le problème persiste, veuillez contacter l'assistance en précisant le code d'erreur suivant : {requestId}.",
    "bot.message.goToTheMyProfileTab": "Accéder à l'onglet « Mon profil »",
    "bot.message.helpMessage": "Bonjour, \nAvec Vacation Tracker, vous pouvez effectuer l'une des actions suivantes:\n- Envoyer **Request leave** dans un message direct pour demander un nouveau congé en utilisant le bot de Microsoft Teams.\n- Envoyer **Request toil** dans un message direct pour demander un nouveau congé compensatoire en utilisant le bot de Microsoft Teams.\n- Visitez l'onglet **Mon profil** pour consulter vos informations personnelles, telles que vos congés et congés à venir, ainsi que l'historique de vos congés.\n- Épinglez l'onglet **Vue d'ensemble** sur l'un de vos canaux pour voir la vue d'ensemble de l'équipe, y compris les congés et congés à venir pour votre équipe.\n- Visitez le [**Tableau de bord Web de Vacation Tracker ⧉**]({dashboardLink}/connect?platform=microsoft&redirect=/app/dashboard).\nPour plus d'aide et de tutoriels, visitez notre [**Helpdesk ⧉**](https://vacationtracker.crisp.help/en/). Vous pouvez également céduler une démo personnalisée de Vacation Tracker en cliquant sur ce lien : [https://vacationtracker.io/request-a-demo](https://vacationtracker.io/request-a-demo).",
    "bot.message.holidays": "*Jours fériés :*",
    "bot.message.leaveDuration1": "*{word} demandés pour l'année en cours:*\n{requestedThisYearNumber}",
    "bot.message.leaveDuration2": "*{word} restants pour l'année en cours:*\n{currentYearNumber}",
    "bot.message.leaveDuration2Unlimited": "*{word} restants pour l'année en cours:*\nIllimités",
    "bot.message.leaveDuration3": "*{word} demandés pour l'année prochaine:*\n{requestedNextYearNumber}",
    "bot.message.leaveDuration4": "*{word} restants pour l'année prochaine:*\n{nextYearNumber}",
    "bot.message.leaveDuration4Unlimited": "*{word} restants pour l'année prochaine:*\nIllimités",
    "bot.message.leaveRequestCreated.error": "La demande de congés ne peut pas être envoyée.",
    "bot.message.leaveRequestCreated.errorDateAfter": "❌ La demande de congés ne peut pas être envoyée car la date de début sélectionnée est ultérieure à la date de fin. Assurez-vous que la date de début précède la date de fin, puis renvoyez la demande.",
    "bot.message.leaveRequestCreated.errorDateInThePast": "❌ La demande de congés ne peut pas être envoyée car la date de début sélectionnée est passée. Assurez-vous que les dates sélectionnées ne sont pas déjà passées, puis renvoyez la demande.",
    "bot.message.leaveRequestCreated.errorDateNotSelected": "❌ La demande de congés ne peut pas être envoyée car vous n'avez pas sélectionné de date de fin, ou car cette date n'est pas valide.",
    "bot.message.leaveRequestCreated.errorNoStartDate": "❌ La demande de congés ne peut pas être envoyée car vous n'avez pas sélectionné de date de début, ou car cette date n'est pas valide.",
    "bot.message.leaveRequestCreated.errorNoStartTime": "❌ La demande de congés ne peut pas être envoyée car vous n'avez pas sélectionné d'heure de début, ou car cette heure n'est pas valide.",
    "bot.message.leaveRequestCreated.errorReasonRequired": "❌ La demande de congés ne peut pas être envoyée car vous devez renseigner un motif.",
    "bot.message.leaveRequestNext.endDate": "Date de fin :",
    "bot.message.leaveRequestNext.info": "Super ! 🐨 Sélectionnez la plage horaire souhaitée, puis envoyez votre demande.",
    "bot.message.leaveRequestNext.isHalfDay": "{leaveTypeName} (demi-journée)",
    "bot.message.leaveRequestNext.leaveTime": "Heure des congés :",
    "bot.message.leaveRequestNext.reasonOptional": "Motif (facultatif)",
    "bot.message.leaveRequestNext.reasonRequired": "Motif (obligatoire)",
    "bot.message.leaveRequestNext.startDate": "Date de début :",
    "bot.message.leaveRequestNext.title": "Poser une demande de congés",
    "bot.message.leaves": "*Congés :*",
    "bot.message.leavesHistory.leaveMessageFullDay": "- Congés (*{leaveTypeName}*) du {start} au {end} *[{leaveStatus}]*\n",
    "bot.message.leavesHistory.leaveMessageOn": "- Congés (*{leaveTypeName}*) le {start} *[{leaveStatus}]*\n",
    "bot.message.leavesHistory.leaveMessageOnPartDay": "- Congés (*{leaveTypeName}*) le {start} de {partDayStartHour} à {partDayEndHour} *[{leaveStatus}]*\n",
    "bot.message.leavesHistory": "Votre historique de congés :",
    "bot.message.leavesHistoryNot": "Il semble que vous n'ayez actuellement ajouté aucun congé.",
    "bot.message.manageLeaves.cancelRequest": "Annuler la demande",
    "bot.message.manageLeaves.confirmCancellation": "Voulez-vous vraiment annuler votre demande de congés ?",
    "bot.message.manageLeaves.summary": "Voici un récapitulatif de vos congés à venir et demandes en attente.",
    "bot.message.manageLeaves.summaryNoLeaves": "Vous n'avez aucun congé planifié à venir.",
    "bot.message.manageLeaves.upcomingLeaves": "*Vous avez {upcomingLeaves} demande confirmée :*",
    "bot.message.manageLeaves.upcomingLeavesPlural": "*Vous avez {upcomingLeaves} demandes confirmées :*",
    "bot.message.manageLeaves.waitingLeaves": "*Vous avez {waitingLeaves} demande en attente :*",
    "bot.message.manageLeaves.waitingLeavesPlural": "*Vous avez {waitingLeaves} demandes en attente :*",
    "bot.message.membersAway.holiday": "- Le site *{holidayLocationName}* sera absent le {date} (*{holidayName}*).\n",
    "bot.message.membersAway.leaveMessage.fullDay": "- *{user}* est en congés {leaveTypeName} du {startDate} au {endDate}\n",
    "bot.message.membersAway.leaveMessage": "- *{user}* est en congés {leaveTypeName} aujourd'hui {partDay}\n",
    "bot.message.membersAway.leaveMessageWasWillBe.fullDay": "- *{user}* {verb} en congés {leaveTypeName} du {startDate} au {endDate}\n",
    "bot.message.membersAway.leaveMessageWasWillBe": "- *{user}* {verb} en congés {leaveTypeName} le {startDate} {partDay}\n",
    "bot.message.membersAway.multiHoliday": "- Le site *{holidayLocationName}* sera absent du {start} au {end} (*{holidayName}*).\n",
    "bot.message.memersAway.noLeaves": "Aucun congé n'est actuellement planifié pour votre équipe sur cette période. 😎",
    "bot.message.moreOptions.about": "En savoir plus :koala:",
    "bot.message.moreOptions.leaveHistoryTitle": "Historique des congés",
    "bot.message.moreOptions.leavesAndHolidaysTitle": "Vos congés et jours fériés :",
    "bot.message.moreOptions.membersAway": "Voir les membres de l'équipe absents :",
    "bot.message.moreOptions.menageLeavesTitle": "Gérer les congés",
    "bot.message.moreOptions.showHolidaysTitle": "Afficher les jours fériés",
    "bot.message.moreOptions": "Bonjour <@{username}> !\nComment puis-je vous aider ?",
    "bot.message.myStatus.currentYear": "_Année en cours, jusqu'au {yearEnd}_",
    "bot.message.myStatus.leaveTypeStatusEarnedDays": "*{leaveTypeName}*\nJours restants : {current}\nJours utilisés : {used}\nTotal de jours : {earned}",
    "bot.message.myStatus.leaveTypeStatusUnlimited": "*{leaveTypeName}*\nJours restants : {currentDays}\nJours utilisés : {usedDays}\nTotal de jours : illimité",
    "bot.message.myStatus.nextYear": "_Année prochaine, du {startYear} au {endYear}_",
    "bot.message.myStatus.summary": "Voici un récapitulatif de vos congés pour l'année en cours et l'année prochaine.",
    "bot.message.noLeavePolicies": "Aucune politique de congés ne semble avoir été attribuée à ce site. 🤔 Contactez votre Administrateur pour en savoir plus. ",
    "bot.message.notification.overAllowedLimitForCurrentAndFollowingYear": "L'utilisateur demande {leaveDurationTotal} jours de congés, soit {overLimitCurrentYear} de plus que la limite fixée cette année pour l'utilisateur, et {overLimitNextYear} de plus que la limite pour l'année prochaine.",
    "bot.message.notification.overAllowedLimitForCurrentYear": "L'utilisateur demande {leaveDurationCurrentYear} jour de congé, soit {overLimit} de plus que la limite fixée cette année pour l'utilisateur.",
    "bot.message.notification.overAllowedLimitForCurrentYearPlural": "L'utilisateur demande {leaveDurationCurrentYear} jours de congés, soit {overLimit} de plus que la limite fixée cette année pour l'utilisateur.",
    "bot.message.notification.overAllowedLimitForFollowingYear": "L'utilisateur demande {leaveDurationNextYear} jour de congé, soit {overLimit} de plus que la limite fixée pour l'année prochaine pour l'utilisateur.",
    "bot.message.notification.overAllowedLimitForFollowingYearPlural": "L'utilisateur demande {leaveDurationNextYear} jours de congés, soit {overLimit} de plus que la limite fixée pour l'année prochaine pour l'utilisateur.",
    "bot.message.notSignedUpError": "Vous ne semblez pas avoir de compte Vacation Tracker. Veuillez commencer par consulter [{link}]({utmLink}) et vous en créer un. L'opération prend moins d'une minute !",
    "bot.message.openTheWebDashboard": "Ouvrir le tableau de bord Web",
    "bot.message.partDay": "de {partDayStartHour} à {partDayEndHour}",
    "bot.message.reminder": "Bonjour à toute l'équipe ! Voici un rappel du bot Vacation Tracker",
    "bot.message.requestLeave.error": "*Erreur : {errorMessage}*",
    "bot.message.requestLeave.leaveDuration1": "Cela représente 1 jour de congé.",
    "bot.message.requestLeave.leaveDurationHalfDay": "Cela représente une demi-journée de congés.",
    "bot.message.requestLeave.leaveDurationYear1": "Cela représente {year1Value} jours de congés.",
    "bot.message.requestLeave.leaveDurationYear1PluralYear2": "Cela représente {year1Days} jours de congés pour l'année en cours, et {year2Days} jour de congé pour l'année prochaine.",
    "bot.message.requestLeave.leaveDurationYear1Year2AllPlural": "Cela représente {year1Days} jours de congés pour l'année en cours, et {year2Days} jours de congés pour l'année prochaine.",
    "bot.message.requestLeave.leaveDurationYear2": "Cela représente {year2Value} jour de congé pour l'année prochaine.",
    "bot.message.requestLeave.leaveDurationYear2Plural": "Cela représente {year2Days} jours de congés pour l'année prochaine.",
    "bot.message.requestLeave.leaveDurationYear2PluralYear1": "Cela représente {year1Days} jour de congé pour l'année en cours, et {year2Days} jours de congés pour l'année prochaine.",
    "bot.message.requestLeave.selectDate.info": "*Date de début*\nVeuillez sélectionner une date de début",
    "bot.message.requestLeave.selectLeaveType.info": "*Type de congés*\nVeuillez sélectionner un type de congés",
    "bot.message.requestLeave.selectLeaveType": "Sélectionner un type de congés",
    "bot.message.requestLeave.selectTimeRange.end": "*Date de fin*\nVeuillez sélectionner une date de fin",
    "bot.message.requestLeave.selectTimeRange.info": "*Plage horaire*\nVeuillez sélectionner une plage horaire",
    "bot.message.requestLeave.selectTimeRange": "Sélectionner une plage horaire",
    "bot.message.requestLeave.submitted.end": ":tada: *{message}*.\nÀ bientôt !",
    "bot.message.requestLeave.submitted.letTeamKnow": "PS : N'hésitez pas à dire à mon équipe si je vous ai été utile.",
    "bot.message.requestLeave.submitted.withApproval": "'Les dates demandées ont été soumises à l'approbation de votre responsable.",
    "bot.message.requestLeave.submitted.withoutApproval": "Les dates que vous avez demandées ont été approuvées automatiquement.",
    "bot.message.requestLeave": "Veuillez sélectionner l'un des types de congés disponibles dans la liste ci-dessous, puis sélectionner les dates de début et de fin de vos congés. Cliquez sur le bouton « Envoyer » pour soumettre votre demande à approbation.",
    "bot.message.requestLeaveModal.requestALeave": "Demande de congés",
    "bot.message.requestLeaveModal.requestingLeave": "Pose de demande de congés",
    "bot.message.requestLeaveModal.sendFeedback": "Laisser votre avis",
    "bot.message.requestLeaveModal.sendRequest": "Envoyer une demande",
    "bot.message.selectADate": "Sélectionner une date",
    "bot.message.showHolidays.noUpcomingHolidays": "Votre organisation ne présente aucun jour férié, ou votre Administrateur n'en a encore défini aucun.",
    "bot.message.showHolidays.one": "- Le site *{holidayLocationName}* sera absent du {startDate} au {endDate} (*{holidayName}*).\n",
    "bot.message.showHolidays.two": "- Le site *{holidayLocationName}* sera absent le {date} (*{holidayName}*).\n",
    "bot.message.showHolidays.upcomingHolidays": "Jours fériés à venir",
    "bot.message.slack.userChanged": "L'utilisateur a modifié cette demande",
    "bot.message.slashCommand.helpCommand1": "*Voici plus d'informations sur Vacation Tracker :*",
    "bot.message.slashCommand.helpCommand2": "Vacation Tracker est apprécié de plus de 1 000 entreprises à travers le monde, et *permet à votre équipe de suivre facilement les absences au bureau à tout moment*.",
    "bot.message.slashCommand.helpCommand3": "Découvrez notre histoire sur notre <https://vacationtracker.io/about|page « À propos »>.",
    "bot.message.slashCommand.helpCommand4": "Pour bénéficier de guides détaillés, consultez notre <https://vacationtracker.crisp.help/en/|Centre d'assistance>.",
    "bot.message.slashCommand.helpCommand5": "Pour vous connecter au tableau de bord en ligne, visitez la page <{dashboardUrl}/connect?platform=slack>.",
    "bot.message.slashCommand.helpCommand6": "Si vous avez besoin d'aide, n'hésitez pas à nous envoyer un e-mail à tout moment à l'adresse hello@vacationtracker.io.",
    "bot.message.slashCommand.noPermissions": "Pardon, mais vous ne semblez pas bénéficier des autorisations requises pour accéder à Vacation Tracker. 😟 Veuillez contacter votre Administrateur.",
    "bot.message.slashCommand.title": "Bonjour <@{userId}> ! Vous voulez poser quelques jours de congés ? Laissez-moi vous aider !",
    "bot.message.unableToRequestLeave": "Envoi de la demande impossible",
    "bot.message.unknownCommand": "Pardon, mais je ne comprends pas cette commande.\nTapez **help** pour voir la liste des commandes disponibles.",
    "bot.notification.approverCard.approved": "✅ @{approverName} a approuvé cette demande. Aucune autre action n'est requise.",
    "bot.notification.approverCard.autoApproved": "Cette demande a été approuvée automatiquement. Aucune autre action n'est requise. ",
    "bot.notification.approverCard.cancelled": "⚠️ {requesterName} semble avoir changé d'avis. Cette demande a été annulée.",
    "bot.notification.approverCard.cancelledTitle": "⚠️ Ces congés ont été annulés par un Approbateur.",
    "bot.notification.approverCard.denied": "❌ @{approverName} a rejeté cette demande. Aucune autre action n'est requise.",
    "bot.notification.approverCard.deniedEdited": "❌ @{approverName} a rejeté cette demande modifiée. Aucune autre action n'est requise.",
    "bot.notification.approverCard.editedByApprover": "@{approverName} a modifié une demande de congés posée par @{requesterName}",
    "bot.notification.approverCard.editedByApproverForUser": "@{approverName} a modifié une demande de congés pour @{requesterName}.",
    "bot.notification.approverCard.info": "Motiver cette approbation ou ce rejet",
    "bot.notification.approverCard.notActive": "⚠️ Cette demande de congés n'est plus active.",
    "bot.notification.daily.info": "C'est l'heure de votre résumé quotidien ! 🐨 Voici les congés à venir :",
    "bot.notification.daily.noLeaves.isNotForToday": "Aucun congé n'est prévu le {dayName}.",
    "bot.notification.daily.noLeaves.isToday": "Aucun congé n'est prévu aujourd'hui",
    "bot.notification.holiday.plural": "- Les sites **{locationNames}** seront absents {holidayPeriodText} (**{holidayName}**)",
    "bot.notification.holiday.singular": "- Le site **{locationNames}** sera absent {holidayPeriodText} (**{holidayName}**)",
    "bot.notification.leave.info.isInTheFuture": "sera en congés {leaveTypeText} {leavePeriodText}",
    "bot.notification.leave.info.isToday": "est en congés {leaveTypeText} {leavePeriodText}",
    "bot.notification.leaveDates.partDay": "{start} de {partDayStartHour} à {partDayEndHour}",
    "bot.notification.leavePeriod.fullDay": "le {day}",
    "bot.notification.leavePeriod.moreDays": "du {start} au {end}",
    "bot.notification.leavePeriod.partDay": "le {start} de {partDayStartHour} à {partDayEndHour}",
    "bot.notification.leavePolicyInfo": "*Remarque : le solde défini pour les congés demandés est de 0 pour cet utilisateur ou ce type de congés.*",
    "bot.notification.leaveRequestError.general": "Erreur de la demande de congés",
    "bot.notification.leaveRequestError.notApprover": "Cette action est uniquement possible pour les Approbateurs et les Administrateurs.",
    "bot.notification.leaveRequestError.notOpen": "La demande de congé a été {leaveRequestStatus}",
    "bot.notification.leaveRequestError.notOpenIsSameAppover": "La demande de congés a été {leaveRequestStatus}",
    "bot.notification.leaveTypeText.isPartDay": "({leaveTypeName} : demi-journée)",
    "bot.notification.processingApproval": "Traitement de l'approbation…",
    "bot.notification.processingDenial": "Traitement du rejet…",
    "bot.notification.requesterCard.addedLeave": "Des congés ont été créés pour vous",
    "bot.notification.requesterCard.addedLeaveBy": "Ajouté par {approverName}",
    "bot.notification.requesterCard.approvedTitle": "Votre demande de congés a été approuvée",
    "bot.notification.requesterCard.approverInfo": "Approuvé par {approverName}",
    "bot.notification.requesterCard.autoApprovedInfo": "La demande a été automatiquement approuvée.",
    "bot.notification.requesterCard.cancelled": "⚠️ Annulé",
    "bot.notification.requesterCard.cancelledEdited": "'⚠️ Des modifications ont été apportées à la demande d'origine.",
    "bot.notification.requesterCard.cancelledEditedByRequester": "@{requesterName} a modifié cette demande de congés.",
    "bot.notification.requesterCard.cancelledTitle": "⚠️ Vos congés ont été annulés par un Approbateur.",
    "bot.notification.requesterCard.deniedBy": "Votre demande de congés a été approuvée",
    "bot.notification.requesterCard.deniedEditedDescription": "Votre demande de congés modifiée a été rejetée, mais la demande d'origine reste active.",
    "bot.notification.requesterCard.deniedEditedTitle": "La modification de la demande a été rejetée.",
    "bot.notification.requesterCard.deniedOpenDescription": "La demande de congés d'origine et la demande modifiée ont été rejetées.",
    "bot.notification.requesterCard.deniedOpenTitle": "La demande de congés a été rejetée",
    "bot.notification.requesterCard.deniedTitle": "Votre demande de congés a été rejetée",
    "bot.notification.requesterCard.edited": "@{requesterName} a modifié sa demande précédemment acceptée et en demande à nouveau l'approbation :",
    "bot.notification.requesterCard.editedOpen": "@{requesterName} a modifié, puis renvoyé sa demande de congés.",
    "bot.notification.requesterCard.moreDays": "❌ Vous avez demandé plus que le nombre de jours disponibles.",
    "bot.notification.requesterCard.moreThanFullDay": "❌ La demande de congés ne peut pas être envoyée car vous avez demandé plusieurs journées complètes de congés sur un même jour de travail.",
    "bot.notification.requesterCard.noWorkingDays": "❌ La demande de congés ne peut pas être envoyée car vous n'avez inclus aucun jour de travail. Assurez-vous de vérifier les jours de travail de votre équipe avant d'envoyer votre demande.",
    "bot.notification.requesterCard.originalLeaveRequest": "Demande de congés d'origine :",
    "bot.notification.requesterCard.overlap": "❌ La demande de congés ne peut pas être envoyée car les dates choisies se chevauchent avec celles d'un congé existant.",
    "bot.notification.requesterCard.requestInfo": "Votre demande va être transmise à l'Approbateur. À bientôt ! 🐨",
    "bot.notification.requesterCard.requestPossibleActions": "Pour modifier ou annuler cette demande, consulter le tableau de bord",
    "bot.notification.requesterInfo": "Bonjour, @{userName} vous a soumis une requête à examiner.",
    "bot.notification.requesterInfoAutoApproved": "Bonjour, @{userName} a soumis une requête.",
    "bot.notification.requesterInfoEdited": "@{userName} a modifié et soumis à nouveau sa requête.",
    "bot.notification.requesterInfoEditedByApprover": "@{approverName} a modifié une requête soumise par @{userName}",
    "bot.notification.requesterInfoEditedForUser": "Votre requête a été modifiée.",
    "bot.notification.requesterInfoEditedForUserByApprover": "L'Approbateur @{approverName} a apporté des modifications à votre requête.",
    "bot.notification.requesterInfoEditedOpen": "@{userName} a modifié et soumis à nouveau sa requête.",
    "bot.notification.slack.additional": "… Entre autres.\nD'autres congés n'ont pas pu s'afficher par manque de place. Veuillez ouvrir le tableau de bord pour en avoir une vue d'ensemble.",
    "bot.notification.slack.approverChanged": "Bonjour, l'Approbateur <@{approverSlackId}> a modifié une demande de congés.",
    "bot.notification.slack.approverChangedYour": "L'Approbateur <@{approverSlackId}> a modifié votre demande de congés.",
    "bot.notification.slack.calendar": ":calendar: |  *{dayName}* | :calendar: ",
    "bot.notification.slack.cancelled": "Bonjour, <@{userName}> a annulé ses congés.",
    "bot.notification.slack.cancelledByApprover": "Vos congés ont été annulés par un Approbateur.",
    "bot.notification.slack.cancelledInfo": "Ces congés ont été annulés par un Approbateur.",
    "bot.notification.slack.created": "Ces congés ont été créés pour vous par un Approbateur.",
    "bot.notification.slack.holiday.plural": "- Les sites *{locationNames}* seront absents {holidayPeriodText} (*{holidayName}*)",
    "bot.notification.slack.holiday.singular": "- Le site *{locationNames}* sera absent {holidayPeriodText} (*{holidayName}*)",
    "bot.notification.slack.holidays": ":airplane: *Jours fériés :*",
    "bot.notification.slack.leave.info.isInTheFuture": "- *{userName}* sera en congés {leaveTypeText} {leavePeriodText}",
    "bot.notification.slack.leave.info.isToday": "- *{userName}* est en congés {leaveTypeText} {leavePeriodText}",
    "bot.notification.slack.leaveRequestCreated": "Bonjour, <@{userName}> a envoyé une demande de congés.",
    "bot.notification.slack.leaves": ":loud_sound: *Congés :*",
    "bot.notification.slack.reason": "*Motif*\n{reason}",
    "bot.notification.slack.reasonEdited": "*Motif (modifié)*\n{reason}",
    "bot.notification.slack.statusAutoApproved": "*État*\nApprouvé automatiquement",
    "bot.notification.slack.title": "Notification: \"_{title}_\"",
    "bot.notification.slack.warning": ":warning: *Avertissement : {infoMessage}*",
    "bot.notification.title": "Notification: {title}",
    "bot.notification.weekly.current.info": "Bonjour !\n 🐨 Je viens vous informer des congés planifiés cette semaine ({start} - {end})",
    "bot.notification.weekly.next.info": "Bonjour !\n 🐨 Je viens vous informer des congés planifiés la semaine prochaine ({start} - {end})",
    "bot.notification.weekly.noHolidays": "Cette semaine ne comporte aucun jour férié.",
    "bot.notification.weekly.noLeaves": "Aucun congé planifié n'est prévu cette semaine.",
    "bot.welcomeMessage.furtherAssistance": "Vous trouvez qu'il y a trop de nouvelles informations ? Pas de panique : vous n'avez pas besoin de tout mémoriser ! Saisissez simplement « Help » dans cette discussion pour que notre bot puisse vous aider. Si vous avez encore besoin d'aide, n'hésitez pas à contacter notre service d'assistance depuis notre site Web.",
    "bot.welcomeMessage.goToMyProfile": "Accéder à l'onglet « Mon profil »",
    "bot.welcomeMessage.greeting": "Bonjour {name}, je suis le bot Vacation Tracker ! Je suis là pour vous aider à planifier vos congés via Microsoft Teams et pour garantir que l'ensemble de votre organisation est au courant des absences au bureau. Commençons le suivi des congés sans plus tarder !",
    "bot.welcomeMessage.happyVacationing": "Bonnes vacances 🌴",
    "bot.welcomeMessage.howToAccessTabs": "Accéder aux onglets",
    "bot.welcomeMessage.noCompany": "Bonjour,\n\nMerci d'avoir installé le bot et l'onglet Vacation Tracker dans Microsoft Teams. La dernière étape consiste à vous rendre sur notre site Web pour finaliser l'enregistrement et configurer les paramètres de votre organisation :  [{dashboardLink}]({utmDashboardLink})",
    "bot.welcomeMessage.noCompanyInfo": "Si vous avez la moindre question, n'hésitez pas à envoyer un message à notre service d'assistance via notre site Web ou à consulter notre Centre d'assistance.",
    "bot.welcomeMessage.openWebDashboardInfo": "Vous pouvez aussi consulter ces informations depuis le tableau de bord Web. Pour y accéder, cliquez simplement sur le bouton « Ouvrir le tableau de bord Web » ci-dessous, puis connectez-vous à votre compte Microsoft.",
    "bot.welcomeMessage.requestLeaveInfo": "Pour commencer, envoyez-moi simplement un message direct indiquant « Request leave » ou regardez ce court tutoriel vidéo sur Vacation Tracker :",
    "bot.welcomeMessage.restInfo": "En plus du bot, vous pouvez aussi envoyer des demandes de congés, consulter votre solde de congés, les calendriers de votre équipe et bien plus depuis Microsoft Teams à l'aide d'onglets :",
    "bot.welcomeMessage.title": "Vacation Tracker vous souhaite la bienvenue",
    "calendar.syncButton": "Synchroniser avec votre calendrier (Google, Outlook, etc.)",
    "calendar.syncModal.description": "Copiez le lien ci-dessous et intégrez Vacation Tracker à votre calendrier Google Agenda, Outlook ou à tout autre calendrier compatible avec les flux iCal.",
    "calendar.syncModal.filters": "Les filtres suivants s'appliquent au lien de ce calendrier :",
    "calendar.syncModal.helpdesk": "Pour obtenir de l'aide et des instructions détailles, consultez notre <helpDesk>Centre d'assistance</helpDesk>.",
    "calendar.week.monFri": "lun-ven",
    "calendar.week.sunThu": "dim-jeu",
    "components.billing.email": "Adresse e-mail de facturation",
    "components.billing.emailPlaceholder": "Saisissez une adresse e-mail.",
    "components.billing.emailsValidationRequired": "Une adresse e-mail est requise pour la facturation.",
    "components.billing.resubscribe": "Se réinscrire",
    "components.filterSimple.filterBy": "Filtrer par",
    "components.filterSimple.selectType": "Sélectionner un type",
    "components.filterSimple.tooltipInfo": "Si vous ne sélectionnez aucun filtre, l'exportation comprendra tous les utilisateurs.",
    "components.helpDrawer.adminProductTourInfo": "En tant qu'Administrateur, vous possédez le niveau d'autorisation le plus élevé de Vacation Tracker, et vous pouvez personnaliser tous les paramètres depuis le tableau de bord d'administration.\n\nLe tableau de bord vous permet de :\n- poser des demandes de congés, ou en ajouter pour d'autres membres du service ;\n- bénéficier d'une vue d'ensemble depuis la page d'accueil et le calendrier ;\n- créer et configurer plusieurs services (définir des Approbateurs) ;\n- définir des notifications ;\n- gérer les utilisateurs (actifs, inactifs ou supprimés) ;\n- créer et gérer les sites (types de congés, jours fériés, notifications).\n\nLe bot vous permet de :\n- poser des demandes de congés ;\n- approuver ou rejeter des demandes de congés ;\n- recevoir des notifications quotidiennes ou hebdomadaires.\n\nSi vous avez d'autres questions ou remarques, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle : ",
    "components.helpDrawer.adminProductTourTitle": "Votre rôle : Administrateur",
    "components.helpDrawer.approverProductTourInfo": "En tant qu'Approbateur, vous possédez le deuxième niveau d'autorisation le plus élevé de Vacation Tracker, et vous pouvez accepter ou rejeter des demandes de congés envoyées par les utilisateurs du service pour lequel vous avez été désigné comme Approbateur.\n\nLe tableau de bord vous permet de :\n- poser des demandes de congés, ou en ajouter pour d'autres membres du service ;\n- bénéficier d'une vue d'ensemble depuis la page d'accueil et le calendrier ;\n- définir des notifications ;\n- consulter votre profil utilisateur ;\n- gérer les demandes (en consulter l'historique, les approuver ou les rejeter).\n\nLe bot vous permet de :\n- poser des demandes de congés ;\n- approuver ou rejeter des demandes de congés ;\n- recevoir des notifications quotidiennes ou hebdomadaires.\n\nSi vous avez d'autres questions ou remarques, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle :",
    "components.helpDrawer.approverProductTourTitle": "Votre rôle : Approbateur",
    "components.helpDrawer.google.adminProductTourInfo": "En tant qu'Administrateur, vous possédez le niveau d'autorisation le plus élevé de Vacation Tracker, et vous pouvez personnaliser tous les paramètres depuis le tableau de bord d'administration.\n\nLe tableau de bord vous permet de :\n- poser des demandes de congés, ou en ajouter pour d'autres membres du service ;\n- bénéficier d'une vue d'ensemble depuis la page d'accueil et le calendrier ;\n- créer et configurer plusieurs services (définir des Approbateurs) ;\n- définir des notifications ;\n- gérer les utilisateurs (actifs, inactifs ou supprimés) ;\n- créer et gérer les sites (types de congés, jours fériés, notifications).\n\nSi vous avez d'autres questions ou remarques, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle : ",
    "components.helpDrawer.google.approverProductTourInfo": "En tant qu'Approbateur, vous possédez le deuxième niveau d'autorisation le plus élevé de Vacation Tracker, et vous pouvez accepter ou rejeter des demandes de congés envoyées par les utilisateurs du service pour lequel vous avez été désigné comme Approbateur.\n\nLe tableau de bord vous permet de :\n- poser des demandes de congés, ou en ajouter pour d'autres membres du service ;\n- bénéficier d'une vue d'ensemble depuis la page d'accueil et le calendrier ;\n- définir des notifications ;\n- consulter votre profil utilisateur ;\n- gérer les demandes (les approuver ou les rejeter, en consulter l'historique).\n\nSi vous avez d'autres questions ou remarques, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle : ",
    "components.helpDrawer.google.userProductTourInfo": "En tant qu'Utilisateur Vacation Tracker, vous pouvez poser des demandes de congés et les gérer depuis le tableau de bord ou le bot, et bénéficier d'une vue d'ensemble des absences planifiées au sein de votre organisation.\n\nLe tableau de bord vous permet de :\n- poser des demandes de congés ;\n- bénéficier d'une vue d'ensemble depuis la page d'accueil et le calendrier ;\n- consulter votre profil utilisateur.\n\nSi vous avez d'autres questions ou remarques, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle : ",
    "components.helpDrawer.helpDeskInfo": "Pour en savoir plus sur notre produit et consulter les réponses aux questions fréquentes, consultez notre Centre d'assistance.",
    "components.helpDrawer.learnMore": "En savoir plus",
    "components.helpDrawer.needhelp": "Vous avez besoin d'aide ?",
    "components.helpDrawer.quickTourInfo": "Nous avons rédigé une procédure que nous vous recommandons de suivre pour permettre la configuration rapide de votre organisation.",
    "components.helpDrawer.quickTourTitle": "Visite guidée",
    "components.helpDrawer.supportInfo": "Si vous avez des questions ou besoin d'aide, envoyez un message à notre service d'assistance ou un e-mail à l'adresse <link>hello@vacationtracker.io</link>.",
    "components.helpDrawer.supportTitle": "Assistance",
    "components.helpDrawer.userProductTourInfo": "En tant qu'Utilisateur Vacation Tracker, vous pouvez poser des demandes de congés et les gérer depuis le tableau de bord ou le bot, et bénéficier d'une vue d'ensemble des absences planifiées au sein de votre organisation.\n\nLe tableau de bord vous permet de :\n- poser des demandes de congés ;\n- bénéficier d'une vue d'ensemble depuis la page d'accueil et le calendrier ;\n- consulter votre profil utilisateur.\n\nLe bot vous permet de :\n- poser des demandes de congés ;\n- recevoir des notifications quotidiennes ou hebdomadaires.\n\nSi vous avez d'autres questions ou remarques, n'hésitez pas à nous contacter à tout moment. Nous nous faisons toujours une joie d'échanger avec notre clientèle : ",
    "components.helpDrawer.userProductTourTitle": "Votre rôle : utilisateur",
    "components.importUsersForm.warningNumberOfUsersToImport": "En cliquant sur le bouton « Importer et activer les utilisateurs », les {users} utilisateurs de votre répertoire seront activés. Cette action peut avoir un impact sur votre facturation.",
    "components.leaveForm.selectUser": "Sélectionner un utilisateur",
    "components.leavePolicyForm.allowAddAndEditLeaveInPast": "Modifier des demandes de congés passées et en poser",
    "components.leavePolicyForm.allowAddAndEditLeaveInPastInfo": "Choisissez si vous voulez autoriser les utilisateurs standards à modifier des demandes de congés passées ou à en poser.",
    "components.leaveRequestAction.addToCalendar": "Ajouter au calendrier",
    "components.leaveRequestAction.addToCalendarMoreInfo": "En savoir plus sur la fonctionnalité « Ajouter au calendrier »",
    "components.locationForm.noRolloverExpiryDate": "Aucune date d'expiration des jours de congés reportés",
    "components.locationForm.warningInitialRollover": "Toute modification des paramètres d'un site peut affecter la configuration et les informations liées aux congés des utilisateurs (solde et compte de jours congés, par exemple).",
    "components.manageAssignLicenses.assignLicenses": "Attribuer {numberOfToAssignLicenses} licences",
    "components.manageAssignLicenses.avaliableToAssign": "Licences pouvant être attribuées : {avaliableLicenses}",
    "components.manageAssignLicenses.microsoftTeamsMessage": "Message Microsoft Teams",
    "components.manageAssignLicenses.moreUserThenLicenses": "Vous avez attribué {users} licences de trop",
    "components.manageAssignLicenses.placeholderBulkWelcomeMessage": "Sélectionner une action groupée",
    "components.manageAssignLicenses.placeholderSelectRole": "Sélectionner un rôle",
    "components.manageAssignLicenses.roleTooltipInfo": "Attribuer des rôles aux utilisateurs de Vacation Tracker. Pour en savoir plus, consultez notre <helpDesk>Centre d'assistance</helpDesk>.",
    "components.manageAssignLicenses.sendWelcomeMessage": "Envoyer un message de bienvenue",
    "components.manageAssignLicenses.totalLicenses": "Nombre total de licences : {numberOflicenses}",
    "components.notificationDestination.label": "Envoyer à",
    "components.notificationDestination.tooltip": "Notez que vous pouvez envoyer une notification à plusieurs adresses e-mail, mais uniquement vers un canal à la fois",
    "components.onboarding.installMSBotButton": "Installer le bot",
    "components.onboarding.installMSBotDescription": "Installez le bot et les onglets Vacation Tracker pour gérer facilement les congés de votre équipe dans Microsoft Teams.",
    "components.onboarding.installMSBotTitle": "<icon></icon> Finaliser la configuration du bot",
    "components.onboarding.inviteFriendsDescription": "Intégrez les membres de votre équipe pour bénéficier d'une gestion précise et rapide de leurs congés (ajoutez au moins trois membres de votre équipe pour finaliser cette étape).",
    "components.onboarding.inviteFriendsTitle": "<icon></icon> Inviter les membres de votre équipe",
    "components.onboarding.openNotificationsButton": "Ouvrir les notifications",
    "components.onboarding.requestALeaveDescription": "Suivez trois étapes simples pour envoyer votre première demande de congés.",
    "components.onboarding.requestALeaveTitle": "<icon></icon> Poser une demande de congés",
    "components.onboarding.setNotificationsDescription": "Choisissez la fréquence à laquelle vous souhaitez recevoir des notifications vous informant des congés de votre équipe.",
    "components.onboarding.setNotificationsTitle": "<icon></icon> Configurer les notifications",
    "components.onboarding.skipOnboarding": "Passer l'intégration",
    "components.onboarding.syncTheCalendarDescription": "Intégrez le calendrier Vacation Tracker à votre calendrier pour garantir que les congés de votre équipe sont à jour.",
    "components.onboarding.syncTheCalendarTitle": "<icon></icon> Synchroniser les calendriers",
    "components.onboarding.syncTheCalendarVideoForSyncingWithGoogleCalendar": "Vidéo sur la synchronisation avec Google Agenda",
    "components.onboarding.syncTheCalendarVideoForSyncingWithIcal": "Vidéo sur la synchronisation avec iCal",
    "components.onboarding.syncTheCalendarVideoForSyncingWithMicrosoftOutlookCalendar": "Vidéo sur la synchronisation avec Microsoft Windows",
    "components.onboarding.title": "Checklist d'intégration <progressBar>{progressStatus}</progressBar>",
    "components.userProfileTab.employeeIdNotSet": "Non défini",
    "components.userProfileTab.employeeIdTooltip": "ID de l'employé dans un système externe",
    "connect.companyExists.lineEmailAddress": "Votre adresse e-mail",
    "connect.meta.description": "Accédez au tableau de bord Vacation Tracker et bénéficiez de tout le potentiel de cet outil pratique de gestion des congés.",
    "connect.meta.title": "S'inscrire/se connecter : Vacation Tracker",
    "dashboard.days": "Jour(s)",
    "dashboard.upcomingHolidays": "Jours fériés à venir",
    "departments.viewDepartment": "Consulter le service",
    "error.auth.companyExists.line1": "Votre compte n'est pas activé. Pour utiliser Vacation Tracker, veuillez contacter l'Administrateur et lui demander d'activer votre compte.",
    "error.auth.companyExists.line2": "Administrateurs :",
    "error.auth.companyExists.line3": "Vous pouvez aussi contacter l'assistance ({support}) si vous souhaitez utiliser Vacation Tracker comme service indépendant de votre entreprise.",
    "error.auth.companyExists.title": "Votre organisation est déjà inscrite.",
    "error.channelNotFound": "Le canal sélectionné a été supprimé ou n'est plus disponible. Veuillez en sélectionner un nouveau vers lequel envoyer les notifications.",
    "error.companyExists": "Cette entreprise existe déjà",
    "error.fieldIsRequired": "Ce champ est obligatoire.",
    "error.generic.description": "Veuillez contacter l'assistance en indiquant le message suivant :",
    "error.leaveSubmitError": "Erreur d'envoi de la demande",
    "error.microsoft.channelNotFound": "Vous n'êtes pas membre de l'équipe Microsoft Teams sélectionnée",
    "error.microsoft.logInWithDifferentAccount": "Se connecter avec un autre compte",
    "error.microsoft.microsoftPermissionsNotGranted": "Les autorisations Microsoft n'ont pas été attribuées",
    "error.microsoft.notificationsLoginRequiredTitle": "Connexion à votre compte Microsoft requise",
    "error.microsoft.notMemberOfTeam": "Vous n'êtes pas membre de l'équipe Microsoft Teams avec laquelle votre organisation s'est inscrite qui présente l'ID suivant : {msTeamId}.\n\nVous pouvez rejoindre cette équipe depuis votre application Microsoft Teams puis réessayer, ou contacter notre service d'assistance pour modifier votre type d'installation.",
    "error.microsoft.wrongAccount": "Mauvais compte Microsoft",
    "error.notificationGeneral": "Erreur de notification",
    "error.saveFailed": "L'enregistrement a échoué",
    "error.slack.usersLoginRequiredTitle": "Connexion à votre compte Slack requise",
    "error.somethingWentWrong": "Mince 😕 Un problème est survenu. ",
    "error.startDateCantBeInPast": "La date de début ne peut pas être passée. Veuillez sélectionner une date ultérieure.",
    "error.subscriptionExpired": "L'abonnement Vacation Tracker de votre entreprise semble arrivé à expiration ! Veuillez contacter un Administrateur pour le réactiver.",
    "error.subscriptionExpiredTitle": "Abonnement arrivé à expiration",
    "errors.microsoft.permissions.microsoftPermissionsNotGrantedDescription": "Vous semblez avoir annulé l'action ou fermé la page « Se connecter avec Microsoft » avant d'attribuer les autorisations requises. Veuillez cliquer sur le bouton ci-dessous pour vous connecter avec Microsoft et finaliser la configuration Vacation Tracker.\n\nSi vous n'avez pas les autorisations nécessaires pour vous connecter avec Microsoft, veuillez contacter l'administrateur de votre organisation Microsoft 365.",
    "errors.microsoft.subscriptionExpiredDescription": "Votre abonnement semble arrivé à expiration. Veuillez contacter l'Administrateur de votre organisation Vacation Tracker pour en savoir plus.\n\nContactez notre service d'assistance si vous avez besoin de modifier l'Administrateur ou si vous avez d'autres questions.",
    "errors.microsoft.wrongMicrosoftAccountDescription": "Votre connexion actuelle semble correspondre à un autre compte Microsoft. Vous avez acheté les licences à l'aide de l'adresse suivante :\n\n{email}\n\nVeuillez vous connecter au compte à l'aide duquel vous avez acheté les licences pour finaliser la configuration.",
    "export.fileType": "Format d'exportation",
    "export.fileTypeCsv": "CSV",
    "export.fileTypeExcelXlsx": "Excel",
    "holidays.holidaysName": "Nom du jour férié",
    "holidays.importHolidays": "Importer des jours fériés",
    "importUsers.allUsersImported.description": "Vous semblez avoir importé tous les utilisateurs de votre répertoire, qui devraient s'afficher sur la page « Utilisateurs ». Si un utilisateur n'y figure pas, consultez l'onglet « Inactifs ». N'hésitez pas à contacter l'assistance si vous avez des questions ou besoin d'aide.",
    "importUsers.allUsersImported.goToUsersPageButton": "Accéder à la page « Utilisateurs »",
    "importUsers.allUsersImported.title": "Tous les utilisateurs ont déjà été importés !",
    "importUsers.form.backButton": "Retour à la page « Utilisateurs »",
    "importUsers.form.searchUsersPlaceholder": "Rechercher des utilisateurs dans Microsoft Active Directory",
    "importUsers.form.selectedUsersLabel": "Utilisateurs sélectionnés ({selectedUsers})",
    "importUsers.statistics.imported": "Utilisateurs importés dans Vacation Tracker",
    "importUsers.statistics.total": "Nombre total d'utilisateurs dans votre répertoire {directoryName}",
    "leaveTypes.activatingLeaveType": "Activer le type de congés",
    "leaveTypes.deactivateLeaveType": "Désactiver le type de congés",
    "location.fiscalYearStart": "Début de l'année fiscale",
    "locations.leavePolicies.yearlyQuota": "Solde annuel de congés",
    "locations.resetQuotas": "Date de réinitialisation du solde annuel de congés",
    "locations.selectLocation": "Sélectionner un site",
    "manageMicrosoftLicenses.assignLicencesSuccessTitle": "Les licences ont bien été attribuées.",
    "manageMicrosoftLicenses.weAreAssignLicenses": "Nous attribuons les licences.",
    "microsoft.manageLicenses.loginMicrosoft": "Connectez-vous à votre compte Microsoft 365 pour attribuer des licences.",
    "microsoft.manageLicenses.loginMicrosoftInfo": "En vous connectant à votre compte, vos utilisateurs Microsoft Active Directory seront répertoriés. Vous pourrez attribuer les licences que vous avez achetées, et notifier les utilisateurs importés si vous le souhaitez.",
    "microsoft.universalLoginRequiredDescription": "Votre jeton semble expiré. Veuillez vous connecter à votre compte Microsoft Teams pour poursuivre.",
    "microsoft.universalLoginRequiredTitle": "Jeton expiré",
    "microsoftSass.createCompany.alertError.companyAlreadyExistsDescription": "Votre entreprise est déjà inscrite, mais votre compte n'est pas actif. Utilisez-vous la bonne adresse e-mail ?\nVotre adresse e-mail : {email}\nSi cette adresse est correcte, votre compte n'est pas activé. Veuillez contacter l'Administrateur de votre organisation Vacation Tracker : {admins}\nVous pouvez également contacter notre service d'assistance si vous souhaitez vous inscrire comme équipe indépendante de votre organisation.\nSi votre adresse e-mail est incorrecte, veuillez vous connecter avec un autre compte Microsoft ou depuis une fenêtre de navigation privée de votre navigateur.",
    "microsoftSass.createCompany.alertError.errorTitle": "Erreur",
    "microsoftSass.createCompany.alertError.goToAdminCenter": "Accéder au Centre d'administration",
    "microsoftSass.createCompany.alertError.goToConnectPage": "Accéder à la page de connexion",
    "microsoftSass.createCompany.alertError.logInWithPlatform": "Se connecter avec {platform}",
    "microsoftSass.createCompany.alertError.resubscribedDescription": "La réinscription de l'entreprise {companyName} a bien été effectuée.\n\nVeuillez cliquer sur le bouton pour accéder à la page de connexion.",
    "microsoftSass.createCompany.alertError.resubscribedTitle": "La réinscription a bien été effectuée.",
    "microsoftSass.createCompany.alertError.tokenExpiredDescription": "Votre jeton d'achat est arrivé à expiration. Veuillez accéder au Centre d'administration Microsoft Teams, puis cliquer sur le bouton de configuration du compte pour poursuivre.",
    "microsoftSass.createCompany.alertError.tokenExpiredTitle": "Jeton d'achat expiré",
    "microsoftSass.createCompany.alertError.tokenInvalidDescription": "Votre jeton d'achat Microsoft n'est pas valide. Si vous avez souscrit une offre Vacation Tracker via le catalogue d'applications Microsoft Teams, veuillez consulter le Centre d'administration Microsoft Teams, puis cliquer sur le bouton de configuration du compte pour poursuivre. Veuillez sinon accéder à notre page de connexion pour vous connecter ou créer un compte.",
    "microsoftSass.createCompany.alertError.tokenInvalidTitle": "Jeton d'achat non valide",
    "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountDescription": "Vous avez déjà un compte. Votre entreprise ({companyName}) s'est inscrite à l'aide de {platform}. Cliquez sur le bouton ci-dessous pour vous connecter avec votre compte {platform}.\n\nRassurez-vous, nous avons annulé votre achat et les licences que vous venez d'acheter ne vous seront pas facturées. L'ancien abonnement de votre entreprise reste actif. Vous pouvez visiter le Centre d'administration Microsoft pour vérifier que votre nouvel abonnement a bien été résilié.\n\nSi vous souhaitez acheter un autre abonnement, veuillez contacter notre service d'assistance.",
    "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountTitle": "Vous avez déjà un compte",
    "microsoftSass.createCompany.assignLicenses.assignPurchasedLicenses": "ATTRIBUER DES LICENCES ACHETÉES",
    "microsoftSass.createCompany.assignLicenses.assignPurchasedLicensesInfo": "Attribuons désormais les licences que vous avez achetées ! Vous avez {totalLicenses} licences. Attribuez-les aux utilisateurs pour leur permettre d'utiliser Vacation Tracker.",
    "microsoftSass.createCompany.assignLicenses.buyMoreLicensesLater": "Vous pourrez acheter d'autres licences ultérieurement sur la page « Utilisateurs » du tableau de bord Vacation Tracker.",
    "microsoftSass.createCompany.assignLicenses.loginMicrosoftDontWorry": "Rassurez-vous, nous n'importerons ni ne notifierons aucun utilisateur sans votre accord.",
    "microsoftSass.createCompany.completed": "Terminé",
    "microsoftSass.createCompany.createCompanySetupInfo": "Définissons à présent l'adresse e-mail de contact et les paramètres par défaut de votre organisation Vacation Tracker.",
    "microsoftSass.createCompany.createCompanyTitle": "FINALISER LA CONFIGURATION DE L'ENTREPRISE",
    "microsoftSass.createCompany.finishFristStep": "Veuillez finaliser la configuration de l'entreprise pour poursuivre.",
    "microsoftSass.createCompany.finishSecondStep": "Veuillez attribuer des licences pour poursuivre.",
    "microsoftSass.createCompany.finishSetup": "Finaliser la configuration",
    "microsoftSass.createCompany.help": "Si vous avez besoin d'aide, consultez notre <helpdesk>Centre d'assistance</helpdesk> ou <support>contactez l'assistance</support>.",
    "microsoftSass.createCompany.importAllUsers": "Attribué à tous les utilisateurs",
    "microsoftSass.createCompany.letsCompleteSetup": "À présent, finalisons la configuration, attribuons des licences à vos utilisateurs, puis installons l'intégration Vacation Tracker pour Microsoft Teams.",
    "microsoftSass.createCompany.numberOfLicensesAssigned": "{assignedLicenses} licences attribuées",
    "microsoftSass.createCompany.setupMsBot.areYouAdmin": "Êtes-vous l'administrateur de votre intégration Microsoft Teams ?",
    "microsoftSass.createCompany.setupMsBot.errorExtedScopeLogin": "Il semble que deux des autorisations Microsoft Teams requises pour configurer le bot et les onglets des canaux n'ont pas été attribuées. Sans cela, nous ne pouvons pas envoyer de message de bienvenue aux membres d'équipe de votre choix, et vous ne pouvez pas utiliser l'application Vacation Tracker depuis Microsoft Teams.",
    "microsoftSass.createCompany.setupMsBot.errorExtedScopeLoginSkip": "Vous pouvez passer cette étape et installer le bot et les onglets manuellement. Consulter notre Centre d'assistance pour obtenir des instructions détaillées.",
    "microsoftSass.createCompany.setupMsBot.installBotAndTabs": "Installer le bot et les onglets",
    "microsoftSass.createCompany.setupMsBot.installManuallyBotAdnTabs": "Je vais installer le bot et les onglets manuellement",
    "microsoftSass.createCompany.setupMsBot.microsoftTeamsLogin": "Microsoft Teams",
    "microsoftSass.createCompany.setupMsBot.morePermissionInfo": "Nous avons besoin d'autorisations supplémentaires pour installer le bot et les onglets Vacation Tracker dans Microsoft Teams. Cliquez sur le bouton « Continuer » ci-dessous pour que nous vous redirigions vers l'écran des autorisations Microsoft.",
    "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyDescription": "Veuillez installer le bot et les onglets Vacation Tracker manuellement en suivant la procédure décrite dans <helpdesk>cet article du Centre d'assistance</helpdesk>. La configuration manuelle ne nécessite normalement aucun droit d'administrateur, ni aucune aide de votre administrateur. Si vous avez besoin d'aide, contactez notre <support>service d'assistance</support>.",
    "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyTitle": "Vous devez installer l'intégration Microsoft manuellement.",
    "microsoftSass.createCompany.setupMsBot.notAdmin.weNeedAdminConsent": "L'autorisation de votre administrateur est requise pour configurer le bot et les onglets. Vous ne pourrez envoyer de message de bienvenue qu'après l'installation du bot.",
    "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabs": "Sélectionner le canal Microsoft Teams où installer les onglets",
    "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabsPlaceholder": "Sélectionner le canal Microsoft Teams",
    "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBot": "Sélectionner l'équipe Microsoft Teams où installer le bot",
    "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBotPlaceholder": "Sélectionner l'équipe Microsoft Teams",
    "microsoftSass.createCompany.setupMsBotAndTabs": "CONFIGURER LE BOT ET LES ONGLETS VACATION TRACKER",
    "microsoftSass.createCompany.setupMsBotAndTabsInfo": "Installons à présent le bot et les onglets Vacation Tracker pour Microsoft Teams !",
    "microsoftSass.createCompany.stepAssignLicenses": "2. Attribuer des licences",
    "microsoftSass.createCompany.stepCreateComapny": "1. Finaliser la configuration de l'entreprise",
    "microsoftSass.createCompany.stepSetupBotAndTabs": "3. Configurer le bot et les onglets Microsoft Teams",
    "microsoftSass.createCompany.welcomeMessage": "Bienvenue dans Vacation Tracker ! Merci d'avoir acheté <bold>{totalLicenses} licences</bold>.",
    "microsoftSass.createCompany.welcomeTitle": "CONFIGURONS VACATION TRACKER",
    "microsoftSubscription.adminInitialContent": "Votre abonnement est arrivé à expiration. Veuillez accéder au magasin Microsoft AppSource pour le réactiver. Si vous avez des questions ou besoin d'aide, contactez l'assistance.",
    "microsoftSubscription.goToAppSource": "Acheter un nouvel abonnement",
    "myProfile.leaveHistory": "Historique des congés",
    "myProfile.openMyProfile": "Consulter mon profil",
    "notifications.frequency": "Fréquence",
    "notifications.update": "Modifier la notification",
    "notifications.weekStartsOn": "Premier jour de travail",
    "scheduledReports.add": "Nouveau rapport",
    "scheduledReports.reportSentSuccessfully": "Votre rapport est parti ! 🚀",
    "sidebar.onboarding": "Intégration",
    "signInAsUser.codeIsRequired": "Veuillez renseigner un code",
    "signInAsUser.email": "Saisissez votre adresse e-mail",
    "signInAsUser.emailIsRequired": "Veuillez renseigner une adresse e-mail valide",
    "signInAsUser.enterTheCode": "Saisissez le code",
    "signInAsUser.userId": "Saisissez un ID utilisateur",
    "signInAsUser.userIdIsRequired": "Veuillez renseigner un ID utilisateur",
    "subscriptions.switchPlanTitle": "Passer à l'offre <strong>{plan}</strong>",
    "user.employeeId": "ID de l'employé",
    "users.employmentDate": "Date de prise de fonction de l'utilisateur",
    "users.importedSuccessfully": "Les utilisateurs ont bien été importés",
    "app.day": "jour",
    "app.days": "jours",
    "app.noLeaves": "Aucun congé",
    "app.nonWorkingDay": "Jour de repos",
    "app.unknown": "<Unknown>",
    "components.addHolidayForm.editHoliday": "Modifier le jour férié",
    "components.heatmap.clickForMore": "Cliquez pour en savoir plus",
    "components.onboarding.assignLicensesTitle": "<icon></icon> Attribuer des licences",
    "components.userLeaveQuotas.ofDefaultDays": "sur {value} jours",
    "dashboard.noScheduledLeavesHolidays": "Aucun congé planifié ni jour férié",
    "holidays.holidaysTab": "Jours fériés en {year}",
    "holidays.pastHolidays": "Jours fériés passés",
    "users.assignLicenses": "Attribuer des licences",
    "app.addAvatar": "Ajouter un avatar",
    "app.allowHourlyIncrements": "Autoriser la demande d'heures",
    "app.askMeLater": "Me demander plus tard",
    "app.avatar": "Avatar",
    "app.changePassword": "Modifier le mot de passe",
    "app.confirmNewPassword": "Confirmer le nouveau mot de passe",
    "app.confirmPassword": "Confirmer le mot de passe",
    "app.consentRequired.title": "Autorisation de l'administrateur Microsoft Teams requise",
    "app.enable": "Activer",
    "app.enterWorkEmail": "Saisissez votre adresse e-mail professionnelle",
    "app.firstAndLastName": "Prénom et nom",
    "app.hour": "heure",
    "app.hours": "heures",
    "app.leaveHistory": "Historique des congés",
    "app.loading": "Chargement…",
    "app.login": "Se connecter",
    "app.newPassword": "Nouveau mot de passe",
    "app.customDays": "Jours personnalisés",
    "app.oldPassword": "Ancien mot de passe",
    "app.password": "Mot de passe",
    "app.passwordAndSecurity": "Mot de passe et sécurité",
    "app.profilePicture": "Photo de profil",
    "app.reason": "Motif",
    "app.resendCode": "Renvoyer le code",
    "app.resetPassword": "Réinitialiser le mot de passe",
    "app.autoImport": "<Auto Import>",
    "app.upload": "Importer",
    "bot.message.approveLeave": "Approuver les congés",
    "bot.message.approver.autoApproved": "*Approbateur :*\nApprouvé automatiquement",
    "bot.message.approver": "*Approbateur :*\n<@{approverSlackId}>",
    "bot.message.deniedLeave": "Congés rejetés",
    "bot.message.feedback.haveBeenSend": "Vos commentaires ont été envoyés",
    "bot.message.leaveDate": "*Date :*\n{leaveDate}",
    "bot.message.leaveDateEdited": "*Date (modifiée) :*\n{leaveDate}",
    "bot.message.leaveDates": "*Dates :*\n{leaveDate}",
    "bot.message.leaveDatesEdited": "*Dates (modifiées) :*\n{leaveDate}",
    "bot.message.leaveRemainingDays": "Jours de congés restants",
    "bot.message.leaveRemainingDaysCurrent": "Jours de congés restants (année en cours)",
    "bot.message.leaveRemainingDaysNext": "Jours de congés restants (année prochaine)",
    "bot.message.leaveRemainingHours": "Heures de congés restantes",
    "bot.message.leaveRemainingHoursCurrent": "Heures de congés restantes (année en cours)",
    "bot.message.leaveRemainingHoursNext": "Heures de congés restantes (année prochaine)",
    "bot.message.leaveRequest.halfDay": "(demi-journée)",
    "bot.message.leaveRequest.hourly": "(heures de congés)",
    "bot.message.leaveRequest.leaveTypeName": "{leaveTypeName}",
    "bot.message.leaveRequestCreated.errorBadTime": "❌ La demande de congés n'a pas pu être envoyée car elle représente plus de 8 heures, ou car la plage horaire indiquée n'est pas valide.",
    "bot.message.leaveRequestedDays": "Jours de congés demandés",
    "bot.message.leaveRequestedDaysCurrent": "Jours de congés demandés (année en cours)",
    "bot.message.leaveRequestedDaysNext": "Jours de congés demandés (année prochaine)",
    "bot.message.leaveRequestedHours": "Heures de congés demandées",
    "bot.message.leaveRequestedHoursCurrent": "Heures de congés demandées (année en cours)",
    "bot.message.leaveRequestedHoursNext": "Heures de congés demandées (année prochaine)",
    "bot.message.leaveTypeName": "*Type de congés :*\n{leaveTypeName}",
    "bot.message.membersAway.MEMBERS_AWAY_MONTH": "Membres de l'équipe en congés ce mois",
    "bot.message.membersAway.MEMBERS_AWAY_NEXT_MONTH": "Membres de l'équipe en congés le mois prochain",
    "bot.message.membersAway.MEMBERS_AWAY_NEXT_WEEK": "Membres de l'équipe en congés la semaine prochaine",
    "bot.message.membersAway.MEMBERS_AWAY_TODAY": "Membres de l'équipe en congés aujourd'hui",
    "bot.message.membersAway.MEMBERS_AWAY_WEEK": "Membres de l'équipe en congés cette semaine",
    "bot.message.membersAway.noLeaves": "Aucun congé planifié n'est prévu pendant cette période pour votre équipe. 😎",
    "bot.message.membersAway": "Membres en congés",
    "bot.message.menageLeaves": "Gérer les congés",
    "bot.message.myStatus.leaveTypeStatusEarnedHours": "*{leaveTypeName}*\nHeures restantes : {current}\nHeures utilisées : {used}\nTotal d'heures : {earned}",
    "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearDays": "L'utilisateur demande {leaveDurationTotal} {leaveDurationTotal, plural, =1 {jour} other {journées}}, soit {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {jour} other {journées}} de plus que la limite fixée cette année pour l'utilisateur, et {overLimitNextYear} {overLimitNextYear, plural, =1 {jour} other {journées}} de plus que la limite fixée pour l'année prochaine.",
    "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearHours": "L'utilisateur demande {leaveDurationTotal} {leaveDurationTotal, plural, =1 {heure} other {heures}}, soit {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {heure} other {heures}} de plus que la limite fixée cette année pour l'utilisateur, et {overLimitNextYear} {overLimitNextYear, plural, =1 {heure} other {heures}} de plus que la limite fixée pour l'année prochaine.",
    "bot.message.notification.overAllowedLimitForCurrentYearDays": "L'utilisateur demande {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {jour} other {journées}}, soit {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {jour} other {journées}} de plus que la limite fixée cette année pour l'utilisateur.",
    "bot.message.notification.overAllowedLimitForCurrentYearHours": "L'utilisateur demande {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {heure} other {heures}}, soit {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {heure} other {heures}} de plus que la limite fixée cette année pour l'utilisateur.",
    "bot.message.notification.overAllowedLimitForFollowingYearDays": "L'utilisateur demande {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {jour} other {journées}}, soit {overLimitNextYear} {overLimitNextYear, plural, =1 {jour} other {journées}} de plus que la limite fixée l'année prochaine pour l'utilisateur.",
    "bot.message.notification.overAllowedLimitForFollowingYearHours": "L'utilisateur demande {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {heure} other {heures}}, soit {overLimitNextYear} {overLimitNextYear, plural, =1 {heure} other {heures}} de plus que la limite fixée l'année prochaine pour l'utilisateur.",
    "bot.message.requestLeave.initialHelp": "Je suis là pour vous aider à envoyer une demande de congés. 🐨 Veuillez sélectionner un type de congés, puis cliquer sur « Suivant ».",
    "bot.message.requestLeave.leaveDurationYear1And2Days": "Cela représente {year1Value} {year1Value, plural, =1 {jour} other {journées}} de congés cette année, et {year2Value} {year2Value, plural, =1 {jour} other {journées}} de congés l'année prochaine.",
    "bot.message.requestLeave.leaveDurationYear1And2Hours": "Cela représente {year1Value} {year1Value, plural, =1 {heure} other {heures}} de congés cette année, et {year2Value} {year2Value, plural, =1 {heure} other {heures}} de congés l'année prochaine.",
    "bot.message.requestLeave.leaveDurationYear1Days": "Cela représente {year1Value} {year1Value, plural, =1 {jour} other {journées}} de congés.",
    "bot.message.requestLeave.leaveDurationYear1Hours": "Cela représente {year1Value} {year1Value, plural, =1 {heure} other {heures}} de congés.",
    "bot.message.requestLeave.leaveDurationYear2Days": "Cela représente {year2Value} {year2Value, plural, =1 {jour} other {journées}} de congés pour l'année prochaine.",
    "bot.message.requestLeave.leaveDurationYear2Hours": "Cela représente {year2Value} {year2Value, plural, =1 {heure} other {heures}} de congés pour l'année prochaine.",
    "bot.message.requestLeave.selectDate.from": "*Date de début*\nVeuillez sélectionner une date de début",
    "bot.message.requestLeave.selectDate.to": "*Date de fin*\nVeuillez sélectionner une date de fin",
    "bot.message.requestLeave.selectDate.oneDay": "*Date*\nVeuillez sélectionner une date",
    "bot.message.requestLeave.selectTimeRange.from": "*Heure de début*",
    "bot.message.requestLeave.selectTimeRange.to": "*Heure de fin*",
    "bot.message.requestLeave.selectTimeRange.range": "*Plage horaire*",
    "bot.message.submittedLeaveRequest": "Bonjour, <@{userSlackId}> a envoyé la demande de congés suivante :",
    "bot.notification.leave.info.isInThePast": "sera en congé {leaveTypeText} {leavePeriodText}",
    "bot.notification.slack.approved": "✅ <@{approverName}> a approuvé cette demande. Aucune autre action n'est requise.",
    "bot.notification.slack.approverChangedDatesOfLeaveReuquest": "L'Approbateur <@{approverSlackId}> a modifié les dates de cette demande.",
    "bot.notification.slack.approverChangedLeaveReqest": "Bonjour, l'Approbateur <@{approverSlackId}> a modifié la demande de congés suivante pour <@{userSlackId}> :",
    "bot.notification.slack.approverHasDeniedThisRequest": "<@{approverSlackId}> a rejeté cette demande. Aucune autre action n'est requise.",
    "bot.notification.slack.leave.info.isInThePast": "- *{userName}* était en congés {leaveTypeText} {leavePeriodText}",
    "bot.notification.slack.requestedBy": "*Demande de :*\n<@{userSlackId}>",
    "bot.notification.slack.statusApproved": "*État :*\n✅ Approuvé",
    "bot.notification.slack.statusDenied": "*État :*\n❌ Rejeté",
    "bot.notification.slack.statusInfo": "👋 Votre statut Slack peut désormais être défini automatiquement pour ce type de congés ! Cliquez sur le bouton « Activer » pour mettre votre statut à jour dès le début de vos congés. Nous le supprimerons une fois vos congés terminés.",
    "bot.notification.slack.thisLeaveHasBeenCancelledByAppover": "Ces congés ont été annulés par un Approbateur. Vous trouverez plus d'informations ci-dessous.",
    "bot.notification.slack.userCancelledLeaveRequest": "{requesterName} semble avoir changé d'avis. Cette demande a été annulée.",
    "bot.notification.slack.yourLeaveHasBeenCancelledByAppover": "Vos congés ont été annulés par un Approbateur. Vous trouverez plus d'informations ci-dessous.",
    "bot.notification.slack.yourLeaveRequestApproved": "Votre demande a été approuvée",
    "bot.notification.slack.yourRequestHasBeenDenied": "Votre demande a été rejetée",
    "component.password.timeToHack.days": "{duration} jours",
    "component.password.timeToHack.hours": "{duration} heures",
    "component.password.timeToHack.instantly": "Instantanément",
    "component.password.timeToHack.label": "Temps nécessaire pour pirater ce mot de passe :",
    "component.password.timeToHack.minutes": "{duration} minutes",
    "component.password.timeToHack.perfect": "Plus d'une vie",
    "component.password.timeToHack.seconds": "{duration} secondes",
    "component.password.timeToHack.years": "{duration} ans",
    "component.setPassword.enterOldPassword": "Saisissez votre ancien mot de passe",
    "component.setPassword.enterPassword": "Saisissez votre mot de passe",
    "component.setPassword.tooltip.description.line1": "Les informations ci-dessus indiquent une estimation de la durée maximale requise par les pirates informatiques pour pirater votre mot de passe via une attaque par force brute. Cette estimation se base sur le tableau de <link>cet article</link> <italic></italic>indiquant la durée estimée de piratage d'une fonction de hachage PBKDF2 à l'aide d'un processeur graphique RTX 3090.",
    "component.setPassword.tooltip.description.line2": "Il existe de nombreuses méthodes pour choisir un mot de passe sécurisé. Vous pouvez utiliser un gestionnaire de mots de passe, ou éviter de réutiliser les mêmes mots de passe. Consultez <link>ce guide ludique, mais correct</link> pour vous en inspirer.",
    "component.setPassword.tooltip.title": "Temps nécessaire pour pirater ce mot de passe via une attaque par force brute",
    "component.setPassword.validation": "Le mot de passe doit contenir au moins: 8 caractères, une lettre minuscule, un chiffre et un symbole.",
    "components.inviteAndManageUsersWithEmail.canNotInviteYourself": "Vous ne pouvez pas vous inviter vous-même",
    "components.inviteAndManageUsersWithEmail.header": "Envoyer les invitations par e-mail",
    "components.inviteAndManageUsersWithEmail.userAlreadyExists": "L'adresse e-mail fournie correspond à un utilisateur déjà existant",
    "components.leaveBox.columns.daysOut": "{value} {value, plural, =1 {jour} other {journées}}",
    "components.leaveBox.columns.hoursOut": "{value} {value, plural, =1 {heure} other {heures}}",
    "components.leaveBox.hoursOut": "Cela représente {value} {value, plural, =1 {heure} other {heures}} d'absence.",
    "components.leaveForm.endTime": "Heure de fin",
    "components.leaveForm.halfDay": "{leaveTypeName} (demi-journée)",
    "components.leaveForm.hourly": "{leaveTypeName} (heures de congés)",
    "components.leaveForm.startTime": "Heure de début",
    "components.leavePolicyForm.accrualBiWeeklySummaryHours": "Les utilisateurs cumulent {amount} ({quota}/26) heures un {day} sur deux",
    "components.leavePolicyForm.accrualMonthlySummaryHours": "Les utilisateurs cumulent {amount} ({quota}/12) heures tous les {date} du mois",
    "components.leavePolicyForm.accrualSemiMonthlySummaryHours": "Les utilisateurs cumulent {amount} ({quota}/24) heures tous les {firstDate} et les {secondDate} du mois",
    "components.leavePolicyForm.hourlyIncrementsTooltip": "Autorisez les membres de votre équipe à demander à poser des heures de congés.",
    "components.leavePolicyForm.maxRolloverDaysInfoHours": "Sélectionnez le nombre d'heures pouvant être reportées sur la prochaine année civile pour ce type de congés.",
    "components.leavePolicyForm.rolloverPerYearDays": "Nombre maximal de jours",
    "components.leavePolicyForm.rolloverPerYearHours": "Nombre maximal d'heures",
    "components.leavePolicyForm.yearlyQuota": "Solde annuel de congés",
    "components.leavePolicyForm.yearlyQuotaHours": "Solde annuel de congés (heures)",
    "components.leavesColumns.days": "{value} jours",
    "components.leavesColumns.hours": "{value} heures",
    "components.logs.accrualDays": "L'utilisateur a cumulé {earned} {earned, plural, =1 {jour} other {journées}} de type {leaveTypeName} (sur un solde de {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {jour} other {journées}})",
    "components.logs.accrualHours": "L'utilisateur a cumulé {earned} {earned, plural, =1 {heure} other {heures}} de type {leaveTypeName} (sur un solde de {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {heure} other {heures}})",
    "components.logs.nonAccrualLeaveTypeDays": "L'utilisateur a reçu {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {day} other {days}} pour {period}",
    "components.logs.nonAccrualLeaveTypeHours": "L'utilisateur a reçu {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {hour} other {hours}} pour {period}",
    "components.logs.userChangedAvatar": "L'utilisateur a modifié sa photo de profil",
    "components.logs.userInvitationAccepted": "L'utilisateur a accepté l'invitation de <link>{creatorName}</link>",
    "components.logs.userLeaveTypeUpdatedQuotaDays": "<link>{creatorName}</link> a modifié le solde de jours de congés de cet utilisateur pour <b>{leaveTypeName}</b> à <quota></quota> jours",
    "components.logs.userLeaveTypeUpdatedQuotaHours": "<link>{creatorName}</link> a modifié le solde d'heures de congés de cet utilisateur pour <b>{leaveTypeName}</b> à <quota></quota> heures",
    "components.logs.userRolloverExpiredDays": "Les jours de l’année précédente ont expiré: <b>{leaveTypeName}</b> - {value} jour{s}",
    "components.logs.userRolloverExpiredHours": "Les heures de l’année précédente ont expiré: <b>{leaveTypeName}</b> - {value} heure{s}",
    "components.logs.userRolloverHours": "Heures de congés de l'année précédente reportées: <b>{leaveTypeName}</b> - {value} heure{s}",
    "components.userLeaveQuotas.accruedHours": "Heures cumulées",
    "components.userLeaveQuotas.accruedInfo1Days": "{userName} a cumulé {earnedDays} jours sur {defaultDays}",
    "components.userLeaveQuotas.accruedInfo1Hours": "{userName} a cumulé {earnedDaysInHours} heures sur {defaultDaysInHours}",
    "components.userLeaveQuotas.accruedInfo2Days": "{userName} cumulera {toAccrueDays} jours le {earnDate}",
    "components.userLeaveQuotas.accruedInfo2Hours": "{userName} cumulera {toAccrueHours} heures le {earnDate}",
    "components.userLeaveQuotas.ofDefaultHours": "sur {value} heures",
    "connect.alreadyHaveAnAccount": "Vous avez déjà un compte ?",
    "connect.connectWithWorkEmail": "Se connecter avec une adresse professionnelle",
    "connect.createAnOrganization": "Créer une organisation",
    "connect.dontHaveAnAccount": "Vous n'avez pas de compte ?",
    "connect.forgotYourPassword": "Mot de passe oublié ?",
    "connect.inputEmailPlaceholder": "Saisissez votre adresse e-mail professionnelle",
    "connect.inputPasswordPlaceholder": "Saisissez votre mot de passe",
    "connect.meta.title.signin": "Se connecter - Vacation Tracker",
    "connect.meta.title.signup": "Inscription - Vacation Tracker",
    "connect.pleaseInputValidPassword": "Veuillez saisir un mot de passe valide.",
    "connect.pleaseLogIn": "Connectez-vous",
    "connect.signUp": "S'inscrire avec une adresse e-mail professionnelle",
    "connect.signUpInfo": "S'inscrire et bénéficier de l'essai gratuit",
    "connect.signUpWithGoogle": "S'inscrire avec Google",
    "connect.signUpWithMicrosoft": "S'inscrire avec Microsoft",
    "connect.signUpWithSlack": "S'inscrire avec Slack",
    "createCompany.steps.inviteUsers.next": "Inviter {noOfUsers} utilisateur{postfix}",
    "createCompany.steps.inviteUsers.title.info": "Invitez les utilisateurs à ajouter au compte Vacation Tracker de votre organisation. Vous pourrez les modifier ultérieurement depuis le tableau de bord.",
    "createCompany.steps.inviteUsers.title": "Inviter les membres de votre équipe",
    "error.codeDeliveryFailureException": "Échec de l'envoi du code",
    "error.codeDeliveryFailureExceptionMessage": "Nous n'avons pas pu vous envoyer de code de validation. Veuillez vérifier que l'adresse e-mail saisie est valide. Le cas échéant, veuillez réessayer ultérieurement.",
    "error.codeMismatchException": "Code de validation non valide",
    "error.codeMismatchExceptionMessage": "Le code de validation fourni n'est pas valide. Veuillez réessayer ou demander l'envoi d'un nouvel e-mail.",
    "error.emailError.description": "Veuillez saisir une adresse e-mail valide",
    "error.emailError": "Adresse e-mail non valide",
    "error.expiredCodeException": "Code de validation arrivé à expiration",
    "error.expiredCodeExceptionMessage": "Le code de validation fourni n'est plus valable.",
    "error.incorrectPassword": "Mot de passe incorrect",
    "error.incorrectPasswordMessage": "Le mot de passe saisi est incorrect ou est arrivé à expiration. Veuillez réessayer (en vous assurant de déverrouiller les majuscules). Mot de passe oublié ?",
    "error.invalidFileType.description": "Vous avez importé un type de fichier non valide. Veuillez importer une image au format JPEG, PNG ou GIF.",
    "error.invalidFileType": "Type de fichier non valide",
    "error.invalidPasswordException": "Mot de passe non valide",
    "error.invalidPasswordExceptionMessage": "Veuillez saisir un mot de passe valide comportant au moins 8 caractères.",
    "error.passwordResetLimitExceededException": "Limite dépassée",
    "error.passwordResetLimitExceededExceptionMessage": "Vous avez essayé de modifier votre mot de passe un trop grand nombre de fois. Veuillez réessayer dans 15 minutes.",
    "error.samePasswordException": "Mot de passe non modifié",
    "error.samePasswordExceptionMessage": "Le nouveau mot de passe est le même que le précédent. Veuillez saisir un autre mot de passe si vous souhaitez le modifier.",
    "error.signin.inactiveUser": "Utilisateur inactif",
    "error.signup.alreadySignedUp.descriptionLine1": "Vous êtes déjà inscrit avec l'adresse e-mail suivante : <text>{ email }</text>. Veuillez vous connecter.",
    "error.signup.alreadySignedUp.descriptionLine2": "Si vous avez oublié votre mot de passe, cliquez sur le bouton ci-dessous pour le réinitialiser.",
    "error.signup.alreadySignedUp.title": "Vous êtes déjà inscrit",
    "error.signup.alreadySignedUpInactive.descriptionLine1": "Vous êtes déjà inscrit avec l'adresse e-mail suivante : <text>{ email }</text>.",
    "error.signup.alreadySignedUpInactive.descriptionLine2": "Cependant, votre compte est inactif. Veuillez contacter notre service d'assistance pour en savoir plus, en indiquant les informations suivantes :",
    "error.userNotFound": "Utilisateur introuvable",
    "error.userSignup.invitationAccepted.description": "Le code d'invitation a déjà été accepté, veuillez vous connecter.",
    "error.userSignup.invitationAccepted.title": "Code d'invitation déjà accepté",
    "error.userSignup.invitationExpired.description": "Le code d'invitation est arrivé à expiration. Veuillez demander à votre administrateur de renvoyer l'invitation.",
    "error.userSignup.invitationExpired.title": "Code d'invitation arrivé à expiration",
    "error.userSignup.invitationNotFound.description": "Veuillez vérifier que le lien d'invitation est correct, ou demandez à votre administrateur de renvoyer l'invitation.",
    "error.userSignup.invitationNotFound.title": "Invitation introuvable",
    "form.confirmPasswordInfo": "Veuillez confirmer votre mot de passe.",
    "form.passwordDoNotMatch": "Les deux mots de passe saisis ne correspondent pas.",
    "form.userNameRequired": "Veuillez saisir votre nom",
    "general.companyId": "ID de l'entreprise :",
    "general.days": "Jours",
    "general.hourlyLeaveAccounting": "Suivi des congés en",
    "general.hourlyLeaveAccountingTooltip": "Activez cette option pour afficher la durée de tous les congés et soldes de congés en heures (disponible uniquement pour l'offre Complete).",
    "general.hours": "Heures",
    "leaveRequests.hoursRemainingForCurrentYear": "Heures restantes cette année",
    "leaveRequests.hoursRemainingForNextYear": "Heures restantes pour l'année prochaine",
    "leaveRequests.hoursRequestedForCurrentYear": "Heures demandées cette année",
    "leaveRequests.hoursRequestedForNextYear": "Heures demandées l'année prochaine",
    "locations.leavePolicies.quotaDays": "{value} jours",
    "locations.leavePolicies.quotaHours": "{value} heures",
    "modal.dontHaveAnAccount.accountNotFound": "Compte introuvable",
    "modal.dontHaveAnAccount.asCompany": "En tant qu'entreprise",
    "modal.dontHaveAnAccount.asCompanyDescription": "Vous êtes un administrateur ou un chef d'entreprise qui souhaite essayer Vacation Tracker.",
    "modal.dontHaveAnAccount.asEmployee": "En tant qu'employé",
    "modal.dontHaveAnAccount.asEmployeeDescription": "Votre entreprise utilise Vacation Tracker, mais vous n'y avez pas accès.",
    "modal.dontHaveAnAccount.employeeSignUp": "Inscription pour les employés",
    "modal.dontHaveAnAccount.employeeSignupDescription": "Si votre entreprise utilise déjà Vacation Tracker, vous n'avez pas besoin de vous inscrire ! Demandez à votre administrateur d'activer votre compte pour bénéficier de tout ce que le tableau de bord et les intégrations Vacation Tracker ont à offrir.",
    "modal.dontHaveAnAccount.employeeSignupSupport": "Si vous avez besoin d'aide ou si vous ne savez pas qui est votre administrateur, n'hésitez pas à contacter notre service d'assistance.",
    "modal.dontHaveAnAccount.introParagraph": "Vous ne semblez pas avoir de compte. Comment souhaitez-vous utiliser Vacation Tracker ?",
    "modal.forgotPassword.step2Intro": "Nous vous avons envoyé un code par e-mail. Veuillez le saisir ci-dessous, puis définir un nouveau mot de passe.",
    "myProfile.edit.title": "Modifier mon profil",
    "notification.codeResent": "Code de validation renvoyé",
    "notification.codeResentMessage": "Veuillez vérifier vos e-mails.",
    "notification.passwordReset": "Mot de passe réinitialisé",
    "notification.passwordResetMessage": "Vous pouvez désormais vous connecter à l'aide de votre nouveau mot de passe.",
    "overlapInfo.moreUsersAreOnLeaves": "ℹ️ {overlapperNames} semblent être en congés lors de la même période.",
    "overlapInfo.youCanSafelyAcceptTheRequest": "Vous pouvez accepter cette demande sans crainte, personne parmi {teamName} n'a prévu de congés lors de cette période.",
    "user.invitationDeletedTitle": "Invitation supprimée",
    "user.invitationSuccessTitle": "Invitations envoyées",
    "user.signup.intro": "{invitedByName} vous a invité à rejoindre son organisation Vacation Tracker. Veuillez vous inscrire en cliquant ci-dessous pour accepter l'invitation et accéder au tableau de bord Vacation Tracker.",
    "user.signup.introHelpdesk": "Si vous avez des questions ou besoin d'aide, contactez notre service d'assistance ou consultez notre <helpdesk>Centre d'assistance</helpdesk>.",
    "user.signUp": "S'inscrire",
    "users.deleteInvite": "Supprimer l'invitation",
    "users.deleteInviteConfirm": "Voulez-vous vraiment supprimer l'invitation envoyée à {email} ?",
    "users.invitations": "Invitations",
    "users.inviteUsers": "Inviter des utilisateurs",
    "users.reinvite": "Réinviter",
    "users.reInviteConfirm": "Voulez-vous vraiment réinviter {email} ?",
    "importUsers.form.searchUsersNotFound": "Utilisateur introuvable ou déjà importé",
    "app.dashboard": "Tableau de bord",
    "app.leaveRequests": "Demandes de congés",
    "components.onboarding.joyride.admin.assignLeavePolicy.info1": "Comme nous l'avons déjà mentionné, les types de congé sont créés pour l'ensemble de l'organisation. Les politiques de congé sont liées aux sites. Chaque type de congé peut être défini pour chaque site en lui attribuant une politique de congé personnalisée.",
    "components.onboarding.joyride.admin.assignLeavePolicy.info2": "Après que vous avez sélectionné le type de congé que vous souhaitez ajouter à l'emplacement, vous pouvez attribuer la politique de congé. Vous avez la possibilité de copier les paramètres d'une politique de congé existante ou de créer une politique de congé entièrement nouvelle avec de nouvelles valeurs.",
    "components.onboarding.joyride.admin.assignLeavePolicy.title": "Assigner des politiques de congé",
    "components.onboarding.joyride.admin.createLocation.info1": "Si les membres de votre équipe se trouvent à différents sites, vous avez la possibilité de créer plusieurs emplacements dans Vacation Tracker et d'ajouter des utilisateurs à l'emplacement correspondant.",
    "components.onboarding.joyride.admin.createLocation.info2": "Chaque site a ses propres politiques en matière de congés que vous devez affecter à un certain type de congé. Les jours fériés sont également liés aux sites.",
    "components.onboarding.joyride.admin.createLocation.title": "Créer un Site",
    "components.onboarding.joyride.admin.createNotifications.info1": "Maintenant que vous avez terminé les étapes mentionnées ci-dessus, il est temps de créer des notifications.",
    "components.onboarding.joyride.admin.createNotifications.title": "Créer et modifier des notifications",
    "components.onboarding.joyride.admin.createTeams.info1": "Après que vous avez terminé, le tableau de bord vous donne une vue d'ensemble des congés actuels et prévus dans votre organisation. Comme mentionné ci-dessus, il est temps de créer des Départements.",
    "components.onboarding.joyride.admin.createTeams.info2": "Lorsque vous créez un département, vous devez ajouter les Approbateurs. Les Approbateurs ont le deuxième niveau d'autorisation le plus élevé dans Vacation Tracker, et peuvent approuver/refuser les demandes de congé envoyées par les utilisateurs du service dont ils sont les Approbateurs.",
    "components.onboarding.joyride.admin.createTeams.title": "Créer des départements et affecter des Approbateurs",
    "components.onboarding.joyride.admin.helpDesk.info": "Si vous avez des questions ou si vous souhaitez obtenir plus d'informations, consultez notre service d'assistance ou lancez une discussion avec notre équipe d'assistance à la clientèle.",
    "components.onboarding.joyride.admin.helpDesk.title": "Bureau d'assistance",
    "components.onboarding.joyride.admin.importHolidays.info1": "Dans Vacation Tracker, les jours fériés sont définis pour chaque site, et non pour l'ensemble de l'organisation.",
    "components.onboarding.joyride.admin.importHolidays.info2": "Vous avez la possibilité d'importer des jours fériés à partir de notre liste de jours fériés prédéfinis pour chaque pays, et la possibilité d'importer un jour férié manuellement.",
    "components.onboarding.joyride.admin.importHolidays.title": "Jours fériés à l'importation",
    "components.onboarding.joyride.admin.leaveTypesMenu.info1": "Nous vous recommandons toujours de commencer votre parcours dans Vacation Tracker par la création de types de congé. Vous attribuerez ces types de congé plus tard, lorsque vous créerez tous les sites où votre entreprise a des employés.",
    "components.onboarding.joyride.admin.leaveTypesMenu.info2": "Visitez notre Helpdesk pour un guide pas à pas sur la façon de créer et de modifier les types de congé.",
    "components.onboarding.joyride.admin.leaveTypesMenu.title": "Créer des types de congés",
    "components.onboarding.joyride.admin.newLeaveType.info1": "En plus des types de congé par défaut, vous pouvez créer jusqu'à 24 autres types de congé.",
    "components.onboarding.joyride.admin.start.title": "Découvrez Vacation Tracker! 🏖️",
    "components.onboarding.joyride.admin.start1": "Pour vous aider à démarrer, nous vous recommandons de suivre quelques étapes courtes pour mettre rapidement en place votre organisation.",
    "components.onboarding.joyride.admin.start2": "Si vous avez besoin de notre aide en cours de route, vous pouvez nous contacter par courriel à l'adresse hello@vacationtracker.io",
    "components.onboarding.joyride.admin.teams.cta1": "Installer le robot Microsoft Teams",
    "components.onboarding.joyride.admin.teams.cta2": "En savoir plus dans notre Helpdesk",
    "components.onboarding.joyride.admin.teams.info1": "Vous êtes prêt pour le tableau de bord. La dernière étape consiste à installer le robot Microsoft Teams pour que votre équipe puisse faire des demandes directement dans Microsoft Teams.",
    "components.onboarding.joyride.admin.teams.title": "Microsoft Teams",
    "components.onboarding.joyride.approver.dashboard.info1": "Cette page est votre contrôle de mission. Elle vous donne un aperçu des congés en cours, des personnes en congé aujourd'hui et des congés à venir pour votre organisation.",
    "components.onboarding.joyride.approver.leaveRequests.info1": "En tant qu'approbateur, vous pouvez \"Ajouter un congé\" pour tout membre de l'équipe dont vous êtes l'approbateur. Vous pouvez également \"demander un congé\" pour vous-même, qui sera envoyé pour approbation à votre approbateur.",
    "components.onboarding.joyride.approver.notifications.info1": "Cette page offre un aperçu de toutes les notifications envoyées dans votre organisation.",
    "components.onboarding.joyride.approver.notifications.info2": "Vous pouvez créer ou modifier des notifications pour les utilisateurs des départements dont vous êtes l'approbateur.",
    "components.onboarding.joyride.approver.notifications.info3": "Notez que vous ne pouvez gérer que les notifications que vous avez créées.",
    "components.onboarding.joyride.approver.users.info1": "La page \"Utilisateurs\" vous donne un aperçu complet de tous les utilisateurs actifs au sein de votre département, ainsi qu'un accès en lecture seule à leurs profils d'utilisateur. Leurs demandes en cours sont disponibles sur la page \"Tableau de bord\".",
    "components.onboarding.joyride.user.calendar.info1": "Sur cette page, vous pouvez voir l'historique et les prochains congés de votre organisation sous forme de calendrier.",
    "components.onboarding.joyride.user.dashboard.info1": "Le tableau de bord vous donne un aperçu des congés actuels et futurs au sein de votre organisation.",
    "components.onboarding.joyride.user.holidays.info1": "Ici, vous pouvez passer en revue les jours fériés de votre lieu de travail, afin de vous assurer que vous savez quels jours vous êtes déjà en congé.",
    "components.onboarding.joyride.user.myProfile.info1": "Vous pouvez y voir vos types de congés disponibles, vos quotas, vos jours de travail et l'historique de vos congés.",
    "components.onboarding.joyride.user.requestLeave.info1": "Pour demander un congé via le tableau de bord du Vacation Tracker, sélectionnez l'un des types de congé disponibles, ajoutez la période et le motif si nécessaire, puis soumettez la demande. La demande est ensuite envoyée à l'approbateur pour approbation.",
    "components.onboarding.joyride.user.start1": "Pour vous aider à débuter, nous avons établi une liste de cinq étapes que nous vous recommandons de suivre pour mettre rapidement en place votre organisation.",
    "app.approvalReason": "Raison de l'approbation",
    "app.denialReason": "Raison de refus",
    "app.processingRequest": "Traitement de la demande...",
    "containers.topbar.profile.info": "Vous pouvez accéder à votre profil, changer de thème et démarrer la visite rapide à partir de ce nouveau menu.",
    "components.pricePlanCard.displayLeaveInformationInDaysHours": "Afficher les informations sur les congés en jours/heures",
    "components.pricePlanCard.requestHourlyLeaveIncrements": "Demander des incréments de congé horaire",
    "app.year": "An",
    "components.addHolidayForm.date.error": "Veuillez sélectionner une date",
    "components.addHolidayForm.endDate.error": "Veuillez sélectionner la date de fin",
    "components.addHolidayForm.holidaysName.error": "Veuillez entrer un nom de vacances",
    "components.addHolidayForm.startDate.error": "Veuillez sélectionner la date de début",
    "app.cancelled": "Annulé",
    "app.deleted": "Supprimé",
    "app.pending": "En attente",
    "actionNotifications.scheduledReport.viewScheduledReport": "Afficher le rapport programmé",
    "app.friday.short": "ven",
    "app.monday.short": "lun",
    "app.saturday.short": "sam",
    "app.sunday.short": "dim",
    "app.thursday.short": "jeu",
    "app.tuesday.short": "mar",
    "app.wednesday.short": "mer",
    "app.invite": "Inviter",
    "app.displayDaysHoursTooltip.days": "Cela représente {value} jours",
    "app.displayDaysHoursTooltip.hours": "Cela représente {value} heures",
    "app.of": "sur",
    "bot.message.leaveRemaining": "Congés restants",
    "bot.message.leaveRemainingCurrent": "Congés restants (année en cours)",
    "bot.message.leaveRemainingNext": "Congés restants (année prochaine)",
    "bot.message.leaveRequested": "Congés demandés",
    "bot.message.leaveRequestedCurrent": "Congés demandés (année en cours)",
    "bot.message.leaveRequestedNext": "Congés demandés (année prochaine)",
    "bot.message.myStatus.leaveTypeStatusEarned": "*{leaveTypeName}*\nRestants : {current}\nUtilisés : {used}\nTotal : {earned}",
    "components.leaveBox.formattedLeaveInfo": "Cela représente {leave} d'absence.",
    "components.logs.accrualDisplay": "L'utilisateur a cumulé {earned} de type {leaveTypeName} (sur un solde de {defaultDaysPerYear})",
    "components.logs.userRolloverDisplay": "Congés de l'année précédente reportés ({leaveTypeName}) : {value}",
    "dashboard.daysSmallCase": "jour(s)",
    "dashboard.daysSmallCaseSingle": "j",
    "dashboard.hour": "heure(s)",
    "dashboard.hourSingle": "h",
    "leaveRequests.remainingCurrentYear": "Congés restants pour l'année en cours",
    "leaveRequests.remainingNextYear": "Congés restants pour l'année prochaine",
    "leaveRequests.requestedCurrentYear": "Congés demandés pour l'année en cours",
    "leaveRequests.requestedNextYear": "Congés demandés pour l'année prochaine",
    "bot.notification.approverCard.cancelledLeave": "Bonjour, {requesterName} a annulé ses congés.",
    "bot.message.notSignedUpError.slack": "Il semble que vous ne soyez pas inscrit au Vacation Tracker. Veuillez cliquer sur le bouton ci-dessous pour créer un compte. Ca prendra moins d'une minute !",
    "app.full-day": "Journée Complète",
    "app.half-day": "Demi-journée",
    "app.one-hour": "1 Heure",
    "app.shortestLeaveDuration": "Duration congée minimale",
    "bot.message.error.endTimeCantBeBeforeStartTime": "L'heure de début que vous avez sélectionnée soit après l'heure de fin.\n*Veuillez sélectionner une heure de début avant l'heure de fin.",
    "bot.message.error.userEndDateInFuture": "Les dates demandées sont après la date de fin d'emploi de l'utilisateur*.\n *Pour plus d'informations, veuillez contacter l'administrateur de votre organisation ou l'équipe de Vacation Tracker pour plus d'infromations.",
    "bot.message.error.userStartDateInFuture": "Les dates demandées précèdent la date de prise de fonction de l'utilisateur. Pour en savoir plus, contacter l'Administrateur de votre organisation ou l'assistance Vacation Tracker.",
    "bot.message.leaveRequest.leaveTypeNamePartTime": "{leaveTypeName} (heures de congés)",
    "bot.message.requestLeave.leaveDurationYear1And2": "C'est {year1Value} de congé pour l'année currant et {year2Value} de congé pour l'année prochaine. ",
    "components.leaveForm.leaveTypeNamePartTime": "{leaveTypeName} (heures de congés)",
    "email.notification.holiday.plural": "<strong>{locationNames}</strong> Les locations seront congées pour <strong>{holidayName}</strong> {holidayPeriodText}",
    "email.notification.holiday.singular": "<strong>{locationNames}</strong> La location sera congé pour <strong>{holidayName}</strong> {holidayPeriodText}\n",
    "email.notification.title": "Notification de Congé",
    "error.endTimeCantBeBeforeStartTime": "La début de l'heure que vous avez selectionnée se situe après la fin de l'heure. Veuillez sélectionner une heure de début avant l'heure de fin",
    "error.PromoCodeDoesntExist": "Le code promotionnel {promoCode} n'existe pas.",
    "error.PromoCodeIsntValid": "Le code promotionnel {promoCode} n'est pas valide.",
    "leaveRequest.halfDay": "Demi-journée",
    "leaveRequest.hourly": "Horaire",
    "location.rolloverExpiryOnDate.extra": "Ces jours expireront à la fin de la journée.",
    "location.rolloverExpiryOnDate": "Les jours reportés expirent le",
    "notificationsForm.locale": "Langue",
    "users.sendInvitationsInLanguage": "Envoyer les invitations en",
    "app.autoDeleted": "<Suppression Automatique>",
    "connect.signInWithEmail": "Se connecter avec email",
    "connect.backToLoginWithOtherPlatofrm": "Se reconnecter avec une autre plateforme",
    "connect.signUpWithEmail": "S'inscrire avec email",
    "export.leaveBalanceReportCustomDaysInfo": "Veuillez noter que si des jours personnalisés sont sélectionnés, le rapport n'affichera que le quota de congé utilisé. Il ne sera pas possible d'exporter le quota total ou disponible.",
    "app.seeInCalendar": "Voir dans le calendrier",
    "app.reload": "Recharger",
    "bot.message.error.oneHoureIntervalNotAllowed": "L'intervalle de temps le plus court que vous pouvez demander pour ce type de congé est de 4 heures (demi-journée).",
    "components.cancelSubscriptionModal.weSiwtchedToAnHrSolution": "Nous avons changé à un logiciel RH",
    "components.cancelSubscriptionModal.anotherLeaveTrackingTool": "Nous avons trouvé un autre outil de suivi des congés",
    "components.cancelSubscriptionModal.missingFeatures": "L'outil n'a pas les fonctionnalités dont mon équipe a besoin",
    "components.cancelSubscriptionModal.priceOverBudget": "Le prix est au dessus de notre budget.",
    "components.cancelSubscriptionModal.other": "Autre",
    "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfo": "Pouvez-vous nous dire vers quel système RH vous allez changer, s'il vous plaît?",
    "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfoIntegration": "Si nous avions une intégration avec ce système RH, resteriez-vous avec nous?",
    "components.cancelSubscriptionModal.anotherLeaveTrackingToolAdditionalInfo": "Pouvez-vous nous dire vers quel outil vous allez changer, s'il vous plaît?",
    "components.cancelSubscriptionModal.missingFeaturesAdditionalInfo": "Pouvez-vous nous dire en quelques mots quelle fonctionnalité nous manque?",
    "components.cancelSubscriptionModal.priceOverBudgetAdditionalInfo": "Quel est votre budget pour un outil comme celui-ci?",
    "components.cancelSubscriptionModal.otherAdditionalInfo": "Autre",
    "components.cancelSubscriptionModal.min20characters": "20 caractères minimum",
    "error.leaveRequestsLoadingError.description": "Vos congés existent, mais nous ne pouvons pas les charger en raison d'un problème technique de notre côté. Veuillez essayer de recharger la page. Si le problème persiste, veuillez contacter le service client.",
    "error.leaveRequestsLoadingError.title": "Impossible de charger les congés",
    "error.oneHoureIntervalNotAllowed": "L'intervalle de temps le plus court que vous pouvez demander pour ce type de congé est de 4 heures (demi-journée).",
    "error.slack.logInWithDifferentAccount": "Connectez-vous avec un autre compte",
    "error.slack.wrongAccount": "Compte Slack incorrecte ",
    "errors.slack.wrongSlackAccountDescription": "Il semble que vous êtes connecté avec un autre compte Slack.",
    "general.calculationStartYear": "Vacation Tracker suit vos congés depuis",
    "general.calculationStartYearTooltipLine1": "Vous devez modifier ce paramètre seulement si vous souhaitez importer des congés historiques d'un autre système ou de feuilles de calcul et inclure ces congés dans les calculs de Vacation Tracker.",
    "general.calculationStartYearTooltipLine2": "Vacation Tracker calcule les congés à partir de l'année de votre inscription. Cela veut dire que nous calculerons automatiquement les jours restants et avancés en fonction de vos paramètres, et vous pourrez définir les jours avancés de votre système précédent pour chaque utilisateur sur leurs pages de profil. Cependant, si vous souhaitez que Vacation Tracker calcule les congés des années passées, vous pouvez modifier cette date. N'hésitez pas à <support>contacter notre support</support> pour plus d'informations.",
    "overlapInfo.youCanSafelyAcceptTheRequestAutoApproved": "Personne d'autre de {teamName} n'est en congé pendant ce temps.",
    "app.announcementsHaveBeenPaused": "Vos annonces sont en pause depuis 2 mois.",
    "app.learnMore": "Apprendre encore plus",
    "app.reset": "Réinitialiser",
    "app.snoozeForMonths": "Taire pendant 2 mois",
    "bot.message.error.partTimeIsntAllowed": "Le temps partiel n'est pas autorisé",
    "bot.microsoft.whatsNewInfoMessages": "Ce message est une annonce de **Vacation Tracker**. Vous le recevez parce que vous êtes un administrateur ou un approbateur.",
    "bot.slack.whatsNewInfoMessages": "Ce message est une annonce de **Vacation Tracker**. Vous le recevez parce que vous êtes un administrateur ou un approbateur.",
    "bot.somethingNewFromVacationTracker": "Quelque chose de nouveau de Vacation Tracker",
    "components.whatsNew.newMessage": "Nouveau message",
    "components.whatsNew.whatsNew": "Quoi de neuf",
    "connect.forgotYourPasswordWhatsYourEmail": "Quel est votre email?",
    "containers.topbar.whatsNewUnread": "Quoi de neuf - {unread} non lu",
    "error.partTimeIsntAllowed": "Le temps partiel n'est pas autorisé",
    "notifications.emptyViewButton": "Créer une notification quotidienne ou hebdomadaire",
    "notifications.emptyViewMessage": "en envoyant des notifications automatisées sur les congés à venir",
    "notifications.emptyViewTitle": "Gagnez visibilité et tenez votre équipe informée",
    "notifications.emptyViewVideoLink": "Pour plus d'information, consultez notre <link>Service D'assistance</link> ou regardez la vidéo ci-dessous:",
    "app.maybe": "Peut-être",
    "app.trialExtend": "Avez-vous besoin d'une extension d'essai?",
    "components.cancelSubscriptionModal.weAreMovingToPayroll": "Nous changeons à un logiciel de paie",
    "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfo": "Pouvez-vous nous dire vers quel logiciel de paie vous changez s'il vous plaît?",
    "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfoIntegration": "Si nous avions une intégration avec ce logiciel de paie, envisageriez-vous de rester?",
    "app.answerNO": "Non",
    "app.answerYES": "Oui",
    "app.companyLogs": "Journaux de l'entreprise",
    "app.load": "Charger",
    "app.loadMore": "Charger plus",
    "app.notSet": "Non-établi",
    "components.addHolidayForm.addHolidays.isHalfDayHoliday": "Demi-journée de vacances",
    "components.companyLogs.dateRangeLimit": "Les journaux de plus de 3 mois ne sont disponibles que dans le plan complet.",
    "components.companyLogs.rangeLimit": "La plage de dates peut être d'un an maximum",
    "components.feedbackModal.button": "Donnez-nous vos commentaires",
    "components.feedbackModal.emailPlaceholder": "Quelle est votre adresse e-mail?",
    "components.feedbackModal.feedbackForm": "Formulaire de rétroaction",
    "components.feedbackModal.feedbackMessage": "Message de rétroaction",
    "components.feedbackModal.howDoYouLikeApp": "Que pensez-vous de notre application?",
    "components.feedbackModal.howDoYouLikeAppPlaceholder": "Choisissez une option...",
    "components.feedbackModal.iLikeIt": "Je l'aime",
    "components.feedbackModal.iLoveIt": "Je l'adore",
    "components.feedbackModal.ItMakesMeCry": "Ça me fait pleurer",
    "components.feedbackModal.itsOk": "C'est bien",
    "components.feedbackModal.messagePlaceholder": "Votre message de rétroaction",
    "components.feedbackModal.Nah": "Nah",
    "components.feedbackModal.ratingRequired": "Veuillez sélectionner l'une des évaluations données",
    "components.feedbackModal.sendFeedback": "Envoyer des commentaires",
    "components.feedbackModal.yourEmail": "Votre adresse e-mail",
    "components.feedbackModal.yourEmailInfo": "Si votre commentaire nécessite une réponse, veuillez entrer votre email afin que nous puissions vous contacter.",
    "components.leavesColumns.halfDayHoliday": "(Demi-journée de vacances)",
    "components.logs.billingCancelled": "Facturation annulée sur {paymentProcessor}",
    "components.logs.billingUpdated": "Facturation mise à jour sur {paymentProcessor}",
    "components.logs.companyCreated": "Entreprise <b>{name}</b> créée",
    "components.logs.companySettingsUpdated": "{user} a mis à jour les paramètres de l'entreprise.<br></br>Annoncer de nouveaux utilisateurs: {announceNewUsers}.<br></br>Comptabilisation des congés par heure: {hourlyLeaveAccounting}.<br></br>Date de fin de l’utilisateur: {userEndDate}.<br></br>Année de début du calcul: {calculationStartYear}",
    "components.logs.failedToLoadLog": "Impossible de charger le journal",
    "components.logs.importUsers": "<b>{user} a importé {totalUsers} {totalUsers, pluriel, =1 {User} autres {Users}}.",
    "components.logs.leavePolicyDisabled": "{user} a désactivé la politique de congé pour le type de congé <b>{leaveTypeName}</b> sur le site <b>{locationName}</b>",
    "components.logs.leavePolicyEnabled": "{user} a activé la politique de congé <b>{leaveTypeName}</b> pour le site <b>{locationName}</b>.<br></br>Jours par an: {daysPerYear}.<br></br>Nombre maximal de jours reportés: {maxRolloverDays}.<br></br>Les jours reportés doivent-ils expirer: {enableRolloverExpiry}.<br></br>Expiration des jours reportés le: {rolloverExpiryDate}.<br></br>Les jours reportés expirent après: {rolloverExpiryAfterDays} jours.<br></br>Approbation requise: {isApprovalRequired}.<br></br>Jours illimités: {hasUnlimitedDays}.<br></br>Duration congée minimale: {shortestLeaveInterval}.<br></br>Motif requis: {isReasonRequired}.<br></br>Cumul de congés: {accrualType}.<br></br>Solde négatif autorisé: {negativeBallanceAllowed}.<br></br>Autoriser la demande et la modification de congés dans le passé: {allowLeaveInPast}.<br></br>Masquer le type de congé: {hideLeaveType}\"",
    "components.logs.leaveTypeCreated": "{user} a créé le type de licence <b>{name}</b> et l’a attribué sur les sites: {locations}.<br></br>Couleur: {color}.<br></br>Jours par an: {daysPerYear}.<br></br>Nombre maximum de jours de transfert: {maxRolloverDays}.<br></br>Approbation requise: {isApprovalRequired}.<br></br>Jours illimités: {hasUnlimitedDays}.<br></br>Duration congée minimale: {shortestLeaveInterval}.<br></br>Motif requis: {isReasonRequired}.<br></br>Type de cumul de congés: {accrualType}.<br></br>Autoriser les soldes négatifs: {negativeBallanceAllowed}.<br></br>Permettre des demandes de congés passées: {allowLeaveInPast}.<br></br>Masquer le type de congé: {hideLeaveType}",
    "components.logs.leaveTypeDeleted": "{user} a supprimé le type de congé <b>{name}</b>",
    "components.logs.leaveTypeUpdated": "{user} a mis à jour le type de congé <b>{name}</b>.<br></br>Actif: {isActive}.<br></br>Couleur: {color}",
    "components.logs.locationCreated": "{user} a créé le site <b>{name}</b>.<br></br>Mois de début de l’année: {yearStartMonth}.<br></br>Jour de début de l’année: {yearStartDay}.<br></br>Site par défaut: {isDefault}.<br></br>Premier jour de travail: {firstDayOfWeek}.<br></br>Réinitialisation du solde annuel de congés: {resetQuotas}.<br></br>Mois d'expiration des jours d'avance: {rolloverExpiryMonth}.<br></br>Jour d'expiration des jours d'avance: {rolloverExpiryDay}.<br></br>Les jours d'avance expirent après jours: {rolloverExpiryAfterDays}.<br></br>Fuseau horaire: {timezone}.<br></br>Utilisateurs: {users}.<br></br>Jours: {days}",
    "components.logs.locationDeleted": "{user} a supprimé le site",
    "components.logs.locationUpdated": "{user} a mis à jour le site <b>{name}</b>.<br></br>Mois de début de l’année: {yearStartMonth}.<br></br>Jour de début de l’année: {yearStartDay}.<br></br>Site par défaut: {isDefault}.<br></br>Premier jour de travail: {firstDayOfWeek}.<br></br>Réinitialisation du solde annuel de congés: {resetQuotas}.<br></br>Mois d'expiration des jours d'avance: {rolloverExpiryMonth}.<br></br>Jour d'expiration des jours d'avance: {rolloverExpiryDay}.<br></br>Les jours d'avance expirent après jours: {rolloverExpiryAfterDays}.<br></br>Fuseau horaire: {timezone}.<br></br>Utilisateurs: {users}",
    "components.logs.locationWorkWeekUpdated": "{user} a mis à jour la semaine de travail sur le site <b>{name}</b>.<br></br>Jours: {days}",
    "components.logs.locationYearHolidaysChanged": "{user} a modifié les jours fériés de l’année {year} sur le site <b>{name}</b>.<br></br>Jours fériés: {holidays}",
    "components.logs.locationYearRollover": "Le roulement des jours réportes s'est produit sur le site <b>{name}</b>",
    "components.logs.notificationCreated": "L'utilisateur {user} a créé la notification <b>{name}</b>.<br></br>Fréquence: {frequency}.<br></br>Activée: {enabled}.<br></br>Sites: {locations}.<br></br>Services: {teams}.<br></br>Libellés: {labels}.<br></br>Premier jour de travail: {firstDayOfWeek}.<br></br>Fuseau horaire: {timezone}.<br></br>Regrouper par {groupBy}.<br></br>Envoyer vide: {sendEmpty}.<br></br>Destinations: {destinations}.<br></br>Minute: {minute}.<br></br>Heure: {hour}.<br></br>Jour: {day}.<br></br>Langue: {locale}.<br></br>Envoyer les jours: {sendOnDays}.<br></br>Semaine en cours: {currentWeek}",
    "components.logs.notificationDeleted": "L'utilisateur {user} a supprimé la notification",
    "components.logs.notificationUpdated": "L'utilisateur {user} a mis à jour la notification <b>{name}</b>.<br></br>Fréquence: {frequency}.<br></br>Activée: {enabled}.<br></br>Sites: {locations}.<br></br>Services: {teams}.<br></br>Libellés: {labels}.<br></br>Premier jour de travail: {firstDayOfWeek}.<br></br>Fuseau horaire: {timezone}.<br></br>Regrouper par {groupBy}.<br></br>Envoyer vide: {sendEmpty}.<br></br>Destinations: {destinations}.<br></br>Minute: {minute}.<br></br>Heure: {hour}.<br></br>Jour: {day}.<br></br>Langue: {locale}.<br></br>Envoyer les jours: {sendOnDays}.<br></br>Semaine en cours: {currentWeek}",
    "components.logs.subscriptionCancelled": "{user} a annulé son abonnement.<br></br>Motif: {reason}.<br></br>Commentaire: {comment}",
    "components.logs.subscriptionChanged": "Abonnement modifié.<br></br>Nouveau plan: {plan}.<br></br>Période: {period}.<br></br>État: {status}.<br></br>Processeur de paiement: {paymentProcessor}",
    "components.logs.teamApproverStatusChanged": "{user} a défini des approbateurs {approvers} pour le service {team}",
    "components.logs.teamCreated": "{user} a créé un département <b>{team}</b>.<br></br>Département par défaut : {isDefault}.<br></br>Utilisateurs : {users}.<br></br>Approbateurs: {approvers}<br></br>Département parent : {parentDepartment}",
    "components.logs.teamDeleted": "{user} a supprimé le service",
    "components.logs.teamUpdated": "{user} a mis à jour le service <b>{team}</b>.<br></br>Service par défaut: {isDefault}.<br></br>Utilisateurs: {users}.<br></br>Approbateurs: {approvers}",
    "components.userLeaveQuotas.remainingAfterRequest": "Restant après la demande",
    "components.userLeaveQuotas.requested": "Demandé",
    "components.userLeaveQuotasCompact.title": "Votre solde de jours de congés",
    "containers.topbar.announcement": "Annonce",
    "containers.topbar.giveUsFeedback": "Donnez-nous vos commentaires",
    "containers.topbar.help": "Assistance",
    "containers.topbar.logout": "Se déconnecter",
    "error.paymentFailed": "Échec de paiement",
    "events.logs.BILLING": "Facturation",
    "events.logs.BULK_ACTIONS": "Importer des utilisateurs",
    "events.logs.COMPANY": "Entreprise",
    "events.logs.LABEL": "Libellé",
    "events.logs.LEAVE_POLICY": "Politique de congés",
    "events.logs.LEAVE_TYPE": "Type de congés",
    "events.logs.LOCATION": "Site",
    "events.logs.LOCATION_YEAR_ROLLOVER": "Le roulement des jours réportes",
    "events.logs.NOTIFICATION": "Notifications",
    "events.logs.REPORT": "Rapport",
    "events.logs.TEAM": "Service",
    "events.logs.TEAM_POLICY": "Politique de service",
    "leaveRequest.simpleQuota.info": "Voici votre solde de congés pour {leaveTypeName} de {start} - {end}.",
    "leaveRequest.simpleQuota.requestAnotherLeave": "Demander un autre congé",
    "leaveRequest.simpleQuota.summaryInfo": "Nous avons envoyé la demande suivante pour approbation à votre responsable",
    "stripe.dynamicMessage": "{message}",
    "app.halfDay": "Demi-journée",
    "app.halfDayInParenthesis": "(Demi-journée)",
    "bot.message.showHolidays.holidaysHalfDay": "*{holidayLocationName}* sera absent pour *{holidayName}* le {date} (Demi-journée).",
    "bot.notification.leavePeriod.halfDay": "Le {day} (Demi-journée)",
    "calendar.listView": "Vue liste",
    "calendar.monthView": "Vue du mois",
    "components.logs.notificationDetailsDaily": "Utilisateur {user} {action} notification <b>{name}</b>.<br></br>Fréquence: {frequency}.<br></br>Activée: {enabled}.<br></br>Sites: {locations}.<br></br>Services: {teams}.<br></br>Libellés: {labels}.<br></br>Fuseau horaire: {timezone}.<br></br>Regrouper par {groupBy}.<br></br>Envoyer vide: {sendEmpty}.<br></br>Destinations: {destinations}.<br></br>Minute: {minute}.<br></br>Heure: {hour}.<br></br>Langue: {locale}.<br></br>Envoyer les jours: {sendOnDays}.\n",
    "components.logs.notificationDetailsMonthly": "Utilisateur {user} {action} notification <b>{name}</b>.<br></br>Fréquence: {frequency}.<br></br>Activée: {enabled}.<br></br>Sites: {locations}.<br></br>Services: {teams}.<br></br>Libellés: {labels}.<br></br>Premier jour de travail: {firstDayOfWeek}.<br></br>Fuseau horaire: {timezone}.<br></br>Regrouper par {groupBy}.<br></br>Envoyer vide: {sendEmpty}.<br></br>Destinations: {destinations}.<br></br>Minute: {minute}.<br></br>Heure: {hour}.<br></br>Jour: {day}.<br></br>Langue: {locale}.<br></br>Semaine en cours: {currentWeek}",
    "components.platformSwitchModal.content": "Le processus de migration d'une plateforme à une autre nécessite l'assistance de notre équipe de support. Pour le moment, vous ne pouvez utiliser Vacation Tracker qu'avec une seule plateforme. Cependant, cela pourrait changer bientôt. En cliquant sur le bouton ci-dessous, vous enverrez un message à notre équipe de support pour vous aider à migrer vers la nouvelle {platform} plateforme.",
    "components.platformSwitchModal.title": "La migration nécessite l'assistance de notre équipe de support",
    "general.connectToAnotherPlatform": "Passer à une autre plateforme",
    "general.connectWith": "Passer à",
    "general.connectWithHelpdeskInfo": "Vous ne savez pas quelle plateforme choisir ? Cet <link>article</link> pourrait vous aider.",
    "location.holidays.brokenHolidays": "Ces jours fériés ne peuvent pas être ajoutés, veuillez contacter l'équippe de support pour résoudre le problème",
    "app.configure": "Configurer",
    "app.dontSend": "Ne pas envoyer",
    "app.message": "message",
    "app.microsoftTeams": "Microsoft Teams",
    "app.send": "envoyer",
    "app.slack": "Slack",
    "app.via": "à travers de",
    "components.billingCouponCode.activeCouponInfo": "<percentOffCover>{off}%</percentOffCover><amountOffCover>US${off}</amountOffCover> désactivé <durationCover>{duration}</durationCover><durationInMonthsCover>{duration} mois</durationInMonthsCover>, depuis {start}",
    "components.billingCouponCode.activeCouponInfoTitle": "Code Promo Actif",
    "components.configureImport.configureProratedLeaveTypesErrorMessage": "Vous devez sélectionner au moins un type de congé",
    "components.configureImport.defaultDepartmentTooltip": "Tous les utilisateurs importés seront ajoutés à ce Service. Après leur importation, vous pouvez les placer dans un autre Service.",
    "components.configureImport.defaultLocationTooltip": "Tous les utilisateurs importés seront ajoutés à ce Site. Après leur importation, vous pouvez les placer dans un autre Site.",
    "components.configureImport.guestUsers": "Voulez-vous importer des utilisateurs invités multicanaux?",
    "components.configureImport.guestUsersTooltip": "Si cette option est activée, les utilisateurs invités multicanaux de votre espace de travail seront importés dans Vacation Tracker.",
    "components.configureImport.proRateLabel": "Calculer au prorata le quota de nouveaux utilisateurs pour cette année?",
    "components.configureImport.proRateLeaveTypes": "Appliquer le prorata aux types de congés suivants",
    "components.configureImport.proRateLeaveTypesTooltip": "Veuillez indiquer les types de congés pour lesquels vous souhaitez répartir au prorata les quotas de congés disponibles pour les utilisateurs.",
    "components.configureImport.proRateTooltip": "Veuillez indiquer si vous souhaitez que notre système calcule le quota de congés disponibles des utilisateurs importés pour l'année initiale en fonction des paramètres de votre entreprise et de la date de début de l'utilisateur.",
    "components.importUsersForm.button.importUsersAutmaticallyFromPlatform": "Voulez-vous importer automatiquement tous les utilisateurs de {platform}?",
    "components.importUsersForm.calculationStartYearInfo": "Vacation Tracker calculera les congés à partir de {year}",
    "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOff": "Vacation Tracker n'importera pas automatiquement les utilisateurs invités multicanaux de votre espace de travail. Vous pouvez toujours les importer manuellement si nécessaire.",
    "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOn": "Vacation Tracker importera automatiquement les utilisateurs invités multicanaux de votre espace de travail.␣",
    "components.importUsersForm.confirmImportUsersModalContentChangeLaterInfo": "Vous pouvez modifier ces paramètres ultérieurement dans le tableau de bord Vacation Tracker",
    "components.importUsersForm.confirmImportUsersModalContentDepartmentAndLocationInfo": "Le nouvel {noOfUsers, plural, =1 {utilisateur sera ajouté} other {utilisateurs seront ajoutés}} au service {chosenDepartment} et site {chosenLocation}",
    "components.importUsersForm.confirmImportUsersModalContentNoOfUsers": "Importer {noOfUsers} {noOfUsers, plural, =1 {utilisateur} other {utilisateurs}}",
    "components.importUsersForm.confirmImportUsersModalContentOpener": "Vous avez sélectionné:",
    "components.importUsersForm.confirmImportUsersModalContentProrateInfo": "Prorata du quota d'utilisateurs pour les types de congés suivants: {leaveTypes}",
    "components.importUsersForm.confirmImportUsersModalContentProrateInfoTurnOff": "Les quotas des utilisateurs ne seront plus calculés au prorata",
    "components.importUsersForm.confirmImportUsersModalContentWelcomeMessage": "Envoyer un {channel, select, Email {} other {message}} {channel} de bienvenue aux nouveaux utilisateurs.",
    "components.importUsersForm.confirmImportUsersModalContentWelcomeMessageTurnOff": "Nous n'enverrons plus de message de bienvenue aux nouveaux utilisateurs",
    "components.importUsersForm.confirmImportUsersModalTitle": "Confirmer l'importation des {noOfUsers} utilisateurs sélectionnés",
    "components.importUsersForm.importAllUsersTooltip": "{noOfUsersToImport} {noOfUsersToImport, plural, =1 {utilisateur sera importé} other {utilisateurs seront importés}} de {platform} dans Vacation Tracker. Cela pourrait affecter votre facturation.",
    "components.importUsersForm.importUsersAutomaticallyTooltip": "Une fois qu'un utilisateur nouvellement ajouté à votre {platform} est importé dans Vacation Tracker, il recevra un court message expliquant ce qu'est Vacation Tracker et comment y naviguer.",
    "components.importUsersForm.newCompanyNameInfo": "Le nouveau nom de l'entreprise sera: {newName}",
    "components.importUsersForm.selectUsersNext": "Configurer les paramètres pour les utilisateurs sélectionnés",
    "components.importUsersForm.selectUsersStepName": "Sélectionnez les utilisateurs",
    "components.importUsersForm.sendWelcomeMessageLabel": "Envoyer un message de bienvenue aux nouveaux utilisateurs?",
    "components.importUsersForm.trackingQuotaInfoDays": "Vacation Tracker suivra la durée de tous les congés et quotas en jours.",
    "components.importUsersForm.trackingQuotaInfoHours": "Vacation Tracker suivra la durée de tous les congés et quotas en heures.",
    "components.importUsersForm.userEndDateInfo": "Les utilisateurs avec une date de fin ne pourront pas soumettre de demandes de congé après cette date, et nous les désactiverons automatiquement.",
    "components.importUsersForm.userEndDateTurnOffInfo": "Les utilisateurs avec une date de fin pourront soumettre des demandes de congé après cette date, et nous ne les désactiverons pas automatiquement.",
    "components.importUsersForm.warningAllUsersImport": "Vacation Tracker peut automatiquement synchroniser, importer et supprimer des utilisateurs de votre {platform}.",
    "components.importUsersForm.warningOfUsersImport": "L'importation de {noOfUsers} {noOfUsers, plural, =1 {utilisateur sélectionné} other {utilisateurs sélectionnés}} peut affecter votre facturation.",
    "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNewBucket": "L'ajout de {noOfUsers} {noOfUsers, plural, =1 {utilisateur} other {utilisateurs}} augmentera votre prochaine facture à {amount} (hors TVA) à partir de {startDate}.",
    "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNoOfUsers": "Des frais estimés au prorata de {proratedAmount} (hors TVA) seront appliqués aujourd'hui.",
    "components.importUsersForm.warningOfUsersImportNoChange": "Vous êtes sur le point d'importer {noOfUsers} {noOfUsers, plural, =1 {utilisateur} other {utilisateurs}}. Votre facturation n'en sera pas affectée.",
    "components.importUsersForm.warningOfUsersImportProrateAdditionalNewBucketMonthly": "L'importation de {noOfUsers} {noOfUsers, plural, =1 {utilisateur} other {utilisateurs}} affectera votre facturation. Votre prochaine facture sera de {amount} (hors TVA) le {startDate}",
    "components.userLeaveTypeForm.prorateInfo": "{user} disposera de {proratedQuota} ({preciseQuota} jours, pour être précis) pour l'année initiale ({yearPeriod}) et de {fullQuota} jours pour toutes les années à partir du {startOfNextYear}.",
    "components.userLeaveTypeForm.prorateLabel": "Prorata pour l'année initiale en fonction de la date de début de l'utilisateur ({startDate})",
    "error.google.loginRequiredTitle": "La connexion à votre compte Google est requise",
    "error.google.usersLoginRequiredDescription1": "La gestion des utilisateurs nécessite des autorisations Google. Si vous cliquez sur le bouton OK, nous vous redirigerons vers la page de connexion Google. Selon les paramètres de votre navigateur, vous êtes peut-être déjà connecté et serez automatiquement redirigé.",
    "error.google.usersLoginRequiredDescription2": "Après vous être connecté à votre compte Google, nous vous ramènerons à cette page afin que vous puissiez gérer les utilisateurs.",
    "general.announceNewUsersTooltip": "Chaque fois que vous ajoutez un nouvel utilisateur à votre {platform}, il recevra un court message de Vacation Tracker expliquant son fonctionnement. Si vous choisissez l'option Ne pas envoyer, les nouveaux utilisateurs ne recevront aucun message d'annonce de Vacation Tracker.",
    "general.confirmModalTitle": "Confirmer les mises à jour des paramètres de l'entreprise",
    "general.importUsersAutomaticallyTurnOffInfo": "Nous n'importerons et n'activerons plus automatiquement tous les nouveaux utilisateurs réguliers de {platform}.",
    "general.importUsersConfigSection": "Importer les paramètres des utilisateurs",
    "importUsers.form.searchGoogleUsersPlaceholder": "Rechercher des utilisateurs dans votre espace de travail Google",
    "importUsers.form.searchMicrosoftUsersPlaceholder": "Tapez au moins un caractère pour commencer à rechercher des utilisateurs dans votre répertoire actif de Microsoft 365",
    "importUsers.form.searchSlackUsersPlaceholder": "Rechercher des utilisateurs dans votre espace de travail Slack",
    "importUsers.form.showSelectedUsersAsNumber": "Utilisateurs sélectionnés",
    "app.full-name": "Nom complet",
    "components.importUsersForm.importAllUsersExceedingLimt": "Vous avez un grand nombre d'utilisateurs dans votre annuaire. Si vous souhaitez importer tous les utilisateurs, veuillez contacter notre équipe d'assistance et nous pourrons activer l'importation. Vous pouvez également sélectionner des utilisateurs spécifiques à importer.",
    "components.importUsersForm.importUsersAutomaticallyExceedingLimt": "Vous avez un grand nombre d'utilisateurs dans votre annuaire. Si vous souhaitez importer des utilisateurs automatiquement, veuillez contacter notre équipe d'assistance et nous pourrons activer l'importation.",
    "connect.alreadyHaveAnAccountWithLink": "Avez-vous déjà un compte? <b><link>Se connecter</link></b>",
    "registration.flowB.progress": "Étape {current} sur {total}",
    "registration.flowB.step1.list1": "<b>Essai gratuit de 7 jours</b>, toutes les fonctionnalités incluses",
    "registration.flowB.step1.list2": "Aucune carte de crédit requise pendant l'essai",
    "registration.flowB.step1.list3": "Intégration avec votre outil de collaboration",
    "registration.flowB.step1.submitButton": "Commencer",
    "registration.flowB.step1.subtitle": "Quelle est l'adresse e-mail de votre entreprise?",
    "registration.flowB.step1.title": "Rejoignez les milliers d'équipes du monde entier qui optimisent le suivi des congés avec Vacation Tracker.",
    "registration.flowB.step2.submitButton": "Créer un compte",
    "registration.flowB.step2.subtitle": "Bienvenue à Vacation Tracker",
    "registration.flowB.step3.list1": "Gérer les demandes de congé",
    "registration.flowB.step3.list2": "Envoyer des notifications des congés",
    "registration.flowB.step3.list3": "Personnaliser les types de congés",
    "registration.flowB.step3.list4": "Créer des Sites et des Services",
    "registration.flowB.step3.list5": "Générer des rapports",
    "registration.flowB.step3.list6": "Définir les cumuls de congés",
    "registration.flowB.step3.list7": "Regrouper les membres de l'équipe par libellés",
    "registration.flowB.step3.list8": "Planifier des rapports",
    "registration.flowB.step3.list9": "Demander un congé horaire",
    "registration.flowB.step3.list10": "et plus!␣",
    "registration.flowB.step3.continueWithEmailPlatform": "Continuer avec l'adresse e-mail professionnel",
    "registration.flowB.step3.continueWithPlatform": "Continuer avec {platform}",
    "registration.flowB.step3.notice": "Nous ne communiquerons en aucune façon avec votre équipe tant que vous n'êtes pas prêt.",
    "registration.flowB.step3.submitButton": "UNE DERNIÈRE ÉTAPE",
    "registration.flowB.step3.subtitle": "Avec quel outil souhaitez-vous vous intégrer, {name}?",
    "registration.flowB.step3.title": "Essai de 7 jours pour le Plan Avantage",
    "registration.flowB.step3.title2": "Vous recevrez un <b>essai gratuit</b> de notre <b>Plan Avantage</b>, qui comprend:",
    "registration.flowB.testimonial1.profession": "Productrice",
    "registration.flowB.testimonial1.title": "\"La facilité avec laquelle tout le monde a utilisé Vacation Tracker sans poser un million de questions était impressionnante!\"",
    "registration.flowB.testimonial2.profession": "PDG",
    "registration.flowB.testimonial2.title": "\"Très simple à mettre en place. Il est super simple de demander et d'approuver les congés et l'équipe est très réactive et prend le retour d'information au sérieux.\"",
    "registration.flowB.testimonial3.profession": "Directrice des Services Administratifs",
    "registration.flowB.testimonial3.title": "\"Vacation Tracker m'a rendu au moins 10 heures par semaine plus de nombreuses heures à nos managers et employés car tout est électronique.\"",
    "app.change": "Changer",
    "app.note": "Note",
    "app.wallchart": "Tableau mural",
    "wallchart.range.thisMonth": "Mois en cours",
    "wallchart.range.nextMonth": "Mois prochain",
    "wallchart.range.previousMonth": "Mois précédent",
    "wallchart.range.next4Weeks": "4 prochaines semaines",
    "wallchart.range.next5Weeks": "5 prochaines semaines",
    "calendar.halfdayTagsShort": "Congés de moins d'un jour",
    "components.importHolidaysForm.backButton": "Retour",
    "components.importHolidaysForm.selectCountry": "Choisissez un pays",
    "components.importHolidaysForm.selectHolidays": "Sélectionnez les jours fériés",
    "components.importHolidaysForm.selectYear": "Sélectionnez l'année",
    "components.importUsersForm.googleAPIErrorMessage": "Il semble qu'il y a un problème avec les paramètres de votre répertoire Google. Veuillez contacter votre administrateur et lui envoyer l'erreur suivante:",
    "components.importUsersForm.importUsersAutomaticallyExceedingLimit": "Vous avez un grand nombre d'utilisateurs dans votre répertoire. Si vous souhaitez importer des utilisateurs automatiquement, veuillez contacter notre équipe d'assistance et nous pourrons activer l'importation.",
    "components.logs.locationHolidaysAutoimportOFF": "<b>{user}</b> a désactivé l'importation automatique des jours fériés pour le site de <b>{location}</b>",
    "components.logs.locationHolidaysAutoimportON": "<b>{user}</b> a activé l'importation automatique des jours fériés pour le site de <b>{location}</b><br></br>Holidays: {holidays}",
    "holidays.filterByType": "Filtrer par type de jour férié",
    "holidays.holidaysType": "Type de jour férié",
    "holidays.importHolidaysAutomaticallyQuestion": "Voulez-vous importer automatiquement les jours fériés sélectionnés pour les années futures?",
    "holidays.importHolidaysAutomaticallyTooltip": "Vacation Tracker ajustera automatiquement les jours fériés qui ne tombent pas à la même date chaque année, comme action de grâce, pâques, etc.",
    "holidays.importHolidaysAutomaticallyTurnedOff": "Importation automatique des jours fériés désactivée",
    "holidays.importHolidaysAutomaticallyTurnedOn": "Importation automatique des jours fériés activée",
    "holidays.importHolidaysCount": "Importer {count} jours fériés sélectionnés",
    "holidays.importHolidaysFor": "Importer jours fériés de <b>{year}</b> pour <b>{countryState}</b>",
    "holidays.importHolidaysSummary": "Vous avez sélectionné les jours fériés suivants de <b>{year}</b> pour <b>{countryState}</b>",
    "holidays.totalSelectedHolidays": "Vous avez sélectionné {total} jour(s) férié(s)",
    "holidays.typeBank": "Banque",
    "holidays.typeObservance": "Observance",
    "holidays.typeOptional": "Facultatif",
    "holidays.typePublic": "Public",
    "holidays.typeSchool": "École",
    "location.holidays.autoimportTitle": "Les paramètres d'importation automatique des jours fériés ont été modifiés",
    "leaveRequestStatus.open": "ouvert",
    "leaveRequestStatus.approved": "approuvé",
    "leaveRequestStatus.denied": "refusé",
    "leaveRequestStatus.cancelled": "annulé",
    "leaveRequestStatus.expired": "expiré",
    "leaveRequestStatus.deleted": "supprimé",
    "accountSettings.connectYourPersonalCalendar.description": "Connectez votre calendrier personnel aux congés automatiquement. Lorsque vous demandez un congé, vous pouvez définir si vous souhaitez que le congé demandé s'affiche comme disponible ou occupé sur votre calendrier. Visitez notre <helpdesk>Helpdesk</helpdesk> pour plus d'informations.",
    "accountSettings.connectYourPersonalCalendar": "Connectez votre calendrier personnel",
    "app.accountSettings": "Paramètres du compte",
    "app.leaveRequestCalendarStatusLabel": "Afficher dans votre calendrier comme",
    "bot.message.requestLeave.selectCalendarStatus.info": "*Calendar status*\nAfficher dans votre calendrier comme",
    "bot.message.requestLeave.selectCalendarStatus": "Sélectionnez l'état du calendrier",
    "bot.notification.adminToApprovers": "Vous avez été désigné comme nouvel Approbateur pour {teamName}, car le compte de l'Approbateur précédent a été désactivé.",
    "calendarListModal.description": "Veuillez sélectionner le calendrier dans la liste suivante des calendriers disponibles. Après avoir connecté un calendrier, Vacation Tracker ajoutera, modifiera ou annulera vos congés dans le calendrier choisi.",
    "calendarListModal.descriptionGoogleFirstLine": "Veuillez sélectionner le calendrier dans la liste suivante des calendriers disponibles. ",
    "calendarListModal.descriptionOutlookFirstLine": "Vous ne pouvez connecter que votre calendrier par défaut.␣",
    "calendarListModal.descriptionSecondLine": "Après avoir connecté un calendrier, Vacation Tracker ajoutera, modifiera ou annulera vos congés dans le calendrier choisi.",
    "calendarListModal.hiddenLeaveTypesDescription": "En savoir plus sur <helpdesk>l'affichage des types de congé masqués</helpdesk>.",
    "calendarListModal.selectPlaceHolder": "Veuillez sélectionner un calendrier...",
    "calendarListModal.title": "Sélectionnez le calendrier",
    "components.companySettings.contactEmailInfo": "Votre nouvelle adresse e-mail de contact d'entreprise est : {email}.",
    "components.createLeaveTypeForm.calendarStatusBusy": "Occupé",
    "components.createLeaveTypeForm.calendarStatusFree": "Disponible",
    "components.createLeaveTypeForm.calendarStatusLabel": "Afficher ce type de congé dans votre calendrier comme",
    "components.leaveForm.connectedCalendarTimezone": "Le fuseau horaire du calendrier {calendarType} connecté est {timezone}\n\n",
    "connectCalendar.connect": "Se connecter avec",
    "connectCalendar.disconnect": "Se déconnecter",
    "connectCalendar.disconnectDescription": "Voulez-vous vraiment déconnecter le calendrier? Vacation Tracker ne le mettra plus à jour avec vos futurs congés",
    "connectCalendar.disconnectError": "Erreur de déconnexion du calendrier",
    "connectCalendar.disconnectErrorDescription": "Une erreur s'est produite lors de la déconnexion de votre calendrier avec Vacation Tracker. Veuillez cliquer sur Ok pour réessayer.",
    "connectCalendar.disconnectSuccess": "Vous avez déconnecté avec succès le calendrier {calendar}.",
    "connectCalendar.disconnectTitle": "Déconnecter {calendarPlatform} calendrier",
    "connectCalendar.error": "Erreur de connexion du calendrier",
    "connectCalendar.errorDescription": "Une erreur s'est produite lors de la déconnexion de votre calendrier avec Vacation Tracker. Veuillez cliquer sur Ok pour réessayer.",
    "connectCalendar.success": "Vous avez connecté avec succès le calendrier {calendar}.",
    "connectedCalendar.eventDescription": "Ajouté par <link>Vacation Tracker</link>",
    "disconnectCalendar.success": "Vous avez réussi à déconnecter le calendrier.",
    "error.fetchingConnectedCalendarInfo": "Un problème est survenu lors de la récupération de votre calendrier connecté. Veuillez réessayer. Si ce problème persiste, veuillez contacter notre service client",
    "error.goToRequestLeaveTab": "Erreur de transition avec l'onglet pour demander des congés",
    "general.pleaseEnterContactEmail": "Veuillez entrer l'adresse e-mail de l'entreprise",
    "holidays.totalSelectedHolidaysTip": "({total} non visible à cause du filtre appliqué)",
    "notifications.passwordChangedDescription": "Vous avez changé votre mot de passe avec succès, veuillez vous connecter",
    "notifications.passwordChangedTitle": "Mot de passe changé",
    "holidays.importHolidaysAutomaticallyTooltipForMissingHolidays": "Malheureusement, cette fonctionnalité n'est pas encore disponible dans ce pays. Nous travaillons à étendre nos services, alors attendez-vous à d'autres mises à jour.",
    "leaveRequests.pending": "Demande en attente",
    "connect.signInWithGoogleVerification": "Se connecter avec Google",
    "error.google.directoryApiPermissionDeniedP1": "Vacation Tracker doit pouvoir répertorier les utilisateurs de votre annuaire Google Workspace. Veuillez cliquer sur le bouton \"Se connecter avec Google\" et cocher la case comme indiqué dans l'image ci-dessous pour pouvoir vous inscrire.",
    "error.google.directoryApiPermissionDeniedP2": "Nous enregistrerons uniquement les informations suivantes pour les utilisateurs que vous sélectionnez explicitement pour l'importation: nom, photo de profil et adresse e-mail.",
    "error.google.directoryApiPermissionDeniedP3": "Si vous ne voyez pas la case à cocher lorsque vous cliquez sur le bouton \"Se connecter avec Google\", cliquez sur le bouton \"Continuer\" dans la fenêtre modale des autorisations Google pour poursuivre le processus d'inscription.",
    "error.google.directoryApiPermissionDeniedTitle": "Accordez les autorisations manquantes pour continuer",
    "actionNotifications.outOfOfficeSetting.success": "Votre paramètre d'absence du bureau a été mis à jour",
    "app.automations": "Automatisations",
    "app.createdAt": "Créé",
    "app.notValidEmail": "S'il vous plaît, mettez une adresse email valide.",
    "app.snooze": "Taire",
    "automations.add": "Créer une automatisation",
    "automations.automationDeleted": "L'extension est supprimée",
    "automations.automationType": "Type d'automatisation",
    "automations.BLACKOUT_PERIOD": "Période non autorisée",
    "automations.blackoutPeriod.createInProgress": "La création d'une période non autorisée est en cours",
    "automations.blackoutPeriod.createInProgressCompleted": "La création de la période de non autorisée est complète",
    "automations.blackoutPeriod.updateInProgress": "Une mise à jour de la période non autorisée  est en cours",
    "automations.blackoutPeriod.updateInProgressCompleted": "Mise à jour de la période non autorisée est complète",
    "automations.blackoutPeriodFromTo": "Période non autorisée de {fromDate} <endDate>à {toDate}</endDate>",
    "automations.cantOpenAutomations": "Cette automatisation a été créée par un autre utilisateur et vous ne pouvez pas la modifier.",
    "automations.datePeriod": "De {fromDate} <endDate>à {toDate}</endDate><recurring> chaque année</recurring>\n",
    "automations.deleteInProgress": "Suppression de l'automatisation {automationName}",
    "automations.period": "Période",
    "automations.SENIORITY_ENTITLEMENT": "Avantages liés à l'ancienneté",
    "automations.update": "Mettre à jour les automatisations",
    "automationsForm.advancedFilter.tooltipInfo": "Vous pouvez filtrer les utilisateurs auxquels cette automatisation s'appliquera en fonction des services, des sites ou des libellés.",
    "automationsForm.applyToAllUsers": "Appliquer à tous les utilisateurs?",
    "automationsForm.applyToAllUsersTooltip": "Veuillez choisir si vous souhaitez appliquer cette automatisation de manière universelle à tous les utilisateurs ou la personnaliser en fonction de services, de sites ou des libellés.",
    "automationsForm.autoRejectReason": "Réponse de rejet automatique",
    "automationsForm.autoRejectReasonTooltip": "Fournissez à l'utilisateur une réponse automatique expliquant pourquoi sa demande de congé a été rejetée pendant cette période.",
    "automationsForm.blackoutPeriodShortText": "Une période pendant laquelle il est interdit aux employés de prendre des congés",
    "automationsForm.period": "Période",
    "automationsForm.recurring": "Récurrent annuellement",
    "bot.message.blackoutPeriod": "*Période non autorisée* : {startDate} - {endDate}\n\n_{message}_",
    "bot.message.accruedDaysInfoForApprover": "Cette demande de congé est basée sur les futurs jours de congé accumulés, et non sur le montant actuel. <@{name}> a {amount} jour(s) restant(s) aujourd'hui. Ils s'accumuleront {accrued} le {earningDate}.",
    "bot.message.accruedDaysInfoForUser": "Votre demande de congé est basée sur les futurs jours de congé accumulés, et non sur le montant actuel. Il vous reste {amount} jour(s) aujourd'hui et vous accumulerez {accrued} le {earningDate}.",
    "bot.message.on": "Dans",
    "bot.message.leaveTypeWithHalfDay": "*Type de congé:*\n{leaveTypeName} (demi-journée)",
    "bot.message.reminderSnoozed": "Les rappels de cette requête ont été annulés",
    "bot.message.requestLeave.errorAutomation": "{errorMessage}\n",
    "bot.message.submittedLeaveRequestReminder": "Bonjour, juste un rappel que <@{name}> a soumis une requête qui requiert votre attention.",
    "bot.message.submittedLeaveRequestReminderExpiration": "⏰ Veuillez noter que cette requête expirera dans {days} jour(s). Ceci est le rappel {reminderNo} de 3.",
    "bot.notification.changeOutOfOfficeMessage": "Modifier le modèle",
    "bot.notification.leaveRequestExpired": "Votre demande a expiré",
    "bot.notification.leaveRequestExpiredGeneral": "La requête a expiré",
    "bot.notification.oooDefaultMessage": "Bonjour,\n\nMerci pour votre message. Je suis actuellement absent du bureau et indisponible pour répondre aux e-mails jusqu'au {firstWorkingDay}.\n\nJe ferai de mon mieux pour répondre à votre e-mail rapidement à mon retour.\n\nMerci pour votre compréhension.\n\nCordialement",
    "bot.notification.processingResend": "En train de renvoyer la demande de congé...",
    "bot.notification.processingSnooze": "En train de taire les rappels...",
    "bot.notification.reminderSent": "Rappel de demande de congé envoyé",
    "bot.notification.requesterCard.blackoutPeriod": "Période : {startDate} - {endDate}",
    "bot.notification.requesterCard.blackoutPeriodReason": "Motif: {message}\n",
    "bot.notification.requesterCard.blackoutPeriodTitle": "Période non autorisée",
    "bot.notification.requesterCardapp.tryAnotherDates": "Essayez d'autres dates",
    "bot.notification.accruedDaysInfoForUser": "ℹ️Votre demande de congé est basée sur les futurs jours de congé accumulés, et non sur le montant actuel. Il vous reste {amount} jour(s) aujourd'hui et vous accumulerez {accrued} le {earningDate}.",
    "bot.notification.accruedDaysInfoForApprover": "Cette demande de congé est basée sur les futurs jours de congé accumulés, et non sur le montant actuel. @{name} a {amount} jour(s) restant(s) aujourd'hui. Ils s'accumuleront {accrued} à la {earningDate}.",
    "bot.notification.setOutOfOffice": "Votre congé {leaveTypeName} doit commencer dans moins de {days} {days, plural, =1 {day} other {days}} le {leavePeriodStart}. Souhaitez-vous définir une réponse automatique d'absence du bureau à envoyer pendant votre absence?",
    "bot.notification.setOutOfOfficeButton": "Definir la réponse d'absence du bureau",
    "bot.notification.setOutOfOfficeFallbackText": "Définir la réponse d'absence du bureau pour {leaveTypeName}",
    "bot.notification.snoozeOutOfOfficeButton": "Ne me demandez pas à nouveau",
    "bot.notification.snoozeOutOfOfficeMessage": "Nous ne vous demanderons plus de définir l'absence du bureau pour {leaveTypeName}. Vous pouvez toujours modifier ce paramètre dans le panneau web Vacation Tracker - page Paramètres du compte.",
    "choiceAutomations.blackoutPeriodInfo": "Une période non autorisée est une période spécifiée pendant laquelle les employés ne sont pas autorisés à prendre des congés.",
    "choiceAutomations.selectAutomations": "Sélectionnez l'automatisation de votre choix",
    "choiceAutomations.seniorityEntitlementInfo": "Les avantages liés à l'ancienneté sont une approche où les employés obtiennent plus de jours de congé payé en fonction de leurs années de service dans l'entreprise. Actuellement, vous pouvez créer seulement une avantage liée à l'ancienneté par site. Cette automatisation est valable à partir de votre exercice fiscal actuel. <link>Contactez-nous</link> si vous avez besoin de plus d'une avantage liée à l'ancienneté dans un site ou si vous souhaitez appliquer cette automatisation aux années précédentes.",
    "components.automationBlackoutSummaryInfo.blacoutSummary": "{noOfUsers} l'utilisateur<multiUsers>s</multiUsers> <link>(View User<multiUsers>s</multiUsers>)</link> ne pourra pas prendre {leaveTypes},<endDate> entre</endDate> {fromDate} <endDate>et {toDate}</endDate><recurring> de chaque année.</recurring>",
    "components.leavePolicyForm.allowAdvanceAccrualUsage": "Autoriser la demande de congé basé sur les cumuls de congés à l'avance",
    "components.leavePolicyForm.allowAdvanceAccrualUsageInfo": "Permettez aux employés de demander des congés en fonction de leur accumulation prévue de jours de congé, plutôt que simplement de ce qu'ils ont accumulé au moment de la demande. Cela signifie que si un employé sait qu'il aura suffisamment de jours accumulés au moment où le congé demandé se produit, il peut soumettre la demande à l'avance, même s'il n'a pas assez de jours au moment où la demande est faite.",
    "components.accrualsCalculator.calculateAccrualsDays": "Calculer les cumuls de congés",
    "components.accrualsCalculator.calculate": "Calculer",
    "components.accrualsCalculator.youWillEarnedInCurrentPeriod": "Vous gagnerez <strong>{days}</strong> jours dans la période en cours pour cette date",
    "components.accrualsCalculator.youWillEarnedInNextPeriod": "Vous gagnerez <strong>{days}</strong> jours dans la période prochaine pour cette date",
    "dashboard.accountSettingsOutOfOfficeTitle": "Configurer la réponse automatique d'absence du bureau",
    "dashboard.accountSettingsPageOOODescription": "Une fois activé, Vacation Tracker vous avertira deux jours avant votre congé prévu et vous offrira la possibilité de configurer une réponse automatique d'absence du bureau.\n\n",
    "dashboard.googlePermissionInfo2": "- L'accès à vos paramètres Google Calendar est nécessaire pour obtenir vos paramètres de fuseau horaire Google.",
    "dashboard.googlePermissionInfo3": "- L'accès à vos paramètres Gmail est nécessaire pour configurer votre réponse d'absence du bureau et définir la plage de dates pertinente.",
    "dashboard.noLeaveRequest": "Cette demande de congé n'est pas active.",
    "dashboard.outlookPermissionInfo2": "- Accès hors ligne : Ceci est nécessaire pour que Vacation Tracker configure votre réponse d'absence du bureau.",
    "dashboard.outlookPermissionInfo3": "- Paramètres de la boîte aux lettres Outlook: Nous avons besoin d'accéder à vos paramètres de fuseau horaire Outlook pour garantir une synchronisation précise. De plus, nous avons besoin d'une autorisation pour définir votre réponse d'absence du bureau et définir la plage de dates souhaitée.",
    "dashboard.outOfOffice": "Définir la réponse d'absence du bureau",
    "dashboard.outOfOffice.requestIntegrationButton": "Demander une intégration",
    "dashboard.outOfOfficeActiveLabel": "Absent du bureau",
    "dashboard.outOfOfficeGoogleMailButton": "e-mail Google",
    "dashboard.outOfOfficeGoogleSigninModalTitle": "Connexion au compte Google requise",
    "dashboard.outOfOfficeMicrosoftSigninModalTitle": "Connexion au compte Microsoft requise",
    "dashboard.outOfOfficeNotActiveInfo": "Il semble que vous n'ayez pas encore activé la fonction de réponse automatique d'absence du bureau dans votre compte. Cet outil vous permet de configurer des e-mails automatisés pour des types de congé spécifiques, en veillant à ce que vos contacts soient informés de votre indisponibilité. Souhaitez-vous activer cette fonctionnalité?\n\n",
    "dashboard.outOfOfficeOtherEmail": "Avez-vous un autre fournisseur de messagerie?",
    "dashboard.outOfOfficeOtherEmailInfo": "Pour le moment, nous prenons en charge Gmail et Outlook. Cependant, nous nous efforçons constamment d'améliorer notre logiciel. Vos commentaires sur le fournisseur de messagerie que vous utilisez nous aideraient à déterminer nos priorités de développement. Jusqu'à ce que nous étendions notre assistance à d'autres fournisseurs, vous pouvez établir manuellement votre réponse d'absence du bureau dans votre service de messagerie.",
    "dashboard.outOfOfficeOtherEmailPlaceholder": "Entrez votre fournisseur de messagerie par example ProtonMail, Yahoo! Mail...",
    "dashboard.outOfOfficeOutlookButton": "Outlook",
    "dashboard.outOfOfficePageSettingsTitle": "CHOISISSEZ VOTRE FOURNISSEUR DE MESSAGERIE",
    "dashboard.outOfOfficePageTitle": "Absent du Bureau",
    "dashboard.outOfOfficeSet": "Définir la réponse d'absence du bureau",
    "dashboard.outOfOfficeSetError": "Une erreur s'est produite lors de la définition de votre réponse automatique d'absence du bureau. Veuillez réessayer.",
    "dashboard.outOfOfficeSetInfo": "C'est ici que vous pouvez configurer votre réponse d'absence du bureau. Pendant votre absence, ce message sera automatiquement envoyé aux personnes qui tenteront de vous contacter par e-mail. Si vous décidez de modifier ce message à l'avenir, vous pouvez facilement le faire via vos paramètres de messagerie.",
    "dashboard.outOfOfficeSetInfoBot": "Votre réponse d'absence du bureau est définie. Pendant votre absence, ce message sera automatiquement envoyé aux personnes qui tenteront de vous contacter par e-mail. Si vous décidez de modifier ce message à l'avenir, vous pouvez facilement le faire via vos paramètres de messagerie.",
    "dashboard.outOfOfficeSetSuccess": "Félicitations, vous avez réussi à configurer votre message automatique d'absence du bureau. Il sera actif au début de votre congé.",
    "dashboard.permissionModalInfo": "Pour que Vacation Tracker définisse automatiquement votre réponse d'absence du bureau, nous avons besoin d'autorisations supplémentaires:",
    "dashboard.selectSnoozeLeaveTypesPlaceholder": "Sélectionnez les types de congés...",
    "dashboard.snoozedLeaveTypesLabel": "Désactiver pour",
    "error.blackOutPeriod.isntCreated": "Période non autorisée n'est pas créée",
    "error.blackOutPeriod.isntDeleted": "Période non autorisée n'est pas supprimée",
    "error.blackOutPeriod.isntUpdated": "Période non autorisée n'est pas à jour",
    "error.blackOutPeriod.UserNotAdminOrApprover": "Utilisateur n'est ni administrateur ni approbateur",
    "error.blackOutPeriod.UserNotFound": "Utilisateur non trouvé",
    "error.fetchingUserOutOfOfficeSettings": "Un problème est survenu lors de la récupération de vos paramètres d'absence du bureau. Veuillez réessayer. Si ce problème persiste, veuillez contacter notre service client",
    "error.holidayDateInvalid": "Date de vacances invalide {date} pour {year}␣",
    "error.token.permissionDenied": "À l'invite de connexion Google, vous avez cliqué sur Continuer sans autoriser les étendues d'authentification requises. Cliquez sur Tout sélectionner ou sélectionnez les autorisations appropriées (voir, modifier, partager et supprimer définitivement tous les calendriers auxquels vous pouvez accéder à l'aide de Google et voir, modifier, créer ou changer vos paramètres et filtres de messagerie dans Gmail), puis cliquez sur Continuer.",
    "notifications.deleteAutomationConfirm": "Veuillez confirmer que vous souhaitez supprimer la notification <strong>{name}</strong>.",
    "notifications.deleteAutomationTitle": "Supprimer l'automatisation",
    "teams.removeUserInfo": "Vous ne pouvez pas supprimer l'utilisateur du service par défaut, si vous souhaitez déplacer l'utilisateur vers un autre service, vous pouvez modifier un autre service et y ajouter l'utilisateur.",
    "user.resend": "Renvoyer la demande",
    "user.resending": "En train de renvoyer la demande de congé...",
    "user.resent": "Renvoi de la requête",
    "user.accrualsCalculatorInfo": "Combien de jours aurai-je cumulé pour une date précise?",
    "user.accrualsCalculator": "Calculer",
    "app.text": "Texte",
    "components.createLeaveTypeForm.locationsPolicyHint": "Pour modifier la politique (les règles) pour ce type de congé, accédez aux {locations, plural, =1 {Site} other {Sites}} spécifiques:",
    "components.leaveTypeForm.tooltipHeader": "Dans Vacation Tracker, les \"Types de congés\" font référence aux catégories de congés globales telles que les congés payés, les jours de maladie, le travail à domicile, etc. D'un autre côté, les \"Politiques de congé\" sont des règles et paramètres spécifiques attribués à chaque Type de Congé par Site. Ces politiques peuvent inclure diverses options telles que:",
    "components.leaveTypeForm.tooltipElement1": "<strong>Approbation requise</strong>: Cette option peut être activée si un congé nécessite l'approbation d'un responsable.",
    "components.leaveTypeForm.tooltipElement2": "<strong>Autoriser les soldes négatifs</strong>: Cette option permet aux utilisateurs de demander un congé même après avoir épuisé leur quota.",
    "components.leaveTypeForm.tooltipElement3": "<strong>Autoriser les demi-journées</strong>: Cette option peut être activée selon les besoins de votre organisation.",
    "components.leaveTypeForm.tooltipFooter": "Il est important de noter que les politiques de congés sont définies par Site. Vous pouvez personnaliser ces politiques en fonction des besoins de votre organisation et les attribuer aux types de congés appropriés.",
    "components.userLeaveQuotas.userInactiveInfo": "Le compte étant désormais inactif, aucun jour supplémentaire ne sera ajouté jusqu'à la réactivation.",
    "email.notification.accruedDaysInfoForApprover": "Cette demande de congé est basée sur les futurs jours de congé accumulés, et non sur le montant actuel. Le {name} dispose de {amount} jour(s) restant(s) aujourd'hui. Ils s'accumuleront {accrued} le {earningDate}.",
    "email.notification.accruedDaysInfoForUser": "Votre demande de congé est basée sur les futurs jours de congé accumulés, et non sur le montant actuel. Il vous reste {amount} jour(s) aujourd'hui et vous accumulerez {accrued} le {earnedDate}.",
    "location.rolloverExpiryAfterHours": "Expiration des heures avancées",
    "location.rolloverExpiryHour": "Les heures de congés reportées devraient-elles expirer?",
    "location.rolloverExpiryOnDateHours.extra": "Ces heures expireront à la fin de la journée.",
    "location.rolloverExpiryOnDateHours": "Les heures reportées expirent le",
    "surveyAboutUs.OptionQuestion1-1": "Recherche Google",
    "surveyAboutUs.OptionQuestion1-1-1": "Qu'avez-vous recherché sur Google (par exemple, \"suivi des congés\")?",
    "surveyAboutUs.OptionQuestion1-2": "Magasin d'applications (Slack, MS Teams, etc...)",
    "surveyAboutUs.OptionQuestion1-3": "Médias sociaux, plateforme d'évaluation ou autre site web",
    "surveyAboutUs.OptionQuestion1-3-1": "Quelle plateforme ou quel site ?",
    "surveyAboutUs.OptionQuestion1-3-1-1": "Un collègue",
    "surveyAboutUs.OptionQuestion1-3-1-2": "Un ami",
    "surveyAboutUs.OptionQuestion1-3-1-3": "J'ai lu sur Vacation Tracker sur un site Web",
    "surveyAboutUs.OptionQuestion1-3-1-3-1": "Pouvez-vous nous dire de quel site Web il s'agissait?",
    "surveyAboutUs.OptionQuestion1-3-1-4": "Autre",
    "surveyAboutUs.OptionQuestion1-4": "Recommandation",
    "surveyAboutUs.Question1": "Comment avez-vous connu Vacation Tracker?",
    "surveyAboutUs.textarea": "Veuillez préciser",
    "app.code": "Code",
    "app.mfa": "Authentification multi-facteurs",
    "app.mfaCode": "Code",
    "app.mfaCodePlaceholder": "Veuillez saisir le code affiché dans votre application d'authentification",
    "app.mfaDisableAnswerNo": "Non, conserver MFA",
    "app.mfaDisableAnswerYes": "Oui, désactiver MFA",
    "app.mfaDisableError": "Échec de la désactivation de l'authentification multi-facteurs. Veuillez réessayer plus tard",
    "app.mfaDisableSuccess": "Vous avez désactivé MFA avec succès.",
    "app.mfaDisableWarning": "Êtes-vous sûr de vouloir désactiver MFA ?",
    "app.mfaDisableWarningTitle": "Désactiver l'authentification multi-facteurs",
    "app.mfaEnterCode": "Entrez le code",
    "app.mfaEnableError": "L'activation de l'authentification multi-facteurs a échoué. Veuillez réessayer plus tard.",
    "app.mfaEnableSuccess": "Vous avez configuré avec succès l'authentification multi-facteurs.",
    "app.mfaLabel": "Activer MFA:",
    "app.mfaLoginFailed": "Échec de la connexion",
    "app.mfaSecretKey": "Ou saisissez la clé secrète manuellement:",
    "app.mfaSubTitle": "Scannez le code QR suivant dans votre application MFA (c'est-à-dire Authy ou Google Authenticator):",
    "app.mfaTitle": "Activer l'authentification multi-facteurs",
    "app.mfaTooltip": "Une fois que vous avez ajouté Vacation Tracker à votre application MFA, attendez qu'il génère le code et saisissez-le ci-dessous:",
    "bot.notification.slack.leaveRequestInfo": "Bonjour, <@{userName}> veuillez confirmer les informations concernant votre demande de congé.",
    "bot.notification.slack.leaveRequestInfoDateFrom": "Date de début: {date}",
    "bot.notification.slack.leaveRequestInfoDateTo": "Date de fin: {date}",
    "bot.notification.slack.leaveRequestInfoError": "Nous n'avons pas pu suggérer de congé à partir des informations fournies.",
    "bot.notification.slack.leaveRequestInfoLeaveType": "Type de congé: {leaveType}",
    "bot.notification.slack.leaveRequestInfoReason": "Motif: {reason}",
    "bot.notification.slack.leaveRequestSuggestionCancelled": "Formulaire de demande de congé annulé ❌",
    "components.importUsersForm.warningOfUsersImportOverSeatsLimit": "Vous avez atteint votre limite actuelle de licences ({seats}). Voulez-vous en acheter plus?",
    "components.modal.verify": "Vérifiez",
    "components.userLeaveTypeForm.prorateInfoDays": "{user} aura {proratedQuota} ({preciseQuota} jours, pour être précis) pour l'année initiale ({yearPeriod}) et {fullQuota} jours pour toutes les années à partir de {startOfNextYear}.",
    "components.userLeaveTypeForm.prorateInfoHours": "{user} aura {proratedQuota} ({preciseQuota} heures, pour être précis) pour l'année initiale ({yearPeriod}) et {fullQuota} heures pour toutes les années à partir de {startOfNextYear}.",
    "error.automations.automationSubmitError": "Erreur de soumission automatique",
    "error.automations.insufficientPrivileges.title": "Erreur de permission",
    "error.automations.insufficientPrivileges.description": "Vous n'avez pas la permission d'effectuer cette action. Veuillez contacter votre administrateur.",
    "form.inputSize": "Ce champ doit comporter {len} chiffres",
    "leaveRequest.sent": "Demande de congé envoyée",
    "app.add": "Ajouter",
    "app.areYouSure": "Êtes-vous sûr?",
    "app.pleaseSelect": "Veuillez sélectionner",
    "app.expires": "Expire",
    "app.mfaCodeErrorGeneric": "Impossible de valider le code",
    "app.mfaCodeErrorMissmatch": "Le code ne correspond pas",
    "app.mfaCodeErrorNetwork": "ErreurRéseau",
    "app.nextInvoice": "␣Votre prochaine facture sera de {amount} (hors TVA) le {startDate}",
    "app.ofThat": "de cela",
    "app.timeOffInLieu": "Congé Compensatoire",
    "app.timeOffInLieuTooltip": "Voulez-vous modifier le Congé Compensatoire? <helpDesk>Découvrez comment procéder</helpDesk>",
    "automations.seniorityEntitlement.createInProgress": "Création des avantages liés à l'ancienneté en cours",
    "automations.seniorityEntitlement.createInProgressCompleted": "Création des avantages liés à l'ancienneté terminée",
    "automations.seniorityEntitlement.updateInProgress": "Mise à jour des avantages liés à l'ancienneté en cours",
    "automations.seniorityEntitlement.updateInProgressCompleted": "Mise à jour des avantages liés à l'ancienneté terminée",
    "automationsForm.seniorityEntitlement.applyToAllUsersTooltip": "Souhaitez-vous appliquer cet avantage lié à l'ancienneté à tous les utilisateurs de votre entreprise ou uniquement aux utilisateurs d'un site spécifique? Si vous avez différentes règles d'avantages liés à l'ancienneté selon les sites, vous devez créer plusieurs règles d'automatisation d'avantages liés à l'ancienneté, une pour chaque site.",
    "billing.seats.buyLessSeatsConfirmModalContent": "Vous disposez de {currentNoOfSeats} licences. Vous êtes sur le point de supprimer {additionalSeats}.",
    "billing.seats.buyMoreSeatsConfirmModalContent": "Vous disposez de {currentNoOfSeats} licences. Vous êtes sur le point d'acheter {additionalSeats} supplémentaires.",
    "billing.seats.canNotDowngrade": "Vous ne pouvez pas réduire votre nombre de licences ({currentNumberOfSeats}) sans désactiver un ou plusieurs utilisateurs actuellement actifs.",
    "billing.seats.info": "Le nombre de licences que vous avez achetées.",
    "billing.seats.loadingUpcomingPaymentInformation": "Chargement des informations sur les paiements à venir...",
    "billing.seats.manage": "Gérer les licences",
    "billing.seats.noBillingChange": "Vous êtes sur le point d'acheter {noOfSeats} {noOfSeats, plural, =1 {seat} other {seats}}. Votre facturation ne sera pas affectée.",
    "billing.seats.warningOfProrateAdditional": "Un montant proratisé estimé de {proratedAmount} (hors TVA) sera appliqué aujourd'hui.",
    "billing.seats.warningOfSeatsAnnualProrateAdditionalNewBucket": "Ajouter {noOfSeats} {noOfSeats, plural, =1 {seat} other {seats}} augmentera votre prochaine facture à {amount} (hors TVA) à partir du {startDate}.",
    "billing.seats.warningOfSeatsProrateAdditionalNewBucketMonthly": "Acheter {noOfSeats} {noOfSeats, plural, =1 {seat} other {seats}} affectera votre facturation. Votre prochaine facture sera de {amount} (hors TVA) le {startDate}",
    "billing.seats.warningOfSeatsRemovingAnnualProrateAdditionalNewBucket": "Supprimer {noOfSeats} {noOfSeats, plural, =1 {seat} other {seats}} réduira votre prochaine facture à {amount} (hors TVA) à partir du {startDate}. ",
    "billing.seats.warningOfSeatsRemovingProrateAdditionalNewBucketMonthly": "Supprimer {noOfSeats} {noOfSeats, plural, =1 {seat} other {seats}} affectera votre facturation. Votre prochaine facture sera de {amount} (hors TVA) le {startDate} ",
    "billing.seats": "Licences",
    "choiceAutomations.seniorityEntitlementShortInfo": "Les avantages liés à l'ancienneté sont une approche où les employés obtiennent plus de jours de congé payé en fonction de leurs années de service dans l'entreprise.",
    "components.seniorityEntitlement.startApplayingSeniorityEnintlementFrom": "Commencer à appliquer les avantages liés à l'ancienneté à partir du",
    "components.seniorityEntitlement.prorateForTheFirstYear": "Voulez-vous activer la proratisation?",
    "components.seniorityEntitlement.prorateForTheFirstYearTooltip": "Si la date de début de l'employé ne correspond pas à la date de début de l'exercice fiscal, nous proratiserons les jours basés sur l'ancienneté dans l'année où ils sont attribués. Par exemple, si votre exercice fiscal commence le 1er janvier, que l'utilisateur a commencé à travailler le 1er juillet il y a 5 ans, et que votre entreprise offre 5 jours de congé supplémentaires le 5 juillet après 5 ans de service, l'utilisateur recevra 2,5 jours le 1er juillet en raison de la proratisation. Cet utilisateur recevra les 5 jours basés sur l'ancienneté lors du prochain exercice fiscal.",
    "components.seniorityEntitlement.daysAfter": "jour(s) après",
    "components.seniorityEntitlement.yearsInTheCOmpany": "années dans l'entreprise",
    "components.seniorityEntitlement.allFiledsAreRequired": "Tous les champs sont requis",
    "components.generalSettings.aiBotHelperLabel": "Autoriser la communication avec le Vacation Tracker Bot",
    "components.generalSettings.aiBotHelperLabelDisable": "Désactiver la communication avec le Vacation Tracker Bot",
    "components.generalSettings.aiBotHelperTooltip": "Communiquer avec le bot est un moyen pratique et efficace pour les employés de demander un congé. Les employés peuvent simplement envoyer un message de demande de congé au bot, en précisant des détails tels que les dates de début et de fin, le nombre de jours demandés et le type de congé.",
    "components.importUsersForm.warningOfUsersImportOverSeatsLimitImportAll": "Vous ne pouvez pas importer tous les utilisateurs de votre espace de travail/répertoire car vous dépasserez alors votre limite actuelle de licences ({seats}). Voulez-vous en acheter plus?␣",
    "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivated": "Mettre en pause les cumuls de congés lorsque l'utilisateur est désactivé",
    "components.pricePlanCard.forSeats": "pour moins de {users} licences",
    "components.pricePlanCard.pricePerSeat": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>par utilisateur par mois",
    "components.pricePlanCard.totalPriceSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/par mois pour {totalUsers} utilisateurs",
    "components.pricePlanCard.totalPricePerSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/par an",
    "components.userLeaveQuotas.accrualsTooltipTitle": "Les cumuls de congés sont l'augmentation progressive de vos congés au cours de l'année.",
    "components.userLeaveQuotas.accrualsTooltipTitle-bi-weekly": "Une accumulation bihebdomadaire {leaveType} a lieu toutes les deux semaines",
    "components.userLeaveQuotas.accrualsTooltipTitle-monthly": "Une accumulation mensuelle {leaveType} a lieu une fois par mois.",
    "components.userLeaveQuotas.accrualsTooltipTitle-semi-monthly": "Une accumulation bimensuelle {leaveType} a lieu deux fois par mois.",
    "components.userLeaveQuotas.baseQuota": "Solde de base",
    "components.userLeaveQuotas.broughtForwardInfo": "Le report de congés représente le nombre de jours non utilisés reportés de la période précédente.",
    "components.userLeaveQuotas.broughtForwardInfoDetails": "Pour {leaveTypeName} au site {locationName}, les employés peuvent reporter jusqu'à {broughtForwardLimit} {broughtForwardLimit, plural, =1 {day} other {days}} non utilisé(s).",
    "components.userLeaveQuotas.broughtForwardValue": "Report de congés",
    "components.userLeaveQuotas.editInitialQuotaTitle": "Modifier les jours de Report Initial pour {leaveTypeName}",
    "components.userLeaveQuotas.editInitialQuotaTooltip": "Le report de congés initial vous permet de saisir manuellement les soldes de départ des employés provenant d'un système de suivi ou RH précédent.",
    "components.userLeaveQuotas.expirationTooltip": "Jours que l'employé a reportés de la période précédente mais qui n'ont pas été utilisés avant la date d'expiration",
    "components.userLeaveQuotas.paidDays": "Jours payés",
    "components.userLeaveQuotas.quotaInfo": "Le solde représente le nombre de jours de congé payé disponibles pour la période en cours. Ce chiffre inclut le solde de base, les congés compensatoires, et les jours attribués en fonction du rôle ou de l'ancienneté de l'employé.",
    "components.userLeaveQuotas.quotaInfoSummary": "Solde pour la période en cours :",
    "components.userLeaveQuotas.remainingTooltip": "Jours de {leaveTypeName} restants pour la période en cours. Total:",
    "components.userLeaveQuotas.remainingTooltipFooter": "Jusqu'à {broughtForwardLimit} {period} inutilisés seront reportés à la période suivante",
    "components.userLeaveQuotas.remainingTooltipFooterZero": "Aucun jour non utilisé ne peut être reporté à la période suivante.",
    "components.userLeaveQuotas.remainingTooltipFooterAll": "Tous les jours non utilisés peuvent être reportés à la période suivante.",
    "components.userLeaveQuotas.scheduledDays": "Jours programmés",
    "components.userLeaveQuotas.usedDays": "Jours utilisés",
    "components.userLeaveQuotas.usedTooltip": "Jours de {leaveTypeName} utilisés, programmés et payés pour la période en cours. Total:",
    "components.userLeaveTypeForm.quotaAdditionalRole": "Définissez les jours supplémentaires en fonction du rôle",
    "components.userLeaveTypeForm.quotaAdditionalSeniority": "Définissez les jours supplémentaires en fonction des avantages liés à l'ancienneté",
    "components.userLeaveTypeForm.quotaAdditionalSeniorityInfo": "Les jours basés sur l'ancienneté sont ajoutés par l'automatisation des avantages liés à l'ancienneté. Vous pouvez écraser les jours de {leaveTypeName} basés sur l'ancienneté en définissant la valeur dans ce champ.",
    "components.userLeaveTypeForm.quotaAdditionalSeniorityInfoUpcomingYear": "Les jours basés sur l'ancienneté sont ajoutés par l'automatisation des avantages liés à l'ancienneté. Vous pouvez écraser les jours de {leaveTypeName} basés sur l'ancienneté en définissant la valeur dans ce champ. Cependant, ce champ est verrouillé car vous ne pouvez pas modifier cette valeur pour les années futures avant que les jours ne soient attribués.",
    "components.seniorityEntitlement.disableEditInfo": "Vous ne pouvez pas modifier l'automatisation des avantages liés à l'ancienneté. Si vous devez apporter des modifications, supprimez cette automatisation et créez-en une nouvelle. <link>Contactez-nous</link> si vous avez des questions ou besoin d'aide.",
    "components.userLeaveTypeForm.quotaAdditionalSummary": "Vous définirez le quota de congés {leaveTypeName} pour {user} pour la période sélectionnée à {total} {hourlyLeaveAccounting, plural, =1 {hours} other {days}}. Le quota de base et les jours supplémentaires basés sur les droits à l'ancienneté et par rôle ({total} {hourlyLeaveAccounting, plural, =1 {hours} other {days}}) seront appliqués pour la {future, plural, =1 {période sélectionnée et les périodes futures} other {période sélectionnée}}. {prorateText}",
    "components.userLeaveTypeForm.quotaAdditionalSummaryUnlimited": "Vous définirez le solde de congé {leaveTypeName} pour {user} pour la période sélectionnée comme étant illimité.",
    "components.userLeaveTypeForm.quotaBase": "Définir le solde de congé de base",
    "notifications.deleteSeniorityEntitlementAutomationConfirm": "Êtes-vous sûr de vouloir supprimer cette automatisation des avantages liés à l'ancienneté? Vous ne pourrez pas annuler cette action. Cependant, la suppression de l'automatisation n'affectera pas les jours {leaveTypeName} déjà attribués.",
    "components.pricePlanCard.customizeLeaveTypes": "Personnalisez les types de congés",
    "components.pricePlanCard.hourlyLeaves": "Suivez les congés en heures",
    "components.pricePlanCard.managePTO": "Gérez les demandes de congés",
    "components.pricePlanCard.reports": "Générez des rapports",
    "components.pricePlanCard.reportsSchedule": "Planifiez des rapports",
    "components.pricePlanCard.support": "Assistance prioritaire",
    "components.pricePlanCard.unlimitedDepartmentsAndLocations": "Créez des services et des sites illimités",
    "components.importUsersForm.warningOfUsersImportTrial": "Vous êtes actuellement en mode d'essai. Vous serez facturé {amount} (hors TVA) le {startDate}",
    "components.userLeaveQuotas.broughtForwardInfoDetailsAll": "Pour {leaveTypeName} au Site {locationName}, tous les jours non utilisés peuvent être reportés à la période suivante.",
    "components.userLeaveQuotas.noLimit": "Vous avez des jours illimités.",
    "components.userLeaveQuotas.scheduled": "Planifiés",
    "components.userLeaveQuotas.usedAlready": "Déjà Utilisés",
    "components.seniorityEntitlement.noLocations": "Vous avez une automatisation qui a été appliquée à tous les utilisateurs, vous ne pouvez pas avoir plusieurs automatisations pour tous les utilisateurs.",
    "components.userLeaveTypeForm.quotaBaseTooltip": "Le quota de base est le quota par défaut de l'utilisateur, qui exclut les jours supplémentaires reçus pour des avantages liés à l'ancienneté, le rôle, le congé compensatoire, ou tout autre jour supplémentaire. Vous pouvez modifier le quota de base pour tout le monde dans un lieu sur la page de politique de congé ou utiliser ce champ pour définir un quota personnalisé pour un utilisateur sélectionné.",
    "leaveRequest.simpleQuota.addAnotherLeave": "Ajouter un autre congé",
    "leaveRequests.addedCurrentYear": "Ajouté pour l'année en cours",
    "leaveRequests.addeddNextYear": "Ajouté pour l'année prochaine",
    "app.reviewAndConfirm": "Vérifier & Confirmer",
    "dashboard.b2bSoftware": "Veuillez noter que ceci est un logiciel B2B et par conséquent, nous ne sommes pas en mesure de modifier ou de supprimer vos données sur demande. Pour plus d'informations, veuillez contacter votre manager dédié. Si vous avez besoin d'assistance, notre équipe de support est disponible à <mail>hello@vacationtracker.io</mail>",
    "general.deleteAccount": "Si vous souhaitez modifier ou supprimer vos données, veuillez contacter notre équipe de support à <mail>hello@vacationtracker.io</mail>",
    "app.12h": "12h",
    "app.24h": "24h",
    "app.accountPreferences": "Afficher les préférences",
    "automation.feedback.thankYou": "Merci de partager vos réflexions avec nous. Vos commentaires alimentent notre croissance constante.",
    "automations.anythingMissing": "Est-ce qu'il manque quelque chose?",
    "automations.EARLY_EMPLOYMENT_LEAVE_RESTRICTION": "Restriction des congés de début d'emploi",
    "automations.emptyViewButton": "Voir les extensions disponibles",
    "automations.emptyViewMessage": "Ajoutez des jours de congé en fonction de l'ancienneté, créez des périodes non autorisées, automatisez le processus d'approbation ou de refus, et bien plus encore avec les Automatisations.",
    "automations.emptyViewTitle": "Automatisez vos tâches laborieuses et intégrez-les à des systèmes externes",
    "automations.entitlementByRole.createInProgress": "Création d'attribution par rôle en cours",
    "automations.entitlementByRole.createInProgressCompleted": "Création d'attribution par rôle terminée",
    "automations.entitlementByRole.updateInProgress": "Mise à jour de l'attribution par rôle en cours",
    "automations.entitlementByRole.updateInProgressCompleted": "Mise à jour de l'attribution par rôle terminée",
    "automations.LEAVE_DURATION_LIMIT": "Limite de durée des congés",
    "automations.message": "Message",
    "automations.messagePlaceholder": "Votre message",
    "automations.requestAnAutomation": "Demander une extension",
    "automations.requestAutomationTitle": "Vous ne trouvez pas l'extension dont vous avez besoin ?",
    "automations.TEAM_LEAVE_BALANCING": "Équilibrage des congés d'équipe",
    "bot.message.requestLeave.selectTimeFormat": "*AM/PM*",
    "choiceAutomations.employmentLeaveRestrictionInfo": "La politique de congé anticipé pour les nouveaux employés permet de garantir un temps d'installation dédié au cours des premiers jours d'emploi en restreignant les demandes de congé.",
    "choiceAutomations.enitlementByRoleShortInfo": "Les employés se bénéficient de jours de congé supplémentaires en fonction de leur rôle, tel que défini par le(s) libellé(s)",
    "choiceAutomations.entitlementByRoleInfo": "Les employés bénéficient de jours de congé supplémentaires en fonction de leur rôle, tel que défini par le(s) libellé(s). Par exemple, vous pouvez créer un libellé appelé \"Manager\" et l'attribuer à tous les gérants de votre organisation. Ensuite, vous pouvez créer une automatisation qui attribue 5 jours de congé supplémentaires à tous les employés avec le libellé \"Manager\". Vous pouvez créer plusieurs automatisations pour différents rôles. Cette automatisation est valable à partir de votre année fiscale en cours. <link>Contactez-nous</link> si vous avez besoin d'appliquer cette automatisation à des années antérieures.",
    "choiceAutomations.leaveDurationLimitInfo": "Personnalisez la durée du congé: Définissez le nombre minimum et maximum de jours pour la période souhaitée.",
    "choiceAutomations.teamLeaveBalancingInfo": "Gérez le pourcentage de congés simultanés des équipes",
    "components.entitlementByRole.additionalDays": "jour(s) supplémentaire(s)",
    "components.entitlementByRole.applayToUsersWithLabel": "Appliquer aux utilisateurs avec le libellé",
    "components.entitlementByRole.startApplayingEnintlementByRoleFrom": "Commencer à appliquer l'attribution en fonction du rôle à partir de",
    "components.leavePolicyForm.accrualCap": "Activer la limite d'accumulation",
    "components.leavePolicyForm.accrualCapRate": "Limiter les jours attribués à",
    "components.leavePolicyForm.accrualCapRateTimes": "multiplié par le taux d'accumulation annuel",
    "components.leavePolicyForm.accrualCapTooltip": "La limite d'accumulation régule la quantité de jours de congé qu'un employé peut accumuler pendant une période spécifique. Elle est définie comme une multiplication du taux d’accumulation annuel, et non comme un nombre précis.",
    "components.leavePolicyForm.futureBroughtForward": "Report des jours à venir",
    "components.leavePolicyForm.previouesBroughForwardAndFutureOnce": "Reports antérieurs et futurs",
    "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChanges": "Quand souhaitez-vous appliquer ces modifications?",
    "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChangesInfo": "Sélectionnez la période pour appliquer les modifications à votre congé. Choisissez \"Futur reporté\" pour ajuster uniquement les dates de congé à venir, ou \"Précédent et futur reportés\" pour modifier à la fois les dates de congé passées et futures.",
    "components.requestAutomation.anythingElse": "Y a-t-il autre chose que nous devrions savoir?",
    "components.requestAutomation.automationExtraCharge": "Utiliseriez-vous cette extension si elle était facturée ?",
    "components.requestAutomation.automationFunctionalities": "Quelles sont les fonctionnalités ou les tâches que l'extension vous permettra d'accomplir ?",
    "components.requestAutomation.automationShouldWeAdd": "Quelle automatisation devrions-nous ajouter?",
    "components.requestAutomation.submiteRequest": "Soumettre la demande",
    "components.requestAutomation.suggestAnAutomation": "Proposer une automatisation",
    "dashboard.accountSettingsLanguageLabel": "Choisissez votre langue préférée",
    "dashboard.accountSettingsTimeDisplayLabel": "Choisissez votre format d'affichage horaire préféré",
    "dashboard.accountSettingsUIModeLabel": "Mode Sombre vs Mode Clair",
    "error.entitlementByRole.isntCreated": "L'attribution par rôle n'a pas été créée.",
    "error.entitlementByRole.isntDeleted": "L'attribution par rôle n'a pas été supprimée.",
    "error.entitlementByRole.isntUpdated": "L'attribution par rôle n'a pas été mise à jour.",
    "error.seniorityEntitlement.isntCreated": "L'attribution en fonction d'ancienneté n'a pas été créée.",
    "error.seniorityEntitlement.isntDeleted": "L'attribution en fonction d'ancienneté n'a pas été supprimée.",
    "error.seniorityEntitlement.isntUpdated": "L'attribution en fonction d'ancienneté n'a pas été mise à jour.",
    "error.UserNotAdminOrApprover": "Utilisateur non administrateur ou approbateur",
    "error.UserNotFound": "Utilisateur non trouvé",
    "notifications.deleteEntitlementByRoleAutomationConfirm": "Êtes-vous sûr de vouloir supprimer cette automatisation d'attribution par rôle? Vous ne pourrez pas annuler cette action. Cependant, la suppression de l'automatisation n'affectera pas les jours déjà attribués pour {leaveTypeName}.",
    "automations.ENTITLEMENT_BY_ROLE": "Droit basé sur le rôle",
    "automations.blackoutPeriod.autoRejectReasonDefault": "Pendant notre période actuelle de volume élevé, toute notre équipe est essentielle pour maintenir les opérations. Malheureusement, nous ne pouvons pas approuver les congés car cela affecterait nos objectifs commerciaux et nos engagements envers les clients.",
    "choiceAutomations.comingSoonScheduleCallTitle": "Bientôt disponible",
    "choiceAutomations.comingSoonScheduleCall": "Veuillez nous laisser votre adresse e-mail si vous souhaitez planifier une réunion où vous pouvez expliquer vos besoins spécifiques liés à l'automatisation que nous développons",
    "choiceAutomations.scheduleCall": "Programmez un appel",
    "components.userLeaveTypeForm.quotaAdditionalRoleInfo": "Allouez des jours de congé supplémentaires aux employés en fonction de leur rôle professionnel. Ce champ vous permet d'adapter les droits aux congés pour refléter les responsabilités ou les exigences spécifiques des différents postes au sein de l'organisation.",
    "app.every": "chaque",
    "app.import": "Importer",
    "app.importType": "Type d'importation",
    "app.importFile": "Fichier d'importation",
    "app.downloadTemplate": "Télécharger un croquis",
    "app.importLeaves": "Importer les congés",
    "app.importLeaves.manifestTitle": "Données attendues:",
    "app.importLeaves.manifestDescription": "(Vous aurez la possibilité de renommer ou de supprimer des colonnes dans les prochaines étapes)",
    "app.importLeaves.maxRecordsExceeded": "Trop d'enregistrements. Jusqu'à {maxRecords} autorisés",
    "app.importLeaves.dropzone.title": "Téléchargez un fichier .xlsx, .xls ou .csv",
    "app.importLeaves.dropzone.errorToastDescription": "Téléchargez un fichier .xlsx, .xls ou .csv",
    "app.importLeaves.dropzone.buttonTitle": "Sélectionnez le fichier",
    "app.importLeaves.dropzone.loadingTitle": "Traitement en cours...",
    "app.importLeaves.nextButtonTitle": "Suivant",
    "app.importLeaves.selectSheet.title": "Sélectionner la feuille à utiliser",
    "app.importLeaves.selectHeaderStep.title": "Sélectionner la ligne d'en-tête",
    "app.importLeaves.matchColumnsStep.title": "Correspondre les colonnes",
    "app.importLeaves.matchColumnsStep.nextButtonTitle": "Suivant",
    "app.importLeaves.matchColumnsStep.userTableTitle": "Votre tableau",
    "app.importLeaves.matchColumnsStep.templateTitle": "Deviendra",
    "app.importLeaves.matchColumnsStep.selectPlaceholder": "Sélectionner la colonne...",
    "app.importLeaves.matchColumnsStep.ignoredColumnText": "Colonne ignorée",
    "app.importLeaves.matchColumnsStep.subSelectPlaceholder": "Sélectionner...",
    "app.importLeaves.matchColumnsStep.matchDropdownTitle": "Correspondre",
    "app.importLeaves.matchColumnsStep.unmatched": "Non correspondant",
    "app.importLeaves.matchColumnsStep.duplicateColumnWarningTitle": "Une autre colonne non sélectionnée",
    "app.importLeaves.matchColumnsStep.duplicateColumnWarningDescription": "Les colonnes ne peuvent pas se dupliquer",
    "app.importLeaves.validationStep.title": "Valider les données",
    "app.importLeaves.validationStep.nextButtonTitle": "Confirmer",
    "app.importLeaves.validationStep.noRowsMessage": "Aucune donnée trouvée",
    "app.importLeaves.validationStep.noRowsMessageWhenFiltered": "Aucune donnée contenant des erreurs",
    "app.importLeaves.validationStep.discardButtonTitle": "Supprimer les lignes sélectionnées",
    "app.importLeaves.validationStep.filterSwitchTitle": "Afficher uniquement les lignes avec des erreurs",
    "app.importLeaves.alerts.confirmClose.headerTitle": "Quitter le flux d'importation",
    "app.importLeaves.alerts.confirmClose.bodyText": "Êtes-vous sûr ? Vos informations actuelles ne seront pas enregistrées.",
    "app.importLeaves.alerts.confirmClose.cancelButtonTitle": "Annuler",
    "app.importLeaves.alerts.confirmClose.exitButtonTitle": "Quitter",
    "app.importLeaves.alerts.confirmClose.submitIncomplete.headerTitle": "Erreurs détectées",
    "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyText": "Il y a encore des lignes contenant des erreurs. Les lignes avec des erreurs seront ignorées lors de la soumission.",
    "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyTextSubmitForbidden": "Il y a encore des lignes contenant des erreurs. Veuillez supprimer les lignes invalides ou corriger les erreurs et réessayer.",
    "app.importLeaves.alerts.confirmClose.submitIncomplete.cancelButtonTitle": "Annuler",
    "app.importLeaves.alerts.confirmClose.submitIncomplete.finishButtonTitle": "Soumettre",
    "app.importLeaves.alerts.unmatchedRequiredFields.headerTitle": "Toutes les colonnes ne correspondent pas",
    "app.importLeaves.alerts.unmatchedRequiredFields.bodyText": "Il y a des colonnes obligatoires qui ne correspondent pas ou qui sont ignorées. Vous devez faire correspondre toutes les colonnes obligatoires pour continuer.",
    "app.importLeaves.alerts.unmatchedRequiredFields.listTitle": "Colonnes obligatoires ne correspondent pas:",
    "app.importLeaves.alerts.unmatchedRequiredFields.cancelButtonTitle": "Annuler",
    "app.importLeaves.alerts.unmatchedRequiredFields.continueButtonTitle": "Continuer",
    "app.importLeaves.alerts.toast": "Erreur",
    "app.resent": "Renvoyé",
    "app.years": "Années",
    "billing.linkPaymentMethod": "Lien Stripe",
    "billing.paymentMethod": "Méthode de paiement",
    "components.entitlementByRole.applyToUsersWithLabel": "Appliquer aux utilisateurs avec une étiquette",
    "components.entitlementByRole.startApplyingEntitlementByRoleFrom": "Commencez à appliquer les droits par rôle à partir de",
    "components.entitlementByRole.noLabels": "Il n'y a pas d'étiquettes créées ou toutes sont utilisées pour d'autres automatisations des droits par rôle.",
    "components.leavePolicyForm.createLeaveTypeFormAccrualsInfo1": " Les accumulations sont inactives lors de la création d'un type de congé. Pour l'activer, il faut personnaliser la politique de congé pour un lieu spécifique.",
    "components.leavePolicyForm.createLeaveTypeFormAccrualsInfo2": "*L'option d'accumulation est exclusivement accessible avec le plan complet.",
    "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedInfo": "Activez cette fonctionnalité pour suspendre temporairement l'accumulation des jours de congé pour tout utilisateur désactivé. Une fois que l'utilisateur est réactivé, son accumulation de jours de congé se poursuivra automatiquement, garantissant un suivi précis de ses droits aux congés.",
    "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChanges": "Quand souhaitez-vous appliquer ces modifications ?",
    "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChangesInfo": "Sélectionnez la période pour appliquer vos modifications de congé. Choisissez 'Futur reporté' pour ajuster uniquement les dates de congé à venir, ou 'Reporté précédent et futur' pour modifier à la fois les dates de congé passées et futures.",
    "components.logs.blackoutPeriodCreated": "{user} a créé une période non autorisée <b>{name}</b>.<br></br>Activée: {enabled}.<br></br>Récurrente: {recurring}.<br></br>Date de début: {startDate}.<br></br>Date de fin: {endDate}.<br></br>Types de congé: {leaveTypes}.<br></br>Sites: {locations}.<br></br>Services: {teams}.<br></br>Libellés: {labels}.<br></br>",
    "components.logs.blackoutPeriodUpdated": "{user} a mis à jour une période non autorisée <b>{name}</b>.<br></br>Activée: {enabled}.<br></br>Récurrente: {recurring}.<br></br>Date de début: {startDate}.<br></br>Date de fin: {endDate}.<br></br>Types de congé: {leaveTypes}.<br></br>Sites: {locations}.<br></br>Services: {teams}.<br></br>Libellés: {labels}.<br></br>",
    "components.logs.blackoutPeriodDeleted": "{user} a supprimé une période non autorisée.",
    "components.logs.seniorityEntitlementCreated": "{user} a créé une avantage liée à l'ancienneté<b>{name}</b>.<br></br>Activée: {enabled}.<br></br>Proratisée: {prorated}.<br></br>Date de début: {startDate}.<br></br>Type de congé: {leaveType}.<br></br>Attribué: {entitlementPeriods}.<br></br>Sites: {locations}.<br></br>Services: {teams}.<br></br>Libellés: {labels}.<br></br>",
    "components.logs.seniorityEntitlementDeleted": "{user} a supprimé un droit d'ancienneté.",
    "components.seniorityEntitlement.startApplyingSeniorityEntitlementFrom": "Commencer à appliquer le droit d'ancienneté à partir de",
    "components.userLeaveQuotasCompact.titleForApprover": "Solde de congé",
    "importLeaves.importSuccess": "Vous avez soumis des congés à importer. Vérifiez dans quelques minutes si elles ont été importées avec succès.",
    "importLeaves.importFail": "L'importation a échoué avec le message d'erreur suivant:",
    "app.confirm": "Confirmer",
    "app.createdBy": "Créé par",
    "app.downloadProgressReport": "Nous créons votre fichier, cela peut prendre un instant...",
    "app.downloadReady": "Votre fichier est prêt. Cliquez ici pour le télécharger.",
    "app.importFormat": "Format de l'importation",
    "app.importLeaves.downloadTemplateFileTooltip": "Ici, vous pouvez télécharger notre croquis d'importation, qui sert d'exemple pour remplir les données que vous souhaitez importer. Lors de la création de votre fichier d'importation, assurez-vous qu'il contient une colonne d'en-tête pour que le système reconnaisse correctement vos données.<br></br><br></br>Vérifiez que vos données correspondent au croquis pour garantir une importation réussie. Pour des instructions supplémentaires, n'hésitez pas à contacter notre équipe de support.␣",
    "app.importLeaves.dropzone.activeDropzoneTitle": "Déposez le fichier ici.",
    "app.importLeaves.helpdesk": "Pour des conseils détaillés sur l'importation de vos fichiers, veuillez consulter les instructions étape par étape disponibles sur la page du <helpdesk>service d'assistance</helpdesk>.",
    "app.importLeaves.importFormatTooltip": "Sélectionnez le format d'importation qui correspond à votre type de données et à vos préférences.",
    "app.importLeaves.leaveStatusWarning": "Le statut des congés sera considéré comme 'Approuvé' pour tous les congés que vous importez.",
    "app.reconnect": "Reconnecter",
    "app.sync": "Sync",
    "app.type": "Type",
    "automations.activateInProgress": "En cours - Activation",
    "automations.activateInProgressDescription": "Votre demande d'activation de l'automatisation est en cours. Nous travaillons à activer cette fonctionnalité pour vous.",
    "automations.deactivateInPgoress": "En cours - Désactivation",
    "automations.deactivateInPgoressDescription": "Votre demande de désactivation de l'automatisation est en cours de traitement. Veuillez patienter pendant que nous mettons à jour vos paramètres.",
    "automations.addpnSuccessfullyAdded": "Terminé - Activation",
    "automations.addpnSuccessfullyAddedDescription": "L'automatisation a été activée avec succès. Vous pouvez désormais utiliser toutes ces fonctionnalités.",
    "automations.addpnSuccessfullyDeactivated": "Terminé - Désactivation",
    "automations.addpnSuccessfullyDeactivatedDescription": "L'automatisation a été désactivée avec succès. Elle ne sera plus active dans votre système.",
    "automations.PROBATION_PERIOD_LEAVE_RESTRICTION": "Restriction de congé pendant la période de probation",
    "automations.removeAddons": "Supprimer les extensions",
    "automations.subscripteToAddOns": "Souscrire aux extensions",
    "automations.unsubscripteAddOns": "Se désabonner de l'extension?",
    "automations.removeAddonsDescirptionForAdmin": "Êtes-vous sûr de vouloir vous désabonner de cette extension ? Vous conserverez l'accès à toutes ses fonctionnalités jusqu'à la fin de votre période de facturation actuelle. Ensuite, elle sera désactivée, ce qui affectera votre configuration de gestion des congés en conséquence.",
    "automations.removeAddonsDescirptionForApprover": "Seuls les utilisateurs de niveau administrateur ont la possibilité d'acheter ou de se désabonner des extensions. Si des ajustements de vos abonnements aux extensions sont nécessaires, veuillez contacter votre administrateur.",
    "automations.addAddonsDescirptionForAdmin": "Voulez-vous procéder à l'achat de l'extension sélectionnée ? Le montant sera calculé au prorata à ${prorateAmount}, avec votre prochaine facture s'élevant à ${nextInvoiceAmount}. Cliquez sur 'Confirmer' pour finaliser.␣",
    "automations.addAddonsDescirptionForApprover": "Seuls les utilisateurs de niveau administrateur ont la possibilité d'acheter ou de se désabonner des extensions. Si des ajustements de vos abonnements aux extensions sont nécessaires, veuillez contacter votre administrateur.␣",
    "bot.notification.reconnectCalendar": "Rétablir la connexion.",
    "calendar.sync.canNotUpdateSharedCalendar": "Vous avez connecté le calendrier {calendarName} {provider}. La modification du calendrier n'est pas autorisée. Pour changer le calendrier connecté, veuillez supprimer cette intégration et en créer une nouvelle.",
    "calendar.sync.confirmDelete": "Veuillez confirmer que vous souhaitez supprimer le calendrier <strong>{name}</strong>.",
    "calendar.sync.connect": "Connectez votre calendrier {provider}",
    "calendar.sync.connectCalendar": "Connecter le calendrier",
    "calendar.sync.connectCalendarCompletePlanyOnly": "PLAN COMPLET UNIQUEMENT",
    "calendar.sync.connectCalendarIcalOptionLabel": "N'importe quel calendrier (via le lien iCal)",
    "calendar.sync.connectCalendarOptionLabel": "{platform} calendrier",
    "calendar.sync.connectInfo": "Connectez votre compte {provider} et sélectionnez un calendrier partagé où tous les congés approuvés seront envoyés et synchronisés automatiquement.",
    "calendar.sync.createICalButton": "Créer un lien iCal",
    "calendar.sync.createICalFiltersInfo": "Les filtres suivants seront appliqués à ce lien de calendrier iCal:",
    "calendar.sync.createICalFiltersWarning": "Votre calendrier ne rafraîchit pas les données iCal en temps réel. Le délai peut aller jusqu'à 24 heures (selon votre fournisseur de calendrier). Pour des mises à jour en temps réel, connectez directement votre calendrier Google ou votre calendrier Microsoft Outlook.",
    "calendar.sync.createICalInfo": "Le lien de calendrier iCal vous permet de vous abonner et de voir les congés planifiés de votre équipe depuis Vacation Tracker dans n'importe quel calendrier (y compris Google Calendar, Microsoft Outlook Calendar, Apple Calendar, et bien d'autres).",
    "calendar.sync.createPushToSharedInfo": "Et envoyés et synchronisez les congés approuvés filtrés vers le calendrier {provider} partagé suivant:",
    "calendar.sync.createSyncCalendarButton": "Créer une synchronisation de calendrier",
    "calendar.sync.createSyncCalendarFiltersInfo": "Vacation Tracker appliquera les filtres suivants à tous les congés approuvés:",
    "calendar.sync.creating": "Création de la synchronisation du calendrier",
    "calendar.sync.creatingError": "Erreur lors de la création de la synchronisation du calendrier",
    "calendar.sync.creatingSuccess": "La création de la synchronisation du calendrier a réussi",
    "calendar.sync.deletedCalendar": "Cette synchronisation du calendrier a été supprimée",
    "calendar.sync.deleteTitle": "Supprimer le calendrier",
    "calendar.sync.deleting": "Suppression de la synchronisation du calendrier",
    "calendar.sync.deletingError": "Erreur lors de la suppression de la synchronisation du calendrier",
    "calendar.sync.deletingSuccess": "La suppression de la synchronisation du calendrier a réussi",
    "calendar.sync.emptyDataMessage": "Synchronisez et affichez tous les congés de l'équipe dans votre calendrier partagé. Vous pouvez connecter votre calendrier Outlook ou Google, ou utiliser iCal pour synchroniser Vacation Tracker avec n'importe quel autre type de calendrier.",
    "calendar.sync.emptyDataTitle": "Synchronisez les congés avec votre calendrier d'équipe",
    "calendar.sync.loginWithDifferentAccountInfo": "Vous ne voyez pas le calendrier ?",
    "calendar.sync.loginWithDifferentAccountLink": "Connectez-vous avec un autre compte {provider}",
    "calendar.sync.reconnect": "Reconnectez votre calendrier {provider}",
    "calendar.sync.reconnectInfo": "Reconnectez votre compte {provider} pour continuer la synchronisation automatique des congés avec votre calendrier partagé.",
    "calendar.sync.reconnecting": "Reconnexion de la synchronisation de calendrier",
    "calendar.sync.reconnectingError": "Erreur lors de la reconnexion de la synchronisation de calendrier",
    "calendar.sync.reconnectingSuccess": "La reconnexion de la synchronisation de calendrier a réussi",
    "calendar.sync.selectSharedCalendarPlaceholder": "Sélectionnez le calendrier partagé {provider}",
    "calendar.sync.stepByStep": "Instructions étape par étape",
    "calendar.sync.title": "Synchronisez votre calendrier d'équipe",
    "calendar.sync.tooltipInfo": "Tous vos calendriers synchronisés se regroupent sur cette page pour une gestion plus facile. Pour en savoir plus, veuillez consulter notre <helpDesk>article d'aide</helpDesk>",
    "calendar.sync.update": "Mettez à jour votre calendrier {provider}",
    "calendar.sync.updateSyncCalendarButton": "Mettre à jour la synchronisation de calendrier",
    "calendar.sync.updating": "Mise à jour de la synchronisation de calendrier",
    "calendar.sync.updatingError": "Erreur lors de la mise à jour de la synchronisation de calendrier",
    "calendar.sync.updatingSuccess": "La mise à jour de la synchronisation de calendrier a réussi",
    "calendarListModal.reconnectDescription": "Reconnectez votre calendrier pour continuer la synchronisation automatique des congés avec votre calendrier connecté.",
    "calendarListModal.reconnectTitle": "Reconnecter le calendrier",
    "choiceAutomations.activeSubscribedAutomations": "Automatisations actives auxquelles vous êtes abonné",
    "choiceAutomations.deacticate": "Se désabonner",
    "choiceAutomations.acticate": "Examiner et acheter",
    "choiceAutomations.cancelAtPeriodEnd": "Actif jusqu'à la fin de la période de facturation",
    "choiceAutomations.cancelAtPeriodEndTooltip": "Vous avez annulé votre abonnement à ce service additionnel. Il restera actif jusqu'à la fin de la période de facturation en cours et sera désactivé par la suite.",
    "choiceAutomations.notActivatedAddons": "Disponible à l'abonnement",
    "choiceAutomations.automationPrice": "Prix : ${price}/utilisateur/mois",
    "choiceAutomations.automationPriceInfo": "Vous pouvez acheter et activer l'une des automatisations ci-dessous pour $0.50/utilisateur/mois",
    "choiceAutomations.accessibleTo": "Accessible uniquement aux <approver>Approbateurs </approver> et aux <admin>Administrateurs </admin>",
    "components.createLeaveTypeForm.calendarStatusLabelTooltipInfo": "Choisissez 'Occupé' pour une absence complète comme les vacances, indiquant votre indisponibilité, ou 'Libre' pour les jours de travail à distance, indiquant que vous n'êtes pas au bureau mais disponible.",
    "components.entitlementByRole.additionalHours": "Heure(s) additionelle(s)",
    "components.entitlementByRole.completePlanOnly": "Cette fonctionnalité est exclusivement accessible aux utilisateurs abonnés à notre plan Complet.",
    "components.leavePolicyForm.accrualCapWarning": "La limite d'accumulation pour {leaveTypeName} a été atteint. L'accumulation de nouveaux jours reprendra une fois que certains jours existants seront utilisés.",
    "components.leavePolicyForm.accrualCapWarning2": "Cet utilisateur a atteint la limite d'accumulation de {leaveTypeName} et n'accumulera plus de jours tant qu'il n'aura pas utilisé une partie du solde actuel.",
    "components.logs.importLeaves": "<b>{user}</b> a importé {totalLeaves} {totalLeaves, plural, =1 {congé} other {congés}}.",
    "components.logs.seniorityEntitlementUpdated": "{user} a mis à jour l'avantage de la seniorité <b>{name}</b>.<br></br>Activé : {enabled}.<br></br>Proratisé : {prorated}.<br></br>Date de début : {startDate}.<br></br> Type de congé : {leaveType}.<br></br>Attribué : {entitlementPeriods}.<br></br>Sites : {locations}.<br></br>Équipes : {teams}.<br></br>Étiquettes : {labels}.<br></br>",
    "components.seniorityEntitlement.hoursAfter": "heure(s) après",
    "components.seniorityEntitlement.sameYearError": "Vous ne pouvez pas attribuer plusieurs règles au même nombre d'années.",
    "components.seniorityEntitlement.notSortedYearInPeriodError": "Les années doivent être triées chronologiquement.",
    "components.seniorityEntitlement.summaryInfoPrefix": "L'automatisation de l'ancienneté attribue des congés supplémentaires selon une approche basée sur des jalons. Par exemple:␣",
    "components.seniorityEntitlement.summeryInfoFirstRow": "{days} <showDays>jours</showDays><showHour>heure(s)</showHour> sont ajoutés au bout de {year} ans",
    "components.seniorityEntitlement.summeryInfoMoreDays": ", {days} de plus <showDays>jours</showDays><showHour>heure(s)</showHour> au bout de {year} ans",
    "components.seniorityEntitlement.summaryInfoSuffix": "pour un total de {days} <showDays>jours</showDays><showHour>heure(s)</showHour> de congé d'ancienneté.",
    "connectCalendar.errorReconnectDescription": "Une erreur s'est produite lors de la tentative de reconnexion de votre calendrier avec Vacation Tracker. Veuillez cliquer sur OK pour réessayer.",
    "connectCalendar.reconnect": "<r>Reconnecter</r>",
    "connectCalendar.reconnectError": "Erreur de reconnexion du calendrier",
    "connectCalendar.reconnectInfo": "Pour vous assurer que vos congés sont visibles, veuillez vous reconnecter à votre calendrier.",
    "connectCalendar.reconnectNotification": "La connexion à votre calendrier a expiré. Veuillez cliquer ici pour vous reconnecter et vous assurer que vos congés sont visibles dans votre calendrier.",
    "connectCalendar.reconnectNotificationSharedCalendar": "Votre connexion au calendrier partagé a expiré. Veuillez cliquer ici pour vous reconnecter et vous assurer que les congés sont visibles dans votre calendrier.",
    "connectCalendar.reconnectSuccess": "Vous avez réussi à reconnecter le calendrier {calendar} avec succès.",
    "connectedCalendar.eventDescriptionShared": "Événement de calendrier partagé ajouté par <link>Vacation Tracker</link>",
    "events.logs.AUTOMATION": "Automatisations",
    "app.leave": "Congé",
    "app.requestToil": "Requête congé compensatoire",
    "app.toil": "Congé Compensatoire",
    "bot.message.requestToil.initialHelp": "Je suis là pour vous aider à soumettre une demande de congé compensatoire. 🐨 Veuillez sélectionner un type de congé, puis cliquez sur Suivant.",
    "bot.notification.reminderSentToil": "Envoi du rappel de la requête de congé compensatoire",
    "bot.notification.requesterCard.addedLeaveToil": "Congé compensatoire a été créé pour vous",
    "bot.notification.requesterCard.approvedTitleToil": "Votre demande de congé compensatoire a été approuvée",
    "components.importUsersForm.warningOfUsersImportTrialWithoutPaymentMethod": "Vous êtes en période d'essai et vous ne serez pas facturé pour l'importation de tout utilisateur supplémentaire avant le {stripeCurrentPeriodEnd}. Ajoutez vos informations de facturation pour voir l'estimation des coûts.",
    "components.leaveBox.toilDuration": "Durée : {leave}",
    "components.leavePolicyForm.createLeaveTypeFormToilInfo1": "Vous pourrez mettre en place des politiques de temps compensatoire lorsque vous modifierez les politiques de congé pour un lieu spécifique.",
    "components.leavePolicyForm.createLeaveTypeFormToilInfo2": "L'option TOIL est exclusivement accessible avec le plan complet.",
    "components.logs.earned": "L'utilisateur a gagné {total} jours de {leaveTypeName} pour travail supplémentaire (temps compensatoire) {period}. {showExpiration, plural, =1 {Days will expire on } autre {}}{expiration}",
    "components.pricePlanCard.sharedCalendars": "Calendriers partagés",
    "components.toil.accrualsToilsTotal": "Total acquis {hours, plural, =1 {heure} autre {jours}} (temps compensatoire + accumulation) : {total}",
    "components.toil.added": "Congé compensatoire ajouté",
    "components.toil.addToil": "Ajoute congé compensatoire",
    "components.toil.addToilProgress": "Ajouter congé compensatoire",
    "components.toil.anotherAdd": "Ajoute un autre congé compensatoire",
    "components.toil.anotherRequest": "Ajouter un autre congé compensatoire",
    "components.toil.approveInProgress": "Approbation de la demande de congé compensatoire",
    "components.toil.bot.message.editedToilRequest": "Bonjour, <@{userSlackId}> a modifié la demande de congé compensatoire suivante :",
    "components.toil.bot.message.leaveTypeName": "Type de congé :\nCongé compensatoire - {leaveTypeName}",
    "components.toil.bot.message.requestToil": "Demander un congé compensatoire",
    "components.toil.bot.message.requestToilSummary": "Veuillez initier votre demande de congé compensatoire en spécifiant les dates auxquelles vous avez effectué des heures supplémentaires et inclure toutes remarques pertinentes. Notez que les demandes de congé compensatoire ne peuvent être effectuées que pour des dates passées, y compris aujourd'hui.",
    "components.toil.bot.message.submittedToilRequest": "Bonjour, <@{userSlackId}> a soumis la requête compensatoire suivante:",
    "components.toil.bot.notification.slack.toilRequestAdded": "Un congé compensatoire a été créé pour vous par un approbateur",
    "components.toil.bot.notification.slack.toilRequestAddedSelf": "Vous avez ajouté un congé compensatoire",
    "components.toil.bot.notification.slack.toilRequestApproved": "✅ <@{approverName}> a approuvé la requête, aucune autre action n'est requise.",
    "components.toil.bot.notification.slack.toilRequestApprovedTitle": "Votre demande de congé a été approuvée",
    "components.toil.bot.notification.slack.toilRequestCreated": "Bonjour, <@{userSlackId}> a soumis une requête de congé compensatoire.",
    "components.toil.bot.notification.slack.toilRequestEdited": "Bonjour, <@{userSlackId}> a modifié une requête de congé compensatoire.",
    "components.toil.cancelInProgress": "Annulation de la requête de temps compensatoire",
    "components.toil.earned": "Congé compensatoire acquis",
    "components.toil.deleteInProgress": "Suppression du congé compensatoire",
    "components.toil.denied": "Votre requête de temps compensatoire a été refusée",
    "components.toil.deniedBy": "Votre requête de temps compensatoire a été refusée par {approverName}.",
    "components.toil.denyInProgress": "Refuser une requête de temps compensatoire",
    "components.toil.durationLabel": "Durée du congé compensatoire le plus court",
    "components.toil.durationLabelTooltip": "Veuillez sélectionner la durée la plus courte qui s'applique à la requête de temps compensatoire. Cette sélection doit refléter le nombre minimum d'heures supplémentaires qu'un utilisateur peut faire.",
    "components.toil.edit": "Mettre à jour le temps compensatoire",
    "components.toil.edited": "Le temps compensatoire a été mis à jour",
    "components.toil.enableLabel": "Activer le temps compensatoire",
    "components.toil.enableLabelTooltip": "Le temps compensatoire est une pratique qui consiste à compenser les heures supplémentaires des travailleurs en leur accordant un congé équivalent, au lieu de leur payer une rémunération supplémentaire pour les heures additionnelles travaillées.",
    "components.toil.expiration": "Expiration",
    "components.toil.expirationDuration": "{noOfMonths} {noOfMonths, plural, =1 {month} autres {months}}",
    "components.toil.expirationDurationCalendar": "Fin de l'année fiscale",
    "components.toil.expirationLabel": "Est-ce que les jours compensatoires expirent ?",
    "components.toil.expirationLabelTooltip": "Activez ce paramètre si vous souhaitez que les jours compensatoires aient une date d'expiration, après laquelle ils ne peuvent plus être utilisés. En sélectionnant \"Oui\", vous serez invité à choisir les règles d'expiration. Si vous sélectionnez \"Non\", les jours compensatoires n'expireront pas et resteront disponibles jusqu'à ce qu'ils soient utilisés.",
    "components.toil.featurePlan": "La fonction de congé compensatoire est disponible uniquement pour le plan complet.",
    "components.toil.fullDayLeavePeriod": "{date}{endDate}␣",
    "components.toil.halfDay": "Demi-journée de congé compensatoire",
    "components.toil.hourly": "Heures compensatoires",
    "components.toil.lapsed": "périmé",
    "components.toil.locationNotHaveToil": "Aucune politique de congé compensatoire n'est mise en place pour {me, plural, =1 {your} l'autre {the user's}} site.",
    "components.toil.notification.added": "Congé compensatoire <forUser>de </forUser>{requestor} a été ajouté <byActionCreator>par {actionCreator}</byActionCreator>",
    "components.toil.notification.approved": "Le congé compensatoire de {requestor} a été approuvé <byActionCreator>par {actionCreator}</byActionCreator> ✅",
    "components.toil.notification.cancelled": "Le congé compensatoire de {requestor} a été annulé <byActionCreator>par {actionCreator}</byActionCreator>",
    "components.toil.notification.created": "Demande de congé compensatoire effectuée <byActionCreator>par {actionCreator}</byActionCreator>",
    "components.toil.notification.deleted": "Congé compensatoire supprimé <byActionCreator>par {actionCreator}</byActionCreator>",
    "components.toil.notification.denied": "La demande de congé compensatoire de {requestor} a été refusé <byActionCreator>par {actionCreator}</byActionCreator> ❌",
    "components.toil.notification.edited": "Le congé compensatoire de {requestor} a été modifié <byActionCreator>par {actionCreator}</byActionCreator>",
    "components.toil.notification.editedApproved": "Un congé compensatoire planifié a été modifié <byActionCreator>par {actionCreator}</byActionCreator> ✅",
    "components.toil.notification.editedDenied": "La modification du congé compensatoire a été refusé <byActionCreator>par {actionCreator}</byActionCreator>",
    "components.toil.partDayPeriod": "{date} - de {partDayStartHour}{hourFormat24, sélectionner, true {:00} autre {}}  à {partDayEndHour}{hourFormat24, select, true {:00} autre {}}␣",
    "components.toil.reasonDefault": "Heures supplémentaires travaillées pendant la période spécifiée ci-dessus.",
    "components.toil.request": "Demande de congé compensatoire",
    "components.toil.requested": "Demande de congé compensatoire effectuée",
    "components.toil.requestedOrAddedDaysHours": "{action, plural, =1 {Requested} autre {Added}} {period}",
    "components.toil.requestNotFound": "Demande de congé compensatoire introuvable",
    "components.toil.requestToil": "Demande congé compensatoire",
    "components.toil.requestToilProgress": "Demander un congé compensatoire",
    "components.toil.requester": "Qui peut demander du congé compensatoire ?",
    "components.toil.requesterAdmin": "Seuls les administrateurs et les approbateurs",
    "components.toil.requesterAll": "Tous les utilisateurs",
    "components.toil.requesterTooltip": "Choisissez \"Tous les utilisateurs\" pour permettre à chaque employé de soumettre ses propres demandes de TOIL après avoir travaillé des heures supplémentaires. Optez pour \"Seuls les administrateurs et les approbateurs\" si vous préférez centraliser la gestion du TOIL et maintenir un contrôle plus strict sur son approbation et son enregistrement.",
    "components.toil.submitError": "Erreur de soumission de congé compensatoire",
    "components.toil.submitErrorDate": "La demande de congé compensatoire ne peut pas être soumise car la date de début que vous avez sélectionnée est après la date de fin. Assurez-vous que la date de début est antérieure à la date de fin, puis soumettez à nouveau la demande",
    "components.toil.submitErrorDateFuture": "La date de congé compensatoire demandée doit être dans le passé",
    "components.toil.submitErrorOverlap": "Les jours de congé compensatoire demandés se confondent avec des jours de congé compensatoire existants",
    "components.toil.submitErrorNotOpen": "Le congé compensatoire n'est pas ouvert",
    "components.toil.submitErrorPlan": "Cette fonctionnalité est exclusivement accessible aux utilisateurs abonnés à notre plan complet.",
    "components.toil.submitErrorPolicy": "La politique de congé pour ce type de congé n'autorise pas le temps compensatoire.\n",
    "components.toil.submitErrorPolicyLocation": "L'option de temps compensatoire n'est pas activée pour votre site. Vous pouvez l'activer sur la page des politiques de congé ou contacter votre administrateur pour obtenir de l'aide.\n",
    "components.toil.submitErrorReasonNotSet": "Le motif du congé compensatoire est requis",
    "components.toil.submitErrorRole": "Demande de temps compensatoire non autorisée",
    "components.userLogsTab.TOIL_REQUEST_APPROVED": "Congé compensatoire",
    "leaveRequests.leaveRequestInfoTitleToil": "<strong>{name}</strong> a soumis la demande de congé compensatoire suivante :",
    "leaveRequestStatus.APPROVED": "approuvé",
    "leaveRequestStatus.CANCELLED": "annulé",
    "leaveRequestStatus.DELETED": "supprimé",
    "leaveRequestStatus.DENIED": "refusé",
    "leaveRequestStatus.EXPIRED": "expiré",
    "leaveRequestStatus.OPEN": "ouvert",
    "user.deleteToil": "Supprimer congé compensatoire",
    "user.deleteToilConfirmText": "Veuillez confirmer que vous souhaitez supprimer le congé compensatoire.",
    "components.toil.editRequestToil": "Modifier la demande de congé compensatoire",
    "components.toil.editToilProgress": "Modifier le congé compensatoire",
    "components.pricePlanCard.coreDepartmentsAndLocations": "Créez jusqu'à 3 sites et 10 départements",
    "components.pricePlanCard.disiableLimitTeam": "Vous ne pouvez pas passer au plan CORE tant que vous n'avez pas supprimé certains départements.",
    "components.pricePlanCard.disableLimitLocation": "Vous ne pouvez pas passer au plan CORE tant que vous n'avez pas supprimé certains sites.",
    "error.TeamLimitReached": "Le nombre actuel de vos départements dépasse la limite autorisée dans le plan CORE. Pour passer à un plan inférieur, veuillez réduire le nombre de vos départements à {limitNumber} ou moins, ou contactez notre support pour obtenir de l'aide.",
    "error.LocationLimitReached": "Nous sommes désolés, mais vous ne pouvez pas passer au plan CORE car vous dépassez actuellement la limite de {limitNumber} sites. Veuillez ajuster vos sites pour répondre aux critères du plan CORE ou contactez le service d'assistance pour plus d'informations.",
    "locationForm.locationLimitReached": "Oops! Il semble que vous ayez atteint la limite de {limitNumber} sites pour votre plan actuel. Pour gérer ou mettre à niveau votre plan, veuillez visiter la <billingPage>page de facturation</billingPage>.",
    "locations.locationLimitReached": "Vous utilisez actuellement tous les {limitNumber} sites disponibles dans le plan CORE. Pour gérer ou mettre à niveau votre plan, veuillez visiter la <billingPage>page de facturation</billingPage>.",
    "teamForm.teamLimitReached": "Oops! Vous avez atteint la limite de {limitNumber} départements de votre plan actuel. Pour gérer ou mettre à niveau votre plan, veuillez visiter la <billingPage>page de facturation</billingPage>.",
    "teams.teamsLimitReached": "Vous utilisez actuellement tous les {limitNumber} sites disponibles dans le plan CORE. Pour gérer ou mettre à jour votre plan, veuillez visiter la <billingPage>page de facturation</billingPage>.",
    "app.substituteApprover": "Approbateur remplaçant",
    "bot.message.error.userCannotBeFound": "L'utilisateur sélectionné ne peut pas être trouvé dans votre organisation. *Veuillez contacter l'administrateur de votre organisation ou le support de Vacation Tracker pour plus d'informations.",
    "bot.message.selectSubstituteApprover": "*Sélectionner un approbateur remplaçant* \nSélectionnez une personne pour gérer vos requêtes de congé pendant votre absence.",
    "bot.notification.submittedLeaveRequestReminder": "Bonjour, juste un rappel que @{name} a soumis une demande qui nécessite votre attention.",
    "bot.message.substituteApproverPlaceholder": "Aucun(e)",
    "bot.notification.substituteApproverSelected": "Vous avez été sélectionné par {creatorName} comme approbateur remplaçant pour le(s) département(s) {teamNames} au nom de {approverName} pendant son absence. En tant qu'approbateur remplaçant, vous serez responsable des demandes de congés soumises à {approverName} du {startDate} au {endDate}.",
    "bot.notification.substituteApproverUpdated": "Votre période en tant qu'approbateur remplaçant est mise à jour du {startDate} au {endDate}.",
    "bot.notification.substituteApproverDeleted": "Votre rôle d'approbateur remplaçant pour la période du {startDate} au {endDate} a été annulé.",
    "components.leaveForm.selectSubstituteApprover": "Sélectionner un approbateur remplaçant",
    "components.leaveForm.selectSubstituteApproverInfoMessage": "Sélectionnez une personne pour gérer vos requêtes de congé pendant votre absence. L'approbateur suppléant aura des droits limités, puisqu'il ne pourra qu'approuver ou refuser les demandes de congé. Cette étape est optionnelle.",
    "components.pricePlanCard.substituteApprovers": "Approbateurs remplaçants",
    "app.billingStatus.ACTIVE": "Paiement actif",
    "app.billingStatus.CANCEL_AT_PERIOD_END": "Actif jusqu'à la fin de la période de facturation",
    "app.billingStatus.CANCELED": "Paiement annulé",
    "app.billingStatus.GRANT_PERIOD": "Période de gratuité",
    "app.billingStatus": "Statut de facturation",
    "app.continue": "Continuer",
    "app.enabled": "Activé",
    "app.importLeaves.errors.dateFromAfterDateTo": "La date de début est après la date de fin",
    "app.importLeaves.errors.dateFromRequired": "La date de début est obligatoire",
    "app.importLeaves.errors.dateFromNotValid": "La date de début n'est pas valide",
    "app.importLeaves.errors.dateToNotValid": "La date de fin n'est pas valide",
    "app.importLeaves.errors.dateToRequired": "La date de fin est obligatoire",
    "app.importLeaves.errors.hourFromAfterHourTo": "L'heure de début est après l'heure de fin",
    "app.importLeaves.errors.hourFromNotValid": "L'heure de début n'est pas valide, entrez une valeur entre 0 et 24",
    "app.importLeaves.errors.hourFromRequired": "L'heure de début est obligatoire",
    "app.importLeaves.errors.hourToNotValid": "L'heure de fin n'est pas valide, entrez une valeur entre 0 et 24",
    "app.importLeaves.errors.hourToRequired": "L'heure de fin est obligatoire",
    "app.importLeaves.errors.leavePolicyError": "La politique de congé n'autorise que des jours complets",
    "app.importLeaves.errors.leaveTypeNotFound": "Type de congé non trouvé",
    "app.importLeaves.errors.leaveTypeNotInUserLocation": "Le type de congé n'est pas disponible sur le site de l'utilisateur",
    "app.link": "Lien",
    "components.seniorityEntitlement.addDaysFrom": "Ajouter des jours à partir de",
    "automationsForm.seniorityEntitlement.employeeStartDate": "Date de début de l'employé",
    "automationsForm.seniorityEntitlement.beginningOfTheFiscalYear": "Début de l'année fiscale",
    "automationsForm.seniorityEntitlement.endOfTheFiscalYear": "Fin de l'année fiscale",
    "components.createLeaveTypeForm.assignAndConfigure": "Attribuer et configurer",
    "components.createLeaveTypeForm.continueCreatingLeaveType": "Oui, je veux créer un type de congé",
    "components.createLeaveTypeForm.similarLeaveTypeChecking": "Vérification des types de congé existants...",
    "components.createLeaveTypeForm.similarLeaveTypeInfoAreYouSure": "Voulez-vous toujours créer un nouveau type (<strong>{newLeaveTypeName}</strong>)?",
    "components.createLeaveTypeForm.similarLeaveTypeInfoAssigningTip": "Vous pouvez attribuer un type de congé à plusieurs sites et personnaliser les règles par site (tels que les quotas, les reports, les cumuls, etc.). Pas besoin de créer des types distincts pour chaque endroit!",
    "components.createLeaveTypeForm.similarLeaveTypeInfoRecommend": "Nous recommandons d'utiliser un seul type de congé sur plusieurs sites pour maintenir la cohérence et les données de congé des utilisateurs.",
    "components.createLeaveTypeForm.similarLeaveTypeInfoSetRules": "Définir les règles spécifiques au site pour:",
    "components.createLeaveTypeForm.similarLeaveTypeInfoSimilarLeaveType": "Un type de congé similaire existe déjà (<strong>{leaveTypeName}</strong>).",
    "components.createLeaveTypeForm.similarLeaveTypeTitle": "Type de congé similaire",
    "components.leaveTypeForm.editLeaveTypeTitle": "Modifier le type de congé {leaveTypeName}",
    "components.logs.automationAddonsUpdatedPurchased": "{user} a acheté le module complémentaire : {automationType}",
    "components.logs.automationAddonsUpdatedUnsubscribed": "{user} s'est désabonné du module complémentaire : {automationType}",
    "components.logs.entitlementByRoleCreated": "{user} a créé un droit lié au rôle <b>{name}</b>.<br></br>Activé : {enabled}.<br></br>Jours : {days}.<br></br>Étiquette : {label}.<br></br>Type de congé : {leaveType}.<br></br>",
    "components.logs.entitlementByRoleUpdated": "{user} a mis à jour un droit lié au rôle <b>{name}</b>.<br></br>Activé : {enabled}.<br></br>Jours : {days}.<br></br>Étiquette : {label}.<br></br>Type de congé : {leaveType}.<br></br>",
    "components.logs.entitlementByRoleDeleted": "{user} a supprimé un droit lié au rôle.",
    "NE-KORISTI-SE": "Not in use",
    "-NE-KORISTI-SE": "Not in use",
    "components.logs.userSeniorityEntitlement": "L'utilisateur a reçu {totalDays} jour(s) de {leaveTypeName} grâce aux avantages liés à l'ancienneté",
    "components.logs.userSeniorityEntitlementOverride": "Les jours de droit de l'utilisateur pour {leaveTypeName} ont été remplacés par {totalDays} jour(s) en raison des ajustements liés aux avantages d'ancienneté",
    "components.logs.userEntitlementByRole": "L'utilisateur a reçu {totalDays} jour(s) de {leaveTypeName} grâce aux avantages liés au rôle",
    "components.logs.userEntitlementByRoleOverride": "Les jours de droit de l'utilisateur pour {leaveTypeName} ont été remplacés par {totalDays} jour(s) en raison des ajustements liés aux avantages par rôle",
    "components.logs.userEntitlementByRoleDeleted": "Les jours de droit de l'utilisateur liés au rôle pour {leaveTypeName} ont été supprimés en raison de la suppression ou de la désactivation de l'étiquette <b>{labelName}</b>.",
    "components.seniorityEntitlement.confirmDescription": "Choisir de commencer à calculer les avantages liés à l'ancienneté à partir de la date de début de l'employé ajustera rétrospectivement les quotas des utilisateurs à partir de l'année de début du calcul.",
    "components.seniorityEntitlement.confirmTitle": "Confirmer la création de l'automatisation",
    "components.seniorityEntitlement.startApplyingSeniorityEntitlementFromInfo": "Si vous choisissez de commencer à appliquer les avantages liés à l'ancienneté à partir de la date de début de l'employé, cette modification affectera les quotas de l'utilisateur dans le passé.",
    "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE": "Droit lié au rôle",
    "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_OVERRIDE": "Remplacement du droit lié au rôle",
    "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_DELETED": "Droit lié au rôle supprimé",
    "components.userLogsTab.USER_SENIORITY_ENTITLEMENT": "Droit lié à l'ancienneté",
    "components.userLogsTab.USER_SENIORITY_ENTITLEMENT_OVERRIDE": "Remplacement du droit lié à l'ancienneté",
    "connect.google.sharedPermissionsConsent": "En cliquant sur le bouton ci-dessous, vous autorisez Vacation Tracker à accéder à l'annuaire des utilisateurs de votre admin Google.",
    "connect.google.sharedPermissionsError": "Échec du partage des autorisations Google, votre lien a expiré.",
    "connect.google.sharedPermissionsRequired": "Autorisations insuffisantes",
    "connect.google.sharedPermissionsRequiredDescription": "Pour procéder à l'importation des utilisateurs, vous devrez obtenir les autorisations de votre administrateur Google. Veuillez partager ce lien avec votre administrateur pour accorder le consentement nécessaire: ",
    "connect.google.sharedPermissionsSuccess": "Autorisations Google partagées avec succès!",
    "connect.google.sharedPermissionsSuccessRedirect": "Vous serez redirigé vers la page d'accueil de Vacation Tracker dans quelques secondes.",
    "connect.signInWithSlackInstallBot": "Ajouter Vacation Tracker à Slack",
    "connect.slack.usersLoginRequiredButton": "Accorder les autorisations Slack",
    "connect.welcome": "Bienvenue, {name}!",
    "connect.slackPostInstallation1": "Vous avez installé avec succès l'application Slack de Vacation Tracker 🎉",
    "connect.slackPostInstallation2": "Pour commencer à utiliser l'application, veuillez vous connecter au tableau de bord Vacation Tracker et terminer la configuration.",
    "connect.slackPostInstallation3": "Nous allons vous poser quelques questions simples. Cela ne devrait pas prendre plus de quelques minutes. Un clin d'œil et c'est fait (comme vos dernières vacances).",
    "connect.slackPostInstallationError.invalidCode": "Le code de redirection n'est plus valide. Cela peut arriver si le code a expiré, a déjà été utilisé ou si vous avez actualisé la page avant la fin du processus de connexion. Mais ne vous inquiétez pas, obtenons-en un nouveau!",
    "connect.slackPostInstallationError.generic": "Hé, nous devons d'abord installer le bot Vacation Tracker. Vous pourrez examiner les autorisations de l'application Slack Vacation Tracker et les accorder à l'étape suivante.",
    "dashboard.accountSettingsOutOfOfficeConnectedWith": "Connecté avec",
    "dashboard.accountSettingsOutOfOfficeChangeModalInfo": "Vacation Tracker définira tous vos futurs messages d'absence pour l'adresse e-mail {email}.\n Cependant, si vous avez programmé un message d'absence avant ce changement, nous le définirons toujours pour l'ancienne adresse e-mail.",
    "dashboard.accountSettingsOutOfOfficeChangeModalTitle": "Modifier l'adresse e-mail pour les futurs messages d'absence",
    "error.leavePolicyMissing": "Politique de congé manquante",
    "error.UserNotFoundApprover": "Approbateur non trouvé",
    "notifications.updateAutomationConfirm": "La désactivation d'une automatisation n'annulera pas l'abonnement. Si vous souhaitez désactiver l'abonnement, vous devez accéder à la liste des automatisations en cliquant sur \"Créer une automatisation\", puis cliquer sur le bouton \"Se désabonner\"",
    "notifications.updateAutomationTitle": "Mettre à jour l'automatisation",
    "components.leavePolicyForm.yearlyQuotaInfo": "Cette rubrique représente le quota annuel de base pour les congés. Vous pourrez ajuster les quotas individuels manuellement via les profils d'utilisateurs ou automatiser les ajustements pour différents utilisateurs avec notre fonctionnalité d'automatisation.",
    "components.logs.substituteApproverCreated": "{substituteApproverName} a été désigné comme Approbateur Substitut pour {approverFor} par {creator} à partir du {startDate} jusqu'au {endDate}.",
    "components.logs.substituteApproverDeleted": "{creator} a supprimé {substituteApproverName} en tant qu'Approbateur Substitut pour les dates du {startDate} au {endDate}.",
    "components.logs.substituteApproverUpdated": "{creator} a mis à jour l'approbateur substitut.<br></br>Nom de l'Approbateur substitut : {substituteApproverName}<br></br>Date de début : {startDate}<br></br>Date de fin : {endDate}<br></br>",
    "bot.message.leaveRequest.FULL-DAY": "{leaveTypeName}",
    "bot.message.leaveRequest.ONE-HOUR": "{leaveTypeName} (Horaire)\n",
    "bot.message.leaveRequest.HALF-DAY": "{leaveTypeName} (Demi-journée)",
    "error.slack.allowConversationWithSlackBotTitle": "Autoriser la conversation avec le bot Vacation Tracker",
    "error.slack.allowConversationWithSlackBotDescription": "Cette fonctionnalité a besoin d'une autorisation Slack supplémentaire qui permet au bot Vacation Tracker de recevoir des messages directs. Notre bot pourra lire les messages que vous et les autres utilisateurs de votre espace de travail Slack lui enverrez, mais il n'aura pas l'autorisation de lire vos canaux, groupes ou messages directs.",
    "error.slack.allowConversationWithSlackBotLlmWarningTitle": "Clause de non-responsabilité pour les grands modèles de langage (LLM)",
    "error.slack.allowConversationWithSlackBotLlmWarningLine1": "Le bot de Vacation Tracker prend en charge une commande slash et un raccourci pour les demandes de congé. En plus de cela, vous pouvez activer la conversation en langage humain sur le tableau de bord Web (et choisir d'utiliser le LLM).",
    "error.slack.allowConversationWithSlackBotLlmWarningLine2": "L'activation de cette fonction peut parfois amener le bot à suggérer des dates ou des types de congés incorrects. Il est important de vérifier ses recommandations avant de faire une demande de congé.",
    "error.slack.allowConversationWithSlackBotLlmWarningLine3": "Ni Vacation Tracker ni ses tiers n'utilisent vos informations pour entraîner ses grands modèles de langage.",
    "users.deleteUser": "Supprimer l'Utilisateur",
    "users.deleteUserUserConfirm": "Êtes-vous sûr de vouloir supprimer {name}?",
    "app.deleteUser": "Supprimer l'Utilisateur",
    "app.emailDeleted": "Adresse email supprimée",
    "bot.message.sorryYouAreNotExist": "Désolé, votre compte n'a pas été trouvé",
    "bot.message.sorryYouAreNotActive": "Désolé, votre compte est inactif",
    "addonsPaymentStatus.activeUntilBillingPeriodEnds": "Actif jusqu'à la fin de la période de facturation",
    "addonsPaymentStatus.canceled": "Annulé",
    "addonsPaymentStatus.grantPeriod": "Période de subvention",
    "addonsPaymentStatus.paymentActive": "Paiement actif",
    "app.addons": "Extensions",
    "app.addOns": "Extensions",
    "app.integrations": "Intégrations",
    "app.label": "Étiquette",
    "app.view": "Afficher",
    "automations.addAddonsDescirptionForAdminInTrial": "Ce module complémentaire sera facturé sur votre prochaine facture : ${total}.00 basé sur {userCount} utilisateurs à ${addonsPrice} par add-on. Pendant la période d'essai, vous pouvez l'activer et le désactiver librement sans frais si aucun module complémentaire n'est actif à la fin.",
    "automations.createAutomation": "Créer une automatisation",
    "automations.discoveryMoreAddons": "Découvrez plus d'extensions",
    "automations.entitlementByRole.days": "Jours",
    "automations.myAddons": "Mes extensions",
    "automations.OPEN_API": "API ouvert",
    "automations.reSubscribeAddOns": "Se réabonner à l'extension",
    "automations.reSubscribeAddonsDescirptionForAdmin": "Voulez-vous vraiment vous réabonner à cette extension ? Vous serez facturé ${addonsPrice} par utilisateur et par mois à partir de votre prochaine facture. Les frais du premier mois seront calculés au prorata en fonction du nombre de jours d'utilisation de l'extension.",
    "automations.reSubscribeAddonsDescirptionForAdminInTrial": "Voulez-vous vraiment vous réabonner à cette extension ? Elle restera gratuite jusqu'à la fin de la période d'essai. Ensuite, un montant de ${addonsPrice} par utilisateur et par mois sera appliqué.",
    "automations.reSubscribeInfo": "Renouvelez votre abonnement à l'extension et continuez à utiliser nos fonctionnalités avancées. Cliquez sur Réabonner pour continuer.",
    "automations.seniorityEntitlement.periodsViewTable": "{days} jours après {years} années",
    "automations.showCaneledAddons": "Afficher les extensions annulées",
    "automations.unsubscribeInfo": "Vous êtes actuellement abonné à cette extension pour ${addonsPrice} par utilisateur. Si vous souhaitez vous désabonner, cliquez sur le bouton de désabonnement à droite.",
    "automations.VISIBILITY": "Gestionnaire de visibilité",
    "choiceAutomations.activeSubscribedAddons": "Extensions souscrites actives",
    "choiceAutomations.mySbscribedAddons": "Mes extensions souscrites",
    "choiceAutomations.openApiInfo": "Fournit une intégration transparente avec les systèmes externes, permettant une synchronisation automatisée des données et une fonctionnalité améliorée.",
    "choiceAutomations.reviewAndBuy": "Examiner et acheter",
    "choiceAutomations.visibilityInfo": "Permet aux administrateurs de personnaliser la visibilité des informations sur les congés pour les utilisateurs réguliers, améliorant ainsi la confidentialité et la pertinence des données.",
    "components.feedbackModal.namePlaceholder": "Entrez votre nom",
    "components.feedbackModal.yourName": "Votre nom",
    "components.pricePlanCard.disableLimitSubdepartments": "Dans le plan Core, les départements ne peuvent être imbriqués qu'à un seul niveau de profondeur. Vous avez actuellement {numberOfLevels} niveaux de sous-départements. Veuillez supprimer certains départements pour passer au plan Core.",
    "error.DepartmentWithChildrenDeleteForbidden": "Vous ne pouvez pas supprimer un département qui a des sous-départements. Veuillez d'abord supprimer les sous-départements.",
    "error.team.delete": "Le département ne peut pas être supprimé",
    "sidebar.addons": "Extensions",
    "teamForm.setAsSubdepartment": "Choisissez le département parent",
    "ai.assistant.defaultMessage": "En quoi puis-je vous aider ?",
    "ai.assisted.onboarding.companyLogUserNameDecorator": "(par l'intermédiaire de l'assistant IA)",
    "ai.assisted.onboarding.finalAssistantMessage": "Votre processus d'intégration est terminé. Vacation Tracker va maintenant configurer le compte de votre entreprise avec cette configuration. N'oubliez pas que vous pouvez toujours modifier votre configuration plus tard si nécessaire.",
    "ai.assisted.onboarding.initialAssistantMessage": "Pendant que vous vous inscriviez, nous avons déjà mis en place certaines choses pour vous :\n\nLes sites ({locations}),\nLes départements ({departments}), et\nLes types de congé ({leaveTypes}).\n\nTous vos employés travaillent-ils sur ce site ?",
    "ai.assisted.onboarding.initialUserMessage": "Bonjour ! Je suis votre assistant AI. Je suis là pour vous aider à démarrer avec le compte de votre nouvelle entreprise.\n\nCette configuration AI est bien sûr optionnelle. Vous pouvez la passer et configurer le système vous-même. (Vous voudrez probablement commencer dans la section \"Paramètres\" de notre menu). Cependant, je vais vous guider à travers quelques parties essentielles de Vacation Tracker et tout configurer pour vous en quelques minutes. Nous ne stockons aucune de vos données privées (mais veuillez ne pas partager d'informations sensibles avec moi).",
    "ai.assisted.onboarding.sendMessageError": "Il semble que la réponse de l'assistant AI prenne trop de temps. Veuillez relancer l'opération.",
    "app.AIAssistant": "Assistant IA",
    "app.feature.access": "Disponible exclusivement dans le plan Complete.",
    "app.goodAfternoon": "Bon après-midi",
    "app.goodEvening": "Bonne soirée",
    "app.goodMorning": "Bonjour",
    "app.notDone": "Pas encore terminé",
    "app.rename": "Renommer",
    "app.substituteApproverSet": "Vous avez été désigné comme approbateur suppléant.",
    "automations.deactivateVisibilityWarn": "Désactiver une automatisation ne mettra pas fin à l'abonnement. Si vous souhaitez désactiver l'abonnement, vous devez cliquer sur le bouton \"Se désabonner\".",
    "automations.departmentVisibilityLevelInfo": "Les utilisateurs ne verront que les informations relatives aux congés au sein de leur propre département. Les approbateurs verront les informations relatives aux congés dans leur propre département et dans tous les départements pour lesquels ils sont approbateurs, même s'ils ne sont pas membres. Les administrateurs verront toujours tout.",
    "automations.visibility.createInProgress": "Limitation de la visibilité en cours",
    "automations.visibility.createInProgressCompleted": "Limitation de la visibilité complétée",
    "automations.visibility.updateInProgress": "Mise à jour de l'extension de visibilité",
    "automations.visibility.updateInProgressCompleted": "Mise à jour de l'extension de visibilité terminée",
    "automations.visibilityForm.DEPARTMENT": "Leur département",
    "automations.visibilityForm.limitVisibility": "Limiter la visibilité pour les utilisateurs",
    "automations.visibilityForm.visibilityLevel": "Les utilisateurs peuvent voir",
    "automations.visibilityForm.visibilityTypeValidationRequired": "Vous devez sélectionner au moins un type de visibilité",
    "automations.visibilityManagerNotificationsInfo": "Les calendriers partagés et les notifications créés avant la modification des paramètres de visibilité doivent être supprimés manuellement s'ils ne sont pas en conformité avec les nouvelles règles.",
    "billing.pricingPlansText": "Cette section affiche votre plan d'abonnement. Vous pouvez vous réabonner au plan que vous aviez précédemment ou changer de plan avant de vous réabonner.",
    "billing.subscriptionExpiredText": "Votre abonnement a expiré. Réinscrivez-vous pour continuer à utiliser Vacation Tracker. Si vous avez des problèmes ou des questions, contactez notre {supportLink}.",
    "billing.SubscriptionPlan": "Plan d'abonnement",
    "components.billingCouponCode.text": "Avez-vous un code promo ? Saisissez-le ici pour l'appliquer à votre abonnement.",
    "components.billingInfo.text": "Vos informations de facturation sont utilisées pour traiter les paiements de votre abonnement. Vous pouvez mettre à jour vos informations de facturation et ajouter les informations relatives à votre carte de crédit ici.",
    "components.leavePolicyForm.accrualWeeklySummary": "Les utilisateurs accumuleront {amount} ({quota}/52) jours tous les {day}",
    "components.leavePolicyForm.accrualWeeklySummaryHours": "Les utilisateurs accumuleront {amount} ({quota}/52) heures tous les {day}\n\n\n\n\n",
    "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedUserInfo": "Selon la politique de votre organisation, l'accumulation des congés est suspendue lorsque le compte de cet utilisateur est inactif. Veuillez contacter votre administrateur pour plus de détails.",
    "components.userLeaveQuotas.accrualsTooltipTitle-weekly": "Une accumulation hebdomadaire {leaveType} a lieu chaque semaine",
    "form.department.generalDescriptionCreate": "Définissez le nom de votre département et sélectionnez ses utilisateurs et ses approbateurs. Si vous souhaitez configurer le département avant d'ajouter des utilisateurs, vous pouvez créer le département d'abord et ajouter des utilisateurs ensuite, mais il est nécessaire d'ajouter au moins un approbateur.",
    "form.department.generalDescriptionEdit": "Modifiez le nom de votre département ou ses utilisateurs et approbateurs.",
    "form.department.generalTitle": "Paramètres du département",
    "form.department.setDefaultDescription": "Département par défaut",
    "form.department.setDefaultTitle": "Département par défaut",
    "form.department.subdepartmentDescription": "Vous pouvez imbriquer des départements pour créer une structure arborescente. Si c'est un sous-département, vous pouvez définir son département parent dans cette section.",
    "form.department.subdepartmentTitle": "Paramètres du sous-département",
    "form.general.companySettingsDescription": "Indiquez le nom de votre entreprise et le courriel du contact principal. Le courriel que vous fournissez ne sera utilisé que pour les communications importantes sur les produits. Nous vous promettons qu'il n'y aura pas de spam.",
    "form.general.companySettingsTitle": "Paramètres de l'entreprise",
    "form.general.configurationDescription": "Cette section vous permet d'affiner des aspects spécifiques du suivi des congés pour votre entreprise. Activez les dates de fin des utilisateurs pour désactiver automatiquement les employés avec une date de fin, les empêchant de soumettre des demandes de congé après leur départ. Choisissez de suivre les quotas de congés en jours ou en heures, selon les politiques de votre entreprise. De plus, définissez l'année de début pour le suivi des congés. Par défaut, les calculs de congés commencent à partir de l'année de votre inscription, mais vous pouvez ajuster cela pour inclure des données historiques provenant d'autres systèmes ou tableurs.",
    "form.general.configurationTitle": "Configuration",
    "form.general.migrationDescription": "Utilisez-vous Slack, Microsoft Teams ou Google Workspace ? Vous pouvez passer de l'authentification par e-mail et mot de passe à une intégration spécifique à une plateforme. Passer à l'une de nos intégrations simplifierait le processus de connexion et d'invitation des utilisateurs (importer les utilisateurs depuis votre annuaire d'utilisateurs) et vous offrirait des intégrations supplémentaires, telles que les onglets Microsoft Teams et les bots Microsoft Teams ou Slack.",
    "form.general.migrationTitle": "Passer à une autre plateforme",
    "general.importUsersConfigSectionDescription": "Cette section permet d'activer l'importation automatique d'utilisateurs à partir de votre répertoire d'utilisateurs et de configurer les paramètres d'importation d'utilisateurs, tels que le message d'annonce, le département par défaut et l'emplacement par défaut pour les nouveaux utilisateurs.",
    "surveyAboutUs.OptionQuestion1-4-1": "C'est génial ! Qui nous a recommandé ?",
    "surveyAboutUs.OptionQuestion1-4-1-1": "Un collègue ou un ami",
    "surveyAboutUs.OptionQuestion1-4-1-2": "Une autre entreprise l'a suggéré",
    "surveyAboutUs.OptionQuestion1-4-1-3": "Je l'ai déjà utilisé dans le passé",
    "surveyAboutUs.OptionQuestion1-5": "Autre",
    "addonsPaymentStatus.activateForFree": "Activer gratuitement",
    "ai.assisted.onboarding.openSetupNotification": "Voir ce qui a été fait jusqu'à présent",
    "ai.assisted.onboarding.openSetupNotificationFetchingSetup": "Merci d'avoir fourni toutes les informations nécessaires. Je commence maintenant à configurer votre Vacation Tracker. Vous verrez bientôt des mises à jour en temps réel...",
    "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigned": "Le type de congé {leaveTypeName} est attribué à {locationName}",
    "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigning": "Attribution du type de congé {leaveTypeName} à {locationName}",
    "ai.assisted.onboarding.openSetupNotificationLeavePolicySuccessAction": "{entityName} {entity} a été {action} avec succès",
    "ai.assisted.onboarding.openSetupNotificationNoData": "Aucune donnée de configuration trouvée",
    "ai.assisted.onboarding.openSetupNotificationTitle": "Statut de la configuration d'intégration",
    "app.applyTo": "Appliquer à",
    "app.creating": "Création en cours",
    "app.customWorkHours": "Heures de travail personnalisées",
    "app.deleting": "Suppression en cours",
    "app.departmentsSub": "Sous-départements",
    "app.downgradeWithoutUnsubscribe": "Rétrograder sans se désabonner",
    "app.downgradeWithSubscribe": "Rétrograder et se désabonner",
    "app.gotIt": "Compris",
    "app.importLeaves.errors.halfDay": "Le congé est supérieur à un jour",
    "app.importLeaves.errors.hourRange": "Les heures sélectionnées dépassent la durée de la journée de travail de cet utilisateur ({hours} heures). Veuillez ajuster votre sélection.",
    "app.leavePolicies": "Politiques de congé",
    "app.moreDetails": "Plus de détails",
    "app.setOn": "défini le",
    "app.updated": "mis à jour",
    "app.updating": "Mise à jour en cours",
    "app.workday": "Durée de la journée de travail",
    "app.workdayWarning": "L'option de congé d'une demi-journée n'est disponible que pour une journée de travail de 8 heures. Modifier la durée standard de la journée de travail à partir de 8 heures désactivera cette option.",
    "automations.addFreeAddonsDescirptionForAdmin": "Ce module complémentaire est inclus sans frais supplémentaires dans votre plan actuel. Vous pouvez l'activer et le désactiver librement pendant la durée de votre abonnement sans frais supplémentaires.",
    "automations.automationBlackoutPeriodDeleted": "Période non autorisée supprimée\n",
    "automations.automationEntitlementByRoleDeleted": "Droit basé sur le rôle supprimée\n \n",
    "automations.automationProbationPeriodDeleted": "Période de probation supprimée",
    "automations.automationSeniorityEntitlementDeleted": "Avantages liés à l'ancienneté supprimée",
    "automations.PROBATION_PERIOD": "Période de probation",
    "automations.probationPeriod.autoRejectReasonDefault": "Votre demande de congé a été automatiquement rejetée car vous êtes dans votre phase d'emploi initiale, période pendant laquelle les congés ne sont pas autorisés. Cette politique aide les nouveaux employés à s'immerger pleinement et à s'adapter à leur nouveau rôle et à la dynamique de l'équipe.",
    "automations.probationPeriod.createInProgress": "Création de la période de probation en cours",
    "automations.probationPeriod.createInProgressCompleted": "Création de la période de probation terminée",
    "automations.probationPeriod.lengthDays": "Durée (jours)",
    "automations.probationPeriod.name": "Nom de la politique de probation",
    "automations.probationPeriod.namePlaceholder": "Saisissez le nom de la politique de probation",
    "automations.probationPeriod.settings.applyToMDescription": "Vous pouvez choisir d'appliquer cette automatisation de manière universelle à tous les utilisateurs | ou de la personnaliser en fonction des départements, emplacements ou étiquettes spécifiques.",
    "automations.probationPeriod.settings.applyToMDescriptionTwo": "Vous pouvez également sélectionner un ou plusieurs types de congés auxquels vous souhaitez appliquer cette automatisation.",
    "automations.probationPeriod.settings.lengthDaysDescription": "Saisissez le nombre de jours pendant lesquels les nouveaux employés seront restreints de prendre certains types de congés, à partir de leur date de début d'emploi.",
    "automations.probationPeriod.settings.nameDescription": "Cette section vous permet de définir une politique qui restreint les nouveaux employés de prendre des congés pendant leur période initiale d'emploi.",
    "automations.probationPeriod.settings.nameDescriptionThree": "Assurez-vous que les utilisateurs reçoivent un message automatique expliquant pourquoi leur demande de congé a été refusée.",
    "automations.probationPeriod.settings.nameDescriptionTwo": "Saisissez le nombre de jours, et nous calculerons la période de probation en fonction de la date d'embauche de chaque utilisateur",
    "automations.probationPeriod.settings.nameTitle": "Définir la politique",
    "automations.probationPeriod.updateInProgress": "Mise à jour de la période de probation en cours",
    "automations.probationPeriod.updateInProgressCompleted": "Mise à jour de la période de probation terminée",
    "automations.probationPeriodMessages": "Votre période de probation dure jusqu'au {date}",
    "automations.stepByStepHelpDesk": "Pour des conseils détaillés sur la configuration de l'automatisation <automationName></automationName>, veuillez consulter les instructions pas à pas disponibles sur <link>la page d'aide</link>.",
    "automations.unsubscribeForFreeAddonsInfo": "Vous êtes actuellement abonné à cet add-on sans frais supplémentaires, car il est inclus dans votre plan. Si vous souhaitez vous désabonner, cliquez sur le bouton de désabonnement à droite.",
    "automations.visibilityInfo.settings.nameTitle": "Paramètres de visibilité",
    "bot.message.approveToil": "Approuver le Repos compensateur",
    "bot.message.deniedToil": "Repos compensateur refusé",
    "bot.message.probationPeriod": "*Période de probation*:  \nVotre période de probation dure jusqu'au {date}  \n\nRaison : _{message}_",
    "bot.message.sorryLetsSpokePriveate": "Chut, parlons en privé. 🤫",
    "bot.notification.requesterCard.probationPeriod": "Votre période de probation dure jusqu'au {date}",
    "bot.notification.requesterCard.probationPeriodReason": "Raison : {message}",
    "bot.notification.requesterCard.probationPeriodTitle": "Période de probation",
    "bot.slackFuckedUpAgain": "Slack a encore corrigé quelque chose, veuillez contacter notre support pour résoudre les bugs liés à Slack.",
    "choiceAutomations.includedInYourPlan": "Inclus dans votre plan",
    "components.locationForm.settings.makeDefaultDescription": "En définissant cet emplacement comme emplacement par défaut, tous les nouveaux membres de l'équipe seront automatiquement ajoutés à cet emplacement.",
    "components.locationForm.settings.makeDefaultTitle": "Site par défaut",
    "components.locationForm.settings.nameDescription": "Définissez le nom de votre site et sélectionnez ses utilisateurs. Si vous souhaitez configurer le site avant d'ajouter des utilisateurs, vous pouvez d'abord créer le site et ajouter les utilisateurs plus tard.",
    "components.locationForm.settings.nameDescription2": "Ce fuseau horaire par défaut est utilisé dans tout le système. Par exemple, pour afficher correctement les informations de congé dans le calendrier et pour les événements système répertoriés dans les journaux.",
    "components.locationForm.settings.namePlaceholder": "Saisissez le nom du site",
    "components.locationForm.settings.nameTitle": "Paramètres du site",
    "components.locationForm.settings.quotasDescription": "Ce paramètre déterminera si votre solde de congés sera réinitialisé en fonction de l'année comptable (année fiscale de l'entreprise) ou de la date de début de l'employé. En plus des quotas, votre politique de report sera également affectée par ce paramètre.",
    "components.locationForm.settings.quotasTitle": "Quota de congés et année fiscale",
    "components.locationForm.settings.workWeekDescription": "Veuillez sélectionner les jours ouvrables de la semaine afin d'assurer un suivi précis des données de congés.",
    "components.locationForm.settings.workWeekTitle": "Semaine de travail et journée de travail",
    "components.logs.probationPeriodCreated": "{user} a créé une période de probation <b>{name}</b>.<br></br>Activé : {enabled}.<br></br>Durée (jours) : {lengthDays}.<br></br>Types de congés : {leaveTypes}.<br></br>Emplacements : {locations}.<br></br>Départements : {teams}.<br></br>Étiquettes : {labels}.<br></br>",
    "components.logs.probationPeriodDeleted": "{user} a supprimé une période de probation.",
    "components.logs.probationPeriodUpdated": "{user} a mis à jour une période de probation <b>{name}</b>.<br></br>Activé : {enabled}.<br></br>Durée (jours) : {lengthDays}.<br></br>Types de congés : {leaveTypes}.<br></br>Emplacements : {locations}.<br></br>Départements : {teams}.<br></br>Étiquettes : {labels}.<br></br>",
    "components.logs.visibilityAddonCreated": "{user} a limité la visibilité pour l'entreprise.<br></br>Activé : {enabled}.<br></br>Niveau de visibilité : {visibilityLevel}.<br></br>",
    "components.logs.visibilityAddonUpdated": "{user} a mis à jour la visibilité limitée pour l'entreprise.<br></br>Activé : {enabled}.<br></br>Niveau de visibilité : {visibilityLevel}.<br></br>",
    "components.pricePlanCard.disableDowngradeToCore": "Le passage au plan Core nécessite {noOfActions} {noOfActions, plural, =1 {action} other {actions}}.",
    "components.pricePlanCard.disableDowngradeToCoreDescription": "Pour passer au plan Core, veuillez apporter les ajustements suivants :",
    "components.pricePlanCard.disableDowngradeToCoreReasonDepartments": "Le plan Core permet un maximum de 10 départements. Veuillez supprimer les départements supplémentaires.",
    "components.pricePlanCard.disableDowngradeToCoreReasonLocations": "Le plan Core permet un maximum de 3 sites. Veuillez supprimer les sites supplémentaires.",
    "components.pricePlanCard.disableDowngradeToCoreReasonSubdepartments": "Avec le plan Core, les départements ne peuvent être imbriqués qu'à un seul niveau. Vous avez actuellement {total} sous-départements. Veuillez supprimer certains sous-départements pour passer au plan Core.",
    "components.pricePlanCard.disableDowngradeToCoreReasonWorkday": "Passer au plan Core n'est pas possible tant que vous utilisez des horaires de travail personnalisés. Veuillez réinitialiser les horaires de travail personnalisés à la valeur par défaut (8) dans les paramètres des sites",
    "components.pricePlanCard.disableDowngradeToCoreTitle": "Rétrogradation de plan. Ajustements nécessaires",
    "components.switchPlanModal.downgradeProbationPeriodWarning": "L'extension Période de probation est une fonctionnalité payante pour le plan Core. Si vous rétrogradez et ne vous désabonnez pas de cette extension, elle sera facturée à 0,50 $ par utilisateur et par mois.",
    "components.switchPlanModal.unsubscribeProbationPeriodAddons": "Se désabonner de la période de probation",
    "components.toil.submitErrorLongerThanWorkday": "Les heures de repos compensateur demandées dépassent les heures de travail",
    "components.toil.submitErrorPolicyDuration": "Les utilisateurs avec des horaires de travail personnalisés ne sont pas autorisés à demander des jours multiples de repos compensateur",
    "dashboard.minuteSingle": "m",
    "error.botTokenNotFound": "Jeton du bot introuvable, veuillez vous connecter à notre tableau de bord et réessayer.",
    "error.CannotDuplicateName": "Le nom que vous avez saisi est déjà utilisé. Veuillez entrer un autre nom.",
    "error.CustomWorkday": "La rétrogradation vers un plan sans horaires de travail personnalisés est impossible. Veuillez supprimer les horaires de travail personnalisés dans les paramètres ou contacter le support.",
    "error.probationPeriod.isntCreated": "La période de probation n'est pas créée",
    "error.probationPeriod.isntDeleted": "La période de probation n'est pas supprimée",
    "error.probationPeriod.isntUpdated": "La période de probation n'est pas mise à jour",
    "user.probationPeriodDescription": "L'utilisateur est en période de probation sous la politique <probationPeriodName></probationPeriodName> pour <leaveTypeNames></leaveTypeNames>, d'une durée de <lengthDays></lengthDays> jour(s), jusqu'au <date></date>",
    "ai.assistant.disclaimer": "L'Assistant IA est en version bêta. Il peut faire des erreurs. En cas d'erreurs ou de réponses déroutantes, veuillez <support>contacter notre support</support>.",
    "ai.assisted.onboarding.noTopics": "Hé, il semble que vous ayez trouvé un œuf de Pâques ! 🐣 Nous travaillons sur quelque chose de nouveau qui devrait vous faire gagner beaucoup de temps, mais ce n'est pas encore prêt. Alors, s'il vous plaît, gardons cela comme notre petit secret. Merci !",
    "ai.assisted.onboarding.openSetupNotificationNoDataSubtitle": "Je ne peux pas afficher les données de configuration pour le moment. Cela peut se produire en raison d'une courte interruption réseau ou parce que le système n'a pas encore fini de traiter les données. Veuillez recharger cette page ou vérifier les paramètres dans la section Paramètres du menu.",
    "ai.assisted.onboarding.summary.intro": "Vous êtes maintenant prêt. Vous pouvez ouvrir le tableau de bord et commencer à utiliser l'application.",
    "ai.assisted.onboarding.summary.introSummary": "Voici le résumé de votre configuration :",
    "ai.assisted.onboarding.summary.leavePoliciesInfo": "Les politiques de congé sont des configurations de types de congé spécifiques à un emplacement. Pour voir les politiques de congé créées, allez à la page des emplacements, sélectionnez un emplacement, puis ouvrez l'onglet des politiques de congé.",
    "ai.assisted.onboarding.summary.view.departments": "Voir les départements",
    "ai.assisted.onboarding.summary.view.leaveTypes": "Voir les types de congé",
    "ai.assisted.onboarding.summary.view.locations": "Voir les emplacements",
    "ai.assistant.completedConversationTooltip": "Cette conversation est terminée. Vous ne pouvez plus envoyer de nouveaux messages, mais vous pouvez voir la conversation existante.",
    "ai.assistant.inputLockedTooltip": "Ceci est une version préliminaire de notre assistant IA. Actuellement, il vous aide uniquement avec l'intégration. Cependant, vous pourrez bientôt entamer de nouvelles conversations et discuter avec l'Assistant IA de Vacation Tracker. Merci de votre patience !",
    "ai.assistant.lastMessageTimestampTooltip": "Le dernier message a été envoyé le : {date}",
    "app.company": "Entreprise",
    "app.completed": "Terminé",
    "app.error": "Erreur",
    "app.logoutNow": "Déconnexion immédiate",
    "app.trialPeriod": "Période d'essai",
    "calendar.forAllUsers": "Pour tous les utilisateurs",
    "calendar.sync.calendarAlreadyConnected": "Ce calendrier est déjà connecté",
    "company.platformChanged": "Migration vers une nouvelle plateforme",
    "company.platformChanged.confirmDescription": "Vous êtes sur le point de migrer vers une nouvelle plateforme. Cette action est irréversible. Veuillez examiner les informations suivantes avant de continuer.",
    "company.platformChanged.confirmDescription.unmappedUsers.deleted": "Nous avons trouvé {unmappedUsers} utilisateurs qui ne sont pas présents dans votre compte {newPlatform}. Ces utilisateurs seront marqués comme SUPPRIMÉS une fois la migration terminée.",
    "company.platformChanged.confirmDescription.unmappedUsers.unmatched": "Nous avons trouvé {unmappedUsers} utilisateurs qui ne sont pas présents dans votre compte {newPlatform}. Ces utilisateurs ne pourront pas se connecter à Vacation Tracker.",
    "company.platformChanged.confirmQuestion": "Êtes-vous sûr de vouloir continuer ?",
    "company.platformChanged.confirmTitle": "Confirmer la migration",
    "company.platformChanged.description": "Veuillez associer vos utilisateurs de Vacation Tracker à leurs comptes {newPlatform}. Nous associerons automatiquement les utilisateurs ayant les mêmes adresses e-mail.",
    "company.platformChanged.googleAccount": "Compte Google",
    "company.platformChanged.hideInactive": "Masquer les inactifs :",
    "company.platformChanged.markUnmappedAsDeleted": "Marquer les utilisateurs non associés comme supprimés ?",
    "company.platformChanged.microsoftAccount": "Compte Microsoft",
    "company.platformChanged.slackAccount": "Compte Slack",
    "company.platformChanged.title": "Migration de {oldPlatform} vers {newPlatform}",
    "company.platformChanged.unmatched": "Non trouvé dans {platform}",
    "company.platformChanged.vacationTrackerUser": "Utilisateur de Vacation Tracker",
    "components.leaveForm.pleaseSelectValidRangeTime": "Sélectionnez une plage horaire valide",
    "createCompany.steps.companyForm.companyInfoSectionDescription": "Parlez-nous de votre entreprise et de votre site. Nous utiliserons ces informations pour créer votre compte d'entreprise et votre premier site. Vous pourrez toujours ajouter d'autres sites plus tard.",
    "createCompany.steps.companyForm.step1Title": "Détails de l'entreprise",
    "createCompany.steps.companyForm.step2Title": "Sélectionner les utilisateurs",
    "createCompany.steps.companyForm.step3Title": "Sélectionner le plan",
    "createCompany.steps.companyForm.UserInfoSectionDescription": "Dites-nous qui vous êtes. Nous utiliserons ces informations pour créer votre profil utilisateur. Par défaut, vous recevrez les rôles d'administrateur et d'approbateur. Vous pourrez ajouter d'autres utilisateurs et modifier leurs rôles par la suite.",
    "form.general.migrationFeatureDescription": "Cela lancera le processus de migration. Vous serez redirigé vers une plateforme où vous pourrez connecter votre compte.",
    "form.general.migrationFeatureTitle": "Commencer la migration vers une autre plateforme",
    "general.clickToSelectPlatform": "Cliquez pour sélectionner la plateforme",
    "surveyAboutUs.description": "Pouvez-vous nous dire comment vous avez entendu parler de nous ? Nous aimerions en savoir plus sur votre parcours vers Vacation Tracker. Cela est facultatif, mais cela nous aidera beaucoup. Merci !",
    "surveyAboutUs.title": "Comment avez-vous découvert Vacation Tracker ?",
    "addons.openApi.apiKeyName": "Nom de la clé API",
    "addons.openApi.apiKeyNamePlaceholder": "Nom de la clé API",
    "addons.openApi.apiKeySecret": "Secret de la clé API",
    "addons.openApi.apiKeysInfo": "Générez et gérez vos clés API.",
    "addons.openApi.apiKeysTitle": "Clés API",
    "addons.openApi.buyAppiAddon": "Acheter l'extension API",
    "addons.openApi.buyOpenApiInfoPrice": "L'add-on API est un add-on payant qui coûte ${price}/mois/utilisateur actif. L'achat augmentera votre facture mensuelle de ${totalPaid} pour vos {userCount} {userCount, plural, =1 {utilisateur actif} other {utilisateurs actifs}} (nous vous donnerons une estimation précise lorsque vous cliquerez sur le bouton \"Acheter\").",
    "addons.openApi.buyOpenApiInfoPrice2line": "Après l'achat de l'extension, vous ou tout autre administrateur de votre organisation pourrez générer et gérer votre clé API.",
    "addons.openApi.buyOpenApiTitle": "Acheter l'extension API pour générer des clés API",
    "addons.openApi.createInProgress": "Création de la clé API en cours",
    "addons.openApi.createInProgressCompleted": "Création de la clé API terminée",
    "addons.openApi.createNewApiKey": "Créer une nouvelle clé API",
    "addons.openApi.currentMonthUsage": "Utilisation du mois en cours",
    "addons.openApi.documentation": "Documentation de l'API",
    "addons.openApi.documentationInfo": "La documentation de l'API décrit le fonctionnement de notre API et les méthodes actuellement disponibles.",
    "addons.openApi.dontHaveApiKeys": "Vous n'avez pas de clés API, cliquez sur le bouton ci-dessous pour en créer une",
    "addons.openApi.dpenDocumentation": "Ouvrir la documentation",
    "addons.openApi.feedback": "Si vous avez des commentaires ou besoin de données que notre API n'expose pas, <link>cliquez ici pour nous en informer</link>.",
    "addons.openApi.limitRequestPerMonth": "Vous pouvez envoyer jusqu'à 30000 requêtes par mois",
    "addons.openApi.limitRequestPerSecound": "Les requêtes sont limitées à 10 par seconde avec un mode rafale de 50 requêtes/seconde",
    "addons.openApi.notificationDeleteConfirm": "Veuillez confirmer que vous souhaitez supprimer la clé API <strong>{name}</strong>.",
    "addons.openApi.notificationDeleteTitle": "Supprimer la clé API",
    "addons.openApi.openApiDeleted": "Clé API supprimée",
    "addons.openApi.quotasAndRateLimitsInfo": "Voici nos quotas et limites de débit par clé API :",
    "addons.openApi.quotasAndRateLimitsTitle": "Quotas et limites de débit",
    "addons.openApiInfo": "Notre extension API vous permet de connecter Vacation Tracker à d'autres applications de manière programmatique. Actuellement, notre API vous permet un accès en lecture seule à vos utilisateurs, leurs congés, sites, départements, types de congés et étiquettes.",
    "app.documentation": "Documentation",
    "automations.ADP_INTEGRATION": "Ouvrir la documentation de l'API",
    "automations.BAMBOOHR_INTEGRATION": "Intégration BambooHR",
    "automations.OPEN_API_DOCUMENTATION": "Documentation de l'API ouverte",
    "automations.reSubscribeAddons": "Réabonnement à l'add-on",
    "automations.subscripteToAddons": "S'abonner aux add-ons",
    "automations.unsubscripteAddons": "Se désabonner de l'add-on ?",
    "choiceAutomations.adpIntegration": "Fournit une connexion directe à votre outil de paie, garantissant que les données de paie sont précises et à jour sur toutes les plateformes.",
    "choiceAutomations.bambooHrIntegration": "Fournit une connexion directe à votre outil RH, garantissant que les données sont précises et cohérentes dans tous les systèmes.",
    "error.openApi.isntCreated": "La clé API ouverte n'est pas créée.",
    "error.openApi.isntDeleted": "La clé API ouverte n'est pas supprimée.",
    "company.platformChangedTenant.title": "Migration du locataire {newPlatform}",
    "general.switchTenant": "Changer le locataire {platform}",
    "app.amount": "Montant",
    "automations.seniorityEntitlement.periodsViewTableDays": "{amount} {amount, plural, =1 {jour} other {jours}} après {years} ans\n",
    "automations.seniorityEntitlement.periodsViewTableHours": "{amount} {amount, plural, =1 {heure} other {heures}} après {years} ans\n",
    "calendar.sync.calendarNotFound": "Impossible de trouver le calendrier. Il se peut qu'il ait été supprimé ou qu'il ne soit plus disponible. Veuillez vérifier vos paramètres de calendrier.\n",
    "calendar.sync.outlookAdminConsentButton": "Accorder le consentement de l'administrateur",
    "calendar.sync.outlookAdminConsentInfo": "L'insertion ou la mise à jour des congés dans le calendrier {calendarName} nécessite le consentement de l'administrateur. Nous avons besoin de permissions supplémentaires parce que vous avez connecté un calendrier créé par une autre personne de votre organisation ou si votre organisation a des règles spécifiques de partage de calendrier Microsoft Outlook.",
    "calendar.sync.outlookAdminConsentInquiry": "Êtes-vous administrateur de votre compte Microsoft 365 ?",
    "calendar.sync.outlookAdminConsentLinkInfo": "Si vous n'êtes pas administrateur, envoyez ce lien à l'administrateur de votre organisation :",
    "calendar.sync.outlookAdminConsentLinkResyncInfo": "Une fois que l'administrateur aura accordé les autorisations supplémentaires, cliquez ici pour obtenir un nouveau jeton et ajouter les événements manquants au calendrier {calendarName} :",
    "calendar.sync.outlookAdminConsentModalTitle": "Consentement de l'administrateur requis",
    "calendar.sync.outlookAdminConsentResyncButton": "Reconnecter et synchroniser",
    "components.leavePolicyForm.rollover": "Nombre maximal",
    "components.logs.initialRolloverUpdatedDays": "Report initial pour le type de congé : {leaveTypeName} modifié à {amount} {amount, plural, =1 {jour} other {jours}} par <link>{creatorName}</link>",
    "components.logs.initialRolloverUpdatedHours": "Report initial pour le type de congé : {leaveTypeName} modifié à {amount} {amount, plural, =1 {heure} other {heures}} par <link>{creatorName}</link>",
    "components.userLeaveQuotas.broughtForwardInfoDetailsDays": "Pour le type de congé {leaveTypeName} dans le site {locationName}, les employés peuvent reporter jusqu'à {broughtForwardLimit} {broughtForwardLimit, plural, =1 {jour} other {jours}} non utilisés.",
    "components.userLeaveQuotas.broughtForwardInfoDetailsHours": "Pour le type de congé {leaveTypeName} dans le site {locationName}, les employés peuvent reporter jusqu'à {broughtForwardLimit} {broughtForwardLimit, plural, =1 {heure} other {heures}} non utilisées.",
    "dashboard.day": "day",
    "dashboard.daysPlural": "Jours",
    "dashboard.hourOne": "Heure",
    "dashboard.hours": "Heures",
    "dashboard.minute": "Minute",
    "dashboard.minuteAbbreviation": "m",
    "dashboard.minutes": "Minutes",
    "error.EmailAlreadyExists": "Un utilisateur avec cet e-mail existe déjà dans Vacation Tracker.",
    "error.userUpdated": "Erreur lors de la mise à jour de l'utilisateur"
}