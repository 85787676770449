import React from 'react'
import { Alert } from 'antd'
import { NotificationOutlined } from '@ant-design/icons'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { Link } from 'react-router-dom'
import { useIntl } from 'react-intl'

interface IProps {
  daysToTrialEnd: number
}

const AlertTrial = ({
  daysToTrialEnd,
}: IProps): React.ReactElement => {
  const { formatMessage } = useIntl()
  const daysLabel = daysToTrialEnd > 1 ? 'app.days' : 'app.day'
  return (<Alert
    style={{ margin: '5px 5px 5px 5px' }}
    message={
      <IntlMessages id="app.trialExpired"
        values={{
          days: daysToTrialEnd,
          daysInfo: formatMessage({ id: daysLabel }),
          // eslint-disable-next-line
          a: (...chunks) => (
            <Link to={'/app/settings/billing'}>
              {chunks}
            </Link>
          ),
        }}
      />
    }
    type="warning"
    icon={<NotificationOutlined />}
    showIcon
  />)
}

export default AlertTrial