export function isToilLeave(id: string, strict = false): boolean {

  if (!strict) {
    return id.startsWith('toilRequest')
  }

  if (id.startsWith('toilRequest')) {
    return true
  }

  if (id.startsWith('leaveRequest')) {
    return false
  }
  
  // On import page we import leaves with prefix leave-request, this is somthing what we need to change in our DB, after then this we can remove.
  if (id.startsWith('leave-request')) {
    return false
  }

  throw new Error(`Unknown request type: ${id}`)
}
