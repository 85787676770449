import antdPt from 'antd/locale/pt_BR'
import ptMessages from '@vacationtracker/shared/i18n/pt-br.json'

const PtLang = {
  messages: {
    ...ptMessages,
  },
  antd: antdPt,
  locale: 'pt-BR',
}
export default PtLang
