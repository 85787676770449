import { IError } from './types'

export const NoCurrentUser: IError = {
  code: 'NoCurrentUser',
  message: 'Current user not signed in',
}

export const NoTokens: IError = {
  code: 'NoTokens',
  message: 'ID and Access tokens are not available',
}

export const NoCredentials: IError = {
  code: 'NoCredentials',
  message: 'AWS credentials are not available',
}
