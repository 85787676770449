import React, { useEffect, useState } from 'react'
import { Button, Spin, Typography } from 'antd'
import { useManualQuery } from 'graphql-hooks'
import { History } from 'history'

import { getCompanyAndUserInfo } from '../../graphql/custom-queries'
import { wait } from '@vacationtracker/shared/functions/wait'
import { useAppDispatch } from '../../store/hooks'
import { setUserId } from '../../store/user-id-slice'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any

import IntlMessages from '../../util/IntlMessages'

import { FrontendUrls } from '../../types/urls'
import { IGetCompanyAndUserInfo } from '../../types/custom-queries'
import { confirmSigninWithCustomChallengeAnswer, IAuthNextStep, signin } from '@vacationtracker/shared/services/auth'
import { AuthStateEnum } from '@vacationtracker/shared/types/auth-state'
import { setAuthState } from '../../store/auth-state-slice'
import { getCompanyAndUser } from '../../services/api/companies'

const { Text } = Typography

interface IConnect {
  match: {
    params: {
      code: string
    }
  }
  location: {
    pathname: string
    search: string
  }
  history: History
}

interface IUrlValues {
  userId: string
  token: string
  page: string
  query: string
  leaveRequestId?: string
}

const ExternalConnect = ({ location, history, match }: IConnect): React.ReactElement => {
  const dispatch = useAppDispatch()
  const [signinInProgress, setSigninInProgress] = useState(true)
  const [parseError, setParseError] = useState(false)
  const [loginFailed, setLoginFailed] = useState(false)
  const [urlValues, setUrlValues] = useState<IUrlValues | null>(null)


  useEffect(() => {
    if(!match.params.code) {
      history.push(FrontendUrls.signup)
    } else {
      getValueFromCode(match.params.code, location.search)
    }
  }, [match.params.code])

  const getValueFromCode = (code: string, qr: string) => {
    let query = '/'
    try {
      const values = JSON.parse(window.atob(code))
      if (values.userId, values.token && values.page) {
        if (values.leaveRequestId) {
          query = qr.length > 0 ? `${qr}&leaveRequestId=${values.leaveRequestId}` : `?leaveRequestId=${values.leaveRequestId}`
        }
        authSignin(values.userId, values.token, values.page, query)
      }
      setUrlValues({...values, query})
    } catch (error) {
      console.log('error', error)
      setParseError(true)
      setSigninInProgress(false)
    }
  }

  const [getCompanyAndUserInfoQuery] = useManualQuery<IGetCompanyAndUserInfo, { userId: string }>(getCompanyAndUserInfo)


  const authSignin = async (username: string, oneTimeToken: string, page: string, query: string) => {
    try {
      const signInResponse = await signin(username) as IAuthNextStep
      if (signInResponse.challengeName === 'CUSTOM_CHALLENGE' && signInResponse.challengeParam?.question === 'token') {
        const cognitoResponse = await confirmSigninWithCustomChallengeAnswer(oneTimeToken, { loginType: 'oneTimeToken' })
        localStorage.setItem('userId', cognitoResponse.username)
        dispatch(setUserId(cognitoResponse.username))

        await getCompanyAndUser(cognitoResponse.username, getCompanyAndUserInfoQuery, dispatch, history)

        dispatch(setAuthState(AuthStateEnum.signedIn))
        history.push(`/app/${page}${query}`)
      } else {
        history.push(`/app/${page}${query}`)
      }
    } catch (error) {
      if (error.code === 'NotAuthorizedException') {
        setLoginFailed(true)
        setSigninInProgress(false)
        await wait(10000)
        history.push(`${FrontendUrls.signin}?redirect=/app/${page}${query}`)
      }
    }
  }

  const handleOnLoginClick = () => {
    if (urlValues?.page && urlValues?.query) {
      history.push(`${FrontendUrls.signin}?redirect=/app/${urlValues.page}${urlValues.query}`)
    }
  }

  return (
    <div className="auth-wrap">
      <div className="auth-container">
        <div className="auth-main-container">
          {signinInProgress &&
            <>
              <Spin spinning={signinInProgress}>
              </Spin>
              <Text style={{ textAlign: 'center' }}><IntlMessages id="externalConnect.pleaseWait" /></Text>
            </>
          }
          {parseError && <Text style={{ marginTop: 20, textAlign: 'center' }} type="danger"><IntlMessages id="externalConnect.parseError" /></Text> }
          {loginFailed && <div className="center-elements-vertically">
            <Text style={{ marginTop: 20, textAlign: 'center' }} type="danger">
              <IntlMessages id="externalConnect.faildLogin" />
            </Text>
            <br />
            <Button
              type="primary"
              onClick={handleOnLoginClick}
              style={{ width: '200px' }}
            >
              <IntlMessages id="app.login" />
            </Button>
          </div>}
        </div>
      </div>
    </div>
  )
}

export default ExternalConnect
