
export const saveUserActionFlow = /* GraphQL */ `
  mutation SaveUserActionFlow($type: String!, $skipped: Boolean!, $completed: Boolean!, $actions: UserActionFlowActionsInput!) {
    saveUserActionFlow(
      type: $type,
      skipped: $skipped,
      completed: $completed,
      actions: $actions,
    ) {
      type
      skipped
      completed
      actions {
        ONBOARDING_CHECKLIST_BOT_VIDEO_WATCHED
        ONBOARDING_CHECKLIST_BOT_BUTTON_CLICKED
        ONBOARDING_CHECKLIST_BOT_COMPLETED
        ONBOARDING_CHECKLIST_LEAVE_REQUEST_VIDEO_WATCHED
        ONBOARDING_CHECKLIST_LEAVE_REQUEST_BUTTON_CLICKED
        ONBOARDING_CHECKLIST_LEAVE_REQUEST_COMPLETED
        ONBOARDING_CHECKLIST_ICAL_VIDEO_WATCHED
        ONBOARDING_CHECKLIST_ICAL_BUTTON_CLICKED
        ONBOARDING_CHECKLIST_ICAL_COMPLETED
        ONBOARDING_CHECKLIST_NOTIFICATIONS_VIDEO_WATCHED
        ONBOARDING_CHECKLIST_NOTIFICATIONS_BUTTON_CLICKED
        ONBOARDING_CHECKLIST_NOTIFICATIONS_COMPLETED
        ONBOARDING_CHECKLIST_IMPORT_USERS_VIDEO_WATCHED
        ONBOARDING_CHECKLIST_IMPORT_USERS_BUTTON_CLICKED
        ONBOARDING_CHECKLIST_IMPORT_USERS_COMPLETED
      }
    }
  }
`

export const saveUserHelpInfo = /* GraphQL */ `
  mutation SaveUserHelpInfo($actions: UserHelpInfoActionsInput!) {
    saveUserHelpInfo(
      actions: $actions,
    ) {
      actions {
        HELP_INFO_LOCATION_CREATE
        HELP_INFO_LEAVE_TYPE_CREATE
      }
    }
  }
`

export const setWhatsNewAsRead = /* GraphQL */ `
  mutation SetWhatsNewAsRead($messageId: String!) {
    setWhatsNewAsRead(
      messageId: $messageId,
    ) {
      messageId
    }
  }
`
