import React, { useState } from 'react'
import { App, Form, Input, Modal, Radio } from 'antd'
import { CommentOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'

import Api from '@vacationtracker/shared/services/api'
import IntlMessages from '../../util/IntlMessages'
import { track } from '../../services/analytics/analytics'

interface IRequestAddon {
  visible: boolean
  onCancel: () => void
}

const RequestAddon = ({
  visible,
  onCancel,
}: IRequestAddon) => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const { notification } = App.useApp()

  const [isSending, setSending] = useState<boolean>(false)

  const sendRequest = async () => {
    await form.validateFields().then(async value => {
      try {
        setSending(true)
        track('AUTOMATION_REQUEST_FORM_SUBMITTED', {})
        await Api.post('/core/event', {
          ...value,
          eventGroup: 'FEEDBACK',
          eventType: 'REQUEST_AUTOMATION_FEEDBACK',
        })
        onCancel()
        notification.success({
          message: formatMessage({ id: 'automation.feedback.thankYou' }),
          icon: <CommentOutlined style={{ color: '#7f00ff' }} />,
        })
        setSending(false)
      } catch (error) {
        setSending(false)
        if(error?.outOfDate) {
          return
        }
        const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
        notification.error({
          message: formatMessage({ id: 'error.generic' }),
          description: errorDescription,
          duration: 0,
        })
      }
    })
  }

  return (
    <Modal
      title={<IntlMessages id="components.requestAutomation.suggestAnAutomation" />}
      open={visible}
      onCancel={onCancel}
      onOk={() => {
        sendRequest()
      }}
      okText={<IntlMessages id="components.requestAutomation.submiteRequest" />}
      cancelText={<IntlMessages id="app.close" />}
      confirmLoading={isSending}
      style={{ top: 20 }}
    >
      <Form
        key="requestAutomation"
        form={form}
        name="requestAutomation"
        layout='vertical'
      >
        <Form.Item
          name="email"
          rules={[
            { required: true, message: <IntlMessages id="error.fieldIsRequired" /> },
          ]}
          label={<IntlMessages id="components.feedbackModal.yourEmail" />}
        >
          <Input type="email" />
        </Form.Item>

        <Form.Item
          name="automation"
          rules={[
            { required: true, message: <IntlMessages id="error.fieldIsRequired" /> },
          ]}
          label={<IntlMessages id="components.requestAutomation.automationShouldWeAdd" />}
        >
          <Input.TextArea rows={2} style={{ resize: 'none' }}/>
        </Form.Item>
        
        <Form.Item
          name="functionality"
          rules={[
            { required: true, message: <IntlMessages id="error.fieldIsRequired" /> },
          ]}
          label={<IntlMessages id="components.requestAutomation.automationFunctionalities" />}
        >
          <Input.TextArea rows={2} style={{ resize: 'none' }}/>
        </Form.Item>
        
        <Form.Item
          name="extraCharge"
          rules={[
            { required: true, message: <IntlMessages id="error.fieldIsRequired" /> },
          ]}
          label={<IntlMessages id="components.requestAutomation.automationExtraCharge" />}
        >
          <Radio.Group>
            <Radio.Button value={true}><IntlMessages id="app.yes" /></Radio.Button>
            <Radio.Button value={false}><IntlMessages id="app.no" /></Radio.Button>
          </Radio.Group>
        </Form.Item>
        
        <Form.Item
          name="anythingElse"
          required={false}
          label={<IntlMessages id="components.requestAutomation.anythingElse" />}
        >
          <Input.TextArea rows={2} style={{ resize: 'none' }}/>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default RequestAddon
