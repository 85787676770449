/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, useState, useEffect } from 'react'
import { DARK_THEME, LIGHT_THEME } from '@vacationtracker/shared/constants/ThemeSetting'

interface IThemeSetting {
  theme: string
  setThemeHandler: Function
}

interface IThemeProvider {
  children: React.ReactNode
}

const themeSetting: IThemeSetting = {
  theme: 'light',
  // Placeholder functions
  setThemeHandler: () => {},
}

export const ThemeContext = createContext(themeSetting)


// This context provider is passed to any component requiring the context
export const ThemeProvider = ({ children }: IThemeProvider) => {
  const [theme, setTheme] = useState('light')

  useEffect(() => {
    const theme = localStorage.getItem('theme')
    const darkMql = window.matchMedia('(prefers-color-scheme: dark)')

    // default theme setting is system setting
    // user can choose theme from out UI
    // then we write chosen theme to storage
    // and it will be chosen theme from that point on
    // TODO @Ivan in some of next releases we should add option for user to reset to system
    if (!theme) {
      setSystemTheme(darkMql)
    } else {
      setThemeHandler(theme || 'light')
    }
    darkMql.addListener(eventDarkMql => {
      if (!theme) {
        setSystemTheme(eventDarkMql)
      }
    })
    return () => darkMql.removeListener(eventDarkMql => {
      if (!theme) {
        setSystemTheme(eventDarkMql)
      }
    })
  }, [])


  const setThemeAndToggleClass = (theme: string, action: string) => {
    setTheme(theme)
    document.body.classList[action]('dark-theme')
  }

  const setSystemTheme = (mql) => {
    if (mql.matches) {
      setThemeAndToggleClass(DARK_THEME, 'add')
    } else {
      setThemeAndToggleClass(LIGHT_THEME, 'remove')
    }
  }

  const setThemeHandler = (theme: string) => {
    if (theme === DARK_THEME) {
      setThemeAndToggleClass(DARK_THEME, 'add')
    } else {
      setThemeAndToggleClass(LIGHT_THEME, 'remove')
    }
    localStorage.setItem('theme', `${theme}`)
  }


  const value = {
    theme,
    setThemeHandler,
  }

  return (
    <ThemeContext.Provider value={value}>
      {children}
    </ThemeContext.Provider>
  )
}
