import React, { Fragment, useContext, useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { withRouter, Link } from 'react-router-dom'
import { App, Breadcrumb, Tabs, Alert, Typography, Checkbox, Tooltip, TabsProps } from 'antd'
import { ExclamationCircleOutlined, LoadingOutlined, InfoCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import sortBy from 'lodash/sortBy'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import { getLocationByIdLeaveTypesAllLeavePolicies } from '../../../../graphql/custom-queries'
import { notificationStore } from '../../../../context/notificationsContext/store'
import { useAppSelector } from '../../../../store/hooks'
import { selectAuthCompanySlice } from '../../../../store/auth-company-slice'
import * as logger from '../../../../services/logger'

import IntlMessages from '../../../../util/IntlMessages'
import CircularProgress from '../../../../components/circular-progress'
import LocationGeneral from '../../../../components/location-general'
import LocationHolidays from '../../../../components/location-holidays'
import LocationLeavePolicies from '../../../../components/location-leave-policies'

import {
  IGetLocationLeaveTypesAllLeavePoliciesData,
  IGetLocationList,
  IGetLeaveTypeList,
  IGetLocationGeneral,
  ILocationGeneralLeavePolicies,
  IHolidaysByYear
} from '../../../../types/locations'
import { IHoliday } from '../../../../types/holidays'
import { getTimeZoneOrDefaultToUtc } from '@vacationtracker/shared/functions/timezone'
import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { selectLocaleSlice } from '../../../../store/locale-slice'
import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { isWorkDayFeatureAllowed } from '@vacationtracker/shared/functions/work-week'
import { selectFeatureFlagsSlice } from '../../../../store/feature-flags-slice'
import { FrontendUrls } from '../../../../types/urls'

const { Text } = Typography

interface ILocationPage {
  match: {
    params: {
      id: string
      type: string
    }
  }
}

const LocationPage = ({ match }: ILocationPage): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)
  const { locale } = useAppSelector(selectLocaleSlice)
  const { notification } = App.useApp()

  const [isLoading, setIsLoading] = useState(true)
  const [showRenameWarning, setShowRenameWarning] = useState(false)
  const [location, setLocation] = useState<IGetLocationGeneral>({
    id: '',
    name: '',
    workWeek: [],
    timestamp: '',
    resetQuotas: '',
    rolloverNeverExpireDays: true,
    firstDayOfWeek: 0,
    yearStartDay: 1,
    yearStartMonth: 1,
    timezone: getTimeZoneOrDefaultToUtc(),
    users: [],
    holidays: [],
    holidaysAutoImport: [],
    isDefault: false,
    leavePolicies: [],
  })
  const [locationHolidays, setLocationHolidays] = useState<IHolidaysByYear[]>()
  const [leavePolicies, setLeavePolicies] = useState<ILocationGeneralLeavePolicies[]>([])
  const [allLeavePolicies, setAllLeavePolicies] = useState<ILocationGeneralLeavePolicies[]>([])
  const [leaveTypes, setLeaveTypes] = useState<IGetLeaveTypeList[]>([])
  const [selectedTab] = useState<string>(() => {
    return match.params.type || 'general'
  })
  const [autoImportHolidays, setAutoImportHolidays] = useState(false)
  const [autoImportCheckboxTouched, setAutoImportCheckboxTouched] = useState(false)

  const [ getLocationByIdLeaveTypesAllLeavePoliciesQuery ] = useManualQuery<IGetLocationLeaveTypesAllLeavePoliciesData, { id: string }>(getLocationByIdLeaveTypesAllLeavePolicies)

  useEffect(() => {
    fetchLocation(match.params.id)
  }, [match.params.id])

  useEffect(() => {
    fetchLocation(match.params.id)
  }, [actionNotifications])

  useEffect(() => {
    const shouldRename = location.name.includes('<Autodetected for Departments')
    if (shouldRename) {
      setShowRenameWarning(true)
    }
  }, [location])

  const fetchLocation = async (id) => {
    try {
      const response = await getLocationByIdLeaveTypesAllLeavePoliciesQuery({ variables: { id }})
      if (!response.data || response.error) throw response.error

      // Set leave types which not exist in this location
      setLeaveTypes(
        response.data.getLeaveTypeList
          .filter(lt => lt.isActive)
          .filter(({ id: leaveTypeId }) => {
            return !response.data?.getLocation.leavePolicies
              .some(({ leaveType: locationLeaveType }) => locationLeaveType.id === leaveTypeId)
          })
          .sort((a, b) => a.position < b.position ? -1 : 1)
      )
      setLocation(response.data.getLocation)
      !autoImportCheckboxTouched && setAutoImportHolidays(response.data.getLocation.holidaysAutoImport?.length > 0)
      const leavePoliciesData = response.data.getLocation.leavePolicies
        .filter((leavePolicy) => !leavePolicy.leaveType.deleted)
        .map((leavePolicy) => {
          return {
            ...leavePolicy,
            index: leavePolicy.leaveType.position,
            key: leavePolicy.leaveType.position,
          }
        })
        .filter(lp => lp.leaveType.isActive)
      setLeavePolicies(leavePoliciesData.sort((a,b) => a.leaveType.position - b.leaveType.position))

      let allLeavePoliciesData: ILocationGeneralLeavePolicies[] = []
      response.data.getLocationList.forEach((loc: IGetLocationList) => {
        const locationLeavePolicies: ILocationGeneralLeavePolicies[] = loc.leavePolicies
          .filter(lp => lp.isActive)
          .filter(lp => !lp.leaveType.deleted)
          .filter(lp => lp.leaveType.isActive)
          .map(lp => {
            lp.leaveType.name = `${lp.leaveType.name} (${loc.name})`
            return lp
          })

        allLeavePoliciesData = allLeavePoliciesData.concat(locationLeavePolicies)
      })
      setAllLeavePolicies(sortBy(allLeavePoliciesData, ['leaveType.position', 'leaveType.name']))

      const locationHolidaysData: IHolidaysByYear[] = response.data.getLocation.holidays
      response.data.getLocation.holidays.forEach((holidayData, index) => {
        const holidaysArray: IHoliday[] = []
        holidayData.holidays.forEach((single => {
          if (single.multiDayId !== null) {
            if (holidaysArray.filter(holiday => single.multiDayId === holiday.multiDayId).length === 0) {
              const filtered = locationHolidaysData[index].holidays
                .filter(holiday => holiday.multiDayId === single.multiDayId)
                .sort((a: IHoliday, b: IHoliday) => dayjs(a.date).format() < dayjs(b.date).format() ? -1 : 1)
              holidaysArray.push({ ...filtered[0], endDate: filtered[filtered.length - 1].date })
            }
          } else {
            delete single.multiDayId
            holidaysArray.push(single)
          }
        }))
        locationHolidaysData[index].holidays = holidaysArray
      })
      setLocationHolidays(locationHolidaysData)
      setIsLoading(false)
    } catch (err) { logger.error('error fetching location', err) }
  }

  const onSaveHolidays = async (holidays) => {
    
    let brokenHolidays = false    
    holidays.holidays.forEach((holiday: IHoliday) => {
      const holidayYear = parseInt(holiday.date.substring(0,4))
      if (!holiday.multiDayId && holidayYear !== parseInt(holidays.year)){
        brokenHolidays = true
      }
    })
    if (brokenHolidays) {
      notification.open({
        key: 'test',
        message: formatMessage({ id: 'location.holidays.brokenHolidays' }),
        icon: (<ExclamationCircleOutlined />),
        duration: 0,
      })
      throw new Error()
    }
    
    const response = await Api.post('/core/event', {
      eventType: 'LOCATION_YEAR_HOLIDAYS_CHANGED',
      eventGroup: 'LOCATION',
      locationId: match.params.id,
      holidays: holidays.holidays,
      year: holidays.year,
    })
    notification.open({
      key: response.correlationId,
      message: formatMessage({ id: 'location.holidays.updateInProgress' }),
      icon: (<LoadingOutlined />),
      duration: 0,
    })

    setActionNotifications([ ...actionNotifications, response.correlationId ])
  }

  const turnOffAutoImportHolidays = async (e: CheckboxChangeEvent) => {
    setAutoImportHolidays(e.target.checked)
    setAutoImportCheckboxTouched(true)
    if (e.target.checked === false) {
      const response = await Api.post('/core/event', {
        eventType: 'LOCATION_HOLIDAYS_AUTOIMPORT_CHANGED',
        eventGroup: 'LOCATION',
        locationId: match.params.id,
        holidays: [],
      })      
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'location.holidays.updateInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId, response.correlationId ])
    }
  }

  const operations = <Link to={`/app/settings/locations/${match.params.id}/edit`}><IntlMessages id="location.editLocation" /></Link>

  const locationsTabs: TabsProps['items'] = [
    {
      key: 'general',
      label: <IntlMessages id="app.general" />,
      children: <LocationGeneral location={location} locale={locale.locale || LocaleEnum.en} workdayEnabled={isWorkDayFeatureAllowed(featureFlags, authCompany?.plan)} />,
    },
    {
      key: 'leave-policies',
      label: <><IntlMessages id="locations.leavePolicies" /> {leavePolicies.length === 0 && <ExclamationCircleOutlined style={{ color: 'red' }} /> }</>,
      children: <LocationLeavePolicies
        leavePoliciesList={leavePolicies}
        location={location}
        leaveTypes={leaveTypes}
        allLeavePolicies={allLeavePolicies}
        hourlyLeaveAccounting={Boolean(authCompany?.hourlyLeaveAccounting)}
      />,
    },
    {
      key: 'holidays',
      label: <IntlMessages id="app.holidays" />,
      children: <><div>{autoImportHolidays
        ? <><Checkbox checked={autoImportHolidays} onChange={turnOffAutoImportHolidays}>
        </Checkbox>&nbsp;<IntlMessages id="holidays.importHolidaysAutomaticallyTurnedOn" />
        <Tooltip className="info-tooltip" title={<IntlMessages id="holidays.importHolidaysAutomaticallyTooltip" />}>
          <InfoCircleOutlined />
        </Tooltip></>
        : <IntlMessages id="holidays.importHolidaysAutomaticallyTurnedOff"/>}</div>
      <LocationHolidays
        holidays={locationHolidays}
        onSaveHolidays={onSaveHolidays}
        locationName={location.name}
        locationId={location.id}
      /></>,
    },
  ]

  return (
    <div className='main-content'>
      {isLoading ? <CircularProgress /> :
        <div>
          <div className="main-content-header">
            <div className="main-content-header-title">
              <span><IntlMessages id="app.location" />: {location.name}</span>
            </div>
            <div className="main-content-header-breadcrumb">
              <Breadcrumb
                items={[
                  {
                    title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
                  },
                  {
                    title: <IntlMessages id='sidebar.settings' />,
                  },
                  {
                    title: <Link to='/app/settings/locations'><IntlMessages id="app.locations" /></Link>,
                  },
                  {
                    title: <Link to={`/app/settings/locations/${location.id}/holidays`}>{location.name}</Link>,
                  },
                ]}
              />
            </div>
          </div>
          {showRenameWarning &&
            <Alert
              message="Please rename automatically generated location name *"
              description={
                <Fragment>
                  <Text>We automatically created locations for your organization by grouping the following teams: </Text>
                  <Text strong>{location.name.replace('<Autodetected for Departments: ', '').replace('>', '')}</Text>.
                  <Text> All leave policies are transferred, you just need to rename your locations.</Text>
                </Fragment>
              }
              type="warning"
              showIcon
              style={{ marginBottom: '20px' }}
            />
          }
          <div className="main-content-body">
            <div className="location-body">
              {leavePolicies.length === 0 &&
                <Alert
                  message={<><ExclamationCircleOutlined style={{ color: 'red' }} /> <IntlMessages id="location.leavePoliciesNotExistInfo" /></>}
                  type="error"
                  closable
                />
              }
              <Tabs defaultActiveKey={selectedTab} tabBarExtraContent={operations} items={locationsTabs} />
            </div>
          </div>
        </div>
      }
    </div>
  )
}

export default withRouter(LocationPage)
