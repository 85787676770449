import { IFilter } from '@vacationtracker/shared/types/filter'
import { isEmpty, sortBy } from 'lodash'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const filterData = (filter: IFilter, data: any[]) => {
  const { labelIds, locationIds, teamIds } = filter

  const dataFiltered = data
    .filter((leave) => {
      if (isEmpty(locationIds)) {
        return leave
      } else if (locationIds.find(id => id === leave.locationId)) {
        return leave
      }
    })
    .filter((leave) => {
      if (isEmpty(teamIds)) {
        return leave
      } else if (teamIds.find(id => id === leave.teamId)) {
        return leave
      }
    })
    .filter((leave) => {
      if (isEmpty(labelIds)) {
        return leave
      } else if (labelIds.find(id => leave?.labelIds && leave.labelIds.includes(id))) {
        return leave
      }
    })

  return sortBy(dataFiltered, ['startDate'])
}