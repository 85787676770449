import React from 'react'
import { Button, Modal } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import IntlMessages from '../../util/IntlMessages'
import YoutubeEmbed from '../youtube-embed'

interface ISetupMSBot {
  visibleModal: boolean
  handleCancel: Function
}

const SetupMSBot = ({ visibleModal, handleCancel }: ISetupMSBot): React.ReactElement => {
  return (
    <Modal
      key="leave-policy-form"
      title={<IntlMessages id="components.setupMsBot.title" />}
      open={visibleModal}
      onCancel={() => handleCancel()}
      closeIcon={<CloseCircleOutlined />}
      style={{ top: 20 }}
      width={600}
      footer={[
        <Button key="close" onClick={() => handleCancel()}><IntlMessages id="app.close" /></Button>,
      ]}
    >
      <p>
        <IntlMessages id="components.setupMsBot.followDirectLink" values={{
          // eslint-disable-next-line
          directLink: (...chunks) => (
            <a href="https://teams.microsoft.com/l/app/eab5463e-8168-40ee-887a-7ac78de1d266" target="_blank" rel="noopener noreferrer">
              {chunks}
            </a>
          ),
        }} />
      </p>
      <p><IntlMessages id="components.setupMsBot.inMSAppPreviewWindows" /></p>
      <YoutubeEmbed embedId="vUbpc7-aEz0" width="560" height="315" title="Set up the Microsoft Teams bot and tabs" />
      <p><IntlMessages id="components.setupMsBot.setUpInMS" /></p>
      <p>
        <IntlMessages id="components.setupMsBot.stepByStep" values={{
          // eslint-disable-next-line
          helpDesk: (...chunks) => (
            <a href="https://vacationtracker.crisp.help/en/" target="_blank" rel="noopener noreferrer">
              {chunks}
            </a>
          ),
        }} />
      </p>
    </Modal>
  )
}

export default SetupMSBot
