/* eslint-disable max-len */
import React from 'react'
import { useIntl } from 'react-intl'

import { computeDaysAndHours, getFormattedDaysAndHours } from '../../functions/get-days-or-hours'

import { IDisplayLeaveDuration, IDisplayLeaveDurationBots, IDisplayLeaveInDaysAndHours, IDisplayLeaveInDaysAndHoursBots, LeaveDisplayFormatEnum } from '../../types/display-in-days-hours'
import { IToilRequest } from '../../types/toil-request'
import { DEFAULT_WORKING_HOURS_IN_DAY } from '../../data/app-parameters'

export const DisplayLeaveDuration = ({
  doNotConvertToHours = false,
  value,
  format = LeaveDisplayFormatEnum.long,
  daysList,
}: IDisplayLeaveDuration): React.ReactElement => {
  
  const { formatMessage } = useIntl()
  if (value === 0) {
    return <>{value}</>
  }
  
  //new leaves will have daysList, old ones(8h) will not
  const numberOfWorkingHoursPerDay = daysList?.[0]?.workingHoursInDay || DEFAULT_WORKING_HOURS_IN_DAY

  const val = doNotConvertToHours ? value : value * numberOfWorkingHoursPerDay
  const leave = computeDaysAndHours(val, numberOfWorkingHoursPerDay)

  return <>
    {getFormattedDaysAndHours({
      leave,
      formatMessage,
      displayFormat: format,
    })}
  </>
}

export const displayLeaveDuration = ({
  doNotConvertToHours = false,
  value,
  format = LeaveDisplayFormatEnum.long,
  daysList,
  formatMessage,
}: IDisplayLeaveDurationBots) => {
  if (value === 0) {
    return `${value}`
  }
  //new leaves will have daysList, old ones(8h) will not
  const numberOfWorkingHoursPerDay = daysList?.[0]?.workingHoursInDay || DEFAULT_WORKING_HOURS_IN_DAY

  const val = doNotConvertToHours ? value : value * numberOfWorkingHoursPerDay
  const leave = computeDaysAndHours(val, numberOfWorkingHoursPerDay)
  return getFormattedDaysAndHours({
    leave,
    formatMessage,
    displayFormat: format,
  })
}

export const DisplayLeaveInDaysAndHours = ({
  doNotConvertToHours = false,
  value,
  format = LeaveDisplayFormatEnum.long,
  //TODO workday change this argument once we implement user workweek schedule
  numberOfWorkingHoursPerDay,
}: IDisplayLeaveInDaysAndHours): React.ReactElement => {
  const { formatMessage } = useIntl()
  if (value === 0) {
    return <>{value}</>
  }

  const val = doNotConvertToHours ? value : value * numberOfWorkingHoursPerDay
  const leave = computeDaysAndHours(val, numberOfWorkingHoursPerDay)

  return <>
    {getFormattedDaysAndHours({
      leave,
      formatMessage,
      displayFormat: format,
    })}
  </>
}


export const displayLeaveInDaysAndHours = ({
  doNotConvertToHours = false,
  value,
  format = LeaveDisplayFormatEnum.long,
  formatMessage,
  //TODO workday change this argument once we implement user workweek schedule
  numberOfWorkingHoursPerDay,
}: IDisplayLeaveInDaysAndHoursBots) => {
  if (value === 0) {
    return `${value}`
  }
  const val = doNotConvertToHours ? value : value * numberOfWorkingHoursPerDay
  const leave = computeDaysAndHours(val, numberOfWorkingHoursPerDay)
  return getFormattedDaysAndHours({
    leave,
    formatMessage,
    displayFormat: format,
  })
}

export const displayToilInDaysAndHours = (formatMessage, toilRequest: IToilRequest) => {
  const amount = toilRequest.isPartDay 
    ? (toilRequest.partDayEndHour as number - Number(toilRequest.partDayStartHour) as number)
    : toilRequest.daysList.length
  
  const unit = toilRequest.isPartDay 
    ? formatMessage({ id: amount === 1 ? 'app.hour' : 'app.hours' })
    : formatMessage({ id: amount === 1 ? 'app.day' : 'app.days' })

  return `${amount} ${unit}`
}
