import React, { useState } from 'react'
import { Result, Row, Col, Button, Typography } from 'antd'
import { CheckCircleOutlined } from '@ant-design/icons'
import { useIntl } from 'react-intl'

import { FeedbackModal } from '../feedback-modal'
import IntlMessages from '../utils/IntlMessages'

import { ILeaveTypeResult, IQuota } from '../../types/calculations'
import { IToilRequestSummaryParams } from './types'
import { calculateToilPeriod, formatToilPeriod } from './period'

const { Text } = Typography

export const getLeavePolicyFromQuota = (leaveTypeId: string, quota: IQuota): ILeaveTypeResult | null => {
  const leavePolicy = quota?.leaveTypes.find(q => q.leaveTypeId === leaveTypeId || `${q.leaveTypeId}|part-day` === leaveTypeId)
  if (leavePolicy) {
    return leavePolicy
  }
  return null
}

export const ToilRequestSummary: React.FC<IToilRequestSummaryParams> = ({
  userId,
  isAdding,
  toilRequest,
  approvers,
  locale,
  hourFormat,
  resetToilRequestForm,
  onSave,
}) => {
  const { formatMessage } = useIntl()
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [isFeedbackSent, setIsFeedbackSent] = useState(false)

  const toggleFeedbackModal = () => setShowFeedbackModal(!showFeedbackModal)

  const handleSendFeedback = async (data) => {
    onSave && await onSave({
      eventGroup: 'FEEDBACK',
      eventType: 'USER_FEEDBACK',
      userId,
      ...data,
    })
    setShowFeedbackModal(false)
    setIsFeedbackSent(true)
  }

  return <div>
    <Result
      icon={<CheckCircleOutlined />}
      title={<IntlMessages id={isAdding ? 'components.toil.added' : 'components.toil.requested'} />}
      subTitle={isAdding
        ? null
        : <IntlMessages id='leaveRequest.simpleQuota.summaryInfo' />}
          
      extra={isFeedbackSent && <div style={{ color: '#7f00ff' }}><IntlMessages id="bot.message.feedback.thanks" /></div>}
    />
    <div style={{width: '50%', margin: 'auto'}}>
      <div>
        <Row>
          <Col span={12}>{formatMessage({ id: 'components.leaveRequestAction.leaveTypeLabel' })}</Col>
          <Col span={12}>{formatMessage({ id: 'components.leaveRequestAction.periodLabel' })}</Col>
        </Row>
        <Row>
          <Col span={12}><Text strong>{toilRequest.leaveTypeName}</Text></Col>
          <Col span={12}>
            <Text strong>{formatToilPeriod( {...toilRequest, ...{locale, hourFormat}}, formatMessage)}</Text>
          </Col>
        </Row>
      </div>
      <br />
      <div>
        <Row>
          <Col span={12}>{formatMessage(
            { id: 'components.toil.requestedOrAddedDaysHours'},
            { 
              period: calculateToilPeriod( {...toilRequest, ...{locale, hourFormat}}, formatMessage),
              action: isAdding ? 0 : 1,
            })}</Col>
        </Row>
        <Row>
          <Col span={12}>
            <Text strong>{toilRequest.userName}</Text>
          </Col>
        </Row>
      </div>
      <br />
      {approvers?.length > 0 && <div>
        <Row>
          <Col span={12}>{formatMessage({ id: 'app.role.approvers'})}:</Col>
        </Row>
        <Row>
          <Text style={{ color: '#7f00ff'}}>{approvers.map(approver => approver.name).join(', ')}</Text>
        </Row>
      </div>}
      <br />
      <Row justify='center'>
        <Col style={{ marginRight: 10 }}>
          {!isFeedbackSent &&
            <Button type='primary' onClick={toggleFeedbackModal}>
              <IntlMessages id='components.feedbackModal.button' />
            </Button>
          }
        </Col>
        <Col>
          <Button onClick={resetToilRequestForm}>
            <IntlMessages id={isAdding ? 'components.toil.anotherAdd' : 'components.toil.anotherRequest'} />
          </Button>
        </Col>
      </Row>
      <FeedbackModal
        visible={showFeedbackModal}
        onCancel={toggleFeedbackModal}
        onSave={handleSendFeedback}
      />
    </div>
  </div>
}