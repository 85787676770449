import { format, addDays, subDays, differenceInDays } from 'date-fns'
import { IAccrualsDates } from './types'

interface IParams {
  accrualPeriodStart?: string
  today: Date
}

export function getDatesForAccrualsWeekly({ today, accrualPeriodStart }: IParams): IAccrualsDates {
  const accrualPeriodStartDate = accrualPeriodStart ? new Date(accrualPeriodStart) : today

  const daysAfterAccrualStartDate = differenceInDays(today, accrualPeriodStartDate) % 14
  const currentAccrualPeriodStart = subDays(today, daysAfterAccrualStartDate)
  const currentAccrualPeriodEnd = addDays(currentAccrualPeriodStart, 6)
  const pickerStartDate = currentAccrualPeriodEnd
  const pickerEndDate = addDays(pickerStartDate, 6)
  const nextAccrualPeriod = addDays(currentAccrualPeriodEnd, 1)

  return {
    accrualPeriodStart: format(currentAccrualPeriodStart, 'yyyy-MM-dd'),
    accrualPeriodEnd: format(currentAccrualPeriodEnd, 'yyyy-MM-dd'),
    pickerStartDate: format(currentAccrualPeriodStart, 'yyyy-MM-dd'),
    pickerEndDate: format(pickerEndDate, 'yyyy-MM-dd'),
    nextAccrualPeriod: format(nextAccrualPeriod, 'yyyy-MM-dd'),
  }
}
