/* eslint-disable max-len */
import React from 'react'
import { Col, Row, Typography } from 'antd'
const { Text } = Typography
import { IGenerateNotificationValues, IUpdateToilRequestNotification } from './types'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'
import { formatToilPeriod } from '@vacationtracker/shared/components/toil-form/period'

const getRequestor = (
  userId,
  authUser,
  subscriptionEvent,
  userName
) => {
  if (userId === authUser?.id) {
    return ''
  }

  if (subscriptionEvent?.eventType === 'TOIL_REQUEST_ADDED') {
    return userName
  }

  return `${userName}'s`
}


const generateNotification = (values: IGenerateNotificationValues, formatMessage: any) => {
  const toilMessage = formatToilPeriod(values, formatMessage)

  return {
    key: values.key,
    description: (
      <div>
        <div>
          <Row>
            <Col span={12}>{formatMessage({ id: 'components.leaveRequestAction.leaveTypeLabel' })}</Col>
            <Col span={12}>{formatMessage({ id: 'components.leaveRequestAction.periodLabel' })}</Col>
          </Row>
          <Row>
            <Col span={12}><Text strong>{values.leaveTypeName}</Text></Col>
            <Col span={12}><Text strong>{toilMessage}</Text></Col>
          </Row>
          <Row>
            <Col span={12}>{formatMessage({ id: 'app.reason' })}</Col>
          </Row>
          <Row>
            <Col span={12}><Text strong>{values.reason}</Text></Col>
          </Row>
          <Row>
            <Col span={12}>{formatMessage({ id: 'app.role.approvers'})}:</Col>
          </Row>
          <Row>
            <Col span={24}><Text strong>{values.approvers.map((a) => a.name).join(', ')}</Text></Col>
          </Row>
        </div>
      </div>
    ),
    message: values.message,
    duration: values.duration,
  }
}

export const updateToilRequestNotification = ({
  subscriptionEvent,
  toilRequest,
  result,
  authUser,
  locale,
  formatMessage,
  notification,
}: IUpdateToilRequestNotification): { key: string } | void | object => {
  const {
    user: { team, name: userName, id: userId },
    leaveType, startDate, endDate, partDayStartHour, partDayEndHour, reason,
  } = toilRequest.getToilRequest

  const { name: editorName, id: editorId } = toilRequest.getUser

  let title
  const actionCreator = editorId === authUser?.id ? '' : editorName
  const requestor = getRequestor(userId, authUser, subscriptionEvent, userName)
  const titleValues = { actionCreator, requestor }
  const descriptionValues = {
    editor: editorName,
    action: '',
    leaveTypeName: leaveType.name,
    startDate,
    endDate,
    partDayStartHour,
    partDayEndHour,
    locale,
    approvers: team.approvers,
    hourFormat: authUser?.hourFormat || HourFormatEnum.twentyFour,
    reason,
  }

  const message: string = subscriptionEvent.message
  switch (subscriptionEvent?.eventType) {
    case 'TOIL_REQUEST_APPROVED':
      title = 'components.toil.notification.approved'
      break

    case 'TOIL_REQUEST_DENIED':
      if (result && result?.isEdited && result?.originalVersion.status === 'APPROVED') {
        title = 'components.toil.notification.editedDenied'
      } else {
        title = 'components.toil.notification.denied'
      }
      break

    case 'TOIL_REQUEST_CANCELLED':
      title = 'components.toil.notification.cancelled'
      break

    case 'TOIL_REQUEST_ADDED':
      title = 'components.toil.notification.added'
      break
    case 'TOIL_REQUEST_UPDATED':
      title = 'components.toil.notification.edited'
      break

    case 'TOIL_REQUEST_CREATED':
      title = 'components.toil.notification.created'
      break

    case 'TOIL_REQUEST_DELETED':
      title = 'components.toil.notification.deleted'
      break
  }

  const getMessageValue = (title, titleValues, message) => {
    const formatedMessage = formatMessage(
      { id: title },
      {
        actionCreator: titleValues.actionCreator,
        byActionCreator: (...chunks) => titleValues?.actionCreator ? chunks : '',
        requestor: titleValues.requestor,
        forUser: (...chunks) => titleValues?.requestor ? chunks : '',
      }
    )
    if (title && formatedMessage) {
      return formatedMessage
    } else if (/^error\.[a-zA-Z0-9]+$/.test(message)) {
      return formatMessage({ id: message })
    } else {
      return message
    }
  }

  const generatedNotification = generateNotification(
    {
      key: subscriptionEvent.correlationId,
      message: getMessageValue(title, titleValues, message),
      ...descriptionValues,
      duration: 30,
    },
    formatMessage
  )

  // notify other user approvers
  if (authUser?.approverTo?.find(u => u.id === subscriptionEvent.userId) && authUser.id !== subscriptionEvent.creator) {
    notification.open(generatedNotification)
  }

  // notify approver of a user who changed toil request
  if (authUser?.approverTo?.find(u => u.id === subscriptionEvent.userId) && editorId === subscriptionEvent.creator) {
    notification.open(generatedNotification)
  }

  // notify user toil request belongs to
  if (authUser?.id === subscriptionEvent.userId) {
    notification.open(generatedNotification)
  }
}