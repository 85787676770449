import { format } from 'date-fns-tz'

import { getDateFnsLocale } from '@vacationtracker/shared/i18n'
import { getTimeZoneOrDefaultToUtc } from '@vacationtracker/shared/functions/timezone'

import { LocaleEnum } from '@vacationtracker/shared/types/i18n'
import { MessageFormatPrimitiveValue } from '@vacationtracker/shared/types/display-in-days-hours'



interface IResetQuotas {
  status: string
  yearStartDay: number
  yearStartMonth: number
  locale: LocaleEnum
  locationTimezone: string
}

export const resetQuotas = (
  { status, yearStartDay, yearStartMonth, locale, locationTimezone }: IResetQuotas,
  formatMessage: ({ id }: { id: string }, values?: Record<string, MessageFormatPrimitiveValue>) => string
): string => {
  if(status === 'FISCAL_YEAR') {
    const timeZone = locationTimezone || getTimeZoneOrDefaultToUtc()
    const currentYear = new Date().getFullYear()
    const monthIndex = yearStartMonth - 1
    const date = format(new Date(currentYear, monthIndex, yearStartDay), 'LLLL do', { timeZone, locale: getDateFnsLocale(locale) })
    return formatMessage({ id: 'components.resetQuotas.fiscalYear' }, { date })
  } else {
    return formatMessage({ id: 'users.employmentDate' })
  }
}
