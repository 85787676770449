import { IEarnedEvent } from '@vacationtracker/shared/types/calculations'
import {
  differenceInBusinessDays,
  getDaysInMonth,
  addDays,
  getDay,
  nextDay,
  subDays,
  differenceInDays,
  isAfter,
  isWithinInterval,
  eachWeekOfInterval,
  addWeeks,
  setDay,
  isBefore,
  endOfDay,
  isLastDayOfMonth,
  lastDayOfMonth,
  min,
  endOfMonth
} from 'date-fns'

/**
 * Round number to 4 decimals
 * Note:
 * Number.EPSILON is the difference between 1 and the next number existing in the double precision floating point numbers.
 * It is relevant only when the value to round is the result of an arithmetic operation, as it can swallow some floating point error delta.
 * https://stackoverflow.com/a/48764436
 */
export function roundTo4Decimals(num: number): number {
  return Math.round((num + Number.EPSILON) * 10000) / 10000
}

export function roundTo2Decimals(num: number): number {
  return Math.round((num + Number.EPSILON) * 100) / 100
}

/**
 * Sum days from all earned events
 */
export function sumEarnedEvents(earnedEvents: IEarnedEvent[]): number {
  const result = earnedEvents.reduce((acc, earnedEvent) => {
    return acc + earnedEvent.amount
  }, 0)
  return roundTo4Decimals(result)
}

/**
 * Prorated amount of earned days in one period.
 * Can be userd for any period - year, month, half month, week, etc...
 * User can start or end employment at any point during the period, so we must prorate the earned amount.
 */
export function proRateQuotaByBusinessDays(periodFrom: Date, periodTo: Date, workedFromDate: Date, workedToDate: Date, fullPeriodDays: number): number {
  const workDaysInWholePeriod = differenceInBusinessDays(addDays(periodTo, 1), periodFrom)
  const daysWorkedByUserInPeriod = differenceInBusinessDays(addDays(workedToDate, 1), workedFromDate)

  return roundTo4Decimals(fullPeriodDays * (daysWorkedByUserInPeriod / workDaysInWholePeriod))
}

/**
 * Days earned for last period, to take care of the rounding error
 */
export function getEarningForLastPeriod(quotaForCurrentYear: number, earnedEventsSoFar: IEarnedEvent[]): number {
  const earnedDaysSoFar: number = sumEarnedEvents(earnedEventsSoFar)
  return roundTo4Decimals(quotaForCurrentYear - earnedDaysSoFar)
}

/**
 * Payout date for periods in Montlhy calculation
 * @param periodEnd End of the mothly period
 * @param firstEarningDate Date chosen in the date picker as the first payout date after cofiguring accruals
 */
export function getPayoutDateForMonthlyPeriod(periodEnd: Date, firstEarningDate: Date, accrualPeriodStart?: Date): Date {
  // We don't need UTC time on the front end, but on the backend, the time is in UTC.
  const payoutDayOfMonth = firstEarningDate.getDate()

  const currentPeriodEndDayOfMonth = periodEnd.getDate()
  const isPayoutOnLastDayOfMonth = payoutDayOfMonth === getDaysInMonth(firstEarningDate)

  if (accrualPeriodStart) {
    const firstEarningDateMonth = firstEarningDate.getMonth()
    const firstEarningDateDay = firstEarningDate.getDate()
    const accrualPeriodStartMonth = accrualPeriodStart.getMonth()
    const accrualPeriodStartDay = accrualPeriodStart.getDate()
    const isOneDayBeforeLastDayOfMonth = differenceInDays(
      lastDayOfMonth(accrualPeriodStart),
      new Date(accrualPeriodStart.getFullYear(), accrualPeriodStart.getMonth(), payoutDayOfMonth)
    ) === 1
    const isTwoDaysBeforeLastDayOfMonth = differenceInDays(
      lastDayOfMonth(accrualPeriodStart),
      new Date(accrualPeriodStart.getFullYear(), accrualPeriodStart.getMonth(), payoutDayOfMonth)
    ) === 2

    if (isLastDayOfMonth(firstEarningDate)) {
      return new Date(periodEnd.getFullYear(), periodEnd.getMonth(), lastDayOfMonth(periodEnd).getDate())
    }
    else if (isOneDayBeforeLastDayOfMonth) {
      return new Date(periodEnd.getFullYear(), periodEnd.getMonth(), subDays(lastDayOfMonth(periodEnd), 1).getDate())
    }
    else if (isTwoDaysBeforeLastDayOfMonth) {
      return new Date(periodEnd.getFullYear(), periodEnd.getMonth(), subDays(lastDayOfMonth(periodEnd), 2).getDate())
    }
    else if (firstEarningDateMonth === accrualPeriodStartMonth && payoutDayOfMonth === 1) {
      return new Date(periodEnd.getFullYear(), periodEnd.getMonth(), payoutDayOfMonth)
    }
    else if (firstEarningDateMonth === accrualPeriodStartMonth && firstEarningDateDay > accrualPeriodStartDay && accrualPeriodStartDay !== 1) {
      return new Date(periodEnd.getFullYear(), periodEnd.getMonth() - 1, payoutDayOfMonth)
    }
    else if (firstEarningDateMonth === accrualPeriodStartMonth && accrualPeriodStartDay >= payoutDayOfMonth) {
      return new Date(periodEnd.getFullYear(), periodEnd.getMonth() - 1, payoutDayOfMonth)
    }
    else if (firstEarningDateMonth === accrualPeriodStartMonth + 1 && accrualPeriodStartDay > payoutDayOfMonth) {
      return new Date(periodEnd.getFullYear(), periodEnd.getMonth(), payoutDayOfMonth)
    }
    else if (firstEarningDateMonth === accrualPeriodStartMonth) {
      return new Date(periodEnd.getFullYear(), periodEnd.getMonth(), payoutDayOfMonth)
    }
  }

  if (isPayoutOnLastDayOfMonth) {
    const daysInMonth = getDaysInMonth(periodEnd)
    return new Date(periodEnd.getFullYear(), periodEnd.getMonth(), daysInMonth)
  } else if (currentPeriodEndDayOfMonth <= payoutDayOfMonth) {
    const daysInMonth = getDaysInMonth(periodEnd)
    const adjustedPayoutDay = daysInMonth < payoutDayOfMonth ? daysInMonth : payoutDayOfMonth
    return new Date(periodEnd.getFullYear(), periodEnd.getMonth(), adjustedPayoutDay)
  }
  else {
    const daysInMonth = getDaysInMonth(new Date(periodEnd.getFullYear(), periodEnd.getMonth() + 1))
    const adjustedPayoutDay = daysInMonth < payoutDayOfMonth ? daysInMonth : payoutDayOfMonth
    return new Date(periodEnd.getFullYear(), periodEnd.getMonth() + 1, adjustedPayoutDay)
  }
}

/**
 * Payout date for periods in Semi-Montlhy calculation
 * @param periodStart Start of the semi-mothly period
 * @param periodEnd End of the semi-mothly period
 * @param firstEarningDate Date chosen in the date picker as the first payout date after cofiguring accruals
 */
export function getPayoutDateForSemiMonthlyPeriod(periodStart: Date, periodEnd: Date, firstEarningDate: Date, accrualPeriodStart?: Date): Date {

  // We always need to have exactly 2 payout days in one month
  // If the day of the month is greater than 15, subtract 15 days from the first earning date and get the new day of the month.
  // Otherwise, add 15 days to the first earning date, but if this results in a date in the next month, use the last day of the current month instead.
  // Then get the day of the month for the resulting date.
  const firstPayoutDate = firstEarningDate.getDate()
  const secoundPayoutDate = firstPayoutDate > 15 ? subDays(firstEarningDate, 15).getDate() : min([addDays(firstEarningDate, 15), endOfMonth(firstEarningDate)]).getDate()
  
  const [payoutDay1, payoutDay2] = [firstPayoutDate, secoundPayoutDate].sort((a,b) => a - b)

  let currentPeriodEndDay = periodEnd.getDate()
  const daysInMonth = getDaysInMonth(periodEnd)
  let currentPeriod = periodEnd

  if (accrualPeriodStart && accrualPeriodStart.toISOString().split('T')[0] !== new Date(0).toISOString().split('T')[0]) {
    const earningDateIsInCurrentPeriod = isWithinInterval(new Date(firstEarningDate), {
      start: new Date(accrualPeriodStart),
      end: addDays(new Date(accrualPeriodStart), 16),
    })

    if (earningDateIsInCurrentPeriod) {
      currentPeriodEndDay = periodStart.getDate()
      currentPeriod = periodStart
    }
    if (currentPeriodEndDay <= payoutDay1) {
      return new Date(currentPeriod.getFullYear(), currentPeriod.getMonth(), payoutDay1)
    }
    // Period ends before the second payout day, but still in the same month = payout on day 2
    else if (currentPeriodEndDay <= payoutDay2) {
      return new Date(currentPeriod.getFullYear(), currentPeriod.getMonth(), payoutDay2)
    }
    // Period ends after payout day 2 = move payout to payout day 1 next month
    else {
      return new Date(currentPeriod.getFullYear(), currentPeriod.getMonth() + 1, payoutDay1)
    }
  }

  // Period ends before the first payout day = payout on day 1
  if (currentPeriodEndDay <= payoutDay1) {
    return new Date(currentPeriod.getFullYear(), currentPeriod.getMonth(), payoutDay1)
  }
  // Currently in second period, and payout day 2 is the end of the month = payout on the end of the month
  else if (currentPeriodEndDay <= daysInMonth && [28, 29, 30, 31].includes(payoutDay2)) {
    return new Date(currentPeriod.getFullYear(), currentPeriod.getMonth(), daysInMonth)
  }
  // Period ends before the second payout day, but still in the same month = payout on day 2
  else if (currentPeriodEndDay <= payoutDay2) {
    return new Date(currentPeriod.getFullYear(), currentPeriod.getMonth(), payoutDay2)
  }
  // Period ends after payout day 2 = move payout to payout day 1 next month
  else {
    return new Date(currentPeriod.getFullYear(), currentPeriod.getMonth() + 1, payoutDay1)
  }
}

/**
 * Payout date for periods in Bi-Weekly calculation
 * @param periodStart start of the bi-weekly period
 * @param periodEnd End of the bi-weekly period
 * @param firstEarningDate Date chosen in the date picker as the first payout date after cofiguring accruals
 */
export function getPayoutDateForBiWeeklyPeriod(periodStart: Date, periodEnd: Date, firstEarningDate: Date, accrualPeriodStart?: Date): Date {
  const payoutDayOfWeek = getDay(firstEarningDate)

  if (
    accrualPeriodStart &&
    new Date(accrualPeriodStart).toISOString().split('T')[0] !== new Date(0).toISOString().split('T')[0] &&
    !isBefore(new Date(firstEarningDate), new Date(accrualPeriodStart)) &&
    (isAfter(addDays(new Date(accrualPeriodStart.toISOString().split('T')[0]), 13), new Date(firstEarningDate.toISOString().split('T')[0])))
  ) {
    const differenceInWeeks = eachWeekOfInterval({
      start: new Date(accrualPeriodStart),
      end: new Date(firstEarningDate),
    }).length

    return endOfDay(setDay(addWeeks(periodStart, differenceInWeeks - 1), payoutDayOfWeek))
  }
  return nextDay(subDays(periodEnd, 1), payoutDayOfWeek)
}

/**
 * Payout date for periods in Weekly calculation
 * @param periodStart start of the weekly period
 * @param periodEnd End of the weekly period
 * @param firstEarningDate Date chosen in the date picker as the first payout date after cofiguring accruals
 */
export function getPayoutDateForWeeklyPeriod(periodStart: Date, periodEnd: Date, firstEarningDate: Date, accrualPeriodStart?: Date): Date {
  const payoutDayOfWeek = getDay(firstEarningDate)

  if (
    accrualPeriodStart &&
    new Date(accrualPeriodStart).toISOString().split('T')[0] !== new Date(0).toISOString().split('T')[0] &&
    !isBefore(new Date(firstEarningDate), new Date(accrualPeriodStart)) &&
    (isAfter(addDays(new Date(accrualPeriodStart.toISOString().split('T')[0]), 13), new Date(firstEarningDate.toISOString().split('T')[0])))
  ) {
    const differenceInWeeks = eachWeekOfInterval({
      start: new Date(accrualPeriodStart),
      end: new Date(firstEarningDate),
    }).length

    return endOfDay(setDay(addWeeks(periodStart, differenceInWeeks - 1), payoutDayOfWeek))
  }
  return nextDay(subDays(periodEnd, 1), payoutDayOfWeek)
}

export function calculateProratedQuota(params: {
  defaultDaysPerYear: number
  yearStart: Date
  userStartDate: Date
}): number {
  const {yearStart, userStartDate, defaultDaysPerYear} = params
  let yearMultiplier
  if (isAfter(yearStart, userStartDate)) {
    yearMultiplier = 1
  } else {
    const diffInDays = 365 - differenceInDays(userStartDate, yearStart)
    yearMultiplier = diffInDays / 365
  }
  return Math.round(defaultDaysPerYear * yearMultiplier * 10000) / 10000
}
