import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Button, Form, Result, Select } from 'antd'
import axios from 'axios'
import { useAppSelector } from '../../store/hooks'
import { selectFeatureFlagsSlice } from '../../store/feature-flags-slice'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { FrontendUrls } from '../../types/urls'
import { getAuthTokens } from '@vacationtracker/shared/services/auth'

const { Option } = Select

const FeatureFlagsPage: React.FC = () => {
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)
  const [showSuccess, setShowSuccess] = React.useState(false)
  const [form] = Form.useForm()
  const history = useHistory()
  const hash = location.hash?.slice(1)

  useEffect(() => {
    if (!hash) {
      history.push(FrontendUrls.dashboard)
    }
  }, [])

  useEffect(() => {
    if (featureFlags) {
      form.setFieldsValue({ featureFlags })
    }
  }, [featureFlags, form])

  const handleSave = () => {
    form
      .validateFields()
      .then(async (values) => {
        const tokens = await getAuthTokens()
        await axios.post(`${process.env.REACT_APP_API_URL}/core/feature-flags`, { featureFlags: values.featureFlags, hash }, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${tokens?.idToken}`,
          },
        })
        setShowSuccess(true)
        setTimeout(() => { setShowSuccess(false) }, 2000)
      })
      .catch((errorInfo) => {
        console.error('Validation Failed:', errorInfo)
      })
  }

  return (
    <div className="main-content">
      <div className="main-content-header">
        <div className="main-content-header-title">Feature Flags</div>
      </div>
      <div className="main-content-body">
        <div>
          <Form form={form}>
            <Form.Item label="Select" name="featureFlags">
              <Select mode="multiple" value={form.getFieldValue('featureFlags')}>
                {Object.values(FeatureFlagEnum).map((flag) => (<Option key={flag} value={flag}>{flag}</Option>))}
              </Select>
            </Form.Item>
            <Form.Item>
              <div style={{ textAlign: 'right' }}><Button type="primary" onClick={handleSave}>Save</Button></div>
            </Form.Item>
          </Form>
        </div>
        {showSuccess && <Result status="success" title="Flags updated!"/>}
      </div>
    </div>
  )
}

export default FeatureFlagsPage
