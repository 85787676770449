import React from 'react'
import { Modal, Skeleton } from 'antd'

import IntlMessages from '../../util/IntlMessages'
import { IUpcomingInvoiceInformation } from '@vacationtracker/shared/types/stripe'

interface ISubscribeToAddons {
  visibleSubscribeAddon: boolean
  amIAdmin: boolean
  handleSubscribeToAddons: () => void
  onCancel: () => void
  isLoadingButtonAddon: boolean
  isTrialSubscription: boolean
  addonsPrice: number
  userCount: number
  upcomingInvoices: IUpcomingInvoiceInformation | null
}

const SubscribeToAddons = ({
  visibleSubscribeAddon,
  amIAdmin,
  handleSubscribeToAddons,
  onCancel,
  isLoadingButtonAddon,
  isTrialSubscription,
  addonsPrice,
  userCount,
  upcomingInvoices,
}: ISubscribeToAddons) => {

  return (
    <Modal
      title={<IntlMessages id="automations.subscripteToAddons" />}
      open={visibleSubscribeAddon}
      onCancel={onCancel}
      onOk={handleSubscribeToAddons}
      okText={amIAdmin ? <IntlMessages id="app.confirm" /> : <IntlMessages id="app.ok" />}
      cancelText={<IntlMessages id="app.close" />}
      confirmLoading={isLoadingButtonAddon}
    >
      <>
        {amIAdmin && isTrialSubscription && 
          <IntlMessages id="automations.addAddonsDescirptionForAdminInTrial"
            values={{
              addonsPrice: addonsPrice,
              userCount: userCount,
              total: (addonsPrice * userCount).toLocaleString('en', { minimumFractionDigits: 2 }),
            }}
          />
        }
        {amIAdmin && !isTrialSubscription && 
          <IntlMessages id="automations.addAddonsDescirptionForAdmin"
            values={{
              prorateAmount:
                <span>
                  {!upcomingInvoices ? <Skeleton.Input active style={{ height: 24, minWidth: 80, width: 80 }} /> : upcomingInvoices?.prorateAmount}
                </span>,
              nextInvoiceAmount:
                <span>
                  {!upcomingInvoices ? <Skeleton.Input active style={{ height: 24, minWidth: 80, width: 80 }} /> : upcomingInvoices?.nextInvoiceAmount}
                </span>,
            }}
          />
        }
        {!amIAdmin &&
          <IntlMessages id="automations.addAddonsDescirptionForApprover" />
        }
      </>
    </Modal>
  )
}
export default SubscribeToAddons
