import {
  addDays,
  differenceInCalendarYears,
  setHours,
  setMilliseconds,
  setMinutes,
  setSeconds,
  addYears
} from 'date-fns'

import { IUserQuota } from '../../../frontend/src/types/custom-queries'

const getValidityStart = (selectedQuotaPeriod: IUserQuota, currentPeriod: IUserQuota): string => {
  const diffYears = differenceInCalendarYears(new Date(selectedQuotaPeriod.yearEnd), new Date(currentPeriod.yearEnd))
  const todaysDateInSelectedRange = addYears(new Date(), diffYears)
  const percentageOfThePeriodElapsed = ( // ((today - start) / (end - today)) * 60 MIN * 60 S * 1000 MS
    (todaysDateInSelectedRange.getTime() - new Date(selectedQuotaPeriod.yearStart).getTime()) /
    (new Date(selectedQuotaPeriod.yearEnd).getTime() - todaysDateInSelectedRange.getTime())
  )
  const percentageOfThePeriod = Math.abs(Math.round(percentageOfThePeriodElapsed * 60 * 60 * 1000))

  const oneDayAfterYearStart = addDays(new Date(selectedQuotaPeriod.yearStart), 2) // Add two days to the year start
  const validityStart = setMilliseconds(
    setSeconds(
      setMinutes(
        setHours(oneDayAfterYearStart, 0),                // Set hours to 0
        Math.round(percentageOfThePeriod / 60000)         // period / 60 / 1000 = number of minutes
      ),
      Math.round((percentageOfThePeriod % 60000) / 1000) // remaining after minutes / 1000 = number of seconds
    ),
    Math.round((percentageOfThePeriod % 60000) % 1000)    // remaining = number of milliseconds
  )

  // Uncomment for debug purposes:
  // console.log('%', percentageOfThePeriod)
  // console.log('(selectedQuotaPeriod.yearEnd)', selectedQuotaPeriod.yearEnd)
  // console.log('(selectedQuotaPeriod.yearStart)', selectedQuotaPeriod.yearStart)
  // console.log('(validityStart)', validityStart.toISOString())

  return validityStart.toISOString()
}

export default getValidityStart

