import { IComputeDaysAndHours } from '../functions/get-days-or-hours'
import { ILeaveDayDetails } from './leave-request'

export type MessageFormatPrimitiveValue = string | number | boolean | null | undefined

export enum LeaveDisplayFormatEnum {
  long = 'long',
  short = 'short'
}
export interface IFormatMessage {
  id: string
  values?: Record<string, MessageFormatPrimitiveValue>
}

export interface IDisplayLeaveDuration {
  value: number
  doNotConvertToHours?: boolean
  format?: LeaveDisplayFormatEnum
  formatMessage?: (d: IFormatMessage) => string
  daysList?: ILeaveDayDetails[]
}

export interface IDisplayLeaveDurationBots {
  value: number
  doNotConvertToHours?: boolean
  format?: LeaveDisplayFormatEnum
  formatMessage: (d: IFormatMessage) => string
  daysList?: ILeaveDayDetails[]
}

export interface IDisplayLeaveInDaysAndHours {
  value: number
  formatMessage?: (d: IFormatMessage) => string
  doNotConvertToHours?: boolean
  format?: LeaveDisplayFormatEnum
  numberOfWorkingHoursPerDay: number
}
export interface IDisplayLeaveInDaysAndHoursBots {
  value: number
  formatMessage: (d: IFormatMessage) => string
  doNotConvertToHours?: boolean
  format?: LeaveDisplayFormatEnum
  numberOfWorkingHoursPerDay: number
}

export interface IGetFormattedDaysAndHours {
  leave: IComputeDaysAndHours
  formatMessage: (d: IFormatMessage) => string
  displayFormat: LeaveDisplayFormatEnum
}