import React from 'react'
import { Typography } from 'antd'

import IntlMessages from '../../util/IntlMessages'

const { Title } = Typography

const BotUpdatePermissionsPage: React.FC = () => {

  return (
    <div className="main-content" style={{ paddingTop: 150, textAlign: 'center' }}>
      <Title level={4}><IntlMessages id="botUpdatePermissions.thanks" /></Title>
    </div>
  )
}

export default BotUpdatePermissionsPage
