import React from 'react'
import { useIntl } from 'react-intl'
import { Col, Divider, Form, Input, Row, Select, Tooltip, Typography } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import IntlMessages from '../../util/IntlMessages'
import { filterOptions } from '../../util/filter-options'
import YesNoSwitch from '../yes-no-switch'

import { IGetUserListShort } from '../../types/teams'
import { ITeamShort } from '@vacationtracker/shared/types/team'

interface ITeamForm {
  team: {
    id: string
    name: string
    isDefault: boolean
    users: string[]
  }
  teams: ITeamShort[]
  users: IGetUserListShort[]
  totalTeams: number
  formType: 'create' | 'update'
}

const { Paragraph, Title } = Typography

const TeamForm = ({ team, users, teams, totalTeams, formType }: ITeamForm): React.ReactElement => {
  const { formatMessage } = useIntl()

  const isRemovalFromTeamDisabled = (userId: string) => {
    if (!team.isDefault) {
      return false
    }
    return team.users.find(id => id === userId) !== undefined
  }

  return (
    <>
      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <Title level={4}>
            <IntlMessages id="form.department.generalTitle" />
          </Title>
          <Paragraph type="secondary">
            {
              formType === 'create' ? (
                <IntlMessages id="form.department.generalDescriptionCreate" />
              ) : (
                <IntlMessages id="form.department.generalDescriptionEdit" />
              )
            }
          </Paragraph>
        </Col>
        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          <Form.Item
            name="name"
            label={<IntlMessages id="app.name" />}
            rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
          >
            <Input maxLength={100} placeholder={`${formatMessage({ id: 'components.teamForm.pleaseEnterTeamName' })}`} data-qa='department-name' />
          </Form.Item>
          <Form.Item
            name="users"
            label={<>
              <span>{formatMessage({ id: 'app.users' })}</span>
              {team.isDefault && <Tooltip className='info-tooltip' title={<IntlMessages id="teams.removeUserInfo" />} ><InfoCircleOutlined/></Tooltip>}
            </>}
          >
            <Select
              mode="multiple"
              disabled={(team.id && totalTeams === 1) ? true : false}
              filterOption={filterOptions}
            >
              {users.map(user => <Select.Option disabled={isRemovalFromTeamDisabled(user.id)} key={user.id} value={user.id}>{user.name}</Select.Option>)}
            </Select>
          </Form.Item>

          <Form.Item
            name="approvers"
            label={<IntlMessages id="app.role.approvers" />}
            rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
          >
            <Select
              mode="multiple"
              filterOption={filterOptions}
              data-qa='teams-approvers'
            >
              {users.map(user => <Select.Option key={user.id} value={user.id}>{user.name}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Divider />

      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <Title level={4}>
            <IntlMessages id="form.department.setDefaultTitle" />
          </Title>
          <Paragraph type="secondary">
            <IntlMessages id="components.teamForm.isDefaultExtra" />
          </Paragraph>
        </Col>
        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          <YesNoSwitch
            name="isDefault"
            label={`${formatMessage({ id: 'components.teamForm.isDefault' })}`}
            defaultValue={team.isDefault}
            disabled={Boolean(team.isDefault || (team.id && totalTeams === 1))}
          />
        </Col>
      </Row>

      <Divider />

      <Row gutter={{
        xs: 8,
        sm: 16,
        md: 24,
        lg: 32,
      }}>
        <Col xxl={8} xl={8} lg={6} md={6} sm={24} xs={24}>
          <Title level={4}>
            <IntlMessages id="form.department.subdepartmentTitle" />
          </Title>
          <Paragraph type="secondary">
            <IntlMessages id="form.department.subdepartmentDescription" />
          </Paragraph>
        </Col>
        <Col xxl={16} xl={16} lg={18} md={18} sm={24} xs={24}>
          <Form.Item
            name="parentId"
            label={<IntlMessages id="teamForm.setAsSubdepartment" />}
          >
            <Select
              filterOption={filterOptions}
            >
              <Select.Option value={null}>{formatMessage({ id: 'app.none' })}</Select.Option>
              {teams.filter(t => t.id !== team.id).map(t => <Select.Option key={t.id} value={t.id}>{t.name}</Select.Option>)}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      
    </>
  )
}

export default TeamForm
