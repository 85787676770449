import React from 'react'
import { Typography } from 'antd'
import { getBucketPlanInfo, PlanPricePerUser } from '../../util/get-price-plan-bucket-info'
import IntlMessages from '../../util/IntlMessages'
import { USER_PLAN_LIMIT } from '@vacationtracker/shared/types/billing'

const { Title, Paragraph } = Typography

interface CurrentPlanMessageProps {
  period: string
  plan: string
  noOfActiveUsers: number
}

const CurrentPlanMessage: React.FC<CurrentPlanMessageProps> = ({ period, plan, noOfActiveUsers }) => {
  const { price } = getBucketPlanInfo(noOfActiveUsers, plan)

  if (period === 'annual') {
    return <>
      <Title level={4}>{plan}</Title>
      <Paragraph>${price} <IntlMessages id="app.billing.periodAnnual" /></Paragraph>
    </>
  }
  
  if (period === 'monthly') {
    if (noOfActiveUsers > USER_PLAN_LIMIT) {
      return (<>
        <Title level={4}>{plan}</Title>
        <Paragraph>${plan === 'Complete'
          ? Math.max(noOfActiveUsers, USER_PLAN_LIMIT) * PlanPricePerUser.Complete
          : Math.max(noOfActiveUsers, USER_PLAN_LIMIT) * PlanPricePerUser.Core
        } <IntlMessages id="app.monthly" /></Paragraph>
      </>)
    } else {
      return (<>
        <Title level={4}>{plan}</Title>
        <Paragraph>${plan === 'Complete'
          ? Math.max(noOfActiveUsers, USER_PLAN_LIMIT) * PlanPricePerUser.Complete // 25 is the minimum number of users
          : Math.max(noOfActiveUsers, USER_PLAN_LIMIT) * PlanPricePerUser.Core
        } <IntlMessages id="app.monthly" /></Paragraph>
      </>)
    }
  }

  return null
}

export default CurrentPlanMessage