import { Dispatch } from 'react'

interface IActions {
  type: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any
}

export const actions = (dispatch: Dispatch<IActions>) => {
  return {
    setActionNotifications: (actionNotifications: string[]) => {
      dispatch({
        type: 'SET_ACTION_NOTIFICATION',
        value: actionNotifications,
      })
    },
    setEventsNotifications: (eventsNotifications: object) => {
      dispatch({
        type: 'SET_EVENTS_NOTIFICATION',
        value: eventsNotifications,
      })
    },
  }
}