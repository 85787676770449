export interface ICountry {
  name: string
  iso: string
  googleId?: string
  states?: IState[]
}

export interface IState {
  name: string
  iso: string
}

// https://www.googleapis.com/calendar/v3/calendars/en.${values.country}%23holiday%40group.v.calendar.google.com/events?key=AIzaSyDKEPIrmlCfBp9OpeW-vg6eQg1eWTarcZo&maxResults=2500

//google country code to iso code:
//https://gist.github.com/seanblanchfield/4df78d73c85b149133615c7953894076

export const countries: ICountry[] = [
  { iso: 'AD', name: 'Andorra' },
  { iso: 'AE', name: 'United Arab Emirates' },
  { iso: 'AF', name: 'Afghanistan', googleId: 'af' },
  { iso: 'AG', name: 'Antigua & Barbuda' },
  { iso: 'AI', name: 'Anguilla' },
  { iso: 'AL', name: 'Albania' },
  { iso: 'AM', name: 'Armenia' },
  { iso: 'AO', name: 'Angola' },
  { iso: 'AQ', name: 'Antarctica', googleId: 'aq' },
  { iso: 'AR', name: 'Argentina' },
  { iso: 'AS', name: 'American Samoa' },
  { iso: 'AT', name: 'Austria' },
  { iso: 'AU', name: 'Australia' },
  { iso: 'AW', name: 'Aruba' },
  { iso: 'AX', name: 'Åland Islands' },
  { iso: 'AZ', name: 'Azerbaijan' },
  { iso: 'BA', name: 'Bosnia and Herzegovina' },
  { iso: 'BB', name: 'Barbados' },
  { iso: 'BD', name: 'People\'s Republic of Bangladesh' },
  { iso: 'BE', name: 'Belgium' },
  { iso: 'BF', name: 'Burkina Faso' },
  { iso: 'BG', name: 'Bulgaria' },
  { iso: 'BH', name: 'Bahrain' },
  { iso: 'BI', name: 'Burundi' },
  { iso: 'BJ', name: 'Benin' },
  { iso: 'BL', name: 'St. Barths' },
  { iso: 'BM', name: 'Bermuda' },
  { iso: 'BN', name: 'Brunei' },
  { iso: 'BO', name: 'Bolivia' },
  { iso: 'BQ', name: 'Caribbean Netherlands' },
  { iso: 'BR', name: 'Brazil' },
  { iso: 'BS', name: 'Bahamas' },
  { iso: 'BT', name: 'Bhutan', googleId: 'bt' },
  { iso: 'BV', name: 'Bouvet Island', googleId: 'bv' },
  { iso: 'BW', name: 'Botswana' },
  { iso: 'BY', name: 'Belarus' },
  { iso: 'BZ', name: 'Belize' },
  { iso: 'CA', name: 'Canada', googleId: 'canadian', states: [
    { name: 'Alberta', iso: 'ab' },
    { name: 'British Columbia', iso: 'bc' },
    { name: 'Manitoba', iso: 'mb' },
    { name: 'New Brunswick', iso: 'nb' },
    { name: 'Newfoundland and Labrador', iso: 'nl' },
    { name: 'Nova Scotia', iso: 'ns' },
    { name: 'Nunavut', iso: 'nu' },
    { name: 'Ontario', iso: 'on' },
    { name: 'Prince Edward Island', iso: 'pe' },
    { name: 'Quebec', iso: 'qc' },
    { name: 'Saskatchewan', iso: 'sk' },
    { name: 'Northwest Territories', iso: 'nt' },
    { name: 'Yukon', iso: 'yt' },
  ]},
  { iso: 'CC', name: 'Cocos (Keeling) Islands' },
  { iso: 'CD', name: 'Democratic Republic of the Congo' },
  { iso: 'CF', name: 'Central African Republic' },
  { iso: 'CG', name: 'Republic of the Congo' },
  { iso: 'CH', name: 'Switzerland' },
  { iso: 'CI', name: 'Republic of Côte d\'Ivoire' },
  { iso: 'CK', name: 'Cook Islands' },
  { iso: 'CL', name: 'Chile' },
  { iso: 'CM', name: 'Cameroon' },
  { iso: 'CN', name: 'China' },
  { iso: 'CO', name: 'Colombia' },
  { iso: 'CR', name: 'Costa Rica' },
  { iso: 'CU', name: 'Cuba' },
  { iso: 'CV', name: 'Cape Verde' },
  { iso: 'CW', name: 'Curaçao' },
  { iso: 'CX', name: 'Christmas Island' },
  { iso: 'CY', name: 'Cyprus' },
  { iso: 'CZ', name: 'Czech Republic' },
  { iso: 'DE', name: 'Germany' },
  { iso: 'DJ', name: 'Djibouti' },
  { iso: 'DK', name: 'Denmark' },
  { iso: 'DM', name: 'Dominica' },
  { iso: 'DO', name: 'Dominican Republic' },
  { iso: 'DZ', name: 'Algeria' },
  { iso: 'EC', name: 'Ecuador' },
  { iso: 'EE', name: 'Estonia' },
  { iso: 'EG', name: 'Egypt' },
  { iso: 'EH', name: 'Western Sahara' },
  { iso: 'ER', name: 'Eritrea' },
  { iso: 'ES', name: 'Spain' },
  { iso: 'ET', name: 'Ethiopia' },
  { iso: 'FI', name: 'Finland' },
  { iso: 'FK', name: 'Falkland Islands', googleId: 'fk' },
  { iso: 'FJ', name: 'Fiji' },
  { iso: 'FM', name: 'Micronesia', googleId: 'fm' },
  { iso: 'FO', name: 'Faroe Islands' },
  { iso: 'FR', name: 'France' },
  { iso: 'GA', name: 'Gabon' },
  { iso: 'GB', name: 'United Kingdom' },
  { iso: 'GD', name: 'Grenada' },
  { iso: 'GE', name: 'Georgia' },
  { iso: 'GF', name: 'French Guiana' },
  { iso: 'GG', name: 'Guernsey' },
  { iso: 'GH', name: 'Ghana' },
  { iso: 'GI', name: 'Gibraltar' },
  { iso: 'GL', name: 'Greenland' },
  { iso: 'GM', name: 'The Gambia' },
  { iso: 'GN', name: 'Guinea' },
  { iso: 'GP', name: 'Guadeloupe' },
  { iso: 'GQ', name: 'Republic of Equatorial Guinea' },
  { iso: 'GR', name: 'Greece' },
  { iso: 'GS', name: 'South Georgia & South Sandwich Islands', googleId: 'gs' },
  { iso: 'GT', name: 'Guatemala' },
  { iso: 'GU', name: 'Guam' },
  { iso: 'GW', name: 'Guinea-Bissau' },
  { iso: 'GY', name: 'Guyana' },
  { iso: 'HK', name: 'Hong Kong' },
  { iso: 'HM', name: 'Heard Island and Mcdonald Islands', googleId: 'hm' },
  { iso: 'HN', name: 'Honduras' },
  { iso: 'HR', name: 'Croatia' },
  { iso: 'HT', name: 'Haiti' },
  { iso: 'HU', name: 'Hungary' },
  { iso: 'IC', name: 'Canary Islands' },
  { iso: 'ID', name: 'Indonesia' },
  { iso: 'IE', name: 'Ireland' },
  { iso: 'IL', name: 'Israel' },
  { iso: 'IM', name: 'Isle of Man' },
  { iso: 'IN', name: 'India', googleId: 'indian' },
  { iso: 'IO', name: 'British Indian Ocean Territory', googleId: 'io' },
  { iso: 'IQ', name: 'Iraq', googleId: 'iq' },
  { iso: 'IR', name: 'Iran' },
  { iso: 'IS', name: 'Iceland' },
  { iso: 'IT', name: 'Italia' },
  { iso: 'JE', name: 'Jersey' },
  { iso: 'JM', name: 'Jamaica' },
  { iso: 'JO', name: 'Jordan', googleId: 'jo' },
  { iso: 'JP', name: 'Japan' },
  { iso: 'KE', name: 'Kenya' },
  { iso: 'KG', name: 'Kyrgyzstan', googleId: 'kg' },
  { iso: 'KH', name: 'Cambodia', googleId: 'kh' },
  { iso: 'KI', name: 'Kiribati', googleId: 'ki' },
  { iso: 'KM', name: 'Comoros' },
  { iso: 'KN', name: 'St. Kitts & Nevis' },
  { iso: 'KP', name: 'North Korea', googleId: 'kp' },
  { iso: 'KR', name: 'South Korea' },
  { iso: 'KW', name: 'Kuwait', googleId: 'kw' },
  { iso: 'KY', name: 'Cayman Islands' },
  { iso: 'KZ', name: 'Kazakhstan', googleId: 'kz' },
  { iso: 'LA', name: 'Laos', googleId: 'la' },
  { iso: 'LB', name: 'Lebanon', googleId: 'lb' },
  { iso: 'LC', name: 'St. Lucia' },
  { iso: 'LI', name: 'Lichtenstein' },
  { iso: 'LK', name: 'Sri Lanka', googleId: 'lk' },
  { iso: 'LR', name: 'Liberia' },
  { iso: 'LS', name: 'Lesotho' },
  { iso: 'LT', name: 'Lithuania' },
  { iso: 'LU', name: 'Luxembourg' },
  { iso: 'LV', name: 'Latvia' },
  { iso: 'LY', name: 'Libya' },
  { iso: 'MA', name: 'Kingdom of Morocco' },
  { iso: 'MC', name: 'Monaco' },
  { iso: 'MD', name: 'Moldova' },
  { iso: 'ME', name: 'Montenegro' },
  { iso: 'MF', name: 'St. Martin' },
  { iso: 'MG', name: 'Madagascar' },
  { iso: 'MH', name: 'Marshall Islands', googleId: 'mh' },
  { iso: 'MK', name: 'Macedonia' },
  { iso: 'ML', name: 'Republic of Mali' },
  { iso: 'MM', name: 'Myanmar', googleId: 'mm' },
  { iso: 'MN', name: 'Mongolia', googleId: 'mn' },
  { iso: 'MO', name: 'Macau SAR China', googleId: 'mo' },
  { iso: 'MP', name: 'Northern Mariana Islands', googleId: 'mp' },
  { iso: 'MQ', name: 'Martinique' },
  { iso: 'MR', name: 'Mauritania' },
  { iso: 'MS', name: 'Montserrat' },
  { iso: 'MT', name: 'Malta' },
  { iso: 'MU', name: 'Mauritius', googleId: 'mu' },
  { iso: 'MV', name: 'Maldives', googleId: 'mv' },
  { iso: 'MW', name: 'Malawi' },
  { iso: 'MX', name: 'Mexico' },
  { iso: 'MY', name: 'Malaysia' },
  { iso: 'MZ', name: 'Mozambique' },
  { iso: 'NA', name: 'Namibia' },
  { iso: 'NC', name: 'New Caledonia' },
  { iso: 'NE', name: 'Republic of the Niger' },
  { iso: 'NF', name: 'Norfolk Island', googleId: 'nf' },
  { iso: 'NG', name: 'Nigeria' },
  { iso: 'NI', name: 'Nicaragua' },
  { iso: 'NL', name: 'Netherlands' },
  { iso: 'NO', name: 'Norway' },
  { iso: 'NP', name: 'Nepal', googleId: 'np' },
  { iso: 'NR', name: 'Nauru', googleId: 'nr' },
  { iso: 'NU', name: 'Niue', googleId: 'nu' },
  { iso: 'NZ', name: 'New Zealand' },
  { iso: 'OM', name: 'Oman', googleId: 'om' },
  { iso: 'PA', name: 'Panama' },
  { iso: 'PE', name: 'Peru' },
  { iso: 'PF', name: 'French Polynesia', googleId: 'pf' },
  { iso: 'PG', name: 'Papua New Guinea', googleId: 'pg' },
  { iso: 'PH', name: 'Philippines' },
  { iso: 'PK', name: 'Pakistan', googleId: 'pk' },
  { iso: 'PL', name: 'Poland' },
  { iso: 'PM', name: 'St. Pierre & Miquelon' },
  { iso: 'PN', name: 'Pitcairn Islands', googleId: 'pn' },
  { iso: 'PR', name: 'Puerto Rico' },
  { iso: 'PS', name: 'Palestinian Territories', googleId: 'ps' },
  { iso: 'PT', name: 'Portugal' },
  { iso: 'PW', name: 'Palau', googleId: 'pw' },
  { iso: 'PY', name: 'Paraguay' },
  { iso: 'QA', name: 'Qatar', googleId: 'qa' },
  { iso: 'RE', name: 'Réunion' },
  { iso: 'RO', name: 'Romania' },
  { iso: 'RS', name: 'Serbia' },
  { iso: 'RU', name: 'Russia' },
  { iso: 'RW', name: 'Rwanda' },
  { iso: 'SA', name: 'Saudi Arabia', googleId: 'sa' },
  { iso: 'SB', name: 'Solomon Islands', googleId: 'sb' },
  { iso: 'SC', name: 'Seychelles' },
  { iso: 'SD', name: 'Sudan' },
  { iso: 'SE', name: 'Sweden' },
  { iso: 'SG', name: 'Singapore' },
  { iso: 'SH', name: 'St. Helena' },
  { iso: 'SI', name: 'Slovenia' },
  { iso: 'SJ', name: 'Svalbard & Jan Mayen' },
  { iso: 'SK', name: 'Slovakia' },
  { iso: 'SL', name: 'Sierra Leone' },
  { iso: 'SM', name: 'San Marino' },
  { iso: 'SN', name: 'Senegal' },
  { iso: 'SO', name: 'Somalia' },
  { iso: 'SR', name: 'Suriname' },
  { iso: 'SS', name: 'South Sudan' },
  { iso: 'ST', name: 'São Tomé & Príncipe' },
  { iso: 'SV', name: 'El Salvador' },
  { iso: 'SX', name: 'Sint Maarten' },
  { iso: 'SY', name: 'Syria', googleId: 'sy' },
  { iso: 'SZ', name: 'Swaziland' },
  { iso: 'TC', name: 'Turks & Caicos Islands' },
  { iso: 'TD', name: 'Chad' },
  { iso: 'TF', name: 'French Southern Territories', googleId: 'tf' },
  { iso: 'TG', name: 'Togolese Republic' },
  { iso: 'TH', name: 'Thailand' },
  { iso: 'TJ', name: 'Tajikistan', googleId: 'tj' },
  { iso: 'TK', name: 'Tokelau', googleId: 'tk' },
  { iso: 'TL', name: 'Timor-Leste', googleId: 'tl' },
  { iso: 'TM', name: 'Turkmenistan', googleId: 'tm' },
  { iso: 'TN', name: 'Tunisia' },
  { iso: 'TO', name: 'Tonga' },
  { iso: 'TR', name: 'Turkey' },
  { iso: 'TT', name: 'Trinidad & Tobago' },
  { iso: 'TV', name: 'Tuvalu', googleId: 'tv' },
  { iso: 'TW', name: 'Taiwan' },
  { iso: 'TZ', name: 'Tanzania' },
  { iso: 'UA', name: 'Ukraine' },
  { iso: 'UG', name: 'Uganda' },
  { iso: 'UM', name: 'U.S. Outlying Islands', googleId: 'um' },
  { iso: 'US', name: 'USA', googleId: 'usa', states: [
    { iso: 'al', name: 'Alabama' },
    { iso: 'ak', name: 'Alaska' },
    { iso: 'az', name: 'Arizona' },
    { iso: 'ar', name: 'Arkansas' },
    { iso: 'ca', name: 'California' },
    { iso: 'co', name: 'Colorado' },
    { iso: 'ct', name: 'Connecticut' },
    { iso: 'de', name: 'Delaware' },
    { iso: 'dc', name: 'District Of Columbia' },
    { iso: 'fl', name: 'Florida' },
    { iso: 'ga', name: 'Georgia' },
    { iso: 'hi', name: 'Hawaii' },
    { iso: 'id', name: 'Idaho' },
    { iso: 'il', name: 'Illinois' },
    { iso: 'in', name: 'Indiana' },
    { iso: 'ia', name: 'Iowa' },
    { iso: 'ks', name: 'Kansas' },
    { iso: 'ky', name: 'Kentucky' },
    { iso: 'la', name: 'Louisiana' },
    { iso: 'me', name: 'Maine' },
    { iso: 'md', name: 'Maryland' },
    { iso: 'ma', name: 'Massachusetts' },
    { iso: 'mi', name: 'Michigan' },
    { iso: 'mn', name: 'Minnesota' },
    { iso: 'ms', name: 'Mississippi' },
    { iso: 'mo', name: 'Missouri' },
    { iso: 'mt', name: 'Montana' },
    { iso: 'ne', name: 'Nebraska' },
    { iso: 'nv', name: 'Nevada' },
    { iso: 'nh', name: 'New Hampshire' },
    { iso: 'nj', name: 'New Jersey' },
    { iso: 'nm', name: 'New Mexico' },
    { iso: 'ny', name: 'New York' },
    { iso: 'nc', name: 'North Carolina' },
    { iso: 'nd', name: 'North Dakota' },
    { iso: 'oh', name: 'Ohio' },
    { iso: 'ok', name: 'Oklahoma' },
    { iso: 'or', name: 'Oregon' },
    { iso: 'pa', name: 'Pennsylvania' },
    { iso: 'ri', name: 'Rhode Island' },
    { iso: 'sc', name: 'South Carolina' },
    { iso: 'sd', name: 'South Dakota' },
    { iso: 'tn', name: 'Tennessee' },
    { iso: 'tx', name: 'Texas' },
    { iso: 'ut', name: 'Utah' },
    { iso: 'vt', name: 'Vermont' },
    { iso: 'va', name: 'Virginia' },
    { iso: 'wa', name: 'Washington' },
    { iso: 'wv', name: 'West Virginia' },
    { iso: 'wi', name: 'Wisconsin' },
    { iso: 'wy', name: 'Wyoming' },
  ]},
  { iso: 'UY', name: 'Uruguay' },
  { iso: 'UZ', name: 'Uzbekistan', googleId: 'uz' },
  { iso: 'VA', name: 'Vatican City' },
  { iso: 'VC', name: 'St. Vincent & Grenadines' },
  { iso: 'VE', name: 'Venezuela' },
  { iso: 'VG', name: 'British Virgin Islands' },
  { iso: 'VI', name: 'U.S. Virgin Islands' },
  { iso: 'VN', name: 'Vietnam' },
  { iso: 'VU', name: 'Vanuatu' },
  { iso: 'YE', name: 'Yemen', googleId: 'ye' },
  { iso: 'YT', name: 'Mayotte' },
  { iso: 'ZA', name: 'South Africa' },
  { iso: 'ZM', name: 'Zambia' },
  { iso: 'ZW', name: 'Zimbabwe '},

]
export default countries
