import React, { useState, useEffect } from 'react'
import { Form, Input, Select, DatePicker, Button, TimePicker, Skeleton, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import IntlMessages from '../utils/IntlMessages'
import dayjs, { Dayjs } from 'dayjs'
import range from 'lodash/range'
import isSameDay from 'date-fns/isSameDay'
import { useIntl } from 'react-intl'

import { TimezoneInfo } from '@vacationtracker/shared/components/timezone/timezone-info'
import { getCalendarStatusSelectOptions } from '../../functions/get-calendar-status-options'
import { getShortestLeaveInterval } from '../../functions/get-shortest-leave-interval'

import { IGetUserForLeaveReueqstUserData, IGetUserForLeaveReueqstUserDataLocationLeavePolicies, IUserIdAndName } from '../../../frontend/src/types/custom-queries'
import { ShortestLeaveIntervalEnum } from '../../types/leave-policy'
import { ILeaveForm, ILeaveFormSaveData } from './types'
import { LeaveTypeCalendarStatusesEnum } from '../../types/calendar'
import { HourFormatEnum } from '../../types/user'
import { WorkingHours } from '../../types/work-week'
import { DEFAULT_WORKING_HOURS_IN_DAY } from '../../data/app-parameters'
import { getUserWorkingHoursOnDay, userHasDefaultWorkingHours } from '../../functions/work-week'
import logger from '../../functions/logger'
import { BaseInfo } from 'rc-picker/lib/interface'
import { NoUndefinedRangeValueType } from 'rc-picker/lib/PickerInput/RangePicker'

const { TextArea } = Input
const { Option } = Select
const { RangePicker } = DatePicker


const LeaveForm = ({
  authUserId,
  authUserRole,
  onSave,
  onCancel,
  listOfUsers,
  formType,
  modalForm = false,
  loading,
  leaveRequest,
  onLeaveTypeSelected,
  onEndDateSelected,
  onStartDateSelected,
  onSelectUserToAddLeaveRequest,
  onHandleTimeSelected,
  holidays,
  connectedGoogleCalendar,
  connectedOutlookCalendar,
  hourFormat,
  selectedUser,
  shouldShowSubstituteApprovers,
  substituteApprovers,
  isSubstituteListLoading,
  setShowOverlappingInfoAndQuotas,
}: ILeaveForm): React.ReactElement => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()

  const [leaveTypes, setLeaveTypes] = useState<IGetUserForLeaveReueqstUserDataLocationLeavePolicies[]>([])
  const [selectedLeaveType, setSelectedLeaveType] = useState<IGetUserForLeaveReueqstUserDataLocationLeavePolicies | undefined>()
  const [reasonRequired, setReasonRequired] = useState<boolean>(false)
  const [allowLeaveInPast, setAllowLeaveInPast] = useState<boolean>(false)
  const [formLoading, setFormLoading] = useState(false)
  const [userTimezone, setUserTimezone] = useState<string | undefined>()
  const [shortestLeaveInterval, setShortestLeaveInterval] = useState<ShortestLeaveIntervalEnum>(ShortestLeaveIntervalEnum.fullDay)
  const [substituteApprover, setSubstituteApprover] = useState<IUserIdAndName | undefined>()
  let defaultValue

  if (leaveRequest && !leaveRequest.isPartDay) {
    defaultValue = {
      reason: leaveRequest.reason,
      rangeDate: [dayjs(leaveRequest.startDate), dayjs(leaveRequest.endDate)],
      leaveTypeId: leaveRequest.leaveType.id,
    }
  } else if (leaveRequest && leaveRequest.isPartDay) {
    defaultValue =  {
      reason: leaveRequest.reason,
      startDate: dayjs(leaveRequest.startDate),
      range: [dayjs().hour(leaveRequest.partDayStartHour), dayjs().hour(leaveRequest.partDayEndHour)],
      leaveTypeId: `${leaveRequest.leaveType.id}|part-day`,
    }
  } else {
    defaultValue =  {
      reason: '',
    }
  }

  if (leaveRequest?.substituteApproverId) {
    defaultValue.substituteApprover = leaveRequest.substituteApproverId
  }

  useEffect(() => {
    if (connectedGoogleCalendar  || connectedOutlookCalendar) {
      // TODO: when we decide to allow users to edit calendar status we should uncomment this
      // if (formType === 'edit' && leaveRequest) {
      //   form.setFieldsValue({calendarStatus:leaveRequest?.calendarStatus})
      // } else {
      //   form.setFieldsValue({calendarStatus: LeaveTypeCalendarStatusesEnum.busy})
      // }
      form.setFieldsValue({calendarStatus: LeaveTypeCalendarStatusesEnum.busy})
    }
  }, [connectedGoogleCalendar, connectedOutlookCalendar])

  useEffect(() => {
    if (loading) {
      setFormLoading(true)
    } else {
      setFormLoading(false)
    }
  }, [loading])

  useEffect(() => {
    if (selectedUser) {
      setUserTimezone(selectedUser.location.timezone)
      setLeaveTypes(leaveTypeLoop(selectedUser.location.leavePolicies, selectedUser.workHours))
    }
  }, [selectedUser])

  useEffect(() => {if (formType !== 'edit') {
    form.setFieldsValue({rangeDate: []})
  }}, [selectedLeaveType])

  useEffect(() => {if (formType !== 'edit') {
    form.setFieldsValue({rangeDate: [], userId: form.getFieldValue('userId'), leaveTypeId: ''})
  }}, [form.getFieldValue('userId')])

  const leaveTypeLoop = (data: IGetUserForLeaveReueqstUserDataLocationLeavePolicies[], workHours?: WorkingHours) => {
    const userLeaveTypesData = [] as IGetUserForLeaveReueqstUserDataLocationLeavePolicies[]
    data.forEach((lp: IGetUserForLeaveReueqstUserDataLocationLeavePolicies) => {
      if (lp.isActive && lp.leaveType.isActive && !lp.leaveType.deleted) {
        userLeaveTypesData.push({
          ...lp,
          id: lp.leaveType.id,
        })
        // This `type.shortestLeaveInterval && ...` need to be here because sometime can be null and without this, this is always true
        if ((!lp.shortestLeaveInterval && lp.allowHalfDays) || (lp.shortestLeaveInterval && lp.shortestLeaveInterval !== ShortestLeaveIntervalEnum.fullDay)) {
          const shortestLeaveInterval = getShortestLeaveInterval(lp.shortestLeaveInterval, lp.allowHalfDays)
          const shortestLeaveIntervalSuffix = shortestLeaveInterval === ShortestLeaveIntervalEnum.oneHour ? 'leaveRequest.hourly' : 'leaveRequest.halfDay'
          if (shortestLeaveInterval === ShortestLeaveIntervalEnum.halfDay && !userHasDefaultWorkingHours(workHours)) {
            return
          }
          userLeaveTypesData.push({
            ...lp,
            id: `${lp.leaveType.id}|part-day`,
            leaveType: {
              ...lp.leaveType,
              name: `${lp.leaveType.name} (${formatMessage({ id: shortestLeaveIntervalSuffix })})`,
            },
          })
        }
      }
    })

    return userLeaveTypesData.sort((a, b) => a.leaveType.position < b.leaveType.position ? -1 : 1)
  }

  const setCalendarStatus = (v) => {
    form.setFieldsValue({ calendarStatus:  v})
  }

  const onFilterUsers = (input, option) => {
    if (!option?.children) return false
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const onFilterSubstituteApprovers = (input, option) => {
    if (!option?.children) return false
    return option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
  }

  const onChangeUser = (id: string) => {
    const selectedUser = listOfUsers.find(u => u.id === id) as IGetUserForLeaveReueqstUserData | IUserIdAndName

    if (selectedUser) {
      if ('location' in selectedUser) {
        setUserTimezone(selectedUser.location.timezone)
        setLeaveTypes(leaveTypeLoop(selectedUser.location.leavePolicies, selectedUser.workHours))
      }
      onSelectUserToAddLeaveRequest && onSelectUserToAddLeaveRequest(selectedUser.id)
    }
  }

  const onChangeSubstituteApprover = (id: string) => {
    const selected = substituteApprovers?.find(u => u.id === id)

    if (selected) {
      setSubstituteApprover(selected)
    }
  }

  const onChangeType = (leaveTypeId: string) => {
    const selectedLeaveType = leaveTypes.find(leaveType => leaveType?.id === leaveTypeId)
    formType == 'request' ? form.setFieldsValue({ startDate: '', range: [], rangeDate: [] }) : null
    setSelectedLeaveType(selectedLeaveType)
    onLeaveTypeSelected && onLeaveTypeSelected(selectedLeaveType)
    if(leaveTypeId.includes('|part-day') && selectedLeaveType) {
      setShortestLeaveInterval(getShortestLeaveInterval(selectedLeaveType?.shortestLeaveInterval, selectedLeaveType?.allowHalfDays))
    } else {
      setShortestLeaveInterval(ShortestLeaveIntervalEnum.fullDay)
    }

    if ((connectedGoogleCalendar  || connectedOutlookCalendar) && formType === 'request') {
      form.setFieldsValue({calendarStatus: LeaveTypeCalendarStatusesEnum.busy})
    }

    setCalendarStatus(selectedLeaveType?.leaveType?.calendarStatus || LeaveTypeCalendarStatusesEnum.busy)
    setReasonRequired(Boolean(selectedLeaveType?.isReasonRequired))
    setAllowLeaveInPast(Boolean(selectedLeaveType?.allowLeaveInPast))
  }

  useEffect(() => {
    if (formType === 'request' || formType === 'add-to-user' || formType === 'edit') {
      const selectedUser = listOfUsers[0]
      if ('location' in selectedUser) {
        setUserTimezone(selectedUser.location.timezone)
        setLeaveTypes(leaveTypeLoop(selectedUser?.location.leavePolicies, selectedUser?.workHours))
      }
    }
    if (formType === 'edit' && leaveRequest) {
      const leavePolicies = leaveRequest.leaveType.leavePolicies.find(lp => lp.locationId === leaveRequest.user.locationId)
      setShortestLeaveInterval(!leaveRequest?.partDayStartHour
        ? ShortestLeaveIntervalEnum.fullDay
        : getShortestLeaveInterval(leavePolicies?.shortestLeaveInterval, leaveRequest.isPartDay))
      setReasonRequired(leavePolicies?.isReasonRequired || false)
    }

  }, [formType, listOfUsers])

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        setFormLoading(true)

        const isPartDay = values.range ? true : false
        let partDayStartHour: number | undefined
        let partDayEndHour: number | undefined
        if (isPartDay) {
          partDayStartHour = values.range[0] ? values.range[0].hour() : 0
          partDayEndHour = values.range[1] ? values.range[1].hour() : 0
        }

        const saveObj: ILeaveFormSaveData = {
          userId: values.userId,
          isPartDay,
          leaveTypeId: values.leaveTypeId.split('|part-day')[0],
          partDayStartHour,
          partDayEndHour,
          startDate: values.range ? values.startDate.format('YYYY-MM-DD') : values.rangeDate[0].format('YYYY-MM-DD'),
          endDate: values.range ? values.startDate.format('YYYY-MM-DD') : values.rangeDate[1].format('YYYY-MM-DD'),
          reason: values.reason,
          substituteApproverId: values.substituteApprover,
        }

        if ((connectedGoogleCalendar  || connectedOutlookCalendar) && formType === 'request') {
          saveObj.calendarStatus = values.calendarStatus
        }

        if (formType === 'add' || formType === 'add-to-user') {
          saveObj.calendarStatus = selectedLeaveType?.leaveType?.calendarStatus || LeaveTypeCalendarStatusesEnum.busy
        }

        if ((connectedGoogleCalendar  || connectedOutlookCalendar) && formType === 'add-to-user') {
          saveObj.calendarStatus = selectedLeaveType?.leaveType?.calendarStatus || LeaveTypeCalendarStatusesEnum.busy
        }

        if (formType === 'edit') {
          saveObj.calendarStatus = values.calendarStatus
          || leaveRequest?.calendarStatus
          || selectedLeaveType?.leaveType?.calendarStatus
          || LeaveTypeCalendarStatusesEnum.busy
        }

        onSave(saveObj)

        if (formType !== 'edit') {
          form.setFieldsValue({
            range: '',
            startDate: '',
            reason: '',
            rangeDate: '',
          })
        }
      })
      .catch(info => {
        logger.debug('Validate Failed:', info)
      })
  }

  const disableDatePicker = (current: Dayjs): boolean => {
    if (formType === 'add' || formType === 'add-to-user' || (formType === 'edit' && ['Admin', 'Approver'].includes(authUserRole)) || allowLeaveInPast) {
      return !current || current > dayjs().add(1, 'year').endOf('year')
    } else {
      return !current || current < dayjs().subtract(1, 'day') || current > dayjs().add(1, 'year').endOf('year')
    }
  }

  const disabledDateRangePicker = (current: Dayjs): boolean => {
    let tooLate: boolean
    let tooEarly: boolean
    if (formType === 'add' || formType === 'add-to-user' || (formType === 'edit' && ['Admin', 'Approver'].includes(authUserRole)) || allowLeaveInPast) {
      tooLate = current && current > dayjs().add(1, 'year').endOf('year')
      tooEarly = false
    } else {
      tooLate = current && current > dayjs().add(1, 'year').endOf('year')
      tooEarly = current && current < dayjs().subtract(1, 'day')
    }

    return tooEarly || tooLate
  }

  const handleResetForm = () => {
    form.resetFields()
    setShowOverlappingInfoAndQuotas && setShowOverlappingInfoAndQuotas(false)
  }

  const handleDisableTime = (
    _: dayjs.Dayjs,
    pickedRange: 'start' | 'end',
    info: { from?: dayjs.Dayjs | undefined }
  ) => {
    // No need to validate only start time or if start date is not selected
    if (pickedRange === 'start' || !form.getFieldValue('startDate')) {
      return {}
    }

    // Get start hour
    const startHour = info.from?.hour() || 0
    // Disable all hours before start hour + 1 hour (we do not allow less than 1 hour leaves)
    const disabledBefore = range(0, startHour + 1)
    // Get user working hours and available hours for a selected date
    const user = selectedUser ?? listOfUsers[0] as IGetUserForLeaveReueqstUserData
    let workingHours = user.workHours ? getUserWorkingHoursOnDay(user.workHours, form.getFieldValue('startDate')?.toDate() as Date) : DEFAULT_WORKING_HOURS_IN_DAY
    if (isHalfDayHoliday()) {
      workingHours /= 2
    }

    // For half day, they can select only one value (now + half day hours)
    if (shortestLeaveInterval === ShortestLeaveIntervalEnum.halfDay) {
      const allowedValue = startHour + Math.floor(workingHours / 2)
      return {
        disabledHours: () => [
          ...range(0, allowedValue),
          ...range(allowedValue + 1, 24),
        ],
      }
    }

    // Disable all hours after start hour + working hours + 1 hour until the end of the current day
    const disabledAfter = range(startHour + Math.floor(workingHours + 1), 24)
    return {
      disabledHours: () => [...disabledBefore, ...disabledAfter],
    }
  }

  const isHalfDayHoliday = () => {
    const day: Date = form.getFieldValue('startDate') && form.getFieldValue('startDate').toDate()

    const isHalfDay = day && holidays?.filter(
      (h) =>
        h.year === day.getFullYear() &&
        h.holidays.find((h) => isSameDay(day, new Date(h.date)) && h.isHalfDay)
    )

    return isHalfDay && isHalfDay?.length > 0
  }

  // eslint-disable-next-line @typescript-eslint/require-await
  const timePickerValidation = async (_, values: Dayjs[]) => {
    try {
      if (values.length < 2) {
        throw new Error(formatMessage({ id: 'components.leaveForm.pleaseSelectRangeTime' }))
      }

      const user = selectedUser ?? listOfUsers[0] as IGetUserForLeaveReueqstUserData
      const workingHours = user.workHours ? getUserWorkingHoursOnDay(user.workHours, form.getFieldValue('startDate')?.toDate() as Date) : DEFAULT_WORKING_HOURS_IN_DAY
      const availableWorkingHours = isHalfDayHoliday() ? workingHours / 2 : workingHours

      const [startTime, endTime] = values
      const hourDiff = endTime.diff(startTime, 'hour')

      if (
        hourDiff > availableWorkingHours ||
        hourDiff === 0 ||
        (shortestLeaveInterval === ShortestLeaveIntervalEnum.halfDay && hourDiff !== workingHours / 2)
      ) {
        throw new Error(formatMessage({ id: 'components.leaveForm.pleaseSelectValidRangeTime' }))
      }
      onHandleTimeSelected && onHandleTimeSelected({startTime: startTime.get('hours'), endTime: endTime.get('hours')})
      return {}
    } catch (error) {
      if (error.message.includes('Cannot read properties of undefined')) {
        // swallow JS error
        return {}
      }
      throw new Error(formatMessage({ id: 'components.leaveForm.pleaseSelectValidRangeTime' }))
    }

  }

  const formLayout = {
    labelCol: { span: modalForm ? 8 : 6 },
    wrapperCol: { span: modalForm ? 16 : 12 },
  }

  const offsetItemLayout = {
    wrapperCol: {
      span: modalForm ? 12 : 12,
      xs: {offset: modalForm ? 10 : 0} ,md: {offset: modalForm ? 10 : 6},
    },
  }

  const disableReasonField = (numberOfLeaveTypes: number, type: string, userList: IGetUserForLeaveReueqstUserData[] | IUserIdAndName[]): boolean => {
    return numberOfLeaveTypes === 0 || type === 'edit' && userList.length === 1 && userList[0].id !== authUserId
  }

  const handleTimeSelect = (dates: NoUndefinedRangeValueType<Dayjs>, dateStrings: [string, string], info: BaseInfo) => {
    const start = dates[0]
    if (start && info.range === 'start' && shortestLeaveInterval === ShortestLeaveIntervalEnum.halfDay) {
      // Get user working hours and available hours for a selected date
      const user = selectedUser ?? listOfUsers[0] as IGetUserForLeaveReueqstUserData
      const workingHours = user.workHours ? getUserWorkingHoursOnDay(user.workHours, form.getFieldValue('startDate')?.toDate() as Date) : DEFAULT_WORKING_HOURS_IN_DAY

      form.setFieldsValue({
        range: [start, start.add(Math.floor(workingHours / 2), 'hours')],
      })
    }
  }

  const handleOnEndDateSelected = (dateRange) => {
    if (onEndDateSelected && dateRange) {
      const startDate = dateRange[0]?.format('YYYY-MM-DD')
      const endDate = dateRange[1]?.format('YYYY-MM-DD')
      onEndDateSelected({
        startDate,
        endDate,
      })
    }
  }

  const handleOnStartDateSelected = (startDate: Dayjs | null) => {
    if (startDate) {
      onStartDateSelected && onStartDateSelected(startDate.format('YYYY-MM-DD'))
    }
  }

  return (
    <Form
      form={form}
      {...formLayout}
      scrollToFirstError
      onFinish={onFinish}
      initialValues={defaultValue}
      labelWrap
    >
      {['Admin', 'Approver'].includes(authUserRole) && (formType === 'add' || formType === 'add-to-user') && (
        <p><IntlMessages id="components.leaveForm.addLeaveDescription" /></p>
      )}
      {['Admin', 'Approver'].includes(authUserRole) && formType === 'request' && (
        <p><IntlMessages id="components.leaveForm.requestLeaveDescription" /></p>
      )}
      {['Admin', 'Approver'].includes(authUserRole) && formType === 'edit' && leaveRequest && leaveRequest.userId !== authUserId && (
        <p><IntlMessages id="components.leaveForm.editLeaveDescription.adminAndApprover" /></p>
      )}
      {leaveRequest && leaveRequest.userId === authUserId && formType === 'edit' && (
        <p><IntlMessages id="components.leaveForm.editLeaveDescription.user" /></p>
      )}

      {formType === 'add' &&
        <Form.Item name="userId"
          label={<IntlMessages id="components.leaveForm.selectUser" />}
          rules={[{ required: true, message: <IntlMessages id="components.leaveForm.selectUser" /> }]}
        >
          <Select style={{ width: 320 }} onChange={onChangeUser} filterOption={onFilterUsers} showSearch>
            {listOfUsers.map(item => (
              <Option key={item.id} value={item.id}>{item.name}</Option>
            ))}
          </Select>
        </Form.Item>
      }
      <Form.Item name="leaveTypeId"
        label={<IntlMessages id="app.leaveType" />}
        rules={[{ required: true, message: <IntlMessages id="components.leaveForm.pleaseSelectLeaveType" /> }]}
      >
        <Select onChange={onChangeType} style={{ width: 320 }} disabled={leaveTypes.length === 0 || formType === 'edit'}>
          {leaveTypes.map(item => (<Option key={item.id} value={item.id}>{item.leaveType.name}</Option>))}
        </Select>
      </Form.Item>

      {(shortestLeaveInterval !== ShortestLeaveIntervalEnum.fullDay) &&
        <>
          <Form.Item name="startDate"
            label={<IntlMessages id="components.leaveForm.date" />}
            rules={[{ required: true, message: <IntlMessages id="components.leaveForm.pleaseSelectstartDate" /> }]}
          >
            <DatePicker disabledDate={disableDatePicker} style={{ width: 320 }} onChange={handleOnStartDateSelected} />
          </Form.Item>
          <Form.Item name="range"
            label={<IntlMessages id="app.time" />}
            rules={[{ required: true, validator: timePickerValidation }]}
            extra={<TimezoneInfo userTimezone={userTimezone} />}
          >
            <TimePicker.RangePicker
              format={hourFormat === HourFormatEnum.twelve ? 'hh' : 'HH'}
              needConfirm={false}
              allowClear={false}
              use12Hours={hourFormat === HourFormatEnum.twelve}
              disabledTime={handleDisableTime}
              onCalendarChange={handleTimeSelect}
              style={{ width: 320 }}
            />
          </Form.Item>
        </>
      }

      {shortestLeaveInterval === ShortestLeaveIntervalEnum.fullDay &&
        <Form.Item name="rangeDate"
          label={<IntlMessages id="app.dateRange" />}
          rules={[{ required: true, message: <IntlMessages id="components.leaveForm.pleaseSelectstartDate" /> }]}>
          <RangePicker
            disabledDate={disabledDateRangePicker}
            disabled={leaveTypes.length === 0}
            onChange={handleOnEndDateSelected}
            style={{ width: 320 }}
          />
        </Form.Item>
      }

      <Form.Item name="reason"
        label={<IntlMessages id="app.reason" />}
        rules={[{ required: reasonRequired, message: <IntlMessages id="components.leaveForm.pleaseInputReason" /> }]}
      >
        <TextArea
          rows={4}
          style={{ width: 2 * 250 }}
          disabled={disableReasonField(leaveTypes.length, formType, listOfUsers)}
        />
      </Form.Item>

      {isSubstituteListLoading ? <Skeleton.Input size="large" style={{ width: '90vw' }} active /> : (shouldShowSubstituteApprovers &&
        <Form.Item name="substituteApprover"
          label={
            <>
              <IntlMessages id="components.leaveForm.selectSubstituteApprover" />
              <Tooltip className="info-tooltip" title={<IntlMessages id="components.leaveForm.selectSubstituteApproverInfoMessage" />}><InfoCircleOutlined /></Tooltip>
            </>
          }
        >
          <Select
            style={{ width: 320 }}
            onChange={onChangeSubstituteApprover}
            filterOption={onFilterSubstituteApprovers} showSearch
            defaultValue={formatMessage({ id: 'app.none' })}
          >
            {substituteApprovers && substituteApprovers.map(item => (
              <Option key={item.id} value={item.id}>{item.name} <strong>({item.role})</strong></Option>
            ))}
          </Select>
        </Form.Item>)}

      {/*
        TODO: when we decide to allow users to edit calendar status we should enable this
        {connectedCalendar && (formType === 'request' || formType === 'edit') && <Form.Item
      */}
      {(connectedGoogleCalendar  || connectedOutlookCalendar) && formType === 'request' &&
        <Form.Item
          name='calendarStatus'
          label={<IntlMessages id="app.leaveRequestCalendarStatusLabel" />}
          extra={
            <>
              {connectedGoogleCalendar && <div>
                <IntlMessages
                  id="components.leaveForm.connectedCalendarTimezone"
                  values={{
                    calendarType: 'Google',
                    timezone: connectedGoogleCalendar?.timezone,
                  }} />
              </div>}
              {connectedOutlookCalendar && <div>
                <IntlMessages
                  id="components.leaveForm.connectedCalendarTimezone"
                  values={{
                    calendarType: 'Outlook',
                    timezone: connectedOutlookCalendar?.timezone,
                  }} />
              </div>}
            </>
          }
        >
          <Select options={getCalendarStatusSelectOptions(formatMessage)} style={{ width: 100 }} />
        </Form.Item>
      }

      <Form.Item {...offsetItemLayout} >
        {onCancel &&
          <Button type="default" danger style={{ marginRight: 10 }} onClick={onCancel}>
            <IntlMessages id="app.cancel" />
          </Button>
        }
        <Button className='vt-btn-reset'
          type="default"
          style={{ marginRight: 40, marginBottom: 10 }}
          onClick={handleResetForm}>
          <IntlMessages id="app.reset" />
        </Button>
        <Button
          htmlType="submit"
          type="primary"
          loading={formLoading}
          data-qa='submit-leave-request'
        >
          {formType === 'request' && <IntlMessages id="app.requestLeave" />}
          {formType === 'edit' && <IntlMessages id="app.editLeave" />}
          {(formType === 'add' || formType === 'add-to-user') && <IntlMessages id="app.addLeave" />}
        </Button>
      </Form.Item>
    </Form >
  )
}

export default LeaveForm
