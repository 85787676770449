import React, { useEffect, useState } from 'react'
import { Modal, Collapse, Drawer, Progress, Typography, Button } from 'antd'
import { CheckCircleOutlined, CloseCircleOutlined, CheckCircleFilled, CaretRightOutlined } from '@ant-design/icons'
import { useHistory } from 'react-router-dom'
import pickBy from 'lodash/pickBy'
import isBefore from 'date-fns/isBefore'
import addDays from 'date-fns/addDays'
import identity from 'lodash/identity'
import { useManualQuery, useMutation } from 'graphql-hooks'
import { saveUserActionFlow } from '../../graphql/custom-mutation'
import { getUserActionFlow } from '../../graphql/custom-queries'

import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setOnboardingActions, selectOnboardingSlice, setOnboardingActiveKey, skipOnboardingActions } from '../../store/onboarding-slice'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'

import IntlMessages from '../../util/IntlMessages'
import YoutubeEmbed from '../youtube-embed'

import { Platform } from '@vacationtracker/shared/types/core-event'
import { IUserActionFlowOnboardingActions } from '@vacationtracker/shared/types/user-action-flow'
import { IGetUserActionFlowData, ISaveUserActionFlowData, ISaveUserHelpInfoParams } from '../../types/custom-queries'

interface IOnboardingAdmin {
  isOnboardingAdminVisible: boolean
  onboardingAdminClose: () => void
  platform: Platform
  isMicrosoftBillingPayment: boolean
}

const { Paragraph } = Typography
const { Panel } = Collapse

export const OnboardingAdmin: React.FC<IOnboardingAdmin> = ({
  isOnboardingAdminVisible,
  onboardingAdminClose,
  platform,
  isMicrosoftBillingPayment,
}: IOnboardingAdmin) => {
  const [fetchOnboarding, setFetchOnboarding] = useState<string>('')
  const history = useHistory()
  const dispatch = useAppDispatch()
  const {
    onboardingCompleted,
    progressCompleted,
    totalChecklist,
    onboardingActiveKey,
    actions,
    fetchOnboardingString,
    hideOnboardingFlow,
  } = useAppSelector(selectOnboardingSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const [getUserActionFlowQuery] = useManualQuery<IGetUserActionFlowData, { type: 'dashboard' }>(getUserActionFlow)
  const [saveUserActionFlowMutation] = useMutation<ISaveUserActionFlowData, ISaveUserHelpInfoParams>(saveUserActionFlow)

  const showSkipButton = authCompany && authCompany.timestamp ? isBefore(addDays(new Date(authCompany.timestamp), 7), new Date()) : false

  const getUserAction = async () => {
    const response = await getUserActionFlowQuery({ variables: {type: 'dashboard' }})
    if (!response.data || response.error) throw response.error
    const actions = pickBy(response.data.getUserActionFlow?.actions || {}, identity)
    response.data.getUserActionFlow.actions = actions
    dispatch(setOnboardingActions({
      ...response.data.getUserActionFlow,
      actions,
    }))
  }

  useEffect(() => {
    if (!hideOnboardingFlow && fetchOnboardingString && fetchOnboardingString !== '' && fetchOnboardingString !== fetchOnboarding) {
      setFetchOnboarding(fetchOnboardingString)
      // This setTimeout is just to be sure that changes are in view table
      setTimeout(() => {
        getUserAction()
      }, 2000)
    }
  }, [fetchOnboardingString])

  const saveUserAction = async (data: IUserActionFlowOnboardingActions) => {
    const newActions = pickBy(data || {}, (key, value) => { if(value) return key })
    const response = await saveUserActionFlowMutation({ variables: {
      type: 'dashboard',
      skipped: false,
      completed: onboardingCompleted,
      actions: newActions,
    }})
    if (!response.data || response.error) throw response.error
    const actions = pickBy(response.data.saveUserActionFlow.actions || {}, identity)
    response.data.saveUserActionFlow.actions = actions
    dispatch(setOnboardingActions(response.data.saveUserActionFlow))
  }

  const onChangeCollpase = (type) => {
    dispatch(setOnboardingActiveKey(type as string))
  }

  const handleYoutubeLink = async (type) => {
    let embedId
    let newActions
    await getUserAction()

    switch (type) {
      case 'install-bot':
        embedId = 'vUbpc7-aEz0'
        newActions = {
          ...actions,
          ONBOARDING_CHECKLIST_BOT_VIDEO_WATCHED: true,
        }
        break
      case 'request-a-leave':
        embedId = 'JRxJ7YPB5HA'
        newActions = {
          ...actions,
          ONBOARDING_CHECKLIST_LEAVE_REQUEST_VIDEO_WATCHED: true,
        }
        break
      case 'sync-the-calendar-outlook':
        embedId = 'pQP8A_JvfY8'
        newActions = {
          ...actions,
          ONBOARDING_CHECKLIST_ICAL_VIDEO_WATCHED: true,
        }
        break
      case 'sync-the-calendar-google':
        embedId = 'C_97Yrh-q9I'
        newActions = {
          ...actions,
          ONBOARDING_CHECKLIST_ICAL_VIDEO_WATCHED: true,
        }
        break
      case 'sync-the-calendar-ical':
        embedId = 'b2chLjJjaoA'
        newActions = {
          ...actions,
          ONBOARDING_CHECKLIST_ICAL_VIDEO_WATCHED: true,
        }
        break
      case 'set-notifications':
        embedId = 'KSSMOP1xzsA'
        newActions = {
          ...actions,
          ONBOARDING_CHECKLIST_NOTIFICATIONS_VIDEO_WATCHED: true,
        }
        break
      case 'invite-friends':
        if (isMicrosoftBillingPayment) {
          embedId = 'HQEBUfVfVBs'
        } else {
          embedId = 'fZFEff17kAk'
        }
        newActions = {
          ...actions,
          ONBOARDING_CHECKLIST_IMPORT_USERS_VIDEO_WATCHED: true,
        }
        break
      default:
        return
    }

    Modal.info({
      closeIcon: <CloseCircleOutlined />,
      closable: true,
      className: 'youtube-modal',
      width: 600,
      content: (
        <div>
          <YoutubeEmbed embedId={embedId} width="548" height="308" autoplay={true} />
        </div>
      ),
      okButtonProps: { style: {'display': 'none'} },
      icon: null,
    })

    await saveUserAction(newActions as IUserActionFlowOnboardingActions)
  }

  const handleInstallBot = async () => {
    await getUserAction()
    await saveUserAction({
      ...actions,
      ONBOARDING_CHECKLIST_BOT_BUTTON_CLICKED: true,
      ONBOARDING_CHECKLIST_BOT_COMPLETED: true,
    })
    window.open('https://teams.microsoft.com/l/app/eab5463e-8168-40ee-887a-7ac78de1d266', '_blank')
  }

  const handleRequestALeave = async () => {
    if (!actions.ONBOARDING_CHECKLIST_LEAVE_REQUEST_BUTTON_CLICKED) {
      await getUserAction()
      await saveUserAction({
        ...actions,
        ONBOARDING_CHECKLIST_LEAVE_REQUEST_BUTTON_CLICKED: true,
      })
    }
    history.push('/app/leaves/request-leave')
  }

  const handleSyncTheCalendar = async () => {
    if (!actions.ONBOARDING_CHECKLIST_ICAL_COMPLETED && !actions.ONBOARDING_CHECKLIST_ICAL_COMPLETED) {
      await getUserAction()
      await saveUserAction({
        ...actions,
        ONBOARDING_CHECKLIST_ICAL_BUTTON_CLICKED: true,
        ONBOARDING_CHECKLIST_ICAL_COMPLETED: true,
      })
    }
    history.push('/app/calendar?openSyncCalendar=true')
  }

  const handleSetNotifications = async () => {
    if (actions.ONBOARDING_CHECKLIST_NOTIFICATIONS_COMPLETED) {
      history.push('/app/notifications')
      return
    }
    await getUserAction()
    await saveUserAction({
      ...actions,
      ONBOARDING_CHECKLIST_NOTIFICATIONS_BUTTON_CLICKED: true,
    })
    history.push('/app/notifications/create')
  }

  const handleInviteFriends = async () => {
    await getUserAction()
    await saveUserAction({
      ...actions,
      ONBOARDING_CHECKLIST_IMPORT_USERS_BUTTON_CLICKED: true,
    })

    if (isMicrosoftBillingPayment) {
      history.push('/app/users/manage-licences')
    } else {
      history.push('/app/users?openImportUsers=true')
    }
  }

  const handleSkipOnboarding = async () => {
    await getUserAction()

    const newActions = pickBy(actions || {}, (key, value) => { if(value) return key })
    const response = await saveUserActionFlowMutation({ variables: {
      type: 'dashboard',
      skipped: true,
      completed: onboardingCompleted,
      actions: newActions,
    }})
    if (!response.data || response.error) throw response.error

    const responseActions = pickBy(response.data.saveUserActionFlow.actions || {}, identity)
    response.data.saveUserActionFlow.actions = responseActions

    dispatch(setOnboardingActions(response.data.saveUserActionFlow))
    dispatch(skipOnboardingActions())
  }

  return (
    <Drawer
      title={<IntlMessages id="components.onboarding.title" values={{
        progressStatus: `${progressCompleted}/${totalChecklist}`,
        progressBar: (...chunks) => (
          <span>{chunks}</span>
        ),
      }}/>}
      placement="right"
      destroyOnClose={false}
      mask={false}
      maskClosable={false}
      onClose={onboardingAdminClose}
      open={isOnboardingAdminVisible}
      width={330}
      className="onboarding-admin"
    >
      <div>
        <Progress percent={100/totalChecklist*progressCompleted} showInfo={false} />
        <Collapse
          defaultActiveKey={onboardingActiveKey}
          onChange={onChangeCollpase}
          accordion={true}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          expandIconPosition="end"
        >
          {platform === 'microsoft' &&
            <Panel
              key="install-bot"
              header={<IntlMessages id="components.onboarding.installMSBotTitle" values={{
                icon: () => actions.ONBOARDING_CHECKLIST_BOT_COMPLETED ? <CheckCircleFilled style={{'color': 'green'}} /> : <CheckCircleOutlined />,
              }}/>}
            >
              <Paragraph><IntlMessages id="components.onboarding.installMSBotDescription" /></Paragraph>
              <div className="youtube-link" onClick={() => handleYoutubeLink('install-bot')}>
                <img src={require('../../assets/images/onboarding/install-ms-bot.png')} width="296" />
                <div className="youtube-link-black">
                  <img src={require('../../assets/images/youtube-black.png')} height="36" />
                </div>
                <div className="youtube-link-red">
                  <img src={require('../../assets/images/youtube-red.png')} height="36" />
                </div>
              </div>
              <Button onClick={handleInstallBot} type="primary">
                <IntlMessages id="components.onboarding.installMSBotButton" />
              </Button>
            </Panel>
          }

          <Panel
            key="request-a-leave"
            header={<IntlMessages id="components.onboarding.requestALeaveTitle" values={{
              icon: () => actions.ONBOARDING_CHECKLIST_LEAVE_REQUEST_COMPLETED ? <CheckCircleFilled style={{'color': 'green'}} /> : <CheckCircleOutlined />,
            }}/>}
          >
            <Paragraph><IntlMessages id="components.onboarding.requestALeaveDescription" /></Paragraph>
            <div className="youtube-link" onClick={() => handleYoutubeLink('request-a-leave')}>
              <img src={require('../../assets/images/onboarding/request-a-leave.png')} width="296" />
              <div className="youtube-link-black">
                <img src={require('../../assets/images/youtube-black.png')} height="36" />
              </div>
              <div className="youtube-link-red">
                <img src={require('../../assets/images/youtube-red.png')} height="36" />
              </div>
            </div>
            <Button onClick={handleRequestALeave} type="primary">
              <IntlMessages id="app.requestLeave" />
            </Button>
          </Panel>

          <Panel
            key="sync-the-calendar"
            header={<IntlMessages id="components.onboarding.syncTheCalendarTitle" values={{
              icon: () => actions.ONBOARDING_CHECKLIST_ICAL_COMPLETED ? <CheckCircleFilled style={{'color': 'green'}} /> : <CheckCircleOutlined />,
            }}/>}
          >
            <Paragraph><IntlMessages id="components.onboarding.syncTheCalendarDescription" /></Paragraph>
            <Paragraph>
              <Button block onClick={() => handleYoutubeLink('sync-the-calendar-outlook')} className='wrap-button-text'>
                <IntlMessages id="components.onboarding.syncTheCalendarVideoForSyncingWithMicrosoftOutlookCalendar" />
              </Button>
            </Paragraph>
            <Paragraph>
              <Button block onClick={() => handleYoutubeLink('sync-the-calendar-google')} className='wrap-button-text'>
                <IntlMessages id="components.onboarding.syncTheCalendarVideoForSyncingWithGoogleCalendar" />
              </Button>
            </Paragraph>
            <Paragraph>
              <Button block onClick={() => handleYoutubeLink('sync-the-calendar-ical')} className='wrap-button-text'>
                <IntlMessages id="components.onboarding.syncTheCalendarVideoForSyncingWithIcal" />
              </Button>
            </Paragraph>
            <Button onClick={handleSyncTheCalendar} type="primary">
              <IntlMessages id="calendar.getCalendarLink" />
            </Button>
          </Panel>

          <Panel
            key="set-notifications"
            header={<IntlMessages id="components.onboarding.setNotificationsTitle" values={{
              icon: () => actions.ONBOARDING_CHECKLIST_NOTIFICATIONS_COMPLETED ? <CheckCircleFilled style={{'color': 'green'}} /> : <CheckCircleOutlined />,
            }}/>}
          >
            <Paragraph><IntlMessages id="components.onboarding.setNotificationsDescription" /></Paragraph>
            <div className="youtube-link" onClick={() => handleYoutubeLink('set-notifications')}>
              <img src={require('../../assets/images/onboarding/create-notification.png')} width="296" />
              <div className="youtube-link-black">
                <img src={require('../../assets/images/youtube-black.png')} height="36" />
              </div>
              <div className="youtube-link-red">
                <img src={require('../../assets/images/youtube-red.png')} height="36" />
              </div>
            </div>
            <Button onClick={handleSetNotifications} type="primary">
              {actions.ONBOARDING_CHECKLIST_NOTIFICATIONS_COMPLETED ?
                <IntlMessages id="components.onboarding.openNotificationsButton" /> :
                <IntlMessages id="notifications.add" />
              }
            </Button>
          </Panel>

          <Panel
            key="invite-friends"
            header={<IntlMessages
              id={isMicrosoftBillingPayment ? 'components.onboarding.assignLicensesTitle' : 'components.onboarding.inviteFriendsTitle'}
              values={{
                icon: () => actions.ONBOARDING_CHECKLIST_IMPORT_USERS_COMPLETED ? <CheckCircleFilled style={{'color': 'green'}} /> : <CheckCircleOutlined />,
              }}
            />}
          >
            <Paragraph><IntlMessages id="components.onboarding.inviteFriendsDescription" /></Paragraph>
            <div className="youtube-link" onClick={() => handleYoutubeLink('invite-friends')}>
              <img src={require('../../assets/images/onboarding/import-users.png')} width="296" />
              <div className="youtube-link-black">
                <img src={require('../../assets/images/youtube-black.png')} height="36" />
              </div>
              <div className="youtube-link-red">
                <img src={require('../../assets/images/youtube-red.png')} height="36" />
              </div>
            </div>
            <Button onClick={handleInviteFriends} type="primary">
              <IntlMessages id={isMicrosoftBillingPayment ? 'users.assignLicenses' : 'users.importUsers'}  />
            </Button>
          </Panel>

        </Collapse>
      </div>
      <div>
        {showSkipButton &&
          <Button onClick={handleSkipOnboarding} size="large" type="primary" block style={{ borderRadius: 0, marginBottom: 10 }}>
            <IntlMessages id="components.onboarding.skipOnboarding" />
          </Button>
        }
      </div>
    </Drawer>
  )
}
