import React, { useEffect, useState } from 'react'
import { Form, InputNumber, Checkbox, Row, Col, Select, Tooltip, Divider, DatePicker, Typography, Radio, Space } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import dayjs, { Dayjs } from 'dayjs'
import { useIntl } from 'react-intl'

import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { selectFeatureFlagsSlice } from '../../store/feature-flags-slice'
import { useShouldEnableFeatures } from '../../store/use-should-enable-features'
import { useAppSelector } from '../../store/hooks'
import { 
  getPayoutDateForMonthlyPeriod,
  getPayoutDateForSemiMonthlyPeriod,
  getPayoutDateForBiWeeklyPeriod,
  getPayoutDateForWeeklyPeriod
} from '@vacationtracker/shared/functions/calculation-shared'
import { getDatesForAccrualsSemiMonthly } from '@vacationtracker/shared/functions/get-dates-for-accruals/get-dates-for-accruals-semi-monthly'
import { getDatesForAccrualsMonthly } from '@vacationtracker/shared/functions/get-dates-for-accruals/get-dates-for-accruals-monthly'
import { getDatesForAccrualsBiWeekly } from '@vacationtracker/shared/functions/get-dates-for-accruals/get-dates-for-accruals-bi-weekly'
import { getDatesForAccrualsWeekly } from '@vacationtracker/shared/functions/get-dates-for-accruals/get-dates-for-accruals-weekly'
import { roundTo2Decimals } from '@vacationtracker/shared/functions/calculation-shared'
import { getDaysOrHours, setDaysOrHours } from '@vacationtracker/shared/functions/get-days-or-hours'
import { getShortestLeaveInterval } from '@vacationtracker/shared/functions/get-shortest-leave-interval'
import { getLeaveDaysNumber } from '@vacationtracker/shared/components/utils/leave-quotas'
import { addTimezoneOffsetToDate } from '@vacationtracker/shared/components/utils/add-offset-to-date'

import IntlMessages from '../../util/IntlMessages'

import { ILeavePolicyForm } from './types'
import { ILeavePolicy, RolloverStatus } from '../../types/leave-types'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { dayNames } from '@vacationtracker/shared/types/calendar'
import { numberOfDaysInMonth, generateDaysForMonth } from '../../util/number-of-days-in-month'
import { AccrualType, AccrualTypeEnum, ShortestLeaveIntervalEnum } from '@vacationtracker/shared/types/leave-policy'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { getUserWorkingHoursPerDay } from '@vacationtracker/shared/functions/work-week'
import isNull from 'lodash/isNull'

const { RangePicker } = DatePicker
const { Text } = Typography

const LeavePolicyForm = ({
  leavePolicy,
  form,
  copypolicy,
  location,
  locationWorkWeek,
  showAccruals = false,
  yearStartDay = 1,
  showLeavePolicyTitle = false,
  hourlyLeaveAccounting,
  isAssignLeavePolicyForm = false,
  isCreateLeaveTypeForm = false,
}: ILeavePolicyForm): React.ReactElement => {
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)
  const hasToilFeatureFlag = featureFlags.includes(FeatureFlagEnum.toil)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { formatMessage } = useIntl()
  const [hasUnlimitedDays, setUnlimitedDays] = useState(leavePolicy?.hasUnlimitedDays || false)
  const [daysPerYear, setDaysPerYear] = useState<number>(leavePolicy?.daysPerYear || 0)
  const [rolloverType, setRolloverType] = useState<RolloverStatus>(form.getFieldValue('rolloverType') as RolloverStatus)
  const [showAccrualsDate, setShowAccrualsDate] = useState(false)
  const [enableRolloverExpiry, setEnableRolloverExpiry] = useState(false)
  const [accrualType, setAccrualType] = useState('NONE')
  const [showApplyBroughtForwardSettingsToThePastYear, setShowApplyBroughtForwardSettingsToThePastYear] = useState(false)

  const [accrualCapRate, setAccrualCapRate] = useState(() => {
    if (leavePolicy?.accrualCapRate && leavePolicy?.accrualCapRate > 0) {
      form.setFieldsValue({ accrualCap: true })
      form.setFieldsValue({ accrualCapRate: leavePolicy?.accrualCapRate })
      return leavePolicy.accrualCapRate
    } else {
      form.setFieldsValue({ accrualCap: false })
    }
    return 0
  })
  const defaultAccrualCapRate = 2
  const accrualCapRates:number[] = [1, 1.25, 1.5, 1.75, 2, 2.25, 2.5, 2.75, 3]

  const [shouldAccrualsBeDisabled, setShouldAccrualsBeDisabled] = useState(false)
  const [shortestLeaveIntervals, setShortestLeaveIntervals] = useState([ShortestLeaveIntervalEnum.fullDay, ShortestLeaveIntervalEnum.halfDay])
  const [rolloverExpirySelectDays, setRolloverExpirySelectDays] = useState(
    generateDaysForMonth(
      numberOfDaysInMonth(
        (leavePolicy?.rolloverExpiryMonth || location?.rolloverExpiryMonth) ?? 1
      )
    )
  )
  const [step, setStep] = useState(
    (leavePolicy?.shortestLeaveInterval === ShortestLeaveIntervalEnum.fullDay || leavePolicy?.shortestLeaveInterval == undefined)
    || hourlyLeaveAccounting ? 1 : 0.5
  )
  const shouldEnableAccruals = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.accruals)
  const shouldEnableHourIntervals = useShouldEnableFeatures(SubscriptionPlanEnum.complete, FeatureFlagEnum.shortestLeaveInterval)

  const dateFormat = 'YYYY-MM-DD'

  const shoudlDisableAccruals = (hasUnlimitedDays: boolean, daysPerYear: number): boolean => {
    return Boolean(hasUnlimitedDays || !daysPerYear)
  }

  const [toil, setToil] = useState(() => {
    const toil = Boolean(leavePolicy?.toil)
    form.setFieldsValue({ toil })
    if (toil) {
      form.setFieldsValue({
        toilShortestInterval: leavePolicy?.toilShortestInterval,
        toilRequestsAllowedForUsers: leavePolicy?.toilRequestsAllowedForUsers || false,
        toilExpiration: leavePolicy?.toilExpiration,
      })
      if (leavePolicy?.toilExpirationMonths && leavePolicy?.toilExpirationMonths > 0) {
        form.setFieldsValue({ toilExpirationMonths: leavePolicy.toilExpirationMonths })
      } else if (leavePolicy?.toilExpiration && leavePolicy.toilExpirationDate) {
        form.setFieldsValue({ toilExpirationMonths: 'calendar-year' })
      }
    }
    return toil
  })
  const [toilExpiration, setToilExpiration] =  useState(() => {
    return Boolean(leavePolicy?.toilExpiration)
  })

  const months = [
    { label: 'app.january', value: 1 },
    { label: 'app.february', value: 2 },
    { label: 'app.march', value: 3 },
    { label: 'app.april', value: 4 },
    { label: 'app.may', value: 5 },
    { label: 'app.june', value: 6 },
    { label: 'app.july', value: 7 },
    { label: 'app.august', value: 8 },
    { label: 'app.september', value: 9 },
    { label: 'app.october', value: 10 },
    { label: 'app.november', value: 11 },
    { label: 'app.december', value: 12 },
  ]

  useEffect(() => {
    if(shouldEnableHourIntervals) {
      setShortestLeaveIntervals((data) => {
        return [
          ...data,
          ShortestLeaveIntervalEnum.oneHour,
        ]
      })
    }
  }, [shouldEnableHourIntervals])

  useEffect(()=> {
    setShouldAccrualsBeDisabled(shoudlDisableAccruals(hasUnlimitedDays, daysPerYear))
    form.setFieldsValue({accrualCap: leavePolicy?.accrualCapRate !== 0})
  }, [leavePolicy, hasUnlimitedDays, daysPerYear])

  useEffect(() => {
    if (leavePolicy?.shortestLeaveInterval === ShortestLeaveIntervalEnum.oneHour && !shouldEnableHourIntervals) {
      leavePolicy.shortestLeaveInterval = ShortestLeaveIntervalEnum.halfDay
      form.setFieldsValue({
        shortestLeaveInterval: ShortestLeaveIntervalEnum.halfDay,
      })
    }
  }, [shouldEnableHourIntervals, leavePolicy])

  useEffect(() => {
    if (copypolicy) {
      setShouldAccrualsBeDisabled(false)
      setUnlimitedDays(copypolicy?.hasUnlimitedDays || false)
      changeAccrualType(copypolicy.accrualType)
      if (copypolicy.toil) {
        setToil(Boolean(copypolicy.toil))
        setToilExpiration(Boolean(copypolicy?.toilExpiration))
      }

      let rolloverType: RolloverStatus
      if (copypolicy.maxRolloverDays === 366 || copypolicy.maxRolloverDays === 2928) {
        rolloverType = RolloverStatus.all
      } else if (copypolicy.maxRolloverDays > 0) {
        rolloverType = RolloverStatus.limited
      } else {
        rolloverType = RolloverStatus.no
      }
      setRolloverType(rolloverType)

      const daysPerYear = form.getFieldValue('daysPerYear')
      setDaysPerYear(daysPerYear as number)

      setTimeout(() => { // wait for form to be initialized
        setAccrualCapRate(copypolicy.accrualCapRate ? copypolicy.accrualCapRate : 0)

        if (copypolicy.toil) {
          form.setFieldsValue({
            toilShortestInterval: copypolicy?.toilShortestInterval,
            toilRequestsAllowedForUsers: copypolicy?.toilRequestsAllowedForUsers || false,
            toilExpiration: copypolicy?.toilExpiration,
          })
          if (copypolicy?.toilExpirationMonths && copypolicy?.toilExpirationMonths > 0) {
            form.setFieldsValue({ toilExpirationMonths: copypolicy.toilExpirationMonths })
          } else if (copypolicy?.toilExpiration && copypolicy.toilExpirationDate) {
            form.setFieldsValue({ toilExpirationMonths: 'calendar-year' })
          }
        }
        
        form.setFieldsValue({
          rolloverType,
          shortestLeaveInterval: getShortestLeaveInterval(copypolicy?.shortestLeaveInterval, copypolicy.allowHalfDays),
          accrualCap: (copypolicy?.accrualCapRate && copypolicy?.accrualCapRate > 0) ? true : false,
          accrualCapRate: copypolicy?.accrualCapRate,
        })
      }, 0)
    }
  }, [copypolicy])

  useEffect(() => {
    const accrualType = form.getFieldValue('accrualType')
    if (accrualType !== 'NONE') {
      changeAccrualType(accrualType as AccrualType)
    }
  }, [form])

  useEffect(() => {
    if (typeof leavePolicy?.broughtForwardExpirationSettings?.enabled === 'boolean') {
      form.setFieldsValue({
        enableRolloverExpiry: leavePolicy?.enableRolloverExpiry,
      })
      setEnableRolloverExpiry(leavePolicy?.broughtForwardExpirationSettings.enabled)
    } else if (typeof location?.rolloverNeverExpireDays === 'boolean') {
      setEnableRolloverExpiry(!location?.rolloverNeverExpireDays)
    } else {
      setEnableRolloverExpiry(false)
    }
  }, [leavePolicy, location])

  const onChangeMonthExpiryDay = (month: number) => {
    setShowApplyBroughtForwardSettingsToThePastYear(true)
    const numberOfDays = numberOfDaysInMonth(month)
    const days = generateDaysForMonth(numberOfDays)
    setRolloverExpirySelectDays(days)
  }

  const onChangeRolloverExpiryAfterDays = (days: number) => {
    form.setFieldsValue({
      rolloverExpiryAfterDays: Math.round(days),
    })
  }

  const onChangeHandleRolloverExpiry = (value: boolean) => {
    setShowApplyBroughtForwardSettingsToThePastYear(true)
    setEnableRolloverExpiry(value)

    form.setFieldsValue({
      enableRolloverExpiry: value,
    })
  }

  const onToggleToil = (e) => {
    const toilEnabled = e.target.value as boolean
    setToil(toilEnabled)
    form.setFieldsValue({ toil: toilEnabled })
    if (toilEnabled) {
      form.setFieldsValue({
        toilShortestInterval: leavePolicy?.shortestLeaveInterval || ShortestLeaveIntervalEnum.fullDay,
        toilExpiration: false,
        toilRequestsAllowedForUsers: false,
      })
    }
  }

  const onToggleExpiration = (e) => {
    const expirationEnabled = e.target.value as boolean
    setToilExpiration(expirationEnabled)
    form.setFieldsValue({ toilExpiration: expirationEnabled })
    if (expirationEnabled) {
      form.setFieldsValue({ toilExpirationMonths: 1})
    }
  }


  const getDayWithSuffix = (d) => {
    if (d > 3 && d < 21) {
      return `${d}th`
    }
    switch (d % 10) {
      case 1: {
        return `${d}st`
      }
      case 2: {
        return `${d}nd`
      }
      case 3: {
        return `${d}rd`
      }
      default: {
        return `${d}th`
      }
    }
  }

  const getAccrualDaysMessage = () => {
    const earningDate = form.getFieldValue('firstEarningDate') as Date

    if (!earningDate) {
      return ''
    }

    if (accrualType !== 'NONE') {
      switch (accrualType) {
        case AccrualTypeEnum.monthly:
          return <IntlMessages
            id={hourlyLeaveAccounting ? 'components.leavePolicyForm.accrualMonthlySummaryHours' : 'components.leavePolicyForm.accrualMonthlySummary'}
            values={{
              amount: roundTo2Decimals(form.getFieldValue('daysPerYear') / 12),
              quota: form.getFieldValue('daysPerYear'),
              date: getDayWithSuffix(dayjs(form.getFieldValue('firstEarningDate') as Date).date()),
            }}
          />
        case AccrualTypeEnum.semiMonthly: {
          let firstDate: string, secondDate: string

          if (dayjs(earningDate).date() >= 16) {
            firstDate =  getDayWithSuffix(dayjs(earningDate).subtract(15, 'days').date())
            secondDate = getDayWithSuffix(dayjs(earningDate).date())
          } else {
            firstDate = getDayWithSuffix(dayjs(earningDate).date())
            secondDate = getDayWithSuffix(dayjs(earningDate).add(15, 'days').date())
          }

          return <IntlMessages
            id={hourlyLeaveAccounting ? 'components.leavePolicyForm.accrualSemiMonthlySummaryHours' : 'components.leavePolicyForm.accrualSemiMonthlySummary'}
            values={{
              amount: roundTo2Decimals(form.getFieldValue('daysPerYear') / 24),
              quota: form.getFieldValue('daysPerYear'),
              firstDate,
              secondDate,
            }}
          />
        }
        case AccrualTypeEnum.biWeekly:
          return <IntlMessages
            id={hourlyLeaveAccounting ? 'components.leavePolicyForm.accrualBiWeeklySummaryHours' : 'components.leavePolicyForm.accrualBiWeeklySummary'}
            values={{
              amount: roundTo2Decimals(form.getFieldValue('daysPerYear') / 26),
              quota: form.getFieldValue('daysPerYear'),
              day: formatMessage({ id: dayNames[form.getFieldValue('firstEarningDate').day()] }),
            }}
          />
        case AccrualTypeEnum.weekly:
          return <IntlMessages
            id={hourlyLeaveAccounting ? 'components.leavePolicyForm.accrualWeeklySummaryHours' : 'components.leavePolicyForm.accrualWeeklySummary'}
            values={{
              amount: roundTo2Decimals(form.getFieldValue('daysPerYear') / 52),
              quota: form.getFieldValue('daysPerYear'),
              day: formatMessage({ id: dayNames[form.getFieldValue('firstEarningDate').day()] }),
            }}
          />
        default:
          return null
      }
    } else {
      return null
    }
  }

  const accrualTypes = [
    { type: AccrualTypeEnum.none },
    { type: AccrualTypeEnum.monthly },
    { type: AccrualTypeEnum.semiMonthly },
    { type: AccrualTypeEnum.biWeekly },
    { type: AccrualTypeEnum.weekly },
  ]

  const formItemLayout = {
    labelCol: { span: 17, offset: 0 },
    wrapperCol: { span: 7, offset: 0 },
  }
  const formFullWidthLayout = {
    labelCol: { span: 11, offset: 0 },
    wrapperCol: { span: 11, offset: 0 },
  }

  const changeRolloveType = (type: RolloverStatus) => {
    setRolloverType(type)
    setShowApplyBroughtForwardSettingsToThePastYear(true)
    form.setFieldsValue({
      applyBroughtForwardSettingsToThePastYear: false,
    })
  }

  const onChangeDaysPerYear = (days: number): void => {

    // The name of the field is daysPerYear, but it can be hours per year
    const daysOrHoursPerYear = getLeaveDaysNumber(accrualType, leavePolicy?.allowHalfDays, form.getFieldValue('shortestLeaveInterval') as string, days, hourlyLeaveAccounting)

    // if we track leaves in hours then we need to prevent decimal values
    const value = hourlyLeaveAccounting ? Math.trunc(daysOrHoursPerYear) : daysOrHoursPerYear

    form.setFieldsValue({
      daysPerYear: value,
    })

    setDaysPerYear(setDaysOrHours(value, hourlyLeaveAccounting, getUserWorkingHoursPerDay(location?.workHours)))

    if (!days) {
      resetAccrualState(null)
      return
    }
  }

  const onChangeMaxRolloverDays = (days: number): void => {
    setShowApplyBroughtForwardSettingsToThePastYear(true)
    // The name of the field is maxRolloverDays, but it can be hours
    const maxRolloverDays = getLeaveDaysNumber(accrualType, leavePolicy?.allowHalfDays, form.getFieldValue('shortestLeaveInterval') as string, days, hourlyLeaveAccounting)

    // if we track leaves in hours then we need to prevent decimal values
    const value = hourlyLeaveAccounting ? Math.trunc(maxRolloverDays) : maxRolloverDays

    form.setFieldsValue({
      maxRolloverDays: value,
    })
  }

  const onChangeAccrualCap = (event): void => {
    const value = Boolean(event.target.value)
    if (value) {
      form.setFieldsValue({rolloverType: RolloverStatus.all, enableRolloverExpiry: false, accrualCapRate: defaultAccrualCapRate})
      setRolloverType(RolloverStatus.all)
      setEnableRolloverExpiry(false)
      setAccrualCapRate(defaultAccrualCapRate)
    } else {
      form.setFieldsValue({ accrualCapRate: 0 })
      setAccrualCapRate(0)
    }
  }

  const disabledAcrrualsDate = (checkDate: Dayjs): boolean => {
    const today = new Date()
    const checkDateString = checkDate.format(dateFormat)

    if (accrualType === AccrualTypeEnum.monthly) {
      const { pickerEndDate, pickerStartDate } = getDatesForAccrualsMonthly({today, yearStartDay})
      return checkDateString < pickerStartDate || checkDateString > pickerEndDate
    }
    if (accrualType === AccrualTypeEnum.semiMonthly) {
      const { pickerStartDate, pickerEndDate } = getDatesForAccrualsSemiMonthly({ today, yearStartDay })
      return checkDateString < pickerStartDate || checkDateString > pickerEndDate
    }
    if (accrualType === AccrualTypeEnum.biWeekly) {
      const accrualPeriodStart = form.getFieldValue('accrualPeriod')[0]
      const { pickerStartDate, pickerEndDate } = getDatesForAccrualsBiWeekly({ today, accrualPeriodStart })
      return checkDateString < pickerStartDate || checkDateString > pickerEndDate
    }
    if (accrualType === AccrualTypeEnum.weekly) {
      const accrualPeriodStart = form.getFieldValue('accrualPeriod')[0]
      const { pickerStartDate, pickerEndDate } = getDatesForAccrualsWeekly({ today, accrualPeriodStart })
      return checkDateString < pickerStartDate || checkDateString > pickerEndDate
    }

    return false
  }

  const changeAccrualType = (newAccrualType: AccrualType): void => {
    const today = new Date()

    if (newAccrualType === AccrualTypeEnum.monthly) {
      const { accrualPeriodStart, accrualPeriodEnd } = getDatesForAccrualsMonthly({today, yearStartDay})

      form.setFieldsValue({
        accrualPeriod: [dayjs(accrualPeriodStart), dayjs(accrualPeriodEnd)],
        pauseAccrualsWhenUserIsDeactivated: isNull(leavePolicy?.pauseAccrualsWhenUserIsDeactivated) || leavePolicy?.pauseAccrualsWhenUserIsDeactivated,
      })

      if (leavePolicy?.firstEarningDate && leavePolicy.accrualType === AccrualTypeEnum.monthly) {
        form.setFieldsValue({
          firstEarningDate: dayjs(
            getPayoutDateForMonthlyPeriod(
              addTimezoneOffsetToDate(new Date(accrualPeriodEnd)),
              addTimezoneOffsetToDate(new Date(leavePolicy.firstEarningDate)),
              leavePolicy?.accrualPeriodStart ? addTimezoneOffsetToDate(new Date(leavePolicy?.accrualPeriodStart)) : undefined
            )
          ),
        })
      } else if (copypolicy?.firstEarningDate) {
        form.setFieldsValue({
          firstEarningDate: dayjs(
            getPayoutDateForMonthlyPeriod(
              addTimezoneOffsetToDate(new Date(accrualPeriodEnd)),
              addTimezoneOffsetToDate(new Date(copypolicy.firstEarningDate)),
              copypolicy?.accrualPeriodStart ? addTimezoneOffsetToDate(new Date(copypolicy?.accrualPeriodStart)) : undefined
            )
          ),
        })
      } else {
        form.setFieldsValue({
          firstEarningDate: dayjs(accrualPeriodEnd).add(1, 'days'),
        })
      }
    }

    if (newAccrualType === AccrualTypeEnum.semiMonthly) {
      const { accrualPeriodStart, accrualPeriodEnd } = getDatesForAccrualsSemiMonthly({today, yearStartDay})

      form.setFieldsValue({
        accrualPeriod: [dayjs(accrualPeriodStart), dayjs(accrualPeriodEnd)],
      })

      if (leavePolicy?.firstEarningDate && leavePolicy.accrualType === AccrualTypeEnum.semiMonthly) {
        form.setFieldsValue({
          firstEarningDate: dayjs(getPayoutDateForSemiMonthlyPeriod(
            addTimezoneOffsetToDate(new Date(accrualPeriodStart)),
            addTimezoneOffsetToDate(new Date(accrualPeriodEnd)),
            addTimezoneOffsetToDate(new Date(leavePolicy.firstEarningDate)),
            leavePolicy?.accrualPeriodStart ? addTimezoneOffsetToDate(new Date(leavePolicy?.accrualPeriodStart)) : undefined
          )),
        })
      } else if (copypolicy?.firstEarningDate) {
        form.setFieldsValue({
          firstEarningDate: dayjs(getPayoutDateForSemiMonthlyPeriod(
            addTimezoneOffsetToDate(new Date(accrualPeriodStart)),
            addTimezoneOffsetToDate(new Date(accrualPeriodEnd)),
            addTimezoneOffsetToDate(new Date(copypolicy.firstEarningDate)),
            copypolicy?.accrualPeriodStart ? addTimezoneOffsetToDate(new Date(copypolicy?.accrualPeriodStart)) : undefined
          )),
        })
      } else {
        form.setFieldsValue({
          firstEarningDate: dayjs(accrualPeriodEnd),
        })
      }
    }

    if (newAccrualType === AccrualTypeEnum.biWeekly) {
      const { accrualPeriodStart, accrualPeriodEnd } = getDatesForAccrualsBiWeekly({ today, accrualPeriodStart: leavePolicy?.accrualPeriodStart })

      form.setFieldsValue({
        accrualPeriod: [dayjs(accrualPeriodStart), dayjs(accrualPeriodEnd)],
      })

      if (leavePolicy?.accrualPeriodStart && leavePolicy?.firstEarningDate && leavePolicy.accrualType === AccrualTypeEnum.biWeekly) {
        form.setFieldsValue({
          firstEarningDate: dayjs(getPayoutDateForBiWeeklyPeriod(
            addTimezoneOffsetToDate(new Date(accrualPeriodStart)),
            addTimezoneOffsetToDate(new Date(accrualPeriodEnd)),
            addTimezoneOffsetToDate(new Date(leavePolicy.firstEarningDate)),
            leavePolicy?.accrualPeriodStart ? addTimezoneOffsetToDate(new Date(leavePolicy?.accrualPeriodStart)) : undefined
          )).utc(),
        })
      } else if (copypolicy?.firstEarningDate) {
        const { accrualPeriodStart, accrualPeriodEnd } = getDatesForAccrualsBiWeekly({ today, accrualPeriodStart: copypolicy?.accrualPeriodStart })
        form.setFieldsValue({
          firstEarningDate: dayjs(getPayoutDateForBiWeeklyPeriod(
            addTimezoneOffsetToDate(new Date(accrualPeriodStart)),
            addTimezoneOffsetToDate(new Date(accrualPeriodEnd)),
            addTimezoneOffsetToDate(new Date(copypolicy.firstEarningDate)),
            copypolicy?.accrualPeriodStart ? addTimezoneOffsetToDate(new Date(copypolicy?.accrualPeriodStart)) : undefined
          )).utc(),
        })
      } else {
        form.setFieldsValue({
          firstEarningDate: dayjs(accrualPeriodEnd),
        })
      }
    }

    if (newAccrualType === AccrualTypeEnum.weekly) {
      const { accrualPeriodStart, accrualPeriodEnd } = getDatesForAccrualsWeekly({ today, accrualPeriodStart: leavePolicy?.accrualPeriodStart })

      form.setFieldsValue({
        accrualPeriod: [dayjs(accrualPeriodStart), dayjs(accrualPeriodEnd)],
      })

      if (leavePolicy?.accrualPeriodStart && leavePolicy?.firstEarningDate && leavePolicy.accrualType === AccrualTypeEnum.weekly) {
        form.setFieldsValue({
          firstEarningDate: dayjs(getPayoutDateForWeeklyPeriod(
            addTimezoneOffsetToDate(new Date(accrualPeriodStart)),
            addTimezoneOffsetToDate(new Date(accrualPeriodEnd)),
            addTimezoneOffsetToDate(new Date(leavePolicy.firstEarningDate)),
            leavePolicy?.accrualPeriodStart ? addTimezoneOffsetToDate(new Date(leavePolicy?.accrualPeriodStart)) : undefined
          )).utc(),
        })
      } else if (copypolicy?.firstEarningDate) {
        const { accrualPeriodStart, accrualPeriodEnd } = getDatesForAccrualsWeekly({ today, accrualPeriodStart: copypolicy?.accrualPeriodStart })
        form.setFieldsValue({
          firstEarningDate: dayjs(getPayoutDateForWeeklyPeriod(
            addTimezoneOffsetToDate(new Date(accrualPeriodStart)),
            addTimezoneOffsetToDate(new Date(accrualPeriodEnd)),
            addTimezoneOffsetToDate(new Date(copypolicy.firstEarningDate)),
            copypolicy?.accrualPeriodStart ? addTimezoneOffsetToDate(new Date(copypolicy?.accrualPeriodStart)) : undefined
          )).utc(),
        })
      } else {
        form.setFieldsValue({
          firstEarningDate: dayjs(accrualPeriodEnd),
        })
      }
    }

    if (newAccrualType === 'NONE') {
      setShowAccrualsDate(false)
    } else {
      setShowAccrualsDate(true)
    }

    setAccrualType(newAccrualType)
  }

  const resetAccrualState = (leavePolicy?: ILeavePolicy | null) => {
    if (leavePolicy) {
      changeAccrualType(leavePolicy?.accrualType)
      form.setFieldsValue({
        accrualType: leavePolicy?.accrualType,
      })
    } else {
      changeAccrualType('NONE')
      form.setFieldsValue({
        accrualType: 'NONE',
        accrualPeriod: [dayjs(), dayjs().add(13, 'days')],
        firstEarningDate: null,
      })
    }
  }

  const onChangeBiWeeklyPeriod = (_: unknown, dates: [string, string]) => {
    form.setFieldsValue({
      accrualPeriod: [dayjs(dates[0]), dayjs(dates[0]).add(13, 'days')],
      firstEarningDate: dayjs(dayjs(dates[0]).add(13, 'days')),
    })
  }

  const onChangeWeeklyPeriod = (_: unknown, dates: [string, string]) => {
    form.setFieldsValue({
      accrualPeriod: [dayjs(dates[0]), dayjs(dates[0]).add(6, 'days')],
      firstEarningDate: dayjs(dayjs(dates[0]).add(6, 'days')),
    })
  }

  const changeShortestLeaveDuration = () => {
    const shortestLeaveInterval = form.getFieldValue('shortestLeaveInterval')
    const maxRolloverDays = form.getFieldValue('maxRolloverDays')
    const daysPerYear = form.getFieldValue('daysPerYear')
    if (shortestLeaveInterval === ShortestLeaveIntervalEnum.fullDay || shortestLeaveInterval === undefined) {
      form.setFieldsValue({maxRolloverDays: Math.trunc(maxRolloverDays as number)})
      form.setFieldsValue({daysPerYear: Math.trunc(daysPerYear as number)})
      setStep(1)
    } else {
      setStep(0.5)
    }
  }

  return (
    <>
      {showLeavePolicyTitle &&
        <Divider><IntlMessages id="components.leavePolicyForm.leavePolicy" /></Divider>
      }

      <Row>
        <Col span={14}>
          <Form.Item
            className='wrap-label'
            label={<IntlMessages id="app.approvalRequired" />}
            name="isApprovalRequired"
            valuePropName="checked"
            {...formItemLayout}
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            className='wrap-label'
            label={<IntlMessages id="components.leavePolicyForm.hasUnlimitedDays" />}
            name="hasUnlimitedDays"
            valuePropName="checked"
            {...formItemLayout}
          >
            <Checkbox onChange={(e) => {
              setUnlimitedDays(!hasUnlimitedDays)
              if (accrualType === 'NONE') {
                resetAccrualState(null)
                return
              }
              if (leavePolicy?.accrualType === 'NONE' || e.target.checked || !form.getFieldValue('daysPerYear')) {
                resetAccrualState(null)
              } else {
                resetAccrualState(leavePolicy)
              }
            }
            } />
          </Form.Item>

          <Form.Item
            className='wrap-label'
            label={<IntlMessages id="app.shortestLeaveDuration" />}
            name="shortestLeaveInterval"
            {...formItemLayout}
          >
            <Select
              key="shortestLeaveInterval"
              popupMatchSelectWidth={false}
              onChange={changeShortestLeaveDuration}
            >
              {shortestLeaveIntervals.map(period =>
                <Select.Option key={period} value={period}>
                  <IntlMessages id={`app.${period.toLowerCase()}`} />
                </Select.Option>
              )}
            </Select>
          </Form.Item>

          <Form.Item
            label={(<>
              <IntlMessages id="components.leavePolicyForm.allowAddAndEditLeaveInPast" />
              <Tooltip className="info-tooltip" title={<IntlMessages id="components.leavePolicyForm.allowAddAndEditLeaveInPastInfo" />} ><InfoCircleOutlined /></Tooltip>
            </>)}
            className="form-item-label-pre-wrap wrap-label"
            name="allowLeaveInPast"
            valuePropName="checked"
            {...formItemLayout}
          >
            <Checkbox />
          </Form.Item>
        </Col>
        <Col span={10}>
          <Form.Item
            className='wrap-label'
            label={(<>
              <IntlMessages id="components.leavePolicyForm.negativeBalance" />
              <Tooltip className="info-tooltip" title={<IntlMessages id="components.leavePolicyForm.negativeBalanceInfo" />} ><InfoCircleOutlined /></Tooltip>
            </>)}
            name="negativeBallanceAllowed"
            valuePropName="checked"
            {...formItemLayout}
          >
            <Checkbox disabled={hasUnlimitedDays} />
          </Form.Item>

          <Form.Item
            className='wrap-label'
            label={(<>
              {hourlyLeaveAccounting ? 
                <IntlMessages id="components.leavePolicyForm.yearlyQuotaHours" /> : 
                <IntlMessages id="components.leavePolicyForm.yearlyQuota" />}
              <Tooltip className="info-tooltip" title={<IntlMessages id="components.leavePolicyForm.yearlyQuotaInfo" />} ><InfoCircleOutlined /></Tooltip>
            </>)}
            name="daysPerYear"
            {...formItemLayout}
            rules={[{ required: true, message: <IntlMessages id="components.leavePolicyForm.required" /> }]}
          >
            <InputNumber
              step={step}
              min={0}
              max={hourlyLeaveAccounting ? 2920 : 365}
              style={{ width: 70 }}
              value={getDaysOrHours(daysPerYear, hourlyLeaveAccounting, getUserWorkingHoursPerDay(locationWorkWeek?.workHours))}
              onChange={(value) => onChangeDaysPerYear(value as number)}
              disabled={hasUnlimitedDays}
              data-qa='days-per-year'
            />
          </Form.Item>

          <Form.Item
            className='wrap-label'
            label={<IntlMessages id="components.leavePolicyForm.reasonRequired" />}
            name="isReasonRequired"
            valuePropName="checked"
            {...formItemLayout}
          >
            <Checkbox />
          </Form.Item>

          <Form.Item
            className='wrap-label'
            label={(<>
              <IntlMessages id="components.leavePolicyForm.hideLeaveType" />
              <Tooltip className="info-tooltip" title={<IntlMessages id="components.leavePolicyForm.hideLeaveTypeInfo" />} ><InfoCircleOutlined /></Tooltip>
            </>)}
            name="hideLeaveType"
            valuePropName="checked"
            {...formItemLayout}
          >
            <Checkbox />
          </Form.Item>
        </Col>
      </Row>

      {shouldEnableAccruals && showAccruals &&
        <>
          <Divider><IntlMessages id="app.accruals" /></Divider>
          <Form.Item
            className='wrap-label'
            key="accrualTypeWrap"
            label={(<>
              <IntlMessages id="components.leavePolicyForm.accrualType" />
              <Tooltip className="info-tooltip" title={<IntlMessages id="components.leavePolicyForm.accrualTypeInfo" />} >
                <InfoCircleOutlined />
              </Tooltip>
            </>)}
            {...formFullWidthLayout}
            shouldUpdate>{() => {
              return <>
                <Form.Item
                  key="accrualType"
                  name="accrualType"
                  style={{marginBottom: '0px'}}
                >
                  <Select
                    key="accrualTypeSelect"
                    onChange={changeAccrualType}
                    disabled={shoudlDisableAccruals(hasUnlimitedDays, daysPerYear)}
                  >
                    {accrualTypes.map(event =>
                      <Select.Option key={event.type} value={event.type}>
                        <IntlMessages id={`app.${event.type.toLowerCase()}`} />
                      </Select.Option>
                    )}
                  </Select>
                </Form.Item>
              </>
            }}
          </Form.Item>

          {(hasUnlimitedDays || daysPerYear === 0) &&
            <Form.Item
              wrapperCol={{ span: 24 }}
              style={{ textAlign: 'center' }}
              shouldUpdate>{() => {
                return (
                  <Text type='secondary'>
                    <IntlMessages id="components.leavePolicyForm.accrualDisabledInfo" />
                  </Text>
                )
              }}
            </Form.Item>
          }

          {showAccrualsDate && (accrualType === AccrualTypeEnum.monthly || accrualType === AccrualTypeEnum.semiMonthly) &&
            <Form.Item name="accrualPeriod" label={<IntlMessages id="components.leavePolicyForm.currentAccrualPeriod" />} {...formFullWidthLayout}>
              <RangePicker
                format={dateFormat}
                disabled={true}
              />
            </Form.Item>
          }
          {showAccrualsDate && accrualType === 'BI-WEEKLY' &&
            <Form.Item
              className='wrap-label'
              name="accrualPeriod"
              label={<IntlMessages id="components.leavePolicyForm.currentAccrualPeriod" />}
              rules={[{ required: true, message: <IntlMessages id="components.leavePolicyForm.required" /> }]}
              {...formFullWidthLayout}
            >
              <RangePicker
                allowClear={false}
                format={dateFormat}
                disabledDate={(checkDate) => {
                  return checkDate < dayjs().subtract(13, 'days') || checkDate > dayjs()
                }}
                disabled={[shouldAccrualsBeDisabled, true]}
                onCalendarChange={onChangeBiWeeklyPeriod}
              />
            </Form.Item>
          }
          {showAccrualsDate && accrualType === 'WEEKLY' &&
            <Form.Item
              className='wrap-label'
              name="accrualPeriod"
              label={<IntlMessages id="components.leavePolicyForm.currentAccrualPeriod" />}
              rules={[{ required: true, message: <IntlMessages id="components.leavePolicyForm.required" /> }]}
              {...formFullWidthLayout}
            >
              <RangePicker
                allowClear={false}
                format={dateFormat}
                disabledDate={(checkDate) => {
                  return checkDate < dayjs().subtract(6, 'days') || checkDate > dayjs()
                }}
                disabled={[shouldAccrualsBeDisabled, true]}
                onCalendarChange={onChangeWeeklyPeriod}
              />
            </Form.Item>
          }
          {showAccrualsDate &&
            <>
              <Form.Item
                className='wrap-label'
                label={<IntlMessages id="components.leavePolicyForm.nextAccrualDay" />}
                name="firstEarningDate"
                rules={[{ required: true, message: <IntlMessages id="components.leavePolicyForm.required" /> }]}
                {...formFullWidthLayout}
              >
                <DatePicker format={dateFormat} disabledDate={disabledAcrrualsDate} disabled={shouldAccrualsBeDisabled} />
              </Form.Item>
              <Form.Item
                wrapperCol={{ span: 20, offset: 4 }}
                shouldUpdate>{() => {
                  return <p className="vt-primary-color" style={{ textAlign: 'center', margin: 0 }}>{getAccrualDaysMessage()}</p>
                } }
              </Form.Item>
            </>
          }
          {showAccrualsDate &&
            <Form.Item
              className='wrap-label'
              label={(<>
                <IntlMessages id="components.leavePolicyForm.allowAdvanceAccrualUsage" />
                <Tooltip className="info-tooltip" title={<IntlMessages id="components.leavePolicyForm.allowAdvanceAccrualUsageInfo" />} ><InfoCircleOutlined /></Tooltip>
              </>)}
              name="allowAdvanceAccrualUsage"
              {...formFullWidthLayout}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={true}><IntlMessages id="app.yes" /></Radio.Button>
                <Radio.Button value={false}><IntlMessages id="app.no" /></Radio.Button>
              </Radio.Group>
            </Form.Item>
          }
          {showAccrualsDate &&
            <Form.Item
              className='wrap-label'
              label={(<>
                <IntlMessages id="components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivated" />
                <Tooltip className="info-tooltip" title={<IntlMessages id="components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedInfo" />} ><InfoCircleOutlined /></Tooltip>
              </>)}
              name="pauseAccrualsWhenUserIsDeactivated"
              {...formFullWidthLayout}
            >
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={true}><IntlMessages id="app.yes" /></Radio.Button>
                <Radio.Button value={false}><IntlMessages id="app.no" /></Radio.Button>
              </Radio.Group>
            </Form.Item>
          }
          {showAccrualsDate &&
            <>
              <Form.Item
                className='wrap-label'
                label={(<>
                  <IntlMessages id="components.leavePolicyForm.accrualCap" />
                  <Tooltip className="info-tooltip" title={<IntlMessages id="components.leavePolicyForm.accrualCapTooltip" />}><InfoCircleOutlined /></Tooltip>
                </>)}
                name="accrualCap"
                {...formFullWidthLayout}
              >
                <Radio.Group buttonStyle="solid" onChange={onChangeAccrualCap}>
                  <Radio.Button value={true}><IntlMessages id="app.yes" /></Radio.Button>
                  <Radio.Button value={false}><IntlMessages id="app.no" /></Radio.Button>
                </Radio.Group>
              </Form.Item>
              {accrualCapRate > 0 &&
              <Form.Item
                label={(<IntlMessages id="components.leavePolicyForm.accrualCapRate" />)}
                {...formFullWidthLayout}
              >
                <Space>
                  <Form.Item
                    className='wrap-label'
                    name="accrualCapRate"
                    style={{marginBottom: '0px'}}
                    {...formFullWidthLayout}
                  >
                    <Select
                      value={accrualCapRate}
                      onChange={(val) => {
                        setAccrualCapRate(val)
                        form.setFieldsValue({ accrualCapRate: val })
                      }}
                      style={{ width: '73px' }}
                    >
                      {accrualCapRates.map(rate => <Select.Option key={rate} value={rate}>{rate}</Select.Option>)}
                    </Select>
                  </Form.Item>
                  <Typography><IntlMessages id="components.leavePolicyForm.accrualCapRateTimes" /></Typography>
                </Space>
              </Form.Item>
              }
            </>
          }
        </>
      }

      {isCreateLeaveTypeForm && <div>
        <Divider style={{opacity: 0.5}}><IntlMessages id="app.accruals" /></Divider>
        <Form.Item
          className='wrap-label'
          key="accrualTypeWrap"
          label={<IntlMessages id="components.leavePolicyForm.accrualType" />}
          style={{opacity: 0.5}}
        >
          <Select value={<IntlMessages id={'app.none'} />} disabled>
            <Select.Option>
              <IntlMessages id={'app.none'} />
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          wrapperCol={{ span: 24 }}
        >
          <Text type='secondary'>
            <IntlMessages id="components.leavePolicyForm.createLeaveTypeFormAccrualsInfo1" />
            &nbsp;{authCompany?.plan === 'Core' && <IntlMessages id="app.feature.access" />}
          </Text>
        </Form.Item>
      </div>}

      <Divider><IntlMessages id="components.leavePolicyForm.rolloverSettings" /></Divider>

      <Form.Item
        {...formFullWidthLayout}
        className='wrap-label'
        label={(<>
          <IntlMessages id="app.broughtForward" />
          <Tooltip
            className="info-tooltip"
            title={hourlyLeaveAccounting ?
              <IntlMessages id="components.leavePolicyForm.maxRolloverDaysInfoHours"/> :
              <IntlMessages id="components.leavePolicyForm.maxRolloverDaysInfo"/> }
          >
            <InfoCircleOutlined />
          </Tooltip>
        </>)}
        name="rolloverType"
      >
        <Select onSelect={changeRolloveType} value={rolloverType} disabled={hasUnlimitedDays || accrualCapRate > 0}>
          <Select.Option key="no" value={RolloverStatus.no}>
            <IntlMessages id="components.leavePolicyForm.noRollover" />
          </Select.Option>
          <Select.Option key="limited" value={RolloverStatus.limited}>
            <IntlMessages id="components.leavePolicyForm.limitedNumberOfDays" />
          </Select.Option>
          <Select.Option key="all" value={RolloverStatus.all}>
            <IntlMessages id="components.leavePolicyForm.allDays" />
          </Select.Option>
        </Select>
      </Form.Item>
      {!hasUnlimitedDays && rolloverType === 'limited' &&
        <Form.Item
          {...formFullWidthLayout}
          className='wrap-label'
          label={<IntlMessages id={hourlyLeaveAccounting ? 'components.leavePolicyForm.rolloverPerYearHours' : 'components.leavePolicyForm.rolloverPerYearDays'} />}
          name="maxRolloverDays"
          rules={[{ required: true, min: 1, type: 'number', message: <IntlMessages id="components.leavePolicyForm.required" /> }]}
        >
          <InputNumber<number>
            min={1}
            max={hourlyLeaveAccounting ? 2920 : 365}
            step={step}
            disabled={hasUnlimitedDays}
            onChange={(value) => onChangeMaxRolloverDays(value as number)}
          />
        </Form.Item>
      }
      {!hasUnlimitedDays && rolloverType !== 'no' && location &&
        <>
          <Form.Item
            className='wrap-label'
            label={(<>
              <IntlMessages id={hourlyLeaveAccounting ? 'location.rolloverExpiryHour' : 'location.rolloverExpiryDay'} />
              <Tooltip className="info-tooltip" title={<IntlMessages id="components.locationForm.rolloverExpiryDayInfo" />} ><InfoCircleOutlined /></Tooltip>
            </>)}
            valuePropName="checked"
            name="enableRolloverExpiry"
            {...formFullWidthLayout}
          >
            <Radio.Group
              disabled={accrualCapRate > 0}
              value={enableRolloverExpiry}
              buttonStyle="solid"
              options={[
                { label: <IntlMessages id="app.yes" />, value: true },
                { label: <IntlMessages id="app.no" />, value: false },
              ]}
              optionType="button"
              onChange={e => onChangeHandleRolloverExpiry(e.target.value as boolean)}
            />
          </Form.Item>
          {enableRolloverExpiry && location?.resetQuotas === 'FISCAL_YEAR' &&
            <Form.Item
              className='wrap-label'
              {...formFullWidthLayout}
              label={<IntlMessages id={hourlyLeaveAccounting ? 'location.rolloverExpiryOnDateHours' : 'location.rolloverExpiryOnDate'} />}
              style={{ marginBottom: 24 }}
              extra={<IntlMessages id={hourlyLeaveAccounting ? 'location.rolloverExpiryOnDateHours.extra' : 'location.rolloverExpiryOnDate.extra'} />}
            >
              <Form.Item name="rolloverExpiryMonth" style={{ display: 'inline-block', width: 140, marginBottom: 0 }}
                rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }, ({ getFieldValue }) => ({
                  validator(_, value) {
                    const numberOfDays = numberOfDaysInMonth(value as number)
                    const selectedExpiryDay = getFieldValue('rolloverExpiryDay')
                    if (selectedExpiryDay <= numberOfDays) {
                      return Promise.resolve()
                    }
                    form.setFieldsValue({ rolloverExpiryDay: 1})
                    return Promise.resolve()
                  },
                })]}>
                <Select<number> style={{ width: 130, marginRight: 10 }} onChange={(month) => onChangeMonthExpiryDay(month)}>
                  {months.map(month => <Select.Option key={month.value} value={month.value}><IntlMessages id={month.label} /></Select.Option>)}
                </Select>
              </Form.Item>
              <Form.Item name="rolloverExpiryDay" style={{ display: 'inline-block', width: 80, marginBottom: 0 }}
                rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}>
                <Select<number> style={{ width: 80 }} onChange={() => setShowApplyBroughtForwardSettingsToThePastYear(true)}>
                  {rolloverExpirySelectDays.map(day => <Select.Option key={day.value} value={day.value}>{day.label}</Select.Option>)}
                </Select>
              </Form.Item>
            </Form.Item>
          }
        </>
      }
      {!isAssignLeavePolicyForm && location?.resetQuotas === 'FISCAL_YEAR' && accrualCapRate === 0 && showApplyBroughtForwardSettingsToThePastYear &&
        <Form.Item
          name="applyBroughtForwardSettingsToThePastYear"
          rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
          label={<>
            <IntlMessages id="components.leavePolicyForm.whenWouldYouLikeToApplyTheseChanges" />
            <Tooltip className="info-tooltip" title={<IntlMessages id="components.leavePolicyForm.whenWouldYouLikeToApplyTheseChangesInfo" />} >
              <InfoCircleOutlined />
            </Tooltip>
          </>}
          {...formFullWidthLayout}
        >
          <Select style={{ width: 320 }}>
            <Select.Option key="false" value={false}><IntlMessages id="components.leavePolicyForm.futureBroughtForward" /></Select.Option>
            <Select.Option key="true" value={true}><IntlMessages id="components.leavePolicyForm.previouesBroughForwardAndFutureOnce" /></Select.Option>
          </Select>
        </Form.Item>
      }
      {!hasUnlimitedDays && rolloverType !== 'no' && location &&
        <>
          {enableRolloverExpiry && location?.resetQuotas === 'USER_YEAR' &&
            <Form.Item
              className='wrap-label'
              label={(<>
                <IntlMessages id={hourlyLeaveAccounting ? 'location.rolloverExpiryAfterHours' : 'location.rolloverExpiryAfterDays'} />
                <Tooltip className="info-tooltip" title={<IntlMessages id="components.locationForm.daysInfo" />} ><InfoCircleOutlined /></Tooltip>
              </>)}
              extra={<IntlMessages id="components.locationForm.daysAfterEmploymentDate" />}
              name="rolloverExpiryAfterDays"
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              {...formFullWidthLayout}>
              <InputNumber onChange={(value) => onChangeRolloverExpiryAfterDays(value as number)} style={{ width: 70 }} min={0} max={364} />
            </Form.Item>
          }
        </>
      }
      <Divider style={isCreateLeaveTypeForm ? {opacity: 0.5, textTransform: 'capitalize'} : {textTransform: 'capitalize'}}><IntlMessages id="app.timeOffInLieu" />
        {authCompany?.plan === 'Core' && !hasToilFeatureFlag && <Tooltip className='info-tooltip' title={<IntlMessages id="components.toil.featurePlan" />}>
          <InfoCircleOutlined />
        </Tooltip>}
      </Divider>
      <Form.Item
        className='wrap-label'
        label={(<>
          <IntlMessages id='components.toil.enableLabel' />
          <Tooltip className="info-tooltip" title={<IntlMessages id='components.toil.enableLabelTooltip' />} ><InfoCircleOutlined /></Tooltip>
        </>)}
        valuePropName="checked"
        name="toil"
        {...formFullWidthLayout}
        style={isCreateLeaveTypeForm ? {opacity: 0.5} : {}}
      >
        <Radio.Group
          value={toil}
          buttonStyle="solid"
          disabled={isCreateLeaveTypeForm || (authCompany?.plan === 'Core' && !hasToilFeatureFlag)}
          options={[
            { label: <IntlMessages id="app.yes" />, value: true },
            { label: <IntlMessages id="app.no" />, value: false },
          ]}
          onChange={onToggleToil}
          optionType="button"
        />
      </Form.Item>
      {isCreateLeaveTypeForm &&
        <Form.Item
          wrapperCol={{ span: 24 }}
        >
          <Text type='secondary'>
            <IntlMessages id="components.leavePolicyForm.createLeaveTypeFormToilInfo1" />
            &nbsp;{authCompany?.plan === 'Core' && <IntlMessages id="app.feature.access" />}
          </Text>
        </Form.Item>
      }
      {(authCompany?.plan === 'Complete' || hasToilFeatureFlag) && toil && <>
        <Form.Item
          className='wrap-label'
          label={<>
            <IntlMessages id="components.toil.durationLabel" />
            <Tooltip className="info-tooltip" title={<IntlMessages id='components.toil.durationLabelTooltip' />} ><InfoCircleOutlined /></Tooltip>
          </>}
          name="toilShortestInterval"
          {...formFullWidthLayout}
        >
          <Select key="toilShortestInterval">
            {shortestLeaveIntervals.map(period =>
              <Select.Option key={period} value={period}>
                <IntlMessages id={`app.${period.toLowerCase()}`} />
              </Select.Option>
            )}
          </Select>
        </Form.Item>
        <Form.Item
          className='wrap-label'
          label={<>
            <IntlMessages id="components.toil.requester" />
            <Tooltip className="info-tooltip" title={<IntlMessages id='components.toil.requesterTooltip' />} ><InfoCircleOutlined /></Tooltip>
          </>}
          name="toilRequestsAllowedForUsers"
          {...formFullWidthLayout}
        >
          <Select key="toilRequestsAllowedForUsers">
            <Select.Option key={'true'} value={true}><IntlMessages id={'components.toil.requesterAll'} /></Select.Option>
            <Select.Option key={'false'} value={false}><IntlMessages id={'components.toil.requesterAdmin'} /></Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          className='wrap-label'
          label={(<>
            <IntlMessages id='components.toil.expirationLabel' />
            <Tooltip className="info-tooltip" title={<IntlMessages id='components.toil.expirationLabelTooltip' />} ><InfoCircleOutlined /></Tooltip>
          </>)}
          valuePropName="checked"
          name="toilExpiration"
          {...formFullWidthLayout}
        >
          <Radio.Group
            value={toilExpiration}
            buttonStyle="solid"
            options={[
              { label: <IntlMessages id="app.yes" />, value: true },
              { label: <IntlMessages id="app.no" />, value: false },
            ]}
            onChange={onToggleExpiration}
            optionType="button"
          />
        </Form.Item>
        {toilExpiration &&
          <Form.Item
            className='wrap-label'
            label={<IntlMessages id="components.toil.expiration" />}
            name="toilExpirationMonths"
            {...formFullWidthLayout}
          >
            <Select key="toilExpiration">
              <Select.Option key={'calendar-year'} value={'calendar-year'}><IntlMessages id={'components.toil.expirationDurationCalendar'} /></Select.Option>
              <Select.Option key={'1'} value={1}><IntlMessages id={'components.toil.expirationDuration'} values={{ noOfMonths: 1 }} /></Select.Option>
              <Select.Option key={'3'} value={3}><IntlMessages id={'components.toil.expirationDuration'} values={{ noOfMonths: 3 }} /></Select.Option>
              <Select.Option key={'6'} value={6}><IntlMessages id={'components.toil.expirationDuration'} values={{ noOfMonths: 6 }} /></Select.Option>
            </Select>
          </Form.Item>
        }
      </>}
    </>
  )
}
export default LeavePolicyForm
