import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'

import dayjs from 'dayjs'

import { Button, Col, Row, Select, Space, Spin, Table, Typography } from 'antd'

import { ImportHolidaysNavigation } from '../components'

import IntlMessages from '../../../../../util/IntlMessages'

import { dayOfWeekMap } from '../../../../../constants'

import { IImportHolidaysStepTwoFormProps, ImportHolidaysStepEnum } from '../types'
import { HolidayType, IGetHolidaysResponse } from '@vacationtracker/shared/types/holidays'

const { Text } = Typography
const { Option } = Select

interface IHoliday extends IGetHolidaysResponse {
  id: string
  checked: boolean
  show: boolean
}

const SelectHolidaysForm = ({
  setSteps,
  setSelectedHolidays,
  findHolidaysForCountry,
  year,
  country,
  state,
}: IImportHolidaysStepTwoFormProps): React.ReactElement => {
  const { formatMessage } = useIntl()
  
  const [allHolidays, setAllHolidays] = useState<IHoliday[]>()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>()
  const totalInvisibleHolidays = allHolidays?.reduce((count, item) => item.checked && !item.show ? count + 1 : count, 0) || 0

  useEffect(() => {
    setHolidays()
  }, [])

  const setHolidays = async () => {
    const holidays = await findHolidaysForCountry()
    const initialHolidays: IHoliday[] = holidays
      .map(holiday => ({ ...holiday, 
        checked: holiday.type as HolidayType === 'public',
        id: (Math.random() + 1).toString(36).substring(7),
        show: true,
      }))
    setAllHolidays(initialHolidays)
    setSelectedRowKeys(initialHolidays?.filter(holiday => holiday.checked).map(holiday => holiday.id))
  }

  const backToFirstStep = () => {
    setSteps(ImportHolidaysStepEnum.stepOne, 0)
  }

  const goToNextStep = () => {
    setSteps(ImportHolidaysStepEnum.stepThree, 2)
    setSelectedHolidays(allHolidays?.filter(holiday => holiday.checked) as unknown as IGetHolidaysResponse)
  }

  const filterHolidays = (last, filters) => {
    const selectedHolidayTypes = filters.map(item => item.key)
    const holidays = filters.length === 0 ? 
      allHolidays?.map(holiday => ({ ...holiday, show: true })) : 
      allHolidays?.map(holiday => ({
        ...holiday,
        show: selectedHolidayTypes.includes(holiday.type),
      }))
    setSelectedRowKeys(holidays?.filter(holiday => holiday.checked).map(holiday => holiday.id))
    setAllHolidays(holidays)
  }

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys: React.Key[]) => {      
      setSelectedRowKeys(selectedRowKeys)
    },
    onSelect: (record: IHoliday, selected: boolean) => {
      record.checked = selected
    },
    onSelectAll: (checked , selected) => {
      const selectedIds = selected.map(holiday => holiday.id)
      const holidays = allHolidays?.map(holiday => ({
        ...holiday,
        checked: selectedIds.includes(holiday.id),
      }))
      setAllHolidays(holidays)
    },
  }

  const columns = [
    {
      title: <IntlMessages id="holidays.holidaysName" />,
      dataIndex: 'name',
    },
    {
      title: <IntlMessages id="components.leavesColumns.dates" />,
      dataIndex: 'date',
      render: (date: string) => {
        const day = new Date(date).getDate()
        const monthName = dayjs().month(new Date(date).getMonth()).locale('en-US').format('MMMM').toLowerCase()
        return <><IntlMessages id={`app.${monthName}`} ></IntlMessages>{' '}{day}</>
      },
    },
    {
      title: <IntlMessages id="dashboard.days" />,
      dataIndex: 'date',
      render: (date: string) => {
        return <IntlMessages id={dayOfWeekMap.find((day) => day.value === new Date(date).getDay())?.name} />
      },
    },
    {
      title: <IntlMessages id="holidays.holidaysType" />,
      dataIndex: 'type',
      render: (type: string) => {return type.charAt(0).toUpperCase() + type.slice(1)},
    },
    {
      title: <IntlMessages id="app.note" />,
      dataIndex: 'note',
    },
  ]
  

  return <>
    <Row gutter={[8,24]} justify='space-between'>
      <Col xs='24' xl='8'>
        <Space>
          <div>
            <p><IntlMessages id="holidays.importHolidaysFor" values={{
              year: year,
              countryState: state ? `${state.name}, ${country.name}` : country.name,
              b: (...chunks) => (
                <Text strong>{chunks}</Text>
              ),
            }} /></p>
          </div>
          <Space><Button onClick={backToFirstStep} style={{ marginBottom: '15px'}}><IntlMessages id="app.change"/></Button></Space>
        </Space>
      </Col>
      <Col xs='24' xl='8'>
        <IntlMessages id="holidays.filterByType" />
        <Select
          style={{ marginLeft: 10, marginRight: 10, width: 200 }}
          onChange={filterHolidays}
          mode="multiple"
        >
          <Option key='public' value='public'><IntlMessages id={'holidays.typePublic'} /></Option>
          <Option key='bank' value='bank'><IntlMessages id={'holidays.typeBank'} /></Option>
          <Option key='school' value='school'><IntlMessages id={'holidays.typeSchool'} /></Option>
          <Option key='optional' value='optional'><IntlMessages id={'holidays.typeOptional'} /></Option>
          <Option key='observance' value='observance'><IntlMessages id={'holidays.typeObservance'} /></Option>
        </Select>
      </Col>
    </Row>
    <Table 
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      loading={{ indicator: <div><Spin /></div>, spinning: allHolidays === undefined}} 
      dataSource={allHolidays?.filter(holiday => holiday.show)}
      columns={columns}
      rowKey={'id'}
      scroll={{x: 400}}
      pagination={{ defaultPageSize: 30, pageSizeOptions: [20,30,50,100] }}
    />
    <div style={{ marginBottom: 20 }}>
      <IntlMessages
        id="holidays.totalSelectedHolidays"
        values={{ 
          total: allHolidays?.reduce((count, item) => item.checked ? count + 1 : count, 0),
        }}
      />&nbsp;
      {totalInvisibleHolidays > 0 &&
      <IntlMessages
        id="holidays.totalSelectedHolidaysTip"
        values={{ 
          total: totalInvisibleHolidays,
        }}
      />}
    </div>
    <ImportHolidaysNavigation
      stepComplete={Boolean(selectedRowKeys && selectedRowKeys?.length > 0)}
      handleNext={goToNextStep}
      handleBack={backToFirstStep}
      nextButtonTitle={formatMessage({ id: 'app.next' })}
    /></>
}

export default SelectHolidaysForm