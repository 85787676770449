import React, { useContext, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { App, Button, Card, Form, Input, Modal, Row, Typography } from 'antd'
import { CommentOutlined, LoadingOutlined } from '@ant-design/icons'

import Api from '@vacationtracker/shared/services/api'
import AddonsCard from '../addons-card'
import RequestAddon from '../request-addon'
import { notificationStore } from '../../context/notificationsContext/store'
import { useAppSelector } from '../../store/hooks'
import { isTrial, selectAuthCompanySlice } from '../../store/auth-company-slice'
import { track } from '../../services/analytics/analytics'
import IntlMessages from '../../util/IntlMessages'

import { FrontendUrls } from '../../types/urls'
import { AddonsStatusEnum, IAddon } from '@vacationtracker/shared/types/addons'
import { IAddonsData } from '@vacationtracker/shared/types/automations'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { IUpcomingInvoiceInformation } from '@vacationtracker/shared/types/stripe'
import SubscribeToAddons from '../subscribe-to-addons'

const { TextArea } = Input

interface IChoiceAddons {
  subscribedAddons: IAddon[]
  allSubscribedAddons: IAddon[]
  addonsEvent: IAddonsData[]
  formatMessage: Function
  amIAdmin: boolean
  userCount: number
  addonsPrice: number
}

const ChoiceAddons = ({
  subscribedAddons,
  allSubscribedAddons,
  addonsEvent,
  formatMessage,
  amIAdmin,
  userCount,
  addonsPrice,
}: IChoiceAddons) => {
  const history = useHistory()
  const [form] = Form.useForm()
  const { notification } = App.useApp()

  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const isTrialSubscription = useSelector(isTrial)
  
  const [showRequestAddon, setShowRequestAddon] = useState(false)
  const [scheduleACall, setScheduleACall] = useState(false)
  const [selectedScheludeACallType, setSelectedScheludeACallType] = useState<string | undefined>()
  const [isSending, setSending] = useState<boolean>(false)
  const [visibleSubscribeAddon, setVisibleSubscribeAddon] = useState<boolean>(false)
  const [visibleSubscribeFreeAddon, setVisibleSubscribeFreeAddon] = useState<boolean>(false)
  const [selectedAddonType, setSelectedAddonType] = useState<string | null>()
  const [upcomingInvoices, setUpcomingInvoices] = useState<IUpcomingInvoiceInformation | null>(null)
  const [isLoadingButtonAddon, setIsLoadingButtonAddon] = useState<boolean>(false)
  const [correlationId, setCorrelationId] = useState<string | null>()

  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  
  useEffect(() => {
    if (correlationId && !actionNotifications.includes(correlationId) && isLoadingButtonAddon) {
      setVisibleSubscribeAddon(false)
      setVisibleSubscribeFreeAddon(false)
      setIsLoadingButtonAddon(false)
      setCorrelationId(null)
      // if selected addon is visibility, redirect to view page
      history.push(
        `${FrontendUrls.addons}/${selectedAddonType
          ?.toLowerCase()
          .replace(/_/g, '-')}/${
          selectedAddonType === 'VISIBILITY' ? 'view' : 'create'
        }`
      )
    }
  }, [actionNotifications])

  const sendScheduleACall = async (selectedScheludeACallType) => {
    await form.validateFields().then(async value => {
      try {
        setSending(true)
        track('AUTOMATION_COMING_SOON_FORM_SUBMITTED', {})
        await Api.post('/core/event', {
          ...value,
          eventGroup: 'FEEDBACK',
          eventType: 'SCHEDULE_A_CALL_AUTOMATION_FEEDBACK',
          addonsType: selectedScheludeACallType,
        })
        setScheduleACall(false)
        setSelectedScheludeACallType(undefined)
        notification.success({
          message: formatMessage({ id: 'automation.feedback.thankYou' }),
          icon: <CommentOutlined />,
        })
        form.resetFields()
        setSending(false)
      } catch (error) {
        setSending(false)
        const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
        notification.error({
          message: formatMessage({ id: 'error.generic' }),
          description: errorDescription,
          duration: 0,
        })
      }
    })
  }

  const onSelectAddon = async (url: string, parent: string, type: string, allowViewMode: boolean) => {
    if (url === 'coming-soon') {
      setScheduleACall(true)
      setSelectedScheludeACallType(type)
      return
    }

    if (allowViewMode) {
      history.push(`/app/add-ons/${url}/view`)
      return
    }

    if (type === 'ENTITLEMENT_BY_ROLE' && authCompany?.plan === SubscriptionPlanEnum.core) {
      history.push('/app/add-ons/entitlement-by-role/create')
      return
    }

    setSelectedAddonType(type)

    const addons = addonsEvent.find(addon => addon.type === type)
    if (addons?.includedIn && addons?.includedIn.length > 0 && addons?.includedIn.includes(authCompany?.plan as SubscriptionPlanEnum)) {
      setVisibleSubscribeFreeAddon(true)
      return
    }

    setVisibleSubscribeAddon(true)
    try {
      const info = await Api.post('/stripe/upcoming-invoice-information', {
        additionalProductName: parent.toUpperCase(),
        date: Math.ceil(new Date().getTime() / 1000),
      })
      setUpcomingInvoices(info as IUpcomingInvoiceInformation)
    } catch (e) {
      return
    }
  }

  const automationAddonsUpdate = async () => {
    if (!amIAdmin) return
    try {
      setIsLoadingButtonAddon(true)
      const response = await Api.post('/core/event', {
        eventType: 'ADDONS_UPDATED',
        eventGroup: 'BILLING',
        type: selectedAddonType,
        isActive: true,
      })

      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'automations.activateInProgress' }),
        description: formatMessage({ id: 'automations.activateInProgressDescription' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])

      setCorrelationId(response.correlationId as string)

      if (allSubscribedAddons.find(addon => addon.type === selectedAddonType)?.status === 'CANCELED') {
        track('ADDON_RESUBSCRIBED', { addonName: selectedAddonType })
      } else {
        track('ADDON_SUBSCRIBED', { addonName: selectedAddonType })
      }
    } catch (error) {
      showErrorNotification(error)
      setIsLoadingButtonAddon(false)
    }
  }

  const showErrorNotification = (error) => {
    const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: formatMessage({ id: 'error.generic' }),
      description: errorDescription,
      duration: 0,
    })
  }
  
  return (
    <>
      <div style={{ marginBottom: 30 }}>
        <Typography.Text style={{ fontWeight: 700, display: 'block' }}><IntlMessages id="choiceAutomations.notActivatedAddons" /></Typography.Text>
        <Typography.Text style={{ marginBottom: 20, display: 'block' }}><IntlMessages id="choiceAutomations.automationPriceInfo" /></Typography.Text>
      </div>

      <Row>
        {addonsEvent.map(addon => {
          if (subscribedAddons.find(subscribedAddon => subscribedAddon.type === addon.type)) return ''
          return (
            <AddonsCard
              viewMode={false}
              status={AddonsStatusEnum.BUY}
              key={addon.type}
              type={addon.type}
              parent={addon.parent}
              parentLabel={addon.parentLabel}
              roles={addon.roles}
              url={addon.url}
              icon={addon.icon}
              description={addon.description}
              onSelectAddon={(url: string, parent: string, type: string, allowViewMode: boolean) => {
                onSelectAddon(url, parent, type, allowViewMode)
              }}
              addonsPrice={addonsPrice}
              helpDesklink={addon.helpDesklink}
              isTrialSubscription={isTrialSubscription}
              includedIn={addon.includedIn}
              subscriptionPlan={authCompany?.plan as SubscriptionPlanEnum}
              allowViewMode={addon.allowViewMode || false}
            />
          )
        })}
        <Card
          key={'MISSING_AUTOMATION'}
          title={<IntlMessages id={'automations.requestAutomationTitle'} />}
          size="small"
          className="request-automation"
          style={{ width: 400, marginBottom: 30, marginRight: 30, display: 'inline-block' }}
        >
          <Button type="primary" size="large" onClick={() => {
            setShowRequestAddon(true)
            track('AUTOMATION_REQUEST_FORM_VIEWED',{})
          }}>
            <IntlMessages id={'automations.requestAnAutomation'} />
          </Button>
        </Card>
      </Row>

      <RequestAddon
        visible={showRequestAddon}
        onCancel={() => { setShowRequestAddon(false) }}
      />
      <Modal
        title={<IntlMessages id="choiceAutomations.comingSoonScheduleCallTitle" />}
        open={scheduleACall}
        onCancel={() => {
          setScheduleACall(false)
          setSelectedScheludeACallType(undefined)
        }}
        onOk={() => {
          sendScheduleACall(selectedScheludeACallType)
        }}
        okText={<IntlMessages id="app.submit" />}
        cancelText={<IntlMessages id="app.close" />}
        confirmLoading={isSending}
        style={{ top: 20 }}
      >
        <Form
          key="scheduleACall"
          form={form}
          name="scheduleACall"
          layout='vertical'
        >
          <IntlMessages id="choiceAutomations.comingSoonScheduleCall" />
          <Form.Item
            name="name"
            rules={[
              { required: true, message: <IntlMessages id="error.fieldIsRequired" /> },
            ]}
            style={{ marginTop: '20px' }}
            label={<IntlMessages id="components.feedbackModal.yourName" />}
          >
            <Input type="email" placeholder={formatMessage({ id: 'components.feedbackModal.namePlaceholder' })} />
          </Form.Item>
          <Form.Item
            name="email"
            rules={[
              { required: true, message: <IntlMessages id="error.fieldIsRequired" /> },
            ]}
            style={{ marginTop: '20px' }}
            label={<IntlMessages id="components.feedbackModal.yourEmail" />}
          >
            <Input type="email" placeholder={formatMessage({ id: 'components.billing.emailPlaceholder' })} />
          </Form.Item>
          <Form.Item
            name="note"
            style={{ marginTop: '20px' }}
            label={<IntlMessages id="app.note" />}
          >
            <TextArea rows={5}/>
          </Form.Item>
        </Form>
      </Modal>
      
      <SubscribeToAddons
        visibleSubscribeAddon={visibleSubscribeAddon}
        amIAdmin={amIAdmin}
        onCancel={() => { 
          setVisibleSubscribeAddon(false)
          setSelectedAddonType(null)
        }}
        handleSubscribeToAddons={() => {
          if (amIAdmin) {
            automationAddonsUpdate()
            setVisibleSubscribeAddon(false)
          } else {
            setVisibleSubscribeAddon(false)
          }
        }}
        isLoadingButtonAddon={isLoadingButtonAddon}
        isTrialSubscription={isTrialSubscription}
        addonsPrice={addonsPrice}
        userCount={userCount}
        upcomingInvoices={upcomingInvoices}
      />

      <Modal
        title={<IntlMessages id="automations.subscripteToAddons" />}
        open={visibleSubscribeFreeAddon}
        onCancel={() => {
          setVisibleSubscribeFreeAddon(false)
          setSelectedAddonType(null)
        }}
        onOk={() => {
          if (amIAdmin) {
            automationAddonsUpdate()
            setVisibleSubscribeFreeAddon(false)
          } else {
            setVisibleSubscribeFreeAddon(false)
          }
        }}
        okText={amIAdmin ? <IntlMessages id="app.confirm" /> : <IntlMessages id="app.ok" />}
        cancelText={<IntlMessages id="app.close" />}
        confirmLoading={isLoadingButtonAddon}
      >
        <>
          {amIAdmin && <IntlMessages id="automations.addFreeAddonsDescirptionForAdmin" />}
          {!amIAdmin && <IntlMessages id="automations.addAddonsDescirptionForApprover" />}
        </>
      </Modal>
    </>
  )
}

export default ChoiceAddons
