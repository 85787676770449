/* eslint-disable max-len */
import React from 'react'

export const TimelineVert = () => (<svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500"><path d="M316 256c0 28-19 49-45 58l0 61c26 7 45 30 45 58 0 32-28 58-60 58-32 0-58-26-58-60 0-28 20-49 45-58l0-62c-25-6-45-29-45-57 0-28 20-49 45-58l0-62c-25-6-45-29-45-57 0-32 26-60 60-60 34 0 60 26 60 60 0 28-19 49-45 57l0 62c24 11 43 32 43 60z m-94 175c0 19 15 34 34 34 19 0 34-15 34-34 0-19-15-34-34-34-19 0-34 17-34 34z m0-175c0 19 15 34 34 34 19 0 34-15 34-34 0-19-15-34-34-34-19 0-34 15-34 34z m68-177c0-19-15-34-34-34-19 0-34 15-34 34 0 19 15 34 34 34 19 0 34-15 34-34z" /></svg>)

export const Notification = () => (<svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500" transform="rotate(180)"><path d="M452 102l-388 0c-6 0-13 5-15 9-2 6-2 13 2 17l28 34 0 167c0 98 81 179 179 179 98 0 179-81 179-179l0-165 28-34c4-4 4-11 2-17-4-6-8-11-15-11z m-356 32l322 0-11 15c-2 2-4 7-4 11l0 169c0 81-66 147-147 147-81 0-147-66-147-147l0-169c0-4-2-6-4-11z m162-125c-36 0-68 17-89 44-5 7-5 17 4 22 6 4 17 4 21-5 15-21 39-32 64-32 26 0 49 13 64 32 4 7 15 9 21 5 7-5 9-15 5-22-22-27-56-44-90-44z" /></svg>)

export const GoogleLogo = () => (<svg viewBox="0 0 32.273 32.167" xmlns="http://www.w3.org/2000/svg">
  <defs>
    <filter x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox" id="filter-1">
      <feOffset dx="0" dy="1" in="SourceAlpha" result="shadowOffsetOuter1"/>
      <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter1" result="shadowBlurOuter1"/>
      <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.168 0" in="shadowBlurOuter1" type="matrix" result="shadowMatrixOuter1"/>
      <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter2"/>
      <feGaussianBlur stdDeviation="0.5" in="shadowOffsetOuter2" result="shadowBlurOuter2"/>
      <feColorMatrix values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.084 0" in="shadowBlurOuter2" type="matrix" result="shadowMatrixOuter2"/>
      <feMerge>
        <feMergeNode in="shadowMatrixOuter1"/>
        <feMergeNode in="shadowMatrixOuter2"/>
        <feMergeNode in="SourceGraphic"/>
      </feMerge>
    </filter>
  </defs>
  <g id="Google-Button" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
    <g id="9-PATCH" transform="translate(-608.000000, -160.000000)"/>
    <g id="btn_google_light_normal" transform="translate(-1.000000, -1.000000)">
      <g id="button" transform="translate(4.000000, 4.000000)" filter="url(#filter-1)">
        <g id="button-bg">
          <rect x="-2.401" y="-2.442" width="30.867" height="30.867" fill="#FFFFFF" fillRule="evenodd"/>
          <rect x="0" y="0" width="40" height="40" rx="2" transform="matrix(1, 0, 0, 1, 0, 0)" fill="none"/>
          <rect x="0" y="0" width="40" height="40" rx="2" transform="matrix(1, 0, 0, 1, 0, 0)" fill="none"/>
          <rect x="0" y="0" width="40" height="40" transform="matrix(1, 0, 0, 1, 0, 0)" fill="none" rx="15.153" ry="15.153"/>
          <path d="M 21.355 13.205 C 21.355 12.566 21.298 11.953 21.191 11.364 L 12.715 11.364 L 12.715 14.845 L 17.559 14.845 C 17.35 15.97 16.716 16.923 15.763 17.561 L 15.763 19.82 L 18.671 19.82 C 20.373 18.253 21.355 15.945 21.355 13.205 Z" id="path-1" fill="#4285F4"/>
          <path d="M 12.715 22 C 15.145 22 17.182 21.194 18.671 19.82 L 15.763 17.561 C 14.957 18.101 13.926 18.42 12.715 18.42 C 10.371 18.42 8.387 16.837 7.679 14.71 L 4.672 14.71 L 4.672 17.042 C 6.153 19.983 9.197 22 12.715 22 Z" id="path-1" fill="#34A853"/>
          <path d="M 7.679 14.71 C 7.499 14.17 7.397 13.593 7.397 13 C 7.397 12.407 7.499 11.83 7.679 11.29 L 7.679 8.958 L 4.672 8.958 C 4.063 10.173 3.715 11.548 3.715 13 C 3.715 14.452 4.063 15.827 4.672 17.042 L 7.679 14.71 Z" id="path-1" fill="#FBBC05"/>
          <path d="M 12.715 7.58 C 14.036 7.58 15.223 8.034 16.155 8.925 L 18.737 6.344 C 17.178 4.892 15.141 4 12.715 4 C 9.197 4 6.153 6.017 4.672 8.958 L 7.679 11.29 C 8.387 9.163 10.371 7.58 12.715 7.58 Z" id="path-1" fill="#EA4335"/>
        </g>
      </g>
      <g id="logo_googleg_48dp" transform="translate(15.000000, 15.000000)">
        <path d="M0,0 L18,0 L18,18 L0,18 L0,0 Z" id="Shape"/>
      </g>
      <g id="handles_square"/>
    </g>
  </g>
</svg>)