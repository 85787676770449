import React from 'react'
import { IConnect } from './types'
import SignupA from './signupA'
// import { Col, Row, Skeleton } from 'antd'
// import axios from 'axios'
// import { getSignupFlowByIp } from './signup-flow-strategy'

const Connect = ({ location }: IConnect): React.ReactElement => {

  // Currently commented out, for later if we decide to do A/B testing for signup.
  // const [flow, setFlow] = useState<SignupFlowType | undefined>()
  // const flowStorageKey = 'signup-flow'

  // useEffect(() => {
  //   async function setSignupFlow() {
  //     let signupFlow = localStorage.getItem(flowStorageKey)
  //     if (!signupFlow) {
  //       const ipAddressResponse = await axios.get(`${process.env.REACT_APP_API_URL}/core/ping`)
  //       signupFlow = getSignupFlowByIp(ipAddressResponse.data.ip as string)
  //       localStorage.setItem(flowStorageKey, signupFlow)
  //     }
  //     setFlow(signupFlow as SignupFlowType)
  //   }
  //   setSignupFlow()
  // }, [])

  // const getSkeletonWrapper = () => {
  //   return (
  //     <>
  //       <Skeleton paragraph={false} style={{ width: '240px', margin: '0 auto', padding: '30px 0' }} />
  //       <Row gutter={[8,24]} justify='space-between'>
  //         <Col flex={'1 1 50%'} xs='24' xl='8'><Skeleton paragraph={false} style={{ width: '90%', margin: '0 auto' }} /></Col>
  //         <Col flex={'1 1 50%'} xs='24' xl='8'><Skeleton paragraph={false} style={{ width: '90%', margin: '0 auto' }} /></Col>
  //         <Col flex={'1 1 50%'} xs='24' xl='8'><Skeleton title={{style: {height: '30px'}}} paragraph={false} style={{ width: '90%', margin: '0 auto' }} /></Col>
  //         <Col flex={'1 1 50%'} xs='24' xl='8'><Skeleton title={{style: {height: '30px'}}} paragraph={false} style={{ width: '90%', margin: '0 auto' }} /></Col>
  //         <Col flex={'1 1 50%'} xs='24' xl='8'><Skeleton title={{style: {height: '30px'}}} paragraph={false} style={{ width: '90%', margin: '0 auto' }} /></Col>
  //         <Col flex={'1 1 50%'} xs='24' xl='8'><Skeleton title={{style: {height: '30px'}}} paragraph={false} style={{ width: '90%', margin: '0 auto' }} /></Col>
  //         <Col flex={'1 1 50%'} xs='24' xl='8'><Skeleton paragraph={false} style={{ width: '90%', margin: '0 auto' }} /></Col>
  //         <Col flex={'1 1 50%'} xs='24' xl='8'><Skeleton paragraph={false} style={{ width: '90%', margin: '0 auto' }} /></Col>
  //       </Row>
  //       <Skeleton paragraph={false} style={{ width: '240px', margin: '0 auto', padding: '30px 0' }}>
  //       </Skeleton>
  //     </>
  //   )
  // }

  return (
    <>
      {/* {!flow && getSkeletonWrapper()}
      {flow && <SignupA location={location}></SignupA>} */}
      <SignupA location={location} ></SignupA>
    </>
  )
}

export default Connect