import React from 'react'
import { Avatar, Tooltip } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'

interface IUserAvatar {
  id: string
  avatar?: string
  name: string
  avatarSize?: number | 'small' | 'default' | 'large'
  isShowingName?: boolean
  isLinkToPage?: boolean
  isTooltip?: boolean
  shape: 'square' | 'circle'
  style?: React.CSSProperties
  className?: string
}

const legacyImageBaseUrl = process.env.REACT_APP_LEGACY_IMAGE_BASE_URL
const cdnImageBaseUrl = process.env.REACT_APP_CDN_IMAGE_BASE_URL

export const UserAvatar = ({ id, avatar, name, avatarSize, isShowingName, isLinkToPage, isTooltip, shape, style, className}: IUserAvatar): React.ReactElement => {
  let avatarUrl = (legacyImageBaseUrl && cdnImageBaseUrl && avatar) ? avatar.replace(legacyImageBaseUrl, cdnImageBaseUrl) : avatar

  if (avatarUrl && !avatarUrl.includes('https')) {
    avatarUrl = `https://${avatarUrl}`
  }

  if (avatarUrl && !avatarUrl.includes(id) && avatarUrl.includes('cloudfront')) {
    avatarUrl = `${avatarUrl}/${id}`
  }

  const avatarData = <Avatar key={id} size={avatarSize} icon={<UserOutlined />} src={avatarUrl} shape={shape || 'circle'} style={style} />

  let result = avatarData

  if (isShowingName) {
    const nameData = <>{result} <span className="name">{name}</span></>
    result = nameData
  }

  if (isLinkToPage) {
    const linkData = <Link to={`/app/users/${id}`}>{result}</Link>
    result = linkData
  }

  if (isTooltip && name) {
    const tooltipData = <Tooltip title={name} key={id}>{result}</Tooltip>
    result = tooltipData
  }

  return <div className={className}>{result}</div>
}
