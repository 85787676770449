{
  "accountSettings.connectYourPersonalCalendar": "Connetti il tuo calendario personale",
  "accountSettings.connectYourPersonalCalendar.description": "Connetti automaticamente il tuo calendario personale alle assenze. Quando richiedi un'assenza, puoi impostare se vuoi che l'assenza richiesta appaia come disponibile o occupato nel tuo calendario. Visita il nostro <helpdesk>Helpdesk</helpdesk> per maggiori informazioni.",
  "actionNotifications.companyAnnouncement.success": "Annuncio inviato",
  "actionNotifications.leave.editedRequestDenied": "Modifica della richiesta di assenza negata.",
  "actionNotifications.leave.editedRequestDeniedDescription": "La tua richiesta di assenza modificata è stata negata, tuttavia, la tua richiesta originale rimane attiva.",
  "actionNotifications.leave.requestApproved": "La richiesta di assenza è stata approvata",
  "actionNotifications.leave.requestDenied": "La richiesta di assenza è stata negata.",
  "actionNotifications.leaveType.created": "Il tipo di assenza {leaveTypeName} è stato creato",
  "actionNotifications.leaveType.deleted": "Il tipo di assenza è stato eliminato",
  "actionNotifications.leaveType.updated": "Il tipo di assenza {leaveTypeName} è stato aggiornato",
  "actionNotifications.location.createdTitle": "La sede {locationName} è stata creata",
  "actionNotifications.location.deletedTitle": "La sede è stata eliminata",
  "actionNotifications.location.updatedTitle": "La sede {locationName} è stata aggiornata",
  "actionNotifications.location.view": "Visualizza sede",
  "actionNotifications.notification.created": "La notifica è stata creata",
  "actionNotifications.notification.deleted": "La notifica è stata eliminata",
  "actionNotifications.notification.updated": "La notifica è stata aggiornata",
  "actionNotifications.outOfOfficeSetting.success": "La tua impostazione di fuori ufficio è stata aggiornata",
  "actionNotifications.scheduledReport.createdTitle": "Il report programmato {scheduledReportName} è stato creato",
  "actionNotifications.scheduledReport.deletedTitle": "Il report programmato è stato eliminato",
  "actionNotifications.scheduledReport.updatedTitle": "Il report programmato {scheduledReportName} è stato aggiornato",
  "actionNotifications.scheduledReport.viewScheduledReport": "Visualizza report programmato",
  "actionNotifications.team.createdTitle": "Il dipartimento {teamName} è stato creato",
  "actionNotifications.team.deletedTitle": "Il dipartimento è stato eliminato",
  "actionNotifications.team.updatedTitle": "Il dipartimento {teamName} è stato aggiornato",
  "actionNotifications.team.view": "Visualizza dipartimento",
  "addLeave.addLeave": "Aggiungi assenza",
  "addLeave.handleSubmitErrorTitle": "Errore nell'invio dell'assenza",
  "addLeave.inProgress": "Aggiunta assenza in corso",
  "addLeave.noApproverUsers": "Se non fai parte di un dipartimento, non potrai aggiungere assenze per te stesso. Per richiedere un'assenza anche se non fai parte di un dipartimento per cui hai diritti di approvazione, apri il modulo \"Richiedi assenza\" e invia una richiesta di assenza.",
  "addLeave.title": "Assenza aggiunta",
  "addons.openApi.apiKeyName": "Nome della chiave API",
  "addons.openApi.apiKeyNamePlaceholder": "Nome della chiave API",
  "addons.openApi.apiKeySecret": "Segreto della chiave API",
  "addons.openApi.apiKeysInfo": "Genera e gestisci le tue chiavi API.",
  "addons.openApi.apiKeysTitle": "Chiavi API",
  "addons.openApi.buyAppiAddon": "Acquista l'add-on API",
  "addons.openApi.buyOpenApiInfoPrice": "L'add-on API è un add-on a pagamento che costa ${price}/mese/utente attivo. L'acquisto aumenterà la tua fattura mensile di ${totalPaid} per {userCount, plural, =1 {il} other {i}} tuoi {userCount} {userCount, plural, =1 {utente attivo} other {utenti attivi}} (ti daremo una stima precisa quando clicchi sul pulsante \"Acquista\").",
  "addons.openApi.buyOpenApiInfoPrice2line": "Dopo l'acquisto dell'add-on, tu o qualsiasi altro amministratore nella tua organizzazione potrete generare e gestire la vostra chiave API.",
  "addons.openApi.buyOpenApiTitle": "Acquista l'add-on API per generare chiavi API",
  "addons.openApi.createInProgress": "Creazione della chiave API in corso",
  "addons.openApi.createInProgressCompleted": "Creazione della chiave API completata",
  "addons.openApi.createNewApiKey": "Crea una nuova chiave API",
  "addons.openApi.currentMonthUsage": "Utilizzo del mese corrente",
  "addons.openApi.documentation": "Documentazione API",
  "addons.openApi.documentationInfo": "La documentazione API descrive come funziona la nostra API e quali metodi sono attualmente disponibili.",
  "addons.openApi.dontHaveApiKeys": "Non hai chiavi API, clicca sul pulsante qui sotto per crearne una",
  "addons.openApi.dpenDocumentation": "Apri documentazione",
  "addons.openApi.feedback": "Se hai feedback o hai bisogno di dati che la nostra API non espone, <link>clicca qui per farcelo sapere</link>.",
  "addons.openApi.limitRequestPerMonth": "Puoi inviare fino a 30000 richieste al mese",
  "addons.openApi.limitRequestPerSecound": "Le richieste sono limitate a 10 al secondo con una modalità burst di 50 richieste/secondo",
  "addons.openApi.notificationDeleteConfirm": "Conferma che vuoi eliminare la chiave API <strong>{name}</strong>.",
  "addons.openApi.notificationDeleteTitle": "Elimina chiave API",
  "addons.openApi.openApiDeleted": "Chiave API aperta eliminata",
  "addons.openApi.quotasAndRateLimitsInfo": "Ecco le nostre quote API e limiti di velocità per chiave API:",
  "addons.openApi.quotasAndRateLimitsTitle": "Quote e limiti di velocità",
  "addons.openApiInfo": "Il nostro add-on API ti permette di connettere Vacation Tracker ad altre applicazioni programmaticamente. Attualmente, la nostra API ti consente l'accesso in sola lettura ai tuoi utenti, le loro assenze, le sedi, i dipartimenti, i tipi di assenza e le etichette.",
  "addonsPaymentStatus.activateForFree": "Attiva gratuitamente",
  "addonsPaymentStatus.activeUntilBillingPeriodEnds": "Attivo fino alla fine del periodo di fatturazione",
  "addonsPaymentStatus.canceled": "Annullato",
  "addonsPaymentStatus.grantPeriod": "Periodo di concessione",
  "addonsPaymentStatus.paymentActive": "Pagamento attivo",
  "ai.assistant.completedConversationTooltip": "Questa conversazione è completata. Non puoi inviare nuovi messaggi, ma puoi vedere la conversazione esistente.",
  "ai.assistant.defaultMessage": "Come posso aiutarti?",
  "ai.assistant.disclaimer": "L'Assistente AI è in versione Beta. Può commettere errori. In caso di errori o risposte confuse, per favore <support>contatta il nostro supporto</support>.",
  "ai.assistant.inputLockedTooltip": "Questa è una versione iniziale del nostro Assistente AI. Attualmente, ti aiuta solo con l'onboarding. Tuttavia, presto potrai avviare nuove conversazioni e parlare con l'Assistente AI di Vacation Tracker. Grazie per la tua pazienza!",
  "ai.assistant.lastMessageTimestampTooltip": "L'ultimo messaggio è stato inviato il: {date}",
  "ai.assisted.onboarding.companyLogUserNameDecorator": "(tramite Assistente AI)",
  "ai.assisted.onboarding.finalAssistantMessage": "Il tuo processo di onboarding è completo. Vacation Tracker configurerà ora il tuo account aziendale con questa configurazione. Ricorda, puoi sempre apportare modifiche alla tua configurazione in seguito, se necessario.",
  "ai.assisted.onboarding.initialAssistantMessage": "Mentre ti stavi registrando, abbiamo già configurato alcune cose per te:\n\nSedi ({locations}), \nDipartimenti ({departments}), e \nTipi di assenza ({leaveTypes}).\n\nTutti i tuoi dipendenti lavorano in questa sede?",
  "ai.assisted.onboarding.initialUserMessage": "Ciao! Sono il tuo Assistente AI. Sono qui per aiutarti a iniziare con il tuo nuovo account aziendale.\n\nQuesta configurazione AI è ovviamente opzionale. Puoi saltarla e configurare il sistema da solo. (Probabilmente vuoi iniziare dalla sezione \"Impostazioni\" del nostro menu). Tuttavia, ti guiderò attraverso alcune parti essenziali di Vacation Tracker e configurerò tutto per te in pochi minuti. Non memorizziamo nessuno dei tuoi dati privati (ma per favore non condividere informazioni sensibili con me).",
  "ai.assisted.onboarding.noTopics": "Ehi, sembra che tu abbia appena trovato un Easter egg! 🐣 Stiamo lavorando a qualcosa di nuovo che dovrebbe farti risparmiare molto tempo, ma non è ancora pronto. Quindi, per favore, manteniamo questo come il nostro piccolo segreto. Grazie!",
  "ai.assisted.onboarding.openSetupNotification": "Vedi cosa è stato fatto finora",
  "ai.assisted.onboarding.openSetupNotificationFetchingSetup": "Grazie per aver fornito tutte le informazioni necessarie. Sto iniziando a creare la tua configurazione di Vacation Tracker. Inizierai a vedere aggiornamenti in tempo reale da un momento all'altro...",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigned": "Tipo di assenza {leaveTypeName} assegnato a {locationName}",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigning": "Assegnazione del tipo di assenza {leaveTypeName} a {locationName}",
  "ai.assisted.onboarding.openSetupNotificationLeavePolicySuccessAction": "{entityName} {entity} è stato {action} con successo",
  "ai.assisted.onboarding.openSetupNotificationNoData": "Impossibile visualizzare i dati di configurazione",
  "ai.assisted.onboarding.openSetupNotificationNoDataSubtitle": "Non posso mostrare i dati di configurazione in questo momento. Questo può accadere a causa di una breve interruzione di rete o perché il sistema non ha ancora finito di elaborare i dati. Per favore, ricarica questa pagina o controlla le impostazioni nella sezione Impostazioni del menu.",
  "ai.assisted.onboarding.openSetupNotificationTitle": "Stato di configurazione dell'onboarding",
  "ai.assisted.onboarding.sendMessageError": "Sembra che la risposta dell'Assistente AI stia impiegando troppo tempo. Si prega di ricaricare.",
  "ai.assisted.onboarding.summary.intro": "La configurazione è completata. Ora puoi aprire la dashboard e iniziare a utilizzare l'app.",
  "ai.assisted.onboarding.summary.introSummary": "Ecco il riepilogo della tua configurazione:",
  "ai.assisted.onboarding.summary.leavePoliciesInfo": "Le politiche di congedo sono configurazioni dei tipi di congedo specifiche per una località. Per visualizzare le politiche di congedo create, vai alla pagina delle località, seleziona la località, quindi apri la scheda delle politiche di congedo.",
  "ai.assisted.onboarding.summary.view.departments": "Visualizza Dipartimenti",
  "ai.assisted.onboarding.summary.view.leaveTypes": "Visualizza Tipi di Congedo",
  "ai.assisted.onboarding.summary.view.locations": "Visualizza Località",
  "app.12h": "12 ore",
  "app.24h": "24 ore",
  "app.aboutMe": "Su di me:",
  "app.accountPreferences": "Visualizza Preferenze",
  "app.accountSettings": "Impostazioni Account",
  "app.accruals": "Accumuli",
  "app.actions": "Azioni",
  "app.activate": "Attiva",
  "app.active": "Attivo",
  "app.add": "Aggiungi",
  "app.addAvatar": "Aggiungi Avatar",
  "app.addLeave": "Aggiungi Congedo",
  "app.addNewEmail": "Aggiungi Destinatario",
  "app.addons": "Componenti aggiuntivi",
  "app.addToGoogleCalendar": "Calendario Google",
  "app.addToMicrosoft365Calendar": "Calendario Microsoft 365",
  "app.AIAssistant": "Assistente AI",
  "app.all": "Tutti",
  "app.allDay": "Tutto il giorno",
  "app.allowHalfDays": "Consenti Mezze Giornate",
  "app.allowHourlyIncrements": "Consenti Incrementi Orari",
  "app.and": "e",
  "app.announcementsHaveBeenPaused": "I tuoi annunci sono stati messi in pausa per 2 mesi.",
  "app.applyTo": "Applica a",
  "app.approvalReason": "Motivo dell'approvazione",
  "app.approvalRequired": "Approvazione Richiesta",
  "app.approve": "Approva",
  "app.approved": "Approvato",
  "app.approveLeaveText": "Conferma di voler approvare la richiesta di congedo.",
  "app.approveLeaveTitle": "Approva Richiesta di Congedo",
  "app.april": "Aprile",
  "app.areYouSure": "Sei sicuro?",
  "app.askMeLater": "Chiedimelo più tardi",
  "app.assignLeavePolicy": "Assegna Politica di Congedo",
  "app.august": "Agosto",
  "app.autoApproved": "Approvato automaticamente",
  "app.autoDeleted": "<Eliminato automaticamente>",
  "app.autoImport": "<Importazione automatica>",
  "app.automations": "Automazioni",
  "app.avatar": "Avatar",
  "app.back": "Indietro",
  "app.backToMenu": "Torna al menu",
  "app.bi-weekly": "Bisettimanale",
  "app.billing.periodAnnual": "Annuale",
  "app.billingStatus": "Stato di fatturazione",
  "app.billingStatus.ACTIVE": "Pagamento attivo",
  "app.billingStatus.CANCEL_AT_PERIOD_END": "Attivo fino alla fine del periodo di fatturazione",
  "app.billingStatus.CANCELED": "Pagamento annullato",
  "app.billingStatus.GRANT_PERIOD": "Periodo di concessione",
  "app.broughtForward": "Riportato",
  "app.by": "da",
  "app.calendar": "Calendario",
  "app.cancel": "Annulla",
  "app.cancelLeave": "Annulla congedo",
  "app.cancelLeaveConfirmText": "Sei sicuro di voler annullare questo congedo programmato?",
  "app.cancelled": "Annullato",
  "app.change": "Cambia",
  "app.changePassword": "Cambia password",
  "app.close": "Chiudi",
  "app.code": "Codice",
  "app.color": "Colore",
  "app.comingSoon": "Prossimamente",
  "app.company": "Azienda",
  "app.companyLogs": "Registri aziendali",
  "app.companyNotExist": "Ops! Sembra che tu non abbia completato l'iscrizione a Vacation Tracker. Visita: app.vacationtracker.io per completare l'iscrizione.",
  "app.companyNotExist.description1": "Hai ora aggiunto il bot e la scheda di Vacation Tracker al tuo spazio di lavoro Microsoft Teams.",
  "app.companyNotExist.description2": "Per favore, vai sul nostro sito web per completare la tua registrazione e configurare le impostazioni della tua organizzazione.",
  "app.companyNotExist.getStarted": "Completa l'iscrizione",
  "app.completed": "Completato",
  "app.completeRegistration": "Completa la registrazione",
  "app.configuration.moreInfo": "Per ulteriori informazioni, visita il nostro help desk: <link>https://vacationtracker.crisp.help/en/</link>",
  "app.configuration.option.overview": "Panoramica",
  "app.configuration.options.info": "Seleziona il tipo di scheda: ",
  "app.configuration.welcome": "Benvenuto in Vacation Tracker! Questa scheda ti permette di richiedere facilmente un congedo senza uscire da Microsoft Teams, gestire i tuoi congedi, controllare lo stato dei tuoi congedi, vedere chi è fuori ufficio e visualizzare le prossime festività per il tuo team.",
  "app.configure": "Configura",
  "app.confirm": "Conferma",
  "app.confirmNewPassword": "Conferma Nuova Password",
  "app.confirmPassword": "Conferma Password",
  "app.consentRequired": "È richiesto il consenso dell'amministratore di Microsoft Teams. Sembra che tu non sia un amministratore della tua organizzazione Microsoft Teams. Per accedere alla scheda Vacation Tracker, avrai bisogno del consenso dell'amministratore. Invia il seguente link all'amministratore della tua organizzazione per ottenere il consenso dell'amministratore: {link}",
  "app.consentRequired.title": "È richiesto il consenso dell'amministratore di Microsoft Teams",
  "app.contactSupport": "Contatta il supporto",
  "app.continue": "Continua",
  "app.copyright": "Copyright VacationTracker",
  "app.correlationIdError": "Si è verificato un errore. Contatta il nostro servizio clienti all'indirizzo hello@vacationtracker.io con il seguente ID: {correlationId}",
  "app.country": "Paese",
  "app.create": "Crea",
  "app.created": "Creato",
  "app.createdAt": "Creato il",
  "app.createdBy": "Creato da",
  "app.createdSuccessfully": "Richiesta inviata",
  "app.creating": "Creazione in corso",
  "app.creditCard": "Carta di credito",
  "app.currentYear": "Anno corrente",
  "app.customDays": "Giorni personalizzati",
  "app.customWorkHours": "Ore di lavoro personalizzate",
  "app.daily": "Giornaliero",
  "app.dashboard": "Dashboard",
  "app.dateRange": "Data di inizio e fine",
  "app.day": "giorno",
  "app.days": "giorni",
  "app.december": "Dicembre",
  "app.delete": "Elimina",
  "app.deleted": "Eliminato",
  "app.deleteFailed": "Eliminazione fallita",
  "app.deleteUser": "Elimina utente",
  "app.deleting": "Eliminazione in corso",
  "app.deliveryFailed": "Consegna fallita",
  "app.denialReason": "Motivo del rifiuto",
  "app.denied": "Rifiutato",
  "app.deny": "Rifiuta",
  "app.denyLeaveRequest": "Rifiuta richiesta di congedo",
  "app.denyLeaveRequestText": "Conferma di voler rifiutare la richiesta di congedo.",
  "app.denyWithReason": "Rifiuta con motivazione",
  "app.department": "Dipartimento",
  "app.departments": "Dipartimenti",
  "app.departmentsSub": "Sotto-dipartimenti",
  "app.deselectAll": "Deseleziona Tutto",
  "app.displayDaysHoursTooltip.days": "Sono {value} giorni",
  "app.displayDaysHoursTooltip.hours": "Sono {value} ore",
  "app.documentation": "Documentazione",
  "app.done": "Fatto",
  "app.dontSend": "Non inviare",
  "app.downgradeWithoutUnsubscribe": "Declassa senza disdire l'abbonamento",
  "app.downgradeWithSubscribe": "Declassa e disdici l'abbonamento",
  "app.download": "Scarica",
  "app.downloadProgressReport": "Creazione del file in corso, potrebbe richiedere un momento...",
  "app.downloadReady": "Il tuo file è pronto. Clicca qui per scaricarlo.",
  "app.downloadTemplate": "Scarica Modello",
  "app.edit": "Modifica",
  "app.edited": "Modificato",
  "app.editLeave": "Modifica Congedo",
  "app.email": "Email",
  "app.emailDeleted": "Email eliminata",
  "app.employeeEndDate": "Data di Fine Impiego",
  "app.enable": "Abilita",
  "app.enabled": "Abilitato",
  "app.enterWorkEmail": "Inserisci la tua email di lavoro",
  "app.error": "Errore",
  "app.every": "ogni",
  "app.expired": "Scaduto",
  "app.expires": "Scade",
  "app.export": "Esporta",
  "app.export.comingSoon": "Il nostro team sta perfezionando questa funzionalità. Sarà disponibile a breve.",
  "app.feature.access": "Disponibile esclusivamente nel piano Completo.",
  "app.february": "Febbraio",
  "app.firstAndLastName": "Nome e Cognome",
  "app.friday": "Venerdì",
  "app.friday.short": "Ven",
  "app.full-day": "Giornata Intera",
  "app.full-name": "Nome Completo",
  "app.general": "Generale",
  "app.goodAfternoon": "Buon pomeriggio",
  "app.goodEvening": "Buona sera",
  "app.goodMorning": "Buongiorno",
  "app.gotIt": "Capito",
  "app.half-day": "Mezza giornata",
  "app.halfDay": "Mezza Giornata",
  "app.halfDayInParenthesis": " (Mezza Giornata)",
  "app.happyVacationing": "Buone vacanze 🌴",
  "app.helpdesk": "Assistenza",
  "app.holidays": "Festività",
  "app.home": "Home",
  "app.hour": "ora",
  "app.hours": "ore",
  "app.import": "Importa",
  "app.importFile": "Importa File",
  "app.importFormat": "Formato di Importazione",
  "app.importLeaves": "Importa Permessi",
  "app.importLeaves.alerts.confirmClose.bodyText": "Sei sicuro? Le informazioni attuali non saranno salvate.",
  "app.importLeaves.alerts.confirmClose.cancelButtonTitle": "Annulla",
  "app.importLeaves.alerts.confirmClose.exitButtonTitle": "Esci",
  "app.importLeaves.alerts.confirmClose.headerTitle": "Esci dal flusso di importazione",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyText": "Ci sono ancora alcune righe che contengono errori. Le righe con errori saranno ignorate durante l'invio.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.bodyTextSubmitForbidden": "Ci sono ancora alcune righe contenenti errori. Rimuovi le righe non valide o correggi gli errori e riprova.",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.cancelButtonTitle": "Annulla",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.finishButtonTitle": "Invia",
  "app.importLeaves.alerts.confirmClose.submitIncomplete.headerTitle": "Errori Rilevati",
  "app.importLeaves.alerts.toast": "Errore",
  "app.importLeaves.alerts.unmatchedRequiredFields.bodyText": "Ci sono colonne obbligatorie che non sono abbinate o ignorate. Devi abbinare tutte le colonne obbligatorie per continuare.",
  "app.importLeaves.alerts.unmatchedRequiredFields.cancelButtonTitle": "Annulla",
  "app.importLeaves.alerts.unmatchedRequiredFields.continueButtonTitle": "Continua",
  "app.importLeaves.alerts.unmatchedRequiredFields.headerTitle": "Non tutte le colonne sono abbinate",
  "app.importLeaves.alerts.unmatchedRequiredFields.listTitle": "Colonne obbligatorie non abbinate:",
  "app.importLeaves.downloadTemplateFileTooltip": "Qui puoi scaricare il nostro file modello di importazione, che serve come esempio per compilare i dati che desideri importare. Quando crei il tuo file di importazione, assicurati che contenga una colonna di intestazione affinché il sistema riconosca correttamente i tuoi dati.<br></br><br></br>Verifica che i tuoi dati corrispondano al modello per garantire un'importazione corretta. Per ulteriori istruzioni, non esitare a contattare il nostro team di supporto.",
  "app.importLeaves.dropzone.activeDropzoneTitle": "Rilascia il file qui",
  "app.importLeaves.dropzone.buttonTitle": "Seleziona file",
  "app.importLeaves.dropzone.errorToastDescription": "Carica file .xlsx, .xls o .csv",
  "app.importLeaves.dropzone.loadingTitle": "Elaborazione in corso...",
  "app.importLeaves.dropzone.title": "Carica file .xlsx, .xls o .csv",
  "app.importLeaves.errors.dateFromAfterDateTo": "La Data di Inizio è successiva alla Data di Fine",
  "app.importLeaves.errors.dateFromNotValid": "La Data di Inizio non è valida",
  "app.importLeaves.errors.dateFromRequired": "La Data di Inizio è obbligatoria",
  "app.importLeaves.errors.dateToNotValid": "La Data di Fine non è valida",
  "app.importLeaves.errors.dateToRequired": "La Data di Fine è obbligatoria",
  "app.importLeaves.errors.halfDay": "Il permesso è superiore a un giorno",
  "app.importLeaves.errors.hourFromAfterHourTo": "L'ora di inizio è successiva all'ora di fine",
  "app.importLeaves.errors.hourFromNotValid": "L'ora di inizio non è valida, inserisci un valore tra 0 e 24",
  "app.importLeaves.errors.hourFromRequired": "L'ora di inizio è obbligatoria",
  "app.importLeaves.errors.hourRange": "Le ore selezionate superano la durata della giornata lavorativa di questo utente ({hours} ore). Si prega di modificare la selezione.",
  "app.importLeaves.errors.hourToNotValid": "L'ora di fine non è valida, inserisci un valore tra 0 e 24",
  "app.importLeaves.errors.hourToRequired": "L'ora di fine è obbligatoria",
  "app.importLeaves.errors.leavePolicyError": "La politica dei permessi consente solo giorni interi",
  "app.importLeaves.errors.leaveTypeNotFound": "Tipo di permesso non trovato",
  "app.importLeaves.errors.leaveTypeNotInUserLocation": "Tipo di permesso non presente nella località dell'utente",
  "app.importLeaves.helpdesk": "Per una guida dettagliata sull'importazione dei tuoi file, consulta le istruzioni passo-passo disponibili sulla <helpdesk>pagina dell'helpdesk</helpdesk>.",
  "app.importLeaves.importFormatTooltip": "Seleziona il formato di importazione che corrisponde al tuo tipo di dati e alle tue preferenze.",
  "app.importLeaves.leaveStatusWarning": "Lo stato del permesso sarà impostato su \"Approvato\" per tutti i permessi che importi.",
  "app.importLeaves.manifestDescription": "(Avrai la possibilità di rinominare o rimuovere le colonne nei passaggi successivi)",
  "app.importLeaves.manifestTitle": "Dati che ci aspettiamo:",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningDescription": "Le colonne non possono essere duplicate",
  "app.importLeaves.matchColumnsStep.duplicateColumnWarningTitle": "Un'altra colonna deselezionata",
  "app.importLeaves.matchColumnsStep.ignoredColumnText": "Colonna ignorata",
  "app.importLeaves.matchColumnsStep.matchDropdownTitle": "Abbina",
  "app.importLeaves.matchColumnsStep.nextButtonTitle": "Avanti",
  "app.importLeaves.matchColumnsStep.selectPlaceholder": "Seleziona colonna...",
  "app.importLeaves.matchColumnsStep.subSelectPlaceholder": "Seleziona...",
  "app.importLeaves.matchColumnsStep.templateTitle": "Diventerà",
  "app.importLeaves.matchColumnsStep.title": "Abbina Colonne",
  "app.importLeaves.matchColumnsStep.unmatched": "Non abbinato",
  "app.importLeaves.matchColumnsStep.userTableTitle": "La tua tabella",
  "app.importLeaves.maxRecordsExceeded": "Troppi record. Consentiti fino a {maxRecords}",
  "app.importLeaves.nextButtonTitle": "Avanti",
  "app.importLeaves.selectHeaderStep.title": "Seleziona la riga dell'intestazione",
  "app.importLeaves.selectSheet.title": "Seleziona il foglio da utilizzare",
  "app.importLeaves.validationStep.discardButtonTitle": "Scarta le righe selezionate",
  "app.importLeaves.validationStep.filterSwitchTitle": "Mostra solo le righe con errori",
  "app.importLeaves.validationStep.nextButtonTitle": "Conferma",
  "app.importLeaves.validationStep.noRowsMessage": "Nessun dato trovato",
  "app.importLeaves.validationStep.noRowsMessageWhenFiltered": "Nessun dato contenente errori",
  "app.importLeaves.validationStep.title": "Convalida dati",
  "app.importType": "Tipo di Importazione",
  "app.integrations": "Integrazioni",
  "app.invite": "Invita",
  "app.january": "Gennaio",
  "app.july": "Luglio",
  "app.june": "Giugno",
  "app.label": "Etichetta",
  "app.labels": "Etichette",
  "app.lastMonth": "Mese scorso",
  "app.learnMore": "Scopri di più",
  "app.leave": "Congedo",
  "app.leaveBalanceReport": "Rapporto sul saldo dei congedi",
  "app.leaveCanceled": "Congedo annullato",
  "app.leaveHistory": "Cronologia dei congedi",
  "app.leaveHistoryReport": "Rapporto sulle richieste di congedo",
  "app.leavePolicies": "Politiche dei congedi",
  "app.leaveRequestCalendarStatusLabel": "Mostra nel tuo calendario come ",
  "app.leaveRequestCreated": "Richiesta di congedo creata",
  "app.leaveRequests": "Richieste di congedo",
  "app.leaveType": "Tipo di congedo",
  "app.leaveTypes": "Tipi di congedo",
  "app.link": "Link",
  "app.load": "Carica",
  "app.loading": "Caricamento",
  "app.loadMore": "Carica altro",
  "app.location": "Posizione",
  "app.locations": "Posizioni",
  "app.login": "Accedi",
  "app.logInWithMicrosoft": "Accedi con Microsoft",
  "app.logout": "Esci",
  "app.logoutNow": "Esci ora",
  "app.logs": "Registri",
  "app.manageLabels": "Gestisci etichette",
  "app.march": "Marzo",
  "app.may": "Maggio",
  "app.maybe": "Forse",
  "app.message": "messaggio",
  "app.meta.description": "Amato da oltre 1.000 aziende in tutto il mondo, Vacation Tracker rende notoriamente facile gestire le ferie. Disponibile per Slack, Microsoft Teams e Google Workspace.",
  "app.meta.title": "Vacation Tracker - Soluzione per il tracciamento dei congedi compatibile con il lavoro remoto",
  "app.mfa": "Autenticazione a più fattori",
  "app.mfaCode": "Codice",
  "app.mfaCodeErrorGeneric": "Impossibile convalidare il codice",
  "app.mfaCodeErrorMissmatch": "Codice non corrispondente",
  "app.mfaCodeErrorNetwork": "Errore di rete",
  "app.mfaCodePlaceholder": "Inserisci il codice visualizzato nella tua app di autenticazione",
  "app.mfaDisableAnswerNo": "No, mantieni MFA",
  "app.mfaDisableAnswerYes": "Sì, disabilita MFA",
  "app.mfaDisableError": "Impossibile disabilitare MFA. Riprova più tardi.",
  "app.mfaDisableSuccess": "Hai disabilitato MFA con successo.",
  "app.mfaDisableWarning": "Sei sicuro di voler disabilitare MFA?",
  "app.mfaDisableWarningTitle": "Disabilita l'autenticazione a più fattori",
  "app.mfaEnableError": "Impossibile abilitare MFA. Riprova più tardi.",
  "app.mfaEnableSuccess": "Hai configurato MFA con successo.",
  "app.mfaEnterCode": "Inserisci il codice",
  "app.mfaLabel": "Abilita MFA",
  "app.mfaLoginFailed": "Accesso fallito",
  "app.mfaSecretKey": "O inserisci la chiave segreta manualmente:",
  "app.mfaSubTitle": "Scansiona il seguente codice QR nella tua applicazione MFA (ad es. Authy o Google Authenticator):",
  "app.mfaTitle": "Abilita l'autenticazione a più fattori",
  "app.mfaTooltip": "Dopo aver aggiunto Vacation Tracker alla tua applicazione MFA, attendi che generi il codice e inseriscilo di seguito:",
  "app.microsoft.manageLicenses": "Gestisci licenze",
  "app.microsoftTeams": "Microsoft Teams",
  "app.monday": "Lunedì",
  "app.monday.short": "Lun",
  "app.monthly": "Mensile",
  "app.monthlyLeaveBalanceReport": "Report mensile del saldo ferie",
  "app.moreDetails": "Più dettagli",
  "app.myProfile": "Il mio profilo",
  "app.myStatus": "Il mio stato",
  "app.name": "Nome",
  "app.newPassword": "Nuova password",
  "app.next": "Avanti",
  "app.nextInvoice": " La tua prossima fattura sarà di {amount} (IVA esclusa) il {startDate}",
  "app.nextMonth": "Prossimo mese",
  "app.nextYear": "Prossimo anno",
  "app.no": "No",
  "app.noLeaves": "Nessuna ferie",
  "app.none": "Nessuno",
  "app.nonWorkingDay": "Giorno non lavorativo",
  "app.notDone": "Non fatto",
  "app.note": "Nota",
  "app.notificationError": "Oh no! 😕 Qualcosa è andato storto. ",
  "app.notifications": "Notifiche",
  "app.notSet": "Non impostato",
  "app.notValidEmail": "Inserisci un indirizzo email valido.",
  "app.november": "Novembre",
  "app.october": "Ottobre",
  "app.of": "di",
  "app.ofThat": "di quello",
  "app.ok": "Ok",
  "app.oldPassword": "Vecchia Password",
  "app.one-hour": "1 ora",
  "app.openCalendar": "Apri Calendario",
  "app.openDashboard": "Apri dashboard",
  "app.openWebDashboard": "Apri Dashboard Web",
  "app.or": "O",
  "app.password": "Password",
  "app.passwordAndSecurity": "Password e Sicurezza",
  "app.pastDue": "A causa di un pagamento non riuscito, il tuo accesso a Vacation Tracker sarà chiuso presto. Se desideri continuare a utilizzare Vacation Tracker, per favore <a>inserisci una carta valida</a>.",
  "app.pending": "In sospeso",
  "app.pendingLeaveRequests": "Richieste di Congedo in Sospeso",
  "app.pleaseSelect": "Seleziona",
  "app.prev": "Prec",
  "app.previous": "Precedente",
  "app.proceed": "Procedi",
  "app.processingRequest": "Elaborazione richiesta...",
  "app.profilePicture": "Foto Profilo",
  "app.reason": "Motivo",
  "app.recipients": "Destinatari",
  "app.recommended": "consigliato",
  "app.reconnect": "Riconnetti",
  "app.reload": "Ricarica",
  "app.removed": "rimosso",
  "app.rename": "Rinomina",
  "app.reportType": "Tipo di rapporto",
  "app.requestLeave": "Richiedi Congedo",
  "app.requestToil": "Richiedi TOIL",
  "app.required": "Obbligatorio",
  "app.resendCode": "Reinvia codice",
  "app.resent": "Reinviato",
  "app.reset": "Reimposta",
  "app.resetPassword": "Reimposta password",
  "app.reviewAndConfirm": "Rivedi e Conferma",
  "app.role.administrator": "Amministratore",
  "app.role.approver": "Approvatore",
  "app.role.approvers": "Approvatori",
  "app.saturday": "Sabato",
  "app.saturday.short": "Sab",
  "app.save": "Salva",
  "app.scheduledLeaves": "Permessi Programmati",
  "app.scheduledReports": "Rapporti Programmati",
  "app.seeInCalendar": "🗓️ Vedi nel Calendario",
  "app.seeMoreOptions": "Vedi più opzioni",
  "app.select": "Seleziona",
  "app.selectAll": "Seleziona Tutto",
  "app.semi-monthly": "Quindicinale",
  "app.send": "Invia",
  "app.sendNow": "Invia Ora",
  "app.september": "Settembre",
  "app.set": "imposta",
  "app.setOn": "impostato il",
  "app.shortestLeaveDuration": "Durata Minima del Permesso",
  "app.showLess": "Mostra meno",
  "app.showMore": "Mostra di più",
  "app.skip": "Salta",
  "app.slack": "Slack",
  "app.snooze": "Posticipa",
  "app.snoozeForMonths": "Posticipa per 2 mesi",
  "app.startFreeTrial": "Inizia la Prova Gratuita di 7 Giorni",
  "app.state": "Stato",
  "app.status": "Stato",
  "app.submit": "Invia",
  "app.substituteApprover": "Approvatore Sostitutivo",
  "app.substituteApproverSet": "Sei stato impostato come Approvatore Sostitutivo.",
  "app.sunday": "Domenica",
  "app.sunday.short": "Dom",
  "app.switch": "Cambia",
  "app.sync": "Sincronizza",
  "app.takeQuickTour": "Fai un tour rapido",
  "app.text": "Testo",
  "app.thisMonth": "Questo mese",
  "app.thisWeek": "Questa settimana",
  "app.thursday": "Giovedì",
  "app.thursday.short": "Gio",
  "app.time": "Ora",
  "app.timeOffInLieu": "Tempo libero compensativo",
  "app.timeOffInLieuTooltip": "Vuoi modificare il Tempo libero compensativo? <helpDesk>Scopri come farlo</helpDesk>",
  "app.timezone": "Fuso orario",
  "app.today": "Oggi",
  "app.toil": "TOIL",
  "app.trialExpired": "La versione di prova scade tra {days} {daysInfo}. Se ti piace, per favore <a>abbonati</a> per continuare a utilizzare Vacation Tracker dopo la fine del periodo di prova.",
  "app.trialExtend": "Hai bisogno di un'estensione del periodo di prova?",
  "app.trialPeriod": "Periodo di prova",
  "app.tryAgain": "Riprova",
  "app.tryLateOrContactSupport": "Qualcosa è andato storto. Per favore riprova più tardi o contatta il nostro supporto clienti all'indirizzo hello@vacationtracker.io con il seguente ID: {code}",
  "app.tuesday": "Martedì",
  "app.tuesday.short": "Mar",
  "app.type": "Tipo",
  "app.unknown": "<Sconosciuto>",
  "app.unlimited": "Illimitato",
  "app.update": "Aggiorna",
  "app.updated": "aggiornato",
  "app.updatedInProgress": "Elaborazione della risposta",
  "app.updatedSuccessfully": "Richiesta inviata.",
  "app.updating": "Aggiornamento in corso",
  "app.upload": "Carica",
  "app.user": "Utente",
  "app.users": "Utenti",
  "app.users.role": "Ruolo",
  "app.via": "tramite",
  "app.view": "Visualizza",
  "app.viewAll": "Visualizza tutto",
  "app.visitHelpdesk": "Visita l'Helpdesk",
  "app.wallchart": "Tabellone",
  "app.was": "era",
  "app.watchVideo": "Guarda il video",
  "app.wednesday": "Mercoledì",
  "app.wednesday.short": "Mer",
  "app.weekly": "Settimanale",
  "app.willBe": "sarà",
  "app.workday": "Durata della giornata lavorativa",
  "app.workdayWarning": "L'opzione di mezza giornata di congedo è disponibile solo per una giornata lavorativa di 8 ore. Cambiare la giornata lavorativa standard da 8 ore disabiliterà questa opzione.",
  "app.workweek": "Settimana lavorativa",
  "app.year": "Anno",
  "app.years": "Anni",
  "app.yes": "Sì",
  "automation.feedback.thankYou": "Grazie per aver condiviso i tuoi pensieri con noi. Il tuo contributo alimenta la nostra crescita costante.",
  "automations.activateInProgress": "In Corso - Attivazione",
  "automations.activateInProgressDescription": "La tua richiesta di attivare l'automazione è attualmente in corso. Stiamo lavorando per abilitare questa funzione per te.",
  "automations.add": "Crea automazione",
  "automations.addAddonsDescirptionForAdmin": "Procedere con l'acquisto del componente aggiuntivo selezionato? L'importo sarà calcolato proporzionalmente a ${prorateAmount}, con la tua prossima fattura che ammonta a ${nextInvoiceAmount}. Clicca 'Conferma' per finalizzare.",
  "automations.addAddonsDescirptionForAdminInTrial": "Questo componente aggiuntivo verrà addebitato sulla tua prossima fattura: ${total} basato su {userCount} utenti a ${addonsPrice} per componente aggiuntivo. Durante il periodo di prova, puoi attivarlo e disattivarlo liberamente senza costi se nessun componente aggiuntivo è attivo alla fine.",
  "automations.addAddonsDescirptionForApprover": "Solo gli utenti a livello di amministratore hanno la possibilità di acquistare o disdire i componenti aggiuntivi. Se sono necessarie modifiche alle tue sottoscrizioni di componenti aggiuntivi, contatta il tuo amministratore.",
  "automations.addFreeAddonsDescirptionForAdmin": "Questo componente aggiuntivo è incluso senza costi aggiuntivi nel tuo piano attuale. Puoi attivarlo e disattivarlo liberamente durante l'abbonamento al tuo piano attuale senza alcun costo extra.",
  "automations.addpnSuccessfullyAdded": "Completato - Attivazione",
  "automations.addpnSuccessfullyAddedDescription": "L'automazione è stata attivata con successo. Ora puoi utilizzare tutte le sue funzionalità.",
  "automations.addpnSuccessfullyDeactivated": "Completato - Disattivazione",
  "automations.addpnSuccessfullyDeactivatedDescription": "L'automazione è stata disattivata con successo. Non sarà più attiva nel tuo sistema.",
  "automations.ADP_INTEGRATION": "Integrazione ADP",
  "automations.anythingMissing": "Manca qualcosa?",
  "automations.automationBlackoutPeriodDeleted": "Periodo di blackout eliminato",
  "automations.automationEntitlementByRoleDeleted": "Diritto per ruolo eliminato",
  "automations.automationProbationPeriodDeleted": "Periodo di prova eliminato",
  "automations.automationSeniorityEntitlementDeleted": "Diritto per anzianità eliminato",
  "automations.automationType": "Tipo di automazione",
  "automations.BAMBOOHR_INTEGRATION": "Integrazione BambooHR",
  "automations.BLACKOUT_PERIOD": "Periodo di Blackout",
  "automations.blackoutPeriod.autoRejectReasonDefault": "Durante il nostro attuale periodo di alto volume, tutto il nostro team è essenziale per mantenere le operazioni. Purtroppo, non possiamo approvare permessi poiché influenzerebbero i nostri obiettivi aziendali e gli impegni con i clienti.",
  "automations.blackoutPeriod.createInProgress": "Creazione del periodo di blackout in corso",
  "automations.blackoutPeriod.createInProgressCompleted": "Creazione del periodo di blackout completata",
  "automations.blackoutPeriod.updateInProgress": "Aggiornamento del periodo di blackout in corso",
  "automations.blackoutPeriod.updateInProgressCompleted": "Aggiornamento del periodo di blackout completato",
  "automations.blackoutPeriodFromTo": "Periodo di blackout dal {fromDate} <endDate>al {toDate}</endDate>",
  "automations.cantOpenAutomations": "Questa Automazione è stata creata da un altro utente e non puoi modificarla.",
  "automations.createAutomation": "Crea automazione",
  "automations.datePeriod": "{fromDate} <endDate>e {toDate}</endDate><recurring> ogni anno</recurring>",
  "automations.deactivateInPgoress": "In Corso - Disattivazione",
  "automations.deactivateInPgoressDescription": "La tua richiesta di disattivare l'automazione è in fase di elaborazione. Attendi mentre aggiorniamo le tue impostazioni",
  "automations.deactivateVisibilityWarn": "Disabilitare un'automazione non cancellerà l'abbonamento. Se vuoi disattivare l'abbonamento, devi cliccare sul pulsante \"Disdici\".",
  "automations.deleteInProgress": "Eliminazione dell'automazione {automationName} in corso",
  "automations.departmentVisibilityLevelInfo": "Gli utenti vedranno le informazioni sui permessi solo all'interno del proprio dipartimento. Gli approvatori vedranno le informazioni sui permessi del proprio dipartimento e di qualsiasi dipartimento di cui sono approvatori, anche se non ne sono membri. Gli amministratori vedranno sempre tutto.",
  "automations.discoveryMoreAddons": "Scopri Altri Componenti Aggiuntivi",
  "automations.EARLY_EMPLOYMENT_LEAVE_RESTRICTION": "Restrizione Permessi per Nuovo Impiego",
  "automations.emptyViewButton": "Vedi componenti aggiuntivi disponibili",
  "automations.emptyViewMessage": "Aggiungi giorni di ferie basati sull'anzianità, crea periodi di blackout, automatizza il processo di approvazione o rifiuto, e molto altro con le nostre automazioni, componenti aggiuntivi e integrazioni.",
  "automations.emptyViewTitle": "Automatizza le tue attività dispendiose in termini di tempo e integra con sistemi esterni",
  "automations.ENTITLEMENT_BY_ROLE": "Diritto per Ruolo",
  "automations.entitlementByRole.createInProgress": "Creazione del diritto per ruolo in corso",
  "automations.entitlementByRole.createInProgressCompleted": "Creazione del diritto per ruolo completata",
  "automations.entitlementByRole.days": "Giorni",
  "automations.entitlementByRole.updateInProgress": "Aggiornamento del diritto per ruolo in corso",
  "automations.entitlementByRole.updateInProgressCompleted": "Aggiornamento del diritto per ruolo completato",
  "automations.LEAVE_DURATION_LIMIT": "Limite Durata Congedo",
  "automations.message": "Messaggio",
  "automations.messagePlaceholder": "Il tuo messaggio",
  "automations.myAddons": "I miei Add-on",
  "automations.OPEN_API": "API Aperta",
  "automations.OPEN_API_DOCUMENTATION": "Documentazione API Aperta",
  "automations.period": "Periodo",
  "automations.PROBATION_PERIOD": "Periodo di Prova",
  "automations.probationPeriod.autoRejectReasonDefault": "La tua richiesta di congedo è stata automaticamente respinta perché ti trovi nella fase iniziale del tuo impiego, un periodo in cui non sono consentiti congedi. Questa politica aiuta i nuovi dipendenti a immergersi completamente e adattarsi ai loro nuovi ruoli e alle dinamiche del team.",
  "automations.probationPeriod.createInProgress": "Creazione del periodo di prova in corso",
  "automations.probationPeriod.createInProgressCompleted": "Creazione del periodo di prova completata",
  "automations.probationPeriod.lengthDays": "Durata (giorni)",
  "automations.probationPeriod.name": "Nome Politica di Prova",
  "automations.probationPeriod.namePlaceholder": "Inserisci il nome della politica di prova",
  "automations.probationPeriod.settings.applyToMDescription": "Puoi scegliere se applicare questa automazione universalmente a tutti gli utenti o personalizzarla in base a specifici dipartimenti, luoghi o etichette.",
  "automations.probationPeriod.settings.applyToMDescriptionTwo": "Puoi anche selezionare uno o più tipi di congedo a cui desideri applicare questa automazione.",
  "automations.probationPeriod.settings.lengthDaysDescription": "Inserisci il numero di giorni per i quali ai nuovi dipendenti sarà vietato prendere specifici tipi di congedo, a partire dalla data di inizio del loro impiego.",
  "automations.probationPeriod.settings.nameDescription": "Questa sezione ti permette di impostare una politica che limita i nuovi assunti dal prendere congedi durante il loro periodo iniziale di impiego.",
  "automations.probationPeriod.settings.nameDescriptionThree": "Assicurati che gli utenti ricevano un messaggio automatico che spiega perché la loro richiesta di congedo è stata negata.",
  "automations.probationPeriod.settings.nameDescriptionTwo": "Inserisci il numero di giorni e calcoleremo il periodo di prova basandoci sulla data di assunzione di ciascun utente.",
  "automations.probationPeriod.settings.nameTitle": "Definisci Politica",
  "automations.probationPeriod.updateInProgress": "Aggiornamento del periodo di prova in corso",
  "automations.probationPeriod.updateInProgressCompleted": "Aggiornamento del periodo di prova completato",
  "automations.probationPeriodMessages": "Il tuo periodo di prova dura fino al {date}",
  "automations.removeAddons": "Rimuovi add-on",
  "automations.removeAddonsDescirptionForAdmin": "Sei sicuro di voler annullare l'iscrizione a questo Add-on? Manterrai l'accesso a tutte le sue funzionalità fino alla fine del tuo periodo di fatturazione corrente. Dopo di che, verrà disattivato, influenzando di conseguenza la tua configurazione di gestione dei congedi.",
  "automations.removeAddonsDescirptionForApprover": "Solo gli utenti di livello Admin hanno la possibilità di acquistare o annullare l'iscrizione agli Add-on. Se sono necessarie modifiche alle tue iscrizioni agli Add-on, contatta il tuo amministratore.",
  "automations.requestAnAutomation": "Richiedi un Add-On",
  "automations.requestAutomationTitle": "Non trovi l'Add-On di cui hai bisogno?",
  "automations.reSubscribeAddons": "Riabbonati all'Add-on",
  "automations.reSubscribeAddonsDescirptionForAdmin": "Sei sicuro di voler riabbonarti a questo Add-on? Ti verrà addebitato ${addonsPrice} per utente al mese a partire dalla tua prossima fattura. L'addebito del primo mese sarà proporzionato in base al numero di giorni di utilizzo dell'add-on.",
  "automations.reSubscribeAddonsDescirptionForAdminInTrial": "Sei sicuro di voler riabbonarti a questo Add-on? Rimarrà gratuito fino alla fine del periodo di prova. Dopo di che, verrà applicato un addebito di ${addonsPrice} per utente al mese.",
  "automations.reSubscribeInfo": "Rinnova il tuo abbonamento all'add-on e continua a utilizzare le nostre funzionalità avanzate. Clicca su Riabbonati per procedere.",
  "automations.SENIORITY_ENTITLEMENT": "Anzianità di servizio",
  "automations.seniorityEntitlement.createInProgress": "Creazione dell'anzianità di servizio in corso",
  "automations.seniorityEntitlement.createInProgressCompleted": "Creazione dell'anzianità di servizio completata",
  "automations.seniorityEntitlement.periodsViewTable": "{days} giorni dopo {years} anni",
  "automations.seniorityEntitlement.updateInProgress": "Aggiornamento dell'anzianità di servizio in corso",
  "automations.seniorityEntitlement.updateInProgressCompleted": "Aggiornamento dell'anzianità di servizio completato",
  "automations.showCaneledAddons": "Mostra componenti aggiuntivi annullati",
  "automations.stepByStepHelpDesk": "Per una guida dettagliata sulla configurazione dell'automazione <automationName></automationName>, consulta le istruzioni passo passo disponibili sulla <link>pagina dell'help desk</link>.",
  "automations.subscripteToAddons": "Abbonati ai componenti aggiuntivi",
  "automations.TEAM_LEAVE_BALANCING": "Bilanciamento dei congedi del team",
  "automations.unsubscribeForFreeAddonsInfo": "Attualmente sei abbonato a questo componente aggiuntivo senza costi aggiuntivi poiché è incluso nel tuo piano. Se desideri annullare l'abbonamento, fai clic sul pulsante di annullamento sulla destra.",
  "automations.unsubscribeInfo": "Attualmente sei abbonato a questo componente aggiuntivo a ${addonsPrice} per utente. Se desideri annullare l'abbonamento, fai clic sul pulsante di annullamento sulla destra.",
  "automations.unsubscripteAddons": "Annullare l'abbonamento al componente aggiuntivo?",
  "automations.update": "Aggiorna automazioni",
  "automations.VISIBILITY": "Gestore di visibilità",
  "automations.visibility.createInProgress": "Limitazione della visibilità in corso",
  "automations.visibility.createInProgressCompleted": "Limitazione della visibilità completata",
  "automations.visibility.updateInProgress": "Aggiornamento del componente aggiuntivo di visibilità",
  "automations.visibility.updateInProgressCompleted": "Aggiornamento del componente aggiuntivo di visibilità completato",
  "automations.visibilityForm.DEPARTMENT": "Il loro dipartimento",
  "automations.visibilityForm.limitVisibility": "Limita la visibilità per gli utenti",
  "automations.visibilityForm.visibilityLevel": "Gli utenti possono vedere",
  "automations.visibilityForm.visibilityTypeValidationRequired": "Devi selezionare almeno un tipo di visibilità",
  "automations.visibilityInfo.settings.nameTitle": "Impostazioni di visibilità",
  "automations.visibilityManagerNotificationsInfo": "I Calendari condivisi e le Notifiche creati prima della modifica delle impostazioni di visibilità devono essere eliminati manualmente se non sono conformi alle nuove regole.",
  "automationsForm.advancedFilter.tooltipInfo": "Puoi filtrare gli utenti a cui verrà applicata questa automazione in base a dipartimenti, località o etichette.",
  "automationsForm.applyToAllUsers": "Applicare a tutti gli utenti?",
  "automationsForm.applyToAllUsersTooltip": "Scegli se desideri applicare questa automazione universalmente a tutti gli utenti o personalizzarla in base a specifici dipartimenti, località o etichette.",
  "automationsForm.autoRejectReason": "Risposta di rifiuto automatico",
  "automationsForm.autoRejectReasonTooltip": "Fornisci all'utente una risposta automatica sul motivo per cui la sua richiesta di congedo è stata rifiutata durante questo periodo.",
  "automationsForm.blackoutPeriodShortText": "Un periodo di tempo in cui ai dipendenti è vietato prendere congedi",
  "automationsForm.period": "Periodo",
  "automationsForm.recurring": "Ricorrenza annuale",
  "automationsForm.seniorityEntitlement.applyToAllUsersTooltip": "Vuoi applicare questa anzianità di servizio a tutti gli utenti della tua azienda o agli utenti in una località specifica? Se hai regole di anzianità di servizio diverse in località diverse, devi creare più regole di automazione dell'anzianità di servizio, una per ogni località.",
  "automationsForm.seniorityEntitlement.beginningOfTheFiscalYear": "Inizio dell'anno fiscale",
  "automationsForm.seniorityEntitlement.employeeStartDate": "Data di inizio del dipendente",
  "automationsForm.seniorityEntitlement.endOfTheFiscalYear": "Fine dell'anno fiscale",
  "billing.applyingCoupon": "Applicazione del coupon",
  "billing.applyingCouponAndResubscribing": "Applicazione del coupon e riabbonamento",
  "billing.cancelSubscription": "Annulla abbonamento",
  "billing.cancelTrial": "Il tuo periodo di prova rimarrà attivo fino al {stripeCurrentPeriodEnd}. Per continuare a utilizzare Vacation Tracker, inserisci le tue informazioni di fatturazione.",
  "billing.cancelTrialButton": "Annulla Prova",
  "billing.cancelUpdateInProgress": "Annullamento in corso...",
  "billing.linkPaymentMethod": "Collegamento Stripe",
  "billing.microsoft.billedLabel": "Fatturato",
  "billing.microsoft.billingCycle": "Il ciclo di fatturazione attuale termina il {date}",
  "billing.microsoft.billingCycleEnds": "Il tuo abbonamento verrà annullato dopo la fine del ciclo di fatturazione e non potrai più utilizzare Vacation Tracker.",
  "billing.microsoft.billingCycleLabel": "Ciclo di fatturazione",
  "billing.microsoft.buyLessLicensesConfirmModalContent": "Hai {currentNoOfLicenses} licenze. Stai per rimuoverne {additionalLicences}. Sei sicuro?",
  "billing.microsoft.buyMoreLicensesConfirmModalContent": "Hai {currentNoOfLicenses} licenze. Stai per acquistarne {additionalLicences} in più. Sei sicuro?",
  "billing.microsoft.canceledSubscriptionMessage": "Il tuo abbonamento verrà annullato tra circa 15 minuti",
  "billing.microsoft.changePlanModalTitle": "Cambia il piano <strong>{plan}</strong> attuale",
  "billing.microsoft.info": "Le tue informazioni di pagamento sono gestite da Microsoft. Visita il <adminCenterLink>Centro amministrazione</adminCenterLink> per aggiornare le tue {labelInfo}",
  "billing.microsoft.licensesSideActionLabel": "Visualizza cronologia",
  "billing.microsoft.licensesTitle": "Licenze",
  "billing.microsoft.pricingPlanLabel": "Piano tariffario",
  "billing.microsoft.switchPlanModal.confirm": "Conferma di voler passare a questo piano.",
  "billing.paymentInformation": "Informazioni di pagamento",
  "billing.paymentMethod": "Metodo di pagamento",
  "billing.pricingPlanPeriodAnnual": "Annuale",
  "billing.pricingPlanPeriodMonthly": "Mensile",
  "billing.pricingPlansText": "Questa sezione mostra il tuo piano di abbonamento. Puoi riabbonarti al piano che avevi in precedenza o cambiare piano prima di riabbonarti.",
  "billing.pricingPlansTitle": "Piani Tariffari",
  "billing.promoCodeErrorDescription": "Il codice promozionale {code} non è valido.",
  "billing.resubscribeCTA": "Riabbonati",
  "billing.resubscribingUpdateInProgress": "Riabbonamento in corso...",
  "billing.seats": "Posti",
  "billing.seats.buyLessSeatsConfirmModalContent": "Hai {currentNoOfSeats} posti. Stai per rimuoverne {additionalSeats}.",
  "billing.seats.buyMoreSeatsConfirmModalContent": "Hai {currentNoOfSeats} posti. Stai per acquistarne {additionalSeats} in più.",
  "billing.seats.canNotDowngrade": "Non puoi ridurre il numero di posti ({currentNumberOfSeats}) senza disattivare uno o più utenti attualmente attivi.",
  "billing.seats.info": "Il numero di posti che hai acquistato.",
  "billing.seats.loadingUpcomingPaymentInformation": "Caricamento delle informazioni sul prossimo pagamento...",
  "billing.seats.manage": "Gestisci posti",
  "billing.seats.noBillingChange": "Stai per acquistare {noOfSeats} {noOfSeats, plural, =1 {posto} other {posti}}. La tua fatturazione non subirà modifiche.",
  "billing.seats.warningOfProrateAdditional": "Verrà applicato oggi un addebito stimato proporzionale di {proratedAmount} (IVA esclusa).",
  "billing.seats.warningOfSeatsAnnualProrateAdditionalNewBucket": "L'aggiunta di {noOfSeats} {noOfSeats, plural, =1 {posto} other {posti}} aumenterà la tua prossima fattura a {amount} (IVA esclusa) a partire dal {startDate}.",
  "billing.seats.warningOfSeatsProrateAdditionalNewBucketMonthly": "L'acquisto di {noOfSeats} {noOfSeats, plural, =1 {posto} other {posti}} influenzerà la tua fatturazione. La tua prossima fattura sarà di {amount} (IVA esclusa) il {startDate}",
  "billing.seats.warningOfSeatsRemovingAnnualProrateAdditionalNewBucket": "La rimozione di {noOfSeats} {noOfSeats, plural, =1 {posto} other {posti}} diminuirà la tua prossima fattura a {amount} (IVA esclusa) a partire dal {startDate}.",
  "billing.seats.warningOfSeatsRemovingProrateAdditionalNewBucketMonthly": "La rimozione di {noOfSeats} {noOfSeats, plural, =1 {posto} other {posti}} influenzerà la tua fatturazione. La tua prossima fattura sarà di {amount} (IVA esclusa) il {startDate}",
  "billing.subscriptionCanceled": "Abbonamento annullato",
  "billing.subscriptionExpired": "Abbonamento non attivo",
  "billing.subscriptionExpiredDescription": "Il tuo abbonamento è stato cancellato o è scaduto. Gli aggiornamenti non sono possibili in questo momento.",
  "billing.subscriptionExpiredText": "Il tuo abbonamento è scaduto. Rinnova l'abbonamento per continuare a utilizzare Vacation Tracker. Se hai problemi o domande, contatta il nostro {supportLink}.",
  "billing.SubscriptionPlan": "Piano di abbonamento",
  "billing.subscriptionUpdateInProgress": "Aggiornamento dell'abbonamento in corso",
  "billing.subscriptionUpdateInProgressDescription": "L'operazione precedente è ancora in corso. Si prega di riprovare tra un minuto.",
  "billing.updateBillingInfo": "Aggiorna informazioni di fatturazione",
  "billing.updatedFaild": "Aggiornamento delle informazioni di fatturazione fallito",
  "billing.updatedSuccessfully": "Informazioni di fatturazione aggiornate",
  "billing.updateInProgress": "Aggiornamento delle informazioni di fatturazione in corso",
  "bot.announce.slack": "Ciao <@{slackUserId}>\n\nIl mio nome è Vacation Tracker. Ti aiuterò a prenotare le ferie tramite Slack e terrò tutti informati sulle prossime assenze nella tua organizzazione.\n\nDigita `/vacation` in qualsiasi punto di Slack per iniziare o dai un'occhiata al mio tutorial su YouTube per saperne di più:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.announce.slack.info": "Ciao <@{slackUserId}>, il mio nome è Vacation Tracker.",
  "bot.announce.slackCompany": "Ciao Team,\n\nIl mio nome è Vacation Tracker Bot!\n\nTi permetto di prenotare le tue ferie tramite Slack e tengo tutti informati sulle prossime assenze dei membri del team. Iniziamo a tracciare alcune ferie!\n\nDigita `/vacation` in qualsiasi punto di Slack o dai un'occhiata al tutorial su YouTube per saperne di più:\nhttps://youtu.be/9e6J1AmfdwM",
  "bot.message.about.commandDescription": "Vacation Tracker semplifica il processo di richiesta e prenotazione delle ferie per la tua organizzazione. I membri del team possono prenotare qualsiasi tipo di ferie o giorni di riposo retribuiti/non retribuiti, senza mai lasciare Slack e senza dover ricordare un altro login o password.\n\nConfigura tipi di ferie personalizzati e lascia che ogni individuo nella tua organizzazione abbia il controllo del proprio tempo libero. Vacation Tracker fornisce a ogni membro del team l'accesso al Dashboard (http://app.vacationtracker.io) dove possono richiedere ferie, controllare il loro stato attuale di ferie o la cronologia, e vedere le ferie programmate per l'intero team.\n\nIn base alle impostazioni dell'Amministratore o dell'Approvatore, i membri del team riceveranno notifiche giornaliere/settimanali sulle prossime assenze. Nessun fastidio e nessuna sorpresa spiacevole.\n\nVuoi saperne di più su Vacation Tracker? Il nostro <https://vacationtracker.crisp.help/en/|Helpdesk> è la tua seconda migliore opzione dopo la <https://vacationtracker.io/request-a-demo/|demo>.\n\nSe le tue domande o dubbi rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: hello@vacationtracker.io",
  "bot.message.accessExpired": "Oh no! Il tuo accesso a Vacation Tracker è scaduto. Contatta il tuo amministratore per ulteriori informazioni.",
  "bot.message.accruedDaysInfoForApprover": "ℹ️ Questa richiesta di ferie si basa su giorni di riposo futuri maturati, non sull'importo attuale. <@{name}> ha {amount} giorno/i rimanente/i oggi. Matureranno {accrued} il {earningDate}.",
  "bot.message.accruedDaysInfoForUser": "ℹ️ La tua richiesta di ferie si basa su giorni di riposo futuri maturati, non sull'importo attuale. Hai {amount} giorno/i rimanente/i oggi, e maturerai {accrued} il {earningDate}.",
  "bot.message.approveLeave": "Approva Ferie",
  "bot.message.approveLeaves.approvedRequest": "✅ Hai approvato la richiesta. Una notifica sarà inviata a <@{userSlackId}> per informarli.",
  "bot.message.approveLeaves.deniedRequest": "❌ Hai rifiutato la richiesta. Una notifica sarà inviata a <@{userSlackId}> per informarli.",
  "bot.message.approver": "*Approvatore:*\n<@{approverSlackId}>",
  "bot.message.approver.autoApproved": "*Approvatore:*\nApprovazione automatica",
  "bot.message.approveToil": "Approva TOIL",
  "bot.message.backToOptions": "Torna alle opzioni",
  "bot.message.blackoutPeriod": "*Periodo di blackout*: {startDate} - {endDate} \n \n_{message}_",
  "bot.message.couldNotProcessRequest": "Ops! Non siamo riusciti a elaborare la tua richiesta.",
  "bot.message.date": "*Data*",
  "bot.message.dateEdited": "*Data [Modificata]*",
  "bot.message.dates": "*Date*",
  "bot.message.datesEdited": "*Date [Modificate]*",
  "bot.message.deniedLeave": "Ferie Negate",
  "bot.message.deniedLeaveDate": "*Data*\n{leaveDate}",
  "bot.message.deniedLeaveDates": "*Date*\n{leaveDate}",
  "bot.message.deniedLeaveLeaveType": "*Tipo di ferie:*\n{leaveTypeName}",
  "bot.message.deniedLeaveWithReason": "Ciao, <@{userSlackId}> ha inviato la seguente richiesta di ferie:",
  "bot.message.deniedToil": "TOIL Negato",
  "bot.message.error.endDateCantBeInPast": "La data di fine non può essere precedente all'inizio, seleziona una data futura",
  "bot.message.error.endTimeCantBeBeforeStartTime": "Sembra che l'ora di inizio selezionata sia successiva all'ora di fine.*\n *Seleziona un'ora di inizio precedente all'ora di fine.",
  "bot.message.error.leaveRequestOverlap": "Le date selezionate si sovrappongono a una ferie esistente.*\n *Seleziona un periodo diverso.",
  "bot.message.error.negativeBallanceNotAllowed": "Il saldo negativo non è consentito per questo tipo di ferie.",
  "bot.message.error.oneHoureIntervalNotAllowed": "L'intervallo di tempo più breve che puoi richiedere per questo tipo di congedo è di 4 ore (mezza giornata).",
  "bot.message.error.partTimeIsntAllowed": "Il part-time non è consentito",
  "bot.message.error.reasonRequired": "Il campo motivo è obbligatorio.",
  "bot.message.error.requestedMoreThanFullDay": "Hai richiesto più di un giorno intero di congedo in una giornata lavorativa.*\n *Si prega di selezionare un giorno diverso.",
  "bot.message.error.startDateCantBeInPast": "La data di inizio non può essere nel passato, si prega di selezionare una data futura",
  "bot.message.error.userCannotBeFound": "L'utente selezionato non può essere trovato nella tua organizzazione.*\n *Si prega di contattare l'amministratore della tua organizzazione o il supporto di Vacation Tracker per ulteriori informazioni.",
  "bot.message.error.userEndDateInFuture": "Le date richieste sono successive alla data di fine impiego dell'utente.*\n *Si prega di contattare l'amministratore della tua organizzazione o il supporto di Vacation Tracker per ulteriori informazioni.",
  "bot.message.error.userStartDateInFuture": "Le date richieste sono precedenti alla data di inizio impiego dell'utente. Si prega di contattare l'amministratore della tua organizzazione o il supporto di Vacation Tracker per ulteriori informazioni.",
  "bot.message.error.zeroDaysLeaveRequest": "Hai selezionato un giorno festivo o non lavorativo.*\n *Si prega di selezionare un intervallo di date valido.",
  "bot.message.feedback.chooseAnOption": "Scegli un'opzione...",
  "bot.message.feedback.form": "Modulo di feedback",
  "bot.message.feedback.haveBeenSend": "Il feedback è stato inviato",
  "bot.message.feedback.howDoYouLikeOurApp": "Come ti piace la nostra app?",
  "bot.message.feedback.info": "Dacci il tuo feedback :heart:",
  "bot.message.feedback.itMakesMeCry": "Mi fa piangere 😢",
  "bot.message.feedback.itsOk": "Va bene 😐",
  "bot.message.feedback.likeIt": "Mi piace 😀",
  "bot.message.feedback.loveIt": "La adoro 😍",
  "bot.message.feedback.message": "Messaggio di feedback",
  "bot.message.feedback.nah": "Nah 🙁",
  "bot.message.feedback.response": "Se il tuo feedback richiede una risposta, inserisci la tua email in modo che possiamo contattarti.",
  "bot.message.feedback.thanks": "Grazie per il feedback! Puoi sempre metterti in contatto con noi usando support@vacationtracker.io.",
  "bot.message.feedback.youEmailAddress": "Il tuo indirizzo email",
  "bot.message.genericError": "Oh no! Si è verificato un errore imprevisto. 😱 Se questo problema persiste, contatta il supporto e fornisci loro il seguente codice di errore: {requestId}.",
  "bot.message.goToTheMyProfileTab": "Vai alla scheda Il Mio Profilo",
  "bot.message.helpMessage": "Ciao,\nCon Vacation Tracker, puoi eseguire una delle seguenti azioni:\n- Invia **Richiedi congedo** in un messaggio diretto per richiedere un nuovo congedo utilizzando il bot di Microsoft Teams.\n- Invia **Richiedi toil** in un messaggio diretto per richiedere un nuovo TOIL utilizzando il bot di Microsoft Teams.\n- Visita la scheda **Il Mio Profilo** per vedere le tue informazioni personali, come i tuoi prossimi congedi e festività, e la tua cronologia dei congedi.\n- Fissa la scheda **Panoramica** a uno dei tuoi canali per vedere la panoramica del team, inclusi i prossimi congedi e festività per il tuo team.\n- Visita la [**Dashboard Web di Vacation Tracker ⧉**]({dashboardLink}/connect?platform=microsoft&redirect=/app/dashboard).\nPer ulteriori aiuti e tutorial, visita il nostro [**Helpdesk ⧉**](https://vacationtracker.crisp.help/en/). Puoi anche programmare una demo personalizzata di Vacation Tracker a questo link: [https://vacationtracker.io/request-a-demo](https://vacationtracker.io/request-a-demo).",
  "bot.message.holidays": "*Festività:*",
  "bot.message.leaveDate": "*Data:*\n{leaveDate}",
  "bot.message.leaveDateEdited": "*Data [Modificata]:*\n{leaveDate}",
  "bot.message.leaveDates": "*Date:*\n{leaveDate}",
  "bot.message.leaveDatesEdited": "*Date [Modificate]:*\n{leaveDate}",
  "bot.message.leaveDuration1": "*{word} richiesti per l'anno corrente:*\n{requestedThisYearNumber}",
  "bot.message.leaveDuration2": "*{word} rimanenti per l'anno corrente:*\n{currentYearNumber}",
  "bot.message.leaveDuration2Unlimited": "*{word} rimanenti per l'anno corrente:*\nIllimitati",
  "bot.message.leaveDuration3": "*{word} richiesti per l'anno prossimo:*\n{requestedNextYearNumber}",
  "bot.message.leaveDuration4": "*{word} rimanenti per l'anno prossimo:*\n{nextYearNumber}",
  "bot.message.leaveDuration4Unlimited": "*{word} rimanenti per l'anno prossimo:*\nIllimitati",
  "bot.message.leaveRemaining": "Congedi Rimanenti",
  "bot.message.leaveRemainingCurrent": "Congedi Rimanenti (Anno Corrente)",
  "bot.message.leaveRemainingDays": "Giorni di Congedo Rimanenti",
  "bot.message.leaveRemainingDaysCurrent": "Giorni di ferie rimanenti (Anno corrente)",
  "bot.message.leaveRemainingDaysNext": "Giorni di ferie rimanenti (Anno successivo)",
  "bot.message.leaveRemainingHours": "Ore di ferie rimanenti",
  "bot.message.leaveRemainingHoursNext": "Ore di ferie rimanenti (Anno successivo)",
  "bot.message.leaveRemainingNext": "Ferie rimanenti (Anno successivo)",
  "bot.message.leaveRequest.FULL-DAY": "{leaveTypeName}",
  "bot.message.leaveRequest.HALF-DAY": "{leaveTypeName} (Mezza giornata)",
  "bot.message.leaveRequest.halfDay": "(Mezza giornata)",
  "bot.message.leaveRequest.hourly": "(Orario)",
  "bot.message.leaveRequest.leaveTypeName": "{leaveTypeName}",
  "bot.message.leaveRequest.leaveTypeNamePartTime": "{leaveTypeName} (Orario)",
  "bot.message.leaveRequest.ONE-HOUR": "{leaveTypeName} (Orario)",
  "bot.message.leaveRequestCreated.error": "La richiesta di ferie non può essere inviata",
  "bot.message.leaveRequestCreated.errorBadTime": "❌ La richiesta di ferie non può essere inviata perché è più lunga dell'orario di lavoro o l'intervallo di tempo non è valido.",
  "bot.message.leaveRequestCreated.errorDateAfter": "❌ La richiesta di ferie non può essere inviata perché la data di inizio selezionata è successiva alla data di fine. Assicurati che la data di inizio sia precedente alla data di fine e invia nuovamente la richiesta.",
  "bot.message.leaveRequestCreated.errorDateInThePast": "❌ La richiesta di ferie non può essere inviata perché la data di inizio selezionata è nel passato. Assicurati di selezionare date future e invia nuovamente la richiesta.",
  "bot.message.leaveRequestCreated.errorDateNotSelected": "❌ La richiesta di ferie non può essere inviata perché non hai selezionato la data di fine o la data non è valida.",
  "bot.message.leaveRequestCreated.errorNoStartDate": "❌ La richiesta di ferie non può essere inviata perché non hai selezionato la data di inizio o la data non è valida.",
  "bot.message.leaveRequestCreated.errorNoStartTime": "❌ La richiesta di ferie non può essere inviata perché non hai selezionato l'ora di inizio o l'ora non è valida.",
  "bot.message.leaveRequestCreated.errorReasonRequired": "❌ La richiesta di ferie non può essere inviata, è necessario specificare un motivo.",
  "bot.message.leaveRequested": "Ferie richieste",
  "bot.message.leaveRequestedCurrent": "Ferie richieste (Anno corrente)",
  "bot.message.leaveRequestedDays": "Giorni di ferie richiesti",
  "bot.message.leaveRequestedDaysCurrent": "Giorni di ferie richiesti (Anno corrente)",
  "bot.message.leaveRequestedDaysNext": "Giorni di ferie richiesti (Anno successivo)",
  "bot.message.leaveRequestedHours": "Ore di ferie richieste",
  "bot.message.leaveRequestedHoursCurrent": "Ore di ferie richieste (Anno corrente)",
  "bot.message.leaveRequestedHoursNext": "Ore di ferie richieste (Anno successivo)",
  "bot.message.leaveRequestedNext": "Ferie richieste (Anno successivo)",
  "bot.message.leaveRequestNext.endDate": "Data di fine:",
  "bot.message.leaveRequestNext.info": "Ottimo! 🐨 Seleziona il periodo di tempo e invia la richiesta.",
  "bot.message.leaveRequestNext.isHalfDay": "{leaveTypeName} (Mezza giornata)",
  "bot.message.leaveRequestNext.leaveTime": "Orario di ferie:",
  "bot.message.leaveRequestNext.reasonOptional": "Motivo (Facoltativo)",
  "bot.message.leaveRequestNext.reasonRequired": "Motivo (Obbligatorio)",
  "bot.message.leaveRequestNext.startDate": "Data di inizio:",
  "bot.message.leaveRequestNext.title": "Richiedi ferie",
  "bot.message.leaves": "*Ferie:*",
  "bot.message.leavesHistory": "La tua cronologia delle ferie:",
  "bot.message.leavesHistory.leaveMessageFullDay": "- Ferie (*{leaveTypeName}*) dal {start} al {end} *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOn": "- Permesso (*{leaveTypeName}*) il {start} *[{leaveStatus}]*\n",
  "bot.message.leavesHistory.leaveMessageOnPartDay": "- Permesso (*{leaveTypeName}*) il {start} dalle {partDayStartHour} alle {partDayEndHour} *[{leaveStatus}]*\n",
  "bot.message.leavesHistoryNot": "Hm, sembra che tu non abbia ancora avuto alcun permesso.",
  "bot.message.leaveTypeName": "*Tipo di permesso:*\n{leaveTypeName}",
  "bot.message.leaveTypeWithHalfDay": "*Tipo di permesso:*\n{leaveTypeName} (mezza giornata)",
  "bot.message.manageLeaves.cancelRequest": "Annulla Richiesta",
  "bot.message.manageLeaves.confirmCancellation": "Per favore conferma che vuoi annullare la tua richiesta di permesso",
  "bot.message.manageLeaves.summary": "Ecco il riepilogo dei tuoi prossimi permessi e delle richieste in sospeso.",
  "bot.message.manageLeaves.summaryNoLeaves": "Non hai prossimi permessi programmati",
  "bot.message.manageLeaves.upcomingLeaves": "*Hai {upcomingLeaves} richiesta confermata:*",
  "bot.message.manageLeaves.upcomingLeavesPlural": "*Hai {upcomingLeaves} richieste confermate:*",
  "bot.message.manageLeaves.waitingLeaves": "*Hai {waitingLeaves} richiesta in sospeso:*",
  "bot.message.manageLeaves.waitingLeavesPlural": "*Hai {waitingLeaves} richieste in sospeso:*",
  "bot.message.membersAway": "Membri assenti",
  "bot.message.membersAway.holiday": "- *{holidayLocationName}* sarà in ferie per *{holidayName}* il {date}.\n",
  "bot.message.membersAway.leaveMessage": "- *{user}* è in permesso{leaveTypeName} oggi {partDay}\n",
  "bot.message.membersAway.leaveMessage.fullDay": "- *{user}* è in permesso{leaveTypeName} dal {startDate} al {endDate}\n",
  "bot.message.membersAway.leaveMessageWasWillBe": "- *{user}* {verb} in permesso{leaveTypeName} il {startDate}{partDay}\n",
  "bot.message.membersAway.leaveMessageWasWillBe.fullDay": "- *{user}* {verb} in permesso{leaveTypeName} dal {startDate} al {endDate}\n",
  "bot.message.membersAway.MEMBERS_AWAY_MONTH": "Membri del team che sono assenti questo mese.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_MONTH": "Membri del team che saranno assenti il prossimo mese.",
  "bot.message.membersAway.MEMBERS_AWAY_NEXT_WEEK": "Membri del team che saranno assenti la prossima settimana.",
  "bot.message.membersAway.MEMBERS_AWAY_TODAY": "Membri del team che sono assenti oggi.",
  "bot.message.membersAway.MEMBERS_AWAY_WEEK": "Membri del team che sono assenti questa settimana.",
  "bot.message.membersAway.multiHoliday": "- *{holidayLocationName}* sarà in ferie per *{holidayName}* dal {start} al {end}.\n",
  "bot.message.membersAway.noLeaves": "Al momento non ci sono permessi programmati per questo periodo nel tuo team 😎.",
  "bot.message.memersAway.noLeaves": "Al momento non ci sono permessi programmati per questo periodo nel tuo team 😎.",
  "bot.message.menageLeaves": "Gestisci Permessi",
  "bot.message.moreOptions": "Ciao <@{username}>!\nCome posso aiutarti?",
  "bot.message.moreOptions.about": "Più informazioni su di me :koala:",
  "bot.message.moreOptions.leaveHistoryTitle": "Cronologia Permessi",
  "bot.message.moreOptions.leavesAndHolidaysTitle": "I tuoi permessi e festività:",
  "bot.message.moreOptions.membersAway": "Vedi quali membri del team sono assenti:",
  "bot.message.moreOptions.menageLeavesTitle": "Gestisci Permessi",
  "bot.message.moreOptions.showHolidaysTitle": "Mostra Festività",
  "bot.message.myStatus.currentYear": "_Anno corrente, fino al {yearEnd}_",
  "bot.message.myStatus.leaveTypeStatusEarned": "*{leaveTypeName}*\nRimanenti: {current}\nUtilizzati: {used}\nTotale: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedDays": "*{leaveTypeName}*\nGiorni rimanenti: {current}\nGiorni utilizzati: {used}\nGiorni totali: {earned}",
  "bot.message.myStatus.leaveTypeStatusEarnedHours": "*{leaveTypeName}*\nOre rimanenti: {current}\nOre utilizzate: {used}\nOre totali: {earned}",
  "bot.message.myStatus.leaveTypeStatusUnlimited": "*{leaveTypeName}*\nGiorni rimanenti: Illimitati\nGiorni utilizzati: {usedDays}\nGiorni totali: Illimitati",
  "bot.message.myStatus.nextYear": "_Anno successivo, dal {startYear} fino al {endYear}_",
  "bot.message.myStatus.summary": "Ecco il riepilogo dei tuoi congedi per l'anno corrente e il prossimo.",
  "bot.message.noLeavePolicies": "Sembra che la tua Sede non abbia politiche di congedo assegnate. 🤔 Contatta il tuo Amministratore per maggiori informazioni.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYear": "L'utente sta richiedendo {leaveDurationTotal} giorni di congedo, che sono {overLimitCurrentYear} giorno oltre il limite che l'utente ha per l'anno corrente, e {overLimitNextYear} giorno oltre il limite per l'anno successivo.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearDays": "L'utente sta richiedendo {leaveDurationTotal} {leaveDurationTotal, plural, =1 {giorno} other {giorni}}, che sono {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {giorno} other {giorni}} oltre il limite che l'utente ha per l'anno corrente, e {overLimitNextYear} {overLimitNextYear, plural, =1 {giorno} other {giorni}} oltre il limite per l'anno successivo.",
  "bot.message.notification.overAllowedLimitForCurrentAndFollowingYearHours": "L'utente sta richiedendo {leaveDurationTotal} {leaveDurationTotal, plural, =1 {ora} other {ore}}, che sono {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {ora} other {ore}} oltre il limite che l'utente ha per l'anno corrente, e {overLimitNextYear} {overLimitNextYear, plural, =1 {ora} other {ore}} oltre il limite per l'anno successivo.",
  "bot.message.notification.overAllowedLimitForCurrentYear": "L'utente sta richiedendo {leaveDurationCurrentYear} giorno di congedo, che è {overLimit} giorno oltre il limite consentito per questo utente per l'anno corrente.",
  "bot.message.notification.overAllowedLimitForCurrentYearDays": "L'utente sta richiedendo {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {giorno} other {giorni}}, che sono {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {giorno} other {giorni}} oltre il limite consentito per questo utente per l'anno corrente.",
  "bot.message.notification.overAllowedLimitForCurrentYearHours": "L'utente sta richiedendo {leaveDurationCurrentYear} {leaveDurationCurrentYear, plural, =1 {ora} other {ore}}, che sono {overLimitCurrentYear} {overLimitCurrentYear, plural, =1 {ora} other {ore}} oltre il limite consentito per questo utente per l'anno corrente.",
  "bot.message.notification.overAllowedLimitForCurrentYearPlural": "L'utente sta richiedendo {leaveDurationCurrentYear} giorni di congedo, che sono {overLimit} giorno oltre il limite consentito per questo utente per l'anno corrente.",
  "bot.message.notification.overAllowedLimitForFollowingYear": "L'utente sta richiedendo {leaveDurationNextYear} giorno di congedo, che è {overLimit} giorno oltre il limite consentito per questo utente per il prossimo anno.",
  "bot.message.notification.overAllowedLimitForFollowingYearDays": "L'utente sta richiedendo {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {giorno} other {giorni}}, che sono {overLimitNextYear} {overLimitNextYear, plural, =1 {giorno} other {giorni}} oltre il limite consentito per questo utente per il prossimo anno.",
  "bot.message.notification.overAllowedLimitForFollowingYearHours": "L'utente sta richiedendo {leaveDurationNextYear} {leaveDurationNextYear, plural, =1 {ora} other {ore}}, che sono {overLimitNextYear} {overLimitNextYear, plural, =1 {ora} other {ore}} oltre il limite consentito per questo utente per il prossimo anno.",
  "bot.message.notification.overAllowedLimitForFollowingYearPlural": "L'utente sta richiedendo {leaveDurationNextYear} giorni di congedo, che sono {overLimit} giorno oltre il limite consentito per questo utente per il prossimo anno.",
  "bot.message.notSignedUpError": "Sembra che tu non sia registrato a Vacation Tracker. Per favore vai su [{link}]({utmLink}) e crea un account prima. Ci vorranno meno di un minuto!",
  "bot.message.notSignedUpError.slack": "Sembra che tu non sia registrato a Vacation Tracker. Per favore clicca sul pulsante qui sotto per creare un account. Ci vorranno meno di un minuto!",
  "bot.message.on": "Il",
  "bot.message.openTheWebDashboard": "Apri la Dashboard Web",
  "bot.message.partDay": "dalle {partDayStartHour} alle {partDayEndHour}",
  "bot.message.probationPeriod": "*Periodo di prova*: \nIl tuo periodo di prova dura fino al {date} \n \nMotivo: _{message}_",
  "bot.message.reminder": "Ehi team! Un promemoria amichevole dal bot di Vacation Tracker",
  "bot.message.reminderSnoozed": "Promemoria delle richieste posticipati",
  "bot.message.requestLeave": "Per favore seleziona uno dei tipi di congedo disponibili dall'elenco qui sotto, e seleziona le date di inizio e fine del tuo congedo. Quando clicchi sul pulsante Invia richiesta, la tua richiesta sarà inviata per l'approvazione.",
  "bot.message.requestLeave.error": "*Errore: {errorMessage}*",
  "bot.message.requestLeave.errorAutomation": "{errorMessage}",
  "bot.message.requestLeave.initialHelp": "Sono qui per aiutarti a inviare una richiesta di congedo. 🐨 Per favore, seleziona un Tipo di Congedo, poi clicca su Avanti.",
  "bot.message.requestLeave.leaveDuration1": "Questo è 1 giorno di congedo.",
  "bot.message.requestLeave.leaveDurationHalfDay": "Questo è un mezzo giorno di congedo",
  "bot.message.requestLeave.leaveDurationYear1": "Questo è {year1Value} di congedo.",
  "bot.message.requestLeave.leaveDurationYear1And2": "Questo è {year1Value} di congedo per l'anno corrente e {year2Value} di congedo per il prossimo anno.",
  "bot.message.requestLeave.leaveDurationYear1And2Days": "Questi sono {year1Value} {year1Value, plural, =1 {giorno} other {giorni}} di congedo per l'anno corrente e {year2Value} {year2Value, plural, =1 {giorno} other {giorni}} di congedo per il prossimo anno.",
  "bot.message.requestLeave.leaveDurationYear1And2Hours": "Queste sono {year1Value} {year1Value, plural, =1 {ora} other {ore}} di congedo per l'anno corrente e {year2Value} {year2Value, plural, =1 {ora} other {ore}} di congedo per il prossimo anno.",
  "bot.message.requestLeave.leaveDurationYear1Days": "Questi sono {year1Value} {year1Value, plural, =1 {giorno} other {giorni}} di congedo.",
  "bot.message.requestLeave.leaveDurationYear1Hours": "Queste sono {year1Value} {year1Value, plural, =1 {ora} other {ore}} di congedo.",
  "bot.message.requestLeave.leaveDurationYear1PluralYear2": "Questi sono {year1Days} giorni di congedo per l'anno corrente e {year2Days} giorno di congedo per il prossimo anno.",
  "bot.message.requestLeave.leaveDurationYear1Year2AllPlural": "Questi sono {year1Days} giorni di congedo per l'anno corrente e {year2Days} giorni di congedo per il prossimo anno.",
  "bot.message.requestLeave.leaveDurationYear2": "Questo è {year2Value} di congedo per il prossimo anno.",
  "bot.message.requestLeave.leaveDurationYear2Days": "Questi sono {year2Value} {year2Value, plural, =1 {giorno} other {giorni}} di congedo per il prossimo anno.",
  "bot.message.requestLeave.leaveDurationYear2Hours": "Queste sono {year2Value} {year2Value, plural, =1 {ora} other {ore}} di congedo per il prossimo anno.",
  "bot.message.requestLeave.leaveDurationYear2Plural": "Questi sono {year2Days} giorni di congedo per il prossimo anno.",
  "bot.message.requestLeave.leaveDurationYear2PluralYear1": "Sono {year1Days} giorno di ferie per l'anno in corso e {year2Days} giorni di ferie per l'anno successivo",
  "bot.message.requestLeave.selectCalendarStatus": "Seleziona lo stato del calendario",
  "bot.message.requestLeave.selectCalendarStatus.info": "*Stato del calendario*\nMostra nel tuo calendario come",
  "bot.message.requestLeave.selectDate.from": "*Data di inizio*\nSeleziona una data di inizio",
  "bot.message.requestLeave.selectDate.info": "*Data di inizio*\nSeleziona una data di inizio",
  "bot.message.requestLeave.selectDate.oneDay": "*Data*\nSeleziona una data",
  "bot.message.requestLeave.selectDate.to": "*Data di fine*\nSeleziona una data di fine",
  "bot.message.requestLeave.selectLeaveType": "Seleziona il tipo di ferie",
  "bot.message.requestLeave.selectLeaveType.info": "*Tipo di ferie*\nSeleziona il tipo di ferie",
  "bot.message.requestLeave.selectTimeFormat": "*AM/PM*",
  "bot.message.requestLeave.selectTimeRange": "Seleziona l'intervallo di tempo",
  "bot.message.requestLeave.selectTimeRange.end": "*Data di fine*\nSeleziona una data di fine",
  "bot.message.requestLeave.selectTimeRange.from": "*Ora di inizio*",
  "bot.message.requestLeave.selectTimeRange.info": "*Intervallo di tempo*\nSeleziona l'intervallo di tempo",
  "bot.message.requestLeave.selectTimeRange.range": "*Intervallo di tempo*",
  "bot.message.requestLeave.selectTimeRange.to": "*Ora di fine*",
  "bot.message.requestLeave.submitted.end": ":tada: *{message}*.\nAlla prossima!",
  "bot.message.requestLeave.submitted.letTeamKnow": "P.S. Fai sapere al mio team se sono stato utile!",
  "bot.message.requestLeave.submitted.withApproval": "Le date che hai richiesto sono state inviate al tuo manager per l'approvazione",
  "bot.message.requestLeave.submitted.withoutApproval": "Le date che hai richiesto sono state approvate automaticamente",
  "bot.message.requestLeaveModal.requestALeave": "Richiedi un permesso",
  "bot.message.requestLeaveModal.requestingLeave": "Richiesta di permesso in corso",
  "bot.message.requestLeaveModal.sendFeedback": "Invia feedback",
  "bot.message.requestLeaveModal.sendRequest": "Invia richiesta",
  "bot.message.requestToil.initialHelp": "Sono qui per aiutarti a inviare una richiesta di TOIL. 🐨 Per favore, seleziona un tipo di permesso, poi clicca su Avanti.",
  "bot.message.selectADate": "Seleziona una data",
  "bot.message.selectSubstituteApprover": "*Seleziona un sostituto approvatore*\nSeleziona una persona per gestire le tue richieste di permesso in entrata mentre sei assente.",
  "bot.message.showHolidays.holidaysHalfDay": "- *{holidayLocationName}* sarà chiuso per *{holidayName}* il {date} (Mezza giornata).\n",
  "bot.message.showHolidays.noUpcomingHolidays": "Non ci sono prossime festività nella tua organizzazione o il tuo amministratore non ha ancora impostato alcuna festività.",
  "bot.message.showHolidays.one": "- *{holidayLocationName}* sarà chiuso per *{holidayName}* dal {startDate} al {endDate}.\n",
  "bot.message.showHolidays.two": "- *{holidayLocationName}* sarà chiuso per *{holidayName}* il {date}.\n",
  "bot.message.showHolidays.upcomingHolidays": "Prossime festività",
  "bot.message.slack.userChanged": "L'utente ha modificato questa richiesta",
  "bot.message.slashCommand.helpCommand1": "*Ecco alcune informazioni in più su di me:*",
  "bot.message.slashCommand.helpCommand2": "Utilizzato da oltre 1000 organizzazioni in tutto il mondo, *Vacation Tracker permette al tuo team di tracciare senza sforzo chi è in ufficio e chi è fuori in ogni momento.*",
  "bot.message.slashCommand.helpCommand3": "Scopri di più sulla nostra storia nella nostra <https://vacationtracker.io/about|pagina Chi siamo>.",
  "bot.message.slashCommand.helpCommand4": "Per guide passo-passo, visita il nostro <https://vacationtracker.crisp.help/en/|Helpdesk>.",
  "bot.message.slashCommand.helpCommand5": "Per accedere alla Dashboard online, visita <{dashboardUrl}/connect?platform=slack>.",
  "bot.message.slashCommand.helpCommand6": "E se mai avessi bisogno di noi lungo il percorso, siamo a solo un'email di distanza a hello@vacationtracker.io.",
  "bot.message.slashCommand.noPermissions": "Mi dispiace essere portatore di cattive notizie, ma sembra che tu non abbia il permesso di accedere a Vacation Tracker. 😟 Per favore, contatta il tuo Amministratore.",
  "bot.message.slashCommand.title": "Ciao <@{userId}>! Vuoi prendere un po' di ferie? Lascia che ti aiuti!",
  "bot.message.sorryLetsSpokePriveate": "Hmm, parliamo in privato. 🤫",
  "bot.message.sorryYouAreNotActive": "Mi dispiace, il tuo account non è attivo",
  "bot.message.sorryYouAreNotExist": "Mi dispiace, il tuo account non è stato trovato",
  "bot.message.submittedLeaveRequest": "Ciao, <@{userSlackId}> ha inviato la seguente richiesta di ferie:",
  "bot.message.submittedLeaveRequestReminder": "Ciao, solo un promemoria che <@{name}> ha inviato una richiesta che richiede la tua attenzione.",
  "bot.message.submittedLeaveRequestReminderExpiration": "⏰ Attenzione, questa richiesta scadrà tra {days} giorno/i. Questo è il promemoria {reminderNo} di 3.",
  "bot.message.substituteApproverPlaceholder": "🔍 Nessuno",
  "bot.message.unableToRequestLeave": "Impossibile richiedere ferie.",
  "bot.message.unknownCommand": "Mi dispiace, non capisco questo comando.\nDigita **help** per vedere l'elenco dei comandi disponibili.",
  "bot.microsoft.whatsNewInfoMessages": "Questo messaggio è un annuncio da **Vacation Tracker**. Lo stai ricevendo perché sei un amministratore o un approvatore.",
  "bot.notification.accruedDaysInfoForApprover": "ℹ️ Questa richiesta di ferie è basata su giorni di ferie futuri maturati, non sulla quantità attuale. @{name} ha {amount} giorno/i rimanente/i oggi. Maturerà {accrued} il {earningDate}.",
  "bot.notification.accruedDaysInfoForUser": "ℹ️ La tua richiesta di ferie è basata su giorni di ferie futuri maturati, non sulla quantità attuale. Hai {amount} giorno/i rimanente/i oggi, e maturerai {accrued} il {earningDate}.",
  "bot.notification.adminToApprovers": "Sei stato nominato nuovo Approvatore per {teamName}, poiché l'account del precedente Approvatore è stato disattivato.",
  "bot.notification.approverCard.approved": "✅ @{approverName} ha approvato la richiesta, non sono necessarie ulteriori azioni.",
  "bot.notification.approverCard.autoApproved": "La richiesta è stata approvata automaticamente, non sono necessarie ulteriori azioni.",
  "bot.notification.approverCard.cancelled": "⚠️ Sembra che {requesterName} abbia cambiato idea. La richiesta è stata annullata.",
  "bot.notification.approverCard.cancelledLeave": "Ciao, {requesterName} ha annullato le sue ferie.",
  "bot.notification.approverCard.cancelledTitle": "⚠️ Queste ferie sono state annullate da un approvatore.",
  "bot.notification.approverCard.denied": "❌ @{approverName} ha rifiutato la richiesta, non sono necessarie ulteriori azioni.",
  "bot.notification.approverCard.deniedEdited": "❌ @{approverName} ha rifiutato la richiesta modificata, non sono necessarie ulteriori azioni.",
  "bot.notification.approverCard.editedByApprover": "@{approverName} ha modificato una richiesta di ferie inviata da @{requesterName}",
  "bot.notification.approverCard.editedByApproverForUser": "@{approverName} ha modificato una richiesta di ferie per @{requesterName}.",
  "bot.notification.approverCard.info": "Aggiungi un motivo per questa approvazione o rifiuto",
  "bot.notification.approverCard.notActive": "⚠️ Questa richiesta di ferie non è più attiva.",
  "bot.notification.changeOutOfOfficeMessage": "Modifica modello",
  "bot.notification.daily.info": "È ora del tuo aggiornamento giornaliero! 🐨 Ecco le prossime ferie:",
  "bot.notification.daily.noLeaves.isNotForToday": "Non ci sono ferie programmate per {dayName}",
  "bot.notification.daily.noLeaves.isToday": "Non ci sono ferie programmate per oggi",
  "bot.notification.holiday.plural": "- Le sedi **{locationNames}** saranno chiuse per **{holidayName}** {holidayPeriodText}",
  "bot.notification.holiday.singular": "- La sede **{locationNames}** sarà chiusa per **{holidayName}** {holidayPeriodText}",
  "bot.notification.leave.info.isInTheFuture": "sarà in ferie{leaveTypeText} {leavePeriodText}",
  "bot.notification.leave.info.isInThePast": "sarà in ferie{leaveTypeText} {leavePeriodText}",
  "bot.notification.leave.info.isToday": "è in ferie{leaveTypeText} {leavePeriodText}",
  "bot.notification.leaveDates.partDay": "{start} dalle {partDayStartHour} alle {partDayEndHour}",
  "bot.notification.leavePeriod.fullDay": "il {day}",
  "bot.notification.leavePeriod.halfDay": "il {day} (Mezza Giornata)",
  "bot.notification.leavePeriod.moreDays": "dal {start} al {end}",
  "bot.notification.leavePeriod.partDay": "il {start} dalle {partDayStartHour} alle {partDayEndHour}",
  "bot.notification.leavePolicyInfo": "*Nota bene: questo utente o questo tipo di ferie ha una quota impostata a 0 per le ferie richieste.*",
  "bot.notification.leaveRequestError.general": "Errore con una richiesta di permesso",
  "bot.notification.leaveRequestError.notApprover": "Questa azione è consentita solo agli approvatori/amministratori del team",
  "bot.notification.leaveRequestError.notOpen": "La richiesta di permesso è stata {leaveRequestStatus}",
  "bot.notification.leaveRequestError.notOpenIsSameAppover": "La richiesta di permesso è stata {leaveRequestStatus}",
  "bot.notification.leaveRequestExpired": "La tua richiesta è scaduta",
  "bot.notification.leaveRequestExpiredGeneral": "La richiesta è scaduta",
  "bot.notification.leaveTypeText.isPartDay": "({leaveTypeName} - Mezza giornata)",
  "bot.notification.oooDefaultMessage": "Ciao,\n\nGrazie per il tuo messaggio. Attualmente sono fuori ufficio e non sarò disponibile a rispondere alle email fino al {firstWorkingDay}.\n\nFarò del mio meglio per rispondere alla tua email tempestivamente al mio ritorno.\n\nGrazie per la comprensione.\n\nCordiali saluti",
  "bot.notification.processingApproval": "Elaborazione dell'approvazione...",
  "bot.notification.processingDenial": "Elaborazione del rifiuto...",
  "bot.notification.processingResend": "Reinvio della richiesta di permesso...",
  "bot.notification.processingSnooze": "Posticipo dei promemoria...",
  "bot.notification.reconnectCalendar": "Riconnetti",
  "bot.notification.reminderSent": "Promemoria della richiesta di permesso inviato",
  "bot.notification.reminderSentToil": "Promemoria della richiesta TOIL inviato",
  "bot.notification.requesterCard.addedLeave": "Il permesso è stato creato per te",
  "bot.notification.requesterCard.addedLeaveBy": "Aggiunto da {approverName}",
  "bot.notification.requesterCard.addedLeaveToil": "Il TOIL è stato creato per te",
  "bot.notification.requesterCard.approvedTitle": "La tua richiesta di permesso è stata approvata",
  "bot.notification.requesterCard.approvedTitleToil": "La tua richiesta TOIL è stata approvata",
  "bot.notification.requesterCard.approverInfo": "Approvato da {approverName}",
  "bot.notification.requesterCard.autoApprovedInfo": "La richiesta è stata approvata automaticamente.",
  "bot.notification.requesterCard.blackoutPeriod": "Periodo: {startDate} - {endDate}",
  "bot.notification.requesterCard.blackoutPeriodReason": "Motivo: {message}",
  "bot.notification.requesterCard.blackoutPeriodTitle": "Periodo di Blackout",
  "bot.notification.requesterCard.cancelled": "⚠️ Annullato",
  "bot.notification.requesterCard.cancelledEdited": "⚠️ Sono state apportate modifiche alla richiesta originale.",
  "bot.notification.requesterCard.cancelledEditedByRequester": "@{requesterName} ha modificato questa richiesta di permesso.",
  "bot.notification.requesterCard.cancelledTitle": "⚠️ Il tuo permesso è stato annullato da un approvatore.",
  "bot.notification.requesterCard.deniedBy": "La tua richiesta di permesso è stata approvata",
  "bot.notification.requesterCard.deniedEditedDescription": "La tua richiesta di permesso modificata è stata rifiutata, tuttavia, la tua richiesta originale rimane attiva.",
  "bot.notification.requesterCard.deniedEditedTitle": "Modifica della richiesta di permesso rifiutata.",
  "bot.notification.requesterCard.deniedOpenDescription": "Sia la tua richiesta di permesso originale che quella modificata sono state rifiutate.",
  "bot.notification.requesterCard.deniedOpenTitle": "Richiesta di permesso rifiutata",
  "bot.notification.requesterCard.deniedTitle": "La tua richiesta di permesso è stata rifiutata",
  "bot.notification.requesterCard.edited": "@{requesterName} ha modificato la sua richiesta precedentemente approvata e sta ri-sottomettendo per l'approvazione:",
  "bot.notification.requesterCard.editedOpen": "@{requesterName} ha modificato e ri-sottomesso la sua richiesta di permesso.",
  "bot.notification.requesterCard.moreDays": "❌ Hai richiesto più giorni di quelli disponibili.",
  "bot.notification.requesterCard.moreThanFullDay": "❌ La richiesta di permesso non può essere inoltrata perché hai richiesto più di un giorno intero di permesso in una giornata lavorativa.",
  "bot.notification.requesterCard.noWorkingDays": "❌ La richiesta di permesso non può essere inoltrata perché non hai incluso alcun giorno lavorativo. Assicurati di rivedere i giorni lavorativi del tuo team prima di inoltrare la richiesta.",
  "bot.notification.requesterCard.originalLeaveRequest": "Richiesta di permesso originale:",
  "bot.notification.requesterCard.overlap": "❌ La richiesta di permesso non può essere inviata perché le date richieste si sovrappongono con le date di una richiesta di permesso esistente.",
  "bot.notification.requesterCard.probationPeriod": "Il tuo periodo di prova dura fino al {date}",
  "bot.notification.requesterCard.probationPeriodReason": "Motivo: {message}",
  "bot.notification.requesterCard.probationPeriodTitle": "Periodo di Prova",
  "bot.notification.requesterCard.requestInfo": "La tua richiesta è in viaggio verso l'approvatore. Alla prossima! 🐨",
  "bot.notification.requesterCard.requestPossibleActions": "Per modificare o annullare la richiesta, visita la Dashboard",
  "bot.notification.requesterCardapp.tryAnotherDates": "Prova altre date",
  "bot.notification.requesterInfo": "Ciao, @{userName} ha inviato una richiesta da esaminare.",
  "bot.notification.requesterInfoAutoApproved": "Ciao, @{userName} ha inviato una richiesta.",
  "bot.notification.requesterInfoEdited": "@{userName} ha modificato e rinviato la sua richiesta.",
  "bot.notification.requesterInfoEditedByApprover": "@{approverName} ha modificato una richiesta inviata da @{userName}",
  "bot.notification.requesterInfoEditedForUser": "La tua richiesta è stata modificata.",
  "bot.notification.requesterInfoEditedForUserByApprover": "L'approvatore @{approverName} ha apportato modifiche alla tua richiesta.",
  "bot.notification.requesterInfoEditedOpen": "@{userName} ha modificato e rinviato la sua richiesta.",
  "bot.notification.setOutOfOffice": "Il tuo permesso {leaveTypeName} è programmato per iniziare tra meno di {days} {days, plural, =1 {giorno} other {giorni}} il {leavePeriodStart}. Vuoi impostare un messaggio automatico di fuori ufficio da inviare durante la tua assenza?",
  "bot.notification.setOutOfOfficeButton": "Imposta messaggio OOO",
  "bot.notification.setOutOfOfficeFallbackText": "Imposta Fuori Ufficio per il tuo {leaveTypeName}",
  "bot.notification.slack.additional": "... e altro ancora.\nUlteriori permessi non possono essere visualizzati a causa del limite di dimensioni. Apri la Dashboard per una panoramica completa.",
  "bot.notification.slack.approved": "✅ <@{approverName}> ha approvato la richiesta, non sono necessarie ulteriori azioni.",
  "bot.notification.slack.approverChanged": "Ciao, l'approvatore <@{approverSlackId}> ha modificato una richiesta di permesso.",
  "bot.notification.slack.approverChangedDatesOfLeaveReuquest": "L'approvatore <@{approverSlackId}> ha modificato le date della richiesta",
  "bot.notification.slack.approverChangedLeaveReqest": "Ciao, l'approvatore <@{approverSlackId}> ha modificato la seguente richiesta di permesso per <@{userSlackId}>:",
  "bot.notification.slack.approverChangedYour": "L'approvatore <@{approverSlackId}> ha modificato la tua richiesta di permesso.",
  "bot.notification.slack.approverHasDeniedThisRequest": "<@{approverSlackId}> ha negato questa richiesta, non sono necessarie ulteriori azioni",
  "bot.notification.slack.calendar": ":calendar: |  *{dayName}*  | :calendar: ",
  "bot.notification.slack.cancelled": "Ciao, <@{userName}> ha annullato il suo permesso.",
  "bot.notification.slack.cancelledByApprover": "Il tuo permesso è stato annullato da un approvatore",
  "bot.notification.slack.cancelledInfo": "Questo permesso è stato annullato da un approvatore",
  "bot.notification.slack.created": "Un permesso è stato creato per te da un approvatore",
  "bot.notification.slack.holiday.plural": "- Le sedi *{locationNames}* saranno assenti per *{holidayName}* {holidayPeriodText}",
  "bot.notification.slack.holiday.singular": "- La sede *{locationNames}* sarà assente per *{holidayName}* {holidayPeriodText}",
  "bot.notification.slack.holidays": ":airplane: *Festività: *",
  "bot.notification.slack.leave.info.isInTheFuture": "- *{userName}* sarà in permesso{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leave.info.isInThePast": "- *{userName}* era in permesso{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leave.info.isToday": "- *{userName}* è in permesso{leaveTypeText} {leavePeriodText}",
  "bot.notification.slack.leaveRequestCreated": "Ciao, <@{userName}> ha inviato una richiesta di permesso.",
  "bot.notification.slack.leaveRequestInfo": "Ciao, <@{userName}> per favore conferma le informazioni sulla richiesta di permesso.",
  "bot.notification.slack.leaveRequestInfoDateFrom": "Data di inizio: {date}",
  "bot.notification.slack.leaveRequestInfoDateTo": "Data di fine: {date}",
  "bot.notification.slack.leaveRequestInfoError": "Non siamo riusciti a suggerire un congedo dai dati forniti",
  "bot.notification.slack.leaveRequestInfoLeaveType": "Tipo di congedo: {leaveType}",
  "bot.notification.slack.leaveRequestInfoReason": "Motivo: {reason}",
  "bot.notification.slack.leaveRequestSuggestionCancelled": "Modulo di richiesta congedo annullato ❌",
  "bot.notification.slack.leaves": ":loud_sound: *Congedi: *",
  "bot.notification.slack.reason": "*Motivo*\n{reason}",
  "bot.notification.slack.reasonEdited": "*Motivo [Modificato] *\n{reason}",
  "bot.notification.slack.requestedBy": "*Richiesto da:*\n<@{userSlackId}>",
  "bot.notification.slack.statusApproved": "*Stato:*\n✅ Approvato",
  "bot.notification.slack.statusAutoApproved": "*Stato*\nApprovazione automatica",
  "bot.notification.slack.statusDenied": "*Stato*\n❌ Rifiutato",
  "bot.notification.slack.statusInfo": "👋 Ora possiamo impostare automaticamente il tuo stato Slack per questo tipo di congedo! Clicca il pulsante Abilita per permetterci di aggiornare il tuo stato quando inizia il tuo congedo. Lo cancelleremo quando finisce.",
  "bot.notification.slack.thisLeaveHasBeenCancelledByAppover": "Questo congedo è stato annullato da un approvatore. Vedi sotto per ulteriori informazioni.",
  "bot.notification.slack.title": "Notifica: \"_{title}_\"",
  "bot.notification.slack.userCancelledLeaveRequest": "Sembra che <@{userSlackId}> abbia cambiato idea. La richiesta è stata annullata.",
  "bot.notification.slack.warning": ":warning: *Attenzione: {infoMessage}*",
  "bot.notification.slack.yourLeaveHasBeenCancelledByAppover": "Il tuo congedo è stato annullato da un approvatore. Vedi sotto per ulteriori informazioni.",
  "bot.notification.slack.yourLeaveRequestApproved": "La tua richiesta è stata approvata",
  "bot.notification.slack.yourRequestHasBeenDenied": "La tua richiesta è stata rifiutata",
  "bot.notification.snoozeOutOfOfficeButton": "Non chiedermelo più",
  "bot.notification.snoozeOutOfOfficeMessage": "Non ti chiederemo più di impostare l'assenza per {leaveTypeName}. Puoi sempre modificare questa impostazione nella pagina Impostazioni account della Dashboard Web di Vacation Tracker",
  "bot.notification.submittedLeaveRequestReminder": "Ciao, solo un promemoria che @{name} ha inviato una richiesta che richiede la tua attenzione.",
  "bot.notification.substituteApproverDeleted": "Il tuo ruolo di Approvatore Sostituto per le date dal {startDate} al {endDate} è stato rimosso.",
  "bot.notification.substituteApproverSelected": "Sei stato selezionato da {creatorName} come approvatore sostituto per il/i dipartimento/i {teamNames} per conto di {approverName} durante la sua assenza. Come approvatore sostituto, sarai responsabile di esaminare e agire sulle richieste di congedo inviate a {approverName} dal {startDate} al {endDate}.",
  "bot.notification.substituteApproverUpdated": "Il tuo periodo come Approvatore Sostituto è stato aggiornato dal {startDate} al {endDate}.",
  "bot.notification.title": "Notifica: {title}",
  "bot.notification.weekly.current.info": "Ciao!\n 🐨 Sono qui per aggiornarti sui congedi programmati per la settimana corrente ({start} - {end})",
  "bot.notification.weekly.next.info": "Ciao!\n 🐨 Sono qui per aggiornarti sui congedi programmati per la prossima settimana ({start} - {end})",
  "bot.notification.weekly.noHolidays": "Non ci sono festività per questa settimana.",
  "bot.notification.weekly.noLeaves": "Non ci sono congedi programmati per questa settimana.",
  "bot.slack.whatsNewInfoMessages": "Questo messaggio è un annuncio da *Vacation Tracker*. Lo stai ricevendo perché sei un amministratore o un approvatore.",
  "bot.slackFuckedUpAgain": "Slack ha sistemato qualcosa di nuovo, per favore contatta il nostro supporto per risolvere i bug di Slack.",
  "bot.somethingNewFromVacationTracker": "Qualcosa di nuovo da Vacation Tracker",
  "bot.welcomeMessage.furtherAssistance": "Ti senti sopraffatto da tutte queste informazioni? Non preoccuparti! Non devi memorizzare tutto. Basta digitare 'Aiuto' nella nostra chat e il nostro bot ti invierà queste informazioni. Per ulteriore assistenza, non esitare a chattare con il nostro team di Supporto Clienti attraverso il nostro sito web o visita il nostro Helpdesk.",
  "bot.welcomeMessage.goToMyProfile": "Vai alla scheda Il mio profilo",
  "bot.welcomeMessage.greeting": "Saluti {name}, sono il Bot di Vacation Tracker! Sono qui per aiutarti a programmare i congedi tramite Microsoft Teams, e per tenere tutti aggiornati su chi è dentro e fuori dall'ufficio. Senza ulteriori indugi, iniziamo a tracciare quei congedi!",
  "bot.welcomeMessage.happyVacationing": "Buone vacanze 🌴",
  "bot.welcomeMessage.howToAccessTabs": "Come accedere alle schede",
  "bot.welcomeMessage.noCompany": "Ciao, \n\n Grazie per aver installato il bot e la scheda di Vacation Tracker in Microsoft Teams. Come ultimo passo, vai sul nostro sito web per completare la registrazione e configurare le impostazioni della tua organizzazione: [{dashboardLink}]({utmDashboardLink})",
  "bot.welcomeMessage.noCompanyInfo": "Se hai domande, non esitare a iniziare una chat con il nostro team di supporto clienti sul nostro sito web o visita il nostro help desk.",
  "bot.welcomeMessage.openWebDashboardInfo": "Le stesse informazioni sono accessibili anche tramite la nostra dashboard web. Basta cliccare sul pulsante 'Apri Dashboard Web' qui sotto e accedere con il tuo account Microsoft per visualizzarle.",
  "bot.welcomeMessage.requestLeaveInfo": "Per iniziare, inviami semplicemente un messaggio diretto con 'Richiedi ferie' o guarda questo breve tutorial su Vacation Tracker:",
  "bot.welcomeMessage.restInfo": "Oltre al bot, puoi anche inviare richieste di ferie, controllare le tue quote di ferie, visualizzare i calendari del team e altro ancora da Microsoft Teams utilizzando le schede:",
  "bot.welcomeMessage.title": "Saluti da Vacation Tracker",
  "botUpdatePermissions.thanks": "Grazie! Tutto è configurato. Puoi chiudere la dashboard.",
  "button.no": "No",
  "button.yes": "Sì",
  "calendar.all": "Tutti",
  "calendar.calendar": "Calendario",
  "calendar.filterByLocation": "Filtra per Località:",
  "calendar.filterByTeam": "Filtra per Dipartimento:",
  "calendar.forAllUsers": "Per tutti gli utenti",
  "calendar.getCalendarLink": "Ottieni link al calendario",
  "calendar.halfdayTags": "Le ferie e le festività più brevi di un giorno intero sono rappresentate da blocchi a strisce.",
  "calendar.halfdayTagsShort": "Ferie più brevi di un giorno",
  "calendar.labels": "Etichetta/e",
  "calendar.listView": "Vista elenco",
  "calendar.location": "Località",
  "calendar.monthView": "Vista mensile",
  "calendar.sync.calendarAlreadyConnected": "Questo calendario è già connesso",
  "calendar.sync.calendarNotFound": "Impossibile trovare il calendario. Potrebbe essere stato eliminato o non è più disponibile. Si prega di verificare le impostazioni del calendario.",
  "calendar.sync.canNotUpdateSharedCalendar": "Hai connesso il calendario {calendarName} di {provider}. Non è consentito modificare il calendario. Per cambiare il calendario connesso, elimina questa integrazione e creane una nuova.",
  "calendar.sync.confirmDelete": "Conferma che vuoi eliminare il calendario <strong>{name}</strong>.",
  "calendar.sync.connect": "Connetti il tuo Calendario {provider}",
  "calendar.sync.connectCalendar": "Connetti Calendario",
  "calendar.sync.connectCalendarCompletePlanyOnly": "SOLO PIANO COMPLETO",
  "calendar.sync.connectCalendarIcalOptionLabel": "Qualsiasi calendario (tramite link iCal)",
  "calendar.sync.connectCalendarOptionLabel": "Calendario {platform}",
  "calendar.sync.connectInfo": "Connetti il tuo account {provider} e seleziona un calendario condiviso dove tutte le ferie approvate saranno inserite e sincronizzate automaticamente.",
  "calendar.sync.createICalButton": "Crea link iCal",
  "calendar.sync.createICalFiltersInfo": "I seguenti filtri saranno applicati a questo link del calendario iCal:",
  "calendar.sync.createICalFiltersWarning": "Il tuo calendario non aggiorna i dati iCal in tempo reale. Il ritardo può arrivare fino a 24 ore (a seconda del tuo provider di calendario). Per aggiornamenti in tempo reale, connetti direttamente il tuo Google Calendar o Microsoft Outlook Calendar.",
  "calendar.sync.createICalInfo": "Il link del calendario iCal ti permette di iscriverti e visualizzare le ferie programmate del tuo team da Vacation Tracker in qualsiasi calendario (inclusi Google Calendar, Microsoft Outlook Calendar, Apple Calendar e molti altri).",
  "calendar.sync.createPushToSharedInfo": "E inserisci e sincronizza le ferie approvate filtrate nel seguente calendario {provider} condiviso:",
  "calendar.sync.createSyncCalendarButton": "Crea sincronizzazione calendario",
  "calendar.sync.createSyncCalendarFiltersInfo": "Vacation Tracker applicherà i seguenti filtri a tutte le ferie approvate:",
  "calendar.sync.creating": "Creazione sincronizzazione calendario in corso",
  "calendar.sync.creatingError": "Errore nella creazione della sincronizzazione del calendario",
  "calendar.sync.creatingSuccess": "Creazione della sincronizzazione del calendario riuscita",
  "calendar.sync.deletedCalendar": "Questa sincronizzazione del calendario è stata eliminata",
  "calendar.sync.deleteTitle": "Elimina calendario",
  "calendar.sync.deleting": "Eliminazione della sincronizzazione del calendario in corso",
  "calendar.sync.deletingError": "Errore durante l'eliminazione della sincronizzazione del calendario",
  "calendar.sync.deletingSuccess": "Eliminazione della sincronizzazione del calendario riuscita",
  "calendar.sync.emptyDataMessage": "Sincronizza e visualizza tutte le assenze del team nel tuo calendario condiviso. Puoi connettere il tuo calendario Outlook o Google Calendar, o utilizzare iCal per sincronizzare Vacation Tracker con qualsiasi altro tipo di calendario.",
  "calendar.sync.emptyDataTitle": "Sincronizza le assenze con il calendario del tuo team",
  "calendar.sync.loginWithDifferentAccountInfo": "Non vedi il calendario?",
  "calendar.sync.loginWithDifferentAccountLink": "Accedi con un altro account {provider}",
  "calendar.sync.reconnect": "Riconnetti il tuo calendario {provider}",
  "calendar.sync.reconnectInfo": "Riconnetti il tuo account {provider} per continuare la sincronizzazione automatica delle assenze con il tuo calendario condiviso.",
  "calendar.sync.reconnecting": "Riconnessione della sincronizzazione del calendario in corso",
  "calendar.sync.reconnectingError": "Errore durante la riconnessione della sincronizzazione del calendario",
  "calendar.sync.reconnectingSuccess": "Riconnessione della sincronizzazione del calendario riuscita",
  "calendar.sync.selectSharedCalendarPlaceholder": "Seleziona il calendario condiviso {provider}",
  "calendar.sync.stepByStep": "Istruzioni passo-passo",
  "calendar.sync.title": "Sincronizza il calendario del tuo team",
  "calendar.sync.tooltipInfo": "Tutti i tuoi calendari sincronizzati sono riuniti in questa pagina per una gestione più facile. Per saperne di più, consulta il nostro <helpDesk>articolo dell'Helpdesk.</helpDesk>",
  "calendar.sync.update": "Aggiorna il tuo calendario {provider}",
  "calendar.sync.updateSyncCalendarButton": "Aggiorna sincronizzazione del calendario",
  "calendar.sync.updating": "Aggiornamento della sincronizzazione del calendario in corso",
  "calendar.sync.updatingError": "Errore durante l'aggiornamento della sincronizzazione del calendario",
  "calendar.sync.updatingSuccess": "Aggiornamento della sincronizzazione del calendario riuscito",
  "calendar.syncButton": "Sincronizza con il tuo calendario (Google, Outlook, ecc.)",
  "calendar.syncModal.description": "Copia il link seguente e integralo nel tuo Google Calendar, Outlook Calendar o qualsiasi altro calendario che supporti i feed iCal.",
  "calendar.syncModal.filters": "I seguenti filtri sono applicati a questo link del calendario:",
  "calendar.syncModal.helpdesk": "Visita il nostro <helpDesk>Helpdesk</helpDesk> per aiuto e istruzioni passo-passo.",
  "calendar.team": "Dipartimento/i",
  "calendar.timezone.info": "L'orario è convertito nel tuo fuso orario",
  "calendar.week.monFri": "Lun-Ven",
  "calendar.week.sunThu": "Dom-Gio",
  "calendarListModal.description": "Seleziona un calendario dalla seguente lista di calendari disponibili. Dopo aver connesso un calendario, Vacation Tracker aggiungerà, modificherà o cancellerà le tue assenze nel calendario scelto.",
  "calendarListModal.descriptionGoogleFirstLine": "Seleziona un calendario dalla seguente lista di calendari disponibili. ",
  "calendarListModal.descriptionOutlookFirstLine": "Puoi connettere solo il tuo calendario predefinito. ",
  "calendarListModal.descriptionSecondLine": "Dopo aver connesso un calendario, Vacation Tracker aggiungerà, modificherà o cancellerà le tue assenze nel calendario scelto.",
  "calendarListModal.hiddenLeaveTypesDescription": "Scopri di più sulla <helpdesk>visualizzazione dei tipi di assenza nascosti</helpdesk>.",
  "calendarListModal.reconnectDescription": "Riconnetti il tuo calendario per continuare la sincronizzazione automatica delle assenze con il tuo calendario connesso.",
  "calendarListModal.reconnectTitle": "Riconnetti Calendario",
  "calendarListModal.selectPlaceHolder": "Per favore, seleziona un calendario...",
  "calendarListModal.title": "Seleziona Calendario",
  "channel_not_found": "Il canale selezionato è stato eliminato o non è più disponibile. Seleziona un altro canale per ricevere le notifiche.",
  "choiceAutomations.accessibleTo": "Accessibile a <approver>Approvatore </approver><and>e </and><admin>Amministratore </admin><only>solo</only>",
  "choiceAutomations.activeSubscribedAddons": "Addon sottoscritti attivi",
  "choiceAutomations.activeSubscribedAutomations": "Automazioni sottoscritte attive",
  "choiceAutomations.adpIntegration": "Fornisce una connessione diretta al tuo strumento di gestione paghe, garantendo che i dati delle buste paga siano accurati e aggiornati su tutte le piattaforme.",
  "choiceAutomations.automationPrice": "Prezzo: ${price}/utente/mese",
  "choiceAutomations.automationPriceInfo": "Puoi acquistare e attivare una qualsiasi delle automazioni sottostanti per $0,50/utente/mese",
  "choiceAutomations.bambooHrIntegration": "Fornisce una connessione diretta al tuo strumento HR, mantenendo i dati accurati e coerenti tra i sistemi.",
  "choiceAutomations.blackoutPeriodInfo": "Un periodo di blackout è un intervallo di tempo specificato durante il quale ai dipendenti non è consentito prendere congedo dal lavoro.",
  "choiceAutomations.cancelAtPeriodEnd": "Attivo fino alla fine del periodo di fatturazione",
  "choiceAutomations.cancelAtPeriodEndTooltip": "Hai disdetto l'abbonamento a questo addon. Rimarrà attivo fino alla fine del periodo di fatturazione corrente e verrà disattivato successivamente.",
  "choiceAutomations.comingSoonScheduleCall": "Sentiti libero di condividere dettagli sulle tue esigenze specifiche riguardo all'automazione su cui stiamo lavorando.",
  "choiceAutomations.comingSoonScheduleCallTitle": "In arrivo",
  "choiceAutomations.deacticate": "Disiscriviti",
  "choiceAutomations.employmentLeaveRestrictionInfo": "La politica di congedo per l'inizio del rapporto di lavoro permette di garantire un periodo dedicato di ambientamento durante i primi giorni di impiego limitando le richieste di congedo dei nuovi dipendenti.",
  "choiceAutomations.enitlementByRoleShortInfo": "Ai dipendenti vengono assegnati giorni di congedo aggiuntivi in base al loro ruolo, come definito dall'etichetta/e.",
  "choiceAutomations.entitlementByRoleInfo": "Ai dipendenti vengono assegnati giorni di congedo aggiuntivi in base al loro ruolo, come definito dall'etichetta/e. Ad esempio, puoi creare un'etichetta chiamata 'Manager' e assegnarla a tutti i manager nella tua organizzazione. Poi, puoi creare un'automazione che assegna 5 giorni di congedo aggiuntivi a tutti i dipendenti con l'etichetta 'Manager'. Puoi creare più automazioni per ruoli diversi. Questa automazione è valida a partire dal tuo anno fiscale corrente. <link>Contattaci</link> se hai bisogno di applicare questa automazione agli anni passati.",
  "choiceAutomations.includedInYourPlan": "Incluso nel tuo piano",
  "choiceAutomations.leaveDurationLimitInfo": "Personalizza la Durata del Congedo: Imposta il numero minimo e massimo di giorni per il periodo desiderato.",
  "choiceAutomations.mySbscribedAddons": "I miei addon sottoscritti",
  "choiceAutomations.notActivatedAddons": "Disponibili per la sottoscrizione",
  "choiceAutomations.openApiInfo": "Fornisce un'integrazione perfetta con sistemi esterni, abilitando la sincronizzazione automatica dei dati e funzionalità avanzate",
  "choiceAutomations.reviewAndBuy": "Rivedi e acquista",
  "choiceAutomations.selectAutomations": "Seleziona la tua Automazione preferita",
  "choiceAutomations.seniorityEntitlementInfo": "L'indennità di anzianità è un sistema in cui i dipendenti guadagnano più tempo libero retribuito (PTO) in base alla loro durata di servizio presso un'azienda. Attualmente, puoi creare solo un'indennità di anzianità per località. Questa automazione è valida a partire dal tuo anno fiscale corrente. <link>Contattaci</link> se hai bisogno di più di un'indennità di anzianità in una località o se devi applicare questa automazione agli anni passati.",
  "choiceAutomations.seniorityEntitlementShortInfo": "L'indennità di anzianità è un sistema in cui i dipendenti guadagnano più tempo libero retribuito (PTO) in base alla loro durata di servizio presso un'azienda.",
  "choiceAutomations.teamLeaveBalancingInfo": "Gestisci la percentuale di congedo simultaneo del team.",
  "choiceAutomations.visibilityInfo": "Permette agli amministratori di personalizzare la visibilità delle informazioni sui congedi per gli utenti normali, migliorando la privacy e la rilevanza dei dati.",
  "company.platformChanged": "Migrazione a una nuova piattaforma",
  "company.platformChanged.confirmDescription": "Stai per migrare a una nuova piattaforma. Questa azione è irreversibile. Si prega di rivedere le seguenti informazioni prima di procedere.",
  "company.platformChanged.confirmDescription.unmappedUsers.deleted": "Abbiamo trovato {unmappedUsers} utenti che non sono presenti nel tuo account {newPlatform}. Questi utenti verranno contrassegnati come ELIMINATI al completamento della migrazione.",
  "company.platformChanged.confirmDescription.unmappedUsers.unmatched": "Abbiamo trovato {unmappedUsers} utenti che non sono presenti nel tuo account {newPlatform}. Questi utenti non saranno in grado di accedere a Vacation Tracker.",
  "company.platformChanged.confirmQuestion": "Sei sicuro di voler procedere?",
  "company.platformChanged.confirmTitle": "Conferma migrazione",
  "company.platformChanged.description": "Si prega di associare i tuoi utenti Vacation Tracker ai loro account {newPlatform}. Abbineremo automaticamente gli utenti con le stesse email.",
  "company.platformChanged.googleAccount": "Account Google",
  "company.platformChanged.hideInactive": "Nascondi inattivi:",
  "company.platformChanged.markUnmappedAsDeleted": "Contrassegnare gli utenti non associati come eliminati?",
  "company.platformChanged.microsoftAccount": "Account Microsoft",
  "company.platformChanged.slackAccount": "Account Slack",
  "company.platformChanged.title": "Migrazione da {oldPlatform} a {newPlatform}",
  "company.platformChanged.unmatched": "Non trovato in {platform}",
  "company.platformChanged.vacationTrackerUser": "Utente Vacation Tracker",
  "component.filterSimple.filterBy": "Filtra per",
  "component.filterSimple.selectType": "Seleziona tipo",
  "component.filterSimple.tooltipInfo": "Se non selezioni alcun filtro, l'esportazione includerà tutti gli utenti.",
  "component.notificationDestination.label": "Invia a",
  "component.notificationDestination.tooltip": "Nota che puoi inviare la Notifica a più email, ma solo a un canale alla volta.",
  "component.password.timeToHack.days": "{duration} giorni",
  "component.password.timeToHack.hours": "{duration} ore",
  "component.password.timeToHack.instantly": "Istantaneamente",
  "component.password.timeToHack.label": "Tempo necessario agli hacker per violare questa password:",
  "component.password.timeToHack.minutes": "{duration} minuti",
  "component.password.timeToHack.perfect": "Più lungo di una vita",
  "component.password.timeToHack.seconds": "{duration} secondi",
  "component.password.timeToHack.years": "{duration} anni",
  "component.setPassword.enterOldPassword": "Inserisci la vecchia password",
  "component.setPassword.enterPassword": "Inserisci la password",
  "component.setPassword.tooltip.description.line1": "Le informazioni sopra mostrano il tempo massimo stimato necessario agli hacker per violare la tua password selezionata con un attacco a forza bruta. La stima si basa sulla tabella <italic>\"Password table for cracking a PBKDF2 hash function using an RTX 3090 GPU\"</italic> di <link>questo articolo</link>.",
  "component.setPassword.tooltip.description.line2": "Come scegliere una buona password? Ci sono molti modi per selezionare una buona password. Noi usiamo gestori di password e non riutilizziamo le password. Dai un'occhiata a questa <link>guida divertente ma corretta</link> per maggiore ispirazione.",
  "component.setPassword.tooltip.title": "Tempo necessario agli hacker per violare la tua password con un attacco a forza bruta",
  "component.setPassword.validation": "La password deve contenere almeno: 8 caratteri, una lettera minuscola, un numero e un simbolo.",
  "components.accrualsCalculator.calculate": "Calcola",
  "components.accrualsCalculator.calculateAccrualsDays": "Calcola i Giorni di Maturazione",
  "components.accrualsCalculator.youWillEarnedInCurrentPeriod": "Guadagnerai <strong>{days}</strong> giorni nel periodo corrente in quella data",
  "components.accrualsCalculator.youWillEarnedInNextPeriod": "Guadagnerai <strong>{days}</strong> giorni nel prossimo periodo in quella data",
  "components.addHolidayForm.addHolidays": "Aggiungi Festività",
  "components.addHolidayForm.addHolidays.cancel": "Annulla",
  "components.addHolidayForm.addHolidays.isHalfDayHoliday": "Festività di Mezza Giornata",
  "components.addHolidayForm.addHolidays.isHolidayMultiday": "Più di Un Giorno?",
  "components.addHolidayForm.addHolidays.saveHoliday": "Salva",
  "components.addHolidayForm.date.error": "Seleziona una data",
  "components.addHolidayForm.editHoliday": "Modifica festività",
  "components.addHolidayForm.editHolidays": "Modifica festività",
  "components.addHolidayForm.endDate.error": "Seleziona la data di fine",
  "components.addHolidayForm.holidaysName": "Nome della festività",
  "components.addHolidayForm.holidaysName.error": "Inserisci il nome della festività",
  "components.addHolidayForm.startDate.error": "Seleziona la data di inizio",
  "components.announcementModal.announceToCompany": "Vuoi far sapere al tuo team che stai usando Vacation Tracker?",
  "components.announcementModal.send": "Invia Annuncio",
  "components.automationBlackoutSummaryInfo.blacoutSummary": "{noOfUsers} utent<multiUsers>e</multiUsers> <link>(Visualizza Utent<multiUsers>e</multiUsers>)</link> non potr<multiUsers>à</multiUsers><multiUsers>anno</multiUsers> prendere ferie di tipo {leaveTypes},<endDate> tra</endDate> il {fromDate} <endDate>e il {toDate}</endDate><recurring> ogni anno.</recurring>",
  "components.billing.email": "Email di Fatturazione",
  "components.billing.emailPlaceholder": "Inserisci l'indirizzo email.",
  "components.billing.emailsValidationRequired": "È richiesta un'email per scopi di fatturazione.",
  "components.billing.resubscribe": "Riabbonati",
  "components.billingCouponCode.activeCouponInfo": "<percentOffCover>{off}%</percentOffCover><amountOffCover>US${off}</amountOffCover> di sconto <durationCover>{duration}</durationCover><durationInMonthsCover>{duration} mese</durationInMonthsCover>, da {start}",
  "components.billingCouponCode.activeCouponInfoTitle": "Codice Coupon Attivo",
  "components.billingCouponCode.applyCode": "Applica codice",
  "components.billingCouponCode.applyCodeAndResubscribe": "Applica codice e Riabbonati",
  "components.billingCouponCode.couponCode": "Codice Coupon",
  "components.billingCouponCode.text": "Hai un codice coupon? Inseriscilo qui per applicarlo al tuo abbonamento.",
  "components.billingCouponCode.title": "Coupon",
  "components.billingEmails.emailPlaceholder": "Inserisci l'indirizzo email.",
  "components.billingEmails.emails": "Email",
  "components.billingEmails.emailsValidationRequired": "Un'email è richiesta per scopi di fatturazione.",
  "components.billingEmails.title": "Email di Fatturazione",
  "components.billingForm.address1": "Indirizzo riga 1",
  "components.billingForm.address1ValidationRequired": "L'indirizzo è obbligatorio.",
  "components.billingForm.address2": "Indirizzo riga 2",
  "components.billingForm.billingEmails": "Email di Fatturazione",
  "components.billingForm.billingEmailsPlaceholder": "Inserisci l'indirizzo email.",
  "components.billingForm.billingEmailsValidationRequired": "Un'email è richiesta per scopi di fatturazione.",
  "components.billingForm.ccRequired": "Il numero della carta è obbligatorio.",
  "components.billingForm.city": "Città",
  "components.billingForm.cityValidationRequired": "La città è obbligatoria.",
  "components.billingForm.companyName": "Nome Azienda",
  "components.billingForm.companyNameValidationRequired": "Il nome dell'azienda è obbligatorio.",
  "components.billingForm.country": "Paese",
  "components.billingForm.countryValidationRequired": "Il paese è obbligatorio.",
  "components.billingForm.couponCode": "Codice Coupon",
  "components.billingForm.coupons": "Coupon",
  "components.billingForm.description": "Inserisci le tue informazioni di pagamento nel modulo sopra per pagare il tuo abbonamento con una carta di credito.",
  "components.billingForm.invoiceDescription": "Se la persona responsabile del pagamento dell'abbonamento non è un utente Slack o Microsoft Teams, possiamo inviare loro una fattura via email che può essere pagata con una carta di credito.",
  "components.billingForm.invoiceLabel": "Invia fatture via email",
  "components.billingForm.invoiceTitle": "Pagamento con fattura via email",
  "components.billingForm.paymentCC": "Carta di Credito",
  "components.billingForm.paymentTitle": "Informazioni di pagamento",
  "components.billingForm.planTitle": "Piano di fatturazione ",
  "components.billingForm.state": "Stato/Provincia",
  "components.billingForm.stateValidationRequired": "Lo stato/provincia è obbligatorio.",
  "components.billingForm.title": "Informazioni di fatturazione ",
  "components.billingInfo.address": "Indirizzo",
  "components.billingInfo.canceledDescription": "Hai annullato il tuo abbonamento a Vacation Tracker. Rimarrà attivo fino al {periodEnd} ",
  "components.billingInfo.canceledTrialDescription": "Il tuo periodo di prova rimarrà attivo fino al {periodEnd}, dopodiché il tuo account Vacation Tracker verrà annullato. ",
  "components.billingInfo.companyName": "Nome dell'azienda",
  "components.billingInfo.email": "Email di fatturazione",
  "components.billingInfo.resubscribe": "Riabbonati",
  "components.billingInfo.text": "Le tue informazioni di fatturazione vengono utilizzate per elaborare i pagamenti del tuo abbonamento. Puoi aggiornare le tue informazioni di fatturazione e aggiungere le informazioni della tua carta di credito qui.",
  "components.billingInfo.title": "Informazioni di fatturazione ",
  "components.billingInvoice.description": "Puoi gestire i metodi di pagamento e le fatture sul nostro portale clienti. <link>Clicca qui per visitare il portale clienti</link><creatingLink>Creazione del link al portale clienti</creatingLink>",
  "components.billingInvoice.title": "Fatture",
  "components.cancelSubscriptionModal.anotherLeaveTrackingTool": "Abbiamo trovato un altro strumento di monitoraggio delle assenze",
  "components.cancelSubscriptionModal.anotherLeaveTrackingToolAdditionalInfo": "Puoi dirci a quale strumento stai passando?",
  "components.cancelSubscriptionModal.cancelText": "Non cancellare l'abbonamento",
  "components.cancelSubscriptionModal.contactInfo": "Per tutto il resto, contatta il nostro supporto all'indirizzo {email}",
  "components.cancelSubscriptionModal.info": "Puoi dirci qual è il motivo per cui stai cancellando il tuo abbonamento a Vacation Tracker?",
  "components.cancelSubscriptionModal.min20characters": "Minimo 20 caratteri",
  "components.cancelSubscriptionModal.missingFeatures": "Lo strumento non ha le funzionalità di cui il mio team ha bisogno",
  "components.cancelSubscriptionModal.missingFeaturesAdditionalInfo": "Puoi dirci in poche parole quale funzionalità manca?",
  "components.cancelSubscriptionModal.moreInfo": "Cosa avremmo potuto fare per mantenerti come cliente?",
  "components.cancelSubscriptionModal.okText": "Cancella ora",
  "components.cancelSubscriptionModal.option1": "Siamo passati a un software HR",
  "components.cancelSubscriptionModal.option2": "Abbiamo trovato un altro strumento di monitoraggio delle assenze",
  "components.cancelSubscriptionModal.option3": "Lo strumento non ha le funzionalità di cui il mio team ha bisogno",
  "components.cancelSubscriptionModal.option4": "Il prezzo è al di sopra del nostro budget",
  "components.cancelSubscriptionModal.option5": "Altro",
  "components.cancelSubscriptionModal.other": "Altro",
  "components.cancelSubscriptionModal.otherAdditionalInfo": "Altro",
  "components.cancelSubscriptionModal.priceOverBudget": "Il prezzo è al di sopra del nostro budget",
  "components.cancelSubscriptionModal.priceOverBudgetAdditionalInfo": "Qual è il tuo budget per uno strumento come questo?",
  "components.cancelSubscriptionModal.title": "Ci dispiace vederti andare via...",
  "components.cancelSubscriptionModal.weAreMovingToPayroll": "Stiamo passando a un sistema di buste paga",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfo": "Puoi dirci a quale sistema di buste paga state passando?",
  "components.cancelSubscriptionModal.weAreMovingToPayrollAdditionalInfoIntegration": "Se avessimo un'integrazione con questo sistema di buste paga, considereresti di rimanere?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolution": "Siamo passati a un software HR",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfo": "Puoi dirci a quale sistema HR state passando?",
  "components.cancelSubscriptionModal.weSiwtchedToAnHrSolutionAdditionalInfoIntegration": "Se avessimo un'integrazione con questo sistema HR, considereresti di rimanere?",
  "components.cancelTrialSubscriptionModal.cancelText": "Continua la prova",
  "components.cancelTrialSubscriptionModal.info": "Per favore, spiega in una frase perché vuoi cancellare la tua prova?",
  "components.cancelTrialSubscriptionModal.okText": "Cancella la prova",
  "components.cancelTrialSubscriptionModal.title": "Ci dispiace vederti andare via...",
  "components.changeTeamForm.changeTeamWarning": "Seleziona il Dipartimento a cui vuoi trasferire l'Utente.",
  "components.changeTeamForm.changeUserTeam": "Cambia il Dipartimento dell'Utente",
  "components.changeTeamForm.selectTeam": "Seleziona Dipartimento",
  "components.changeTeamForm.selectTeamError": "Seleziona un dipartimento.",
  "components.companyAlreadySignup.connectWith": " Connettiti con {existsOnAnotherPlatform}",
  "components.companyAlreadySignup.info": "Abbiamo notato che la tua azienda ha già un account Vacation Tracker. Se intendevi accedere, clicca semplicemente qui:",
  "components.companyAlreadySignup.signupWithMail": "Connettiti con la tua email di lavoro",
  "components.companyAlreadySignup.title": "La tua azienda sta già utilizzando Vacation Tracker",
  "components.companyLogs.dateRangeLimit": "I log più vecchi di 3 mesi sono disponibili solo nel piano completo.",
  "components.companyLogs.rangeLimit": "L'intervallo di date può essere al massimo di un anno",
  "components.companySettings.contactEmailInfo": "Il nuovo indirizzo email di contatto della tua azienda è: {email}.",
  "components.comparePlatformsModal.forMoreInfoSeeOurHelpdesk": "Per saperne di più, visita il nostro <helpDesk>Helpdesk</helpDesk>.",
  "components.comparePlatformsModal.info": "Indipendentemente dalla piattaforma scelta, avrai accesso a tutte le principali funzionalità di Vacation Tracker. Alcune, tuttavia, hanno vantaggi aggiuntivi non disponibili in altre. Consulta la tabella di confronto qui sotto.",
  "components.comparePlatformsModal.table.comingSoon": "Prossimamente",
  "components.comparePlatformsModal.table.email": "Email",
  "components.comparePlatformsModal.table.feature": "Funzionalità",
  "components.comparePlatformsModal.table.google": "Google",
  "components.comparePlatformsModal.table.importUsersFromGoogleWorkspace": "Importa utenti dal tuo Google Workspace",
  "components.comparePlatformsModal.table.importUsersFromMicrosoftTeams": "Importa utenti dalla tua Microsoft Active Directory",
  "components.comparePlatformsModal.table.importUsersFromSlack": "Importa utenti dal tuo workspace Slack",
  "components.comparePlatformsModal.table.inviteUsersViaEmail": "Invita utenti via email",
  "components.comparePlatformsModal.table.manageLeaveTypes": "Gestisci tipi di congedo, ferie e richieste di congedo",
  "components.comparePlatformsModal.table.microsoft": "Microsoft",
  "components.comparePlatformsModal.table.multiTeamLocation": "Configura più dipartimenti e sedi con diverse politiche di congedo",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromMicrosoftTeams": "Invia e gestisci richieste di congedo direttamente da Microsoft Teams",
  "components.comparePlatformsModal.table.requestAndManageLeaveFromSlack": "Invia e gestisci richieste di congedo direttamente da Slack",
  "components.comparePlatformsModal.table.slack": "Slack",
  "components.comparePlatformsModal.table.syncToGoogleCalendar": "Sincronizza Vacation Tracker con Google Calendar",
  "components.comparePlatformsModal.table.syncToOutlookCalendar": "Sincronizza Vacation Tracker con Outlook 365 Calendar",
  "components.comparePlatformsModal.title": "Quale integrazione di Vacation Tracker fa al caso mio?",
  "components.configureImport.configureProratedLeaveTypesErrorMessage": "Devi selezionare almeno un tipo di congedo",
  "components.configureImport.defaultDepartmentTooltip": "Tutti gli utenti importati saranno aggiunti a questo dipartimento. Una volta importati, potrai spostarli in un dipartimento diverso.",
  "components.configureImport.defaultLocationTooltip": "Tutti gli utenti importati saranno aggiunti a questa sede. Una volta importati, potrai spostarli in una sede diversa.",
  "components.configureImport.guestUsers": "Vuoi importare gli utenti ospiti multicanale?",
  "components.configureImport.guestUsersTooltip": "Se questa opzione è attivata, gli utenti ospiti multicanale del tuo workspace saranno importati in Vacation Tracker.",
  "components.configureImport.proRateLabel": "Calcolare proporzionalmente la quota dei nuovi utenti per quest'anno?",
  "components.configureImport.proRateLeaveTypes": "Applica il calcolo proporzionale ai seguenti tipi di congedo",
  "components.configureImport.proRateLeaveTypesTooltip": "Elenca i tipi di congedo per i quali desideri calcolare proporzionalmente le quote di congedo disponibili per gli utenti.",
  "components.configureImport.proRateTooltip": "Seleziona se vuoi che il nostro sistema calcoli la quota di congedo disponibile per gli utenti importati per l'anno iniziale in base alle impostazioni della tua azienda e alla data di inizio dell'utente.",
  "components.createLeaveType.assignToLocationManyInProgress": "Assegnazione della politica di congedo alle sedi",
  "components.createLeaveType.assignToLocationManyInProgressCompleted": "Assegnazione della politica di congedo alle sedi completata",
  "components.createLeaveType.assignToLocationOneInProgress": "Assegnazione della politica di congedo alla sede",
  "components.createLeaveType.assignToLocationOneInProgressCompleted": "Assegnazione della politica di congedo alla sede completata",
  "components.createLeaveType.createInProgress": "Creazione del tipo di permesso {leaveTypeName} in corso",
  "components.createLeaveTypeForm.assignAndConfigure": "Assegna e Configura",
  "components.createLeaveTypeForm.assignToLocations": "Assegna alle Sedi",
  "components.createLeaveTypeForm.calendarStatusBusy": "Occupato",
  "components.createLeaveTypeForm.calendarStatusFree": "Libero",
  "components.createLeaveTypeForm.calendarStatusLabel": "Mostra questo tipo di permesso nel tuo calendario come",
  "components.createLeaveTypeForm.calendarStatusLabelTooltipInfo": "Scegli 'Occupato' per un'assenza completa come una vacanza, segnalando indisponibilità, o 'Libero' per giorni di lavoro remoto, indicando che non sei in ufficio ma disponibile.",
  "components.createLeaveTypeForm.continueCreatingLeaveType": "Sì, voglio creare il tipo di permesso",
  "components.createLeaveTypeForm.createAndAssign": "Crea e Assegna",
  "components.createLeaveTypeForm.createLeaveType": "Crea Tipo di Permesso",
  "components.createLeaveTypeForm.justLastStep.assign": "Assegna",
  "components.createLeaveTypeForm.justLastStep.title": "Assegna Politica di Permesso",
  "components.createLeaveTypeForm.Locations": "Sedi",
  "components.createLeaveTypeForm.locationsInfo": "Assegna <strong>{leaveTypeName}</strong> a tutte le sedi o a una sede specifica.",
  "components.createLeaveTypeForm.locationsPolicyHint": "Per modificare la politica (regole) per questo tipo di permesso, vai alle specifiche {locations, plural, =1 {Sede} other {Sedi}}:",
  "components.createLeaveTypeForm.selectLocation": "Seleziona sede",
  "components.createLeaveTypeForm.similarLeaveTypeChecking": "Verifica dei tipi di permesso esistenti...",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAreYouSure": "Vuoi comunque creare un nuovo tipo (<strong>{newLeaveTypeName}</strong>)?",
  "components.createLeaveTypeForm.similarLeaveTypeInfoAssigningTip": "Puoi assegnare un tipo di permesso a più sedi e personalizzare le regole per sede (come quote, riporti, maturazioni, ecc.). Non è necessario creare tipi separati per ogni luogo!",
  "components.createLeaveTypeForm.similarLeaveTypeInfoRecommend": "Consigliamo di utilizzare un singolo tipo di permesso in più sedi per mantenere la coerenza e i dati dei permessi degli utenti.",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSetRules": "Imposta le regole specifiche per sede per:",
  "components.createLeaveTypeForm.similarLeaveTypeInfoSimilarLeaveType": "Esiste già un tipo di permesso simile (<strong>{leaveTypeName}</strong>).",
  "components.createLeaveTypeForm.similarLeaveTypeTitle": "Tipo di Permesso Simile",
  "components.createLeaveTypeForm.title": "Crea Tipo di Permesso e Assegna Politica di Permesso",
  "components.denyWithReasonForm.deny": "Rifiuta",
  "components.denyWithReasonForm.denyLeaveRequest": "Rifiuta Richiesta di Permesso",
  "components.denyWithReasonForm.reason": "Aggiungi una Motivazione",
  "components.denyWithReasonForm.reasonIsRequired": "Questo campo è obbligatorio.",
  "components.downgradeAlert.cancelChangeToPlan": "Annulla il cambio al piano <strong>{nextPlan}</strong>",
  "components.downgradeAlert.cancelSwitchPlan": "Vuoi annullare il cambio programmato al piano <strong>{nextPlan}</strong> {nextPeriod}?",
  "components.downgradeAlert.noIWantToSwitch": "No, voglio passare al piano <strong>{nextPlan}</strong> {nextPeriod}",
  "components.downgradeAlert.yesIWantToStay": "Sì, voglio rimanere sul piano <strong>{currentPlan}</strong> {currentPeriod}",
  "components.entitlementByRole.additionalDays": "giorno/i aggiuntivo/i",
  "components.entitlementByRole.additionalHours": "ora/e aggiuntiva/e",
  "components.entitlementByRole.applayToUsersWithLabel": "Applica agli utenti con etichetta",
  "components.entitlementByRole.applyToUsersWithLabel": "Applica agli utenti con etichetta",
  "components.entitlementByRole.completePlanOnly": "Questa funzione è accessibile esclusivamente agli utenti abbonati al nostro piano Complete.",
  "components.entitlementByRole.noLabels": "Non ci sono etichette create o tutte sono utilizzate per altre automazioni di Diritto per Ruolo.",
  "components.entitlementByRole.startApplayingEnintlementByRoleFrom": "Inizia ad applicare il diritto per ruolo da",
  "components.entitlementByRole.startApplyingEntitlementByRoleFrom": "Inizia ad applicare il diritto per ruolo da",
  "components.fakeDoorMailLoginModal.discount": "Come parte del gruppo di accesso anticipato, riceverai anche il 50% di sconto su Vacation Tracker per i primi 3 mesi.",
  "components.fakeDoorMailLoginModal.info": "Stiamo attualmente lavorando per abilitare la registrazione via email. Per ottenere l'accesso anticipato, per favore firma qui sotto e ti contatteremo a breve.",
  "components.fakeDoorMailLoginModal.no": "No, aspetterò.",
  "components.fakeDoorMailLoginModal.title": "Interessato all'accesso anticipato? 🚀 ",
  "components.fakeDoorMailLoginModal.yes": "Sì, voglio ottenere l'accesso anticipato.",
  "components.feedbackModal.button": "Dacci un feedback",
  "components.feedbackModal.emailPlaceholder": "Qual è il tuo indirizzo email?",
  "components.feedbackModal.feedbackForm": "Modulo di feedback",
  "components.feedbackModal.feedbackMessage": "Messaggio di feedback",
  "components.feedbackModal.howDoYouLikeApp": "Come ti piace la nostra app?",
  "components.feedbackModal.howDoYouLikeAppPlaceholder": "Scegli un'opzione...",
  "components.feedbackModal.iLikeIt": "Mi piace",
  "components.feedbackModal.iLoveIt": "La adoro",
  "components.feedbackModal.ItMakesMeCry": "Mi fa piangere",
  "components.feedbackModal.itsOk": "È ok",
  "components.feedbackModal.messagePlaceholder": "Il tuo messaggio di feedback",
  "components.feedbackModal.Nah": "Nah",
  "components.feedbackModal.namePlaceholder": "Inserisci il tuo nome",
  "components.feedbackModal.ratingRequired": "Per favore, seleziona una delle valutazioni fornite",
  "components.feedbackModal.sendFeedback": "Invia feedback",
  "components.feedbackModal.yourEmail": "Il tuo indirizzo email",
  "components.feedbackModal.yourEmailInfo": "Se il tuo feedback richiede una risposta, inserisci la tua email così possiamo contattarti.",
  "components.feedbackModal.yourName": "Il tuo nome",
  "components.filterSimple.filterBy": "Filtra per",
  "components.filterSimple.selectType": "Seleziona tipo",
  "components.filterSimple.tooltipInfo": "Se non selezioni alcun filtro, l'esportazione includerà tutti gli utenti.",
  "components.generalSettings.aiBotHelperLabel": "Consenti la comunicazione con il Bot di Vacation Tracker",
  "components.generalSettings.aiBotHelperLabelDisable": "Disabilita la comunicazione con il Bot di Vacation Tracker",
  "components.generalSettings.aiBotHelperTooltip": "La messaggistica con il bot è un modo conveniente ed efficiente per i dipendenti di richiedere tempo libero dal lavoro. I dipendenti possono semplicemente inviare un messaggio di richiesta di permesso al bot, specificando dettagli come le date di inizio e fine, il numero di giorni richiesti e il tipo di permesso.",
  "components.heatmap.clickForMore": "Clicca per maggiori informazioni",
  "components.heatmap.holiday": "Festività",
  "components.heatmap.holidayDescription": "La sede di {location} è assente per {holiday}",
  "components.heatmap.loading": "caricamento {day}",
  "components.heatmap.nextMonth": "Mese Successivo",
  "components.heatmap.nonWorkingDay": "giorno non lavorativo",
  "components.heatmap.previousMonth": "Mese Precedente",
  "components.heatmap.showLess": "Mostra meno",
  "components.heatmap.showMore": "Mostra altri {amount}",
  "components.heatmap.today": "oggi",
  "components.helpDrawer.adminProductTourInfo": "Come Amministratore hai il più alto livello di permessi in Vacation Tracker e puoi personalizzare tutte le impostazioni dell'organizzazione attraverso la Dashboard dell'Amministratore.\n\nLa Dashboard ti permette di: \n- Richiedere permessi o aggiungere permessi per altri membri del dipartimento. \n- Avere una panoramica completa attraverso la Homepage della Dashboard e il Calendario. \n- Creare e configurare più dipartimenti (Assegnare Approvatori) \n- Impostare Notifiche \n- Gestire Utenti (Attivi, Inattivi, Eliminati).\n- Creare e gestire Sedi (Tipi di Permesso, Festività, Notifiche)\n\nIl Bot ti permette di: \n- Richiedere Permessi \n- Approvare/Negare Richieste di Permesso\n- Ricevere Notifiche Giornaliere/Settimanali\n\nSe le tue domande o dubbi rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: ",
  "components.helpDrawer.adminProductTourTitle": "Il tuo ruolo: Amministratore",
  "components.helpDrawer.approverProductTourInfo": "Come Approvatore, hai il secondo livello più alto di permessi in Vacation Tracker e puoi approvare o negare le richieste di permesso presentate dagli Utenti del dipartimento per cui sei stato assegnato come Approvatore.\n\nLa Dashboard ti permette di:\n- Richiedere permessi o aggiungere permessi per altri membri del dipartimento.\n- Avere una panoramica completa attraverso la Homepage della Dashboard e il Calendario.\n- Impostare Notifiche\n- Visualizzare il tuo Profilo Utente.\n- Gestire le Richieste (Approvare/Negare Richieste, Visualizzare la Cronologia).\n\nIl Bot ti permette di:\n- Richiedere Permessi\n- Approvare/Negare Richieste di Permesso\n- Ricevere Notifiche Giornaliere/Settimanali\n\nSe le tue domande o preoccupazioni rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: ",
  "components.helpDrawer.approverProductTourTitle": "Il tuo ruolo: Approvatore",
  "components.helpDrawer.google.adminProductTourInfo": "Come Amministratore hai il livello più alto di permessi in Vacation Tracker e puoi personalizzare tutte le impostazioni dell'organizzazione attraverso la Dashboard dell'Amministratore.\n\nLa Dashboard ti permette di:\n- Richiedere Permessi o aggiungere Permessi per altri membri del dipartimento.\n- Avere una panoramica completa attraverso la Homepage della Dashboard e il Calendario.\n- Creare e configurare più dipartimenti (Assegnare Approvatori)\n- Impostare Notifiche\n- Gestire Utenti (Attivi, Inattivi, Eliminati).\n- Creare e gestire Località (Tipi di Permesso, Festività, Notifiche)\n\nSe le tue domande o preoccupazioni rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: ",
  "components.helpDrawer.google.approverProductTourInfo": "Come Approvatore, hai il secondo livello più alto di permessi in Vacation Tracker e puoi approvare o negare le richieste di permesso presentate dagli Utenti del dipartimento per cui sei stato assegnato come Approvatore.\n\nLa Dashboard ti permette di:\n- Richiedere Permessi o aggiungere Permessi per altri membri del dipartimento.\n- Avere una panoramica completa attraverso la Homepage della Dashboard e il Calendario.\n- Impostare Notifiche\n- Visualizzare il tuo Profilo Utente.\n- Gestire le Richieste (Approvare/Negare Richieste, Visualizzare la Cronologia).\n\nSe le tue domande o preoccupazioni rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: ​​​​​​​​",
  "components.helpDrawer.google.userProductTourInfo": "Come Utente di Vacation Tracker, puoi inviare e gestire le tue Richieste di Permesso attraverso la Dashboard, oltre ad avere una panoramica completa delle assenze pianificate all'interno della tua organizzazione.\n\nLa Dashboard ti permette di:\n- Richiedere Permessi\n- Avere una panoramica completa attraverso la Homepage della Dashboard e il Calendario.\n- Visualizzare il tuo Profilo Utente.\n\nSe le tue domande o preoccupazioni rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: ​​​​​​​​",
  "components.helpDrawer.helpDeskInfo": "Visita il nostro Helpdesk per saperne di più sul nostro prodotto e ottenere risposte ad alcune delle domande comuni.",
  "components.helpDrawer.learnMore": "Scopri di più",
  "components.helpDrawer.needhelp": "Hai bisogno di aiuto?",
  "components.helpDrawer.quickTourInfo": "Abbiamo messo insieme alcuni brevi passaggi che ti consigliamo di seguire per configurare rapidamente la tua organizzazione.",
  "components.helpDrawer.quickTourTitle": "Tour rapido",
  "components.helpDrawer.supportInfo": "Se hai domande o hai bisogno di assistenza, avvia una chat con il nostro team di supporto clienti o inviaci un'email a <link>hello@vacationtracker.io</link>",
  "components.helpDrawer.supportTitle": "Supporto",
  "components.helpDrawer.userProductTourInfo": "Come Utente di Vacation Tracker, puoi inviare e gestire le tue Richieste di Permesso attraverso la Dashboard o il Bot, oltre ad avere una panoramica completa delle assenze pianificate all'interno della tua organizzazione.\n\nLa Dashboard ti permette di:\n- Richiedere Permessi\n- Avere una panoramica completa attraverso la Homepage della Dashboard e il Calendario.\n- Visualizzare il tuo Profilo Utente.\n\nIl Bot ti permette di:\n- Richiedere Permessi\n- Ricevere Notifiche Giornaliere/Settimanali\n\nSe le tue domande o preoccupazioni rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: ",
  "components.helpDrawer.userProductTourTitle": "Il tuo ruolo: Utente",
  "components.importHolidaysForm.backButton": "Indietro",
  "components.importHolidaysForm.selectCountry": "Seleziona paese",
  "components.importHolidaysForm.selectHolidays": "Seleziona festività",
  "components.importHolidaysForm.selectYear": "Seleziona anno",
  "components.importUsersForm.button.importAllUsers": "Importa e Attiva Tutti i {users} Utenti",
  "components.importUsersForm.button.importSelectedUsers": "Importa {noOfUsers} {noOfUsers, plural, =1 {Utente Selezionato} other {Utenti Selezionati}}",
  "components.importUsersForm.button.importUsersAutmaticallyFromPlatform": "Vuoi importare automaticamente tutti gli utenti da {platform}?",
  "components.importUsersForm.calculationStartYearInfo": "Vacation Tracker calcolerà i permessi a partire dal {year}",
  "components.importUsersForm.cancel": "Annulla",
  "components.importUsersForm.chooseUsersPlaceholder": "Nome del Membro del Team",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOff": "Vacation Tracker non importerà automaticamente gli utenti ospiti multicanale dal tuo spazio di lavoro. Puoi comunque importarli manualmente se necessario.",
  "components.importUsersForm.confirmImportSlackMultichannelUsersInfoOn": "Vacation Tracker importerà automaticamente gli utenti ospiti multicanale dal tuo spazio di lavoro. ",
  "components.importUsersForm.confirmImportUsersModalContentChangeLaterInfo": "Puoi modificare queste impostazioni in seguito nella dashboard di Vacation Tracker",
  "components.importUsersForm.confirmImportUsersModalContentDepartmentAndLocationInfo": "{noOfUsers, plural, =1 {Il nuovo utente} other {I nuovi utenti}} {noOfUsers, plural, =1 {verrà aggiunto} other {verranno aggiunti}} al dipartimento {chosenDepartment} e nella località {chosenLocation}",
  "components.importUsersForm.confirmImportUsersModalContentNoOfUsers": "Importa {noOfUsers} {noOfUsers, plural, =1 {utente} other {utenti}}",
  "components.importUsersForm.confirmImportUsersModalContentOpener": "Hai selezionato:",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfo": "Quota proporzionale degli utenti per i seguenti tipi di permesso: {leaveTypes}",
  "components.importUsersForm.confirmImportUsersModalContentProrateInfoTurnOff": "Le quote degli utenti non saranno più proporzionali",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessage": "Invia un messaggio di benvenuto {channel, select, Email {} other {messaggio}} {channel} {noOfUsers, plural, =1 {al nuovo utente} other {ai nuovi utenti}}",
  "components.importUsersForm.confirmImportUsersModalContentWelcomeMessageTurnOff": "Non invieremo più un messaggio di benvenuto ai nuovi utenti",
  "components.importUsersForm.confirmImportUsersModalTitle": "Conferma l'importazione di {noOfUsers} utenti selezionati",
  "components.importUsersForm.consent.line1": "Per importare gli utenti, dovrai ottenere il consenso dell'amministratore dal tuo amministratore di Microsoft Teams. Per ottenere questo consenso una tantum, invia il seguente link al tuo amministratore di Microsoft Teams:",
  "components.importUsersForm.consent.line2": "Una volta ottenuto il consenso, chiudi e riapri questa finestra modale e questo messaggio scomparirà.",
  "components.importUsersForm.consent.warning": "Si prega di chiudere e riaprire questa finestra modale una volta ottenuto il consenso per poter importare tutti gli utenti.",
  "components.importUsersForm.googleAPIErrorMessage": "Sembra che ci sia un problema con le impostazioni della tua directory Google. Per favore, contatta il tuo amministratore e invia loro il seguente errore:",
  "components.importUsersForm.importAllUsers": "Vuoi importare tutti gli utenti?",
  "components.importUsersForm.importAllUsersExceedingLimt": "Hai un gran numero di utenti nella tua directory. Se desideri importare tutti gli utenti, contatta il nostro team di Supporto e possiamo abilitare l'importazione. In alternativa, puoi selezionare specifici utenti da importare.",
  "components.importUsersForm.importAllUsersTooltip": "{noOfUsersToImport} {noOfUsersToImport, plural, =1 {utente} other {utenti}} da {platform} saranno importati in Vacation Tracker. Questo potrebbe influenzare la tua fatturazione.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimit": "Hai un gran numero di utenti nella tua directory. Se desideri importare gli utenti automaticamente, contatta il nostro team di Supporto e possiamo abilitare l'importazione.",
  "components.importUsersForm.importUsersAutomaticallyExceedingLimt": "Hai un gran numero di utenti nella tua directory. Se desideri importare gli utenti automaticamente, contatta il nostro team di Supporto e possiamo abilitare l'importazione.",
  "components.importUsersForm.importUsersAutomaticallyTooltip": "Una volta che un nuovo utente aggiunto al tuo {platform} viene importato in Vacation Tracker, riceverà un breve messaggio che spiega cos'è Vacation Tracker e come navigarlo.",
  "components.importUsersForm.importUsersTitle": "Importa Utenti",
  "components.importUsersForm.newCompanyNameInfo": "Il nuovo nome dell'azienda sarà: {newName}",
  "components.importUsersForm.selectUsers": "Seleziona gli utenti da importare:",
  "components.importUsersForm.selectUsersNext": "Configura le impostazioni per gli utenti selezionati",
  "components.importUsersForm.selectUsersStepName": "Seleziona Utenti",
  "components.importUsersForm.sendWelcomeMessageLabel": "Inviare un messaggio di benvenuto ai nuovi utenti?",
  "components.importUsersForm.trackingQuotaInfoDays": "Vacation Tracker monitorerà la durata di tutte le assenze e le quote in giorni.",
  "components.importUsersForm.trackingQuotaInfoHours": "Vacation Tracker monitorerà la durata di tutte le assenze e le quote in ore.",
  "components.importUsersForm.userEndDateInfo": "Gli utenti con una data di fine non potranno inviare richieste di assenza oltre quella data, e li disattiveremo automaticamente.",
  "components.importUsersForm.userEndDateTurnOffInfo": "Gli utenti con una data di fine potranno inviare richieste di assenza oltre quella data, e non li disattiveremo automaticamente.",
  "components.importUsersForm.usersIsRequired": "Questo campo è obbligatorio.",
  "components.importUsersForm.warningAllUsersImport": "Vacation Tracker può sincronizzare, importare ed eliminare automaticamente gli utenti dal tuo {platform}.",
  "components.importUsersForm.warningNumberOfUsersToImport": "Quando clicchi sul pulsante \"Importa e Attiva Tutti gli Utenti\", attiverai tutti i {users} utenti dalla tua directory. Questa azione può influenzare la tua fatturazione.",
  "components.importUsersForm.warningOfUsersImport": "L'importazione di {noOfUsers} {noOfUsers, plural, =1 {utente selezionato} other {utenti selezionati}} potrebbe influenzare la tua fatturazione.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNewBucket": "L'aggiunta di {noOfUsers} {noOfUsers, plural, =1 {utente} other {utenti}} aumenterà la tua prossima fattura a {amount} (IVA esclusa) a partire dal {startDate}.",
  "components.importUsersForm.warningOfUsersImportAnnualProrateAdditionalNoOfUsers": "Un addebito stimato proporzionale di {proratedAmount} (IVA esclusa) sarà applicato oggi.",
  "components.importUsersForm.warningOfUsersImportNoChange": "Stai per importare {noOfUsers} {noOfUsers, plural, =1 {utente} other {utenti}}. La tua fatturazione non sarà influenzata.",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimit": "Hai raggiunto il tuo limite attuale di posti ({seats}). Vuoi acquistarne di più?",
  "components.importUsersForm.warningOfUsersImportOverSeatsLimitImportAll": "Non puoi importare tutti gli utenti dal tuo spazio di lavoro/directory perché supererai il tuo limite attuale di posti ({seats}). Vuoi acquistarne di più?",
  "components.importUsersForm.warningOfUsersImportProrateAdditionalNewBucketMonthly": "L'importazione di {noOfUsers} {noOfUsers, plural, =1 {utente} other {utenti}} influenzerà la tua fatturazione. La tua prossima fattura sarà di {amount} (IVA esclusa) il {startDate}",
  "components.importUsersForm.warningOfUsersImportTrial": "Sei attualmente in modalità di prova. Ti verranno addebitati {amount} (IVA esclusa) il {startDate}",
  "components.importUsersForm.warningOfUsersImportTrialWithoutPaymentMethod": "Sei in un periodo di prova e non ti verrà addebitato nulla per l'importazione di utenti aggiuntivi prima del {stripeCurrentPeriodEnd}. Aggiungi le tue informazioni di fatturazione per vedere la stima dell'addebito.",
  "components.insertHolidaysForm.back": "Indietro",
  "components.insertHolidaysForm.deselectAll": "Deseleziona Tutto",
  "components.insertHolidaysForm.findHolidays": "Trova Festività",
  "components.insertHolidaysForm.insertHolidays": "Importa Festività",
  "components.insertHolidaysForm.pleaseChooseACountry": "Scegli un Paese",
  "components.insertHolidaysForm.saveHolidays": "Salva Festività",
  "components.insertHolidaysForm.selectAll": "Seleziona Tutto",
  "components.insertHolidaysForm.selectHolidays": "Seleziona festività",
  "components.insertHolidaysForm.subtitle": "La modifica potrebbe influenzare il numero di giorni di ferie per alcuni utenti. A questi utenti verranno aggiunti giorni alla loro quota disponibile per le ferie richieste. Sei sicuro di voler procedere?",
  "components.insertHolidaysForm.title": "L'importazione delle Festività sovrascriverà tutte le Festività precedentemente aggiunte.",
  "components.inviteAndManageUsersWithEmail.canNotInviteYourself": "Non puoi invitare te stesso",
  "components.inviteAndManageUsersWithEmail.header": "Inviti via email",
  "components.inviteAndManageUsersWithEmail.userAlreadyExists": "L'utente con l'email fornita esiste già",
  "components.inviteBotToPrivateChannel.descriptionOne": "Per aggiungere Vacation Tracker a un canale privato, digita",
  "components.inviteBotToPrivateChannel.descriptionTwo": "nel canale e aggiorna l'elenco",
  "components.inviteBotToPrivateChannel.text": "Vuoi selezionare un canale privato?",
  "components.inviteBotToPrivateChannel.title": "Aggiungi canale privato",
  "components.leaveBox.approve": "Approva",
  "components.leaveBox.approvedBy": "Approvato da",
  "components.leaveBox.approveLeaveText": "Conferma di voler approvare la richiesta di permesso.",
  "components.leaveBox.approveLeaveTitle": "Approva richiesta di permesso",
  "components.leaveBox.autoApproved": "Approvazione automatica",
  "components.leaveBox.cancelLeave": "Annulla permesso",
  "components.leaveBox.cancelLeaveConfirm": "Sei sicuro di voler annullare il permesso?",
  "components.leaveBox.cancelLeaveTitle": "Annulla permesso",
  "components.leaveBox.columns.daysOut": "{value} {value, plural, =1 {giorno} other {giorni}}",
  "components.leaveBox.columns.hoursOut": "{value} {value, plural, =1 {ora} other {ore}}",
  "components.leaveBox.daysLeft": "Permessi rimanenti:",
  "components.leaveBox.daysOut": "Sono {value} {value, plural, =1 {giorno} other {giorni}} fuori dall'ufficio.",
  "components.leaveBox.deny": "Nega",
  "components.leaveBox.denyLeaveConfirm": "Conferma di voler negare la richiesta di permesso.",
  "components.leaveBox.denyLeaveTitle": "Nega richiesta di permesso",
  "components.leaveBox.denyWithReason": "Nega con motivazione",
  "components.leaveBox.edit": "Modifica",
  "components.leaveBox.formattedLeaveInfo": "Sono {leave} fuori dall'ufficio.",
  "components.leaveBox.hoursOut": "Sono {value} {value, plural, =1 {ora} other {ore}} fuori dall'ufficio.",
  "components.leaveBox.leavesBoxHalfDay": "Sarà assente per {leaveTypeName} il {leaveStartDate} dalle {startHour}{hourFormat24, select, true {:00} other {}} alle {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayPending": "Richiesta di {leaveTypeName} il {leaveStartDate} dalle {startHour}{hourFormat24, select, true {:00} other {}} alle {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayToday": "È assente oggi dalle {startHour}{hourFormat24, select, true {:00} other {}} alle {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesBoxHalfDayTodayWithoutReason": "È assente oggi dalle {startHour}{hourFormat24, select, true {:00h} other {}} alle {endHour}{hourFormat24, select, true {:00h} other {}}.",
  "components.leaveBox.leavesBoxHalfDayWithoutReason": "Prende un permesso il {leaveStartDate} dalle {startHour}{hourFormat24, select, true {:00} other {}} alle {endHour}{hourFormat24, select, true {:00} other {}}.",
  "components.leaveBox.leavesOffTodayInfo": "È assente per {leaveTypeName} dal {leaveStartDate} al {leaveEndDate}. Entrambe le date incluse.",
  "components.leaveBox.leavesOffTodayWithoutReasonInfo": "È assente dal {leaveStartDate} al {leaveEndDate}. Incluse entrambe le date.",
  "components.leaveBox.leavesPendingInfo": "Richiesta di {leaveTypeName} dal {leaveStartDate} al {leaveEndDate}. Entrambe le date incluse.",
  "components.leaveBox.leavesPendingOneDay": "Richiesta di {leaveTypeName} il {leaveStartDate}.",
  "components.leaveBox.leavesUpcomingOneDayInfo": "Sarà assente per {leaveTypeName} il {leaveStartDate}.",
  "components.leaveBox.leavesUpcomingOneDayWithoutReasonInfo": "Sarà assente il {leaveStartDate}.",
  "components.leaveBox.reason": "Motivo",
  "components.leaveBox.toilDuration": "Durata: {leave}",
  "components.leaveForm.addLeave": "Aggiungi permesso",
  "components.leaveForm.addLeaveDescription": "Usa questo modulo se stai aggiungendo un permesso per un altro utente, o se stai aggiungendo un permesso per te stesso che non richiede approvazione.",
  "components.leaveForm.connectedCalendarTimezone": "Il fuso orario del calendario {calendarType} connesso è {timezone}",
  "components.leaveForm.date": "Data",
  "components.leaveForm.editLeave": "Modifica Congedo",
  "components.leaveForm.editLeaveDescription.adminAndApprover": "Puoi modificare le date di questo congedo. Se desideri modificare il tipo di congedo, annulla il congedo e aggiungine uno nuovo.",
  "components.leaveForm.editLeaveDescription.user": "Puoi modificare le date e il motivo di questo congedo. Se desideri modificare il tipo di congedo, annulla il congedo e crea una nuova richiesta.",
  "components.leaveForm.endTime": "Ora di Fine",
  "components.leaveForm.halfDay": "{leaveTypeName} (Mezza Giornata)",
  "components.leaveForm.hourly": "{leaveTypeName} (Orario)",
  "components.leaveForm.leaveTypeNamePartTime": "{leaveTypeName} (Orario)",
  "components.leaveForm.pleaseInputReason": "Il motivo è obbligatorio",
  "components.leaveForm.pleaseSelectEndDate": "Seleziona una Data di Fine",
  "components.leaveForm.pleaseSelectLeaveType": "Seleziona un Tipo di Congedo",
  "components.leaveForm.pleaseSelectRangeTime": "Seleziona un Intervallo di Tempo",
  "components.leaveForm.pleaseSelectstartDate": "Seleziona una Data di Inizio",
  "components.leaveForm.pleaseSelectUser": "Seleziona un Utente",
  "components.leaveForm.pleaseSelectValidRangeTime": "Seleziona un Intervallo di Tempo Valido",
  "components.leaveForm.rangeDate": "Data di Inizio e Fine",
  "components.leaveForm.reason": "Motivo",
  "components.leaveForm.request": "Richiedi",
  "components.leaveForm.requestLeave": "Richiedi Congedo",
  "components.leaveForm.requestLeaveDescription": "Usa questo modulo se la tua richiesta deve essere approvata da qualcun altro oltre te stesso.",
  "components.leaveForm.selectSubstituteApprover": "Seleziona Approvatore Sostitutivo",
  "components.leaveForm.selectSubstituteApproverInfoMessage": "Seleziona una persona per gestire le tue richieste di congedo in arrivo mentre sei assente. L'Approvatore Sostitutivo avrà diritti limitati, in quanto potrà solo approvare o negare le richieste di congedo. Questo passaggio è opzionale.",
  "components.leaveForm.selectUser": "Seleziona un Utente",
  "components.leaveForm.startTime": "Ora di Inizio",
  "components.leaveForm.time": "Ora",
  "components.leaveForm.user": "Seleziona un Utente",
  "components.leavePolicyForm.accrualBiWeeklySummary": "Gli utenti accumuleranno {amount} ({quota}/26) giorni ogni secondo {day}",
  "components.leavePolicyForm.accrualBiWeeklySummaryHours": "Gli utenti accumuleranno {amount} ({quota}/26) ore ogni secondo {day}",
  "components.leavePolicyForm.accrualCap": "Abilita Limite di Accumulo",
  "components.leavePolicyForm.accrualCapRate": "Limita i giorni accumulati a",
  "components.leavePolicyForm.accrualCapRateTimes": "volte il tasso di accumulo annuale",
  "components.leavePolicyForm.accrualCapTooltip": "Il Limite di Accumulo restringe la quantità di PTO che un dipendente può accumulare durante un periodo specifico. È definito come un multiplo del tasso di accumulo annuale, non come un numero preciso.",
  "components.leavePolicyForm.accrualCapWarning": "Raggiunto il Limite di Accumulo per {leaveTypeName}. L'accumulo di nuovi giorni riprenderà una volta utilizzati alcuni giorni esistenti.",
  "components.leavePolicyForm.accrualCapWarning2": "Questo utente ha raggiunto il limite di accumulo per {leaveTypeName} e non accumulerà altri giorni fino a quando non utilizzerà parte del saldo attuale.",
  "components.leavePolicyForm.accrualDay": "Giorno di Accumulo",
  "components.leavePolicyForm.accrualDayBiWeekly": "Accumulo Assegnato il",
  "components.leavePolicyForm.accrualDisabledInfo": "Disattiva la quota illimitata e imposta la quota a un numero maggiore di 0 per abilitare gli accumuli.",
  "components.leavePolicyForm.accrualMonthlySummary": "Gli utenti accumuleranno {amount} ({quota}/12) giorni il {date} di ogni mese",
  "components.leavePolicyForm.accrualMonthlySummaryHours": "Gli utenti accumuleranno {amount} ({quota}/12) ore il {date} di ogni mese",
  "components.leavePolicyForm.accruals": "Accumuli",
  "components.leavePolicyForm.accrualSemiMonthlySummary": "Gli utenti accumuleranno {amount} ({quota}/24) giorni il {firstDate} e il {secondDate} di ogni mese",
  "components.leavePolicyForm.accrualSemiMonthlySummaryHours": "Gli utenti accumuleranno {amount} ({quota}/24) ore il {firstDate} e il {secondDate} di ogni mese",
  "components.leavePolicyForm.accrualType": "Tipo di Accumulo",
  "components.leavePolicyForm.accrualTypeError": "Obbligatorio",
  "components.leavePolicyForm.accrualTypeInfo": "Il Congedo Accumulato si accumula gradualmente durante l'anno in base alla politica aziendale. Se la tua azienda ha Accumuli, seleziona il Tipo di Accumulo determinato dalla tua direzione.",
  "components.leavePolicyForm.accrualWeeklySummary": "Gli utenti accumuleranno {amount} ({quota}/52) giorni ogni {day}",
  "components.leavePolicyForm.accrualWeeklySummaryHours": "Gli utenti accumuleranno {amount} ({quota}/52) ore ogni {day}",
  "components.leavePolicyForm.addTitle": "Assegna Politica di Congedo",
  "components.leavePolicyForm.allDays": "Tutti",
  "components.leavePolicyForm.allowAddAndEditLeaveInPast": "Modifica e Richiedi Congedi nel Passato",
  "components.leavePolicyForm.allowAddAndEditLeaveInPastInfo": "Scegli se vuoi permettere agli utenti regolari di modificare o richiedere congedi nel passato.",
  "components.leavePolicyForm.allowAdvanceAccrualUsage": "Consenti Richiesta di Congedo Basato su Accumulo Futuro",
  "components.leavePolicyForm.allowAdvanceAccrualUsageInfo": "Consenti ai dipendenti di richiedere congedi basati sul loro accumulo previsto di giorni liberi, invece che solo su quanto hanno accumulato al momento della richiesta. Ciò significa che se un dipendente sa che avrà abbastanza giorni accumulati al momento del congedo richiesto, può inviare la richiesta in anticipo, anche se non ha abbastanza giorni al momento della richiesta.",
  "components.leavePolicyForm.approvalRequired": "Approvazione Richiesta",
  "components.leavePolicyForm.assignLeavePolicy": "Assegna Politica di Congedo",
  "components.leavePolicyForm.back": "Indietro",
  "components.leavePolicyForm.BI-WEEKLY": "Bisettimanale",
  "components.leavePolicyForm.cancel": "Annulla",
  "components.leavePolicyForm.copyLeavePolicy": "Copia Politica Da",
  "components.leavePolicyForm.copyLeavePolicyDivider": "Puoi utilizzare un modello da un altro congedo o località.",
  "components.leavePolicyForm.copyLeavePolicyInfo": "Copia le impostazioni della Politica di Congedo da un Tipo di Congedo precedentemente impostato. Nel menu a discesa, scegli il Tipo di Congedo da cui vuoi copiare la Politica di Congedo, e le impostazioni verranno configurate automaticamente. Se desideri farlo manualmente, puoi farlo configurando le impostazioni sottostanti.",
  "components.leavePolicyForm.createLeaveTypeFormAccrualsInfo1": "Gli accumuli sono inattivi durante la creazione di un tipo di congedo. Per attivarli, personalizza la politica di congedo per una località specifica.",
  "components.leavePolicyForm.createLeaveTypeFormToilInfo1": "Il TOIL (Tempo Libero Compensativo) è inattivo durante la creazione di un tipo di congedo. Per attivarlo, personalizza la politica di congedo per una località specifica.",
  "components.leavePolicyForm.currentAccrualPeriod": "Periodo di Accumulo Corrente",
  "components.leavePolicyForm.daysPerYear": "Quota Annuale di Congedo",
  "components.leavePolicyForm.defaultLeavePolicy": "Non copiare, inizierò una nuova politica",
  "components.leavePolicyForm.editTitle": "Modifica Politica di Congedo {leavePolicyName} per {locationName}",
  "components.leavePolicyForm.futureBroughtForward": "Riporto futuro",
  "components.leavePolicyForm.halfDays": "Consenti Mezze Giornate",
  "components.leavePolicyForm.hasUnlimitedDays": "Quota Illimitata",
  "components.leavePolicyForm.hideLeaveType": "Nascondi Tipo di Congedo",
  "components.leavePolicyForm.hideLeaveTypeInfo": "Scegli se vuoi nascondere questo Tipo di Congedo dal Calendario e dalle Notifiche. Solo gli Amministratori, gli Approvatori e l'Utente che ha richiesto il Tipo di Congedo potranno identificarlo sul Calendario.",
  "components.leavePolicyForm.hourlyIncrementsTooltip": "Consenti ai membri del tuo team di richiedere congedi basati sulle ore.",
  "components.leavePolicyForm.isActive": "Attivo",
  "components.leavePolicyForm.leavePolicy": "Politica di Congedo",
  "components.leavePolicyForm.limitedNumberOfDays": "Limitato",
  "components.leavePolicyForm.maxRolloverDays": "Massimo Giorni Riportati",
  "components.leavePolicyForm.maxRolloverDaysError": "Obbligatorio",
  "components.leavePolicyForm.maxRolloverDaysInfo": "Seleziona il numero di giorni che possono essere riportati all'anno successivo per questo Tipo di Congedo.",
  "components.leavePolicyForm.maxRolloverDaysInfoHours": "Seleziona il numero di ore che possono essere riportate all'anno successivo per questo Tipo di Congedo.",
  "components.leavePolicyForm.MONTHLY": "Mensile",
  "components.leavePolicyForm.negativeBalance": "Consenti Saldo Negativo",
  "components.leavePolicyForm.negativeBalanceInfo": "Scegli se vuoi autorizzare gli Utenti a superare la Quota di Ferie consentita che hai impostato per questo Tipo di Ferie. Se non vuoi consentire questa opzione, lascia il pulsante deselezionato.",
  "components.leavePolicyForm.nextAccrualDay": "Prossimo Accumulo Assegnato Il",
  "components.leavePolicyForm.noLeaveTypes": "Non ci sono Tipi di Ferie disponibili. Per aggiungere un nuovo Tipo di Ferie con una Politica di Ferie personalizzata, vai alla scheda Tipi di Ferie per creare un nuovo Tipo di Ferie, e poi aggiungilo alla Località.",
  "components.leavePolicyForm.NONE": "Nessuno",
  "components.leavePolicyForm.noRollover": "Disabilitato",
  "components.leavePolicyForm.override.affectedUsers": "Utenti interessati: <strong>{totalUsers}</strong>",
  "components.leavePolicyForm.override.change": "Modifica: <unlimited>Modifica politica ferie illimitate</unlimited><customDays>Aggiornamento quota ferie</customDays>",
  "components.leavePolicyForm.override.customLeaveQuotaInfo": "Alcuni degli Utenti interessati (<strong>{totalUsers}</strong>) hanno quote ferie personalizzate. Vuoi sovrascrivere questa impostazione?",
  "components.leavePolicyForm.override.leaveType": "Tipo di ferie: <strong>{name}</strong>",
  "components.leavePolicyForm.override.location": "Località: <strong>{name}</strong>",
  "components.leavePolicyForm.override.note": "Nota: Questa modifica influenzerà tutti gli utenti senza una quota ferie personalizzata, indipendentemente dall'opzione scelta.",
  "components.leavePolicyForm.override.overrideDisable": "Sovrascrivi la loro quota ferie personalizzata",
  "components.leavePolicyForm.override.overrideEnable": "Mantieni la quota ferie esistente",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivated": "Sospendi Accumuli Quando l'Utente è Disattivato",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedInfo": "Attiva questa funzione per sospendere temporaneamente gli accumuli di giorni di ferie per qualsiasi utente che viene disattivato. Una volta che l'utente viene riattivato, il loro accumulo di giorni di ferie riprenderà automaticamente, garantendo un tracciamento accurato dei loro diritti di ferie",
  "components.leavePolicyForm.pauseAccrualsWhenUserIsDeactivatedUserInfo": "Secondo le politiche della tua organizzazione, l'accumulo di ferie è sospeso mentre l'account di questo utente è inattivo. Contatta il tuo amministratore per ulteriori dettagli.",
  "components.leavePolicyForm.previouesBroughForwardAndFutureOnce": "Precedenti riportati e futuri",
  "components.leavePolicyForm.reasonRequired": "Motivo Richiesto",
  "components.leavePolicyForm.required": "Obbligatorio",
  "components.leavePolicyForm.rolloverDaysPerYear": "Numero massimo di giorni",
  "components.leavePolicyForm.rolloverPerYearDays": "Numero Massimo Di Giorni",
  "components.leavePolicyForm.rolloverPerYearHours": "Numero Massimo Di Ore",
  "components.leavePolicyForm.rolloverSettings": "Impostazioni Riporto",
  "components.leavePolicyForm.rolloverType": "Riportato",
  "components.leavePolicyForm.save": "Salva",
  "components.leavePolicyForm.saveLeavePolicy": "Salva",
  "components.leavePolicyForm.selectLeaveType": "Seleziona Tipo di Ferie",
  "components.leavePolicyForm.SEMI-MONTHLY": "Quindicinale",
  "components.leavePolicyForm.updateLeavePolicy": "Aggiorna",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChanges": "Quando Vorresti Applicare Queste Modifiche?",
  "components.leavePolicyForm.whenWouldYouLikeToApplayTheseChangesInfo": "Seleziona l'intervallo di tempo per applicare le tue modifiche alle ferie. Scegli 'Riporto futuro' per modificare solo le date di ferie future, o 'Riporti precedenti e futuri' per modificare sia le date passate che future.",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChanges": "Quando Vorresti Applicare Queste Modifiche?",
  "components.leavePolicyForm.whenWouldYouLikeToApplyTheseChangesInfo": "Seleziona l'intervallo di tempo per applicare le tue modifiche alle ferie. Scegli 'Riporto futuro' per modificare solo le date di ferie future, o 'Riporti precedenti e futuri' per modificare sia le date passate che future.",
  "components.leavePolicyForm.yearlyQuota": "Quota Annuale di Ferie",
  "components.leavePolicyForm.yearlyQuotaHours": "Quota Annuale di Ferie (Ore)",
  "components.leavePolicyForm.yearlyQuotaInfo": "Questo campo rappresenta la quota base annuale di ferie. Potrai regolare le quote individuali manualmente tramite i profili utente o automatizzare le regolazioni per diversi utenti con la nostra funzione di automazione.",
  "components.leaveRequestAction.added": "Ferie <forUser>per </forUser>{requestor} aggiunte <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.addToCalendar": "Aggiungi a un Calendario",
  "components.leaveRequestAction.addToCalendarMoreInfo": "Maggiori informazioni sulla funzione \"Aggiungi a un Calendario\"",
  "components.leaveRequestAction.approved": "Permesso di {requestor} approvato <byActionCreator>da {actionCreator}</byActionCreator> ✅",
  "components.leaveRequestAction.approversLabel": "Approvatori: ",
  "components.leaveRequestAction.autoApproved": "Il permesso di {requestor} è stato approvato automaticamente",
  "components.leaveRequestAction.cancelled": "Permesso di {requestor} annullato <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.created": "Permesso richiesto <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.deleted": "Permesso cancellato <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.denied": "Permesso di {requestor} negato <byActionCreator>da {actionCreator}</byActionCreator> ❌",
  "components.leaveRequestAction.edited": "Permesso di {requestor} modificato <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.editedApproved": "Un permesso programmato è stato modificato <byActionCreator>da {actionCreator}</byActionCreator> ✅",
  "components.leaveRequestAction.editedAutoApproved": "Permesso modificato approvato automaticamente <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.editedDenied": "Permesso modificato negato <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.leaveRequestAction.fullDayLeavePeriod": "{date}{endDate} ",
  "components.leaveRequestAction.leaveRemaining": "Permessi rimanenti: ",
  "components.leaveRequestAction.leaveRequested": "Permessi richiesti: ",
  "components.leaveRequestAction.leaveTypeLabel": "Tipo di permesso: ",
  "components.leaveRequestAction.partDayLeavePeriod": "{date} - dalle {partDayStartHour}{hourFormat24, select, true {:00} other {}}  alle {partDayEndHour}{hourFormat24, select, true {:00} other {}} ",
  "components.leaveRequestAction.periodLabel": "Data: ",
  "components.leaveRequestAlertStatus.leaveRequestApprovedByYouInfo": "Hai approvato la richiesta. Una notifica verrà inviata a {name} per informarlo.",
  "components.leaveRequestAlertStatus.leaveRequestApprovedInfo": "La richiesta di permesso è stata approvata da {approverName}.",
  "components.leaveRequestAlertStatus.leaveRequestCancelledInfo": "La richiesta di permesso è stata annullata.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedByYouInfo": "Hai negato la richiesta. Una notifica verrà inviata a {name} per informarlo.",
  "components.leaveRequestAlertStatus.leaveRequestDeniedInfo": "La richiesta di permesso è stata negata da {approverName}.",
  "components.leaveRequestAlertStatus.leaveRequestExpiredInfo": "La richiesta è scaduta.",
  "components.leaveRequestAlertStatus.leaveRequestHasApprovedByYouInfo": "La richiesta di permesso è stata approvata.",
  "components.leaveRequestAlertStatus.leaveRequestHasDeniedByYouInfo": "La richiesta di permesso è stata negata.",
  "components.leavesColumns.approver": "Approvatore",
  "components.leavesColumns.autoApproved": "Approvazione automatica",
  "components.leavesColumns.cancelledBeforeReview": "Annullato prima della revisione",
  "components.leavesColumns.dates": "Data/e",
  "components.leavesColumns.days": "{value} giorni",
  "components.leavesColumns.denyReason": "Motivo dell'Approvatore",
  "components.leavesColumns.duration": "Durata",
  "components.leavesColumns.halfDayHoliday": "(Mezza Giornata Festiva)",
  "components.leavesColumns.hours": "{value} ore",
  "components.leavesColumns.leaveType": "Tipo di Permesso",
  "components.leavesColumns.reason": "Motivo del Richiedente",
  "components.leavesColumns.reviewedby": "Esaminato da",
  "components.leavesColumns.status": "Stato",
  "components.leaveTypeForm.cancel": "Annulla",
  "components.leaveTypeForm.color": "Colore",
  "components.leaveTypeForm.editLeaveTypeTitle": "Modifica tipo di congedo {leaveTypeName}",
  "components.leaveTypeForm.forbiddenCharacters": "Il nome del tipo di congedo non può contenere punteggiatura o caratteri speciali.",
  "components.leaveTypeForm.isActive": "Attivo",
  "components.leaveTypeForm.maxLength": "Il nome del tipo di congedo è troppo lungo.",
  "components.leaveTypeForm.name": "Nome",
  "components.leaveTypeForm.nameIsRequired": "Questo campo è obbligatorio.",
  "components.leaveTypeForm.save": "Salva",
  "components.leaveTypeForm.setSlackStatus": "Imposta stato Slack",
  "components.leaveTypeForm.setSlackStatusPlaceholder": "Si prega di impostare lo stato Slack",
  "components.leaveTypeForm.slackStatus": "Stato Slack",
  "components.leaveTypeForm.title": "Tipo di congedo",
  "components.leaveTypeForm.tooltipElement1": "<strong>Approvazione richiesta</strong>: Questa opzione può essere attivata se un congedo richiede l'approvazione di un manager.",
  "components.leaveTypeForm.tooltipElement2": "<strong>Consenti saldo negativo</strong>: Questa opzione permette agli utenti di richiedere un congedo anche dopo aver esaurito la loro quota.",
  "components.leaveTypeForm.tooltipElement3": "<strong>Consenti mezze giornate</strong>: Questa opzione può essere attivata in base alle esigenze della vostra organizzazione.",
  "components.leaveTypeForm.tooltipFooter": "Ricorda, è importante notare che le politiche di congedo sono definite per località. Puoi personalizzare queste politiche in base alle esigenze della tua organizzazione e assegnarle ai tipi di congedo appropriati.",
  "components.leaveTypeForm.tooltipHeader": "In Vacation Tracker, i 'Tipi di congedo' si riferiscono a categorie globali di congedi come ferie retribuite, giorni di malattia, lavoro da casa e così via. D'altra parte, le 'Politiche di congedo' sono regole e impostazioni specifiche assegnate a ciascun Tipo di congedo per località. Queste politiche possono includere varie opzioni come:",
  "components.legacyPlanAlert.warning": "Stai attualmente utilizzando il piano legacy. Se cambi piano, non potrai più riattivarlo. Per ulteriori informazioni, contatta il nostro <link>supporto</link>.",
  "components.locationForm.changeLocationWarning": "Seleziona la Località in cui vuoi trasferire l'Utente.",
  "components.locationForm.changeUserLocation": "Cambia la località dell'utente",
  "components.locationForm.days": "Giorni",
  "components.locationForm.daysAfterEmploymentDate": "giorni dopo la data di assunzione.",
  "components.locationForm.daysInfo": "Il riporto dei congedi non utilizzati all'anno successivo scadrà dopo il numero di giorni che hai impostato a seguito della Data di Assunzione dell'Utente. La Quota di Congedo per ogni Tipo di Congedo è configurata attraverso la scheda Tipi di Congedo nelle Impostazioni della Località, inclusa l'opzione per abilitare o disabilitare il riporto.",
  "components.locationForm.defaultLocation": "Rendi questa località predefinita",
  "components.locationForm.firstDayOfWeek": "La settimana inizia il",
  "components.locationForm.fiscalYear": "Anno contabile",
  "components.locationForm.fiscalYearStart": "Inizio anno fiscale",
  "components.locationForm.name": "Nome",
  "components.locationForm.noRolloverExpiryDate": "Nessuna data di scadenza per i giorni riportati",
  "components.locationForm.overrideUserSettings": "Sovrascrivi impostazioni utente",
  "components.locationForm.overrideUserSettingsInfo": "La configurazione della settimana lavorativa verrà applicata solo agli Utenti che non hanno Impostazioni personalizzate nel loro Profilo Utente. Se vuoi applicare le nuove impostazioni della Settimana lavorativa a tutti gli Utenti, inclusi quelli che hanno una Settimana lavorativa personalizzata, seleziona l'opzione \"Sovrascrivi impostazioni utente\".",
  "components.locationForm.resetQuotas": "Azzeramento quota congedi basato su",
  "components.locationForm.resetQuotasInfo": "Questa impostazione determinerà se il tuo saldo congedi verrà azzerato in base all'anno contabile (anno fiscale dell'azienda) o in base alla data di inizio del dipendente. Oltre alle quote, anche la tua politica di riporto sarà influenzata da questa impostazione.",
  "components.locationForm.rolloverExpiryAfterDays": "Nessuna data di scadenza per i giorni riportati",
  "components.locationForm.rolloverExpiryAfterDaysInfo": "Ogni anno, i congedi riportati degli utenti scadranno dopo questo numero di giorni dalla loro data di assunzione. Le quote per ogni tipo di congedo sono configurate attraverso la sezione Tipi di Congedo per questa località e ciascuna può essere impostata individualmente per consentire o non consentire i riporti.",
  "components.locationForm.rolloverExpiryDay": "Nessuna data di scadenza per i giorni riportati",
  "components.locationForm.rolloverExpiryDayInfo": "Ogni anno, i congedi riportati dell'utente scadranno alla data che hai impostato. Le quote per ogni tipo di congedo sono configurate attraverso la sezione Tipi di Congedo per questa località e ciascuna può essere impostata individualmente per consentire o non consentire i riporti.",
  "components.locationForm.selectLocation": "Seleziona località",
  "components.locationForm.selectLocationError": "Si prega di selezionare una località.",
  "components.locationForm.settings.makeDefaultDescription": "Rendendo questa Località quella predefinita, tutti i nuovi membri del team saranno automaticamente aggiunti a questa Località.",
  "components.locationForm.settings.makeDefaultTitle": "Posizione Predefinita",
  "components.locationForm.settings.nameDescription": "Imposta il nome della tua posizione e seleziona i suoi utenti. Se vuoi configurare la posizione prima di aggiungere utenti, puoi creare prima la posizione e aggiungere gli utenti in seguito.",
  "components.locationForm.settings.nameDescription2": "Questo fuso orario predefinito viene utilizzato in tutto il sistema. Ad esempio, per visualizzare con precisione le informazioni sulle assenze nel calendario e per gli eventi di sistema elencati nei Log.",
  "components.locationForm.settings.namePlaceholder": "Inserisci il nome della posizione",
  "components.locationForm.settings.nameTitle": "Impostazioni della Posizione",
  "components.locationForm.settings.quotasDescription": "Questa impostazione determinerà se il tuo saldo ferie verrà azzerato in base all'anno contabile (anno fiscale dell'azienda) o in base alla data di inizio del dipendente. Oltre alle quote, anche la tua politica di riporto sarà influenzata da questa impostazione.",
  "components.locationForm.settings.quotasTitle": "Quota Ferie e Anno Fiscale",
  "components.locationForm.settings.workWeekDescription": "Seleziona i giorni lavorativi della settimana per garantire un tracciamento accurato dei dati delle assenze.",
  "components.locationForm.settings.workWeekTitle": "Settimana Lavorativa e Giornata Lavorativa",
  "components.locationForm.users": "Utenti",
  "components.locationForm.userYear": "Data di Assunzione dell'Utente",
  "components.locationForm.warningInitialRollover": "Qualsiasi modifica alle impostazioni della posizione potrebbe influire sulla configurazione degli utenti e sulle informazioni sulle assenze come la quota ferie e i calcoli. \nNota che se hai impostato una data di scadenza per il riporto su qualsiasi politica di assenza in questa posizione, verrà disabilitata.",
  "components.locationForm.warningInitialRolloverFiscalYear": "Qualsiasi modifica alle impostazioni della posizione potrebbe influire sulla configurazione degli utenti e sulle informazioni sulle assenze come la quota ferie e i calcoli.",
  "components.locationForm.warningInitialRolloverResetQuotas": "Qualsiasi modifica alle impostazioni della posizione potrebbe influire sulla configurazione degli utenti e sulle informazioni sulle assenze come la quota ferie e i calcoli.",
  "components.locationForm.warningInitialRolloverUsers": "Aggiungere o rimuovere un utente potrebbe influire sulla configurazione dell'utente e sulle informazioni sulle assenze (ad esempio, i giorni iniziali riportati).",
  "components.locationForm.workWeek": "Settimana Lavorativa",
  "components.locationHolidays.addHolidays": "Aggiungi una Festività",
  "components.locationHolidays.deleteHolidaysConfirm": "Sei sicuro di voler eliminare la festività {name}?",
  "components.locationHolidays.deleteHolidaysTitle": "Elimina Festività",
  "components.locationHolidays.holidaysForLocation": "Festività per la posizione {locationName}",
  "components.locationHolidays.insertHolidays": "Importa Festività",
  "components.locationHolidays.overlap": "Questa festività si sovrappone a una esistente",
  "components.locationLeavePolicies.activatingLeaveTypeInfo": "Attivando questo Tipo di Assenza, consentirai agli Utenti di richiedere il Tipo di Assenza specifico. \n\nLe impostazioni e i dati precedenti verranno ripristinati.",
  "components.locationLeavePolicies.activatingLeaveTypeTitle": "Attiva Tipo di Assenza",
  "components.locationLeavePolicies.allowHalfDays": "Consenti Mezze Giornate",
  "components.locationLeavePolicies.createNewLeavePolicy": "Assegna Politica di Assenza",
  "components.locationLeavePolicies.createNewLeavePolicyInfo": "Per aggiungere un Tipo di Assenza personalizzato a una Posizione, vai su Assegna Politica di Assenza. Seleziona il Tipo di Assenza che hai già creato tramite la scheda Tipi di Assenza e configura le impostazioni della Politica di Assenza.",
  "components.locationLeavePolicies.daysPerYear": "Quota Ferie Annuale",
  "components.locationLeavePolicies.deactivateLeaveTypeInfo": "Una volta disattivato questo Tipo di Assenza, gli Utenti in questa Posizione non potranno più richiedere il Tipo di Assenza specifico. Quando lo riattiverai, il Tipo di Assenza verrà ripristinato con le sue impostazioni e dati precedenti. \n\nSe vuoi disattivare questo Tipo di Assenza per tutte le Posizioni, puoi farlo dalla scheda Tipi di Assenza.",
  "components.locationLeavePolicies.deactivateLeaveTypeTitle": "Disattiva Tipo di Assenza",
  "components.locationLeavePolicies.hasUnlimitedDays": "Illimitato",
  "components.locationLeavePolicies.isApprovalRequired": "Approvazione Richiesta",
  "components.locationLeavePolicies.leaveTypeName": "Nome del Tipo di Assenza",
  "components.locationLeavePolicies.status": "Stato",
  "components.locationLeavePolicy.createInProgress": "Creazione della politica di assenza {name}",
  "components.locationLeavePolicy.successTitle": "La politica di assenza è impostata",
  "components.locationLeavePolicy.updateInProgress": "Aggiornamento della politica di assenza",
  "components.locationLeaveTypesTag.hiddenLeaveType": "Tipo di Assenza Nascosto",
  "components.locationLeaveTypesTag.holidays": "Festività",
  "components.logs.accrual": "L'utente ha maturato {earned} {leaveTypeName} {earnedDayInGrammaticalNumber} (su una quota di {defaultDaysPerYear} {defaultDayInGrammaticalNumber})",
  "components.logs.accrualDays": "L'utente ha maturato {earned} {leaveTypeName} {earned, plural, =1 {giorno} other {giorni}} (su una quota di {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {giorno} other {giorni}})",
  "components.logs.accrualDisplay": "L'utente ha maturato {earned} {leaveTypeName} (su una quota di {defaultDaysPerYear})",
  "components.logs.accrualHours": "L'utente ha maturato {earned} {leaveTypeName} {earned, plural, =1 {ora} other {ore}} (su una quota di {defaultDaysPerYear} {defaultDaysPerYear, plural, =1 {ora} other {ore}})",
  "components.logs.automationAddonsUpdatedPurchased": "{user} ha acquistato l'Add-On: {automationType}",
  "components.logs.automationAddonsUpdatedUnsubscribed": "{user} ha disdetto l'abbonamento all'Add-On: {automationType}",
  "components.logs.billingCancelled": "Fatturazione annullata su {paymentProcessor}",
  "components.logs.billingUpdated": "Fatturazione aggiornata su {paymentProcessor}",
  "components.logs.blackoutPeriodCreated": "{user} ha creato un Periodo di Blocco <b>{name}</b>.<br></br>Abilitato: {enabled}.<br></br>Ricorrente: {recurring}.<br></br>Data di inizio: {startDate}.<br></br>Data di fine: {endDate}.<br></br>Tipi di congedo: {leaveTypes}.<br></br>Sedi: {locations}.<br></br>Dipartimenti: {teams}.<br></br>Etichette: {labels}.<br></br>",
  "components.logs.blackoutPeriodDeleted": "{user} ha eliminato un Periodo di Blocco.",
  "components.logs.blackoutPeriodUpdated": "{user} ha aggiornato un Periodo di Blocco <b>{name}</b>.<br></br>Abilitato: {enabled}.<br></br>Ricorrente: {recurring}.<br></br>Data di inizio: {startDate}.<br></br>Data di fine: {endDate}.<br></br>Tipi di congedo: {leaveTypes}.<br></br>Sedi: {locations}.<br></br>Dipartimenti: {teams}.<br></br>Etichette: {labels}.<br></br>",
  "components.logs.companyCreated": "L'azienda <b>{name}</b> è stata creata",
  "components.logs.companySettingsUpdated": "<b>{user}</b> ha aggiornato le impostazioni aziendali.<br></br>Annuncia nuovi utenti: {announceNewUsers}.<br></br>Conteggio ferie orario: {hourlyLeaveAccounting}.<br></br>Data di fine utente: {userEndDate}.<br></br>Anno di inizio calcolo: {calculationStartYear}<br></br>Importa utenti automaticamente: {importUsersAutomatically}",
  "components.logs.deleted": "<Eliminato>",
  "components.logs.earned": "L'utente ha guadagnato {total} giorni di {leaveTypeName} dal lavoro straordinario (TOIL) {period}. {showExpiration, plural, =1 {I giorni scadranno il } other {}}{expiration}",
  "components.logs.entitlementByRoleCreated": "{user} ha creato un Diritto per Ruolo <b>{name}</b>.<br></br>Abilitato: {enabled}.<br></br>Giorni: {days}.<br></br>Etichetta: {label}.<br></br>Tipo di congedo: {leaveType}.<br></br>",
  "components.logs.entitlementByRoleDeleted": "{user} ha eliminato un Diritto per Ruolo.",
  "components.logs.entitlementByRoleUpdated": "{user} ha aggiornato un Diritto per Ruolo <b>{name}</b>.<br></br>Abilitato: {enabled}.<br></br>Giorni: {days}.<br></br>Etichetta: {label}.<br></br>Tipo di congedo: {leaveType}.<br></br>",
  "components.logs.failedToLoadLog": "Caricamento del log fallito",
  "components.logs.importLeaves": "<b>{user}</b> ha importato {totalLeaves} {totalLeaves, plural, =1 {congedo} other {congedi}}.",
  "components.logs.importUsers": "<b>{user}</b> ha importato {totalUsers} {totalUsers, plural, =1 {utente} other {utenti}}.",
  "components.logs.initialRolloverDeleted": "Il riporto iniziale per il Tipo di Congedo: {leaveTypeName} è stato azzerato da <link>{creatorName}</link>",
  "components.logs.initialRolloverUpdated": "Il riporto iniziale per il Tipo di Congedo: {leaveTypeName} è stato modificato a {customRolloverDays} da <link>{creatorName}</link>",
  "components.logs.leavePolicyDisabled": "{user} ha disabilitato la politica di congedo <b>{leaveTypeName}</b> nella sede <b>{locationName}</b>",
  "components.logs.leavePolicyEnabled": "{user} ha abilitato la politica di congedo <b>{leaveTypeName}</b> nella sede <b>{locationName}</b>.<br></br>Giorni all'anno: {daysPerYear}.<br></br>Massimo giorni riportabili: {maxRolloverDays}.<br></br>I giorni riportati devono scadere: {enableRolloverExpiry}.<br></br>I giorni riportati scadono il: {rolloverExpiryDate}.<br></br>I giorni riportati scadono dopo giorni: {rolloverExpiryAfterDays}.<br></br>Approvazione richiesta: {isApprovalRequired}.<br></br>Giorni illimitati: {hasUnlimitedDays}.<br></br>Intervallo minimo di congedo: {shortestLeaveInterval}.<br></br>Motivazione richiesta: {isReasonRequired}.<br></br>Tipo di maturazione: {accrualType}.<br></br>Saldo negativo consentito: {negativeBallanceAllowed}.<br></br>Consenti congedo nel passato: {allowLeaveInPast}.<br></br>Limite di maturazione: {accrualCapRate, plural, =0 {Disabilitato} other {Abilitato con tasso {accrualCapRate}}}.<br></br>Nascondi tipo di congedo: {hideLeaveType}.<br></br>TOIL abilitato: {toil}.<br></br>Intervallo minimo TOIL: {toilShortestInterval}.<br></br>TOIL per tutti gli utenti: {toilRequestsAllowedForUsers}.<br></br>Scadenza TOIL: {toilExpiration}.<br></br>Data di scadenza TOIL: {toilExpirationDate}.<br></br>Mesi di scadenza TOIL: {toilExpirationMonths}.",
  "components.logs.leaveTypeCreated": "{user} ha creato il tipo di congedo <b>{name}</b> e lo ha assegnato alle sedi: <b>{locations}</b>.<br></br>Colore: {color}.<br></br>Giorni all'anno: {daysPerYear}.<br></br>Numero massimo di giorni riportabili: {maxRolloverDays}.<br></br>Approvazione richiesta: {isApprovalRequired}.<br></br>Giorni illimitati: {hasUnlimitedDays}.<br></br>Intervallo minimo di congedo: {shortestLeaveInterval}.<br></br>Motivazione richiesta: {isReasonRequired}.<br></br>Tipo di maturazione: {accrualType}.<br></br>Saldo negativo consentito: {negativeBallanceAllowed}.<br></br>Consenti l'aggiunta di congedi nel passato: {allowLeaveInPast}.<br></br>Nascondi tipo di congedo: {hideLeaveType}",
  "components.logs.leaveTypeDeleted": "{user} ha eliminato il tipo di congedo <b>{name}</b>",
  "components.logs.leaveTypeUpdated": "{user} ha aggiornato il tipo di congedo <b>{name}</b>.<br></br>Attivo: {isActive}.<br></br>Colore: {color}",
  "components.logs.locationCreated": "{user} ha creato una sede <b>{name}</b>.<br></br>Mese di inizio anno: {yearStartMonth}.<br></br>Giorno di inizio anno: {yearStartDay}.<br></br>Sede predefinita: {isDefault}.<br></br>Primo giorno della settimana: {firstDayOfWeek}.<br></br>Reset delle quote basato su: {resetQuotas}.<br></br>Mese di scadenza del riporto: {rolloverExpiryMonth}.<br></br>Giorno di scadenza del riporto: {rolloverExpiryDay}.<br></br>Scadenza del riporto dopo giorni: {rolloverExpiryAfterDays}.<br></br>Fuso orario: {timezone}.<br></br>Utenti: {users}.<br></br>Giorni: {days}",
  "components.logs.locationDeleted": "{user} ha eliminato la sede",
  "components.logs.locationHolidaysAutoimportOFF": "<b>{user}</b> ha disattivato l'importazione automatica delle festività per la sede <b>{location}</b>",
  "components.logs.locationHolidaysAutoimportON": "<b>{user}</b> ha attivato l'importazione automatica delle festività per la sede <b>{location}</b><br></br>Festività: {holidays}",
  "components.logs.locationUpdated": "{user} ha aggiornato la sede <b>{name}</b>.<br></br>Mese di inizio anno: {yearStartMonth}.<br></br>Giorno di inizio anno: {yearStartDay}.<br></br>Sede predefinita: {isDefault}.<br></br>Primo giorno della settimana: {firstDayOfWeek}.<br></br>Reset delle quote: {resetQuotas}.<br></br>Mese di scadenza del riporto: {rolloverExpiryMonth}.<br></br>Giorno di scadenza del riporto: {rolloverExpiryDay}.<br></br>Scadenza del riporto dopo giorni: {rolloverExpiryAfterDays}.<br></br>Fuso orario: {timezone}.<br></br>Utenti: {users}",
  "components.logs.locationWorkWeekUpdated": "{user} ha aggiornato la settimana lavorativa nella sede <b>{name}</b>.<br></br>Giorni: {days}.<br></br>Ore: {hours}",
  "components.logs.locationYearHolidaysChanged": "<b>{user}</b> ha modificato le festività per l'anno {year} nella sede <b>{name}</b>.<br></br>Festività: {holidays}",
  "components.logs.locationYearRollover": "Il riporto annuale è avvenuto nella sede <b>{name}</b>",
  "components.logs.nonAccrualLeaveType": "L'utente ha ricevuto {earned} <b>{leaveTypeName}</b> per {period}",
  "components.logs.nonAccrualLeaveTypeDays": "L'utente ha ricevuto {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {giorno} other {giorni}} per {period}",
  "components.logs.nonAccrualLeaveTypeHours": "L'utente ha ricevuto {earned} <b>{leaveTypeName}</b> {earned, plural, =1 {ora} other {ore}} per {period}",
  "components.logs.notificationCreated": "L'utente {user} ha creato una notifica <b>{name}</b>.<br></br>Frequenza: {frequency}.<br></br>Abilitata: {enabled}.<br></br>Sedi: {locations}.<br></br>Dipartimenti: {teams}.<br></br>Etichette: {labels}.<br></br>Primo giorno della settimana: {firstDayOfWeek}.<br></br>Fuso orario: {timezone}.<br></br>Raggruppa per {groupBy}.<br></br>Invia vuota: {sendEmpty}.<br></br>Destinazioni: {destinations}.<br></br>Minuto: {minute}.<br></br>Ora: {hour}.<br></br>Giorno: {day}.<br></br>Lingua: {locale}.<br></br>Invia nei giorni: {sendOnDays}.<br></br>Settimana corrente: {currentWeek}",
  "components.logs.notificationDeleted": "L'utente {user} ha eliminato la notifica",
  "components.logs.notificationDetailsDaily": "L'utente {user} {action} notifica <b>{name}</b>.<br></br>Frequenza: {frequency}.<br></br>Abilitato: {enabled}.<br></br>Località: {locations}.<br></br>Dipartimenti: {teams}.<br></br>Etichette: {labels}.<br></br>Fuso orario: {timezone}.<br></br>Raggruppa per {groupBy}.<br></br>Invia vuoto: {sendEmpty}.<br></br>Destinazioni: {destinations}.<br></br>Minuto: {minute}.<br></br>Ora: {hour}.<br></br>Lingua: {locale}.<br></br>Invia nei giorni: {sendOnDays}.",
  "components.logs.notificationDetailsMonthly": "L'utente {user} {action} notifica <b>{name}</b>.<br></br>Frequenza: {frequency}.<br></br>Abilitato: {enabled}.<br></br>Località: {locations}.<br></br>Dipartimenti: {teams}.<br></br>Etichette: {labels}.<br></br>Primo giorno della settimana: {firstDayOfWeek}.<br></br>Fuso orario: {timezone}.<br></br>Raggruppa per {groupBy}.<br></br>Invia vuoto: {sendEmpty}.<br></br>Destinazioni: {destinations}.<br></br>Minuto: {minute}.<br></br>Ora: {hour}.<br></br>Giorno: {day}.<br></br>Lingua: {locale}.<br></br>Settimana corrente: {currentWeek}",
  "components.logs.notificationUpdated": "L'utente {user} ha aggiornato una notifica <b>{name}</b>.<br></br>Frequenza: {frequency}.<br></br>Abilitato: {enabled}.<br></br>Località: {locations}.<br></br>Dipartimenti: {teams}.<br></br>Etichette: {labels}.<br></br>Primo giorno della settimana: {firstDayOfWeek}.<br></br>Fuso orario: {timezone}.<br></br>Raggruppa per {groupBy}.<br></br>Invia vuoto: {sendEmpty}.<br></br>Destinazioni: {destinations}.<br></br>Minuto: {minute}.<br></br>Ora: {hour}.<br></br>Giorno: {day}.<br></br>Lingua: {locale}.<br></br>Invia nei giorni: {sendOnDays}.<br></br>Settimana corrente: {currentWeek}",
  "components.logs.probationPeriodCreated": "{user} ha creato un Periodo di Prova <b>{name}</b>.<br></br>Abilitato: {enabled}.<br></br>Durata (giorni): {lengthDays}.<br></br>Tipi di Congedo: {leaveTypes}.<br></br>Località: {locations}.<br></br>Dipartimenti: {teams}.<br></br>Etichette: {labels}.<br></br>",
  "components.logs.probationPeriodDeleted": "{user} ha eliminato un Periodo di Prova.",
  "components.logs.probationPeriodUpdated": "{user} ha aggiornato un Periodo di Prova <b>{name}</b>.<br></br>Abilitato: {enabled}.<br></br>Durata (giorni): {lengthDays}.<br></br>Tipi di Congedo: {leaveTypes}.<br></br>Località: {locations}.<br></br>Dipartimenti: {teams}.<br></br>Etichette: {labels}.<br></br>",
  "components.logs.seniorityEntitlementCreated": "{user} ha creato un Diritto di Anzianità <b>{name}</b>.<br></br>Abilitato: {enabled}.<br></br>Proporzionato: {prorated}.<br></br>Data di Inizio: {startDate}.<br></br>Tipo di Congedo: {leaveType}.<br></br>Assegnato: {entitlementPeriods}.<br></br>Località: {locations}.<br></br>Dipartimenti: {teams}.<br></br>Etichette: {labels}.<br></br>",
  "components.logs.seniorityEntitlementDeleted": "{user} ha eliminato un Diritto di Anzianità.",
  "components.logs.seniorityEntitlementUpdated": "{user} ha aggiornato il Diritto di Anzianità <b>{name}</b>.<br></br>Abilitato: {enabled}.<br></br>Proporzionato: {prorated}.<br></br>Data di Inizio: {startDate}.<br></br>Tipo di Congedo: {leaveType}.<br></br>Assegnato: {entitlementPeriods}.<br></br>Località: {locations}.<br></br>Dipartimenti: {teams}.<br></br>Etichette: {labels}.<br></br>",
  "components.logs.subscriptionCancelled": "<b>{user}</b> ha annullato l'abbonamento.<br></br>Motivo: {reason}.<br></br>Commento: {comment}",
  "components.logs.subscriptionChanged": "L'abbonamento è cambiato.<br></br>Nuovo piano: {plan}.<br></br>Periodo: {period}.<br></br>Stato: {status}.<br></br>Processore di pagamento: {paymentProcessor}",
  "components.logs.substituteApproverCreated": "{substituteApproverName} è stato impostato come Approvatore Sostitutivo per {approverFor} da {creator} a partire dal {startDate} al {endDate}",
  "components.logs.substituteApproverDeleted": "{creator} ha rimosso {substituteApproverName} come Approvatore Sostitutivo.",
  "components.logs.substituteApproverUpdated": "{creator} ha aggiornato l'approvatore sostitutivo.<br></br>Nome dell'Approvatore Sostitutivo: {substituteApproverName}<br></br>Data di inizio: {startDate}<br></br>Data di fine: {endDate}<br></br>",
  "components.logs.teamApproverStatusChanged": "{user} ha impostato gli approvatori {approvers} per il team {team}",
  "components.logs.teamCreated": "{user} ha creato un dipartimento <b>{team}</b>.<br></br>Dipartimento predefinito: {isDefault}.<br></br>Utenti: {users}.<br></br>Approvatore(i): {approvers} <br></br>Dipartimento padre: {parentDepartment}",
  "components.logs.teamDeleted": "{user} ha eliminato il team",
  "components.logs.teamUpdated": "{user} ha aggiornato un dipartimento <b>{team}</b>.<br></br>Dipartimento predefinito: {isDefault}.<br></br>Utenti: {users}.<br></br>Approvatore(i): {approvers} <br></br>Dipartimento padre: {parentDepartment}",
  "components.logs.userActivatedBy": "L'utente è stato attivato da <link>{creatorName}</link>",
  "components.logs.userApproverStatus": "L'utente è <status></status> come approvatore per il dipartimento <team>{teamName}</team> da <link>{creatorName}</link>",
  "components.logs.userChangedAvatar": "L'utente ha aggiornato la sua immagine del profilo",
  "components.logs.userDeactivatedBy": "L'utente è stato disattivato da <link>{creatorName}</link>",
  "components.logs.userDeletedBy": "L'utente è stato eliminato da <link>{creatorName}</link>",
  "components.logs.userEntitlementByRole": "All'utente sono stati concessi {totalDays} giorno(i) di {leaveTypeName} tramite il Diritto per Ruolo",
  "components.logs.userEntitlementByRoleDeleted": "I giorni di Diritto per Ruolo dell'utente per {leaveTypeName} sono stati rimossi a causa della rimozione o eliminazione dell'etichetta <b>{labelName}</b>.",
  "components.logs.userEntitlementByRoleOverride": "I giorni di diritto dell'utente per {leaveTypeName} sono stati sovrascritti a {totalDays} giorno(i) a causa di aggiustamenti del Diritto per Ruolo",
  "components.logs.userInvitationAccepted": "L'utente ha accettato l'invito di <link>{creatorName}</link>",
  "components.logs.userLeaveTypeDeleteQuota": "La quota per il tipo di congedo <b>{leaveTypeName}</b> è stata reimpostata al valore predefinito da <link>{creatorName}</link>",
  "components.logs.userLeaveTypeUnlimitedDays": "illimitato",
  "components.logs.userLeaveTypeUpdatedQuota": "<link>{creatorName}</link> ha modificato la quota per questo utente per <b>{leaveTypeName}</b> a <quota></quota> giorni",
  "components.logs.userLeaveTypeUpdatedQuotaDays": "<link>{creatorName}</link> ha modificato la quota per questo utente per <b>{leaveTypeName}</b> a <quota></quota> giorni",
  "components.logs.userLeaveTypeUpdatedQuotaHours": "<link>{creatorName}</link> ha modificato la quota per questo utente per <b>{leaveTypeName}</b> a <quota></quota> ore",
  "components.logs.userMovedToLocation": "L'utente è stato spostato nella località <locationLink>{location}</locationLink> da <link>{creatorName}</link>",
  "components.logs.userMovedToTeam": "L'utente è stato spostato nel dipartimento <teamLink>{team}</teamLink> da <link>{creatorName}</link>",
  "components.logs.userRolloverDays": "Riportato dall'anno precedente: {leaveTypeName} - {value} giorno(i)",
  "components.logs.userRolloverDisplay": "Riportato dall'anno precedente: {leaveTypeName} - {value}",
  "components.logs.userRolloverExpired": "<b>{total}</b> giorni di <b>{leaveTypeName}</b> dell'anno precedente sono scaduti",
  "components.logs.userRolloverExpiredDays": "Giorni dell'anno precedente scaduti: <b>{leaveTypeName}</b> - {value} giorno(i)",
  "components.logs.userRolloverExpiredHours": "Giorni dell'anno precedente scaduti: <b>{leaveTypeName}</b> - {value} ora(e)",
  "components.logs.userRolloverHours": "Riportato dall'anno precedente: <b>{leaveTypeName}</b> - {value} ora(e)",
  "components.logs.userSeniorityEntitlement": "All'utente sono stati concessi {totalDays} giorno/i di {leaveTypeName} tramite il Diritto di Anzianità",
  "components.logs.userSeniorityEntitlementOverride": "I giorni di diritto dell'utente per {leaveTypeName} sono stati sovrascritti a {totalDays} giorno/i a causa delle modifiche del Diritto di Anzianità",
  "components.logs.userUpdated": "Utente <name></name> <startDate></startDate> <endDate></endDate> <userStatus></userStatus> da <link>{creatorName}</link>",
  "components.logs.userUpdatedChangeStatusToAdmin": "ruolo cambiato in Amministratore",
  "components.logs.userUpdatedChangeStatusToUser": "ruolo cambiato in Utente",
  "components.logs.userUpdatedEndDate": "data di fine cambiata in {endDate}",
  "components.logs.userUpdatedName": "Nome cambiato in <wrappedName>{name}</wrappedName>",
  "components.logs.userUpdatedStartDate": "Data di inizio cambiata in {startDate}",
  "components.logs.userWorkWeek": "La settimana lavorativa dell'utente è stata cambiata in {workDays} da <link>{creatorName}</link>",
  "components.logs.userWorkWeekDefaultLocation": "La settimana lavorativa dell'utente è stata reimpostata al valore predefinito della sede da <link>{creatorName}</link>",
  "components.logs.visibilityAddonCreated": "{user} ha limitato la visibilità per l'azienda.<br></br>Abilitato: {enabled}.<br></br> Livello di visibilità: {visibilityLevel}.<br></br>",
  "components.logs.visibilityAddonUpdated": "{user} ha aggiornato la visibilità limitata per l'azienda.<br></br>Abilitato: {enabled}.<br></br> Livello di visibilità: {visibilityLevel}.<br></br>",
  "components.manageAssignLicenses.assignLicenses": "Assegna {numberOfToAssignLicenses} licenze",
  "components.manageAssignLicenses.avaliableToAssign": "Disponibili da assegnare: {avaliableLicenses}",
  "components.manageAssignLicenses.microsoftTeamsMessage": "Messaggio di Microsoft Teams",
  "components.manageAssignLicenses.moreUserThenLicenses": "Hai assegnato {users} licenze in più rispetto a quelle disponibili",
  "components.manageAssignLicenses.placeholderBulkWelcomeMessage": "Seleziona azione di massa",
  "components.manageAssignLicenses.placeholderSelectRole": "Seleziona ruolo",
  "components.manageAssignLicenses.roleTooltipInfo": "Assegna ruoli utente in Vacation Tracker. Per maggiori informazioni, visita il nostro <helpDesk>Helpdesk</helpDesk>.",
  "components.manageAssignLicenses.sendWelcomeMessage": "Invia messaggio di benvenuto",
  "components.manageAssignLicenses.totalLicenses": "Totale licenze: {numberOflicenses}",
  "components.modal.confirm": "Conferma",
  "components.modal.verify": "Verifica",
  "components.notificationDestination.label": "Invia a",
  "components.notificationDestination.tooltip": "Nota che puoi inviare la Notifica a più email, ma solo a un canale alla volta.",
  "components.notificationForm.channel": "Canale",
  "components.notificationForm.channelSlackInfo": "Le notifiche possono essere inviate solo ai canali di cui fai parte.",
  "components.notificationForm.daily": "Giornaliero",
  "components.notificationForm.day": "Giorno",
  "components.notificationForm.firstDayOfWeek": "La Settimana Inizia Il",
  "components.notificationForm.refreshTheList": "Aggiorna la lista",
  "components.notificationForm.team": "Team di Microsoft Teams",
  "components.notificationForm.time": "Ora",
  "components.notificationForm.timezone": "Fuso orario",
  "components.notificationForm.weekly": "Settimanale",
  "components.notificationSummaryInfo.notificationSummary": "La Notifica verrà inviata <to>a</to> <channelWrapper>{channelName} </channelWrapper> <and>e</and> <addressesWrapper>{numberOfEmailAddress} indirizzo email<multiEmailAddress>i</multiEmailAddress></addressesWrapper> il {sendingDaysInfo} alle ore {time}. Includerà informazioni sulle assenze di <danger>{noOfUsers} Utente<multiUsers>i</multiUsers></danger> <link>(Visualizza Utente<multiUsers>i</multiUsers>)</link>",
  "components.onboarding.assignLicensesTitle": "<icon></icon> Assegna Licenze",
  "components.onboarding.installMSBotButton": "Installa il bot",
  "components.onboarding.installMSBotDescription": "Installa il Bot e le Schede di Vacation Tracker per gestire facilmente le assenze del tuo team in Microsoft Teams.",
  "components.onboarding.installMSBotTitle": "<icon></icon> Completa la Configurazione del Bot",
  "components.onboarding.inviteFriendsDescription": "Coinvolgi il tuo team per una gestione precisa e tempestiva delle assenze. (Aggiungi almeno 3 membri del team per completare questo passaggio.)",
  "components.onboarding.inviteFriendsTitle": "<icon></icon> Invita i Membri del Tuo Team",
  "components.onboarding.joyride.admin.assignLeavePolicy.info1": "Come abbiamo menzionato prima, i Tipi di Assenza sono creati per l'intera Organizzazione. Le Politiche di Assenza sono legate alle Sedi. Ogni Tipo di Assenza può essere impostato per ogni Sede assegnandogli una Politica di Assenza personalizzata.",
  "components.onboarding.joyride.admin.assignLeavePolicy.info2": "Una volta selezionato il Tipo di Assenza che vuoi aggiungere alla Sede, puoi assegnare la Politica di Assenza. Hai l'opzione di copiare le Impostazioni da una Politica di Assenza esistente, o creare una Politica di Assenza completamente nuova con nuovi valori.",
  "components.onboarding.joyride.admin.assignLeavePolicy.title": "Assegna Politiche di Assenza",
  "components.onboarding.joyride.admin.createLocation.info1": "Se hai membri del team in sedi diverse, hai l'opzione di creare più Sedi in Vacation Tracker e aggiungere Utenti alla Sede corrispondente.",
  "components.onboarding.joyride.admin.createLocation.info2": "Ogni Sede ha le proprie Politiche di Assenza che devi assegnare a un determinato Tipo di Assenza. Anche le festività sono legate alle Sedi.",
  "components.onboarding.joyride.admin.createLocation.title": "Crea una Sede",
  "components.onboarding.joyride.admin.createNotifications.info1": "Ora che hai completato i passaggi menzionati sopra, è il momento di creare le Notifiche.",
  "components.onboarding.joyride.admin.createNotifications.title": "Crea e Modifica Notifiche",
  "components.onboarding.joyride.admin.createTeams.info1": "Una volta completato il Dashboard che ti dà una panoramica delle assenze di oggi e di quelle programmate all'interno della tua organizzazione menzionato sopra, è il momento di creare i Dipartimenti.",
  "components.onboarding.joyride.admin.createTeams.info2": "Quando crei un Dipartimento, dovrai aggiungere gli Approvatori. Gli Approvatori hanno il secondo livello più alto di permessi in Vacation Tracker, e possono approvare/rifiutare le richieste di assenza inviate dagli Utenti del Dipartimento in cui sono Approvatori.",
  "components.onboarding.joyride.admin.createTeams.title": "Crea Dipartimenti e Assegna Approvatori",
  "components.onboarding.joyride.admin.helpDesk.info": "Se hai domande o hai bisogno di maggiori informazioni, consulta il nostro Help Desk o avvia una chat con il nostro team di supporto clienti.",
  "components.onboarding.joyride.admin.helpDesk.title": "Help Desk",
  "components.onboarding.joyride.admin.importHolidays.info1": "Le festività in Vacation Tracker sono impostate per ogni Sede, e non per l'intera Organizzazione.",
  "components.onboarding.joyride.admin.importHolidays.info2": "Hai l'opzione di Importare le Festività dalla nostra lista di Festività preimpostate per ogni paese, e l'opzione di importare una Festività manualmente.",
  "components.onboarding.joyride.admin.importHolidays.title": "Importa Festività",
  "components.onboarding.joyride.admin.leaveTypesMenu.info1": "Ti consigliamo sempre di iniziare il tuo percorso con Vacation Tracker creando i Tipi di Assenza. Assegnerai questi Tipi di Assenza più tardi quando creerai tutte le sedi dove la tua azienda ha dipendenti.",
  "components.onboarding.joyride.admin.leaveTypesMenu.info2": "Visita <link>Helpdesk</link> per una guida passo passo su come creare e modificare i Tipi di Assenza.",
  "components.onboarding.joyride.admin.leaveTypesMenu.title": "Crea Tipi di Assenza",
  "components.onboarding.joyride.admin.newLeaveType.info1": "Oltre al Tipo di Assenza Predefinito (PTO), puoi creare fino a 24 altri Tipi di Assenza.",
  "components.onboarding.joyride.admin.start.title": "Scopri Vacation Tracker! 🏖️",
  "components.onboarding.joyride.admin.start1": "Per aiutarti a iniziare, abbiamo messo insieme alcuni brevi passaggi che ti consigliamo di seguire per configurare rapidamente la tua organizzazione.",
  "components.onboarding.joyride.admin.start2": "Se hai bisogno del nostro aiuto lungo il percorso, siamo a solo una email di distanza a <link>hello@vacationtracker.io</link>",
  "components.onboarding.joyride.admin.teams.cta1": "Installa il bot di Microsoft Teams",
  "components.onboarding.joyride.admin.teams.cta2": "Scopri di più nel nostro Helpdesk",
  "components.onboarding.joyride.admin.teams.info1": "Hai completato tutto sulla dashboard. L'ultimo passo è installare il bot di Microsoft Teams così il tuo team può fare richieste direttamente in Microsoft Teams.",
  "components.onboarding.joyride.admin.teams.title": "Microsoft Teams",
  "components.onboarding.joyride.approver.dashboard.info1": "Questa pagina è il tuo centro di controllo. Ti offre una panoramica delle assenze in sospeso, chi è assente oggi e le prossime assenze per la tua organizzazione.",
  "components.onboarding.joyride.approver.leaveRequests.info1": "Come Approvatore, puoi \"Aggiungere Assenza\" per qualsiasi membro del team di cui sei Approvatore. Puoi anche \"Richiedere Assenza\" per te stesso che sarà inviata per l'approvazione al tuo Approvatore.",
  "components.onboarding.joyride.approver.notifications.info1": "Questa pagina offre una panoramica di tutte le Notifiche inviate in tutta la tua organizzazione.",
  "components.onboarding.joyride.approver.notifications.info2": "Puoi creare o modificare Notifiche per gli Utenti dei Dipartimenti di cui sei Approvatore.",
  "components.onboarding.joyride.approver.notifications.info3": "Nota che puoi gestire solo le Notifiche create da te.",
  "components.onboarding.joyride.approver.users.info1": "La pagina 'Utenti' ti offre una panoramica completa di tutti gli utenti attivi nel tuo Dipartimento insieme all'accesso in sola lettura ai loro Profili Utente. Le loro richieste in sospeso sono disponibili nella pagina 'Dashboard'.",
  "components.onboarding.joyride.user.calendar.info1": "In questa pagina, puoi vedere la cronologia e le prossime assenze per la tua organizzazione in formato calendario.",
  "components.onboarding.joyride.user.dashboard.info1": "La Dashboard ti offre una panoramica delle assenze di oggi e di quelle programmate all'interno della tua organizzazione.",
  "components.onboarding.joyride.user.holidays.info1": "Qui puoi rivedere le Festività nella tua Sede, così ti assicuri di sapere quali giorni hai già liberi.",
  "components.onboarding.joyride.user.myProfile.info1": "Qui puoi vedere i tuoi tipi di assenza disponibili, le quote, i giorni lavorativi e la cronologia delle tue assenze.",
  "components.onboarding.joyride.user.requestLeave.info1": "Per richiedere un'Assenza attraverso la Dashboard di Vacation Tracker, seleziona uno dei tipi di assenza disponibili, aggiungi il periodo di tempo e il motivo se necessario, e invia la richiesta. Una volta inviata, sarà mandata all'Approvatore per l'approvazione.",
  "components.onboarding.joyride.user.start1": "Per aiutarti a iniziare, abbiamo messo insieme una lista di cinque passaggi che ti consigliamo di seguire per configurare rapidamente la tua organizzazione.",
  "components.onboarding.openNotificationsButton": "Apri notifiche",
  "components.onboarding.requestALeaveDescription": "Invia la tua prima richiesta di congedo in 3 semplici passaggi.",
  "components.onboarding.requestALeaveTitle": "<icon></icon> Richiedi un Congedo",
  "components.onboarding.setNotificationsDescription": "Scegli quanto spesso desideri essere informato sui congedi del tuo team per rimanere aggiornato.",
  "components.onboarding.setNotificationsTitle": "<icon></icon> Configura Notifiche",
  "components.onboarding.skipOnboarding": "Salta l'onboarding",
  "components.onboarding.syncTheCalendarDescription": "Integra il calendario di Vacation Tracker con il tuo calendario in modo che i congedi del tuo team siano sempre aggiornati.",
  "components.onboarding.syncTheCalendarTitle": "<icon></icon> Sincronizza Calendari",
  "components.onboarding.syncTheCalendarVideoForSyncingWithGoogleCalendar": "Video per la sincronizzazione con Google Calendar",
  "components.onboarding.syncTheCalendarVideoForSyncingWithIcal": "Video per la sincronizzazione con iCal",
  "components.onboarding.syncTheCalendarVideoForSyncingWithMicrosoftOutlookCalendar": "Video per la sincronizzazione con MS Windows",
  "components.onboarding.title": "Lista di controllo per l'onboarding <progressBar>{progressStatus}</progressBar>",
  "components.paymentInformation.expiryDate": "Data di scadenza",
  "components.paymentInformation.labelCreditCard": "Carta di credito",
  "components.paymentInformation.title": "Informazioni di pagamento ",
  "components.platformSwitchModal.content": "Devi prima accedere con il tuo account {platform} per passare alla piattaforma {platform}. Fai clic sul pulsante qui sotto per continuare. Dopo aver effettuato l'accesso e concesso le autorizzazioni necessarie, ti reindirizzeremo al processo di mappatura degli utenti.",
  "components.platformSwitchModal.title": "Migrazione alla piattaforma {platform}",
  "components.pricePlanCard.accruals": "Accumula Ferie",
  "components.pricePlanCard.APISupport": "Supporto API",
  "components.pricePlanCard.calendarIntegrations": "Integrazione Calendario",
  "components.pricePlanCard.contactUs": "Contattaci",
  "components.pricePlanCard.coreDepartmentsAndLocations": "Crea fino a 3 Sedi e 10 Dipartimenti",
  "components.pricePlanCard.currentPlan": "Piano Attuale",
  "components.pricePlanCard.customContract": "Contratto personalizzato",
  "components.pricePlanCard.customizeLeaveTypes": "Personalizza Tipi di Congedo",
  "components.pricePlanCard.customLocationsDepartmentsLeaveTypes": "Sedi, Dipartimenti e Tipi di Congedo Personalizzati Illimitati",
  "components.pricePlanCard.customOnboardingAndSetup": "Onboarding e configurazione personalizzati",
  "components.pricePlanCard.dedicatedSupport": "Supporto Dedicato",
  "components.pricePlanCard.disableDowngradeToCore": "Il passaggio al piano Core richiede {noOfActions} {noOfActions, plural, =1 {azione} other {azioni}}",
  "components.pricePlanCard.disableDowngradeToCoreDescription": "Per passare al piano Core, effettua le seguenti modifiche:",
  "components.pricePlanCard.disableDowngradeToCoreReasonDepartments": "Il piano Core consente un massimo di 10 dipartimenti. Rimuovi eventuali dipartimenti aggiuntivi.",
  "components.pricePlanCard.disableDowngradeToCoreReasonLocations": "Il piano Core consente un massimo di 3 sedi. Rimuovi eventuali sedi aggiuntive.",
  "components.pricePlanCard.disableDowngradeToCoreReasonSubdepartments": "Nel piano Core, i dipartimenti possono essere annidati solo a un livello di profondità. Attualmente hai {total} sotto-dipartimenti. Elimina alcuni sotto-dipartimenti per passare al piano Core.",
  "components.pricePlanCard.disableDowngradeToCoreReasonWorkday": "Il passaggio al piano Core non è possibile mentre si utilizzano orari di lavoro personalizzati. Ripristina gli orari di lavoro personalizzati al valore predefinito (8) nelle impostazioni delle sedi",
  "components.pricePlanCard.disableDowngradeToCoreTitle": "Downgrade del piano. Modifiche necessarie",
  "components.pricePlanCard.displayLeaveInformationInDaysHours": "Visualizza Informazioni sui Congedi in Giorni/Ore",
  "components.pricePlanCard.downgradePlan": "Il piano verrà declassato nel prossimo ciclo di fatturazione",
  "components.pricePlanCard.duringTheTrial": "Durante il periodo di prova, qualsiasi modifica al piano verrà implementata immediatamente",
  "components.pricePlanCard.everythingInTheCompletePlan": "Tutto nel piano Completo",
  "components.pricePlanCard.EverythingInTheCorePlan": "Tutto nel piano Core",
  "components.pricePlanCard.exportsAndReports": "Esportazioni e Rapporti",
  "components.pricePlanCard.forSeats": "per meno di {users} postazioni",
  "components.pricePlanCard.forUsers": "per meno di {users} utenti",
  "components.pricePlanCard.hourlyLeaves": "Traccia le Assenze in Ore",
  "components.pricePlanCard.HRFields": "Campi HR (in arrivo)",
  "components.pricePlanCard.labels": "Organizza gli Utenti per Etichette",
  "components.pricePlanCard.managePTO": "Gestisci le richieste di ferie retribuite",
  "components.pricePlanCard.minimumPlanPrice": "minimo <price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>",
  "components.pricePlanCard.notifications": "Invia Notifiche di Ferie Retribuite",
  "components.pricePlanCard.pricePerSeat": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>per postazione/mese",
  "components.pricePlanCard.pricePerUser": "<price><oldPrice>${pricePerUser}</oldPrice><newPrice>${newPricePerUser}</newPrice></price>per utente/mese",
  "components.pricePlanCard.reports": "Genera Rapporti",
  "components.pricePlanCard.reportsSchedule": "Pianifica Rapporti",
  "components.pricePlanCard.requestHourlyLeaveIncrements": "Richiedi Incrementi di Assenza Orari",
  "components.pricePlanCard.scheduleAConsultation": "Pianifica una Consulenza",
  "components.pricePlanCard.scheduledReport": "Rapporti Pianificati",
  "components.pricePlanCard.selectPlan": "Seleziona Piano",
  "components.pricePlanCard.sharedCalendars": "Calendari Condivisi",
  "components.pricePlanCard.SSOIntegration": "Integrazione SSO Enterprise (Okta, ecc.)",
  "components.pricePlanCard.startFreeTrial": "Inizia la Prova Gratuita di 14 Giorni",
  "components.pricePlanCard.substituteApprovers": "Approvatori Sostitutivi",
  "components.pricePlanCard.support": "Supporto Prioritario",
  "components.pricePlanCard.switchPlan": "Passa <period> al Piano {newPeriod}</period>",
  "components.pricePlanCard.totalPrice": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/mese per {totalUsers} utenti attivi",
  "components.pricePlanCard.totalPriceForLessThan25Users": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/mese per meno di 25 utenti",
  "components.pricePlanCard.totalPricePerSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/anno",
  "components.pricePlanCard.totalPricePerUser": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/anno",
  "components.pricePlanCard.totalPriceSeats": "<price><oldPrice>${totalPrice}</oldPrice><newPrice>${newTotalPrice}</newPrice></price>/mese per {totalUsers} postazioni",
  "components.pricePlanCard.unlimitedDepartmentsAndLocations": "Crea Dipartimenti e Sedi Illimitate",
  "components.pricePlanCard.upgradePlan": "Il piano verrà attivato immediatamente e il prezzo sarà calcolato proporzionalmente",
  "components.requestAutomation.anythingElse": "C'è altro che dovremmo sapere?",
  "components.requestAutomation.automationExtraCharge": "Useresti questo Add-On se comportasse un costo aggiuntivo?",
  "components.requestAutomation.automationFunctionalities": "Quali funzionalità o compiti ti permetterà di eseguire l'Add-On?",
  "components.requestAutomation.automationShouldWeAdd": "Quale Add-On dovremmo aggiungere?",
  "components.requestAutomation.submiteRequest": "Invia Richiesta",
  "components.requestAutomation.suggestAnAutomation": "Suggerisci un Add-On",
  "components.resetQuotas.fiscalYear": "Anno Fiscale ({date})",
  "components.resetQuotas.userYear": "Data di Assunzione dell'Utente",
  "components.selectChannelForm.addNewEmail": "Aggiungi Destinatario",
  "components.selectChannelForm.channel": "Canale",
  "components.selectChannelForm.channelSlackInfo": "Le notifiche possono essere inviate solo ai canali di cui fai parte.",
  "components.selectChannelForm.email": "Destinatari",
  "components.selectChannelForm.errorNotification": "Errore di Notifica",
  "components.selectChannelForm.errorNotInTeams": "Accesso negato. Per utilizzare questo canale, devi essere membro di questo reparto.",
  "components.selectChannelForm.notFound": "La tua organizzazione si è registrata con un singolo team di Microsoft Teams, e tu non sei membro del team con il seguente ID: {msTeamId}.\n\nPuoi unirti a questo team nella tua applicazione Microsoft Teams e riprovare, oppure contattare il nostro supporto per modificare il tipo di installazione.",
  "components.selectChannelForm.notFoundTitle": "Non sei un membro del team Microsoft Teams selezionato",
  "components.selectChannelForm.team": "Team",
  "components.selectChannelFormMicrosoft.channelPlaceholder": "Seleziona un Canale",
  "components.selectChannelFormMicrosoft.errorNotInTeams": "Accesso negato. Per utilizzare questo canale, devi essere membro di questo reparto.",
  "components.selectChannelFormMicrosoft.notFound": "La tua organizzazione si è registrata con un singolo team di Microsoft Teams, e tu non sei membro del team con il seguente ID: {msTeamId}.\n\nPuoi unirti a questo team nella tua applicazione Microsoft Teams e riprovare, oppure contattare il nostro supporto per modificare il tipo di installazione.",
  "components.selectChannelFormMicrosoft.notFoundTitle": "Non sei un membro del team Microsoft Teams selezionato",
  "components.selectChannelFormMicrosoft.teamPlaceholder": "Seleziona un Team",
  "components.selectChannelFormSlack.errorNotification": "Errore di Notifica",
  "components.selectChannelFormSlack.missingSlackScopes": "Ambiti Slack mancanti",
  "components.selectChannelFormSlack.privateChannel": "(canale privato)",
  "components.selectChannelFormSlack.slackBotIdIsRequred": "Errore Slack: l'ID del Bot Slack è richiesto! Prova a ricaricare la pagina!",
  "components.selectChannelFormSlack.slackError": "Errore Slack",
  "components.selectChannelFormSlack.toProccedPleaseLogin": "Per procedere, accedi al tuo account Slack",
  "components.selectChannelFormSlack.youCanAddTheBotOnlyOnTheChannels": "Non sei membro di {channelName}. Per inviare la notifica a questo canale, devi esserne membro.",
  "components.seniorityEntitlement.addDaysFrom": "Aggiungi giorni da",
  "components.seniorityEntitlement.allFiledsAreRequired": "Tutti i campi sono obbligatori",
  "components.seniorityEntitlement.confirmDescription": "Scegliere di iniziare a calcolare il diritto di anzianità dalla data di inizio del dipendente adatterà retroattivamente le quote utente a partire dall'anno di inizio del calcolo.",
  "components.seniorityEntitlement.confirmTitle": "Conferma creazione automazione",
  "components.seniorityEntitlement.daysAfter": "giorno/i dopo",
  "components.seniorityEntitlement.disableEditInfo": "Non puoi modificare l'automazione del diritto di anzianità. Se hai bisogno di apportare modifiche, elimina questa automazione e creane una nuova. <link>Contattaci</link> se hai domande o hai bisogno di aiuto.",
  "components.seniorityEntitlement.hoursAfter": "ora/e dopo",
  "components.seniorityEntitlement.noLocations": "Hai un'automazione che è stata applicata a tutti gli utenti, non puoi avere più automazioni per tutti gli utenti.",
  "components.seniorityEntitlement.notSortedYearInPeriodError": "Gli anni devono essere ordinati cronologicamente.",
  "components.seniorityEntitlement.prorateForTheFirstYear": "Vuoi abilitare il pro-rata?",
  "components.seniorityEntitlement.prorateForTheFirstYearTooltip": "Se la data di inizio del dipendente non corrisponde alla data di inizio dell'anno fiscale, calcoleremo il pro-rata dei giorni basati sull'anzianità nell'anno in cui vengono assegnati. Per esempio, se il tuo anno fiscale inizia il 1° gennaio, l'utente ha iniziato a lavorare il 1° luglio 5 anni fa, e la tua azienda concede 5 giorni aggiuntivi di ferie il 5 luglio dopo 5 anni di servizio, l'utente riceverà 2,5 giorni il 1° luglio a causa del pro-rata. Questo utente riceverà tutti i 5 giorni basati sull'anzianità nel prossimo anno fiscale.",
  "components.seniorityEntitlement.sameYearError": "Non puoi assegnare più regole allo stesso numero di anni.",
  "components.seniorityEntitlement.startApplayingSeniorityEnintlementFrom": "Inizia ad applicare il diritto di anzianità da",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFrom": "Inizia ad applicare il diritto di anzianità da",
  "components.seniorityEntitlement.startApplyingSeniorityEntitlementFromInfo": "Se scegli di iniziare ad applicare il diritto di anzianità dalla data di inizio del dipendente, questa modifica influenzerà le quote dell'utente nel passato.",
  "components.seniorityEntitlement.summaryInfoPrefix": "L'Automazione dell'Anzianità assegna ferie aggiuntive con un approccio basato su traguardi. Per esempio: ",
  "components.seniorityEntitlement.summaryInfoSuffix": ", per un totale di {days} <showDays>giorni</showDays><showHour>ora/e</showHour> di ferie per anzianità.",
  "components.seniorityEntitlement.summeryInfoFirstRow": "{days} <showDays>giorni</showDays><showHour>ora/e</showHour> vengono aggiunti al traguardo dei {year} anni",
  "components.seniorityEntitlement.summeryInfoMoreDays": ", altri {days} <showDays>giorni</showDays><showHour>ora/e</showHour> al traguardo dei {year} anni",
  "components.seniorityEntitlement.yearsInTheCOmpany": "anni in azienda",
  "components.setupMsBot.followDirectLink": "1) Fai clic su <directLink>questo link</directLink> per trovare l'applicazione Vacation Tracker nel negozio di Microsoft Teams.",
  "components.setupMsBot.inMSAppPreviewWindows": "2) Nella finestra di anteprima dell'app, fai clic sulla freccia accanto al pulsante \"Apri\" e clicca su \"Aggiungi a un team\"",
  "components.setupMsBot.setUpInMS": "3) Ti verrà chiesto di selezionare il team e il canale in cui desideri installare Vacation Tracker. Avrai anche l'opzione di fissare Vacation Tracker come scheda per un accesso più facile e veloce!",
  "components.setupMsBot.stepByStep": "Per istruzioni dettagliate passo dopo passo, guarda questo video o visita il nostro <helpDesk>Helpdesk</helpDesk>.",
  "components.setupMsBot.title": "Configura il bot e le schede di Microsoft Teams",
  "components.signupWithAnotherPlatform.connectWith": " Iscriviti con {platform}",
  "components.signupWithAnotherPlatform.info": "Vuoi connetterti con il tuo account {platform}? Facendolo, sarai in grado di importare e sincronizzare gli utenti dal tuo {importUsersPlatform} e gestire le assenze direttamente dalla tua applicazione {platform}. Per maggiori informazioni, consulta il nostro <helpDesk>Helpdesk</helpDesk>.",
  "components.signupWithAnotherPlatform.or": "Oppure",
  "components.signupWithAnotherPlatform.title": "Sembra che tu stia utilizzando {platform}",
  "components.switchPlanModal.downgradeProbationPeriodWarning": "L'Add-On Periodo di Prova è una funzionalità a pagamento per il piano Core. Se effettui il downgrade e non ti disiscriviti da questo Add-On, verrà addebitato a $0.50 per utente al mese.",
  "components.switchPlanModal.switch": "Cambia",
  "components.switchPlanModal.switchCoreMonthlyToCompleteMonthly": "Il piano mensile <strong>Complete</strong> sarà attivato immediatamente e il prezzo sarà proporzionato per il ciclo di fatturazione corrente.<legacyPlan> Si prega di notare che non sarà possibile tornare al piano legacy.</legacyPlan>",
  "components.switchPlanModal.switchFromAnnuallyToMonthly": "Il piano mensile <strong>{newPlan}</strong> sarà attivato e fatturato dopo la fine del ciclo di fatturazione annuale corrente ({endDatePeriod}).<legacyPlan> Si prega di notare che non sarà possibile tornare al piano legacy.</legacyPlan>",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreAnnual": "Il piano annuale <strong>Core</strong> sarà attivato dopo la fine del ciclo di fatturazione annuale corrente ({endDatePeriod}), fino ad allora puoi continuare a utilizzare le funzionalità del piano <strong>Complete</strong>",
  "components.switchPlanModal.switchFromCompleteAnnualToCoreMonthly": "Il piano <strong>Core</strong> sarà attivato dopo la fine del ciclo di fatturazione annuale corrente ({endDatePeriod}), fino ad allora puoi continuare a utilizzare le funzionalità del piano <strong>Complete</strong>.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreAnnual": "Il piano annuale <strong>Core</strong> sarà attivato dopo la fine del ciclo di fatturazione mensile corrente ({endDatePeriod}), fino ad allora puoi continuare a utilizzare le funzionalità del piano <strong>Complete</strong>.",
  "components.switchPlanModal.switchFromCompleteMonthlyToCoreMonthly": "Il piano mensile <strong>Core</strong> sarà attivato dopo la fine del ciclo di fatturazione corrente ({endDatePeriod}), fino ad allora puoi continuare a utilizzare le funzionalità del piano <strong>Complete</strong>.",
  "components.switchPlanModal.switchFromCoreAnnualToCompleteAnnual": "Il piano annuale <strong>Complete</strong> sarà attivato immediatamente e ti verrà addebitata la differenza proporzionale di prezzo fino alla fine del ciclo di fatturazione annuale corrente.<legacyPlan> Si prega di notare che non sarà possibile tornare al piano legacy.</legacyPlan>",
  "components.switchPlanModal.switchFromCoreMonthlyToCompleteAnnual": "Il piano annuale <strong>Complete</strong> sarà attivato immediatamente. Il tuo prezzo annuale sarà ridotto dell'importo rimanente sulla tua fattura mensile corrente.<legacyPlan> Si prega di notare che non sarà possibile tornare al piano legacy.</legacyPlan>",
  "components.switchPlanModal.switchFromMonthlyToAnnually": "Il piano annuale <strong>{newPlan}</strong> sarà attivato immediatamente. Il tuo prezzo annuale sarà ridotto dell'importo rimanente sulla tua fattura mensile corrente.<legacyPlan> Si prega di notare che non sarà possibile tornare al piano legacy.</legacyPlan>",
  "components.switchPlanModal.title": "Passa al piano <strong>{plan}</strong>",
  "components.switchPlanModal.unsubscribeProbationPeriodAddons": "Annulla l'iscrizione al periodo di prova",
  "components.teamForm.approvers": "Approvatore/i",
  "components.teamForm.generalSettings": "Impostazioni Generali",
  "components.teamForm.isDefault": "Rendi Questo Dipartimento Predefinito",
  "components.teamForm.isDefaultExtra": "Rendendo questo Dipartimento predefinito, tutti i nuovi membri del team saranno automaticamente aggiunti a questo Dipartimento",
  "components.teamForm.name": "Nome",
  "components.teamForm.pleaseEnterTeamName": "Inserisci il nome del dipartimento",
  "components.teamForm.users": "Utenti",
  "components.toil.accrualsToilsTotal": "Totale {hours, plural, =1 {ore} other {giorni}} guadagnati (TOIL + maturazione): {total}",
  "components.toil.added": "TOIL Aggiunto",
  "components.toil.addToil": "Aggiungi TOIL",
  "components.toil.addToilProgress": "Aggiunta TOIL in corso",
  "components.toil.anotherAdd": "Aggiungi un altro TOIL",
  "components.toil.anotherRequest": "Richiedi un altro TOIL",
  "components.toil.approveInProgress": "Approvazione richiesta TOIL in corso",
  "components.toil.bot.message.editedToilRequest": "Ciao, <@{userSlackId}> ha modificato la seguente richiesta TOIL:",
  "components.toil.bot.message.leaveTypeName": "*Tipo di assenza:*\nTOIL - {leaveTypeName}",
  "components.toil.bot.message.requestToil": "Richiedi TOIL",
  "components.toil.bot.message.requestToilSummary": "Avvia la tua richiesta di Time Off in Lieu specificando le date in cui hai svolto il lavoro straordinario e includi eventuali osservazioni pertinenti. Nota che le richieste di Time Off in Lieu possono essere fatte solo per date passate, incluso oggi.",
  "components.toil.bot.message.submittedToilRequest": "Ciao, <@{userSlackId}> ha inviato la seguente richiesta TOIL:",
  "components.toil.bot.notification.slack.toilRequestAdded": "Un congedo TOIL è stato creato per te da un approvatore",
  "components.toil.bot.notification.slack.toilRequestAddedSelf": "Hai aggiunto un congedo TOIL",
  "components.toil.bot.notification.slack.toilRequestApproved": "✅ <@{approverName}> ha approvato la richiesta, non sono necessarie ulteriori azioni.",
  "components.toil.bot.notification.slack.toilRequestApprovedTitle": "La tua richiesta di congedo è stata approvata",
  "components.toil.bot.notification.slack.toilRequestCreated": "Ciao, <@{userSlackId}> ha inviato una richiesta TOIL.",
  "components.toil.bot.notification.slack.toilRequestEdited": "Ciao, <@{userSlackId}> ha modificato una richiesta TOIL.",
  "components.toil.cancelInProgress": "Annullamento della richiesta TOIL in corso",
  "components.toil.deleteInProgress": "Eliminazione TOIL in corso",
  "components.toil.denied": "La tua richiesta TOIL è stata rifiutata",
  "components.toil.deniedBy": "La richiesta TOIL è stata rifiutata da {approverName}.",
  "components.toil.denyInProgress": "Rifiuto della richiesta TOIL in corso",
  "components.toil.durationLabel": "Durata minima del tempo libero in compensazione",
  "components.toil.durationLabelTooltip": "Seleziona la durata più breve applicabile alla richiesta TOIL. Questa selezione dovrebbe riflettere la quantità minima di straordinario che un utente può registrare.",
  "components.toil.earned": "TOIL guadagnato",
  "components.toil.edit": "Aggiorna TOIL",
  "components.toil.edited": "TOIL aggiornato",
  "components.toil.editRequestToil": "Modifica richiesta di tempo libero in compensazione",
  "components.toil.editToilProgress": "Modifica del tempo libero in compensazione in corso",
  "components.toil.enableLabel": "Abilita tempo libero in compensazione:",
  "components.toil.enableLabelTooltip": "TOIL è una pratica in cui i dipendenti vengono compensati per il lavoro straordinario ricevendo un equivalente tempo libero dal lavoro, invece di essere pagati extra per le ore aggiuntive lavorate.",
  "components.toil.expiration": "Scadenza",
  "components.toil.expirationDuration": "{noOfMonths} {noOfMonths, plural, =1 {mese} other {mesi}}",
  "components.toil.expirationDurationCalendar": "Fine dell'anno solare",
  "components.toil.expirationLabel": "I giorni di tempo libero in compensazione dovrebbero scadere?",
  "components.toil.expirationLabelTooltip": "Abilita questa impostazione se desideri che i giorni TOIL abbiano una data di scadenza, dopo la quale non possono più essere utilizzati. Selezionando 'Sì' ti verrà chiesto di scegliere le regole di scadenza. Se viene selezionato 'No', i giorni TOIL non scadranno e rimarranno disponibili fino all'utilizzo.",
  "components.toil.featurePlan": "La funzione TOIL è disponibile solo per il piano Completo.",
  "components.toil.fullDayLeavePeriod": "{date}{endDate} ",
  "components.toil.halfDay": "TOIL mezza giornata",
  "components.toil.hourly": "TOIL orario",
  "components.toil.lapsed": "scaduto",
  "components.toil.locationNotHaveToil": "Nessuna politica di congedo con TOIL è impostata per {me, plural, =1 {la tua} other {la}} sede dell'utente",
  "components.toil.notification.added": "TOIL <forUser>per </forUser>{requestor} aggiunto <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.toil.notification.approved": "TOIL di {requestor} approvato <byActionCreator>da {actionCreator}</byActionCreator> ✅",
  "components.toil.notification.cancelled": "TOIL di {requestor} annullato <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.toil.notification.created": "TOIL richiesto <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.toil.notification.deleted": "TOIL eliminato <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.toil.notification.denied": "TOIL di {requestor} rifiutato <byActionCreator>da {actionCreator}</byActionCreator> ❌",
  "components.toil.notification.edited": "TOIL di {requestor} modificato <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.toil.notification.editedApproved": "Un TOIL programmato è stato modificato <byActionCreator>da {actionCreator}</byActionCreator> ✅",
  "components.toil.notification.editedDenied": "TOIL modificato negato <byActionCreator>da {actionCreator}</byActionCreator>",
  "components.toil.partDayPeriod": "{date} - dalle {partDayStartHour}{hourFormat24, select, true {:00} other {}} alle {partDayEndHour}{hourFormat24, select, true {:00} other {}} ",
  "components.toil.reasonDefault": "Ore aggiuntive lavorate durante il periodo specificato sopra.",
  "components.toil.request": "Richiesta TOIL",
  "components.toil.requested": "TOIL richiesto",
  "components.toil.requestedOrAddedDaysHours": "{action, plural, =1 {Richiesto} other {Aggiunto}} {period}",
  "components.toil.requester": "Chi può richiedere il tempo libero compensativo?",
  "components.toil.requesterAdmin": "Solo amministratori e approvatori",
  "components.toil.requesterAll": "Tutti gli utenti",
  "components.toil.requesterTooltip": "Scegli 'Tutti gli utenti' per consentire a ogni dipendente di presentare le proprie richieste TOIL dopo aver lavorato straordinari. Opta per 'Solo amministratori e approvatori' se preferisci centralizzare la gestione del TOIL e mantenere un controllo più stretto sulla sua approvazione e registrazione.",
  "components.toil.requestNotFound": "Richiesta TOIL non trovata",
  "components.toil.requestToil": "Richiedi tempo libero compensativo",
  "components.toil.requestToilProgress": "Richiesta di tempo libero compensativo in corso",
  "components.toil.submitError": "Errore di invio TOIL",
  "components.toil.submitErrorDate": "La richiesta TOIL non può essere inviata perché la data di inizio selezionata è successiva alla data di fine. Assicurati che la data di inizio sia precedente alla data di fine e invia nuovamente la richiesta",
  "components.toil.submitErrorDateFuture": "La data TOIL richiesta deve essere nel passato",
  "components.toil.submitErrorLongerThanWorkday": "Le ore TOIL richieste sono superiori alle ore lavorative",
  "components.toil.submitErrorNotOpen": "Il TOIL non è aperto",
  "components.toil.submitErrorOverlap": "I giorni TOIL richiesti si sovrappongono con TOIL esistenti",
  "components.toil.submitErrorPlan": "Questa funzione è accessibile esclusivamente agli utenti abbonati al nostro piano Completo.",
  "components.toil.submitErrorPolicy": "La politica di congedo per questo tipo di congedo non consente il TOIL",
  "components.toil.submitErrorPolicyDuration": "Gli utenti con orari di lavoro personalizzati non possono richiedere TOIL di più giorni",
  "components.toil.submitErrorPolicyLocation": "L'opzione TOIL non è abilitata per la tua posizione. Puoi abilitarla nella pagina delle politiche di congedo o contattare il tuo amministratore per supporto.",
  "components.toil.submitErrorReasonNotSet": "È richiesto il motivo del TOIL",
  "components.toil.submitErrorRole": "Richiesta TOIL non consentita",
  "components.updateWorkweekForm.cancel": "Annulla",
  "components.updateWorkweekForm.form.error": "Seleziona almeno un giorno lavorativo",
  "components.updateWorkweekForm.title": "Aggiorna settimana lavorativa",
  "components.updateWorkweekForm.update": "Aggiorna",
  "components.updateWorkweekForm.workWeek": "Settimana lavorativa",
  "components.userBroughtForwardForm.customRolloverDays": "Giorni",
  "components.userBroughtForwardForm.leaveTypeEditInfo": "Stai modificando il tipo di congedo \"{leaveTypeName}\" per il periodo {dateRangeName}.",
  "components.userBroughtForwardForm.update": "Aggiorna",
  "components.userBroughtForwardForm.updateUserLeaveType": "Aggiorna i giorni riportati per il tipo di congedo {name}",
  "components.userLabes.addLabel": "Aggiungi etichetta",
  "components.userLabes.manageLabels": "Gestisci etichette",
  "components.userLabes.newLabel": "(nuova etichetta)",
  "components.userLabes.noLabelsFound": "Nessuna etichetta trovata",
  "components.userLeaveQuotas.accrualsTooltipTitle": "L'accumulo è l'aumento graduale delle tue ore di ferie retribuite durante l'anno.",
  "components.userLeaveQuotas.accrualsTooltipTitle-bi-weekly": "Un accumulo {leaveType} bisettimanale avviene ogni due settimane",
  "components.userLeaveQuotas.accrualsTooltipTitle-monthly": "Un accumulo mensile di {leaveType} avviene una volta al mese.",
  "components.userLeaveQuotas.accrualsTooltipTitle-semi-monthly": "Un accumulo semi-mensile di {leaveType} avviene due volte al mese.",
  "components.userLeaveQuotas.accrualsTooltipTitle-weekly": "Un accumulo settimanale di {leaveType} avviene ogni settimana",
  "components.userLeaveQuotas.accruedDays": "Giorni Accumulati",
  "components.userLeaveQuotas.accruedHours": "Ore Accumulate",
  "components.userLeaveQuotas.accruedInfo1": "{userName} ha accumulato {earnedDays} giorni ({earnedDaysInHours} ore) su {defaultDays} giorni ({defaultDaysInHours} ore)",
  "components.userLeaveQuotas.accruedInfo1Days": "{userName} ha accumulato {earnedDays} su {defaultDays} giorni",
  "components.userLeaveQuotas.accruedInfo1Hours": "{userName} ha accumulato {earnedDaysInHours} su {defaultDaysInHours} ore",
  "components.userLeaveQuotas.accruedInfo2": "{userName} accumulerà {toAccrueDays} giorni ({toAccrueHours} ore) il {earnDate}",
  "components.userLeaveQuotas.accruedInfo2Days": "{userName} accumulerà {toAccrueDays} giorni il {earnDate}",
  "components.userLeaveQuotas.accruedInfo2Hours": "{userName} accumulerà {toAccrueHours} ore il {earnDate}",
  "components.userLeaveQuotas.baseQuota": "Quota base",
  "components.userLeaveQuotas.broughtForward": "Riportato",
  "components.userLeaveQuotas.broughtForwardDaysInfo": "<rolloverDays>Massimo {maxRolloverDays} giorni<br></br></rolloverDays><expiresDate>Scade il {date}</expiresDate>",
  "components.userLeaveQuotas.broughtForwardInfo": "Riportato rappresenta il numero di giorni non utilizzati trasferiti dal periodo precedente.",
  "components.userLeaveQuotas.broughtForwardInfoDetails": "Per {leaveTypeName} nella sede {locationName} i dipendenti possono riportare fino a {broughtForwardLimit} {broughtForwardLimit, plural, =1 {giorno non utilizzato} other {giorni non utilizzati}}.",
  "components.userLeaveQuotas.broughtForwardInfoDetailsAll": "Per {leaveTypeName} nella sede {locationName} tutti i giorni non utilizzati possono essere riportati al periodo successivo.",
  "components.userLeaveQuotas.broughtForwardValue": "Riportato",
  "components.userLeaveQuotas.editInitialQuotaTitle": "Modifica i giorni iniziali riportati per {leaveTypeName}",
  "components.userLeaveQuotas.editInitialQuotaTooltip": "Il Riporto Iniziale ti permette di inserire manualmente i saldi iniziali dei dipendenti da un sistema di tracciamento o HR precedente",
  "components.userLeaveQuotas.editQuotaButton": "Modifica",
  "components.userLeaveQuotas.expirationTooltip": "Giorni che il dipendente ha riportato dal periodo precedente ma che non sono stati utilizzati prima della data di scadenza",
  "components.userLeaveQuotas.expiredDateInfo": "il {date}",
  "components.userLeaveQuotas.forMoreDetails": "Per maggiori dettagli, vedi",
  "components.userLeaveQuotas.initialBroughtForward": "Riporto Iniziale",
  "components.userLeaveQuotas.initialBroughtForwardInfo": "Il Riporto Iniziale è il saldo delle ferie che un Utente ha riportato (trasferito) dall'anno scorso, quando Vacation Tracker non faceva parte della vostra organizzazione. Nota che puoi modificare solo il saldo del Riporto Iniziale, mentre le ferie disponibili che puoi riportare negli anni successivi saranno calcolate da Vacation Tracker.",
  "components.userLeaveQuotas.leavePoliciesNotExistInfo": "Al momento non ci sono Tipi di Ferie disponibili. Si prega di assegnare le Politiche di Ferie alla Sede a cui appartiene questo utente.",
  "components.userLeaveQuotas.leaveTypes": "Tipi di Ferie",
  "components.userLeaveQuotas.logsTab": "scheda dei log.",
  "components.userLeaveQuotas.noLimit": "Hai una quota illimitata.",
  "components.userLeaveQuotas.ofDefaultDays": "di {value} giorni",
  "components.userLeaveQuotas.ofDefaultHours": "di {value} ore",
  "components.userLeaveQuotas.overlapDaysContactSupport": "Sovrapposizione di date, si prega di contattare il Supporto per maggiori informazioni.",
  "components.userLeaveQuotas.paidDays": "Giorni pagati",
  "components.userLeaveQuotas.quota": "Quota",
  "components.userLeaveQuotas.quotaInfo": "La Quota rappresenta il numero di giorni di Ferie Retribuite disponibili per il periodo corrente. Questo numero include la quota base, il tempo libero compensativo e i giorni assegnati in base al ruolo o all'anzianità del dipendente.",
  "components.userLeaveQuotas.quotaInfoSummary": "Quota per il periodo corrente:",
  "components.userLeaveQuotas.remaining": "Rimanente",
  "components.userLeaveQuotas.remainingAfterRequest": "Rimanente dopo la richiesta",
  "components.userLeaveQuotas.remainingTooltip": "Giorni {leaveTypeName} rimanenti per il periodo corrente. Totale:",
  "components.userLeaveQuotas.remainingTooltipFooter": "Fino a {broughtForwardLimit} {period} non utilizzati saranno riportati al periodo successivo",
  "components.userLeaveQuotas.remainingTooltipFooterAll": "Tutti i giorni non utilizzati possono essere riportati al periodo successivo.",
  "components.userLeaveQuotas.remainingTooltipFooterZero": "Nessun giorno non utilizzato può essere riportato al periodo successivo.",
  "components.userLeaveQuotas.requested": "Richiesto",
  "components.userLeaveQuotas.saveQuotaButton": "Salva",
  "components.userLeaveQuotas.scheduled": "Programmato",
  "components.userLeaveQuotas.scheduledDays": "Giorni programmati",
  "components.userLeaveQuotas.sectionTitle": "Quote ferie",
  "components.userLeaveQuotas.selectPeriod": "Seleziona periodo:",
  "components.userLeaveQuotas.unlock": "Sblocca",
  "components.userLeaveQuotas.unlockBroughtForwardInfo": "Per sbloccare, vai su Impostazioni → Sedi → {locationName} → Visualizza Politiche Ferie, e modifica la politica {leaveTypeName} per consentire il Riporto.",
  "components.userLeaveQuotas.used": "Utilizzato",
  "components.userLeaveQuotas.usedAlready": "Già utilizzato",
  "components.userLeaveQuotas.usedDays": "Giorni utilizzati",
  "components.userLeaveQuotas.usedTooltip": "Giorni {leaveTypeName} utilizzati, programmati e pagati per il periodo corrente. Totale:",
  "components.userLeaveQuotas.userInactiveInfo": "Poiché l'account è ora inattivo, non verranno aggiunti ulteriori giorni fino alla riattivazione.",
  "components.userLeaveQuotasCompact.title": "Il tuo saldo ferie",
  "components.userLeaveQuotasCompact.titleForApprover": "Saldo ferie",
  "components.userLeaveTypeForm.applyForFutureYears": "Vuoi applicare la quota base di ferie per gli anni futuri?",
  "components.userLeaveTypeForm.cancel": "Annulla",
  "components.userLeaveTypeForm.daysPerYear": "Qual è la tua nuova quota di ferie?",
  "components.userLeaveTypeForm.hasUnlimitedDays": "Vuoi consentire Ferie Illimitate?",
  "components.userLeaveTypeForm.leaveTypeEditInfo": "Stai modificando il tipo di ferie \"{leaveTypeName}\" per il periodo {dateRangeName}.",
  "components.userLeaveTypeForm.prorateInfo": "{user} avrà {proratedQuota} ({preciseQuota} giorni, per essere precisi) per l'anno iniziale ({yearPeriod}) e {fullQuota} per tutti gli anni a partire da {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateInfoDays": "{user} avrà {proratedQuota} ({preciseQuota} giorni, per essere precisi) per l'anno iniziale ({yearPeriod}) e {fullQuota} giorni per tutti gli anni a partire da {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateInfoHours": "{user} avrà {proratedQuota} ({preciseQuota} ore, per essere precisi) per l'anno iniziale ({yearPeriod}) e {fullQuota} ore per tutti gli anni a partire da {startOfNextYear}.",
  "components.userLeaveTypeForm.prorateLabel": "Calcola proporzionalmente per l'anno iniziale in base alla data di inizio dell'utente ({startDate})",
  "components.userLeaveTypeForm.quotaAdditionalRole": "Imposta i giorni aggiuntivi in base al ruolo",
  "components.userLeaveTypeForm.quotaAdditionalRoleInfo": "Assegna giorni di ferie aggiuntivi ai dipendenti in base al loro ruolo lavorativo. Questo campo ti permette di personalizzare i diritti alle ferie per riflettere le responsabilità o i requisiti specifici delle diverse posizioni all'interno dell'organizzazione.",
  "components.userLeaveTypeForm.quotaAdditionalSeniority": "Imposta i giorni aggiuntivi in base al diritto di anzianità",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfo": "I giorni basati sull'anzianità sono aggiunti dall'automazione del diritto di anzianità. Puoi sovrascrivere i giorni {leaveTypeName} basati sull'anzianità impostando il valore in questo campo.",
  "components.userLeaveTypeForm.quotaAdditionalSeniorityInfoUpcomingYear": "I giorni basati sull'anzianità sono aggiunti dall'automazione del diritto di anzianità. Puoi sovrascrivere i giorni {leaveTypeName} basati sull'anzianità impostando il valore in questo campo. Tuttavia, questo campo è bloccato perché non puoi modificare questo valore per gli anni futuri prima che i giorni vengano assegnati.",
  "components.userLeaveTypeForm.quotaAdditionalSummary": "Imposterai la quota di ferie {leaveTypeName} per {user} per il periodo selezionato a {total} {hourlyLeaveAccounting, plural, =1 {ore} other {giorni}}. La quota base e i giorni aggiuntivi basati sul diritto di anzianità e sul diritto per ruolo ({total} {hourlyLeaveAccounting, plural, =1 {ore} other {giorni}} in totale) saranno applicati per {future, plural, =1 {il periodo selezionato e i periodi futuri} other {il periodo selezionato}}. {prorateText}",
  "components.userLeaveTypeForm.quotaAdditionalSummaryUnlimited": "Imposterai la quota di ferie {leaveTypeName} per {user} per il periodo selezionato come Illimitata.",
  "components.userLeaveTypeForm.quotaBase": "Imposta la quota base di ferie",
  "components.userLeaveTypeForm.quotaBaseTooltip": "La quota base è la quota predefinita dell'utente, che esclude i giorni aggiuntivi ricevuti in base alla loro anzianità, ruolo, TOIL o qualsiasi altro giorno extra. Puoi modificare la quota base per tutti in una sede nella pagina delle politiche ferie o utilizzare questo campo per impostare una quota personalizzata per un utente selezionato.",
  "components.userLeaveTypeForm.required": "Questo campo è obbligatorio.",
  "components.userLeaveTypeForm.update": "Aggiorna",
  "components.userLeaveTypeForm.updateUserLeaveType": "Aggiorna le quote dell'utente per il tipo di ferie {name}",
  "components.userLogsTab.ACCRUED_DAYS": "Giorni maturati",
  "components.userLogsTab.ALL": "Tutto",
  "components.userLogsTab.APPROVER_STATUS": "Cambio ruolo",
  "components.userLogsTab.filter": "Filtro:",
  "components.userLogsTab.LOCATION_USER_MOVED": "Cambio sede",
  "components.userLogsTab.logsNotFound": "Nessun log trovato per l'intervallo di date selezionato.",
  "components.userLogsTab.ROLLOVER_EXPIRED": "Giorni riportati scaduti",
  "components.userLogsTab.selectFromTo": "Seleziona intervallo di date:",
  "components.userLogsTab.TEAM_USER_MOVED": "Cambio dipartimento",
  "components.userLogsTab.TOIL_REQUEST_APPROVED": "TOIL",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE": "Diritto per ruolo",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_DELETED": "Diritto per ruolo eliminato",
  "components.userLogsTab.USER_ENTITLEMENT_BY_ROLE_OVERRIDE": "Sovrascrittura diritto per ruolo",
  "components.userLogsTab.USER_LEAVE_TYPES_DELETED": "Quota ferie reimpostata al valore predefinito",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_DELETED": "Riporto iniziale eliminato",
  "components.userLogsTab.USER_LEAVE_TYPES_ROLLOVER_UPDATED": "Riporto iniziale aggiornato",
  "components.userLogsTab.USER_LEAVE_TYPES_UPDATED": "Quota ferie aggiornata",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT": "Diritto per anzianità",
  "components.userLogsTab.USER_SENIORITY_ENTITLEMENT_OVERRIDE": "Sovrascrittura diritto per anzianità",
  "components.userLogsTab.USER_STATUS_CHANGED": "Cambio stato",
  "components.userLogsTab.USER_UPDATED": "Aggiornamento informazioni utente",
  "components.userLogsTab.USER_WORK_WEEK_DELETED": "Settimana lavorativa personalizzata eliminata",
  "components.userLogsTab.USER_WORK_WEEK_UPDATED": "Settimana lavorativa aggiornata",
  "components.userLogsTab.YEAR_START": "Inizio anno",
  "components.userManageLabels.actions": "Azioni",
  "components.userManageLabels.color": "Colore",
  "components.userManageLabels.deleteLabel": "Sei sicuro di voler eliminare questa etichetta?\n\nQuesto eliminerà l'etichetta per tutti gli utenti assegnati.",
  "components.userManageLabels.findLabel": "Trova Etichetta",
  "components.userManageLabels.isRequired": "{title} è obbligatorio",
  "components.userManageLabels.manageLabels": "Gestisci Etichette",
  "components.userManageLabels.name": "Nome",
  "components.userManageLabels.userCount": "Conteggio Utenti",
  "components.userProfileTab.admin": "Amministratore",
  "components.userProfileTab.approver": "Approvatore",
  "components.userProfileTab.approvers": "Approvatori:",
  "components.userProfileTab.approverSuffix": " (Approvatore)",
  "components.userProfileTab.customWorkWeekEditedBefore": "La settimana lavorativa di {userName} è stata modificata nella versione precedente di Vacation Tracker (prima del 1 gennaio 2021)",
  "components.userProfileTab.customWorkWeekLabel": "settimana lavorativa personalizzata",
  "components.userProfileTab.customWorkWeekTooltip": "La settimana lavorativa di {userName} è stata modificata da {adminName} il {timestamp}",
  "components.userProfileTab.employeeIdNotSet": "Non impostato",
  "components.userProfileTab.employeeIdTooltip": "ID del dipendente in un sistema esterno",
  "components.userProfileTab.employeeSince": "Dipendente dal:",
  "components.userProfileTab.endDate": "Data di fine:",
  "components.userProfileTab.location": "Località:",
  "components.userProfileTab.name": "Nome:",
  "components.userProfileTab.nameEdited": "Il nome è stato modificato manualmente e non verrà più sincronizzato automaticamente. Questo nome viene utilizzato solo all'interno di Vacation Tracker.",
  "components.userProfileTab.role": "Ruolo:",
  "components.userProfileTab.status": "Stato:",
  "components.userProfileTab.team": "Dipartimento:",
  "components.userProfileTab.user": "Utente",
  "components.userProfileTab.userProfile": "Profilo Utente",
  "components.userProfileTab.workingDays": "Giorni Lavorativi",
  "components.userTodayOff.cancelLeave": "Annulla assenza",
  "components.userTodayOff.editLeave": "Modifica assenza",
  "components.userTodayOff.leaveInfo": "Informazioni sull'assenza",
  "components.userTodayOff.offFromTo": "L'utente ha preso {leaveTypeName} dal {startDate} al {endDate}",
  "components.userTodayOff.offTodayFullDay": "L'utente ha preso {leaveTypeName} oggi, {today}",
  "components.userTodayOff.offTodayPartDay": "L'utente ha preso {leaveTypeName} oggi dalle {from}:00h alle {to}:00h.",
  "components.userTodayOff.offTodayReason": "Motivo: {reason}",
  "components.userUpdateForm.active": "Attivo",
  "components.userUpdateForm.admin": "Amministratore",
  "components.userUpdateForm.approver": "Approvatore",
  "components.userUpdateForm.approverSuffix": " (Approvatore)",
  "components.userUpdateForm.cancel": "Annulla",
  "components.userUpdateForm.employeeSince": "Dipendente Dal",
  "components.userUpdateForm.location": "Località",
  "components.userUpdateForm.name": "Nome",
  "components.userUpdateForm.pleaseSelectALocation": "Seleziona una località",
  "components.userUpdateForm.pleaseSelectATeam": "Seleziona un dipartimento",
  "components.userUpdateForm.pleaseSelectAUserRole": "Seleziona un Ruolo Utente",
  "components.userUpdateForm.pleaseSelectEmployeeSince": "Aggiungi Data Inizio Dipendente",
  "components.userUpdateForm.pleaseSelectUserEndDate": "Aggiungi Data Fine Utente",
  "components.userUpdateForm.role": "Ruolo",
  "components.userUpdateForm.team": "Dipartimento",
  "components.userUpdateForm.update": "Aggiorna",
  "components.userUpdateForm.updateUser": "Aggiorna Utente",
  "components.userUpdateForm.user": "Utente",
  "components.userUpdateForm.userEndDate": "Data Fine Dipendente",
  "components.userUpdateForm.warningInitialRolloverForChangeLocation": "La modifica della località dell'utente potrebbe influire sulla configurazione dell'utente e sulle informazioni relative alle assenze (ad esempio, i giorni iniziali riportati).",
  "components.userUpdateForm.warningInitialRolloverForChangeStartDate": "La modifica della data di inizio di un utente potrebbe influire sulle informazioni relative alle assenze dell'utente (ad esempio, i giorni iniziali riportati).",
  "components.whatsNew.newMessage": "Nuovo messaggio",
  "components.whatsNew.whatsNew": "Cosa c'è di nuovo",
  "connect.alreadyHaveAnAccount": "Hai già un account?",
  "connect.alreadyHaveAnAccountWithLink": "Hai già un account? <b><link>Accedi</link></b>",
  "connect.backToLoginWithOtherPlatofrm": "Torna al login con un'altra piattaforma",
  "connect.companyExists.line1": "{companyName} è già registrata con Vacation Tracker",
  "connect.companyExists.line2Other": "Stai usando l'email corretta?",
  "connect.companyExists.line2Slack": "Stai usando le credenziali corrette?",
  "connect.companyExists.line3aSlack": "Il tuo Workspace Slack: {workspaceName}",
  "connect.companyExists.line3Other": "Il tuo indirizzo email: {email}",
  "connect.companyExists.line3Slack": "Il tuo indirizzo email: {email}",
  "connect.companyExists.line4Other": "Se questa email è corretta, il tuo account non è attivato. Contatta l'amministratore della tua organizzazione Vacation Tracker:",
  "connect.companyExists.line4Slack": "Se queste credenziali sono corrette, il tuo account non è attivato. Contatta l'amministratore della tua organizzazione Vacation Tracker:",
  "connect.companyExists.line5": "Altrimenti, accedi con un account <capitalize>{platform}</capitalize> diverso o accedi da una finestra in incognito del tuo browser.",
  "connect.companyExists.line6Microsoft": "Contatta il Supporto per creare un nuovo dipartimento in {companyName}",
  "connect.companyExists.lineEmailAddress": "Il tuo indirizzo email",
  "connect.companyExists.title": "L'azienda esiste già",
  "connect.connect": "Connetti",
  "connect.connectWithWorkEmail": "Accedi con l'email di lavoro",
  "connect.connectWithYourWorkEmail": "Accedi usando la tua email di lavoro",
  "connect.copyEmail": "Clicca per copiare l'email",
  "connect.copyTextEnd": "Buone vacanze! 🌴",
  "connect.createAnOrganization": "Crea un'organizzazione",
  "connect.description": "Per accedere alla Dashboard di Vacation Tracker, seleziona il pulsante \"Connetti con\" che corrisponde alla piattaforma utilizzata dal tuo team.",
  "connect.dontHaveAnAccount": "Non hai un account?",
  "connect.doYouWantToLoginInToOrganization": "Vuoi accedere a {existingOrganizationName}?",
  "connect.forgotYourPassword": "Hai dimenticato la password?",
  "connect.forgotYourPasswordWhatsYourEmail": "Qual è la tua email?",
  "connect.google.notWorkspaceUserDescription": "Sembra che tu stia cercando di registrarti con un account personale e non con un account Google Workspace. Al momento, Vacation Tracker supporta solo gli account Google Workspace.",
  "connect.google.notWorkspaceUserTitle": "È richiesto un account Google Workspace",
  "connect.google.sharedPermissionsConsent": "Cliccando sul pulsante qui sotto, concedi a Vacation Tracker il permesso di accedere alla directory degli utenti amministratori di Google.",
  "connect.google.sharedPermissionsError": "Impossibile condividere i permessi di Google, il tuo link è scaduto.",
  "connect.google.sharedPermissionsRequired": "Permessi insufficienti",
  "connect.google.sharedPermissionsRequiredDescription": "Per procedere con l'importazione degli utenti, dovrai ottenere i permessi dal tuo amministratore Google. Condividi questo link con il tuo amministratore per concedere il consenso necessario: ",
  "connect.google.sharedPermissionsSuccess": "Permessi Google condivisi con successo!",
  "connect.google.sharedPermissionsSuccessRedirect": "Verrai reindirizzato alla pagina principale di Vacation Tracker tra pochi secondi.",
  "connect.howShouldIConnect": "Come dovrei connettermi?",
  "connect.inputEmailPlaceholder": "Inserisci la tua email di lavoro",
  "connect.inputPasswordPlaceholder": "Inserisci la tua password",
  "connect.interactionRequiredMSErrorDescription1": "Sembra che il tuo account Microsoft richieda permessi aggiuntivi. Fai clic sul pulsante Accedi qui sotto per concedere i permessi aggiuntivi.",
  "connect.interactionRequiredMSErrorDescription2": "Se il problema persiste, contatta il nostro supporto e invia loro il seguente codice:",
  "connect.interactionRequiredMSErrorTitle": "Microsoft richiede autorizzazioni aggiuntive",
  "connect.login": "Accedi",
  "connect.meta.description": "Accedi alla Dashboard di Vacation Tracker e sblocca tutto il potenziale di una gestione delle ferie senza sforzo.",
  "connect.meta.title": "Registrati/Accedi - Vacation Tracker",
  "connect.meta.title.signin": "Accedi - Vacation Tracker",
  "connect.meta.title.signup": "Registrati - Vacation Tracker",
  "connect.or": "O",
  "connect.platformErrorDescription1": "Si è verificato il seguente errore:",
  "connect.platformErrorDescription2": "Potrebbe essere un errore di {platform}. Tuttavia, non esitare a contattare il nostro supporto per ulteriore assistenza.",
  "connect.platformErrorTitle": "Qualcosa è andato storto",
  "connect.pleaseInputValidEmail": "Inserisci un'email valida",
  "connect.pleaseInputValidPassword": "Inserisci una password valida",
  "connect.pleaseLogIn": "Per favore accedi",
  "connect.readMore": "Per ulteriori informazioni, visita il nostro <helpdesk>Helpdesk</helpdesk> o vai sul nostro <website>sito web</website>.",
  "connect.signInWithEmail": "Accedi con l'email",
  "connect.signInWithGoogle": "Accedi con Google",
  "connect.signInWithGoogleToOrganization": "Accedi a Google Workspaces per {existingOrganizationName}",
  "connect.signInWithGoogleVerification": "Accedi con Google",
  "connect.signInWithMS": "Accedi con Microsoft Teams",
  "connect.signInWithMSToOrganization": "Accedi con Microsoft Teams a {existingOrganizationName}",
  "connect.signInWithSlack": "Accedi con Slack",
  "connect.signInWithSlackInstallBot": "Aggiungi Vacation Tracker a Slack",
  "connect.signInWithSlackToOrganization": "Accedi con Slack a {existingOrganizationName}",
  "connect.signUp": "Registrati usando la tua email di lavoro",
  "connect.signUpInfo": "Registrati per la tua prova gratuita",
  "connect.signupNewOrganizationUsingNewPlatform": "Registrati come nuova organizzazione usando il tuo account {selectedPlatform}",
  "connect.signUpWithEmail": "Registrati con l'email",
  "connect.signUpWithGoogle": "Registrati con Google",
  "connect.signUpWithMicrosoft": "Registrati con Microsoft",
  "connect.signUpWithSlack": "Registrati con Slack",
  "connect.slack.usersLoginRequiredButton": "Concedi autorizzazioni a Slack",
  "connect.slack.usersLoginRequiredDescription1": "Il tuo token del bot Slack è scaduto o non è più valido. Non potrai accedere al bot di Vacation Tracker o ricevere notifiche finché non attiverai un nuovo token.",
  "connect.slack.usersLoginRequiredDescription2": "Per attivare un nuovo token del bot Slack, clicca su OK.",
  "connect.slack.usersLoginRequiredDescription3": "Per saltare questo passaggio e procedere alla Dashboard online, clicca su Salta.",
  "connect.slack.usersLoginRequiredTitle": "È richiesto l'accesso al tuo account Slack",
  "connect.slackPostInstallation1": "Hai installato con successo l'applicazione Slack di Vacation Tracker 🎉",
  "connect.slackPostInstallation2": "Per iniziare a utilizzare l'app, accedi alla Dashboard di Vacation Tracker e completa la configurazione.",
  "connect.slackPostInstallation3": "Ti faremo alcune semplici domande. Non ci vorrà più di qualche minuto. In un batter d'occhio sarà fatto (proprio come la tua ultima vacanza).",
  "connect.slackPostInstallationError.generic": "Ehi, dobbiamo prima installare il bot di Vacation Tracker. Potrai esaminare e concedere le autorizzazioni dell'app Slack di Vacation Tracker nel prossimo passaggio.",
  "connect.slackPostInstallationError.invalidCode": "Il codice di reindirizzamento non è più valido. Può accadere se il codice è scaduto, è già stato utilizzato o hai aggiornato la pagina prima che terminassimo il processo di accesso. Ma non preoccuparti, otterremo un nuovo codice!",
  "connect.subscriptionExpired": "Ops, sembra che l'abbonamento della tua azienda a Vacation Tracker sia scaduto! Contatta un amministratore per riattivare l'abbonamento della tua azienda.",
  "connect.subscriptionExpiredTitle": "Abbonamento Scaduto",
  "connect.thisOrganizationUsePlatform": "Tuttavia, {existingOrganizationName} utilizza {existsOnAnotherPlatform}, quindi dovrai accedere con {existsOnAnotherPlatform} per accedere al tuo account",
  "connect.welcome": "Benvenuto, {name}!",
  "connect.youAreAlreadyMember": "Sei già membro dell'organizzazione {existingOrganizationName}",
  "connectCalendar.connect": "Connetti con",
  "connectCalendar.disconnect": "Disconnetti",
  "connectCalendar.disconnectDescription": "Sei sicuro di voler disconnettere il calendario? Vacation Tracker non lo aggiornerà più con le tue future assenze",
  "connectCalendar.disconnectError": "Errore di disconnessione del calendario",
  "connectCalendar.disconnectErrorDescription": "Si è verificato un errore durante il tentativo di disconnettere il tuo calendario da Vacation Tracker. Per favore, clicca Ok per riprovare.",
  "connectCalendar.disconnectSuccess": "Hai disconnesso con successo il calendario {calendar}.",
  "connectCalendar.disconnectTitle": "Disconnetti il Calendario {calendarPlatform}",
  "connectCalendar.error": "Errore di connessione del calendario",
  "connectCalendar.errorDescription": "Si è verificato un errore durante il tentativo di connettere il tuo calendario con Vacation Tracker. Per favore, clicca Ok per riprovare.",
  "connectCalendar.errorReconnectDescription": "Si è verificato un errore durante il tentativo di riconnettere il tuo calendario con Vacation Tracker. Per favore, clicca Ok per riprovare.",
  "connectCalendar.reconnect": "Riconnetti",
  "connectCalendar.reconnectError": "Errore di riconnessione del calendario",
  "connectCalendar.reconnectInfo": "Per assicurarti che la tua assenza sia visibile, per favore riconnetti il tuo calendario.",
  "connectCalendar.reconnectNotification": "La connessione al tuo calendario è scaduta. Per favore clicca qui per riconnetterti e assicurarti che la tua assenza sia visibile nel tuo calendario.",
  "connectCalendar.reconnectNotificationSharedCalendar": "La tua connessione al calendario condiviso: {calendarName} è scaduta. Per favore clicca qui per riconnetterti e assicurarti che le assenze siano visibili nel tuo calendario.",
  "connectCalendar.reconnectSuccess": "Hai riconnesso con successo il calendario {calendar}.",
  "connectCalendar.success": "Hai connesso con successo il calendario {calendar}.",
  "connectedCalendar.eventDescription": "Aggiunto da <link>Vacation Tracker</link>",
  "connectedCalendar.eventDescriptionShared": "Evento del calendario condiviso aggiunto da <link>Vacation Tracker</link>",
  "containers.google.topbar.adminProductTourInfo": "Come Amministratore hai il livello più alto di permessi in Vacation Tracker e puoi personalizzare tutte le impostazioni dell'organizzazione attraverso la Dashboard dell'Amministratore.\n\nLa Dashboard ti permette di: \n- Richiedere Assenze o aggiungere Assenze per altri membri del dipartimento. \n- Avere una panoramica completa attraverso la Homepage della Dashboard e il Calendario. \n- Creare e configurare più dipartimenti (Assegnare Approvatori)  \n- Impostare Notifiche \n- Gestire Utenti (Attivi, Inattivi, Eliminati).\n- Creare e gestire Località (Tipi di Assenza, Festività, Notifiche)\n\nSe le tue domande o preoccupazioni rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: ",
  "containers.google.topbar.approverProductTourInfo": "Come Approvatore, hai il secondo livello più alto di permessi in Vacation Tracker e puoi approvare o negare le richieste di assenza inviate dagli Utenti del dipartimento per cui sei stato assegnato come Approvatore.\n\nLa Dashboard ti permette di:\n- Richiedere Assenze o aggiungere Assenze per altri membri del dipartimento.\n- Avere una panoramica completa attraverso la Homepage della Dashboard e il Calendario. \n- Impostare Notifiche \n- Visualizzare il tuo Profilo Utente.\n- Gestire Richieste (Approvare/Negare Richieste, Visualizzare Storico).\n\nSe le tue domande o preoccupazioni rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: ​​​​​​​​",
  "containers.google.topbar.userProductTourInfo": "Come Utente di Vacation Tracker, puoi inviare e gestire le tue Richieste di Assenza attraverso la Dashboard, e avere anche una panoramica completa delle assenze pianificate all'interno della tua organizzazione.\n\nLa Dashboard ti permette di:\n- Richiedere Assenze\n- Avere una panoramica completa attraverso la Homepage della Dashboard e il Calendario.\n- Visualizzare il tuo Profilo Utente.\n\nSe le tue domande o preoccupazioni rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: ​​​​​​​​",
  "containers.topbar.adminProductTourInfo": "Come Amministratore hai il livello più alto di permessi in Vacation Tracker e puoi personalizzare tutte le impostazioni dell'organizzazione attraverso la Dashboard dell'Amministratore.\n\nLa Dashboard ti permette di: \n- Richiedere Assenze o aggiungere Assenze per altri membri del dipartimento. \n- Avere una panoramica completa attraverso la Homepage della Dashboard e il Calendario. \n- Creare e configurare più dipartimenti (Assegnare Approvatori) \n- Impostare Notifiche  \n- Gestire Utenti (Attivi, Inattivi, Eliminati).\n- Creare e gestire Località (Tipi di Assenza, Festività, Notifiche)\n\nIl Bot ti permette di: \n- Richiedere Assenze \n- Approvare/Negare Richieste di Assenza\n- Ricevere Notifiche Giornaliere/Settimanali\n\nSe le tue domande o preoccupazioni rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: ",
  "containers.topbar.adminProductTourTitle": "Amministratore",
  "containers.topbar.announcement": "Annuncio",
  "containers.topbar.approverProductTourInfo": "Come Approvatore, hai il secondo livello più alto di permessi in Vacation Tracker e puoi approvare o negare le richieste di assenza inviate dagli Utenti del dipartimento per cui sei stato assegnato come Approvatore.\n\nLa Dashboard ti permette di:\n- Richiedere Assenze o aggiungere Assenze per altri membri del dipartimento.\n- Avere una panoramica completa attraverso la Homepage della Dashboard e il Calendario. \n- Impostare Notifiche \n- Visualizzare il tuo Profilo Utente.\n- Gestire Richieste (Approvare/Negare Richieste, Visualizzare Storico).\n\nIl Bot ti permette di:\n- Richiedere Assenze\n- Approvare/Negare Richieste di Assenza\n- Ricevere Notifiche Giornaliere/Settimanali\n\nSe le tue domande o preoccupazioni rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: ",
  "containers.topbar.approverProductTourTitle": "Approvatore",
  "containers.topbar.giveUsFeedback": "Dacci un feedback",
  "containers.topbar.help": "Aiuto",
  "containers.topbar.logout": "Esci",
  "containers.topbar.profile.info": "Puoi accedere al tuo profilo, cambiare il tema e iniziare il tour rapido da questo nuovo menu.",
  "containers.topbar.setUpTheBot": "Configura il bot",
  "containers.topbar.userProductTourInfo": "Come Utente di Vacation Tracker, puoi inviare e gestire le tue Richieste di Assenza attraverso la Dashboard o il Bot, e avere anche una panoramica completa delle assenze pianificate all'interno della tua organizzazione.\n\nLa Dashboard ti permette di:\n- Richiedere Assenze\n- Avere una panoramica completa attraverso la Homepage della Dashboard e il Calendario.\n- Visualizzare il tuo Profilo Utente.\n\nIl Bot ti permette di:\n- Richiedere Assenze \n- Ricevere Notifiche Giornaliere/Settimanali\n\nSe le tue domande o preoccupazioni rimangono senza risposta, contattaci in qualsiasi momento. Parlare con i nostri clienti è la parte migliore della nostra giornata: ",
  "containers.topbar.userProductTourTitle": "Utente",
  "containers.topbar.whatsNewUnread": "Novità - {unread} non lette",
  "Conversation not found.": "Il canale selezionato è stato eliminato o non è più disponibile. Seleziona un altro canale per ricevere le notifiche.",
  "createCompany.signupError": "Qualcosa è andato storto",
  "createCompany.steps.back": "Indietro",
  "createCompany.steps.cancel": "Annulla",
  "createCompany.steps.companyForm": "Dettagli dell'azienda",
  "createCompany.steps.companyForm.agreeTerms": "Accetto l'<aPP>Informativa sulla privacy</aPP>, i <aTOS>Termini di servizio</aTOS> e il <aDPA>DPA</aDPA>. Riconosco inoltre e acconsento al trattamento dei miei dati personali in conformità con i termini delineati nell'Informativa sulla privacy",
  "createCompany.steps.companyForm.agreeToTerms.error": "Devi accettare i nostri Termini di servizio e l'Informativa sulla privacy per continuare.",
  "createCompany.steps.companyForm.companyInfoSectionDescription": "Parlaci della tua azienda e della sua ubicazione. Utilizzeremo queste informazioni per creare il tuo account aziendale e la tua prima sede. Potrai sempre aggiungere altre sedi in seguito.",
  "createCompany.steps.companyForm.companyLeaveSection": "Tipo di congedo predefinito",
  "createCompany.steps.companyForm.companyName": "Nome dell'azienda",
  "createCompany.steps.companyForm.companyNameInput.validation.required": "Questo campo è obbligatorio.",
  "createCompany.steps.companyForm.contactEmail.validation.country": "Questo campo è obbligatorio.",
  "createCompany.steps.companyForm.contactEmail.validation.required": "Necessario per importanti comunicazioni sul prodotto.",
  "createCompany.steps.companyForm.contactEmail.validation.state": "Questo campo è obbligatorio.",
  "createCompany.steps.companyForm.contactEmail.validation.type": "Inserisci un indirizzo email valido.",
  "createCompany.steps.companyForm.contactEmailExtra": "L'indirizzo email fornito sarà utilizzato solo per importanti comunicazioni sul prodotto. Niente spam, lo promettiamo.",
  "createCompany.steps.companyForm.country": "Paese",
  "createCompany.steps.companyForm.defaultPtoQuota": "Quota di ferie retribuite (PTO)",
  "createCompany.steps.companyForm.defaultPtoQuota.validation": "Inserisci la tua quota PTO predefinita",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.maxDefaultPtoQuota": "La tua quota PTO predefinita non può superare i 366 giorni",
  "createCompany.steps.companyForm.defaultPtoQuota.validation.minDefaultPtoQuota": "La tua quota PTO predefinita non può essere inferiore a 0 giorni",
  "createCompany.steps.companyForm.defaultPtoQuotaExtra": "Puoi modificare questo valore in seguito nelle Impostazioni.",
  "createCompany.steps.companyForm.email": "Email di contatto",
  "createCompany.steps.companyForm.location": "Informazioni sulla sede:",
  "createCompany.steps.companyForm.state": "Stato/Regione",
  "createCompany.steps.companyForm.step1Title": "Dettagli dell'azienda",
  "createCompany.steps.companyForm.step2Title": "Seleziona utenti",
  "createCompany.steps.companyForm.step3Title": "Seleziona piano",
  "createCompany.steps.companyForm.unlimitedLeavePolicy": "PTO illimitato",
  "createCompany.steps.companyForm.UserInfoSectionDescription": "Parlaci di te. Useremo queste informazioni per creare il tuo profilo utente. Per impostazione predefinita, ti verrà assegnato il ruolo di amministratore e approvatore. Potrai aggiungere altri utenti e modificare i loro ruoli in seguito.",
  "createCompany.steps.inviteUsers.next": "Invita {noOfUsers} utent{postfix}",
  "createCompany.steps.inviteUsers.title": "Invita i membri del tuo team",
  "createCompany.steps.inviteUsers.title.info": "Invita gli utenti che desideri aggiungere all'account Vacation Tracker della tua organizzazione. Potrai sempre apportare modifiche in seguito tramite il dashboard.",
  "createCompany.steps.next": "Continua",
  "createCompany.steps.selectPlan.noCC": "Nessuna carta di credito richiesta per la prova di 7 giorni",
  "createCompany.steps.selectPlan.title": "Iniziamo!",
  "createCompany.steps.selectPlan.youCanSwitchPlanDuringTrial": "Puoi cambiare piano in qualsiasi momento durante il periodo di prova",
  "createCompany.steps.selectUsers": "Importa utenti",
  "createCompany.steps.selectUsers.deselectAll": "Deseleziona tutto",
  "createCompany.steps.selectUsers.doneButton": "Importa {noOfUsers} Utent{postfix}",
  "createCompany.steps.selectUsers.doneButtonTryItOut": "Inizia la tua prova gratuita di 14 giorni",
  "createCompany.steps.selectUsers.loadingAll": "Caricamento di tutti gli utenti",
  "createCompany.steps.selectUsers.searchUsers": "Trova Nemo...",
  "createCompany.steps.selectUsers.selectAll": "Seleziona tutto",
  "createCompany.steps.selectUsers.title": "Scegli i membri del tuo team",
  "createCompany.steps.selectUsers.title.info": "Seleziona gli utenti che vuoi aggiungere all'account Vacation Tracker della tua organizzazione. Puoi sempre apportare modifiche in seguito tramite il dashboard. Gli utenti selezionati non saranno notificati fino a quando non farai l'annuncio.",
  "dashboard.accountSettingsLanguageLabel": "Scegli la tua lingua preferita",
  "dashboard.accountSettingsOutOfOfficeChangeModalInfo": "Vacation Tracker imposterà tutti i tuoi futuri messaggi di assenza per l'email {email}.\n Tuttavia, se hai programmato un messaggio di assenza prima di questa modifica, lo imposteremo comunque per la vecchia email.",
  "dashboard.accountSettingsOutOfOfficeChangeModalTitle": "Cambia indirizzo email per i futuri messaggi di assenza",
  "dashboard.accountSettingsOutOfOfficeConnectedWith": "Connesso con",
  "dashboard.accountSettingsOutOfOfficeTitle": "Configura risposta automatica di assenza (OOO)",
  "dashboard.accountSettingsPageOOODescription": "Una volta abilitato, Vacation Tracker ti avviserà due giorni prima del tuo congedo pianificato e ti offrirà l'opportunità di impostare un messaggio di risposta automatica di assenza (OOO).",
  "dashboard.accountSettingsTimeDisplayLabel": "Scegli il formato di visualizzazione dell'ora preferito",
  "dashboard.accountSettingsUIModeLabel": "Modalità scura vs. Modalità chiara",
  "dashboard.b2bSoftware": "Si prega di notare che questo è un software B2B e pertanto non siamo in grado di modificare o cancellare i tuoi dati su richiesta. Per ulteriori informazioni, contatta il tuo manager dedicato. Se hai bisogno di assistenza, il nostro team di supporto è disponibile all'indirizzo <mail>hello@vacationtracker.io</mail>",
  "dashboard.days": "Giorno/i",
  "dashboard.daysSmallCase": "giorno/i",
  "dashboard.daysSmallCaseSingle": "g",
  "dashboard.googlePermissionInfo2": "- L'accesso alle impostazioni di Google Calendar è necessario per ottenere le tue impostazioni di fuso orario di Google.",
  "dashboard.googlePermissionInfo3": "- L'accesso alle impostazioni di Gmail è necessario per impostare la tua risposta OOO e definire l'intervallo di date pertinente.",
  "dashboard.hour": "ora/e",
  "dashboard.hourSingle": "h",
  "dashboard.minuteSingle": "m",
  "dashboard.noLeaveRequest": "Questa richiesta di congedo non è attiva.",
  "dashboard.noOneIsOffToday": "Non ci sono congedi pianificati per oggi.",
  "dashboard.noOneIsTackingLeaves": "Non ci sono congedi programmati al momento.",
  "dashboard.noOpenLeavesRequests": "Non ci sono richieste in sospeso.",
  "dashboard.noScheduledLeavesHolidays": "Nessun congedo o festività programmati",
  "dashboard.openLeavesRequests": "Richieste in sospeso",
  "dashboard.outlookPermissionInfo2": "- Accesso offline: Questo è necessario affinché Vacation Tracker possa impostare la tua risposta OOO.",
  "dashboard.outlookPermissionInfo3": "- Impostazioni della casella di posta Outlook: Abbiamo bisogno di accedere alle tue impostazioni del fuso orario di Outlook per garantire una temporizzazione accurata. Inoltre, richiediamo il permesso di impostare il tuo messaggio OOO e definire l'intervallo di date desiderato.",
  "dashboard.outOfOffice": "Imposta messaggio OOO",
  "dashboard.outOfOffice.requestIntegrationButton": "Richiedi un'integrazione",
  "dashboard.outOfOfficeActiveLabel": "Fuori ufficio",
  "dashboard.outOfOfficeGoogleMailButton": "Email Google",
  "dashboard.outOfOfficeGoogleSigninModalTitle": "Accesso all'account Google richiesto",
  "dashboard.outOfOfficeMicrosoftSigninModalTitle": "Accesso all'account Microsoft richiesto",
  "dashboard.outOfOfficeNotActiveInfo": "Sembra che tu non abbia ancora abilitato la funzione di risposta automatica di email per assenza dall'ufficio nel tuo account. Questo strumento ti permette di impostare email automatiche per specifici tipi di congedo, assicurando che i tuoi contatti siano consapevoli quando non sei disponibile. Vuoi attivare questa funzione?",
  "dashboard.outOfOfficeOtherEmail": "Hai un provider di posta elettronica diverso?",
  "dashboard.outOfOfficeOtherEmailInfo": "Al momento, supportiamo Gmail e Outlook. Tuttavia, ci sforziamo costantemente di migliorare il nostro software. Il tuo feedback sul provider di posta elettronica che stai utilizzando sarebbe estremamente utile per aiutarci a determinare le nostre priorità di sviluppo. Fino a quando non estenderemo il nostro supporto ad altri provider, puoi impostare manualmente la tua risposta email di Fuori Ufficio all'interno del tuo servizio di posta elettronica.",
  "dashboard.outOfOfficeOtherEmailPlaceholder": "Inserisci il tuo provider, ad es. ProtonMail, Yahoo! Mail...",
  "dashboard.outOfOfficeOutlookButton": "Outlook",
  "dashboard.outOfOfficePageSettingsTitle": "SCEGLI IL TUO PROVIDER DI POSTA ELETTRONICA",
  "dashboard.outOfOfficePageTitle": "Fuori Ufficio",
  "dashboard.outOfOfficeSet": "Imposta messaggio Fuori Ufficio",
  "dashboard.outOfOfficeSetError": "Si è verificato un errore nell'impostazione della tua risposta automatica Fuori Ufficio. Per favore riprova.",
  "dashboard.outOfOfficeSetInfo": "Qui puoi impostare la tua risposta email 'Fuori Ufficio'. Mentre sei assente, questo messaggio verrà inviato automaticamente alle persone che cercano di contattarti via email. Se decidi di modificare questo messaggio in futuro, puoi farlo facilmente attraverso le impostazioni della tua email.",
  "dashboard.outOfOfficeSetInfoBot": "La tua risposta email 'Fuori Ufficio' è impostata. Mentre sei assente, questo messaggio verrà inviato automaticamente alle persone che cercano di contattarti via email. Se decidi di modificare questo messaggio in futuro, puoi farlo facilmente attraverso le impostazioni della tua email.",
  "dashboard.outOfOfficeSetSuccess": "Congratulazioni — hai impostato con successo il tuo messaggio automatico di fuori ufficio. Sarà attivo quando inizierà la tua assenza.",
  "dashboard.peopleOffToday": "Assenze di oggi",
  "dashboard.permissionModalInfo": "Affinché Vacation Tracker imposti automaticamente la tua risposta Fuori Ufficio (OOO), abbiamo bisogno di ulteriori permessi:",
  "dashboard.selectSnoozeLeaveTypesPlaceholder": "Seleziona i tipi di assenza...",
  "dashboard.snoozedLeaveTypesLabel": "Disattiva per ",
  "dashboard.upcomingDaysOff": "Assenze programmate ({total})",
  "dashboard.upcomingHolidays": "Prossime festività",
  "departments.viewDepartment": "Visualizza Dipartimento",
  "disconnectCalendar.success": "Hai disconnesso il calendario con successo.",
  "editLeave.editLeave": "Modifica Assenza",
  "editLeave.handleSubmitErrorTitle": "Errore di modifica dell'assenza",
  "editLeave.inProgress": "Modifica dell'assenza in corso",
  "editLeave.requestLeave": "Modifica Assenza",
  "editLeave.title": "Assenza modificata",
  "email.notification.accruedDaysInfoForApprover": "ℹ️ Questa richiesta di assenza si basa su giorni di ferie futuri maturati, non sull'importo attuale. {name} ha {amount} giorno/i rimanente/i oggi. Matureranno {accrued} il {earningDate}.",
  "email.notification.accruedDaysInfoForUser": "ℹ️ La tua richiesta di assenza si basa su giorni di ferie futuri maturati, non sull'importo attuale. Hai {amount} giorno/i rimanente/i oggi, e maturerai {accrued} il {earningDate}.",
  "email.notification.holiday.plural": "Le sedi <strong>{locationNames}</strong> saranno chiuse per <strong>{holidayName}</strong> {holidayPeriodText}",
  "email.notification.holiday.singular": "La sede <strong>{locationNames}</strong> sarà chiusa per <strong>{holidayName}</strong> {holidayPeriodText}",
  "email.notification.title": "Notifica di Assenza",
  "error.auth.companyExists.line1": "Il tuo account non è attivato. Per utilizzare Vacation Tracker, contatta l'amministratore e chiedi di attivare il tuo account.",
  "error.auth.companyExists.line2": "Amministratori:",
  "error.auth.companyExists.line3": "Oppure, contatta il nostro supporto ({support}) se desideri utilizzare Vacation Tracker come dipartimento indipendente nella stessa azienda.",
  "error.auth.companyExists.title": "La tua organizzazione è già registrata",
  "error.automations.automationSubmitError": "Errore di invio dell'automazione",
  "error.automations.insufficientPrivileges.description": "Non hai il permesso di eseguire questa azione. Contatta il tuo amministratore.",
  "error.automations.insufficientPrivileges.title": "Errore di permesso",
  "error.blackOutPeriod.isntCreated": "Il periodo di blackout non è stato creato",
  "error.blackOutPeriod.isntDeleted": "Il periodo di blackout non è stato eliminato",
  "error.blackOutPeriod.isntUpdated": "Il periodo di blackout non è stato aggiornato",
  "error.blackOutPeriod.UserNotAdminOrApprover": "L'utente non è amministratore o approvatore",
  "error.blackOutPeriod.UserNotFound": "Utente non trovato",
  "error.botTokenNotFound": "Token del bot non trovato, effettua il login alla nostra dashboard e riprova.",
  "error.CannotDuplicateName": "Il nome inserito è già in uso. Inserisci un nome diverso.",
  "error.channelNotFound": "Il canale selezionato è stato eliminato o non è più disponibile. Seleziona un altro canale per ricevere le notifiche.",
  "error.codeDeliveryFailureException": "Errore nella consegna del codice",
  "error.codeDeliveryFailureExceptionMessage": "Non siamo in grado di inviare un codice di verifica. Verifica di aver fornito un indirizzo email valido. Se il tuo indirizzo email è valido, riprova più tardi.",
  "error.codeMismatchException": "Codice di verifica non valido",
  "error.codeMismatchExceptionMessage": "Il codice di verifica fornito non è valido. Riprova o richiedi un nuovo invio dell'email.",
  "error.companyExists": "L'azienda esiste già",
  "error.CustomWorkday": "Non è possibile passare a un piano senza ore di lavoro personalizzate. Rimuovi le ore di lavoro personalizzate dalle impostazioni o contatta l'assistenza.",
  "error.deletedLocation": "La sede selezionata è stata eliminata o non è più disponibile. Seleziona un'altra sede per ricevere le notifiche.",
  "error.deletedTeam": "Il reparto selezionato è stato eliminato o non è più disponibile. Seleziona un altro reparto per ricevere le notifiche.",
  "error.DepartmentWithChildrenDeleteForbidden": "Non puoi eliminare un reparto che ha sottoreparti. Elimina prima i sottoreparti.",
  "error.emailError": "Email non valida",
  "error.emailError.description": "Fornisci un indirizzo email valido",
  "error.endTimeCantBeBeforeStartTime": "Sembra che l'ora di inizio selezionata sia successiva all'ora di fine. Seleziona un'ora di inizio precedente all'ora di fine.",
  "error.entitlementByRole.isntCreated": "Il diritto per ruolo non è stato creato",
  "error.entitlementByRole.isntDeleted": "Il diritto per ruolo non è stato eliminato",
  "error.entitlementByRole.isntUpdated": "Il diritto per ruolo non è stato aggiornato",
  "error.expiredCodeException": "Codice di verifica scaduto",
  "error.expiredCodeExceptionMessage": "Il codice di verifica fornito è scaduto.",
  "error.fetchingConnectedCalendarInfo": "Si è verificato un problema nel recupero delle informazioni del tuo calendario connesso. Riprova. Se il problema persiste, contatta il nostro servizio clienti.",
  "error.fetchingUserOutOfOfficeSettings": "Si è verificato un problema nel recupero delle tue impostazioni di assenza dall'ufficio. Riprova. Se il problema persiste, contatta il nostro servizio clienti.",
  "error.fieldIsRequired": "Questo campo è obbligatorio.",
  "error.firstEarningDateMissing": "Manca la prima data di guadagno",
  "error.generic": "Si è verificato un errore",
  "error.generic.description": "Contatta l'assistenza con il seguente messaggio:",
  "error.google.directoryApiDisabledP1": "Vacation Tracker ha bisogno di accedere ai dati della Directory per importare nuovi utenti.",
  "error.google.directoryApiDisabledP2": "L'amministratore del tuo Google Workspace può consentire l'accesso alla directory seguendo i passaggi dell'articolo di supporto: ",
  "error.google.directoryApiDisabledP3": "Puoi registrarti senza abilitare questa funzionalità. Tuttavia, non sarai in grado di importare utenti dal tuo Google Workspace.",
  "error.google.directoryApiDisabledP4": "Se hai bisogno di ulteriore assistenza, contattaci all'indirizzo hello@vacationtracker.io",
  "error.google.directoryApiDisabledTitle": "Condivisione dei contatti disattivata",
  "error.google.directoryApiPermissionDeniedP1": "Vacation Tracker ha bisogno di poter elencare gli utenti dalla directory del tuo Google Workspace. Fai clic sul pulsante \"Accedi con Google\" e seleziona la casella di controllo come mostrato nell'immagine sottostante per poter effettuare la registrazione.",
  "error.google.directoryApiPermissionDeniedP2": "Memorizzeremo solo le seguenti informazioni per gli utenti che selezioni esplicitamente per l'importazione: nome, immagine del profilo ed email.",
  "error.google.directoryApiPermissionDeniedP3": "Se non vedi la casella di controllo quando fai clic sul pulsante \"Accedi con Google\", fai clic sul pulsante \"Continua\" nella finestra modale delle autorizzazioni di Google per procedere con il processo di registrazione.",
  "error.google.directoryApiPermissionDeniedTitle": "Concedi le autorizzazioni mancanti per continuare",
  "error.google.loginRequiredTitle": "È richiesto l'accesso al tuo account Google",
  "error.google.usersLoginRequiredDescription1": "La gestione degli utenti richiede ambiti di Google. Se fai clic sul pulsante OK, ti reindirizzeremo alla pagina di accesso di Google. A seconda delle impostazioni del tuo browser, potresti essere già connesso e automaticamente reindirizzato.",
  "error.google.usersLoginRequiredDescription2": "Dopo aver effettuato l'accesso al tuo account Google, ti riporteremo a questa pagina in modo che tu possa gestire gli utenti.",
  "error.goToRequestLeaveTab": "Errore di transizione alla scheda Richiesta Ferie",
  "error.holidayDateInvalid": "Data festività non valida {date} per l'anno {year} ",
  "error.incorrectPassword": "Password errata",
  "error.incorrectPasswordMessage": "La password inserita non è corretta o è scaduta. Riprova (assicurati che il blocco maiuscole sia disattivato). Hai dimenticato la password?",
  "error.invalidFileType": "Tipo di file non valido",
  "error.invalidFileType.description": "Hai caricato un tipo di file non valido. Carica un'immagine JPEG, PNG o GIF.",
  "error.invalidPasswordException": "Password non valida",
  "error.invalidPasswordExceptionMessage": "Fornisci una password valida con almeno 8 caratteri.",
  "error.leavePolicyMissing": "Politica di congedo mancante",
  "error.leaveRequestNotEditable": "Non sei autorizzato a modificare questa richiesta di congedo.",
  "error.leaveRequestOverlap": "Le date scelte si sovrappongono a un congedo esistente. Seleziona un periodo diverso.",
  "error.leaveRequestsLoadingError.description": "I tuoi congedi esistono, ma non abbiamo potuto caricarli a causa di un problema tecnico da parte nostra. Prova a ricaricare la pagina. Se il problema persiste, contatta il nostro <link>Supporto Clienti</link>.",
  "error.leaveRequestsLoadingError.title": "Impossibile caricare i congedi",
  "error.leaveRequestStatusNotApproved": "Stato della richiesta di congedo: non approvata.",
  "error.leaveRequestStatusNotOpen": "Stato della richiesta di congedo: non aperta",
  "error.leaveSubmitError": "Errore nell'invio del congedo",
  "error.leaveTypeMissing": "Tipo di congedo mancante",
  "error.LocationLimitReached": "Ci dispiace, ma non puoi passare al piano CORE poiché attualmente superi il limite di {limitNumber} sedi. Modifica le tue sedi per soddisfare i criteri del piano CORE o contatta il supporto per ulteriori indicazioni.",
  "error.microsoft.channelNotFound": "Non sei membro del team Microsoft Teams selezionato",
  "error.microsoft.logInWithDifferentAccount": "Accedi con un account diverso",
  "error.microsoft.microsoftPermissionsNotGranted": "Permessi Microsoft non concessi",
  "error.microsoft.notificationsLoginRequiredTitle": "È richiesto l'accesso al tuo account Microsoft",
  "error.microsoft.notMemberOfTeam": "La tua organizzazione si è registrata con un singolo team Microsoft Teams e non sei membro del team con il seguente ID: {msTeamId}.\n\nPuoi unirti a questo team nella tua applicazione Microsoft Teams e riprovare, oppure contattare il nostro supporto per modificare il tipo di installazione.",
  "error.microsoft.wrongAccount": "Account Microsoft errato",
  "error.missingSlackBotToken": "L'azione è stata elaborata, tuttavia manca il token del bot per notificare l'utente. Effettua il logout e il login nuovamente per ottenere il token del bot.",
  "error.missingSlackBotTokenError": "Non possiamo elaborare la richiesta perché manca il token del bot. Effettua il logout e il login nuovamente per ricevere il token del bot e riprova.",
  "error.mustBeApproverForTeam": "Non sei autorizzato a eseguire questa azione, devi essere un Approvatore per questo dipartimento per farlo.",
  "error.mustBeCreatorOrApprover": "Devi essere il creatore o l'approvatore per modificare lo stato della richiesta di congedo.",
  "error.negativeBallanceNotAllowed": "Il saldo negativo non è consentito per questo tipo di congedo.",
  "error.notificationGeneral": "Errore di notifica",
  "error.oneHoureIntervalNotAllowed": "L'intervallo di tempo più breve che puoi richiedere per questo tipo di congedo è di 4 ore (mezza giornata).",
  "error.openApi.isntCreated": "La chiave API Open non è stata creata",
  "error.openApi.isntDeleted": "La chiave API Open non è stata eliminata",
  "error.partTimeIsntAllowed": "Il part-time non è consentito",
  "error.passwordResetLimitExceededException": "Limite superato",
  "error.passwordResetLimitExceededExceptionMessage": "Limite di tentativi di modifica della password superato. Riprova tra 15 minuti.",
  "error.paymentFailed": "Pagamento fallito",
  "error.paymentNotProcessed": "Pagamento fallito, prova con una carta diversa.",
  "error.probationPeriod.isntCreated": "Il periodo di prova non è stato creato",
  "error.probationPeriod.isntDeleted": "Il periodo di prova non è stato eliminato",
  "error.probationPeriod.isntUpdated": "Il periodo di prova non è stato aggiornato",
  "error.PromoCodeDoesntExist": "Il codice promozionale {promoCode} non esiste",
  "error.PromoCodeIsntValid": "Il codice promozionale {promoCode} non è valido",
  "error.reasonRequired": "Il campo motivo è obbligatorio.",
  "error.requestedMoreThanFullDay": "Hai richiesto più di un giorno intero di ferie in un giorno lavorativo. Per favore, seleziona un giorno diverso.",
  "error.samePasswordException": "Password non cambiata",
  "error.samePasswordExceptionMessage": "La nuova password è identica a quella vecchia. Inserisci una password diversa se vuoi cambiarla.",
  "error.saveFailed": "Salvataggio fallito",
  "error.seniorityEntitlement.isntCreated": "Il diritto di anzianità non è stato creato",
  "error.seniorityEntitlement.isntDeleted": "Il diritto di anzianità non è stato eliminato",
  "error.seniorityEntitlement.isntUpdated": "Il diritto di anzianità non è stato aggiornato",
  "error.signin.inactiveUser": "Utente Inattivo",
  "error.signup.alreadySignedUp.descriptionLine1": "Sei già registrato con il seguente indirizzo email: <text>{ email }</text>. Per favore, effettua il login.",
  "error.signup.alreadySignedUp.descriptionLine2": "In caso tu abbia dimenticato la password, clicca il pulsante qui sotto per reimpostarla.",
  "error.signup.alreadySignedUp.title": "Sei già registrato",
  "error.signup.alreadySignedUpInactive.descriptionLine1": "Sei già registrato con il seguente indirizzo email: <text>{ email }</text>.",
  "error.signup.alreadySignedUpInactive.descriptionLine2": "Tuttavia, il tuo account è inattivo. Per favore, contatta il nostro supporto per maggiori informazioni e invia loro le seguenti informazioni:",
  "error.slack.allowConversationWithSlackBotDescription": "Questa funzione richiede un permesso aggiuntivo di Slack che consente al bot di Vacation Tracker per Slack di ricevere messaggi diretti. Il nostro bot sarà in grado di leggere i messaggi che tu e altri utenti del tuo Workspace Slack gli inviate, ma non avrà il permesso di leggere i tuoi canali, gruppi o messaggi diretti.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine1": "Il bot di Vacation Tracker supporta un comando slash e una scorciatoia per le richieste di ferie. Inoltre, puoi abilitare la conversazione in linguaggio umano nella Dashboard Web (e optare per l'uso di LLM).",
  "error.slack.allowConversationWithSlackBotLlmWarningLine2": "L'attivazione di questa funzione potrebbe causare occasionalmente suggerimenti errati di date o tipi di ferie da parte del bot. È importante verificare le sue raccomandazioni prima di effettuare una richiesta di ferie.",
  "error.slack.allowConversationWithSlackBotLlmWarningLine3": "Né Vacation Tracker né terze parti utilizzano le tue informazioni per addestrare Modelli di Linguaggio di Grandi Dimensioni.",
  "error.slack.allowConversationWithSlackBotLlmWarningTitle": "Disclaimer sul Modello di Linguaggio di Grandi Dimensioni (LLM)",
  "error.slack.allowConversationWithSlackBotTitle": "Consenti la conversazione con il bot di Vacation Tracker",
  "error.slack.logInWithDifferentAccount": "Accedi con un account diverso",
  "error.slack.usersLoginRequiredTitle": "È richiesto l'accesso al tuo account Slack",
  "error.slack.wrongAccount": "Account Slack errato",
  "error.somethingWentWrong": "Oh no! 😕 Qualcosa è andato storto. ",
  "error.startDateCantBeInPast": "La data di inizio non può essere nel passato, per favore seleziona una data futura",
  "error.subscriptionExpired": "Oops, sembra che l'abbonamento della tua azienda a Vacation Tracker sia scaduto! Per favore, contatta un amministratore per riattivare l'abbonamento della tua azienda.",
  "error.subscriptionExpiredTitle": "Abbonamento scaduto",
  "error.team.delete": "Il dipartimento non può essere eliminato",
  "error.TeamLimitReached": "Il numero attuale di dipartimenti supera il limite consentito nel piano CORE. Per effettuare il downgrade, riduci i tuoi dipartimenti a {limitNumber} o meno, oppure contatta il nostro supporto per assistenza.",
  "error.token.button": "Accedi con {platform}",
  "error.token.description": "Il tuo token {platform} è scaduto. Per favore, clicca sul pulsante qui sotto per ottenere un nuovo token",
  "error.token.permissionDenied": "Nella schermata di accesso di Google hai cliccato su Continua senza consentire gli ambiti di autenticazione richiesti. Per favore, clicca su Seleziona tutto o seleziona i permessi adeguati (Vedi, modifica, condividi ed elimina permanentemente tutti i calendari a cui puoi accedere usando Google Calendar e Vedi, modifica, crea o modifica le tue impostazioni e-mail e i filtri in Gmail) e poi clicca su Continua.",
  "error.userEndDateInFuture": "Le date richieste sono successive alla data di fine impiego dell'utente. Per favore, contatta l'amministratore della tua organizzazione o il supporto di Vacation Tracker per maggiori informazioni.",
  "error.UserNotAdminOrApprover": "L'utente non è un amministratore o un approvatore",
  "error.userNotFound": "Utente non trovato",
  "error.UserNotFound": "Utente non trovato",
  "error.UserNotFoundApprover": "Approvatore non trovato",
  "error.userSignup.invitationAccepted.description": "Il codice di invito è già stato accettato, per favore effettua il login",
  "error.userSignup.invitationAccepted.title": "Codice di invito già accettato",
  "error.userSignup.invitationExpired.description": "Il codice di invito è scaduto, chiedi al tuo amministratore di inviare nuovamente l'invito",
  "error.userSignup.invitationExpired.title": "Codice di invito scaduto",
  "error.userSignup.invitationNotFound.description": "Verifica che il link di invito sia corretto o contatta il tuo amministratore per inviare nuovamente l'invito.",
  "error.userSignup.invitationNotFound.title": "Invito non trovato",
  "error.userStartDateInFuture": "Le date richieste sono precedenti alla data di inizio impiego dell'utente. Contatta l'amministratore della tua organizzazione o il supporto di Vacation Tracker per maggiori informazioni.",
  "error.zeroDaysLeaveRequest": "Hai selezionato un giorno festivo o non lavorativo. Seleziona un intervallo di date valido.",
  "errors.microsoft.permissions.microsoftPermissionsNotGrantedDescription": "Sembra che tu abbia annullato o chiuso la pagina \"Accedi con Microsoft\" prima di concedere le autorizzazioni richieste. Fai clic sul pulsante qui sotto per accedere con Microsoft e completare la configurazione di Vacation Tracker.\n\nSe non hai l'autorizzazione per accedere con Microsoft, contatta l'amministratore Microsoft 365 della tua organizzazione.",
  "errors.microsoft.subscriptionExpiredDescription": "Sembra che il tuo abbonamento sia scaduto. Contatta l'amministratore della tua organizzazione Vacation Tracker per maggiori dettagli.\n\nContatta il nostro supporto se hai bisogno di cambiare l'amministratore o hai altre domande.",
  "errors.microsoft.wrongMicrosoftAccountDescription": "Sembra che tu abbia effettuato l'accesso con un account Microsoft diverso. Hai acquistato le licenze utilizzando il seguente indirizzo email:\n\n{email}.\n\nAccedi con l'account che hai usato per acquistare le licenze per completare la configurazione.",
  "errors.slack.wrongSlackAccountDescription": "Sembra che tu abbia effettuato l'accesso con un account Slack diverso.",
  "events.logs.AUTOMATION": "Automazioni",
  "events.logs.BILLING": "Fatturazione",
  "events.logs.BULK_ACTIONS": "Importa utenti",
  "events.logs.COMPANY": "Azienda",
  "events.logs.LABEL": "Etichetta",
  "events.logs.LEAVE_POLICY": "Politica di congedo",
  "events.logs.LEAVE_TYPE": "Tipo di congedo",
  "events.logs.LOCATION": "Sede",
  "events.logs.LOCATION_YEAR_ROLLOVER": "Passaggio annuale della sede",
  "events.logs.NOTIFICATION": "Notifiche",
  "events.logs.REPORT": "Rapporto",
  "events.logs.TEAM": "Dipartimento",
  "events.logs.TEAM_POLICY": "Politica del team",
  "export.approved": "Approvato",
  "export.currentYear": "Anno corrente",
  "export.denied": "Negato",
  "export.export": "Esporta",
  "export.exportPeriod": "Periodo di esportazione",
  "export.fileType": "Formato di esportazione",
  "export.fileTypeCsv": "CSV",
  "export.fileTypeExcelXlsx": "Excel",
  "export.labels": "Etichette",
  "export.lastMonth": "Mese scorso",
  "export.leaveBalanceReport": "Rapporto sul saldo dei congedi",
  "export.leaveBalanceReportCustomDaysInfo": "Nota che il rapporto mostrerà solo i giorni o le ore utilizzati se selezioni un periodo personalizzato. Non potrai esportare il totale o la quota disponibile a meno che non scegli una delle opzioni predefinite.",
  "export.leaveBalanceReportDescription": "La quantità di congedi assegnati e usufruiti per ogni tipo di congedo per ciascun dipendente in un periodo specifico.",
  "export.leaveHistoryReport": "Rapporto sulle richieste di congedo",
  "export.leaveHistoryReportDescription": "Storico di tutte le richieste di congedo (approvate, negate, scadute) per ogni tipo di congedo per ciascun dipendente in un periodo specifico.",
  "export.locations": "Sedi",
  "export.monthlyLeaveBalanceReport": "Report del Saldo Ferie Mensile",
  "export.monthlyLeaveBalanceReportDescription": "La quantità di ferie prese (o programmate) per Tipo di Ferie per ogni dipendente per il mese corrente e i successivi 11 mesi.",
  "export.nextYear": "Anno prossimo",
  "export.previousYear": "Anno precedente",
  "export.rangeDate": "Data di Inizio e Fine",
  "export.reportType": "Tipo di report",
  "export.required": "Questo campo è obbligatorio.",
  "export.status": "Stato",
  "export.teams": "Dipartimenti",
  "externalConnect.faildLogin": "Il tuo token monouso è scaduto o è già stato utilizzato. Ti reindirizzeremo alla pagina di destinazione in pochi secondi, ma potrebbe essere necessario effettuare nuovamente l'accesso.\n\nSe hai fretta, clicca sul pulsante qui sotto.",
  "externalConnect.parseError": "Oh no! 😕 Qualcosa è andato storto.\n\nSarai reindirizzato alla pagina di login in 10 secondi.",
  "externalConnect.pleaseWait": "Per favore attendi, il tuo PC non è Superman!",
  "form.confirmPasswordInfo": "Per favore conferma la tua password!",
  "form.department.generalDescriptionCreate": "Imposta il nome del tuo dipartimento e seleziona i suoi utenti e approvatori. Se vuoi configurare il dipartimento prima di aggiungere utenti, puoi creare il dipartimento prima e aggiungere gli utenti dopo, ma è necessario aggiungere almeno un approvatore.",
  "form.department.generalDescriptionEdit": "Modifica il nome del tuo dipartimento o i suoi utenti e approvatori.",
  "form.department.generalTitle": "Impostazioni del Dipartimento",
  "form.department.setDefaultDescription": "Dipartimento predefinito",
  "form.department.setDefaultTitle": "Dipartimento predefinito",
  "form.department.subdepartmentDescription": "Puoi annidare i dipartimenti per creare una struttura ad albero dell'organizzazione. Se questo è un sotto-dipartimento, puoi impostare il suo dipartimento principale in questa sezione.",
  "form.department.subdepartmentTitle": "Impostazioni del sotto-dipartimento",
  "form.general.companySettingsDescription": "Imposta il nome della tua azienda e l'email di contatto principale. L'indirizzo email che fornisci sarà utilizzato solo per comunicazioni importanti relative al prodotto. Niente spam, te lo promettiamo.",
  "form.general.companySettingsTitle": "Impostazioni aziendali",
  "form.general.configurationDescription": "Questa sezione ti permette di perfezionare aspetti specifici del monitoraggio delle ferie per la tua azienda. Abilita le date di fine per gli utenti per disattivare automaticamente i dipendenti con una data di fine, impedendo loro di inviare richieste di ferie oltre la loro partenza. Scegli di monitorare le quote di ferie in giorni o ore, in base alle politiche della tua azienda. Inoltre, definisci l'anno di inizio per il monitoraggio delle ferie. Per impostazione predefinita, i calcoli delle ferie iniziano dall'anno in cui ti sei iscritto, ma puoi modificarlo per includere dati storici da altri sistemi o fogli di calcolo.",
  "form.general.configurationTitle": "Configurazione",
  "form.general.migrationDescription": "Stai usando Slack, Microsoft Teams o Google Workspace? Puoi passare dall'autenticazione basata su email e password a un'integrazione specifica per piattaforma. Il passaggio a una delle nostre integrazioni semplificherebbe il processo di login e invito degli utenti (importazione degli utenti dalla tua directory utenti) e ti darebbe integrazioni aggiuntive, come le schede di Microsoft Teams e i bot di Microsoft Teams o Slack.",
  "form.general.migrationFeatureDescription": "Questo avvierà il processo di migrazione. Sarai reindirizzato a una piattaforma dove potrai connettere il tuo account.",
  "form.general.migrationFeatureTitle": "Avvia la migrazione verso un'altra piattaforma",
  "form.general.migrationTitle": "Passa a un'altra piattaforma",
  "form.inputRequired": "Questo campo è obbligatorio",
  "form.inputSize": "Il campo deve avere {len} cifre",
  "form.passwordDoNotMatch": "Le due password inserite non corrispondono!",
  "form.userNameRequired": "Per favore inserisci il tuo nome",
  "form.validEmailRequired": "Per favore inserisci un indirizzo email valido.",
  "general.announceNewUsers": "Annuncia ai nuovi utenti",
  "general.announceNewUsersInfo": "Invia un messaggio di annuncio su Vacation Tracker ai nuovi utenti di Slack/Teams/Google Workspaces",
  "general.announceNewUsersInfoTooltip": "Quando questa casella è spuntata, ogni volta che aggiungi un nuovo utente alla tua organizzazione Slack/Teams/GW, riceveranno un breve messaggio da Vacation Tracker che spiega come funziona. Se questa casella non è spuntata, i nuovi utenti non riceveranno alcun messaggio di annuncio da Vacation Tracker.",
  "general.announceNewUsersTooltip": "Ogni volta che aggiungi un nuovo utente alla tua {platform}, riceveranno un breve messaggio da Vacation Tracker che spiega come funziona. Se scegli l'opzione Non inviare, i nuovi utenti non riceveranno alcun messaggio di annuncio da Vacation Tracker.",
  "general.calculationStartYear": "Vacation Tracker monitora le tue ferie da",
  "general.calculationStartYearTooltipLine1": "Devi modificare questa impostazione solo se vuoi importare ferie storiche da un altro sistema o fogli di calcolo e includere queste ferie nei calcoli di Vacation Tracker.",
  "general.calculationStartYearTooltipLine2": "Vacation Tracker calcola le ferie dall'anno in cui ti sei iscritto. Ciò significa che calcoleremo automaticamente i giorni rimanenti e riportati in base alle tue impostazioni, e potrai impostare i giorni riportati dal tuo sistema precedente per ogni utente nelle loro pagine del profilo. Tuttavia, se vuoi che Vacation Tracker calcoli le ferie per gli anni passati, puoi modificare questa data. Non esitare a <support>contattare il nostro supporto</support> per ulteriori informazioni.",
  "general.clickToSelectPlatform": "Clicca per selezionare la piattaforma",
  "general.companyId": "ID azienda:",
  "general.companyName": "Nome azienda",
  "general.confirmModalTitle": "Conferma aggiornamenti impostazioni aziendali",
  "general.connectSync": "Connetti sincronizzazione",
  "general.connectToAnotherPlatform": "Passa a un'altra piattaforma",
  "general.connectWith": "Passa a {platform}",
  "general.connectWithHelpdeskInfo": "Non sei sicuro quale piattaforma scegliere? Questo <link>articolo</link> potrebbe aiutarti.",
  "general.days": "Giorni",
  "general.deleteAccount": "Se desideri modificare o eliminare i tuoi dati, contatta il nostro team di supporto a <mail>hello@vacationtracker.io</mail>",
  "general.enableUserEndDate": "Abilita data di fine utente",
  "general.enableUserEndDateInfo": "Quando attivato, gli utenti con una data di fine non potranno inviare richieste di congedo oltre quella data, e li disattiveremo automaticamente.",
  "general.enableUserEndDateTooltip": "Quando la Data di Fine Utente è attivata, gli Utenti non potranno inviare richieste di congedo per il periodo successivo alla loro data di fine. Saranno automaticamente disattivati e non potranno accedere a Vacation Tracker.",
  "general.googleConsent": "Accesso Google",
  "general.googleConsentInfo": "Consenso Google",
  "general.googleMorePermission": "Google zeza sa imenima ako hocete da budete sigurni da imate puno ime i prezime treba nam jos permission-a, kliknite ovde da nam date jos permission-a",
  "general.hourlyLeaveAccounting": "Imposta quote di congedo in",
  "general.hourlyLeaveAccountingTooltip": "Quando attivata, questa opzione tiene traccia della durata di tutti i congedi e le quote in ore. Disponibile solo per il piano Complete.",
  "general.hours": "Ore",
  "general.importUsersAutomatically": "Importa utenti automaticamente",
  "general.importUsersAutomaticallyInfo": "Con l'importazione automatica degli utenti attivata, importeremo e attiveremo automaticamente tutti i nuovi utenti regolari da {platform}.",
  "general.importUsersAutomaticallyInfoTooltip": "Ad esempio, quando inviti un nuovo utente nel tuo spazio di lavoro, quell'utente sarà disponibile in Vacation Tracker pochi minuti dopo l'invito. Se vuoi attivare un utente ospite multicanale, dovrai invitarlo manualmente.",
  "general.importUsersAutomaticallyTurnOffInfo": "Non importeremo e attiveremo più automaticamente tutti i nuovi utenti regolari da {platform}.",
  "general.importUsersConfigSection": "Configurazione Importazione Utenti",
  "general.importUsersConfigSectionDescription": "Questa sezione permette di abilitare l'importazione automatica degli utenti dalla tua directory utenti e configurare le impostazioni di importazione degli utenti, come il messaggio di annuncio, il dipartimento predefinito e la posizione predefinita per i nuovi utenti.",
  "general.pleaseEnterCompanyName": "Inserisci il nome dell'azienda",
  "general.pleaseEnterContactEmail": "Inserisci l'email aziendale",
  "general.syncGoogleUsers": "Sincronizza utenti Google",
  "general.syncGoogleUsersInfo": "Sincronizza utenti Google cancellazione automatica, aggiornamento e aggiunta utenti",
  "general.updateInProgress": "Aggiornamento impostazioni aziendali",
  "general.updateSuccessTitle": "Impostazioni aziendali aggiornate",
  "general.usefulLinks": "<healthcheck>Controllo salute</healthcheck>, <helpdesk>Helpdesk</helpdesk>",
  "general.usefulLinksTitle": "Link utili: ",
  "holidays.filterByType": "Filtra per tipo di festività",
  "holidays.holidaysName": "Nome festività",
  "holidays.holidaysTab": "Festività per {year}",
  "holidays.holidaysType": "Tipo di festività",
  "holidays.importHolidays": "Importa festività",
  "holidays.importHolidaysAutomaticallyQuestion": "Vuoi importare automaticamente le festività selezionate per gli anni futuri?",
  "holidays.importHolidaysAutomaticallyTooltip": "Vacation Tracker regolerà automaticamente i giorni per le festività pubbliche che non cadono nella stessa data ogni anno, come il Ringraziamento, Pasqua ecc.",
  "holidays.importHolidaysAutomaticallyTooltipForMissingHolidays": "Purtroppo, questa funzionalità non è attualmente supportata per questo paese. Stiamo lavorando per espandere i nostri servizi, quindi restate sintonizzati per gli aggiornamenti.",
  "holidays.importHolidaysAutomaticallyTurnedOff": "Importazione automatica delle festività disattivata",
  "holidays.importHolidaysAutomaticallyTurnedOn": "Importazione automatica delle festività attivata",
  "holidays.importHolidaysCount": "Importa {count} festività selezionate",
  "holidays.importHolidaysFor": "Importa festività per <b>{year}</b> per <b>{countryState}</b>",
  "holidays.importHolidaysSummary": "Hai selezionato le seguenti festività per <b>{year}</b> per <b>{countryState}</b>",
  "holidays.pastHolidays": "Festività passate",
  "holidays.totalSelectedHolidays": "Hai selezionato {total} festività",
  "holidays.totalSelectedHolidaysTip": "({total} non visibili a causa del filtro applicato)",
  "holidays.typeBank": "Bancario",
  "holidays.typeObservance": "Commemorazione",
  "holidays.typeOptional": "Facoltativo",
  "holidays.typePublic": "Pubblico",
  "holidays.typeSchool": "Scolastico",
  "importLeaves.importFail": "Importazione fallita con il seguente messaggio di errore:",
  "importLeaves.importSuccess": "Hai inviato le assenze per l'importazione. Controlla tra qualche minuto per vedere se sono state importate con successo.",
  "importUsers.allUsersImported.description": "Sembra che tu abbia già importato tutti gli utenti dalla tua directory! Dovresti vedere tutti gli utenti nella pagina Utenti. Se non vedi qualcuno, dai un'occhiata alla scheda Inattivi. Non esitare a contattare il nostro supporto se hai domande o hai bisogno di assistenza.",
  "importUsers.allUsersImported.goToUsersPageButton": "Vai alla pagina Utenti",
  "importUsers.allUsersImported.title": "Tutti gli utenti sono già stati importati!",
  "importUsers.form.backButton": "Torna alla pagina Utenti",
  "importUsers.form.searchGoogleUsersPlaceholder": "Cerca utenti nel tuo Google Workspace",
  "importUsers.form.searchMicrosoftUsersPlaceholder": "Digita almeno un carattere per iniziare a cercare utenti nella tua Directory Attiva di Microsoft 365",
  "importUsers.form.searchSlackUsersPlaceholder": "Cerca utenti nel tuo Workspace Slack",
  "importUsers.form.searchUsersNotFound": "Utente non trovato o già importato",
  "importUsers.form.searchUsersPlaceholder": "Cerca utenti nella tua Directory Attiva di Microsoft 365",
  "importUsers.form.selectedUsersLabel": "Utenti selezionati ({selectedUsers})",
  "importUsers.form.showSelectedUsersAsNumber": "Utenti selezionati",
  "importUsers.statistics.imported": "Utenti importati in Vacation Tracker",
  "importUsers.statistics.total": "Totale utenti nella tua {directoryName}",
  "leaveRequest.halfDay": "Mezza giornata",
  "leaveRequest.hourly": "Orario",
  "leaveRequest.sent": "Richiesta di assenza inviata",
  "leaveRequest.simpleQuota.addAnotherLeave": "Aggiungi un'altra assenza",
  "leaveRequest.simpleQuota.info": "Ecco il tuo saldo ferie per {leaveTypeName} dal {start} al {end}.",
  "leaveRequest.simpleQuota.requestAnotherLeave": "Richiedi un'altra assenza",
  "leaveRequest.simpleQuota.summaryInfo": "Abbiamo inviato la seguente richiesta di approvazione al tuo responsabile",
  "leaveRequests.addedCurrentYear": "Aggiunto per l'anno corrente",
  "leaveRequests.addeddNextYear": "Aggiunto per l'anno prossimo",
  "leaveRequests.cancelThisAction": "Annulla questa azione",
  "leaveRequests.dates": "Data{plural}",
  "leaveRequests.daysRemainingForCurrentYear": "Giorni rimanenti per l'anno corrente",
  "leaveRequests.daysRemainingForNextYear": "Giorni rimanenti per l'anno prossimo",
  "leaveRequests.daysRequestedForCurrentYear": "Giorni richiesti per l'anno corrente",
  "leaveRequests.daysRequestedForNextYear": "Giorni richiesti per l'anno prossimo",
  "leaveRequests.denyWithReason": "Nega con motivazione",
  "leaveRequests.hoursRemainingForCurrentYear": "Ore rimanenti per l'anno corrente",
  "leaveRequests.hoursRemainingForNextYear": "Ore rimanenti per l'anno prossimo",
  "leaveRequests.hoursRequestedForCurrentYear": "Ore richieste per l'anno corrente",
  "leaveRequests.hoursRequestedForNextYear": "Ore richieste per l'anno prossimo",
  "leaveRequests.leaveRequestInfoTitle": "<strong>{name}</strong> ha inviato la seguente richiesta di congedo:",
  "leaveRequests.leaveRequestInfoTitleToil": "<strong>{name}</strong> ha inviato la seguente richiesta di TOIL:",
  "leaveRequests.leaveTypeName": "Tipo di Congedo",
  "leaveRequests.pending": "Richiesta in sospeso",
  "leaveRequests.reason": "Motivo",
  "leaveRequests.reasonForDenying": "Motivo del rifiuto",
  "leaveRequests.remainingCurrentYear": "Rimanente per l'anno corrente",
  "leaveRequests.remainingNextYear": "Rimanente per l'anno prossimo",
  "leaveRequests.requestedCurrentYear": "Richiesto per l'anno corrente",
  "leaveRequests.requestedNextYear": "Richiesto per l'anno prossimo",
  "leaveRequests.requestSummary": "Riepilogo della richiesta",
  "leaveRequests.wrongAction": "Questa azione non ci è nota...🤔 Per favore, prova un'altra! Sarai reindirizzato alla Dashboard tra 7 secondi.",
  "leaveRequests.wrongLeaveRequestId": "Ops! Questa richiesta di congedo non si trova da nessuna parte! 🔎 Sarai reindirizzato alla Dashboard tra 7 secondi.",
  "leaveRequestStatus.approved": "approvato",
  "leaveRequestStatus.APPROVED": "approvato",
  "leaveRequestStatus.cancelled": "annullato",
  "leaveRequestStatus.CANCELLED": "annullato",
  "leaveRequestStatus.deleted": "eliminato",
  "leaveRequestStatus.DELETED": "eliminato",
  "leaveRequestStatus.denied": "negato",
  "leaveRequestStatus.DENIED": "negato",
  "leaveRequestStatus.open": "aperto",
  "leaveRequestStatus.OPEN": "aperto",
  "leaveTypes.activatingLeaveType": "Attiva Tipo di Congedo",
  "leaveTypes.activatingLeaveTypeInfo": "Attivando questo Tipo di Congedo, stai ripristinando la sua precedente collocazione nelle Località, così come le sue impostazioni e dati passati.",
  "leaveTypes.activatingLeaveTypeTitle": "Attiva Tipo di Congedo",
  "leaveTypes.addLeaveTypeInfo": "Crea un Tipo di Congedo personalizzato aggiungendo un nome, un colore per una navigazione più facile e uno stato (Attivo/Inattivo). Non preoccuparti, potrai modificare tutte le impostazioni del Tipo di Congedo in seguito. \n\nPer assegnare una Politica di Congedo personalizzata a un Tipo di Congedo, visita Località -> Assegna Politica di Congedo.",
  "leaveTypes.addTypeToLocationButton": "Aggiungi",
  "leaveTypes.assignedToLocationsColumn": "Assegnato alle Località",
  "leaveTypes.assignedToLocationsColumnWarning": "Questo tipo di permesso non è assegnato a nessuna località.",
  "leaveTypes.color": "Colore",
  "leaveTypes.createInProgress": "Creazione del tipo di permesso {leaveTypeName}",
  "leaveTypes.createNewLeaveType": "Crea un Tipo di Permesso",
  "leaveTypes.deactivateLeaveType": "Disattiva Tipo di Permesso",
  "leaveTypes.deactivateLeaveTypeInfo": "Disattivando questo Tipo di Permesso, lo rimuoverai da tutte le Località che lo stanno attualmente utilizzando. Quando riattiverai il Tipo di Permesso, apparirà nelle stesse Località con le impostazioni e i dati esistenti.",
  "leaveTypes.deactivateLeaveTypeTitle": "Disattiva Tipo di Permesso",
  "leaveTypes.deleteInProgress": "Eliminazione del tipo di permesso {leaveTypeName}",
  "leaveTypes.deleteLeaveType": "Elimina Tipo di Permesso",
  "leaveTypes.deleteLeaveTypeConfirm": "Sei sicuro di voler eliminare il tipo di permesso {name}?",
  "leaveTypes.deleteLeaveTypeTitle": "Elimina Tipo di Permesso",
  "leaveTypes.editLeaveType": "Modifica Tipo di Permesso",
  "leaveTypes.isActive": "Attivo",
  "leaveTypes.sort": "Ordina",
  "leaveTypes.typeName": "Nome",
  "leaveTypes.updateInProgress": "Aggiornamento del tipo di permesso {leaveTypeName}",
  "location.createInProgress": "Creazione della località {locationName}",
  "location.deleteInProgress": "Eliminazione della località {locationName}",
  "location.editLocation": "Modifica località",
  "location.fiscalYearStart": "Inizio Anno Fiscale",
  "location.general": "Generale",
  "location.hasNoUsers": "La località non ha utenti.",
  "location.holidays": "Festività",
  "location.holidays.autoimportTitle": "Impostazioni di importazione automatica delle festività modificate",
  "location.holidays.brokenHolidays": "Questa festività non può essere aggiunta, contatta il supporto per risolvere il problema",
  "location.holidays.successTitle": "Le festività sono state aggiornate con successo",
  "location.holidays.updateInProgress": "Aggiornamento delle festività",
  "location.holidaysUpdated": "Festività aggiornate",
  "location.leavePolicies": "Politiche di Permesso",
  "location.leavePoliciesNotExistInfo": "Al momento non ci sono Politiche di Permesso disponibili. Si prega di assegnare una Politica di Permesso alla Località.",
  "location.name": "Nome",
  "location.never": "Mai",
  "location.resetQuotas": "Ripristino Quota Permessi Basato Su",
  "location.resetQuotasInfo": "Questa impostazione determinerà se il tuo saldo annuale di permessi si ripristinerà in base all'anno contabile (anno fiscale dell'azienda) o in base alla data di inizio del dipendente. Oltre alle quote, anche la tua politica di riporto sarà influenzata da questa impostazione.",
  "location.rolloverExpiryAfterDays": "Scadenza dei Giorni Riportati",
  "location.rolloverExpiryAfterHours": "Scadenza delle Ore Riportate",
  "location.rolloverExpiryDay": "I Giorni Riportati Dovrebbero Scadere?",
  "location.rolloverExpiryHour": "Le Ore Riportate Dovrebbero Scadere?",
  "location.rolloverExpiryOnDate": "I Giorni Riportati Scadono Il",
  "location.rolloverExpiryOnDate.extra": "I giorni scadranno alla fine della giornata.",
  "location.rolloverExpiryOnDateHours": "Le ore riportate scadono il",
  "location.rolloverExpiryOnDateHours.extra": "Le ore scadranno alla fine della giornata.",
  "location.startYear": "Inizio Anno Fiscale",
  "location.startYearInfo": "L'Anno Fiscale di un'organizzazione è un anno calcolato ai fini fiscali o contabili. Le date di reset del periodo di vacanza dipendono dall'inizio e dalla fine dell'anno fiscale dell'organizzazione.",
  "location.timezone": "Fuso orario",
  "location.timezoneDefault": "(GMT+00:00) GMT (senza ora legale)",
  "location.updateInProgress": "Aggiornamento della posizione {locationName}",
  "location.users": "Utente/i",
  "location.workWeek": "Settimana lavorativa",
  "locationForm.assignLeaveTypesInfo": "Una volta creata una Posizione, assegna una Politica di Congedo alla Posizione, per consentire agli Utenti di richiedere il Congedo. \nPer assegnare una Politica di Congedo, vai su Posizione -> Politiche di Congedo -> Assegna Politica di Congedo.",
  "locationForm.createdSuccessfully": "Per favore, concedici un momento, stiamo creando la tua Posizione.",
  "locationForm.createLocation": "Crea Posizione",
  "locationForm.editLocation": "Modifica Posizione",
  "locationForm.locationLimitReached": "Ops! Sembra che tu abbia raggiunto il limite di {limitNumber} posizioni per il tuo piano attuale. Per gestire o aggiornare il tuo piano, visita la <billingPage>pagina di Fatturazione</billingPage>.",
  "locationForm.updateLocation": "Aggiorna Posizione",
  "locations.automaticallyGeneratedName": "Abbiamo generato automaticamente questa posizione per te raggruppando i seguenti team: <t>{team}</t>.",
  "locations.automaticallyTransferredLeavePoliciesAndHolidays": "Le politiche di congedo e le festività sono state trasferite automaticamente per te, quindi <b>{not}</b> è necessario ricrearle.",
  "locations.created": "Creato",
  "locations.createNewLocation": "Crea una Posizione",
  "locations.default": "Predefinito",
  "locations.defaultLocation": "Posizione Predefinita",
  "locations.defaultLocationDescription": "Tutti i nuovi utenti verranno automaticamente aggiunti a questa posizione.",
  "locations.deleteLocation": "Elimina Posizione",
  "locations.deleteLocation.disabled": "Non puoi eliminare la posizione predefinita",
  "locations.deleteLocationConfirm": "Conferma che desideri eliminare la posizione {name}.",
  "locations.deleteLocationConfirmInitialRollover": "L'eliminazione di una posizione potrebbe influire sulla configurazione degli utenti e sulle informazioni sui congedi (ad esempio, i giorni inizialmente riportati).",
  "locations.deleteLocationTitle": "Elimina Posizione {name}",
  "locations.editLocation": "Modifica",
  "locations.hasNoUsers": "Nessuno",
  "locations.holidays": "Festività",
  "locations.leavePolicies": "Politiche di Congedo",
  "locations.leavePolicies.quotaDays": "{value} giorni",
  "locations.leavePolicies.quotaHours": "{value} ore",
  "locations.leavePolicies.yearlyQuota": "Quota Annuale di Congedo",
  "locations.locationLimitReached": "Stai attualmente utilizzando tutti i {limitNumber} slot di posizione disponibili nel piano CORE. Per gestire o aggiornare il tuo piano, visita la <billingPage>pagina di Fatturazione</billingPage>.",
  "locations.name": "Nome",
  "locations.pleaseRenameLocation": "Per favore, rinomina questa posizione!",
  "locations.pleaseRenameLocationName": "Per favore, rinomina i nomi delle posizioni generati automaticamente *",
  "locations.resetQuotas": "Reset della Quota di Congedo Basato Su",
  "locations.selectLocation": "Seleziona posizione",
  "locations.unableToNameThisLocation": "Tuttavia, il nostro sistema non è stato in grado di nominare correttamente questa posizione.",
  "locations.users": "Utente/i",
  "locations.viewHolidays": "Visualizza Festività",
  "locations.viewLeavePolicies": "Visualizza Politiche di Congedo",
  "locations.viewLocation": "Visualizza",
  "locations.weAutomaticallyCreatedLocations": "Abbiamo creato automaticamente le posizioni per la tua organizzazione raggruppando i tuoi team in base alle festività. Tutte le politiche di congedo sono state trasferite, devi solo rinominare le tue posizioni.",
  "manageMicrosoftLicenses.assignLicencesSuccessTitle": "Le licenze sono state assegnate con successo.",
  "manageMicrosoftLicenses.weAreAssignLicenses": "Stiamo assegnando le licenze.",
  "microsoft.adminConsentErrorButton": "Ottieni accesso",
  "microsoft.adminConsentErrorDescription1": "Sembra che tu non sia un amministratore della tua organizzazione Microsoft Teams. La configurazione delle notifiche richiede il consenso dell'amministratore. Si prega di inviare il seguente link all'amministratore della tua organizzazione per ottenere il consenso dell'amministratore: ",
  "microsoft.adminConsentErrorDescription2": "Quando ottieni il consenso dell'amministratore, clicca il pulsante qui sotto per ottenere l'accesso alle notifiche. Se sei un amministratore, visita lo stesso link, dai il consenso dell'amministratore e potrai configurare le notifiche.",
  "microsoft.adminConsentErrorTitle": "Richiesto il Consenso dell'Amministratore di Microsoft Teams.",
  "microsoft.createCompanyTeamLoginAdminConsentError1": "Sembra che tu non sia un amministratore della tua organizzazione Microsoft Teams. L'importazione dei membri del team da un singolo team di Microsoft Teams richiede il consenso dell'amministratore. Si prega di inviare il seguente link all'amministratore della tua organizzazione per ottenere il consenso dell'amministratore: ",
  "microsoft.createCompanyTeamLoginAdminConsentError2": "Quando ottieni il consenso dell'amministratore, puoi continuare con il processo di registrazione. Se sei un amministratore, visita lo stesso link, dai il consenso dell'amministratore e potrai continuare con la registrazione.",
  "microsoft.createCompanyTeamLoginRequiredDescription1": "Abbiamo bisogno di ulteriori permessi di Microsoft Teams per elencare gli utenti dal team che hai selezionato. Se clicchi sul pulsante OK, ti reindirizzeremo alla pagina di login di Microsoft. A seconda delle impostazioni del tuo browser, potresti essere già loggato e automaticamente reindirizzato indietro.",
  "microsoft.createCompanyTeamLoginRequiredDescription2": "Dopo aver effettuato l'accesso al tuo account Microsoft, ti riporteremo a questa pagina in modo che tu possa selezionare gli utenti da importare. Cliccando il pulsante Annulla potrai comunque registrarti con tutta la tua organizzazione.",
  "microsoft.createCompanyTeamLoginRequiredTitle": "Richiesto Permesso Aggiuntivo di Microsoft Teams.",
  "microsoft.manageLicenses.loginMicrosoft": "Accedi con il tuo account Microsoft 365 per assegnare le licenze.",
  "microsoft.manageLicenses.loginMicrosoftInfo": "L'accesso con il tuo account elencherà gli utenti nella tua Directory Attiva di Microsoft. Potrai assegnare le licenze acquistate e, opzionalmente, notificare gli utenti importati.",
  "microsoft.notificationsLoginRequiredDescription1": "La gestione delle notifiche richiede un token di Microsoft Teams. Se clicchi sul pulsante OK, ti reindirizzeremo alla pagina di login di Microsoft. A seconda delle impostazioni del tuo browser, potresti essere già loggato e automaticamente reindirizzato indietro.",
  "microsoft.notificationsLoginRequiredDescription2": "Dopo aver effettuato l'accesso al tuo account Microsoft, ti riporteremo a questa pagina in modo che tu possa gestire le notifiche. Cliccando il pulsante Annulla tornerai all'ultima pagina che hai visitato sulla nostra dashboard.",
  "microsoft.notificationsLoginRequiredTitle": "È richiesto l'accesso al tuo account Microsoft",
  "microsoft.universalLoginRequiredDescription": "Sembra che il tuo token sia scaduto. Effettua l'accesso al tuo account Microsoft Teams per procedere.",
  "microsoft.universalLoginRequiredTitle": "Token scaduto",
  "microsoft.usersLoginRequiredDescription1": "La gestione degli utenti richiede un token di Microsoft Teams. Se clicchi sul pulsante OK, ti reindirizzeremo alla pagina di login di Microsoft. A seconda delle impostazioni del tuo browser, potresti essere già loggato e automaticamente reindirizzato indietro.",
  "microsoft.usersLoginRequiredDescription2": "Dopo aver effettuato l'accesso al tuo account Microsoft, ti riporteremo a questa pagina in modo che tu possa gestire gli utenti.",
  "microsoft.usersLoginRequiredTitle": "È richiesto l'accesso al tuo account Microsoft",
  "microsoftSass.createCompany.alertError.companyAlreadyExistsDescription": "La tua azienda è già registrata, ma non sei attivo. Stai usando l'email corretta?\nIl tuo indirizzo email: {email}\nSe questa email è corretta, il tuo account non è attivato. Contatta l'amministratore della tua organizzazione Vacation Tracker:\n{admins}\nOppure contatta il nostro supporto se desideri registrarti come team indipendente separato nello stesso tenant.\nSe la tua email non è quella corretta, effettua l'accesso con un account Microsoft diverso o accedi da una finestra in incognito nel tuo browser.",
  "microsoftSass.createCompany.alertError.errorTitle": "Errore",
  "microsoftSass.createCompany.alertError.goToAdminCenter": "Vai al Centro di Amministrazione",
  "microsoftSass.createCompany.alertError.goToConnectPage": "Vai alla pagina di Connessione",
  "microsoftSass.createCompany.alertError.logInWithPlatform": "Accedi con {platform}",
  "microsoftSass.createCompany.alertError.resubscribedDescription": "Sei stato risottoscritto con successo come azienda - {companyName}.\n\nClicca il pulsante per essere reindirizzato alla pagina di accesso.",
  "microsoftSass.createCompany.alertError.resubscribedTitle": "Ti sei risottoscritto con successo",
  "microsoftSass.createCompany.alertError.tokenExpiredDescription": "Il tuo token di acquisto è scaduto. Vai al Centro di Amministrazione di Microsoft Teams e clicca sul pulsante \"Configura account\" per continuare.",
  "microsoftSass.createCompany.alertError.tokenExpiredTitle": "Token di acquisto scaduto",
  "microsoftSass.createCompany.alertError.tokenInvalidDescription": "Il tuo token di acquisto Microsoft non è valido. Se hai acquistato il piano Vacation Tracker attraverso il Microsoft Teams App Store, vai al Centro di Amministrazione di Microsoft Teams e clicca sul pulsante \"Configura account\" per continuare. Altrimenti, vai alla nostra pagina di connessione per accedere o creare un account.",
  "microsoftSass.createCompany.alertError.tokenInvalidTitle": "Token di acquisto non valido",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountDescription": "Hai già un account. La tua azienda ({companyName}) si è registrata utilizzando {platform}. Clicca il pulsante qui sotto per accedere utilizzando il tuo account {platform}.\n\nNon preoccuparti. Abbiamo annullato il tuo acquisto e non ti verrà addebitato nulla per le licenze che hai appena acquistato. La vecchia sottoscrizione per la tua azienda è ancora attiva. Puoi visitare il Centro di Amministrazione Microsoft per verificare che la tua nuova sottoscrizione sia stata annullata.\n\nSe desideri acquistare intenzionalmente un'altra sottoscrizione, contatta il nostro supporto.",
  "microsoftSass.createCompany.alertError.youAlreadyHaveAnAccountTitle": "Hai già un account",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicenses": "ASSEGNA LICENZE ACQUISTATE",
  "microsoftSass.createCompany.assignLicenses.assignPurchasedLicensesInfo": "Assegniamo le licenze che hai acquistato! Hai {totalLicenses} licenze. Assegnale agli utenti che potranno utilizzare Vacation Tracker.",
  "microsoftSass.createCompany.assignLicenses.buyMoreLicensesLater": "Puoi acquistare altre licenze in seguito nella pagina Utenti del dashboard di Vacation Tracker.",
  "microsoftSass.createCompany.assignLicenses.loginMicrosoftDontWorry": "Non preoccuparti! Non importeremo né notificheremo nessuno senza la tua approvazione.",
  "microsoftSass.createCompany.completed": "Completato",
  "microsoftSass.createCompany.createCompanySetupInfo": "Impostiamo l'email di contatto e le impostazioni predefinite per la tua organizzazione Vacation Tracker.",
  "microsoftSass.createCompany.createCompanyTitle": "COMPLETA LA CONFIGURAZIONE DELL'AZIENDA",
  "microsoftSass.createCompany.finishFristStep": "Completa la configurazione dell'azienda per procedere.",
  "microsoftSass.createCompany.finishSecondStep": "Assegna le licenze per procedere.",
  "microsoftSass.createCompany.finishSetup": "Termina configurazione",
  "microsoftSass.createCompany.help": "Se hai bisogno di assistenza, visita il nostro <helpdesk>Helpdesk</helpdesk> o <support>contatta il nostro supporto</support>.",
  "microsoftSass.createCompany.importAllUsers": "Assegnato a tutti gli utenti",
  "microsoftSass.createCompany.letsCompleteSetup": "Completiamo la configurazione, assegniamo le licenze ai tuoi utenti e installiamo l'integrazione di Vacation Tracker per Microsoft Teams.",
  "microsoftSass.createCompany.numberOfLicensesAssigned": "{assignedLicenses} licenze assegnate",
  "microsoftSass.createCompany.setupMsBot.areYouAdmin": "Sei l'amministratore della tua integrazione Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLogin": "Sembra che tu non abbia concesso i due permessi aggiuntivi di Microsoft Teams necessari per configurare il bot e le schede dei canali. Senza questo passaggio, non saremo in grado di inviare un messaggio di benvenuto ai membri del team selezionati e non potrai utilizzare l'app Vacation Tracker dalla tua applicazione Microsoft Teams.",
  "microsoftSass.createCompany.setupMsBot.errorExtedScopeLoginSkip": "Puoi saltare questo passaggio e installare il bot e le schede manualmente. Consulta il nostro Helpdesk per una guida passo-passo.",
  "microsoftSass.createCompany.setupMsBot.installBotAndTabs": "Installa bot e schede",
  "microsoftSass.createCompany.setupMsBot.installManuallyBotAdnTabs": "Installerò manualmente bot e schede",
  "microsoftSass.createCompany.setupMsBot.microsoftTeamsLogin": "Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.morePermissionInfo": "Abbiamo bisogno di autorizzazioni aggiuntive per installare il chatbot e le schede di Vacation Tracker nel tuo Microsoft Teams. Ti reindirizzeremo alla schermata delle autorizzazioni di Microsoft quando fai clic sul pulsante Procedi qui sotto.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyDescription": "Installa manualmente il bot e le schede di Vacation Tracker per Microsoft Teams seguendo i passaggi descritti in <helpdesk>questo articolo dell'Helpdesk</helpdesk>. Non dovresti aver bisogno di privilegi di amministratore per l'installazione manuale. Non dovresti aver bisogno di assistenza amministrativa per l'installazione manuale. Se hai bisogno di assistenza, contatta il <support>nostro supporto</support>.",
  "microsoftSass.createCompany.setupMsBot.notAdmin.installBotManuallyTitle": "Devi installare manualmente l'integrazione Microsoft",
  "microsoftSass.createCompany.setupMsBot.notAdmin.weNeedAdminConsent": "Abbiamo bisogno del consenso dell'amministratore per configurare il bot e le schede. Non possiamo inviare messaggi di benvenuto finché non installi il bot.",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabs": "Seleziona il canale Microsoft Teams in cui vuoi installare le schede",
  "microsoftSass.createCompany.setupMsBot.selectMSChannelToInstallTabsPlaceholder": "Seleziona il canale Microsoft Teams",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBot": "Seleziona il team Microsoft Teams in cui vuoi installare il bot",
  "microsoftSass.createCompany.setupMsBot.selectMSTeamsToInstallBotPlaceholder": "Seleziona il team Microsoft Teams",
  "microsoftSass.createCompany.setupMsBotAndTabs": "CONFIGURA BOT E SCHEDE DI VACATION TRACKER",
  "microsoftSass.createCompany.setupMsBotAndTabsInfo": "Installiamo il bot e le schede di Vacation Tracker per Microsoft Teams!",
  "microsoftSass.createCompany.stepAssignLicenses": "2. Assegna licenze",
  "microsoftSass.createCompany.stepCreateComapny": "1. Completa la configurazione dell'azienda",
  "microsoftSass.createCompany.stepSetupBotAndTabs": "3. Configura il bot e le schede di Microsoft Teams",
  "microsoftSass.createCompany.welcomeMessage": "Benvenuto in Vacation Tracker! Grazie per aver acquistato <bold>{totalLicenses} licenze</bold>.",
  "microsoftSass.createCompany.welcomeTitle": "CONFIGURIAMO VACATION TRACKER",
  "microsoftSubscription.adminInitialContent": "Il tuo abbonamento è scaduto. Vai nel Microsoft AppSource store per riattivare l'abbonamento. Se hai domande o hai bisogno di aiuto, contatta il nostro supporto.",
  "microsoftSubscription.goToAppSource": "Acquista un nuovo abbonamento",
  "modal.dontHaveAnAccount.accountNotFound": "Account non trovato",
  "modal.dontHaveAnAccount.asCompany": "Come azienda",
  "modal.dontHaveAnAccount.asCompanyDescription": "Sei un amministratore o un proprietario d'azienda che vuole provare Vacation Tracker.",
  "modal.dontHaveAnAccount.asEmployee": "Come dipendente",
  "modal.dontHaveAnAccount.asEmployeeDescription": "La tua azienda utilizza Vacation Tracker, ma tu non hai accesso.",
  "modal.dontHaveAnAccount.employeeSignUp": "Registrazione dipendente",
  "modal.dontHaveAnAccount.employeeSignupDescription": "Se la tua azienda sta già utilizzando Vacation Tracker, non hai bisogno di registrarti! Contatta il tuo amministratore per attivare il tuo account e avrai pieno accesso alla dashboard e alle integrazioni di Vacation Tracker.",
  "modal.dontHaveAnAccount.employeeSignupSupport": "Se hai bisogno di assistenza o non sei sicuro di chi sia il tuo amministratore, non esitare a contattare il nostro supporto.",
  "modal.dontHaveAnAccount.introParagraph": "Sembra che tu non abbia un account. Come vuoi utilizzare Vacation Tracker?",
  "modal.forgotPassword.step2Intro": "Abbiamo inviato un codice alla tua email. Inseriscilo qui sotto e imposta una nuova password. Se non hai ricevuto un'email entro 5 minuti, controlla la cartella spam e verifica di aver inserito l'indirizzo email corretto.",
  "myProfile.cancel": "Annulla",
  "myProfile.cancelLeave": "Annulla",
  "myProfile.cancelLeaveConfirmTitle": "Annulla Ferie",
  "myProfile.dontHaveAnyHistory": "{name} non ha ancora preso alcun periodo di ferie.",
  "myProfile.edit.title": "Modifica Il Mio Profilo",
  "myProfile.editLeave": "Modifica Ferie",
  "myProfile.editLeaveWord": "Modifica",
  "myProfile.general": "Generale",
  "myProfile.history": "Cronologia",
  "myProfile.historyLeaves": "Cronologia",
  "myProfile.leaveHistory": "Cronologia Ferie",
  "myProfile.leaveTypes": "Tipi di Ferie",
  "myProfile.noUpcomingLeaves": "Nessuna Feria Programmata",
  "myProfile.openMyProfile": "Visualizza Il Mio Profilo",
  "myProfile.upcomingLeaves": "Ferie Programmate",
  "myProfile.userWorkWeek": "Settimana Lavorativa",
  "not_in_channel": "Il canale selezionato è stato eliminato o non è più disponibile. Seleziona un altro canale per ricevere le notifiche.",
  "notification.codeResent": "Codice di verifica reinviato",
  "notification.codeResentMessage": "Per favore controlla la tua email",
  "notification.passwordReset": "Password reimpostata con successo",
  "notification.passwordResetMessage": "Ora puoi accedere utilizzando la tua nuova password.",
  "notifications.add": "Nuova Notifica",
  "notifications.cantOpenNoptification": "Questa notifica è stata creata da un altro utente e non puoi modificarla.",
  "notifications.createDailyRequestSubmitted": "Impostazione notifiche giornaliere",
  "notifications.createWeeklyRequestSubmitted": "Impostazione notifiche settimanali",
  "notifications.deleteAutomationConfirm": "Conferma che desideri eliminare l'automazione <strong>{name}</strong>.",
  "notifications.deleteAutomationTitle": "Elimina automazione",
  "notifications.deleteDailyRequestSubmitted": "Eliminazione notifiche giornaliere",
  "notifications.deleteEntitlementByRoleAutomationConfirm": "Sei sicuro di voler eliminare questa automazione di diritto per ruolo? Non puoi annullare questa azione. Tuttavia, l'eliminazione dell'automazione non influirà sui giorni di {leaveTypeName} già assegnati.",
  "notifications.deleteNotificationConfirm": "Conferma che desideri eliminare la notifica <strong>{name}</strong>.",
  "notifications.deleteNotificationOk": "Elimina",
  "notifications.deleteNotificationTitle": "Elimina notifica",
  "notifications.deleteSeniorityEntitlementAutomationConfirm": "Sei sicuro di voler eliminare questa automazione di diritto per anzianità? Non puoi annullare questa azione. Tuttavia, l'eliminazione dell'automazione non influirà sui giorni di {leaveTypeName} già assegnati.",
  "notifications.deleteWeeklyRequestSubmitted": "Eliminazione delle notifiche settimanali in corso",
  "notifications.emptyViewButton": "Crea una notifica giornaliera o settimanale",
  "notifications.emptyViewMessage": "inviando notifiche automatiche sulle prossime assenze",
  "notifications.emptyViewTitle": "Aumenta la visibilità e mantieni informata la tua squadra",
  "notifications.emptyViewVideoLink": "Per maggiori informazioni, visita il nostro <link>Helpdesk</link> o guarda il video qui sotto:",
  "notifications.error": "Se il problema persiste, contatta il supporto e fornisci loro il seguente codice di errore: {correlationId}.",
  "notifications.frequency": "Frequenza",
  "notifications.handleSubmitErrorTitle": "Errore di Notifica",
  "notifications.microsoftTokenMissing": "Non siamo in grado di impostare le notifiche perché manca il token Microsoft. Per favore, esci e accedi di nuovo per ricevere il token Microsoft, e riprova.",
  "notifications.notificationInfo": "Se desideri impostare le Notifiche per un dipartimento specifico, vai alla pagina Dipartimenti e modifica il dipartimento per cui vuoi impostare le Notifiche.",
  "notifications.notificationSendNow": "La tua notifica è in arrivo! 🚀",
  "notifications.notificationSendNowFailed": "Consegna Fallita",
  "notifications.notificationSendNowFailedInfo": "Oh no! 😕 Qualcosa è andato storto. ",
  "notifications.notificationTitle": "Notifiche",
  "notifications.passwordChangedDescription": "Hai cambiato la password con successo, per favore accedi",
  "notifications.passwordChangedTitle": "Password cambiata",
  "notifications.sendNotificationNowCofirmModalDesc": "La notifica <strong>{name}</strong> non è attiva. Conferma se vuoi inviarla comunque ora.",
  "notifications.sendNotificationNowCofirmModalTitle": "Invia notifica",
  "notifications.sendNotificationNowOk": "Invia",
  "notifications.table.actions": "Azioni",
  "notifications.table.active": "Attivo",
  "notifications.table.DAILY": "Giornaliero",
  "notifications.table.frequency": "Frequenza",
  "notifications.table.name": "Nome",
  "notifications.table.owner": "Proprietario",
  "notifications.table.scheduledTime": "Orario Programmato",
  "notifications.table.sendNotificationNow": "Invia Ora",
  "notifications.table.WEEKLY": "Settimanale",
  "notifications.title": "Notifiche",
  "notifications.tooltipInfo": "Tutte le tue Notifiche sono raccolte in questa pagina per una gestione più facile. Per saperne di più, consulta il nostro <helpDesk>articolo dell'Helpdesk.</helpDesk>",
  "notifications.update": "Aggiorna Notifica",
  "notifications.updateAutomationConfirm": "Disabilitare un'automazione non annullerà l'abbonamento. Se vuoi disattivare l'abbonamento, devi accedere all'elenco delle automazioni cliccando su \"Crea Automazione\" e poi cliccare sul pulsante \"Annulla abbonamento\"",
  "notifications.updateAutomationTitle": "Aggiorna automazione",
  "notifications.updateNotification": "Aggiorna",
  "notifications.weekStartsOn": "La Settimana Inizia il",
  "notificationsForm.advancedFilter.tooltipInfo": "Se non viene selezionato alcun Filtro, la Notifica includerà tutti gli Utenti.",
  "notificationsForm.createInProgress": "Creazione della notifica {notificationName} in corso",
  "notificationsForm.currentWeek": "Settimana corrente",
  "notificationsForm.DAILY": "Giornaliero",
  "notificationsForm.deleteFailed": "Eliminazione fallita",
  "notificationsForm.deleteInProgress": "Eliminazione della notifica {notificationName} in corso",
  "notificationsForm.frequency": "Frequenza",
  "notificationsForm.groupBy": "Raggruppa permessi per",
  "notificationsForm.groupBy.tooltip": "I permessi possono essere visualizzati in base alle tue preferenze. Possono essere ordinati per Posizione degli utenti, Dipartimento o Alfabeticamente (Nessuno).",
  "notificationsForm.locale": "Lingua",
  "notificationsForm.LOCATION": "Posizione",
  "notificationsForm.name": "Nome della notifica",
  "notificationsForm.newNotificationTitle": "Nuova notifica",
  "notificationsForm.nextWeek": "Prossima settimana",
  "notificationsForm.NONE": "Nessuno",
  "notificationsForm.periodCovered": "Periodo coperto",
  "notificationsForm.saveFailed": "Salvataggio fallito",
  "notificationsForm.selectChannel": "Seleziona canale",
  "notificationsForm.sendEmpty": "Se non ci sono permessi imminenti",
  "notificationsForm.sendEmptyNo": "No, non inviare la notifica",
  "notificationsForm.sendEmptyYes": "Sì, invia la notifica",
  "notificationsForm.sendOnDays": "Invia il",
  "notificationsForm.sendOnDaysInfo": "La notifica verrà inviata nei giorni selezionati.",
  "notificationsForm.submit": "Salva notifica",
  "notificationsForm.TEAM": "Dipartimento",
  "notificationsForm.time": "Ora",
  "notificationsForm.timezone": "Fuso orario",
  "notificationsForm.update": "Aggiorna notifica",
  "notificationsForm.updateInProgress": "Aggiornamento della notifica {notificationName} in corso",
  "notificationsForm.updateNotificationTitle": "Aggiorna notifica",
  "notificationsForm.WEEKLY": "Settimanale",
  "notificationsForm.weekStartsOn": "La settimana inizia il",
  "overlapInfo.moreUsersAreOnLeaves": "ℹ️ Sembra che {overlapperNames} saranno in permesso durante lo stesso periodo.",
  "overlapInfo.youCanSafelyAcceptTheRequest": "Puoi accettare tranquillamente la richiesta, nessun altro di {teamName} è in permesso durante questo periodo.",
  "overlapInfo.youCanSafelyAcceptTheRequestAutoApproved": "Nessun altro di {teamName} è in permesso durante questo periodo.",
  "page404.message": "La pagina che stai cercando si sta godendo un meritato riposo.",
  "page404.title": "Pagina in vacanza.",
  "quickTour.admin.buttonTitle": "Tour rapido",
  "quickTour.buttons.back": "Indietro",
  "quickTour.buttons.close": "Chiudi",
  "quickTour.buttons.last": "Chiudi",
  "quickTour.buttons.next": "Avanti",
  "quickTour.buttons.skip": "Salta",
  "registration.flowB.progress": "Passaggio {current} di {total}",
  "registration.flowB.step1.list1": "<b>Prova gratuita di 7 giorni</b>, tutte le funzionalità incluse",
  "registration.flowB.step1.list2": "Nessuna carta di credito richiesta durante la prova",
  "registration.flowB.step1.list3": "Integrazione con il tuo strumento di collaborazione",
  "registration.flowB.step1.submitButton": "Inizia",
  "registration.flowB.step1.subtitle": "Qual è l'indirizzo email della tua azienda?",
  "registration.flowB.step1.title": "Unisciti a migliaia di team in tutto il mondo che semplificano il monitoraggio delle ferie con Vacation Tracker.",
  "registration.flowB.step2.submitButton": "Crea Account",
  "registration.flowB.step2.subtitle": "Benvenuto in Vacation Tracker",
  "registration.flowB.step3.continueWithEmailPlatform": "Continua con l'email di lavoro",
  "registration.flowB.step3.continueWithPlatform": "Continua con {platform}",
  "registration.flowB.step3.list1": "Gestisci richieste di ferie",
  "registration.flowB.step3.list10": "e altro ancora!",
  "registration.flowB.step3.list2": "Invia notifiche di ferie",
  "registration.flowB.step3.list3": "Personalizza tipi di ferie",
  "registration.flowB.step3.list4": "Crea sedi e dipartimenti",
  "registration.flowB.step3.list5": "Genera report",
  "registration.flowB.step3.list6": "Imposta accumuli di ferie",
  "registration.flowB.step3.list7": "Raggruppa membri del team per etichette",
  "registration.flowB.step3.list8": "Pianifica report",
  "registration.flowB.step3.list9": "Richiedi ferie orarie",
  "registration.flowB.step3.notice": "Non comunicheremo con il tuo team in alcun modo finché non sarai pronto.",
  "registration.flowB.step3.submitButton": "ULTIMO PASSAGGIO",
  "registration.flowB.step3.subtitle": "Con quale strumento vorresti integrare, {name}?",
  "registration.flowB.step3.title": "Prova del piano completo di 7 giorni",
  "registration.flowB.step3.title2": "Riceverai una <b>prova gratuita</b> del nostro <b>piano Completo</b>, che include:",
  "registration.flowB.testimonial1.profession": "Produttore",
  "registration.flowB.testimonial1.title": "\"La facilità con cui tutti sono passati all'uso di Vacation Tracker senza fare un milione di domande è stata impressionante!\"",
  "registration.flowB.testimonial2.profession": "CEO",
  "registration.flowB.testimonial2.title": "\"Molto semplice da configurare, rende super semplice richiedere e approvare giorni di ferie e il team è molto reattivo e prende sul serio i feedback.\"",
  "registration.flowB.testimonial3.profession": "Direttore dei Servizi Amministrativi",
  "registration.flowB.testimonial3.title": "\"Ora posso concentrarmi sul mio personale e su molte altre cose - Vacation Tracker mi ha restituito almeno 10 ore a settimana più numerose ore ai nostri manager e dipendenti perché è tutto elettronico.\"",
  "requestLeave.handleSubmitErrorTitle": "Errore nell'invio della richiesta di ferie",
  "requestLeave.inProgress": "Richiesta di ferie in corso",
  "requestLeave.requestLeave": "Richiedi ferie",
  "requestLeave.title": "Ferie richieste",
  "scheduledReportForm.addNewEmail": "Aggiungi destinatario",
  "scheduledReportForm.approved": "Approvato",
  "scheduledReportForm.createAScheduledReport": "Nuovo Report",
  "scheduledReportForm.createInProgress": "Creazione del report programmato {scheduledReportName}",
  "scheduledReportForm.deleteFailed": "Eliminazione fallita",
  "scheduledReportForm.deleteInProgress": "Eliminazione del report programmato {scheduledReportName}",
  "scheduledReportForm.deleteReport": "Elimina Rapporto",
  "scheduledReportForm.deleteScheduledReport": "Elimina rapporto programmato",
  "scheduledReportForm.deleteScheduledReportConfirm": "Conferma che vuoi eliminare il rapporto programmato {name}.",
  "scheduledReportForm.deleteScheduledReportTitle": "Elimina il rapporto programmato {name}",
  "scheduledReportForm.denied": "Rifiutato",
  "scheduledReportForm.emails": "Destinatari",
  "scheduledReportForm.lastMonth": "Mese scorso",
  "scheduledReportForm.leaveBalanceReport": "Rapporto Saldo Ferie",
  "scheduledReportForm.leaveHistoryReport": "Rapporto Richieste Ferie",
  "scheduledReportForm.leaveStatus": "Stato Ferie",
  "scheduledReportForm.locations": "Località",
  "scheduledReportForm.monthlyLeaveBalanceReport": "Rapporto Mensile Saldo Ferie",
  "scheduledReportForm.period": "Periodo di Riferimento",
  "scheduledReportForm.periodInfo": "Mese Scorso",
  "scheduledReportForm.periodInfoTootltip": "Il rapporto è attualmente limitato al mese precedente. Per più opzioni, invia un'email a hello@vacationtracker.io e ci assicureremo di prendere nota della tua richiesta per possibili miglioramenti futuri.",
  "scheduledReportForm.pleaseInputEmailOrDeleteThisField": "Inserisci un'email valida o elimina questo campo.",
  "scheduledReportForm.pleaseInputValidEmail": "Inserisci un'email valida.",
  "scheduledReportForm.reportName": "Nome",
  "scheduledReportForm.reportSummary": "Riepilogo Rapporto",
  "scheduledReportForm.reportSummaryInfo": "Periodo di Riferimento: {from} - {to}\nTipo di Rapporto: {reportType}\nIl prossimo rapporto sarà inviato il {dateReport} alle {timeReport}",
  "scheduledReportForm.reportSummaryInfoMonthlyBalance": "Periodo di Riferimento: Prossimi 12 mesi. \nTipo di Rapporto: {reportType}\nIl prossimo rapporto sarà inviato il {dateReport} alle {timeReport}",
  "scheduledReportForm.reportType": "Tipo di Rapporto",
  "scheduledReportForm.required": "Questo campo è obbligatorio.",
  "scheduledReportForm.saveFailed": "Salvataggio fallito",
  "scheduledReportForm.saveReport": "Programma Rapporto",
  "scheduledReportForm.sendDayOfMonth": "Data/Giorno di Consegna",
  "scheduledReportForm.sendDayOfMonthInfo": "Configura le tue preferenze di consegna del rapporto, specificando il giorno esatto in cui il rapporto sarà inviato ai destinatari assegnati.",
  "scheduledReportForm.sendHourAndMinute": "Ora di Consegna",
  "scheduledReportForm.sendHourAndMinuteInfo": "Configura le tue preferenze di consegna del rapporto, specificando l'ora esatta del giorno in cui il rapporto sarà inviato ai destinatari assegnati.",
  "scheduledReportForm.sendReportFailed": "Consegna Fallita",
  "scheduledReportForm.sendReportFailedInfo": "Oh no! 😕 Qualcosa è andato storto.",
  "scheduledReportForm.sendReportNow": "Invia Ora",
  "scheduledReportForm.sendReportSuccessfully": "Il tuo rapporto è in arrivo! 🚀",
  "scheduledReportForm.teams": "Dipartimento(i)",
  "scheduledReportForm.timezone": "Fuso Orario",
  "scheduledReportForm.updateAScheduledReport": "Aggiorna Rapporto",
  "scheduledReportForm.updateInProgress": "Aggiornamento del rapporto programmato {scheduledReportName} in corso",
  "scheduledReportForm.updateReport": "Aggiorna Rapporto",
  "scheduledReports.add": "Nuovo Rapporto",
  "scheduledReports.allLocations": "Tutte le Sedi",
  "scheduledReports.allTeams": "Tutti i Dipartimenti",
  "scheduledReports.createScheduledReports": "Nuovo Report",
  "scheduledReports.deleteFailed": "Eliminazione fallita",
  "scheduledReports.deleteInProgress": "Eliminazione del report programmato {scheduledReportName} in corso",
  "scheduledReports.deleteReportConfirm": "Eliminare il report {name}?",
  "scheduledReports.deleteReportOkText": "Elimina",
  "scheduledReports.deleteReportTitle": "Conferma Eliminazione",
  "scheduledReports.deliveryDate": "Data di Consegna",
  "scheduledReports.edit": "Modifica",
  "scheduledReports.lastMonth": "Mese scorso",
  "scheduledReports.leaveBalanceReport": "Report Saldo Ferie",
  "scheduledReports.leaveHistoryReport": "Report Richieste Ferie",
  "scheduledReports.locations": "Sede/i",
  "scheduledReports.monthlyLeaveBalanceReport": "Report Mensile Saldo Ferie",
  "scheduledReports.name": "Nome",
  "scheduledReports.period": "Periodo di Riferimento",
  "scheduledReports.receivers": "Destinatari",
  "scheduledReports.reportSentSuccessfully": "Il tuo report è in arrivo! 🚀",
  "scheduledReports.reportType": "Tipo di Report",
  "scheduledReports.sendReportFailed": "Consegna Fallita",
  "scheduledReports.sendReportFailedInfo": "Oh no! 😕 Qualcosa è andato storto. ",
  "scheduledReports.sendReportNow": "Invia Ora",
  "scheduledReports.sendReportSuccessfully": "Il tuo report è in arrivo! 🚀",
  "scheduledReports.teams": "Dipartimento/i",
  "sidebar.addLeave": "Aggiungi Ferie",
  "sidebar.addons": "Componenti Aggiuntivi",
  "sidebar.billing": "Fatturazione",
  "sidebar.calendar": "Calendario",
  "sidebar.changelog": "Registro Modifiche",
  "sidebar.dashboard": "Dashboard",
  "sidebar.export": "Esporta",
  "sidebar.faq": "Assistenza",
  "sidebar.general": "Generale",
  "sidebar.holidays": "Festività",
  "sidebar.leaves": "Richieste di Ferie",
  "sidebar.leaveTypes": "Tipi di Ferie",
  "sidebar.location": "Sede",
  "sidebar.locations": "Sedi",
  "sidebar.main": "Principale",
  "sidebar.myProfile": "Il mio profilo",
  "sidebar.notifications": "Notifiche",
  "sidebar.onboarding": "Inserimento",
  "sidebar.reports": "Rapporti",
  "sidebar.requestLeave": "Richiedi permesso",
  "sidebar.scheduledReports": "Rapporti programmati",
  "sidebar.settings": "Impostazioni",
  "sidebar.teams": "Dipartimenti",
  "sidebar.user": "Utente",
  "sidebar.users": "Utenti",
  "signInAsUser.codeIsRequired": "È richiesto un codice",
  "signInAsUser.email": "Inserisci il tuo indirizzo email",
  "signInAsUser.emailIsRequired": "È richiesto un indirizzo email valido",
  "signInAsUser.enterTheCode": "Inserisci il codice",
  "signInAsUser.userId": "Inserisci l'ID utente",
  "signInAsUser.userIdIsRequired": "È richiesto un ID utente",
  "slack.notificationsLoginRequiredDescription1": "La gestione delle notifiche richiede le autorizzazioni di Slack. Se fai clic sul pulsante OK, verrai reindirizzato alla pagina di accesso di Slack. A seconda delle impostazioni del tuo browser, potresti essere già connesso e automaticamente reindirizzato indietro.",
  "slack.notificationsLoginRequiredDescription2": "Dopo aver effettuato l'accesso al tuo account Slack, ti riporteremo a questa pagina in modo che tu possa gestire le notifiche. Facendo clic sul pulsante Annulla tornerai all'ultima pagina che hai visitato sulla nostra dashboard.",
  "slack.notificationsLoginRequiredTitle": "È richiesto l'accesso al tuo account Slack",
  "slack.usersLoginRequiredDescription1": "La gestione degli utenti richiede le autorizzazioni di Slack. Se fai clic sul pulsante OK, verrai reindirizzato alla pagina di accesso di Slack. A seconda delle impostazioni del tuo browser, potresti essere già connesso e automaticamente reindirizzato indietro.",
  "slack.usersLoginRequiredDescription2": "Dopo aver effettuato l'accesso al tuo account Slack, ti riporteremo a questa pagina in modo che tu possa gestire gli utenti.",
  "slack.usersLoginRequiredTitle": "È richiesto l'accesso al tuo account Slack",
  "stripe.dynamicMessage": "{message}",
  "subscription.annual.info": "Attiverai il Piano {plan} {period} - ${price}",
  "subscription.hidePlansButton": "Nascondi piani",
  "subscription.logout": "Esci",
  "subscription.monthly.info": "Attiverai il Piano {plan} {period} - ${usersPrice} ({noOfActiveUsers} utenti x {dollarValue})",
  "subscription.monthly.infoUnderUserPLanLimit": "Attiverai il Piano {plan} {period} - ${usersPrice}",
  "subscription.showPlansButton": "Mostra piani",
  "subscription.stripeCustomerPortalLink": "Clicca qui per visitare il portale clienti",
  "subscription.subscribe": "Abbonati",
  "subscription.title": "Abbonamento",
  "subscription.updateInProgress": "Tentativo di pagamento in corso",
  "subscriptions.switchPlanTitle": "Passa al piano <strong>{plan}</strong>",
  "surveyAboutUs.description": "Puoi dirci come hai sentito parlare di noi? Ci piacerebbe sapere di più sul tuo percorso verso Vacation Tracker. Questa è una domanda facoltativa, ma ci aiuterebbe molto. Grazie!",
  "surveyAboutUs.OptionQuestion1-1": "Ricerca Google",
  "surveyAboutUs.OptionQuestion1-1-1": "Cosa hai cercato su Google (ad esempio \"tracciamento ferie\")?",
  "surveyAboutUs.OptionQuestion1-2": "App Store (Slack - App Directory, MS Teams - App Source, ecc...)",
  "surveyAboutUs.OptionQuestion1-3": "Social Media, Piattaforma di Recensioni o Altro Sito Web",
  "surveyAboutUs.OptionQuestion1-3-1": "Quale piattaforma o sito?",
  "surveyAboutUs.OptionQuestion1-4": "Raccomandazione",
  "surveyAboutUs.OptionQuestion1-4-1": "Fantastico! Chi ci ha raccomandato?",
  "surveyAboutUs.OptionQuestion1-4-1-1": "Un collega o un amico",
  "surveyAboutUs.OptionQuestion1-4-1-2": "Un'altra azienda l'ha suggerito",
  "surveyAboutUs.OptionQuestion1-4-1-3": "L'ho usato in passato",
  "surveyAboutUs.OptionQuestion1-5": "Altro",
  "surveyAboutUs.Question1": "Come hai scoperto Vacation Tracker?",
  "surveyAboutUs.textarea": "Per favore, specifica",
  "surveyAboutUs.title": "Come hai scoperto Vacation Tracker?",
  "team.approvers": "Approvatore/i",
  "team.createInProgress": "Creazione del reparto {teamName} in corso",
  "team.editTeam": "Modifica Reparto",
  "team.hasNoUsers": "Il reparto non ha utenti.",
  "team.name": "Nome",
  "team.team": "Reparto",
  "team.updateInProgress": "Aggiornamento del reparto {teamName} in corso",
  "team.users": "Utente/i",
  "teamForm.createTeam": "Crea Reparto",
  "teamForm.createTeamError": "Oh no! 😕 Qualcosa è andato storto.",
  "teamForm.editTeam": "Modifica Reparto",
  "teamForm.name": "Nome",
  "teamForm.pleaseEnterTeamName": "Inserisci un nome per il reparto",
  "teamForm.setAsSubdepartment": "Scegli il reparto principale",
  "teamForm.teamLimitReached": "Ops! Hai raggiunto il limite di {limitNumber} reparti del tuo piano attuale. Per gestire o aggiornare il tuo piano, visita la <billingPage>pagina di fatturazione</billingPage>.",
  "teamForm.updateTeam": "Aggiorna Reparto",
  "teams.approvers": "Approvatore/i",
  "teams.created": "Creato",
  "teams.createNewTeam": "Crea Nuovo Reparto",
  "teams.defaultTeam": "Tutti i nuovi utenti verranno automaticamente aggiunti a questo reparto.",
  "teams.deleteInProgress": "Eliminazione del reparto {teamName} in corso",
  "teams.deleteTeam": "Elimina",
  "teams.deleteTeamConfirm": "Sei sicuro di voler eliminare il reparto {name}?",
  "teams.deleteTeamTitle": "Elimina Reparto",
  "teams.editTeam": "Modifica Reparto",
  "teams.handleDeleteError": "Errore di eliminazione",
  "teams.handleDeleteErrorTitle": "Errore di eliminazione del reparto",
  "teams.name": "Nome",
  "teams.removeUserInfo": "Non puoi rimuovere l'utente dal reparto predefinito. Se vuoi spostare l'utente in un altro reparto, puoi modificare l'altro reparto e aggiungere l'utente lì.",
  "teams.teamsLimitReached": "Stai attualmente utilizzando tutti i {limitNumber} slot di reparto disponibili nel piano CORE. Per gestire o aggiornare il tuo piano, visita la <billingPage>pagina di fatturazione</billingPage>.",
  "teams.users": "Utente/i",
  "teams.viewTeam": "Visualizza Dipartimento",
  "user.accrualsCalculator": "Calcola",
  "user.accrualsCalculatorInfo": "Quanti giorni avrò maturato entro una data specifica?",
  "user.addLeave": "Aggiungi Permesso",
  "user.approve": "Approva",
  "user.approveInProgress": "Approvazione della richiesta di permesso in corso",
  "user.approveLeaveText": "Conferma di voler approvare la richiesta di permesso.",
  "user.approveLeaveTitle": "Approva Richiesta di Permesso",
  "user.approveSuccessTitle": "Richiesta di permesso approvata",
  "user.bulkChangeStatusInProgress": "Aggiornamento degli stati degli utenti in corso.",
  "user.bulkChangeStatusSuccessTitle": "Gli stati degli utenti sono stati aggiornati.",
  "user.cancelLeave": "Annulla Permesso",
  "user.cancelLeave.buttonLink": "Annulla",
  "user.cancelLeaveConfirmTitle": "Annulla Permesso",
  "user.cancelLeaveRequestInProgress": "Annullamento della richiesta di permesso in corso",
  "user.cancelLeaveRequestTitle": "La richiesta di permesso è stata annullata",
  "user.currentLeaves": "Permessi Attuali",
  "user.deleteLeave": "Elimina Permesso",
  "user.deleteLeave.buttonLabel": "Elimina",
  "user.deleteLeaveConfirmText": "Conferma di voler eliminare il permesso.",
  "user.deleteLeaveConfirmTitle": "Elimina Permesso",
  "user.deleteLeaveInProgress": "Eliminazione del permesso in corso",
  "user.deleteLeaveSuccessTitle": "Il permesso è stato eliminato",
  "user.deleteToil": "Elimina TOIL",
  "user.deleteToilConfirmText": "Conferma di voler eliminare il TOIL.",
  "user.deny": "Rifiuta",
  "user.denyInProgress": "Rifiuto della richiesta di permesso in corso",
  "user.denyLeaveConfirm": "Conferma di voler rifiutare la richiesta di permesso.",
  "user.denyLeaveTitle": "Rifiuta Richiesta di Permesso",
  "user.denySuccessTitle": "Richiesta di permesso rifiutata",
  "user.denyWithReason": "Rifiuta con Motivazione",
  "user.dontHaveAnyHistory": "{name} non ha ancora preso alcun permesso.",
  "user.editLeave": "Modifica Permesso",
  "user.editLeave.buttonLabel": "Modifica",
  "user.editLeaveWord": "Modifica",
  "user.editUser": "Modifica",
  "user.editWorkWeek": "Modifica",
  "user.employeeEndDate": "Data di Fine Impiego",
  "user.employeeId": "ID Dipendente",
  "user.employeeSince": "Dipendente Dal",
  "user.general": "Generale",
  "user.history": "Cronologia",
  "user.historyLeaves": "Cronologia",
  "user.invitationDeletedTitle": "Invito eliminato",
  "user.invitationSuccessTitle": "Inviti inviati",
  "user.leaves": "Permessi",
  "user.leaveTypes": "Tipi di permesso",
  "user.location": "Posizione",
  "user.location.moveInProgress": "Spostamento dell'utente nella posizione {name}",
  "user.location.moveSuccessTitle": "L'utente è stato spostato nella posizione {name}",
  "user.logs": "Registri",
  "user.noUpcomingLeaves": "Nessun permesso programmato",
  "user.pendingLeaves": "Richieste di permesso in sospeso",
  "user.probationPeriodDescription": "L'utente è in un periodo di prova secondo la politica <probationPeriodName></probationPeriodName> per <leaveTypeNames></leaveTypeNames>, della durata di <lengthDays></lengthDays> giorno/i, fino al <date></date>.",
  "user.profile": "Profilo",
  "user.resend": "Reinvia richiesta",
  "user.resending": "Reinvio della richiesta di permesso...",
  "user.resent": "Richiesta reinviata",
  "user.role": "Ruolo",
  "user.signUp": "Registrati",
  "user.signup.intro": "{invitedByName} ti ha invitato a unirti alla loro organizzazione su Vacation Tracker. Registrati qui sotto per accettare l'invito e accedere alla dashboard di Vacation Tracker.",
  "user.signup.introHelpdesk": "Se hai domande o hai bisogno di assistenza, contatta il nostro supporto o visita <helpdesk>l'Helpdesk</helpdesk>.",
  "user.status": "Stato",
  "user.status.updateInProgress": "Aggiornamento dello stato dell'utente.",
  "user.status.updateSuccessTitle": "Lo stato dell'utente è stato aggiornato.",
  "user.statusReason": "Aggiungi motivo",
  "user.team.moveInProgress": "Spostamento dell'utente nel reparto {name}",
  "user.team.moveSuccessTitle": "L'utente è stato spostato nel reparto {name}",
  "user.upcomingLeaves": "Permessi programmati",
  "user.updateInProgress": "Aggiornamento dell'utente",
  "user.updateSuccessTitle": "Utente aggiornato",
  "user.userWorkWeek": "Settimana lavorativa dell'utente",
  "user.workWeek.updateInProgress": "Aggiornamento della settimana lavorativa",
  "user.workWeek.updateSuccessTitle": "Settimana lavorativa aggiornata",
  "users.aboutToSync": "Sincronizzando tutti gli utenti esistenti, stai sincronizzando i dati degli utenti dalla piattaforma di collaborazione utilizzata dal tuo team al loro profilo su Vacation Tracker (es. Nome, Immagine del profilo, ecc.)",
  "users.activateUsers": "Attiva",
  "users.activeUser": "Attiva",
  "users.activeUsers": "Attivi",
  "users.assignLicenses": "Assegna licenze",
  "users.cancel": "Annulla",
  "users.changeUserLocation": "Cambia Posizione",
  "users.changeUserTeam": "Cambia Dipartimento",
  "users.deactivate": "Disattiva",
  "users.deactivateUser": "Disattiva",
  "users.deactivateUserConfirm": "Sei sicuro di voler disattivare {name}?",
  "users.deactivateUsers": "Disattiva",
  "users.deactivateUserTitle": "Disattiva",
  "users.deletedUsers": "Eliminati",
  "users.deleteInvite": "Elimina invito",
  "users.deleteInviteConfirm": "Sei sicuro di voler eliminare l'invito inviato a {email}?",
  "users.deleteUser": "Elimina Utente",
  "users.deleteUserUserConfirm": "Sei sicuro di voler eliminare {name}?",
  "users.employmentDate": "Data di Assunzione dell'Utente",
  "users.endDate": "Data di Fine",
  "users.import.description": "L'importazione potrebbe richiedere del tempo, nel caso in cui non vedi nuovi utenti nell'elenco, aggiorna questa pagina dopo 10-15 secondi.",
  "users.importedSuccessfully": "Utenti importati con successo",
  "users.importMany.InProgress": "Importazione utenti in corso",
  "users.importMany.successTitle": "Utenti importati con successo",
  "users.importNewUsers": "Aggiungi Utenti",
  "users.importOne.InProgress": "Importazione di un nuovo utente",
  "users.importOne.successTitle": "Utente importato con successo",
  "users.importUsers": "Importa Utenti",
  "users.inactiveUsers": "Inattivi",
  "users.invitations": "Inviti",
  "users.inviteUsers": "Invita Utenti",
  "users.location": "Posizione",
  "users.name": "Nome",
  "users.next": "Successivo",
  "users.prev": "Precedente",
  "users.reinvite": "Reinvita",
  "users.reInviteConfirm": "Sei sicuro di voler reinvitare {email}?",
  "users.role": "Ruolo",
  "users.search": "Cerca utenti",
  "users.sendInvitationsInLanguage": "Invia inviti in",
  "users.startDate": "Data di Inizio",
  "users.syncExistingUsers": "Sincronizza Utenti Esistenti",
  "users.syncExistingUsers.InProgress": "Sincronizzazione utenti esistenti in corso",
  "users.syncExistingUsers.successTitle": "Gli utenti esistenti sono stati sincronizzati con successo.",
  "users.syncWithGoogleConsent": "Sincronizza con il consenso di Google",
  "users.syncWithoutGoogleConsent": "Sincronizza senza il consenso di Google",
  "users.team": "Dipartimento",
  "users.updateQuota.InProgress": "Aggiornamento della quota utente in corso",
  "users.updateQuota.successTitle": "La quota utente è stata aggiornata con successo",
  "users.updateRolloverQuota.successTitle": "La quota iniziale riportata è stata aggiornata con successo.",
  "users.viewUser": "Visualizza Utente",
  "wallchart.range.next4Weeks": "Prossime 4 settimane",
  "wallchart.range.next5Weeks": "Prossime 5 settimane",
  "wallchart.range.nextMonth": "Prossimo mese",
  "wallchart.range.previousMonth": "Mese precedente",
  "wallchart.range.thisMonth": "Questo mese"
}
