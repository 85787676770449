import { IAvailableLanguages, LocaleEnum } from '../types/i18n'
import { fr, enUS, es, ptBR, de, it, pl, tr } from 'date-fns/locale'
import { Locale } from 'date-fns'


export const availableLanguages: IAvailableLanguages = {
  en: {
    locale: LocaleEnum.en,
    name: 'English',
    icon: '🇺🇸',
  },
  fr: {
    locale: LocaleEnum.fr,
    name: 'Français',
    icon: '🇫🇷',
  },
  es: {
    locale: LocaleEnum.es,
    name: 'Español',
    icon: '🇪🇸',
  },
  pt: {
    locale: LocaleEnum.pt,
    name: 'Português',
    icon: '🇧🇷',
  },
  de: {
    locale: LocaleEnum.de,
    name: 'Deutsch',
    icon: '🇩🇪',
  },
  it: {
    locale: LocaleEnum.it,
    name: 'Italiano',
    icon: '🇮🇹',
  },
  pl: {
    locale: LocaleEnum.pl,
    name: 'Polski',
    icon: '🇵🇱',
  },
  tr: {
    locale: LocaleEnum.tr,
    name: 'Türkçe',
    icon: '🇹🇷',
  },
}

export const getDateFnsLocale = (locale: LocaleEnum): Locale => {
  switch (locale) {
    case LocaleEnum.en:
      return enUS
    case LocaleEnum.fr:
      return fr
    case LocaleEnum.es:
      return es
    case LocaleEnum.pt:
      return ptBR
    case LocaleEnum.de:
      return de
    case LocaleEnum.it:
      return it
    case LocaleEnum.pl:
      return pl
    case LocaleEnum.tr:
      return tr
    default:
      return enUS
  }
}

export const getSafeLocale = (locale: LocaleEnum | null | undefined) => locale || LocaleEnum.en
