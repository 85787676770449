export function Observable(this: any) {
  const listeners: any[] = []
  const add = function (listener) {
    if (listener && typeof listener === 'function') {
      listeners.push(listener)
    } else {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      throw new Error('cannot add ' + listener)
    }
  }
  const notify = function (...args) {
    // eslint-disable-next-line prefer-spread
    listeners.forEach(listener => listener.apply(null, args))
  }
  Object.freeze(Object.assign(this, {add, notify}))
}
