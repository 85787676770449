import React from 'react'
import { useManualQuery, useSubscription } from 'graphql-hooks'
import { useIntl } from 'react-intl'
import { isNumber } from 'lodash'
import { App } from 'antd'

import * as logger from '../../services/logger'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { selectAuthCompanySlice, setAuthCompanyPlan, setAuthCompany } from '../../store/auth-company-slice'
import { selectAuthUserSlice, setAuthUserLocationWorkWeek, setAuthUserWorkWeek } from '../../store/auth-user-slice'
import { setLeaveRequestActionEvent } from '../../store/leave-request-action-event-slice'
import { setCompanySettingsBillingUpdatedActionEvent } from '../../store/company-settings-billing-updated-action-event-slice'
import {
  companyUpdateSubscription,
  getCompanyBillingInfo,
  getConnectedCalendars,
  getLeaveRequestByIdForSubscription,
  getToilRequestByIdForSubscription,
  getUserForLeaveRequestUpdateNotification
} from '../../graphql/custom-queries'

import { ISubscriptionEvent } from '@vacationtracker/shared/types/notification'
import { IGetConnectedCalendarsResponse, ILeaveRequestUpdate, IToilRequestUpdate, IUserForLeaveRequestUpdate } from '../../types/custom-queries'
import { selectLocaleSlice } from '../../store/locale-slice'
import { BillingTypesEnum } from '@vacationtracker/shared/types/billing'
import { IGetCompanyBillingInfoData } from '../../types/company'
import { updateLeaveRequestNotification } from '../../components/update-leave-request-notification'
import { updateToilRequestNotification } from '../../components/update-toil-request-notification'
import { setToilRequestActionEvent } from '../../store/toil-request-action-event-slice'

type CompanyEventResponse = {
  companyUpdates: ISubscriptionEvent
}

const CompanyUpdatesNotifications = () => {
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { authUser} = useAppSelector(selectAuthUserSlice)
  const { locale} = useAppSelector(selectLocaleSlice)
  const dispatch = useAppDispatch()
  const { formatMessage } = useIntl()
  const { notification } = App.useApp()

  const [getCompanyBillingInfoQuery] = useManualQuery<IGetCompanyBillingInfoData, { companyId: string }>(getCompanyBillingInfo)
  const [leaveRequestResponseQuery] = useManualQuery<ILeaveRequestUpdate, { leaveRequestId: string }>(getLeaveRequestByIdForSubscription)
  const [getUserForLeaveRequestUpdateNotificationQuery] = useManualQuery<IUserForLeaveRequestUpdate, { id: string }>(getUserForLeaveRequestUpdateNotification)
  const [getConnectedCalendarsQuery] = useManualQuery<IGetConnectedCalendarsResponse, { userId: string }>(getConnectedCalendars)
  const [getToilRequestByIdForSubscriptionQuery] = useManualQuery<IToilRequestUpdate, { toilRequestId: string }>(getToilRequestByIdForSubscription)

  useSubscription<CompanyEventResponse>({
    query: companyUpdateSubscription,
    variables: { companyId: authCompany?.id },
  }, ({ data, errors }) => {
    // The "errors" type is `object[] | undefined`, but WebSocket error returns just one object with `type: 'error'`
    const err: any = errors
    if (err && (err.type === 'error' || !Array.isArray(err))) {
      // Handled in the <Notifications /> component
      return
    } else if (errors || !data) {
      logger.error(...errors || [])
      return
    }
    try {
      const subscriptionEvent = JSON.parse(data.companyUpdates.originalEvent)
      const rawResult = data?.companyUpdates?.result
      if (!rawResult) {
        // Nothing to do here, just return
        return
      }
      const result = JSON.parse(rawResult)
      handleSubscriptionEvent(subscriptionEvent, result)
    } catch (err) {
      logger.error('Error parsing subscription event (CompanyUpdatesNotifications)', err)
    }
  })

  const handleSubscriptionEvent = async (event, result) => {
    if (!authCompany || !authUser.id) {
      return
    }

    switch (event.eventType) {
      case 'SUBSCRIPTION_CHANGED':
        dispatch(setAuthCompanyPlan(event.plan))
        if (event.paymentProcessor === 'microsoft-billing') {
          dispatch(setAuthCompany({
            ...authCompany,
            billing: {
              ...authCompany?.billing,
              quantity: event.quantity,
              subscriptionPeriod: event.subscriptionPeriod,
              msSubscriptionStatus: event.msSubscriptionStatus,
              msPeriodStartDate: event.msPeriodStartDate,
              msPeriodEndDate: event.msPeriodEndDate,
            },
          }))
        }
        break
      case 'PAYMENT_PROCESSOR_UPDATED':
        if (event.paymentProcessor === 'stripe'
          && authCompany.billing?.billingType === BillingTypesEnum.seatBased
          && isNumber(authCompany.billing?.seats)
        ) {
          const billingResponse = await getCompanyBillingInfoQuery({ variables: { companyId: authCompany.id }})
          if (!billingResponse.data || billingResponse.error) throw billingResponse.error

          dispatch(setAuthCompany({
            ...authCompany,
            billing: {
              ...authCompany?.billing,
              seats: billingResponse.data.getCompany.billing.seats,
            },
          }))
          dispatch(setCompanySettingsBillingUpdatedActionEvent(event))
        }
        break
      case 'LOCATION_WORK_WEEK_UPDATED':
        dispatch(setAuthUserLocationWorkWeek(event))
        break
      case 'USER_WORK_WEEK_UPDATED':
        dispatch(setAuthUserWorkWeek(event))
        break
      case 'LEAVE_REQUEST_UPDATED':
      case 'LEAVE_REQUEST_CREATED':
      case 'LEAVE_REQUEST_CANCELLED':
      case 'LEAVE_REQUEST_APPROVED':
      case 'LEAVE_REQUEST_DENIED':
      case 'LEAVE_REQUEST_EXPIRED':
      case 'LEAVE_REQUEST_ADDED':
      case 'LEAVE_REQUEST_DELETED':
        
        if (event.isMigration) return
        if (result?.eventAction === 'LeaveRequestAction') {
          let isCalendarConnected = false
          try {
            const leaveRequestResponse = await leaveRequestResponseQuery({ variables: { leaveRequestId: result.id }})
            if (!leaveRequestResponse.data || leaveRequestResponse.error) throw leaveRequestResponse.error

            let userResponse
            if (authUser.role === 'Admin') {
              userResponse = await getUserForLeaveRequestUpdateNotificationQuery({ variables: {
                id: event.creator,
              }})
            } else if (authUser.approverTo.find(u => u.id === leaveRequestResponse.data?.getLeaveRequest.user.id)) {
              userResponse = await getUserForLeaveRequestUpdateNotificationQuery({ variables: {
                id: event.creator,
              }})
            } else if (authUser.id === leaveRequestResponse.data.getLeaveRequest.user.id) {
              userResponse = await getUserForLeaveRequestUpdateNotificationQuery({ variables: {
                id: leaveRequestResponse.data.getLeaveRequest.user.id,
              }})
            } else {
              return // regular users cant see other users profiles anyway
            }

            const calendarResponse = await getConnectedCalendarsQuery({ variables: { userId: authUser.id }})
            if (calendarResponse.data?.getConnectedGooogleCalendar?.calendarId || calendarResponse.data?.getConnectedOutlookCalendar?.calendarId) {
              isCalendarConnected = true
            }

            if (leaveRequestResponse.data && userResponse.data) {
              updateLeaveRequestNotification({
                subscriptionEvent: event,
                leaveRequest: {
                  getLeaveRequest: leaveRequestResponse.data.getLeaveRequest,
                  getUser: userResponse.data.getUser,
                },
                result,
                authUser,
                authCompany,
                formatMessage,
                locale: locale.locale,
                isCalendarConnected,
                notification,
              })
            }
          } catch (err) {
            logger.error('ERROR FETCHING LEAVE REQUEST', err)
          }
          dispatch(setLeaveRequestActionEvent(event))
        }
        break
      case 'TOIL_REQUEST_UPDATED':
      case 'TOIL_REQUEST_CREATED':
      case 'TOIL_REQUEST_APPROVED':
      case 'TOIL_REQUEST_DENIED':
      case 'TOIL_REQUEST_EXPIRED':
      case 'TOIL_REQUEST_ADDED':
      case 'TOIL_REQUEST_DELETED':
      case 'TOIL_REQUEST_CANCELLED':
        if (event.isMigration) return
        if (result?.eventAction === 'ToilRequestAction') {
          try {
            const toilRequestResponse = await getToilRequestByIdForSubscriptionQuery({ variables: {
              toilRequestId: result.id,
            }})
            if (!toilRequestResponse.data || toilRequestResponse.error) throw toilRequestResponse.error
            let userResponse
            if (authUser.role === 'Admin') {
              userResponse = await getUserForLeaveRequestUpdateNotificationQuery({ variables: {
                id: event.creator,
              }})
            } else if (authUser.approverTo.find(u => u.id === toilRequestResponse.data?.getToilRequest.user.id)) {
              userResponse = await getUserForLeaveRequestUpdateNotificationQuery({ variables: {
                id: event.creator,
              }})
            } else {
              userResponse = await getUserForLeaveRequestUpdateNotificationQuery({ variables: {
                id: toilRequestResponse.data.getToilRequest.user.id,
              }})
            }
            if (toilRequestResponse.data && userResponse.data) {
              updateToilRequestNotification({
                subscriptionEvent: event,
                toilRequest: {
                  getToilRequest: toilRequestResponse.data.getToilRequest,
                  getUser: userResponse.data.getUser,
                },
                result,
                authUser,
                authCompany,
                formatMessage,
                locale: locale.locale,
                notification,
              })
            }
          } catch (err) {
            logger.error('ERROR FETCHING TOIL REQUEST', err)
          }
          dispatch(setToilRequestActionEvent(event))
        }
        break  
      default:
        break
    }
  }

  return (<></>)
}

export default CompanyUpdatesNotifications
