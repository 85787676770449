import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'

interface UserIdState {
  userId: string
}

const initialState: UserIdState = {
  userId: localStorage.getItem('userId') || '',
}

export const userIdSlice = createSlice({
  name: 'userId',
  initialState,
  extraReducers: (builder) => builder.addCase('USER_LOGGED_OUT', () => ({ userId: '' })),
  reducers: {
    setUserId: (state, action: PayloadAction<string>) => {
      state.userId = action.payload
    },
  },
})

export const { setUserId } = userIdSlice.actions

export const selectUserIdSlice = (state: RootState) => state.userIdSlice

export default userIdSlice.reducer
