import { ShortestLeaveIntervalEnum } from '../../types/leave-policy'

export const getLeaveDaysNumber = (
  accrualType: string,
  allowHalfDays = false,
  shortestLeaveInterval: string | undefined,
  value: number,
  hourlyLeaveAccounting: boolean
): number => {
  if (hourlyLeaveAccounting) {
    return value
  }
  if (accrualType === 'NONE') {
    if (
      allowHalfDays ||
      shortestLeaveInterval === ShortestLeaveIntervalEnum.halfDay ||
      shortestLeaveInterval === ShortestLeaveIntervalEnum.oneHour
    ) {
      return Math.round(value * 2) / 2
    } else {
      return Math.round(value)
    }
  } else {
    return Math.round(value * 100) / 100
  }
}
