// Based on https://github.com/autonomoussoftware/fast-password-entropy
// and https://dropbox.tech/security/zxcvbn-realistic-password-strength-estimation#threat-model-entropy-to-crack-time
// speed adjusted to be similar to the following table: https://www.hivesystems.io/blog/are-your-passwords-in-the-green
interface ICharset {
  name: string
  re: RegExp
  length: number
}

const SINGLE_GUESS = 0.00004
const NUM_ATTACKERS = 100
const SECONDS_PER_GUESS = SINGLE_GUESS / NUM_ATTACKERS

// Calculate the entropy of a string based on the size of the charset used and the length of the string.
// Based on: http://resources.infosecinstitute.com/password-security-complexity-vs-length/
const calcEntropy = (charset: number, length: number): number =>
  Math.round(length * Math.log(charset) / Math.LN2)

// Standard character sets list.
// It assumes the `uppercase` and `lowercase` charsets to have 26 chars as in
// the English alphabet. Numbers are 10 characters long. Symbols are the rest
// of the 33 remaining chars in the 7-bit ASCII table.
const stdCharsets: ICharset[] = [
  {
    name: 'lowercase',
    re: /[a-z]/, // abcdefghijklmnopqrstuvwxyz
    length: 26,
  },
  {
    name: 'uppercase',
    re: /[A-Z]/, // ABCDEFGHIJKLMNOPQRSTUVWXYZ
    length: 26,
  }, {
    name: 'numbers',
    re: /[0-9]/, // 1234567890
    length: 10,
  },
  {
    name: 'symbols',
    re: /[^a-zA-Z0-9]/, //  !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~ (and any other)
    length: 33,
  },
]

// Creates a function to calculate the total charset length of a string based on the given charsets.
const calcCharsetLengthWith = (charsets: ICharset[]) =>
  (password: string) =>
    charsets.reduce((length, charset) =>
      length + (charset.re.test(password) ? charset.length : 0), 0)

// Helper function to calculate the total charset lengths of a given string using the standard character sets.
const calcCharsetLength = calcCharsetLengthWith(stdCharsets)

// Calculate the given password entropy.
const passwordEntropy = (password: string): number =>
  password ? calcEntropy(calcCharsetLength(password), password.length) : 0

const entropyToCrackTime = (entropy: number) => 0.5 * Math.pow(2, entropy) * SECONDS_PER_GUESS

export const calculatePasswordStrength = (password: string) => {
  const entropy = passwordEntropy(password)
  const timeInSeconds = entropyToCrackTime(entropy)
  return {
    entropy,
    timeInSeconds,
  }
}
