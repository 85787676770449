import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Modal, Typography, Radio, Input } from 'antd'
import IntlMessages from '../../util/IntlMessages'
import { CancelReasonEnum } from '@vacationtracker/shared/types/billing'
import { shuffle } from 'lodash'

const { Paragraph } = Typography
const { TextArea } = Input

interface ICancelSubscriptionModal {
  openCancelSubscriptionModal: boolean
  handleOnCancelSubscription: (reason: CancelReasonEnum, comment: string, integrationWithHr?: string, movingToPayroll?: string) => Promise<void> | void
  closeModal: () => void
}

export const CancelSubscriptionModal = ({
  openCancelSubscriptionModal,
  handleOnCancelSubscription,
  closeModal,
}: ICancelSubscriptionModal): React.ReactElement => {
  const { formatMessage } = useIntl()

  const [cancelReason, setCancelReason] = useState<CancelReasonEnum | undefined>()
  const [cancelReasonComment, setCancelReasonComment] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [additionalInfo, setAdditionalInfo] = useState(false)
  const [integrationWithHr, setIntegrationWithHr] = useState()
  const [movingToPayroll, setMovingToPayroll] = useState()
  const [cancelReasons] = useState(() => {
    return shuffle([
      'weSiwtchedToAnHrSolution',
      'weAreMovingToPayroll',
      'anotherLeaveTrackingTool',
      'missingFeatures',
      'priceOverBudget',
    ])
  })

  const handleCancelReasonChange = e => {
    const value = e.target.value
    setCancelReason(CancelReasonEnum[value])
    setAdditionalInfo(value)
    setCancelReasonComment('')
    setIntegrationWithHr(undefined)
    setMovingToPayroll(undefined)
  }

  const handleIntegrationWithHrChange = e => {
    const value = e.target.value
    setIntegrationWithHr(value)
  }

  const handleMovingToPayroll = e => {
    const value = e.target.value
    setMovingToPayroll(value)
  }

  const isCancelButtonDisabled = () => {
    if (!cancelReason) {
      return true
    }
    if (cancelReasonComment.length < 1 && (cancelReason === CancelReasonEnum.priceOverBudget
      || cancelReason === CancelReasonEnum.weSiwtchedToAnHrSolution
      || cancelReason === CancelReasonEnum.weAreMovingToPayroll
    )) {
      return true
    }
    if (cancelReasonComment.length < 20 && !(cancelReason === CancelReasonEnum.priceOverBudget
      || cancelReason === CancelReasonEnum.weSiwtchedToAnHrSolution
      || cancelReason === CancelReasonEnum.weAreMovingToPayroll
    )) {
      return true
    }
    if (cancelReason === CancelReasonEnum.weSiwtchedToAnHrSolution && !integrationWithHr) {
      return true
    }
    if (cancelReason === CancelReasonEnum.weAreMovingToPayroll && !movingToPayroll) {
      return true
    }
    return false
  }

  return (
    <Modal
      title={<IntlMessages id="components.cancelSubscriptionModal.title" />}
      open={openCancelSubscriptionModal}
      onOk={() => {
        setIsLoading(true)
        if (!cancelReason) return
        handleOnCancelSubscription(cancelReason, cancelReasonComment, integrationWithHr, movingToPayroll )
      }}
      okButtonProps={{
        danger: true,
        disabled: isCancelButtonDisabled(),
      }}
      destroyOnClose={true}
      okText={<IntlMessages id="components.cancelSubscriptionModal.okText" />}
      confirmLoading={isLoading}
      onCancel={() => closeModal()}
      cancelText={<IntlMessages id="components.cancelSubscriptionModal.cancelText" />}
    >
      <Paragraph>
        <IntlMessages id="components.cancelSubscriptionModal.info" />
      </Paragraph>
      <Radio.Group
        style={{marginBottom: 20}}
        onChange={handleCancelReasonChange}
        value={additionalInfo}

      >
        {cancelReasons.map(cancelReason => {
          return (
            <Radio key={cancelReason} style={{ display: 'block' }} value={cancelReason}>
              <IntlMessages id={`components.cancelSubscriptionModal.${cancelReason}`} />
            </Radio>
          )
        })}
        <Radio style={{ display: 'block' }} value='other'>
          <IntlMessages id="components.cancelSubscriptionModal.other" />
        </Radio>
      </Radio.Group>
      {additionalInfo &&
        <>
          <Paragraph style={{ marginBottom: 0 }}>
            <span style={{color: 'red'}}>* </span>
            <IntlMessages id={`components.cancelSubscriptionModal.${additionalInfo}AdditionalInfo`} />
          </Paragraph>
          {cancelReason !== CancelReasonEnum.priceOverBudget &&
          <>
            <TextArea
              showCount
              rows={cancelReason !== CancelReasonEnum.anotherLeaveTrackingTool ? 2 : 3}
              value={cancelReasonComment}
              onChange={e => setCancelReasonComment(e.target.value)}
            />
            {cancelReason !== CancelReasonEnum.weSiwtchedToAnHrSolution &&
              <Paragraph type="secondary" style={{ marginBottom: 10 }}>
                <IntlMessages id="components.cancelSubscriptionModal.min20characters" />
              </Paragraph>
            }
          </>
          }
          {cancelReason === CancelReasonEnum.weSiwtchedToAnHrSolution &&
            <>
              <Paragraph style={{ marginBottom: 0, marginTop: 15 }}>
                <span style={{color: 'red'}}>* </span>
                <IntlMessages id={`components.cancelSubscriptionModal.${additionalInfo}AdditionalInfoIntegration`} />
              </Paragraph>
              <Radio.Group onChange={handleIntegrationWithHrChange} value={integrationWithHr}>
                <Radio value="yes"><IntlMessages id="app.yes" /> </Radio>
                <Radio value="maybe"><IntlMessages id="app.maybe" /> </Radio>
                <Radio value="no"><IntlMessages id="app.no" /> </Radio>
              </Radio.Group>
            </>
          }
          {cancelReason === CancelReasonEnum.weAreMovingToPayroll &&
            <>
              <Paragraph style={{ marginBottom: 0, marginTop: 15 }}>
                <span style={{color: 'red'}}>* </span>
                <IntlMessages id={`components.cancelSubscriptionModal.${additionalInfo}AdditionalInfoIntegration`} />
              </Paragraph>
              <Radio.Group onChange={handleMovingToPayroll} value={movingToPayroll}>
                <Radio value="yes"><IntlMessages id="app.yes" /> </Radio>
                <Radio value="maybe"><IntlMessages id="app.maybe" /> </Radio>
                <Radio value="no"><IntlMessages id="app.no" /> </Radio>
              </Radio.Group>
            </>
          }
          {cancelReason === CancelReasonEnum.priceOverBudget &&
            <Input value={cancelReasonComment} onChange={e => setCancelReasonComment(e.target.value)} />
          }
        </>
      }
      <Paragraph style={{ marginTop: 15 }}>
        {formatMessage({ id: 'components.cancelSubscriptionModal.contactInfo' }, {email: 'hello@vacationtracker.io'})}
      </Paragraph>
    </Modal>
  )
}

export default CancelSubscriptionModal
