import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

export const DateLocalToUtc = (minutes, hours, weekDay, timezone) => {
  const date = dayjs().tz(timezone).day(weekDay).hour(hours).minute(minutes).utc()
  return {
    hour: Number(date.format('HH')),
    day: Number(date.format('d')),
    minute: Number(date.format('mm')),
  }
}

export const DateUtcToLocal = (minutes, hours, weekDay, timezone) => {
  const date = dayjs.utc().day(weekDay).hour(hours).minute(minutes).tz(timezone)
  return {
    hour: date.format('HH'),
    day: date.format('d'),
    minute: date.format('mm'),
  }
}

export const TimeLocalToUtc = (minutes, hours, timezone) => {
  const date = dayjs().tz(timezone).hour(hours).minute(minutes).utc()
  return {
    hour: Number(date.format('HH')),
    minute: Number(date.format('mm')),
  }
}

export const TimeUtcToLocal = (minutes, hours, timezone) => {
  const date = dayjs.utc().hour(hours).minute(minutes).tz(timezone)
  return {
    hour: date.format('HH'),
    minute: date.format('mm'),
  }
}
