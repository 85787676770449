import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { App, Button, Col, Divider, Form, Modal, Progress, Row, Skeleton, Space, Statistic, Steps, Tooltip, Typography } from 'antd'
import { InfoCircleOutlined, LoadingOutlined, UsergroupAddOutlined, UserOutlined } from '@ant-design/icons'
import { captureException } from '@sentry/react'
import { addMinutes, isAfter } from 'date-fns'
import * as Sentry from '@sentry/react'
import { isBoolean, isNumber } from 'lodash'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { MicrosoftAuth } from '../../../services/auth/microsoft/microsoft'
import { GoogleAuth } from '../../../services/auth/google/google'
import { SlackAuth } from '../../../services/auth/slack/slack'
import { getName, getEmail, vtUserId } from '@vacationtracker/shared/functions/microsoft-common/microsoft-data-mappings'
import * as logger from '../../../services/logger'

import {
  getAllUserListShortPaginated, getGoogleScopes, getSlackDetails, slackIds
} from '../../../graphql/custom-queries'
import { notificationStore } from '../../../context/notificationsContext/store'
import { useAppSelector } from '../../../store/hooks'
import { selectAuthCompanySlice } from '../../../store/auth-company-slice'
import { selectFeatureFlagsSlice } from '../../../store/feature-flags-slice'
import { getDontSendWelcomeOption, getWelcomeMessageOptionsDefault } from '../../../components/configure-import-users-options-form'
import { track } from '../../../services/analytics/analytics'
import { useShouldEnableFeatures } from '../../../store/use-should-enable-features'

import IntlMessages from '../../../util/IntlMessages'
import ImportUsersPageForm from './steps/import-users-form'
import { ConfigureImportUsersOptions } from './steps/configure-import'

import { IGetUserListShort } from '../../../types/teams'
import { IGetAllUserListShortPaginatedData } from '../../../types/users'
import { IMicrosoftUser } from '../../../types/microsoft'
import {
  IAutocompleteOption,
  IAutocompleteOptionValue,
  IImportData,
  IImportUser,
  IImportUsersEventBody,
  IUserToImportData,
  ImportUsersStepEnum,
  WelcomeMessageOptions
} from './types'
import { Platform } from '@vacationtracker/shared/types/core-event'
import { ISlackUser } from '@vacationtracker/shared/types/repository/slack-api-repository'
import { ISlackIdUser, ISlackIds, IGetGoogleScopes, IGetSlackDetails, ISlackDetails } from '../../../types/custom-queries'
import { IUpcomingInvoiceInformation } from '@vacationtracker/shared/types/stripe'
import { AUTOMATIC_USERS_IMPORT_LIMIT, GOOGLE_READ_USER_PERMISSION, LEGACY_PLAN_IDS } from '../../../constants'
import { IGoogleScopes } from '../../../types/google'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import { wait } from '@vacationtracker/shared/functions/wait'
import { BillingTypesEnum } from '@vacationtracker/shared/types/billing'
import { SubscriptionPlanEnum } from '@vacationtracker/shared/types/company'
import { NotFound } from '@vacationtracker/shared/errors/request'

if (!process.env.REACT_APP_MICROSOFT_CLIENT_ID) {
  throw new Error('Client IDs are required')
}

const { Paragraph, Text } = Typography
const supportLink = 'https://vacationtracker.crisp.help/en/article/google-workspace-editing-permissions-15pdkie/'


const msAuth = new MicrosoftAuth(process.env.REACT_APP_MICROSOFT_CLIENT_ID)
const slackAuth = new SlackAuth(process.env.REACT_APP_SLACK_CLIENT_ID)
const googleAuth = new GoogleAuth(process.env.REACT_APP_GOOGLE_CLIENT_ID)

const getActiveUsersTooltipContent = (users: IGetUserListShort[]) => {
  const active = users.filter(user => user.status === 'ACTIVE').length
  const inactive = users.filter(user => user.status === 'INACTIVE').length
  const deleted = users.filter(user => user.status === 'DELETED').length
  let activeUsers = ''
  let inactiveUsers = ''
  let deletedUsers = ''

  if (active > 0) {
    activeUsers = `${active} active`
  }
  if (inactive > 0) {
    inactiveUsers = `${inactive} inactive`
  }
  if (deleted > 0) {
    deletedUsers = `${deleted} deleted`
  }
  return `${activeUsers} ${inactive > 0 ? 'and' : ''} ${inactiveUsers} ${deleted > 0 ? 'and' : ''} ${deletedUsers}`
}

const ImportUsersPage = (): React.ReactElement => {
  const { notification } = App.useApp()
  const [form] = Form.useForm()
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { eventsNotifications, setEventsNotifications } = useContext(notificationStore)
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)
  const shouldEnableGoogleAdminPermissions = useShouldEnableFeatures(SubscriptionPlanEnum.core, FeatureFlagEnum.googleAdminPermissions, true)

  if (!authCompany?.platform) {
    throw new Error('Company is required!')
  }

  const platform = authCompany.platform

  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const [activeUsers, setActiveUsers] = useState<IGetUserListShort[]>([])
  const [isMsAdminConsentRequired, setMsAdminConsentRequired] = useState(false)
  const [adminConsentUrl, setAdminConsentUrl] = useState<null | string>(null)
  const [totalNumberOfUsers, setTotalNumberOfUsers] = useState(0)
  const [showAdminConsentWarning, setShowAdminConsentWarning] = useState(false)
  const [selectedUsers, setSelectedUsers] = useState<IAutocompleteOptionValue[]>([])
  const [showMsLoginRequiredModal, setShowMsLoginRequiredModal] = useState(false)
  const [showMsLoginAdditionalScopesRequiredModal, setShowMsLoginAdditionalScopesRequiredModal] = useState(false)
  const [showSlackLoginRequiredModal, setShowSlackLoginRequiredModal] = useState(false)
  const [showGoogleLoginRequiredModal, setShowGoogleLoginRequiredModal] = useState(false)
  const [showRequestGoogleAdminPermissionsModal, setShowRequestGoogleAdminPermissionsModal] = useState(false)
  const [showEnableGoogleApiModal, setShowEnableGoogleApiModal] = useState(false)
  const [notImportedUsers, setNotImportedUsers] = useState<IUserToImportData[]>([])
  const [correlationId, setCorrelationId] = useState('')
  const [correlationExist, setCorrelationExist] = useState(false)
  const [percentageOfImportedUsers, setPercentageOfImportedUsers] = useState(0)
  const [currentStep, setCurrentStep] = useState(0)
  const [step, setStep] = useState(ImportUsersStepEnum.selectUsers)
  const [importAllUsers, setImportAllUsers] = useState(true)
  const [importUsersAutomatically, setImportUsersAutomatically] = useState(Boolean(authCompany?.importUsersAutomatically))
  const [welcomeMessageOpts, setWelcomeMessageOpts] = useState<WelcomeMessageOptions[]>(getWelcomeMessageOptionsDefault(formatMessage))
  const [selectedWelcomeMessageOpt, setSelectedWelcomeMessageOpt] = useState(getDontSendWelcomeOption(formatMessage))
  const [msTenantId, setMsTenantId] = useState('')
  const [slackUserDetails, setSlackUserDetails] = useState<ISlackDetails>()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const [googleScopes, setGoogleScopes] = useState<IGoogleScopes>()
  const [totalUsersAfterImport, setTotalUsersAfterImport] = useState(0)
  const [upcomingInvoiceInfo, setUpcomingInvoiceInfo] = useState<IUpcomingInvoiceInformation | null>(null)
  const [isLoadingUpcomingInvoiceInfo, setIsLoadingUpcomingInvoiceInfo] = useState(true)
  const [shouldEnableImportAll, setShouldEnableImportAll] = useState(false)
  const [noOfUsersToImport, setNoOfUsersToImport] = useState(0)

  const [ getSlackDetailsQuery ] = useManualQuery<IGetSlackDetails, { id: string }>(getSlackDetails)
  const [ slackIdsQuery ] = useManualQuery<ISlackIds, {
    limit: number
    nextToken?: string
  }>(slackIds)
  const [ getGoogleScopesQuery ] = useManualQuery<IGetGoogleScopes, { accessToken: string }>(getGoogleScopes)
  const [ getAllUserListShortPaginatedQuery ] = useManualQuery<IGetAllUserListShortPaginatedData, {
    limit: number
    nextToken?: string
  }>(getAllUserListShortPaginated)

  useEffect(() => {
    setNoOfUsersToImport(importAllUsers ? totalNumberOfUsers - activeUsers.length : selectedUsers.length)
  }, [importAllUsers, totalNumberOfUsers, activeUsers.length, selectedUsers.length])

  useEffect(() => {
    // TODO: Do we need this part of microsoft code here
    if (platform === 'microsoft') {
      getTotalUsersInMicrosoftTenant()
    }

    if (platform === 'google') {
      shouldEnableGoogleAdminPermissions ? fetchFromGoogleApiWithFeatureFlag() : fetchFromGoogleApi()
    }

    track('IMPORT_USERS_PAGE_VISITED', {
      platform,
      tokenExists: checkForTokenExistence(platform),
    })
  }, [shouldEnableGoogleAdminPermissions])

  useEffect(() => {
    if (msAuth) {
      const tenantId = msAuth.getTenantId() || 'common'
      setAdminConsentUrl(`https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`)
    }
  }, [msAuth])

  useEffect(() => {
    if (activeUsers.length > 0 && totalNumberOfUsers > 0) {
      const percentage = Math.round(activeUsers.length / totalNumberOfUsers * 100)
      setPercentageOfImportedUsers(Math.min(percentage, 100))
    } else {
      setPercentageOfImportedUsers(0)
    }
  }, [activeUsers, totalNumberOfUsers])

  useEffect(() => {
    fetchActiveUsers()
    if (eventsNotifications[correlationId]) {
      setCorrelationExist(true)
    }
    if (!eventsNotifications[correlationId] && correlationExist) {
      setCorrelationExist(false)
      setCorrelationId('')
    }
  }, [eventsNotifications])

  useEffect(() => {
    setImportUsersAutomatically(Boolean(authCompany?.importUsersAutomatically))
  }, [authCompany])

  useEffect(() => {
    if (platform === 'slack') {
      getSlackUserDetails()
    }
  }, [authUser])

  useEffect(() => {
    const calculatedNumberOfPossibleActiveUsersAfterImport = importAllUsers
      ? totalNumberOfUsers - activeUsers.filter(user => user.status !== 'ACTIVE').length
      : activeUsers.filter(user => user.status === 'ACTIVE').length + selectedUsers.length
    setTotalUsersAfterImport(calculatedNumberOfPossibleActiveUsersAfterImport)
  }, [totalNumberOfUsers, importAllUsers, activeUsers.length, selectedUsers.length])

  useEffect(() => {
    if (totalUsersAfterImport > 0) {
      getUpcomingInvoiceInfo(totalUsersAfterImport)
    }
  }, [totalUsersAfterImport])

  useEffect(() => {
    if (totalNumberOfUsers > AUTOMATIC_USERS_IMPORT_LIMIT) {
      setImportAllUsers(false)
      setShouldEnableImportAll(featureFlags.includes(FeatureFlagEnum.importUsers))
    } else {
      setShouldEnableImportAll(true)
    }
  }, [featureFlags, totalNumberOfUsers, activeUsers.length])

  useEffect(() => {
    if (platform === 'slack' && slackUserDetails) {
      fetchFromSlackApi(isBoolean(authCompany?.shouldImportGuestUsers) ? authCompany?.shouldImportGuestUsers : false)
    }
  }, [slackUserDetails])

  const getUpcomingInvoiceInfo = async (noOfUsersToImport: number) => {
    if (noOfUsersToImport === 0) {
      return
    }
    setIsLoadingUpcomingInvoiceInfo(true)
    try {
      const info = await Api.post('/stripe/upcoming-invoice-information', {
        quantity: noOfUsersToImport,
        date: Math.ceil(new Date().getTime() / 1000),
      })
      if (info) {
        setUpcomingInvoiceInfo(info)
        setIsLoadingUpcomingInvoiceInfo(false)
      }
    } catch (error) {
      setIsLoadingUpcomingInvoiceInfo(false)
    }
  }

  const getSlackUserDetails = async (id = authUser.id) => {
    try {
      const response = await getSlackDetailsQuery({ variables: { id }})
      if (!response.data?.slackId) {
        throw new Error('No slack user details found')
      }
      setSlackUserDetails(response.data.slackId)
    } catch (err) {
      // Retry with unprefixed id
      // TODO: @slobodan remove this after migration
      if (id === authUser.id) {
        return getSlackUserDetails(authUser.id.replace('slack-', ''))
      }
      return err
    }
  }

  const setSteps = (step: ImportUsersStepEnum, activeStep: number) => {
    setStep(step)
    setCurrentStep(activeStep)
  }

  const checkForTokenExistence = (platform: Platform) => {
    try {
      let tokens
      switch (platform) {
        case 'microsoft': {
          tokens = msAuth.getWebAppTokens()
          break
        }
        case 'slack': {
          tokens = slackAuth.getBotToken()
          break
        }
        case 'google': {
          tokens = googleAuth.getAccessToken()
          break
        }
      }
      return Boolean(tokens)
    } catch (e) {
      return false
    }
  }

  const getMsNumberOfUsers = async (accessToken, teamId: string | null=null) => {
    const numberOfUsers = await msAuth.getTotalUsers(accessToken as string, teamId)
    setTotalNumberOfUsers(numberOfUsers)
  }

  const getBasicMsToken = () => {
    setShowMsLoginRequiredModal(false)
    msAuth.signin(['user.readbasic.all', 'team.readbasic.all'])
      .then(([tokens, msUser, tenantId]) => {
        if (tenantId !== msTenantId || `microsoft-${msUser.id}` !== authUser.id) {
          wrongMicrosoftAccount()
          return
        }
        return getMsNumberOfUsers(tokens.accessToken, authCompany.msTeamId === msTenantId ? null : authCompany.msTeamId)
      })
      .catch((error) => {
        Sentry.captureException(JSON.stringify(error))
        errorNotificationHandler(error, 'microsoft', getBasicMsToken)
      })
  }

  const wrongMicrosoftAccount = () => {
    msAuth.removeTokens()
    notification.error({
      message: formatMessage({ id: 'error.microsoft.wrongAccount' }),
      description: <div>
        <Paragraph>{formatMessage({id: 'errors.microsoft.wrongMicrosoftAccountDescription'}, { email: authUser.email })}</Paragraph>
        <Button onClick={() => getBasicMsToken()}>{formatMessage({id: 'error.microsoft.logInWithDifferentAccount'})}</Button>
      </div>,
      duration: 0,
    })
  }

  const checkAutoImportAutomatically = (value: boolean) => {
    if (!value) return

    if (!sessionStorage.getItem('msWebAppTokensExpiration') || isAfter(new Date(), new Date(sessionStorage.getItem('msWebAppTokensExpiration') as string))) {
      setShowMsLoginAdditionalScopesRequiredModal(true)
    } else {
      const msWebAppTokensAccessToken = sessionStorage.getItem('msWebAppTokensAccessToken') as string
      const scopes = msAuth.getPermissions(msWebAppTokensAccessToken)
      if (!scopes.toLowerCase().includes('group.read.all')) {
        return setShowMsLoginAdditionalScopesRequiredModal(true)
      }
    }
  }

  const getWebAppTokens = async (tenantId: string) => {
    try {
      await msAuth.getMSWebAppTokens(tenantId, addMinutes(new Date(), 15).toString(), 'offline_access user.read group.read.all')
      setImportUsersAutomatically(importUsersAutomatically)
      setShowMsLoginAdditionalScopesRequiredModal(false)
    } catch (error) {
      logger.warning(error)
      setShowMsLoginAdditionalScopesRequiredModal(false)
      setMsAdminConsentRequired(true)
    }
  }

  const getTotalUsersInMicrosoftTenant = async () => {
    try {
      const tenantId = await Api.get<string>('/microsoft/get-tenant')
      setMsTenantId(tenantId)

      logger.debug(tenantId)
      logger.debug(authCompany.msTeamId || 'NO TEAM ID')

      const accessToken = (await msAuth.refreshTokens()).accessToken
      const teamId = authCompany.msTeamId && authCompany.msTeamId !== tenantId ? authCompany.msTeamId : null
      await getMsNumberOfUsers(accessToken, teamId)
    } catch(err) {
      if (err?.data?.error?.code === 'Authorization_RequestDenied') {
        setMsAdminConsentRequired(true)
        setShowAdminConsentWarning(true)
        return
      }
      if (err?.data?.error?.code === 'InvalidAuthenticationToken'
      || err?.includes('FAILED CODE OR TOKEN FETCH')
      || err?.includes('invalid_grant')
      || err?.includes('invalid_request') && err?.includes('refresh_token')
      ) {
        return setShowMsLoginRequiredModal(true)
      }

      captureException(err)
      errorNotificationHandler(err, 'microsoft', () => { getTotalUsersInMicrosoftTenant() })
      setMsAdminConsentRequired(true)
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleErrorNotification = (error: any, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'notifications.handleSubmitError' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'error.generic' }),
      description,
      duration: 0,
    })
  }

  const fetchSlackUsersInSystem = async (nextToken?: string, limit = 200, slackUsers: ISlackIdUser[] = []) => {
    const usersInSystem = await slackIdsQuery({ variables: { nextToken, limit } })
    if (!usersInSystem.data || usersInSystem.error) throw usersInSystem.error
    const users = slackUsers.concat(usersInSystem.data.slackIds.users)

    if (usersInSystem.data.slackIds.nextToken) {
      return await fetchSlackUsersInSystem(usersInSystem.data.slackIds.nextToken, limit, users)
    }

    return users
  }

  const fetchFromSlackApi = async (shouldImportGuestUsers = true): Promise<void> => {
    try {
      let slackUsers: ISlackUser[] = []
      try {
        slackAuth.getBotToken()
        slackUsers = await slackAuth.getUsersList()
        // this is preventing the case when user manually changes token in local storage(and thus pulls users from other team):
        if (slackUsers[0].team_id !== slackUserDetails?.slackTeamId) {
          logger.error('WRONG SLACK TEAM ID', {slackUsers: JSON.stringify(slackUsers), slackUserDetails: JSON.stringify(slackUserDetails)})
          wrongSlackAccount()
          return
        }
      } catch (error) {
        if (error?.error === 'not_authed' || error?.error === 'invalid_auth' || error?.error === 'missing_scope' || error?.error === 'token_revoked') {
          setShowSlackLoginRequiredModal(true)
        } else if(error?.message === 'no_tokens') {
          errorNotificationHandler(error, 'slack', getSlackTokens)
        } else {
          handleErrorNotification({message: `Slack error: ${error?.error}${error.needed ? ` ${error.needed}` : ''}`})
        }
        return
      }
      const activeUsers = slackUsers
        .filter(user => !user.deleted)
        .filter(user => !user.is_bot)
        .filter(user => !user.is_ultra_restricted)
        .filter(user => user.id !== 'USLACKBOT')

      if (shouldImportGuestUsers) {
        setTotalNumberOfUsers(activeUsers.filter(user => !user.is_restricted).length)
      } else {
        setTotalNumberOfUsers(activeUsers.length)
      }

      const usersInSystem = await fetchSlackUsersInSystem()

      const uniqueList = activeUsers.filter(user => {
        const isUserExists = usersInSystem.find(u => u.slackUserId === user.id)
        if (!isUserExists) {
          return user
        }

        return null
      })

      setNotImportedUsers(uniqueList.map(user => {
        return {
          label: `${user.real_name} (${user.name})`,
          value: JSON.stringify({
            id: user.id,
            name: user.name,
            realName: user.real_name,
          }),
        }
      }))
    } catch (error) {
      logger.error('ERROR FETCH FROM SLACK API', error)
    }
  }

  const handleChangeGuestUsersImport = async (v: boolean) => {
    await fetchFromSlackApi(v)
  }

  const wrongSlackAccount = () => {
    slackAuth.removeTokens()
    notification.error({
      message: formatMessage({ id: 'error.slack.wrongAccount' }),
      description: <div>
        <Paragraph>{formatMessage({id: 'errors.slack.wrongSlackAccountDescription'})}</Paragraph>
        <Button onClick={() => getSlackTokens()}>{formatMessage({id: 'error.slack.logInWithDifferentAccount'})}</Button>
      </div>,
      duration: 0,
    })
    setShowSlackLoginRequiredModal(false)
  }

  const getSlackTokens = () => {
    slackAuth.signin(
      ['chat:write', 'commands', 'team:read', 'users:read', 'users:read.email'],
      ['channels:write', 'groups:write', 'users:read', 'team:read', 'channels:read', 'groups:read', 'users.profile:write']
    ).then(async (response) => {
      if ((!response.enterprise && response.team.id !== authCompany.organizationId) || (response.enterprise && response.enterprise?.id !== authCompany.organizationId)) {
        logger.error('WRONG SLACK ACCOUNT', JSON.stringify(response))
        wrongSlackAccount()
        return
      }
      slackAuth.setBotToken(response.access_token)
      track('IMPORT_USERS_LOGIN_CLICKED', { platform }) // TODO: add this tracking for google platform
      slackAuth.setUserToken(response.authed_user.access_token)
      try {
        await Api.post('/slack/update-tokens', {
          token: response.authed_user.access_token,
          botToken: response.access_token,
        })
      } catch (error) {
        logger.error('UPDATE TOKEN ERROR', error.response)
      }
      setShowSlackLoginRequiredModal(false)
      await fetchFromSlackApi(isBoolean(authCompany?.shouldImportGuestUsers) ? authCompany?.shouldImportGuestUsers : true)
    }).catch(error => {
      logger.warning('SLACK SIGNIN ERROR', error)
      handleErrorNotification({message: 'To proceed, please login to your Slack account'}, undefined, 'Missing Slack Scopes')
    })
  }

  const getGoogleTokens = (withConsent = false) => {
    googleAuth.signin(withConsent).then(() => {
      setShowGoogleLoginRequiredModal(false)
      wait(500)
      shouldEnableGoogleAdminPermissions ? fetchFromGoogleApiWithFeatureFlag() : fetchFromGoogleApi(true)
    })
  }

  const fetchFromGoogleApi = async (ignorePermission = false): Promise<void> => {
    const googleTokenExpiration = sessionStorage.getItem('googleTokenExpiration')
    if (!googleTokenExpiration || isAfter(new Date(), new Date(googleTokenExpiration))) {
      setShowGoogleLoginRequiredModal(true)
    } else {
      setShowGoogleLoginRequiredModal(false)
      const googleScopesResponse = await getGoogleScopesQuery({ variables: { accessToken: googleAuth.getAccessToken() }})
      if (!googleScopesResponse.data || googleScopesResponse.error) throw googleScopesResponse.error
      const googleApiScopes = googleScopesResponse.data.getGoogleTokenInfo.scopes as string[]
      const vtGooogleIntegrationScopes = googleScopesResponse.data.getGoogleIntegrationSettings?.scopes

      setGoogleScopes({
        googleApiScopes,
        vtGooogleIntegrationScopes,
      })
      if (googleApiScopes?.includes(GOOGLE_READ_USER_PERMISSION) && !vtGooogleIntegrationScopes?.includes(GOOGLE_READ_USER_PERMISSION)) {
        const userInfo = googleAuth.getSignedInUser()
        await Api.post('/core/event', {
          eventType: 'GOOGLE_INTEGRATION',
          eventGroup: 'INTEGRATION',
          scope: googleApiScopes.join(' '),
          domainName: userInfo.hd,
        })
      }

      try {
        let googleUsers
        if (!ignorePermission && googleApiScopes?.includes(GOOGLE_READ_USER_PERMISSION)) {
          await googleAuth.tokenInfo()
          const token = googleAuth.getAccessToken()
          googleUsers = await Api.post('/google/get-google-admin-user-list', {
            accessToken: token,
          })
        } else {
          googleUsers = await googleAuth.getUserListForImportFull()
        }

        setTotalNumberOfUsers(googleUsers.length as number || 0)

        const usersInSystem = await fetchAllUsersInSystem()

        const uniqueList = googleUsers.filter(user => {
          const isUserExists = usersInSystem.find(u => u.status !== 'DELETED' && u.id === user.id)
          if (!isUserExists) {
            return user
          }

          return null
        })

        setNotImportedUsers(uniqueList.map(user => {
          return {
            label: `${user.name} (${user.name})`,
            value: JSON.stringify({
              id: user.id,
              name: user.name,
              realName: user.name,
            }),
          }
        }) as IUserToImportData[])

      } catch (error) {
        Sentry.captureException(JSON.stringify(error))

        if (error.message.includes('Not Authorized to access this resource/api') && googleApiScopes?.includes(GOOGLE_READ_USER_PERMISSION)) {
          notification.error({
            message: 'Google API Error',
            description: <>
              <Paragraph>{formatMessage({id: 'components.importUsersForm.googleAPIErrorMessage'})}</Paragraph>
              <Paragraph code copyable>{error.message}</Paragraph>
              {error.code && <Paragraph>{error.code}</Paragraph>}
            </>,
            duration: 0,
          })
          return
        }

        if (error.message.includes('400') && googleApiScopes?.includes(GOOGLE_READ_USER_PERMISSION)) {
          return fetchFromGoogleApi(true)
        }

        if (error.message === 'GOOGLE_DIRECTORY_SHARING_DISABLED' || error.message === 'GOOGLE_DIRECTORY_PERMISSION_DENIED') {
          track('GOOGLE_ERROR_DIRECTORY_SHARING_DISABLED', {page: 'ImportUsersPage', platform: 'google', error: error.message})
          setShowEnableGoogleApiModal(true)
          return
        }

        if (error.message === 'no_tokens') {
          errorNotificationHandler(error, 'google', getGoogleTokens)
          return
        }

        handleErrorNotification({message: `Google error: ${error?.error}${error.needed ? ` ${error.needed}` : ''}`})
      }

    }
  }

  const fetchFromGoogleApiWithFeatureFlag = async (): Promise<void> => {
    const googleTokenExpiration = sessionStorage.getItem('googleTokenExpiration')
    if (!googleTokenExpiration || isAfter(new Date(), new Date(googleTokenExpiration))) {
      setShowGoogleLoginRequiredModal(true)
    } else {
      setShowGoogleLoginRequiredModal(false)
      const googleScopesResponse = await getGoogleScopesQuery({ variables: { accessToken: googleAuth.getAccessToken() }})
      if (!googleScopesResponse.data || googleScopesResponse.error) throw googleScopesResponse.error
      const googleApiScopes = googleScopesResponse.data.getGoogleTokenInfo.scopes as string[]
      const vtGooogleIntegrationScopes = googleScopesResponse.data.getGoogleIntegrationSettings?.scopes

      setGoogleScopes({
        googleApiScopes,
        vtGooogleIntegrationScopes,
      })
      if (googleApiScopes?.includes(GOOGLE_READ_USER_PERMISSION) && !vtGooogleIntegrationScopes?.includes(GOOGLE_READ_USER_PERMISSION)) {
        const userInfo = googleAuth.getSignedInUser()
        await Api.post('/core/event', {
          eventType: 'GOOGLE_INTEGRATION',
          eventGroup: 'INTEGRATION',
          scope: googleApiScopes.join(' '),
          domainName: userInfo.hd,
        })
      }

      try {
        await googleAuth.tokenInfo()
        const token = googleAuth.getAccessToken()
        await getGoogleAdminDirectoryUsersList(token)
      } catch (error) {
        Sentry.captureException(JSON.stringify(error))

        if (
          error?.response?.data?.reason === 'forbidden' ||
          error.response?.data?.message === 'Insufficient Permission' || 
          error.message.includes('Insufficient Permission') || 
          (error.message.includes('Not Authorized to access this resource/api') && !googleApiScopes?.includes(GOOGLE_READ_USER_PERMISSION))) 
        {
          handleInsufficientGoogleAdminPermissions()
          return
        }

        if (error.message.includes('Not Authorized to access this resource/api') && googleApiScopes?.includes(GOOGLE_READ_USER_PERMISSION)) {
          notification.error({
            message: 'Google API Error',
            description: <>
              <Paragraph>{formatMessage({id: 'components.importUsersForm.googleAPIErrorMessage'})}</Paragraph>
              <Paragraph code copyable>{error.message}</Paragraph>
              {error.code && <Paragraph>{error.code}</Paragraph>}
            </>,
            duration: 0,
          })
          return
        }

        if (error.message.includes('400') && googleApiScopes?.includes(GOOGLE_READ_USER_PERMISSION)) {
          return fetchFromGoogleApiWithFeatureFlag()
        }

        if (error.message === 'GOOGLE_DIRECTORY_SHARING_DISABLED' || error.message === 'GOOGLE_DIRECTORY_PERMISSION_DENIED') {
          track('GOOGLE_ERROR_DIRECTORY_SHARING_DISABLED', {page: 'ImportUsersPage', platform: 'google', error: error.message})
          setShowEnableGoogleApiModal(true)
          return
        }

        if (error.message === 'no_tokens') {
          errorNotificationHandler(error, 'google', getGoogleTokens)
          return
        }

        handleErrorNotification({message: `Google error: ${error?.error}${error.needed ? ` ${error.needed}` : ''}`})
      }

    }
  }

  const getGoogleAdminDirectoryUsersList = async (accessToken: string) => {
    const googleUsers = await Api.post('/google/get-google-admin-user-list', { accessToken })

    setTotalNumberOfUsers(googleUsers.length as number || 0)

    const usersInSystem = await fetchAllUsersInSystem()

    const uniqueList = googleUsers.filter(user => {
      const isUserExists = usersInSystem.find(u => u.status !== 'DELETED' && u.id === user.id)
      if (!isUserExists) {
        return user
      }

      return null
    })

    setNotImportedUsers(uniqueList.map(user => {
      return {
        label: `${user.name} (${user.name})`,
        value: JSON.stringify({
          id: user.id,
          name: user.name,
          realName: user.name,
        }),
      }
    }) as IUserToImportData[])
  }

  const handleInsufficientGoogleAdminPermissions = async () => {
    try {
      const existingGoogleAdminToken = await Api.get('/google/admin-token')
      getGoogleAdminDirectoryUsersList(existingGoogleAdminToken as string)
    } catch (err) {
      if (err.response.data.code === NotFound.code) {
        let hash = sessionStorage.getItem('adminTokenHash')
        if (!hash) {
          hash = await Api.post('/google/admin-token-hash', {})
          sessionStorage.setItem('adminTokenHash', hash as string)
        }
        setShowRequestGoogleAdminPermissionsModal(true)
      }
    }
  }

  const getGoogleAdminExtraPermissionsLink = () => {
    return `https://app.vacationtracker.io/vt-google-permissions#${sessionStorage.getItem('adminTokenHash')}`
  }

  const fetchActiveUsers = async () => {
    const usersInSystem = await fetchAllUsersInSystem()
    setActiveUsers(usersInSystem)
  }

  const fetchAllUsersInSystem = async (nextToken?: string, limit = 200, shortUsers: IGetUserListShort[] = []): Promise<IGetUserListShort[]> => {
    const usersInSystem = await getAllUserListShortPaginatedQuery({ variables: { nextToken, limit } })
    if (!usersInSystem.data || usersInSystem.error) throw usersInSystem.error

    const users = shortUsers.concat(usersInSystem.data.getAllUserListPaginated.users as IGetUserListShort[])

    if (usersInSystem.data.getAllUserListPaginated.nextToken) {
      return await fetchAllUsersInSystem(usersInSystem.data.getAllUserListPaginated.nextToken, limit, users)
    }

    // We do not want deleted users as they are not in the directory anymore
    return users.filter(user => user.status !== 'DELETED')
  }

  const onSearch = async (value: string) => {
    if (platform === 'microsoft') {
      return await microsoftOnUserSearch(value)
    }
    return []
  }

  const microsoftOnUserSearch = async (value: string): Promise<IAutocompleteOption[]> => {
    if (value.length < 1) {
      return []
    }
    try {
      const msTeamId = authCompany.msTeamId && authCompany.msTeamId !== msTenantId ? authCompany.msTeamId : null
      const msUsers = await msAuth.searchUserByEmailAndDisplayname(value, sessionStorage.getItem('msTokensAccessToken') as string, msTeamId)
      const filteredUsers = filterUsers('microsoft', msUsers)
      return filteredUsers.map(user => {
        const userName = getName(user)
        const userEmail = getEmail(user)

        return {
          label: `${userName} (${userEmail})`,
          value: JSON.stringify({
            id: user.id,
            name: userName,
            mail: userEmail,
          }),
        }
      })
    } catch(err) {
      if (err?.data?.error?.code === 'InvalidAuthenticationToken'
      || err?.includes('FAILED CODE OR TOKEN FETCH')
      || err?.includes('invalid_grant')
      || err?.includes('invalid_request') && err?.includes('refresh_token')
      ) {
        setShowMsLoginRequiredModal(true)
        return []
      }

      captureException(err)

      errorNotificationHandler(err, 'microsoft', () => { getTotalUsersInMicrosoftTenant() })
      setMsAdminConsentRequired(true)
      return []
    }
  }

  const filterUsers = (platform: string, msUsers: IMicrosoftUser[]): IMicrosoftUser[] => {
    if (platform === 'microsoft') {
      return msUsers.map(msUser => {
        const existingUser = activeUsers.find(u => u.id === vtUserId(msUser.id))
        const alreadySelected = selectedUsers.find(u => u.id === msUser.id)
        if ((!existingUser || existingUser.status === 'DELETED') && !alreadySelected) {
          return msUser
        }

        return false
      }).filter(Boolean) as IMicrosoftUser[]
    }

    return []
  }

  const onFinish = async (importAllUsers: boolean, selectedUsers: IAutocompleteOptionValue[], configData: any) => {

    let newUsers = selectedUsers.map(user => {
      return {
        userId: user.id,
        name: user.name,
        email: user.mail,
        ...(user.imageUrl && { imageUrl: user.imageUrl }),
      }
    })
    if (platform === 'microsoft' && importAllUsers && isMsAdminConsentRequired && adminConsentUrl) {
      return setShowAdminConsentWarning(true)
    }

    setShowAdminConsentWarning(false)
    await form.validateFields()
    if (importAllUsers) {
      track('IMPORT_USERS_IMPORT_ALL', { platform, totalNumberOfUsers })
    } else {
      track('IMPORT_USERS_IMPORT_SELECTED', { platform, totalNumberOfUsers, selectedNumberOfUsers: noOfUsersToImport })
    }
    if (platform === 'microsoft') {
      return await importMicrosoftUsers({
        ...configData,
        sendWelcomeMessageTo: Boolean(selectedWelcomeMessageOpt?.platform !== 'none'),
        newUsers,
        importAllUsers: importAllUsers,
      } as IImportData, importAllUsers)
    } else if (platform === 'slack') {
      newUsers = importAllUsers ? notImportedUsers.map(user => JSON.parse(user.value).id) : selectedUsers.map(user => user.id)
      importSlackUsers({
        ...configData,
        sendWelcomeMessageTo: Boolean(selectedWelcomeMessageOpt?.platform !== 'none'),
        newUsers,
        organizationId: authCompany.organizationId,
        importAllUsers: importAllUsers,
        totalUsers: totalNumberOfUsers,
      })
    }  else if (platform === 'google') {
      newUsers = importAllUsers ? notImportedUsers.map(user => JSON.parse(user.value).id.substring(7)) : selectedUsers.map(user => user.id.substring(7))
      importGoogleUsers({
        ...configData,
        sendWelcomeMessageTo: Boolean(selectedWelcomeMessageOpt?.platform !== 'none'),
        newUsers,
        organizationId: authCompany.organizationId,
        importAllUsers: importAllUsers,
        totalUsers: totalNumberOfUsers,
      })
    }
  }

  const importMicrosoftUsers = async (importData: IImportData, importAllUsers: boolean) => {
    if (!importData) return

    try {
      const accessToken = sessionStorage.getItem('msWebAppTokensAccessToken') || sessionStorage.getItem('msTokensAccessToken') as string
      const scopes = msAuth.getPermissions(accessToken)
      if (Boolean(importUsersAutomatically) && !scopes.toLowerCase().includes('group.read.all')) {
        return setShowMsLoginAdditionalScopesRequiredModal(true)
      }
      const refreshToken = sessionStorage.getItem('msWebAppTokensRefreshToken') || sessionStorage.getItem('msTokensRefreshToken') as string

      const body: IImportUsersEventBody & {msTeamId: string; token: string; version: number} = {
        eventType: 'IMPORT_USERS',
        eventGroup: 'BULK_ACTIONS',
        users: importData.newUsers || [],
        organizationId: authCompany.organizationId,
        msTeamId: authCompany.msTeamId as string,
        token: accessToken,
        importAllUsers: importData.importAllUsers,
        totalUsers: noOfUsersToImport,
        importUsersAutomatically: Boolean(importUsersAutomatically),
        shouldProrate: importData.shouldProrate,
        prorateLeaveTypes: importData.shouldProrate ? importData.selectedLeaveTypes : [],
        defaultLocation: importData.selectedLocation,
        defaultDepartment: importData.selectedDepartment,
        announceNewUsers: importData.sendWelcomeMessageTo,
        refreshToken,
        version: 2,
      }
      if (!importAllUsers && body.importUsersAutomatically !== Boolean(authCompany?.importUsersAutomatically)) {
        delete body.importUsersAutomatically
      }
      const response = await Api.post('/core/event', body)

      setSelectedUsers([])

      showNotification(importData.newUsers, importData.importAllUsers, response.correlationId as string, authCompany.platform)

      if (importAllUsers) {
        setEventsNotifications({
          ...eventsNotifications,
          [response.correlationId]: {
            eventType: 'IMPORT_USERS',
            payload: {
              totalUsers: totalNumberOfUsers - activeUsers.length,
            },
          },
        })
      }
      // Send user to the list of all users
      history.push('/app/users')
    } catch (error) {
      track('IMPORT_USERS_ERROR', { platform, error })
      showErrorNotification(error)
    }
  }

  const importSlackUsers = async (importData) => {
    if (!importData) return
    const body: IImportUsersEventBody = {
      eventType: 'IMPORT_USERS',
      eventGroup: 'BULK_ACTIONS',
      users: importData.newUsers,
      organizationId: authCompany.organizationId,
      importAllUsers: importData.importAllUsers,
      totalUsers: noOfUsersToImport,
      importUsersAutomatically: importUsersAutomatically,
      shouldProrate: importData.shouldProrate,
      prorateLeaveTypes: importData.shouldProrate ? importData.selectedLeaveTypes : [],
      shouldImportGuestUsers: importData.shouldImportGuestUsers,
      defaultLocation: importData.selectedLocation,
      defaultDepartment: importData.selectedDepartment,
      announceNewUsers: importData.sendWelcomeMessageTo,
    }
    
    if (!importAllUsers && body.importUsersAutomatically !== Boolean(authCompany?.importUsersAutomatically)) {
      delete body.importUsersAutomatically
    }

    try {
      const response = await Api.post('/core/event', body)
      showNotification(importData.newUsers, importData.importAllUsers, response.correlationId as string, authCompany.platform)
      // Send user to the list of all users
      history.push('/app/users')
    } catch (error) {
      track('IMPORT_USERS_ERROR', { platform, error })
      logger.error('error', error)
    }
  }

  const importGoogleUsers = async (importData) => {
    if (!importData) return
    const googleTokenExpiration = sessionStorage.getItem('googleTokenExpiration')
    if (!googleTokenExpiration || isAfter(new Date(), new Date(googleTokenExpiration))) {
      return setShowGoogleLoginRequiredModal(true)
    }
    await googleAuth.tokenInfo()
    const userInfo = googleAuth.getSignedInUser()
    const body: IImportUsersEventBody & {token: string; domainName?: string}= {
      eventType: 'IMPORT_USERS',
      eventGroup: 'BULK_ACTIONS',
      users: importData.newUsers || [],
      organizationId: authCompany.organizationId,
      token: googleAuth.getAccessToken(),
      refreshToken: googleAuth.getRefreshToken(),
      importAllUsers: importData.importAllUsers,
      totalUsers: noOfUsersToImport,
      importUsersAutomatically: importUsersAutomatically,
      shouldProrate: importData.shouldProrate,
      prorateLeaveTypes: importData.selectedLeaveTypes,
      defaultLocation: importData.selectedLocation,
      defaultDepartment: importData.selectedDepartment,
      announceNewUsers: importData.sendWelcomeMessageTo,
      domainName: googleScopes?.googleApiScopes?.includes('https://www.googleapis.com/auth/admin.directory.user.readonly') ? userInfo.hd : undefined,
    }

    if (!importAllUsers && body.importUsersAutomatically !== Boolean(authCompany?.importUsersAutomatically))  {
      delete body.importUsersAutomatically
    }

    try {
      const response = await Api.post('/core/event', body)
      showNotification(importData.newUsers, importData.importAllUsers, response.correlationId as string, authCompany.platform)
      // Send user to the list of all users
      history.push('/app/users')
    } catch (error) {
      logger.error('error', error)
    }
  }

  const showErrorNotification = (error) => {
    const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: formatMessage({ id: 'error.generic' }),
      description: errorDescription,
      duration: 0,
    })
  }

  const showNotification = (users: IImportUser[], importAllUsers: boolean, correlationId: string, platform: string) => {
    if (importAllUsers) {
      notification.open({
        message: formatMessage({ id: 'users.import.description' }),
        duration: 15,
      })
      return
    }

    notification.open({
      key: correlationId,
      message: users.length === 1 ?
        formatMessage({ id: 'users.importOne.InProgress' }) :
        formatMessage({ id: 'users.importMany.InProgress' }),
      description: <Progress percent={0} size="small" />,
      duration: 0,
    })
    setCorrelationId(correlationId)
    setEventsNotifications({
      ...eventsNotifications,
      [correlationId]: {
        eventType: 'USER_STATUS_CHANGED',
        payload: {
          users: users.map(user => {
            if (platform === 'google') {
              return `${platform}-${user}`
            } else {
              return `${platform}-${user.userId}`
            }
          }),
          totalUsers: users.length,
        },
      },
    })
  }

  const errorNotificationHandler = (error: Error, platform: Platform, onClickHandler: Function) => {
    const isTokenError = error.message === 'no_tokens'
    const errorMessageDescription = isTokenError ? formatMessage({ id: 'error.token.description' }, {platform} ) : error.message
    notification.error({
      message: formatMessage({ id: 'error.generic' } ),
      description: errorMessageDescription,
      key: 'error-notification-handler',
      btn: isTokenError &&
        <Button onClick={() => {
          onClickHandler()
          notification.destroy('error-notification-handler')
        }}>
          {formatMessage({ id: 'error.token.button' }, {platform} )}
        </Button>,
      duration: 0,
    })
  }

  const onSetImportAllUsers = (v) => {
    setSelectedUsers([])
    setImportAllUsers(v)
  }

  const computeSelectedUsersNumberForStatistics = ({
    importAllUsers,
    totalNumberOfUsers,
    activeUsersLength,
    selectedUsersLength,
  }) => {
    const diff = totalNumberOfUsers - activeUsersLength
    if (diff < 0) {
      return 0
    }
    if (importAllUsers) {
      return diff
    }

    if (selectedUsersLength < 0) {
      return 0
    }

    return selectedUsersLength
  }

  const submitBilling = async (seats) => {
    let response
    try {
      response = await Api.post('/core/event', {
        eventType: 'BILLING_UPDATED',
        eventGroup: 'BILLING',
        paymentProcessor: 'stripe',
        billingName: authCompany?.billing?.billingName,
        billingEmails: authCompany?.billing?.billingEmails,
        billingType: authCompany?.billing?.billingType,
        keepLegacyPlan: LEGACY_PLAN_IDS.includes(authCompany?.billing?.stripePriceId as string),
        subscriptionPlan: authCompany?.billing?.nextSubscriptionPlan || authCompany?.billing?.subscriptionPlan,
        activeUsers: getJustActiveUsersNumber(activeUsers),
        // Only for the legacy plan, because we don't have info about period for legacy companies.
        subscriptionPeriod: authCompany?.billing?.nextSubscriptionPeriod
        || authCompany?.billing?.subscriptionPeriod
        || (LEGACY_PLAN_IDS.includes(authCompany?.billing?.stripePriceId as string) && authCompany?.billing?.stripePriceId === 'price_1HyIJYCZGLYlCm3DSBaYeqVA' && 'annual'),
        ...(isNumber(seats) && { seats }),
      })

      setCorrelationId(response.correlationId)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'billing.updateInProgress' }),
        icon: <LoadingOutlined />,
        duration: 0,
      })
      setActionNotifications([...actionNotifications, response.correlationId])
    } catch (error) {
      handleBillingUpdateError(error, response?.correlationId)
    }
  }

  const handleBillingUpdateError = (error, correlationId?: string, title?: string): void => {
    const description = correlationId ?
      formatMessage({ id: 'notifications.error' }, { correlationId }) :
      error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
    notification.error({
      message: title ? title : formatMessage({ id: 'billing.updatedFaild' }),
      description,
      duration: 0,
    })
  }

  const getJustActiveUsersNumber = (activeUsers: IGetUserListShort[]): number => {
    return activeUsers.filter(user => user.status === 'ACTIVE').length
  }

  return <div className='main-content'>
    <div className="main-content-header">
      <div className="main-content-header-title">
        <span>
          <IntlMessages id="users.importUsers" />
        </span>
      </div>
    </div>
    <div className="main-content-body" data-testid="import-users-page-body">
      {
        (activeUsers.length > 0 && totalNumberOfUsers > 0) ? (
          <div>
            {totalNumberOfUsers !== activeUsers.length &&
            <Steps
              current={currentStep}
              style={{ paddingBottom: 35 }}
              items={[
                {
                  title: <IntlMessages id='components.importUsersForm.selectUsersStepName' />,
                },
                {
                  title: <IntlMessages id='app.configure' />,
                },
                {
                  title: <IntlMessages id='components.modal.confirm' />,
                },
              ]}
            />}
            <Row gutter={16} style={{ border: 'solid 1px #7f00ff', padding: 20, margin: 0 }}>
              <Col span={3}>
                <Progress type="circle" strokeWidth={10} percent={percentageOfImportedUsers} size={60} />
              </Col>
              <Col span={authCompany?.billing?.billingType === BillingTypesEnum.seatBased && isNumber(authCompany?.billing?.seats) ? 5 : 7}>
                <Statistic
                  title={<IntlMessages id="importUsers.statistics.imported" />}
                  value={activeUsers.length}
                  prefix={<UserOutlined />}
                  suffix={<sup>
                    <Text type='secondary' style={{ fontSize: 'smaller' }}>
                      <Tooltip title={getActiveUsersTooltipContent(activeUsers)}>
                        <InfoCircleOutlined />
                      </Tooltip>
                    </Text>
                  </sup>
                  }
                />
              </Col>
              {authCompany?.billing?.billingType === BillingTypesEnum.seatBased && isNumber(authCompany?.billing?.seats) &&
                <Col span={authCompany?.billing?.billingType === BillingTypesEnum.seatBased && isNumber(authCompany?.billing?.seats) ? 5 : 7}>
                  <Statistic
                    title={<IntlMessages id="billing.seats" />}
                    value={authCompany?.billing?.seats}
                    prefix={<UserOutlined />}
                    suffix={<sup>
                      <Text type='secondary' style={{ fontSize: 'smaller' }}>
                        <Tooltip title={<IntlMessages id="billing.seats.info" />}>
                          <InfoCircleOutlined />
                        </Tooltip>
                      </Text>
                    </sup>
                    }
                  />
                </Col>
              }
              <Col span={authCompany?.billing?.billingType === BillingTypesEnum.seatBased && isNumber(authCompany?.billing?.seats) ? 6 : 9}>
                <Statistic
                  title={<IntlMessages
                    id="importUsers.statistics.total"
                    values={{ directoryName: (platform === 'microsoft') ? 'Microsoft Active Directory' : 'User Directory' }} />
                  }
                  value={totalNumberOfUsers}
                  prefix={<UsergroupAddOutlined />}
                />
              </Col>
              <Col span={5}>
                <Statistic
                  title={<IntlMessages id="importUsers.form.showSelectedUsersAsNumber" />}
                  value={computeSelectedUsersNumberForStatistics({
                    totalNumberOfUsers,
                    importAllUsers,
                    activeUsersLength: activeUsers.length,
                    selectedUsersLength: selectedUsers.length,
                  })}
                  prefix={<UsergroupAddOutlined />}
                />
              </Col>
            </Row>
          </div>
        ) : (
          <Space>
            <Skeleton.Avatar size="large" shape="circle" active />
            <Skeleton.Button size="large" shape="square" style={{ width: '20vw'}} active />
            <Skeleton.Button size="large" shape="square" style={{ width: '20vw'}} active />
            <Skeleton.Button size="large" shape="square" style={{ width: '20vw'}} active />
          </Space>
        )
      }
      <Divider />
      {
        ((!totalNumberOfUsers || !activeUsers) && !isMsAdminConsentRequired) ? (
          <Skeleton paragraph={{ rows: 3 }} active />
        ) : (
          <>
            {step === ImportUsersStepEnum.selectUsers &&
              <ImportUsersPageForm
                totalNumberOfUsers={totalNumberOfUsers}
                platform={platform}
                showAdminConsentWarning={showAdminConsentWarning}
                selectedUsers={selectedUsers}
                setSelectedUsers={setSelectedUsers}
                notImportedUsers={notImportedUsers}
                setNotImportedUsers={setNotImportedUsers}
                isMsAdminConsentRequired={isMsAdminConsentRequired}
                adminConsentUrl={adminConsentUrl}
                onSubmit={onFinish}
                onSearchUsers={onSearch}
                setSteps={setSteps}
                activeStep={step}
                onSetImportUsersAutomatically={(value: boolean) => {
                  setImportUsersAutomatically(value)
                  if (platform === 'microsoft') {
                    checkAutoImportAutomatically(value)
                  }
                }}
                onSetImportAllUsers={onSetImportAllUsers}
                importAllUsers={importAllUsers}
                noOfUsersToImport={noOfUsersToImport}
                upcomingInvoiceInfo={upcomingInvoiceInfo}
                isLoadingUpcomingInvoiceInfo={isLoadingUpcomingInvoiceInfo}
                totalNumberOfUsersAfterImport={totalUsersAfterImport}
                shouldEnableImportAll={shouldEnableImportAll}
                shouldImportUsersAutomatically={importUsersAutomatically}
                submitBilling={submitBilling}
                numberOfActiveUsers={getJustActiveUsersNumber(activeUsers)}
              />
            }
            {(step === ImportUsersStepEnum.configure || step === ImportUsersStepEnum.confirm) &&
              <ConfigureImportUsersOptions
                activeStep={step}
                noOfUsersToImport={noOfUsersToImport}
                selectedUsers={selectedUsers}
                importAllUsers={importAllUsers}
                importUsersAutomatically={Boolean(importUsersAutomatically)}
                setSteps={setSteps}
                welcomeMessageOpts={welcomeMessageOpts}
                setWelcomeMessageOpts={setWelcomeMessageOpts}
                setSelectedWelcomeMessageOpt={setSelectedWelcomeMessageOpt}
                onChangeGuestUsersImport={handleChangeGuestUsersImport}
                setShowMsLoginAdditionalScopesRequiredModal={setShowMsLoginAdditionalScopesRequiredModal}
                onSubmit={onFinish}
                upcomingInvoiceInfo={upcomingInvoiceInfo}
                isLoadingUpcomingInvoiceInfo={isLoadingUpcomingInvoiceInfo}
                totalNumberOfUsersAfterImport={totalUsersAfterImport}
                isAnnualBilling={authCompany?.billing?.subscriptionPeriod === 'annual'}
              />
            }
          </>
        )
      }
    </div>
    {showMsLoginRequiredModal &&
      <Modal
        title={formatMessage({ id: 'error.microsoft.notificationsLoginRequiredTitle' })}
        open={showMsLoginRequiredModal}
        onOk={getBasicMsToken}
        onCancel={() => {
          setShowMsLoginRequiredModal(false)
          history.push('/app/users')
        }}
      >
        <p><IntlMessages id="microsoft.usersLoginRequiredDescription1" /></p>
        <p><IntlMessages id="microsoft.usersLoginRequiredDescription2" /></p>
      </Modal>
    }
    {showMsLoginAdditionalScopesRequiredModal &&
      <Modal
        title={formatMessage({ id: 'error.microsoft.notificationsLoginRequiredTitle' })}
        open={showMsLoginAdditionalScopesRequiredModal}
        onOk={() => { getWebAppTokens(msTenantId)} }
        onCancel={() => {
          setImportUsersAutomatically(false)
          setShowMsLoginAdditionalScopesRequiredModal(false)
        }}
      >
        <p><IntlMessages id="microsoft.usersLoginRequiredDescription1" /></p>
        <p><IntlMessages id="microsoft.usersLoginRequiredDescription2" /></p>
      </Modal>
    }
    {showSlackLoginRequiredModal &&
      <Modal
        title={formatMessage({ id: 'error.slack.usersLoginRequiredTitle' })}
        open={showSlackLoginRequiredModal}
        onOk={getSlackTokens}
        onCancel={() => {
          setShowSlackLoginRequiredModal(false)
          history.push('/app/users')
        }}
      >
        <p><IntlMessages id="slack.notificationsLoginRequiredDescription1" /></p>
        <p><IntlMessages id="slack.notificationsLoginRequiredDescription2" /></p>
      </Modal>
    }
    {showGoogleLoginRequiredModal &&
      <Modal
        title={formatMessage({ id: 'error.google.loginRequiredTitle' })}
        open={showGoogleLoginRequiredModal}
        onOk={() => getGoogleTokens(googleScopes?.googleApiScopes?.includes('https://www.googleapis.com/auth/admin.directory.user.readonly'))}
        onCancel={() => {
          setShowGoogleLoginRequiredModal(false)
          history.push('/app/users')
        }}
      >
        <p><IntlMessages id="error.google.usersLoginRequiredDescription1" /></p>
        <p><IntlMessages id="error.google.usersLoginRequiredDescription2" /></p>
      </Modal>
    }
    {showEnableGoogleApiModal &&
      <Modal
        title={formatMessage({ id: 'error.google.directoryApiDisabledTitle' })}
        open={showEnableGoogleApiModal}
        footer={null}
        onCancel={() => {
          setShowEnableGoogleApiModal(false)
        }}
      >
        <Paragraph><IntlMessages id="error.google.directoryApiDisabledP1" /></Paragraph>
        <Paragraph><IntlMessages id="error.google.directoryApiDisabledP2" /></Paragraph>
        <Paragraph copyable={{text: supportLink}}><a href={supportLink} target="_blank" rel="noopener noreferrer">{supportLink}</a></Paragraph>
        <Paragraph><IntlMessages id="error.google.directoryApiDisabledP4" /></Paragraph>
      </Modal>
    }
    {showRequestGoogleAdminPermissionsModal &&
      <Modal
        title={formatMessage({ id: 'connect.google.sharedPermissionsRequired' })}
        open={showRequestGoogleAdminPermissionsModal}
        footer={null}
        onCancel={() => {
          setShowRequestGoogleAdminPermissionsModal(false)
        }}
      >
        <Paragraph><IntlMessages id="connect.google.sharedPermissionsRequiredDescription" /></Paragraph>
        <Paragraph copyable={{text: getGoogleAdminExtraPermissionsLink()}}>
          <a target="_blank" rel="noopener noreferrer"><IntlMessages id="app.link" /></a>
        </Paragraph>
      </Modal>
    }
    <Modal
      title={formatMessage({ id: 'microsoft.notificationsLoginRequiredTitle' })}
      open={Boolean(authCompany?.platform === 'microsoft'
        && isMsAdminConsentRequired
        && importUsersAutomatically
        && adminConsentUrl
      )}
      onCancel={() => {
        setMsAdminConsentRequired(false)
      }}
      footer={null}
    >
      <Paragraph>
        <IntlMessages id="components.importUsersForm.consent.line1" />
      </Paragraph>
      <Paragraph copyable={{ text: adminConsentUrl as string}}>
        <a href={adminConsentUrl as string}>{adminConsentUrl}</a>
      </Paragraph>
    </Modal>
  </div>
}

export default ImportUsersPage
