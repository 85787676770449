import { IError, IErrorData, IExtendedError } from './types'


export function generateError (error: IError, errorData?: IErrorData): IExtendedError {
  const err: IExtendedError = new Error(error.message)
  err.name = error.code

  if (errorData) {
    err.data = errorData
  }

  return err
}