import React, { RefObject } from 'react'
import { useIntl } from 'react-intl'
import { Form, Input } from 'antd'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { OTPRef } from 'antd/es/input/OTP'

interface MFACodeInputProps {
  handleEnter?: () => void
  inputRef: RefObject<OTPRef>
}

const MFACodeInput = ({ inputRef, handleEnter }: MFACodeInputProps) => {

  const { formatMessage } = useIntl()

  return (
    <Form.Item
      label={`${formatMessage({ id: 'app.code' })}:`}
      name="code"
      rules={[
        {
          required: true,
          message: <IntlMessages id="form.inputRequired"/>,
        },
        {
          pattern: /^[0-9]{6}$/,
          message: <IntlMessages id="form.inputSize" values={{ len:6 }}/>,
        },
      ]}
    >
      <Input.OTP
        onKeyUp={(e) => {
          if(e.key === 'Enter' && handleEnter){
            handleEnter()
          }
        }}
        inputMode='numeric'
        length={6}
        placeholder={`${formatMessage({ id: 'app.mfaEnterCode' })}`}
        ref={inputRef} // Used for auto-focus
      />
    </Form.Item>
  )
}

export default MFACodeInput