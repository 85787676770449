import { uniqBy } from 'lodash'
import { MessageDescriptor } from 'react-intl'
import { IGetLeaveTypeList, IGetLocationsShort } from '../../../types/locations'
import { AIAssistedOnboardingCoreEventStatusEnum, IAIAssistedOnboardingSetupItem, IBasicEntitiesAIAssistentResponse } from '@vacationtracker/shared/types/ai-assisted-onboarding'
import { IMatchedEventsToEntities } from '.'


export const matchEventsToEntities = (
  setupJson: IBasicEntitiesAIAssistentResponse,
  eventJsons: IAIAssistedOnboardingSetupItem[]
): IMatchedEventsToEntities => {
  const result: { [key: string]: any[] } = Object
    .keys(setupJson)
    .reduce((acc, key) => {
      acc[key] = []
      return acc
    }, {})

  const eventGroupMapping: { [key: string]: string } = {
    LOCATION: 'locations',
    LEAVE_TYPE: 'leaveTypes',
    TEAM: 'departments',
    LEAVE_POLICY: 'leavePolicies',
  }

  eventJsons.forEach(eventJson => {
    const key = eventGroupMapping[eventJson.eventGroup]
    // eslint-disable-next-line no-prototype-builtins
    if (result.hasOwnProperty(key)) {
      result[key].push(eventJson)
    }
  })

  Object
    .keys(result)
    .forEach(key => {
      if (result[key].length === 0) {
        delete result[key]
      }
    })

  return result as unknown as IMatchedEventsToEntities
}

export const translateEventGroup = (
  eventGroup: string,
  formatMessage: (descriptor: MessageDescriptor, values?: Record<string, string>) => string
) => {
  switch (eventGroup) {
    case 'locations':
      return formatMessage({id: 'app.locations'})
    case 'leaveTypes':
      return formatMessage({id: 'app.leaveTypes'})
    case 'departments':
      return formatMessage({id: 'app.departments'})
    case 'leavePolicies':
      return formatMessage({id: 'app.leavePolicies'})
    default:
      return eventGroup
  }
}

export const generateEventMessage = (
  eventType: string,
  status: AIAssistedOnboardingCoreEventStatusEnum,
  formatMessage: (descriptor: MessageDescriptor, values?: Record<string, string>) => string,
  leavePolicyValues: {
    leaveTypeName?: string
    locationName?: string
  },
  entityName?: string
) => {
  let action = ''
  let entity = ''

  const { leaveTypeName, locationName } = leavePolicyValues || {}
  // Determine action based on eventType
  if (eventType.endsWith('_CREATED')) {
    action = status === AIAssistedOnboardingCoreEventStatusEnum.completed
      ? formatMessage({id: 'app.created'}).toLowerCase()
      : formatMessage({id: 'app.creating'})
  } else if (eventType.endsWith('_UPDATED')) {
    action = formatMessage({id: status === AIAssistedOnboardingCoreEventStatusEnum.completed ? 'app.updated' : 'app.updating'})
  } else if (eventType.endsWith('_DELETED')) {
    action = status === AIAssistedOnboardingCoreEventStatusEnum.completed
      ? formatMessage({id: 'app.deleted'}).toLowerCase()
      : formatMessage({id: 'app.deleting'})
  }

  // Determine entity based on eventType
  if (eventType.startsWith('LOCATION')) {
    entity = formatMessage({id: 'app.location'})
  } else if (eventType.startsWith('TEAM')) {
    entity = formatMessage({id: 'app.department'})
  } else if (eventType.startsWith('LEAVE_TYPE')) {
    entity = formatMessage({id: 'app.leaveType'})
  }
  let message = `${action} ${entityName} ${entity}`

  // Special handling for LEAVE_POLICY or other entities with specific conditions
  if (eventType === 'LEAVE_POLICY_ENABLED') {
    if (status === AIAssistedOnboardingCoreEventStatusEnum.pending) {
      message = formatMessage({id: 'ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigning'}, {
        leaveTypeName: leaveTypeName as string,
        locationName: locationName as string,
      })
    } else {
      message = formatMessage({id: 'ai.assisted.onboarding.openSetupNotificationLeavePolicyAssigned'}, {
        leaveTypeName: leaveTypeName as string,
        locationName: locationName as string,
      })
    }
  } else if (status === AIAssistedOnboardingCoreEventStatusEnum.completed) {
    message = formatMessage({id: 'ai.assisted.onboarding.openSetupNotificationLeavePolicySuccessAction'}, {
      entityName: entityName as string,
      entity: entity as string,
      action: action as string,
    })
  }
  return message
}

export const getLeaveTypeAndLocationNames = (
  event: IAIAssistedOnboardingSetupItem,
  leaveTypes: IGetLeaveTypeList[],
  locations: IGetLocationsShort[],
  setUpLeaveTypeEvents: IAIAssistedOnboardingSetupItem[],
  setUpLocationEvents: IAIAssistedOnboardingSetupItem[]
) => {
  let leaveTypeName
  let locationName
  const eventLeaveTypes = setUpLeaveTypeEvents || []
  const eventLocations = setUpLocationEvents || []
  const allLeaveTypes = uniqBy([...leaveTypes, ...eventLeaveTypes], lt => lt.name)
  const allLocations = uniqBy([...locations, ...eventLocations], lt => lt.name)

  if (event.eventType === 'LEAVE_POLICY_ENABLED') {
    leaveTypeName = allLeaveTypes?.find((item) => ((item as IAIAssistedOnboardingSetupItem).leaveTypeId || item.id) === event.leaveTypeId)?.name
    locationName = allLocations?.find((item) => ((item as IAIAssistedOnboardingSetupItem).locationId || item.id) === event.locationId)?.name
  }
  return { leaveTypeName, locationName }
}