import React from 'react'
import { Form, Select, Radio, Button, Tooltip, Alert } from 'antd'
import { DownloadOutlined } from '@ant-design/icons'
import { ImportFormProps } from './types'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import SpreadsheetImport from '../spreadsheet-import'

const layout = {
  labelCol: {span: 3},
  wrapperCol: {span: 12},
}

const tailLayout = {
  wrapperCol: {xs: { offset: 0, span: 12 }, lg: {offset: 3, span: 12}},
}

const ImportForm: React.FC<ImportFormProps> = ({
  defaultValue,
  form,
  handleChangeImportType,
  selectedFormat,
  changeFormat,
  setSpreadSheetOpen,
  spreadSheetOpen,
  onHideModal,
  companyUsers,
  validationData,
  onUpload,
  loadLeaveRequests,
  getDownloadTemplateUrl,
}: ImportFormProps) => {
  return (
    <div className='export-body'>
      <Form form={form} initialValues={defaultValue} {...layout}>
        <Form.Item
          label={<IntlMessages id='app.importType' />}
          name='type'
          rules={[
            {
              required: true,
              message: <IntlMessages id='error.fieldIsRequired' />,
            },
          ]}
        >
          <Select onChange={handleChangeImportType}>
            <Select.Option value='importLeaves' key='importLeaves'>
              <IntlMessages id='app.importLeaves' />
            </Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={<IntlMessages id='app.importFormat' />}
          name='importFormat'
          tooltip={<IntlMessages id="app.importLeaves.importFormatTooltip" />}
        >
          <Radio.Group
            style={{display: 'flex', float: 'left' }}
            onChange={(e) => changeFormat(e.target.value)}
          >
            <Radio.Button value='csv' defaultChecked>
              {<IntlMessages id='export.fileTypeCsv' />}
            </Radio.Button>
            <Radio.Button value='excel'>
              {<IntlMessages id='export.fileTypeExcelXlsx' />}
            </Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Tooltip
            title={<IntlMessages id='app.importLeaves.downloadTemplateFileTooltip'
              values={{
                br: () => <br/>,
              }}
            />}
          >
            <Button
              href={getDownloadTemplateUrl(selectedFormat)}
              target='_blank'
              icon={<DownloadOutlined />}
            >
              <span>
                <IntlMessages id='app.downloadTemplate' />
              </span>
            </Button>
          </Tooltip>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <div style={{display: 'inline-flex'}}>
            <Button
              type='primary'
              onClick={() => setSpreadSheetOpen(true)}
              style={{marginRight: '10px'}}
            >
              <IntlMessages id='app.importFile' />
            </Button>
          </div>
        </Form.Item>
      </Form>
      <Alert
        style={{ marginBottom: 20 }}
        showIcon
        description={<IntlMessages id="app.importLeaves.leaveStatusWarning" />}
        type="warning"
      />
      <SpreadsheetImport
        isOpen={spreadSheetOpen}
        onHideModal={onHideModal}
        companyUsers={companyUsers}
        validationData={validationData}
        onUpload={onUpload}
        loadLeaves={loadLeaveRequests}
      />
    </div>
  )
}

export default ImportForm
