import invert, {HexColor} from 'invert-color'
import * as logger from '../services/logger'

/**
 * Return white in case the hex color is invalid for some reason
 */
export function invertHexColor(color: HexColor, bw: boolean): HexColor {
  try {
    return invert(color.trim(), bw)
  } catch (err) {
    logger.warning(err)
    return 'ffffff'
  }
}