import React, { useState } from 'react'
import { Modal, Form, Checkbox, Input, DatePicker } from 'antd'
import { CloseCircleOutlined } from '@ant-design/icons'
import dayjs, { Dayjs } from 'dayjs'
import * as logger from '../../services/logger'

import { useAppSelector } from '../../store/hooks'
import { selectDateFormatSlice } from '../../store/date-format-slice'
import IntlMessages from '../../util/IntlMessages'
import { IHoliday } from '../../types/holidays'

interface IAddHolidayProps {
  visibleModal: boolean
  handleCancel: () => void
  onSave: (data) => void
  holiday: IHoliday | null
  defaultDay: Dayjs
}

const AddHolidaysForm = ({ visibleModal, handleCancel, onSave, holiday, defaultDay }: IAddHolidayProps) => {
  const { dateFormat } = useAppSelector(selectDateFormatSlice)
  const [form] = Form.useForm()

  const defaultValue = {
    date: holiday?.date
      ? dayjs(holiday.date)
      : defaultDay
        ? dayjs(defaultDay)
        : '',
    startDate: holiday?.date ? dayjs(holiday.date) : defaultDay,
    endDate: holiday?.endDate ? dayjs(holiday.endDate) : holiday?.date ? dayjs(holiday?.date) : defaultDay,
    name: holiday?.name ? holiday.name : '',
    halfDay: holiday?.isHalfDay ?? false,
  }
  const [isMultiday, setMultiday] = useState(() => holiday?.multiDayId ? true : false)
  const [isHalfDay, setIsHalfDay] = useState(holiday?.isHalfDay ?? false)
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = () => {
    form
      .validateFields()
      .then(values => {
        setIsLoading(true)
        if (isMultiday) {
          values.startDate = dayjs(values.startDate).format('YYYY-MM-DD')
          values.endDate = dayjs(values.endDate).format('YYYY-MM-DD')
        } else {
          values.date = dayjs(values.date).format('YYYY-MM-DD')
        }
        values.multiday = isMultiday
        values.isHalfDay = isHalfDay
        onSave(values)
      })
      .catch(info => {
        logger.error('Validate Failed', info)
      })
  }

  const formItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
  }
  const formTailLayout = {
    labelCol: { span: 6 },
    wrapperCol: {xs: { span: 14, offset: 0 }, md: { span: 14, offset: 6 }},
  }

  const isBeforeStartDateOrNextNextYear = (current) => {
    return current < dayjs(form.getFieldValue('startDate')) || isNextNextYear(current)
  }

  const isNextNextYear = (current) => {    
    return current > dayjs().add(2, 'years').startOf('year')
  }

  const isDisabledDate = (current) => {
    if (isMultiday && isBeforeStartDateOrNextNextYear(current)) {
      return true
    }
    const currentYear = dayjs().year()
    const tabYear = defaultDay.year()
    const chosenYear = current.year()
    if (tabYear === currentYear) {
      return chosenYear !== currentYear
    }
    if (tabYear > currentYear) {
      return chosenYear !== currentYear + 1
    }
    return chosenYear >= currentYear
  }

  const changeStartDate = () => {
    form.setFieldsValue({
      endDate: dayjs(form.getFieldValue('startDate')).add(1, 'days'),
    })
  }

  return (
    <Modal
      title={holiday ?
        <IntlMessages id="components.addHolidayForm.editHolidays" /> :
        <IntlMessages id="components.addHolidayForm.addHolidays" />
      }
      open={visibleModal}
      style={{ top: 20 }}
      closeIcon={<CloseCircleOutlined />}
      confirmLoading={isLoading}
      okText={<IntlMessages id="app.save" />}
      onOk={onFinish}
      cancelText={<IntlMessages id="app.cancel" />}
      onCancel={handleCancel}
    >
      <Form
        key="addHolidaysForm"
        form={form}
        name="addHolidaysForm"
        onFinish={onFinish}
        requiredMark={false}
        initialValues={defaultValue}
      >
        <Form.Item
          {...formItemLayout}
          name="name"
          label={<IntlMessages id="holidays.holidaysName" />}
          rules={[{
            required: true,
            message: <IntlMessages id='components.addHolidayForm.holidaysName.error' />,
          }]}
        >
          <Input />
        </Form.Item>
        <Form.Item {...formTailLayout}>
          <Checkbox checked={isMultiday} onChange={() => setMultiday(!isMultiday)} disabled={isHalfDay}>
            <IntlMessages id="components.addHolidayForm.addHolidays.isHolidayMultiday" />
          </Checkbox>
        </Form.Item> 
        {!isMultiday ?
          <Form.Item {...formItemLayout} name="date" label={<IntlMessages id='components.leaveForm.date' />}
            rules={[{
              required: true,
              message: <IntlMessages id='components.addHolidayForm.date.error' />,
            }]}>
            <DatePicker disabledDate={isDisabledDate} format={dateFormat} />
          </Form.Item> :
          <>
            <Form.Item {...formItemLayout} name="startDate" label={<IntlMessages id='users.startDate' />}
              rules={[{
                required: isMultiday,
                message: <IntlMessages id='components.addHolidayForm.startDate.error' />,
              }]}>
              <DatePicker disabled={isHalfDay} disabledDate={isDisabledDate} onChange={changeStartDate}/>
            </Form.Item>
            <Form.Item {...formItemLayout} name="endDate" label={<IntlMessages id='users.endDate' />}
              rules={[{
                required: isMultiday,
                message: <IntlMessages id='components.addHolidayForm.endDate.error' />,
              }]}>
              <DatePicker disabled={isHalfDay} disabledDate={isDisabledDate} />
            </Form.Item>
          </>
        }
        <Form.Item {...formTailLayout}>
          <Checkbox name="halfDay" checked={isHalfDay} disabled={isMultiday} onChange={() => setIsHalfDay(!isHalfDay)}>
            <IntlMessages id="components.addHolidayForm.addHolidays.isHalfDayHoliday" />
          </Checkbox>
        </Form.Item>
      </Form>
    </Modal>
  )
}
export default AddHolidaysForm
