import { ILeavePolicy } from '@vacationtracker/shared/types/leave-policy'
import { ILeaveRequest } from '@vacationtracker/shared/types/leave-request'
import { isBefore } from 'date-fns'
import { isToilLeave } from './is-toil-leave-request'

export function shouldResendLeaveRequest(
  leaveRequest: Pick<ILeaveRequest, 'status' | 'startDate' | 'id'>,
  leavePolicy: Pick<ILeavePolicy, 'allowLeaveInPast'>
): boolean {

  if (leaveRequest.status !== 'EXPIRED') {
    return false
  }

  if (isToilLeave(leaveRequest.id as string)) {
    return true
  }

  if (!leavePolicy.allowLeaveInPast && isBefore(new Date(leaveRequest.startDate), new Date())) {
    return false
  }

  return true

}
