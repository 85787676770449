/* 
   Analytics plugin for developemnt and testing purposes. It will help you figure out when each function is triggered and what the kind of payload it has.
   To start using it pass logging:true info configuration
*/
export default function testPlugin(userConfig: {logging: boolean}) {
  let logging: false

  // return object for analytics to use
  return {
    /* All plugins require a name */
    name: 'test-plugin',
    /* Everything else below this is optional depending on your plugin requirements */
    config: {
      logging: userConfig.logging,
    },
    initialize: ({ config }) => {
      // load provider script to page
      logging = config.logging
      if (logging) {
        console.log('initialize', config)
      }
    },
    page: ({ payload }) => {
      // call provider specific page tracking
      if (logging) {
        console.log('page', payload)
      }
    },
    track: ({ payload }) => {
      // call provider specific event tracking
      if (logging) {
        console.log('track', payload)
      }
    },
    identify: ({ payload }) => {
      // call provider specific user identify method
      if (logging) {
        console.log('identify', payload)
      }
    },
    loaded: () => {
      // return boolean so analytics knows when it can send data to third party
      if (logging) {
        console.log('loaded')
      }
      return true
    },
    ready: () => {
      // return boolean so analytics knows when it can send data to third party
      if (logging) {
        console.log('ready')
      }
      return true
    },
  }
}
