import React from 'react'
import { useIntl } from 'react-intl'
import { Modal, Button } from 'antd'
import { GoogleOutlined, WindowsFilled, SlackOutlined} from '@ant-design/icons'

import IntlMessages from '../../util/IntlMessages'

interface ICompanyAlreadySignedUpModal {
  showModal: boolean
  existsOnAnotherPlatform: string
  connectWithPlatform: (e) => void
  handleCloseCompanyAlreadySignedUpModal: () => void
}

const CompanyAlreadySignedUpModal= ({
  showModal,
  existsOnAnotherPlatform,
  connectWithPlatform,
  handleCloseCompanyAlreadySignedUpModal,
}: ICompanyAlreadySignedUpModal): React.ReactElement => {
  const { formatMessage } = useIntl()

  return (
    <Modal
      title={formatMessage({ id: 'components.companyAlreadySignup.title' })}
      open={showModal}
      onCancel={handleCloseCompanyAlreadySignedUpModal}
      footer="" //Need to remove default buttons
      width={560}
    >
      <p><IntlMessages id="components.companyAlreadySignup.info" /></p>
      <p>
        <Button type="primary" block size="large"
          icon={existsOnAnotherPlatform === 'microsoft' ? <WindowsFilled /> : existsOnAnotherPlatform === 'slack' ? <SlackOutlined /> : <GoogleOutlined />}
          onClick={() => {
            handleCloseCompanyAlreadySignedUpModal()
            connectWithPlatform(existsOnAnotherPlatform)
          }}
        > <IntlMessages id="microsoftSass.createCompany.alertError.logInWithPlatform"
            values={{ platform: existsOnAnotherPlatform.charAt(0).toUpperCase() + existsOnAnotherPlatform.slice(1) }}
          />
        </Button>
      </p>
    </Modal>
  )
}

export default CompanyAlreadySignedUpModal
