import React, { useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { Breadcrumb, Tabs, Row, Col, TabsProps, Statistic } from 'antd'
import { useManualQuery } from 'graphql-hooks'
import { getTeamById } from '../../../../graphql/custom-queries'
import * as logger from '../../../../services/logger'

import IntlMessages from '../../../../util/IntlMessages'
import CircularProgress from '../../../../components/circular-progress'
import { UserAvatar } from '@vacationtracker/shared/components/user-avatar'
import { FrontendUrls } from '../../../../types/urls'

import { IGetTeamByIdData, IGetTeamById, ITeamUsers } from '../../../../types/teams'
import { useIntl } from 'react-intl'

interface ITeamsPage {
  match: {
    params: {
      id: string
    }
  }
}

const TeamsPage = ({ match }: ITeamsPage): React.ReactElement => {
  const { formatMessage } = useIntl()
  const history = useHistory()
  const [isLoading, setIsLoading] = useState(true)
  const [team, setTeam] = useState<IGetTeamById>({
    id: '',
    name: '',
    timestamp: '',
    users: [],
    approvers: [],
    isDefault: false,
  })

  const [ getTeamByIdQuery ] = useManualQuery<IGetTeamByIdData, { id: string }>(getTeamById)

  useEffect(() => {
    fetchTeam(match.params.id)
  }, [match.params.id])

  const fetchTeam = async (id) => {
    try {
      const response = await getTeamByIdQuery({ variables: { id }})
      if (!response.data || response.error) throw response.error
      if (response.data.getTeam === null) {
        history.push('/app/settings/departments')
        return
      }
      setTeam(response.data?.getTeam)
      setIsLoading(false)
    } catch (err) { logger.error('error fetching team', err) }
  }

  const operations = <Link to={`/app/settings/departments/${team.id}/edit`}><IntlMessages id="teams.editTeam" /></Link>

  const teamsTabs: TabsProps['items'] = [{
    key: 'general',
    label: <IntlMessages id="app.general" />,
    children: <div className="view-form">
      <Row align="top" gutter={[0, 20]}>
        <Col xl={18} lg={24}>
          <Statistic title={<IntlMessages id="app.name" />} value={team.name} />
        </Col>

        <Col xl={18} lg={24}>
          <Statistic
            title={<IntlMessages id="app.role.approvers" />}
            value={`${team.approvers.length} ${formatMessage({ id: team.approvers.length === 1 ? 'app.role.approver' : 'app.role.approvers' })}`}
          />
          {<Row gutter={10} style={{ marginTop: 10 }}>
            {team.approvers.map((approvers: ITeamUsers) => {
              return (
                <Col key={approvers.id} xs={24} sm={12} md={12} lg={8} xl={8} className="user-avatar">
                  <UserAvatar id={approvers.id} avatar={approvers.imageUrl} name={approvers.name} isShowingName={true} isLinkToPage={true} shape="circle" />
                </Col>
              )
            })}
          </Row>}
        </Col>

        <Col xl={18} lg={24}>
          <Statistic
            title={<IntlMessages id="app.users" />}
            value={`${team.users.length} ${formatMessage({ id: team.users.length === 1 ? 'app.user' : 'app.users' })}`}
          />
          {team.users.length > 0 ?
            <Row gutter={10} style={{ marginTop: 10 }}>
              {team.users.map((user: ITeamUsers) => {
                return (
                  <Col key={user.id} xs={24} sm={12} md={12} lg={8} xl={8} style={{ marginBottom: 14 }}>
                    <UserAvatar id={user.id} avatar={user.imageUrl} name={user.name} isShowingName={true} isLinkToPage={true} shape="circle" />
                  </Col>
                )
              })}
            </Row> : <IntlMessages id="team.hasNoUsers" />
          }
        </Col>
      </Row>
    </div>,
  }]

  return (
    <div className='main-content'>
      {isLoading ? <CircularProgress /> :
        <>
          <div className="main-content-header">
            <div className="main-content-header-title">
              <span><IntlMessages id="app.department" />: {team?.name}</span>
            </div>
            <div className="main-content-header-breadcrumb">
              <Breadcrumb
                items={[
                  {
                    title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
                  },
                  {
                    title: <IntlMessages id="sidebar.settings" />,
                  },
                  {
                    title: <Link to='/app/settings/departments'><IntlMessages id="app.departments" /></Link>,
                  },
                  {
                    title: team?.name,
                  },
                ]}
              />
            </div>
          </div>
          <div className="main-content-body">
            <div className="team-body">
              <Tabs defaultActiveKey="general" tabBarExtraContent={operations} items={teamsTabs} />
            </div>
          </div>
        </>
      }
    </div>
  )
}

export default TeamsPage
