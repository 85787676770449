/* eslint-disable react/no-unescaped-entities, max-len */
import React from 'react'
import IntlMessages from '../../util/IntlMessages'

export const approverSteps = [
  {
    target: 'body',
    placement: 'center' as const,
    isFixed: true,
    title: <IntlMessages id='components.onboarding.joyride.admin.start.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.user.start1' />
        </p>
        <p>
          <IntlMessages
            id='components.onboarding.joyride.admin.start2'
            values={{
              link: (...chunks) => (
                <a href='mailto:hello@vacationtracker.io'>{chunks}</a>
              ),
            }}
          />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-dashboard-menu',
    placement: 'center' as const,
    isFixed: true,
    title: <IntlMessages id='app.dashboard' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.approver.dashboard.info1' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-users-menu',
    placement: 'auto' as const,
    title: <IntlMessages id='app.users' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.approver.users.info1' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-request-leave',
    placement: 'auto' as const,
    title: <IntlMessages id='app.leaveRequests' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.approver.leaveRequests.info1' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-calendar-menu',
    placement: 'auto' as const,
    title: <IntlMessages id='app.calendar' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.user.calendar.info1' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-my-profile-menu',
    placement: 'auto' as const,
    title: <IntlMessages id='app.myProfile' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.user.myProfile.info1' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-notifications',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.createNotifications.title' />,
    previousUrl: '/app/notifications',
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.approver.notifications.info1' />
        </p>
        <p>
          <IntlMessages id='components.onboarding.joyride.approver.notifications.info2' />
        </p>
        <p>
          <IntlMessages id='components.onboarding.joyride.approver.notifications.info3' />
        </p>
      </>
    ),
  },
  {
    target: 'body',
    placement: 'center' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.helpDesk.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.helpDesk.info' />
        </p>
      </>
    ),
  },
]
