import invert, {HexColor} from 'invert-color'

/**
 * Return white in case the hex color is invalid for some reason
 */
export function invertHexColor(color: HexColor, bw: boolean): HexColor {
  try {
    return invert(color.trim(), bw)
  } catch (err) {
    console.warn(err)
    return 'ffffff'
  }
}

export function hexToRGBA(hex: string, alpha: number) {
  const r = parseInt(hex.slice(1, 3), 16)
  const g = parseInt(hex.slice(3, 5), 16)
  const b = parseInt(hex.slice(5, 7), 16)

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`
  } else {
    return `rgb(${r}, ${g}, ${b})`
  }
}
