import React, { useState, useEffect } from 'react'
import { Input, Spin } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

import useDebounce from '../../util/use-debounce'

interface ISearchProps {
  onSearch: (e) => void
  displaySpinner?: boolean
  onLoad?: boolean
  width?: number
  placeholder?: string
}

export const Search = ({ onSearch, displaySpinner = false, onLoad = false, width = 325, placeholder = ''}: ISearchProps): React.ReactElement => {

  const [searchTerm, setSearchTerm] = useState('')
  const [showSpinner, setShowSpinner] = useState(true)

  const debouncedSearchTerm = useDebounce(searchTerm, 500)

  useEffect(() =>  {
    setShowSpinner(true)
    onSearch(debouncedSearchTerm)
  }, [debouncedSearchTerm])

  useEffect(() => {
    if (!onLoad) {
      setShowSpinner(false)
    }
  }, [onLoad])

  return (
    <Input 
      className="search"
      suffix={(showSpinner && displaySpinner) ? <Spin size="small" /> : <SearchOutlined />} 
      placeholder={placeholder}
      style={{ width: width }}
      onChange={(e) => {
        setSearchTerm(e.target.value)
      }}
    />
  )
}