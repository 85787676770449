import { track } from '../services/analytics/analytics'

export const openSupportChat = (eventName?: string) => {
  if (eventName) {
    track(eventName, {})
  }
  if (window && window.$crisp) {
    window.$crisp.push(['do', 'chat:open'])
  } else {
    window.open('https://vacationtracker.crisp.help/en/', '_blank')?.focus()
  }
}