import { ICountry, IState } from '@vacationtracker/shared/data/countries'
import { IGetHolidaysResponse } from '@vacationtracker/shared/types/holidays'

export enum ImportHolidaysStepEnum {
  stepOne = 'SELECT_COUNTRY',
  stepTwo = 'SELECT_HOLIDAYS',
  stepThree = 'CONFIRM',
}

export interface IImportHolidaysNavigationProps {
  stepComplete: boolean
  handleNext: () => void
  nextButtonTitle: string
  backButtonTitle?: string
  handleBack?: () => void
}

export interface IImportHolidaysStepOneFormProps {
  setSteps: (step: ImportHolidaysStepEnum, activeStep: number) => void
  setYear: (year: number) => void
  setCountry: (country: ICountry) => void
  setState: (state: IState) => void
  locationId: string
  defaultYear: number
}

export interface IImportHolidaysStepTwoFormProps {
  setSteps: (step: ImportHolidaysStepEnum, activeStep: number) => void
  setSelectedHolidays: (holidays: any) => void
  findHolidaysForCountry: () => Promise<IGetHolidaysResponse[]>
  year: number
  country: ICountry
  state: IState
}

export interface IImportHolidaysStepThreeFormProps {
  setSteps: (step: ImportHolidaysStepEnum, activeStep: number) => void
  saveNewHolidays: (autoImport: boolean) => Promise<void>
  holidays: IGetHolidaysResponse[]
  year: number
  country: ICountry
  state: IState
  locationId: string
}