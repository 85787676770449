import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'

import { IUserHelpInfoActions, IGetUserHelpInfo } from '@vacationtracker/shared/types/user-action-flow'

interface IUserHelpInfoState {
  actions: IUserHelpInfoActions
}

const initialState: IUserHelpInfoState = {
  actions: {
    HELP_INFO_LOCATION_CREATE: false,
    HELP_INFO_LEAVE_TYPE_CREATE: false,
  },
}

export const helpInfoSlice = createSlice({
  name: 'help-info',
  initialState,
  reducers: {
    setHelpInfoActions: (state, action: PayloadAction<IGetUserHelpInfo>) => {      
      for (const [key] of Object.entries(state.actions)) {        
        if (action.payload && action.payload.actions && action.payload.actions[key]) {
          state.actions[key] = true
        }
      }      
    },
  },
})

export const {
  setHelpInfoActions,
} = helpInfoSlice.actions

export const selectHelpInfoSlice = (state: RootState) => state.helpInfoSlice

export default helpInfoSlice.reducer
