interface IState {
  actionNotifications: string[]
  eventsNotifications: {}
  setActionNotifications: Function
  setEventsNotifications: Function
}

export const initialState: IState = {
  actionNotifications: [],
  eventsNotifications: {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setActionNotifications: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setEventsNotifications: () => {},
}

export const reducer = (state, action) => {
  const { type, value } = action
  switch (type) {
    case 'SET_ACTION_NOTIFICATION':
      return {
        ...state,
        actionNotifications: value,
      }
    case 'SET_EVENTS_NOTIFICATION':
      return {
        ...state,
        eventsNotifications: value,
      }
    default:
      return state
  }
}