import React from 'react'
import { Alert } from 'antd'
import { NotificationOutlined } from '@ant-design/icons'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { Link } from 'react-router-dom'

const AlertPastDue = (): React.ReactElement => {
  return (<Alert
    style={{ margin: '5px 5px 5px 5px' }}
    message={
      <IntlMessages id="app.pastDue"
        values={{
          // eslint-disable-next-line
          a: (...chunks) => (
            <Link to={'/app/settings/billing'}>
              {chunks}
            </Link>
          ),
        }}
      />
    }
    type="error"
    icon={<NotificationOutlined />}
    showIcon
  />)
}

export default AlertPastDue