import dayjs, { Dayjs } from 'dayjs'

export function* dayjsIterator(startDate: Dayjs | string, endDate?: Dayjs | string) {
  let currentDate = dayjs(startDate)

  const end = endDate ? dayjs(endDate) : dayjs(startDate)

  while (currentDate <= end) {
    yield currentDate
    currentDate = currentDate.add(1, 'day')
  }
}
