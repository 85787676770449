import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ICompanySettingsUpdatedEvent
} from '@vacationtracker/shared/types/company'
import {
  IBillingUpdatedV2Event
} from '@vacationtracker/shared/types/billing'
import { ISubscriptionChangedEvent } from '@vacationtracker/shared/types/billing'

import { RootState } from './configure'

type CompanySettingsBillingUpdatedActionEvent = ICompanySettingsUpdatedEvent
| IBillingUpdatedV2Event
| ISubscriptionChangedEvent
| null

interface IInitialState {
  companySettingsBillingUpdatedActionEvent: CompanySettingsBillingUpdatedActionEvent
}

const initialState: IInitialState = {
  companySettingsBillingUpdatedActionEvent: null,
}

export const companySettingsBillingUpdatedActionEventSlice = createSlice({
  name: 'companySettingsBillingUpdatedActionEvent',
  initialState,
  reducers: {
    setCompanySettingsBillingUpdatedActionEvent: (state, action: PayloadAction<CompanySettingsBillingUpdatedActionEvent>) => {
      state.companySettingsBillingUpdatedActionEvent = action.payload
    },
  },
})

export const { setCompanySettingsBillingUpdatedActionEvent } = companySettingsBillingUpdatedActionEventSlice.actions

export const selectCompanySettingsBillingUpdatedActionEventSlice = (state: RootState) => state.companySettingsBillingUpdatedActionEventSlice

export default companySettingsBillingUpdatedActionEventSlice.reducer