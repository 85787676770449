import React from 'react'
import * as _ from 'lodash'
import {ReactSpreadsheetImport} from 'react-spreadsheet-import'
import {fields} from './fields'
import {validateRow} from './hooks/rowHook'
import {IGetImportPageValidationData, IUserSlim} from '../../types/users'
import {findOverlaps} from './hooks/tableHook'
import {ThemeContext} from '../../context/themeContext'
import {customTheme} from './customTheme'
import { getTranslations } from './translations'
import { IUserLeaves } from '@vacationtracker/shared/types/leave-request'
import { getEarliestAndLatestDates } from './helpers'
import { Dayjs } from 'dayjs'
import { useIntl } from 'react-intl'

type Props = {
  isOpen: boolean
  onHideModal: () => void
  onUpload: (data: any) => Promise<void>
  loadLeaves: (dateStart: Dayjs, dateEnd: Dayjs) => Promise<IUserLeaves[]>
  companyUsers: IUserSlim[]
  validationData: IGetImportPageValidationData
}

const SpreadsheetImport = ({
  isOpen,
  onHideModal,
  companyUsers,
  loadLeaves,
  validationData,
  onUpload,
}: Props): React.ReactElement => {
  const { formatMessage } = useIntl()
  const {theme} = React.useContext(ThemeContext)
  const [existingLeaves, setExistingLeaves] = React.useState<IUserLeaves[]>([])
  // Called after user completes the flow. Provides data array, where data keys matches your field keys.
  function onSubmit(data: any) {
    onUpload(data)
  }

  return (
    <ReactSpreadsheetImport
      allowInvalidSubmit={false}
      isOpen={isOpen}
      onClose={onHideModal}
      onSubmit={onSubmit}
      fields={fields}
      rowHook={validateRow(companyUsers, validationData, existingLeaves, formatMessage)}
      tableHook={findOverlaps}
      customTheme={customTheme(theme)}
      translations={getTranslations(formatMessage)}
      uploadStepHook={async (data) => {
        await Promise.resolve(data)
        data.forEach((item) => {
          //sometimes this is in clients csv/xls => reason: '  ', hourFrom: '  12  ' and so on..
          for (const [key, value] of Object.entries(item as Record<string, any>)) {
            if (typeof value === 'string') {
              item[key] = value.trim()
            }
          }
        })
        return data
      }}
      selectHeaderStepHook={async (headerValues, data) => {
        await Promise.resolve(data)
        return {
          headerValues,
          data, 
        }
      }}
      matchColumnsStepHook={async (data: any) => {
        try {
          await Promise.resolve(data)
          const {earliestDate, latestDate} = getEarliestAndLatestDates(data)
          if (!earliestDate || !latestDate) {
            setExistingLeaves([])
            return data
          }
          const leaves = await loadLeaves(earliestDate, latestDate)
          setExistingLeaves(leaves)
          return data
        } catch (error) {
          return
        }
      }}
    />
  )
}

export default SpreadsheetImport
