import React from 'react'
import { Tag } from 'antd'
import { IImportUser } from '@vacationtracker/shared/types/user'

interface ISlackUserTags {
  user: IImportUser
}

export const SlackUserTags = ({ user }: ISlackUserTags): React.ReactElement  => {
  let color = 'default'
  let role = 'user'

  if (user.isAdmin) {
    color = 'processing'
    role = 'admin'
  }

  if (user.isOwner) {
    color = 'warning'
    role = 'owner'
  }

  return <Tag style={{ position: 'absolute', right: 5 }} color={color}>{role}</Tag>
}
