import React, { useContext, useState, useEffect } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { App, Button, Table, Breadcrumb, Divider, Form, Input, Typography } from 'antd'
import { DeleteOutlined, ExclamationCircleOutlined, LoadingOutlined, CommentOutlined } from '@ant-design/icons'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import { getAutomations } from '../../graphql/custom-queries'
import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../circular-progress'
import { notificationStore } from '../../context/notificationsContext/store'
import { FeedbackModal } from '@vacationtracker/shared/components/feedback-modal'
import { OPEN_API_LIMIT_REQUESTS_PER_MONTH } from '@vacationtracker/shared/data/app-parameters'
import HandleSubscribeAutomations from '../handle-subscribe-automations'

import { IAutomations, IGetAutomations } from '../../types/custom-queries'
import { FrontendUrls } from '../../types/urls'
import { AddonsParent, IAutomationViewPage } from '@vacationtracker/shared/types/automations'
import { AddonsStatusEnum } from '@vacationtracker/shared/types/addons'

const { Paragraph, Text } = Typography

const OpenApiView = ({
  formatMessage,
  amIAdmin,
  deleteAutomation,
  handleSubscribe,
  status,
  userCount,
  handleBuySubscriptionModal,
  addonsPrice,
}: IAutomationViewPage) => {
  const history = useHistory()
  const { modal, notification } = App.useApp()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = useState(true)
  const [automations, setAutomations] = useState<IAutomations[]>([])
  const [deleteAutomationId, setDeleteAutomationId] = useState<string | null>(null)
  const [showFeedbackForm, setShowFeedbackForm] = useState(false)
  const [btnLoading, setBtnLoading] = useState(false)
  const [correlationId, setCorrelationId] = useState<string | null>(null)

  const [getAutomationsQuery] = useManualQuery<IGetAutomations>(getAutomations)

  useEffect(() => {
    fetchData()
    if (correlationId && !actionNotifications.includes(correlationId)) {
      setCorrelationId(null)
      setBtnLoading(false)
      form.resetFields()
    }
  }, [actionNotifications])

  useEffect(() => {
    // If a user has at least one API key generated, we store it in the localStorage so that the 
    // React Swagger UI component can load that API key automatically.
    // By doing this, users can try out our API without copy-pasting the API key. 
    // We'll set the first API key if users have multiple keys, but they can change it if necessary.
    const authorized = localStorage.getItem('authorized')
    if (!authorized && automations.length > 0) {
      localStorage.setItem('authorized', JSON.stringify({
        apiKeyAuth: {
          name: 'apiKeyAuth',
          schema: {
            type:'apiKey',
            name:'x-api-key',
            in:'header',
          },
          value: automations[0].apiKeyValue,
        },
      }))
    } else if (authorized && automations.length === 0) {
      // When there are no keys (i.e., a user just deleted the last one), remove the key from the localStorage.
      localStorage.removeItem('authorized')
    }
  }, [automations])

  const fetchData = async () => {
    const response = await getAutomationsQuery()
    if (!response.data || response.error) throw response.error
    setAutomations(response.data.getAutomations.filter(automation => automation.automationType === 'OPEN_API'))

    setIsLoading(false)
  }

  const sendFeedbackContactForm = async (value) => {
    // track('AUTOMATION_ANYTHING_MISSING_SUBMITTED', {
    //   buttonLocation: page,
    // })
    try {
      await Api.post('/core/event', {
        ...value,
        eventGroup: 'FEEDBACK',
        eventType: 'AUTOMATION_FEEDBACK',
      })
      setShowFeedbackForm(false)
      notification.success({
        message: formatMessage({ id: 'automation.feedback.thankYou' }),
        icon: <CommentOutlined style={{ color: '#7f00ff' }} />,
      })
    } catch (error) {
      const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
      notification.error({
        message: formatMessage({ id: 'error.generic' }),
        description: errorDescription,
        duration: 0,
      })
    }
  }

  const showConfirmDelete = (row: IAutomations) => {
    modal.confirm({
      title: formatMessage({ id: 'addons.openApi.notificationDeleteTitle' }),
      icon: <ExclamationCircleOutlined />,
      content: formatMessage(
        { id: 'addons.openApi.notificationDeleteConfirm' },
        { name: row.name, strong: (...chunks) => <strong>{chunks}</strong> }
      ),
      okText: formatMessage({ id: 'app.delete' }),
      okType: 'danger',
      width: 500,
      maskClosable: true,
      onOk() {
        setDeleteAutomationId(row.id)
        deleteAutomation(row.id as string, row.name as string, 'OPEN_API_ADDON')
      },
    })
  }

  const createApiKey = async () => {
    try {
      setBtnLoading(true)
      const value = await form.validateFields()
      const response = await Api.post('/core/event', {
        ...value,
        eventGroup: 'AUTOMATION',
        eventType: 'OPEN_API_ADDON_CREATED',
      })
      setCorrelationId(response.correlationId as string)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'addons.openApi.createInProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ...actionNotifications, response.correlationId ])
    } catch (error) {
      setBtnLoading(false)
      const errorDescription = error.response?.data?.message ? error.response?.data.message : error.message ? error.message : JSON.stringify(error)
      notification.error({
        message: formatMessage({ id: 'error.generic' }),
        description: errorDescription,
        duration: 0,
      })
    }
  }

  const automationsColumns = [
    {
      title: <IntlMessages id="addons.openApi.apiKeyName" />,
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: <IntlMessages id="addons.openApi.currentMonthUsage" />,
      dataIndex: 'currentMonthUsage',
      key: 'currentMonthUsage',
      render: (currentMonthUsage: string) => {
        if(Number(currentMonthUsage) >= OPEN_API_LIMIT_REQUESTS_PER_MONTH) {
          return (<Text type="danger">${OPEN_API_LIMIT_REQUESTS_PER_MONTH} / ${OPEN_API_LIMIT_REQUESTS_PER_MONTH}</Text>)
        } else {
          return (`${currentMonthUsage} / ${OPEN_API_LIMIT_REQUESTS_PER_MONTH}`)
        }
      },
    },
    {
      title: <IntlMessages id="addons.openApi.apiKeySecret" />,
      dataIndex: 'apiKeyValue',
      key: 'apiKeyValue',
      render: (apiKeyValue: string) => (<Paragraph copyable={{ text: apiKeyValue }}>{apiKeyValue.slice(0, 4)}******</Paragraph>),
    },
    {
      title: '',
      className: 'action',
      width: 78,
      dataIndex: 'id',
      key: 'id',
      render: (id: string, row: IAutomations) => (
        <Button
          size='large'
          danger
          type="link"
          disabled={!amIAdmin}
          onClick={() => showConfirmDelete(row)}
          loading={Boolean(deleteAutomationId && deleteAutomationId === id)}
        >
          {!(deleteAutomationId && deleteAutomationId === id) && <DeleteOutlined />}
        </Button>
      ),
    },
  ]
  
  return (
    <div className='main-content addons-view-page'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id="automations.OPEN_API" />
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb
            items={[
              {
                title: <IntlMessages id="sidebar.dashboard" />,
              },
              {
                title: <Link to={FrontendUrls.addons}><IntlMessages id="sidebar.addons" /></Link>,
              },
              {
                title: <IntlMessages id="automations.OPEN_API" />,
              },
            ]}
          />
        </div>
      </div>
      <div className="main-content-body">
        {amIAdmin && status !== AddonsStatusEnum.GRANT_PERIOD &&
          <HandleSubscribeAutomations
            handleSubscribe={handleSubscribe}
            userCount={userCount}
            status={status}
            automationType="OPEN_API"
            formatMessage={formatMessage}
          />
        }
        <div className="main-content-info">
          <IntlMessages id="addons.openApiInfo" />
        </div>
        <div className="open-api-documentation-card">
          <h2><IntlMessages id="addons.openApi.documentation" /></h2>
          <p><IntlMessages id="addons.openApi.documentationInfo" /></p>
          <Button size='large' onClick={() => history.push('/app/add-ons/open-api/view/documentation')}>
            <IntlMessages id="addons.openApi.dpenDocumentation" />
          </Button>
        </div>
        <p>
          <IntlMessages 
            id="addons.openApi.feedback"
            values={{
              link: (...chunks) => <a onClick={() => setShowFeedbackForm(true) }>{chunks}</a>,
            }}
          />
        </p>
        <Divider />

        <h2><IntlMessages id="addons.openApi.apiKeysTitle" /></h2>
        <p><IntlMessages id="addons.openApi.apiKeysInfo" /></p>

        {isLoading ?
          <CircularProgress /> :
          <>
            {(!status || (status && status === AddonsStatusEnum.CANCELED)) ?
              <div className="open-api-buy-card">
                <h2><IntlMessages id="addons.openApi.buyOpenApiTitle" /></h2>
                <p><IntlMessages id="addons.openApi.buyOpenApiInfoPrice" values={{ price: addonsPrice, userCount, totalPaid: userCount * (addonsPrice as number) }} /></p>
                <p><IntlMessages id="addons.openApi.buyOpenApiInfoPrice2line" /></p>
                <div style={{ maxWidth: 400 }}>
                  <Button 
                    size='large'
                    type='primary'
                    block 
                    onClick={() => handleBuySubscriptionModal && handleBuySubscriptionModal(AddonsParent.addons, 'OPEN_API')}
                  >
                    <IntlMessages id="addons.openApi.buyAppiAddon" />
                  </Button>
                </div>
              </div>
              :
              <>
                <Table
                  dataSource={automations}
                  columns={automationsColumns}
                  rowClassName={(record) => {
                    if(record.error) {
                      return 'clickable-table-error'
                    }
                    return ''
                  }}
                  locale={{ emptyText: <IntlMessages id="addons.openApi.dontHaveApiKeys" /> }}
                  rowKey="id"
                  pagination={false}
                />
                <Form
                  onFinish={() => { createApiKey() }}
                  layout="inline"
                  form={form}
                  style={{ marginTop: 24 }}
                  size="large"
                >
                  <Form.Item name="name" >
                    <Input style={{ width: 300 }} placeholder={formatMessage({ id: 'addons.openApi.apiKeyNamePlaceholder' })} required/>
                  </Form.Item>
                  <Form.Item>
                    <Button 
                      type="link"
                      htmlType="submit"
                      loading={btnLoading}
                      disabled={btnLoading}
                    >
                      <IntlMessages id="addons.openApi.createNewApiKey" />
                    </Button>
                  </Form.Item>
                </Form>
              </>
            }
          </>
        }

        <Divider />

        <h2><IntlMessages id="addons.openApi.quotasAndRateLimitsTitle" /></h2>
        <p><IntlMessages id="addons.openApi.quotasAndRateLimitsInfo" /></p>
        <ul>
          <li><IntlMessages id="addons.openApi.limitRequestPerMonth" /></li>
          <li><IntlMessages id="addons.openApi.limitRequestPerSecound" /></li>
        </ul>

      </div>
      <FeedbackModal
        visible={showFeedbackForm}
        onCancel={() => {setShowFeedbackForm(false)}}
        onSave={sendFeedbackContactForm}
        emailRequired={true}
      />
    </div>
  )
}

export default OpenApiView
