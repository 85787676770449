import React from 'react'
import { Form, Radio } from 'antd'
import { useIntl } from 'react-intl'
import IntlMessages from '../../util/IntlMessages'

interface IYesNoSwitchProps {
  name?: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any
  testIdPrefix?: string
  disabled?: boolean
  required?: boolean
  label: string | React.ReactNode
  onChange?: (value: boolean) => void
}

const YesNoSwitch = ({ defaultValue, disabled, required = true, label, name, testIdPrefix, onChange }: IYesNoSwitchProps): React.ReactElement => {
  const { formatMessage } = useIntl()
  const radioOptions = [
    {
      value: true,
      label: formatMessage({ id: 'app.yes' }),
      key: 'yes',
    }, {
      value: false,
      label: formatMessage({ id: 'app.no' }),
      key: 'no',
    },
  ]

  const testIdPrefixValue = testIdPrefix ? `${testIdPrefix}-` : ''

  return <Form.Item
    name={name}
    label={label}
    rules={[{ required, message: (<IntlMessages id='error.fieldIsRequired' />)}]}
  >
    <Radio.Group
      optionType="button"
      defaultValue={defaultValue}
      onChange={(e) => onChange && onChange(e.target.value as boolean)}
    >
      {
        radioOptions.map(option => (
          <Radio.Button
            value={option.value}
            key={option.key}
            data-testid={`${testIdPrefixValue}${option.key}`}
            disabled={disabled}
          >
            {option.label}
          </Radio.Button>
        ))
      }
    </Radio.Group>
  </Form.Item>
}

export default YesNoSwitch
