import { ILeaveType } from './leave-type'
import { ILocation } from './location'
import { ITeam } from './team'

export enum ConversationRole {
  user = 'user',
  assistant = 'assistant',
  system = 'system',
}

export enum ConversationSortKeyEnum {
  info = 'CONVERSATION_INFO',
  messages = 'CONVERSATION_MESSAGES',
}

export interface IConversationInitialState {
  leaveTypes: ILeaveType[]
  locations: ILocation[]
  departments: ITeam[]
}

export interface IConversationRequestBody {
  conversationId: string
  isInitial: boolean
  userLang?: string
  message: string
}

export interface IConversation extends IBaseConversation{
  id: string
  userId: string
  ttl: number
  topic?: string
  type?: ConversationSortKeyEnum
  companyId?: string
  initialState?: IConversationInitialState
  isDone?: boolean
  updatedAt?: string
  eventId?: string
}

export interface IBaseConversation {
  timestamp?: string
  role?: ConversationRole
  content?: string
  typing?: boolean
}

export interface IBedrockAIConversation {
  role: ConversationRole
  content: {
    type: string | 'text'
    text: string
  }[]
}

export interface IConversationInfo {
  companyId: string
  userId: string
  conversationId: string
  updatedAt: string
  type: ConversationSortKeyEnum
}

export interface IConversationMessage {
  companyId: string
  id: string
  userId: string
  ttl?: number
  content?: string
  role: string
  timestamp: string
  isDone: boolean
  type: ConversationSortKeyEnum
}