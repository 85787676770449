import antdDe from 'antd/locale/de_DE'
import deMessages from '@vacationtracker/shared/i18n/de-de.json'

const DeLang = {
  messages: {
    ...deMessages,
  },
  antd: antdDe,
  locale: 'de-DE',
}
export default DeLang
