
import timezone from '../data/timezone'

export const getTimezoneText = (tz: string | undefined | null): string => {
  if (!tz) {
    return ''
  }
  const foundZone = timezone.find(timezone => timezone.id === tz)
  return foundZone ? foundZone.text : ''
}

export const getTimeZoneOrDefaultToUtc = (): string => Intl.DateTimeFormat().resolvedOptions().timeZone || 'Etc/GMT'