export const customTheme = (theme) => {
  if (theme === 'dark') {
    return {
      colors: {
        textColor: '#F7FAFC', // Lighter text color for contrast
        subtitleColor: '#A0AEC0',
        inactiveColor: '#718096',
        border: '#4A5568', // Darker border color
        background: '#2D3748', // Dark background
        backgroundAlpha: 'rgba(23, 25, 35, 0.6)',
        secondaryBackground: '#1A202C', // Darker secondary background
        highlight: '#4A5568', // Subdued highlight color
        rsi: {
          50: '#e8e1ff', // Lightest
          100: '#e1d8ff',
          200: '#dacfff',
          300: '#d3c6ff',
          400: '#ccbfff',
          500: '#7f00ff', // Base brand color for dark theme
          600: '#d1a3ff',
          700: '#5d00c2',
          800: '#4c00a3',
          900: '#4d4384', // Darkest
        },
      },
      shadows: {
        outline: 0,
      },
      styles: {
        global: {
          '.rdg': {
            contain: 'size layout style paint',
            borderRadius: 'lg',
            border: 'none',
            borderTop: '1px solid var(--rdg-border-color)',
            blockSize: '100%',
            flex: '1',

            // we have to use vars here because chakra does not autotransform unknown props
            '--rdg-row-height': '35px',
            '--rdg-color': 'var(--chakra-colors-textColor)',
            '--rdg-background-color': 'var(--chakra-colors-background)',
            '--rdg-header-background-color': 'var(--chakra-colors-background)',
            '--rdg-row-hover-background-color':
              'var(--chakra-colors-background)',
            '--rdg-selection-color': 'var(--chakra-colors-blue-400)',
            '--rdg-row-selected-background-color': '#4f00a3',
            '--row-selected-hover-background-color':'#4d4384',
            '--rdg-error-cell-background-color': '#F56565',
            '--rdg-warning-cell-background-color':
              'var(--chakra-colors-orange-50)',
            '--rdg-info-cell-background-color': 'var(--chakra-colors-blue-50)',
            '--rdg-border-color': 'var(--chakra-colors-border)',
            '--rdg-frozen-cell-box-shadow': 'none',
            '--rdg-font-size': 'var(--chakra-fontSizes-sm)',
          },
        },
      },
    }
  } else {
    // Light theme colors (existing)
    return {
      colors: {
        textColor: '#2D3748',
        subtitleColor: '#718096',
        inactiveColor: '#A0AEC0',
        border: '#E2E8F0',
        background: '#F7FAFC',
        backgroundAlpha: 'rgba(255,255,255,0)',
        secondaryBackground: '#EDF2F7',
        highlight: '#E2E8F0',
        rsi: {
          50: '#d9b3ff', // Lightest
          100: '#c999ff',
          200: '#b980ff',
          300: '#aa66ff',
          400: '#9a4dff',
          500: '#7f00ff', // Base brand color for light theme
          600: '#6f00e0',
          700: '#5f00c2',
          800: '#4f00a3',
          900: '#3f0085', // Darkest
        },
      },
      shadows: {
        outline: 0,
      },
      styles: {
        global: {
          '.rdg': {
            // we have to use vars here because chakra does not autotransform unknown props
            '--rdg-row-selected-background-color': '#d9b3ff',
            '--row-selected-hover-background-color':'#b980ff',
            '--rdg-error-cell-background-color': '#F56565',
          },
        },
      },
    }
  }
}
