import { MicrosoftAuth } from '../../services/auth/microsoft/microsoft'
import { IMicrosoftUser } from '../../types/microsoft'

export enum ParentComponentEnum {
  createCompany = 'create-company',
  usersManageLicenses = 'users-manage-licenses'
}

export interface IManageAssignLicensesProps {
  numberOflicenses: number
  msAuth: MicrosoftAuth
  onSelectUser: (user: IMicrosoftUser) => void
  curentUser: {
    id: string
    mail: string
    displayName: string
  }
  selectedUsers: IMicrosoftUser[]
  importAllUsers: boolean
  handleChangeRole: (role: 'admin' | 'user', userId: string) => void
  handleChangeAnnounce: (announce: 'none' | 'email' | 'chat', userId: string) => void
  assignLicenses: () => void
  parentComponent: ParentComponentEnum
  assignLicensesLoading?: boolean
  totalUsersProp?: number
  handleBack?: () => void
  handleImportAllUsers?: (importAllUsers: boolean) => void
  handleBulkSelect?: (importAllUsers: boolean, userList: IMicrosoftUser[]) => void
  handleBulkAnnounceAction?: (action: 'none' | 'email' | 'chat') => void
}