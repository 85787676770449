import { find, filter } from 'lodash'

import { CreateCompanyActionsEnum, ICreateCompanyState } from './types'

export const SELECTED_USERS_DEFAULT_LIMIT = 50

export const initialState: ICreateCompanyState = {
  createUser: {
    name: '',
    id: '',
    mail: '',
  },
  createCompany: {
    contactEmail: '',
    name: '',
    country: '',
    state: '',
    hasUnlimitedDays: false,
    daysPerYear: 20,
    plan: null,
    announceNewUsers: false,
  },
  currentStep: 0,
  isLoading: true,
  allUsers: [],
  selectedUsers: [],
  importAllUsers: false,
  emailInvites: [],
  isCreateCompanyLoader: false,
  agreeToTerms: false,
  allUsersLoaded: false,
  skipSelectPlan: false,
}

export const reducer = (state: ICreateCompanyState, action) => {
  switch (action.type) {
    case CreateCompanyActionsEnum.setCurrentStep:
      return {
        ...state,
        currentStep: action.payload,
      }
    case CreateCompanyActionsEnum.onNextStepChange:
      return {
        ...state,
        currentStep: state.currentStep + 1,
      }
    case CreateCompanyActionsEnum.onPrevStepChange:
      return {
        ...state,
        currentStep: state.currentStep - 1,
      }
    case CreateCompanyActionsEnum.setCreateCompanyDetails: {
      const newState = {
        ...state,
        createCompany: {
          ...state.createCompany,
          contactEmail: action.payload.contactEmail,
          name: action.payload.name,
          country: action.payload.country,
          state: action.payload.state,
          hasUnlimitedDays: action.payload.hasUnlimitedDays,
          daysPerYear: action.payload.daysPerYear,
          announceNewUsers: action.payload.announceNewUsers,
          surveyQuestionMainOption: action.payload.surveyQuestionMainOption,
          ...(action.payload.surveyQuestionSubOption ? { surveyQuestionSubOption: action.payload.surveyQuestionSubOption } : {}),
        },
        agreeToTerms: action.payload.agreeToTerms,
      }
      return newState
    }
    case CreateCompanyActionsEnum.setSubscriptionPlan:
      return {
        ...state,
        createCompany: {
          ...state.createCompany,
          plan: action.payload,
        },
      }
    case CreateCompanyActionsEnum.setAnnounceNewUsers:
      return {
        ...state,
        createCompany: {
          ...state.createCompany,
          announceNewUsers: action.payload,
        },
      }
    case CreateCompanyActionsEnum.setCreateUser:{
      let initialUser
      if (action.payload.platform === 'slack') {
        initialUser = {
          name: action.payload.name,
          email: action.payload.email,
          slackId: action.payload.slackUserId,
          isOwner: action.payload.isOwner,
          isAdmin: action.payload.isAdmin,
          image: action.payload.imageUrl,
          id: action.payload.slackUserId,
        }
      }

      if (action.payload.platform === 'microsoft') {
        initialUser = {
          name: action.payload.name,
          email: action.payload.mail,
          msUserId: action.payload.msUserId,
          id: action.payload.id,
        }
      }

      if (action.payload.platform === 'google') {
        initialUser = {
          name: action.payload.name,
          email: action.payload.mail,
          googleId: action.payload.googleUserId,
          id: action.payload.id,
          image: action.payload.image,
        }
      }
      return {
        ...state,
        createUser: action.payload,
        allUsers: [initialUser],
        selectedUsers: [initialUser],
      }
    }
    case CreateCompanyActionsEnum.loadAllUsersSuccess: {
      const initialUser = find(state.allUsers, (u) => u.id === (state.createUser.slackUserId || state.createUser.id))
      const selectedUsersFormStorage = JSON.parse(localStorage.getItem('vtSelectedUsers') || '[]')
      const selectedUsers = state.allUsers.length > SELECTED_USERS_DEFAULT_LIMIT && selectedUsersFormStorage.length === 0
        ? [initialUser]
        : selectedUsersFormStorage.length > 0 ? selectedUsersFormStorage : state.allUsers

      localStorage.setItem('vtSelectedUsers', JSON.stringify(selectedUsers))

      return {
        ...state,
        allUsersLoaded: true,
        importAllUsers: selectedUsers.length === state.allUsers.length,
        selectedUsers,
      }}
    case CreateCompanyActionsEnum.onSelectDeselectAllUsers: {
      const initialUser = find(state.allUsers, (u) => u.id === (state.createUser.slackUserId || state.createUser.id))
      const selectedUsers = !state.importAllUsers ? state.allUsers : [initialUser]

      localStorage.setItem('vtSelectedUsers', JSON.stringify(selectedUsers))

      return {
        ...state,
        selectedUsers,
        importAllUsers: !state.importAllUsers,
      }
    }
    case CreateCompanyActionsEnum.onSelectUser: {
      const selectedUser = find(state.selectedUsers, user => user.id === action.payload.id)
      const selectedUsers = selectedUser ?
        filter(state.selectedUsers, user => user.id !== selectedUser.id) :
        [...state.selectedUsers, action.payload]
      const importAllUsers = state.allUsers.length === selectedUsers.length

      localStorage.setItem('vtSelectedUsers', JSON.stringify(selectedUsers))

      return {
        ...state,
        selectedUsers,
        importAllUsers,
      }
    }
    case CreateCompanyActionsEnum.onAddEmailInvite: {
      const invited = find(state.emailInvites, invite => invite.email === action.payload.email)
      const allInvited = invited ? state.emailInvites : [...state.emailInvites, action.payload]

      localStorage.setItem('vtEmailInvites', JSON.stringify(allInvited))
      return {
        ...state,
        emailInvites: allInvited,
      }
    }
    case CreateCompanyActionsEnum.onRemoveEmailInvite: {
      const invited = find(state.emailInvites, invite => invite.email === action.payload.email)
      const allInvited = invited ? filter(state.emailInvites, invite => invite.email !== invited.email) : state.emailInvites

      localStorage.setItem('vtEmailInvites', JSON.stringify(allInvited))
      return {
        ...state,
        emailInvites: allInvited,
      }
    }
    case CreateCompanyActionsEnum.setEmailInvites: {
      localStorage.setItem('vtEmailInvites', JSON.stringify(action.payload))
      return {
        ...state,
        emailInvites: action.payload,
      }
    }
    case CreateCompanyActionsEnum.isCreateCompanyLoader: {
      return {
        ...state,
        isCreateCompanyLoader: action.payload,
      }}
    case CreateCompanyActionsEnum.insertLoadedUsers: {
      return {
        ...state,
        allUsers: state.allUsers.concat(action.payload),
      }}
    case CreateCompanyActionsEnum.setSkipSelectPlan: {
      return {
        ...state,
        skipSelectPlan: true,
      }}
    default:
      throw new Error()
  }
}