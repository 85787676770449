import { getAuthTokens } from '../../services/auth'

export const getAuth = async (force = false) => {
  const tokens = await getAuthTokens(force)
  const jwtToken = tokens.idToken

  const GraphQlApiUrl = process.env.REACT_APP_GRAPHQL_ENDPOINT || ''
  const GraphQlHost = new URL(GraphQlApiUrl).hostname
  const header = {
    Authorization: jwtToken,
    host: GraphQlHost,
  }
  // For the real-time endpoint, see
  // https://docs.aws.amazon.com/appsync/latest/devguide/real-time-websocket-client.html#discovering-the-appsync-real-time-endpoint-from-the-appsync-graphql-endpoint
  const graphQlSubscriptionUrl = GraphQlApiUrl.includes('appsync-api') ? (
    // If it's not a custom domain, transform it
    GraphQlApiUrl
      .replace('https://', 'wss://')
      .replace('appsync-api', 'appsync-realtime-api')
  ) : (
    // If it's a custom domain, append '/realtime'
    GraphQlApiUrl.replace('https://', 'wss://') + '/realtime'
  )

  return {
    subscriptionUrl: graphQlSubscriptionUrl,
    header,
    jwtToken,
  }
}