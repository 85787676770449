import { useAppSelector } from './hooks'
import { selectAuthCompanySlice } from './auth-company-slice'
import { selectFeatureFlagsSlice } from './feature-flags-slice'

export const useShouldEnableFeatures = (planName: string, feature?: string, onlyFeatureFlag = false): boolean  => {
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const { featureFlags } = useAppSelector(selectFeatureFlagsSlice)
  
  if (onlyFeatureFlag) {
    return !!feature && featureFlags.includes(feature)
  }

  if (authCompany && authCompany.plan) {
    return authCompany.plan === planName || (!!feature && featureFlags.includes(feature))
  }
  return false
}
