/* eslint-disable react/no-unescaped-entities, max-len */
import React from 'react'
import { Button } from 'antd'
import IntlMessages from '../../util/IntlMessages'


export const adminStepsMicrosoft = [
  {
    target: '.joyride-start',
    placement: 'center' as const,
    isFixed: true,
    title:  <IntlMessages id='components.onboarding.joyride.admin.start.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.start1' />
        </p>
        <p>
          <IntlMessages
            id='components.onboarding.joyride.admin.start2'
            values={{
              link: (...chunks) => (
                <a href='mailto:hello@vacationtracker.io'>{chunks}</a>
              ),
            }}
          />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-leave-types-menu',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.leaveTypesMenu.title' />,
    nextUrl: '/app/settings/leave-types',
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.leaveTypesMenu.info1' />
        </p>
        <p>
          <IntlMessages
            id='components.onboarding.joyride.admin.leaveTypesMenu.info2'
            values={{
              link: (...chunks) => (
                <a href='https://vacationtracker.crisp.help/en/'>{chunks}</a>
              ),
            }}
          />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-new-leave-type',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.leaveTypesMenu.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.newLeaveType.info1' />
        </p>
        <p>
          <IntlMessages
            id='components.onboarding.joyride.admin.leaveTypesMenu.info2'
            values={{
              link: (...chunks) => (
                <a href='https://vacationtracker.crisp.help/en/'>{chunks}</a>
              ),
            }}
          />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-location-menu',
    placement: 'auto' as const,
    title:  <IntlMessages id='components.onboarding.joyride.admin.createLocation.title' />,
    previousUrl: '/app/settings/leave-types',
    nextUrl: '/app/settings/locations',
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.createLocation.info1' />
        </p>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.createLocation.info2' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-location-leave-policies',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.assignLeavePolicy.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.assignLeavePolicy.info1' />
        </p>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.assignLeavePolicy.info2' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-location-holidays',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.importHolidays.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.importHolidays.info1' />
        </p>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.importHolidays.info2' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-teams-menu',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.createTeams.title' />,
    previousUrl: '/app/settings/locations',
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.createTeams.info1' />
        </p>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.createTeams.info2' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-notifications',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.createNotifications.title' />,
    previousUrl: '/app/settings/locations',
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.createNotifications.info1' />
        </p>
      </>
    ),
  },
  {
    target: 'body',
    placement: 'center' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.teams.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.teams.info1' />
        </p>
        <Button href="https://teams.microsoft.com/l/app/eab5463e-8168-40ee-887a-7ac78de1d266" type="primary" target="_blank">
          <IntlMessages id='components.onboarding.joyride.admin.teams.cta1' />
        </Button>
        <Button href="https://vacationtracker.crisp.help/en/" type="link" target="_blank">
          <IntlMessages id='components.onboarding.joyride.admin.teams.cta2' />
        </Button>
      </>
    ),
  },
  {
    target: 'body',
    placement: 'center' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.helpDesk.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.helpDesk.info' />
        </p>
      </>
    ),
  },
]

export const adminStepsSlackAndGoogle = [
  {
    target: '.joyride-start',
    placement: 'center' as const,
    isFixed: true,
    title: <IntlMessages id='components.onboarding.joyride.admin.start.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.start1' />
        </p>
        <p>
          <IntlMessages
            id='components.onboarding.joyride.admin.start2'
            values={{
              link: (...chunks) => (
                <a href='mailto:hello@vacationtracker.io'>{chunks}</a>
              ),
            }}
          />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-leave-types-menu',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.leaveTypesMenu.title' />,
    nextUrl: '/app/settings/leave-types',
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.leaveTypesMenu.info1' />
        </p>
        <p>
          <IntlMessages
            id='components.onboarding.joyride.admin.leaveTypesMenu.info2'
            values={{
              link: (...chunks) => (
                <a href='https://vacationtracker.crisp.help/en/'>{chunks}</a>
              ),
            }}
          />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-new-leave-type',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.leaveTypesMenu.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.newLeaveType.info1' />
        </p>
        <p>
          <IntlMessages
            id='components.onboarding.joyride.admin.leaveTypesMenu.info2'
            values={{
              link: (...chunks) => (
                <a href='https://vacationtracker.crisp.help/en/'>{chunks}</a>
              ),
            }}
          />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-location-menu',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.createLocation.title' />,
    previousUrl: '/app/settings/leave-types',
    nextUrl: '/app/settings/locations',
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.createLocation.info1' />
        </p>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.createLocation.info2' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-location-leave-policies',
    placement: 'auto' as const,
    itle: <IntlMessages id='components.onboarding.joyride.admin.assignLeavePolicy.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.assignLeavePolicy.info1' />
        </p>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.assignLeavePolicy.info2' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-location-holidays',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.importHolidays.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.importHolidays.info1' />
        </p>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.importHolidays.info2' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-teams-menu',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.createTeams.title' />,
    previousUrl: '/app/settings/locations',
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.createTeams.info1' />
        </p>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.createTeams.info2' />
        </p>
      </>
    ),
  },
  {
    target: '.joyride-notifications',
    placement: 'auto' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.createNotifications.title' />,
    previousUrl: '/app/settings/locations',
    content: (
      <>
        <IntlMessages id='components.onboarding.joyride.admin.createNotifications.info1' />
      </>
    ),
  },
  {
    target: 'body',
    placement: 'center' as const,
    title: <IntlMessages id='components.onboarding.joyride.admin.helpDesk.title' />,
    content: (
      <>
        <p>
          <IntlMessages id='components.onboarding.joyride.admin.helpDesk.info' />
        </p>
      </>
    ),
  },
]
