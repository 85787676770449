export function safeJSONParse (text: string) {
  if (!text || typeof text !== 'string') {
    return false
  }
  try {
    return JSON.parse(text)
  } catch (e) {
    return ''
  }
}

