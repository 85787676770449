import { useState } from 'react'
import * as Sentry from '@sentry/react'
import { useManualQuery } from 'graphql-hooks'
import { format } from 'date-fns'
import { getWallchartData } from '../../../graphql/custom-queries'
import { ArgsProps } from 'antd/lib/notification'
import { IGetLeaveRequestsByUsers, IWallchartData } from '../../../types/wallchart'
import { NotificationInstance } from 'antd/es/notification/interface'

interface IGetLeavesParams {
  dateFrom: Date
  dateTo: Date
  locationIds: string[]
  teamIds: string[]
  labelIds: string[]
  limit: number
  lastId?: string
  leaveTypeIdFilter?: string
  leaveStatusFilter?: string
}

interface IGetLeavesQueryVariables {
  fromDate: string
  toDate: string
  limit: number
  locationIds: string[]
  labelIds: string[]
  teamIds: string[]
  lastId?: string
}

const useGetLeaves = (errorArgs: ArgsProps, notification: NotificationInstance) => {

  const [leaves, setLeaves] = useState<IGetLeaveRequestsByUsers[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [noMoreLeaves, setNoMoreLeaves] = useState<boolean>(false)
  const dateFormat = 'yyyy-MM-dd'

  const [ getWallchartDataQuery ] = useManualQuery<IWallchartData, IGetLeavesQueryVariables>(getWallchartData)

  const hideLeaves = (leaves: IGetLeaveRequestsByUsers[], leaveStatusFilter?: string, leaveTypeIdFilter?: string) => {
    const hidden = leaves
      .map(data => {
        data.leaves.map(leave => leave.hide = (
          (leaveStatusFilter != undefined && leave.status !== leaveStatusFilter) ||
          (leaveTypeIdFilter != undefined && leave.leaveType.id !== leaveTypeIdFilter) ||
          false
        ))
        return data
      })
    return hidden
  }

  const markMultiLeaveDay = (leaves: IGetLeaveRequestsByUsers[]) => {
    return leaves.forEach(userLeaves => {
      const leaves = userLeaves.leaves
      leaves.forEach(leave => {
        const { startDate } = leave
        const sameDateLeaves = leaves.filter(leave => leave.startDate === startDate)
        if (sameDateLeaves.length > 1) {
          sameDateLeaves.forEach(leave => {
            leave.isMultiLeaveDay = leave.isPartDay ?? false
          })
        }
      })
    })
  }

  const getLeaves = async (params: IGetLeavesParams) => {
    try {
      setNoMoreLeaves(false)
      setLoading(true)
      const response = await getWallchartDataQuery({ variables: {
        fromDate: format(params.dateFrom, dateFormat),
        toDate: format(params.dateTo, dateFormat),
        limit: params.limit,
        locationIds: params.locationIds,
        labelIds: params.labelIds,
        teamIds: params.teamIds,
        lastId: params.lastId,
      }})

      if (response?.data?.getLeaveRequestsByUsers) {
        response.data.getLeaveRequestsByUsers.forEach(leaveRequest => {
          leaveRequest.leaves = leaveRequest.leaves.filter(leave => leave.status === 'APPROVED' || leave.status === 'OPEN')
          leaveRequest.leaves = leaveRequest.leaves ? leaveRequest.leaves : []
        })
      }

      if (!response || !response.data || !response.data.getLeaveRequestsByUsers || response.error) {
        throw new Error(JSON.stringify(response))
      }

      let newLeaves = response.data?.getLeaveRequestsByUsers || []

      markMultiLeaveDay(newLeaves)

      newLeaves = hideLeaves(newLeaves, params.leaveStatusFilter as string, params.leaveTypeIdFilter as string)

      params.lastId ? setLeaves(prevData => {
        const rowData = [...prevData, ...newLeaves]
        const arrayUniqueByKey = [...new Map(rowData.map(item => [item.user.id, item])).values()]

        return arrayUniqueByKey
      }) : setLeaves(newLeaves)

      if (newLeaves.length < params.limit) {
        setNoMoreLeaves(true)
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      Sentry.captureException(error)
      notification.error(errorArgs)
    }
  }

  return { leaves, loading, noMoreLeaves, getLeaves, setLeaves, setLoading, hideLeaves }
}

export default useGetLeaves