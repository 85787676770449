import sha256 from 'crypto-digest-sync/sha256'
import qs from 'qs'

export const	BrowserRuntime = function BrowserRuntime() {
  const loadJSONFromUrl = function (url: string) {
    return fetch(url).then(response => response.json())
  }
  const setIntervalWrapper = function (func: Function, timeout: number) {
    return window.setInterval(func, timeout)
  }
  const clearIntervalWrapper = function (intervalId) {
    return window.clearInterval(intervalId)
  }
  const createFormData = function () {
    return new window.FormData()
  }
  const createBlob = function (contents, contentType) {
    return new Blob([contents], {type: contentType})
  }
  const delay = function (timeout) {
    return new Promise(resolve => window.setTimeout(resolve, timeout))
  }
  const postFormData = function (url, formData, progressListener) {
    if (!url || !formData) {
      throw new Error('invalid-args')
    }
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest(),
        sendError = (e, label) => {
          reject(label)
        }
      request.open('POST', url)
      if (progressListener) {
        request.upload.addEventListener('progress', progressListener)
      }
      request.upload.addEventListener('error', (e) => sendError(e, 'upload error'))
      request.upload.addEventListener('timeout', (e) => sendError(e, 'upload timeout'))
      request.addEventListener('load', () => {
        if (request.status >= 200 && request.status < 400) {
          resolve(request.responseText)
        } else {
          reject(request.responseText || request.status)
        }
      })
      request.addEventListener('error', (e) => sendError(e, 'server error'))
      request.addEventListener('abort', (e) => sendError(e, 'server aborted request'))
      request.send(formData)
    })
  }
  const postUrlEncoded = function (url, map) {
    if (!url || !map) {
      throw new Error('invalid-args')
    }
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest(),
        sendError = (e, label) => {
          reject(label)
        }
      request.open('POST', url)
      request.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
      request.upload.addEventListener('error', (e) => sendError(e, 'upload error'))
      request.upload.addEventListener('timeout', (e) => sendError(e, 'upload timeout'))
      request.addEventListener('load', () => {
        if (request.status >= 200 && request.status < 400) {
          resolve(request.responseText)
        } else {
          reject(request.responseText || request.status)
        }
      })
      request.addEventListener('error', (e) => sendError(e, 'server error'))
      request.addEventListener('abort', (e) => sendError(e, 'server aborted request'))
      request.send(qs.stringify(map))
    })

  }
  const replaceQueryString = function (map) {
    const queryString = qs.stringify(map)
    const url = `${window.location.pathname}?${queryString}`
    window.history.replaceState({}, 'Video Puppet Project', url)
  }
  const queryStringParameters = function () {
    const queryString: string = window.location.search.slice(1)
    return qs.parse(queryString)
  }
  const hashParameters = function () {
    const hash = window.location.hash.slice(1)
    return qs.parse(hash)
  }
  const parseXML = function (xmlString, textQueryElement) {
    try {
      const parser = new DOMParser(),
        doc = parser.parseFromString(xmlString, 'application/xml'),
        element = textQueryElement && doc.querySelector(textQueryElement)
      if (!textQueryElement) {
        return doc
      }
      return element && element.textContent
    } catch (e) {
      // ...
    }
  }
  const removeSessionProperty = function (propKey) {
    try {
      window.sessionStorage.removeItem(propKey)
    } catch (e) {
      return false
    }
  }
  const storeSessionProperty = function (propKey, propVal) {
    if (!propVal) {
      removeSessionProperty(propKey)
    }
    if (typeof propVal !== 'string') {
      throw new Error('invalid-args')
    }
    try{
      window.sessionStorage.setItem(propKey, propVal)
    } catch (e) {
      return false
    }
  }
  const readSessionProperty = function (propKey) {
      try {
        return window.sessionStorage.getItem(propKey)
      } catch (e) {
        return false
      }
    },

    redirect = function (url) {
      window.location.replace(url)
    }
  const decodeBase64 = function (text) {
    return atob(text)
  }
  const bufferEncodeBase64 = function (buffer) {
    const bytes = new Uint8Array(buffer)
    return window.btoa(String.fromCharCode(...bytes))
  }
  const currentTimestamp = () => Date.now(),
    randomArray = (numElements = 32) => {
      const array = new Uint8Array(numElements)
      window.crypto.getRandomValues(array)
      return array
    }
  const uniqueString = (numElements: number) => {
    const validChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
      array = randomArray(numElements).map(x => validChars.charCodeAt(x % validChars.length))
    return String.fromCharCode(...array)
  }
  const absoluteUrl = (url: string, queryStringMap) => {
    const baseUrl = url || '/'
    const urlWithSlash = baseUrl.startsWith('/') ? baseUrl : '/' + baseUrl
    const queryString = qs.stringify(queryStringMap)
    return `${document.location.origin}${urlWithSlash}?${queryString}`
  }
  const postOpenerMessage = (type, payload) => {
    // TODO: restrict this to window.origin;
    window.opener.postMessage({type, payload}, '*')
  }
  const // don't make this async, it will break browser security
    openAndWaitForMessage = (url: string, messageType: string): Promise<any> => {
      let callback

      const windowListener = (windowData) => {
        // todo: validate origin!
        if (windowData && windowData.data && windowData.data.type === messageType) {
          window.removeEventListener('message', windowListener)
          callback(windowData.data.payload)
        }
      }

      const storageListener = (windowData) => {
        const urlString = new URL(url)
        const state = urlString.searchParams.get('state')
        if (windowData && windowData.type === 'storage' && windowData.key === state) {
          window.removeEventListener('storage', storageListener)
          const windowDataUrl = new URL(windowData.url)
          const queryString = windowDataUrl.search
          if (state && localStorage.getItem(state)) {
            localStorage.removeItem(state)
          }
          callback(queryString)
        }
      }
      window.addEventListener('message', windowListener)
      window.addEventListener('storage', storageListener)
      window.open(url)
      return new Promise(resolve => callback = resolve)
    }
  const closeWindow = () => {
    window.close()
  }
  const sha256Digest = (text) => {
    const encoder = new TextEncoder()
    return window.crypto.subtle.digest('SHA-256', encoder.encode(text))
  }
  const sha256DigestSync = (text) => {
    const encoder = new TextEncoder()
    return sha256(encoder.encode(text))
  }
  return Object.freeze(Object.assign({}, {
    loadJSONFromUrl,
    setInterval: setIntervalWrapper,
    clearInterval: clearIntervalWrapper,
    createFormData,
    createBlob,
    delay,
    postFormData,
    replaceQueryString,
    queryStringParameters,
    hashParameters,
    parseXML,
    storeSessionProperty,
    readSessionProperty,
    removeSessionProperty,
    redirect,
    decodeBase64,
    bufferEncodeBase64,
    uniqueString,
    absoluteUrl,
    postUrlEncoded,
    close: closeWindow,
    postOpenerMessage,
    openAndWaitForMessage,
    currentTimestamp,
    sha256Digest,
    sha256DigestSync,
  }))
}
