import { IGetFormattedDaysAndHours } from '../types/display-in-days-hours'
import { roundTo2Decimals, roundTo4Decimals } from './calculation-shared'

export interface IComputeDaysAndHours {
  days: number
  hours: number
  minutes: number
  isNegative: boolean
}

//TODO workday change this once we implement user workweek schedule
export function getDaysOrHours(value: number, hourlyLeaveAccounting: boolean, numberOfWorkingHoursPerDay: number): number {
  if (hourlyLeaveAccounting) {
    return value * numberOfWorkingHoursPerDay
  } else {
    return value
  }
}

export function setDaysOrHours(value: number, hourlyLeaveAccounting: boolean, numberOfWorkingHoursPerDay: number): number {
  if (hourlyLeaveAccounting) {
    return roundTo4Decimals(value / numberOfWorkingHoursPerDay)
  } else {
    return value
  }
}

export function formatDaysHoursValue(days: number, hasUnlimitedDays: boolean, hourlyLeaveAccounting: boolean, numberOfWorkingHoursPerDay: number): string {
  if (hasUnlimitedDays) {
    return '∞'
  } else if (hourlyLeaveAccounting) {
    return roundTo2Decimals(days * numberOfWorkingHoursPerDay).toString()
  } else {
    return roundTo2Decimals(days).toString()
  }
}

export function computeDaysAndHours(hours: number, numberOfWorkingHoursPerDay: number): IComputeDaysAndHours {
  const numberOfMinutesPerHour = 60
  const totalMinutes = hours * numberOfMinutesPerHour
  const workingDayMinutes = numberOfWorkingHoursPerDay * numberOfMinutesPerHour
  const isNegative = Math.sign(hours) === -1

  const absMinutes = Math.abs(totalMinutes)
  let days = Math.floor(absMinutes / workingDayMinutes)
  const remainingMinutes = absMinutes % workingDayMinutes
  let computedHours = Math.floor(remainingMinutes / numberOfMinutesPerHour)
  let minutes = Math.round(remainingMinutes % numberOfMinutesPerHour)

  if (minutes === 60) {
    computedHours += 1
    minutes = 0
  }

  if (computedHours === numberOfWorkingHoursPerDay) {
    days += 1
    computedHours = 0
  }

  const roundedMinutes = numberOfWorkingHoursPerDay === Math.floor(numberOfWorkingHoursPerDay) ? 0 : Math.floor(minutes / 15) * 15

  return {
    days,
    hours: computedHours,
    minutes: roundedMinutes,
    isNegative,
  }
}

export const getFormattedDaysAndHours = ({
  leave,
  formatMessage,
  displayFormat,
}: IGetFormattedDaysAndHours): string => {
  if (leave.days === 0 && leave.hours === 0) {
    return displayFormat === 'long'
      ? `0 ${formatMessage({ id: 'dashboard.hour' })}`
      : `0${formatMessage({ id: 'dashboard.hourSingle' })}`
  }

  const daysLong = leave.days !== 0
    ? `${leave.days} ${formatMessage({ id: 'dashboard.daysSmallCase' })}`
    : ''
  const hoursLong = leave.hours !== 0
    ? `${leave.hours} ${formatMessage({ id: 'dashboard.hour' })}`
    : ''
  const daysShort = leave.days !== 0
    ? `${leave.days}${formatMessage({ id: 'dashboard.daysSmallCaseSingle' })}`
    : ''
  const hoursShort = leave.hours !== 0
    ? `${leave.hours}${formatMessage({ id: 'dashboard.hourSingle' })}`
    : ''
  const minutes = leave.minutes !== 0
    ? ` ${leave.minutes}${formatMessage({ id: 'dashboard.minuteSingle' })}`
    : ''
  const justString = displayFormat === 'long'
    ? `${daysLong} ${hoursLong}${minutes}`
    : `${daysShort} ${hoursShort}${minutes}`

  // This replace removing whitespace from both sides of a string
  return `${leave.isNegative ? '-' : ''}${justString.trim()}`
}
