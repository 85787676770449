import React, { useState, useContext, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { App, Breadcrumb } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import omit from 'lodash/omit'
import { useManualQuery } from 'graphql-hooks'

import Api from '@vacationtracker/shared/services/api'
import * as logger from '../../../services/logger'
import { getUserForLeaveRequest } from '../../../graphql/custom-queries'
import { getToilRequestByIdForSubscription } from '../../../graphql/custom-queries'
import { notificationStore } from '../../../context/notificationsContext/store'
import { useAppSelector } from '../../../store/hooks'
import { selectAuthUserSlice } from '../../../store/auth-user-slice'
import { FrontendUrls } from '../../../types/urls'

import CircularProgress from '../../../components/circular-progress'
import IntlMessages from '../../../util/IntlMessages'
import ToilForm from '@vacationtracker/shared/components/toil-form'
import { showErrors } from '@vacationtracker/shared/components/toil-form/errors'

import { IGetToilRequestForSubscription, IGetUserForLeaveRequest, IGetUserForLeaveReueqstUserData } from '../../../types/custom-queries'
import { IToilRequestUpdate } from '../../../types/custom-queries'
import { IToilFormData } from '@vacationtracker/shared/components/toil-form/types'
import { HourFormatEnum } from '@vacationtracker/shared/types/user'

interface IRequestToilProps {
  match: {
    params: {
      id: string
    }
  }
  history: {
    push: Function
    goBack: Function
  }
}

const RequestToilPage = ({ match, history }: IRequestToilProps) => {
  const { notification } = App.useApp()
  const { authUser } = useAppSelector(selectAuthUserSlice)

  const { formatMessage } = useIntl()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)

  const [toilRequestId, setToilRequestId] = useState(match.params.id)
  const [userId, setUserId] = useState<string>('')
  const [user, setUser] = useState<IGetUserForLeaveReueqstUserData>()

  const [isFormBeingSubmitted, setFormIsBeingSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  const [toilRequest, setToilRequest] = useState<IGetToilRequestForSubscription | undefined>(undefined)

  const [ getToilRequestByIdForSubscriptionQuery ] = useManualQuery<IToilRequestUpdate, { toilRequestId: string }>(getToilRequestByIdForSubscription)
  const [ getUserForLeaveRequestQuery ] = useManualQuery<IGetUserForLeaveRequest, { id: string }>(getUserForLeaveRequest)

  useEffect(() => {
    fetchData(match.params.id)
    setToilRequestId(match.params.id)
  }, [match.params.id])

  const fetchData = async (toilRequestId: string) => {
    try {
  
      const response = await getToilRequestByIdForSubscriptionQuery({ variables: { toilRequestId } })

      if (!response?.data?.getToilRequest) {
        notification.error({
          message: formatMessage({ id: 'components.toil.requestNotFound' }),
          icon: (<LoadingOutlined />),
          duration: 0,
        })
        history.push(FrontendUrls.dashboard)
      } else {
        setToilRequest(response.data.getToilRequest)
        const userIdLocal = response.data.getToilRequest.user.id
        setUserId(userIdLocal)
        const userResponse = await getUserForLeaveRequestQuery({ variables: { id: userIdLocal } })
        if (!userResponse?.data || userResponse.error) throw userResponse.error
        setUser(userResponse.data.getUser)
        setIsLoading(false)
      }
    } catch (err) {
      notification.error({
        message: formatMessage({ id: 'components.toil.requestNotFound' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      history.push(FrontendUrls.dashboard)
    }
  }

  const handleSubmit = async (data: IToilFormData) => {
    setFormIsBeingSubmitted(true)
    let response
    const params = omit(data, ['addToil'])
    
    const body = { 
      ...params ,
      eventType: 'TOIL_REQUEST_UPDATED', 
      eventGroup: 'USER_TOIL_REQUEST', 
      toilRequestId,
      userId,
    }

    try {
      response = await Api.post('/core/toil-request-validate', body)
      response = await Api.post('/core/event', body)
      notification.open({
        key: response.correlationId,
        message: formatMessage({ id: 'components.toil.editToilProgress' }),
        icon: (<LoadingOutlined />),
        duration: 0,
      })
      setActionNotifications([ ... (actionNotifications ?? []), response.correlationId ])
      setFormIsBeingSubmitted(false)
      history.goBack()
    } catch (error) {
      logger.warning('error', error)
      setFormIsBeingSubmitted(false)
      if (error.response?.data?.error || error.response.data.message) {
        notification.error({
          message: formatMessage({ id: 'components.toil.submitError' }),
          description: formatMessage({ id: showErrors(error.response.data.message || error.response?.data?.error) }),
          duration: 0,
        })
      } else {
        const description = response?.correlationId ? formatMessage({ id: 'app.correlationIdError' }, { correlationId: response.correlationId }) : JSON.stringify(error)

        notification.error({
          message: formatMessage({ id: 'components.toil.submitError' }),
          description,
          duration: 0,
        })
      }
    }
  }

  return (
    <div className='main-content'>
      <div className="main-content-header">
        <div className="main-content-header-title">
          <IntlMessages id='components.toil.editRequestToil' />
        </div>
        <div className="main-content-header-breadcrumb">
          <Breadcrumb
            items={[
              {
                title: <Link to={FrontendUrls.dashboard}><IntlMessages id="sidebar.dashboard" /></Link>,
              },
              {
                title: <IntlMessages id='components.toil.editRequestToil' />,
              },
            ]}
          />
        </div>
      </div>
      <div className="main-content-body">
        {isLoading ? 
          <CircularProgress /> : 
          <ToilForm
            authUserId={authUser.id}
            authUserRole={authUser.role}
            loading={isFormBeingSubmitted}
            selectedUser={user}
            listOfUsers={[user as IGetUserForLeaveReueqstUserData]}
            hourFormat={authUser.hourFormat as HourFormatEnum}
            onSave={(data: IToilFormData) => {
              (async () => {
                await handleSubmit(data)
              })()
            }}
            onCancel={() => { history.goBack(); history.goBack() }}
            toilRequest={toilRequest}
            isEditToilRequest={true}
          />
        }
      </div>
    </div>
  )
}

export default RequestToilPage
