import { History } from 'history'

import { signout } from '@vacationtracker/shared/services/auth'
import { AppDispatch, logoutAction } from '../../../store/configure'
import { DASHBOARD_SURVEY_STORAGE_KEY } from '../../../types/dashboard'
import { FrontendUrls } from '../../../types/urls'
import { setAuthState } from '../../../store/auth-state-slice'

import { AuthStateEnum } from '@vacationtracker/shared/types/auth-state'

interface ILogoutArgument {
  history: History
  reduxDispatch: AppDispatch
  redirectRoute?: string
  userId?: string
  globalSignout?: boolean
}

export const logout = async ({
  history,
  redirectRoute = FrontendUrls.signin,
  reduxDispatch,
  userId,
  globalSignout,
}: ILogoutArgument) => {
  const badgeFeatures = localStorage.getItem('badgeFeatures')
  const dashboardSurvey = localStorage.getItem(DASHBOARD_SURVEY_STORAGE_KEY)
  const onboardingKey = localStorage.getItem('onboardingKey')
  const dashboardFilters = localStorage.getItem(`${userId ? `${userId}#` : ''}dashboardFilters`)
  const wallchartFilters = localStorage.getItem(`${userId ? `${userId}#` : ''}wallchartFilters`)
  const usersFilters = localStorage.getItem(`${userId ? `${userId}#` : ''}usersFilters`)
  const calendarFilters = localStorage.getItem(`${userId ? `${userId}#` : ''}calendarFilters`)
  localStorage.clear()
  const vtOnboardingClosedDate = localStorage.getItem('vtOnboardingClosedDate')
  sessionStorage.clear()
  // since we clear clear local storage
  // we need to keep badge features state in there
  // in order not to show badges on already seen features
  // on the next login
  badgeFeatures && localStorage.setItem('badgeFeatures', badgeFeatures as string)
  dashboardFilters && localStorage.setItem(`${userId ? `${userId}#` : ''}dashboardFilters`, dashboardFilters as string)
  wallchartFilters && localStorage.setItem(`${userId ? `${userId}#` : ''}wallchartFilters`, wallchartFilters as string)
  usersFilters && localStorage.setItem(`${userId ? `${userId}#` : ''}usersFilters`, usersFilters as string)
  calendarFilters && localStorage.setItem(`${userId ? `${userId}#` : ''}calendarFilters`, calendarFilters as string)
  dashboardSurvey && localStorage.setItem(DASHBOARD_SURVEY_STORAGE_KEY, dashboardSurvey as string)
  onboardingKey && localStorage.setItem('onboardingKey', onboardingKey as string)
  vtOnboardingClosedDate && localStorage.setItem('vtOnboardingClosedDate', vtOnboardingClosedDate as string)
  reduxDispatch(setAuthState(AuthStateEnum.signIn))
  history.push(redirectRoute)
  await signout(globalSignout || false)
  reduxDispatch(logoutAction)
}
