import { isAfter } from 'date-fns'
import { AccrualType, AccrualTypeEnum } from '../types/leave-policy'

interface IAccruedDaysInfo {
  accrualType: AccrualType
  allowAdvanceAccrualUsage?: boolean
  leaveRequestEndDate?: string
  currentDays?: number
  currentYearDays?: number
}

export function shouldShowFutureAccruedDaysInfo({
  accrualType,
  allowAdvanceAccrualUsage,
  currentYearDays,
  leaveRequestEndDate,
  currentDays,
}: IAccruedDaysInfo): boolean {
  return Boolean(allowAdvanceAccrualUsage
    && accrualType !== AccrualTypeEnum.none
    && leaveRequestEndDate
    && isAfter(new Date(leaveRequestEndDate as string), new Date())
    && currentYearDays !== currentDays)
}