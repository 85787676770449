import { Tooltip } from 'antd'
import { UnlockOutlined } from '@ant-design/icons'
import React from 'react'
import { IQuotaBoxLockedProps } from './types'
import IntlMessages from '../utils/IntlMessages'
import { DisplayLeaveInDaysAndHours } from '../display-leave-in-days-and-hours'
import { LeaveDisplayFormatEnum } from '../../types/display-in-days-hours'

const QuotaBox = ({
  isLocked,
  title,
  lockedTooltip,
  dataTestId,
  footer,
  value,
  //TODO workday change this once we implement user workweek schedule
  numberOfWorkingHoursPerDay,
}: IQuotaBoxLockedProps): React.ReactElement => {
  return (
    <Tooltip title={isLocked ? lockedTooltip : ''}>
      <div className={`quota quota-box ${isLocked ? 'lock' : ''}`}>
        {isLocked && (
          <div className='quota-card-lock'>
            <div>
              <UnlockOutlined />
              <br />
              <IntlMessages id='components.userLeaveQuotas.unlock' />
            </div>
          </div>
        )}
        <div className='quota-box-title' data-testid={`${dataTestId}-title`}>
          {title}
        </div>
        <div className='quota-box-value' data-testid={`${dataTestId}-value`}>
          {formatValue(value, numberOfWorkingHoursPerDay)}
        </div>
        <div className='quota-box-footer' data-testid={`${dataTestId}-footer`}>
          {footer}
        </div>
      </div>
    </Tooltip>
  )
}

function formatValue(value: string | number, numberOfWorkingHoursPerDay: number): string | React.ReactElement {
  return typeof value === 'number'
    ? <DisplayLeaveInDaysAndHours value={value} format={LeaveDisplayFormatEnum.short} numberOfWorkingHoursPerDay={numberOfWorkingHoursPerDay}/>
    : value
}

export default QuotaBox
