import axios from 'axios'

import { ICheckUserId } from '@vacationtracker/shared/types/company'
import Api from '@vacationtracker/shared/services/api'

const url = `${process.env.REACT_APP_API_URL}/slack`

export const getUserId = async (slackId: string, token: string): Promise<ICheckUserId> => {
  const response = await axios.post(`${url}/get-user`, {
    slackId,
    token,
  }, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  return response.data
}

export interface IUpdateTokenParams {
  token: string
  botToken?: string
}

export const updateSlackToken = async (token: string, botToken?: string) => {
  const body: IUpdateTokenParams = {
    token,
  }
  if (botToken) {
    body.botToken = botToken
  }

  return await Api.post('/slack/update-tokens', body)
}