import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { App, Typography, Form, Checkbox, Input, DatePicker, Radio, Button, Select } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useManualQuery } from 'graphql-hooks'
import { useIntl } from 'react-intl'
import { notificationStore } from '../../context/notificationsContext/store'
import { getAutomationsById } from '../../graphql/custom-queries'
import { track } from '../../services/analytics/analytics'
import * as logger from '../../services/logger'

import FilterAdvanced from '@vacationtracker/shared/components/filter-advanced'
import IntlMessages from '../../util/IntlMessages'
import CircularProgress from '../circular-progress'
import AddonBlackoutSummaryInfo from '../blackout-summary-info'
import Api from '@vacationtracker/shared/services/api'

import { RangePickerProps } from 'antd/lib/date-picker'
import { IAutomationBlackOutCreateEvent, IAutomationBlackOutForm } from '@vacationtracker/shared/types/automations'
import { ITeamShort } from '@vacationtracker/shared/types/team'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { IGetTeamsShort } from '../../types/teams'
import { IGetLocationsShort } from '../../types/locations'
import { IGetLabelsShort } from '../../types/labels'
import { IBlackOutAutomation, IGetAutomationsByIdData, ILeaveTypeListShort } from '../../types/custom-queries'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'
import { FrontendUrls } from '../../types/urls'
import { AddonsStatus, AddonsStatusEnum } from '@vacationtracker/shared/types/addons'

const { TextArea } = Input
const { RangePicker } = DatePicker
const { Text } = Typography
const { Option } = Select

interface IBlackOutPeriodForm {
  teams: IGetTeamsShort[] | ITeamShort[]
  locations: IGetLocationsShort[]
  labels: IGetLabelsShort[]
  leaveTypes: ILeaveTypeListShort[]
  totalUsers: number
  filter: IFilter
  handleFilter: (filter: IFilter) => void
  backToAddons: () => void
  shouldEnableLabels: boolean
  automationId?: string
  amIAdmin: boolean
  status?: AddonsStatus
}

const BlackoutPeriodForm = ({
  teams,
  locations,
  labels,
  leaveTypes,
  totalUsers,
  filter,
  handleFilter,
  shouldEnableLabels,
  automationId,
  backToAddons,
  amIAdmin,
  status,
}: IBlackOutPeriodForm) => {
  const [form] = Form.useForm()
  const { formatMessage } = useIntl()
  const history = useHistory()
  const { actionNotifications, setActionNotifications } = useContext(notificationStore)
  const { notification } = App.useApp()

  const [getAutomationsByIdQuery] = useManualQuery<IGetAutomationsByIdData, { id: string }>(getAutomationsById)

  const [applyToAllUsers, setApplyToAllUsers] = useState(amIAdmin ? true : false)
  const [isLoading, setIsLoading] = useState(true)
  const [btnLoading, setBtnLoading] = useState(false)
  const [addon, setAddon] = useState<IAutomationBlackOutForm>({
    automationId: '',
    name: '',
    leaveTypeIds: leaveTypes.map(leaveType => leaveType.id),
    startDate: '',
    endDate: '',
    recurring: true,
    applyToAllUsers: amIAdmin ? true : false,
    reason: formatMessage({ id: 'automations.blackoutPeriod.autoRejectReasonDefault' }),
    isActive: true,
    locations: [],
    teams: [],
    labels: [],
    period: [dayjs(), dayjs()],
  })
  const [leaveTypeNames, setLeaveTypeNames] = useState<string[]>([])
  const [initialFilterValues, setInitialFilterValues] = useState([{}])

  useEffect(() => {
    if (automationId) {
      fetchAutomations(automationId)
    }
  }, [automationId])

  const fetchAutomations = async (id) => {
    try {
      const response = await getAutomationsByIdQuery({ variables: { id } })
      if (!response.data || response.error) throw response.error
      let applyToAllUsers = true
      const getAutomationData = response.data.getAutomation as IBlackOutAutomation

      handleFilter({
        labelIds: getAutomationData?.labels || [],
        locationIds: getAutomationData?.locations || [],
        teamIds: getAutomationData?.teams || [],
      })

      if (
        getAutomationData?.locations?.length > 0 ||
        getAutomationData?.teams?.length > 0 ||
        getAutomationData?.labels?.length > 0
      ) {
        applyToAllUsers = false
        form.setFieldsValue({ applyToAllUsers })
        setApplyToAllUsers(applyToAllUsers)
        setInitialFilterValues(() => {
          const data: ISelected[] = []
          const automationData = getAutomationData

          if (automationData) {
            if (automationData.locations.length > 0) {
              data.push({ type: 'Locations', values: automationData.locations })
            }
            if (automationData.teams.length > 0) {
              data.push({ type: 'Departments', values: automationData.teams })
            }
            if (automationData?.labels && automationData.labels.length > 0) {
              data.push({ type: 'Labels', values: automationData.labels})
            }
          }
          if(data.length === 0) {
            return [{}]
          }
          return data
        })
      }

      setAddon({
        automationId: getAutomationData.id,
        name: getAutomationData.name,
        leaveTypeIds: getAutomationData.leaveTypeIds,
        startDate: getAutomationData.startDate as string,
        endDate: getAutomationData.endDate as string,
        recurring: getAutomationData.recurring,
        applyToAllUsers: applyToAllUsers,
        reason: getAutomationData.reason,
        isActive: getAutomationData.isActive,
        locations: getAutomationData.locations,
        teams: getAutomationData.teams,
        labels: getAutomationData.labels,
        period: [dayjs(getAutomationData.startDate as string), dayjs(getAutomationData.endDate)],
      })
      changeLeaveTypes(getAutomationData.leaveTypeIds)

      setIsLoading(false)
    } catch (err) {
      logger.error('ERROR FETCH DATA', err)
    }
  }

  const onFinish = () => {
    if (status === AddonsStatusEnum.CANCELED) return

    form
      .validateFields()
      .then(async (values) => {
        setBtnLoading(true)
        let response
        try {
          const data = {
            name: values.name,
            leaveTypeIds: values.leaveTypeIds,
            startDate: values.period[0].format('YYYY-MM-DD'),
            endDate: values.period[1].format('YYYY-MM-DD'),
            recurring: values.recurring,
            reason: values.reason,
            locations: filter.locationIds,
            teams: amIAdmin ? filter.teamIds : values.teams,
            labels: filter.labelIds,
          }

          track('BLACKOUT_PERIOD_LEAVE_TYPE_LIST_UPDATED', {
            includedLeaveTypes: leaveTypes.filter(leaveType => values.leaveTypeIds.includes(leaveType.id)).map(leaveType => leaveType.name),
            excludedLeaveTypes: leaveTypes.filter(leaveType => !values.leaveTypeIds.includes(leaveType.id)).map(leaveType => leaveType.name),
          })

          if (!automationId) {
            response = await Api.post<Partial<IAutomationBlackOutCreateEvent>>('/core/event', {
              eventType: 'BLACKOUT_PERIOD_CREATED',
              eventGroup: 'AUTOMATION',
              ...data,
            })
          } else {
            response = await Api.post<Partial<IAutomationBlackOutCreateEvent>>('/core/event', {
              eventType: 'BLACKOUT_PERIOD_UPDATED',
              eventGroup: 'AUTOMATION',
              automationId,
              ...data,
              isActive: true,
            })
          }

          notification.open({
            key: response.correlationId,
            message: formatMessage({ id: automationId ? 'automations.blackoutPeriod.updateInProgress' : 'automations.blackoutPeriod.createInProgress' }),
            icon: (<LoadingOutlined />),
            duration: 0,
          })
          setActionNotifications([ ...actionNotifications, response.correlationId ])

          setTimeout(() => {
            // It's not necessary, but it looks nicer if it waits a bit because in 90% of cases automation creation will be completed by then, 
            // and it will immediately appear in the view section.
            history.push(`${FrontendUrls.addons}/blackout-period/view`)
          }, 700)
        } catch (error) {
          setBtnLoading(false)
          if (error.response?.data?.error) {
            notification.error({
              message: formatMessage({ id: 'error.automations.automationSubmitError' }),
              description: formatMessage({ id: error.response.data.error }),
              duration: 0,
            })
          } else {
            let description
            let message = formatMessage({ id: 'error.automations.automationSubmitError' })
            if (response?.correlationId) {
              description = formatMessage({ id: 'error.automations.automationSubmitError' })
            } else if (error.message === 'Request failed with status code 400') {
              message = formatMessage({ id: 'error.automations.insufficientPrivileges.title' })
              description = formatMessage({ id: 'error.automations.insufficientPrivileges.description' })
            } else {
              description = error.message
            }

            notification.error({
              message,
              description,
              duration: 0,
            })
          }
        }
      })
      .catch(info => {
        logger.warning('Validate Failed:', info)
        setBtnLoading(false)
      })
  }

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().subtract(1, 'day')
  }

  const changeRecurring = (e) => {
    setAddon(automation => {
      return {
        ...automation,
        recurring: e.target.checked,
      }
    })
  }

  const changePeriod = (period) => {
    setAddon(automation => {
      return {
        ...automation,
        startDate: period[0].format('YYYY-MM-DD'),
        endDate: period[1].format('YYYY-MM-DD'),
      }
    })
  }

  const changeLeaveTypes = (selectedLeaveTypeIds) => {
    const leaveTypesNames = leaveTypes.map(leaveType => {
      const selectedLeaveTypes = selectedLeaveTypeIds || []
      if (selectedLeaveTypes.includes(leaveType.id)) {
        return leaveType.name
      }
    }).filter(Boolean) as string[] | []
    setLeaveTypeNames(leaveTypesNames)
  }

  const handleApplyToAllUsers = () => {
    setApplyToAllUsers(!applyToAllUsers)
    if(!applyToAllUsers) {
      handleFilter({
        labelIds: [],
        locationIds: [],
        teamIds: [],
      })
    }
  }

  const layout = {
    labelCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 6 }, lg: { span: 7 }, xl: { span: 6 } },
    wrapperCol: { xs: { span: 24 }, sm: { span: 24 }, md: { span: 10 }, lg: { span: 17 }, xl: { span: 14 } },
  }

  const tailLayout = {
    wrapperCol: { xs: { span: 24, offset: 0 }, sm: { span: 24, offset: 0 }, md: { span: 12, offset: 7 }, lg: { span: 12, offset: 7 } },
  }

  return (
    <>
      {(isLoading && automationId) ?
        <CircularProgress /> :
        <>
          <div style={{ marginBottom: 20 }}>
            <Text><IntlMessages id="automationsForm.blackoutPeriodShortText" /></Text>
          </div>
          <Form
            layout="horizontal"
            form={form}
            name="automationsForm"
            initialValues={addon}
            requiredMark="optional"
            labelWrap
            onFinish={onFinish}
            disabled={status === AddonsStatusEnum.CANCELED}
            scrollToFirstError
            {...layout}
          >
            <Form.Item
              label={<IntlMessages id="app.name" />}
              name="name"
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
            >
              <Input style={{ maxWidth: 300 }}/>
            </Form.Item>

            {amIAdmin &&
              <Form.Item
                required={true}
                label={<IntlMessages id="automationsForm.applyToAllUsers" />}
                tooltip={<IntlMessages id="automationsForm.applyToAllUsersTooltip" />}
                name="applyToAllUsers"
              >
                <Radio.Group onChange={handleApplyToAllUsers}>
                  <Radio.Button value={true}><IntlMessages id="app.yes" /></Radio.Button>
                  <Radio.Button value={false}><IntlMessages id="app.no" /></Radio.Button>
                </Radio.Group>
              </Form.Item>
            }

            {!applyToAllUsers && amIAdmin &&
              <FilterAdvanced
                isForm={true}
                data={{
                  Locations: locations,
                  Departments: teams,
                  Labels: labels,
                }}
                onChangeFilter={handleFilter}
                showLabels={shouldEnableLabels}
                toolTipInfo="automationsForm.advancedFilter.tooltipInfo"
                initialValues={initialFilterValues}
                filterByWidth={160}
                page={automationId ? undefined : 'automationsForm'}
              />
            }
            {!amIAdmin &&
              <Form.Item
                label={<IntlMessages id="calendar.filterByTeam" />}
                rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
                name="teams"
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder={<IntlMessages id="app.pleaseSelect" />}
                >
                  {teams.map((team) => (
                    <Option value={team.id} key={team.id}>
                      {team.name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            }

            <Form.Item
              label={<IntlMessages id="app.leaveTypes" />}
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              name="leaveTypeIds"
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder={<IntlMessages id="app.pleaseSelect" />}
                onChange={changeLeaveTypes}
              >
                {leaveTypes.map((leaveType) => (
                  <Option value={leaveType.id} key={leaveType.id}>
                    {leaveType.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              label={<IntlMessages id="automationsForm.period" />}
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
              name="period"
            >
              <RangePicker disabledDate={disabledDate} onChange={changePeriod} allowClear={false}/>
            </Form.Item>

            <Form.Item
              required={true}
              label={<IntlMessages id="automationsForm.recurring" />}
              name="recurring"
              valuePropName="checked"
            >
              <Checkbox onChange={changeRecurring} disabled={status === AddonsStatusEnum.CANCELED}/>
            </Form.Item>

            <AddonBlackoutSummaryInfo
              leaveTypes={leaveTypeNames}
              filters={filter}
              noOfUsers={totalUsers}
              recurring={addon.recurring}
              fromDate={addon.startDate}
              toDate={addon.endDate}
            />

            <Form.Item
              style={{ marginTop: 20 }}
              name="reason"
              label={<IntlMessages id="automationsForm.autoRejectReason"/>}
              tooltip={<IntlMessages id="automationsForm.autoRejectReasonTooltip" />}
              rules={[{ required: true, message: <IntlMessages id="form.inputRequired" /> }]}
            >
              <TextArea rows={4} />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <div>
                <Button type="default" style={{ marginRight: 10 }} onClick={backToAddons}>
                  <IntlMessages id="app.cancel" />
                </Button>
                <Button type="primary"
                  loading={btnLoading}
                  htmlType="submit"
                >
                  {automationId ? <IntlMessages id="app.update" /> : <IntlMessages id="app.create" />}
                </Button>
              </div>
            </Form.Item>
          </Form>
          <IntlMessages 
            id="automations.stepByStepHelpDesk" 
            values={{
              automationName: () => <IntlMessages id="automations.BLACKOUT_PERIOD" />,
              link: (...chunks) => 
                <a 
                  href="https://vacationtracker.crisp.help/en/article/how-to-set-a-blackout-period-block-time-1dwxmnh/" 
                  target="_blank" 
                  id="helpdesk" 
                  rel="noopener noreferrer"
                >
                  {chunks}
                </a>,
            }}
          />
        </>
      }
    </>
  )
}
export default BlackoutPeriodForm
