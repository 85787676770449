import React, { useState } from 'react'
import { Modal, Button, Form, Col, Row } from 'antd'
import IntlMessages from '../../util/IntlMessages'
import { SelectChannelFormFragment } from '../select-channel-form-fragment'
import { track } from '../../services/analytics/analytics'
import * as logger from '../../services/logger'
import Api from '@vacationtracker/shared/services/api'
import { IAnnounceVtCompanyEvent } from '@vacationtracker/shared/types/bulk'

interface Props {
  visible: boolean
  onCancel: () => void
  platform: string
}

export const AnnouncementModal: React.FC<Props> = ({ visible, onCancel, platform}: Props) => {
  const [form] = Form.useForm()
  const [isSending, setSending] = useState<boolean>(false)

  const sendAnnounceMessage = async () => {
    try {
      const values = await form.validateFields()
      setSending(true)
      await Api.post<Partial<IAnnounceVtCompanyEvent>>('/core/event', {
        eventType: 'ANNOUNCE_VT_COMPANY',
        eventGroup: 'BULK_ACTIONS',
        destination: platform === 'google' || platform === 'email'
          ? values.channel.map(element => element.name).join()
          : values.channel,
      })
      track('ANNOUNCEMENT_SENT', { platform })
      setSending(false)
      onCancel()
    } catch(err) {
      logger.warning(err)
      setSending(false)
    }
  }

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 6 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 12 },
    },
  }

  return (
    <Modal
      title={<IntlMessages id="components.announcementModal.announceToCompany" />}
      open={visible}
      footer={null}
      onCancel={onCancel}
    >

      <Form
        {...formItemLayout}
        key="channelForm"
        form={form}
        name="channelForm"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onFinish={sendAnnounceMessage}
      >
        <Row style={{ marginTop: 24 }}>
          <Col span={24}>
            <SelectChannelFormFragment />
          </Col>
        </Row>
        <Row>
          <Col>
            <Button type="primary" htmlType="submit" disabled={isSending} loading={isSending}>
              <IntlMessages id="components.announcementModal.send" />
            </Button>
          </Col>
        </Row>
      </Form>

    </Modal>
  )
}
