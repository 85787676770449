import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'
import { AuthStateEnum } from '@vacationtracker/shared/types/auth-state'

interface AuthState {
  authState: AuthStateEnum
}

const initialState: AuthState = {
  authState: AuthStateEnum.signedOut,
}

export const authStateSlice = createSlice({
  name: 'authState',
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthStateEnum>) => {
      state.authState = action.payload
    },
  },
})

export const { setAuthState } = authStateSlice.actions

export const selectAuthStateSlice = (state: RootState) => state.authStateSlice

export default authStateSlice.reducer
