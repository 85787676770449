import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from './configure'

interface AuthToken {
  authToken?: string
}

const initialState: AuthToken = {
}

export const authTokenSlice = createSlice({
  name: 'authToken',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string | undefined>) => {
      state.authToken = action.payload
    },
  },
})

export const { setAuthToken } = authTokenSlice.actions

export const selectAuthTokenSlice = (state: RootState) => state.authTokenSlice

export default authTokenSlice.reducer
