import { ShortestLeaveIntervalEnum } from '../types/leave-policy'


export function getShortestLeaveInterval(shortestLeaveInterval?: ShortestLeaveIntervalEnum, allowHalfDays = false, hourlyLeaveAccounting = false): ShortestLeaveIntervalEnum {
  if (shortestLeaveInterval) {
    return shortestLeaveInterval
  } else if (allowHalfDays && hourlyLeaveAccounting) {
    return ShortestLeaveIntervalEnum.oneHour
  } else if (allowHalfDays) {
    return ShortestLeaveIntervalEnum.halfDay
  } else {
    return ShortestLeaveIntervalEnum.fullDay
  }
}
