import { IUserQuota } from '../../../frontend/src/types/custom-queries'

const getvalidityStartForQuota = (selectedQuotaPeriod: IUserQuota, currentPeriod: IUserQuota): string => {
  if (selectedQuotaPeriod.yearEnd === currentPeriod.yearEnd) {
    return new Date().toISOString()
  } else {
    return selectedQuotaPeriod.yearEnd
  }
}

export default getvalidityStartForQuota
