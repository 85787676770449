
export const fields = [
  {
    // Visible in table header and when matching columns.
    label: 'Employee ID',
    // This is the key used for this field when we call onSubmit.
    key: 'employeeId',
    // Allows for better automatic column matching. Optional.
    alternateMatches: ['employee ID', 'employee Id', 'id'],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    description: 'This field is optional. Provide employee IDs if you configured them in Vacation Tracker. We\'ll match users based on the employee ID when provided.',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Name',
    // This is the key used for this field when we call onSubmit.
    key: 'fullName',
    // Allows for better automatic column matching. Optional.
    alternateMatches: ['full name', 'name'],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
  },
  {
    // Visible in table header and when matching columns.
    label: 'Email',
    // This is the key used for this field when we call onSubmit.
    key: 'email',
    alternateMatches: ['employee email', 'user email'],
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'example@email.com',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Email is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
    description: 'This field is mandatory. We\'ll match users based on their email addresses.',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Leave Type',
    // This is the key used for this field when we call onSubmit.
    key: 'leaveType',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'Paid Time Off',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Leave Type is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
    description: 'This field is mandatory, and it should be one of the configured leave types in Vacation Tracker.',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Date From',
    // This is the key used for this field when we call onSubmit.
    key: 'dateFrom',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: '10/12/2021, 10/12/21, 10-12-2021, 10-12-21, 10.12.2021, 10.12.21',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Date From is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
    description: 'This field is mandatory. The date format should be YYYY-MM-DD, i.e., 2023-10-15 for October 15th, 2023.',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Date To',
    // This is the key used for this field when we call onSubmit.
    key: 'dateTo',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: '10/12/2021, 10/12/21, 10-12-2021, 10-12-21, 10.12.2021, 10.12.21',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Date To is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
    description: 'This field is mandatory. The date format should be YYYY-MM-DD, i.e., 2023-10-15 for October 15th, 2023.',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Is Half Day / Hourly',
    // Allows for better automatic column matching. Optional.
    alternateMatches: ['Is Half Day / Hourly', 'Is Half Day', 'Is Hourly'],

    key: 'isHalfDay',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'checkbox',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'yes/no',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Is Half Day field is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
    description: 'This field is mandatory. If you want to import half day or hourly leave, you should provide "yes" value in this column.',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Hour From',
    // Allows for better automatic column matching. Optional.
    alternateMatches: ['hour from', 'Hour From', 'Hour From (only hour-no minutes)'],
    // This is the key used for this field when we call onSubmit.
    key: 'hourFrom',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: '10',
    // Can have multiple validations that are visible in Validation Step table.
    description: 'For half days and hourly leaves, the "Hour From" value must be a number between 0 and 23, i.e., "8" for 8 AM',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Hour To',
    // Allows for better automatic column matching. Optional.
    alternateMatches: ['hour to', 'Hour To', 'Hour To (only hour-no minutes)'],
    // This is the key used for this field when we call onSubmit.
    key: 'hourTo',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: '12',
    // Can have multiple validations that are visible in Validation Step table.
    description: 'For half days and hourly leaves, the "Hour To" value must be a number between 0 and 23, i.e., "12" for noon',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Reason',
    // This is the key used for this field when we call onSubmit.
    key: 'reason',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'Taking a break',
    // Can have multiple validations that are visible in Validation Step table.
    description: 'This field is optional. If you want to provide a reason for the leave, you can do it here.',
  },
  {
    // Visible in table header and when matching columns.
    label: 'Approver Name',
    // This is the key used for this field when we call onSubmit.
    key: 'approverName',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'John Doe',
    // Can have multiple validations that are visible in Validation Step table.
  },
  {
    // Visible in table header and when matching columns.
    label: 'Approver Email',
    // Allows for better automatic column matching. Optional.
    alternateMatches: ['Approver Email Address'],
    // This is the key used for this field when we call onSubmit.
    key: 'approverEmail',
    // Used when editing and validating information.
    fieldType: {
      // There are 3 types - "input" / "checkbox" / "select".
      type: 'input',
    },
    // Used in the first step to provide an example of what data is expected in this field. Optional.
    example: 'john@example.com',
    // Can have multiple validations that are visible in Validation Step table.
    validations: [
      {
        // Can be "required" / "unique" / "regex"
        rule: 'required',
        errorMessage: 'Approver Email is required',
        // There can be "info" / "warning" / "error" levels. Optional. Default "error".
        level: 'error',
      },
    ],
    description: 'This field is mandatory. The approver will be assigned based on the email value in this field. Only approvers configured in Vacation Tracker are allowed.',
  },
] as const