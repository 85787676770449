import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { useHistory } from 'react-router'
import { Form, Select } from 'antd'

import { ImportHolidaysNavigation } from '../components'

import IntlMessages from '../../../../../util/IntlMessages'
import { filterOptions } from '../../../../../util/filter-options'
import countries, { ICountry, IState } from '@vacationtracker/shared/data/countries'

import { IImportHolidaysStepOneFormProps, ImportHolidaysStepEnum } from '../types'

const { Option } = Select

const SelectCountryForm = ({
  setSteps,
  setYear,
  setCountry,
  setState,
  locationId,
  defaultYear,
}: IImportHolidaysStepOneFormProps): React.ReactElement => {
  
  const [form] = Form.useForm()
  const history = useHistory()
  const { formatMessage } = useIntl()
  const [year, setSelectedYear] = useState(defaultYear)
  const [country, setSelectedCountry] = useState<ICountry>()

  const currentYear = new Date().getFullYear()
  const years = [currentYear - 1, currentYear, currentYear + 1]

  const onYearChange = (year: number) => {
    setYear(year)
    setSelectedYear(year)
  }

  const onCountryChange = (countryIsoCode: string) => {
    const country = countries.find(country => country.iso === countryIsoCode) as ICountry
    setCountry(country)
    setSelectedCountry(country)
  }

  const onStateChange = (stateIsoCode: string) => {
    const state = country?.states?.find(state => state.iso === stateIsoCode) as IState
    setState(state)
  }

  const backToImportPage = () => {
    history.push(`/app/settings/locations/${locationId}/holidays`)
  }

  return <Form
    key="selectCountryForm"
    form={form}
    name="selectCountryForm"
    initialValues={{}}
    colon={false}
    labelCol={{ span: 4 }}
    wrapperCol={{ span: 14 }}
    layout="horizontal"
    style={{ maxWidth: 600 }}
  >
    <Form.Item
      className='wrap-label'
      name="year"
      label={<><IntlMessages id="components.importHolidaysForm.selectYear"/></>}
    > 
      <Select defaultValue={year} onChange={onYearChange}>
        {Object.values(years).map((year) => (<Option value={year} key={year}>{year}</Option>))}
      </Select>
    </Form.Item>
    <Form.Item
      className='wrap-label'
      name="country"
      label={<><IntlMessages id="app.country"/></>}
    > 
      <Select 
        onChange={onCountryChange} 
        allowClear
        filterOption={filterOptions}
        showSearch
      >
        {Object.values(countries).sort((a, b) => a.name.localeCompare(b.name)).map((country) => (
          <Option value={country.iso} key={country.iso}>{country.name}</Option>
        ))}
      </Select>
    </Form.Item>
    {country?.states?.length && country?.states?.length > 0 && 
      <Form.Item
        className='wrap-label'
        name="state"
        label={<><IntlMessages id="app.state"/></>}
      > 
        <Select 
          onChange={onStateChange}
          allowClear
          filterOption={filterOptions}
          showSearch
        >
          {Object.values(country?.states).map((state) => (
            <Option value={state.iso} key={state.iso}>{state.name}</Option>
          ))}
        </Select>
      </Form.Item>
    }
    <ImportHolidaysNavigation
      stepComplete={Boolean(country && year)}
      handleNext={() => setSteps(ImportHolidaysStepEnum.stepTwo, 1)}
      handleBack={backToImportPage}
      nextButtonTitle={formatMessage({ id: 'app.next' })}
    />
  </Form>
}

export default SelectCountryForm