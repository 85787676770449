/* eslint-disable @typescript-eslint/restrict-plus-operands */
import React, { createContext, useEffect, useState, useContext, ReactNode } from 'react'
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride'
import { useIntl } from 'react-intl'
import { adminStepsMicrosoft, adminStepsSlackAndGoogle } from './admin-steps'
import { approverSteps } from './approver-steps'
import { userSteps } from './user-steps'
import { ThemeContext } from '../themeContext'
import { DARK_THEME } from '@vacationtracker/shared/constants/ThemeSetting'
import { IUserInfo } from '../../types/users'
import { RouteComponentProps } from 'react-router-dom'

type InitialContext = {
  run: boolean
  restart: boolean
  role: string
}

type IOnboardingContext = [InitialContext, (v: InitialContext) => void]
interface IOnboardingProviderProps {
  children: ReactNode | ReactNode[]
  history: RouteComponentProps['history']
  user: IUserInfo | undefined
}

const initialContext = {
  run: false,
  restart: false,
  role: 'user',
}

const context: IOnboardingContext = [
  initialContext,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
]

const calculateStyles = theme => {
  return theme === DARK_THEME
    ? {
      options: {
        arrowColor: '#E0E0E0',
        backgroundColor: ' #38424B',
        beaconSize: 36,
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: '#b266ff',
        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
        textColor: '#E0E0E0',
        zIndex: 1100,
      },
      tooltip: {
        fontSize: 14,
      },
    }
    : {
      options: {
        arrowColor: '#fff',
        backgroundColor: '#fff',
        beaconSize: 36,
        overlayColor: 'rgba(0, 0, 0, 0.5)',
        primaryColor: '#7f00ff',
        spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
        textColor: '#333',
        zIndex: 1100,
      },
      tooltip: {
        fontSize: 14,
      },
    }
}

export const OnboardingContext = createContext(context)

export const OnboardingProvider = ({ children, history, user }: IOnboardingProviderProps) => {
  const { theme } = useContext(ThemeContext)
  const initialConfig = {
    continuous: true,
    stepIndex: 0,
    disableScrollParentFix: true,
    styles: calculateStyles(theme),
    showSkipButton: true,
    showProgress: false,
  }
  const [onboardingConfig, setOnboardingConfig] = useState(initialConfig)
  const [stepIndex, setStepIndex] = useState(0)
  const [runOnboarding, setRunOnboarding] = useState(initialContext)
  const [spotlightClicks] = useState(true)
  const { formatMessage } = useIntl()

  useEffect(() => {
    if (runOnboarding.restart && runOnboarding.run) {
      setStepIndex(0)
    }
    setRunOnboarding({
      ...runOnboarding,
      restart: false,
    })
  }, [runOnboarding.restart])


  useEffect(() => {
    setOnboardingConfig(prevConfig => ({
      ...prevConfig,
      styles: calculateStyles(theme),
    })
    )
  }, [theme])

  const onboardingControl = (data) => {
    const searchQuery = history.location.search.replace('tour=true', '')
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(data.status)) {
      if (history.location.search.includes('tour=true')) {
        window.history.pushState({}, '', `${history.location.pathname}${searchQuery}`)
      }
      setRunOnboarding({
        run: false,
        restart: true,
        role: runOnboarding.role,
      })
      setStepIndex(0)
    } else if ([EVENTS.TARGET_NOT_FOUND].includes(data.type)) {
      setStepIndex(data.index + (data.action === ACTIONS.PREV ? 1 : -1))
    } else if (data.action === ACTIONS.NEXT && [EVENTS.STEP_AFTER].includes(data.type)) {
      if (data.step.nextUrl && history.location.pathname !== data.step.nextUrl) {
        history.push(data.step.nextUrl)
        const nextSlideIndex = data.index + 1
        setTimeout(() => setStepIndex(nextSlideIndex), 2000)
      } else {
        setStepIndex(data.index + 1)
      }
    } else if (data.action === ACTIONS.PREV && [EVENTS.STEP_AFTER].includes(data.type)) {
      if (data.step.previousUrl && history.location.pathname !== data.step.previousUrl) {
        history.push(data.step.previousUrl)
        const previousSlideIndex = data.index - 1
        setTimeout(() => setStepIndex(previousSlideIndex), 2000)
      } else {
        setStepIndex(data.index - 1)
      }
    } else if (data.action === ACTIONS.CLOSE && data.step.placement === 'center') {
      if (history.location.search.includes('tour=true')) {
        window.history.pushState({}, '', `${history.location.pathname}${searchQuery}`)
      }
      setRunOnboarding({
        run: false,
        restart: true,
        role: runOnboarding.role,
      })
    }
  }

  const locale = {
    back: formatMessage({ id: 'app.back' }),
    close: formatMessage({ id: 'app.close' }),
    last: formatMessage({ id: 'app.close' }),
    next: formatMessage({ id: 'app.next' }),
    skip: formatMessage({ id: 'app.skip' }),
  }

  let adminSteps
  if (user?.platform === 'microsoft') {
    adminSteps = adminStepsMicrosoft
  } else {
    adminSteps = adminStepsSlackAndGoogle
  }

  return (
    <OnboardingContext.Provider value={[runOnboarding, setRunOnboarding]}>
      <Joyride
        {...onboardingConfig}
        run={runOnboarding.run}
        stepIndex={stepIndex}
        spotlightClicks={spotlightClicks}
        callback={onboardingControl}
        locale={locale}
        steps={
          (runOnboarding.role === 'admin') ? adminSteps :
            (runOnboarding.role === 'approver') ? approverSteps : userSteps
        }
      />
      {children}
    </OnboardingContext.Provider>
  )
}