import React from 'react'

import IntlMessages from './IntlMessages'

export const WorkWeek = [
  { label: <IntlMessages id="app.sunday" />, value: 0 },
  { label: <IntlMessages id="app.monday" />, value: 1 },
  { label: <IntlMessages id="app.tuesday" />, value: 2 },
  { label: <IntlMessages id="app.wednesday" />, value: 3 },
  { label: <IntlMessages id="app.thursday" />, value: 4 },
  { label: <IntlMessages id="app.friday" />, value: 5 },
  { label: <IntlMessages id="app.saturday" />, value: 6 },
]