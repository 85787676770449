/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable camelcase */
/* responsible for OpenID protocol implementation */
import qs from 'qs'
import { Observable } from './observable'
import { safeJSONParse } from './safe-json-parse'

const AUTH_SESSION_KEY = 'vacationtracker:auth'

interface ITokens {
  idToken: string
  accessToken: string
  refreshToken?: string
}

export function OpenIdClient(this: any, {runtime, siteConfig, pkceChallengeGenerator}) {
  if (!runtime || !pkceChallengeGenerator || !siteConfig || !siteConfig.OpenIDUrl || !siteConfig.OpenIDTargetUrl || !siteConfig.OpenIDTargetUrl) {
    throw new Error('invalid-args')
  }
  const error = new Observable()
  const knownMethods = ['login', 'signup', 'session']
  const verifierSessionKey = AUTH_SESSION_KEY+':verifier'
  const stateSessionKey = AUTH_SESSION_KEY+':state'
  const extractTokensFromGrantResult = (resultText: string) => {
    const result = safeJSONParse(resultText)
    if (result && result.error === 'invalid_grant') {
      throw 'session-expired'
    }
    if (result && result.error === 'access_denied' && result.error_subcode === 'cancel') {
      throw 'access-denied-cancel'
    }
    if (result && result.id_token) {
      const tokens: ITokens = {
        idToken: result.id_token,
        accessToken: result.access_token,
      }
      if (result.refresh_token) {
        tokens.refreshToken = result.refresh_token
      }
      return tokens
    }
    error.notify('invalid-response', {action: 'OpenIdClient.extractTokensFromGrantResult', target: resultText })
    throw resultText
  }
  const refreshTokens = async (refreshToken) => {
    const params = {
      'grant_type': 'refresh_token',
      'refresh_token': refreshToken,
      'client_id': siteConfig.OpenIDClient,
    }
    const resultText = await runtime.postUrlEncoded(`${siteConfig.OpenIDUrl}/token`, params)
    return Object.assign({refreshToken}, extractTokensFromGrantResult(resultText))
  }
  const completePKCEFlow = async (response, state, codeVerfier) => {
    if (response && response.error === 'access_denied' && response.error_subcode === 'cancel') {
      throw 'access-denied-cancel'
    }
    if (!response || response.error || !response.code || response.state !== state) {
      error.notify(response, {action: 'OpenIdClient.authorise'})
      throw response
    }
    const params = {
      'grant_type': 'authorization_code',
      'code': response.code,
      'client_id': siteConfig.OpenIDClient,
      'redirect_uri': siteConfig.OpenIDTargetUrl,
      'code_verifier': codeVerfier,
    }
    const resultText = await runtime.postUrlEncoded(`${siteConfig.OpenIDUrl}/token`, params)
    return extractTokensFromGrantResult(resultText)
  }
  const createPKCEURL = (method, codeChallenge, state, scopes = [], additionalParams = {}) => {
    const queryString = qs.stringify({
      code_challenge_method: 'S256',
      code_challenge: codeChallenge,
      response_type: 'code',
      scope: [...scopes, 'openid', 'profile'].join(' '),
      client_id: siteConfig.OpenIDClient,
      redirect_uri: siteConfig.OpenIDTargetUrl,
      state,
      ...additionalParams,
    })
    return `${siteConfig.OpenIDUrl}/${method}?${queryString}`
  }
  const startAuthSession = (method) => {
    if (!knownMethods.includes(method) || method === 'session') {
      throw new Error('invalid-args')
    }
    const challenge = pkceChallengeGenerator.createSync()
    const state = `r:${runtime.uniqueString(10)}`
    const url = createPKCEURL(method, challenge.code, state)
    runtime.storeSessionProperty(verifierSessionKey, challenge.verifier)
    runtime.storeSessionProperty(stateSessionKey, state)
    return runtime.redirect(url)
  }
  const completeAuthSession = async () => {
    const response = runtime.queryStringParameters(),
      verifier = runtime.readSessionProperty(verifierSessionKey),
      state = runtime.readSessionProperty(stateSessionKey),
      result = await completePKCEFlow(response, state, verifier)
    runtime.removeSessionProperty(verifierSessionKey)
    runtime.removeSessionProperty(stateSessionKey)
    return result
  }
  const executePKCEFLow = (method, scopes, additionalParams = {}) => {
    const challenge = pkceChallengeGenerator.createSync()
    const state = `m:${runtime.uniqueString(10)}`
    const url = createPKCEURL(method, challenge.code, state, scopes, additionalParams)
    return runtime.openAndWaitForMessage(url, 'openid')
      .then(response => {
        return qs.parse(response.slice(1))
      })
      .then(response => completePKCEFlow(response, state, challenge.verifier))
      .catch(e => {
        if (e === 'session-expired' || e === 'access-denied-cancel' || e === 'server-error' || e === 'not-ready') {
          throw e
        }
        if (e !== 'server-error') {
          error.notify(e, {action: 'OpenIdClient.authorise', target: url})
        }
        throw e
      })
  }
  const authorise = (method, scopes, additionalParams = {}) => {
    if (method === 'session') {
      return completeAuthSession()
    } else {
      return executePKCEFLow(method, scopes, additionalParams)
    }
  }
  Object.freeze(Object.assign(this, {refreshTokens, authorise, error, startAuthSession}))
}
