import React, { useState, useEffect } from 'react'
import { Select, Typography, Form, TreeSelect, Tree } from 'antd'
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { cloneDeep, difference, isEmpty, uniqBy } from 'lodash'

import IntlMessages from '../utils/IntlMessages'

const { Option } = Select
const { Text } = Typography

import { IFilter } from '../../types/filter'
import { IFilterAdvanced, ISelected, IFilterSelect } from './types'
import { useIntl } from 'react-intl'

const FilterPagesMap = {
  dashboard: 'dashboardFilters',
  wallchart: 'wallchartFilters',
  users: 'usersFilters',
  calendar: 'calendarFilters',
  msTeamsDashboard: 'msTeamsDashboardFilters',
  msTeamsCalendar: 'msTeamsCalendarFilters',
  notificationForm: 'notificationFormFilters',
  calendarSync: 'calendarSyncFilters',
}

const Filter = ({
  availableFilters,
  showAddButton,
  data,
  selected,
  onSelect,
  onAdd,
  onRemove,
  lockTypeSelector,
  isForm = false,
  filterByWidth,
}: IFilterSelect) : React.ReactElement => {

  const { formatMessage } = useIntl()

  const onSelectValues = (value) => {
    if (value) {
      onSelect(selected.type, value)
    } else {
      onSelect(selected.type)
    }
  }

  const getSelectionValue = (type: string) => {
    return type ? formatMessage({id: `app.${type.toLowerCase()}`}) : ''
  }

  return (
    <div id='parent-select' style={{paddingBottom: 3, position: 'relative'}}>
      <Select
        style={
          isForm
            ? {width: filterByWidth}
            : {width: filterByWidth, marginLeft: 5}
        }
        placeholder={<IntlMessages id='app.select' />}
        onSelect={(type) => onSelect(type)}
        onChange={(value) => onSelectValues(value)}
        value={getSelectionValue(selected.type)}
        disabled={lockTypeSelector}
        notFoundContent=''
        getPopupContainer={() =>
          document.getElementById('parent-select') as HTMLElement
        }
      >
        {availableFilters.map((item) => (
          <Option key={item} value={item}>
            <IntlMessages id={`app.${item.toLowerCase()}`} />
          </Option>
        ))}
      </Select>
      {selected.type !== 'Departments' ? (
        <Select
          disabled={!selected.type}
          mode='multiple'
          onChange={onSelectValues}
          value={selected.values}
          style={
            isForm
              ? {width: `calc(100% - ${filterByWidth + 50}px)`, marginLeft: 5}
              : {width: 195, marginLeft: 5}
          }
          optionFilterProp='label'
          getPopupContainer={() =>
            document.getElementById('parent-select') as HTMLElement
          }
        >
          {selected.type &&
            data[selected.type] &&
            data[selected.type]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map((item) => (
                <Option key={item.id} label={item.name} value={item.id}>
                  {item.name}
                </Option>
              ))}
        </Select>
      ) : (
        <TreeSelect
          disabled={!selected.type}
          value={selected.values}
          style={
            isForm
              ? {width: `calc(100% - ${filterByWidth + 50}px)`, marginLeft: 5}
              : {width: 195, marginLeft: 5}
          }
          onChange={onSelectValues}
          multiple
          treeNodeFilterProp='name'
          getPopupContainer={() =>
            document.getElementById('parent-select') as HTMLElement
          }
          showCheckedStrategy={TreeSelect.SHOW_ALL}
          treeData={data['Departments']}
          treeDataSimpleMode={{id: 'id', pId: 'parentId'}}
          fieldNames={{label: 'name', value: 'id'}}
        />
      )}
      {selected.type && (
        <MinusCircleOutlined
          onClick={onRemove}
          style={{color: 'red', marginLeft: 5}}
        />
      )}
      {showAddButton &&
        selected.type &&
        selected.values &&
        selected.values.length > 0 && (
        <PlusCircleOutlined
          onClick={onAdd}
          style={{color: 'green', marginLeft: 5}}
        />
      )}
    </div>
  )
}

const FilterAdvanced = ({
  data,
  onChangeFilter,
  page,
  showLabels = false,
  isForm = false,
  toolTipInfo = '',
  initialValues = [{}],
  saveFilters = true,
  filterByWidth = 125,
  shouldReset,
  currentUserId,
}: IFilterAdvanced): React.ReactElement => {
  const [ selectedTypes, setSelectedTypes ] = useState<string[]>([])
  const [ selected, setSelected ] = useState<ISelected[] | {}[]>(initialValues)
  const [ availableFilters, setAvailableFilters ] = useState(Object.keys(data))

  useEffect(() => {
    if (page && saveFilters && localStorage.getItem(`${currentUserId ? `${currentUserId}#` : ''}${FilterPagesMap[page] as string}`) && isEmpty(initialValues[0])) {
      const newSelected: ISelected[] | {}[] = JSON.parse(localStorage.getItem(`${currentUserId ? `${currentUserId}#` : ''}${FilterPagesMap[page] as string}`) || '')
      setSelected(newSelected)
      setSelectedTypes(newSelected.map(f => f.type))
    } else {
      setSelected(initialValues)
      if (page && saveFilters) {
        localStorage.setItem(`${currentUserId ? `${currentUserId}#` : ''}${FilterPagesMap[page] as string}`, JSON.stringify(initialValues))
      }
      setSelectedTypes(() => {
        if(initialValues && !isEmpty(initialValues[0])) {
          return initialValues.map(f => f.type)
        } else {
          return []
        }
      })
    }
  }, [])

  useEffect(() => {
    if (shouldReset) {
      setSelected(initialValues)
      setSelectedTypes(() => {
        if(initialValues && !isEmpty(initialValues[0])) {
          return initialValues.map(f => f.type)
        } else {
          return []
        }
      })
      if (page && saveFilters) {
        localStorage.setItem(`${currentUserId ? `${currentUserId}#` : ''}${FilterPagesMap[page] as string}`, JSON.stringify([{}]))
      }
    }
  }, [shouldReset])

  useEffect(() => {
    setAvailableFilters(() => {
      if(showLabels) {
        return Object.keys(data)
      }
      delete data.Labels
      return Object.keys(data)
    })
  }, [data])

  const onSelect = (type: string, values) => {
    if (selectedTypes.includes(type)) {
      if(availableFilters.includes(values)) {
        setSelected(prevState => {
          const newStateSelected = prevState.filter(state => state && (state as ISelected).type !== type)
          return [...newStateSelected, { type: values, values: {} }]
        })
        setSelectedTypes(prevState => {
          const newStateSelectedTypes = prevState.filter(state => state !== type)
          return [...newStateSelectedTypes, values]
        })
      } else {
        const newSelected = selected
          .map(item => (item.type === type) ? { type, values } : item)
          .filter(f => !isEmpty(f))
        setSelected(newSelected)
        if (page && saveFilters) {
          localStorage.setItem(`${currentUserId ? `${currentUserId}#` : ''}${FilterPagesMap[page]}`, JSON.stringify(newSelected))
        }
      }
    } else if (type) {
      setSelected((prevState) => {
        const newSelected = uniqBy([...prevState.filter(f => !isEmpty(f)), { type, values }], 'type')
          .map(item => {
            if (isEmpty((item as ISelected).values)) {
              return {
                type: (item as ISelected).type,
                values: undefined,
              }
            }
            return item
          })

        if (page && saveFilters) {
          localStorage.setItem(`${currentUserId ? `${currentUserId}#` : ''}${FilterPagesMap[page]}`, JSON.stringify(newSelected))
        }
        return newSelected
      })
      setSelectedTypes((prevState) => {
        return [...new Set([...prevState, type])]
      })
    }
  }

  useEffect(() => {
    const filter: IFilter = {
      locationIds: [],
      teamIds: [],
      labelIds: [],
    }

    selected.map(item => {
      if (item?.type === 'Locations') {
        filter.locationIds = item.values || []
      }
      if (item?.type === 'Departments') {
        filter.teamIds = item.values || []
      }
      if (item?.type === 'Labels') {
        filter.labelIds = item.values || []
      }
    })
    onChangeFilter(filter)
  }, [selected])

  const onAdd = () => {
    const newSelected = [
      ...selected,
      {},
    ]
    setSelected(newSelected)
    if (page && saveFilters) {
      localStorage.setItem(`${currentUserId ? `${currentUserId}#` : ''}${FilterPagesMap[page] as string}`, JSON.stringify(newSelected))
    }
  }

  const onRemove = (index: number) => {
    const newSelected = cloneDeep(selected)
    if(!isEmpty(newSelected)) {
      const removed = newSelected.splice(index, 1) as ISelected[]
      setSelected(newSelected.length ? newSelected : [{}])
      setSelectedTypes(selectedTypes.filter(type => type !== removed[0].type))
      if (page && saveFilters) {
        localStorage.setItem(`${currentUserId ? `${currentUserId}#` : ''}${FilterPagesMap[page] as string}`, JSON.stringify(newSelected.length ? newSelected : [{}]))
      }
    }
  }

  return (
    <div className="vt-filters-wrapper"> 
      {/* This condition is for displaying real data instead of IDs (for slow internet) */}
      {data.Locations && data.Locations.length > 0 &&
        <>
          {isForm ?
            <Form.Item
              label={<IntlMessages id="components.filterSimple.filterBy" />}
              // name="filter"
              tooltip={toolTipInfo && <IntlMessages id={toolTipInfo} />}
            >
              {selected.map((filter, i: number) => (
                <Filter
                  isForm={true}
                  availableFilters={difference(availableFilters, selectedTypes)}
                  showAddButton={
                    i !== (availableFilters.length - 1) &&
                    i === (selected.length - 1)
                  }
                  lockTypeSelector={
                    i < (selected.length - 1)
                  }
                  data={data}
                  selected={selected[i] as ISelected}
                  onSelect={onSelect}
                  onAdd={onAdd}
                  onRemove={() => onRemove(i)}
                  key={i}
                  filterByWidth={filterByWidth}
                />
              ))}
            </Form.Item> :
            <div style={{ display: 'flex' }}>
              <Text style={{ paddingTop: 5 }}>
                <IntlMessages id="components.filterSimple.filterBy" />
              </Text>
              <div>
                {selected.map((filter, i: number) => (
                  <Filter
                    availableFilters={difference(availableFilters, selectedTypes)}
                    showAddButton={
                      i !== (availableFilters.length - 1) &&
                      i === (selected.length - 1)
                    }
                    lockTypeSelector={
                      i < (selected.length - 1)
                    }
                    data={data}
                    selected={selected[i] as ISelected}
                    onSelect={onSelect}
                    onAdd={onAdd}
                    onRemove={() => onRemove(i)}
                    key={i}
                    filterByWidth={filterByWidth}
                  />
                ))}
              </div>
            </div>
          }
        </>
      }
    </div>
  )
}

export default FilterAdvanced