import React from 'react'
import { useIntl } from 'react-intl'
import { App, Alert, Button } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import IntlMessages from '../../util/IntlMessages'

import { SubscriptionPeriod } from '@vacationtracker/shared/types/billing'
import { SubscriptionPlan } from '@vacationtracker/shared/types/company'

interface IDowngradeAlert {
  currentPlan: SubscriptionPlan
  currentPeriod: SubscriptionPeriod
  nextPlan: SubscriptionPlan
  nextPeriod: SubscriptionPeriod
  endDatePeriod: String
  cancelChangePlan: () => void
}

export const DowngradeAlert = ({currentPlan, currentPeriod, nextPlan, nextPeriod, endDatePeriod, cancelChangePlan}: IDowngradeAlert): React.ReactElement => {
  const { formatMessage } = useIntl()
  const { modal } = App.useApp()

  if (currentPlan === nextPlan && currentPeriod === nextPeriod) {
    return <></>
  }

  let translateId = ''

  if (currentPlan === 'Complete' && currentPeriod === 'monthly' && nextPlan === 'Core' && nextPeriod === 'monthly') {
    translateId = 'components.switchPlanModal.switchFromCompleteMonthlyToCoreMonthly'
  }

  if (currentPlan === 'Complete' && currentPeriod === 'monthly' && nextPlan === 'Core' && nextPeriod === 'annual') {
    translateId = 'components.switchPlanModal.switchFromCompleteMonthlyToCoreAnnual'
  }

  if (currentPlan === nextPlan && currentPeriod === 'annual' && nextPeriod === 'monthly') {
    translateId = 'components.switchPlanModal.switchFromAnnuallyToMonthly'
  }

  if (currentPlan === 'Complete' && currentPeriod === 'annual' && nextPlan === 'Core' && nextPeriod === 'monthly') {
    translateId = 'components.switchPlanModal.switchFromCompleteAnnualToCoreMonthly'
  }

  if (currentPlan === 'Complete' && currentPeriod === 'annual' && nextPlan === 'Core' && nextPeriod === 'annual') {
    translateId = 'components.switchPlanModal.switchFromCompleteAnnualToCoreAnnual'
  }

  if (translateId === '') {
    return <></>
  }

  const showCancelNewPlan = () => {
    modal.confirm({
      title: formatMessage(
        { id: 'components.downgradeAlert.cancelSwitchPlan' },
        { nextPlan, nextPeriod, strong: (...chunks) => <strong>{chunks}</strong> }
      ),
      icon: <ExclamationCircleOutlined />,
      content: '',
      width: 560,
      okType: 'primary',
      okText: formatMessage(
        { id: 'components.downgradeAlert.yesIWantToStay' },
        { currentPlan, currentPeriod, strong: (...chunks) => <strong style={{ marginLeft: 4, marginRight: 4 }}>{chunks}</strong> }
      ),
      okButtonProps: {
        block: true,
        style: { marginLeft: 0, marginTop: 10 },
      },
      cancelButtonProps: {
        block: true,
      },
      cancelText: formatMessage(
        { id: 'components.downgradeAlert.noIWantToSwitch' },
        { nextPlan, nextPeriod, strong: (...chunks) => <strong style={{ marginLeft: 4, marginRight: 4 }}>{chunks}</strong> }
      ),
      onOk() {
        cancelChangePlan()
      },
    })
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Alert
        message={
          <>
            <IntlMessages id={translateId} values={{
              newPlan: nextPlan,
              endDatePeriod,
              legacyPlan: () => '',
              strong: (...chunks) => <strong>{chunks}</strong>,
            }} />
            <Button onClick={showCancelNewPlan} style={{ marginLeft: 5 }}>
              <IntlMessages id="components.downgradeAlert.cancelChangeToPlan" values={{
                nextPlan,
                strong: (...chunks) => <strong>{chunks}</strong>,
              }} />
            </Button>
          </>
        }
        type="warning"
        showIcon
        style={{ marginBottom: '20px' }}
      />
    </div>
  )
}

export default DowngradeAlert
