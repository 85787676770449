import { each, pick } from 'lodash'
import { preventXss } from '@vacationtracker/shared/functions/security'

export const getCookieByName = (name: string): string => {
  if (!name) {
    return ''
  }
  if (!document.cookie) {
    return ''
  }
  try {
    return document.cookie.match(`(^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || ''
  } catch (error) {
    return ''
  }
}

export const parseAndPrepareUtmCookie = (cookie: string): {[key: string]: string} => {
  if (!cookie) {
    return {}
  }
  const decoded = Buffer.from(cookie, 'base64')
  const decodedString = decoded.toString()
  const parsed = JSON.parse(decodedString)
  return prepareUtmTags(parsed)
}

export const prepareUtmTags = (tags: {[key: string]: string}): {[key: string]: string} => {
  const utmTags = {}
  each(pick(tags, ['utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'ref', 'gclid']), (value, key) => {
    utmTags[`signup_${key}`] = preventXss(value)
  })
  return utmTags
}