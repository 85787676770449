import { ISyncedCalendar } from '@vacationtracker/shared/types/calendar'
import { IFilter } from '@vacationtracker/shared/types/filter'
import { ISelected } from '@vacationtracker/shared/components/filter-advanced/types'
import { CalendarSyncActionsEnum, GetCalendarsSuccessPayload, IOutlookCalendarOwnerAndUserWhoConnectedCalendar } from './types'


export const actions = {
  getCalendarsStart: () => ({
    type: CalendarSyncActionsEnum.getCalendarsStart,
  }),
  getCalendarsSuccess: (payload: GetCalendarsSuccessPayload) => ({
    type: CalendarSyncActionsEnum.getCalendarsSuccess,
    payload,
  }),
  setCalendar: (payload: ISyncedCalendar | undefined) => ({
    type: CalendarSyncActionsEnum.setCalendar,
    payload,
  }),
  setFilteredCalendars: (payload: ISyncedCalendar[]) => ({
    type: CalendarSyncActionsEnum.setFilteredCalendars,
    payload,
  }),
  setCalendarToConnectType: (payload) => ({
    type: CalendarSyncActionsEnum.setCalendarToConnectType,
    payload,
  }),
  setWhatToRender: (payload: string) => ({
    type: CalendarSyncActionsEnum.setWhatToRender,
    payload,
  }),
  setFilters: (payload: IFilter) => ({
    type: CalendarSyncActionsEnum.setFilters,
    payload,
  }),
  setInitialFilters: (payload: ISelected[] | [{}]) => ({
    type: CalendarSyncActionsEnum.setInitialFilters,
    payload,
  }),
  setGoogleSyncCalendarData: (payload) => ({
    type: CalendarSyncActionsEnum.setGoogleSyncCalendarData,
    payload,
  }),
  setOutlookSyncCalendarData: (payload) => ({
    type: CalendarSyncActionsEnum.setOutlookSyncCalendarData,
    payload,
  }),
  setCalendarToConnect: (payload) => ({
    type: CalendarSyncActionsEnum.setCalendarToConnect,
    payload,
  }),
  setCalendarForAdminConsent: (payload) => ({
    type: CalendarSyncActionsEnum.setCalendarForAdminConsent,
    payload,
  }),
  setOutlookCalendarOwnerAndUserWhoConnectedCalendar: (payload: IOutlookCalendarOwnerAndUserWhoConnectedCalendar) => ({
    type: CalendarSyncActionsEnum.setOutlookCalendarOwnerAndUserWhoConnectedCalendar,
    payload,
  }),
  toggleGrantAdminConsentModal: () => ({
    type: CalendarSyncActionsEnum.toggleGrantAdminConsentModal,
  }),
  toggleMsLoginModal: (payload: boolean) => ({
    type: CalendarSyncActionsEnum.toggleMsLoginModal,
    payload,
  }),
  setIsReconnecting: (payload: boolean) => ({
    type: CalendarSyncActionsEnum.setIsReconnecting,
    payload,
  }),
}