import React from 'react'
import IntlMessages from '@vacationtracker/shared/components/utils/IntlMessages'
import { MessageDescriptor } from 'react-intl'

export const getTranslations = (formatMessage: (descriptor: MessageDescriptor) => string) => ({
  uploadStep: {
    title: <IntlMessages id="app.importLeaves" />,
    manifestTitle: <IntlMessages id="app.importLeaves.manifestTitle" />,
    manifestDescription: <IntlMessages id="app.importLeaves.manifestDescription" />,
    maxRecordsExceeded: <IntlMessages id="app.importLeaves.maxRecordsExceeded" />,
    dropzone: {
      title: <IntlMessages id="app.importLeaves.dropzone.title" />,
      // The "errorToastDescription" key must be a string, and not a React element
      errorToastDescription: formatMessage({ id: 'app.importLeaves.dropzone.errorToastDescription' }),
      activeDropzoneTitle: <IntlMessages id="app.importLeaves.dropzone.activeDropzoneTitle" />, // Assuming you have this key in your JSON
      buttonTitle: <IntlMessages id="app.importLeaves.dropzone.buttonTitle" />,
      loadingTitle: <IntlMessages id="app.importLeaves.dropzone.loadingTitle" />,
    },
    selectSheet: {
      title: <IntlMessages id="app.importLeaves.selectSheet.title" />,
      nextButtonTitle: <IntlMessages id="app.importLeaves.nextButtonTitle" />,
    },
  },
  selectHeaderStep: {
    title: <IntlMessages id="app.importLeaves.selectHeaderStep.title" />,
    nextButtonTitle: <IntlMessages id="app.importLeaves.nextButtonTitle" />,
  },
  matchColumnsStep: {
    title: <IntlMessages id="app.importLeaves.matchColumnsStep.title" />,
    nextButtonTitle: <IntlMessages id="app.importLeaves.matchColumnsStep.nextButtonTitle" />,
    userTableTitle: <IntlMessages id="app.importLeaves.matchColumnsStep.userTableTitle" />,
    templateTitle: <IntlMessages id="app.importLeaves.matchColumnsStep.templateTitle" />,
    selectPlaceholder: <IntlMessages id="app.importLeaves.matchColumnsStep.selectPlaceholder" />,
    ignoredColumnText: <IntlMessages id="app.importLeaves.matchColumnsStep.ignoredColumnText" />,
    subSelectPlaceholder: <IntlMessages id="app.importLeaves.matchColumnsStep.subSelectPlaceholder" />,
    matchDropdownTitle: <IntlMessages id="app.importLeaves.matchColumnsStep.matchDropdownTitle" />,
    unmatched: <IntlMessages id="app.importLeaves.matchColumnsStep.unmatched" />,
    duplicateColumnWarningTitle: <IntlMessages id="app.importLeaves.matchColumnsStep.duplicateColumnWarningTitle" />,
    duplicateColumnWarningDescription: <IntlMessages id="app.importLeaves.matchColumnsStep.duplicateColumnWarningDescription" />,
  },
  validationStep: {
    title: <IntlMessages id="app.importLeaves.validationStep.title" />,
    nextButtonTitle: <IntlMessages id="app.importLeaves.validationStep.nextButtonTitle" />,
    noRowsMessage: <IntlMessages id="app.importLeaves.validationStep.noRowsMessage" />,
    noRowsMessageWhenFiltered: <IntlMessages id="app.importLeaves.validationStep.noRowsMessageWhenFiltered" />,
    discardButtonTitle: <IntlMessages id="app.importLeaves.validationStep.discardButtonTitle" />,
    filterSwitchTitle: <IntlMessages id="app.importLeaves.validationStep.filterSwitchTitle" />,
  },
  alerts: {
    confirmClose: {
      headerTitle: <IntlMessages id="app.importLeaves.alerts.confirmClose.headerTitle" />,
      bodyText: <IntlMessages id="app.importLeaves.alerts.confirmClose.bodyText" />,
      cancelButtonTitle: <IntlMessages id="app.importLeaves.alerts.confirmClose.cancelButtonTitle" />,
      exitButtonTitle: <IntlMessages id="app.importLeaves.alerts.confirmClose.exitButtonTitle" />,
    },
    submitIncomplete: {
      headerTitle: <IntlMessages id="app.importLeaves.alerts.confirmClose.submitIncomplete.headerTitle" />,
      bodyText: <IntlMessages id="app.importLeaves.alerts.confirmClose.submitIncomplete.bodyText" />,
      bodyTextSubmitForbidden: <IntlMessages id="app.importLeaves.alerts.confirmClose.submitIncomplete.bodyTextSubmitForbidden" />,
      cancelButtonTitle: <IntlMessages id="app.importLeaves.alerts.confirmClose.submitIncomplete.cancelButtonTitle" />,
      finishButtonTitle: <IntlMessages id="app.importLeaves.alerts.confirmClose.submitIncomplete.finishButtonTitle" />,
    },
    unmatchedRequiredFields: {
      headerTitle: <IntlMessages id="app.importLeaves.alerts.unmatchedRequiredFields.headerTitle" />,
      bodyText: <IntlMessages id="app.importLeaves.alerts.unmatchedRequiredFields.bodyText" />,
      listTitle: <IntlMessages id="app.importLeaves.alerts.unmatchedRequiredFields.listTitle" />,
      cancelButtonTitle: <IntlMessages id="app.importLeaves.alerts.unmatchedRequiredFields.cancelButtonTitle" />,
      continueButtonTitle: <IntlMessages id="app.importLeaves.alerts.unmatchedRequiredFields.continueButtonTitle" />,
    },
    toast: {
      error: <IntlMessages id="app.importLeaves.alerts.toast" />,
    },
  },
})
