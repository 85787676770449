import React, { useEffect, useState } from 'react'
import { App, Button, List, Input, Empty, Tag, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { format } from 'date-fns'
import { useManualQuery } from 'graphql-hooks'
import { useIntl } from 'react-intl'
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react'

import Api from '@vacationtracker/shared/services/api'
import * as logger from '../../services/logger'
import { getAiAssistantConversationInfoData } from '../../graphql/custom-queries'
import { useAppSelector } from '../../store/hooks'
import { selectAuthUserSlice } from '../../store/auth-user-slice'
import { selectAuthCompanySlice } from '../../store/auth-company-slice'
import { track } from '../../services/analytics/analytics'
import { openSupportChat } from '../../util/open-support-chat'

import { IConversation } from '@vacationtracker/shared/types/conversation'
import { FrontendUrls } from '../../types/urls'
import { FeatureFlagEnum } from '@vacationtracker/shared/types/feature-flags'
import IntlMessages from '../../util/IntlMessages'

type IConverssationInfo = Pick<IConversation, 'timestamp' | 'topic' | 'id' | 'isDone' | 'updatedAt'>
type IConversationResponse = { getAiAssistedConversationInfo: { topic: string; timestamp: string; id: string }[] }

const AiAssistedOnboarding = ({ history }) => {
  const { notification } = App.useApp()
  const { formatMessage } = useIntl()
  const { authUser } = useAppSelector(selectAuthUserSlice)
  const { authCompany } = useAppSelector(selectAuthCompanySlice)
  const posthog = usePostHog()
  const [ topics, setTopics ] = useState<IConverssationInfo[] | null>(null)
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(true)
  const [ getAiAssistantConversationInfoDataQuery ] = useManualQuery<IConversationResponse, { companyId: string }>(getAiAssistantConversationInfoData)
  const aiAssistedOnboardingEnabled = useFeatureFlagEnabled(FeatureFlagEnum.aiAssistedOnboarding)

  useEffect(() => {
    // Start screen recording in production
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      posthog.startSessionRecording()
    }
  }, [])

  useEffect(() => {
    if (typeof aiAssistedOnboardingEnabled === 'undefined') {
      return
    }
    if (authUser?.initialUser && aiAssistedOnboardingEnabled) {
      track('AI_ASSISTANT_ALL_CONVERSATIONS_PAGE_VIEW', {
        initialUser: authUser?.initialUser,
        aiAssistedOnboardingEnabled,
      })
      fetchData()
    } else {
      track('AI_ASSISTANT_ALL_CONVERSATIONS_PAGE_VIEW_REDIRECT', {
        initialUser: authUser?.initialUser,
        aiAssistedOnboardingEnabled,
        redirectTo: FrontendUrls.dashboard,
      })
      history.push(FrontendUrls.dashboard)
    }
  }, [authCompany?.trialPeriod, authUser?.initialUser, aiAssistedOnboardingEnabled])

  const fetchData = async () => {
    setIsLoading(true)
    try {
      if (!authCompany?.id) {
        // Auth company ID is not yet available, return
        return
      }
      const conversationResponse = await getAiAssistantConversationInfoDataQuery({ variables: { companyId: authCompany.id } })
      if (!conversationResponse.data || conversationResponse.error) throw conversationResponse.error

      const conversationData = conversationResponse.data?.getAiAssistedConversationInfo
      logger.warning('AI Assistant Conversations', JSON.stringify(conversationData))
      setTopics(conversationData)
      setIsLoading(false)
    } catch (error) {
      logger.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const sendTopic = async () => {
    if (!input || input.trim() === '' || input.length < 1) {
      return
    }
    try {
      // TODO: THIS IS FOR THE FIRST VERSION, LATER WE SHOULD ADD NEW API ENDPOINT, ONCE WE HAVE MORE CONVERSATIONS WITH AI
      await Api.post('/core/ai-assisted-onboarding', {
        isInitial: false,
        userLang: authUser?.locale,
        message: 'bla bla bla',
      })
      setInput('')
    } catch (error) {
      logger.error(error instanceof Error ? error : JSON.stringify(error))
      const errorDescription = error.response?.data?.message ? error.response?.data?.message : error.message ? error.message : JSON.stringify(error)
      notification.error({
        message: formatMessage({ id: 'ai.assisted.onboarding.sendMessageError' }),
        description: errorDescription,
        duration: 0,
      })
      setInput('')
    }
  }

  const handleUserInput = (e) => {
    setInput(e.target.value)
  }

  const getHelloMessage = () => {
    const getHours = new Date().getHours()
    let message = ''
    if (getHours < 12) {
      message = `${formatMessage({id: 'app.goodMorning'})}, ${authUser?.name}`
    } else if (getHours < 18) {
      message = `${formatMessage({id: 'app.goodAfternoon'})}, ${authUser?.name}`
    } else {
      message = `${formatMessage({id: 'app.goodEvening'})}, ${authUser?.name}`
    }
    return <h1>{message}</h1>
  }

  function handleOnOnboardingTopicClick(id: string) {
    history.push(`${FrontendUrls.aiAssistant}/${id}`)
  }

  return (
    <div className="main-content vt-callendar-sync-page">
      <div className="main-content-header" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '18px' }}>
        {getHelloMessage()}
      </div>
      <div className="main-content-body">
        {aiAssistedOnboardingEnabled && <div className="ai-assistant-conversation-list-container">
          <Tooltip title={<IntlMessages id="ai.assistant.inputLockedTooltip" />}>
            <Input.Group className="ai-assistant-conversation-list-input" compact>
              <Input
                maxLength={1000}
                placeholder={formatMessage({ id: 'ai.assistant.defaultMessage' })}
                value={input}
                onChange={handleUserInput}
                onPressEnter={sendTopic}
                disabled // TODO: THIS IS FOR THE FIRST VERSION, LATER WE"LL ENABLE MORE CONVERSATIONS WIHR AI
                size='large'
                className="ai-assistant-conversation-list-input-field"
              />
              <Button
                size="large"
                type="primary"
                className="ai-assistant-conversation-list-input-button"
                disabled // TODO: THIS IS FOR THE FIRST VERSION, LATER WE"LL ENABLE MORE CONVERSATIONS WIHR AI
                onClick={sendTopic}
              >
                <IntlMessages id="app.send" />
              </Button>
            </Input.Group>
          </Tooltip>
          <div className="ai-assistant-input-disclaimer">
            <IntlMessages id="ai.assistant.disclaimer" values={{
              support: (...chunks) => <a onClick={() => openSupportChat('AI_ASSISTANT_CONVERSATIONS')}>{chunks}</a>,
            }} />
          </div>
        </div>}
        <br />
        <br />
        <br />
        {aiAssistedOnboardingEnabled && <List
          size='large'
          loading={isLoading}
          bordered
          className="ai-assistant-conversation-list-container"
          dataSource={topics as IConverssationInfo[] || []}
          renderItem={item => {
            if (item.topic === 'Onboarding') {
              return <Link to={`${FrontendUrls.aiAssistant}/${item.id}`}>
                <List.Item
                  onClick={() => handleOnOnboardingTopicClick(item.id)}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <List.Item.Meta
                    title={<>
                      {item.topic} { item.isDone &&
                        <Tooltip title={<IntlMessages id="ai.assistant.completedConversationTooltip" />}>
                          <Tag color="green">{formatMessage({ id: 'app.completed' })}</Tag>
                        </Tooltip>
                      }
                    </>}
                  />
                  <div className="ai-assistant-conversation-list-timestamp">
                    <Tooltip title={<IntlMessages id="ai.assistant.lastMessageTimestampTooltip" values={{
                      date: new Date((item.updatedAt || item.timestamp) as string).toString(),
                    }} />}>
                      {format(new Date((item.updatedAt || item.timestamp) as string), 'MMMM d, HH:mm')}
                    </Tooltip>
                  </div>
                </List.Item>
              </Link>
            }
          }}
          locale={{
            emptyText: <Empty
              image={Empty.PRESENTED_IMAGE_DEFAULT}
              description={
                <IntlMessages id="ai.assisted.onboarding.noTopics" />
              }
            />,
          }}
        />}
      </div>
    </div>
  )
}

export default AiAssistedOnboarding